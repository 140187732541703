import React from 'react';
import JumpToEditSelect from './JumpToEditSelect';

interface JumpToEditProps {
  content: {
    type: string;
    jumpToId: string;
  };
  contentErrors: {
    jumpToId: string;
  };
  path: string;
  setFieldValue: (value: string) => void;
  containingStepId: string;
}

const JumpToEdit = React.memo(({ content, contentErrors, path, setFieldValue, containingStepId }: JumpToEditProps) => (
  <div className="flex flex-row w-full">
    <div className="flex flex-col w-96 mr-2">
      <span className="field-title">Jump To</span>
      <JumpToEditSelect
        includeSections={true}
        path={`${path}.jumpToId`}
        containingStepId={containingStepId}
        value={content.jumpToId}
        onChange={setFieldValue}
        isDisabled={false}
      />
      {contentErrors && contentErrors.jumpToId && <div className="text-red-700">{contentErrors.jumpToId}</div>}
    </div>
  </div>
));

export default JumpToEdit;

/**
 * @typedef {Object} RevToken
 * @property {number} num - The revision positive increasing number.
 * @property {string} signature - The document signature (hash).
 */

export interface CouchDBChanges {
  results: {
    id: string;
    changes: DocRev[];
    deleted?: boolean;
  }[];
}

export interface DocChange {
  _id: string;
  _rev: string;
  deleted?: boolean;
}

export interface DocRev {
  rev: string;
}

export interface RevToken {
  num: number;
  signature: string;
}

const couchdbUtil = {
  /**
   * Parses a couchdb revision string into the number and hash components.
   *
   * In CouchDB, _rev is an "MVCC" token, defined by "Revision" in:
   * https://docs.couchdb.org/en/3.2.2-docs/replication/protocol.html
   *
   * @param {String} rev - A couchdb document revision string.
   * @returns {RevToken} results - The parsed revision token information.
   */
  parseRev: (rev: string): RevToken => {
    const [num, signature] = rev.split('-');
    return {
      num: parseInt(num),
      signature,
    };
  },
  /**
   * Returns true if the first rev is an earlier (lower) rev than the second rev.
   *
   * @param {String} rev1 - First rev to compare.
   * @param {String} rev2 - Second rev to compare.
   * @returns {Boolean} - True if rev1 < rev2, that is, rev2 is a later rev.
   */
  isEarlierRev: (rev1: string, rev2: string): boolean => {
    const { num: num1 } = couchdbUtil.parseRev(rev1);
    const { num: num2 } = couchdbUtil.parseRev(rev2);
    return num1 < num2;
  },

  _getLatestRev: (revisionChanges: DocRev[]): string => {
    let latest;
    for (const rev of revisionChanges) {
      if (!latest || couchdbUtil.isEarlierRev(latest, rev.rev)) {
        latest = rev.rev;
      }
    }
    return latest;
  },

  getChangedDocs: (changes: CouchDBChanges): DocChange[] => {
    return changes.results.map((docChanges) => {
      const change: DocChange = {
        _id: docChanges.id,
        _rev: couchdbUtil._getLatestRev(docChanges.changes),
      };
      if (docChanges.deleted !== undefined && docChanges.deleted !== null) {
        change.deleted = docChanges.deleted;
      }
      return change;
    });
  },
};

export default couchdbUtil;

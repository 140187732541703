import { ExpressionTokenDiffElement } from 'shared/lib/types/views/procedures';
import React, { useMemo } from 'react';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import ReviewReferenceToken from './Blocks/ReviewReferenceToken';
import { isEqual, mapValues } from 'lodash';
import DiffOldAndNew from '../Diff/DiffOldAndNew';

interface ReviewReferenceDetailProps {
  tokens: Array<ExpressionTokenDiffElement>;
}

const ReviewReferenceDetail = ({ tokens }: ReviewReferenceDetailProps) => {
  const oldTokens = useMemo(() => {
    return tokens.flatMap((token) => {
      if (
        !token.diff_change_state ||
        token.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED ||
        token.diff_change_state === ARRAY_CHANGE_SYMBOLS.UNCHANGED
      ) {
        return token;
      }
      if (token.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED) {
        return {
          ...mapValues(token, (value) => sharedDiffUtil.getDiffValue({ value }, 'value', 'old')),
          diff_change_state: ARRAY_CHANGE_SYMBOLS.REMOVED,
          reference_id: sharedDiffUtil.getDiffValue(token, 'reference_id', 'old'),
        } as ExpressionTokenDiffElement;
      }
      return [];
    });
  }, [tokens]);

  const newTokens = useMemo(() => {
    return tokens.flatMap((token) => {
      if (
        !token.diff_change_state ||
        token.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED ||
        token.diff_change_state === ARRAY_CHANGE_SYMBOLS.UNCHANGED
      ) {
        return token;
      }
      if (token.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED) {
        return {
          ...mapValues(token, (value) => sharedDiffUtil.getDiffValue({ value }, 'value', 'new')),
          diff_change_state: ARRAY_CHANGE_SYMBOLS.ADDED,
          reference_id: sharedDiffUtil.getDiffValue(token, 'reference_id', 'new'),
        } as ExpressionTokenDiffElement;
      }
      return [];
    });
  }, [tokens]);

  const hasChanged = useMemo(() => !isEqual(oldTokens, newTokens), [newTokens, oldTokens]);

  return (
    <DiffOldAndNew
      previous={
        <>
          {oldTokens &&
            oldTokens.length > 0 &&
            oldTokens.map((token, index) => {
              return token.type === 'reference' && token.reference_id ? (
                <ReviewReferenceToken
                  key={index}
                  originalReferencedContentId={token.reference_id}
                  diffChangeState={token.diff_change_state}
                  isLight={hasChanged}
                />
              ) : (
                <div key={index} className="py-1 mx-0.5 text-sm">
                  {token.value}
                </div>
              );
            })}
        </>
      }
      updated={
        <>
          {newTokens &&
            newTokens.length > 0 &&
            newTokens.map((token, index) => {
              return token.type === 'reference' && token.reference_id ? (
                <ReviewReferenceToken
                  key={index}
                  originalReferencedContentId={token.reference_id}
                  diffChangeState={token.diff_change_state}
                  isLight={hasChanged}
                />
              ) : (
                <div key={index} className="py-1 mx-0.5 text-sm">
                  {token.value}
                </div>
              );
            })}
        </>
      }
      hasChanged={hasChanged}
      label="Expression"
    />
  );
};

export default ReviewReferenceDetail;

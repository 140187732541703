import { EventEmitter2 } from 'eventemitter2';
import { Selectable } from '../../contexts/Selection';
import { Boundary } from './Selectable';

type Callback = () => void;

// Total number of sections, steps, and context blocks we support
const MAX_LISTENERS = 1000000;

class SelectionApi {
  selected: Array<Selectable>;
  emitter: EventEmitter2;

  constructor() {
    this.selected = [];
    this.emitter = new EventEmitter2();

    this.emitter.setMaxListeners(MAX_LISTENERS);

    this.addSelection = this.addSelection.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.removeSelection = this.removeSelection.bind(this);
    this.clearSelections = this.clearSelections.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
  }

  addSelection(selectable: Selectable, isMultiSelect?: boolean): void {
    if (this.isSelected(selectable)) {
      return;
    }

    if (!isMultiSelect) {
      this.selected = [selectable];
      this.emitter.emit('selection_change', this.selected);
      return;
    }

    this.selected.push(selectable);
    this.emitter.emit('selection_change', this.selected);
  }

  isSelected(selectable: Selectable): boolean {
    const { id, type } = selectable;
    const equalsSelected = (s) => s.id === id && s.type === type;
    return this.selected.some(equalsSelected);
  }

  removeSelection(selectable: Selectable): void {
    const { id, type } = selectable;
    const equalsSelected = (s) => s.id === id && s.type === type;
    this.selected = this.selected.filter((s) => !equalsSelected(s));
    this.emitter.emit('selection_change', this.selected);
  }

  clearSelections(boundary?: Boundary): void {
    if (!boundary) {
      this.selected = [];
      this.emitter.emit('selection_change', this.selected);
      return;
    }

    const equalsBoundary = (s) => s.type === boundary;
    this.selected = this.selected.filter((s) => !equalsBoundary(s));
    this.emitter.emit('selection_change', this.selected);
  }

  onSelectionChanged(callback: Callback): Callback {
    this.emitter.on('selection_change', callback);

    return () => {
      this.emitter.off('selection_change', callback);
    };
  }
}

export default SelectionApi;

import { useState } from 'react';

type UseExpectedStateReturn<V, E> = {
  value?: V;
  setValue: (v?: V) => void;
  error?: E;
  setError: (e?: E) => void;
};

type Expected<V, E> = {
  value?: V;
  error?: E;
};

/*
 * Wrapper around React.useState that holds at most one of two values-- an expected value of
 * type V or an unexpected value of type E.
 *
 * useExpectedState manages both the expected and unexpected value in the same react state
 * to enforce that both values cannot be present at the same time.
 */
const useExpectedState = <V, E>(
  initialValue?: V
): UseExpectedStateReturn<V, E> => {
  const [expected, setExpected] = useState<Expected<V, E>>({
    value: initialValue,
  });

  const value = expected.value;
  const setValue = (v?: V): void => setExpected({ value: v });

  const error = expected.error;
  const setError = (e?: E): void => setExpected({ error: e });

  return {
    value,
    setValue,
    error,
    setError,
  };
};

export default useExpectedState;

import React, { useCallback, useEffect, useState } from 'react';
import { StepDuration } from 'shared/lib/types/couch/procedures';
import { Duration } from 'luxon';
import { useRunContext } from '../contexts/RunContext';
import Button from './Button';

interface StepDetailProps {
  value: StepDuration;
  disabled: boolean;
  timingPresent: boolean;
  updateStepDetail: (field: string, value: StepDuration) => void;
  isPaused: boolean;
}

const FieldStepDuration = (props: StepDetailProps) => {
  const { value, disabled, updateStepDetail, timingPresent, isPaused } = props;

  const { calculatePauseDurationInMs } = useRunContext();
  const [durationInMs, setDurationInMs] = useState(value.duration || 0);
  const [started, setStarted] = useState(Boolean(value.started_at));
  const [displayMessage, setDisplayMessage] = useState('');

  const calculateTimeElapsed = useCallback(() => {
    if (value.started_at) {
      const startTimeInMs = new Date(value.started_at).getTime();
      const currentTimeInMs = new Date().getTime();
      const adjustedTimeInMs = currentTimeInMs - startTimeInMs - calculatePauseDurationInMs(startTimeInMs);

      return Math.max(0, adjustedTimeInMs);
    }
    return 0;
  }, [value.started_at, calculatePauseDurationInMs]);

  useEffect(() => {
    let timerId;
    if (started && !isPaused && !value.duration) {
      timerId = setInterval(() => {
        const newDurationInMs = calculateTimeElapsed();
        setDurationInMs(newDurationInMs);
      }, 1000);
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [started, isPaused, calculateTimeElapsed, value.duration]);

  useEffect(() => {
    setStarted(Boolean(value.started_at));
    if (value.started_at) {
      const newDuration = calculateTimeElapsed();
      setDurationInMs(newDuration);
    }
  }, [value.started_at, calculateTimeElapsed]);

  const onButtonPress = useCallback(() => {
    if (!started) {
      const startAt = new Date().toISOString();
      setStarted(true);
      value.started_at = startAt;
      updateStepDetail('duration', value);
    }
  }, [started, value, updateStepDetail]);

  useEffect(() => {
    if (value.duration) {
      setDisplayMessage(Duration.fromISO(value.duration).toFormat('hh:mm:ss'));
    } else {
      setDisplayMessage(Duration.fromMillis(durationInMs).toFormat('hh:mm:ss'));
    }
  }, [value.duration, durationInMs]);

  const isStarted = value.started_at;
  const showButton = !isStarted && !isPaused && !timingPresent;

  return (
    <div className="flex flex-row">
      {isStarted && <div className="mr-1 text-lg">{displayMessage}</div>}
      {showButton && (
        <Button onClick={onButtonPress} isDisabled={disabled} type="primary" size="sm">
          <span>Start</span>
        </Button>
      )}
    </div>
  );
};

export default FieldStepDuration;

import { TypedFieldSetProps } from '../Blocks/BlockTypes';
import { Field } from 'formik';
import ExpressionEdit from './ExpressionEditPlainText';

const ExpressionBlockEdit = ({
  path,
  content,
  contentErrors,
  pendingStep,
  precedingStepId,
  setFieldValue,
}: TypedFieldSetProps<'expression'>) => {
  return (
    <div className="flex flex-row w-full">
      <div className="flex flex-col w-full mr-2">
        <Field
          name={`${path}.name`}
          path={path}
          component={ExpressionEdit}
          content={content}
          contentErrors={contentErrors}
          pendingStep={pendingStep}
          precedingStepId={precedingStepId}
          setFieldValue={setFieldValue}
        />
      </div>
    </div>
  );
};

export default ExpressionBlockEdit;

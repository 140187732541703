import React, { useCallback, useState, useMemo } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Pluralize from 'pluralize';
import Select from 'react-select';
import { reactSelectStyles } from '../lib/styles';
import referenceUtil from '../lib/referenceUtil';
import { PrintContent, V2Variable } from 'shared/lib/types/views/procedures';
import { FieldInputProps, FormikProps } from 'formik';
import { SUPPORTED_REFERENCES } from '../lib/printUtil';

const MAX_CHARACTERS = 100;

interface LimitedCharactersTextAreaProps {
  name: string;
  maxCharacters: number;
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (value) => void;
  onBlur?: (event) => void;
}

const LimitedCharactersTextArea = ({
  name,
  maxCharacters,
  value,
  placeholder,
  className,
  onChange,
  onBlur,
}: LimitedCharactersTextAreaProps) => {
  const [remainingCharacters, setRemainingCharacters] = useState(maxCharacters - (value || '').length);

  const onTextChange = useCallback(
    (event) => {
      setRemainingCharacters(maxCharacters - event.target.value.length);
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange, maxCharacters]
  );

  return (
    <>
      <TextareaAutosize
        aria-label="PrintContent Text"
        onChange={onTextChange}
        placeholder={placeholder || 'Enter Text'}
        className={className}
        maxLength={maxCharacters}
        value={value || ''}
        name={name}
        onBlur={onBlur}
      />
      <div className="text-xs text-gray-500 mt-1 ml-3 font-medium tracking-tight">
        {`${Pluralize('character', remainingCharacters, true)} remaining`}
      </div>
    </>
  );
};

type ReferenceOptionsType = {
  label: string;
  value: string;
};

interface FieldSetPrintContentProps {
  value?: PrintContent;
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  variables?: Array<V2Variable>;
  maxCharacters?: number;
}

const FieldSetPrintContent = ({
  value,
  field,
  form,
  variables,
  maxCharacters = MAX_CHARACTERS,
}: FieldSetPrintContentProps) => {
  const [variableOptions, setVariableOptions] = useState<Array<ReferenceOptionsType>>([]);
  const textFieldName = `${field.name}.text`;
  const selectFieldName = `${field.name}.variable_id`;

  const onTextChange = useCallback(
    (value) => {
      form.setFieldValue(textFieldName, value);
    },
    [form, textFieldName]
  );

  const getVariableOptions = useCallback(() => {
    const options: Array<ReferenceOptionsType> = [];
    if (!variables) {
      return;
    }
    variables.forEach((contentBlock) => {
      if (referenceUtil.isReferenceEnabled(contentBlock, SUPPORTED_REFERENCES)) {
        options.push({
          label: contentBlock.name,
          value: contentBlock.id,
        });
      }
    });

    setVariableOptions(options);
  }, [variables]);

  const selectValue = useMemo((): ReferenceOptionsType | null => {
    if (!value || !value.variable_id || !variables) {
      return null;
    }

    const matchingVariable = variables.find((variable) => variable.id === value.variable_id);
    if (matchingVariable) {
      return {
        label: matchingVariable.name,
        value: matchingVariable.id,
      };
    }
    return null;
  }, [value, variables]);

  const onSelectChange = (option: ReferenceOptionsType) => {
    if (!option) {
      form.setFieldValue(selectFieldName, '');
      return;
    }
    const selectedOptionValue = option.value;

    // Skip onChange when re-selecting the same value.
    if (value && selectedOptionValue === value.variable_id) {
      return;
    }

    form.setFieldValue(selectFieldName, selectedOptionValue);
  };

  const textValue = useMemo(() => {
    if (!value || !value.text) {
      return '';
    }
    return value.text;
  }, [value]);

  return (
    <>
      <label className="my-1 text-sm font-semibold">Text</label>
      <LimitedCharactersTextArea
        className="grow text-sm border-1 border-gray-400 rounded"
        maxCharacters={maxCharacters}
        value={textValue}
        name={textFieldName}
        onChange={onTextChange}
        onBlur={field.onBlur}
      />
      <label className="my-1 text-sm font-semibold">Variable</label>
      <Select
        className="text-sm"
        aria-label="PrintContent Variable"
        classNamePrefix="react-select"
        formatOptionLabel={(option) => `${option.label}`}
        name={selectFieldName}
        onBlur={field.onBlur}
        onChange={onSelectChange}
        onMenuOpen={getVariableOptions}
        options={variableOptions}
        placeholder="Select Variable"
        styles={reactSelectStyles}
        value={selectValue}
        isOptionSelected={(option) => value && option.value === value.variable_id}
        isClearable={true}
        menuPlacement="auto"
      />
    </>
  );
};

export default React.memo(FieldSetPrintContent);

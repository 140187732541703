import {
  RunActions,
  Severity,
  Status,
  ReferenceType,
  SubStatus,
} from 'shared/lib/types/postgres/issues';
import {
  Activity,
  EntityDetailWithValue,
} from 'shared/lib/types/postgres/util';
import { Attachment } from 'shared/lib/types/attachments';
import { Step } from 'shared/lib/types/views/procedures';

export type Issue = {
  id: number;
  title: string;
  status: Status;
  subStatus: SubStatus | null;
  createdAt: string;
  createdBy: string;
  runId?: string;
  referenceId: string;
  referenceType: ReferenceType;
  assignee?: string;
  severity?: Severity;
  notes?: string;
  attachments?: Array<Attachment>;
  activity: Array<Activity | RunActions>;
  details?: Array<EntityDetailWithValue>;
  steps: Array<Step>;
  projectId?: string;
};

export type IssueCreate = {
  title: string;
  runId?: string;
  referenceId: string;
  referenceType: ReferenceType;
  assignee?: string;
  severityId?: number;
  notes?: string;
  attachments?: Array<Attachment>;
  details?: Array<EntityDetailWithValue>;
  projectId?: string | null;
};

export type IssueUpdate = {
  title?: string;
  status_id?: number;
  sub_status_id?: number | null;
  severity_id?: number;
  assignee?: string;
  notes?: string | null;
  attachments?: Array<Attachment>;
  details?: Array<EntityDetailWithValue>;
  steps?: Array<Step>;
  project_id?: string | null;
};

export type IssueRow = {
  id: number;
  title: string;
  createdAt: string;
  runId?: string;
  referenceId: string;
  referenceType: ReferenceType;
  assignee?: string;
  severity?: Severity;
  status?: Status;
  subStatus: SubStatus | null;
  projectId?: string;
};

export enum StatusTab {
  Open = 'open',
  Closed = 'closed',
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface DetachedRedlineSpanProps {
  openRedlines: () => void;
}

const DetachedRedlineSpan = React.memo(({ openRedlines }: DetachedRedlineSpanProps) => {
  return (
    <button onClick={openRedlines}>
      <div className="relative flex flex-row w-full truncate text-sm cursor-pointer hover:text-blue-600">
        <div className="absolute w-full h-full rounded -z-30" />
        <div className="flex flex-row flex-nowrap items-center w-full px-2 py-2">
          <span className="text-gray-400">Detached Redlines</span>
          <FontAwesomeIcon icon={'fa-solid fa-strikethrough' as IconProp} className="text-red-500 ml-1" />
        </div>
      </div>
    </button>
  );
});

export default DetachedRedlineSpan;

import { RunTags, Tags } from 'shared/lib/types/couch/settings';
import idUtil from './idUtil';
import { FilterOption } from '../components/lib/SearchFilter';

type Tag = {
  key: string;
  name: string;
};

// Creates new tag with unique ID from tag name
export const createNewTag = (name: string): Tag => {
  return {
    key: idUtil.generateId(),
    name,
  };
};

export const getTagNames = (
  keys: ReadonlySet<string | null>,
  tags: Tags | null,
  runTags?: RunTags | null
): Set<string> => {
  // todo - only have one set of tag keys
  const tagNames: Set<string> = new Set<string>();
  keys.forEach((key) => {
    if (key === null) {
      tagNames.add('None');
    } else {
      if (runTags?.run_tags?.[key]) {
        tagNames.add(runTags.run_tags[key].name);
      } else if (tags?.tags?.[key]) {
        tagNames.add(tags.tags[key].name);
      }
    }
  });
  return tagNames;
};

export const getTagsAsOptions = (
  tags: Tags | null,
  /** If given, run tags are combined with procedure tags */
  runTags?: RunTags | null
): Array<FilterOption<string>> => {
  const combinedTags = new Map<string, FilterOption<string>>();

  for (const tag of Object.values(tags?.tags ?? {})) {
    combinedTags.set(tag.name, { id: tag.key, label: tag.name });
  }

  for (const tag of Object.values(runTags?.run_tags ?? {})) {
    combinedTags.set(tag.name, { id: tag.key, label: tag.name });
  }
  return [...combinedTags.values()];
};

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { toolInstanceDetailPath } from '../../../lib/pathUtil';
import useToolAndToolInstance from '../../hooks/useToolAndToolInstance';
import toDisplay, { ToolAndToolInstance } from '../../lib/tools/toDisplay';
import Badge from '../Badge';

type ToolInstanceBadgeProps = {
  toolInstanceId: number;
  isCheckedOut?: boolean;
};

const ToolInstanceBadge = ({ toolInstanceId, isCheckedOut }: ToolInstanceBadgeProps) => {
  const { toolAndToolInstance } = useToolAndToolInstance({ toolInstanceId });
  if (!toolAndToolInstance) {
    return null;
  }

  return (
    <Badge
      attachment={toDisplay.fromToolImageProps(toolAndToolInstance)}
      title={<Title toolAndToolInstance={toolAndToolInstance} />}
      subtitle={<Subtitle toolAndToolInstance={toolAndToolInstance} isCheckedOut={isCheckedOut} />}
    />
  );
};

type TitleProps = {
  toolAndToolInstance: ToolAndToolInstance;
};

const Title = ({ toolAndToolInstance }: TitleProps) => {
  const { currentTeamId } = useDatabaseServices();

  return (
    <div className="flex gap-x-1">
      <Link
        className="text-blue-600"
        to={toolInstanceDetailPath(currentTeamId, toolAndToolInstance.tool_id, toolAndToolInstance.id)}
      >
        <div>{toolAndToolInstance.serial_number}</div>
      </Link>
      <div className="text-gray-500">{toolAndToolInstance.tool_number}</div>
    </div>
  );
};

type SubtitleProps = {
  toolAndToolInstance: ToolAndToolInstance;
  isCheckedOut?: boolean;
};

const Subtitle = ({ toolAndToolInstance, isCheckedOut }: SubtitleProps) => {
  return (
    <div className="flex items-center gap-x-1">
      <div className="text-gray-700">{toolAndToolInstance.name}</div>
      {isCheckedOut && <FontAwesomeIcon className="text-green-500" size="sm" icon="upload" />}
    </div>
  );
};

export default ToolInstanceBadge;

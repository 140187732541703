import { cloneDeep } from 'lodash';
import {
  Draft,
  DraftCommandingBlock,
  DraftTelemetryBlock,
} from 'shared/lib/types/views/procedures';
import { getInitialFormValues } from '../components/Blocks/BlockTypes';
import { ProcedureContentBlockTypes } from 'shared/lib/types/blockTypes';
import { TelemetryModel } from './models/postgres/telemetry';
import { Command } from './models/postgres/commanding';

const dictionaryUtil = {
  /*
   * When the dictionary changes, revalidate the existing telemetry and commanding fields
   * If an option is no longer available in the list, clear it
   * If there is a name match in the new dictionary, set it to that
   */
  updateProcedureOnDictionaryChange: (
    procedure: Draft,
    dictionaryId: number,
    telemetries: Array<TelemetryModel>,
    commands: Array<Command>
  ): Draft => {
    const telemetryMap = Object.fromEntries(
      telemetries.map((parameter) => [parameter.name, parameter.dictionary_id])
    );
    const commandMap = Object.fromEntries(
      commands.map((command) => [command.name, command.dictionary_id])
    );

    for (const sectionId in procedure.sections) {
      const section = procedure.sections[sectionId];
      for (const stepId in section.steps) {
        const step = section.steps[stepId];
        for (const contentId in step.content) {
          const content = cloneDeep(step.content[contentId]);
          if (
            content.type === 'telemetry' &&
            content.dictionary_id !== dictionaryId &&
            content.name
          ) {
            if (telemetryMap[content.name]) {
              // unambiguous name match
              procedure.sections[sectionId].steps[stepId].content[contentId] = {
                ...content,
                dictionary_id: telemetryMap[content.name],
              };
            } else {
              // no match
              procedure.sections[sectionId].steps[stepId].content[contentId] = {
                ...getInitialFormValues(ProcedureContentBlockTypes.Telemetry),
                id: content.id,
              } as DraftTelemetryBlock;
            }
          } else if (
            content.type === 'commanding' &&
            content.dictionary_id !== dictionaryId &&
            content.name
          ) {
            if (commandMap[content.name]) {
              // unambiguous name match
              procedure.sections[sectionId].steps[stepId].content[contentId] = {
                ...content,
                dictionary_id: commandMap[content.name],
              };
            } else {
              // no match
              procedure.sections[sectionId].steps[stepId].content[contentId] = {
                ...getInitialFormValues(ProcedureContentBlockTypes.Commanding),
                id: content.id,
              } as DraftCommandingBlock;
            }
          }
        }
      }
    }
    return procedure;
  },
};

export default dictionaryUtil;

import { useCallback, useMemo, useState } from 'react';
import Select from 'react-select';
import { Tool } from 'shared/lib/types/api/manufacturing/tools/models';
import { DraftToolUsageBlock, InitialToolUsageBlock } from 'shared/lib/types/views/procedures';
import FieldSetCheckbox from '../../../components/FieldSetCheckbox';
import Tooltip from '../../../elements/Tooltip';
import { NumberSelectOption as SelectOption } from '../../../lib/formik';
import { reactSelectStyles } from '../../../lib/styles';
import useTools from '../../hooks/useTools';
import useUsageTypes from '../../hooks/useUsageTypes';
import toDisplay from '../../lib/tools/toDisplay';
import ThumbnailImage from '../ThumbnailImage';
import UsageTypeSelector from './UsageTypeSelector';
import { toolToSelectOption } from './utils';
import { useRunContext } from '../../../contexts/RunContext';

export type ContentErrors = {
  tool?: string;
  usage_type_id?: string;
};

interface DraftToolUsageProps {
  content: InitialToolUsageBlock | DraftToolUsageBlock;
  contentErrors?: ContentErrors | null;
  path: string;
  setFieldValue?: (path: string, value: InitialToolUsageBlock | DraftToolUsageBlock) => void;
}

const DraftToolUsage = ({ content, contentErrors, path, setFieldValue }: DraftToolUsageProps) => {
  const { allTools } = useTools();
  const { isRun } = useRunContext();

  const [selectedToolId, setSelectedToolId] = useState<number | null>(content.tool_id);
  const [selectedUsageTypeId, setSelectedUsageTypeId] = useState<number | null>(content.usage_type_id);
  const [toolSelectMenuOpen, setToolSelectMenuOpen] = useState(false);
  const { usageTypes } = useUsageTypes();

  const selectedTool = useMemo(() => {
    if (!allTools || !selectedToolId) {
      return undefined;
    }
    const tool = allTools.find((tool) => tool.id === selectedToolId);
    if (!tool) {
      return undefined;
    }
    return toolToSelectOption(tool);
  }, [allTools, selectedToolId]);

  const toolOptions: Array<SelectOption> = useMemo(() => {
    if (!allTools) {
      return [];
    }
    return allTools.map((tool) => ({
      value: tool.id,
      label: `${tool.tool_number} ${tool.name}`,
      isDisabled: tool.usage_type_ids.length === 0,
    }));
  }, [allTools]);

  const formatOptionLabel = (tool?: Tool) => {
    if (!tool || !usageTypes) {
      return null;
    }
    const usageTypesForTool = usageTypes.filter((usageType) => tool.usage_type_ids.includes(usageType.id));
    const tooltipContent = usageTypesForTool.length === 0 ? 'No usage types available' : null;
    return (
      <div className="flex">
        <div>
          <ThumbnailImage size="sm" attachment={toDisplay.fromToolImageProps(tool)} />
        </div>
        {toolSelectMenuOpen && tooltipContent && (
          <Tooltip content={tooltipContent}>
            <span className="pl-2 truncate">
              {tool.tool_number} {tool.name}
            </span>
          </Tooltip>
        )}
        {(!toolSelectMenuOpen || !tooltipContent) && (
          <span className="pl-2 truncate">
            {tool.tool_number} {tool.name}
          </span>
        )}
      </div>
    );
  };

  const availableUsageTypeIds = useMemo(
    () => allTools?.find((tool) => tool.id === selectedToolId)?.usage_type_ids ?? [],
    [allTools, selectedToolId]
  );

  const getUsageTypeId = useCallback(
    (availableUsageTypeIds: Array<number>) =>
      selectedUsageTypeId && !availableUsageTypeIds.includes(selectedUsageTypeId)
        ? availableUsageTypeIds[0] ?? null
        : selectedUsageTypeId ?? availableUsageTypeIds[0],
    [selectedUsageTypeId]
  );

  const updateFieldValue = useCallback(
    (toolId: number | null, usageTypeId: number | null) => {
      if (!setFieldValue) {
        return;
      }
      const value: InitialToolUsageBlock | DraftToolUsageBlock = {
        ...content,
        tool_id: toolId,
        usage_type_id: usageTypeId,
      };
      setFieldValue(path, value);
    },
    [content, path, setFieldValue]
  );

  const onToolChange = useCallback(
    (toolOption: SelectOption) => {
      setSelectedToolId(toolOption.value);
      const availableUsageTypeIds = allTools?.find((tool) => tool.id === toolOption.value)?.usage_type_ids ?? [];
      const usageTypeId = getUsageTypeId(availableUsageTypeIds);
      setSelectedUsageTypeId(usageTypeId);
      updateFieldValue(toolOption.value, usageTypeId);
    },
    [allTools, getUsageTypeId, updateFieldValue]
  );

  const onUsageChange = useCallback(
    (options: SelectOption | Array<SelectOption>) => {
      const option = options as SelectOption;
      setSelectedUsageTypeId(option.value);
      updateFieldValue(selectedToolId, option.value);
    },
    [selectedToolId, updateFieldValue]
  );

  return (
    <>
      <div className="flex flex-row">
        <div className="w-72">
          <div className="field-title">Tool for Recording Usage</div>
          <div className="flex flex-row">
            <div className="w-72">
              <Select
                styles={reactSelectStyles}
                classNamePrefix="react-select"
                formatOptionLabel={(option: SelectOption) =>
                  formatOptionLabel(allTools?.find((tool) => tool.id === option.value))
                }
                options={toolOptions}
                value={selectedTool}
                onChange={onToolChange}
                placeholder="Search tools"
                aria-label="Select tool"
                onMenuOpen={() => setToolSelectMenuOpen(true)}
                onMenuClose={() => setToolSelectMenuOpen(false)}
              />
            </div>
          </div>
        </div>
        <div className="w-2" />
        <div>
          <div className="field-title">Usage Type</div>
          <UsageTypeSelector
            usageTypeIds={selectedUsageTypeId}
            availableUsageTypeIds={availableUsageTypeIds}
            onChange={onUsageChange}
          />
        </div>
        <div className="w-2" />
        {/* Include in summary checkbox */}
        <div className="self-end mb-2 flex flex-row items-center">
          <FieldSetCheckbox
            text="Include in Summary"
            fieldName={`${path}.include_in_summary`}
            setFieldValue={setFieldValue}
            isDisabled={isRun}
          />
        </div>
      </div>
      <div className="flex flex-row">
        <div className="w-72">
          {contentErrors?.tool && <div className="text-red-700 text-sm">{contentErrors.tool}</div>}
        </div>
        <div className="w-2" />
        <div className="w-72">
          {contentErrors?.usage_type_id && <div className="text-red-700 text-sm">{contentErrors.usage_type_id}</div>}
        </div>
      </div>
    </>
  );
};

export default DraftToolUsage;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentPart, Part } from 'shared/lib/types/postgres/manufacturing/types';
import PartBadge from './PartBadge';
import RestrictedInfo from './RestrictedInfo';
import ItemQuantityInput from './ItemQuantityInput';

type FieldInputComponentProps = {
  part?: Part;
  component: ComponentPart;
  teamId: string;
  onChanged: (updated: ComponentPart) => void;
  onRemoved: (component: ComponentPart) => void;
  partRestricted?: boolean;
};

const FieldInputComponent = ({
  part,
  component,
  teamId,
  onChanged,
  onRemoved,
  partRestricted = false,
}: FieldInputComponentProps) => {
  const allowDecimalAmount = part?.allow_decimal_quantities === true;
  const onChangeAmount = (amount: number) => {
    const updated = {
      ...component,
      amount,
    };
    onChanged?.(updated);
  };

  return (
    <tr className="h-10 text-sm bg-white border-b items-center">
      <td>
        <PartBadge teamId={teamId} part={part} partRestricted={partRestricted} />
      </td>
      <td>
        <div className="p-2">
          {!partRestricted && (
            <ItemQuantityInput
              value={component.amount}
              allowDecimalValue={allowDecimalAmount}
              allowNull={false}
              onChange={(value) => onChangeAmount(value as number)}
              w={44}
              textSize="sm"
            />
          )}
          {partRestricted && <RestrictedInfo />}
        </div>
      </td>
      <td>
        <button type="button" className="secondary group" onClick={() => onRemoved?.(component)}>
          <FontAwesomeIcon className="text-gray-400 group-hover:text-gray-500 p-1" icon="trash" />
        </button>
      </td>
    </tr>
  );
};

export default FieldInputComponent;

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import userSettings from '../api/userSettings';

interface State {
  currentUserId: string | null;
  unacknowledgedMessageKeys: Array<string>;
}

export type ReduxState = {
  users: State;
};

export const fetchUnacknowledgedUserMessageKeys = createAsyncThunk(
  'users/unacknowledgedMessageKeys',
  async (): Promise<Array<string>> => {
    const keys = await userSettings.getUnacknowledgedUserMessageKeys();
    return keys;
  }
);

const reduceKeys = (state, action) => {
  if (action.payload) {
    state.unacknowledgedMessageKeys = action.payload;
  }
};

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    currentUserId: null,
    unacknowledgedMessageKeys: [] as Array<string>,
  } as State,
  reducers: {
    setCurrentUserId: (state, action) => {
      state.currentUserId = action.payload;
    },
    acknowledgeMessage: (state, action: PayloadAction<string>) => {
      state.unacknowledgedMessageKeys = state.unacknowledgedMessageKeys.filter(
        (k) => k !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    // Must use Builder notation for Typescript to infer types correctly
    builder.addCase(fetchUnacknowledgedUserMessageKeys.fulfilled, reduceKeys);
  },
});

export const selectCurrentUserId = (state: ReduxState): string | null =>
  state.users.currentUserId;

// Action creators are generated for each case reducer function
export const { setCurrentUserId, acknowledgeMessage } = usersSlice.actions;

export default usersSlice.reducer;

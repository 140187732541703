import {
  ProcedurePrimitive,
  DiffFieldChange,
  SectionDiffElement,
  StepDiffElement,
} from './types/views/procedures';

export const ARRAY_CHANGE_SYMBOLS = {
  ADDED: '+',
  REMOVED: '-',
  MODIFIED: '~',
  UNCHANGED: ' ',
} as const;

const isNil = (value) => {
  return value === null || value === undefined;
};

const diffUtil = {
  wasFieldAdded: (parent: object, field: string): boolean => {
    return parent && !(field in parent) && `${field}__added` in parent;
  },

  wasFieldDeleted: (parent: object, field: string): boolean => {
    return parent && !(field in parent) && `${field}__deleted` in parent;
  },

  isChanged: (parent: object | undefined, field: string): boolean => {
    if (!parent) {
      return false;
    }
    const oldValue = diffUtil.getDiffValue(parent, field, 'old');
    const newValue = diffUtil.getDiffValue(parent, field, 'new');

    return oldValue !== newValue;
  },

  isBooleanChanged: (parent: object | undefined, field: string): boolean => {
    if (!parent) {
      return false;
    }
    const oldValue = diffUtil.getDiffValue(parent, field, 'old');
    const newValue = diffUtil.getDiffValue(parent, field, 'new');

    return Boolean(oldValue) !== Boolean(newValue);
  },

  /**
   * Extracts the old or new value from a diff of a primitive variable.
   * If the entry passed in is already a primitive, return the entry.
   */
  getDiffValue: <T extends ProcedurePrimitive>(
    parent: object,
    field: string,
    version: 'old' | 'new'
  ): T => {
    if (diffUtil.wasFieldAdded(parent, field) && version === 'new') {
      return parent[`${field}__added`] as T;
    }
    if (diffUtil.wasFieldDeleted(parent, field) && version === 'old') {
      return parent[`${field}__deleted`] as T;
    }

    const entry = parent[field];
    if (entry === undefined || isNil(entry) || typeof entry !== 'object') {
      return entry as T;
    }

    // At this point, `entry` must be an object.
    const changeEntry = entry as DiffFieldChange<T>;
    if (version === 'old' && '__old' in changeEntry) {
      return changeEntry.__old;
    }
    if (version === 'new' && '__new' in changeEntry) {
      return changeEntry.__new;
    }

    return entry as T;
  },

  getContainerMap: (
    containers: Array<SectionDiffElement | StepDiffElement>,
    version: 'old' | 'new'
  ): Map<string, number> => {
    const ignoreType =
      version === 'old'
        ? ARRAY_CHANGE_SYMBOLS.ADDED
        : ARRAY_CHANGE_SYMBOLS.REMOVED;
    const versionSections = containers.filter(
      (section) => section.diff_change_state !== ignoreType
    );

    const sectionMap = new Map<string, number>();
    versionSections.forEach((section, sectionIndex) => {
      const sectionId = diffUtil.getDiffValue<string>(
        section,
        'id',
        section.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED
          ? 'old'
          : 'new'
      );
      sectionMap.set(sectionId, sectionIndex);
    });

    return sectionMap;
  },
};

export default diffUtil;

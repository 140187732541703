import BatchStepHeader, { BatchStepHeaderProps } from './BatchStepHeader';

const SummaryBatchStepHeader = (props: BatchStepHeaderProps) => (
  <tr>
    <td></td>
    <td colSpan={2}>
      <div className="ml-4">
        <BatchStepHeader {...props} />
      </div>
    </td>
  </tr>
);

export default SummaryBatchStepHeader;

type FieldLabelProps = {
  label: string;
  required?: boolean;
};

const FieldLabel = ({ label, required = false }: FieldLabelProps) => {
  return (
    <label htmlFor={label} className="text-xs text-gray-500">
      {label}
      {required && '*'}
    </label>
  );
};

export default FieldLabel;

import React, { useMemo, useCallback } from 'react';
import { Field } from 'formik';
import FieldSetSignoffSelect from './FieldSetSignoffSelect';
import cloneDeep from 'lodash.clonedeep';
import { Signoff } from 'shared/lib/types/views/procedures';

interface FieldSetSignoffProps {
  name: string;
  signoffIndex: number;
  signoff: Signoff;
  operatorOptions: { value: string; label: string }[];
  onSignoffUpdated: (updatedSignoff: Signoff, signoffIndex: number) => void;
  placeholder: string;
}

const FieldSetSignoff = ({
  name,
  signoffIndex,
  signoff,
  operatorOptions,
  onSignoffUpdated,
  placeholder,
}: FieldSetSignoffProps) => {
  const getOnChange = useCallback(() => {
    return (options) => {
      const values = options.map((o) => o.label);
      const updated = cloneDeep(signoff);
      updated.operators = values;
      onSignoffUpdated(updated, signoffIndex);
    };
  }, [signoff, onSignoffUpdated, signoffIndex]);

  const signoffOperatorValueLabels = useMemo(
    () =>
      signoff.operators.map((operator) => ({
        value: operator,
        label: operator,
      })),
    [signoff.operators]
  );

  return (
    <Field name={name}>
      {({ field }) => (
        <div className="relative group max-w-xl">
          <FieldSetSignoffSelect
            options={operatorOptions}
            name={field.name}
            placeholder={placeholder}
            opacity={0.7}
            value={signoffOperatorValueLabels}
            onChange={getOnChange()}
            onBlur={field.onBlur}
            ariaLabel="Signoff Select"
            hoverOpacity={undefined}
          />
        </div>
      )}
    </Field>
  );
};

export default FieldSetSignoff;

import useAttachment from '../../hooks/useAttachment';
import ReviewInlineImage from './ReviewInlineImage';
import { AttachmentBlockDiffElement, DiffArrayChangeSymbol } from 'shared/lib/types/views/procedures';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';

interface ReviewInlineImageStandaloneProps {
  attachment: AttachmentBlockDiffElement;
  showCaptionText: boolean;
  file?: File;
  onSave?: (file: File) => void;
  onClose?: () => void;
  isAnnotating?: boolean;
  diffChangeState?: DiffArrayChangeSymbol;
}

const ReviewInlineImageStandalone = ({
  attachment,
  file,
  showCaptionText,
  onSave,
  onClose,
  isAnnotating = false,
  diffChangeState = ARRAY_CHANGE_SYMBOLS.UNCHANGED,
}: ReviewInlineImageStandaloneProps) => {
  const { url } = useAttachment({
    // @ts-ignore - https://linear.app/epsilon3/issue/EPS-3423/update-attachment-type
    attachment,
    file,
    isDiff: true,
  });

  return (
    <ReviewInlineImage
      attachment={attachment}
      showCaptionText={showCaptionText}
      url={url}
      onSave={onSave}
      onClose={onClose}
      isAnnotating={isAnnotating}
      diffChangeState={diffChangeState}
    />
  );
};

export default ReviewInlineImageStandalone;

import { useQuery } from '@tanstack/react-query';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { Requirement } from 'shared/lib/types/testing';
import { useSettings } from '../contexts/SettingsContext';

interface UseInternalRequirementsResult {
  requirements: Array<Requirement>;
  isLoading: boolean;
  error: Error | null;
}

const useInternalRequirements = (): UseInternalRequirementsResult => {
  const { services } = useDatabaseServices();
  const { isTestConditionsMatrixEnabled } = useSettings();

  const fetchInternalRequirements = async (): Promise<Array<Requirement>> => {
    return services.testing.getRequirements(false);
  };

  const { data, error, isLoading } = useQuery({
    queryKey: ['internalRequirement'],
    queryFn: fetchInternalRequirements,
    enabled: isTestConditionsMatrixEnabled(),
  });

  return {
    requirements: data ?? [],
    isLoading,
    error,
  };
};

export default useInternalRequirements;

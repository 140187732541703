import React, { useEffect, useMemo } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';

import { SettingsNavigationSection } from './types';
import useLocationParams from '../../hooks/useLocationParams';

interface SettingsNavigationProps {
  sections: SettingsNavigationSection[];
  onSectionKeyChange?: () => void;
  title?: string;
  children({ sectionKey }: { sectionKey: string }): React.ReactNode;
}

/**
 * Component that provides a navigation structure for various settings pages.
 * @param section - Array of objects that represent each clickable section in the navigation panel.
 * @param onSectionKeyChange - Function to perform actions when user selects a different section.
 * @param title - Title of this navigation component
 * @param children - Subsections to render in this navigation component
 *
 * @returns React.Component
 */
const SettingsNavigation = ({
  sections = [],
  onSectionKeyChange,
  title = 'Settings',
  children,
}: SettingsNavigationProps) => {
  if (!sections.length) {
    throw new Error('Section objects are required.');
  }
  const location = useLocation();
  const { hash } = useLocationParams(location);

  const sectionKey = useMemo(() => {
    const sectionKeys = sections.map((section) => section.key);

    if (sectionKeys.includes(hash)) {
      return hash;
    }

    return sectionKeys[0];
  }, [sections, hash]);

  useEffect(() => onSectionKeyChange && onSectionKeyChange(), [onSectionKeyChange, sectionKey]);

  return (
    <div className="flex flex-col md:flex-row flex-grow">
      {/* Navigation panel */}
      <div className="bg-slate-200 flex flex-col w-full md:min-w-[10rem] md:max-w-[10rem]">
        <div className="p-4 text-xs uppercase font-medium">{title}</div>
        <div className="flex flex-col text-sm">
          {sections.map((section) => (
            <Link
              key={section.key}
              to={`#${section.key}`}
              className={`flex items-center h-8 px-4 focus:bg-gray-50 focus:outline-none hover:bg-gray-50 ${
                sectionKey === section.key && 'bg-gray-50'
              }`}
            >
              <FontAwesomeIcon
                icon={section.icon as IconProp}
                className="flex flex-none text-gray-400"
                fixedWidth={true}
              />
              <div className="flex flex-grow pl-2 text-left text-gray-700">{section.name}</div>
            </Link>
          ))}
        </div>
      </div>
      <div className="overflow-auto flex flex-col flex-grow">{children({ sectionKey })}</div>
    </div>
  );
};

export default React.memo(SettingsNavigation);

import { Field, useFormikContext } from 'formik';
import Tooltip from '../elements/Tooltip';
import { ID_PLACEHOLDER_SUFFIX } from '../hooks/useAutoProcedureId';

interface FieldSetProcedureCodeProps {
  isAutoGenerated?: boolean;
}

const FieldSetProcedureCode = ({ isAutoGenerated }: FieldSetProcedureCodeProps) => {
  const { values } = useFormikContext<{ code?: string }>();

  return (
    <>
      {!isAutoGenerated && (
        <Field name="code">
          {({ field }) => (
            <input
              {...field}
              type="text"
              placeholder="Procedure Code"
              className="w-full text-sm border-1 border-gray-400 rounded"
            />
          )}
        </Field>
      )}
      {isAutoGenerated && (
        <Tooltip content="Auto generate procedure ID is enabled">
          <div className="w-full text-sm p-2 border border-gray-400 rounded bg-gray-300 bg-opacity-50 truncate">
            {values?.code ?? ID_PLACEHOLDER_SUFFIX}
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default FieldSetProcedureCode;

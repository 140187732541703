export type AutoNumberingDelimiterProps = {
  delimiter: string;
  isVisible: boolean;
};

const AutoNumberingDelimiter = ({ delimiter, isVisible }: AutoNumberingDelimiterProps) => {
  return (
    <div className="border-r border-gray-300 w-[1.45rem] px-[0.45rem] pt-[0.6rem] bg-inherit">
      {isVisible ? delimiter : ''}
    </div>
  );
};

export default AutoNumberingDelimiter;

import { RunsTableRowModel } from '../types';
import Tag from '../../Tag';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import RunLabel from '../../RunLabel';

const renderRunLinkWithIcon = ({ row }: { row: RunsTableRowModel }) => {
  const isChild = row.isChild;
  const isLastChild = row.isLastChild;

  const link = row.runLink.link;
  const code = row.runLink.code;
  const name = row.runLink.name;
  const runNumber = row.runLink.run_number;
  const icon = row.runLinkWithIcon.icon;
  const iconLink = row.runLinkWithIcon.iconLink;
  const iconTitle = row.runLinkWithIcon.iconTitle;
  const tags = (row.runLinkWithIcon.tags ?? []).concat(row.runLinkWithIcon.runTags ?? []);

  return (
    <div className="flex flex-row h-full w-full items-center">
      {isLastChild && <div className="flex -mt-8 h-3/4 w-3 border-blue-400 ml-3 border-l-2 border-b-2 mr-3" />}
      {isChild && <div className="flex h-full w-3 border-blue-400 ml-3 border-l-2 mr-3" />}
      <div className="w-full leading-4">
        <div className="">
          <RunLabel
            code={code}
            name={name}
            runNumber={runNumber}
            link={link}
            icon={icon as IconProp}
            iconLink={iconLink}
            iconTitle={iconTitle}
          />
        </div>
        <div className="flex">
          {tags && tags.map((tag) => <Tag clickable={true} key={tag.key} id={tag.key} name={tag.name}></Tag>)}
        </div>
      </div>
    </div>
  );
};

export default renderRunLinkWithIcon;

import PlotLegend from './PlotLegend';

type PlotModalBottomBarProps = {
  dictionaryName: string;
  measurementName: string;
};

const PlotModalBottomBar = ({ dictionaryName, measurementName }: PlotModalBottomBarProps) => {
  return (
    <div className="px-3 py-2 rounded-b-lg">
      <PlotLegend dictionaryName={dictionaryName} measurementName={measurementName} />
    </div>
  );
};

export default PlotModalBottomBar;

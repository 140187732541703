import React, { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import stepConditionals from 'shared/lib/stepConditionals';
import { useRunContext } from '../../contexts/RunContext';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import TooltipOverlay from '../TooltipOverlay';
import ReviewStepConditionalDetails from './ReviewStepConditionalDetails';
import {
  FieldInputConditionalBlock,
  Step,
  StepConditionalDiffElement,
  StepDiffElement,
} from 'shared/lib/types/views/procedures';
import useProcedureAdapter from '../../hooks/useProcedureAdapter';
import { useReviewContext } from '../../contexts/ReviewContext';
import DiffContainer from '../Diff/DiffContainer';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';

interface StepConditionalProps {
  step: Step | StepDiffElement;
  conditionals: StepConditionalDiffElement[];
}

const ReviewStepConditionals = ({ step, conditionals }: StepConditionalProps) => {
  const { stepIdsToLabelsMap: runLabelMap, getSectionAndStepIdPair } = useRunContext();
  const { scrollTo } = useProcedureContext();
  const { isStepRemoved } = useReviewContext();

  const { stepIdsToLabelsMap } = useProcedureAdapter();

  const onStepLinkClicked = useCallback(
    (stepId: string | null) => {
      const { sectionId } = getSectionAndStepIdPair(stepId);

      if (typeof scrollTo === 'function' && stepId) {
        scrollTo({
          sectionId,
          stepId,
        });
      }
    },
    [getSectionAndStepIdPair, scrollTo]
  );

  const nextStepId = useMemo(() => {
    // Disables for viewing in edit procedure
    if (typeof runLabelMap !== 'object') {
      return null;
    }
    for (const conditional of conditionals) {
      // TODO EPS-4221 remove "as Step" cast when full procedure diffs are ready to be enabled.
      if (stepConditionals.isConditionalFulfilled(step as Step, conditional)) {
        return sharedDiffUtil.getDiffValue<string>(conditional, 'target_id', 'new');
      }
    }
    return null;
  }, [runLabelMap, step, conditionals]);

  const stepRemoved = useMemo(() => isStepRemoved && isStepRemoved(step), [isStepRemoved, step]);

  const getDiffContainerDiffChangeState = () => {
    if (
      (step as StepDiffElement).diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED ||
      (step as StepDiffElement).diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED
    ) {
      return ARRAY_CHANGE_SYMBOLS.UNCHANGED;
    }
    if (
      conditionals.length > 0 &&
      conditionals.every((conditional) => conditional.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED)
    ) {
      return ARRAY_CHANGE_SYMBOLS.ADDED;
    }
    if (
      conditionals.length > 0 &&
      conditionals.every((conditional) => conditional.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED)
    ) {
      return ARRAY_CHANGE_SYMBOLS.REMOVED;
    }
    if (
      conditionals.length > 0 &&
      conditionals.every(
        (conditional) =>
          !conditional.diff_change_state || conditional.diff_change_state === ARRAY_CHANGE_SYMBOLS.UNCHANGED
      )
    ) {
      return ARRAY_CHANGE_SYMBOLS.UNCHANGED;
    }
    return ARRAY_CHANGE_SYMBOLS.MODIFIED;
  };

  return (
    <DiffContainer label="Conditional" diffChangeState={getDiffContainerDiffChangeState()} isTextSticky={false}>
      <div className="flex flex-row gap-x-1 py-1">
        <FontAwesomeIcon className="text-gray-500 self-center" icon="sitemap" />
        <div className="ml-1">
          <div className="flex flex-row">
            Next:&nbsp;
            <TooltipOverlay
              delayClose={true}
              content={
                <ReviewStepConditionalDetails
                  content={step.content as FieldInputConditionalBlock[]}
                  conditionals={conditionals}
                  onStepLinkClicked={onStepLinkClicked}
                  stepIdsToLabelsMap={stepIdsToLabelsMap}
                  areConditionalsValid={!stepRemoved}
                  diffChangeState={getDiffContainerDiffChangeState()}
                  stepId={(step as Step).id}
                />
              }
            >
              <button
                className="link disabled:text-gray-400"
                onClick={() => onStepLinkClicked(nextStepId)}
                disabled={typeof scrollTo !== 'function' || !nextStepId} // Disables for viewing in edit procedure
              >
                {nextStepId ? stepIdsToLabelsMap[nextStepId] : 'Pending'}
              </button>
            </TooltipOverlay>
          </div>
        </div>
      </div>
    </DiffContainer>
  );
};

export default React.memo(ReviewStepConditionals);

import React from 'react';

import EditSignoffCell from './EditSignoffCell';
import RunSignoffCell from './RunSignoffCell';
import tableUtil from 'shared/lib/tableUtil';

const SignoffCell = ({ signoffs, onUpdateSignoffs, isActive }) => {
  const signoffsCleaned = tableUtil.isSignoffCell(signoffs) ? signoffs : [];
  return (
    <div className="w-full">
      {isActive && <EditSignoffCell cell={signoffsCleaned} onUpdateCell={onUpdateSignoffs} />}
      {!isActive && <RunSignoffCell signoffs={signoffsCleaned} isActive={false} />}
    </div>
  );
};

export default React.memo(SignoffCell);

import React, { useCallback, useMemo, useState } from 'react';
import { Field, FormikHelpers, FormikValues } from 'formik';
import TextAreaAutoHeight from '../components/TextAreaAutoHeight';
import InlineImageStandalone from './InlineImageStandalone';
import ThumbnailFile from './ThumbnailFile';
import useContentType from '../hooks/useContentType';
import { AttachmentImageDisplayStyle, AttachmentImageSize, BlockValues } from './Blocks/BlockTypes';
import { useMixpanel } from '../contexts/MixpanelContext';
import Button from './Button';

export type AttachmentSize = 'small' | 'best_fit' | 'original';
export type AttachmentDisplayStyle = 'inline' | 'to_the_side';

interface FieldSetAttachmentProps {
  attachment: BlockValues<'attachment'>;
  path: string;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
  isToTheSideImageEnabled: boolean;
  isPending: boolean;
  source: string;
  url?: string;
}

const FieldSetAttachment = React.memo(
  ({ attachment, path, setFieldValue, isToTheSideImageEnabled, isPending, source, url }: FieldSetAttachmentProps) => {
    const { shouldRenderImage } = useContentType({
      attachment,
      file: attachment.file,
    });
    const { mixpanel } = useMixpanel();
    const [isAnnotating, setIsAnnotating] = useState(false);
    const isUploaded = useMemo(() => attachment.file === undefined, [attachment.file]);

    const setImageSize = useCallback(
      (size: AttachmentSize) => {
        setFieldValue(`${path}.size`, size);
      },
      [setFieldValue, path]
    );

    const onChangeImageDisplayStyle = useCallback(
      (event) => {
        const value = event.target.value;
        setFieldValue(`${path}.display_style`, value);
      },
      [setFieldValue, path]
    );

    const attachmentSize = useMemo<AttachmentSize>(() => {
      // For backwards compatibility, take no size to mean original size.
      if (!attachment.size) {
        return AttachmentImageSize.Original as AttachmentSize;
      }
      return attachment.size as AttachmentSize;
    }, [attachment]);

    const shouldDisplayToTheSide = useMemo(() => {
      return (attachment.display_style as AttachmentDisplayStyle) === AttachmentImageDisplayStyle.ToTheSide;
    }, [attachment]);

    const onSave = (file: File) => {
      if (mixpanel) {
        mixpanel.track('Save Annotation', { Source: source });
      }
      setFieldValue(`${path}.file`, file);
      setIsAnnotating(false);
    };

    const startAnnotating = () => {
      if (mixpanel) {
        mixpanel.track('Start Annotatation', { Source: source });
      }
      setIsAnnotating(true);
    };

    return (
      <div className="w-full">
        <div className="flex flex-row mr-2 items-start">
          <span className="field-title">File</span>
        </div>
        {/* Full size image for now */}
        {shouldRenderImage && (
          <>
            <div className="flex flex-col items-start mt-2">
              <div className="flex flex-row items-start">
                <InlineImageStandalone
                  attachment={attachment}
                  file={attachment.file}
                  showCaptionText={false}
                  onSave={onSave}
                  onClose={() => setIsAnnotating(false)}
                  isAnnotating={isAnnotating}
                />
                {!isPending && (
                  <Button
                    isDisabled={!isUploaded}
                    onClick={startAnnotating}
                    size="sm"
                    leadingIcon="pencil"
                    title={!isUploaded ? 'Cannot annotate until changes have saved' : 'Annotate image'}
                    type="tertiary"
                  />
                )}
              </div>
              {!shouldDisplayToTheSide && (
                <div>
                  {attachmentSize === AttachmentImageSize.Small ? (
                    <span className="text-xs text-gray-600">Small</span>
                  ) : (
                    <button
                      type="button"
                      className="text-xs link"
                      onClick={() => setImageSize(AttachmentImageSize.Small)}
                    >
                      Small
                    </button>
                  )}
                  &nbsp;|&nbsp;
                  {attachmentSize === AttachmentImageSize.BestFit ? (
                    <span className="text-xs text-gray-600">Best Fit</span>
                  ) : (
                    <button
                      type="button"
                      className="text-xs link"
                      onClick={() => setImageSize(AttachmentImageSize.BestFit)}
                    >
                      Best Fit
                    </button>
                  )}
                  &nbsp;|&nbsp;
                  {attachmentSize === AttachmentImageSize.Original ? (
                    <span className="text-xs text-gray-600">Original</span>
                  ) : (
                    <button
                      type="button"
                      className="text-xs link"
                      onClick={() => setImageSize(AttachmentImageSize.Original)}
                    >
                      Original
                    </button>
                  )}
                </div>
              )}
            </div>
            <div className="flex flex-row flex-wrap">
              {isToTheSideImageEnabled && (
                <div className="flex flex-col mr-2 items-start mt-2">
                  <span className="field-title">Display Style</span>
                  <Field
                    name={`${path}.display_style`}
                    as="select"
                    className="text-sm border border-gray-400 rounded"
                    onChange={onChangeImageDisplayStyle}
                  >
                    <option value="inline">Inline</option>
                    <option value="to_the_side">To the side</option>
                  </Field>
                </div>
              )}
              <div className="flex flex-col mr-2 items-start mt-2">
                <span className="field-title">Image Caption</span>
                <Field name={`${path}.caption`}>
                  {({ field }) => (
                    <div className="min-w-0 w-full">
                      <div className="flex flex-col min-w-0 w-full relative">
                        <TextAreaAutoHeight placeholder="Enter caption" {...field} />
                      </div>
                    </div>
                  )}
                </Field>
              </div>
            </div>
          </>
        )}
        {!shouldRenderImage && <ThumbnailFile attachment={attachment} file={attachment.file} />}
      </div>
    );
  }
);

export default FieldSetAttachment;

import React from 'react';
import Button from '../Button';

export interface NotificationLinkProps {
  notificationId: number;
  ariaLabel: string;
  to: string;
  onClick: () => void;
  duplicateIds: Array<number>;
  children: React.ReactNode;
}

const NotificationLink = ({
  notificationId,
  ariaLabel,
  to,
  onClick,
  duplicateIds,
  children,
}: NotificationLinkProps) => {
  return (
    <Button key={notificationId} ariaLabel={ariaLabel} to={to} onClick={onClick} type="tertiary" width="full">
      <div className="flex items-center">
        <div className="mr-1">{children}</div>
        {duplicateIds && duplicateIds.length > 1 && <div className="text-gray-400">({duplicateIds.length})</div>}
      </div>
    </Button>
  );
};

export default NotificationLink;

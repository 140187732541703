import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  ariaLabel: string;
  checked: boolean;
  disabled: boolean;
  readyToRelease?: boolean;
  label?: string;
  size?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

/*
 * (Feb 2022|TODO) Ensure this gets pulled out into a separate generic checkbox
 * element and a checkbox element for the "ready to release" procedure page
 */

/**
 * Checkbox with optional text label.
 *
 * @param {String} ariaLabel - label for checkbox button.
 * @param {Boolean} checked - true if the checkbox is checked.
 * @param {Boolean} disabled - true if the checkbox is disabled.
 * @param {String} label (optional) - text rendered next to checkbox.
 * @param {String} size (optional) - text rendered next to checkbox.
 * @param {Boolean} readyToRelease - is review ready to release, default to false
 * @param {Function} onClick - function called when checkbox or label is clicked.
 */
const Checkbox = ({ ariaLabel, checked, disabled, label, size, onClick }: Props) => {
  const CHECKBOX_BASE_CLASS = `fa-stack-1x ${size ? size : 'text-2xl'}`;
  const CHECKBOX_FOREGROUND_CLASS = `text-gray-500 ${CHECKBOX_BASE_CLASS}`;

  return (
    <div className="w-full">
      <button
        // negative margins needed to counteract the margin added by fa-stack
        className="-ml-2 flex items-center cursor-pointer disabled:cursor-default"
        onClick={onClick}
        disabled={disabled}
        aria-label={ariaLabel}
      >
        <div className="fa-stack">
          {checked && (
            <>
              <FontAwesomeIcon icon="square" className={`text-white ${CHECKBOX_BASE_CLASS}`} />
              <FontAwesomeIcon icon="check-square" className={CHECKBOX_FOREGROUND_CLASS} />
            </>
          )}
          {!checked && disabled && (
            <>
              <FontAwesomeIcon icon="square" className={`text-gray-300 ${CHECKBOX_BASE_CLASS}`} />
              <FontAwesomeIcon icon={['far', 'square']} className={CHECKBOX_FOREGROUND_CLASS} />
            </>
          )}
          {!checked && !disabled && (
            <>
              <FontAwesomeIcon icon="square" className={`text-white ${CHECKBOX_BASE_CLASS}`} />
              <FontAwesomeIcon icon={['far', 'square']} className={CHECKBOX_FOREGROUND_CLASS} />
            </>
          )}
        </div>
        {label && <span title={label}>{label}</span>}
      </button>
    </div>
  );
};

export default Checkbox;

import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToolbarButtonWithHoverLabel from '../../elements/ToolbarButtonWithHoverLabel';

interface TableHeaderMenuContextProps {
  label: 'row' | 'column';
  index: number;
  onAddHeader?: (index: number) => void;
  onRemoveHeader?: (index: number) => void;
  onDuplicateHeader?: (index: number) => void;
  isAddHeaderDisabled: boolean;
  isRemoveHeaderDisabled: boolean;
  isNarrow?: boolean;
}
const TableHeaderMenuContext = React.memo(
  ({
    label,
    index,
    onAddHeader,
    onRemoveHeader,
    onDuplicateHeader,
    isRemoveHeaderDisabled = false,
    isAddHeaderDisabled = false,
    isNarrow = false,
  }: TableHeaderMenuContextProps) => {
    const [isMenuContextVisible, setIsMenuContextVisible] = useState(false);

    const showMenuContext = useCallback(() => {
      setIsMenuContextVisible(true);
    }, []);

    const hideMenuContext = useCallback(() => {
      setIsMenuContextVisible(false);
    }, []);

    useEffect(() => {
      if (isMenuContextVisible) {
        document.addEventListener('click', hideMenuContext);
      }
      // Remove listener when menu visibility changes or component unmounts.
      return () => {
        document.removeEventListener('click', hideMenuContext);
      };
    }, [isMenuContextVisible, hideMenuContext]);

    const addHeader = useCallback(() => {
      if (onAddHeader) {
        // Add row/column one below/right-of selected row/column.
        onAddHeader(index + 1);
      }
    }, [onAddHeader, index]);

    const removeHeader = useCallback(() => {
      if (onRemoveHeader) {
        onRemoveHeader(index);
      }
    }, [onRemoveHeader, index]);

    const duplicateHeader = useCallback(() => {
      if (onDuplicateHeader) {
        onDuplicateHeader(index);
      }
    }, [onDuplicateHeader, index]);

    return (
      <div className={`self-center relative h-8 ${isNarrow ? 'w-4' : 'w-8'}`}>
        <button
          type="button"
          className={`text-gray-400 h-full w-full justify-center hover:text-gray-600 ${
            isMenuContextVisible ? 'text-gray-600' : 'text-gray-400'
          }`}
          aria-label={`${label} context menu`}
          onClick={showMenuContext}
          tabIndex={-1}
        >
          <FontAwesomeIcon className="text-xl" icon="ellipsis-v" />
        </button>

        {isMenuContextVisible && (
          <div className="absolute -left-12 p-2 w-fit flex flex-col bg-white border border-gray-300 shadow-lg text-sm font-normal rounded-md z-50">
            <ul>
              <li className="flex flex-row">
                {onAddHeader && (
                  <ToolbarButtonWithHoverLabel
                    icon="plus"
                    ariaLabel={`add ${label}`}
                    label="Add"
                    disabled={isAddHeaderDisabled}
                    onClick={addHeader}
                  />
                )}
                {onDuplicateHeader && (
                  <ToolbarButtonWithHoverLabel
                    icon={['far', 'copy']}
                    ariaLabel={`duplicate ${label}`}
                    label="Duplicate"
                    disabled={isAddHeaderDisabled}
                    onClick={duplicateHeader}
                  />
                )}
                {onRemoveHeader && (
                  <ToolbarButtonWithHoverLabel
                    icon="x"
                    ariaLabel={`remove ${label}`}
                    label="Remove"
                    disabled={isRemoveHeaderDisabled}
                    onClick={removeHeader}
                  />
                )}
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }
);

export default TableHeaderMenuContext;

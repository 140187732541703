import { useMemo } from 'react';
import { ViewTab } from 'shared/lib/types/postgres/users';
import { useSettings } from '../../contexts/SettingsContext';
import SearchInputControlled from '../../elements/SearchInputControlled';
import projectUtil from '../../lib/projectUtil';
import Button, { BUTTON_TYPES } from '../Button';
import DefaultTitle from '../DefaultTitle';
import FlashMessage from '../FlashMessage';
import LoadingBadge from '../LoadingBadge';
import TabBar, { TabProps } from '../TabBar/TabBar';
import SearchFilter, { FilterOption } from '../lib/SearchFilter';
import GridExpandCollapseButton, { RowWithProjectName } from './GridExpandCollapseButton';
import ViewTabToggle from './ViewTabToggle';
import { usePersistedViewReturns } from './usePersistedView';
import { getOperationsAsOptions } from './runGridUtils';
import { RUN_HISTORY_KEY } from '../../screens/RunList';

export enum Filter {
  Projects,
  Tags,
  RunTags,
  Operations,
}

interface ListHeaderProps {
  name: string;
  tabs: ReadonlyArray<TabProps<string>>;
  rows: ReadonlyArray<RowWithProjectName>;
  isLoading: boolean;
  persistedView: usePersistedViewReturns;
  filters: ReadonlySet<Filter>;
  /** because procedures and runs use different tags... */
  tagOptions: Array<FilterOption<string>>;
  actions?: JSX.Element;

  // maybe bring these in
  navigatedSection: string;
  updateTab: (tab: string) => void;
}

const ListHeader = ({
  name,
  tabs,
  rows,
  isLoading,
  persistedView,
  filters,
  tagOptions,
  navigatedSection,
  updateTab,
  actions,
}: ListHeaderProps) => {
  const { projects, operations } = useSettings();

  const projectOptions = useMemo(() => projectUtil.getProjectsAsOptions(projects), [projects]);
  const operationOptions = useMemo(
    () => getOperationsAsOptions(Object.values(operations?.operations ?? []), navigatedSection === RUN_HISTORY_KEY),
    [navigatedSection, operations?.operations]
  );

  return (
    <>
      <DefaultTitle />

      <FlashMessage message={persistedView.saveError} messageUpdater={persistedView.setSaveError} type="warning" />
      <FlashMessage message={persistedView.saveSuccess} messageUpdater={persistedView.setSaveSuccess} />

      {isLoading && (
        <div className="absolute z-200 inset-y-1/2 inset-x-1/2 flex items-center justify-center h-16">
          <LoadingBadge />
        </div>
      )}

      <div className="pt-4 flex flex-col w-full top-0">
        <div className="flex flex-row justify-between items-start dark:text-white">
          <h1 className="pl-2 pt-2 mb-0 pb-0 whitespace-nowrap">{name}</h1>
          {actions}
        </div>
        <div className="flex flex-row pb-0 pt-2 bg-gray-50">
          <div className="flex w-full">
            <div className="flex flex-row pb-2">
              <ViewTabToggle viewTab={persistedView.viewTab} setViewTab={persistedView.setViewTab} />
              <SearchInputControlled
                placeholder={`Search ${name}`}
                searchTerm={persistedView.searchTerm}
                setSearchTerm={persistedView.setSearchTerm}
              />

              {filters.has(Filter.Projects) && (
                <SearchFilter
                  filterTitle="Projects"
                  filterOptions={projectOptions}
                  selectedFilterIds={persistedView.selectedProjectIds}
                  onFilterIdsChange={(ids) => persistedView.setSelectedProjectIds(ids)}
                  ariaLabel="Projects Filter"
                />
              )}
              {filters.has(Filter.Tags) && (
                <>
                  <div className="border-l border-gray-300 h-6 mt-2"></div>
                  <SearchFilter
                    filterTitle="Tags"
                    filterOptions={tagOptions}
                    selectedFilterIds={persistedView.selectedTagKeys}
                    onFilterIdsChange={(ids) => persistedView.setSelectedTagKeys(ids)}
                    ariaLabel="Tags Filter"
                  />
                </>
              )}
              {filters.has(Filter.RunTags) && (
                <>
                  <div className="border-l border-gray-300 h-6 mt-2"></div>
                  <SearchFilter
                    filterTitle="Tags"
                    filterOptions={tagOptions}
                    selectedFilterIds={persistedView.selectedRunTagKeys}
                    onFilterIdsChange={(ids) => persistedView.setSelectedRunTagKeys(ids)}
                    ariaLabel="Run Tags Filter"
                  />
                </>
              )}
              {filters.has(Filter.Operations) && (
                <>
                  <div className="border-l border-gray-300 h-6 mt-2"></div>
                  <SearchFilter
                    filterTitle="Operations"
                    filterOptions={operationOptions}
                    selectedFilterIds={persistedView.selectedOperationKeys}
                    onFilterIdsChange={(ids) => persistedView.setSelectedOperationKeys(ids)}
                    disableSelectAll={true}
                    ariaLabel="Operations Filter"
                  />
                </>
              )}
            </div>
            <div className="flex grow"></div>
            <Button
              type={BUTTON_TYPES.TERTIARY}
              ariaLabel="Save View as Default"
              leadingIcon="save"
              onClick={persistedView.saveDefaultView}
              isDisabled={!persistedView.isSaveDefaultViewEnabled}
            >
              Save Default View
            </Button>
          </div>
        </div>
        <div className="flex flex-row justify-between my-2 mr-2">
          <TabBar tabs={tabs} selectedTab={navigatedSection} setSelectedTab={updateTab} />

          {persistedView.viewTab === ViewTab.Tree && (
            <GridExpandCollapseButton
              rows={rows as Array<RowWithProjectName>}
              expandedProjectNames={persistedView.expandedProjectNames}
              setExpandedProjectNames={persistedView.setExpandedProjectNames}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ListHeader;

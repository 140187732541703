/*
 * Default colors used by chartjs, We need access to them to make the vertical axis
 * labels and tick colors consistent with the color of each channel in the plot.
 */
const CHART_COLORS = [
  'rgb(54, 162, 235)', // blue
  'rgb(255, 99, 132)', // red
  'rgb(255, 159, 64)', // orange
  'rgb(255, 205, 86)', // yellow
  'rgb(75, 192, 192)', // green
  'rgb(153, 102, 255)', // purple
  'rgb(201, 203, 207)', // grey
];

const chartColorsLib = {
  getSelectionColor: (selectionIndex: number): string =>
    CHART_COLORS[selectionIndex % CHART_COLORS.length],
};

export default chartColorsLib;

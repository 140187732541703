/**
 * Flips 2D Array from:
 *  [
 *    [1, 1, 1],
 *    [2, 2, 2],
 *    [3, 3, 3]
 *    [4, 4, 4]
 *  ]
 * to:
 *  [
 *    [1, 2, 3, 4],
 *    [1, 2, 3, 4],
 *    [1, 2, 3, 4]
 *  ]
 *
 * Uses the length of the first item in array to set the length of the new array.
 *
 * @param {Array} array - A 2 dimensional array.
 * @returns a transposed array (columns are flipped to rows)
 */
const transpose2DArray = (array) => {
  if (!Array.isArray(array)) {
    throw new Error('Please pass in an array.');
  } else if (!Array.isArray(array[0])) {
    throw new Error('Please pass in a 2D array.');
  }

  return array[0].map((column, columnIndex) => array.map((row) => row[columnIndex]));
};

export const removeItemFromArray = (array, index) => {
  const updatedArray = [...array];
  updatedArray.splice(index, 1);
  return updatedArray;
};

/**
 * Inserts (in place) a column into a 2D array at a specified index.
 */
export const addColumnTo2DArray = (array, index, column) => {
  if (!Array.isArray(array)) {
    throw new Error('Please pass in an array.');
  } else if (!Array.isArray(array[0])) {
    throw new Error('Please pass in a 2D array.');
  } else if (array.length !== column.length) {
    throw new Error('Column length must match array height.');
  } else if (index < 0 || index > array[0].length) {
    throw new Error('Invalid index');
  }

  array.map((row, rowIndex) => {
    row.splice(index, 0, column[rowIndex]);
    return row;
  });
};

/**
 * Removes (in place) a column from a 2D array at a specified index.
 */
export const removeColumnFrom2DArray = (array, index) => {
  if (!Array.isArray(array)) {
    throw new Error('Please pass in an array.');
  } else if (!Array.isArray(array[0])) {
    throw new Error('Please pass in a 2D array.');
  } else if (index < 0 || index >= array[0].length) {
    throw new Error('Invalid index');
  }

  return array.map((row) => {
    row.splice(index, 1);
    return row;
  });
};

/**
 * @param array - a 1D array
 * @param value - the value whose uniqueness is to be determined
 * @param valueExtractor - a function to get the value from an array element
 * @returns {boolean} whether the value is unique in the array
 */
export const isValueUnique = (array, value, valueExtractor = (a) => a) => {
  const countMap = {};
  array.forEach((element) => {
    const currentValue = valueExtractor(element);
    if (!countMap[currentValue]) {
      countMap[currentValue] = 0;
    }
    countMap[currentValue]++;
  });
  return countMap[value] === 1;
};

export default transpose2DArray;

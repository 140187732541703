import React, { useCallback } from 'react';
import 'linkify-plugin-mention';
import Linkify from 'linkify-react';
import Tooltip from '../elements/Tooltip';

/**
 * This component will wrap any components in Linkify, which will auto detect
 * links and wrap the links in an a tag with a href.
 *
 * Links will open in a new tab and have a blue underlined style.
 */
const TextLinkify = ({ children, mentionsEnabled = false, mentionList = [] }) => {
  const renderMention = useCallback(
    ({ attributes, content }) => {
      let displayTooltip = false;
      let userEmail;
      if (mentionList.length > 0) {
        const mention = mentionList.find((m) => m.username === content.substring(1));
        if (mention) {
          displayTooltip = true;
          userEmail = mention.email;
        }
      }
      return displayTooltip ? (
        <Tooltip content={userEmail}>
          <span className="text-blue-600 bg-blue-200 font-medium" {...attributes}>
            {content}
          </span>
        </Tooltip>
      ) : (
        <span className="font-medium" {...attributes}>
          {content}
        </span>
      );
    },
    [mentionList]
  );

  const renderLink = useCallback(({ attributes, content }) => {
    return (
      <a {...attributes} className="text-blue-600 underline" target="_blank">
        {content}
      </a>
    );
  }, []);

  const options = {
    render: {
      url: renderLink,
    },
  };

  if (mentionsEnabled) {
    options.render.mention = renderMention;
  }

  return <Linkify options={options}>{children}</Linkify>;
};

export default TextLinkify;

import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export default function UpdateOnDirty({ onDirtyChanged }) {
  const formik = useFormikContext();

  useEffect(() => {
    if (typeof onDirtyChanged !== 'function') {
      return;
    }
    onDirtyChanged(formik.dirty);
  }, [formik.dirty, onDirtyChanged]);

  return null;
}

import { Field } from 'formik';
import Select from 'react-select';
import { StringSelectOption as SelectOption } from '../../../lib/formik';
import { reactSelectStyles } from '../../../lib/styles';

export type AutoNumberingPrefixProps = {
  name?: string;
  prefixes?: Array<string>;
  value?: string;
  onChange?: (option: SelectOption | null) => void;
  useFormik: boolean;
  isDisabled?: boolean;
};

const styles = {
  control: (base, { isDisabled }) => ({
    ...base,
    ...reactSelectStyles.control(base, { isDisabled }),
    border: 'none',
    boxShadow: 'none',
  }),
};

const AutoNumberingPrefix = ({
  name,
  prefixes,
  value,
  onChange,
  useFormik,
  isDisabled = false,
}: AutoNumberingPrefixProps) => {
  const options = (prefixes ?? []).map((prefix) => ({
    label: prefix,
    value: prefix,
  }));

  const select = (
    <div className="text-sm w-[4.8rem]">
      <Select
        styles={styles}
        classNamePrefix="react-select"
        className="bg-inherit"
        options={options}
        isClearable={true}
        value={options.find((option) => option.value === value) ?? null}
        onChange={onChange}
        placeholder=""
        components={{
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
        }}
        aria-label="Prefix"
        isDisabled={isDisabled}
      />
    </div>
  );

  return (
    <div className="pt-[0.05rem] border-r border-gray-300">
      {useFormik && (
        <Field name={name} aria-label="Prefix">
          {() => select}
        </Field>
      )}
      {!useFormik && select}
    </div>
  );
};

export default AutoNumberingPrefix;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStorageTrack from '../hooks/useStorageTrack';

interface ShowRunPlotIconProps {
  onClick: () => void;
}

const ShowRunPlotIcon = ({ onClick }: ShowRunPlotIconProps) => {
  const storageTrack = useStorageTrack();

  const handleClick = () => {
    storageTrack('Opened run plot');
    onClick();
  };

  return (
    <FontAwesomeIcon
      className="text-blue-500 hover:text-blue-600 hover:cursor-pointer"
      icon="chart-line"
      aria-label="Show Run Plot"
      onClick={handleClick}
    />
  );
};

export default ShowRunPlotIcon;

import React, { useMemo } from 'react';
import { ProcedureState } from 'shared/lib/types/views/procedures';
import procedureUtil from '../lib/procedureUtil';
import stickyHeaderUtil from '../lib/stickyHeaderUtil';
import ToggleComments from './Comments/ToggleComments';
import DraftStatusLabel from './DraftStatusLabel';
import ExpandCollapseButtons from './ExpandCollapse/ExpandCollapseButtons';
import ReleaseStatusLabel from './ReleaseStatusLabel';
import ReviewStatusLabel from './ReviewStatusLabel';
import BackToLink from './Toolbar/BackToLink';
import Divider from './Toolbar/Divider';
import LeftSide from './Toolbar/LeftSide';
import RightSide from './Toolbar/RightSide';
import Spacer from './Toolbar/Spacer';
import Title from './Toolbar/Title';
import Toolbar from './Toolbar/Toolbar';
import TrackingWithQRCode from '../manufacturing/components/TrackingWithQRCode';

interface ProcedureStickyHeaderProps {
  code: string;
  name: string;
  hasComments: boolean;
  showComments: boolean;
  setShowComments?: React.Dispatch<React.SetStateAction<boolean>>;
  state?: ProcedureState;
  isApproved: boolean;
  onExpandAll?: () => void;
  onCollapseAll?: () => void;
  source?: {
    title: string;
    from: string;
  };
  qrCodeUrl: string;
}
const ProcedureStickyHeader = ({
  code,
  name,
  hasComments,
  showComments,
  setShowComments,
  state,
  isApproved,
  onExpandAll,
  onCollapseAll,
  source,
  qrCodeUrl,
}: ProcedureStickyHeaderProps) => {
  const title = useMemo(() => procedureUtil.getProcedureTitle(code, name), [code, name]);

  return (
    <Toolbar>
      <LeftSide>
        {source && (
          <>
            <BackToLink name={source.title} to={source.from} />
            <Divider />
          </>
        )}
        <Spacer />
        <Title title={title} />

        <Spacer />
        {state === 'draft' && <DraftStatusLabel />}
        {state === 'in_review' && <ReviewStatusLabel isReviewApproved={isApproved} />}
        {state === 'released' && <ReleaseStatusLabel />}
      </LeftSide>
      <RightSide>
        {onExpandAll && onCollapseAll && (
          <ExpandCollapseButtons
            buttonClasses={stickyHeaderUtil.getButtonClassNames(false, false)}
            onExpand={onExpandAll}
            onCollapse={onCollapseAll}
            isDisabled={false}
          />
        )}
        {state === 'released' && (
          <>
            <Divider ml={1} mr={1} />
            <h2>
              <TrackingWithQRCode name={title} url={qrCodeUrl} />
            </h2>
          </>
        )}

        {hasComments && setShowComments && (
          <>
            <Divider ml={1} mr={3} />
            <ToggleComments showComments={showComments} setShowComments={setShowComments} />
          </>
        )}
      </RightSide>
    </Toolbar>
  );
};

export default React.memo(ProcedureStickyHeader);

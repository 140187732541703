import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { reactSelectStyles } from '../lib/styles';

const onChange = (options, form, field) => {
  const values = options.map((option) => option.value);
  form.setFieldValue(field.name, values);
};

const placeholderIcon = (icon) => (
  <div className="text-gray-400">
    <FontAwesomeIcon icon={icon} title={icon} />
  </div>
);

/*
 * Renders a react multi-select with a placheholder icon.
 *
 * @param {Array} value - array of objects with "value" and "label" fields. The label
 *                        field is what is displayed and the value field is what is
 *                        saved after selection.
 * @param {Array} options - array of objects with "value" and "label" fields used as
 *                          the options from which the user can select.
 * @param {Object} field - Formik field.
 * @param {Object} form - Formik form.
 * @param {String} icon - String describing fontawesome icon used as the field
 *                        placeholder when no options are selected.
 * @param {String} ariaLabel - Label used to describe this component.
 */
const FieldSetMultiSelectWithIcon = ({ value, options, field, form, icon, ariaLabel }) => (
  <div className="grow w-full">
    <div className="grow">
      <div>
        <Select
          classNamePrefix="react-select"
          styles={reactSelectStyles}
          options={options}
          name={field.name}
          value={value}
          onChange={(options) => onChange(options, form, field)}
          onBlur={field.onBlur}
          placeholder={placeholderIcon(icon)}
          aria-label={ariaLabel}
          isMulti
        />
      </div>
    </div>
  </div>
);

export default FieldSetMultiSelectWithIcon;

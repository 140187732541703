import { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { acceptInvite, getTokenDetails, registerWithInvite, TokenDetails } from '../api/invites';
import { getSession } from '../api/superlogin';
import FormAcceptInvite from '../components/forms/FormAcceptInvite';
import FormNewPassword from '../components/forms/FormNewPassword';
import { useAuth } from '../contexts/AuthContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import useAuthentication from '../hooks/useAuth';
import apm from '../lib/apm';
import { submitErrorsType } from './types';

const DEFAULT_ERROR_MSG = 'There was a problem processing your request, please try again.';

const Register = () => {
  const location = useLocation();
  const { auth } = useAuth();
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const [submitErrors, setSubmitErrors] = useState<submitErrorsType>({
    message: null,
    showSupportLink: false,
  });
  const [tokenDetails, setTokenDetails] = useState<TokenDetails>({
    token,
    existingUser: false,
    loaded: false,
  });
  const { mixpanel } = useMixpanel();
  const { onAuthenticationSuccess } = useAuthentication();

  const clearSubmitErrors = useCallback(() => {
    setSubmitErrors({
      message: null,
      showSupportLink: false,
    });
  }, []);

  // Go to app home page after accepting team invite.
  const onInviteSuccess = useCallback(() => {
    // let the session data take care of redirecting the user to the correct spot here
    history.push('/');
  }, [history]);

  const onInviteError = useCallback((error) => {
    if (error && error.message) {
      setSubmitErrors({
        message: error.message,
        showSupportLink: false,
      });
    } else if (error && error.error) {
      setSubmitErrors({
        message: error.error,
        showSupportLink: false,
      });
    } else {
      setSubmitErrors({
        message: DEFAULT_ERROR_MSG,
        showSupportLink: true,
      });
    }
  }, []);

  useEffect(() => {
    if (!tokenDetails.loaded) {
      (async () => {
        try {
          const details = await getTokenDetails(token);
          details.loaded = true;
          details.valid = true;
          setTokenDetails(details);
        } catch (error) {
          const details = {
            token: '',
            existingUser: true, //probably true at least
            loaded: true,
            valid: false,
          };
          setTokenDetails(details);
        }
      })().catch((err) => apm.captureError(err));
    }
  }, [tokenDetails.loaded, token]);

  const register = useCallback(
    (values, { setFieldError }) => {
      clearSubmitErrors();

      /**
       * After user account is created, auth.isAuthenticated will change and
       * this page will re-render with the "Accept Invite" prompt.
       */
      return registerWithInvite(token, values.password, values.confirmPassword)
        .then(() => {
          if (mixpanel) {
            mixpanel.track('User Registered');
          }
        })
        .catch((error) => {
          if (error && error.validationErrors) {
            if (error.validationErrors.password) {
              setFieldError('password', error.validationErrors.password.join(', '));
            }
            if (error.validationErrors.confirmPassword) {
              setFieldError('confirmPassword', error.validationErrors.confirmPassword.join(', '));
            }
            /**
             * Show helpful error for other account creation problems.
             *
             *`email` is a secret field used by the backend, and errors bubble
             * back up to the frontend. If there's an error in the `email` field
             * it means there was a problem creating this account. (Eg, if an
             * invited email is already on a different team). We'll need to do
             * some customer support here, so show a support link.
             */
            if (error.validationErrors.email) {
              setSubmitErrors({
                message: error.validationErrors.email.join(', '),
                showSupportLink: true,
              });
            }
          } else {
            onInviteError(error);
          }
        });
    },
    [token, mixpanel, onInviteError, clearSubmitErrors]
  );

  const accept = useCallback(() => {
    clearSubmitErrors();

    return acceptInvite(token)
      .then(() => {
        if (mixpanel) {
          mixpanel.track('User Registered');
        }
        /**
         * Accepting an invite updates the user session object with the new list
         * of team databases, so go through the session update flow again with
         * the onAuthenticationSuccess flow.
         */
        return getSession();
      })
      .then(onAuthenticationSuccess)
      .then(onInviteSuccess)
      .catch(onInviteError);
  }, [token, mixpanel, onInviteError, onInviteSuccess, clearSubmitErrors, onAuthenticationSuccess]);

  // Redirect to home (or login)
  if (!token) {
    return <Redirect to="/" />;
  }

  if (tokenDetails.loaded && !tokenDetails.valid) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { flash: 'The invitation is no longer valid' },
        }}
      />
    );
  }

  // Wait for auth to settle.
  if (!auth) {
    return null;
  }

  if (tokenDetails.existingUser && !auth.isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          search: `?redirect=${location.pathname}`,
          state: { flash: 'Please log in to accept your invitation.' },
        }}
      />
    );
  }

  // don't show the screen until it is known which to display
  if (!tokenDetails.loaded) {
    return <></>;
  }

  return (
    <div className="container mx-auto px-12 py-4">
      <div className="items-start max-w-md mx-auto">
        {auth.isAuthenticated && (
          <Fragment>
            <h1 className="mb-4">Welcome to your new workspace!</h1>
            <div className="mb-3">
              You've been invited to a new workspace on Epsilon3. Join now to start collaborating!
            </div>
            <FormAcceptInvite onSubmit={accept} submitError={submitErrors.message} />
          </Fragment>
        )}
        {!auth.isAuthenticated && (
          <Fragment>
            <h1 className="mb-4">Create your account</h1>
            <FormNewPassword
              submitLabel="Create Account"
              onSubmit={register}
              onFieldBlur={clearSubmitErrors}
              submitError={submitErrors.message}
              showSupportLink={submitErrors.showSupportLink}
            />
            <div className="flex my-3 justify-center">
              Already have an account?
              <Link
                to={{
                  pathname: '/login',
                  search: `?redirect=${location.pathname}`,
                }}
                className="ml-2 link"
              >
                Log in
              </Link>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Register;

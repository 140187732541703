import React from 'react';
import WelcomeModal from '../../components/WelcomeModal';
import { BUILDS_TUTORIAL_URL } from '../../config';

const Modal = () => (
  <WelcomeModal
    title="Welcome to Builds!"
    modalKey="BUILDS_WELCOME"
    imgAspectRatio={2.04} // 1200x589
    imgPath="/welcome-gifs/manufacturing.gif"
    tutorialUrl={BUILDS_TUTORIAL_URL}
  >
    <span>
      Streamline your entire manufacturing lifecycle by managing your parts, inventory, and build history in one
      location.
    </span>
    <div>
      <span>Here you can:</span>
      <ul className="list-disc pl-6">
        <li>Manage parts, Bill of Materials (BOMs), components, lead times, and more.</li>
        <li>Import from other product lifecycle management (PLM) systems and keep them in sync.</li>
        <li>Trace parts and assemblies end to end from build through test, operations, and maintenance.</li>
        <li>Receive vendor shipments, kit parts into active work instructions, and fine-grained location controls.</li>
        <li>Detailed analytics, dashboards, and reports of work in progress, assembly duration, and traceability.</li>
        <li>Interactive real-time synchronized work instructions.</li>
      </ul>
    </div>
  </WelcomeModal>
);

export default Modal;

export const DEFAULT_DICTIONARY_ID = 1;
export const DEFAULT_DICTIONARY_NAME = 'Default';

export type TelemetryParam = {
  name: string;
  dictionaryId?: number;
  refreshRateMs?: number;
  isSimulation?: boolean;
};

export type TelemetryParams = {
  [paramName: string]: TelemetryParam;
};

export type Reading = {
  stream_id: string;
  name: string;
  value?: string | number | object;
  recorded_at?: string;
  stale_after_ms?: number;
};

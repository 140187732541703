import FormRunVariable from './FormRunVariable';
import { useRunContext } from '../contexts/RunContext';
import { RunVariable, V2RunVariable } from 'shared/lib/types/views/procedures';
import FormReviewVariable from './Review/FormReviewVariable';

interface RunProcedureVariablesProps {
  isEnabled: boolean;
  variables: Array<RunVariable>;
  onSaveVariable?: (variable: V2RunVariable) => void;
  onRefChanged: (id: string, element: HTMLElement) => void;
  scrollMarginTopValueRem: number;
  diff?: boolean;
}

const RunProcedureVariables = ({
  isEnabled,
  variables,
  onSaveVariable,
  onRefChanged,
  scrollMarginTopValueRem,
  diff = false,
}: RunProcedureVariablesProps) => {
  const { isRun, isIntroductionVisible } = useRunContext();

  if (isRun && !isIntroductionVisible()) {
    return null;
  }

  if (!variables || !variables.length) {
    return null;
  }

  return (
    <div className="space-y-2">
      {variables.map((variable) =>
        diff ? (
          <FormReviewVariable
            key={`variables[${variable.name}]`}
            isEnabled={isEnabled}
            variable={variable}
            onSaveVariable={onSaveVariable}
            onRefChanged={onRefChanged}
            scrollMarginTopValueRem={scrollMarginTopValueRem}
          />
        ) : (
          <FormRunVariable
            key={`variables[${variable.name}]`}
            isEnabled={isEnabled}
            variable={variable}
            onSaveVariable={onSaveVariable}
            onRefChanged={onRefChanged}
            scrollMarginTopValueRem={scrollMarginTopValueRem}
          />
        )
      )}
    </div>
  );
};

export default RunProcedureVariables;

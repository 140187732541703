import React, { ReactNode, useMemo } from 'react';

import { RunFieldInputRecorded, TimestampValue } from 'shared/lib/types/views/procedures';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker/dist/entry.nostyle'; // To use TimePicker without default styles
// To populate TimePicker with an edit of the default style file
import '../../TimeDatePicker.css';
import Tooltip from '../../../elements/Tooltip';
import DateTimeTooltipContent from '../../DateTimeTooltipContent';
import TimeFieldInputHelpIcon from './TimeFieldInputHelpIcon';
import { getFormattedRecordedTimestamp, getHasAllValuesRecorded } from '../../../lib/datetime';
import { getTimestampFromRecorded } from 'shared/lib/datetime';

interface TimestampFieldInputDisplayProps {
  type: 'date' | 'time' | 'datetime';
  recorded?: RunFieldInputRecorded<TimestampValue>;
  isGridFormat?: boolean;
  children: ReactNode;
}

const TimestampFieldInputDisplay = React.memo(
  ({ type, recorded, children, isGridFormat }: TimestampFieldInputDisplayProps) => {
    const { timestamp, zone } = useMemo(() => getTimestampFromRecorded(recorded), [recorded]);
    const formattedTimestamp = useMemo(
      () => getFormattedRecordedTimestamp(type, timestamp, zone),
      [timestamp, type, zone]
    );
    const hasAllValuesRecorded = useMemo(() => getHasAllValuesRecorded(type, recorded), [recorded, type]);

    return (
      <>
        {children}
        {!isGridFormat && <div className="py-2.5">=</div>}
        <div className="flex flex-nowrap space-x-2 grow justify-between">
          <div className="w-full">
            {hasAllValuesRecorded ? (
              <div className={`flex items-center gap-x-2 ${isGridFormat ? 'w-full' : ''}`}>
                <Tooltip
                  content={
                    <DateTimeTooltipContent
                      timestamp={timestamp ?? ''}
                      zone={zone ?? undefined}
                      type={type as 'time' | 'datetime'}
                    />
                  }
                  visible={type !== 'date'}
                >
                  <div
                    className={`py-3 px-2 whitespace-nowrap overflow-hidden truncate ${
                      isGridFormat
                        ? 'w-full h-11 border-gray-300 bg-opacity-50'
                        : 'w-fit rounded border border-gray-400 bg-gray-300 bg-opacity-50'
                    }`}
                  >
                    {formattedTimestamp}
                  </div>
                </Tooltip>
                {type === 'time' && <TimeFieldInputHelpIcon />}
              </div>
            ) : (
              <div className="flex flex-row flex-nowrap gap-x-2 justify-start items-center">
                {isGridFormat ? (
                  <div className="py-3 px-2 bg-transparent whitespace-nowrap overflow-hidden w-full h-11"></div>
                ) : (
                  <>
                    {['datetime', 'date'].includes(type) && (
                      <DatePicker
                        className="w-full py-2 px-2 rounded border border-gray-400 bg-gray-300 bg-opacity-50"
                        selected={null}
                        dateFormat="dd MMM yyyy"
                        disabled={true}
                        onChange={() => {
                          /* no-op */
                        }}
                      />
                    )}
                    {['datetime', 'time'].includes(type) && (
                      <TimePicker
                        className="w-full rounded border border-gray-400 bg-gray-300 bg-opacity-50"
                        format="HH:mm:ss"
                        maxDetail="second"
                        disabled={true}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
);

export default TimestampFieldInputDisplay;

import LocationSelect from '../LocationSelect';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import { PartBuildRecordedItem as RecordedItem } from 'shared/lib/types/views/procedures';
import { getComponentPartFromRecordedItem } from '../PartBuild';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import ComponentPartLabel from '../ComponentPartLabel';
import FullNumberInput from '../Numbering/FullNumberInput';

type ReviewBuildItemProps = {
  part: Part | undefined;
  item: RecordedItem;
  recorded: RecordedItem;
  isEnabled: boolean;
  onRecordValuesChanged?: (values: RecordedItem) => void;
  teamId: string;
};

const ReviewBuildItem = ({ part, item, recorded, isEnabled, onRecordValuesChanged, teamId }: ReviewBuildItemProps) => {
  if (part?.tracking === 'none' && !recorded) {
    const values: RecordedItem = {
      ...item,
    };
    onRecordValuesChanged?.(values);
  }

  const styleByDiffChangeState =
    item.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED
      ? 'bg-emerald-100 text-emerald-800'
      : item.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED
      ? 'bg-red-100 line-through text-red-600'
      : 'bg-white';

  const componentItem = getComponentPartFromRecordedItem(item);

  return (
    <>
      <tr className={`h-10 text-sm ${styleByDiffChangeState} border-b items-center`}>
        <td>
          <ComponentPartLabel teamId={teamId} component={componentItem} part={part} />
        </td>
        <td>
          <div className="p-1">
            <input
              type="number"
              className={`w-full max-w-[9rem] text-sm border-1 border-gray-400 rounded disabled:bg-gray-100 ${
                item.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED ? 'line-through' : ''
              }`}
              disabled={!isEnabled}
              value={recorded?.amount || item.amount}
            />
          </div>
        </td>
        <td>
          <div className="p-1">
            {part?.tracking === 'lot' && <FullNumberInput type="lot_numbers" disabled={!isEnabled} useFormik={false} />}
          </div>
        </td>
        <td>
          {part && (
            <div className="max-w-[12rem]">
              <LocationSelect locationId={recorded?.location_id || ''} isDisabled={!isEnabled} />
            </div>
          )}
        </td>
      </tr>
      {/* Spacer for "add" button */}
      <div className="h-10" />
    </>
  );
};

export default ReviewBuildItem;

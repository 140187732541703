import React from 'react';
import Comment from './Comment';
import { ReviewComment } from 'shared/lib/types/views/procedures';
import ReviewCommentForm from './ReviewCommentForm';

interface ChildrenReviewCommentProps {
  childComments?: Array<ReviewComment>;
  parentId?: string;
  stepId?: string;
  isDocStateActive?: boolean;
  onResolveComment?: (commentId: string) => void;
  onUnresolveComment?: (commentId: string) => void;
  saveReviewComment?: (comment: ReviewComment) => Promise<void>;
  onSetCommentFormVisible: (visible: boolean) => void;
  editReviewComment?: (comment: ReviewComment) => Promise<void>;
  formVisible: boolean;
  commentButton: React.ReactElement;
  parentCollapsed?: boolean;
  canResolve?: boolean;
}

const ChildrenReviewComments = ({
  childComments,
  parentId,
  stepId,
  isDocStateActive,
  onResolveComment,
  onUnresolveComment,
  saveReviewComment,
  onSetCommentFormVisible,
  editReviewComment,
  formVisible,
  commentButton,
  parentCollapsed,
  canResolve,
}: ChildrenReviewCommentProps) => {
  return (
    <>
      {/* Render Child Comments */}
      {!parentCollapsed &&
        childComments?.map((comment, index) => (
          <div key={index} className="ml-2 mt-2 mb-1 border-l-2 border-slate-400">
            <Comment
              key={comment.id}
              comment={comment}
              onResolve={onResolveComment}
              onUnresolve={onUnresolveComment}
              editComment={canResolve ? editReviewComment : undefined}
              commentButton={undefined}
              onFieldRefChanged={undefined}
              onRefChanged={undefined}
              error={undefined}
              isChild={true}
              onSetParentCollapsed={undefined}
              parentCollapsed={undefined}
              mentionsEnabled={true}
            />
          </div>
        ))}

      {/* Render Comment form here */}
      <ReviewCommentForm
        parentId={parentId}
        stepId={stepId}
        saveReviewComment={saveReviewComment}
        formVisible={formVisible}
        onSetCommentFormVisible={onSetCommentFormVisible}
        commentButton={(childComments && childComments.length === 0) || !canResolve ? <></> : commentButton}
        isDocStateActive={isDocStateActive}
      />
    </>
  );
};

export default React.memo(ChildrenReviewComments);

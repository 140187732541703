import { useMemo } from 'react';

const IMAGE_MIME_TYPE_PREFIX = 'image';
const SVG_MIME_TYPE_SUFFIX = 'svg+xml';
const UNSUPPORTED_IMAGE_TYPES = {
  'image/heic': true,
  'image/heif': true,
};

/**
 * Hook for parsing and using content type from attachments and files.
 *
 * @param {Object} props - Props object for hook.
 * @param {File} [props.file] - Local file that will be uploaded as an attachment.
 * @param {Object} props.attachment - Attachment object.
 * @returns {Object} results - Results object.
 * @property {Boolean} results.isImageType - True if the file or attachment
 *   has an image content type.
 * @property {Boolean} results.isSvgType - True if the file or attachment
 *   has an SVG image content type.
 * @property {Boolean} results.shouldRenderImage - True if we should render the
 *   file or attachment as an image, otherwise false to render as a file link.
 */
const useContentType = ({ file, attachment }) => {
  const contentType = useMemo(() => {
    return file ? file.type : attachment.content_type;
  }, [file, attachment]);

  const isImageType = useMemo(() => {
    if (!contentType) {
      return;
    }
    return contentType.startsWith(IMAGE_MIME_TYPE_PREFIX);
  }, [contentType]);

  const isSvgType = useMemo(() => {
    if (!contentType) {
      return;
    }
    return contentType.endsWith(SVG_MIME_TYPE_SUFFIX);
  }, [contentType]);

  // Unsafe to preview .svg files because of "billion laughs attack."
  const shouldRenderImage = useMemo(() => {
    return isImageType && !isSvgType && !UNSUPPORTED_IMAGE_TYPES[contentType];
  }, [contentType, isImageType, isSvgType]);

  return {
    isImageType,
    isSvgType,
    shouldRenderImage,
  };
};

export default useContentType;

import { MILLISECONDS_PER_MINUTE } from './datetime';

/*
 * Heuristic for determining if a user is currently editing is if they've
 * saved in the last N minutes.
 */
const SHOW_LOCK_THRESHOLD_MINUTES = 1;

type ShouldShowLockParams = {
  editedAt?: string;
  editedUserId?: string;
  userId?: string;
};

export const shouldShowLock = ({
  editedAt,
  editedUserId,
  userId,
}: ShouldShowLockParams): boolean => {
  if (!editedAt || !editedUserId || !userId) {
    return false;
  }
  if (editedUserId === userId) {
    return false;
  }
  const diffMs = Date.now() - new Date(editedAt).getTime();
  const thresholdMs = SHOW_LOCK_THRESHOLD_MINUTES * MILLISECONDS_PER_MINUTE;
  return diffMs < thresholdMs;
};

export const getLockMessage = (userId: string): string => {
  return `Are you sure you want to proceed? ${userId} is editing this draft.`;
};

import { Redirect, useLocation } from 'react-router-dom';
import { useDatabaseServices } from '../contexts/DatabaseContext';

const TeamUrlRedirect = () => {
  const { currentTeamId } = useDatabaseServices();
  const location = useLocation();
  const newPath = `/app/team/${currentTeamId}${location.pathname}`;
  return (
    <Redirect
      to={{
        pathname: newPath,
        search: location.search,
        state: location.state,
      }}
    />
  );
};

export default TeamUrlRedirect;

import { useEffect, useState } from 'react';
import { useSettings } from '../../contexts/SettingsContext';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { ITEM_REFERENCE_TYPE } from '../../issues/constants';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import { Issue } from '../../issues/types';
import apm from '../../lib/apm';

interface ItemIssuesState {
  itemIdsToNumIssues: {
    mapOfCriticalIssues: { [key: string]: Issue[] };
    itemCounts: { [key: string]: number };
    loaded: boolean;
  };
}

const useItemIssues = (): ItemIssuesState => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { isIssuesEnabled } = useSettings();
  const [itemIdsToNumIssues, setItemIdsToNumIssues] = useState({
    itemCounts: {},
    loaded: false,
    mapOfCriticalIssues: {},
  });

  useEffect(() => {
    if (!isIssuesEnabled() || itemIdsToNumIssues.loaded || !services) {
      return;
    }

    services.ncr
      .listOpenIssues(undefined, ITEM_REFERENCE_TYPE)
      .then((issues) => {
        const itemCounts = {};
        const mapCriticalIssues = {};
        issues.forEach((issue) => {
          if (itemCounts[issue.reference_id]) {
            itemCounts[issue.reference_id]++;
          } else {
            itemCounts[issue.reference_id] = 1;
          }

          if (issue.severity.name === 'Critical') {
            if (mapCriticalIssues[issue.reference_id]) {
              mapCriticalIssues[issue.reference_id].push(issue);
            } else {
              mapCriticalIssues[issue.reference_id] = [issue];
            }
          }
        });

        setItemIdsToNumIssues((prevState) => ({
          ...prevState,
          itemCounts,
          loaded: true,
          mapOfCriticalIssues: mapCriticalIssues,
        }));
      })
      .catch((err) => apm.captureError(err));
  }, [isIssuesEnabled, itemIdsToNumIssues, services]);

  return {
    itemIdsToNumIssues,
  };
};

export default useItemIssues;

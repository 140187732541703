import { ViewTab } from 'shared/lib/types/postgres/users';
import Tooltip from '../../elements/Tooltip';
import Button, { BUTTON_TYPES } from '../Button';

export interface ViewTabToggleProps {
  viewTab: ViewTab;
  setViewTab: (viewTab: ViewTab) => void;
}

const ViewTabToggle = ({ viewTab, setViewTab }) => {
  return (
    <div className="flex flex-row gap-x-1 items-center">
      <Tooltip content="List View" visible={viewTab !== ViewTab.List}>
        <div className={`${viewTab === ViewTab.List ? 'bg-slate-300' : ''} text-slate-700 hover:bg-slate-300 rounded `}>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            leadingIcon="list"
            size="sm"
            onClick={() => setViewTab(ViewTab.List)}
            isDisabled={viewTab === ViewTab.List}
            iconTextColor="text-slate-700"
            ariaLabel="List View"
          />
        </div>
      </Tooltip>
      <Tooltip content="Tree View" visible={viewTab !== ViewTab.Tree}>
        <div className={`${viewTab === ViewTab.Tree ? 'bg-slate-300' : ''} text-slate-700 hover:bg-slate-300 rounded `}>
          <Button
            type={BUTTON_TYPES.TERTIARY}
            leadingIcon="sitemap"
            size="sm"
            onClick={() => setViewTab(ViewTab.Tree)}
            isDisabled={viewTab === ViewTab.Tree}
            iconTextColor="text-slate-700"
            ariaLabel="Tree View"
          />
        </div>
      </Tooltip>
      <div className="pr-1"></div>
    </div>
  );
};

export default ViewTabToggle;

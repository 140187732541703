const fieldInputTypes = Object.freeze({
  text: {
    displayName: 'Text',
    allowsUnits: true,
  },
  number: {
    displayName: 'Number',
    allowsRules: true,
    allowsUnits: true,
    unitsRequired: true,
  },
  checkbox: { displayName: 'Checkbox' },
  timestamp: { displayName: 'Timestamp' },
  attachment: { displayName: 'Attachment' },
  list: { displayName: 'List' },
  select: { displayName: 'Custom List' },
  external_item: { displayName: 'External Data' },
  external_search: { displayName: 'External Search' },
  multiple_choice: { displayName: 'Multiple Choice' },
  sketch: { displayName: 'Sketch' },
});

export default fieldInputTypes;

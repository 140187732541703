import React, { useCallback, useMemo } from 'react';
import { isReleased } from 'shared/lib/procedureUtil';
import { DraftState, ProcedureMetadata, ReleaseState } from 'shared/lib/types/couch/procedures';
import { Procedure, ProcedureDiff } from 'shared/lib/types/views/procedures';
import diffUtil, { ChangeEntry } from '../../lib/diffUtil';
import procedureUtil from '../../lib/procedureUtil';
import stickyHeaderUtil from '../../lib/stickyHeaderUtil';
import ExpandCollapseButtons from '../ExpandCollapse/ExpandCollapseButtons';
import Divider from '../Toolbar/Divider';
import LeftSide from '../Toolbar/LeftSide';
import RightSide from '../Toolbar/RightSide';
import Toolbar from '../Toolbar/Toolbar';
import DiffSelector from './DiffSelector';
import NavigationChanges from './NavigationChanges';
import BackToLink, { BROWSER_BACK } from '../Toolbar/BackToLink';

export const DIFF_STICKY_HEADER_HEIGHT_REM = 2.5;

interface DiffStickyHeaderProps {
  oldProcedureMetadataList: Array<ProcedureMetadata>;
  newProcedureMetadataList: Array<ProcedureMetadata>;
  procedureDiff: ProcedureDiff;
  oldProcedure: Procedure;
  newProcedure: Procedure;
  showDiff: boolean;
  scrollToDiff: (changeEntry: ChangeEntry) => void;
  onExpandAll: () => void;
  onCollapseAll: () => void;
  onCompare: (values: {
    old: string;
    oldVersion: string;
    oldPending: 'true' | 'false';
    new: string;
    newVersion: string;
    newPending: 'true' | 'false';
  }) => void;
}

const DiffStickyHeader = ({
  oldProcedureMetadataList,
  newProcedureMetadataList,
  procedureDiff,
  oldProcedure,
  newProcedure,
  showDiff,
  scrollToDiff,
  onExpandAll,
  onCollapseAll,
  onCompare,
}: DiffStickyHeaderProps) => {
  const changes = useMemo(() => (procedureDiff ? diffUtil.getChangeEntries(procedureDiff) : []), [procedureDiff]);

  const diffLoading = !procedureDiff;

  const getVersionLabel = useCallback((procedureMetadata) => {
    return isReleased(procedureMetadata)
      ? `Version ${procedureMetadata.version}`
      : procedureUtil.getVersionLabel(procedureMetadata.version, procedureMetadata.state as DraftState | ReleaseState);
  }, []);

  const getVersionOptions = useCallback(
    (metadataList: Array<ProcedureMetadata>, excludeVersionData) => {
      return metadataList
        .filter((procedureMetadata) => {
          return excludeVersionData.excludePending
            ? isReleased(procedureMetadata)
            : !(excludeVersionData.excludedVersions.has(procedureMetadata.version) && isReleased(procedureMetadata));
        })
        .map((procedureMetadata) => ({
          value: procedureMetadata.version,
          label: getVersionLabel(procedureMetadata),
          pending: !isReleased(procedureMetadata),
        }))
        .sort((optionA, optionB) => optionB.label.localeCompare(optionA.label));
    },
    [getVersionLabel]
  );

  return (
    <Toolbar>
      <LeftSide showOverflow={true}>
        <BackToLink to={BROWSER_BACK} name="Back" />
        <Divider />
        <div className="mr-2">
          <DiffSelector
            secondaryLabel="Version"
            oldSecondaryOptions={getVersionOptions(oldProcedureMetadataList, {
              excludedVersions: new Set([newProcedure.version]),
              excludePending: !isReleased(newProcedure),
            })}
            newSecondaryOptions={getVersionOptions(newProcedureMetadataList, {
              excludedVersions: new Set([oldProcedure.version]),
              excludePending: !isReleased(oldProcedure),
            })}
            initialOldPrimaryValue={{
              value: oldProcedure.procedure_id,
              label: `${procedureUtil.getProcedureTitle(oldProcedure.code, oldProcedure.name)}`,
            }}
            initialOldSecondaryValue={{
              value: oldProcedure.version,
              label: getVersionLabel(oldProcedure),
              pending: !isReleased(oldProcedure),
            }}
            initialNewPrimaryValue={{
              value: newProcedure.procedure_id,
              label: `${procedureUtil.getProcedureTitle(newProcedure.code, newProcedure.name)}`,
            }}
            initialNewSecondaryValue={{
              value: newProcedure.version,
              label: getVersionLabel(newProcedure),
              pending: !isReleased(newProcedure),
            }}
            onCompare={onCompare}
          />
        </div>
      </LeftSide>
      <RightSide>
        <ExpandCollapseButtons
          buttonClasses={stickyHeaderUtil.getButtonClassNames(false, false)}
          onExpand={onExpandAll}
          onCollapse={onCollapseAll}
          isDisabled={false}
        />
        <Divider ml={1} mr={3} />
        <NavigationChanges changes={changes} scrollToDiff={scrollToDiff} isEnabled={showDiff} />
        {diffLoading && (
          <div className="uppercase text-gray-400 text-sm font-bold flex items-center">Diff is loading</div>
        )}
      </RightSide>
    </Toolbar>
  );
};

export default React.memo(DiffStickyHeader);

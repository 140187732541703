import { cloneDeep, sum } from 'lodash';
import { CouchLikeOperationProcedure } from 'shared/lib/types/operations';
import { RunByOperation } from 'shared/lib/types/couch/views';

class OperationUtil {
  procedures: Array<CouchLikeOperationProcedure>;
  runs: Array<RunByOperation>;

  constructor(
    procedures: Array<CouchLikeOperationProcedure>,
    runs: Array<RunByOperation>
  ) {
    this.procedures = procedures;
    this.runs = cloneDeep(runs).sort();
  }

  /**
   * @returns a map of procedure id to count for all planned procedures that have yet to be started
   */
  getUnstartedProcedures = (): Map<string, number> => {
    return this._reduceProcedures(
      (proc) => proc.count - this._getAllRunsOfProcedure(proc.id).length
    );
  };

  hasUnstartedProcedures = (): boolean =>
    this.getUnstartedProcedures().size > 0;

  getUnstartedProcedureIds = (): Array<string> =>
    Array.from(this.getUnstartedProcedures().keys());

  numUnstartedProcedures = (): number =>
    sum(Array.from(this.getUnstartedProcedures().values()));

  /**
   * @returns all procedures for this operation, planned and ad hoc
   */
  getAllProcedures = (): Array<CouchLikeOperationProcedure> => {
    const adHocProcedureIds = this._getAdHocRuns().map(
      (run) => run.procedure
    ) as Array<string>;
    return adHocProcedureIds.reduce((procs, procedureId) => {
      const index = procs.findIndex((p) => p.id === procedureId);
      if (index === -1) {
        procs.push({
          id: procedureId,
          count: 1,
        });
      } else {
        procs[index].count++;
      }
      return procs;
    }, cloneDeep(this.procedures));
  };

  _reduceProcedures = (
    count: (proc: CouchLikeOperationProcedure) => number
  ): Map<string, number> => {
    return this.procedures.reduce((procs, proc) => {
      if (count(proc) > 0) {
        procs.set(proc.id, count(proc));
      }
      return procs;
    }, new Map<string, number>());
  };

  _getAllRunsOfProcedure = (procedureId: string): Array<RunByOperation> => {
    return this.runs.filter((run) => run.procedure === procedureId);
  };

  /**
   * @returns an array of all planned runs in excess of their procedure count, plus all unplanned runs
   */
  _getAdHocRuns = (): Array<RunByOperation> =>
    this._getAdditionalRuns().concat(this._getUnplannedRuns());

  /**
   * @returns any runs that have a defined procedure in the operation, in excess of the count of that procedure
   */
  _getAdditionalRuns = (): Array<RunByOperation> => {
    return this.procedures.reduce((procs, proc) => {
      const allRunsOfProcedure = this._getAllRunsOfProcedure(proc.id);
      if (allRunsOfProcedure.length > proc.count) {
        procs.push(...allRunsOfProcedure.slice(proc.count));
      }
      return procs;
    }, [] as Array<RunByOperation>);
  };

  /**
   * @returns any runs that do not have a defined procedure in the operation
   */
  _getUnplannedRuns = (): Array<RunByOperation> => {
    return this.runs.filter(
      (run) => !this.procedures.some((proc) => proc.id === run.procedure)
    );
  };
}

export default OperationUtil;

import React, { useState, useCallback, useMemo, useRef } from 'react';
import { Form, Formik } from 'formik';
import MenuEndProcedure from './MenuEndProcedure';
import { useRunContext } from '../contexts/RunContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Tooltip from '../elements/Tooltip';
import stickyHeaderUtil from '../lib/stickyHeaderUtil';
import { OverlayPanel } from 'primereact/overlaypanel';

const EndProcedureButton = ({ endRun, isDisabled, hasEndRunPermissions, showLabel = false }) => {
  const { isUserParticipant } = useRunContext();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOnSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);
      endRun(values.comment, values.status).finally(() => {
        setIsSubmitting(false);
      });
    },
    [endRun]
  );

  const op = useRef<OverlayPanel>(null);

  const isRunButtonDisabled = useMemo(() => {
    return isDisabled || !isUserParticipant || isSubmitting;
  }, [isDisabled, isSubmitting, isUserParticipant]);

  return (
    <>
      <Tooltip
        content="None of your operator roles can end runs"
        position="bottom"
        visible={isDisabled && !hasEndRunPermissions}
      >
        <button
          className={`flex flex-none items-center ${stickyHeaderUtil.getButtonClassNames(isRunButtonDisabled)}`}
          type="button"
          title={isDisabled ? '' : 'End Procedure'}
          aria-label="End Procedure"
          disabled={isRunButtonDisabled}
          onClick={(e) => op?.current?.toggle(e)}
        >
          <FontAwesomeIcon fixedWidth={true} icon={'fa-solid fa-square' as IconProp} />
          {showLabel && <div className="text-sm ml-2">End</div>}
          <FontAwesomeIcon className="ml-2" icon="caret-down" />
        </button>
      </Tooltip>
      <OverlayPanel className="p-0" ref={op}>
        <Formik initialValues={{ comment: '' }} onSubmit={handleOnSubmit}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <MenuEndProcedure onSubmit={handleSubmit} onCancel={() => op?.current?.hide()} />
            </Form>
          )}
        </Formik>
      </OverlayPanel>
    </>
  );
};

export default EndProcedureButton;

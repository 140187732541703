import { useCallback, useMemo } from 'react';
import { Issue } from '../types';
import { Issue as ServerIssue } from 'shared/lib/types/postgres/issues';
import useRealtimeUpdates, { useRealtimeUpdatesProps } from '../../hooks/useRealtimeUpdates';
import { mapServerIssueToClient } from '../api/issues';
import { useDatabaseServices } from '../../contexts/DatabaseContext';

const useRealtimeIssues = () => {
  const { services } = useDatabaseServices();

  const getInitialIssues = useCallback(async () => {
    if (!services) {
      return;
    }
    return services.ncr.listIssues();
  }, [services]);

  const { realtimeData: serverIssues, isLoading } = useRealtimeUpdates<ServerIssue>({
    entityType: 'issues',
    initialGetter: getInitialIssues,
  } as useRealtimeUpdatesProps);

  const issues = useMemo<Array<Issue>>(() => {
    if (isLoading) {
      return [];
    }
    return (serverIssues as Array<ServerIssue>).map(mapServerIssueToClient);
  }, [isLoading, serverIssues]);

  return { isLoading, issues };
};

export default useRealtimeIssues;

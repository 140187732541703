import { Unit } from 'shared/lib/types/api/settings/units/models';
import { useSettings } from '../contexts/SettingsContext';
import { useCallback, useMemo } from 'react';

type UnitsHook = {
  settingsUnits: Unit[];
  findDefinedUnit: (unit: string) => Unit | undefined;
  addUnitsToLabel: (label: string, unit?: string) => string;
};

const useUnits = (): UnitsHook => {
  const { units } = useSettings();

  const settingsUnits = useMemo(() => units?.units || [], [units]);

  const findDefinedUnit = useCallback(
    (unit: string) => settingsUnits.find((u) => u.name === unit),
    [settingsUnits]
  );

  const addUnitsToLabel = useCallback(
    (label: string, unit?: string) => {
      let unitLabel = unit;
      if (unitLabel && findDefinedUnit) {
        const settingsUnit = findDefinedUnit(unitLabel);
        if (settingsUnit) {
          unitLabel = settingsUnit.abbreviation;
        }
      }
      const unitsSuffix = unitLabel ? ` (${unitLabel})` : '';
      return `${label}${unitsSuffix}`;
    },
    [findDefinedUnit]
  );

  return { settingsUnits, findDefinedUnit, addUnitsToLabel };
};

export default useUnits;

import React, { useMemo, useCallback } from 'react';
import { Interval } from 'luxon';
import Day from './Day';
import { nestEventsByDate } from '../../lib/event';

const Calendar = ({ period, events }) => {
  const nestedEvents = useMemo(() => nestEventsByDate(events.filter((e) => e.start)), [events]);

  const getEvents = useCallback(
    (date: Interval) => {
      const events = nestedEvents?.[date.s.year]?.[date.s.month]?.[date.s.day];
      return events ? events : [];
    },
    [nestedEvents]
  );

  const renderInterval = useMemo(() => {
    const start = period.start.weekdayShort === 'Sun' ? period.start : period.start.startOf('week').minus({ days: 1 });

    const end =
      period.end.weekdayShort === 'Sun'
        ? period.end.plus({ days: 1 }).endOf('week').startOf('day')
        : period.end.endOf('week').startOf('day');

    return Interval.fromDateTimes(start, end);
  }, [period]);

  const renderDays = useMemo(() => renderInterval.splitBy({ days: 1 }), [renderInterval]);

  return (
    <div
      className="flex flex-col h-full w-full bg-gray-500 border border-gray-500 overflow-auto"
      aria-label="Calendar Schedule"
    >
      <div className="grid grid-cols-7 gap-px h-6 w-full sticky top-0 z-10">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, idx) => (
          <div key={idx} className="flex justify-center bg-blue-100">
            {day}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7 gap-px h-full w-full">
        {renderDays.map((date, idx) => (
          <Day key={idx} date={date} events={getEvents(date)} isDisabled={!period.overlaps(date)} />
        ))}
      </div>
    </div>
  );
};

export default Calendar;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormStepComment from './FormStepComment';
import procedureUtil from '../lib/procedureUtil';
import { ReviewComment } from 'shared/lib/types/views/procedures';
import { useMixpanel } from '../contexts/MixpanelContext';
import { generateCommentId } from 'shared/lib/idUtil';

/*
 * Component for rendering step comment form and existing comments.
 *
 */

interface ReviewCommentFormProps {
  parentId?: string;
  stepId?: string;
  saveReviewComment?: (comment: ReviewComment) => Promise<void>;
  formVisible: boolean;
  onSetCommentFormVisible: (visible: boolean) => void;
  commentButton: React.ReactElement;
  isDocStateActive?: boolean;
}

const ReviewCommentForm = ({
  parentId,
  stepId,
  saveReviewComment,
  formVisible,
  onSetCommentFormVisible,
  commentButton,
  isDocStateActive,
}: ReviewCommentFormProps) => {
  const [isAddingText, setIsAddingText] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [hasSubmissionError, setHasSubmissionError] = useState(false);
  const isMounted = useRef(true);
  const { mixpanel } = useMixpanel();

  const mixpanelTrack = useCallback(
    (name) => {
      if (mixpanel && name) {
        mixpanel.track(name);
      }
    },
    [mixpanel]
  );

  const onCancelText = () => {
    onSetCommentFormVisible(false);
    setIsAddingText(false);
  };

  useEffect(() => {
    if (formVisible && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [formVisible, textAreaRef]);

  const saveTextComment = async (values) => {
    const trimmed = procedureUtil.trimLeadingAndTrailing(values.comment);
    if (!trimmed || typeof saveReviewComment !== 'function') {
      onSetCommentFormVisible(false);
      setIsAddingText(false);
      return;
    }

    const mentions = procedureUtil.getValidMentions(values.mentions, trimmed);
    if (mentions.length > 0) {
      mixpanelTrack('User mentioned in comment');
    }

    const comment = {
      id: generateCommentId(),
      parent_id: values.parentId ?? '',
      reference_id: stepId,
      type: 'review_comment',
      text: trimmed,
      mention_list: mentions,
    };
    return saveReviewComment(comment as ReviewComment)
      .then(() => setHasSubmissionError(false))
      .catch(() => setHasSubmissionError(true));
  };

  const onTextChanged = useCallback((containsText) => {
    setIsAddingText(containsText);
  }, []);

  const onSubmitComment = async (values, { setSubmitting }) => {
    return saveTextComment(values)
      .then(() => {
        // Form is removed on success so no need to call `setSubmitting(false)`.
        onSetCommentFormVisible(false);
        setIsAddingText(false);
      })
      .catch(() => {
        // TODO: Show error message on error?
        if (!isMounted.current) {
          return;
        }
        setSubmitting(false);
        setIsAddingText(false);
      });
  };

  return (
    <>
      {!isDocStateActive && <div className="h-3" />}
      {isDocStateActive && (
        <div className={`flex ${parentId ? 'px-1' : ''} ${isAddingText ? 'flex-col' : ''}`}>
          {(isAddingText || formVisible) && (
            <FormStepComment
              // @ts-ignore
              onSubmit={onSubmitComment}
              onCancel={onCancelText}
              placeholder="Add comment"
              onTextChanged={onTextChanged}
              parentId={parentId}
            />
          )}
        </div>
      )}
      {!formVisible && isDocStateActive && (
        <div className="flex">
          {/* Text commenting Button */}
          {commentButton}
        </div>
      )}
      {/* Show user any submission errors */}
      {hasSubmissionError && (
        <div className="flex items-center text-red-700">Error saving comment. Please try again.</div>
      )}
    </>
  );
};

export default React.memo(ReviewCommentForm);

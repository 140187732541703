import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from './Button';
import { EDIT_STICKY_HEADER_HEIGHT_REM } from './EditToolbar';

const DURATION_NAME = 'duration';
const ICON = 'stopwatch';

const FieldSetStepDuration = ({ onRemove }) => {
  const remove = useCallback(() => {
    if (onRemove) {
      onRemove(DURATION_NAME);
    }
  }, [onRemove]);

  return (
    <div className="group relative flex flex-col grow">
      <div className="flex flex-col shrink-0" style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}>
        <span className="field-title">Duration</span>
        <div className="grow w-full">
          <div className="grow relative mt-2">
            <FontAwesomeIcon icon={ICON} className="text-gray-400 mr-1" />
            <span className="mr-1">HH:MM:SS</span>
            <Button isDisabled={true} type="primary" size="xs">
              <span>Start</span>
            </Button>
          </div>
        </div>
      </div>
      {onRemove && (
        <FontAwesomeIcon
          className="absolute top-0.5 right-0 text-gray-400 hover:text-gray-600 cursor-pointer opacity-0 group-hover:opacity-100"
          icon="times-circle"
          aria-label="Remove Detail"
          onClick={remove}
        />
      )}
    </div>
  );
};

export default FieldSetStepDuration;

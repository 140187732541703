import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useCallback, useRef, useState } from 'react';
import { IssueType, RunIssue } from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import useExpectedState from '../hooks/useExpectedState';
import CreateIssueModal from '../issues/components/CreateIssueModal';
import { RUN_REFERENCE_TYPE } from '../issues/constants';
import REFRESH_TRY_AGAIN_MESSAGE from '../lib/messages';
import stickyHeaderUtil from '../lib/stickyHeaderUtil';
import RunIssuesDisplay from './RunIssuesDisplay';

interface RunIssuesButtonProps {
  canCreateIssue?: boolean;
  runId: string;
  projectId?: string;
}

export type RunIssues = {
  open: Array<RunIssue>;
  closed: Array<RunIssue>;
};

const INITIAL_RUN_ISSUES = {
  open: [],
  closed: [],
};

const RunIssuesButton = ({ canCreateIssue, runId, projectId }: RunIssuesButtonProps) => {
  const op = useRef<OverlayPanel>(null);
  const [showIssueModal, setShowIssueModal] = useState(false);
  const { services } = useDatabaseServices();
  const {
    value: runIssues,
    setValue: setRunIssues,
    error: runIssuesError,
    setError: setRunIssuesError,
  } = useExpectedState<RunIssues, string>(INITIAL_RUN_ISSUES);

  const convertToRunIssue = useCallback((issue) => {
    return {
      id: issue.id,
      text: issue.title,
      type: IssueType.Internal,
      severity: issue.severity,
      status: issue.status,
      url: issue.runId,
    };
  }, []);

  const convertToRunIssues = useCallback(
    (issues) => {
      return issues.map(convertToRunIssue).sort((a, b) => a.status.name.localeCompare(b.status.name) || a.id - b.id);
    },
    [convertToRunIssue]
  );

  const loadIssues = useCallback(async () => {
    const promises = [services.ncr.listOpenIssuesForRun(runId), services.ncr.listClosedIssuesForRun(runId)];
    const [openIssues, closedIssues] = await Promise.all(promises);
    const runIssues = {
      open: convertToRunIssues(openIssues),
      closed: convertToRunIssues(closedIssues),
    };
    setRunIssues(runIssues);
  }, [services.ncr, runId, convertToRunIssues, setRunIssues]);

  const tryLoadIssues = useCallback(async () => {
    try {
      await loadIssues();
    } catch {
      setRunIssuesError(REFRESH_TRY_AGAIN_MESSAGE);
    }
  }, [loadIssues, setRunIssuesError]);

  return (
    <>
      <button
        className={`py-2 flex flex-none items-center ${stickyHeaderUtil.getButtonClassNames(false, false)}`}
        type="button"
        title="Issues"
        aria-label="Issues"
        onClick={(e) => op?.current?.toggle(e)}
      >
        <FontAwesomeIcon fixedWidth={true} icon={'file-circle-exclamation' as IconProp} />
        <div className="ml-2 lg:hidden">Issues</div>
        <FontAwesomeIcon className="ml-2" icon="caret-down" />
      </button>

      <OverlayPanel onShow={tryLoadIssues} className="p-0" ref={op}>
        <div className="flex flex-col text-sm font-medium ">
          {canCreateIssue && (
            <>
              <div className="m-2">
                <button type="button" className="hover:brightness-50 h-6" onClick={() => setShowIssueModal(true)}>
                  <FontAwesomeIcon className="text-blue-500 opacity-100" icon="circle-plus" />
                  <span className="text-sm font-semibold ml-1">Create Issue</span>
                </button>
              </div>
            </>
          )}
          {runIssues && <RunIssuesDisplay issues={runIssues} />}
          {runIssuesError && <span className="m-2 font-normal text-xs text-red-500">{runIssuesError}</span>}
        </div>
      </OverlayPanel>

      <CreateIssueModal
        runId={runId}
        referenceId={runId}
        referenceType={RUN_REFERENCE_TYPE}
        onAddIssue={() => {
          /*
           * No longer saving the issue on the run doc, so don't pass this up the chain
           * We may need to add it back for offline support unless we make all issues offline compatible
           */
          return Promise.resolve();
        }}
        isModalShown={showIssueModal}
        onHideModal={() => setShowIssueModal(false)}
        projectId={projectId}
      />
    </>
  );
};

export default RunIssuesButton;

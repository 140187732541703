import React, { useCallback } from 'react';
import stepConditionals, { CONDITIONAL_TYPE } from 'shared/lib/stepConditionals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConditionalStateTag from './ConditionalStateTag';
import {
  ContentBinaryConditional,
  ContentTernaryConditional,
  FieldInputConditionalBlock,
  StepConditional,
} from 'shared/lib/types/views/procedures';

interface StepConditionalDetailsProps {
  content: Array<FieldInputConditionalBlock>;
  conditionals: Array<StepConditional>;
  stepIdsToLabelsMap: Record<string, string>;
  onStepLinkClicked: (linkId: string) => void;
  stepId: string;
}

const StepConditionalDetails = ({
  content,
  conditionals,
  stepIdsToLabelsMap,
  onStepLinkClicked,
  stepId,
}: StepConditionalDetailsProps) => {
  const sourceName = useCallback(
    (conditional: StepConditional | ContentBinaryConditional | ContentTernaryConditional) => {
      if (!conditional) {
        return '';
      }
      if (conditional.source_type === CONDITIONAL_TYPE.STEP) {
        return 'Step';
      } else if (stepConditionals.isContentConditional(conditional)) {
        const contentConditional = conditional;
        const contentBlock = content.find((block) => block.id === contentConditional.content_id);
        if (contentBlock) {
          return contentBlock.name;
        }
      }
      return '';
    },
    [content]
  );

  if (!conditionals || !conditionals.length) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2 px-4 py-3">
      <div className="whitespace-nowrap">{sourceName(conditionals[0])}:</div>
      {conditionals.map((conditional) => (
        <div key={conditional.id} className="flex flex-row gap-x-1 items-center">
          <ConditionalStateTag conditional={conditional} size="base" conditionalStepId={stepId} />
          <FontAwesomeIcon className="text-gray-600" icon="arrow-right" />
          <button
            className="link"
            onClick={() => onStepLinkClicked(conditional.target_id)}
            disabled={typeof onStepLinkClicked !== 'function'}
          >
            {stepIdsToLabelsMap[conditional.target_id]}
          </button>
        </div>
      ))}
    </div>
  );
};

export default StepConditionalDetails;

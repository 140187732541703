import React, { useCallback } from 'react';
import Toggle from 'react-toggle';

interface ToggleCommentsProps {
  showComments: boolean;
  setShowComments: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToggleComments = ({ showComments, setShowComments }: ToggleCommentsProps) => {
  const handleToggleComments = useCallback(() => {
    setShowComments((oldShowComments) => !oldShowComments);
  }, [setShowComments]);

  return (
    <label>
      <div className={`flex flex-row items-center whitespace-nowrap ${showComments ? '' : 'text-gray-400'}`}>
        <Toggle checked={showComments} onChange={handleToggleComments} aria-label="Comments Toggle" icons={false} />
        <div className="ml-2 text-sm">Show Comments</div>
      </div>
    </label>
  );
};

export default React.memo(ToggleComments);

import procedureUtil from './procedureUtil';
import { PROCEDURE_STATE_IN_REVIEW } from 'shared/lib/procedureUtil';
import {
  compareReviewActions,
  getHasReviewerApproved,
} from 'shared/lib/reviewUtil';
import {
  Reviewer,
  ReviewerAction,
  ReviewerGroup,
  ReviewerId,
} from 'shared/lib/types/couch/procedures';
import {
  DraftRedlineComment,
  Procedure,
  ProcedureMetadata,
  ReviewComment,
} from 'shared/lib/types/views/procedures';
import { Reviewable } from 'shared/lib/types/reviewers';

const reviewUtil = {
  getStepReviewComments: (
    comments: Array<DraftRedlineComment | ReviewComment>,
    stepId: string
  ): Array<ReviewComment> => {
    const stepComments = procedureUtil.getCommentsByReferenceId(
      stepId,
      comments
    );

    // Reviews show all comments that do not come from a run
    return stepComments.filter(
      (comment) => !(comment as DraftRedlineComment).source_run_id
    ) as Array<ReviewComment>;
  },

  getReviewerLabel: (reviewerId: ReviewerId): string => {
    return reviewerId.value;
  },

  getReviewerSelectOptions: (
    reviewerIds: Array<ReviewerId> = []
  ): Array<{ label: string; type: string; value: string }> => {
    return reviewerIds.map((reviewerId) => ({
      ...reviewerId,
      label: reviewerId.value,
    }));
  },

  // Filter out any pending reviews that belong to archived procedures
  getLiveReviews: (
    reviews: Array<ProcedureMetadata>
  ): Array<ProcedureMetadata> => {
    return reviews.filter((review) => review && !review.archived);
  },

  doesReviewerMatchUserIdOrOperatorRoles: (
    reviewer: Reviewer,
    userId: string,
    operatorRoles: Array<string> = []
  ): boolean => {
    return reviewer.reviewer_ids.some(
      (reviewerId) =>
        reviewerId.value === userId || operatorRoles.includes(reviewerId.value)
    );
  },

  isReviewerGroupPendingReview: (
    reviewerGroup: ReviewerGroup,
    userId: string,
    operatorRoles: Array<string> = []
  ): boolean => {
    return reviewerGroup.reviewers.some((reviewer) => {
      return (
        !getHasReviewerApproved(reviewer, reviewerGroup.actions) &&
        reviewUtil.doesReviewerMatchUserIdOrOperatorRoles(
          reviewer,
          userId,
          operatorRoles
        )
      );
    });
  },

  isProcedurePendingReview: (
    procedure: Procedure | ProcedureMetadata,
    userId: string,
    operatorRoles: Array<string> = []
  ): boolean | undefined => {
    if (procedure.state !== PROCEDURE_STATE_IN_REVIEW) {
      return false;
    }

    return procedure.reviewer_groups?.some((reviewerGroup) => {
      return reviewUtil.isReviewerGroupPendingReview(
        reviewerGroup,
        userId,
        operatorRoles
      );
    });
  },

  // Returns a list of procedures that are pending review for the given user.
  getPendingReviews: (
    proceduresMetadata: Array<ProcedureMetadata>,
    userId: string,
    operatorRoles: Array<string>
  ): Array<ProcedureMetadata> => {
    const reviews = proceduresMetadata
      .filter((procedure) => {
        return reviewUtil.isProcedurePendingReview(
          procedure,
          userId,
          operatorRoles
        );
      })
      .sort((a, b) => -1 * a.editedAt.localeCompare(b.editedAt));

    // Filter out archived procedures.
    return reviewUtil.getLiveReviews(reviews);
  },

  sortReviewActions: (reviewable?: Reviewable): Array<ReviewerAction> => {
    if (!reviewable?.reviewer_groups) {
      return [];
    }
    const allActions: Array<ReviewerAction> = [];
    for (const group of reviewable.reviewer_groups) {
      allActions.push(...group.actions);
    }
    return allActions.sort(compareReviewActions);
  },
};

export default reviewUtil;

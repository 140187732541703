import { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import externalDataUtil from '../lib/externalDataUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { fetchProcedureById, selectProcedureById, syncProcedureData } from '../contexts/proceduresSlice';
import useProfile from '../hooks/useProfile';
import apm from '../lib/apm';

const useProcedureObserver = ({ id }) => {
  const { isOfflineProcedureDataEnabled } = useProfile();
  const isMounted = useRef(true);
  const { services, currentTeamId } = useDatabaseServices();
  const [loading, setLoading] = useState(true);
  const procedure = useSelector((state) => selectProcedureById(state, currentTeamId, id), isEqual);
  const [externalItems, setExternalItems] = useState(null);
  const dispatch = useDispatch();

  // Flag for detecting when component is unmounted.
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  /**
   * Updated version of procedure with any "dynamic" data checks. Currently
   * used to fetch external data items when viewing the procedure.
   *
   * If dynamic data fails to load, we fall back to using the original procedure.
   */
  const dynamic = useMemo(() => {
    if (!procedure || !externalItems) {
      return procedure;
    }
    return externalDataUtil.updateProcedureWithItems(procedure, externalItems);
  }, [procedure, externalItems]);

  // Load procedure and observe changes.
  useEffect(() => {
    if (!id) {
      return;
    }

    // Fetch dynamic procedure data, currently external data items.
    const loadExternalItems = async (procedure) => {
      try {
        const externalItems = await services.externalData.getAllExternalItems(procedure);
        setExternalItems(externalItems);
      } catch {
        // Ignore errors and fall back to using procedure without dynamic data.
      }
    };

    // Procedure document change handler.
    const refreshProcedure = async () => {
      if (!isMounted.current) {
        return;
      }

      try {
        const { teamId, procedure } = await dispatch(
          fetchProcedureById({
            services,
            procedureId: id,
          })
        ).unwrap();

        await loadExternalItems(procedure);

        if (isOfflineProcedureDataEnabled) {
          dispatch(syncProcedureData(teamId, procedure));
        }
      } catch {
        // Ignore errors, we may be offline.
      } finally {
        setLoading(false);
      }
    };
    const procedureObserver = services.procedures.onProcedureChanged(id, refreshProcedure);

    // Call refreshProcedures to fetch the procedures the first time.
    refreshProcedure().catch((err) => apm.captureError(err));

    return () => {
      procedureObserver.cancel();
    };
  }, [id, services, currentTeamId, dispatch, isOfflineProcedureDataEnabled]);

  return {
    procedure: dynamic,
    loading,
  };
};

export default useProcedureObserver;

import { retryDelay } from '../api/lib/retry';
import { OfflineAction } from '@redux-offline/redux-offline/lib/types';

/**
 * Custom retry handler for redux-offline. Uses general retry with exponential
 * backoff up to a max backoff period.
 */
export const retry = (action: OfflineAction, retries: number): number =>
  retryDelay(retries);

/**
 * Custom discard handler for redux-offline.  Discards any client errors (4xx)
 * since retrying will have no effect.  Also includes 500 responses since our
 * backend tends to (incorrectly) return 500s due to improper exception handling.
 * All other errors will be retried.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const discard = (error): boolean => {
  const { request, response } = error;
  if (!request) throw error; // There was an error creating the request
  if (!response) return false; // There was no response
  return 400 <= response.status && response.status <= 500;
};

/*
 * If we have more than 2 items in the ofline outbox, we are offline or on a very
 * slow connection, so give that indication to listeners.  Using a primitive
 * so reference comparison is accurate and the selector doesn't force rerender
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const hasSeveralActions = (state) => {
  if (!state?.offline) {
    return false;
  }
  return state.offline.outbox.length > 2;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isOnline = (state) => {
  if (!state?.offline) {
    return false;
  }
  return state.offline.online;
};

// Selector to obtain useful offline state.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const selectOfflineInfo = (state) => {
  // Check if offline is disabled, likely for testing.
  if (!state?.offline) {
    return {};
  }
  return {
    attempt: state.offline.retryCount + 1,
    busy: state.offline.busy,
    retryScheduled: state.offline.retryScheduled,
    hasActions: state.offline.outbox.length > 0,
    online: state.offline.online,
  };
};

import { init } from '@elastic/apm-rum';
import {
  APM_ACTIVE,
  APM_ENVIRONMENT,
  APM_HOST,
  APM_SERVICE,
  APM_LOG_LEVEL,
  RELEASE_VERSION,
} from '../config';

const apm = init({
  active: APM_ACTIVE,
  serviceName: APM_SERVICE,
  serverUrl: APM_HOST,
  serviceVersion: RELEASE_VERSION,
  logLevel: APM_LOG_LEVEL,
  environment: APM_ENVIRONMENT,
});

export default apm;

import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction, useMemo } from 'react';
import { Redline } from 'shared/lib/types/views/redlines';

// Define the correct type for the context
interface RedlineModalContextType {
  showRedlineModal: boolean;
  setShowRedlineModal: Dispatch<SetStateAction<boolean>>;
  detachedRedlines: Array<Redline>;
  detachedRedlineComments: Array<Redline>;
}

// Create a context with the correct type
const RedlineModalContext = createContext<RedlineModalContextType>({
  showRedlineModal: false,
  setShowRedlineModal: () => {
    throw new Error('setShowRedlineModal is not initialized');
  },
  detachedRedlines: [],
  detachedRedlineComments: [],
});

// Custom hook to use the modal context
const useRedlineModalContext = () => useContext(RedlineModalContext);

const RedlineModalProvider = ({
  children,
  detachedNonCommentRedlines,
  detachedRedlineComments,
}: {
  children: ReactNode;
  detachedNonCommentRedlines: Array<Redline>;
  detachedRedlineComments: Array<Redline>;
}) => {
  const [showRedlineModal, setShowRedlineModal] = useState<boolean>(false);

  const detachedRedlines = useMemo(
    () => [...(detachedNonCommentRedlines ?? []), ...(detachedRedlineComments ?? [])],
    [detachedNonCommentRedlines, detachedRedlineComments]
  );

  return (
    <RedlineModalContext.Provider
      value={{
        showRedlineModal,
        setShowRedlineModal,
        detachedRedlines,
        detachedRedlineComments,
      }}
    >
      {children}
    </RedlineModalContext.Provider>
  );
};

export { RedlineModalProvider, useRedlineModalContext };

import { Duration } from 'luxon';

const durationUtil = {
  isoDurationToHHMMSS: (isoDuration: string): string => {
    if (!isoDuration) return ''; // Return empty string if no duration is provided

    const duration = Duration.fromISO(isoDuration);
    if (!duration.isValid) return ''; // Return empty string if duration is not valid

    // Extracting hours, minutes, and seconds from the duration
    const hours = duration.hours.toString().padStart(2, '0');
    const minutes = duration.minutes.toString().padStart(2, '0');
    const seconds = duration.seconds.toString().padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
  },
};

export default durationUtil;

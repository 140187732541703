import superlogin from '../../api/superlogin';
import { API_URL } from '../../config';
import {
  CustomDetail,
  InsertCustomDetail,
} from 'shared/lib/types/postgres/util';

class RiskDetailsService {
  teamId: string;
  restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    const baseUrl = `${API_URL}/teams/${this.teamId}`;
    this.restUrl = `${baseUrl}/risks/details`;
  }
  async listCustomDetails(): Promise<Array<CustomDetail>> {
    const response = await superlogin.getHttp().get(this.restUrl);
    return response.data.details;
  }

  async createCustomDetail(
    customDetail: InsertCustomDetail
  ): Promise<CustomDetail> {
    const response = await superlogin
      .getHttp()
      .post(this.restUrl, customDetail);
    return response.data;
  }

  async updateCustomDetail(customDetail: CustomDetail): Promise<CustomDetail> {
    const { id: customDetailId, ...toUpdate } = customDetail;
    const url = `${this.restUrl}/${customDetailId}`;
    const response = await superlogin.getHttp().patch(url, toUpdate);
    return response.data;
  }

  async deleteCustomDetail(customDetailId: number): Promise<void> {
    const url = `${this.restUrl}/${customDetailId}`;
    await superlogin.getHttp().delete(url);
  }
}

export default RiskDetailsService;

import {
  TraceViewEmitToolCheckInValue,
  TraceViewEmitToolCheckOutValue,
  TraceViewEmitToolUsageValue,
} from '../../../couch/manufacturing';
import { CreatedAt, CreatedUpdatedAt, Id } from '../../../postgres/util';
import {
  CreatedAt as ClientCreatedAt,
  CreatedUpdatedAt as ClientCreatedUpdatedAt,
} from '../../util';
import { CreateTool, CreateToolInstance, CreateAttachment } from './requests';

export const MAX_FIELD_LENGTHS = {
  tool_number: 255,
  tool_name: 255,
  tool_description: 4096,
  tool_instance_serial_number: 255,
  tool_instance_notes: 4096,
  tool_instance_last_maintained_by: 255, // see access_control.users.id
};

/**
 * Represents a tool in the ether of Plato's forms.
 * It is the type returned by the GET /tools and GET /tools/:id endpoints,
 * and received after string timestamp conversion back to Date.
 */
export type Tool = Id &
  CreateTool & {
    revision_ordinal: number;
    usage_type_ids: Array<number>;
  } & CreatedAt;

/**
 * Represents a tool received by a call to GET /tools and GET /tools/:id.
 * Date timestamps become strings. Other fields are unchanged.
 */
export type ClientTool = Omit<Tool, keyof CreatedAt> & ClientCreatedAt;

/**
 * Represents a tool instance in the ether of Plato's forms.
 * It is the type returned by the GET /tools/instances endpoint,
 * and received after string timestamp conversion back to Date.
 */
export type ToolInstance = Id &
  Omit<CreateToolInstance, 'attachments'> & {
    checked_out?: ToolInstanceCheckOut;
    check_in_outs: Array<ToolInstanceCheckInOut>;
    usage: Array<ToolInstanceUsage>;
    status: ToolInstanceStatus;
    attachments: Array<Attachment>;
  } & CreatedUpdatedAt;

export type ToolInstanceCheckInOut =
  | TraceViewEmitToolCheckInValue
  | TraceViewEmitToolCheckOutValue;
export type ToolInstanceCheckOut = TraceViewEmitToolCheckOutValue;
export type ToolInstanceUsage = TraceViewEmitToolUsageValue;
export type ToolInstanceInRun =
  | TraceViewEmitToolCheckInValue
  | TraceViewEmitToolCheckOutValue
  | TraceViewEmitToolUsageValue;

export enum ToolInstanceStatus {
  Available = 'available',
  Unavailable = 'unavailable',
}
export type Attachment = CreateAttachment & {
  name: string;
  content_type: string;
};

/**
 * Represents a tool received by a call to GET /tools/instances.
 * Date timestamps become strings. Other fields are unchanged.
 */
export type ClientToolInstance = Omit<
  ToolInstance,
  keyof CreatedUpdatedAt | 'last_maintained_at' | 'usage'
> &
  ClientCreatedUpdatedAt & {
    last_maintained_at?: string;
    usage: Array<ToolInstanceUsage>;
    status?: ToolInstanceStatus;
  };

export type ToolUsageType = Id & {
  tool_id: number;
  usage_type_id: number;
};

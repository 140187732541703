import { PERM } from '../../lib/auth';
import { useAuth } from '../../contexts/AuthContext';

type StorageAuth = {
  hasEditPermission: boolean;
};

const useStorageAuth = (): StorageAuth => {
  const { auth } = useAuth();
  const hasEditPermission = auth.hasPermission(PERM.STORAGE_EDIT, null);

  return { hasEditPermission };
};

export default useStorageAuth;

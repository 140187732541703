import { Reading, TelemetryParam } from 'shared/lib/types/telemetry';
import { FieldInputValueDataType } from '../lib/types';

type SimulatedField = {
  type: FieldInputValueDataType;
  bits: number;
  states?: Record<number, string>;
  description: string;
};

export const REFRESH_INTERVAL_MS = 10000;

export const isSimulatedFieldNumberType = (
  simulatedField: SimulatedField
): boolean => {
  return simulatedField.type !== 'text';
};

const STALE_AFTER_MS = 4000;

class Simulator {
  state: Record<string, Reading>;
  identifiers: Array<TelemetryParam>;

  constructor() {
    this.state = {};
  }

  _setValue(name: string, value: number | string): void {
    this.state[name].value = value;
    this.state[name].recorded_at = new Date().toISOString();
  }

  _simulateNumber(name: string, min: number, max: number): void {
    const current = (this.state[name].value ?? min) as number;
    if (current === max) {
      this._setValue(name, min);
    } else {
      this._setValue(name, current + 1);
    }
  }

  _simulateFloat(name: string): void {
    this._setValue(name, Math.random()); // nosemgrep: javascript-crypto-rule-node_insecure_random_generator
  }

  _simulateString(name: string): void {
    const current = this.state[name].value as string;
    if (current === 'STR1') {
      this._setValue(name, 'STR2');
    } else if (current === 'STR2') {
      this._setValue(name, 'STR3');
    } else {
      this._setValue(name, 'STR1');
    }
  }

  _updateState(name: string): void {
    switch (name) {
      case 'sim.node1.flag':
        this._simulateNumber(name, 0, 1);
        break;
      case 'sim.node1.status':
        this._simulateNumber(name, 0, 2);
        break;
      case 'sim.node1.float':
        this._simulateFloat(name);
        break;
      case 'sim.node1.int':
        this._simulateNumber(name, -5, 5);
        break;
      case 'sim.node1.uint':
        this._simulateNumber(name, 0, 5);
        break;
      case 'sim.node1.string':
        this._simulateString(name);
        break;
      default:
        break;
    }
  }

  setParameters(identifiers: Array<TelemetryParam>): void {
    this.state = {};
    this.identifiers = identifiers;
    identifiers.forEach((identifier) => {
      this.state[identifier.name] = {
        stream_id: identifier.name,
        name: identifier.name,
        value: undefined,
        recorded_at: undefined,
        stale_after_ms: STALE_AFTER_MS,
      };
    });
  }

  getSample(identifier: TelemetryParam): Promise<Reading> {
    const name = identifier.name;
    this._updateState(name);
    return new Promise<Reading>((resolve) => {
      resolve(this.state[name]);
    });
  }

  getSamples(): Promise<Record<string, Reading>> {
    this.identifiers.forEach((identifier) => {
      this._updateState(identifier.name);
    });

    return new Promise<Record<string, Reading>>((resolve) => {
      resolve(this.state);
    });
  }
}

export default Simulator;

import sharedDiffUtil from 'shared/lib/diffUtil';
import React from 'react';
import { DiffField, TableColumnDiffElement, TableSignoffDiffElement } from 'shared/lib/types/views/procedures';
import { isEmpty } from 'lodash';
import { SIGNOFF_COLUMN_TYPE } from './TableInputConstants';
import { useSettings } from '../../contexts/SettingsContext';
import tableInputUtil from '../../lib/tableInputUtil';
import ProcedureDiffCompareText from '../ProcedureDiffCompareText';

interface ColumnTypesDiffDetailsProps {
  columnIndex: number;
  column: TableColumnDiffElement;
  cells: Array<Array<DiffField<string | null> | TableSignoffDiffElement>>;
}

const ColumnTypesDiffDetails = ({ columnIndex, column, cells }: ColumnTypesDiffDetailsProps) => {
  const { getListMetadata } = useSettings();

  const getColumnTypeText = (column, version) => {
    const columnType = sharedDiffUtil.getDiffValue(column, 'column_type', version);
    return columnType === 'input'
      ? `${sharedDiffUtil.getDiffValue(column, 'input_type', version)} ${columnType}`
      : columnType;
  };

  const oldTextColumnValues =
    sharedDiffUtil.getDiffValue(column, 'column_type', 'old') === 'text'
      ? cells
          .map((row) => {
            return Array.isArray(row[columnIndex])
              ? row[columnIndex]
              : sharedDiffUtil.getDiffValue<string>({ value: row[columnIndex] }, 'value', 'old');
          })
          .filter((text) => text && typeof text === 'string' && !isEmpty(text.trim()))
          .join(', ')
      : '';

  return (
    <span className="break-normal">
      {sharedDiffUtil.isChanged(column, 'column_type') && (
        <div>
          <span>Column type changed from </span>
          <span className="text-red-600 bg-red-100">{getColumnTypeText(column, 'old')}</span>
          <span> to </span>
          <span className="text-emerald-800 bg-emerald-100">{getColumnTypeText(column, 'new')}</span>
        </div>
      )}
      {sharedDiffUtil.isChanged(column, 'column_type') && !isEmpty(oldTextColumnValues) && (
        <div>
          <span>Text column values removed: </span>
          <span className="text-red-600 bg-red-100">{oldTextColumnValues}</span>
        </div>
      )}
      {!sharedDiffUtil.isChanged(column, 'column_type') && sharedDiffUtil.isChanged(column, 'input_type') && (
        <div>
          <span>Column input type changed from </span>
          <span className="text-red-600 bg-red-100">{sharedDiffUtil.getDiffValue(column, 'input_type', 'old')}</span>
          <span> to </span>
          <span className="text-emerald-800 bg-emerald-100">
            {sharedDiffUtil.getDiffValue(column, 'input_type', 'new')}
          </span>
        </div>
      )}
      {sharedDiffUtil.isChanged(column, 'units') &&
        sharedDiffUtil.getDiffValue(column, 'input_type', 'new') === 'checkbox' && (
          <div>
            <span>Units have been removed: </span>
            <span className="text-red-600 bg-red-100">{sharedDiffUtil.getDiffValue(column, 'units', 'old')}</span>
          </div>
        )}
      {sharedDiffUtil.isChanged(column, 'list') &&
        getListMetadata(sharedDiffUtil.getDiffValue(column, 'list', 'old'))?.name &&
        !(
          sharedDiffUtil.getDiffValue(column, 'column_type', 'new') === 'input' &&
          sharedDiffUtil.getDiffValue(column, 'input_type', 'new') === 'list'
        ) && (
          <div>
            <span>List was removed: </span>
            <span className="text-red-600 bg-red-100">
              {getListMetadata(sharedDiffUtil.getDiffValue(column, 'list', 'old'))?.name}
            </span>
          </div>
        )}
      {sharedDiffUtil.getDiffValue(column, 'column_type', 'new') === SIGNOFF_COLUMN_TYPE &&
        sharedDiffUtil.isBooleanChanged(column, 'allow_input_after_signoff') && (
          <div>
            <span>Signoff settings were changed: </span>
            <div className="flex flex-row gap-x-1">
              {tableInputUtil
                .getSignoffSettingsDescriptionSegments(
                  sharedDiffUtil.getDiffValue(column, 'allow_input_after_signoff', 'new')
                )
                .map((segment, index) => {
                  if (index !== 1) {
                    return segment;
                  }

                  const newCanOrCannot = segment;
                  return (
                    <ProcedureDiffCompareText
                      diffValue={{
                        __old: tableInputUtil.getSignoffSettingsDescriptionSegments(
                          sharedDiffUtil.getDiffValue(column, 'allow_input_after_signoff', 'old')
                        )[1],
                        __new: newCanOrCannot,
                      }}
                    />
                  );
                })}
            </div>
          </div>
        )}
    </span>
  );
};

export default ColumnTypesDiffDetails;

import React, { useMemo } from 'react';
import { Duration } from 'luxon';

const OffsetInput = ({ form }) => {
  const handleChange = (event) => {
    const duration = Duration.fromISOTime(event.target.value);
    form.setFieldValue('predecessor_offset', duration.isValid ? duration : event.target.value);
  };

  const value = useMemo(() => {
    const duration = Duration.fromISO(form.values.predecessor_offset);
    if (duration.isValid) {
      return duration.shiftTo('hours', 'minutes', 'seconds', 'milliseconds').toFormat('hh:mm:ss');
    }
    return form.values.predecessor_offset ?? '';
  }, [form.values.predecessor_offset]);

  return (
    <input
      type="text"
      aria-label="Relative event offset"
      onBlur={handleChange}
      onChange={(e) => form.setFieldValue('predecessor_offset', e.target.value)}
      placeholder="HH:MM:SS"
      value={value}
      className="border-1 border-gray-300 p-2 rounded text-sm h-[38px] w-28"
    />
  );
};
export default OffsetInput;

import LabelDisplay from './LabelDisplay';
import { useMemo } from 'react';
import { selectValueSelectedStyles } from '../../../lib/styles';
import Select from 'react-select';
import NoValueRecorded from './NoValueRecordedDisplay';
import { isEmptyValue } from 'shared/lib/text';
import RecordedValueDisplay from './RecordedValueDisplay';
import {
  FieldInputCustomListBlock,
  FieldInputExternalDataBlock,
  FieldInputSettingsListBlock,
  FieldInputExternalSearchBlock,
  RunFieldInputCustomListBlock,
  RunFieldInputExternalDataBlock,
  RunFieldInputSettingsListBlock,
  RunFieldInputExternalSearchBlock,
} from 'shared/lib/types/views/procedures';
import ReferenceTokenTooltip from './ReferenceTokenTooltip';
import sharedDiffUtil from 'shared/lib/diffUtil';

type RunSelectDisplayBlock =
  | RunFieldInputCustomListBlock
  | RunFieldInputExternalDataBlock
  | RunFieldInputSettingsListBlock
  | RunFieldInputExternalSearchBlock;

interface SelectDisplayProps {
  block:
    | FieldInputCustomListBlock
    | FieldInputExternalDataBlock
    | FieldInputSettingsListBlock
    | FieldInputExternalSearchBlock;
  values?: string[];
  optionSelectedRecorded?: { label: string };
  referenceId?: string;
  referenceSubtype?: string;
  referenceFieldIndex?: number;
}

const SelectDisplay = ({
  block,
  values,
  optionSelectedRecorded,
  referenceId,
  referenceSubtype,
  referenceFieldIndex,
}: SelectDisplayProps) => {
  const valueSelected = useMemo(() => {
    if (!(block as RunSelectDisplayBlock).recorded?.value) {
      return null;
    }
    if (optionSelectedRecorded) {
      return optionSelectedRecorded;
    }
    const selectOptions = !Array.isArray(values)
      ? []
      : values.map((value) => ({
          id: value,
          label: value,
        }));
    return selectOptions.find((option) => option.id === (block as RunSelectDisplayBlock).recorded?.value);
  }, [block, optionSelectedRecorded, values]);

  const recordedValue = useMemo(() => valueSelected?.label, [valueSelected?.label]);
  const blockName = sharedDiffUtil.getDiffValue<string>(block, 'name', 'new');
  const displayName = referenceSubtype ? `${blockName}. ${referenceSubtype}` : blockName;

  return (
    <div className="flex flex-row space-x-2">
      <LabelDisplay text={displayName} />
      <div className="group">
        {referenceId && (
          <>
            <ReferenceTokenTooltip referenceId={referenceId} referenceFieldIndex={referenceFieldIndex} />
            {(!recordedValue || isEmptyValue(recordedValue)) && <NoValueRecorded />}
            {recordedValue && !isEmptyValue(recordedValue) && <RecordedValueDisplay value={recordedValue} />}
          </>
        )}
      </div>

      {!referenceId && (
        <Select
          placeholder="No value recorded"
          classNamePrefix="react-select"
          styles={selectValueSelectedStyles}
          value={valueSelected}
          aria-label="Field Input Select"
          isDisabled={true}
        />
      )}
    </div>
  );
};

export default SelectDisplay;

import { useState, useEffect, HTMLAttributes } from 'react';

type RunControlledInputProps = {
  recorded: string;
  onRecordValue: (value: string) => void;
  type?: 'text' | 'number';
  onChange?: (value: string) => void;
  disabled?: boolean;
  min?: number;
  max?: number;
} & HTMLAttributes<HTMLInputElement>;

/*
 * Useful primarily for substep sync within runs - this controlled input takes
 * input values from either the user or the recorded value from other
 * users (incoming substep sync values).  onRecordValue() will only be called when
 * the user blurs the input to avoid dispatching unnecessary updates to substep sync.
 */
const RunControlledInput = ({
  recorded = '',
  onRecordValue,
  type,
  onChange,
  disabled,
  min,
  max,
  ...rest
}: RunControlledInputProps) => {
  const [value, setValue] = useState<string>(recorded);

  // Incoming recorded values (e.g. from substep sync) will override the input value
  useEffect(() => {
    setValue(recorded);
  }, [recorded]);

  const handleOnChange = (e) => {
    let value = e.target.value;
    if (min !== undefined) {
      value = String(Math.max(parseFloat(value), min));
    }
    if (max !== undefined) {
      value = String(Math.min(parseFloat(value), max));
    }
    setValue(value);
    onChange?.(value);
  };

  return (
    <input
      type={type}
      onChange={handleOnChange}
      onBlur={(e) => onRecordValue(e.target.value)}
      value={value}
      disabled={disabled}
      min={min}
      max={max}
      {...rest}
    />
  );
};

export default RunControlledInput;

import {
  Actions,
  ContentMenuItem,
  addGroupings,
  Shortcuts,
} from './addContentTypes';

export interface GetSectionAddContentOptions {
  hasSectionHeader: boolean;
  canAddSection?: boolean;
  canInsertSnippet?: boolean;
  allStepsAreExpanded: boolean;
  isSectionCollapsed: boolean;
  canDeleteSection?: boolean;
  canCopySection?: boolean;
  canCutSection?: boolean;
  canPasteSection?: boolean;
  canPasteStep?: boolean;
  canSaveAsSnippet?: boolean;
  isSnippet?: boolean;
  hasDependencies?: boolean;
}

// Required params are not in the default because the component that calls this component has to set them.
const defaultOptions: Omit<
  GetSectionAddContentOptions,
  'hasSectionHeader' | 'allStepsAreExpanded' | 'isSectionCollapsed'
> = {
  canAddSection: true,
  canInsertSnippet: true,
  canDeleteSection: true,
  canCopySection: true,
  canCutSection: true,
  canPasteSection: false,
  canPasteStep: false,
  canSaveAsSnippet: true,
  isSnippet: false,
  hasDependencies: false,
};

const getSectionAddContentItems = (
  options: GetSectionAddContentOptions
): Array<ContentMenuItem> => {
  const buttonShowsCollapse =
    options.allStepsAreExpanded && !options.isSectionCollapsed;
  const opts = {
    ...defaultOptions,
    ...options,
  };
  return addGroupings([
    {
      group: 'Add Content',
      action: Actions.AddSection,
      label: 'Add Section',
      icon: 'square',
      description: 'Sections are collections of steps',
      hidden: !opts.canAddSection,
    },
    {
      group: 'Add Content',
      action: Actions.AddSectionDependencies,
      label: 'Add Section Dependencies',
      icon: 'diagram-project',
      description: 'Add dependencies to the entire section',
      hidden: opts.hasDependencies,
    },
    {
      group: 'Add Content',
      action: Actions.AddSectionHeader,
      label: 'Add Section Header',
      icon: 'square',
      description: 'Add a header to the section',
      hidden: opts.hasSectionHeader || opts.isSnippet,
    },
    {
      group: 'Add Content',
      action: Actions.InsertSnippet,
      label: 'Insert Snippet',
      icon: 'puzzle-piece',
      description: 'Insert a section snippet',
      hidden: !opts.canInsertSnippet,
    },
    {
      group: 'Actions',
      action: Actions.CopySection,
      label: 'Copy Section',
      icon: 'clone',
      description: 'Copy this section to clipboard',
      hidden: !opts.canCopySection,
      keyboardShortcut: Shortcuts.copy,
    },
    {
      group: 'Actions',
      action: Actions.CutSection,
      label: 'Cut Section',
      icon: 'scissors',
      description: 'Move this section to clipboard',
      hidden: !opts.canCutSection,
      keyboardShortcut: Shortcuts.cut,
    },
    {
      group: 'Actions',
      action: Actions.PasteSection,
      label: 'Paste Section',
      icon: 'clone',
      description: 'Paste section from clipboard',
      hidden: !opts.canPasteSection,
      keyboardShortcut: Shortcuts.paste,
    },
    {
      group: 'Actions',
      action: Actions.PasteStep,
      label: 'Paste Step',
      icon: 'clone',
      description: 'Paste step from clipboard',
      hidden: !opts.canPasteStep,
      keyboardShortcut: Shortcuts.paste,
    },
    {
      group: 'Actions',
      action: Actions.SaveAsSnippet,
      label: 'Save as Snippet',
      icon: 'puzzle-piece',
      description: 'Turn this section into a snippet',
      hidden: !opts.canSaveAsSnippet || opts.isSnippet,
    },
    {
      group: 'Actions',
      action: Actions.DeleteSection,
      label: 'Delete Section',
      icon: 'circle-xmark',
      description: 'Delete this section',
      hidden: !opts.canDeleteSection,
      keyboardShortcut: Shortcuts.remove,
    },
    {
      group: 'Actions',
      action: Actions.DeleteSectionHeader,
      label: 'Delete Section Header',
      icon: 'circle-xmark',
      description: 'Delete the section header',
      hidden: !opts.hasSectionHeader || opts.isSnippet,
      keyboardShortcut: Shortcuts.remove,
    },
    {
      group: 'Actions',
      action: buttonShowsCollapse
        ? Actions.CollapseAllStepsInSection
        : Actions.ExpandAllStepsInSection,
      label: `${
        buttonShowsCollapse ? 'Collapse' : 'Expand'
      } All Steps in Section`,
      icon: buttonShowsCollapse ? 'compress-alt' : 'expand-alt',
    },
  ]);
};

export default getSectionAddContentItems;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../../../elements/Tooltip';

const ViewModeTab = ({ viewMode, icon, title, currentViewMode, setViewMode }) => {
  return (
    <Tooltip content={title} visible={currentViewMode !== viewMode}>
      <button
        className="bg-slate-300 text-slate-700 rounded items-center px-2 py-0.5 text-sm bg-transparent border border-transparent hover:text-gray-900 hover:bg-slate-300 disabled:bg-slate-300 disabled:border-slate-300"
        disabled={currentViewMode === viewMode}
        onClick={() => setViewMode(viewMode)}
        aria-label={`Toggle ${title}`}
      >
        <FontAwesomeIcon icon={icon} />
      </button>
    </Tooltip>
  );
};

export default ViewModeTab;

import BatchStepHeaderTab, { BatchStepHeaderTabConfig } from './BatchStepHeaderTab';

export type BatchStepHeaderProps = {
  tabs: BatchStepHeaderTabConfig[];
  selectedTabLabel: string;
  onSelectTab: (label: string) => void;
};

const BatchStepHeader = ({ tabs, selectedTabLabel, onSelectTab }: BatchStepHeaderProps) => (
  <div className="w-full bg-gray-100 border-2 border-b-0 border-slate-300 shadow z-0 flex flex-row flex-wrap items-center">
    {tabs.map((tabConfig) => (
      <BatchStepHeaderTab
        key={tabConfig.label}
        config={tabConfig}
        isActive={tabConfig.label === selectedTabLabel}
        onClick={() => onSelectTab(tabConfig.label)}
      />
    ))}
  </div>
);

export default BatchStepHeader;

import React from 'react';
import PropTypes from 'prop-types';
import numericalFieldHandler from '../../../utils/numericalFieldHandler';

const RepeatMonthlyOn = ({ id, mode, on, hasMoreModes, handleChange }) => {
  const isActive = mode === 'on';

  return (
    <div className={`flex flex-row items-center space-x-2 ${!isActive && 'opacity-50'}`}>
      {hasMoreModes && (
        <input
          id={id}
          type="radio"
          name="repeat.monthly.mode"
          aria-label="Repeat monthly on"
          value="on"
          checked={isActive}
          onChange={handleChange}
        />
      )}

      <span>on day</span>

      <select
        id={`${id}-day`}
        name="repeat.monthly.on.day"
        aria-label="Repeat monthly on a day"
        className="rounded text-sm border-gray-300"
        value={on.day}
        disabled={!isActive}
        onChange={numericalFieldHandler(handleChange)}
      >
        {[...new Array(31)].map((day, i) => (
          <option key={i} value={i + 1}>
            {i + 1}
          </option>
        ))}
      </select>
    </div>
  );
};
RepeatMonthlyOn.propTypes = {
  id: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['on', 'on the']).isRequired,
  on: PropTypes.shape({
    day: PropTypes.number.isRequired,
  }).isRequired,
  hasMoreModes: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RepeatMonthlyOn;

import React from 'react';
import runUtil from '../../lib/runUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Section, Step } from 'shared/lib/types/views/procedures';

interface StepNameSpanProps {
  currentSection: Section;
  currentStep: Step;
  currentStepIndex: number;
}

const StepNameSpan = React.memo(({ currentSection, currentStep, currentStepIndex }: StepNameSpanProps) => {
  const stepRepeatKey = runUtil.displayRepeatKey(currentSection.steps, currentStepIndex);
  const repeatSubtitle = runUtil.isStepRepeat(currentStep) ? `(repeat ${stepRepeatKey})` : '';
  return (
    <>
      <span className="truncate" title={`${currentStep.name} ${repeatSubtitle}`}>
        {`${currentStep.name}`}
      </span>
      <span className="w-1" />
      {runUtil.isStepRepeat(currentStep) && (
        <span className="whitespace-nowrap" aria-label={`repeat ${stepRepeatKey}`}>
          {'  '}
          <FontAwesomeIcon icon="redo" className="text-xs" />
          {` ${stepRepeatKey}`}
        </span>
      )}
    </>
  );
});

export default StepNameSpan;

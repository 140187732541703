import { ReactNode } from 'react';
import { Maybe } from 'yup/lib/types';

export interface Column<R> {
  key: string;
  name: string;
  width?: string;
  readonly renderCell?: Maybe<(row: R) => ReactNode>;
}

export interface TableProps<R> {
  columns: ReadonlyArray<Column<R>>;
  rows: ReadonlyArray<R>;
  includeHeader?: boolean;
}

const Table = <R,>(props: TableProps<R>) => {
  const columns = props.columns;
  const rows = props.rows;
  const includeHeader = props.includeHeader || false;

  return (
    <table className="w-full">
      {includeHeader && (
        <thead>
          <tr>
            {columns.map((col, colIndex) => (
              <th key={`header-${colIndex}`} aria-label={`header-${colIndex}`} className="text-left">
                {col.name}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={`data-${rowIndex}`} aria-rowindex={rowIndex} className="border-gray-200 border-b">
            {columns.map((col, colIndex) => (
              <td key={`data-${rowIndex}-${colIndex}`} aria-colindex={colIndex}>
                {col.renderCell ? col.renderCell(row) : row[col.key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;

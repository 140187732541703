import DiffContainer from '../../../components/Diff/DiffContainer';
import AttachmentImage from '../../components/AttachmentImage';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import ReviewPartLabel from './ReviewPartLabel';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import RestrictedInfo, { RESTRICTED_TEXT } from '../RestrictedInfo';

interface ReviewPartBadgeProps {
  part: Part | undefined;
  teamId: string;
  partRestricted?: boolean;
}

const ReviewPartBadge = ({ part, teamId, partRestricted = false }: ReviewPartBadgeProps) => {
  // user doesn't have project permission to view the part
  if (partRestricted) {
    return (
      <div className="flex gap-x-2 text-base">
        <div className="relative w-12 h-12 rounded overflow-hidden">
          <AttachmentImage attachment={undefined} />
        </div>
        <div className="ml-0.5">
          <RestrictedInfo text={RESTRICTED_TEXT} />
        </div>
      </div>
    );
  }

  const attachment = part ? sharedDiffUtil.getDiffValue(part, 'image', 'new') : undefined;
  return (
    <div className="flex gap-x-2 text-base">
      <DiffContainer
        label="Part build"
        diffChangeState={
          sharedDiffUtil.isChanged(part, 'id') ? ARRAY_CHANGE_SYMBOLS.MODIFIED : ARRAY_CHANGE_SYMBOLS.UNCHANGED
        }
        isTextSticky={false}
      >
        <div>
          <div className="relative w-12 h-12 rounded overflow-hidden">
            <AttachmentImage
              // @ts-ignore this should be of type Attachment
              attachment={attachment}
            />
          </div>
        </div>
        <div>
          <ReviewPartLabel part={part} teamId={teamId} />
        </div>
      </DiffContainer>
    </div>
  );
};

export default ReviewPartBadge;

import { useEffect, useState } from 'react';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { StepSnippet } from '../../lib/views/settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { reactSelectStyles } from '../../lib/styles';
import { cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import { TestCaseBlock } from './TestCasesBlock';
import { testingTestCasesPath } from '../../lib/pathUtil';
import AssessmentIndicator from './AssessmentIndicator';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import usePopup from '../../hooks/usePopup';
import HazardPopup from './HazardPopup';

type SnippetOption = {
  value: string;
  label: string;
  snippet: StepSnippet;
};

type SnippetSelectorContent = {
  id: string;
  items: Array<string>;
};

type reorderObject = {
  id: string;
  delta: number;
};

type SnippetSelectorProps = {
  content: SnippetSelectorContent;
  contentErrors?: {
    error: string;
  };
  setFieldValue?: (value: SnippetSelectorContent, action: string, id: string | reorderObject) => void;
  isDraft?: boolean;
};

const SnippetSelector: React.FC<SnippetSelectorProps> = ({
  content,
  contentErrors,
  setFieldValue,
  isDraft = false,
}) => {
  const { services, currentTeamId } = useDatabaseServices();
  const { activeChildId, handlePopupOpen, handlePopupClose } = usePopup();
  const [snippets, setSnippets] = useState<Array<StepSnippet>>([]);
  const [selectedSnippetOption, setSelectedSnippetOption] = useState<SnippetOption | null>(null);
  const [snippetOptions, setSnippetOptions] = useState<Array<SnippetOption>>([]);
  const [addedSnippets, setAddedSnippets] = useState<Array<StepSnippet>>([]);

  useEffect(() => {
    if (!services.settings) {
      return;
    }
    services.settings
      .listSnippets({ isTestSnippet: true })
      .then((snippets) => {
        const filteredSnippets = snippets.filter((snippet: StepSnippet) => snippet.snippet_type === 'step');

        setSnippets(filteredSnippets);
      })
      .catch(() => {
        /* Ignored */
      });
  }, [services.settings]);

  useEffect(() => {
    if (snippets.length === 0) {
      return;
    }

    const existingSnippets = content.items
      .map((snippet_id) => snippets.find((snippet) => snippet.snippet_id === snippet_id))
      .filter((snippet): snippet is StepSnippet => snippet !== undefined);

    setAddedSnippets(existingSnippets);

    const options = snippets
      .filter((snippet) => !content.items.includes(snippet.snippet_id))
      .map((snippet) => ({
        value: snippet.snippet_id,
        label: snippet.name,
        snippet,
      }));

    setSnippetOptions(options);
  }, [snippets, content.items]);

  const onSnippetSelected = (option) => {
    setSelectedSnippetOption(option);
  };

  const onAddSnippet = () => {
    const selectedSnippet = snippets.find((snippet) => snippet.snippet_id === selectedSnippetOption?.value);
    if (!selectedSnippet || !selectedSnippetOption) return;

    const newValue = {
      ...content,
      items: [...content.items, selectedSnippetOption.snippet.snippet_id],
    };
    if (setFieldValue) {
      setFieldValue(newValue, 'added', selectedSnippet?.snippet_id);
    }
    setSelectedSnippetOption(null);
    setAddedSnippets((prev) => [...prev, selectedSnippet]);
  };

  const onRemoveSnippet = (item) => {
    const updated = cloneDeep(content);
    updated.items = updated.items.filter((id) => id !== item.snippet_id);
    if (setFieldValue) {
      setFieldValue(updated, 'removed', item.snippet_id);
    }
    setAddedSnippets((prev) => prev.filter((prevItem) => prevItem.snippet_id !== item.snippet_id));
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const id = addedSnippets[source.index].snippet_id;
    const reorderedSnippets = [...addedSnippets];
    const [removed] = reorderedSnippets.splice(source.index, 1);
    reorderedSnippets.splice(destination.index, 0, removed);

    const newValue = {
      ...content,
      items: reorderedSnippets.map((s) => s.snippet_id),
    };

    const delta = {
      id,
      delta: destination.index - source.index,
    };

    setAddedSnippets(reorderedSnippets);
    if (setFieldValue) {
      setFieldValue(newValue, 'reorder', delta);
    }
  };

  return (
    <div className="flex flex-col grow p-2.5 pl-5 border-gray-200 border rounded bg-white">
      <span className="field-title">Test Cards</span>
      <table className="w-full border-collapse">
        <thead>
          <tr className="items-center">
            <th className="border border-gray-400 p-1">
              <div className="text-left">
                <span className="field-title">Test Card</span>
              </div>
            </th>
            <th className="border border-gray-400 p-1">
              <div className="text-left">
                <span className="field-title">Test Points</span>
              </div>
            </th>
            <th className="border border-gray-400 p-1">
              <div className="text-left">
                <span className="field-title">Hazard</span>
              </div>
            </th>
            {isDraft && (
              <th className="border border-gray-400 p-1">
                <div className="text-right">
                  <span className="field-title">Actions</span>
                </div>
              </th>
            )}
          </tr>
        </thead>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="snippets">
            {(provided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {!addedSnippets.length && (
                  <tr className="h-10 text-sm text-gray-400 bg-white items-center text-center">
                    <td className="border border-gray-400 p-1" colSpan={4}>
                      No Test Card Added
                    </td>
                  </tr>
                )}
                {addedSnippets.map((snippet, index) => (
                  <Draggable key={snippet._id} draggableId={snippet._id} index={index} isDragDisabled={!isDraft}>
                    {(provided) => (
                      <tr
                        key={snippet._id}
                        className="h-10 text-sm border items-center group bg-white"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <td className="border border-gray-400 p-1">
                          <div className="p-1 relative w-72">
                            {snippet.name}
                            <div className="opacity-0 group-hover:opacity-100" {...provided.dragHandleProps}>
                              {isDraft && (
                                <FontAwesomeIcon
                                  icon="grip-vertical"
                                  className="text-gray-400 opacity-100 absolute -left-5 top-0 bottom-0 m-auto"
                                />
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="border border-gray-400 p-1">
                          {snippet?.step &&
                            snippet?.step.content.map((stepContent: unknown, index: number) => {
                              const testCaseContent = stepContent as TestCaseBlock;
                              if (testCaseContent.type === 'test_cases')
                                return testCaseContent.items.map((item, itemIndex) => (
                                  <div key={`item-${index}-${itemIndex}`}>
                                    <Link
                                      key={`hazard-${itemIndex}-${item}`}
                                      to={`${testingTestCasesPath(currentTeamId)}/${item.id}`}
                                      className="text-sm text-blue-600 hover:underline truncate"
                                    >
                                      {item?.name}
                                    </Link>
                                  </div>
                                ));
                              return null;
                            })}
                        </td>
                        <td className="border border-gray-400 p-1">
                          {snippet?.step &&
                            snippet?.step.content.map((stepContent: unknown, index: number) => {
                              const hazardMap = new Map();
                              const testCaseContent = stepContent as TestCaseBlock;
                              if (testCaseContent.type === 'test_cases')
                                return testCaseContent.items.map((item, itemIndex) => (
                                  <div key={`item-${index}-${itemIndex}`}>
                                    {item?.hazards?.map((hazard, hazardIndex) => {
                                      if (!hazardMap.has(hazard.id)) {
                                        hazardMap.set(hazard.id, true); // Add hazard to map
                                        return (
                                          <div
                                            key={`hazard-div-${itemIndex}-${hazardIndex}`}
                                            className="flex items-center space-x-2"
                                          >
                                            <AssessmentIndicator analysis={hazard} />
                                            <span
                                              key={`hazard-${itemIndex}-${hazardIndex}`}
                                              onClick={(e) => handlePopupOpen(e, hazard.id)}
                                              className="text-sm text-blue-600 hover:underline truncate cursor-pointer"
                                            >
                                              {hazard?.name}
                                            </span>
                                            <HazardPopup
                                              isPopUpShown={activeChildId === hazard.id}
                                              onPopupClose={handlePopupClose}
                                              hazard={hazard}
                                            />
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                  </div>
                                ));
                              return null;
                            })}
                        </td>
                        {isDraft && (
                          <td className="border border-gray-400 p-1">
                            <div className="flex justify-end">
                              <button type="button" onClick={() => onRemoveSnippet(snippet)}>
                                <FontAwesomeIcon className="text-gray-400 hover:text-gray-500 p-1" icon="trash" />
                              </button>
                            </div>
                          </td>
                        )}
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                <tr className="bg-white">
                  <td colSpan={3}>
                    {contentErrors?.error && <div className="text-red-700 text-sm">{contentErrors.error}</div>}
                    {isDraft && (
                      <div className="flex flex-row gap-x-2 my-2 items-center">
                        <div className="w-96">
                          <Select
                            styles={reactSelectStyles}
                            classNamePrefix="react-select"
                            options={snippetOptions}
                            value={selectedSnippetOption}
                            onChange={onSnippetSelected}
                            isSearchable={true}
                            placeholder="Search Test Cards"
                            isClearable={true}
                          />
                        </div>
                        <button
                          type="button"
                          className="btn btn-secondary ml-1"
                          onClick={onAddSnippet}
                          disabled={!selectedSnippetOption}
                        >
                          Add Test Card
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
    </div>
  );
};

export default SnippetSelector;

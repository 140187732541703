import React, { useState, useEffect } from 'react';
import apm from './lib/apm';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './app/store';
import './index.css';
import loadApp from './App';
import reportWebVitals from './reportWebVitals';

loadApp()
  .then((App) => {
    /**
     * Component to delay rendering the app until the redux store is rehydrated.
     *
     * This approach feels a bit awkward but it's what the docs in redux-persist
     * and redux-offline appear to recommend.
     */
    const AppStoreLoader = () => {
      const [storeState, setStoreState] = useState({
        store: null,
        loaded: false,
      });

      useEffect(() => {
        const { store } = configureStore(() => {
          setStoreState(() => ({
            store,
            loaded: true,
          }));
        });
      }, []);

      if (!storeState.loaded) {
        return null;
      }

      return (
        <Provider store={storeState.store}>
          <App />
        </Provider>
      );
    };

    ReactDOM.render(
      <React.StrictMode>
        <AppStoreLoader />
      </React.StrictMode>,
      document.getElementById('root')
    );
  })
  .catch((err) => apm.captureError(err));

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();

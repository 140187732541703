import { useMemo } from 'react';
import Label, { LabelSize } from './Label';
import { capitalizeFirstLetter, isEmptyValue } from 'shared/lib/text';
import { RunState, RunStatus } from 'shared/lib/types/views/procedures';
import { OperationState } from 'shared/lib/types/operations';

interface RunStatusLabelProps {
  statusText: RunState | RunStatus | OperationState;
  onClick?: () => void;
  clickable?: boolean;
  size?: LabelSize;
}

/**
 * Renders a pill for a run or operation status.
 */
const RunStatusLabel = ({ statusText, onClick, clickable, size = 'xs' }: RunStatusLabelProps) => {
  const statusColor = useMemo(() => {
    if (statusText === 'running') {
      return 'bg-blue-200';
    }
    if (statusText === 'paused') {
      return 'bg-yellow-200';
    }
    if (statusText === 'completed') {
      return 'bg-gray-200';
    }
    if (statusText === 'success') {
      return 'bg-emerald-200';
    }
    if (statusText === 'abort') {
      return 'bg-orange-200';
    }
    if (statusText === 'failure') {
      return 'bg-red-200';
    }
    if (statusText === 'planning') {
      return 'bg-orange-200';
    }

    return 'bg-gray-200';
  }, [statusText]);

  const statusLabel = useMemo(
    () => (!isEmptyValue(statusText) && capitalizeFirstLetter(statusText)) || '',
    [statusText]
  );

  if (!statusText) {
    return null;
  }

  return (
    <Label
      text={statusLabel}
      clickable={clickable}
      onClick={onClick}
      color={statusColor}
      size={size}
      verticalPadding={false}
    />
  );
};

export default RunStatusLabel;

import procedureVariableUtil from '../../lib/procedureVariableUtil';
import React, { useMemo } from 'react';
import { V2RunVariable, Variable, VariableDiffElement } from 'shared/lib/types/views/procedures';
import DiffContainer from '../Diff/DiffContainer';
import { useReviewContext } from '../../contexts/ReviewContext';
import useDiff from '../../hooks/useDiff';
import FormRunVariable from '../FormRunVariable';

/**
 * Renders a procedure variable.  Compatible with legacy and V2 variables.
 */
interface FormReviewVariableProps {
  variable: Variable | VariableDiffElement;
  onSaveVariable?: (variable: V2RunVariable) => void;
  isEnabled: boolean;
  onRefChanged: (id: string, element: HTMLElement) => void;
  scrollMarginTopValueRem: number;
}

const FormReviewVariable = ({
  variable,
  isEnabled,
  onRefChanged,
  scrollMarginTopValueRem,
}: FormReviewVariableProps) => {
  const updatedVariable: V2RunVariable = useMemo(() => {
    return procedureVariableUtil.getUpdatedVariable(variable);
  }, [variable]);

  const { onScrollToDiffRefChanged } = useReviewContext();
  const { handleOnScrollToDiffRefChanged } = useDiff({ onScrollToDiffRefChanged });

  return (
    <DiffContainer
      label="Variable"
      diffChangeState={(variable as VariableDiffElement).diff_change_state}
      onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged(updatedVariable.id, element)}
    >
      <FormRunVariable
        variable={variable}
        isEnabled={isEnabled}
        onRefChanged={onRefChanged}
        scrollMarginTopValueRem={scrollMarginTopValueRem}
      ></FormRunVariable>
    </DiffContainer>
  );
};

export default React.memo(FormReviewVariable);

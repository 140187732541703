import React, { useMemo } from 'react';
import { Form, Formik, Field } from 'formik';
import UpdateOnDirty from './UpdateOnDirty';
import useFieldRedlines from '../hooks/useFieldRedlines';
import TextAreaAutoHeight from '../components/TextAreaAutoHeight';
import MenuSubmitSuggestedEdit from './MenuSubmitSuggestedEdit';

/**
 * Component for redlining a step field when running a procedure.
 *
 * @param {Object} props
 * @param {String} props.fieldName - Name of the field.
 * @param {String} props.fieldValue - Value of the field.
 * @param {Array} props.redlines - Array of RedlineField objects.
 * @param {String} props.placeholder - Text rendered when form field is empty.
 * @param {Function} props.validate - Function for validating given field during redlining.
 * @param {Function} props.onDirtyChanged - Function called when the user has made any
 *   redline changes in the form.
 * @param {() => void} props.onCancel - Function callback when a redline
 *   change is cancelled.
 * @param {() => void} props.onSubmit - Function callback when a redline
 *   change is submitted.
 * @param {boolean} [props.runOnly]
 */
const ProcedureFieldEdit = ({
  fieldName,
  fieldValue,
  redlines,
  placeholder,
  validate,
  onDirtyChanged,
  onCancel,
  onSubmit,
  runOnly,
}) => {
  const { latestField } = useFieldRedlines({
    fieldName,
    fieldValue,
    redlines,
  });
  const initialValues = useMemo(
    () => ({ [fieldName]: latestField, editType: runOnly ? 'Blueline' : 'Redline' }),
    [fieldName, latestField, runOnly]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ errors, dirty, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} className="w-full">
          <UpdateOnDirty onDirtyChanged={onDirtyChanged} />
          <div className="w-full">
            <div className="w-full text-sm group flex">
              <Field name={fieldName} validate={validate}>
                {({ field }) => (
                  <div className="flex flex-col grow">
                    <TextAreaAutoHeight placeholder={placeholder} {...field} />
                    {errors && errors[fieldName] && <div className="text-red-700 font-normal">{errors[fieldName]}</div>}
                  </div>
                )}
              </Field>
            </div>
          </div>
          <MenuSubmitSuggestedEdit
            isRedlineSubmitDisabled={!dirty || isSubmitting}
            isRedlineCancelDisabled={isSubmitting}
            onRedlineCancel={onCancel}
            onSubmit={handleSubmit}
            runOnly={runOnly}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ProcedureFieldEdit;

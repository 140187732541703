import React from 'react';
import { MenuContextAction } from '../MenuContext';
import ThreeDot from '../../elements/ThreeDotMenu';

export interface ThreeDotMenuProps {
  actions: Array<MenuContextAction>;
  label: string;
}

const MoreActionsMenu = ({ actions, label }: ThreeDotMenuProps) => {
  if (actions.length === 0) {
    return null;
  }

  return (
    <div className="-mr-4">
      <ThreeDot menuActions={actions} menuLabel={label} />
    </div>
  );
};

export default React.memo(MoreActionsMenu);

import apm from '../lib/apm';
import Simulator, { REFRESH_INTERVAL_MS } from './simulation';

class ParametersObserver {
  constructor(telemetryService, operation, variables, runId, onChange, onSignalLost) {
    this.service = telemetryService;
    this.samples = {};
    this.simulator = new Simulator();
    this.operation = operation;
    this.variables = variables;
    this.runId = runId;
    this.onChange = onChange;
    this.onSignalLost = onSignalLost;
  }

  _getLatestSamples(identifiers) {
    const samples = identifiers.map((identifier) => {
      if (identifier.isSimulation) {
        return this.simulator.getSample(identifier);
      }
      return this.service.getLatestSample(
        identifier.name,
        this.operation,
        identifier.dictionaryId,
        this.variables,
        this.runId,
        identifier.streamId
      );
    });
    return Promise.allSettled(samples);
  }

  _refreshSamples(identifiers) {
    return this._getLatestSamples(identifiers)
      .then((samples) => {
        let isRefreshed = false;
        let someSignalsLost = false;
        // Update cache of samples
        samples.forEach((sample) => {
          if (sample.status === 'fulfilled') {
            this.samples[sample.value.stream_id] = sample.value;
            isRefreshed = true;
          } else {
            someSignalsLost = true;
          }
        });
        // Notify observers
        if (isRefreshed && typeof this.onChange === 'function') {
          this.onChange(this.samples);
        }
        if (someSignalsLost) {
          if (typeof this.onSignalLost === 'function') {
            this.onSignalLost();
          }
        }
      })
      .catch(() => {})
      .finally(() => {});
  }

  observeParameters(identifiers) {
    this.simulator.setParameters(identifiers.filter((identifier) => identifier.isSimulation));

    // Refresh samples immediately.
    this._refreshSamples(identifiers).catch((err) => apm.captureError(err));
    // Also refresh samples on every interval.
    this.timers = [];
    identifiers.forEach((identifier) => {
      this.timers?.push(
        setInterval(() => {
          this._refreshSamples([identifier]).catch((err) => apm.captureError(err));
        }, identifier.refreshRateMs ?? REFRESH_INTERVAL_MS)
      );
    });
  }

  clear() {
    if (this.timers) {
      for (const timer of this.timers) {
        clearTimeout(timer);
      }
    }
  }
}

export default ParametersObserver;

import React, { useState } from 'react';
import Select from 'react-select';
import cloneDeep from 'lodash.clonedeep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { reactSelectStyles } from '../../lib/styles';

import { TestCase } from '../types';
import { Link } from 'react-router-dom';
import { testingHazardsPath } from '../../lib/pathUtil';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { TestCaseBlock } from './TestCasesBlock';
import { FormikHelpers, FormikValues } from 'formik';
import { capitalizeFirstLetter } from 'shared/lib/text';
import AssessmentIndicator from './AssessmentIndicator';
import useCases from '../hooks/useCases';
import UnitDisplay from '../../components/Settings/Units/UnitDisplay';

type CaseOption = {
  value: string;
  label: string;
  case: TestCase;
};

interface TestCasesEditProps {
  content: TestCaseBlock;
  contentErrors: {
    error: string;
  };
  path: string;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
}

const TestCasesBlockEdit = ({ content, contentErrors, path, setFieldValue }: TestCasesEditProps) => {
  const { testCases } = useCases(true);
  const { currentTeamId } = useDatabaseServices();
  const [selectedCaseOption, setSelectedCaseOption] = useState<CaseOption | null>(null);
  const [addedCases, setAddedCases] = useState<string[]>(() => {
    return content?.items?.map((item) => item.id) ?? [];
  });

  const availableCaseOptions =
    testCases &&
    testCases
      .filter((tc) => !addedCases.includes(tc.id))
      .map((testCase) => ({
        value: testCase.id,
        label: `${testCase.name}`,
        testCase,
      }));

  const onCaseSelected = (option) => {
    setSelectedCaseOption(option);
  };

  const onAddItem = () => {
    const selectedCase = testCases.find((caseItem) => caseItem.id === selectedCaseOption?.value);

    if (selectedCase) {
      const value = {
        ...content,
        items: [...content.items, selectedCase],
      };
      setFieldValue(path ? path : '', value);
      setSelectedCaseOption(null);
      setAddedCases((prev) => [...prev, selectedCase.id]);
    }
  };

  const onRemoveItem = (item) => {
    const updated = cloneDeep(content);
    updated.items = updated.items.filter((component) => component.id !== item.id);
    setFieldValue(path ? path : '', updated);
    setAddedCases((prev) => prev.filter((caseId) => caseId !== item.id));
  };

  const columnHeaders: string[] = [
    ...content.items.reduce((acc, testCase) => {
      testCase.test_case_conditions.forEach((condition) => acc.add(condition.name));
      return acc;
    }, new Set<string>()),
  ];

  return (
    <div className="flex flex-col overflow-x-auto max-w-full w-full">
      <div className="field-title">Test Points</div>
      <div className="flex flex-col grow">
        <table className="w-full border-collapse">
          <thead>
            <tr className="items-center">
              <th className="border border-gray-400 p-1">
                <div className="text-left">
                  <span className="field-title">Name</span>
                </div>
              </th>
              {columnHeaders.map((header, index) => (
                <th key={index} className="border border-gray-400 p-1">
                  <div className="text-left">
                    <span className="field-title">{capitalizeFirstLetter(header)}</span>{' '}
                  </div>
                </th>
              ))}
              <th className="border border-gray-400 p-1">
                <div className="text-left">
                  <span className="field-title">Hazard</span>
                </div>
              </th>
              <th className="border border-gray-400 p-1">
                <div className="text-right">
                  <span className="field-title">Actions</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {!content?.items.length && (
              <tr className="h-10 text-sm text-gray-400 bg-white border-b items-center text-center">
                <td colSpan={4}>No Test Point Added</td>
              </tr>
            )}
            {content.items.map((testCase) => (
              <tr key={testCase.id} className="h-10 text-sm border items-center">
                {/* Display Test Point ID */}
                <td className="border border-gray-400 p-1">
                  <div>
                    <label className="text-sm truncate">{testCase.name}</label>
                  </div>
                </td>
                {columnHeaders.map((header) => {
                  const condition = testCase.test_case_conditions.find((cond) => cond.name === header);
                  return (
                    <td key={header} className="border border-gray-400 p-1">
                      <div>
                        {condition ? (
                          <div className="flex flex-row gap-x-1">
                            {`${condition.value} `}
                            <UnitDisplay unit={condition.units} />
                          </div>
                        ) : null}
                      </div>
                    </td>
                  );
                })}
                {/* Display Test Point Hazard */}
                <td className="border border-gray-400 p-1">
                  {testCase?.hazards &&
                    testCase?.hazards.map((hazard) => {
                      return (
                        <div key={hazard.id} className="flex items-center space-x-2">
                          <AssessmentIndicator analysis={hazard} />
                          <Link
                            to={`${testingHazardsPath(currentTeamId)}/${hazard.id}`}
                            className="text-sm text-blue-600 hover:underline truncate"
                          >
                            {hazard.name}
                          </Link>
                        </div>
                      );
                    })}
                </td>
                <td className="border border-gray-400 p-1">
                  <div className="flex justify-end">
                    <button type="button" className="secondary group" onClick={() => onRemoveItem?.(testCase)}>
                      <FontAwesomeIcon className="text-gray-400 group-hover:text-gray-500 p-1" icon="trash" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={3}>
                {contentErrors?.error && <div className="text-red-700 text-sm">{contentErrors.error}</div>}
                {content?.items?.length === 0 && (
                  <div className="flex flex-row gap-x-2 my-2">
                    <div className="w-96">
                      <Select
                        styles={reactSelectStyles}
                        classNamePrefix="react-select"
                        options={availableCaseOptions}
                        value={selectedCaseOption}
                        onChange={onCaseSelected}
                        isSearchable={true}
                        placeholder="Search test points"
                        isClearable={true}
                        menuPortalTarget={document.body}
                      />
                    </div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      disabled={!selectedCaseOption}
                      onClick={onAddItem}
                    >
                      Add Test Point
                    </button>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TestCasesBlockEdit;

import React from 'react';
import Modal from './Modal';

interface DisclaimerModalProps {
  disclaimer: string;
  onAgree: () => void;
  onDecline: () => void;
}

const DisclaimerModal = ({ disclaimer, onAgree, onDecline }: DisclaimerModalProps) => {
  return (
    <Modal
      title="Disclaimer"
      primaryActionTitle="I Agree"
      secondaryActionTitle="I Decline"
      onPrimaryAction={onAgree}
      onSecondaryAction={onDecline}
    >
      <div className="mb-4">{disclaimer}</div>
    </Modal>
  );
};

export default DisclaimerModal;

import ProcedureFieldDiff from '../../ProcedureFieldDiff';
import MarkdownView from '../../Markdown/MarkdownView';
import TextLinkify from '../../TextLinkify';
import React from 'react';

interface FieldInputNameDisplayProps {
  blockName: string;
  newBlockName?: string;
}
const FieldInputNameDisplay = React.memo(({ blockName, newBlockName }: FieldInputNameDisplayProps) => {
  return (
    <TextLinkify>
      <div className="flex self-center max-w-max">
        {newBlockName && (
          <div className="min-w-0 whitespace-pre-line break-words">
            <ProcedureFieldDiff original={blockName ?? ''} redlined={newBlockName ?? ''} />
          </div>
        )}
        {!newBlockName && <MarkdownView text={blockName} />}
      </div>
    </TextLinkify>
  );
});

export default FieldInputNameDisplay;

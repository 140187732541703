import React, { useCallback, useMemo, useRef, useState } from 'react';
import ReviewProcedureSection from './ReviewProcedureSection';
import { ProcedureContextProvider } from '../../contexts/ProcedureContext';
import { RunContextProvider } from '../../contexts/RunContext';
import procedureUtil from '../../lib/procedureUtil';
import TextLinkify from '../TextLinkify';
import '../../App.css';
import { useSettings } from '../../contexts/SettingsContext';
import RunProcedureVariables from '../RunProcedureVariables';
import diffUtil from '../../lib/diffUtil';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import ProcedureDiffText from '../ProcedureDiffText';
import { useReviewContext } from '../../contexts/ReviewContext';
import useDiff from '../../hooks/useDiff';
import ReviewPartList from '../../manufacturing/components/Review/ReviewPartList';
import ReviewProcedureHeader from './ReviewProcedureHeader';
import ReviewSnippetSelector from '../../testing/components/Review/ReviewSnippetSelector';
import ProcedureRiskList from '../../risks/components/ProcedureRiskList';
import FieldLabel from '../../elements/internal/FieldLabel';
import { Draft, DraftVariable, ProcedureDiff, Run, SectionDiffElement } from 'shared/lib/types/views/procedures';

interface ReviewProcedureContentProps {
  procedure: Draft | ProcedureDiff;
  onResolveReviewComment;
  onUnresolveReviewComment;
  saveReviewComment;
  isCollapsedMap;
  setIsCollapsed;
  areAllStepsInSectionExpanded;
  setAllStepsInSectionExpanded;
  scrollToBufferRem;
  showReviewComments: boolean;
}

/**
 * Displays the procedure description and all section and step content for review mode.
 */
const ReviewProcedureContent = ({
  procedure,
  onResolveReviewComment,
  onUnresolveReviewComment,
  saveReviewComment,
  isCollapsedMap,
  setIsCollapsed,
  areAllStepsInSectionExpanded,
  setAllStepsInSectionExpanded,
  scrollToBufferRem = 0,
  showReviewComments,
}: ReviewProcedureContentProps) => {
  const sectionAndStepRefs = useRef({});
  const { getSetting, isRisksEnabled } = useSettings();

  const [scrollToId, setScrollToId] = useState(null);
  const { onScrollToDiffRefChanged } = useReviewContext();
  const { handleOnScrollToDiffRefChanged } = useDiff({ onScrollToDiffRefChanged });

  const sourceName = useMemo(() => {
    if (procedure.code && procedure.name) {
      return `${procedure.code} - ${procedure.name}`;
    }

    return 'Untitled procedure';
  }, [procedure]);

  // Array of booleans mapping whether all the steps in section *i* are collapsed
  const allStepsInSectionExpandedMap = React.useMemo(
    () => procedure && procedure.sections.map(areAllStepsInSectionExpanded),
    [areAllStepsInSectionExpanded, procedure]
  );

  // Called when element refs are changed, will scroll to element if element exists.
  const onSectionOrStepRefChanged = useCallback(
    (id, element) => {
      sectionAndStepRefs.current[id] = element;

      if (element && scrollToId && scrollToId === id) {
        element.scrollIntoView({ behavior: 'smooth' });
        setScrollToId(null);
      }
    },
    [sectionAndStepRefs, scrollToId]
  );

  // Scrolls to section/step with associated id and expand that section and/or step.
  const scrollTo = useCallback(
    ({ sectionId, stepId, stepHeaderId, contentId }) => {
      const scrollToId = contentId || stepHeaderId || stepId || sectionId;
      const element = sectionAndStepRefs.current[scrollToId];

      if (!element) {
        return;
      }

      setScrollToId(scrollToId);
      setIsCollapsed(sectionId, false);

      if (stepId) {
        setIsCollapsed(stepId, false);
      }
    },
    [setIsCollapsed]
  );

  const isReviewCommentEnabled = useMemo(() => {
    return showReviewComments && (procedure.state === 'in_review' || procedure.state === 'released');
  }, [showReviewComments, procedure.state]);
  const isDescriptionChanged = useMemo(() => sharedDiffUtil.isChanged(procedure, 'description'), [procedure]);
  const removedSectionMap = useMemo(
    () => sharedDiffUtil.getContainerMap(procedure.sections as Array<SectionDiffElement>, 'old'),
    [procedure.sections]
  );
  const nonRemovedSectionMap = useMemo(
    () => sharedDiffUtil.getContainerMap(procedure.sections as Array<SectionDiffElement>, 'new'),
    [procedure.sections]
  );
  const getSectionKey = useCallback(
    (section, sectionIndex) => {
      if (section.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED) {
        return '--';
      }
      const sectionIndexForKey =
        procedure.state === 'in_review'
          ? diffUtil.getIndexForKey(section, removedSectionMap, nonRemovedSectionMap) ?? 0
          : sectionIndex;

      return procedureUtil.displaySectionKey(sectionIndexForKey, getSetting('display_sections_as', 'letters'));
    },
    [getSetting, nonRemovedSectionMap, procedure.state, removedSectionMap]
  );

  const showProcedureHeaderReviewCommentsProp = useCallback(
    (header) => {
      return showReviewComments && header.diff_change_state !== ARRAY_CHANGE_SYMBOLS.REMOVED;
    },
    [showReviewComments]
  );

  return (
    <>
      <div className="mb-3">
        <span className="whitespace-pre-line break-words">
          {isDescriptionChanged && (
            <ProcedureDiffText
              diffValue={procedure.description}
              onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged('description', element)}
              diffChangeState={ARRAY_CHANGE_SYMBOLS.MODIFIED}
            />
          )}
          {!isDescriptionChanged && <TextLinkify>{procedure.description}</TextLinkify>}
        </span>
      </div>
      <ProcedureContextProvider procedure={procedure} scrollTo={scrollTo}>
        <RunContextProvider run={(procedure as Run) || {}}>
          {isRisksEnabled?.() && procedure.risks && procedure.risks?.length > 0 && (
            <div className="mt-2">
              <ProcedureRiskList procedureRisks={procedure.risks} />
            </div>
          )}

          {procedure.variables && procedure.variables?.length > 0 && (
            <div className="mt-2 p-2 bg-white border border-gray-200 rounded">
              <FieldLabel label="Procedure Variables" />
              <RunProcedureVariables
                isEnabled={false}
                variables={procedure.variables as Array<DraftVariable>}
                onRefChanged={onSectionOrStepRefChanged}
                scrollMarginTopValueRem={scrollToBufferRem}
                diff={true}
              />
            </div>
          )}

          {procedure.part_list && (
            <div className="mt-2 p-2 bg-white border border-gray-200 rounded">
              <FieldLabel label="Bill of Materials" />
              <ReviewPartList content={procedure.part_list} isHidden={false} />
            </div>
          )}

          {procedure.test_case_list && (
            <div className="my-4">
              <ReviewSnippetSelector content={procedure.test_case_list} />
            </div>
          )}

          {/* Procedure Header */}
          {procedure.headers && (
            <div className="mt-3">
              {procedure.headers.map((header) => {
                return (
                  <ReviewProcedureHeader
                    key={header.id}
                    comments={procedure.comments}
                    header={header}
                    isCollapsed={isCollapsedMap[header.id]}
                    onCollapse={setIsCollapsed}
                    onResolveReviewComment={onResolveReviewComment}
                    onUnresolveReviewComment={onUnresolveReviewComment}
                    saveReviewComment={saveReviewComment}
                    showReviewComments={showProcedureHeaderReviewCommentsProp(header)}
                  />
                );
              })}
            </div>
          )}
          {/* Grid tracks: [section, bullet and content 1, content 2 and checkbox] */}
          <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
            <thead>
              <tr>
                <th className="w-4"></th>
                <th className="w-auto"></th>
                <th className="w-64"></th>
              </tr>
            </thead>
            {procedure.sections &&
              procedure.sections.map((section, sectionIndex) => (
                <ReviewProcedureSection
                  key={`section.${sectionIndex}`}
                  section={section}
                  sectionKey={getSectionKey(section, sectionIndex)}
                  sectionIndex={sectionIndex}
                  sourceName={sourceName}
                  isCollapsedMap={isCollapsedMap}
                  onCollapse={setIsCollapsed}
                  onResolveReviewComment={onResolveReviewComment}
                  onUnresolveReviewComment={onUnresolveReviewComment}
                  saveReviewComment={saveReviewComment}
                  docState={procedure.state}
                  comments={procedure.comments}
                  showReviewComments={
                    isReviewCommentEnabled && section.diff_change_state !== ARRAY_CHANGE_SYMBOLS.REMOVED
                  }
                  onRefChanged={onSectionOrStepRefChanged}
                  onExpandCollapseAllSteps={() =>
                    setAllStepsInSectionExpanded(!allStepsInSectionExpandedMap[sectionIndex], section)
                  }
                  allStepsAreExpanded={allStepsInSectionExpandedMap && allStepsInSectionExpandedMap[sectionIndex]}
                  scrollToBufferRem={scrollToBufferRem}
                  repeatKey={undefined}
                  projectId={undefined}
                  runId={undefined}
                  onRepeatSection={undefined}
                  isRepeatable={undefined}
                  onSkipSection={undefined}
                  stepCounts={undefined}
                  runStatus={undefined}
                  onAddStepIssue={undefined}
                />
              ))}
          </table>
        </RunContextProvider>
      </ProcedureContextProvider>
    </>
  );
};

export default ReviewProcedureContent;

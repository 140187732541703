import React, { Fragment, useState, useMemo, useCallback, useRef, useEffect, ReactNode } from 'react';
import { Formik, Form } from 'formik';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import SettingsRow from './SettingsRow';
import SettingsRowCell from './SettingsRowCell';
import { useSettings } from '../../contexts/SettingsContext';
import { ProjectUserRowType, UserRoleDropdownOption } from './types';
import { ACCESS, ALL_LEVELS } from '../../lib/auth';
import Button from '../Button';

const NEW_USER = {
  id: null,
  roles: [],
  isFixed: false,
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    id: Yup.string().nullable().email('User must be a valid email.').required('A user must be selected.'),
    roles: Yup.array(
      Yup.object().shape({
        name: Yup.string().trim(),
        code: Yup.string().nullable().trim(),
        id: Yup.string().trim().notRequired(),
        permissions: Yup.array(Yup.string().trim()),
        isFixed: Yup.boolean().nullable().notRequired(),
      })
    ).min(1, 'At least one role must be selected.'),
  }),
});

/**
 * This component is to be used by the project users table.
 *
 * @param user - User information such as ID, access level (e.g. Admin, Editor etc.)
 *   and operator roles (e.g. [MD, DMS, ...]).
 *
 * @param isEnabled - If set to false will hide the edit button.
 * @param isNewUser - If set to true will enable all new user fields and save controls.
 * @param onSave - Function that will be called with an updated user object when the row is saved.
 *  onSave is expected to return a Promise.
 * @param onValidate - Function that will be called when the user clicks the add user button to check if
 *   the user already exists.
 * @param onCancel - Function that will be called when user presses the cancel button.
 * @param onDeleteUser - Function that will be called if user clicks on Delete User button.
 * @param isUserRemovalDisabled - If set to true will prevent user from removing themselves.
 * @param existingUsers - Users currently displayed on other rows, used to exclude them from the dropdown list of users.
 * @param availableRoles - List of roles to display in the dropdown.
 */
const ProjectUserRow = React.memo<ProjectUserRowType>(
  ({
    user,
    isEnabled,
    isNewUser,
    onSave,
    onValidate,
    onCancel,
    onDeleteUser,
    isUserRemovalDisabled,
    existingUsers,
    availableRoles,
  }) => {
    const [isEditing, setIsEditing] = useState(isNewUser);
    const { users } = useSettings();

    const [errorMessage, setErrorMessage] = useState<null | string>(null);
    const [isDeletingUser, setIsDeletingUser] = useState(false);
    const [isConfirmingDeleteUser, setIsConfirmingDeleteUser] = useState(false);
    const isMounted = useRef(true);

    // Update mounted flag when component is unmounted
    useEffect(
      () => () => {
        isMounted.current = false;
      },
      []
    );

    const usersArray = useMemo(() => {
      if (!users || !users.users) {
        return [];
      }

      return (
        existingUsers &&
        Object.keys(users.users)
          .filter((userId) => !existingUsers.has(userId))
          .map((userId) => {
            return {
              value: userId,
              label: userId,
            };
          })
          .sort((a, b) => a.value.localeCompare(b.value))
      );
    }, [existingUsers, users]);

    // Needs to return an object because of Formik.
    const initialValues = useMemo(() => {
      if (!user) {
        return { user: { ...NEW_USER } };
      }
      // If the user has a workspace role then it can't be removed from here, it has to be removed from the workspace level
      if (user.roles.some((role) => role.isFixed)) {
        user.isFixed = true;
      }
      return { user };
    }, [user]);

    const availableRolesOptions = useMemo(() => {
      if (!availableRoles) {
        return null;
      }

      return availableRoles.map((role) => ({
        value: {
          name: role.name,
          code: role.code,
          id: role.id,
        },
        label: role.name,
      }));
    }, [availableRoles]);

    const availableRolesSelectArray = useCallback(() => {
      if (!availableRolesOptions) {
        return null;
      }

      // Get the workspace level access, if available
      const workspaceRole = user?.roles.filter((roleObject) => roleObject.isFixed)[0];
      // Find the index of the hierarchical access level
      const workspaceRoleIndex = workspaceRole ? ALL_LEVELS.indexOf(workspaceRole.name.toLowerCase() as ACCESS) : 0;
      // Now use existingRoleIndex to index the hierarchical list of roles and only show relevant options
      const availableRoles = ALL_LEVELS.slice(workspaceRoleIndex);

      // Now filter the dropdown options
      return availableRolesOptions
        .filter((role) => {
          return availableRoles.includes(role.label.toLowerCase() as ACCESS);
        })
        .sort((a, b) => a.value.name.localeCompare(b.value.name));
    }, [availableRolesOptions, user]);

    const edit = useCallback(() => {
      setIsEditing(true);
    }, []);

    const cancel = useCallback(() => {
      if (onCancel) {
        onCancel();
      }
      setErrorMessage(null);
      setIsEditing(false);
    }, [onCancel]);

    const confirmDelete = useCallback(() => {
      setIsConfirmingDeleteUser(true);
    }, []);

    const cancelConfirmDelete = useCallback(() => {
      setErrorMessage(null);
      setIsConfirmingDeleteUser(false);
    }, []);

    const modifyUser = useCallback(
      (user) => {
        const payload = {
          id: user.id,
          project_roles: user.roles.map((userRole) => userRole.id),
        };

        onSave &&
          onSave(payload, isNewUser)
            .then(() => {
              if (!isMounted.current) {
                return;
              }

              setIsEditing(false);
            })
            .catch((errors) => {
              if (!isMounted.current) {
                return;
              }

              setErrorMessage(errors);
            });
      },
      [isNewUser, onSave]
    );

    /**
     * Called when the user clicks save from user row.
     */
    const save = useCallback(
      (updated, { setSubmitting }) => {
        // Only submit one role update to the backend, excluding the fixed workspace role
        const fixedRole = user && user.roles.map((role) => role.isFixed && role.name);
        updated.user.roles = updated.user.roles.filter((val) => !fixedRole?.includes(val.name));

        setErrorMessage(null);
        if (isNewUser) {
          onValidate &&
            onValidate(updated.user)
              .then(() => {
                setSubmitting(true);
                modifyUser(updated.user);
                setSubmitting(false);
              })
              .catch((errors) => {
                if (!isMounted.current) {
                  return;
                }

                setSubmitting(false);
                setErrorMessage(errors);
                return;
              });
        } else {
          try {
            setSubmitting(true);
            modifyUser(updated.user);
            setSubmitting(false);
          } catch (errors) {
            if (!isMounted.current) {
              return;
            }

            setSubmitting(false);
            setErrorMessage(errors);
            return;
          }
        }
      },
      [user, isNewUser, onValidate, modifyUser]
    );

    const deleteUser = useCallback(() => {
      setIsDeletingUser(true);

      onDeleteUser &&
        user &&
        onDeleteUser(user)
          .then(() => {
            setIsDeletingUser(false);
          })
          .catch((errorMessage) => {
            setIsDeletingUser(false);
            setErrorMessage(errorMessage);
          });
    }, [onDeleteUser, user]);

    const setValue = useCallback((path, updated, setFieldValue) => {
      let strippedValue;

      if (Array.isArray(updated)) {
        strippedValue = updated.map((update) => update.value);
      } else {
        strippedValue = updated.value;
      }
      setFieldValue(path, strippedValue);
    }, []);

    const setInitialUser = (values) => {
      if (values.user.id) {
        return {
          value: values.user.id,
          label: values.user.id,
        };
      } else {
        return [];
      }
    };

    const mapRoles = (values): UserRoleDropdownOption => {
      let fixedRole;
      let projectRole;

      // If we have multiple roles (workspace and project) then grab the highest one
      values.user.roles.forEach((roleObject) => {
        if (values.user.roles.length > 1 && !roleObject.isFixed) {
          projectRole = {
            label: roleObject.name,
            value: roleObject,
            isFixed: roleObject.isFixed,
          };
        } else {
          fixedRole = {
            label: roleObject.name,
            value: roleObject,
            isFixed: roleObject.isFixed,
          };
        }
      });
      if (projectRole && fixedRole) {
        const projectIndex = ALL_LEVELS.indexOf(projectRole.label.toLowerCase());
        const fixedIndex = ALL_LEVELS.indexOf(fixedRole.label.toLowerCase());
        return projectIndex >= fixedIndex ? projectRole : fixedRole;
      }
      return projectRole || fixedRole;
    };

    const displayRoles = () => {
      const mappedRoles = mapRoles({ user });
      if (user && user.roles) {
        return (
          <div className="my-1" key={mappedRoles?.value?.code}>
            {mappedRoles?.value?.name}
          </div>
        );
      } else {
        return null;
      }
    };

    return (
      <Fragment>
        {!isEditing && (
          <SettingsRow>
            {/* User ID cell */}
            <SettingsRowCell>
              <div className="flex flex-row flex-wrap">
                <div className="py-1.5 px-2 border-2 border-transparent">{user && user.id}</div>
              </div>
              {/* empty div keeps entire row aligned when errors are rendered */}
              {errorMessage && <div className="h-5 mr-1 text-red-700"></div>}
            </SettingsRowCell>
            {/* Access cell */}
            <SettingsRowCell>
              <div className="py-1.5 px-2 border-2 border-transparent">{displayRoles()}</div>
              {/* empty div keeps entire row aligned when errors are rendered */}
              {errorMessage && <div className="h-5 mr-1 text-red-700"></div>}
            </SettingsRowCell>

            {/* Edit button */}
            <SettingsRowCell align="right">
              {isEnabled && (
                <div className="py-1.5">
                  <Button type="tertiary" size="sm" ariaLabel="edit" leadingIcon="pencil-alt" onClick={edit}>
                    Edit
                  </Button>
                </div>
              )}
            </SettingsRowCell>
          </SettingsRow>
        )}

        {isEditing && (
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={save}>
            {({ values, errors, dirty, touched, isValid, isSubmitting, setFieldValue }) => (
              <Form className="table-row border-t border-b border-gray-300 bg-white">
                <SettingsRowCell>
                  {/* Non editable User ID cell */}
                  {!isNewUser && (
                    <div className="flex flex-row flex-wrap">
                      <div className="py-1.5 px-2 border-2 border-transparent">{values.user.id}</div>
                    </div>
                  )}
                  {/* Only available if adding a new user to project. */}
                  {isNewUser && (
                    <Select
                      classNamePrefix="settings-select react-select"
                      value={setInitialUser(values)}
                      options={usersArray}
                      onChange={(value) => setValue('user.id', value, setFieldValue)}
                    />
                  )}
                  {/* render user ID validation errors under the user ID select menu */}
                  {!isValid && touched?.user?.id && errors?.user?.id && (
                    <div className="h-5">
                      <div className="text-red-700 text-sm">{errors?.user?.id}</div>
                    </div>
                  )}
                  {/* empty div keeps entire row aligned when errors are rendered under the buttons */}
                  {(errorMessage || (!isValid && touched?.user?.roles && errors?.user?.roles)) &&
                    !(!isValid && touched?.user?.id && errors?.user?.id) && <div className="h-5 -mt-0.5"></div>}
                </SettingsRowCell>

                {/*Single select user roles cell*/}
                <SettingsRowCell>
                  {!isNewUser && (
                    <div className="-ml-1">
                      <Select
                        classNamePrefix="settings-select react-select"
                        value={mapRoles(values)}
                        options={availableRolesSelectArray()}
                        onChange={(value) => setValue('user.roles', [value], setFieldValue)}
                      />
                      {/* render roles validation errors under the roles select menu */}
                      {!isValid && touched?.user?.roles && errors?.user?.roles && (
                        <div className="h-5">
                          <div className="text-red-700 text-sm">{errors?.user?.roles as ReactNode}</div>
                        </div>
                      )}
                      {/* empty div keeps entire row aligned when errors are rendered under the buttons */}
                      {(errorMessage || (!isValid && touched?.user?.id && errors?.user?.id)) &&
                        !(!isValid && touched?.user?.roles && errors?.user?.roles) && (
                          <div className="h-5 -mt-0.5"></div>
                        )}
                    </div>
                  )}
                  {/* Only a field if adding a new user. py-1.5 is used to match react-select inputs */}
                  {isNewUser && (
                    <div className="-ml-1">
                      <Select
                        classNamePrefix="settings-select react-select"
                        value={mapRoles(values)}
                        options={availableRolesSelectArray()}
                        onChange={(values) => setValue('user.roles', [values], setFieldValue)}
                      />
                      {/* render roles validation errors under the roles select menu */}
                      {!isValid && touched?.user?.roles && errors?.user?.roles && (
                        <div className="h-5">
                          <div className="text-red-700 text-sm">{errors?.user?.roles as ReactNode}</div>
                        </div>
                      )}
                      {/* empty div keeps entire row aligned when errors are rendered under the buttons */}
                      {(errorMessage || (!isValid && touched?.user?.id && errors?.user?.id)) &&
                        !(!isValid && touched?.user?.roles && errors?.user?.roles) && (
                          <div className="h-5 -mt-0.5"></div>
                        )}
                    </div>
                  )}
                </SettingsRowCell>

                {/* Right-side buttons (Remove User, Cancel, Save) */}
                <SettingsRowCell align="right">
                  {/* Need padding top to compensate and keep row content aligned, when errors are present. */}
                  <div>
                    <div className="py-1.5">
                      {/* Delete user button */}
                      {!isUserRemovalDisabled && !isConfirmingDeleteUser && onDeleteUser && (
                        <button
                          type="button"
                          aria-label="delete user"
                          className="px-2 py-1 mx-1 text-xs font-semibold tracking-wide text-black uppercase rounded disabled:opacity-50 disabled:cursor-default"
                          disabled={isDeletingUser || values.user.isFixed}
                          onClick={confirmDelete}
                        >
                          <FontAwesomeIcon className="text-red-500 group-hover:text-red-800" icon="trash" />
                          <span className="pl-2">Remove User</span>
                        </button>
                      )}

                      {/* Save row button */}
                      {!isConfirmingDeleteUser && (
                        <button
                          type="submit"
                          aria-label="save"
                          className="px-2 py-1 mx-1 border border-blue-500 bg-blue-500 text-xs font-semibold tracking-wide text-white uppercase rounded disabled:opacity-50 disabled:cursor-default"
                          disabled={!dirty || isSubmitting}
                        >
                          <span>Save</span>
                        </button>
                      )}

                      {/* Cancel editing button */}
                      {!isConfirmingDeleteUser && (
                        <button
                          type="button"
                          aria-label="cancel"
                          className="px-2 py-1 mx-1 border border-gray-400 text-gray-500 text-xs font-semibold tracking-wide uppercase rounded"
                          onClick={cancel}
                        >
                          <span>Cancel</span>
                        </button>
                      )}

                      {/*Delete user button*/}
                      {isConfirmingDeleteUser && onDeleteUser && (
                        <button
                          type="button"
                          aria-label="delete user"
                          className="px-2 py-1 mx-1 border border-red-500 bg-red-500 text-xs font-semibold tracking-wide text-white uppercase rounded disabled:opacity-50 disabled:cursor-default"
                          disabled={isDeletingUser}
                          onClick={deleteUser}
                        >
                          <span>Confirm</span>
                        </button>
                      )}

                      {isConfirmingDeleteUser && (
                        <button
                          type="button"
                          aria-label="cancel"
                          className="px-2 py-1 mx-1 border border-gray-400 text-gray-500 text-xs font-semibold tracking-wide uppercase rounded"
                          onClick={cancelConfirmDelete}
                        >
                          <span>Cancel</span>
                        </button>
                      )}
                    </div>

                    {/* Empty div keeps entire row aligned when errors are under other cells */}
                    {((!isValid && touched?.user?.id && errors?.user?.id) ||
                      (!isValid && touched?.user?.roles && errors?.user?.roles)) && <div className="h-5"></div>}
                    {/* render post form validation errors */}
                    {errorMessage &&
                      !(!isValid && touched?.user?.id && errors?.user?.id) &&
                      !(!isValid && touched?.user?.roles && errors?.user?.roles) && (
                        <div className="text-red-700 text-sm">{errorMessage}</div>
                      )}
                  </div>
                </SettingsRowCell>
              </Form>
            )}
          </Formik>
        )}
      </Fragment>
    );
  }
);

export default ProjectUserRow;

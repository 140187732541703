import React, { useEffect, useRef } from 'react';
import { useGanttContext } from '../../components/Gantt/GanttContext';
import * as d3 from 'd3';
import { RULER_HEIGHT, PIXELS_PER_TICK } from '../../config';

const Ruler = ({ colorClass }) => {
  const { period, ganttWidth } = useGanttContext();
  const scaleRef = useRef<SVGSVGElement>(null);

  const tzAdjustedStart = period.start.setZone('local', { keepLocalTime: true });
  const tzAdjustedEnd = period.end.setZone('local', { keepLocalTime: true });

  useEffect(() => {
    const svg = d3.select(scaleRef.current).attr('width', ganttWidth).attr('height', RULER_HEIGHT);

    svg.selectAll('*').remove();

    const scale = d3.scaleTime().domain([tzAdjustedStart, tzAdjustedEnd]).range([0, ganttWidth]);
    const nTicks = ganttWidth / PIXELS_PER_TICK;
    const axis = d3.axisBottom(scale).ticks(nTicks);
    svg.append('g').call(axis);
  }, [tzAdjustedStart, tzAdjustedEnd, ganttWidth]);

  return (
    <div className={`h-[${RULER_HEIGHT}px] ${colorClass}`}>
      <svg ref={scaleRef} />
    </div>
  );
};

export default Ruler;

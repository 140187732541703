import React, { useMemo, useCallback, useContext } from 'react';

const RunFilterContext = React.createContext();

export const RunFilterProvider = ({ displaySections, filterSelectedOperators, children }) => {
  const filterMap = useMemo(() => {
    const map = {};
    if (!displaySections) {
      return map;
    }
    displaySections.forEach(([section]) => {
      section.steps.forEach((step) => {
        const stepHasSignoffsWithFilteredOperator =
          step.signoffs &&
          step.signoffs.some((signoff) =>
            signoff.operators.some((operator) => filterSelectedOperators.includes(operator))
          );
        const isDisplayed = !filterSelectedOperators.length || stepHasSignoffsWithFilteredOperator;
        map[step.id] = isDisplayed;
        if (isDisplayed) {
          map[section.id] = true;
        }
      });
    });
    return map;
  }, [filterSelectedOperators, displaySections]);

  const isSectionFiltered = useCallback(
    (sectionId) => {
      return filterMap[sectionId];
    },
    [filterMap]
  );

  const isStepFiltered = useCallback(
    (stepId) => {
      return filterMap[stepId];
    },
    [filterMap]
  );

  const value = {
    isSectionFiltered,
    isStepFiltered,
    filterSelectedOperators,
  };

  return <RunFilterContext.Provider value={value}>{children}</RunFilterContext.Provider>;
};

export const useRunFilter = () => {
  const context = useContext(RunFilterContext);
  if (context === undefined) {
    return {};
  }
  return context;
};

import { useState, useCallback, useMemo, useEffect, useRef } from 'react';

/**
 * This hook is used to store past, present and future states.
 * Will return:
 * 1) computed properties - present, hasPast, hasFuture
 * 2) callbacks - updatePresentAndAddToPast, updatePresent, undoState, redoState.
 * @returns {Object}
 * {
 *  present - returns present state,
 *  hasPast - returns true if has any states in past stack,
 *  hasFuture - returns true if has any states in future stack,
 *  redoState - sets next state (from future) as present,
 *  updatePresent - updates present state,
 *  updatePresentAndAddToPast - updates present state and adds to past stack,
 *  undoState - sets last state (from past) as present
 * }
 */
const useStateHistory = (initial) => {
  const _initial = initial || null;
  const [stateHistory, __setStateHistory] = useState({
    past: [],
    present: _initial,
    future: [],
  });

  // Used to avoid updating unmounted components
  const isMounted = useRef(true);
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const setStateHistory = useCallback(
    (history) => {
      if (!isMounted.current) {
        return;
      }

      __setStateHistory(history);
    },
    [__setStateHistory]
  );

  // Returns the present state.
  const present = useMemo(() => stateHistory.present, [stateHistory]);

  // Returns true if there are any states stored in the past stack.
  const hasPast = useMemo(() => Boolean(stateHistory.past.length), [stateHistory]);

  // Returns true if there are any states stored in the future stack.
  const hasFuture = useMemo(() => Boolean(stateHistory.future.length), [stateHistory]);

  // Updates the present state and adds to the past stack.
  const updatePresentAndAddToPast = useCallback(
    (presentObject, pastObject) => {
      setStateHistory({
        past: [...stateHistory.past, pastObject],
        present: presentObject,
        future: [], // Clear future timeline when adding a new state to past, to prevent multiple future timelines.
      });
    },
    [stateHistory, setStateHistory]
  );

  // Updates the present state.
  const updatePresent = useCallback(
    (presentObject) => {
      setStateHistory({
        ...stateHistory,
        present: presentObject,
      });
    },
    [stateHistory, setStateHistory]
  );

  // Sets the last state (from past stack) as present.
  const undoState = useCallback(
    (newFutureState) => {
      const past = [...stateHistory.past];
      const lastState = past.pop();

      setStateHistory({
        past,
        present: lastState,
        future: [...stateHistory.future, newFutureState],
      });
    },
    [stateHistory, setStateHistory]
  );

  // Sets the next state (from future stack) as present.
  const redoState = useCallback(
    (newPastState) => {
      const future = [...stateHistory.future];
      const nextState = future.pop();

      setStateHistory({
        past: [...stateHistory.past, newPastState],
        present: nextState,
        future,
      });
    },
    [stateHistory, setStateHistory]
  );

  return {
    present,
    hasPast,
    hasFuture,
    redoState,
    updatePresent,
    updatePresentAndAddToPast,
    undoState,
  };
};

export default useStateHistory;

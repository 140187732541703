import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { sortBy } from 'shared/lib/collections';
import { Tool } from 'shared/lib/types/api/manufacturing/tools/models';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import fromClient from '../lib/tools/fromClient';

const QUERY_KEY = 'tools';

interface ToolsState {
  allTools: Array<Tool> | undefined;
  isToolNumberUnique: (toolNumber: string | undefined) => boolean;
  getTool: (toolId: number) => Tool | undefined;
}

const useTools = (): ToolsState => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  const fetchTools = async () => {
    const clientTools = await services.tools.getTools();
    const tools = sortBy(clientTools.map(fromClient.toTool), [
      'tool_number',
      'name',
    ]);
    return { tools };
  };

  const { data } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: fetchTools,
  });

  const allTools = data?.tools;

  const getTool = useCallback(
    (toolId: number): Tool | undefined => {
      return (allTools ?? []).find((tool) => tool.id === toolId);
    },
    [allTools]
  );

  const isToolNumberUnique = (toolNumber: string | undefined) => {
    if (!allTools || !toolNumber) {
      return true;
    }
    return !allTools.some((tool) => tool.tool_number === toolNumber);
  };

  return {
    allTools,
    isToolNumberUnique,
    getTool,
  };
};

export default useTools;

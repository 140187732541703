import superlogin from './superlogin';
import { API_URL } from '../config';
import procedureVariableUtil from '../lib/procedureVariableUtil';
import { Command } from '../lib/models/postgres/commanding';
import {
  RunVariable,
  CommandingBlockArguments,
  CommandingBlockRecordedResults,
} from 'shared/lib/types/views/procedures';
import { OperationId } from 'shared/lib/types/operations';

export type SendCommandError = {
  success: false;
  message: 'Network error';
};

export interface commandMetadata {
  operation?: OperationId;
  variables?: Array<RunVariable>;
  runId: string;
  stepName: string;
}

class CommandingService {
  private teamId: string;

  constructor(teamId: string) {
    this.teamId = teamId;
  }

  /**
   * Searches command objects by name, case insensitive.
   *
   * @param term: search term. Eg 'test', 'test.sh', 'test.shuttle'. If
   *              empty, all commands are returned up to the API limit.
   * @returns A list of command objects from the API.
   */
  async searchCommands(
    term: string,
    dictionaryIds?: Array<number>
  ): Promise<Array<Command>> {
    const url = `${API_URL}/teams/${this.teamId}/commands/search`;
    const params = {
      term,
      dictionaryIds,
    };
    const response = await superlogin.getHttp().get(url, { params });
    return response.data;
  }

  /**
   * Gets a command object by exact name.
   */
  async getCommandByName(
    name: string,
    dictionaryId?: number
  ): Promise<Command> {
    const url = `${API_URL}/teams/${this.teamId}/commands/query`;
    const params = {
      name,
      dictionaryId,
    };
    const response = await superlogin.getHttp().get(url, { params });
    return response.data;
  }

  /**
   * Sends the given command.
   *
   * @param name A command name. Eg, 'test.shuttle.launch'
   * @param args Object, key-value arguments object to send with command.
   * @param operation The current run operation.
   * @param variables List of current procedure variables and their values.
   * @returns A command results object from the API.
   */
  async sendCommand(
    name: string,
    dictionaryId: number,
    args: CommandingBlockArguments,
    metadata: commandMetadata
  ): Promise<CommandingBlockRecordedResults | SendCommandError> {
    // TODO: Clean up these API routes. commanding/command is duplicate?
    const url = `${API_URL}/teams/${this.teamId}/commands/send`;
    const dataTransferVariables =
      procedureVariableUtil.getDataTransferVariables(metadata.variables);
    const body = {
      name,
      dictionary_id: dictionaryId,
      arguments: args,
      operation: metadata.operation,
      variables: dataTransferVariables,
      run_id: metadata.runId,
      step_name: metadata.stepName,
    };
    try {
      const response = await superlogin.getHttp().post(url, body);
      return response.data;
    } catch (error) {
      const results = {
        success: false,
        message: error?.response?.data?.message || 'Network error',
      } as const;
      return results;
    }
  }
}

export default CommandingService;

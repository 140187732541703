import useAttachment from '../hooks/useAttachment';
import { BlockValues } from './Blocks/BlockTypes';
import InlineImage from './InlineImage';

interface InlineImageStandaloneProps {
  attachment: BlockValues<'attachment'>;
  showCaptionText: boolean;
  file?: File;
  onSave?: (file: File) => void;
  onClose?: () => void;
  isAnnotating?: boolean;
}

const InlineImageStandalone = ({
  attachment,
  file,
  showCaptionText,
  onSave,
  onClose,
  isAnnotating = false,
}: InlineImageStandaloneProps) => {
  const { url } = useAttachment({
    attachment,
    file,
  });

  return (
    <InlineImage
      attachment={attachment}
      showCaptionText={showCaptionText}
      url={url}
      onSave={onSave}
      onClose={onClose}
      isAnnotating={isAnnotating}
    />
  );
};

export default InlineImageStandalone;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import runUtil from 'shared/lib/runUtil';
import { ToolInstance } from 'shared/lib/types/api/manufacturing/tools/models';
import { StepState } from 'shared/lib/types/couch/procedures';
import { UsageTypes } from 'shared/lib/types/postgres/manufacturing/usage_types';
import { RunToolUsageBlock } from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { DatabaseServices } from '../../../contexts/proceduresSlice';
import FieldText from '../../../elements/FieldText';
import apm from '../../../lib/apm';
import { NumberSelectOption as SelectOption } from '../../../lib/formik';
import isNumber from '../../../lib/number';
import { toolDetailPath } from '../../../lib/pathUtil';
import useTool from '../../hooks/useTool';
import useUsageTypes from '../../hooks/useUsageTypes';
import fromClient from '../../lib/tools/fromClient';
import toDisplay from '../../lib/tools/toDisplay';
import { getDisplayTextForTimeBasedUsage } from '../../lib/usage';
import ThumbnailImage from '../ThumbnailImage';
import CompletedToolInstance from './CompletedToolInstance';
import SelectToolInstance from './SelectToolInstance';

export type ContentErrors = {
  value?: string;
};

const INVALID_CYCLES_ERROR = 'Enter a non-negative number';
const INVALID_TIME_ERROR = 'Enter a duration in HH:mm:ss format';

const HHmmss = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;

interface RunToolUsageProps {
  content: RunToolUsageBlock;
  errors?: ContentErrors;
  onRecordValuesChanged?: (contentId: string, recorded: RunToolUsageBlock['recorded']) => void;
  onRecordErrorsChanged?: (errors: ContentErrors) => void;
  recorded?: RunToolUsageBlock['recorded'];
  isEnabled: boolean;
  stepState?: StepState | undefined;
  teamId: string;
}

const RunToolUsage = ({
  content,
  errors,
  onRecordValuesChanged,
  onRecordErrorsChanged,
  recorded,
  isEnabled,
  stepState,
  teamId,
}: RunToolUsageProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { tool } = useTool({ toolId: recorded?.tool_id ?? content.tool_id });
  const { usageTypes } = useUsageTypes();
  const [toolInstancesForTool, setToolInstancesForTool] = useState<Array<ToolInstance>>();
  // captures what is input into the value text field
  const [usageValue, setUsageValue] = useState<string>();

  const value = useMemo(() => recorded ?? content, [content, recorded]);
  const selectedUsageType = usageTypes?.find((usageType) => usageType.id === value.usage_type_id);

  const isStepStateEnded = useMemo(() => runUtil.isStepStateEnded(stepState), [stepState]);

  useEffect(() => {
    setUsageValue(recorded?.value ? formatValue(recorded.value, value.usage_type_id) : '');
  }, [recorded?.value, value.usage_type_id]);

  useEffect(() => {
    (async () => {
      const clientToolInstances = await services.tools.getToolInstances();
      setToolInstancesForTool(
        clientToolInstances
          .filter((clientToolInstance) => clientToolInstance.tool_id === content.tool_id)
          .map(fromClient.toToolInstance)
      );
    })().catch((err) => apm.captureError(err));
  }, [content.tool_id, services.tools]);

  const toolInstance = useMemo(() => {
    if (!toolInstancesForTool || !recorded) {
      return undefined;
    }
    return toolInstancesForTool.find((toolInstance) => toolInstance.id === recorded.tool_instance_id);
  }, [recorded, toolInstancesForTool]);

  const onToolInstanceChange = useCallback(
    (toolInstanceOption: SelectOption) => {
      const newValue: RunToolUsageBlock['recorded'] = {
        ...value,
        tool_instance_id: toolInstanceOption.value,
      };
      if (onRecordValuesChanged) {
        onRecordValuesChanged(value.id, newValue);
      }
    },
    [onRecordValuesChanged, value]
  );

  const checkErrors = (usageValue: string): void => {
    if (!onRecordErrorsChanged || !usageValue) {
      return;
    }
    let newError = {};
    if (value.usage_type_id === UsageTypes.Cycles) {
      if (!isNumber(usageValue) || Number(usageValue) < 0) {
        newError = { value: INVALID_CYCLES_ERROR };
      }
    } else {
      const match = usageValue.match(HHmmss);
      if (match === null) {
        newError = { value: INVALID_TIME_ERROR };
      }
    }
    if (!isEqual(errors, newError)) {
      onRecordErrorsChanged(newError);
    }
  };

  const parseSeconds = (usageValue: string) => {
    const match = usageValue.match(HHmmss);
    if (match === null) {
      return null;
    }
    return Number(match[1] ?? 0) * 3600 + Number(match[2] ?? 0) * 60 + Number(match[3]);
  };

  const toHuman = (seconds: number) => getDisplayTextForTimeBasedUsage(seconds);

  const onUsageValueChange = useCallback(
    (newUsageValue: string) => {
      const newValue: RunToolUsageBlock['recorded'] = {
        ...value,
      };
      if (newUsageValue.length > 0) {
        if (value.usage_type_id === UsageTypes.Cycles) {
          const cycles = parseInt(newUsageValue);
          if (!isNaN(cycles)) {
            newValue.value = cycles;
          }
        } else {
          const parsedSeconds = parseSeconds(newUsageValue);
          if (parsedSeconds) {
            newValue.value = parsedSeconds;
            setUsageValue(formatValue(parsedSeconds, value.usage_type_id));
          }
        }
      } else {
        newValue.value = undefined;
      }
      if (onRecordValuesChanged) {
        onRecordValuesChanged(value.id, newValue);
      }
    },
    [onRecordValuesChanged, value]
  );

  const formatValue = (value: number, usageTypeId: number) => {
    if (usageTypeId === 1) {
      return value.toString();
    }

    const HH = Math.floor(value / 3600);
    const mm = Math.floor((value % 3600) / 60);
    const ss = value % 60;

    const format = (n: number) => (n < 10 ? `0${n}` : `${n}`);
    if (HH === 0) {
      if (mm === 0) {
        return `${format(ss)}`;
      }
      return `${format(mm)}:${format(ss)}`;
    }
    return `${format(HH)}:${format(mm)}:${format(ss)}`;
  };

  const displayValue = useMemo(() => {
    // step is complete, format recorded value
    if (isStepStateEnded && recorded?.value) {
      return recorded.usage_type_id === UsageTypes.Cycles ? `${recorded.value} cycles` : toHuman(recorded.value);
    }

    // step is active and being edited, return current value
    if (usageValue !== undefined) {
      return usageValue;
    }

    if (!recorded?.value) {
      return '';
    }

    // handle page reloads or navigating away from the run and returning; display value can only be set from recorded value
    return formatValue(recorded.value, value.usage_type_id);
  }, [recorded, isStepStateEnded, usageValue, value.usage_type_id]);

  // this is displayed as helper text on the right side of the text input if the step is still active
  const computedValue = useMemo(() => {
    if (isStepStateEnded || !displayValue) {
      return null;
    }
    if (value.usage_type_id === UsageTypes.Cycles) {
      return `${displayValue} cycles`;
    }

    const seconds = parseSeconds(displayValue);
    if (!seconds) {
      return null;
    }

    return toHuman(seconds);
  }, [isStepStateEnded, displayValue, value.usage_type_id]);

  if (!tool || !selectedUsageType) {
    return null;
  }

  return (
    <tr>
      <td></td>
      <td colSpan={2}>
        <div className="mt-3 ml-4">
          <div className="flex flex-row flex-wrap">
            <div className="w-72">
              <div className="field-title">Tool for Recording Usage</div>
              <div className="flex pt-2">
                <div>
                  <ThumbnailImage size="sm" attachment={toDisplay.fromToolImageProps(tool)} />
                </div>
                <div className="pl-1 pr-1 pt-[2px] truncate">
                  <Link className="text-blue-600" to={toolDetailPath(teamId, tool.id)}>
                    {tool.tool_number}
                  </Link>{' '}
                  {tool.name}
                </div>
              </div>
            </div>
            <div className="w-2" />
            <div>
              <div className="field-title">Tool Instance</div>
              <div className="flex">
                {!isStepStateEnded && (
                  <div className="w-72">
                    <SelectToolInstance
                      availableToolInstances={toolInstancesForTool}
                      tool={tool}
                      toolInstance={toolInstance}
                      onToolInstanceChange={onToolInstanceChange}
                      isEnabled={isEnabled}
                    />
                  </div>
                )}
                {isStepStateEnded && <CompletedToolInstance teamId={teamId} tool={tool} toolInstance={toolInstance} />}
              </div>
            </div>
            <div className="w-2" />
            <div className="w-72 mr-2">
              <div className="field-title">Usage Value</div>
              <div className="flex relative">
                <FieldText
                  name="value"
                  value={displayValue}
                  placeholder={value.usage_type_id === UsageTypes.Cycles ? 'Enter cycles' : 'Enter time as HH:mm:ss'}
                  onChange={(e) => {
                    checkErrors(e.target.value);
                    setUsageValue(e.target.value);
                  }}
                  onBlur={(e) => {
                    onUsageValueChange(e.target.value);
                  }}
                  isDisabled={!isEnabled}
                  errorMessage={errors?.value}
                  height="h-[2.4rem]"
                />
                {!isStepStateEnded && <div className="absolute right-2 top-[10px] text-gray-300">{computedValue}</div>}
                {isStepStateEnded &&
                  stepState !== undefined &&
                  ['completed', 'failed'].includes(stepState) &&
                  recorded?.value && (
                    <div className="pl-2 pt-[10px]">
                      <FontAwesomeIcon icon="square-check" className="text-green-500" aria-label="Usage recorded" />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default RunToolUsage;

import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import runUtil from '../../lib/runUtil';
import SummaryStep from './SummaryStep';
import { useProcedureFilter } from '../../contexts/ProcedureFilterContext';
import { groupBatchStepsInRun } from '../../lib/batchSteps';
import { RunStep } from 'shared/lib/types/views/procedures';
import SummaryBatchStep from '../BatchSteps/SummaryBatchStep';

const SummarySection = ({ section, sectionKey, stepKeyFormatter, repeatKey, onRefChanged }) => {
  const { isSectionFiltered } = useProcedureFilter();

  if (!isSectionFiltered(section.id)) {
    return null;
  }

  const steps = groupBatchStepsInRun(section.steps ?? []);

  return (
    <Fragment>
      <tr>
        <td></td>
        <td colSpan={2}>
          <div
            className="flex flex-row justify-between items-center my-2 ml-4 page-break"
            style={{ scrollMarginTop: '4rem' }}
          >
            {/* Section name */}
            <div className="text-2xl text-left">
              <span>
                Section {sectionKey}: {section && section.name}
              </span>
              {repeatKey && (
                <Fragment>
                  <span className="ml-4 text-base text-gray-600">
                    <FontAwesomeIcon icon="redo" />
                  </span>
                  <span className="ml-1 text-base font-bold text-gray-600 italic">Repeat {repeatKey}</span>
                </Fragment>
              )}
            </div>
          </div>
        </td>
      </tr>
      {steps.map((stepOrBatch, stepIndex) => {
        if (Array.isArray(stepOrBatch)) {
          const batch = stepOrBatch;
          return (
            <SummaryBatchStep
              steps={batch}
              sectionStepKey={stepKeyFormatter(stepIndex)}
              sectionId={section.id}
              onRefChanged={onRefChanged}
            />
          );
        }

        const step = stepOrBatch as RunStep;
        return (
          <SummaryStep
            key={step.id}
            repeatKey={runUtil.displayRepeatKey(section.steps, stepIndex)}
            sectionStepKey={stepKeyFormatter(stepIndex)}
            sectionId={section.id}
            step={step}
            onRefChanged={onRefChanged}
          />
        );
      })}
    </Fragment>
  );
};

export default SummarySection;

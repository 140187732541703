import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateHiddenClassString } from '../../lib/styles';
import { MouseEventHandler } from 'react';

interface ExpandCollapseCaretProps {
  isHidden?: boolean;
  isExpanded: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  ariaLabel: string;
  customClasses?: string;
}

const ExpandCollapseCaret = ({
  isHidden = false,
  isExpanded,
  onClick,
  ariaLabel,
  customClasses = 'max-h-14',
}: ExpandCollapseCaretProps) => {
  const baseClasses = 'cursor-pointer px-2 justify-center items-center h-full w-8 shrink-0 page-break';
  const className = `${baseClasses} ${customClasses || ''}`;

  return (
    <button
      type="button"
      className={generateHiddenClassString(className, isHidden)}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      <FontAwesomeIcon
        icon={isExpanded ? 'caret-down' : 'caret-right'}
        title={isExpanded ? 'collapse' : 'expand'}
        className="text-black"
      />
    </button>
  );
};

export default ExpandCollapseCaret;

import React from 'react';

/**
 * Renders the action bar portion for a fullscreen, blocking modal.
 *
 * A full modal will use ModalBase, ModelContent, and ModelActionBar.
 */
const ModalActionBar = ({ children }) => {
  return <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-lg">{children}</div>;
};

export default ModalActionBar;

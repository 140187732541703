import { useCallback, useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

export type HeaderScrollContext = { headerId: string };
export type StepScrollContext = {
  sectionId: string;
  stepId: string;
};
type ContentScrollContext = StepScrollContext & { contentId: string };
export type ScrollContext = HeaderScrollContext | StepScrollContext | ContentScrollContext;

type FieldEntry = { fieldName: string };
export type HeaderFieldScrollEntry = HeaderScrollContext & FieldEntry;
export type StepFieldScrollEntry = StepScrollContext & FieldEntry;
type FieldScrollEntry = HeaderFieldScrollEntry | StepFieldScrollEntry;

type IdEntry = { scrollToId: string };
type HeaderIdScrollEntry = HeaderScrollContext & IdEntry;
type StepIdScrollEntry = StepScrollContext & IdEntry;
type ContentIdScrollEntry = ContentScrollContext & IdEntry;
export type IdScrollEntry = HeaderIdScrollEntry | StepIdScrollEntry | ContentIdScrollEntry;

export type ScrollEntry = FieldScrollEntry | IdScrollEntry;

export interface UseScrollProps {
  list: Array<ScrollEntry>;
  goToField: (ScrollEntry) => void;
}

const useScroll = ({ list, goToField }: UseScrollProps) => {
  const currentIndex = useRef<null | number>(null);
  const offsetIndex = useRef<number>(0);
  const oldListRef = useRef<Array<ScrollEntry>>([]);

  /**
   * Recalibrate the current index if the list size changes.
   *
   * If an entry is removed from the list:
   *  If the entry of the current index does not exist:
   *     set the offset index to be the old current index,
   *     set the current index to null (so that the "placeholder index" is where the old entry used to be).
   *  Else if new index of the entry is greater than the old index of the entry:
   *     set the new current index to correspond to the index of the existing entry that was at the old current index.
   *
   * If an entry is added to the list:
   *  set the new current index to correspond to the index of the existing entry that was at the old current index.
   */
  useEffect(() => {
    if (currentIndex.current !== null && oldListRef.current.length !== list.length) {
      const oldEntry = oldListRef.current[currentIndex.current];
      const newIndexOfOldEntry = list.findIndex((entry) => isEqual(entry, oldEntry));
      if (newIndexOfOldEntry === -1) {
        offsetIndex.current = currentIndex.current % list.length; // Take the mod in case the current index is outside the bounds of the new list.
        currentIndex.current = null;
      } else if (newIndexOfOldEntry > currentIndex.current) {
        // Only need to reset the current index if the new index is greater than the current index.
        currentIndex.current = newIndexOfOldEntry;
      }
    }
    oldListRef.current = list;
  }, [list]);

  const onGoToPrevious = useCallback(() => {
    if (list.length === 0) {
      return;
    }
    const updatedIndex =
      currentIndex.current === null
        ? (offsetIndex.current + list.length - 1) % list.length
        : (currentIndex.current + list.length - 1) % list.length;

    const entry = list[updatedIndex];
    currentIndex.current = updatedIndex;
    goToField(entry);
  }, [list, currentIndex, goToField]);

  const onGoToNext = useCallback(() => {
    if (list.length === 0) {
      return;
    }
    const updatedIndex = currentIndex.current === null ? offsetIndex.current : (currentIndex.current + 1) % list.length;

    const entry = list[updatedIndex];
    currentIndex.current = updatedIndex;
    goToField(entry);
  }, [list, currentIndex, goToField]);

  return {
    onGoToPrevious,
    onGoToNext,
  };
};

export default useScroll;

import React from 'react';
import { RunsTableRowModel } from '../types';
import RunNameLinkRenderer from '../../../elements/renderers/RunNameLinkRenderer';

const renderRunLink = ({ row }: { row: RunsTableRowModel }) => {
  const isChild = row.isChild;
  const isLastChild = row.isLastChild;
  const link = row.runLink.link;
  const code = row.runLink.code;
  const runNumber = row.runLink.run_number;
  const name = row.runLink.name;
  const tags = (row.runLink.tags ?? []).concat(row.runLink.runTags ?? []);

  return (
    <RunNameLinkRenderer
      isChild={isChild}
      isLastChild={isLastChild}
      link={link}
      code={code}
      runNumber={runNumber}
      name={name}
      tags={tags}
    />
  );
};

export default renderRunLink;

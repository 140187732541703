import { useEffect, useState, useCallback } from 'react';
import { useUserInfo } from '../contexts/UserContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { getPendingProcedureIndex } from 'shared/lib/procedureUtil';
import SelfEditConflictModal from './SelfEditConflictModal';
import OtherUserEditConflictModal from './OtherUserEditConflictModal';
import apm from '../lib/apm';

type EditConflictModalProps = {
  procedureId: string;
  onHide: () => void;
};

const EditConflictModal = ({ procedureId, onHide }: EditConflictModalProps) => {
  const [editedUserId, setEditedUserId] = useState(null);
  const { services } = useDatabaseServices();
  const { userInfo } = useUserInfo();
  const userId = userInfo.session.user_id;

  const getEditedUserId = useCallback(async () => {
    const draftIndex = getPendingProcedureIndex(procedureId);
    const procedure = await services.procedures.getProcedure(draftIndex);
    return procedure.editedUserId;
  }, [procedureId, services.procedures]);

  useEffect(() => {
    getEditedUserId()
      .then(setEditedUserId)
      .catch((err) => apm.captureError(err));
  }, [getEditedUserId]);

  if (!editedUserId) {
    return null;
  }

  if (editedUserId === userId) {
    return <SelfEditConflictModal onHide={onHide} />;
  }

  return <OtherUserEditConflictModal editedUserId={editedUserId} procedureId={procedureId} onHide={onHide} />;
};

export default EditConflictModal;

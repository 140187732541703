import React from 'react';
import { useGanttContext } from '../../../components/Gantt/GanttContext';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
import { GANTT_ROW_HEIGHT } from '../../../config';
import GanttEvent from '../GanttEvent';
import MultiEvent from '../../../lib/MultiEvent';

interface RowProps {
  events: Array<Event | MultiEvent>;
  expandSwimlane: () => void;
}

const Row = ({ events, expandSwimlane }: RowProps) => {
  const { dateToXPos } = useGanttContext();

  return (
    <div className="relative" style={{ height: `${GANTT_ROW_HEIGHT}px` }}>
      {events.map((event, idx) => (
        <div
          key={idx}
          className="absolute bg-transparent h-full"
          style={{
            transform: `translate3d(${dateToXPos(event.start)}px, 0px, 0px)`,
          }}
        >
          <GanttEvent event={event} expandSwimlane={expandSwimlane} />
        </div>
      ))}
    </div>
  );
};

export default Row;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import TimeAgoDisplay from './TimeAgoDisplay';
import { MILLISECONDS_PER_HOUR, MILLISECONDS_PER_MINUTE, MILLISECONDS_PER_SECOND } from '../../lib/datetime';

const UPDATE_INTERVAL_FOR_SECONDS_MS = 5 * MILLISECONDS_PER_SECOND;
const UPDATE_INTERVAL_FOR_MINUTES_MS = 1 * MILLISECONDS_PER_MINUTE;
const UPDATE_INTERVAL_FOR_HOURS_MS = 1 * MILLISECONDS_PER_HOUR;

interface TimeAgoDisplayUpdatingProps {
  timestamp: Date;
}
/**
 * A component for displaying the difference between two timestamps
 * that updates at a set interval.
 */
const TimeAgoDisplayUpdating = React.memo(({ timestamp }: TimeAgoDisplayUpdatingProps) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const currentTimeIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  // If the timestamp changes, clear the interval
  useEffect(() => {
    if (currentTimeIntervalRef.current) {
      clearInterval(currentTimeIntervalRef.current);
    }
  }, [timestamp]);

  // Get an update interval that corresponds to the magnitude of the time difference, to reduce unnecessary re-renders
  const updateIntervalMs = useMemo(() => {
    const timeDifferenceMs = currentTime.getTime() - timestamp.getTime();

    if (timeDifferenceMs < MILLISECONDS_PER_MINUTE) {
      return UPDATE_INTERVAL_FOR_SECONDS_MS;
    }

    if (timeDifferenceMs < MILLISECONDS_PER_HOUR) {
      return UPDATE_INTERVAL_FOR_MINUTES_MS;
    }

    return UPDATE_INTERVAL_FOR_HOURS_MS;
  }, [currentTime, timestamp]);

  // Set the interval at which the time ago display will be updated
  useEffect(() => {
    currentTimeIntervalRef.current = setInterval(() => setCurrentTime(new Date()), updateIntervalMs);

    return () => {
      if (currentTimeIntervalRef.current) {
        clearInterval(currentTimeIntervalRef.current);
      }
    };
  }, [currentTime, timestamp, updateIntervalMs]);

  return <TimeAgoDisplay timestamp={timestamp} currentTime={currentTime} />;
});

export default TimeAgoDisplayUpdating;

import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import superlogin from '../api/superlogin';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { DatabaseServices } from '../contexts/proceduresSlice';
import apm from '../lib/apm';

const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Required'),
  newPassword: Yup.string().required('Required'),
  confirmPassword: Yup.string().required('Required'),
});

const ChangePassword = () => {
  const history = useHistory();
  const [globalErrors, setGlobalErrors] = useState<string | null>(null);
  const { updateTeamIds } = useDatabaseServices();
  const { services }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();

  const verifyNewPass = async (newPassword: string, setFieldError): Promise<boolean> => {
    if (newPassword) {
      try {
        const response = await services.passwords.verifyNewPassword(newPassword);
        if (!response.isNewPassword) {
          setFieldError('newPassword', 'New password must not be a previously used password');
          return false;
        } else {
          setGlobalErrors(null);
          return true;
        }
      } catch (error) {
        setFieldError('newPassword', error?.validationErrors?.newPassword?.join(', '));
        return true;
      }
    }
    return true;
  };

  const onSubmitChangePassword = async (values, { setFieldError }) => {
    const isNewPassword = await verifyNewPass(values.newPassword, setFieldError);
    if (isNewPassword) {
      setGlobalErrors(null);
      return superlogin
        .changePassword(values)
        .then(async () => {
          /*
           * Logout user
           * TODO: Centralize logout functionality
           */
          updateTeamIds([]);
          superlogin.logout().catch((err) => apm.captureError(err));
          history.push({
            pathname: '/login',
            state: { flash: 'Password changed, please login.' },
          });
          await services.passwords.storeNewPassword(values.newPassword);
        })

        .catch((error) => {
          if (error.validationErrors) {
            if (error.validationErrors.newPassword) {
              setFieldError('newPassword', error.validationErrors.newPassword.join(', '));
            }
            if (error.validationErrors.confirmPassword) {
              setFieldError('confirmPassword', error.validationErrors.confirmPassword.join(', '));
            }
          } else if (error.message) {
            setGlobalErrors(error.message);
          } else if (typeof error === 'string') {
            setGlobalErrors(error);
          }
        });
    }
  };

  return (
    <div className="px-12 py-4">
      {/* Sets the document title */}
      <Helmet>
        <title>Change password</title>
      </Helmet>

      <div className="items-start max-w-md mx-auto">
        <h1 className="mb-4">Change your account password</h1>
        <Formik
          initialValues={{
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
          }}
          validationSchema={ChangePasswordSchema}
          onSubmit={onSubmitChangePassword}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div className="flex flex-col">
                <label htmlFor="currentPassword">Current password</label>
                <Field
                  id="currentPassword"
                  name="currentPassword"
                  placeholder="Current password"
                  type="password"
                  className="w-full border-1 border-gray-400 rounded "
                />
                {errors.currentPassword && touched.currentPassword ? (
                  <div className="text-red-700">{errors.currentPassword}</div>
                ) : null}

                <label htmlFor="newPassword" className="mt-2">
                  New Password
                </label>
                <Field
                  id="newPassword"
                  name="newPassword"
                  placeholder="New Password"
                  type="password"
                  className="w-full border-1 border-gray-400 rounded"
                />
                {errors.newPassword && touched.newPassword ? (
                  <div className="text-red-700">{errors.newPassword}</div>
                ) : null}

                <label htmlFor="confirmPassword" className="mt-2">
                  Confirm Password
                </label>
                <Field
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  type="password"
                  className="w-full border-1 border-gray-400 rounded"
                />
                {errors.confirmPassword && touched.confirmPassword ? (
                  <div className="text-red-700">{errors.confirmPassword}</div>
                ) : null}

                {globalErrors && <p className="self-center mt-2 text-red-700">{globalErrors}</p>}
                <button className="btn self-center mt-2" type="submit" disabled={isSubmitting ? true : undefined}>
                  Change Password
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;

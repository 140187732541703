import React, { useCallback, useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import debounce from 'lodash.debounce';
import externalDataUtil from '../../lib/externalDataUtil';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { reactSelectStyles } from '../../lib/styles';
import { useRunContext } from '../../contexts/RunContext';

const LabeledClearIndicator = ({ children, ...props }) => {
  const innerProps = {
    ...props.innerProps,
    'aria-label': 'Clear selection',
  };
  return (
    <components.ClearIndicator {...props} innerProps={innerProps}>
      {children}
    </components.ClearIndicator>
  );
};

const itemToOption = (item) => {
  if (!item) {
    return null;
  }
  const label = externalDataUtil.getItemLabel(item);
  return {
    value: item.id,
    label,
    item,
  };
};

const ExternalItemSelectFieldSet = ({ itemType, itemDictionary, isDisabled, value, field, onChange }) => {
  const {
    services: { externalData },
  } = useDatabaseServices();
  const [selectData, setSelectData] = useState({
    options: [],
    isLoading: false,
    inputValue: '',
  });
  const runContext = useRunContext();

  const selectValue = useMemo(() => itemToOption(value), [value]);

  const onChangeHandler = (option) => {
    if (!option || !option.item) {
      onChange(null);
      return;
    }
    // Skip onChange when re-selected the same value.
    if (value && value.id === option.item.id) {
      return;
    }

    onChange(option.item);
  };

  const searchItemOptions = useCallback(
    (query) => {
      const runId = runContext.run && runContext.run._id;
      return externalData
        .searchItems(itemType, itemDictionary, query, runId)
        .then((data) => data?.items.map((item) => itemToOption(item)));
    },
    [externalData, itemDictionary, itemType, runContext.run]
  );

  const loadOptions = useCallback(
    (inputValue) => {
      searchItemOptions(inputValue).then((options) => {
        setSelectData((data) => {
          if (data.inputValue !== inputValue) {
            return data;
          }
          return {
            options,
            inputValue,
            isLoading: false,
          };
        });
      });
    },
    [searchItemOptions]
  );

  const debouncedLoadOptions = useMemo(() => debounce((query) => loadOptions(query), 300), [loadOptions]);

  const loadOptionsHandler = useCallback(
    (inputValue) => {
      setSelectData({
        options: [],
        inputValue,
        isLoading: true,
      });
      debouncedLoadOptions(inputValue);
    },
    [debouncedLoadOptions]
  );

  const onInputChange = useCallback(
    (inputValue, { action }) => {
      // See https://react-select.com/props#statemanager-props
      if (action !== 'input-change') {
        return;
      }
      loadOptionsHandler(inputValue);
    },
    [loadOptionsHandler]
  );

  const onMenuOpen = useCallback(() => loadOptionsHandler(''), [loadOptionsHandler]);

  return (
    <div className="grow w-full">
      <div className="grow relative">
        <div>
          <Select
            key={itemType}
            styles={reactSelectStyles}
            classNamePrefix="react-select"
            components={{ ClearIndicator: LabeledClearIndicator }}
            name={field.name}
            onBlur={field.onBlur}
            isLoading={selectData.isLoading}
            options={selectData.isLoading ? [] : selectData.options}
            onChange={onChangeHandler}
            onInputChange={onInputChange}
            onMenuOpen={onMenuOpen}
            placeholder="Search or select item"
            value={selectValue}
            isDisabled={isDisabled || !itemType}
            isClearable={true}
            defaultOptions
          />
        </div>
      </div>
    </div>
  );
};

export default ExternalItemSelectFieldSet;

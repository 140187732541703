import { useCallback, useEffect, useState } from 'react';
import { Location, LocationMap } from '../types';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useSettings } from '../../contexts/SettingsContext';
import useItems from './useItems';

interface LocationsState {
  locations?: LocationMap;
  refreshLocations?: () => void;
  getLocation: (locationId: string) => Location | undefined;
  getLocationLabel: (locationId: string) => string | undefined;
  isLocationInUse: (nodeAndChildrenIds: string[]) => boolean;
}

const getChildNodeMap = (locations: LocationMap) => {
  return Object.values(locations).reduce((map, location) => {
    if (!map[location.parent]) {
      map[location.parent] = {};
    }
    map[location.parent][location.id] = location;
    return map;
  }, {});
};

const updateComputedValuesRecursive = (prefix, location, childNodeMap) => {
  const code = (prefix ? `${prefix}-` : prefix) + location.code;
  const children = Object.values(childNodeMap[location.id] || {});

  location.full_code = code;
  location.items_enabled = !children.length;

  for (const child of children) {
    updateComputedValuesRecursive(code, child, childNodeMap);
  }
};

const updateComputedValues = (locations: LocationMap) => {
  const childNodeMap = getChildNodeMap(locations);
  // Find root location nodes
  const rootNodes = Object.values(locations).filter(
    (location) => !location.parent
  );
  for (const rootNode of rootNodes) {
    updateComputedValuesRecursive('', rootNode, childNodeMap);
  }
};

const useLocations = (): LocationsState => {
  const [locations, setLocations] = useState<LocationMap>();
  const { services, currentTeamId } = useDatabaseServices();
  const { isManufacturingEnabled } = useSettings();
  const { allItems } = useItems();

  const refreshLocations = useCallback(() => {
    services.manufacturing
      .getLocations()
      .then((locations) => {
        const defaultLocations = locations ?? {};
        updateComputedValues(defaultLocations);
        setLocations(defaultLocations);
      })
      .catch((error) => undefined);
  }, [services.manufacturing]);

  useEffect(() => {
    if (!isManufacturingEnabled?.()) {
      return;
    }
    refreshLocations();
  }, [
    services.manufacturing,
    currentTeamId,
    isManufacturingEnabled,
    refreshLocations,
  ]);

  const getLocation = (locationId) => locations?.[locationId];

  const getLocationLabel = (locationId) => {
    if (!locationId) {
      return;
    }
    const location = getLocation(locationId);
    if (!location) {
      return;
    }
    return `${location.full_code} ${location.name}`;
  };

  const isLocationInUse = (nodeAndChildrenIds: string[]) => {
    for (const item of allItems ? allItems : []) {
      if (
        item.amount > 0 &&
        item.location_id !== undefined &&
        nodeAndChildrenIds.includes(item.location_id)
      ) {
        return true;
      }
    }
    return false;
  };

  return {
    locations,
    refreshLocations,
    getLocation,
    getLocationLabel,
    isLocationInUse,
  };
};

export default useLocations;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ThreeDotMenu from '../elements/ThreeDotMenu';
import { MenuContextAction } from './MenuContext';
import { DateTime } from 'luxon';
import { useUserInfo } from '../contexts/UserContext';
import { EDIT_COMMENT_ALLOWED_MINUTES_AFTER } from 'shared/lib/comment';

interface EditCommentButtonProps {
  comment;
  onEdit: (comment) => void;
}

const EditCommentButton = ({ comment, onEdit }: EditCommentButtonProps) => {
  const { userInfo } = useUserInfo();
  const [timeHasElapsed, setTimeHasElapsed] = useState<boolean>(false);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const updateDiff = useCallback(() => {
    const commentTime = DateTime.fromISO(comment.timestamp ?? comment.created_at);
    const currentTime = DateTime.now();
    const diffMinutes = currentTime.diff(commentTime, 'minutes').minutes;

    if (diffMinutes >= EDIT_COMMENT_ALLOWED_MINUTES_AFTER) {
      setTimeHasElapsed(true);
    }
  }, [comment.timestamp, comment.created_at]);

  useEffect(() => {
    if (!timeHasElapsed) {
      updateDiff();
      intervalIdRef.current = setInterval(updateDiff, 60000);

      return () => {
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
        }
      };
    }
  }, [updateDiff, timeHasElapsed]);

  useEffect(() => {
    if (timeHasElapsed && intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  }, [timeHasElapsed]);

  const menuActions: Array<MenuContextAction> = [
    {
      type: 'label',
      label: 'Edit Comment',
      data: {
        icon: 'pencil',
        title: 'Edit Comment',
        disabled: undefined,
        onClick: () => onEdit(comment),
      },
    },
  ];

  const userId = useMemo(() => userInfo?.session?.user_id, [userInfo]);

  const showThreeDot = useMemo(
    () => (userId === comment.user || userId === comment.user_id) && !timeHasElapsed && !comment.attachment && onEdit,
    [userId, comment.user, comment.user_id, comment.attachment, timeHasElapsed, onEdit]
  );

  return showThreeDot ? (
    <ThreeDotMenu
      menuActions={menuActions}
      menuLabel="Edit comment menu"
      hasPadding={false}
      buttonSize="sm"
      boxSize="narrow"
      isHorizontal={true}
    />
  ) : null;
};

export default EditCommentButton;

import React, { useRef, useState, useEffect } from 'react';
import Tooltip from '../../elements/Tooltip';

export interface TitleProps {
  title: string;
}

const Title = ({ title }: TitleProps) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (spanRef.current) {
      setIsTruncated(spanRef.current.scrollWidth > spanRef.current.clientWidth);
    }
  }, []);

  return (
    <Tooltip content={title} visible={isTruncated}>
      <span ref={spanRef} className={`truncate max-w-96 ${isTruncated ? 'pt-1.5 inline-block' : ''}`}>
        {title}
      </span>
    </Tooltip>
  );
};

export default React.memo(Title);

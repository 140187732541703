import { useMemo } from 'react';
import SelectDisplay from './SelectDisplay';
import { useSettings } from '../../../contexts/SettingsContext';
import { FieldInputSettingsListBlock } from 'shared/lib/types/views/procedures';

interface SettingsListDisplayProps {
  block: FieldInputSettingsListBlock;
  referenceId?: string;
  referenceFieldIndex?: number;
}

const SettingsListDisplay = ({ block, referenceId, referenceFieldIndex }: SettingsListDisplayProps) => {
  const { config } = useSettings();

  const values = useMemo(
    () => config?.lists && config.lists[block.list] && config.lists[block.list].options,
    [block.list, config?.lists]
  );

  return (
    <SelectDisplay block={block} values={values} referenceId={referenceId} referenceFieldIndex={referenceFieldIndex} />
  );
};

export default SettingsListDisplay;

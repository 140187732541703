import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusSquare } from '@fortawesome/free-regular-svg-icons';

interface CloseRunPlotIconProps {
  onClick: () => void;
}

const CloseRunPlotIcon = ({ onClick }: CloseRunPlotIconProps) => {
  return (
    <FontAwesomeIcon
      className="text-gray-500 hover:text-gray-600 hover:cursor-pointer"
      icon={faMinusSquare}
      aria-label="Close Run Plot"
      onClick={onClick}
    />
  );
};

export default CloseRunPlotIcon;

import { useMemo } from 'react';
import { faCircleCheck as fasCircleCheck, faCircleXmark as fasCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { faCircleInfo as fasCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface RuleStatusIconProps {
  isPassing: boolean | null;
}

const RuleStatusIcon = ({ isPassing = null }: RuleStatusIconProps) => {
  const icon = useMemo(() => {
    if (isPassing) {
      return fasCircleCheck;
    }
    if (isPassing === false) {
      return fasCircleXmark;
    }
    return fasCircleInfo;
  }, [isPassing]);

  const className = useMemo(() => {
    if (isPassing) {
      return 'text-green-600';
    }

    if (isPassing === false) {
      return 'text-red-600';
    }

    return 'text-gray-400';
  }, [isPassing]);

  const ruleStatus = useMemo(() => {
    if (isPassing) {
      return 'pass';
    }

    if (isPassing === false) {
      return 'fail';
    }

    return 'incomplete';
  }, [isPassing]);

  return <FontAwesomeIcon icon={icon} className={className} aria-label={`rule status: ${ruleStatus}`} />;
};

export default RuleStatusIcon;

import { cloneDeep } from 'lodash';
import {
  BatchStepProps,
  Procedure,
  ReleaseStep,
  Run,
  RunStep,
  Section,
  Step,
} from 'shared/lib/types/views/procedures';
import procedureUtil from './procedureUtil';
import idUtil from './idUtil';

export const canBatchStepsInProcedure = (procedure: Procedure): boolean => {
  return !!procedure.part_list?.part?.id;
};

export const isProcedureWithBatchSteps = (procedure: Procedure): boolean => {
  if (!canBatchStepsInProcedure(procedure)) {
    return false;
  }

  for (const section of procedure.sections) {
    for (const step of section.steps) {
      if ('runAsBatch' in step && step.runAsBatch === true) {
        return true;
      }
    }
  }
  return false;
};

export const isBatchStep = (procedure: Procedure, step: Step): boolean => {
  return canBatchStepsInProcedure(procedure) && step.runAsBatch === true;
};

export const prepareBatchStepsForRun = (
  procedure: Procedure,
  batchSize: number
): void => {
  if (!canBatchStepsInProcedure(procedure)) {
    return;
  }

  procedure.sections.forEach((section: Section) => {
    let stepIndex = 0;
    let stepCount = section.steps.length;
    while (stepIndex < stepCount) {
      const step: ReleaseStep = section.steps[stepIndex++];

      if (!step.runAsBatch) {
        continue;
      }
      if (batchSize < 2) {
        step.runAsBatch = false;
        continue;
      }

      const batchId = `batch_${idUtil.generateId()}`;
      step.batchProps = {
        batchSize,
        batchId,
        // place the original step last in batch
        index: batchSize - 1,
        instanceId: `${batchSize}`,
      };
      for (let i = 0; i < batchSize - 1; i++) {
        const clonedStep = cloneDeep(step);
        procedureUtil.updateStepWithNewIds(clonedStep);
        const clonedBatchProps = clonedStep.batchProps as BatchStepProps;
        clonedBatchProps.index = i;
        clonedBatchProps.instanceId = `${i + 1}`;

        // place cloned steps before the original step in batch
        section.steps.splice(stepIndex - 1, 0, clonedStep);
        stepIndex++;
        stepCount++;
      }
    }
  });
};

export const groupBatchStepsInRun = (
  steps: RunStep[]
): Array<RunStep | RunStep[]> => {
  const stepsAndBatchGroups: Array<RunStep | RunStep[]> = [];

  for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
    const step = steps[stepIndex];
    if (!step.runAsBatch || !step.batchProps) {
      stepsAndBatchGroups.push(step);
      continue;
    }

    const batchGroup: RunStep[] = [step];
    const batchId = step.batchProps.batchId;
    while (steps[stepIndex + 1]?.batchProps?.batchId === batchId) {
      batchGroup.push(steps[++stepIndex]);
    }
    stepsAndBatchGroups.push(batchGroup);
  }

  return stepsAndBatchGroups;
};

export const stepOrBatchMatchesStepId = (
  stepId: string,
  stepOrBatch: RunStep | RunStep[]
): boolean => {
  if (Array.isArray(stepOrBatch)) {
    const batch = stepOrBatch;
    return batch.findIndex((batchStep) => batchStep.id === stepId) !== -1;
  } else {
    const step = stepOrBatch;
    return step.id === stepId;
  }
};

export const getStepDisplayIndex = (
  steps: RunStep[],
  rawIndex: number
): number => {
  const stepToFind = steps[rawIndex];
  const stepsAndBatches = groupBatchStepsInRun(steps);
  for (let index = 0; index < stepsAndBatches.length; index++) {
    const stepOrBatch = stepsAndBatches[index];
    if (stepOrBatchMatchesStepId(stepToFind.id, stepOrBatch)) {
      return index;
    }
  }
  throw new Error(
    `Error computing display index for step (raw index = ${rawIndex}`
  );
};

export const getPreviousStepInBatch = (
  run: Run,
  currentBatchProps: BatchStepProps
): RunStep | undefined => {
  for (const section of run.sections) {
    const stepsAndBatches = groupBatchStepsInRun(section.steps);
    for (const stepOrBatch of stepsAndBatches) {
      if (!Array.isArray(stepOrBatch)) {
        continue;
      }
      const batch = stepOrBatch;
      if (batch[0].batchProps?.batchId !== currentBatchProps.batchId) {
        continue;
      }
      if (batch[0].batchProps.instanceId === currentBatchProps.instanceId) {
        return;
      }
      for (let i = 1; i < batch.length; i++) {
        if (batch[i].batchProps?.instanceId === currentBatchProps.instanceId) {
          return batch[i - 1];
        }
      }
    }
  }
};

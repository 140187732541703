import { useMemo } from 'react';
import Select from 'react-select';
import { reactSelectStyles } from '../lib/styles';
import runUtil from '../lib/runUtil';
import { useRunContext } from '../contexts/RunContext';

export default function RunFilter({ filterSelectedOperators, setFilterSelectedOperators, operatorRoles, docState }) {
  const { isSingleCardEnabled } = useRunContext();

  const onChange = (options) => {
    if (operatorRoles.length === 0) {
      return;
    }
    setFilterSelectedOperators(options.map((option) => option.value));
  };

  const disabled = operatorRoles.length === 0;

  const operatorToOption = (op) => {
    return op
      ? {
          value: op,
          label: op,
        }
      : null;
  };

  const selectOptions = useMemo(() => {
    return operatorRoles.map(operatorToOption);
  }, [operatorRoles]);
  const value = filterSelectedOperators.map((operator) => operatorToOption(operator));

  if (!runUtil.isRunStateActive(docState)) {
    return null;
  }

  return (
    <>
      {!isSingleCardEnabled && (
        <div className="text-right text-sm uppercase font-bold mt-6 relative">
          <span className="mr-4">Filter by required operator</span>

          <Select
            classNamePrefix="react-select"
            className="w-4/12 inline-block text-left"
            placeholder="All"
            styles={reactSelectStyles}
            value={value}
            isDisabled={disabled}
            isClearable
            isMulti
            options={selectOptions}
            onChange={onChange}
            aria-label="Run Operator Filter Selector"
          />
        </div>
      )}
    </>
  );
}

import { ORG_SESSION_TIMEOUT_SECONDS } from 'shared/lib/constants/session';
import {
  E3StripeSubscription,
  E3StripeSubscriptionUpdate,
  E3StripeSubscriptionUpdateQuote,
} from 'shared/lib/types/api/organizations/billing/models';
import {
  GenerateBillingPortalUrlRes,
  IsBillingPortalEnabledRes,
  ListActiveSubscriptionsRes,
  PreviewStripeSubscritionUpdateRes,
} from 'shared/lib/types/api/organizations/billing/responses';
import { OrganizationSettings } from 'shared/lib/types/settings';
import { OrgUsersType } from '../components/Settings/types';
import { API_URL } from '../config';
import superlogin from './superlogin';

class OrgService {
  orgId: string;

  constructor(orgId: string) {
    this.orgId = orgId;
  }

  async listUsers(): Promise<OrgUsersType> {
    const url = `${API_URL}/organizations/${this.orgId}/users`;
    const users = await superlogin.getHttp().get(url);
    return users.data;
  }

  async updateUsers(
    emails: Array<string>,
    organizationRole: string | null
  ): Promise<void> {
    const url = `${API_URL}/organizations/${this.orgId}/users`;
    const body = {
      ids: emails,
      organization_role_id: organizationRole,
    };

    return superlogin.getHttp().patch(url, body);
  }

  /**
   * Fetches the number of billable users.
   */
  async getBillableUsers(): Promise<number> {
    const url = `${API_URL}/organizations/${this.orgId}/billable-users-count`;
    const response = await superlogin.getHttp().get(url, {});
    return response.data.billableUsers;
  }

  /**
   * Fetches the number of non-viewer users.
   */
  async getNonViewerUsers(): Promise<number> {
    const url = `${API_URL}/organizations/${this.orgId}/nonviewer-users-count`;
    const response = await superlogin.getHttp().get(url, {});
    return response.data.nonviewerUsers;
  }

  /**
   * Fetches the number of billable users and non-viewer users.
   */
  async getAllUsers(): Promise<[number, number]> {
    const url = `${API_URL}/organizations/${this.orgId}/billable-users-count`;
    const url2 = `${API_URL}/organizations/${this.orgId}/nonviewer-users-count`;
    const [response, response2] = await Promise.all([
      superlogin.getHttp().get(url, {}),
      superlogin.getHttp().get(url2, {}),
    ]);
    return [response.data.billableUsers, response2.data.nonviewerUsers];
  }

  /**
   * Queries whether generating api keys is enabled for a given org.
   */
  async isApiKeyGenerationEnabled(): Promise<boolean> {
    const url = `${API_URL}/organizations/${this.orgId}/api-key-generation-enabled`;
    const response = await superlogin.getHttp().get(url, {});
    return response.data.enabled;
  }

  /**
   * Queries whether inviting and removing users is enabled for a given org.
   */
  async isInviteRemoveUsersDisabled(): Promise<boolean> {
    const url = `${API_URL}/organizations/${this.orgId}/invite-remove-users-disabled`;
    const response = await superlogin.getHttp().get(url, {});
    return response.data.disabled;
  }

  /**
   * Indicates whether the Billing section should be rendered
   */
  async isBillingPortalEnabled(): Promise<boolean> {
    const url = `${API_URL}/organizations/${this.orgId}/billing-portal-enabled`;
    const response = await superlogin
      .getHttp()
      .get<IsBillingPortalEnabledRes>(url, {});
    return response.data.enabled;
  }

  /**
   * Generates the Billing Portal URL for user's org.
   */
  async generateBillingPortalUrl({
    redirectUrl,
  }: {
    redirectUrl: string;
  }): Promise<string> {
    const url = `${API_URL}/organizations/${this.orgId}/billing-portal-url-generation`;
    const response = await superlogin
      .getHttp()
      .post<GenerateBillingPortalUrlRes>(url, { redirectUrl });
    return response.data.billingPortalUrl;
  }

  /**
   * Lists active subscriptions in Stripe for user's org.
   */
  async getActiveStripeSubscriptions(): Promise<E3StripeSubscription[]> {
    const url = `${API_URL}/organizations/${this.orgId}/active-stripe-subscriptions`;
    const response = await superlogin
      .getHttp()
      .get<ListActiveSubscriptionsRes>(url, {});
    return response.data.subscriptions;
  }

  /**
   * Gets a quote for updating subscription in Stripe for user's org.
   */
  async previewStripeSubscritionUpdate(
    subscriptionId: string,
    itemId: string,
    newQuantity: number
  ): Promise<E3StripeSubscriptionUpdateQuote> {
    const params = new URLSearchParams();
    params.append('subscriptionId', subscriptionId);
    params.append('itemId', itemId);
    params.append('newQuantity', `${newQuantity}`);

    const url = `${API_URL}/organizations/${
      this.orgId
    }/stripe-subscription-update-quote?${params.toString()}`;

    const response = await superlogin
      .getHttp()
      .get<PreviewStripeSubscritionUpdateRes>(url, {});
    return response.data.quote;
  }

  /**
   * Add seats to subscription in Stripe for user's org.
   */
  async addSeatsToStripeSubscription(
    update: E3StripeSubscriptionUpdate
  ): Promise<void> {
    const url = `${API_URL}/organizations/${this.orgId}/stripe-subscription-quantity-update`;
    await superlogin.getHttp().post(url, update);
  }

  /**
   * Queries for locked org users.
   */
  async getLockedUsers(): Promise<Array<string>> {
    const url = `${API_URL}/organizations/${this.orgId}/locked-users`;
    const response = await superlogin.getHttp().get(url, {});
    return response.data.users;
  }

  /**
   * Unlocks user.
   */
  async unlockUser(email: string): Promise<void> {
    const url = `${API_URL}/organizations/${this.orgId}/unlock-user`;
    await superlogin.getHttp().post(url, { email });
  }

  /**
   * Gets org settings filtered by settings keys
   */
  async getSessionExpiration(): Promise<number> {
    const url = `${API_URL}/organizations/${this.orgId}/settings`;
    const params = { keys: [ORG_SESSION_TIMEOUT_SECONDS] };
    const settings = await superlogin.getHttp().get(url, { params });

    return settings.data.settings[ORG_SESSION_TIMEOUT_SECONDS];
  }

  /**
   * Sets org settings. Not required to pass in all settings, just the ones you want to modify
   */
  async setSessionExpiration(
    expiration_seconds: number
  ): Promise<OrganizationSettings> {
    const url = `${API_URL}/organizations/${this.orgId}/settings`;
    return superlogin
      .getHttp()
      .patch(url, { [ORG_SESSION_TIMEOUT_SECONDS]: expiration_seconds });
  }
  /**
   * List reports created by user
   */
  async getMyOrgReports(): Promise<Array<RecentReportData>> {
    const url = `${API_URL}/organizations/${this.orgId}/reports/me`;
    const response = await superlogin.getHttp().get(url);
    return response.data.reports;
  }

  /**
   * List reports created by user
   */
  async downloadReport(reportId: string): Promise<string> {
    const url = `${API_URL}/organizations/${this.orgId}/reports/download/${reportId}`;
    const response = await superlogin
      .getHttp()
      .get(url, { responseType: 'blob' });
    return response.data;
  }
  /**
   * List reports created by user
   */
  async createReportRequest(reportType: string, params: object): Promise<void> {
    const url = `${API_URL}/organizations/${this.orgId}/reports`;
    await superlogin.getHttp().post(url, { reportType, params });
  }
}

export type RecentReportData = {
  id: string;
  team_id?: string;
  created_by: string;
  status: string;
  report_type: string;
  file_id?: string;
  error_message?: string;
  created_at: Date;
  updated_at: Date;
};
export default OrgService;

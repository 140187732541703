import FieldInputFieldSet from './Blocks/FieldInputFieldSet';
import procedureVariableUtil from '../lib/procedureVariableUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIn } from 'formik';
import React, { useMemo } from 'react';
import { EDIT_STICKY_HEADER_HEIGHT_REM } from './EditToolbar';

const FieldSetProcedureVariable = ({
  index,
  variable,
  setFieldValue,
  onFieldRefChanged,
  getOnRemoveVariable,
  errors,
}) => {
  const newVariable = useMemo(() => {
    return procedureVariableUtil.getUpdatedVariable(variable);
  }, [variable]);
  return (
    <div key={`variables[${index}]`} className="flex mt-2">
      <div className="inline-flex group">
        <div
          className="flex relative min-h-full min-w-0 place-items-center"
          ref={(element) => onFieldRefChanged(`variables[${index}]`, element)}
          style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
        >
          {/* Fieldinputs Supporting Procedure Variables*/}
          <FieldInputFieldSet
            path={`variables[${index}]`}
            setFieldValue={setFieldValue}
            content={newVariable}
            blockTitle="Procedure Variable"
            disabledFields={{ include_in_summary: true }}
            contentErrors={getIn(errors, `variables[${index}]`)}
          />
        </div>
        <div className="w-10 h-full flex flex-col justify-start opacity-0 group-hover:opacity-100">
          {/* Remove content block */}
          <button type="button" title="Remove Variable" tabIndex={-1} onClick={getOnRemoveVariable(index)}>
            <FontAwesomeIcon
              icon="times-circle"
              className="self-center text-gray-400 hover:text-gray-500"
            ></FontAwesomeIcon>
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FieldSetProcedureVariable);

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cloneDeep from 'lodash.clonedeep';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import FieldSetCheckbox from '../../components/FieldSetCheckbox';
import Tooltip from '../../elements/Tooltip';
import idUtil from '../../lib/idUtil';
import useParts from '../hooks/useParts';
import { asComponentPart, isPartRestricted } from '../lib/parts';
import ComponentPartLabel from './ComponentPartLabel';
import FullNumberInput from './Numbering/FullNumberInput';
import PartAndRevisionPusher from './PartAndRevisionPusher';
import RestrictedInfo, { RESTRICTED_TEXT } from './RestrictedInfo';
import { useRunContext } from '../../contexts/RunContext';

const TOOLTIP_MESSAGE = 'Additional parts for check-in may be added during a run';

const ComponentBuildRow = ({ component, teamId, onRemove }) => {
  const { amount, part_id, revision_id } = component;
  const { getPartByRevisionId, getPart } = useParts({ partIds: [part_id], includeAllReleasedRevisions: true });
  const part = getPartByRevisionId(revision_id ?? '') ?? getPart(part_id);
  const partRestricted = isPartRestricted(part);

  const hasTracking = part?.tracking === 'serial' || part?.tracking === 'lot';

  if (partRestricted) {
    return (
      <tr className="h-10 text-sm border-b items-center">
        <td>
          <div className="mt-2 ml-1">
            <RestrictedInfo text={RESTRICTED_TEXT} />
          </div>
        </td>
        <td></td>
        <td></td>
        <td>
          <div className="w-6 p-1">
            <button type="button" className="secondary group" onClick={() => onRemove?.(component)}>
              <FontAwesomeIcon className="text-gray-400 group-hover:text-gray-500 p-1" icon="trash" />
            </button>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr className="h-10 text-sm border-b items-center">
      <td>
        <ComponentPartLabel teamId={teamId} component={component} part={part} />
      </td>
      <td>
        <div className="p-1">{amount}</div>
      </td>
      <td>
        {hasTracking && (
          <FullNumberInput
            type={part?.tracking === 'serial' ? 'serials' : 'lot_numbers'}
            disabled={true}
            useFormik={false}
          />
        )}
      </td>
      <td>
        <div className="w-6 p-1">
          <button type="button" className="secondary group" onClick={() => onRemove?.(component)}>
            <FontAwesomeIcon className="text-gray-400 group-hover:text-gray-500 p-1" icon="trash" />
          </button>
        </div>
      </td>
    </tr>
  );
};

const PartBuildFieldSet = ({ content, contentErrors, path, teamId, setFieldValue }) => {
  const { isRun } = useRunContext();

  const onAddItem = (part: Part) => {
    const component = asComponentPart(part, 1);
    const value = {
      ...content,
      items: [
        ...content.items,
        {
          ...component,
          id: idUtil.generateUuidEquivalentId(),
        },
      ],
    };
    setFieldValue(path ? path : '', value);
  };

  const onRemoveItem = (item) => {
    const updated = cloneDeep(content);
    updated.items = updated.items.filter((component) => component.id !== item.id);
    setFieldValue(path ? path : '', updated);
  };

  return (
    <div className="flex flex-col grow">
      <table className="w-full table-fixed">
        <thead>
          <tr className="border-b items-center">
            <td>
              <div className="p-1">
                <span className="field-title">Parts for Check-In</span>
                <Tooltip content={TOOLTIP_MESSAGE}>
                  <FontAwesomeIcon icon="circle-info" className="text-xs text-gray-400 ml-1" />
                </Tooltip>
              </div>
            </td>
            <td>
              <div className="p-1">
                <span className="field-title">Quantity</span>
              </div>
            </td>
            <td>
              <div className="p-1">
                <span className="field-title">Serial / Lot #</span>
              </div>
            </td>
            <td className="w-6"></td>
          </tr>
        </thead>
        <tbody>
          {content?.items.map((component, index) => (
            <ComponentBuildRow key={index} component={component} teamId={teamId} onRemove={onRemoveItem} />
          ))}
          <tr>
            <td colSpan={4}>
              {contentErrors?.error && <div className="text-red-700 text-sm">{contentErrors.error}</div>}
              <div className="flex flex-row flex-wrap">
                <PartAndRevisionPusher onPush={onAddItem} />
                {/* Include in summary checkbox */}
                <div className="ml-2 mt-2 flex flex-row items-center">
                  <FieldSetCheckbox
                    text="Include in Summary"
                    fieldName={`${path}.include_in_summary`}
                    setFieldValue={setFieldValue}
                    isDisabled={isRun}
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PartBuildFieldSet;

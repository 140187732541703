import React, { Dispatch, SetStateAction } from 'react';
import ViewModeTab from './ViewModeTab';

interface ViewModeSelectProps {
  viewMode: string;
  setViewMode: Dispatch<SetStateAction<string>>;
  ganttEnabled?: boolean;
  listEnabled?: boolean;
  calendarEnabled?: boolean;
  unscheduledEnabled?: boolean;
}

const ViewModeSelect = ({
  viewMode,
  setViewMode,
  ganttEnabled = true,
  listEnabled = true,
  calendarEnabled = true,
  unscheduledEnabled = true,
}: ViewModeSelectProps) => {
  return (
    <div className="flex flex-row gap-x-1">
      {ganttEnabled && (
        <ViewModeTab
          viewMode="gantt"
          icon="stream"
          title="Gantt View"
          currentViewMode={viewMode}
          setViewMode={setViewMode}
        />
      )}
      {listEnabled && (
        <ViewModeTab
          viewMode="list"
          icon="list"
          title="List View"
          currentViewMode={viewMode}
          setViewMode={setViewMode}
        />
      )}
      {calendarEnabled && (
        <ViewModeTab
          viewMode="cal"
          icon="calendar"
          title="Calendar View"
          currentViewMode={viewMode}
          setViewMode={setViewMode}
        />
      )}
      {unscheduledEnabled && (
        <ViewModeTab
          viewMode="unscheduled"
          icon="calendar-times"
          title="Unscheduled Events"
          currentViewMode={viewMode}
          setViewMode={setViewMode}
        />
      )}
    </div>
  );
};

export default ViewModeSelect;

import { useEffect, useState, useMemo } from 'react';
import { ReactFlow, MiniMap, Controls, Panel, Edge, useNodesState } from '@xyflow/react';
import flowchartUtil from '../../lib/flowchart';
import { useSettings } from '../../contexts/SettingsContext';
import SectionNode from './SectionNode';
import legend from './flow-legend.png';
import StepNode from './StepNode';
import { Procedure, Run } from 'shared/lib/types/views/procedures';
import '@xyflow/react/dist/style.css';

interface ProcedureFlowChartProps {
  procedure: Procedure | Run;
  height?: number;
}

const ProcedureFlowChart = ({ procedure, height }: ProcedureFlowChartProps) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges] = useState<Array<Edge>>([]);
  const { config } = useSettings();
  const nodeTypes = useMemo(() => ({ section: SectionNode, step: StepNode }), []);

  useEffect(() => {
    if (!procedure) {
      return;
    }
    const { nodes: positionedNodes, edges: positionedEdges } = flowchartUtil.getPositionedElements(procedure, config);

    setNodes(positionedNodes);
    setEdges(positionedEdges as Array<Edge>);
  }, [procedure, config, setNodes]);

  // if height is specified, it's in the sidebar, so alter the viewport
  const defaultViewport = height ? { x: 10, y: 0, zoom: 0.75 } : { x: 100, y: 10, zoom: 1 };
  const showMinimap = !height;
  const MIN_ZOOM = 0.2;

  return (
    <div className="w-full border-2" style={{ height: height ? `${height - 2}px` : '70vh' }}>
      {/* custom edge marker */}
      <svg
        style={{
          position: 'relative',
          top: 0,
          left: 0,
          width: 1, // don't take up space in the regular UI and prevent hovers and clicks
          height: 1,
        }}
      >
        <defs>
          <marker id="circle" viewBox="0 0 10 10" refX="5" refY="6" markerWidth="5" markerHeight="6">
            <circle cx="5" cy="3" r="4" fill="darkgray" />
          </marker>
        </defs>
      </svg>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        defaultViewport={defaultViewport}
        elementsSelectable={true}
        nodesConnectable={false}
        nodesDraggable={true}
        panOnDrag={true}
        nodeTypes={nodeTypes}
        minZoom={MIN_ZOOM}
        proOptions={{
          account: 'paid-pro',
          hideAttribution: true,
        }}
      >
        {showMinimap && <MiniMap offsetScale={20} nodeStrokeColor="black" pannable zoomable />}
        <Controls showInteractive={false} />
        <Panel position="top-right">
          <img src={legend} className="pt-4 w-24" alt="" />
        </Panel>
      </ReactFlow>
    </div>
  );
};

export default ProcedureFlowChart;

import React, { useMemo } from 'react';
import DiffContainerContents from './DiffContainerContents';

interface ModifiedDiffContainerProps {
  label: string;
  children: React.ReactNode;
  width?: 'full' | 'fit';
  onScrollToDiffRefChanged?: (HTMLElement) => void;
  isPlural?: boolean;
  isTextSticky?: boolean;
}

const ModifiedDiffContainer = ({
  label,
  children,
  width,
  onScrollToDiffRefChanged,
  isPlural = false,
  isTextSticky,
}: ModifiedDiffContainerProps) => {
  const diffMessage = useMemo(
    () => (label ? `${label} ${isPlural ? 'have' : 'has'} changed` : 'Changed'),
    [label, isPlural]
  );

  return (
    <DiffContainerContents
      diffIcon="pen"
      diffMessage={diffMessage}
      width={width}
      onScrollToDiffRefChanged={onScrollToDiffRefChanged}
      isTextSticky={isTextSticky}
    >
      {children}
    </DiffContainerContents>
  );
};

export default ModifiedDiffContainer;

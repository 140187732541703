import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import Ruler from './Ruler';
import GanttSwimlane from './Swimlane';
import { GanttContextProvider } from './GanttContext';
import TimeBar from './TimeBar';
import {
  LABEL_WIDTH,
  RULER_HEIGHT,
  SEARCH_PARAM_DEBOUNCE_MS,
  MAGNIFICATION_DIFF,
  INITIAL_TIME_SCALE,
} from '../../config';
import RulerMouseTick from './RulerMouseTick';
import swimlaneLib, { CollapseLevels } from '../../lib/swimlane';
import SwimlaneLabel from './SwimlaneLabel';
import './styles.css';
import { debounce, fill } from 'lodash';
import { useHistory } from 'react-router-dom';
import useSearchParams from '../../../hooks/useSearchParams';
import useScheduleTrack from '../../hooks/useScheduleTrack';
import TimescaleSelect from './TimescaleSelect';
import JumpToDate from './JumpToDate';
import { Interval, DateTime } from 'luxon';
import { FrontendEvent as Event, Swimlane } from 'shared/schedule/types/event';

interface GanttProps {
  period: Interval;
  initialTimeScale?: number;
  events: Array<Event>;
  swimlanes: Array<Swimlane>;
  onChange?: () => Promise<void>;
}

const Gantt = ({ period, initialTimeScale = INITIAL_TIME_SCALE, events, swimlanes, onChange }: GanttProps) => {
  const { replaceSearchParams } = useSearchParams();
  const history = useHistory();
  const scheduleTrack = useScheduleTrack();

  const rulerMouseTickX = useRef(0); // useRef so everything doesn't re-render
  const [showRulerMouseTick, setShowRulerMouseTick] = useState(false);

  const [swimlaneHeights, setSwimlaneHeights] = useState<Array<number>>([]);
  const [swimlaneCollapseLevels, setSwimlaneCollapseLevels] = useState<Array<number>>([]);

  // Refs
  const scheduleRef = useRef<HTMLDivElement | null>(null);
  const labelContainerRef = useRef<HTMLDivElement | null>(null);
  const ganttContainerRef = useRef<HTMLDivElement | null>(null);
  const swimlaneContainerRef = useRef<HTMLDivElement | null>(null);

  // Gantt State
  const [viewWidth, setViewWidth] = useState(0);
  const [timeScale, setTimeScale] = useState(initialTimeScale);

  // Computed Values
  const startEpoch = period.start.toSeconds();
  const endEpoch = period.end.toSeconds();
  const intervalSeconds = endEpoch - startEpoch;
  const ganttWidth = intervalSeconds * timeScale;
  const minTimeScale = viewWidth / intervalSeconds;

  const dateToXPos = useCallback(
    (date: DateTime) => {
      const posRatio = (date.toSeconds() - startEpoch) / intervalSeconds;
      return Math.floor(posRatio * ganttWidth);
    },
    [startEpoch, intervalSeconds, ganttWidth]
  );

  const eventsBySwimlaneId = useMemo(() => {
    const ret = {};
    swimlanes
      .map((s) => s.id)
      .forEach((swimlane_id) => {
        ret[swimlane_id] = events.filter((e) => e.swimlane_id === swimlane_id && e.start);
      });
    return ret;
  }, [events, swimlanes]);

  const eventsWithoutSwimlane = useMemo(() => {
    return events.filter((e) => !e.swimlane_id && e.start);
  }, [events]);

  const handleWheel = useCallback(
    (wheelEvent) => {
      if (!wheelEvent.ctrlKey) {
        return;
      }
      wheelEvent.preventDefault();
      if (wheelEvent.wheelDelta > 0) {
        setTimeScale((s) => s + MAGNIFICATION_DIFF);
      } else if (timeScale - MAGNIFICATION_DIFF < minTimeScale) {
        setTimeScale(minTimeScale);
      } else {
        setTimeScale((s) => s - MAGNIFICATION_DIFF);
      }
    },
    [timeScale, minTimeScale]
  );

  const updateQueryParameter = useMemo(
    () =>
      debounce((timeScale) => {
        replaceSearchParams({
          z: timeScale.toFixed(4),
        });
      }, SEARCH_PARAM_DEBOUNCE_MS),
    [replaceSearchParams]
  );

  useEffect(() => {
    if (timeScale < minTimeScale) {
      setTimeScale(minTimeScale);
    }
    updateQueryParameter(timeScale);
  }, [minTimeScale, timeScale, updateQueryParameter]);

  const nonEmptySwimlanes = useMemo(() => {
    return swimlanes.filter((s) => {
      return eventsBySwimlaneId[s.id] && eventsBySwimlaneId[s.id].length > 0;
    });
  }, [swimlanes, eventsBySwimlaneId]);

  useEffect(() => {
    // Length + 1 to account for "Other" swimlane
    setSwimlaneHeights((curr) => curr.slice(0, nonEmptySwimlanes.length + 1));
    setSwimlaneCollapseLevels(fill(new Array(nonEmptySwimlanes.length + 1), CollapseLevels.Expanded));
  }, [nonEmptySwimlanes]);

  const swimlaneRefCallback = useCallback(
    (node, swimlaneIdx) => {
      if (node === null) {
        return;
      }
      if (swimlaneHeights[swimlaneIdx] !== node.getBoundingClientRect().height) {
        setSwimlaneHeights((prev) => {
          const next = [...prev];
          next[swimlaneIdx] = node.getBoundingClientRect().height;
          return next;
        });
      }
    },
    [swimlaneHeights]
  );

  const scheduleRefCallback = useCallback(
    (node) => {
      if (node) {
        scheduleRef.current = node;
        node.addEventListener('wheel', handleWheel, { passive: false });
      } else if (scheduleRef.current) {
        scheduleRef.current.removeEventListener('wheel', handleWheel);
      }
    },
    [handleWheel]
  );

  const handleMouseMove = useCallback((mouseEvent) => {
    if (swimlaneContainerRef.current) {
      const rect = swimlaneContainerRef.current.getBoundingClientRect();
      rulerMouseTickX.current = mouseEvent.clientX - rect.left;
    }
  }, []);

  const handleResize = useCallback(
    (resizeEvent) => {
      if (ganttContainerRef.current) {
        const rect = ganttContainerRef.current.getBoundingClientRect();
        setViewWidth(rect.width);
      }
    },
    [setViewWidth]
  );

  const ganttContainerRefCallback = useCallback(
    (node) => {
      if (node) {
        const newViewWidth = node.getBoundingClientRect().width;
        setViewWidth(newViewWidth);
        window.addEventListener('resize', handleResize);

        /**
         * On mount set initial scroll position base on url search params if
         * present, otherwise default to starting the scroll in the center of
         * the gantt.
         */
        if (!ganttContainerRef.current) {
          const searchParams = new URLSearchParams(history.location.search);
          const horizontalPos = searchParams.get('pos');
          if (horizontalPos) {
            /*
             * Convert horizontal scroll position between 0 and 1 to the
             * corresponding left scroll position.
             */
            const maxScroll = ganttWidth - newViewWidth;
            const scrollLeft = maxScroll * parseFloat(horizontalPos);
            node.scroll({ left: scrollLeft });
          } else {
            // Scroll to center
            const scrollPos = (ganttWidth - newViewWidth) / 2;
            node.scroll({ left: scrollPos });
          }
        }

        ganttContainerRef.current = node;
      }
    },
    [setViewWidth, handleResize, ganttWidth, history]
  );

  const updateSearchParameter = useMemo(
    () =>
      debounce((pos) => {
        replaceSearchParams({
          pos: pos.toFixed(3),
        });
      }, SEARCH_PARAM_DEBOUNCE_MS),
    [replaceSearchParams]
  );

  const handleScroll = useCallback(
    (e) => {
      const verticalPosition = e.target.scrollTop;
      if (labelContainerRef.current && ganttContainerRef.current) {
        labelContainerRef.current.scroll({ top: verticalPosition });
        ganttContainerRef.current.scroll({ top: verticalPosition });
      }

      // Update search param on horizontal scroll
      if (e.target.scrollLeft) {
        /**
         * Set the horizontal scroll position of the gantt as a fraction of its
         * total width, with 0 being the far left and 1 being the far right.
         */
        const maxScroll = ganttWidth - viewWidth;
        const horizontalPos = maxScroll > 0 ? e.target.scrollLeft / maxScroll : 0;
        updateSearchParameter(horizontalPos);
      }
    },
    [updateSearchParameter, ganttWidth, viewWidth]
  );

  const scrollToDate = (date: DateTime, center = false) => {
    if (ganttContainerRef.current) {
      let xPos = dateToXPos(date);
      if (center) {
        xPos -= viewWidth / 2;
      }
      ganttContainerRef.current.scroll({ left: xPos });
    }
  };

  const setSwimlaneCollapseLevel = useCallback(
    (swimlaneIdx, level) => {
      switch (level) {
        case CollapseLevels.Collapsed:
          scheduleTrack('Swimlane collapsed');
          break;
        case CollapseLevels.Expanded:
          scheduleTrack('Swimlane expanded');
          break;
        default:
          scheduleTrack('Swimlane maximized');
      }

      setSwimlaneCollapseLevels((prev) => {
        const next = [...prev];
        next[swimlaneIdx] = level;
        return next;
      });
    },
    [scheduleTrack]
  );

  return (
    <GanttContextProvider period={period} timeScale={timeScale} ganttWidth={ganttWidth} dateToXPos={dateToXPos}>
      <div
        className="flex flex-row relative h-full w-full overflow-hidden"
        ref={scheduleRefCallback}
        aria-label="Gantt Schedule"
      >
        <div className="absolute flex flex-row space-x-2 top-[1px] h-8 right-0 z-30 bg-blue-100 p-1 rounded">
          <JumpToDate period={period} scrollToDate={scrollToDate} />
          <TimescaleSelect viewWidth={viewWidth} timeScale={timeScale} setTimeScale={setTimeScale} period={period} />
        </div>
        {/* Label Container */}
        <div className="shrink-0 flex flex-col h-full border-r border-black" style={{ width: `${LABEL_WIDTH}px` }}>
          {/* Ruler labels */}
          <div className="sticky top-0 z-20">
            <div
              className="shrink-0 bg-blue-100 flex justify-center items-center"
              style={{ height: `${RULER_HEIGHT}px` }}
            >
              UTC
            </div>
          </div>

          <div className="overflow-y-auto scrollbar-hide" ref={labelContainerRef} onScroll={handleScroll}>
            {/* Swimlane labels */}
            {nonEmptySwimlanes.map((swimlane, idx) => {
              return (
                <SwimlaneLabel
                  key={idx}
                  index={idx}
                  id={swimlane.id}
                  label={swimlane.name}
                  height={swimlaneHeights[idx]}
                  collapseLevel={swimlaneCollapseLevels[idx]}
                  toggleCollapse={() => setSwimlaneCollapseLevel(idx, (swimlaneCollapseLevels[idx] + 1) % 2)}
                  maximize={() => setSwimlaneCollapseLevel(idx, CollapseLevels.Maximized)}
                  onChange={onChange}
                />
              );
            })}

            {eventsWithoutSwimlane.length > 0 && (
              <SwimlaneLabel
                index={nonEmptySwimlanes.length}
                id="Other"
                label="Other"
                height={swimlaneHeights[nonEmptySwimlanes.length]}
                collapseLevel={swimlaneCollapseLevels[nonEmptySwimlanes.length]}
                toggleCollapse={() =>
                  setSwimlaneCollapseLevel(
                    nonEmptySwimlanes.length,
                    (swimlaneCollapseLevels[nonEmptySwimlanes.length] + 1) % 2
                  )
                }
                maximize={() => setSwimlaneCollapseLevel(nonEmptySwimlanes.length, CollapseLevels.Maximized)}
                onChange={undefined}
              />
            )}
          </div>
        </div>

        {/* Gantt Container */}
        <div
          className="flex flex-col w-full overflow-auto scrollbar-show"
          onMouseMove={handleMouseMove}
          onMouseEnter={() => setShowRulerMouseTick(true)}
          onMouseLeave={() => setShowRulerMouseTick(false)}
          ref={ganttContainerRefCallback}
          onScroll={handleScroll}
        >
          {/* Rulers */}
          <div
            className="shrink-0 w-full sticky top-0 flex flex-col z-10 relative"
            style={{ width: `${ganttWidth}px` }}
          >
            {showRulerMouseTick && <RulerMouseTick rulerMouseTickX={rulerMouseTickX} />}

            {/* Rulers */}
            <Ruler colorClass="bg-blue-100" />
          </div>

          {/*Swimlanes*/}
          <div className="shrink-0" ref={swimlaneContainerRef}>
            <div className="relative h-full">
              <TimeBar />
              {nonEmptySwimlanes.map((swimlane, idx) => {
                return (
                  <GanttSwimlane
                    key={idx}
                    ref={(e) => swimlaneRefCallback(e, idx)}
                    bgColorClass={swimlaneLib.swimlaneBgColorClass(idx)}
                    events={eventsBySwimlaneId[swimlane.id]}
                    collapseLevel={swimlaneCollapseLevels[idx]}
                    expandSwimlane={() => setSwimlaneCollapseLevel(idx, CollapseLevels.Expanded)}
                  />
                );
              })}
              {eventsWithoutSwimlane.length > 0 && (
                <GanttSwimlane
                  ref={(e) => swimlaneRefCallback(e, nonEmptySwimlanes.length)}
                  bgColorClass={swimlaneLib.swimlaneBgColorClass(nonEmptySwimlanes.length)}
                  events={eventsWithoutSwimlane}
                  collapseLevel={swimlaneCollapseLevels[nonEmptySwimlanes.length]}
                  expandSwimlane={() => setSwimlaneCollapseLevel(nonEmptySwimlanes.length, CollapseLevels.Expanded)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </GanttContextProvider>
  );
};

export default Gantt;

import { useQuery } from '@tanstack/react-query';
import { JamaRequirement } from 'shared/lib/types/api/integrations/jama';
import { useDatabaseServices } from '../contexts/DatabaseContext';

interface JamaRequirementOption {
  value: string;
  label: string;
  description: string;
  link: string;
}

interface UseJamaRequirementsResult {
  jamaRequirements: JamaRequirementOption[];
  isLoading: boolean;
  error: Error | null;
}

const useJamaRequirements = (
  isJamaIntegrationEnabled: boolean
): UseJamaRequirementsResult => {
  const { services } = useDatabaseServices();

  const fetchJamaRequirements = async (): Promise<JamaRequirementOption[]> => {
    const requirements = await services.jama.getJamaRequirements();
    return requirements.map((req: JamaRequirement) => ({
      value: req.globalId,
      label: req.fields.name,
      description: req.description,
      link: req.link,
    }));
  };

  const { data, error, isLoading } = useQuery({
    queryKey: ['jamaRequirements'],
    queryFn: fetchJamaRequirements,
    enabled: isJamaIntegrationEnabled,
  });

  return {
    jamaRequirements: data ?? [],
    isLoading,
    error,
  };
};

export default useJamaRequirements;

import React, { useCallback } from 'react';
import EditReviewerGroup from './EditReviewerGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { BUTTON_TYPES } from '../Button';
import CancelSaveButtons from '../CancelSaveButtons';
import { ReviewerGroup } from 'shared/lib/types/couch/procedures';
import { getHasReviewerGroupApproved, getNewReviewerGroupWithNewReviewer } from 'shared/lib/reviewUtil';
import { removeItemFromArray } from '../../lib/array';
import { useFormContext } from 'react-hook-form';

interface EditReviewerGroupsProps {
  name: string;
  reviewerGroups: Array<ReviewerGroup>;
  showOperatorRoles: boolean;
  showUsers: boolean;
  onSave?: (reviewerGroups: Array<ReviewerGroup>) => void;
  onCancel?: (reviewerGroups: Array<ReviewerGroup>) => void;
  canEditGroupStructure: boolean;
  isSaveCancelDisabled?: boolean;
  isSaveCancelVisible?: boolean;
  showSettings?: boolean;
  onUpdateReviewerGroups?: (updateFunc: (rgs: Array<ReviewerGroup>) => Array<ReviewerGroup>) => void;
  allowEmptyReviewers?: boolean;
  labelRequired?: boolean;
  canAddUserIdToOperatorRoleSignoff?: boolean;
  changeIdOnReviewerUpdate?: boolean;
}

const EditReviewerGroups = ({
  name,
  reviewerGroups,
  showOperatorRoles,
  showUsers,
  onSave,
  onCancel,
  canEditGroupStructure,
  isSaveCancelDisabled = false,
  isSaveCancelVisible = true,
  showSettings = false,
  allowEmptyReviewers = false,
  labelRequired = false,
  onUpdateReviewerGroups,
  canAddUserIdToOperatorRoleSignoff = true,
  changeIdOnReviewerUpdate = true,
}: EditReviewerGroupsProps) => {
  const { handleSubmit } = useFormContext();

  const updateReviewerGroups = useCallback(
    (updateFunc) => {
      onUpdateReviewerGroups && onUpdateReviewerGroups(updateFunc);
    },
    [onUpdateReviewerGroups]
  );

  const addNewEmptyReviewGroup = () => {
    updateReviewerGroups((currentReviewerGroups) => [...currentReviewerGroups, getNewReviewerGroupWithNewReviewer()]);
  };

  const onReviewerGroupRemoved = (reviewerGroupIndex) => {
    updateReviewerGroups((currentGroupsState) => {
      return removeItemFromArray(currentGroupsState, reviewerGroupIndex);
    });
  };

  const onReviewerGroupUpdated = useCallback(
    (reviewerGroup, reviewerGroupIndex) => {
      updateReviewerGroups((currentGroupsState) => {
        const updatedGroupsState = [...currentGroupsState];

        updatedGroupsState[reviewerGroupIndex] = reviewerGroup;
        return updatedGroupsState;
      });
    },
    [updateReviewerGroups]
  );

  const saveReviewerGroups = useCallback(() => {
    if (!onSave) {
      return;
    }

    onSave(reviewerGroups);
  }, [onSave, reviewerGroups]);

  const cancelReviewerGroups = () => {
    if (!onCancel) {
      return;
    }

    onCancel(reviewerGroups);
  };

  const getCanEdit = useCallback(
    (reviewerGroup, index) => {
      return index === reviewerGroups.length - 1 || !getHasReviewerGroupApproved(reviewerGroup);
    },
    [reviewerGroups.length]
  );

  return (
    <div className="flex flex-col min-w-[35rem]">
      <div className="flex-row">
        {canEditGroupStructure && isSaveCancelVisible && (
          <CancelSaveButtons
            containerClassName="flex flex-row gap-x-2 float-right"
            saveAriaLabel="Save Reviewers"
            onSave={handleSubmit(saveReviewerGroups)}
            cancelAriaLabel="Cancel Editing Reviewers"
            onCancel={cancelReviewerGroups}
            isDisabled={isSaveCancelDisabled}
          />
        )}
      </div>
      <div className="gap-y-12">
        <div className="flex flex-col divide-y-2 divide-slate-300 gap-y-2">
          {reviewerGroups.length > 0 &&
            reviewerGroups.map((reviewerGroup, reviewerGroupIndex) => (
              <div key={reviewerGroup.id} className="relative group/{reviewer-group-card}">
                <EditReviewerGroup
                  name={`${name}[${reviewerGroupIndex}]`}
                  reviewerGroup={reviewerGroup}
                  reviewerGroupIndex={reviewerGroupIndex}
                  showOperatorRoles={showOperatorRoles}
                  showUsers={showUsers}
                  onReviewerGroupUpdated={onReviewerGroupUpdated}
                  canEditReviewers={getCanEdit(reviewerGroup, reviewerGroupIndex)}
                  canEditGroupStructure={canEditGroupStructure}
                  showSettings={showSettings}
                  allowEmptyReviewers={allowEmptyReviewers}
                  labelRequired={labelRequired}
                  canAddUserIdToOperatorRoleSignoff={canAddUserIdToOperatorRoleSignoff}
                  changeIdOnReviewerUpdate={changeIdOnReviewerUpdate}
                />
                {canEditGroupStructure && (
                  <FontAwesomeIcon
                    onClick={() => onReviewerGroupRemoved(reviewerGroupIndex)}
                    aria-label="Remove Review Stage"
                    icon="times-circle"
                    className="absolute top-2 right-0 text-gray-400 hover:text-gray-500 opacity-0 group-hover/{reviewer-group-card}:opacity-100"
                  />
                )}
              </div>
            ))}
        </div>
      </div>
      {canEditGroupStructure && (
        <div className="-ml-2">
          <Button
            type={BUTTON_TYPES.TERTIARY}
            leadingIcon="plus-circle"
            ariaLabel="Add Review Stage"
            onClick={addNewEmptyReviewGroup}
          >
            Add Review Stage
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(EditReviewerGroups);

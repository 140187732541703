import React, { useMemo } from 'react';
import revisions, { DMPDiffType } from '../lib/revisions';
import isNil from 'lodash.isnil';
import { REVIEW_STICKY_HEADER_HEIGHT_REM } from './ReviewStickyHeader';
import MarkdownView from './Markdown/MarkdownView';

/**
 * Renders difference between original and redlined text.
 *
 * @param original - text used as baseline for comparing to redlined text.
 * @param redlined - text used to compare to original text.
 */
interface ProcedureFieldDiffProps {
  original: string;
  redlined?: string;
  onScrollToDiffRefChanged?: (HTMLElement) => void;
  useMarkdownWhenNoDiff?: boolean;
  wrapPlainText?: boolean;
}

const ProcedureFieldDiff = ({
  original,
  redlined,
  onScrollToDiffRefChanged,
  wrapPlainText = true,
  useMarkdownWhenNoDiff = false,
}: ProcedureFieldDiffProps) => {
  const textRedlineDiffs = useMemo(() => {
    if (isNil(original) || isNil(redlined)) {
      return [];
    }
    return revisions.getTextDiffs(`${original}`, `${redlined}`);
  }, [original, redlined]);

  const getDiffTypeClass = (type) => {
    switch (type) {
      case DMPDiffType.insertion:
        return `text-emerald-800 bg-emerald-100`;
      case DMPDiffType.deletion:
        return 'text-red-600 line-through';
      default:
        return '';
    }
  };

  const showMarkdown = useMemo(() => isNil(redlined) && useMarkdownWhenNoDiff, [redlined, useMarkdownWhenNoDiff]);

  return (
    <span
      className={`${!showMarkdown && (wrapPlainText ? 'whitespace-pre-wrap' : 'whitespace-nowrap')}`}
      ref={onScrollToDiffRefChanged}
      style={{ scrollMarginTop: `${REVIEW_STICKY_HEADER_HEIGHT_REM}rem` }}
    >
      {/* Render text with redlined changes */}
      {!isNil(redlined) &&
        textRedlineDiffs &&
        textRedlineDiffs.map(([type, text], index) => (
          <span key={index} className={getDiffTypeClass(type)}>
            {text}
          </span>
        ))}
      {/* Render block text or markdown */}
      {isNil(redlined) && !useMarkdownWhenNoDiff && <span>{original}</span>}
      {showMarkdown && <MarkdownView text={original} />}
    </span>
  );
};

export default React.memo(ProcedureFieldDiff);

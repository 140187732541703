import React, { useMemo } from 'react';

import { DiffSignoffButton } from '../types';
import ReviewSignoffButtons from '../Review/ReviewSignoffButtons';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import { isEmpty } from 'lodash';
import { TableSignoffDiffElement } from 'shared/lib/types/views/procedures';
import tableUtil from 'shared/lib/tableUtil';

interface ReviewSignoffCellProps {
  signoffs?: Array<TableSignoffDiffElement>;
  isAdded: boolean;
  isRemoved: boolean;
}

const ReviewSignoffCell = ({
  signoffs = [],
  isAdded: cellIsAdded,
  isRemoved: cellIsRemoved,
}: ReviewSignoffCellProps) => {
  const buttonDefinitions: Array<Array<DiffSignoffButton>> = useMemo(() => {
    if (!tableUtil.isSignoffCell(signoffs)) {
      return [];
    }
    return signoffs.map((signoff) => {
      if (!signoff.operators.length) {
        return [
          {
            id: signoff.id,
            isDisabled: true,
            onClick: () => null,
            type: 'operator_role',
            isAdded: cellIsAdded || signoff.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED,
            isRemoved: cellIsRemoved || signoff.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED,
          },
        ];
      }

      return signoff.operators.map((operator) => {
        const oldOperator = sharedDiffUtil.getDiffValue<string>({ operator }, 'operator', 'old');
        const newOperator = sharedDiffUtil.getDiffValue<string>({ operator }, 'operator', 'new');

        const isAdded =
          cellIsAdded ||
          signoff.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED ||
          (isEmpty(oldOperator) && !isEmpty(newOperator));
        const isRemoved =
          cellIsRemoved ||
          signoff.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED ||
          (isEmpty(newOperator) && !isEmpty(oldOperator));
        const operatorLabel = isRemoved ? oldOperator ?? '' : newOperator ?? '';
        return {
          id: `${signoff.id}:${operatorLabel}`,
          isDisabled: true,
          onClick: () => null,
          label: operatorLabel,
          type: 'operator_role',
          isAdded,
          isRemoved,
        };
      });
    }, []);
  }, [cellIsAdded, cellIsRemoved, signoffs]);

  return (
    <div className="flex flex-row flex-wrap gap-x-1 gap-y-1 px-2">
      {buttonDefinitions.map((buttonGroup, index) => (
        <ReviewSignoffButtons
          key={index}
          buttons={buttonGroup}
          leadingIcon="check-circle"
          showVerticalSpaceInPrint={true}
        />
      ))}
    </div>
  );
};

export default React.memo(ReviewSignoffCell);

import { useMemo } from 'react';
import { Run } from 'shared/lib/types/views/procedures';
import inventoryUtil from '../manufacturing/lib/inventoryUtil';
import InventoryItemBadge from '../manufacturing/components/InventoryItemBadge';
import ToolInstanceBadge from '../manufacturing/components/Tools/ToolInstanceBadge';
import useItems from '../manufacturing/hooks/useItems';

const NO_CHECKOUTS_MESSAGE = 'No checked out items';

type RunCheckedOutListProps = {
  run: Run;
};

const RunCheckedOutList = ({ run }: RunCheckedOutListProps) => {
  const { allItems } = useItems();
  const items = useMemo(() => {
    return inventoryUtil.checkedOutInventory(run, allItems || []);
  }, [run, allItems]);

  const itemIds = useMemo(() => items.map((item) => item.id), [items]);
  const toolInstanceIds = useMemo(() => {
    return inventoryUtil.checkedOutToolInstanceIds(run);
  }, [run]);

  const hasCheckouts = itemIds.length > 0 || toolInstanceIds.length > 0;
  if (!hasCheckouts) {
    return <div className="text-center pb-4">{NO_CHECKOUTS_MESSAGE}</div>;
  }

  return (
    <div className="flex flex-col pb-4 gap-y-4">
      {itemIds.length > 0 && (
        <div className="flex flex-col ml-2 gap-y-2">
          <div className="font-medium">Parts</div>
          <div className="flex flex-col ml-2 gap-y-2">
            {itemIds.map((itemId) => (
              <InventoryItemBadge itemId={itemId} isCheckedOut={true} />
            ))}
          </div>
        </div>
      )}
      {toolInstanceIds.length > 0 && (
        <div className="flex flex-col ml-2 gap-y-2">
          <div className="font-medium">Tools</div>
          <div className="flex flex-col ml-2 gap-y-2">
            {toolInstanceIds.map((toolInstanceId) => (
              <ToolInstanceBadge toolInstanceId={toolInstanceId} isCheckedOut={true} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RunCheckedOutList;

import { Attachment, CreatedUpdatedAt, StringId, TeamId } from '../util';

export type Item = StringId &
  TeamId & {
    doc: { attachments?: Array<Attachment> };
    part_revision_id: string;
    status: ItemStatus;
    amount: number;
    location_id?: string;
    unit_cost_cents?: number | string;
    notes?: string;
    tracking_id?: string;
  } & CreatedUpdatedAt;

export enum ItemStatus {
  InInventory = 'In Inventory',
  Unavailable = 'Unavailable',
  Scrap = 'Scrap',
}

export type ItemComponent = StringId &
  TeamId & {
    parent_item_id: string;
    component_item_id: string;
    component_quantity: number;
  };

export type InsertItemComponent = Pick<
  ItemComponent,
  'component_item_id' | 'component_quantity'
>;

// ---
// Inventory Activity
// ---

export enum ItemAction {
  Created = 'Created',
  Updated = 'Updated',
  Received = 'Received',
  CheckedOut = 'Checked Out',
  CheckedIn = 'Checked In',
  UsageRecorded = 'Usage Recorded',
  UsageReset = 'Usage Reset',
  DetailUpdated = 'Detail Updated',
  IssueLinked = 'Issue Linked',
}

export enum ItemActorType {
  Api = 'api',
  User = 'user',
  Run = 'run',
}

// TODO rename to InventoryActivity
export type ItemActivity = {
  id: string; // bigint in the database, gets deserialized as a string
  timestamp: Date;
  item_id: string;
  action: ItemAction;
  actor_type: ItemActorType;
  actor_id?: string;
  new_value: object;
  old_value?: object;
};

export type UsageResetActivity = Omit<ItemActivity, 'new_value'> & {
  new_value: { usage_type: string };
};

export type InsertItemActivity = Omit<ItemActivity, 'id' | 'timestamp'>;

export type ListItemActivities = {
  has_older: boolean;
  activities: ItemActivity[];
};

import { useMemo } from 'react';
import ModalBase from '../ModalBase';
import ModalActionBar from '../ModalActionBar';
import ModalContent from '../ModalContent';
import { Snippet } from '../../lib/views/settings';
import ProcedureSection from '../ProcedureSection';
import ProcedureStep from '../ProcedureStep';
import useExpandCollapse from '../../hooks/useExpandCollapse';
import SnippetDeleteButton from './SnippetDeleteButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { BUTTON_TYPES } from '../Button';
import { useAuth } from '../../contexts/AuthContext';
import { PERM } from '../../lib/auth';
import { ProcedureContextProvider } from '../../contexts/ProcedureContext';
import { RunContextProvider } from '../../contexts/RunContext';
import useTelemetryParameters from '../../hooks/useTelemetryParameters';
import procedureUtil from '../../lib/procedureUtil';
import { newRunDoc } from 'shared/lib/runUtil';

const IRRELEVANT_USER_ID = 'irrelevant_user_id';

// Used as a filler prop for ProcedureContextProvider
const generateProcedureShellForSectionSnippet = (sectionSnippet) => {
  const proc = procedureUtil.newProcedure(IRRELEVANT_USER_ID);
  proc.sections = [sectionSnippet];
  return proc;
};

// Used as a filler prop for ProcedureContextProvider
const generateProcedureShellForStepSnippet = (stepSnippet) => {
  const proc = procedureUtil.newProcedure(IRRELEVANT_USER_ID);
  proc.sections = [
    {
      id: '',
      name: '',
      steps: [stepSnippet],
    },
  ];
  return proc;
};
interface ModalPreviewSnippetProps {
  snippet: Snippet;
  onCancel?: () => void;
  onEdit?: () => void;
  onRemove?: (id: string) => Promise<void>;
}

const ModalPreviewSnippet = ({ snippet, onCancel, onRemove, onEdit }: ModalPreviewSnippetProps) => {
  const { isCollapsedMap } = useExpandCollapse();
  const { auth } = useAuth();

  const canEdit = useMemo(() => {
    // Snippets have different edit rules than other settings
    return auth.hasPermission(PERM.PROCEDURES_EDIT);
  }, [auth]);

  const sourceName = useMemo(() => {
    if (snippet && snippet.name) {
      return `${snippet.name}`;
    }

    return `Untitled snippet`;
  }, [snippet]);

  const procedure = useMemo(() => {
    return snippet.snippet_type === 'section'
      ? generateProcedureShellForSectionSnippet(snippet.section)
      : generateProcedureShellForStepSnippet(snippet.step);
  }, [snippet]);

  const run = useMemo(() => {
    const run = newRunDoc({ procedure, userId: IRRELEVANT_USER_ID, userParticipantType: 'viewer' });
    // we don't want it to be an actual run because that adds undesired functionality.
    delete run.state;
    return run;
  }, [procedure]);

  const { fetchedTelemetryParameters } = useTelemetryParameters({ procedure });

  return (
    <ModalBase size="lg">
      <div className="absolute right-5 text-gray-400 hover:text-gray-600 cursor-pointer">
        {/* Modal Cancel Button */}
        <FontAwesomeIcon onClick={onCancel} icon="times" size="lg" />
      </div>

      <div className="bg-white mt-3 sm:px-6 sm:pb-4 rounded-lg">
        <ModalContent title={snippet.name}>
          <div>
            <ProcedureContextProvider procedure={procedure} scrollTo={undefined}>
              {/* @ts-ignore - props not actually required for snippets */}
              <RunContextProvider
                run={run}
                fetchedTelemetryParameters={fetchedTelemetryParameters}
                isPreviewMode={true}
              >
                <table>
                  {snippet.snippet_type === 'section' ? (
                    <>
                      {/* @ts-ignore - props not actually required for snippets */}
                      <ProcedureSection
                        section={snippet.section}
                        isCollapsedMap={isCollapsedMap}
                        sourceName={sourceName}
                      />
                    </>
                  ) : (
                    <ProcedureStep step={snippet.step} sourceName={sourceName} />
                  )}
                </table>
              </RunContextProvider>
            </ProcedureContextProvider>
          </div>
        </ModalContent>
      </div>
      <ModalActionBar>
        <div className="flex gap-x-2">
          {canEdit && <SnippetDeleteButton snippet={snippet} onRemove={onRemove} />}
          {canEdit && (
            <Button
              type={BUTTON_TYPES.PRIMARY}
              leadingIcon="pencil"
              title="Edit Snippet"
              ariaLabel="Edit Snippet"
              size="sm"
              onClick={onEdit}
            >
              Edit
            </Button>
          )}
          {typeof onCancel === 'function' && (
            <Button type={BUTTON_TYPES.SECONDARY} title="Cancel" ariaLabel="Cancel" size="sm" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </div>
      </ModalActionBar>
    </ModalBase>
  );
};

export default ModalPreviewSnippet;

export type ShowOlderButtonProps = {
  isLoading: boolean;
  onLoad: () => void;
};

const ShowOlderButton = ({ isLoading, onLoad }: ShowOlderButtonProps) => {
  return (
    <div className="w-full flex flex-row items-start">
      {isLoading && <div className="text-sm">Loading...</div>}
      {!isLoading && (
        <div className="text-xs text-blue-600 cursor-pointer hover:underline" onClick={onLoad}>
          Show older...
        </div>
      )}
    </div>
  );
};

export default ShowOlderButton;

import React, { useCallback } from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const addControl = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <div className="flex pl-3 last:pl-4">
        <FontAwesomeIcon className="text-blue-500" icon="plus-circle" />
      </div>
      {children}
    </components.Control>
  );
};

const EventDependency = ({
  dependency,
  options,
  onChange,
  onRemove,
  placeholder = 'Add Parent Event',
  isRelative = false,
}) => {
  const linkedEvent = dependency && (dependency.source || dependency.target);
  const sourceEvent = linkedEvent && options.find((o) => o.value === linkedEvent);
  const nonDeletedEvent = options.filter((o) => !o.deleted);

  const handleChange = useCallback(
    ({ value, start, end, label }) => {
      const newDep = { ...dependency };
      newDep.source = value;
      if (isRelative) {
        newDep.start = start;
        newDep.end = end;
        newDep.label = label;
      }
      return onChange(newDep);
    },
    [dependency, onChange, isRelative]
  );

  return (
    <div className="flex flex-row items-center relative">
      <div className="flex flex-row items-center h-9">
        <div className="flex flex-row group items-center h-full">
          <Select
            value={sourceEvent}
            aria-label="Add Parent Event"
            placeholder={placeholder}
            classNamePrefix="react-select"
            className={`${isRelative ? 'w-[16rem]' : 'w-96'} max-w-prose text-sm border-1 border-gray-400 rounded`}
            onChange={handleChange}
            options={nonDeletedEvent}
            components={{ ...(dependency ? {} : { Control: addControl }) }}
          />
          {dependency && (
            <FontAwesomeIcon
              onClick={onRemove}
              icon="times-circle"
              className="ml-2 text-gray-400 hover:text-gray-500 opacity-0 group-hover:opacity-100"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EventDependency;

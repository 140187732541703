import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSlash } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../elements/Tooltip';

const ICON_COLOR = 'text-slate-500';

export const SettingEnabledIcon = ({ icon, ariaLabel }: { icon: IconProp; ariaLabel: string }) => (
  <FontAwesomeIcon className={`${ICON_COLOR}`} icon={icon} aria-label={ariaLabel} />
);

export const SettingDisabledIcon = ({ icon, ariaLabel }: { icon: IconProp; ariaLabel: string }) => {
  return (
    <div className="fa-stack" aria-label={ariaLabel}>
      <FontAwesomeIcon className={`fa-stack-1x ${ICON_COLOR}`} icon={icon} />
      <FontAwesomeIcon className={`fa-stack-1x ${ICON_COLOR}`} icon={faSlash} />
    </div>
  );
};

type SettingBadgeProps = {
  settingValue: boolean | undefined;
  visibleSettingValue: boolean;
  tooltipText: string;
  icon: IconProp;
};

const SettingBadge = ({ settingValue, visibleSettingValue, tooltipText, icon }: SettingBadgeProps) => {
  const settingInfoText = `${tooltipText}: ${settingValue ? 'Enabled' : 'Disabled'}`;

  if (settingValue !== visibleSettingValue) {
    return <></>;
  }
  return (
    <Tooltip content={settingInfoText}>
      <div className="h-8 w-8 flex items-center justify-center">
        {settingValue === false && <SettingDisabledIcon icon={icon} ariaLabel={settingInfoText} />}
        {settingValue === true && <SettingEnabledIcon icon={icon} ariaLabel={settingInfoText} />}
      </div>
    </Tooltip>
  );
};

export default SettingBadge;

import { useCallback, useEffect, useState } from 'react';
import { UsageType } from 'shared/lib/types/api/manufacturing/usage_types/models';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import apm from '../../lib/apm';

interface UsageTypesState {
  usageTypes: Array<UsageType> | undefined;
}

const useUsageTypes = (): UsageTypesState => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const [usageTypes, setUsageTypes] = useState<Array<UsageType>>();

  const getUsageTypes = useCallback(async () => {
    const usageTypes = await services.tools.listUsageTypes();
    setUsageTypes(usageTypes);
  }, [services.tools]);

  useEffect(() => {
    getUsageTypes().catch((err) => apm.captureError(err));
  }, [getUsageTypes]);

  return {
    usageTypes,
  };
};

export default useUsageTypes;

import superlogin from './superlogin';
import { API_URL } from '../config';
import procedureUtil from '../lib/procedureUtil';
import externalDataUtil from '../lib/externalDataUtil';
import { ProcedureContentBlockTypes } from 'shared/lib/types/blockTypes';
import procedureVariableUtil from '../lib/procedureVariableUtil';

class ExternalDataService {
  constructor(teamId) {
    this.teamId = teamId;
  }

  getItem(type, id, metadata, dictionaryId, variables) {
    const url = `${API_URL}/teams/${this.teamId}/external-data/items/request`;
    const body = {
      type,
      id,
      metadata,
      dictionary_id: dictionaryId,
      variables,
    };
    return superlogin
      .getHttp()
      .post(url, body)
      .then((response) => response.data.item);
  }

  searchItems(type, dictionaryId, term, runId) {
    const url = `${API_URL}/teams/${this.teamId}/external-data/items/search`;
    const params = {
      type,
      term,
      run_id: runId,
      dictionary_id: dictionaryId,
    };
    return superlogin
      .getHttp()
      .get(url, { params })
      .then((response) => response.data);
  }

  getItemTypes(type, dictionaryId) {
    const url = `${API_URL}/teams/${this.teamId}/external-data/item-types`;
    const params = {
      type,
      dictionary_id: dictionaryId,
    };
    return superlogin
      .getHttp()
      .get(url, { params })
      .then((response) => response.data && response.data.itemTypes);
  }

  /**
   * Gets all external data items in a procedure (or run) document.
   *
   * 1) Finds all external data item blocks,
   * 2) Fetches updated items
   *
   * @typedef {import('shared/lib/types/externalData').ExternalItem} ExternalItem
   *
   * @param {Object} procedure - A procedure (or run) document.
   * @returns {Promise<Array<ExternalItem>>} - A list of external items.
   */
  async getAllExternalItems(procedure) {
    const dataTransferVariables = procedureVariableUtil.getDataTransferVariables(procedure?.variables);
    const blocks = /** @type {Array<import("shared/lib/types/views/procedures").RunExternalItemBlock>} */ (
      procedureUtil.getAllContentType(procedure, ProcedureContentBlockTypes.ExternalItem)
    );
    // Get updated items.
    const requests = blocks.map((block) => {
      const id = block.item?.id || block.item_id;
      const type = block.item?.type || block.item_type;
      const dictionaryId = block?.dictionary_id;
      return this.getItem(type, id, block.item?.metadata, dictionaryId, dataTransferVariables);
    });
    return Promise.all(requests);
  }

  /**
   * Updates all external data items in a procedure (or run) document.
   *
   * 1) Finds all external data item blocks,
   * 2) Fetches updated items, and
   * 3) Updates procedure (or run) document with updated items.
   *
   * @param {Object} procedure - A procedure (or run) document.
   * @returns {Promise<Object>} - An updated procedure (or run) document.
   */
  async updateExternalItems(procedure) {
    const items = await this.getAllExternalItems(procedure);
    return externalDataUtil.updateProcedureWithItems(procedure, items);
  }
}

export default ExternalDataService;

import React from 'react';
import PropTypes from 'prop-types';
import RepeatMonthlyOn from './On';
import RepeatMonthlyOnThe from './OnThe';
import numericalFieldHandler from '../../../utils/numericalFieldHandler';

const RepeatMonthly = ({ id, monthly: { mode, interval, on, onThe, options }, handleChange }) => {
  const isTheOnlyOneMode = (option) => options.modes === option;
  const isOptionAvailable = (option) => !options.modes || isTheOnlyOneMode(option);

  return (
    <div className="flex flex-col ml-3 space-y-1">
      <div className="flex flex-row items-center space-x-2">
        <span>every</span>
        <input
          id={`${id}-interval`}
          name="repeat.monthly.interval"
          aria-label="Repeat monthly interval"
          className="rounded h-[38px] border w-12 text-center"
          value={interval}
          onChange={numericalFieldHandler(handleChange)}
          onBlur={(e) => {
            if (e.target.value === '' || +e.target.value < 1) {
              // default to 1 if invalid value
              handleChange({ target: { name: e.target.name, value: 1 } });
            }
          }}
        />
        <span>{`${interval === 1 ? 'month' : 'months'}`}</span>
      </div>

      {isOptionAvailable('on') && (
        <RepeatMonthlyOn
          id={`${id}-on`}
          mode={mode}
          on={on}
          hasMoreModes={!isTheOnlyOneMode('on')}
          handleChange={handleChange}
        />
      )}
      {isOptionAvailable('on the') && (
        <RepeatMonthlyOnThe
          id={`${id}-onThe`}
          mode={mode}
          onThe={onThe}
          hasMoreModes={!isTheOnlyOneMode('on the')}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

RepeatMonthly.propTypes = {
  id: PropTypes.string.isRequired,
  monthly: PropTypes.shape({
    mode: PropTypes.oneOf(['on', 'on the']).isRequired,
    interval: PropTypes.number.isRequired,
    on: PropTypes.object.isRequired,
    onThe: PropTypes.object.isRequired,
    options: PropTypes.shape({
      modes: PropTypes.oneOf(['on', 'on the']),
    }).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RepeatMonthly;

import React, { useCallback } from 'react';
import ViewReviewerGroup from './ViewReviewerGroup';
import { getAreAllReviewerGroupsEmpty, getIsReviewerGroupActive } from 'shared/lib/reviewUtil';
import { ReviewerGroup, ReviewerId } from 'shared/lib/types/couch/procedures';

interface ViewReviewerGroupsProps {
  reviewerGroups?: Array<ReviewerGroup>;
  onReviewerApprove?: (reviewerGroupId: string, reviewerId: string, operatorRole: string) => void;
  onRevokeApproval?: (reviewerGroupId: string, reviewerId: string) => void;
  hasPermission: (reviewerId: ReviewerId) => boolean;
  onEdit?: () => void;
  showEditActions: boolean;
  showSignoffCheckmark: boolean;
  showSettings?: boolean;
  allowClearingApprovals?: boolean;
}

const ViewReviewerGroups = ({
  reviewerGroups = [],
  onReviewerApprove,
  onRevokeApproval,
  hasPermission,
  onEdit,
  showEditActions,
  showSignoffCheckmark,
  showSettings = false,
  allowClearingApprovals,
}: ViewReviewerGroupsProps) => {
  const getIsActive = useCallback(
    (reviewerGroupId: string) => {
      return getIsReviewerGroupActive(reviewerGroups, reviewerGroupId);
    },
    [reviewerGroups]
  );

  return (
    <div>
      {showEditActions && getAreAllReviewerGroupsEmpty(reviewerGroups) && (
        <button className="text-sm underline" aria-label="Add Reviewer Groups" onClick={onEdit}>
          Add Reviewers
        </button>
      )}
      <div className="flex flex-col">
        {!showEditActions && getAreAllReviewerGroupsEmpty(reviewerGroups) && (
          <div className="-mt-1 leading-5 whitespace-pre-line text-gray-600 text-sm">
            No reviewers have been assigned
          </div>
        )}
        {!getAreAllReviewerGroupsEmpty(reviewerGroups) && (
          <div className="flex flex-col divide-y-2 divide-gray-300 gap-y-2">
            {reviewerGroups.map((reviewerGroup, index) => (
              <ViewReviewerGroup
                key={reviewerGroup.id}
                reviewerGroup={reviewerGroup}
                onReviewerApprove={onReviewerApprove}
                onRevokeApproval={onRevokeApproval}
                hasPermission={hasPermission}
                onEdit={onEdit}
                showEditActions={showEditActions}
                showSignoffCheckmark={showSignoffCheckmark}
                showSettings={showSettings}
                isActive={getIsActive(reviewerGroup.id)}
                hasTopPadding={index !== 0}
                allowClearingApprovals={allowClearingApprovals}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ViewReviewerGroups);

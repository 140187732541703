import React, { useCallback } from 'react';
import { generateHiddenClassString } from '../../lib/styles';
import useBlockRedlines from '../../hooks/useBlockRedlines';
import { BlockValues, RedlineBlock } from './BlockTypes';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import ReferenceTextView from '../ReferenceTextView';
import { ExpressionReferences } from 'shared/lib/types/views/procedures';

interface BlockTextProps {
  block: BlockValues<'text'>;
  blockLabel?: string;
  redlines?: Array<RedlineBlock<'text'>>;
  isHidden?: boolean;
  onRefChanged?: (id: string, element) => void;
  scrollMarginTopValueRem?: number;
  setExpressionRecorded?: (recorded: { value?: string; display?: string; references?: ExpressionReferences }) => void;
}

/*
 * Component for rendering a text block.
 *
 * block: Block object of type "text", with text to display
 * redlines: Array of RedlineBlock objects with change history
 * isHidden: Whether this block is hidden (expanded/collapsed)
 */
const BlockText = ({
  block,
  blockLabel,
  redlines,
  isHidden,
  onRefChanged,
  scrollMarginTopValueRem,
  setExpressionRecorded,
}: BlockTextProps) => {
  const { latestApprovedBlock } = useBlockRedlines({
    block,
    redlines,
  });

  const onTextBlockRefChanged = useCallback(
    (element) => {
      return onRefChanged && onRefChanged(block.id, element);
    },
    [block.id, onRefChanged]
  );

  // Layout is intended for CSS grid template defined in Run.js and Procedure.js
  return (
    <>
      <div className={generateHiddenClassString('', isHidden)}></div>
      <div
        aria-label="Text Content"
        className={generateHiddenClassString('mt-2 mr-8 flex page-break', isHidden)}
        ref={onTextBlockRefChanged}
        style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
      >
        <Spacer />
        <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
        {/* Content */}
        <div className="min-w-0 w-full self-start">
          {latestApprovedBlock && latestApprovedBlock.text && (
            <div className="max-w-full break-words">
              <ReferenceTextView block={latestApprovedBlock} setExpressionRecorded={setExpressionRecorded} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BlockText;

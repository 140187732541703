import { useCallback } from 'react';
import useScheduleTrack from '../hooks/useScheduleTrack';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import { CouchLikeOperationSummary } from 'shared/lib/types/operations';
import EventModal, { SaveEventOpts } from './EventModal';

const INITIAL_EVENT = {
  id: '',
  name: '',
  dependencies: {
    in: [],
    out: [],
  },
  predecessor_id: '',
};

interface CreateEventModalProps {
  isVisible: boolean;
  onSave: () => void;
  onCancel: () => void;
  operation?: CouchLikeOperationSummary;
}

const CreateEventModal = ({ isVisible, onSave, onCancel, operation }: CreateEventModalProps) => {
  const scheduleTrack = useScheduleTrack();
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  const saveEvent = useCallback(
    async (event: Event, opts: SaveEventOpts) => {
      scheduleTrack('Event created');
      await services.events.createEvent(event, !!opts.ignore_resource_conflicts);

      onSave();
    },
    [onSave, scheduleTrack, services.events]
  );

  return (
    <EventModal
      event={{
        ...INITIAL_EVENT,
        operation,
      }}
      isVisible={isVisible}
      saveEvent={saveEvent}
      onCancel={onCancel}
    />
  );
};

export default CreateEventModal;

import React, { useMemo } from 'react';

interface ReviewerBannerProps {
  isApproved: boolean;
  children: React.ReactNode;
}

const Reviewers = ({ isApproved, children }: ReviewerBannerProps) => {
  const backgroundColorClass = useMemo(() => {
    if (isApproved) {
      return 'bg-emerald-100 border border-emerald-300';
    } else {
      return 'bg-amber-100 border border-amber-300';
    }
  }, [isApproved]);

  return <div className={`flex flex-col px-4 py-3 gap-y-2 rounded ${backgroundColorClass}`}>{children}</div>;
};

export default React.memo(Reviewers);

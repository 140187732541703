import { useEffect, useCallback } from 'react';

const usePrintHeader = (defaultHeader: string, printHeader: string): void => {
  const onBeforePrint = useCallback(() => {
    document.title = printHeader;
  }, [printHeader]);

  const onAfterPrint = useCallback(() => {
    document.title = defaultHeader;
  }, [defaultHeader]);

  useEffect(() => {
    document.title = defaultHeader;
    window.addEventListener('beforeprint', onBeforePrint);
    window.addEventListener('afterprint', onAfterPrint);
    return () => {
      window.removeEventListener('beforeprint', onBeforePrint);
      window.removeEventListener('afterprint', onAfterPrint);
    };
  }, [defaultHeader, onBeforePrint, onAfterPrint]);
};

export default usePrintHeader;

import React, { useMemo } from 'react';
import { Field, FormikHelpers, FormikValues } from 'formik';
import validateUtil from '../../lib/validateUtil';
import { RequirementContentErrors } from '../../lib/types';
import Select from 'react-select';
import { useSettings } from '../../contexts/SettingsContext';
import useJamaRequirements from '../../hooks/useJamaRequirements';
import useInternalRequirements from '../../hooks/useInternalRequirements';
import { RunRequirementBlock } from 'shared/lib/types/views/procedures';
import { reactSelectStyles } from '../../lib/styles';
import { Requirement } from 'shared/lib/types/testing';
import { testingRequirementsViewPath } from '../../lib/pathUtil';
import { useDatabaseServices } from '../../contexts/DatabaseContext';

interface RequirementFieldSetProps {
  path: string;
  block: RunRequirementBlock;
  disabledFields?: { label?: true };
  contentErrors?: RequirementContentErrors;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
}

const subTypeOptions = [
  { value: 'manual', label: 'Manual' },
  { value: 'epsilon3', label: 'Epsilon3' },
  { value: 'jama', label: 'Jama' },
];

const RequirementFieldSet = ({
  path,
  block,
  disabledFields = {},
  contentErrors,
  setFieldValue,
}: RequirementFieldSetProps) => {
  const { currentTeamId } = useDatabaseServices();
  const { isJamaIntegrationEnabled, isTestConditionsMatrixEnabled } = useSettings();
  const { jamaRequirements, isLoading, error } = useJamaRequirements(isJamaIntegrationEnabled());
  const { requirements: internalRequirements } = useInternalRequirements();

  const enabledSubTypes = useMemo(() => {
    return subTypeOptions.filter((integration) => {
      if (integration.value === 'jama') return isJamaIntegrationEnabled();
      if (integration.value === 'epsilon3') return isTestConditionsMatrixEnabled();
      return true;
    });
  }, [isJamaIntegrationEnabled, isTestConditionsMatrixEnabled]);

  const selectedSubType = useMemo(() => {
    return (
      enabledSubTypes.find((option) => option.value === block?.subType) || {
        value: 'manual',
        label: 'Manual',
      }
    );
  }, [enabledSubTypes, block?.subType]);

  const selectedRequirement = useMemo(() => {
    return (selectedSubType.value === 'jama' || selectedSubType.value === 'epsilon3') && block.integrationDetails?.id
      ? {
          value: block.integrationDetails.id,
          label: block.integrationDetails.name,
        }
      : null;
  }, [block?.integrationDetails, selectedSubType]);

  const handleChangeSubType = (option) => {
    if (block.subType !== option.value) {
      const newBlock = {
        ...block,
        subType: option.value,
        integrationDetails: {},
        label: option.value === 'manual' ? block.label : '',
      };
      setFieldValue(path, newBlock);
    }
  };

  const epsilon3Requirement = useMemo(() => {
    return internalRequirements.map((req: Requirement) => ({
      value: req.id,
      label: req.custom_id,
      description: req.text,
      link: testingRequirementsViewPath(currentTeamId, req.id),
    }));
  }, [internalRequirements, currentTeamId]);

  const handleChangeRequirement = (option) => {
    const requirement = selectedSubType.value === 'jama' ? jamaRequirements : epsilon3Requirement;
    const selectedReq = requirement.find((req) => req.value === option.value);
    if (selectedReq) {
      const integrationDetails = {
        id: selectedReq.value,
        name: selectedReq.label,
        description: selectedReq.description,
        link: selectedReq.link,
      };
      setFieldValue(`${path}.integrationDetails`, integrationDetails);
    }
  };

  return (
    <div className="flex flex-col w-full mr-2">
      <span className="field-title">Requirement</span>
      <div className="flex items-center">
        {enabledSubTypes.length === 1 ? (
          <div className="w-full">
            <Field
              name={`${path}.label`}
              type="text"
              placeholder="Requirement name*"
              className="text-sm border border-gray-400 rounded w-full"
              disabled={disabledFields?.label}
              validate={validateUtil.validateNotEmpty}
            />
          </div>
        ) : (
          <>
            <div className="min-w-[125px] flex-none mr-2">
              <Select
                value={selectedSubType}
                onChange={handleChangeSubType}
                options={enabledSubTypes}
                isDisabled={disabledFields?.label}
                isSearchable={false}
                className="w-full"
              />
            </div>
            <div className="flex-1 relative">
              {selectedSubType.value === 'manual' && (
                <Field
                  name={`${path}.label`}
                  type="text"
                  placeholder="Requirement name*"
                  className="text-sm border border-gray-400 rounded w-full"
                  disabled={disabledFields?.label}
                  validate={validateUtil.validateNotEmpty}
                />
              )}
              {selectedSubType.value === 'jama' && (
                <>
                  <Select
                    classNamePrefix="react-select"
                    options={jamaRequirements.map(({ value, label }) => ({ value, label }))}
                    value={selectedRequirement}
                    onChange={handleChangeRequirement}
                    isDisabled={disabledFields?.label}
                    isLoading={isLoading}
                    style={reactSelectStyles}
                    className="w-full"
                  />
                  {error && (
                    <div className="text-red-700 mt-1">
                      An error occurred. Please verify your integration configuration.
                    </div>
                  )}
                </>
              )}
              {selectedSubType.value === 'epsilon3' && (
                <Select
                  classNamePrefix="react-select"
                  style={reactSelectStyles}
                  options={epsilon3Requirement.map(({ value, label }) => ({ value, label }))}
                  value={selectedRequirement}
                  onChange={handleChangeRequirement}
                  isDisabled={disabledFields?.label}
                  className="w-full"
                />
              )}
              {contentErrors?.label && (
                <div className="absolute top-full left-0 mt-1 text-red-700">{contentErrors.label}</div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RequirementFieldSet;

import React, { useCallback, useMemo } from 'react';
import EditReviewers from './EditReviewers';
import { useSettings } from '../../contexts/SettingsContext';
import { ReviewerGroup, ReviewerId, ReviewerOperatorRoleId, ReviewerUserId } from 'shared/lib/types/couch/procedures';
import { getRemainingReviewerIds } from 'shared/lib/reviewUtil';
import { Controller, useFormContext } from 'react-hook-form';
import FieldText from '../../elements/FieldText';
import FieldError from '../../elements/internal/FieldError';
import FieldLabel from '../../elements/internal/FieldLabel';

interface EditReviewerGroupProps {
  name: string;
  reviewerGroup: ReviewerGroup;
  reviewerGroupIndex: number;
  onReviewerGroupUpdated: (reviewerGroup: ReviewerGroup, reviewerGroupIndex: number) => void;
  showOperatorRoles: boolean;
  showUsers: boolean;
  canEditGroupStructure: boolean;
  reviewTypeNameError?: string;
  operatorRoleToUsersMap?: { [userId: string]: Set<string> };
  showSettings?: boolean;
  canEditReviewers?: boolean;
  allowEmptyReviewers?: boolean;
  labelRequired?: boolean;
  canAddUserIdToOperatorRoleSignoff?: boolean;
  changeIdOnReviewerUpdate?: boolean;
}

const EditReviewerGroup = ({
  name,
  reviewerGroup,
  reviewerGroupIndex,
  onReviewerGroupUpdated,
  showOperatorRoles,
  showUsers,
  canEditGroupStructure,
  showSettings = false,
  canEditReviewers = false,
  allowEmptyReviewers = false,
  labelRequired = false,
  canAddUserIdToOperatorRoleSignoff = true,
  changeIdOnReviewerUpdate = true,
}: EditReviewerGroupProps) => {
  const { users, operatorRoles } = useSettings();
  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const operatorOptions: Array<ReviewerOperatorRoleId> = useMemo(() => {
    if (!operatorRoles?.operators) {
      return [];
    }

    // return operator options sorted alphabetically by operator key.
    return operatorRoles.operators
      .map((operatorObject) => operatorObject.code)
      .sort()
      .map((operator) => ({
        type: 'operator_role',
        value: operator,
      }));
  }, [operatorRoles]);

  const userIdOptions: Array<ReviewerUserId> = useMemo(() => {
    if (!users?.users) {
      return [];
    }

    // return user ids sorted alphabetically.
    return Object.keys(users.users)
      .sort()
      .map((userId) => ({
        type: 'user_id',
        value: userId,
      }));
  }, [users]);

  const operatorsAndUsers = useMemo(() => {
    // return operator options first before user options in one array.
    const options: Array<ReviewerId> = [];
    if (showOperatorRoles) {
      options.push(...operatorOptions);
    }
    if (showUsers) {
      options.push(...userIdOptions);
    }
    return options;
  }, [operatorOptions, userIdOptions, showOperatorRoles, showUsers]);

  const reviewerOptions = useMemo(() => {
    return getRemainingReviewerIds(operatorsAndUsers, reviewerGroup.reviewers);
  }, [reviewerGroup, operatorsAndUsers]);

  const onReviewersUpdated = useCallback(
    (reviewers) => {
      const updatedReviewerGroup = {
        ...reviewerGroup,
        reviewers,
      };

      onReviewerGroupUpdated(updatedReviewerGroup, reviewerGroupIndex);
    },
    [reviewerGroup, reviewerGroupIndex, onReviewerGroupUpdated]
  );

  const watchReviewerGroups = watch('reviewer_groups');
  const isLabelRequired = useMemo(() => {
    return labelRequired || (watchReviewerGroups && watchReviewerGroups.length > 1);
  }, [labelRequired, watchReviewerGroups]);

  const reviewersError = useMemo(() => {
    return errors.reviewer_groups?.[reviewerGroupIndex]?.reviewers?.message;
  }, [errors.reviewer_groups, reviewerGroupIndex]);

  return (
    <div aria-label={`Edit Group${reviewerGroup.name ? ` ${reviewerGroup.name}` : ''}`}>
      {canEditGroupStructure && (
        <Controller
          name={`reviewer_groups[${reviewerGroupIndex}].name`}
          control={control}
          rules={{
            required: {
              value: labelRequired || getValues().reviewer_groups.length > 1,
              message: 'Required',
            },
            maxLength: {
              value: 128,
              message: 'Label must have at most 128 characters',
            },
            validate: (name) => {
              const reviewerGroupNameMap = {};
              getValues().reviewer_groups.forEach((rg) => {
                if (!reviewerGroupNameMap[rg.name]) {
                  reviewerGroupNameMap[rg.name] = 0;
                }
                reviewerGroupNameMap[rg.name]++;
              });
              return reviewerGroupNameMap[name] === 1 || 'Review stage name must be unique.';
            },
          }}
          render={({ field }) => (
            <div className="w-1/2 min-w-10">
              <FieldText
                name={field.name}
                value={field.value ?? ''}
                onChange={(e) => {
                  setValue(field.name, e.target.value, { shouldDirty: true });
                }}
                onBlur={(e) => {
                  setValue(field.name, e.target.value.trim());
                }}
                label={`Label${isLabelRequired ? '*' : ''}`}
                placeholder={`Label${isLabelRequired ? '*' : ''}`}
                errorMessage={errors.reviewer_groups?.[reviewerGroupIndex]?.name?.message}
                maxLength={128}
              />
            </div>
          )}
        />
      )}
      {!canEditGroupStructure && (
        <div className="flex items-center space-x-1 text-sm">
          <div className="text-gray-500 font-medium">{reviewerGroup.name}</div>
        </div>
      )}
      <div className="ml-0">
        {canEditGroupStructure && <FieldLabel label="Reviewers*" />}
        <Controller
          name={`${name}.reviewers`}
          control={control}
          rules={{
            validate: (reviewers) => {
              return (
                allowEmptyReviewers ||
                (reviewers && reviewers.length > 0 && reviewers.some((reviewer) => reviewer.reviewer_ids.length > 0)) ||
                'Required'
              );
            },
          }}
          render={({ field }) => (
            <EditReviewers
              reviewers={watch(field.name)}
              actions={reviewerGroup.actions}
              reviewerOptions={reviewerOptions}
              onReviewersUpdated={onReviewersUpdated}
              canEdit={showSettings || canEditReviewers}
              canAddUserIdToOperatorRoleSignoff={!showSettings && canEditReviewers && canAddUserIdToOperatorRoleSignoff}
              changeIdOnReviewerUpdate={changeIdOnReviewerUpdate}
            />
          )}
        />
      </div>
      {reviewersError && <FieldError errorMessage={reviewersError} />}
      {showSettings && (
        <label className="my-2 text-sm font-medium">
          <div className="my-2 flex flex-row items-center gap-x-2">
            <Controller
              name={`reviewer_groups[${reviewerGroupIndex}].persist_approvals`}
              control={control}
              render={({ field }) => (
                <input
                  type="checkbox"
                  className="w-6 h-6 border text-gray-500 rounded-sm"
                  aria-label="Clear approvals if additional changes are made."
                  checked={!field.value}
                  onChange={() => setValue(field.name, !field.value, { shouldDirty: true })}
                />
              )}
            />
            <span className="font-normal text-sm">Clear approvals if additional changes are made.</span>
          </div>
        </label>
      )}
    </div>
  );
};

export default React.memo(EditReviewerGroup);

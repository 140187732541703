import { useHistory } from 'react-router-dom';
import Button, { BUTTON_TYPES } from '../../components/Button';
import { MenuContextAction } from '../../components/MenuContext';
import MoreActionsMenu from '../../components/Toolbar/MoreActionsMenu';
import useTestingAuth from '../hooks/useTestingAuth';

interface EditDeleteButtonsProps {
  onDelete?: () => void;
  resourcePath: string;
  isInReview?: boolean;
  projectId?: string;
}

const EditDeleteButtons = ({ resourcePath, onDelete, projectId, isInReview = false }: EditDeleteButtonsProps) => {
  const history = useHistory();
  const { hasEditPermission, hasCreatePermission } = useTestingAuth();

  const onEdit = () => {
    history.push(`${resourcePath}/edit`);
  };

  const menuActions: Array<MenuContextAction> = [];
  if (onDelete) {
    menuActions.push({
      type: 'label',
      label: 'Delete',
      data: {
        icon: 'times-circle',
        title: 'Delete',
        onClick: onDelete,
      },
    });
  }

  return hasEditPermission(projectId) && hasCreatePermission ? (
    <div className="flex flex-row items-center gap-x-2">
      <Button type={BUTTON_TYPES.PRIMARY} leadingIcon="pencil" ariaLabel="Edit" onClick={onEdit} size="sm">
        Edit
      </Button>
      {menuActions.length > 0 && <MoreActionsMenu actions={menuActions} label="Action Menu" />}
    </div>
  ) : null;
};

export default EditDeleteButtons;

import superlogin from '../../api/superlogin';
import { API_URL } from '../../config';
import { FileSystemFile, FileSystemFolder, FileSystem } from '../types';

class DataService {
  private restUrl: string;

  constructor(teamId: string) {
    this.restUrl = `${API_URL}/teams/${teamId}/storage`;
  }

  async saveFileMetadata(file: File): Promise<void> {
    const url = `${this.restUrl}/file-metadata`;

    const dotIndex = file.name.lastIndexOf('.');
    const body = {
      filename: dotIndex === -1 ? file.name : file.name.substring(0, dotIndex),
      file_type: dotIndex === -1 ? '' : file.name.substring(dotIndex + 1),
      file_last_modified: file.lastModified,
      file_size_bytes: file.size,
    };
    await superlogin.getHttp().post(url, body);
  }

  async getFileMetadata(): Promise<void> {
    const url = `${this.restUrl}/file-metadata`;

    return superlogin
      .getHttp()
      .get(url)
      .then((res) => res.data);
  }

  async listFolders(): Promise<FileSystemFolder[]> {
    const url = `${this.restUrl}/folder`;

    return superlogin
      .getHttp()
      .get(url)
      .then((res) => res.data);
  }

  async listFiles(): Promise<FileSystemFile[]> {
    const url = `${this.restUrl}/file`;

    return superlogin
      .getHttp()
      .get(url)
      .then((res) => res.data);
  }

  async listFileSystem(): Promise<FileSystem> {
    const url = `${this.restUrl}/file-system`;

    return superlogin
      .getHttp()
      .get(url)
      .then((res) => {
        const files: FileSystemFile[] = [];
        const folders: FileSystemFolder[] = [];
        for (const node of res.data) {
          if (node.type === 'file') {
            files.push(node);
          } else {
            folders.push(node);
          }
        }
        return {
          files,
          folders,
        };
      });
  }

  async createFolder(
    folderName: string,
    parentId?: number | undefined
  ): Promise<FileSystemFolder> {
    const url = `${this.restUrl}/folder`;

    const body = {
      name: folderName,
      parent_id: parentId,
    };

    const response = await superlogin.getHttp().post(url, body);
    return response.data;
  }

  async createFile(
    filename: string,
    parentId?: number | undefined
  ): Promise<FileSystemFile> {
    const url = `${this.restUrl}/file`;

    const body = {
      name: filename,
      parent_id: parentId,
    };

    const response = await superlogin.getHttp().post(url, body);
    return response.data;
  }

  async deleteFolder(folderId: number): Promise<void> {
    const url = `${this.restUrl}/folder/${folderId}`;

    await superlogin.getHttp().delete(url);
  }

  async deleteFile(fileId: number): Promise<void> {
    const url = `${this.restUrl}/file/${fileId}`;

    await superlogin.getHttp().delete(url);
  }
}

export default DataService;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Field } from 'formik';
import { reactSelectStyles } from '../../../lib/styles';
import {
  createUtcEquivalentDateFromIsoDate,
  getISODate,
  getISOTime,
  getTimezoneDisplayLabel,
  splitIsoTimestamp,
  TIMEZONE_NAMES,
} from '../../../lib/datetime';
import MarkdownView from '../../Markdown/MarkdownView';
import { FieldInputTimestampBlock, TimestampValue, TimestampValueV2 } from 'shared/lib/types/views/procedures';
import { isEqual } from 'lodash';
import Select, { StylesConfig } from 'react-select';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker/dist/entry.nostyle'; // To use TimePicker without default styles
// To populate TimePicker with an edit of the default style file
import '../../TimeDatePicker.css';
import Button from '../../Button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useSettings } from '../../../contexts/SettingsContext';
import TimeFieldInputHelpIcon from './TimeFieldInputHelpIcon';
import { createIsoString } from 'shared/lib/datetime';

const TIMEZONE_DISPLAY_OPTIONS = TIMEZONE_NAMES.map((name) => ({
  label: getTimezoneDisplayLabel(name),
  value: name,
}));

const TIMEZONE_SELECT_STYLES: StylesConfig = {
  ...reactSelectStyles,
  singleValue: (base) => ({
    ...base,
    position: 'static',
    transform: 'none',
    minWidth: 'fit-content',
    maxWidth: 'none',
  }),
  menu: (base) => ({
    ...base,
    width: 'max-content',
    zIndex: '2',
  }),
};

const isOldDataPresent = (recorded) => {
  return recorded?.value && typeof recorded.value === 'string';
};

/**
 * recorded.value can be either:
 *  1. Old Data: An ISO string that is in UTC.
 *  2. New Data: An object of the form:
 *     { date?: '2023-12-18', time?: '06:00:00', zone?: UTC }
 */
interface TimestampFieldInputProps {
  block: FieldInputTimestampBlock;
  recorded?: { value?: TimestampValue };
  onRecordValuesChanged?: (recorded: { value?: TimestampValue }) => void;
}
const TimestampFieldInput = React.memo(({ block, recorded, onRecordValuesChanged }: TimestampFieldInputProps) => {
  const { getSetting } = useSettings();
  const [editTimezone, setEditTimezone] = useState(false);
  const timezoneWrapperRef = useRef<HTMLDivElement | null>(null);
  const timezoneRef = useRef<Select | null>(null);
  const blockName = block.name;

  const enableEditTimezone = useCallback((event) => {
    event.stopPropagation();
    setEditTimezone(true);
  }, []);

  const disableEditTimezone = useCallback(() => {
    setEditTimezone(false);
  }, []);

  // Auto-focus the timezone selector when it opens.
  useEffect(() => {
    if (editTimezone && timezoneRef.current) {
      timezoneRef.current.focus();
    }
  }, [editTimezone]);

  // Close the timezone selector if anywhere but the selector is clicked.
  useEffect(() => {
    const closeEditTimezone = (e) => {
      if (timezoneWrapperRef.current && !timezoneWrapperRef.current.contains(e.target)) {
        disableEditTimezone();
      }
    };
    if (editTimezone) {
      document.addEventListener('click', closeEditTimezone);
    }
    // Remove listener when menu visibility changes or component unmounts
    return () => {
      document.removeEventListener('click', closeEditTimezone);
    };
  }, [disableEditTimezone, editTimezone]);

  const defaultZone = useMemo(() => {
    return getSetting('timezone', 'UTC');
  }, [getSetting]);

  const existingValue = useMemo(() => {
    if (!recorded?.value) {
      return {};
    }
    return isOldDataPresent(recorded)
      ? { ...splitIsoTimestamp(recorded.value as string), zone: 'UTC' }
      : (recorded.value as TimestampValueV2);
  }, [recorded]);

  const recordedDate = useMemo(() => {
    if (!recorded || !recorded.value) {
      return null;
    }

    /*
     * react-datepicker requires a Date object, which is limited in its support
     * for timezones.
     * We just want the correct date to display, so:
     * 1. Get the recorded date.
     * 2. Make a fake Date in the system local timezone from the isolated date.
     *
     * This will ensure the correct date is always displayed,
     * but this does not mean we are using the local system timezone.
     */

    // Handle old data that are stored as Zulu ISO timestamps.
    const isoDate = isOldDataPresent(recorded)
      ? getISODate(recorded.value as string)
      : (recorded.value as TimestampValueV2).date;

    /*
     * The fake date is in the system's local timezone, but that timezone has no meaning.
     * It is only the year, month, and day values that are valid.
     */
    return createUtcEquivalentDateFromIsoDate(isoDate);
  }, [recorded]);

  const recordedTime = useMemo(() => {
    if (!recorded || !recorded.value) {
      return null;
    }

    if (isOldDataPresent(recorded)) {
      return getISOTime(recorded.value as string);
    }

    return (recorded.value as TimestampValueV2).time;
  }, [recorded]);

  const currentTimezone = useMemo(() => {
    return isOldDataPresent(recorded) ? 'UTC' : existingValue.zone ?? defaultZone;
  }, [defaultZone, existingValue.zone, recorded]);

  const timezoneSelected = useMemo(() => {
    const zone = currentTimezone ?? 'UTC';

    return TIMEZONE_DISPLAY_OPTIONS.find((option) => option.value === zone);
  }, [currentTimezone]);

  const updateDateValue = (dateValue) => {
    const zone = currentTimezone;

    let value: TimestampValueV2 = {
      // Clear values if there is no date value.
      date: null,
      ...(block.dateTimeType === 'datetime' && { time: null }),
      zone,
    };
    if (dateValue) {
      const isoString = createIsoString({
        timestamp: isOldDataPresent(recorded) ? (recorded?.value as string) : undefined,
        zone,
        year: dateValue.getFullYear(),
        month: dateValue.getMonth() + 1, // getMonth is 0-based
        day: dateValue.getDate(),
      });

      value = {
        date: getISODate(isoString),
        ...(block.dateTimeType === 'datetime' && { time: existingValue.time ?? getISOTime(isoString) }),
        zone,
      };
    }

    // Notify observers that value changed
    if (onRecordValuesChanged) {
      const _recorded = { value };
      if (!isEqual(recorded, _recorded)) {
        onRecordValuesChanged(_recorded);
      }
    }
  };

  const updateTimeValue = (timeValue) => {
    const zone = currentTimezone;

    let value: TimestampValueV2 = {
      // Clear values if there is no time value.
      ...(block.dateTimeType === 'datetime' && { date: null }),
      time: null,
      zone,
    };
    if (timeValue) {
      const [hour, minute, second] = timeValue.split(':');

      const isoString = createIsoString({
        timestamp: isOldDataPresent(recorded) ? (recorded?.value as string) : undefined,
        zone,
        hour,
        minute,
        second,
      });

      value = {
        ...(block.dateTimeType === 'datetime' && { date: existingValue.date ?? getISODate(isoString) }),
        time: timeValue,
        zone,
      };
    }

    // Notify observers that value changed
    if (onRecordValuesChanged) {
      const _recorded = { value };
      if (!isEqual(recorded, _recorded)) {
        onRecordValuesChanged(_recorded);
      }
    }
  };

  const updateTimezoneValue = (option) => {
    if (!option) {
      disableEditTimezone(); // Close the timezone editor if the timezone value changes.
      return;
    }

    const zone = option.value;

    const value = {
      ...existingValue,
      zone,
    };

    if (onRecordValuesChanged) {
      const _recorded = { value };
      if (!isEqual(recorded, _recorded)) {
        onRecordValuesChanged(_recorded);
      }
    }
    disableEditTimezone(); // Close the timezone editor if the timezone value changes.
  };

  const setNow = () => {
    const zone = currentTimezone;
    const nowIso = createIsoString({ zone });

    const newData = splitIsoTimestamp(nowIso);
    const value = {
      ...(['date', 'datetime'].includes(block.dateTimeType) && { date: newData.date }),
      ...(['time', 'datetime'].includes(block.dateTimeType) && { time: newData.time }),
      zone,
    };
    if (onRecordValuesChanged) {
      const _recorded = { value };
      if (!isEqual(recorded, _recorded)) {
        onRecordValuesChanged(_recorded);
      }
    }
  };

  return (
    <>
      <div className="self-start mt-1.5">
        <MarkdownView text={blockName} />
      </div>
      <div className="py-1.5">=</div>
      <div className="flex flex-nowrap grow justify-between space-x-2">
        <div className="w-fit">
          <Field name="text">
            {() => (
              <div className="flex flex-row flex-wrap gap-x-2 justify-start items-center">
                {(block.dateTimeType === 'datetime' || block.dateTimeType === 'date') && (
                  <div className="w-[7.5rem]" aria-label="Select Date">
                    <DatePicker
                      className="w-full py-1.5 px-2 rounded border border-gray-400"
                      selected={recordedDate}
                      dateFormat="dd MMM yyyy"
                      onChange={updateDateValue}
                    />
                  </div>
                )}
                {(block.dateTimeType === 'datetime' || block.dateTimeType === 'time') && (
                  <div className="w-[6.5rem]">
                    <TimePicker
                      className="w-full rounded border border-gray-400 bg-white text-black"
                      onChange={updateTimeValue}
                      format="HH:mm:ss"
                      maxDetail="second"
                      hourPlaceholder="HH"
                      minutePlaceholder="mm"
                      secondPlaceholder="ss"
                      value={recordedTime}
                      disableClock={true}
                    />
                  </div>
                )}
                <div className="h-10 flex items-center">
                  {!editTimezone && (
                    <Button type="tertiary" onClick={enableEditTimezone}>
                      <span className="text-xs underline decoration-dotted">{timezoneSelected?.label}</span>
                    </Button>
                  )}
                  {editTimezone && (
                    <div ref={timezoneWrapperRef}>
                      <Select
                        ref={timezoneRef}
                        classNamePrefix="react-select"
                        className="text-xs"
                        placeholder="Select Timezone"
                        onChange={updateTimezoneValue}
                        options={TIMEZONE_DISPLAY_OPTIONS}
                        styles={TIMEZONE_SELECT_STYLES}
                        value={timezoneSelected}
                        aria-label="Select Timezone"
                        onBlur={disableEditTimezone}
                        defaultMenuIsOpen={true}
                      />
                    </div>
                  )}
                </div>
                <Button type="secondary" size="xs" onClick={setNow} leadingIcon={'fa-regular fa-clock' as IconProp}>
                  Set to Now
                </Button>
                {block.dateTimeType === 'time' && <TimeFieldInputHelpIcon />}
              </div>
            )}
          </Field>
        </div>
      </div>
    </>
  );
});

export default TimestampFieldInput;

import React from 'react';
import { Link } from 'react-router-dom';
import ProcedureDiffText from '../ProcedureDiffText';
import { RequirementBlock } from 'shared/lib/types/views/procedures';
import { isEmpty } from 'lodash';

/*
 * Component for rendering a Block of type RequirementBlockDiffElement.
 * Conforms to TypedBlockInterface, see comments in useBlockComponents
 */
interface RequirementProps {
  block: RequirementBlock;
}

const Requirement = ({ block }: RequirementProps) => {
  const { integrationDetails } = block;

  // Function to check if integrationDetails is not an empty object
  const hasIntegrationDetails = !isEmpty(integrationDetails);

  return (
    <div className="flex px-2 py-1 rounded bg-slate-200">
      <div className="ml-2 whitespace-pre-line min-w-0 w-full break-words">
        {!hasIntegrationDetails && (
          <>
            <span>Requirement:</span>
            <span className="ml-2">
              <ProcedureDiffText diffValue={block.label} />
            </span>
          </>
        )}
        {hasIntegrationDetails && (
          <div>
            <div>
              <span className="field-title">Requirement</span>
            </div>
            {block.subType === 'epsilon3' ? (
              <Link to={integrationDetails.link} className="text-blue-500">
                {integrationDetails.name}
              </Link>
            ) : (
              <a href={integrationDetails.link} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                {integrationDetails.name}
              </a>
            )}
            <div>{integrationDetails.description && <>{integrationDetails.description}</>}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Requirement;

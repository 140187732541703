import { useMemo } from 'react';
import runUtil from '../lib/runUtil';

const useRun = ({ run }) => {
  /**
   * Calculate the initial set of sections to show.
   *
   * This is needed since linked runs can be run with only one section.
   */
  const displaySections = useMemo(() => {
    if (!run) {
      return [];
    }
    const sections = runUtil.runSectionAndRepeats(run);
    const display = [];
    sections.forEach((section) => {
      // Find the index of the section in the original run.
      const sectionIndex = run.sections.findIndex((runSection) => section.id === runSection.id);
      display.push([section, sectionIndex]);
    });
    return display;
  }, [run]);

  return { displaySections };
};

export default useRun;

import React, { useCallback } from 'react';
import validateUtil from '../lib/validateUtil';
import { useProcedureEdit } from '../contexts/ProcedureEditContext';
import FieldSetToggle from './FieldSetToggle';

const FieldSetAutomationToggle = ({ value, field, form, procedure }) => {
  const { mergeAllSectionErrors, onProcedureChanged } = useProcedureEdit();

  const onChangeHandler = useCallback(() => {
    if (procedure && !value) {
      const sectionErrors = { sections: {} };
      procedure.automation_enabled = true;
      const updatedProcedure = validateUtil.validateAutomationList(procedure, sectionErrors);
      if (updatedProcedure) {
        onProcedureChanged(updatedProcedure, procedure);
      }
      if (Object.keys(sectionErrors).length > 0) {
        mergeAllSectionErrors(sectionErrors);
      }
    }
    form.setFieldValue(field.name, !value);
  }, [value, form, field.name, procedure, mergeAllSectionErrors, onProcedureChanged]);

  return <FieldSetToggle value={value} onChange={onChangeHandler} label="Automation" grow={false} />;
};

export default FieldSetAutomationToggle;

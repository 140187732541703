import {
  Part,
  PartRevisionSummary,
} from 'shared/lib/types/postgres/manufacturing/types';

export const getReleasedRevisions = (
  revisions: Array<PartRevisionSummary>
): Array<PartRevisionSummary> => {
  return revisions.filter(isReleasedRevision);
};

const isReleasedRevision = (revision: PartRevisionSummary): boolean => {
  return !revision.release_state || revision.release_state === 'released';
};

/**
 * Returns true if the first (most recent) revision of any part is not `'released'`
 */
export const anyUnreleasedRevisions = (parts: Array<Part>): boolean => {
  for (const part of parts) {
    if (part.revisions && part.revisions.length > 0) {
      if (part.revisions[0].release_state !== 'released') {
        return true;
      }
    }
  }
  return false;
};

import { useState } from 'react';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { Link } from 'react-router-dom';
import Label from './Label';
import { RunIssues } from './RunIssuesButton';
import { RunIssue } from 'shared/lib/types/views/procedures';
import { issuePath } from '../lib/pathUtil';
import TabBar, { TabProps } from '../components/TabBar/TabBar';
import { StatusTab } from '../issues/types';
import { StatusType } from 'shared/lib/types/postgres/issues';

const TABS: ReadonlyArray<TabProps<StatusTab>> = [
  { id: StatusTab.Open, label: 'Open' },
  { id: StatusTab.Closed, label: 'Closed' },
];

type RunIssueRowProps = {
  issue: RunIssue;
};

const RunIssueRow = ({ issue }: RunIssueRowProps) => {
  const { currentTeamId } = useDatabaseServices();

  const createNCRIssueLink = (issueId) => {
    return issuePath(currentTeamId, issueId);
  };

  const isDraft = issue?.status?.status === StatusType.Draft;

  return (
    <div key={issue.id} className="flex flex-none ml-2 mb-2 w-72 items-center">
      {issue.severity && (
        <div className="min-w-14">
          <Label text={issue.severity.name} color={issue.severity.color} size="xs" />
        </div>
      )}
      <Link
        to={{ pathname: createNCRIssueLink(issue.id), state: { from: 'run' } }}
        title={`${issue.id} - ${issue.text}`}
        className={`text-base text-blue-600 hover:underline truncate mr-1 ${isDraft ? 'italic' : ''}`}
      >
        {`${issue.id} - ${issue.text}`}
      </Link>
      <span className="text-gray-500 text-xs italic">{`${isDraft ? '(Draft) ' : ''}`}</span>
    </div>
  );
};

type RunIssuesDisplayProps = {
  issues: RunIssues;
};

const RunIssuesDisplay = ({ issues }: RunIssuesDisplayProps) => {
  const [tab, setTab] = useState<StatusTab>(StatusTab.Open);

  const { open, closed } = issues;
  const displayIssues = tab === StatusTab.Open ? open : closed;
  const hasIssues = displayIssues.length > 0;

  return (
    <>
      <div className="text-xs m-2">
        <TabBar tabs={TABS} selectedTab={tab} setSelectedTab={setTab} />
      </div>
      <div className="max-h-80 overflow-y-auto">
        {hasIssues && displayIssues.map((issue) => <RunIssueRow issue={issue} />)}
        {!hasIssues && <div className="text-slate-400 m-2">No issues</div>}
      </div>
    </>
  );
};

export default RunIssuesDisplay;

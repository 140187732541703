import { useState, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { RunIssue } from 'shared/lib/types/views/procedures';
import stickyHeaderUtil from '../lib/stickyHeaderUtil';
import JiraIssueModal from './JiraIssueModal';
import { OverlayPanel } from 'primereact/overlaypanel';

interface JiraIssuesButtonProps {
  issues?: Record<string, RunIssue>;
  onAddIssue: (issue: RunIssue) => Promise<void>;
  canCreateIssue?: boolean;
  runId: string;
  urlText: string;
}

const JiraIssuesButton = ({ issues, onAddIssue, canCreateIssue, runId, urlText }: JiraIssuesButtonProps) => {
  const [showIssueModal, setShowIssueModal] = useState(false);
  const location = window.location;
  const locationUrl = `${location.protocol}//${location.host}${location.pathname}`;
  const op = useRef<OverlayPanel>(null);

  const runIssues = useMemo(() => {
    if (!issues) {
      return [];
    }
    return Object.values(issues);
  }, [issues]);

  const issueLabelText = useMemo(() => {
    if (runIssues.length > 0) {
      return 'Issues';
    }
    return 'No Issues';
  }, [runIssues]);

  return (
    <>
      <button
        className={`py-2 flex flex-none items-center ${stickyHeaderUtil.getButtonClassNames(false, false)}`}
        type="button"
        title="Jira Issues"
        aria-label="Jira Issues"
        onClick={(e) => op?.current?.toggle(e)}
      >
        <FontAwesomeIcon fixedWidth={true} className="text-blue-500" icon={'fa-brands fa-jira' as IconProp} />
        <div className="ml-2 lg:hidden">Issues</div>
        <FontAwesomeIcon className="ml-2" icon="caret-down" />
      </button>
      <OverlayPanel className="p-0" ref={op}>
        <div className="flex flex-col bg-white shadow-md text-sm font-medium z-50 rounded">
          {canCreateIssue && (
            <>
              <div className="m-2">
                <button type="button" className="hover:brightness-50 h-6" onClick={() => setShowIssueModal(true)}>
                  <FontAwesomeIcon className="text-blue-500 opacity-100" icon="circle-plus" />
                  <span className="text-sm font-semibold ml-1">Create Jira Issue</span>
                </button>
              </div>
              <div className="bg-slate-300 min-h-[1px] h-[1px] mt-1 ml-2 w-52"></div>
            </>
          )}
          <div className="text-slate-400 text-xs mt-1 ml-2 mb-2">{issueLabelText}</div>
          <div className="max-h-80 overflow-y-auto">
            {runIssues.map((issue) => (
              <div key={issue.id} className="flex flex-none ml-2 mb-2 w-52 items-center">
                <a href={issue.url} target="_blank" rel="noreferrer" className="link truncate">
                  {issue.text}
                </a>
              </div>
            ))}
          </div>
        </div>
      </OverlayPanel>

      <JiraIssueModal
        // TODO: [EPS-3845] config setting to allow this to be generic
        urlText={urlText}
        url={locationUrl}
        onAddIssue={onAddIssue}
        isModalShown={showIssueModal}
        onHideModal={() => setShowIssueModal(false)}
      />
    </>
  );
};

export default JiraIssuesButton;

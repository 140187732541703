import { cannotUpdateStep, isStepEnded } from 'shared/lib/runUtil';
import runUtils from '../runUtil';
import field from './field';
import tableInput from './tableInput';
import { stringify } from 'csv-stringify/sync';
import signoffUtil from 'shared/lib/signoffUtil';
import { getStepDisplayIndex } from '../batchSteps';

const PROCEDURE_HEADER = ['procedure_started_at', 'procedure_ended_at'];

const RUN_ACTIONS_HEADER = ['run_action_type', 'run_action_user', 'run_action_timestamp', 'run_action_comment'];

const RUN_ACTION_HEADER_TO_DATA_KEY_MAP = {
  run_action_type: 'type',
  run_action_user: 'user_id',
  run_action_timestamp: 'timestamp',
  run_action_comment: 'comment',
};

const STEP_HEADER = ['step', 'step_name', 'section_repeat_number', 'step_repeat_number'];

const FIELD_INPUT_HEADER = ['field_input_name', 'field_input_type', 'field_input_value', 'field_input_units'];

const TABLE_INPUT_HEADER = [
  'table_input_row_index',
  'table_input_row_values',
  'table_input_row_types',
  'table_input_row_units',
];

const STEP_SIGNOFF_HEADER = ['step_signoff_roles', 'step_signoff_timestamps', 'step_signoff_users'];

const STEP_REVOKE_SIGNOFF_HEADER = [
  'step_revoke_signoff_revoked_roles',
  'step_revoke_signoff_timestamps',
  'step_revoke_signoff_users',
];

const STEP_STATUS_HEADER = ['step_completed_at', 'step_completed_user', 'step_skipped_at', 'step_skipped_user'];

const STEP_COMMENT_HEADER = ['comment_type', 'comment', 'posted_at', 'posted_by'];

const TAGS_HEADER = ['tags'];

const CSV_HEADER = [
  ...STEP_HEADER,
  ...FIELD_INPUT_HEADER,
  ...TABLE_INPUT_HEADER,
  ...STEP_SIGNOFF_HEADER,
  ...STEP_REVOKE_SIGNOFF_HEADER,
  ...STEP_STATUS_HEADER,
  ...STEP_COMMENT_HEADER,
  ...PROCEDURE_HEADER,
  ...RUN_ACTIONS_HEADER,
  ...TAGS_HEADER,
];

/**
 * csvUtil provides functions to create the 2D array representation of the CSV to download.
 */
const csvUtil = {
  /**
   * Get CSV data for a run, suitable for export to CSV.
   *
   * @param {Object} run - A run document.
   * @param {Object} config - The { config } field from useSettings()
   * @returns {Array} - Returns CSV data as an array of arrays, where the first
   *   row describes each column, and each column contains information about each
   *   field input, e.g., "name", "type", etc. See the array header for a
   *   description of each column.
   */
  getCsvArray: (run, config = {}) => {
    // Get step-level data.
    let rowDataObjects = [];
    run.sections.forEach((section, sectionIndex) => {
      section.steps.forEach((step, stepIndex) => {
        const stepDataObjects = csvUtil._getStepDataObjects(run, step, sectionIndex, stepIndex, config);
        rowDataObjects.push(...stepDataObjects);
      });
    });

    // Get run action data.
    const runActionDataObjects = csvUtil._getRunActionDataObjects(run);
    rowDataObjects = rowDataObjects.concat(runActionDataObjects);

    // Get run-level data.
    const runDataObject = csvUtil._getRunDataObject(run);

    // Build final CSV array data.
    const rowDataArrays = rowDataObjects.map((rowDataObject) => {
      return [
        ...STEP_HEADER.map((stepHeaderTitle) => rowDataObject[stepHeaderTitle]),
        ...FIELD_INPUT_HEADER.map((fieldInputHeaderTitle) =>
          fieldInputHeaderTitle in rowDataObject ? rowDataObject[fieldInputHeaderTitle] : null
        ),
        ...TABLE_INPUT_HEADER.map((tableInputHeaderTitle) =>
          tableInputHeaderTitle in rowDataObject ? rowDataObject[tableInputHeaderTitle] : null
        ),
        ...STEP_SIGNOFF_HEADER.map((stepSignoffHeaderTitle) =>
          stepSignoffHeaderTitle in rowDataObject ? rowDataObject[stepSignoffHeaderTitle] : null
        ),
        ...STEP_REVOKE_SIGNOFF_HEADER.map((stepRevokeSignoffHeaderTitle) =>
          stepRevokeSignoffHeaderTitle in rowDataObject ? rowDataObject[stepRevokeSignoffHeaderTitle] : null
        ),
        ...STEP_STATUS_HEADER.map((stepStatusHeaderTitle) =>
          stepStatusHeaderTitle in rowDataObject ? rowDataObject[stepStatusHeaderTitle] : null
        ),
        ...STEP_COMMENT_HEADER.map((stepCommentHeaderTitle) =>
          stepCommentHeaderTitle in rowDataObject ? rowDataObject[stepCommentHeaderTitle] : null
        ),
        ...PROCEDURE_HEADER.map((procedureHeaderTitle) =>
          procedureHeaderTitle in runDataObject ? runDataObject[procedureHeaderTitle] : null
        ),
        ...RUN_ACTIONS_HEADER.map((runActionsHeaderTitle) =>
          runActionsHeaderTitle in rowDataObject ? rowDataObject[runActionsHeaderTitle] : null
        ),
        ...TAGS_HEADER.map((header) => (header in runDataObject ? runDataObject[header] : null)),
      ];
    });
    return [CSV_HEADER, ...rowDataArrays];
  },

  /**
   * Get CSV data object rows for a given step.
   *
   * @param {Object} run - The full run document object.
   * @param {Object} step - A step object from a run document.
   * @param {Number} sectionIndex - The step section index.
   * @param {Number} stepIndex - The step index within the section.
   * @returns {Array} - List of row objects suitable for exporting to CSV.
   */
  _getStepDataObjects: (run, step, sectionIndex, stepIndex, config) => {
    // Get supporting data objects.
    const stepSignoffDataObject = csvUtil._getStepSignoffDataObject(step);
    const stepRevokeSignoffDataObject = csvUtil._getStepRevokeSignoffDataObject(step);
    const stepStatusDataObject = csvUtil._getStepStatusDataObject(step);
    const stepContext = csvUtil._getStepContext(run.sections, sectionIndex, stepIndex, config);
    const commentsDataObject = csvUtil._processComments(step);
    const stepContextCsvObject = csvUtil._getStepContextDataObject(stepContext);

    // Build final row data objects list.
    const rowDataObjects = [];
    step.content.forEach((content) => {
      const contentType = content.type.toLowerCase();
      const substepComments = commentsDataObject.filter(
        (comment) => comment.reference_id && comment.reference_id === content.id
      );

      const commonRowData = {
        ...stepSignoffDataObject,
        ...stepRevokeSignoffDataObject,
        ...stepStatusDataObject,
      };

      if (contentType === 'input') {
        const row = csvUtil._getFieldInputDataObject(content, stepContext);
        rowDataObjects.push({
          ...row,
          ...commonRowData,
          ...(substepComments.length > 0 ? substepComments.shift() : {}),
          comment_type: 'substep',
        });
      } else if (contentType === 'expression') {
        const row = csvUtil._getExpressionDataObject(content, stepContext);
        rowDataObjects.push({
          ...row,
          ...commonRowData,
          ...(substepComments.length > 0 ? substepComments.shift() : {}),
          comment_type: 'substep',
        });
      } else if (contentType === 'table_input') {
        const rows = csvUtil._getTableInputDataObjects(content, stepContext);
        for (const row of rows) {
          rowDataObjects.push({
            ...row,
            ...commonRowData,
            ...(substepComments.length > 0 ? substepComments.shift() : {}),
            comment_type: 'substep',
          });
        }
      }
      // Use up the remaining substepComments
      if (substepComments.length > 0) {
        substepComments.forEach((comment) => {
          rowDataObjects.push({
            ...stepContextCsvObject,
            ...commonRowData,
            ...comment,
            comment_type: 'substep',
          });
        });
      }
    });

    /**
     * If there are no content/block rows for this step, add a row so we'll
     * have step signoffs, etc.
     */
    const stepComments = commentsDataObject.filter((comment) => !comment.reference_id);
    if (rowDataObjects.length === 0) {
      const stepContextDataObject = csvUtil._getStepContextDataObject(stepContext);
      rowDataObjects.push({
        ...stepContextDataObject,
        ...stepSignoffDataObject,
        ...stepRevokeSignoffDataObject,
        ...stepStatusDataObject,
        ...(stepComments.length > 0 ? stepComments.shift() : {}),
      });
    }

    // push a row per remaining comment on the step
    if (stepComments.length > 0) {
      stepComments.forEach((comment) => {
        rowDataObjects.push({
          ...stepContextCsvObject,
          ...comment,
        });
      });
    }
    return rowDataObjects;
  },

  _getStepContext: (sections, sectionIndex, stepIndex, config) => {
    const stepDisplayIndex = getStepDisplayIndex(sections[sectionIndex].steps, stepIndex);
    const stepLabel = runUtils.displaySectionStepKey(
      sections,
      sectionIndex,
      stepDisplayIndex,
      config && config.display_sections_as
    );
    const sectionRepeatNumber = runUtils.displayRepeatKey(sections, sectionIndex);

    const steps = sections[sectionIndex].steps;
    const stepRepeatNumber = runUtils.displayRepeatKey(steps, stepIndex);

    const step = steps[stepIndex];
    const includeFieldValues = isStepEnded(step) || cannotUpdateStep(step);

    const name = step.name;

    return {
      stepLabel,
      name,
      sectionRepeatNumber,
      stepRepeatNumber,
      includeFieldValues,
    };
  },

  _getStepSignoffDataObject: (step) => {
    const actions = signoffUtil.getSignoffActions(step);
    return {
      step_signoff_roles: csvUtil._arrayToEscapedCsvString(actions.map((a) => a.operator)),
      step_signoff_timestamps: csvUtil._arrayToEscapedCsvString(actions.map((a) => a.timestamp)),
      step_signoff_users: csvUtil._arrayToEscapedCsvString(actions.map((a) => a.user_id)),
    };
  },

  _getStepRevokeSignoffDataObject: (step) => {
    const actions = signoffUtil.getRevokeSignoffActions(step);

    return {
      step_revoke_signoff_revoked_roles: csvUtil._arrayToEscapedCsvString(actions.map((a) => a.revoked_operator)),
      step_revoke_signoff_timestamps: csvUtil._arrayToEscapedCsvString(actions.map((a) => a.timestamp)),
      step_revoke_signoff_users: csvUtil._arrayToEscapedCsvString(actions.map((a) => a.user_id)),
    };
  },

  _processComments: (step) => {
    if (!step.comments) {
      return [];
    }
    const sortedComments = csvUtil._sortComments(step.comments);
    return sortedComments.map((comment) => csvUtil._getStepCommentsDataObject(comment));
  },

  // Sorts parent comments by timestamp descending and places their replies (also sorted) immediately after them
  _sortComments: (comments) => {
    const parentComments = comments.filter((comment) => comment.parent_id === '');
    const replies = comments.filter((comment) => comment.parent_id !== '');

    parentComments.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

    const getAndSortReplies = (parentId) => {
      return replies
        .filter((reply) => reply.parent_id === parentId)
        .sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
    };

    let sortedComments = [];
    parentComments.forEach((parentComment) => {
      sortedComments.push(parentComment);
      const sortedReplies = getAndSortReplies(parentComment.id);
      sortedComments = sortedComments.concat(sortedReplies);
    });

    return sortedComments;
  },

  _getStepStatusDataObject: (step) => {
    return {
      step_completed_at: step.completedAt,
      step_completed_user: step.completedUserId,
      step_skipped_at: step.skippedAt,
      step_skipped_user: step.skippedUserId,
    };
  },

  _getStepCommentsDataObject: (comment) => {
    return {
      comment_type: comment.parent_id === '' ? 'main' : 'reply',
      comment: comment.text,
      posted_at: comment.timestamp,
      posted_by: comment.user,
      reference_id: comment.reference_id,
    };
  },

  _getStepContextDataObject: (stepContext) => {
    return {
      step: stepContext.stepLabel,
      step_name: stepContext.name,
      section_repeat_number: stepContext.sectionRepeatNumber,
      step_repeat_number: stepContext.stepRepeatNumber,
    };
  },

  _getRunActionDataObjects: (run) => {
    const actionDataObjects = [];

    run.actions?.forEach((action) => {
      const actionObject = {};

      RUN_ACTIONS_HEADER.forEach((headerKey) => {
        const dataKey = RUN_ACTION_HEADER_TO_DATA_KEY_MAP[headerKey];

        if (action[dataKey]) {
          actionObject[headerKey] = action[dataKey];
        }
      });

      if (Object.keys(actionObject).length) {
        actionDataObjects.push(actionObject);
      }
    });

    return actionDataObjects;
  },

  _getTags: (run) => {
    return (run.tags || []).concat(run.run_tags || []).map((tag) => tag.name);
  },

  _getRunDataObject: (run) => {
    return {
      procedure_started_at: run.starttime,
      procedure_ended_at: run.completedAt,
      tags: csvUtil._getTags(run),
    };
  },

  _getFieldInputDataObject: (content, stepContext) => {
    const stepContextCsvObject = csvUtil._getStepContextDataObject(stepContext);
    const fieldValue = field.getValue(content, stepContext.includeFieldValues);
    const fieldInputType = field.getInputType(content);
    const fieldInputUnits = field.getUnits(content);

    return {
      ...stepContextCsvObject,
      field_input_name: content.name,
      field_input_type: fieldInputType,
      field_input_value: fieldValue,
      field_input_units: fieldInputUnits,
    };
  },

  _getExpressionDataObject: (content, stepContext) => {
    const stepContextCsvObject = csvUtil._getStepContextDataObject(stepContext);
    const fieldValue = field.getValue(content, stepContext.includeFieldValues);

    return {
      ...stepContextCsvObject,
      field_input_name: content.name,
      field_input_type: 'expression',
      field_input_value: fieldValue,
      field_input_units: '',
    };
  },

  _getTableInputDataObjects: (content, stepContext) => {
    const stepContextCsvObject = csvUtil._getStepContextDataObject(stepContext);

    const tableData = tableInput.getTableData(content, stepContext.includeFieldValues);

    const tableInputTypesArray = tableInput.getTableInputTypes(tableData);
    const tableInputTypesCsvString = csvUtil._arrayToEscapedCsvString(tableInputTypesArray);

    const tableInputUnits = tableInput.getTableInputUnits(tableData);
    const tableInputUnitsEscaped = csvUtil._arrayToEscapedCsvString(tableInputUnits);

    const tableRows = tableData.map((fieldInputRow, rowIndex) => {
      const fieldInputRowValues = fieldInputRow.map((cell) => {
        return cell.cellValue;
      });
      const fieldInputRowValuesEscaped = csvUtil._arrayToEscapedCsvString(fieldInputRowValues);
      return {
        ...stepContextCsvObject,
        table_input_row_index: rowIndex,
        table_input_row_values: fieldInputRowValuesEscaped,
        table_input_row_types: tableInputTypesCsvString,
        table_input_row_units: tableInputUnitsEscaped,
      };
    });

    return tableRows;
  },

  _arrayToEscapedCsvString: (array) => {
    /*
     *Example 1: array = ['Test Row', '"Test 1, with, commas, and quotation marks"', 'TRUE', '2']
     *Example 2: array = ['Test Row B', 'Test 2, with, commas', 'TRUE', '2']
     */
    array = array.map((element) => {
      // 1. Escape any literal `"`
      element = stringify([[element]], {
        quoted: false,
        quoted_empty: false,
        eof: false,
        escape: '"',
      });
      /*
       *Example 1: element = ...['Test Row', '"""Test 1, with, commas, and quotation marks"""', 'TRUE', '2']
       *Example 2: element = ...['Test Row B', 'Test 2, with, commas', 'TRUE', '2']
       */

      // 2. Remove starting and ending `"` created by the above step, since we just wanted to escape the literal `"` above
      element = element.replace(/^"|"$/g, '');
      /*
       *Example 1: element = ...['Test Row', '""Test 1, with, commas, and quotation marks""', 'TRUE', '2']
       *Example 2: element = ...['Test Row B', 'Test 2, with, commas', 'TRUE', '2']
       */

      // 3. Escape existing quotation marks, and wrap each element with quotation marks, since this is a nested CSV
      element = stringify([[element]], {
        quoted: true,
        quoted_empty: true,
        eof: false,
        escape: '"',
      });
      /*
       *Example 1: element = ...['"Test Row"', '"""""Test 1, with, commas, and quotation marks"""""', '"TRUE"', '"2"']
       *Example 2: element = ...['"Test Row B"', '"Test 2, with, commas"', '"TRUE"', '"2"']
       */

      return element;
    });

    /*
     *4. Wrap each element again with quotation marks (which will effectively escape the `"` added in step 3),
     *and combine all elements in a CSV list (This list will be wrapped with a pair of `"` during the final output)
     */
    const csvEscapedString = stringify([array], {
      quoted: true,
      quoted_empty: true,
      eof: false,
      escape: '',
    });
    /*
     *Example 1: csvEscapedString = '""Test Row"",""""""Test 1, with, commas, and quotation marks"""""",""TRUE"",""2""'
     *Example 2: csvEscapedString = '""Test Row B"",""Test 2, with, commas"",""TRUE"",""2""'
     */

    return csvEscapedString;
  },
};

export default csvUtil;

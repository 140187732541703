import { DateTime } from 'luxon';
import { FrontendEvent as Event } from 'shared/schedule/types/event';

export default class MultiEvent {
  events: Array<Event>;
  isMultiEvent = true;

  constructor(events: Array<Event>) {
    this.events = events;
  }

  get start(): DateTime {
    return DateTime.min(...this.events.map((e) => e.start));
  }

  get end(): DateTime {
    return DateTime.max(...this.events.map((e) => e.end || e.start));
  }
}

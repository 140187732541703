import { useMemo } from 'react';
import diffUtil from '../lib/diffUtil';
import { newStepRedline, REDLINE_TYPE } from 'shared/lib/redlineUtil';
import {
  RedlinedStep,
  RunStep,
  RunStepFullRedline,
  StepDiffElement,
} from 'shared/lib/types/views/procedures';

import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';

interface UseFullStepRedlineProps {
  currentStep: RunStep;
  redlines: Array<RunStepFullRedline>;
}

type UseFullStepRedlineReturn = {
  isCurrentLatest: boolean;
  currentStepCleaned: Partial<RedlinedStep>;
  latestRedline?: RunStepFullRedline;
  latestStepDiff?: Array<StepDiffElement>;
  reversedRedlines: Array<RunStepFullRedline>;
};

const useFullStepRedline = ({
  currentStep,
  redlines = [],
}: UseFullStepRedlineProps): UseFullStepRedlineReturn => {
  const redlinesWithOriginal = useMemo(() => {
    if (redlines[0] && !redlines[0].is_original) {
      const originalPseudoBlueline = newStepRedline({
        step: currentStep,
        userId: 'system',
        pending: false,
        fieldOrBlockMetadata: {},
        isRedline: false,
        createdAt: new Date().toISOString(),
        type: REDLINE_TYPE.FULL_STEP_REDLINE,
      }) as RunStepFullRedline;

      originalPseudoBlueline.is_original = true;
      return [originalPseudoBlueline, ...redlines];
    }
    return redlines;
  }, [currentStep, redlines]);

  const latestRedline = useMemo(() => {
    return redlinesWithOriginal.length > 0
      ? redlinesWithOriginal[redlinesWithOriginal.length - 1]
      : undefined;
  }, [redlinesWithOriginal]);

  const reversedRedlines = useMemo(
    () => [...redlinesWithOriginal].reverse(),
    [redlinesWithOriginal]
  );

  const currentStepCleaned = useMemo(
    () => diffUtil.prepareRedlinedStepForDiff({ step: currentStep }),
    [currentStep]
  );

  const latestStepDiff = useMemo(() => {
    if (!latestRedline) {
      return;
    }

    return diffUtil.getFullStepRedlineDiff({
      previousStep: currentStepCleaned as RedlinedStep,
      updatedStep: latestRedline.step,
    });
  }, [latestRedline, currentStepCleaned]);

  const latestStepDiffIgnoringIds = useMemo(() => {
    if (!latestRedline) {
      return;
    }

    return diffUtil.getFullStepRedlineDiff({
      previousStep: currentStepCleaned as RedlinedStep,
      updatedStep: latestRedline.step,
      ignoreIds: true,
    });
  }, [latestRedline, currentStepCleaned]);

  const isCurrentLatest = useMemo(
    () =>
      !latestStepDiffIgnoringIds?.[0]?.diff_change_state ||
      latestStepDiffIgnoringIds?.[0]?.diff_change_state ===
        ARRAY_CHANGE_SYMBOLS.UNCHANGED,
    [latestStepDiffIgnoringIds]
  );

  return {
    isCurrentLatest,
    currentStepCleaned,
    latestRedline,
    latestStepDiff,
    reversedRedlines,
  };
};

export default useFullStepRedline;

import React, { useEffect, useCallback, useRef } from 'react';
import styles from './TextAreaAutoHeight.module.css';

/**
 * Component for rendering a textarea with autosizing height.
 *
 * Uses a CSS implementation with very simple Javascript for performance. Note
 * that no custom classNames or CSS properties are supported other than what is
 * explicitly made available (eg leftPadding), due to the hidden sizing element.
 *
 * leftPadding: Custom CSS left-padding value for the textarea, eg to make room
 *              for an icon.
 * value: String, the current form value from formik.
 * ...other: All other props will be passed through to the `<textarea>` element.
 */
export default function TextAreaAutoHeight({ leftPadding = null, value, textSize = 'sm', defaultRows = 1, ...other }) {
  const textInput = useRef(null);

  // Update invisible sizing element with text content.
  const updateReplicatedValue = useCallback(() => {
    if (!textInput.current) {
      return;
    }
    textInput.current.parentNode.dataset.replicatedValue = textInput.current.value;
  }, []);

  // Keep replicatedValue in sync with controlled form value.
  useEffect(() => updateReplicatedValue(), [value, updateReplicatedValue]);

  // Track text input ref and update sizing element text.
  const onRefChanged = useCallback(
    (elem) => {
      textInput.current = elem;
      if (!textInput.current) {
        return;
      }
      /**
       * Because the textarea and the invisible ::after element need to be styled
       * exactly the same, we have to pass any customization into the CSS module.
       *
       * Not a big fan of having to use this kind of CSS-in-JS, but this is fast
       * at least. In the future, we could explore not using the ::after element
       * and being able to style both in JSX directly.
       */
      if (leftPadding) {
        textInput.current.parentNode.style.setProperty('--textarea-left-padding', leftPadding);
      }
      if (textSize === 'base') {
        textInput.current.parentNode.style.setProperty('--textarea-font-size', '1rem');
        textInput.current.parentNode.style.setProperty('--textarea-line-height', '1.5rem');
      } else if (textSize === 'sm') {
        textInput.current.parentNode.style.setProperty('--textarea-font-size', '0.875rem');
        textInput.current.parentNode.style.setProperty('--textarea-line-height', '1.25rem');
      }
      updateReplicatedValue();
    },
    [updateReplicatedValue, leftPadding, textSize]
  );

  return (
    <div className={styles.wrapper}>
      {/* Change from browser default 2 rows to just 1 */}
      <textarea {...other} value={value} rows={defaultRows} ref={onRefChanged} />
    </div>
  );
}

import { useState } from 'react';
import procedureUtil from '../lib/procedureUtil';
import { Mention } from 'shared/lib/types/postgres/util';
import { CommentInterface } from 'shared/lib/comment';

interface UseCommentHandlerProps {
  onAddComment?: (comment: string, mentions: Array<Mention>) => Promise<void>;
  onEditComment?: (
    comment: CommentInterface,
    commentId: string
  ) => Promise<void>;
  autoFocus?: boolean;
}

interface EditCommentContext {
  id: string | null;
  text: string;
  mentions: Array<Mention>;
}

interface UseCommentHandlerReturn {
  isSubmittingComment: boolean;
  hasCommentError: boolean;
  editCommentContext: EditCommentContext;
  autoFocus: boolean;
  submitComment: (
    values: CommentInterface,
    helpers: { resetForm: () => void }
  ) => Promise<void>;
  handleEditClick: (comment) => void;
  handleCancel: () => void;
}

const useCommentHandler = ({
  onAddComment,
  onEditComment,
  autoFocus = false,
}: UseCommentHandlerProps): UseCommentHandlerReturn => {
  const [isSubmittingComment, setIsSubmittingComment] = useState(false);
  const [hasCommentError, setHasCommentError] = useState(false);
  const [editCommentContext, setEditCommentContext] =
    useState<EditCommentContext>({
      id: null,
      text: '',
      mentions: [],
    });

  const submitComment = async (values: CommentInterface, { resetForm }) => {
    setIsSubmittingComment(true);
    const trimmed = procedureUtil.trimLeadingAndTrailing(values.comment);
    const mentions = procedureUtil.getValidMentions(values.mentions, trimmed);
    resetForm();
    setHasCommentError(false);
    try {
      if (editCommentContext.id && onEditComment) {
        values.comment = trimmed;
        values.mentions = mentions;
        values.updated_at = new Date().toISOString();
        await onEditComment(values, editCommentContext.id);
        setEditCommentContext({ id: null, text: '', mentions: [] });
      } else if (onAddComment) {
        await onAddComment(trimmed, mentions);
      }
    } catch (error) {
      setHasCommentError(true);
    }
    setIsSubmittingComment(false);
  };

  const handleEditClick = (comment) => {
    setEditCommentContext({
      id: comment.id ?? '',
      text: comment.comment ?? comment.text ?? '',
      mentions: comment.mentions || [],
    });
  };

  const handleCancel = () => {
    setEditCommentContext({ id: null, text: '', mentions: [] });
  };

  return {
    isSubmittingComment,
    hasCommentError,
    editCommentContext,
    autoFocus,
    submitComment,
    handleEditClick,
    handleCancel,
  };
};

export default useCommentHandler;

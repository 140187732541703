import React, { useCallback, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Pluralize from 'pluralize';

const MAX_CHARACTERS = 255;

/**
 * Formik custom input component that allows users to set the procedure description.
 *
 * @param {String} value - Description text.
 * @param {Object} field - Formik field.
 * @param {Object} form - Formik form.
 */
const FieldSetProcedureDescription = React.memo(({ value, field, form }) => {
  const [remainingCharacters, setRemainingCharacters] = useState(MAX_CHARACTERS - (value || '').length);

  const updateRemainingCharacters = useCallback(
    (e) => {
      setRemainingCharacters(MAX_CHARACTERS - e.target.value.length);
      form.setFieldValue(field.name, e.target.value);
    },
    [form, field.name]
  );

  return (
    <>
      <TextareaAutosize
        {...field}
        onChange={updateRemainingCharacters}
        placeholder="Procedure description"
        className="grow text-sm border-1 border-gray-400 rounded"
        maxLength={MAX_CHARACTERS}
      />
      <div className="text-xs text-gray-800 mt-1 ml-3 font-medium tracking-tight">
        {`${Pluralize('character', remainingCharacters, true)} remaining`}
      </div>
    </>
  );
});

export default FieldSetProcedureDescription;

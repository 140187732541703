import React from 'react';

interface ToolbarButtonProps {
  title: string;
  children: React.ReactNode;
  isDisabled: boolean;
  isSelected?: boolean;
  onClick?: () => void;
}

const ToolbarButton = ({ title, children, isDisabled, isSelected, onClick }: ToolbarButtonProps) => {
  return (
    <button
      className={`
        ${isSelected ? 'bg-slate-300' : ''} 
        ${isDisabled ? 'text-slate-400' : 'hover:bg-slate-300'}
        whitespace-nowrap text-gray-700 px-1 py-0 text-sm border-0 w-8 h-10 rounded
      `}
      disabled={isDisabled}
      onClick={onClick}
      aria-label={title}
      title={title}
      type="button"
    >
      {children}
    </button>
  );
};

export default React.memo(ToolbarButton);

import { DataType } from './api/annotation';

type BucketMetadata = {
  bucket: string;
  measurements: Array<string>;
};
export type Schema = Array<BucketMetadata>;

type FlatFileMetadata = {
  filename: string;
  measurement: Array<string>;
};
export type FlatFileSchema = Array<FlatFileMetadata>;

type FlatTelemetryMetadata = {
  procedure_name: string;
  procedure_id: string;
  run_id: string;
  measurement: string;
  dictionary_name: string;
  starttime: string;
  endtime: string;
};
export type FlatTelemetrySchema = Array<FlatTelemetryMetadata>;

type InfluxFileMetadata = {
  filename: string;
  measurements: Array<string>;
};
export type FileSchema = Array<InfluxFileMetadata>;

type MeasurementObject = {
  measurement: string;
  units?: string;
  dictionaryName: string;
};

export type TelemetryMeasurementData = {
  runId: string;
  measurements: Array<MeasurementObject>;
  starttime: string;
  endtime: string | null;
};

export type TelemetryMetadata = {
  procedureName: string;
  procedureId: string;
  runs: Array<TelemetryMeasurementData>;
};
export type TelemetrySchema = Array<TelemetryMetadata>;

export type Point = {
  timestamp: number;
  value: number;
};

export type Series = { is_downsampled: boolean; series: Array<Point> };

export type Measurement = {
  name: string;
  bucket: string;
  series: Point[];
  isDownsampled: boolean;
  folderId: number | string;
  type: DataType;
};

export type Measurements = Array<Measurement>;

// maps measurement and timestamp names to a single value
export type PointGroup = { [key: string]: number };

// maps measurement and timestamp name to an array of values
export type SeriesGroup = { [key: string]: Array<number> };

export type Bounds = {
  min: number;
  max: number | null;
};

export type FileSystemFile = {
  id: number;
  parent_id: number;
  name: string;
  type: string;
  created_at: string;
  updated_at: string;
};

export type FileSystemFolder = {
  id: number;
  parent_id: number;
  name: string;
  type: string;
  created_at: string;
  updated_at: string;
};

export type FileSystem = {
  files: FileSystemFile[];
  folders: FileSystemFolder[];
};

export type FileMetadata = {
  id: string;
  team_id: string;
  filename: string;
  file_type: string;
  upload_time: string;
  file_last_modified: string;
  file_size_bytes: string;
  uploaded_by: string;
};

export enum SelectionType {
  Upload = 'upload',
  RunTelemetry = 'run_telemetry',
  Parameter = 'parameter',
}

export type SelectedMeasurementToggle = (
  bucket: string
) => (
  measurement: string,
  selectionType: SelectionType,
  folderId: string | number,
  units?: string,
  bounds?: Bounds
) => void;

export type Selection = {
  bucket: string;
  measurement: string;
  units?: string;
  folder_id: string | number;
  bounds: Bounds | undefined;
  type: SelectionType;
};

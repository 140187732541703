import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ErrorProps {
  text: string;
  showIcon?: boolean;
}

const Error = React.memo(({ text, showIcon = false }: ErrorProps) => {
  return (
    <div className="px-2 py-2 rounded bg-red-100 text-red-700 border border-red-700 text-center">
      {showIcon && <FontAwesomeIcon icon="exclamation-circle" className="mr-2" />}
      <span>Error: {text || 'Please try again'}</span>
    </div>
  );
});

export default Error;

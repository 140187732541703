import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Field, FieldArray } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FieldSetText from './FieldSetText';
import FieldSetProcedureBlock from './Blocks/FieldSetProcedureBlock';
import { ProcedureContentBlockTypes } from 'shared/lib/types/blockTypes';
import AddContentMenu from './ContentMenu/AddContentMenu';
import getBlockAddContentItems from './ContentMenu/blockAddContentItems';
import { Actions } from './ContentMenu/addContentTypes';
import Selectable, { Boundary } from './Selection/Selectable';
import contentDragHandleVisibilityClasses from './Selection/selectionUtil';
import { copyItemToClipboard, selectClipboardItem } from '../contexts/proceduresSlice';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import FlashMessage from './FlashMessage';
import clipboardUtil from '../lib/clipboardUtil';
import procedureUtil from '../lib/procedureUtil';
import { EDIT_STICKY_HEADER_HEIGHT_REM } from './EditToolbar';

const enabledContentTypes = [ProcedureContentBlockTypes.Alert, ProcedureContentBlockTypes.Text];

const FieldSetProcedureStepHeader = ({
  stepHeaderValues,
  stepHeaderErrors,
  sectionIndex,
  stepIndex,
  fieldRef,
  onDragStart,
  onDragEnd,
  validateForm,
  getSetFieldValueAndSync,
  setFieldValue,
  onRemoveContent,
  onRemoveStepHeader,
  onAddBlock,
  syncHeaderToProcedure,
}) => {
  const { currentTeamId } = useDatabaseServices();
  const { mixpanel } = useMixpanel();

  const getStepHeaderContentErrors = useCallback(
    (stepHeaderId, contentId) => {
      if (!stepHeaderErrors || !stepHeaderErrors[stepHeaderId]?.content) {
        return null;
      }

      return stepHeaderErrors[stepHeaderId].content[contentId];
    },
    [stepHeaderErrors]
  );

  const mixpanelTrack = useCallback(
    (name, options) => {
      if (mixpanel && name) {
        mixpanel.track(name, options);
      }
    },
    [mixpanel]
  );

  const dispatch = useDispatch();
  const [flashMessage, setFlashMessage] = useState('');
  const clipboardItem = useSelector((state) => selectClipboardItem(state, currentTeamId));
  const clipboardBlock = useMemo(() => {
    if (!clipboardItem) {
      return undefined;
    }
    const clipboardBlock = clipboardUtil.getBlockFromClipboardItem(clipboardItem);
    // @ts-ignore enabledContentTypes should be of type string[]
    if (!clipboardBlock || (enabledContentTypes && !enabledContentTypes.includes(clipboardBlock.type))) {
      return undefined;
    }
    return clipboardBlock;
  }, [clipboardItem]);

  const onCopyBlock = useCallback(
    ({ block }) => {
      mixpanelTrack('Copy Block');
      const copiedBlock = procedureUtil.copyBlock(block);
      const clipboardItemBlock = clipboardUtil.createClipboardItemBlock(copiedBlock);
      dispatch(copyItemToClipboard(currentTeamId, clipboardItemBlock));
      setFlashMessage('Content block copied');
    },
    [currentTeamId, dispatch, mixpanelTrack]
  );

  const onCutBlock = useCallback(
    ({ block, blockIndex }) => {
      mixpanelTrack('Cut Block');
      const copiedBlock = procedureUtil.copyBlock(block);
      const clipboardItemBlock = clipboardUtil.createClipboardItemBlock(copiedBlock);
      dispatch(copyItemToClipboard(currentTeamId, clipboardItemBlock));
      onRemoveContent(blockIndex, stepHeaderValues, syncHeaderToProcedure);
      setFlashMessage('Content block cut');
    },
    [currentTeamId, dispatch, mixpanelTrack, stepHeaderValues, syncHeaderToProcedure, onRemoveContent]
  );

  const onPasteBlock = useCallback(
    ({ blockIndex }) => {
      if (!clipboardBlock) {
        return;
      }
      mixpanelTrack('Paste Block');
      const updatedHeader = cloneDeep(stepHeaderValues);
      const blockCopy = procedureUtil.copyBlock(clipboardBlock);
      updatedHeader.content.splice(blockIndex + 1, 0, blockCopy);

      syncHeaderToProcedure(updatedHeader);
    },
    [clipboardBlock, mixpanelTrack, stepHeaderValues, syncHeaderToProcedure]
  );

  const onContentMenuClick = useCallback(
    (menuItem, blockIndex, stepHeaderValues, syncHeaderToProcedure) => {
      switch (menuItem.action) {
        case Actions.DeleteBlock:
          return onRemoveContent(blockIndex, stepHeaderValues, syncHeaderToProcedure);
        case Actions.CopyBlock:
          return onCopyBlock({ block: stepHeaderValues.content[blockIndex] });
        case Actions.CutBlock:
          return onCutBlock({ block: stepHeaderValues.content[blockIndex], blockIndex });
        case Actions.PasteBlock:
          return onPasteBlock({ blockIndex });
        case Actions.AddBlock:
          return onAddBlock(stepHeaderValues, syncHeaderToProcedure, blockIndex, menuItem.blockType, menuItem.subType);
        default:
          return;
      }
    },
    [onAddBlock, onCopyBlock, onCutBlock, onPasteBlock, onRemoveContent]
  );

  const onKeyboard = useCallback(
    (event, blockIndex, header, sync) => {
      if (event.code === 'Backspace' || event.code === 'Delete') {
        onRemoveContent(blockIndex, header, sync);
      } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyC') {
        onCopyBlock({ block: header.content[blockIndex] });
      } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyX') {
        onCutBlock({ block: header.content[blockIndex], blockIndex });
      } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyV') {
        onPasteBlock({ blockIndex });
      }
    },
    [onCopyBlock, onCutBlock, onPasteBlock, onRemoveContent]
  );

  return (
    <div aria-label="Step Header" role="region" className="pb-4 w-full bg-gray-200 rounded group">
      <div
        className="ml-4 pt-2 pl-10 pr-6 flex flex-row w-full"
        ref={fieldRef(`${stepHeaderValues.id}.name`)}
        style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
      >
        <div className="flex flex-col grow">
          <label className="field-title w-full">Step Header</label>
          <Field
            type="text"
            name="headers[0].name"
            placeholder="Header name (optional)"
            className="text-sm w-full border-1 border-gray-400 rounded"
          />
        </div>
        <button
          type="button"
          title="Remove Step Header"
          className="flex items-start ml-2 mr-1"
          tabIndex={-1}
          onClick={onRemoveStepHeader}
        >
          <FontAwesomeIcon
            icon="times-circle"
            className="text-gray-400 hover:text-gray-500 opacity-0 group-hover:opacity-100 cursor-pointer"
          ></FontAwesomeIcon>
        </button>
      </div>
      <FieldArray
        name="headers[0].content"
        key={`section.${sectionIndex}.steps.${stepIndex}.headers[0].content`}
        className="text-sm"
        render={(contentArrayHelpers) => (
          <div>
            {stepHeaderValues.content.length === 0 && (
              <div className="ml-12 mt-4 pl-2">
                <AddContentMenu
                  menuItems={getBlockAddContentItems({
                    enabledContentTypes,
                    canDeleteBlock: false,
                    canCopyBlock: false,
                    canCutBlock: false,
                    canPasteBlock: !!clipboardBlock,
                  })}
                  onClick={(menuItem) => onContentMenuClick(menuItem, -1, stepHeaderValues, syncHeaderToProcedure)}
                  includeTextLabel={true}
                />
              </div>
            )}
            <DragDropContext
              onDragStart={onDragStart}
              onDragEnd={(result) => onDragEnd(result, contentArrayHelpers, stepHeaderValues, syncHeaderToProcedure)}
            >
              <Droppable droppableId={`section.${sectionIndex}.step.${stepIndex}.headers[0]`}>
                {(provided, snapshot) => (
                  <div
                    className={`border-2 border-dashed ml-2 mr-2 ${
                      snapshot.isDraggingOver ? 'border-gray-400' : 'border-transparent'
                    }`}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {stepHeaderValues.content &&
                      stepHeaderValues.content.map((headerContent, headerContentIndex) => (
                        <Selectable
                          key={headerContent.id}
                          boundary={Boundary.ContentBlock}
                          block={headerContent}
                          onKeyboard={(event) =>
                            onKeyboard(event, headerContentIndex, stepHeaderValues, syncHeaderToProcedure)
                          }
                        >
                          {({ isSelected, styles }) => (
                            <Draggable key={headerContent.id} draggableId={headerContent.id} index={headerContentIndex}>
                              {(provided, draggableSnapshot) => (
                                <div
                                  aria-label="Content Block"
                                  role="region"
                                  className="group/content flex"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <div
                                    className={`h-9 flex flex-row gap-x-2 items-center ${
                                      isSelected ? 'opacity-100' : 'opacity-0 group-hover/content:opacity-50'
                                    } `}
                                  >
                                    <AddContentMenu
                                      menuItems={getBlockAddContentItems({
                                        enabledContentTypes,
                                        canPasteBlock: !!clipboardBlock,
                                      })}
                                      onClick={(menuItem) =>
                                        onContentMenuClick(
                                          menuItem,
                                          headerContentIndex,
                                          stepHeaderValues,
                                          syncHeaderToProcedure
                                        )
                                      }
                                    />

                                    {/* Drag Handle */}
                                    <div
                                      className={`relative flex shrink-0 pr-2 text-gray-400 ${contentDragHandleVisibilityClasses(
                                        snapshot,
                                        draggableSnapshot,
                                        isSelected
                                      )}`}
                                    >
                                      <div {...provided.dragHandleProps} tabIndex={null}>
                                        <FontAwesomeIcon icon="grip-vertical" />
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className={`my-0.5 p-1 w-full ${styles}`}
                                    ref={fieldRef(`${stepHeaderValues.id}.content[${headerContent.id}]`)}
                                    style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
                                  >
                                    {headerContent.type.toLowerCase() === 'text' && (
                                      <FieldSetText
                                        block={headerContent}
                                        path={`headers[0].content[${headerContentIndex}]`}
                                        contentErrors={getStepHeaderContentErrors(
                                          stepHeaderValues.id,
                                          headerContent.id
                                        )}
                                        setFieldValue={getSetFieldValueAndSync(setFieldValue, validateForm)}
                                      />
                                    )}
                                    {/* Render field elements for block type */}
                                    {/* Note: Blocks are being refactored to use new architecture, once
                                                      refactor is complete they will all use ProcedureBlockWithRedlining */}
                                    {(headerContent.type.toLowerCase() === 'input' ||
                                      headerContent.type.toLowerCase() === 'alert' ||
                                      headerContent.type.toLowerCase() === 'requirement') && (
                                      <FieldSetProcedureBlock
                                        block={headerContent}
                                        path={`headers[0].content[${headerContentIndex}]`}
                                        contentErrors={getStepHeaderContentErrors(
                                          stepHeaderValues.id,
                                          headerContent.id
                                        )}
                                        setFieldValue={getSetFieldValueAndSync(setFieldValue, validateForm)}
                                      />
                                    )}
                                  </div>

                                  <div
                                    className={`pl-1 justify-end ${
                                      isSelected ? 'opacity-100' : 'opacity-0 group-hover/content:opacity-100'
                                    } `}
                                  >
                                    <button
                                      type="button"
                                      title="Remove Step Header Content"
                                      tabIndex={-1}
                                      onClick={() =>
                                        onRemoveContent(headerContentIndex, stepHeaderValues, syncHeaderToProcedure)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon="times-circle"
                                        className="self-center text-gray-400 hover:text-gray-500"
                                      ></FontAwesomeIcon>
                                    </button>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )}
                        </Selectable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      />
      <FlashMessage
        message={flashMessage}
        // @ts-ignore
        messageUpdater={setFlashMessage}
      />
    </div>
  );
};

export default FieldSetProcedureStepHeader;

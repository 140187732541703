import { useState } from 'react';
import { Bounds } from '../storage/types';
import RunPlot from '../storage/components/RunPlot';
import OpenPlotModalIcon from '../storage/components/OpenPlotModalIcon';
import PlotModal from '../storage/components/PlotModal';
import { Rule } from 'shared/lib/types/views/procedures';
import { PlotType } from '../storage/components/Plot';

type PlotRowProps = {
  runId: string;
  dictionaryName: string;
  parameterName: string | null;
  bounds: Bounds;
  initialBounds?: Bounds;
  setBounds?: (bounds: Bounds) => void;
  resetBounds?: () => void;
  zoomLevel?: number;
  setZoomLevel?: (zoomLevel: number) => void;
  resetZoom?: () => void;
  searchParamsString: string;
  telemetryRule?: Rule;
  shouldPausePlot: boolean;
};

const PlotRow = ({
  runId,
  dictionaryName,
  parameterName,
  bounds,
  initialBounds,
  setBounds,
  resetBounds,
  zoomLevel,
  setZoomLevel,
  resetZoom,
  searchParamsString,
  telemetryRule,
  shouldPausePlot,
}: PlotRowProps) => {
  const [isPlotModalShown, setIsPlotModalShown] = useState(false);
  const showPlotModal = () => setIsPlotModalShown(true);
  const closePlotModal = () => {
    resetBounds && resetBounds();
    resetZoom && resetZoom();
    setIsPlotModalShown(false);
  };

  return (
    <div className="ml-4 mr-8 mb-2 relative">
      {isPlotModalShown && (
        <PlotModal
          runId={runId}
          dictionaryName={dictionaryName}
          measurementName={parameterName || ''}
          bounds={bounds}
          initialBounds={initialBounds}
          setBounds={setBounds}
          zoomLevel={zoomLevel}
          setZoomLevel={setZoomLevel}
          onCloseModal={closePlotModal}
          searchParamsString={searchParamsString}
          telemetryRule={telemetryRule}
          shouldPausePlot={shouldPausePlot}
        />
      )}
      {!isPlotModalShown && (
        <RunPlot
          runId={runId}
          bucketName={dictionaryName}
          measurementName={parameterName || ''}
          bounds={bounds}
          initialBounds={initialBounds}
          setBounds={setBounds}
          zoomLevel={zoomLevel}
          setZoomLevel={setZoomLevel}
          plotType={PlotType.RunPlot}
          maintainAspectRatio={false}
          telemetryRule={telemetryRule}
          shouldPausePlot={shouldPausePlot}
        />
      )}
      <div className="absolute bottom-2 right-2">
        <OpenPlotModalIcon onClick={showPlotModal} />
      </div>
    </div>
  );
};

export default PlotRow;

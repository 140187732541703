import { Dialog } from 'primereact/dialog';
import { PopupProps } from '../../components/Popup';
import useHazardSettings from '../hooks/useHazardsSettings';
import HazardView from './HazardView';
import React from 'react';
import { Hazard } from 'shared/lib/types/testing';

type HazardPopupProps = Omit<
  PopupProps & {
    hazard: Hazard;
  },
  'children'
>;
const HazardPopup = ({
  isPopUpShown,
  onPopupClose,
  draggable = false,
  resizable = false,
  hazard,
}: HazardPopupProps) => {
  const { hazardSettings } = useHazardSettings();

  if (hazardSettings.loading) {
    return null;
  }
  return (
    <Dialog
      draggable={draggable}
      resizable={resizable}
      header={hazard.name}
      visible={isPopUpShown}
      onHide={onPopupClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <HazardView hazard={hazard} isPopup={true} hazardSettings={hazardSettings} />
    </Dialog>
  );
};

export default HazardPopup;

import { VersionDetails } from 'shared/lib/types/couch/settings';
import { versionDateString } from './datetime';
import { isEmptyValue } from 'shared/lib/text';

export const DEFAULT_VERSION_SETTING = {
  enabled: false,
  major: 'letter',
  minor: 'letter',
  delimiter: '.',
} as VersionDetails;

export const DEFAULT_LETTER = 'A';
export const DEFAULT_NUMBER = '1';

const LETTERS_IN_ALPHABET = 26;
const UNICODE_OFFSET = 64;

const version = {
  _isValidLetterVersion: (currentVersion: string): boolean => {
    // we start to lose precision after 11 characters due to number conversion
    if (currentVersion.length > 11) {
      return false;
    }
    return /^[A-Z]+$/.test(currentVersion);
  },

  /*
   * Converts a letter or string of letters to decimal number
   * e.g. A -> 1, Y -> 25, AA -> 27
   */
  _lettersToNumber: (letters: string): number => {
    return letters
      .split('')
      .map((char) => char.charCodeAt(0) - UNICODE_OFFSET)
      .reverse()
      .reduce(
        (prev, curr, currentIndex) =>
          prev + curr * LETTERS_IN_ALPHABET ** currentIndex,
        0
      );
  },

  /*
   * Converts a decimal number to letter or string of letters
   * e.g. 2 -> B, 26 -> Z, 28 -> AB
   */
  _numberToLetters: (num: number): string => {
    const letters: Array<string> = [];
    let currentNumber = num;
    while (currentNumber) {
      let remainder = currentNumber % LETTERS_IN_ALPHABET;
      if (!remainder) {
        remainder = LETTERS_IN_ALPHABET;
        currentNumber -= 1;
      }
      // converts the underlying unicode value to character
      letters.push(String.fromCodePoint(remainder + UNICODE_OFFSET));
      currentNumber = Math.floor(currentNumber / LETTERS_IN_ALPHABET);
    }
    return letters.reverse().join('');
  },

  _getNextLetterVersion: (currentVersion?: string): string => {
    if (currentVersion === undefined || isEmptyValue(currentVersion)) {
      return DEFAULT_LETTER;
    }

    if (!version._isValidLetterVersion(currentVersion)) {
      return currentVersion;
    }
    const num = version._lettersToNumber(currentVersion);
    return version._numberToLetters(num + 1);
  },

  _isValidNumberVersion: (currentVersion: string): boolean => {
    if (/^\d+$/.test(currentVersion) === false) {
      return false;
    }
    const currentNumber = Number(currentVersion);
    if (Number.isNaN(currentNumber)) {
      return false;
    }
    if (currentNumber > Number.MAX_SAFE_INTEGER) {
      return false;
    }
    return true;
  },

  _getNextNumberVersion: (currentVersion?: string): string => {
    if (currentVersion === undefined || isEmptyValue(currentVersion)) {
      return DEFAULT_NUMBER;
    }

    if (!version._isValidNumberVersion(currentVersion)) {
      return currentVersion;
    }
    return String(Number(currentVersion) + 1);
  },

  _getNextVersion: (
    type: 'letter' | 'number',
    currentVersion?: string
  ): string => {
    return type === 'letter'
      ? version._getNextLetterVersion(currentVersion)
      : version._getNextNumberVersion(currentVersion);
  },

  _isValidVersion: (
    type: 'letter' | 'number',
    currentVersion: string
  ): boolean => {
    return type === 'letter'
      ? version._isValidLetterVersion(currentVersion)
      : version._isValidNumberVersion(currentVersion);
  },

  generateVersion: (
    versionSetting: VersionDetails,
    currentVersion?: string
  ): string => {
    if (!versionSetting.enabled) {
      if (currentVersion) {
        return currentVersion;
      }
      return versionDateString(new Date());
    }

    if (currentVersion === undefined || isEmptyValue(currentVersion)) {
      return version._getNextVersion(versionSetting.major);
    }

    // nosemgrep: javascript-dos-rule-regex_injection_dos
    const splits = currentVersion.split(versionSetting.delimiter);
    if (splits.length > 2) {
      return currentVersion;
    }

    let nextMinor;
    if (splits.length === 2) {
      const currentMinor = splits[1];
      if (!version._isValidVersion(versionSetting.minor, currentMinor)) {
        return currentVersion;
      }
      nextMinor = version._getNextVersion(versionSetting.minor, currentMinor);
    }

    const currentMajor = splits[0];
    if (!version._isValidVersion(versionSetting.major, currentMajor)) {
      return currentVersion;
    }
    let nextVersion = version._getNextVersion(
      versionSetting.major,
      currentMajor
    );
    if (nextMinor) {
      nextVersion = `${currentMajor}${versionSetting.delimiter}${nextMinor}`;
    }
    return nextVersion;
  },
};

export default version;

import React, { useMemo } from 'react';
import { Field } from 'formik';
import { useSettings } from '../../../contexts/SettingsContext';
import { FieldInputTimestampBlock, TimestampValue, TimestampValueV2 } from 'shared/lib/types/views/procedures';
import { isEqual } from 'lodash';
import { createIsoString } from 'shared/lib/datetime';

interface GridTimestampFieldInputProps {
  block: FieldInputTimestampBlock;
  recorded?: { value?: TimestampValue };
  onRecordValuesChanged?: (recorded: { value?: TimestampValue }) => void;
}
const GridTimestampFieldInput = React.memo(
  ({ block, recorded, onRecordValuesChanged }: GridTimestampFieldInputProps) => {
    const { getSetting } = useSettings();

    const defaultZone = useMemo(() => {
      return getSetting('timezone', 'UTC');
    }, [getSetting]);

    const existingValue = useMemo(() => {
      if (!recorded?.value) {
        return {};
      }
      return recorded.value as TimestampValueV2;
    }, [recorded]);

    const recordedDate = useMemo(() => {
      if (!recorded || !recorded.value) {
        return null;
      }
      return (recorded.value as TimestampValueV2).date;
    }, [recorded]);

    const recordedTime = useMemo(() => {
      if (!recorded || !recorded.value) {
        return null;
      }

      return (recorded.value as TimestampValueV2).time;
    }, [recorded]);

    const currentTimezone = useMemo(() => {
      return existingValue.zone ?? defaultZone;
    }, [defaultZone, existingValue.zone]);

    const updateDateValue = (event) => {
      const dateValue = event.target.value;
      const zone = currentTimezone;

      let timeValue = '';
      if (block.dateTimeType === 'datetime' && !recordedTime) {
        const timeIsoString = createIsoString({ zone });
        timeValue = (timeIsoString ?? '').split('T')[1].split('.')[0];
      }

      const value: TimestampValueV2 = {
        // Clear values if there is no date value.
        date: dateValue || null,
        time: timeValue || existingValue.time || null,
        zone,
      };

      // Notify observers that value changed
      if (onRecordValuesChanged) {
        const _recorded = { value };
        if (!isEqual(recorded, _recorded)) {
          onRecordValuesChanged(_recorded);
        }
      }
    };

    const updateTimeValue = (event) => {
      const timeValue = event.target.value;
      const zone = currentTimezone;

      const value: TimestampValueV2 = {
        // Clear values if there is no time value.
        ...(block.dateTimeType === 'datetime' && { date: existingValue.date ?? null }),
        time: timeValue || null,
        zone,
      };

      // Notify observers that value changed
      if (onRecordValuesChanged) {
        const _recorded = { value };
        if (!isEqual(recorded, _recorded)) {
          onRecordValuesChanged(_recorded);
        }
      }
    };

    const itemWidth = useMemo(() => (block.dateTimeType === 'datetime' ? 'w-1/2' : 'w-full'), [block.dateTimeType]);

    return (
      <>
        <div className="flex flex-wrap grow justify-between">
          <div className="w-full">
            <Field name="text">
              {() => (
                <div className="flex flex-wrap justify-start">
                  {(block.dateTimeType === 'datetime' || block.dateTimeType === 'date') && (
                    <div className={`relative ${itemWidth} w-full md:w-auto`} aria-label="Select Date">
                      <input
                        type="date"
                        aria-label="Date Picker"
                        className="w-full py-1 text-sm h-[2.5rem] border-0 bg-transparent mt-1 focus:outline-none focus:ring-0"
                        value={recordedDate || ''}
                        onChange={updateDateValue}
                      />
                    </div>
                  )}
                  {(block.dateTimeType === 'datetime' || block.dateTimeType === 'time') && (
                    <div className={`${itemWidth} w-full md:w-auto`}>
                      <input
                        type="time"
                        aria-label="Time Picker"
                        className="w-full text-sm h-[2.5rem] border-0 mt-1 focus:outline-none focus:ring-0"
                        value={recordedTime || ''}
                        onChange={updateTimeValue}
                        step="1"
                      />
                    </div>
                  )}
                </div>
              )}
            </Field>
          </div>
        </div>
      </>
    );
  }
);

export default GridTimestampFieldInput;

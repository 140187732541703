import superlogin from './superlogin';
import { API_URL } from '../config';
import { JamaRequirement } from 'shared/lib/types/api/integrations/jama';

class JamaService {
  teamId: string;
  url: string;

  constructor(teamId: string | null) {
    if (!teamId) {
      throw new Error('Missing teamId');
    }
    this.teamId = teamId;
    this.url = `${API_URL}/teams/${this.teamId}/jama`;
  }

  async getJamaRequirements(): Promise<Array<JamaRequirement>> {
    try {
      const response = await superlogin
        .getHttp()
        .get(`${this.url}/requirements`);
      return response.data.data;
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }
}

export default JamaService;

import { useCallback, useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { OnRefChangeType } from 'react-resize-detector/build/types/types';

export enum SIDEBAR_CONTENT {
  TOC,
  FLOW,
}

interface useSidebarProps {
  onElementsResize: () => void;
}

interface useSidebarReturns {
  toggleView: (e: React.MouseEvent) => void;
  sidebarContainerRef: OnRefChangeType<HTMLDivElement>;
  sidebarContentView: SIDEBAR_CONTENT;
}

const useSidebar = ({ onElementsResize }: useSidebarProps): useSidebarReturns => {
  const [sidebarContentView, setSidebarContentView] = useState(SIDEBAR_CONTENT.TOC);

  const updateSidebarContentView = useCallback(() => {
    setSidebarContentView((current) => (current === SIDEBAR_CONTENT.TOC ? SIDEBAR_CONTENT.FLOW : SIDEBAR_CONTENT.TOC));
  }, []);

  // Handles change in height due to sidebar inner elements resize.
  const { ref: sidebarContainerRef } = useResizeDetector({
    handleWidth: false,
    refreshMode: 'debounce',
    refreshRate: 100,
    onResize: onElementsResize,
  });

  // Handles change in height due to window resize.
  useEffect(() => {
    let resizeTimeoutId: ReturnType<typeof setTimeout>;
    const handleViewportResize = () => {
      clearTimeout(resizeTimeoutId);
      resizeTimeoutId = setTimeout(() => {
        onElementsResize();
      }, 100);
    };
    window.addEventListener('resize', handleViewportResize);

    return () => {
      window.removeEventListener('resize', handleViewportResize);
    };
  }, [onElementsResize]);

  const toggleView = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      updateSidebarContentView();
    },
    [updateSidebarContentView]
  );

  return {
    toggleView,
    sidebarContainerRef,
    sidebarContentView,
  };
};

export default useSidebar;

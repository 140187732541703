import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { Field, FormikHelpers, FormikValues, setIn } from 'formik';
import { SimulatedFields } from 'shared/lib/telemetry';
import FieldSetTelemetrySelect from './FieldSetTelemetrySelect';
import validateUtil from '../lib/validateUtil';
import FieldSetCheckbox from './FieldSetCheckbox';
import FieldSetMathOperation from './FieldSetMathOperation';
import FieldSetRange from './FieldSetRange';
import FieldSetValue from './FieldSetValue';
import { getInitialFormValues } from './Blocks/BlockTypes';
import { ProcedureContentBlockTypes } from 'shared/lib/types/blockTypes';
import { TelemetryBlockContentErrors, FieldInputValueDataType } from '../lib/types';
import { DraftTelemetryBlock, RuleOperator } from 'shared/lib/types/views/procedures';
import Button, { BUTTON_TYPES } from './Button';
import { SUPPORTED_OPERATIONS } from 'shared/lib/math';
import { useRunContext } from '../contexts/RunContext';

export const CONTENT_TYPE_TELEMETRY = 'telemetry';

interface FieldSetTelemetryProps {
  content: DraftTelemetryBlock;
  path: string;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
  contentErrors: TelemetryBlockContentErrors;
  isDisabled?: boolean;
}

export const FieldSetTelemetry = React.memo(
  ({ content, path, setFieldValue, contentErrors, isDisabled = false }: FieldSetTelemetryProps) => {
    const isMounted = useRef(true);
    const [valueDataType, setValueDataType] = useState<FieldInputValueDataType>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { isRun } = useRunContext();

    useEffect(
      () => () => {
        isMounted.current = false;
      },
      []
    );

    const updateValueDataType = useCallback((name) => {
      // Load parameter data type.
      /*
       * Load data type for simulated parameter. Temporary workaround until
       * simulated values are moved to the backend.
       */
      if (name in SimulatedFields) {
        const field = SimulatedFields[name];
        setValueDataType(field.type);
      } else {
        // Unknown, allow entering text values for demonstration purposes.
        setValueDataType('text');
      }
    }, []);

    const showsParameterField = useMemo(() => content.key.toLowerCase() === 'parameter', [content.key]);

    const canHaveRule = useMemo(() => {
      if (content.parameter_type && content.parameter_type === 'aggregate') {
        return false;
      }
      return showsParameterField;
    }, [showsParameterField, content.parameter_type]);

    const showsValueField = useMemo(() => {
      if (!showsParameterField || (content.parameter_type && content.parameter_type === 'aggregate')) {
        return false;
      }
      return content.rule && content.rule !== 'range';
    }, [content.rule, content.parameter_type, showsParameterField]);

    const showsRangeFields = useMemo(() => {
      if (!showsParameterField) {
        return false;
      }
      return content.rule === 'range';
    }, [content.rule, showsParameterField]);

    const showExpressionField = useMemo(() => content.key.toLowerCase() === 'custom', [content.key]);

    // Load initial values if needed
    useEffect(() => {
      if (!isLoading) {
        return;
      }
      const key = content.key;
      if (!key || key.toLowerCase() === 'custom') {
        setIsLoading(false);
        return;
      }

      /*
       * TODO: Move to migration ?
       * Migrate to new telemetry data structure with `name` property
       */
      if (!content.name && content.key.toLowerCase() !== 'parameter') {
        const name = key;
        setFieldValue(`${path}.name`, name);
        setFieldValue(`${path}.key`, 'parameter');
      }
    }, [path, setFieldValue, content, isLoading]);

    // Load initial parameter data type
    useEffect(() => {
      if (valueDataType || !content || !content.name) {
        setIsLoading(false);
        return;
      }
      updateValueDataType(content.name);
      setIsLoading(false);
    }, [content, valueDataType, updateValueDataType]);

    const onChangeValueField = useCallback(
      (value: string, path: string) => {
        validateUtil.updateValueField(value, path, valueDataType, setFieldValue);
      },
      [valueDataType, setFieldValue]
    );

    const onChangeTelemetryKey = (e) => {
      const key = e.target.value || null;
      // Clear dependent values when key changes
      setFieldValue(path, {
        ...getInitialFormValues(ProcedureContentBlockTypes.Telemetry),
        id: content.id,
        key,
      });
    };

    const onChange = (parameter) => {
      const fieldValue = {
        ...content,
        name: parameter.name,
        units: parameter.units,
        dictionary_id: parameter.dictionary_id,
        parameter_id: parameter.parameter_id,
        parameter_type: parameter.parameter_type,
      };

      if (parameter.type) {
        fieldValue['parameter_type'] = parameter.type;

        // aggregate types don't support rules or values so clear them when changing to aggregate type
        if (parameter.type === 'aggregate') {
          fieldValue['rule'] = '';
          fieldValue['value'] = '';
        }
      }

      updateValueDataType(parameter.name);
      setFieldValue(path, fieldValue);
    };

    const onClearField = () => {
      setFieldValue(path, {
        ...getInitialFormValues(ProcedureContentBlockTypes.Telemetry),
        id: content.id,
      });
    };

    const onChangeRule = useCallback(
      (updatedRule: RuleOperator) => {
        if (content.rule === updatedRule) {
          return;
        }
        let blockCopy = setIn(content, `rule`, updatedRule);
        if (!updatedRule) {
          // Clear the value if rule is updated to be empty.
          blockCopy = setIn(blockCopy, `value`, '');
        }
        if (updatedRule === 'range') {
          blockCopy = setIn(blockCopy, `range`, {
            min: '',
            max: '',
            include_min: true,
            include_max: true,
          });
        } else {
          // Clear range if the new rule is not a range
          blockCopy = setIn(blockCopy, `range`, {
            min: '',
            max: '',
            include_min: undefined,
            include_max: undefined,
          });
        }
        setFieldValue(path, blockCopy);
      },
      [content, path, setFieldValue]
    );

    const onRemoveRule = useCallback(() => {
      return onChangeRule('');
    }, [onChangeRule]);

    const onAddRule = useCallback(() => {
      return onChangeRule('=');
    }, [onChangeRule]);

    const hasError = useMemo(() => {
      return contentErrors && ['name', 'rule', 'range', 'value', 'expression'].some((key) => key in contentErrors);
    }, [contentErrors]);

    return (
      <fieldset disabled={isLoading ? true : undefined} className="grow">
        <div className="flex flex-wrap grow gap-x-2">
          {/* Telemetry type */}
          <div className="flex flex-col">
            <span className="field-title">Telemetry</span>
            <Field
              name={`${path}.key`}
              as="select"
              data-testid="expression-field"
              className="text-sm border border-gray-400 rounded"
              onChange={onChangeTelemetryKey}
              disabled={isDisabled}
            >
              <option value="parameter">Parameter</option>
              <option value="custom">Expression</option>
            </Field>
          </div>

          {/* Parameter name */}
          {showsParameterField && (
            <div className="flex flex-col w-96">
              <span className="field-title">Parameter</span>
              <Field
                path={path}
                content={content}
                name={`${path}.name`}
                component={FieldSetTelemetrySelect}
                onChange={onChange}
                onClear={onClearField}
                icon="stream"
                isDisabled={isDisabled}
              />
              {contentErrors && contentErrors.name && <div className="text-red-700">{contentErrors.name}</div>}
            </div>
          )}

          {/* Parameter rule */}
          {canHaveRule && content.rule && (
            <FieldSetMathOperation
              value={content.rule}
              operationOptions={
                content.parameter_type === 'string' || content.parameter_type === 'bool'
                  ? SUPPORTED_OPERATIONS
                  : undefined
              }
              operatorError={contentErrors?.rule}
              onChange={(option) => onChangeRule(option.value)}
              isDisabled={isDisabled}
            />
          )}

          {/* Parameter range min and max values */}
          {showsRangeFields && (
            <FieldSetRange
              path={path}
              isDisabled={isDisabled || !valueDataType}
              rangeError={contentErrors?.range}
              onChange={onChangeValueField}
              setFieldValue={setFieldValue}
            />
          )}

          {/* Parameter value */}
          {showsValueField && (
            <FieldSetValue
              path={path}
              dataType={valueDataType}
              isDisabled={isDisabled || !valueDataType}
              valueError={contentErrors?.value}
              onChange={onChangeValueField}
            />
          )}

          {/* Custom expression */}
          {showExpressionField && (
            <div className="flex flex-col mr-2 grow">
              <span className="field-title">Expression</span>
              <Field
                type="text"
                name={`${path}.expression`}
                placeholder="Enter a boolean expression"
                className="text-sm w-full border-1 border-gray-400 rounded"
                disabled={isDisabled}
              />
              {contentErrors && contentErrors.expression && (
                <div className="text-red-700">{contentErrors.expression}</div>
              )}
            </div>
          )}

          {!isDisabled && canHaveRule && content.rule && (
            <div className="flex flex-col">
              <div className="h-4" />
              <div className="self-center h-10">
                <Button onClick={onRemoveRule} type={BUTTON_TYPES.TERTIARY} leadingIcon="minus-circle">
                  Remove Rule
                </Button>
              </div>
              {hasError && <div />}
            </div>
          )}

          {!isDisabled && canHaveRule && !content.rule && (
            <div className="flex flex-col">
              <div className="h-4" />
              <div className="self-center h-10">
                <Button onClick={onAddRule} type={BUTTON_TYPES.TERTIARY} leadingIcon="plus-circle">
                  Add Rule
                </Button>
              </div>
              {hasError && <div />}
            </div>
          )}
          {/* Include in summary checkbox */}
          <div className="flex flex-col">
            <div className="h-4" />
            <div className="self-center h-10 flex items-center">
              <FieldSetCheckbox
                text="Include in Summary"
                fieldName={`${path}.include_in_summary`}
                setFieldValue={setFieldValue}
                isDisabled={isRun}
              />
            </div>
            {hasError && <div />}
          </div>
        </div>
      </fieldset>
    );
  }
);

import React from 'react';
import Button, { BUTTON_TYPES } from './Button';

interface CancelSaveButtonsProps {
  containerClassName: string;
  saveAriaLabel: string;
  onSave: () => void;
  cancelAriaLabel: string;
  onCancel: () => void;
  isDisabled: boolean;
  canSave?: boolean;
}

const CancelSaveButtons = ({
  containerClassName,
  saveAriaLabel,
  onSave,
  cancelAriaLabel,
  onCancel,
  isDisabled,
  canSave = true,
}: CancelSaveButtonsProps) => {
  return (
    <div className={containerClassName}>
      <Button
        type={BUTTON_TYPES.SECONDARY}
        ariaLabel={cancelAriaLabel}
        title="Cancel"
        onClick={onCancel}
        isDisabled={isDisabled}
      >
        Cancel
      </Button>
      <Button
        type={BUTTON_TYPES.PRIMARY}
        ariaLabel={saveAriaLabel}
        title="Save"
        onClick={onSave}
        isDisabled={isDisabled || !canSave}
      >
        Save
      </Button>
    </div>
  );
};

export default CancelSaveButtons;

import React, { useMemo, useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import { pick } from 'lodash';
import FieldSetProcedureDescription from './FieldSetProcedureDescription';
import FieldSetProcedureCode from './FieldSetProcedureCode';
import useAutoProcedureId from '../hooks/useAutoProcedureId';
import { EDIT_STICKY_HEADER_HEIGHT_REM } from './EditToolbar';

const PROCEDURE_DETAILS_FORM_FIELDS = ['code', 'name', 'version', 'description', 'tags'];

const FieldSetProcedureDetails = ({ procedure, errors, onFieldRefChanged, onProcedureDetailsFormChanged }) => {
  const { isAutoProcedureIdEnabled } = useAutoProcedureId(procedure);
  const fieldRef = useCallback(
    (field) => {
      if (!onFieldRefChanged) {
        return;
      }
      return (element) => onFieldRefChanged(field, element);
    },
    [onFieldRefChanged]
  );

  const initialValues = useMemo(() => {
    return pick(procedure, PROCEDURE_DETAILS_FORM_FIELDS);
  }, [procedure]);

  const initialErrors = useMemo(() => {
    return pick(errors, PROCEDURE_DETAILS_FORM_FIELDS);
  }, [errors]);

  const validate = useCallback(
    (values) => {
      const trimmedValues = {
        ...values,
        name: values.name.trim(),
        code: values.code.trim(),
      };
      onProcedureDetailsFormChanged(trimmedValues);
    },
    [onProcedureDetailsFormChanged]
  );

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={initialErrors}
      onSubmit={() => {}}
      validateOnChange={false}
      validate={validate}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <div>
            <div className="w-full mt-2 flex flex-wrap gap-2 items-start">
              {/* Procedure unique Id */}
              <div
                className="w-1/6 flex flex-col"
                ref={fieldRef('procedure.code')}
                style={{ minWidth: '12rem', scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
              >
                <label className="mb-1 font-semibold field-title">Unique&nbsp;ID*</label>
                <FieldSetProcedureCode isAutoGenerated={isAutoProcedureIdEnabled} />
                {errors && errors.code && <div className="text-red-700">{errors.code}</div>}
              </div>
              {/* Procedure name */}
              <div
                className="grow flex flex-col"
                ref={fieldRef('procedure.name')}
                style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
              >
                <label className="mb-1 font-semibold field-title">Name*</label>
                <Field
                  name="name"
                  type="text"
                  placeholder="Procedure name"
                  className="text-sm border-1 border-gray-400 rounded"
                />
                {errors && errors.name && <div className="text-red-700">{errors.name}</div>}
              </div>
              {/* Procedure version */}
              <div
                className="w-1/6 min-w-max flex flex-col"
                ref={fieldRef('procedure.version')}
                style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
                aria-label="Procedure Version"
              >
                <label className="mb-1 font-semibold field-title">Version*</label>
                <Field
                  name="version"
                  type="text"
                  placeholder="YYYY.MM.DD, etc"
                  className="text-sm border-1 border-gray-400 rounded"
                />
                {errors && errors.version && <div className="text-red-700">{errors.version}</div>}
              </div>
            </div>
            {/* Procedure description */}
            <div className="w-full mt-2 flex items-start space-x-1">
              <div
                className="w-full flex flex-col"
                ref={fieldRef('procedure.description')}
                style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
              >
                <label className="mb-1 field-title">Description</label>
                <Field
                  name="description"
                  className="h-full border border-gray-400 rounded"
                  component={FieldSetProcedureDescription}
                  value={values.description}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FieldSetProcedureDetails;

import Tooltip from '../../elements/Tooltip';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useMemo } from 'react';
import { SettingDisabledIcon, SettingEnabledIcon } from '../SettingBadge';
import { DIFF_COLOR_CLASSES } from '../../lib/diffUtil';

type ReviewSettingBadgeProps = {
  oldSettingValue: boolean | undefined;
  newSettingValue: boolean | undefined;
  oldVisibleSettingValue?: boolean;
  newVisibleSettingValue?: boolean;
  tooltipText: string;
  icon: IconProp;
};

/**
 * Icons show as added if they did not appear in the old version, but did appear in the new version.
 */
const ReviewSettingBadge = ({
  oldSettingValue,
  newSettingValue,
  oldVisibleSettingValue,
  newVisibleSettingValue,
  tooltipText,
  icon,
}: ReviewSettingBadgeProps) => {
  const iconAlwaysShown = useMemo(
    () => oldVisibleSettingValue === undefined || newVisibleSettingValue === undefined,
    [newVisibleSettingValue, oldVisibleSettingValue]
  );
  const diffChangeState: (typeof ARRAY_CHANGE_SYMBOLS)[keyof typeof ARRAY_CHANGE_SYMBOLS] = useMemo(() => {
    if (iconAlwaysShown) {
      return oldSettingValue === newSettingValue ? ARRAY_CHANGE_SYMBOLS.UNCHANGED : ARRAY_CHANGE_SYMBOLS.MODIFIED;
    }

    // Icon will only show if there is an added/removed/modified diff change state, or if the value does not match the visible value.

    if (oldSettingValue !== oldVisibleSettingValue && newSettingValue === newVisibleSettingValue) {
      // The icon became visible in the new version, so mark as added.
      return ARRAY_CHANGE_SYMBOLS.ADDED;
    }

    if (oldSettingValue === oldVisibleSettingValue && newSettingValue !== newVisibleSettingValue) {
      // The icon was removed in the new version, so mark as removed.
      return ARRAY_CHANGE_SYMBOLS.REMOVED;
    }

    if (oldSettingValue === oldVisibleSettingValue && newSettingValue === newVisibleSettingValue) {
      /*
       * The icon was visible in the old and new versions.
       * Mark as modified only if the old and new values differ.
       */
      return oldSettingValue === newSettingValue ? ARRAY_CHANGE_SYMBOLS.UNCHANGED : ARRAY_CHANGE_SYMBOLS.MODIFIED;
    }

    if (oldSettingValue !== oldVisibleSettingValue && newSettingValue !== newVisibleSettingValue) {
      /*
       * The icon was visible in neither the old nor the new version.
       * Mark as modified only if the old and new values differ.
       */
      return oldSettingValue === newSettingValue ? ARRAY_CHANGE_SYMBOLS.UNCHANGED : ARRAY_CHANGE_SYMBOLS.MODIFIED;
    }

    return ARRAY_CHANGE_SYMBOLS.UNCHANGED;
  }, [iconAlwaysShown, oldSettingValue, oldVisibleSettingValue, newSettingValue, newVisibleSettingValue]);

  const boxStyle = `rounded ${DIFF_COLOR_CLASSES[diffChangeState].borderColor} ${DIFF_COLOR_CLASSES[diffChangeState].backgroundColor}`;
  const settingInfoText = `${tooltipText}${oldSettingValue !== newSettingValue ? ` was changed to` : ''}: ${
    newSettingValue ? 'Enabled' : 'Disabled'
  }`;

  const doNotShowIcon = useMemo(() => {
    if (iconAlwaysShown) {
      return false;
    }
    return newSettingValue !== newVisibleSettingValue && diffChangeState === ARRAY_CHANGE_SYMBOLS.UNCHANGED;
  }, [iconAlwaysShown, newSettingValue, newVisibleSettingValue, diffChangeState]);

  const displayValue = useMemo(
    () => (diffChangeState === ARRAY_CHANGE_SYMBOLS.REMOVED ? oldSettingValue : newSettingValue),
    [diffChangeState, newSettingValue, oldSettingValue]
  );
  if (doNotShowIcon) {
    return <></>;
  }

  return (
    <Tooltip content={settingInfoText}>
      <div className={`h-8 w-8 flex items-center justify-center ${boxStyle}`}>
        {displayValue === false && <SettingDisabledIcon icon={icon} ariaLabel={settingInfoText} />}
        {displayValue === true && <SettingEnabledIcon icon={icon} ariaLabel={settingInfoText} />}
      </div>
    </Tooltip>
  );
};

export default ReviewSettingBadge;

import TooltipOverlay from '../../TooltipOverlay';
import Rule from '../Rule';
import RuleStatusIcon from '../RuleStatusIcon';
import UnitDisplay from '../../Settings/Units/UnitDisplay';
import RuleStatus from '../RuleStatus';
import React, { ChangeEventHandler, FocusEventHandler, useMemo } from 'react';
import { FieldInputBlock, FieldInputNumberBlock, RunFieldInputRecorded } from 'shared/lib/types/views/procedures';
import fieldInputUtil from '../../../lib/fieldInputUtil';
import FieldInputNameDisplay from './FieldInputNameDisplay';

interface NumberFieldInputProps {
  block: FieldInputNumberBlock;
  recorded?: RunFieldInputRecorded<number>;
  isEnabled: boolean;
  errors: { text?: string };
  fieldName: string;
  fieldValue: number;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
}

const NumberFieldInput = ({
  block,
  recorded,
  isEnabled,
  errors,
  fieldName,
  fieldValue,
  onChange,
  onBlur,
}: NumberFieldInputProps) => {
  const isPassing = useMemo(() => {
    return fieldInputUtil.isNumberFieldInputPassing(block as FieldInputBlock, recorded);
  }, [block, recorded]);

  const hasRule = Boolean(block.rule?.op);

  return (
    <div className="flex flex-row gap-x-2 items-center">
      <FieldInputNameDisplay blockName={block?.name ?? ''} />
      <div>=</div>
      <TooltipOverlay
        content={
          <div>
            <Rule
              block={block}
              isPassing={null} // Do not show status in the overlay here.
            />
          </div>
        }
        visible={hasRule}
      >
        <div className="flex flex-wrap grow justify-between items-center gap-2">
          <div className="flex flex-no-wrap gap-2 items-center">
            <div className="relative w-fit">
              <input
                name={fieldName}
                value={fieldValue}
                aria-label="Enter Value"
                className={`text-sm px-2 pl-1.5 ${
                  hasRule ? 'pr-5' : ''
                } w-36 h-8 border border-gray-400 rounded disabled:bg-gray-300 disabled:bg-opacity-50 focus:text-gray-500`}
                onBlur={onBlur}
                onChange={onChange}
                disabled={!isEnabled}
              />
              {hasRule && (
                <div className="absolute right-1.5 top-1/2 -translate-y-1/2">
                  <RuleStatusIcon isPassing={isPassing} />
                </div>
              )}
            </div>
            {block.units && (
              <div>
                <UnitDisplay unit={block.units} />
              </div>
            )}
            {errors?.text && <span className="whitespace-nowrap text-sm text-red-700">{errors.text}</span>}
          </div>
          {hasRule && (
            <div>
              <RuleStatus isPassing={isPassing} />
            </div>
          )}
        </div>
      </TooltipOverlay>
    </div>
  );
};

export default NumberFieldInput;

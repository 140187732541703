import { useCallback, useRef, useState } from 'react';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import procedureUtil from '../lib/procedureUtil';
import { Mention } from 'shared/lib/types/postgres/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNil } from 'lodash';
import { generateCommentId } from 'shared/lib/idUtil';
import { DatabaseServices } from '../contexts/proceduresSlice';
import { MAX_FILE_SIZE } from 'shared/lib/types/api/files/requests';
import { MAX_FILE_SIZE_EXCEEDED_MESSAGE } from '../attachments/service';

interface UseAttachmentCommentActionsProps {
  sectionId: string;
  stepId: string;
  parentId?: string;
  onSaveNewComment?: (comment) => void;
}

interface UseAttachmentCommentActionsReturn {
  uploadFile: File | null;
  setUploadFile: (file) => void;
  isSubmittingAttachment: boolean;
  isAttachmentRenderingImage: boolean;
  isAddingText: boolean;
  attachButton: React.ReactElement | undefined;
  setIsAddingText: (isAdding: boolean) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  onFileInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSaveTextComment: (values: { comment: string; mentions: Array<Mention> }) => Promise<void>;
  onSubmitAttachment: () => void;
  onShouldRenderImageChange: (shouldRenderImage: boolean) => void;
}

const useAttachmentCommentActions = ({
  sectionId,
  stepId,
  parentId,
  onSaveNewComment,
}: UseAttachmentCommentActionsProps): UseAttachmentCommentActionsReturn => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { mixpanel } = useMixpanel();
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [isSubmittingAttachment, setIsSubmittingAttachment] = useState(false);
  const [isAttachmentRenderingImage, setIsAttachmentRenderingImage] = useState(false);
  const [isAddingText, setIsAddingText] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const mixpanelTrack = useCallback(
    (name: string) => {
      if (mixpanel && name) {
        mixpanel.track(name, { level: parentId ? 'Child' : 'Parent' });
      }
    },
    [mixpanel, parentId]
  );

  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files[0]) {
      const file = e.currentTarget.files[0];
      if (file.size > MAX_FILE_SIZE) {
        window.alert(MAX_FILE_SIZE_EXCEEDED_MESSAGE);
      } else {
        setUploadFile(file);
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const onSaveTextComment = async (values: { comment: string; mentions: Array<Mention> }): Promise<void> => {
    if (!onSaveNewComment) {
      return;
    }

    const trimmed = procedureUtil.trimLeadingAndTrailing(values.comment);
    if (!trimmed) {
      return;
    }

    const mentions = procedureUtil.getValidMentions(values.mentions, trimmed);
    if (mentions.length > 0) {
      mixpanelTrack('User mentioned in comment');
    }

    const comment = {
      id: generateCommentId(),
      parent_id: parentId,
      sectionId,
      stepId,
      text: trimmed,
      mention_list: mentions,
    };
    return onSaveNewComment(comment);
  };

  const onSaveAttachmentComment = () => {
    if (!services.attachments) {
      return Promise.reject('Services unavailable');
    }
    if (onSaveNewComment && uploadFile) {
      return services.attachments
        .uploadFile(uploadFile, 'runs:comments', { remote: false })
        .then((attachment) => {
          return {
            id: generateCommentId(),
            parent_id: parentId,
            sectionId,
            stepId,
            attachment,
          };
        })
        .then((comment) => onSaveNewComment(comment));
    }
    return Promise.resolve();
  };

  const onSubmitAttachment = () => {
    setIsSubmittingAttachment(true);
    onSaveAttachmentComment()
      .then(() => {
        mixpanelTrack('Run Attachment Saved');
        setUploadFile(null);
      })
      .catch()
      .finally(() => setIsSubmittingAttachment(false));
  };

  const onShouldRenderImageChange = (shouldRenderImage: boolean) => {
    setIsAttachmentRenderingImage(shouldRenderImage);
  };

  const attachButton = !isNil(onSaveNewComment) ? (
    <button
      className="mt-2 mb-1 btn-link px-3 py-1 text-sm rounded hover:bg-slate-300 disabled:bg-transparent disabled:text-gray-400 disabled:cursor-default"
      onClick={() => fileInputRef.current && fileInputRef.current.click()}
    >
      <FontAwesomeIcon icon="paperclip" /> Attach
    </button>
  ) : undefined;

  return {
    uploadFile,
    setUploadFile,
    isSubmittingAttachment,
    isAttachmentRenderingImage,
    isAddingText,
    attachButton,
    setIsAddingText,
    fileInputRef,
    onFileInputChange,
    onSaveTextComment,
    onSubmitAttachment,
    onShouldRenderImageChange,
  };
};

export default useAttachmentCommentActions;

import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import superlogin, { checkRefreshSession } from '../api/superlogin';

const USER_ACTIVITY_THROTTLE_MS = 5000;

/**
 * Observes user activity and attempts to refresh the user's session as needed.
 */
const UserSessionRefresh = React.memo(() => {
  // Refresh session when user is active.
  const onUserAction = async () => {
    if (!superlogin.authenticated()) {
      return;
    }
    try {
      await checkRefreshSession();
    } catch {
      /**
       * Ignore all session refresh errors.
       *
       * `checkRefreshSession` handles 401 auth failures and will logout the user
       * if needed. Otherwise, we may be offline, or the backend may be
       * temporarily unavailable, so without knowing more we can ignore other
       * errors and try again later. The current session will still be valid for
       * a period of time.
       */
    }
  };

  /**
   * Using `throttle` is important here. Since the debounce/throttle timer is
   * reset every time `useIdleTimer` is called, and since this component can
   * re-render without warning, we need to use `throttle` to ensure the user
   * activity callback is run on the leading edge of the even window. This
   * does cause more activity callbacks than normal, but better to be safe.
   *
   * In the future, we could be figure out how to instantiate `useIdleTimer`
   * only once.
   */
  useIdleTimer({
    onAction: onUserAction,
    throttle: USER_ACTIVITY_THROTTLE_MS,
  });

  return null;
});

export default UserSessionRefresh;

import { useMemo, useCallback, Dispatch, SetStateAction, useState, useEffect } from 'react';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { CouchLikeOperation } from 'shared/lib/types/operations';
import { ParentReferenceType, Participant, Release, Run, RunState, RunStatus } from 'shared/lib/types/views/procedures';
import Button, { BUTTON_TYPES } from '../Button';
import { Link, useHistory, useLocation } from 'react-router-dom';
import RunStatusLabel from '../RunStatusLabel';
import runUtil, { PARTICIPANT_TYPE } from '../../lib/runUtil';
import AvatarStack from '../AvatarStack';
import DateTimeDisplay from '../DateTimeDisplay';
import RunProgressBar, { StepCounts } from '../RunProgressBar';
import { procedureViewPath, runViewPath, eventPath, procedureSnapshotViewPathWithSourceUrl } from '../../lib/pathUtil';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
import Grid, { GridColumn } from '../../elements/Grid';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { useUserInfo } from '../../contexts/UserContext';
import externalDataUtil from '../../lib/externalDataUtil';
import { INVALID_ITEMS_MESSAGE } from '../ButtonsProcedure';
import usePendingChangesPrompt from '../../hooks/usePendingChangesPrompt';
import _ from 'lodash';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import RunLabel from '../RunLabel';
import TruncatedColumn from '../../schedule/components/TruncatedColumn';
import Tooltip from '../../elements/Tooltip';
import { DateTime } from 'luxon';
import RelativeScheduledDisplay from '../../schedule/components/RelativeScheduledDisplay';
import { SelectColumn, SortColumn } from 'react-data-grid';
import { compareEventStartTimes } from './lib/operationUtil';
import apm from '../../lib/apm';
import { EventMap } from '../../screens/OperationDetail';
import procedureUtil from '../../lib/procedureUtil';

const MAIN_VERTICAL_PADDING = 190;

const nonViewerParticipants = (participants: Array<Participant>) => {
  const nonViewers = participants.filter((p) => {
    return p.type === PARTICIPANT_TYPE.PARTICIPATING;
  });
  return nonViewers.map((p) => p.user_id);
};

interface OperationProceduresListProps {
  operation: CouchLikeOperation;
  procedures: Array<Release>;
  setProcedures: Dispatch<SetStateAction<Array<Release>>>;
  runs: Array<Run>;
  eventMap: EventMap;
  loadEvents: () => void;
  selectedRows: Set<Event['id']>;
  setSelectedRows: Dispatch<SetStateAction<Set<Event['id']>>>;
}

interface ProcedureMap {
  [procedureId: string]: Release;
}

interface RunMap {
  [runId: string]: Run;
}

const NoDataPlaceholder = ({ text = '-' }: { text?: string }) => {
  return <div className="text-xs text-gray-400">{text}</div>;
};

const PlanningEventStatusColumn = ({
  event,
  operation,
  startProcedure,
  procedureMap,
  currentTeamId,
}: {
  event: Event;
  operation: CouchLikeOperation;
  startProcedure: (procedure: Release, sectionId?: string) => Promise<void>;
  procedureMap: ProcedureMap;
  currentTeamId: string;
}) => {
  const location = useLocation();
  const procedure = procedureMap[event.procedure_id || ''];
  if (!procedure) {
    return <NoDataPlaceholder />;
  }
  const truncateCode = (code: string, maxLength: number) => {
    return code.length > maxLength ? `${code.substring(0, maxLength)}...` : code;
  };
  const truncatedProcedureCode = truncateCode(procedure.code, 18);

  const procedureLink = procedureSnapshotViewPathWithSourceUrl({
    teamId: currentTeamId,
    procedureId: event.procedure_id || '',
    sectionId: event.procedure_section_id,
    sourceUrl: location.pathname,
    sourceName: `Operation ${operation.name}`,
  });
  return (
    <div className="flex flex-row items-center">
      <Button
        type={BUTTON_TYPES.PRIMARY}
        size="sm"
        onClick={() => startProcedure(procedure, event.procedure_section_id)}
      >
        Start
      </Button>
      <Tooltip content={procedure.code} visible={truncatedProcedureCode !== procedure.code}>
        <Link to={procedureLink} className="text-xs ml-2 text-blue-600 hover:underline truncate">
          {truncatedProcedureCode}
        </Link>
      </Tooltip>
    </div>
  );
};

const RunningEventStatusColumn = ({
  event,
  runMap,
  currentTeamId,
  operation,
}: {
  event: Event;
  runMap: RunMap;
  currentTeamId: string;
  operation: CouchLikeOperation;
}) => {
  if (!event.run_id) {
    return <NoDataPlaceholder />;
  }
  const run = runMap[event.run_id];
  const status = runUtil.getStatus(run.state, run.status);
  const stepCounts = runUtil.getRunStepCounts(run).runCounts as StepCounts;
  if (!run || !status) {
    return <NoDataPlaceholder />;
  }
  const linkPath = {
    pathname: runViewPath(currentTeamId, event.run_id),
    state: { operation: operation.key },
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-x-2">
        <RunStatusLabel statusText={status} />
        <RunLabel code={run.code} runNumber={run.run_number} link={linkPath} truncateAtCodeChars={10} />
      </div>
      <div className="flex flex-row self-start w-44">
        <RunProgressBar runStatus={{ id: run._id, status, state: 'running' }} stepCounts={stepCounts} />
      </div>
    </div>
  );
};

const CompletedEventStatusColumn = ({
  event,
  status,
  runMap,
  currentTeamId,
  operation,
}: {
  event: Event;
  status: RunState | RunStatus | undefined;
  runMap: RunMap;
  currentTeamId: string;
  operation: CouchLikeOperation;
}) => {
  if (!event.run_id) {
    return <NoDataPlaceholder />;
  }
  const run = runMap[event.run_id];
  if (!run || !status) {
    return <NoDataPlaceholder />;
  }
  const linkPath = {
    pathname: runViewPath(currentTeamId, event.run_id),
    state: { operation: operation.key },
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center gap-x-2">
        <RunStatusLabel statusText={status} />
        <RunLabel code={run.code} runNumber={run.run_number} link={linkPath} />
      </div>
    </div>
  );
};

const OperationEventList = ({
  operation,
  procedures,
  setProcedures,
  runs,
  eventMap,
  loadEvents,
  selectedRows,
  setSelectedRows,
}: OperationProceduresListProps) => {
  const { services, currentTeamId }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();
  const history = useHistory();
  const { mixpanel } = useMixpanel();
  const { userInfo } = useUserInfo();
  const userId = userInfo.session.user_id;
  const confirmPendingChanges = usePendingChangesPrompt();

  const [allEvents, setAllEvents] = useState<Array<Event>>([]);

  useEffect(() => {
    services.events
      .getSingleEvents()
      .then((newEvents) => {
        setAllEvents(newEvents);
      })
      .catch((err) => apm.captureError(err));
  }, [services.events]);

  const mixpanelTrack = useCallback(
    (trackingKey: string, properties?: object | undefined) => {
      if (mixpanel) {
        mixpanel.track(trackingKey, properties);
      }
    },
    [mixpanel]
  );

  const procedureMap: ProcedureMap = useMemo(
    () => Object.fromEntries(procedures.map((proc) => [proc._id, proc])),
    [procedures]
  );

  const runMap: RunMap = useMemo(() => Object.fromEntries(runs.map((run) => [run._id, run])), [runs]);

  const defaultSort = [
    {
      columnKey: 'start',
      direction: 'ASC',
    },
  ] as Array<SortColumn>;

  const isEventRowSelectable = useCallback(
    (row: Event) => {
      const run = runMap[row.run_id || ''];
      if (!run) {
        return false;
      }
      return !runUtil.isCompleted(run);
    },
    [runMap]
  );

  const handleRowChange = useCallback(
    (eventId: Event['id']) => {
      const newSelectedRows = new Set(selectedRows);
      if (newSelectedRows.has(eventId)) {
        newSelectedRows.delete(eventId);
      } else {
        newSelectedRows.add(eventId);
      }
      setSelectedRows(newSelectedRows);
    },
    [selectedRows, setSelectedRows]
  );

  const handleRowsChange = useCallback(
    (rows: Set<Event['id']>) => {
      const selectableRows = new Set(Array.from(rows).filter((rowId) => isEventRowSelectable(eventMap[rowId])));
      if (selectableRows.size !== selectedRows.size) {
        setSelectedRows(selectableRows);
      } else {
        setSelectedRows(new Set());
      }
    },
    [selectedRows, setSelectedRows, isEventRowSelectable, eventMap]
  );

  // Creates a run doc and updates with dynamic data if online.
  const createRun = useCallback(
    async (procedure: Release, sectionId?: string) => {
      const parentReferenceId = operation.key;
      const parentReferenceType = ParentReferenceType.Operation;
      const run = runUtil.newLinkedProcedureRunDoc({
        procedure,
        linkedSectionIndex: sectionId ? procedureUtil.getSectionIndexById(procedure, sectionId) : null,
        parentReferenceId,
        parentReferenceType,
        operation,
        userId,
      });
      try {
        const externalItems = await services.externalData.getAllExternalItems(run);
        return externalDataUtil.updateProcedureWithItems(run, externalItems);
      } catch (err) {
        // Ignore any errors and fall back to using procedure without dynamic data.
        return run;
      } finally {
        await loadEvents();
      }
    },
    [services.externalData, userId, loadEvents, operation]
  );

  const startProcedure = useCallback(
    async (procedure: Release, sectionId?: string) => {
      if (!operation || !(await confirmPendingChanges(procedure))) {
        return;
      }

      const run = await createRun(procedure, sectionId);
      const hasInvalidExternalItems = externalDataUtil.hasInvalidExternalItems(run);
      if (hasInvalidExternalItems && !window.confirm(INVALID_ITEMS_MESSAGE)) {
        return;
      }

      try {
        run.operation = _.pick(operation, 'name', 'key');
        setProcedures(procedures.concat(run));
        await services.runs.startRun(run);
        mixpanelTrack('Run Procedure', { Source: 'Operation Detail' });
      } catch (err) {
        setProcedures(procedures);
      } finally {
        await loadEvents();
      }
    },
    [procedures, confirmPendingChanges, createRun, mixpanelTrack, operation, services.runs, setProcedures, loadEvents]
  );

  const sortedEvents = useMemo(() => {
    return Array.from(Object.values(eventMap)).sort((a, b) => compareEventStartTimes(a, b, runMap));
  }, [eventMap, runMap]);

  const columns: GridColumn<Event>[] = useMemo(
    () => [
      ...(operation.state === 'running'
        ? [
            {
              ...SelectColumn,
              renderHeaderCell: () => {
                const selectableRowIds = new Set<Event['id']>();
                for (const event of sortedEvents) {
                  if (isEventRowSelectable(event)) {
                    selectableRowIds.add(event.id);
                  }
                }
                const isDisabled = selectableRowIds.size === 0;
                return (
                  <input
                    type="checkbox"
                    className={`rdg-checkbox-input ${isDisabled ? 'bg-gray-200 border-gray-400' : 'cursor-pointer'}`}
                    disabled={isDisabled}
                    onChange={() => handleRowsChange(selectableRowIds)}
                    checked={selectableRowIds.size !== 0 && selectedRows.size === selectableRowIds.size}
                  />
                );
              },
              renderCell: ({ row }: { row: Event }) => {
                const isDisabled = !isEventRowSelectable(row);
                const isChecked = selectedRows.has(row.id);
                return (
                  <input
                    type="checkbox"
                    className={`rdg-checkbox-input ${isDisabled ? 'bg-gray-200 border-gray-400' : 'cursor-pointer'}`}
                    disabled={isDisabled}
                    checked={isChecked}
                    onChange={() => handleRowChange(row.id)}
                  />
                );
              },
            },
          ]
        : []),
      {
        key: 'event_name',
        name: 'Event',
        width: operation.state === 'running' ? '19%' : '20%',
        sortable: true,
        renderCell: ({ row }: { row: Event }) => {
          return (
            <TruncatedColumn
              text={row.name}
              onClick={() => history.push(eventPath(currentTeamId, row.id), { from: 'operation' })}
            />
          );
        },
        comparator: (a: Event, b: Event) => {
          return a.name.localeCompare(b.name);
        },
      },
      {
        key: 'start',
        name: 'Start',
        width: '22%',
        sortable: true,
        renderCell: ({ row }: { row: Event }) => {
          const run = runMap[row.run_id || ''];
          if (run) {
            return (
              <div className="text-xs">
                <DateTimeDisplay timestamp={run.starttime} wrap={true} hasTooltip={true} zone="UTC" />
                <span> UTC</span>
              </div>
            );
          }
          if (row.predecessor_id) {
            const predecessor = allEvents.find((e) => e.id === row.predecessor_id);
            if (predecessor) {
              return (
                <div className="text-xs text-gray-400">
                  <RelativeScheduledDisplay
                    teamId={currentTeamId}
                    predecessorEvent={predecessor}
                    predecessorOffset={row.predecessor_offset}
                    from="operation"
                  />
                </div>
              );
            }
          }
          if (row.start) {
            return (
              <div className="text-xs text-gray-400">
                <span>(</span>
                <DateTimeDisplay timestamp={(row.start as DateTime).toUTC()} wrap={true} hasTooltip={true} zone="UTC" />
                <span> UTC</span>
                <span>)</span>
              </div>
            );
          }
          return <NoDataPlaceholder />;
        },
        comparator: (a: Event, b: Event) => {
          return compareEventStartTimes(a, b, runMap);
        },
      },
      {
        key: 'end',
        name: 'End',
        width: '22%',
        renderCell: ({ row }: { row: Event }) => {
          const run = runMap[row.run_id || ''];
          if (run && run.completedAt) {
            return (
              <div className="text-xs">
                <DateTimeDisplay timestamp={run.completedAt} wrap={true} hasTooltip={true} zone="UTC" />
                <span> UTC</span>
              </div>
            );
          }
          if (row.end) {
            return (
              <div className="text-xs text-gray-400">
                <span>(</span>
                <DateTimeDisplay timestamp={(row.end as DateTime).toUTC()} wrap={true} hasTooltip={true} zone="UTC" />
                <span> UTC</span>
                <span>)</span>
              </div>
            );
          }
          return <NoDataPlaceholder />;
        },
      },
      ...(operation.state !== 'planning'
        ? [
            {
              key: 'participants',
              name: 'Participants',
              width: '11%',
              renderCell: ({ row }: { row: Event }) => {
                if (!row.run_id) {
                  return <NoDataPlaceholder />;
                }
                const run = runMap[row.run_id];
                if (!run) {
                  return <NoDataPlaceholder />;
                }
                return <AvatarStack userIds={run.participants ? nonViewerParticipants(run.participants) : []} />;
              },
            },
            {
              key: 'status',
              name: 'Status',
              width: operation.state === 'running' ? '22%' : '25%',
              sortable: true,
              renderCell: ({ row }: { row: Event }) => {
                // If no procedure set on event, just show the manual status of the event
                if (!row.procedure_id) {
                  return <RunStatusLabel statusText={row.status || 'planning'} />;
                }
                const run = runMap[row.run_id || ''];
                if (run) {
                  const status = runUtil.getStatus(run.state, run.status);
                  if (status === 'running') {
                    return (
                      <RunningEventStatusColumn
                        event={row}
                        runMap={runMap}
                        currentTeamId={currentTeamId}
                        operation={operation}
                      />
                    );
                  }
                  return (
                    <CompletedEventStatusColumn
                      event={row}
                      status={status}
                      runMap={runMap}
                      currentTeamId={currentTeamId}
                      operation={operation}
                    />
                  );
                }
                return (
                  <PlanningEventStatusColumn
                    event={row}
                    operation={operation}
                    procedureMap={procedureMap}
                    currentTeamId={currentTeamId}
                    startProcedure={startProcedure}
                  />
                );
              },
              comparator: (a: Event, b: Event) => {
                // Define status order: planning -> running -> success/failure/abort/complete
                const statusOrder = {
                  undefined: 0,
                  planning: 1,
                  running: 2,
                  success: 3,
                  failure: 4,
                  abort: 5,
                  completed: 6,
                  paused: 7,
                };

                const statusA = statusOrder[a.status as keyof typeof statusOrder] ?? statusOrder.undefined;
                const statusB = statusOrder[b.status as keyof typeof statusOrder] ?? statusOrder.undefined;

                return statusA - statusB;
              },
            },
          ]
        : [
            {
              key: 'procedure',
              name: 'Procedure',
              renderCell: ({ row }: { row: Event }) => {
                if (!row.procedure_id) {
                  return <NoDataPlaceholder />;
                }
                const linkPath = procedureViewPath(currentTeamId, row.procedure_id);
                const procedure = procedureMap[row.procedure_id];
                if (!procedure) {
                  return <NoDataPlaceholder />;
                }
                return (
                  <div className="leading-4">
                    {procedure.code && <RunLabel code={procedure.code} name={procedure.name} link={linkPath} />}
                  </div>
                );
              },
            },
          ]),
    ],
    [
      currentTeamId,
      operation,
      startProcedure,
      history,
      allEvents,
      procedureMap,
      runMap,
      selectedRows,
      handleRowChange,
      isEventRowSelectable,
      handleRowsChange,
      sortedEvents,
    ]
  );

  if (!procedures || !operation || !sortedEvents) {
    return null;
  }

  return (
    <Grid
      key={columns.length} // Force RDG to re-render columns when op starts
      columns={columns}
      rows={sortedEvents}
      usedVerticalSpace={() => MAIN_VERTICAL_PADDING}
      defaultSort={defaultSort}
      selectedRows={selectedRows}
      onSelectedRowsChange={handleRowsChange}
      rowKeyGetter={(row: Event) => row.id}
    />
  );
};

export default OperationEventList;

import { useCallback, useMemo, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { cloneDeep, isEqual } from 'lodash';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { SectionSnippet } from '../../lib/views/settings';
import useExpandCollapse from '../../hooks/useExpandCollapse';
import { ProcedureContextProvider } from '../../contexts/ProcedureContext';
import FieldSetProcedureSection from '../FieldSetProcedureSection';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import validateUtil from '../../lib/validateUtil';
import { ProcedureContentBlockTypes } from 'shared/lib/types/blockTypes';
import procedureUtil from '../../lib/procedureUtil';
import ModalSaveSnippet from './ModalSaveSnippet';
import attachmentUtil from '../../lib/attachmentUtil';
import FieldSetSnippetHeader from './FieldSetSnippetHeader';
import PromptBeforeUnload from '../Prompt/PromptBeforeUnload';
import { Actions } from '../ContentMenu/addContentTypes';
import AddContentMenu from '../ContentMenu/AddContentMenu';
import getSectionAddContentItems from '../ContentMenu/sectionAddContentItems';
import { SelectionContextProvider } from '../../contexts/Selection';
import FlashMessage from '../FlashMessage';
import Selectable, { Boundary } from '../Selection/Selectable';
import clipboardUtil from '../../lib/clipboardUtil';
import snippetUtil from '../../lib/snippetUtil';
import {
  DatabaseServices,
  copyItemToClipboard,
  selectClipboardItem,
  selectProcedures,
} from '../../contexts/proceduresSlice';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS } from '../../screens/ProcedureEdit';
import VirtualizedAPI from '../../elements/Virtualized/VirtualizedAPI';

interface FieldSetSectionSnippetProps {
  snippet: SectionSnippet;
  onClose;
  onRemove?: (id: string) => Promise<void>;
  onSaveSuccess?: () => void;
  isDuplicate?: boolean;
  testingModule?: boolean;
}

const FieldSetSectionSnippet = ({
  snippet,
  onClose,
  onRemove,
  onSaveSuccess,
  isDuplicate,
  testingModule,
}: FieldSetSectionSnippetProps) => {
  const store = useStore();
  const [present, setPresent] = useState(snippet);
  const [sectionErrors, setSectionErrors] = useState({});
  const [showsSaveSectionSnippet, setShowsSaveSectionSnippet] = useState(false);
  const { services, currentTeamId }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();
  const { setIsCollapsed, isCollapsedMap, setAllStepsInSectionExpanded, areAllStepsInSectionExpanded } =
    useExpandCollapse(() => VirtualizedAPI.refresh(PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS));
  const [showsDuplicateModal, setShowsDuplicateModal] = useState(isDuplicate);

  const dispatch = useDispatch();
  const { mixpanel } = useMixpanel();
  const [flashMessage, setFlashMessage] = useState<string | null>(null);
  const clipboardItem = useSelector((state) => selectClipboardItem(state, currentTeamId));
  const clipboardStep = useMemo(() => {
    if (!clipboardItem) {
      return undefined;
    }
    const clipboardStep = clipboardUtil.getStepFromClipboardItem(clipboardItem);
    if (!clipboardStep || snippetUtil.validateStepForSnippet(clipboardStep) !== undefined) {
      return undefined;
    }
    return clipboardStep;
  }, [clipboardItem]);

  const mixpanelTrack = useCallback(
    (trackingKey) => {
      if (mixpanel && trackingKey) {
        mixpanel.track(trackingKey);
      }
    },
    [mixpanel]
  );

  const onCopySection = useCallback(() => {
    mixpanelTrack('Copy Section');
    const copiedSection = procedureUtil.copySection(present.section);
    const clipboardItemSection = clipboardUtil.createClipboardItemSection(copiedSection);
    dispatch(copyItemToClipboard(currentTeamId, clipboardItemSection));
    setFlashMessage('Section copied');
  }, [currentTeamId, dispatch, mixpanelTrack, present]);

  const onPasteStep = useCallback(() => {
    if (!clipboardStep) {
      return;
    }
    mixpanelTrack('Paste Step');
    const updatedSnippet = cloneDeep(present);
    const stepCopy = procedureUtil.copyStep(clipboardStep);
    updatedSnippet.section.steps.push(stepCopy);
    setPresent(updatedSnippet);
  }, [clipboardStep, mixpanelTrack, present]);

  // Array of booleans mapping whether all the steps in section snippet are collapsed
  const allStepsInSectionExpandedMap = useMemo(
    () => present && present.section && areAllStepsInSectionExpanded && areAllStepsInSectionExpanded(present.section),
    [areAllStepsInSectionExpanded, present]
  );

  // Used as a filler prop for ProcedureContextProvider
  const procedureShell = useMemo(() => {
    const shell = {
      _id: '',
      code: '',
      name: '',
      description: '',
      sections: [present.section],
    };
    return shell;
  }, [present]);

  /*
   * Adds a new step following the existing one
   */
  const onAddStep = (stepIndex: number) => {
    const step = procedureUtil.newStep();

    const updated = cloneDeep(present);
    updated.section.steps.splice(stepIndex + 1, 0, step);
    setPresent(updated);
  };

  /*
   * Adds a new Section Header
   */
  const onAddSectionHeader = (sectionIndex: number) => {
    const sectionHeader = procedureUtil.newSectionHeader();
    const updated = cloneDeep(present);

    // Allow for backwards-compatibility if a section was created without a `headers` empty array
    if (!updated.section.headers) {
      updated.section.headers = [];
    }

    updated.section.headers.push(sectionHeader);
    setPresent(updated);
  };

  // Removes the header from the section snippet
  const onRemoveSectionHeader = useCallback(
    (headerIndex: number) => {
      const updated = cloneDeep(present);
      updated.section.headers?.splice(headerIndex, 1);

      setPresent(updated);
    },
    [present]
  );

  const onSectionHeaderFormChanged = useCallback(
    (values: object, sectionIndex: number, headerIndex: number) => {
      if (!present) {
        return;
      }
      const updated = cloneDeep(present);
      if (present.section.headers && updated.section.headers) {
        const header = present.section.headers[headerIndex];
        updated.section.headers[headerIndex] = {
          ...header,
          ...values,
        };
        if (isEqual(present, updated)) {
          return;
        }
        setPresent(updated);
      }
    },
    [present]
  );

  const onAddStepHeader = (stepIndex: number) => {
    const stepHeader = procedureUtil.newStepHeader();
    const updated = cloneDeep(present);

    // Allow for backwards-compatibility if a step was created without a `headers` empty array
    if (!updated.section.steps[stepIndex].headers) {
      updated.section.steps[stepIndex].headers = [];
    }

    updated.section.steps[stepIndex].headers?.push(stepHeader);
    setPresent(updated);
  };

  // Removes the given step header.
  const onRemoveStepHeader = useCallback(
    (stepIndex: number) => {
      const updated = cloneDeep(present);
      updated.section.steps[stepIndex].headers?.pop();

      setPresent(updated);
    },
    [present]
  );

  /*
   * Removes the given step.
   * If this is the only step, the step is replaced with an empty step
   */
  const onRemoveStep = (stepIndex: number) => {
    const numSteps = procedureUtil.numNonAddedStepsInSection(present.section);
    if (numSteps > 1) {
      // There are multiple steps, disregard how many sections
      const updated = cloneDeep(present);

      updated.section.steps.splice(stepIndex, 1);
      setPresent(updated);
    } else {
      // The step is the only step, and there is only one section
      const updated = cloneDeep(present);

      updated.section.steps.splice(stepIndex, 1);

      // insert new step at beginning of section
      updated.section.steps.unshift(procedureUtil.newStep());
      setPresent(updated);
    }
  };

  const onStepChanged = useCallback(
    (values, sectionIndex: number, stepIndex: number) => {
      if (!present) {
        return;
      }

      /* Save Updated Snippet of type Section  */
      const updated = cloneDeep(present);
      const step = present.section.steps[stepIndex];

      // get step index and just overwrite the new step for that section with the values one
      updated.section.steps[stepIndex] = {
        id: step.id,
        ...values,
      };

      setPresent(updated);
    },
    [present]
  );

  const onSectionChanged = useCallback(
    (values, sectionIndex: number) => {
      if (!present) {
        return;
      }
      const updated = cloneDeep(present);
      const section = present.section;
      updated.section = {
        id: section.id, // don't copy everything here because snippet properties can be removed
        steps: section.steps,
        ...(section.headers && { headers: section.headers }),
        ...values,
      };
      if (isEqual(present, updated)) {
        return;
      }

      setPresent(updated);
    },
    [present]
  );

  const onValidateSectionSnippet = useCallback(async () => {
    const section = present.section;

    // Validate section.
    const procedures = selectProcedures(store.getState(), currentTeamId); // get state on demand to prevent re-rendering of component every time procedures change
    const { errors: validationErrors } = await validateUtil.validateSection({
      section,
      teamId: currentTeamId,
      procedures,
      showRedlineValidation: true,
    });

    if (Object.keys(validationErrors).length !== 0) {
      setSectionErrors(validationErrors);
      setAllStepsInSectionExpanded(true, present.section);
      return;
    }
    setSectionErrors(validationErrors);
    setShowsDuplicateModal(false);
    setShowsSaveSectionSnippet(true);
  }, [currentTeamId, present.section, store, setAllStepsInSectionExpanded]);

  const onSaveSectionSnippet = useCallback(
    async (values) => {
      const updatedSection = cloneDeep(present.section);

      const snippetId = present.snippet_id;
      const name = values.name;
      const description = values.description;

      return attachmentUtil
        .uploadAllFilesFromSection(updatedSection, services.attachments)
        .then(() =>
          services.settings.saveSectionSnippet({
            id: snippetId,
            name,
            description,
            section: updatedSection,
            isTestSnippet: testingModule,
          })
        )
        .then(() => {
          const updated = cloneDeep(present);
          updated.name = name;
          updated.description = description;

          setPresent(updated);
          setShowsSaveSectionSnippet(false);
          // Go to edit after saving duplicate snippet
          if (showsDuplicateModal) {
            setShowsDuplicateModal(false);
            return;
          }
          onClose(); //Redirects to Snippet Settings page
        })
        .then(() => onSaveSuccess && onSaveSuccess())
        .catch(() => {
          return;
        });
    },
    [onClose, onSaveSuccess, present, services.attachments, services.settings, showsDuplicateModal, testingModule]
  );

  const onDragStepEnd = useCallback(
    (results: { source; destination }) => {
      const { source, destination } = results;

      if (!source || !destination) {
        return;
      }

      const sourceStepIndex = source.index;
      const destinationStepIndex = destination.index;

      if (sourceStepIndex === destinationStepIndex) {
        return;
      }

      // Remove step from the original list.
      const updated = cloneDeep(present);
      const [removed] = updated.section.steps.splice(sourceStepIndex, 1);

      // Insert step at destination.
      updated.section.steps.splice(destinationStepIndex, 0, removed);

      // If there are no steps left in the source list, add a default step.
      const numSteps = procedureUtil.numNonAddedStepsInSection(updated.section);
      if (numSteps === 0) {
        // insert new step at beginning of section
        const step = procedureUtil.newStep();
        updated.section.steps.unshift(step);
      }

      setPresent(updated);
    },
    [present]
  );

  /*
   *If the drop location is the same as the source location (inferred by same droppableId and same index),
   *we will not have to do anything. If it is not the same, we check if the source list and destination list
   *is the same, which would result in a move in the same list. If the drop list and the source list are different
   *we will need to remove the item from the source list and append it to the destination list.
   */
  const onDragEnd = useCallback(
    (results: { source; destination }) => {
      onDragStepEnd(results);
    },
    [onDragStepEnd]
  );

  const shouldPrompt = useMemo(() => {
    // no prompt needed if no new snippet edits were made
    return !isEqual(present, snippet);
  }, [present, snippet]);

  const onContentMenuClick = (menuItem, section) => {
    switch (menuItem.action) {
      case Actions.AddSectionHeader:
        return onAddSectionHeader(0);
      case Actions.DeleteSectionHeader:
        return onRemoveSectionHeader(0);
      case Actions.CopySection:
        return onCopySection();
      case Actions.PasteStep:
        return onPasteStep();
      case Actions.CollapseAllStepsInSection:
        return setAllStepsInSectionExpanded(false, section);
      case Actions.ExpandAllStepsInSection:
        setIsCollapsed(section.id, false);
        setAllStepsInSectionExpanded(true, section);
        return;
      default:
        return;
    }
  };

  const onKeyboard = useCallback(
    (event) => {
      if ((event.metaKey || event.ctrlKey) && event.code === 'KeyC') {
        onCopySection();
      } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyV') {
        onPasteStep();
      }
    },
    [onCopySection, onPasteStep]
  );

  const onCloseModal = useCallback(() => {
    if (showsDuplicateModal) {
      setShowsDuplicateModal(false);
      onClose();
    } else {
      setShowsSaveSectionSnippet(false);
    }
  }, [showsDuplicateModal, onClose]);

  return (
    <>
      <SelectionContextProvider>
        <PromptBeforeUnload shouldPrompt={shouldPrompt} />
        {/* Save section snippet modal */}
        {(showsSaveSectionSnippet || showsDuplicateModal) && (
          <ModalSaveSnippet
            onPrimaryAction={onSaveSectionSnippet}
            onSecondaryAction={onCloseModal}
            currentName={present.name}
            currentDescription={present.description}
          />
        )}

        {/* Edit Header */}
        <FieldSetSnippetHeader
          present={present}
          snippet={snippet}
          onSave={onValidateSectionSnippet}
          onClose={onClose}
          onRemove={onRemove}
          showSubmitError={Object.keys(sectionErrors).length !== 0}
        />

        <Selectable boundary={Boundary.Section} block={present.section} onKeyboard={onKeyboard}>
          {({ styles }) => (
            <div aria-label="Section" role="region" className="flex flex-row mt-2 gap-x-3">
              <div className="flex flex-row gap-x-3">
                <AddContentMenu
                  menuItems={getSectionAddContentItems({
                    hasSectionHeader: (present.section?.headers?.length || 0) > 0,
                    canAddSection: false,
                    canInsertSnippet: false,
                    allStepsAreExpanded: allStepsInSectionExpandedMap,
                    isSectionCollapsed: isCollapsedMap[present.section.id],
                    canDeleteSection: false,
                    canCopySection: true,
                    canCutSection: false,
                    canPasteSection: false,
                    canPasteStep: !!clipboardStep,
                    canSaveAsSnippet: false,
                    hasDependencies: false,
                  })}
                  onClick={(menuItem) => onContentMenuClick(menuItem, present.section)}
                />
              </div>

              {/* Edit Snippet Section */}
              <div className={`flex flex-col w-full ${styles}`}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <ProcedureContextProvider procedure={procedureShell} scrollTo={undefined}>
                    <FieldSetProcedureSection
                      section={present.section}
                      sectionIndex={0}
                      snippetsMap={{}} // Section snippets do not have any step snippet.
                      onFieldRefChanged={() => undefined}
                      onStepFormChanged={onStepChanged}
                      onAddStepHeader={(stepIndex) => onAddStepHeader(stepIndex)}
                      onRemoveStepHeader={(stepIndex) => onRemoveStepHeader(stepIndex)}
                      onSectionHeaderFormChanged={onSectionHeaderFormChanged}
                      onSectionFormChanged={onSectionChanged}
                      onAddStep={onAddStep}
                      onAddSection={undefined}
                      errors={sectionErrors}
                      onRemoveStep={onRemoveStep}
                      configurePartKitBlock={() => undefined}
                      configurePartBuildBlock={() => undefined}
                      onRemoveSectionHeader={() => onRemoveSectionHeader(0)}
                      onCollapse={setIsCollapsed}
                      isCollapsedMap={isCollapsedMap}
                      isSaveStepSnippetDisabled={true}
                      enabledContentTypes={[
                        ProcedureContentBlockTypes.Alert,
                        ProcedureContentBlockTypes.Text,
                        ProcedureContentBlockTypes.Attachment,
                        ProcedureContentBlockTypes.FieldInput,
                        ProcedureContentBlockTypes.TableInput,
                        ProcedureContentBlockTypes.ProcedureLink,
                        ProcedureContentBlockTypes.Commanding,
                        ProcedureContentBlockTypes.Telemetry,
                        ProcedureContentBlockTypes.Requirement,
                        ProcedureContentBlockTypes.ExternalItem,
                        ProcedureContentBlockTypes.FieldInputTable,
                        ...(testingModule ? [ProcedureContentBlockTypes.TestCases] : []),
                      ]}
                      onInsertStepSnippet={undefined}
                      onSaveStepSnippet={undefined}
                      comments={undefined}
                      onResolveComment={undefined}
                      onUnresolveComment={undefined}
                      onAcceptRedlineField={undefined}
                      onRejectRedlineField={undefined}
                      onAcceptRedlineBlock={undefined}
                      onRejectRedlineBlock={undefined}
                      onAcceptRedlineStep={undefined}
                      onRejectRedlineStep={undefined}
                      stepRedlineMap={undefined}
                      changedStepIdSet={undefined}
                      setChangedStepIdSet={undefined}
                      isRestrictedToSnippetContents={true}
                      areDependenciesEnabled={false}
                      onSaveReviewComment={undefined}
                    />
                  </ProcedureContextProvider>
                </DragDropContext>
              </div>
            </div>
          )}
        </Selectable>
      </SelectionContextProvider>
      <FlashMessage message={flashMessage} messageUpdater={setFlashMessage} />
    </>
  );
};

export default FieldSetSectionSnippet;

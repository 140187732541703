import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Route, Redirect, useParams, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import LoadingScreen from '../components/LoadingScreen';
import { DatabaseServices } from '../contexts/proceduresSlice';
import { getPendingProcedureIndex } from 'shared/lib/procedureUtil';

interface ProjectPermissionsRouteProps {
  permission: string;
  children: ReactNode;
  [rest: string]: unknown;
}

const ProjectPermissionsRoute = ({ permission, children, ...rest }: ProjectPermissionsRouteProps) => {
  /**
   * Wraps components to be rendered by the AppRoute in App.js to also check for project permissions.
   * This route can only be used to check for project permissions on /procedures/<procedure_id>
   * and /projects/<project_id> routes.
   */
  const { id } = useParams<{ id: string }>();
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { auth } = useAuth();
  const location = useLocation();
  const { currentTeamId } = useDatabaseServices();
  const [editState, setEditState] = useState<{ canEdit: boolean; isLoading: boolean }>({
    canEdit: false,
    isLoading: true,
  });

  useEffect(() => {
    // procedure import goes to the proto blocks page first so there is no procedure yet, skip to the else block below in that case
    if (location.pathname.includes('procedures') && !location.pathname.includes('import')) {
      services.procedures
        .getProcedure(id)
        .then((procedure) => {
          const canEdit = auth.hasPermission(permission, procedure.project_id);

          setEditState({
            canEdit,
            isLoading: false,
          });
        })
        .catch(() => {
          const draftIndex = getPendingProcedureIndex(id);
          services.procedures
            .getProcedure(draftIndex)
            .then((procedure) => {
              const canEdit = auth.hasPermission(permission, procedure.project_id);

              setEditState({
                canEdit,
                isLoading: false,
              });
            })
            .catch(() => {
              setEditState({
                canEdit: false,
                isLoading: false,
              });
            });
        });
    } else {
      const canEdit = auth.hasPermission(permission, id) || auth.hasProjectsWithEditPermission();

      setEditState({
        canEdit,
        isLoading: false,
      });
    }
  }, [id, location.pathname, permission, services.procedures, services.settings, currentTeamId, auth]);

  const render = useCallback(() => {
    /*
     * Show the component only when the user has workspace or project permissions
     * Otherwise, redirect the user to / page
     */
    if (editState.canEdit) {
      return <div className="flex flex-col flex-grow">{children}</div>;
    } else {
      return <Redirect to="/" />;
    }
  }, [children, editState.canEdit]);

  if (editState.isLoading) {
    return <LoadingScreen />;
  }

  return <Route {...rest} render={render} />;
};

export default ProjectPermissionsRoute;

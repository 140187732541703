import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as UpDown } from '../../images/up-down-dotted.svg';

interface PlaceholderBlockProps {
  onClick?: () => void;
}

const PlaceholderBlock = ({ onClick }: PlaceholderBlockProps) => {
  return (
    <div
      className="relative h-3.5 w-full flex flex-row gap-x-2 items-center cursor-pointer"
      onClick={onClick ?? (() => null)}
    >
      <div className="absolute -left-1 -translate-x-full">
        <UpDown className="w-4" />
      </div>
      <div className="w-2/6 h-full bg-gray-100 rounded-md" />
      <div className="w-1/6 h-full bg-gray-100 rounded-md" />
      <div className="w-2/6 h-full bg-gray-100 rounded-md" />
      <FontAwesomeIcon icon={faEllipsis} className="self-end text-gray-400" />
    </div>
  );
};

export default PlaceholderBlock;

import React, { useMemo } from 'react';

interface Props {
  align?: 'left' | 'center' | 'right';
  verticalAlign?: 'top' | 'middle' | 'bottom';
  children?: React.ReactNode;
}

/**
 * This will wrap any children elements in a table-cell wrapper to be used in various row components.
 * @param {String} align - one of 'right, left or center'. See table cell align property.
 * @param {String} verticalAlign - one of "top", "middle", or "bottom"
 */
const SettingsRowCell = ({ align, verticalAlign, children }: Props) => {
  const alignClass = useMemo(() => {
    if (align === 'center') {
      return 'text-center';
    }
    if (align === 'right') {
      return 'text-right';
    }
    return 'text-left'; // default
  }, [align]);

  const verticalAlignClass = useMemo(() => {
    if (verticalAlign === 'middle') {
      return 'align-middle';
    }
    if (verticalAlign === 'bottom') {
      return 'align-bottom';
    }
    return 'align-top'; // default
  }, [verticalAlign]);

  return (
    <div className={`table-cell ${alignClass} ${verticalAlignClass}`}>
      <div className="m-1">{children}</div>
    </div>
  );
};

export default React.memo(SettingsRowCell);

import React, { useMemo } from 'react';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import ModifiedDiffContainer from './ModifiedDiffContainer';
import RemovedDiffContainer from './RemovedDiffContainer';
import AddedDiffContainer from './AddedDiffContainer';
import { DiffArrayChangeSymbol } from 'shared/lib/types/views/procedures';

export interface DiffContainerProps {
  diffChangeState?: DiffArrayChangeSymbol;
  label: string;
  children: React.ReactNode;
  width?: 'full' | 'fit';
  onScrollToDiffRefChanged?: (HTMLElement) => void;
  showModified?: boolean;
  isPlural?: boolean;
  isTextSticky?: boolean;
}
const DiffContainer = ({
  diffChangeState,
  label,
  children,
  width,
  onScrollToDiffRefChanged,
  showModified = true,
  isPlural = false,
  isTextSticky,
}: DiffContainerProps) => {
  const innerDiffContainerMap = useMemo(() => {
    return {
      [ARRAY_CHANGE_SYMBOLS.ADDED]: AddedDiffContainer,
      [ARRAY_CHANGE_SYMBOLS.REMOVED]: RemovedDiffContainer,
      [ARRAY_CHANGE_SYMBOLS.MODIFIED]: ModifiedDiffContainer,
    };
  }, []);

  const wasUpdated = useMemo(
    () => diffChangeState && diffChangeState !== ARRAY_CHANGE_SYMBOLS.UNCHANGED,
    [diffChangeState]
  );
  const isEnabled = useMemo(
    () => (diffChangeState === ARRAY_CHANGE_SYMBOLS.MODIFIED ? showModified : true),
    [diffChangeState, showModified]
  );

  const showDiffContainer = useMemo(() => wasUpdated && isEnabled, [isEnabled, wasUpdated]);

  const InnerDiffContainer = useMemo(
    () => diffChangeState && innerDiffContainerMap[diffChangeState],
    [diffChangeState, innerDiffContainerMap]
  );

  return (
    <>
      {showDiffContainer && (
        <InnerDiffContainer
          label={label}
          width={width}
          onScrollToDiffRefChanged={onScrollToDiffRefChanged}
          isPlural={isPlural}
          isTextSticky={isTextSticky}
        >
          {children}
        </InnerDiffContainer>
      )}
      {!showDiffContainer && children}
    </>
  );
};

export default DiffContainer;

import React, { useState } from 'react';
import Button, { BUTTON_TYPES } from '../../components/Button';
import Papa, { ParseResult } from 'papaparse';

import { useMixpanel } from '../../contexts/MixpanelContext';
import tableInputUtil from '../../lib/tableInputUtil';
import UploadCsvModal from '../UploadCsvModal';

const ImportTableButton = ({ onUpload }) => {
  const { mixpanel } = useMixpanel();
  const [showImportModal, setShowImportModal] = useState(false);
  const [importError, setImportError] = useState('');
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const onFileUploadInputChange = (file) => {
    setIsUploading(true);

    if (mixpanel) {
      mixpanel.track('[Import Table] [Edit] File Uploaded', {
        file_type: file.type !== '' ? file.type : file.name.slice(-4),
        file_size: file.size,
      });
    }

    const complete = (results: ParseResult) => {
      const tableInputContent = tableInputUtil.transformImportedDataToTableInput(results);
      if (tableInputContent.rows * tableInputContent.columns.length > 20000) {
        setImportError('Number of cells greater than 20,000');
        setIsUploading(false);
      } else if (tableInputContent.rows === 0 && tableInputContent.columns.length === 0) {
        setImportError('Empty csv file');
        setIsUploading(false);
      } else if (tableInputContent.columns.length > 10) {
        setImportError('Number of columns greater than 10');
        setIsUploading(false);
      } else {
        onUpload(tableInputContent);
        setShowImportModal(false);
        setIsUploading(false);
      }
    };

    const handleError = (error) => {
      setImportError('An error occurred during parsing');
      setIsUploading(false);
    };

    const options = {
      header: true,
      transformHeader: (header) => header.trim(),
      dynamicTyping: false,
      complete,
      error: handleError,
      skipEmptyLines: true,
    };

    Papa.parse(file, options);
  };

  const example = {
    header: ['Col 1', 'Col 2', 'C', 'Part #', 'Location ID'],
    data: [
      ['C-1', 'B', '50', 'A001', 'S1B1'],
      ['C-1', 'B', '50', 'A002', 'S1B2'],
      ['C-1', 'B', '50', 'B001', ''],
      ['G-1', 'X', '100', '', ''],
      ['A-1', 'A', '1', 'PA-01-001', 'W-800'],
      ['A-1', 'A', '1', 'PA-01-003', ''],
      ['A-1', 'A', '1', 'PA-01-004', ''],
    ],
  };

  return (
    <>
      <Button
        type={BUTTON_TYPES.TERTIARY}
        ariaLabel="Import Table"
        leadingIcon="upload"
        onClick={() => setShowImportModal(true)}
      >
        Import Table
      </Button>
      {showImportModal && (
        <UploadCsvModal
          modalSize="md"
          dataType="Table"
          example={example}
          footerText="Note: All existing table data will be replaced."
          descriptionText="More than 10 columns are not allowed."
          isUploading={isUploading}
          uploadErrorMessage={importError}
          onSubmit={onFileUploadInputChange}
          onCancel={() => {
            setImportError('');
            setShowImportModal(false);
          }}
        />
      )}
    </>
  );
};

export default ImportTableButton;

import React, { useCallback, useContext } from 'react';
import { useDatabaseServices } from './DatabaseContext';
import useRealtimeUpdates, { useRealtimeUpdatesProps } from '../hooks/useRealtimeUpdates';
import { AppNotificationDisplay } from 'shared/lib/types/notifications';
import { useUserInfo } from './UserContext';

interface NotificationContextProps {
  children: React.ReactNode;
}

interface NotificationState {
  notifications: Array<AppNotificationDisplay>;
}

const NotificationContext = React.createContext<undefined | NotificationState>(undefined);

const NotificationProvider = ({ children }: NotificationContextProps) => {
  const { services } = useDatabaseServices();
  const { userInfo } = useUserInfo();
  const userId = userInfo.session.user_id;

  const getInitialNotifications = useCallback(async () => {
    if (!services || !services.notifications) {
      return [];
    }
    return services.notifications.getAllNotifications();
  }, [services]);

  const realtimeData = useRealtimeUpdates<AppNotificationDisplay>({
    entityType: 'notifications',
    initialGetter: getInitialNotifications,
    entityId: userId,
  } as useRealtimeUpdatesProps);

  const notifications = realtimeData.realtimeData as Array<AppNotificationDisplay>;

  return <NotificationContext.Provider value={{ notifications }}>{children}</NotificationContext.Provider>;
};

const useNotifications = () => {
  const context = useContext(NotificationContext);

  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};

export { NotificationProvider, useNotifications };

import { useDrag, useDrop } from 'react-dnd';
import React, { useMemo } from 'react';
import { Row, RenderRowProps } from 'react-data-grid';

interface DraggableRowRendererProps<R, SR> extends RenderRowProps<R, SR> {
  onRowReorder: (sourceIndex: number, targetIndex: number) => void;
}

const DraggableRowRenderer = <R, SR>({
  rowIdx,
  isRowSelected,
  className,
  onRowReorder,
  ...props
}: DraggableRowRendererProps<R, SR>) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'ROW_DRAG',
    item: { index: rowIdx },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'ROW_DRAG',
    drop({ index }: { index: number }) {
      onRowReorder(index, rowIdx);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const dndClassName = useMemo(() => {
    if (isDragging) {
      return `${className} opacity-50`;
    }
    if (isOver) {
      return `${className} bg-gray-100`;
    }
    return className;
  }, [isDragging, isOver, className]);

  return (
    <Row
      ref={(ref) => {
        if (ref) {
          drag(ref.firstElementChild);
        }
        drop(ref);
      }}
      rowIdx={rowIdx}
      isRowSelected={isRowSelected}
      className={dndClassName}
      {...props}
    />
  );
};

export default DraggableRowRenderer;

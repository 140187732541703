import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionTab, AccordionTabChangeEvent } from 'primereact/accordion';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Draft, Section } from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import Tooltip from '../elements/Tooltip';
import useSidebar, { SIDEBAR_CONTENT } from '../hooks/useSidebar';
import { procedureViewPath } from '../lib/pathUtil';
import runUtil from '../lib/runUtil';
import stickyHeaderUtil from '../lib/stickyHeaderUtil';
import useProjectFilterHelper from '../testing/hooks/useProjectFilterHelper';
import Label from './Label';
import ProcedureFlowChart from './StepConditionals/ProcedureFlowChart';
import TOCSidebar from './TOCSidebar/TOCSidebar';
import { useRedlineModalContext } from '../contexts/RedlineModalContext';

interface EditSidebarProps {
  draft: Draft;
  activeSidebarTabs: Array<number>;
  updateActiveSidebarTabs: (event: AccordionTabChangeEvent) => void;
  onBeforeTOCNavigate: () => void;
  onProcedureChanged: (updated: Draft, draft: Draft) => void;
  onResolveComment: (commentId: string) => void;
  errors?: object;
  redlineMap?: object;
}

const ACCORDION_HEIGHT = 49;
const TITLE_HEIGHT = 45;
const STICKY_HEADER_HEIGHT = 40;
const PADDING_HEIGHT = 19;
const CLOSED_ACCORDION_HEIGHT = 35;

const getAvailableHeight = (detailsHeight: number | undefined) => {
  return (
    window.innerHeight -
    PADDING_HEIGHT -
    TITLE_HEIGHT -
    (detailsHeight || 0) -
    ACCORDION_HEIGHT -
    STICKY_HEADER_HEIGHT -
    CLOSED_ACCORDION_HEIGHT
  );
};

const EditSidebar = ({
  draft,
  activeSidebarTabs,
  updateActiveSidebarTabs,
  onBeforeTOCNavigate,
  errors,
  redlineMap,
}: EditSidebarProps) => {
  const { currentTeamId } = useDatabaseServices();
  const { getProjectName } = useProjectFilterHelper();
  const detailsAreaRef = useRef<HTMLDivElement>(null);
  const procedureId = useMemo(() => runUtil.getProcedureId(draft), [draft]);
  const [availableHeight, setAvailableHeight] = useState<number>(100);
  const { detachedRedlines, setShowRedlineModal } = useRedlineModalContext();

  const displaySections: Array<[Section, number]> = useMemo(() => {
    const display: Array<[Section, number]> = [];
    draft.sections.forEach((section, sectionIndex) => {
      display.push([section, sectionIndex]);
    });
    return display;
  }, [draft]);

  const hasDetachedRedlines = useMemo(() => detachedRedlines && detachedRedlines.length > 0, [detachedRedlines]);

  const onElementsResize = useCallback(() => {
    const available = getAvailableHeight(detailsAreaRef?.current?.clientHeight);
    setAvailableHeight(available);
  }, []);

  const { toggleView, sidebarContainerRef, sidebarContentView } = useSidebar({ onElementsResize });

  return (
    <div ref={sidebarContainerRef} className="overflow-y-auto overflow-x-hidden">
      <div className="text-lg mb-3">Edit Procedure</div>
      <Accordion onTabChange={updateActiveSidebarTabs} multiple={true} activeIndex={activeSidebarTabs}>
        <AccordionTab key="details" headerTemplate={<div className="py-3">Details</div>}>
          <div ref={detailsAreaRef}>
            <div className="flex flex-col ml-2 pb-2 gap-y-2 lg:w-64">
              {draft.version && (
                <div className="whitespace-nowrap text-sm">
                  <span className="font-medium mr-1">Version</span>
                  <Tooltip content="View Master Procedure">
                    <Link
                      to={procedureViewPath(currentTeamId, procedureId)}
                      className="text-blue-500 hover:brightness-75"
                    >
                      {draft.version}
                    </Link>
                  </Tooltip>
                </div>
              )}
              {draft.project_id && (
                <div className="whitespace-nowrap text-sm">
                  <span className="font-medium mr-1">Project</span>
                  <Label text={getProjectName(draft.project_id) || ''} color="bg-gray-200" />
                </div>
              )}
            </div>
          </div>
        </AccordionTab>

        <AccordionTab
          headerTemplate={
            <div className="w-full flex flex-row justify-between py-2">
              <div className="py-1">Contents</div>
              <div className="w-20 flex flex-row">
                <button
                  className={stickyHeaderUtil.getButtonClassNames(false, sidebarContentView === SIDEBAR_CONTENT.TOC)}
                  onClick={toggleView}
                >
                  <FontAwesomeIcon icon="list" />
                </button>
                <button
                  className={stickyHeaderUtil.getButtonClassNames(false, sidebarContentView === SIDEBAR_CONTENT.FLOW)}
                  onClick={toggleView}
                >
                  <FontAwesomeIcon icon="sitemap" />
                </button>
              </div>
            </div>
          }
        >
          {sidebarContentView === SIDEBAR_CONTENT.TOC && (
            <TOCSidebar
              onBeforeNavigate={onBeforeTOCNavigate}
              stickyMargin={10}
              displaySections={displaySections}
              height={availableHeight}
              run={draft}
              errors={errors}
              redlineMap={redlineMap}
              hasDetachedRedlines={hasDetachedRedlines}
              setShowRedlineModal={setShowRedlineModal}
            />
          )}
          {sidebarContentView === SIDEBAR_CONTENT.FLOW && (
            <ProcedureFlowChart procedure={draft} height={availableHeight} />
          )}
        </AccordionTab>
      </Accordion>
    </div>
  );
};
export default EditSidebar;

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AxiosResponse } from 'axios';
import { ErrorMessage, Field } from 'formik';
import React, { Fragment } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Tooltip from '../elements/Tooltip';
import Button, { BUTTON_TYPES } from './Button';

export type MenuContextActionType = 'label' | 'radioButtons' | 'fieldTextInput' | 'submitButtons';
export type MenuContextButtonType = 'primary' | 'secondary';

interface MenuContextBase {
  type: MenuContextActionType;
  className?: string;
  label?: string;
  path?: string;
}

export type MenuContextRadioButtonAction = MenuContextBase & {
  type: 'radioButtons';
  data: Array<{
    label: string;
    description?: string;
    value: string;
    disabled?: boolean;
    tooltip?: string;
  }>;
};

type MenuContextSubmitButtonAction = MenuContextBase & {
  type: 'submitButtons';
  data: Array<{
    onClick: () => void;
    label: string;
    buttonType: MenuContextButtonType;
    ariaLabel: string;
    disabled?: boolean;
  }>;
};

type MenuContextFieldTextInputAction = MenuContextBase & {
  type: 'fieldTextInput';
  data: {
    placeholder: string;
    onBlur?: () => void;
  };
};

export type MenuContextLabelAction = MenuContextBase & {
  type: 'label';
  data: {
    icon?: IconProp;
    title?: string;
    textColor?: string;
    onClick?: () => Promise<void | AxiosResponse> | void;
    disabled?: boolean;
  };
};

export type MenuContextAction =
  | MenuContextRadioButtonAction
  | MenuContextSubmitButtonAction
  | MenuContextFieldTextInputAction
  | MenuContextLabelAction;

export interface MenuContextActionProps<T extends MenuContextAction> {
  action: T;
  hasDividers?: boolean;
}

interface MenuContextProps {
  menuContextActions: Array<Array<MenuContextAction>>;
  className?: string;
  hasDividers?: boolean;
}

const setButtonDisabled = (disabled: boolean | undefined) => (disabled === undefined ? false : disabled);

const SubmitButtons = React.memo(({ action }: MenuContextActionProps<MenuContextSubmitButtonAction>) => (
  <Fragment>
    <ul className="flex flex-row items-center">
      {action.data?.map((submitButton, index) => (
        <li className="flex px-2 items-center text-xs" key={`submit-button-${index}`}>
          <Button
            type={submitButton.buttonType}
            ariaLabel={submitButton.ariaLabel}
            onClick={submitButton.onClick}
            isDisabled={setButtonDisabled(submitButton.disabled)}
          >
            {submitButton.label}
          </Button>
        </li>
      ))}
    </ul>
  </Fragment>
));

const RadioButtons = React.memo(({ action, hasDividers }: MenuContextActionProps<MenuContextRadioButtonAction>) => {
  return (
    <Fragment>
      {action?.label && <div className="px-2 py-1">{action?.label}</div>}
      <ul className={`${hasDividers ? 'divide-y' : ''}`}>
        {action.data?.map((radioButton, index) => (
          <li key={`radio-button-${index}`}>
            <Tooltip content={radioButton.tooltip ?? undefined} visible={!!radioButton.tooltip}>
              <label
                className={`flex w-full items-center pt-2 pb-1 px-2 text-sm cursor-pointer hover:bg-gray-100 ${
                  radioButton.disabled ? 'cursor-not-allowed opacity-50' : ''
                }`}
              >
                <Field
                  type="radio"
                  className="mx-1 cursor-pointer"
                  name={action.path}
                  value={radioButton.value}
                  disabled={radioButton.disabled}
                />
                <div className="flex flex-col justify-center">
                  {radioButton.label}
                  {radioButton.description && <div className="font-normal normal-case">{radioButton.description}</div>}
                </div>
              </label>
            </Tooltip>
          </li>
        ))}
      </ul>
      <ErrorMessage name="status" component="div" className="text-sm text-red-700 ml-3 pt-1" />
    </Fragment>
  );
});

const FieldTextInput = React.memo(({ action }: MenuContextActionProps<MenuContextFieldTextInputAction>) => (
  <Fragment>
    <span>{action.label}</span>
    <Field
      name={action.path}
      className="text-xs border-2 border-gray-400 font-medium"
      type="text"
      onBlur={action.data?.onBlur}
    >
      {({ field }) => (
        <TextareaAutosize
          className="text-sm border border-gray-400 focus:border-blue-600 rounded"
          placeholder={action.data?.placeholder}
          {...field}
        />
      )}
    </Field>
    <ErrorMessage name="comment" component="div" className="text-sm text-red-700 ml-1" />
  </Fragment>
));

/*
 * Renders a menu list with a white background.
 */
const MenuContext = ({ menuContextActions, className, hasDividers }: MenuContextProps) => (
  <div className={`flex flex-col rounded bg-white shadow-md text-sm font-medium z-50 ${className}`}>
    {menuContextActions.map((actionsGroup, actionsGroupIndex) => (
      <ul key={`action-group-${actionsGroupIndex}`} className={hasDividers ? 'divide-y' : ''}>
        {actionsGroup.map((action, actionIndex) => {
          const key = `action-${actionsGroupIndex}-${actionIndex}`;
          if (action.type === 'radioButtons') {
            return (
              <li key={key} onClick={(e) => e.stopPropagation()}>
                <RadioButtons action={action} hasDividers={hasDividers} />
              </li>
            );
          } else if (action.type === 'fieldTextInput') {
            return (
              <li key={key} className="flex flex-col p-2" onClick={(e) => e.stopPropagation()}>
                <FieldTextInput action={action} />
              </li>
            );
          } else if (action.type === 'submitButtons') {
            return (
              <li key={key} className="flex flex-col py-2" onClick={(e) => e.stopPropagation()}>
                <SubmitButtons action={action} />
              </li>
            );
          } else {
            const labelAction = action;
            return (
              <li
                key={key}
                className={`${labelAction.data?.disabled ? '' : 'cursor-pointer hover:bg-gray-100'}`}
                onClick={labelAction.data?.disabled ? undefined : labelAction.data?.onClick}
                title={labelAction.data?.title}
              >
                <Button
                  key={key}
                  type={BUTTON_TYPES.TERTIARY}
                  leadingIcon={labelAction.data?.icon}
                  iconTextColor={labelAction.data?.textColor ?? 'text-gray-700'}
                  ariaLabel={labelAction.label}
                  isDisabled={labelAction.data?.disabled}
                >
                  {labelAction.label}
                </Button>
              </li>
            );
          }
        })}
        {menuContextActions.length > 1 && actionsGroupIndex !== menuContextActions.length - 1 && (
          <li key={`separator-${actionsGroupIndex}`} className="w-full border-b-2 border-gray-300 my-1"></li>
        )}
      </ul>
    ))}
  </div>
);

export default React.memo(MenuContext);

import React, { useState } from 'react';
import { Redirect, Link, useParams, useHistory } from 'react-router-dom';
import superlogin from '../api/superlogin';
import { useMixpanel } from '../contexts/MixpanelContext';
import FormNewPassword from '../components/forms/FormNewPassword';

const ResetPassword = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const [globalErrors, setGlobalErrors] = useState<string | null>(null);
  const { mixpanel } = useMixpanel();

  const resetPassword = async (values, { setFieldError }) => {
    setGlobalErrors(null);
    const form = {
      token,
      ...values,
    };
    return superlogin
      .resetPassword(form)
      .then(() => {
        if (mixpanel) {
          mixpanel.track('Reset New Password');
        }
        history.push({
          pathname: '/login',
          state: { flash: 'Password changed. Please login.' },
        });
      })
      .catch((error) => {
        if (error && error.validationErrors) {
          if (error.validationErrors.password) {
            setFieldError('password', error.validationErrors.password.join(', '));
          }
          if (error.validationErrors.confirmPassword) {
            setFieldError('confirmPassword', error.validationErrors.confirmPassword.join(', '));
          }
        } else if (error && error.message) {
          setGlobalErrors(error.message);
        } else if (error && error.error) {
          setGlobalErrors(error.error);
        } else {
          setGlobalErrors(error);
        }
      });
  };

  // Redirect to home (or login)
  if (!token) {
    return <Redirect to="/" />;
  }

  return (
    <div className="container mx-auto px-12 py-4">
      <div className="items-start max-w-md mx-auto">
        <h1 className="mb-4">Choose new password</h1>
        <FormNewPassword
          submitLabel="Change Password"
          onSubmit={resetPassword}
          onFieldBlur={() => setGlobalErrors(null)}
          submitError={globalErrors}
        />
        <div className="flex my-3 justify-center">
          <Link to="/login" className="link">
            Return to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

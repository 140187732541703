import Button, { BUTTON_TYPES } from '../Button';
import ModalBase from '../ModalBase';
import ModalActionBar from '../ModalActionBar';
import ModalContent from '../ModalContent';

interface ModalSaveSnippetUnresolvedActionsProps {
  onSecondaryAction: () => void;
}

const ModalSaveSnippetUnresolvedActions = ({ onSecondaryAction }: ModalSaveSnippetUnresolvedActionsProps) => (
  <ModalBase>
    <ModalContent title="Snippet cannot be saved.">
      <div className="w-full">Please address any unresolved actions before saving. </div>
    </ModalContent>
    <ModalActionBar>
      <div className="flex gap-x-2">
        <Button
          type={BUTTON_TYPES.PRIMARY}
          ariaLabel="unresolvedactions"
          isDisabled={false}
          onClick={onSecondaryAction}
        >
          Proceed
        </Button>
      </div>
    </ModalActionBar>
  </ModalBase>
);

export default ModalSaveSnippetUnresolvedActions;

import React, { ReactNode } from 'react';
import { MARKDOWN_MARGIN_CLASS } from './MarkdownView';

interface MarkdownOListWrapperProps {
  children: ReactNode;
}

const MarkdownOListWrapper = React.memo(({ children }: MarkdownOListWrapperProps) => {
  return <ol className={`list-decimal pl-8 ${MARKDOWN_MARGIN_CLASS}`}>{children}</ol>;
});

export default MarkdownOListWrapper;

import ProcedureDiffText from '../../ProcedureDiffText';

interface LabelDisplayProps {
  text: string;
}

const LabelDisplay = ({ text }: LabelDisplayProps) => {
  /* Using flex: 2 for the label and flex: 1 for the value, allows us to give priority to the label before wrapping. */
  return (
    <div style={{ flex: '2' }} className="flex self-top max-w-max">
      <div className="flex self-center">
        <ProcedureDiffText diffValue={text} useMarkdownWhenNoDiff={true} />
      </div>
    </div>
  );
};

export default LabelDisplay;

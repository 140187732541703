import React from 'react';
import { components } from 'react-select';

/**
 * This component is designed to be used in a react-select as a ClearIndicator component.
 * It will render an "x" with a passed in aria label
 */
const EditSelectClearIndicator = React.memo((props) => {
  return (
    <div aria-label="Clear Project Selection">
      <components.ClearIndicator {...props} />
    </div>
  );
});

export default EditSelectClearIndicator;

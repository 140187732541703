import { useEffect, useState } from 'react';
import { ComponentPart, Part, PartRevisionSummary } from 'shared/lib/types/postgres/manufacturing/types';
import Button, { BUTTON_TYPES } from '../../components/Button';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import PartAndRevisionIdSelect, { PartSelection } from './PartAndRevisionIdSelect';

type PartAndRevisionPusherProps = {
  onPush: (part: Part) => void;
  pushBtnLabel?: string;
  isDisabled?: boolean;
  clearOnPush?: boolean;
  shouldDisablePartSelect?: (part: Part | ComponentPart) => boolean;
  initialSelection?: PartSelection;
};

const PartAndRevisionPusher = ({
  pushBtnLabel = 'Add Part',
  onPush,
  isDisabled = false,
  clearOnPush = true,
  shouldDisablePartSelect = undefined,
  initialSelection,
}: PartAndRevisionPusherProps) => {
  const [selected, setSelected] = useState<PartSelection | null>(null);
  const { services } = useDatabaseServices();

  useEffect(() => initialSelection && setSelected(initialSelection), [initialSelection]);

  const handlePushPart = () => {
    if (!selected) {
      return;
    }

    const { part, partRevisionId } = selected;
    if (partRevisionId === (part.revisions as PartRevisionSummary[])[0].id) {
      onPush(part);
    } else {
      const options = { revisionId: partRevisionId };
      services.manufacturing.getPart(part.id, options).then(onPush);
    }

    if (clearOnPush) {
      setSelected(null);
    }
  };

  return (
    <div className="flex flex-row items-end my-2 gap-x-2">
      <PartAndRevisionIdSelect
        selected={selected}
        onSelect={setSelected}
        isDisabled={isDisabled}
        shouldDisablePartSelect={shouldDisablePartSelect}
      />
      <div className="h-10 pt-[0.1rem]">
        <Button type={BUTTON_TYPES.PRIMARY} isDisabled={isDisabled || !selected} onClick={handlePushPart}>
          {pushBtnLabel}
        </Button>
      </div>
    </div>
  );
};

export default PartAndRevisionPusher;

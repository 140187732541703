import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useMixpanel } from '../contexts/MixpanelContext';
import ProcedureFieldEdit from '../components/ProcedureFieldEdit';

/**
 * Higher-order component for adding the ability to redline to a component for
 * rendering a form field.
 *
 * @param {String} fieldName - Name of the field.
 * @param {String} fieldValue - Value of the field.
 * @param {Array} redlines - Array of RedlineField objects.
 * @param {String} placeholder - Text rendered when form field is empty.
 * @param {Boolean} showsRedlineAction - Hides redline edit form if false.
 * @param {Function} onDirtyChanged - Function called when the user has made any
 *                                    redline changes in the form.
 * @param {Function} onSubmitEdit - Function called when user submits redline edit.
 * @param {Function} validate - Function for validating given field during redlining.
 */
const withFieldRedlining =
  (Component) =>
  ({
    fieldName,
    fieldValue,
    redlines,
    placeholder,
    showsRedlineAction,
    onDirtyChanged,
    onSubmitEdit,
    validate,
    onLabelClick,
    isEditing,
    setIsEditing,
    isPadded = false,
    step,
    ...other
  }) => {
    const [showsEditForm, setShowsEditForm] = useState(false);
    const { mixpanel } = useMixpanel();
    const isMounted = useRef(true);

    const runOnly = useMemo(
      () =>
        step &&
        'run_only' in step &&
        /** @type {import('shared/lib/types/views/procedures').RunAddedStep} */ (step)?.run_only,
      [step]
    );

    const closeForm = useCallback(() => {
      setIsEditing && setIsEditing(false);
      setShowsEditForm(false);
    }, [setIsEditing]);

    const openForm = useCallback(() => {
      setIsEditing && setIsEditing(true);
      setShowsEditForm(true);
    }, [setIsEditing]);

    useEffect(
      () => () => {
        isMounted.current = false;
      },
      []
    );

    // Keep `isRedlineActive` prop in sync with internal state,
    useEffect(() => {
      /*
       * If `isEditMode` is false, also hide (cancel) the edit form.
       * If `isEditMode` is true, let `showsEditForm` control form behavior.
       */
      if (!showsRedlineAction) {
        closeForm();
      }
    }, [closeForm, showsRedlineAction]);

    const cancelFormHandler = useCallback(() => {
      if (mixpanel) {
        mixpanel.track('Redline Cancelled', { 'Field Name': fieldName });
      }
      closeForm();

      // Since form has closed, notify parent dirty has changed
      if (typeof onDirtyChanged === 'function') {
        onDirtyChanged(false, fieldName);
      }
    }, [mixpanel, closeForm, onDirtyChanged, fieldName]);

    const dirtyChangedHandler = useCallback(
      (dirty) => {
        if (typeof onDirtyChanged === 'function') {
          onDirtyChanged(dirty, fieldName);
        }
      },
      [onDirtyChanged, fieldName]
    );

    const saveFormHandler = useCallback(
      (values) => {
        if (typeof onSubmitEdit === 'function') {
          return onSubmitEdit(values).then(() => {
            if (!isMounted.current) {
              return;
            }
            closeForm();
          });
        }
      },
      [closeForm, onSubmitEdit]
    );

    /** @type {import('../components/Blocks/BlockTypes').Action} */
    const showEditFormAction = {
      icon: 'pencil-alt',
      onAction: openForm,
      ariaLabel: 'Edit Field',
    };

    const padding = isPadded ? 'mt-1.5' : '';

    return (
      <div className={showsRedlineAction && isEditing && showsEditForm ? 'w-full' : `min-w-0 ${padding}`}>
        {showsRedlineAction && isEditing && showsEditForm && (
          <ProcedureFieldEdit
            fieldName={fieldName}
            fieldValue={fieldValue}
            redlines={redlines}
            validate={validate}
            placeholder={placeholder}
            onDirtyChanged={dirtyChangedHandler}
            onCancel={cancelFormHandler}
            onSubmit={saveFormHandler}
            runOnly={runOnly}
          />
        )}
        {(!showsRedlineAction || !isEditing || !showsEditForm) && (
          <Component
            fieldName={fieldName}
            fieldValue={fieldValue}
            redlines={redlines}
            actions={showsRedlineAction && !isEditing ? [showEditFormAction] : []}
            onLabelClick={onLabelClick}
            {...other}
          />
        )}
      </div>
    );
  };

export default withFieldRedlining;

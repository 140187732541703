import { QueryResourceType, SearchResult } from 'shared/lib/types/search';
import { API_URL } from '../config';
import superlogin from './superlogin';

class SearchService {
  teamId: string;
  url: string;

  constructor(teamId: string | null) {
    if (!teamId) {
      throw new Error('Missing teamId');
    }
    this.teamId = teamId;
    this.url = `${API_URL}/teams/${this.teamId}/search`;
  }

  async getSearchResults(
    query: string,
    resourceType?: QueryResourceType
  ): Promise<Array<SearchResult>> {
    try {
      const url = `${this.url}${
        resourceType ? `?resource_type=${resourceType}` : ''
      }`;
      const response = await superlogin.getHttp().post(url, { query });
      return response.data.data;
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }
}

export default SearchService;

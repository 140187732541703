import { createContext, useContext } from 'react';
import { Draft } from 'shared/lib/types/views/procedures';

type ProcedureEditContextType = {
  mergeAllSectionErrors: (allSectionErrors: {
    [sectionId: string]: object;
  }) => void;
  onProcedureChanged: (updated: Draft, previous: Draft) => void;
};

const ProcedureEditContext = createContext<
  ProcedureEditContextType | undefined
>(undefined);

export const ProcedureEditProvider = ProcedureEditContext.Provider;

export const useProcedureEdit = (): ProcedureEditContextType => {
  const context = useContext(ProcedureEditContext);

  if (context === undefined) {
    return {
      mergeAllSectionErrors: () => {
        // no-op or default implementation
      },
      onProcedureChanged: () => {
        // no-op or default implementation
      },
    };
  }

  return context;
};

export default ProcedureEditContext;

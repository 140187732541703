import React from 'react';
import Modal from './Modal';
import PromptRedline from './PromptRedline';
import ProcedureStep from './ProcedureStep';
import ProcedureHeader from './ProcedureHeader';
import Comment from './Comment';
import { getRedlineFromDoc, isStepRedline, REDLINE_TYPE } from 'shared/lib/redlineUtil';
import { Redline } from 'shared/lib/types/views/redlines';
import revisions from '../lib/revisions';
import { getRedlineDocsSortedLatestToEarliest } from '../lib/redlineUtil';
import { RunHeaderRedline, RunStepFullRedline, RunStepRedline } from 'shared/lib/types/views/procedures';
import { getRedlineSourceStepPath, runViewPath } from '../lib/pathUtil';

interface DetachedRedlinesModalProps {
  show: boolean;
  onClose: () => void;
  detachedRedlines: Array<Redline>;
  currentTeamId: string;
  resolveDisconnectedStepRedline: (id: string) => void;
  resolveDisconnectedHeaderRedline: (id: string) => void;
  onResolveComment: (id: string) => void;
}

const DetachedRedlinesModal = ({
  show,
  onClose,
  detachedRedlines,
  currentTeamId,
  resolveDisconnectedStepRedline,
  resolveDisconnectedHeaderRedline,
  onResolveComment,
}: DetachedRedlinesModalProps) => {
  const sortedRedlines = getRedlineDocsSortedLatestToEarliest(detachedRedlines);

  return (
    <>
      {show && (
        <Modal title="Detached Redlines" primaryActionTitle="Close" onPrimaryAction={onClose} size="lg">
          <div className="flex flex-col space-y-4 w-full max-h-[80vh] overflow-y-auto">
            {!sortedRedlines?.length && <p>No more detached redlines</p>}
            {sortedRedlines?.map((redline) => {
              const runRedline = getRedlineFromDoc(redline);
              const userId = revisions.createdBy(runRedline) ?? '';
              const timestamp = revisions.createdAt(runRedline) ?? '';

              if (isStepRedline(redline)) {
                return (
                  <div key={redline._id} className="w-full">
                    <PromptRedline
                      allRedlineUserIds={[userId]}
                      timestamp={timestamp}
                      onReject={() => resolveDisconnectedStepRedline(redline._id)}
                      isLocationAmbiguous={true}
                      isDisconnected={true}
                      contextUrl={getRedlineSourceStepPath({
                        teamId: currentTeamId,
                        redline,
                      })}
                      comments={(runRedline as RunStepFullRedline).comments}
                    >
                      <ProcedureStep step={(runRedline as RunStepRedline).step} />
                    </PromptRedline>
                  </div>
                );
              } else if (redline.type === REDLINE_TYPE.HEADER_REDLINE) {
                return (
                  <div key={redline._id} className="w-full">
                    <PromptRedline
                      allRedlineUserIds={[userId]}
                      timestamp={timestamp}
                      onReject={() => resolveDisconnectedHeaderRedline(redline._id)}
                      isLocationAmbiguous={true}
                      isDisconnected={true}
                      contextUrl={runViewPath(currentTeamId, redline.run_id)}
                    >
                      <ProcedureHeader header={(runRedline as RunHeaderRedline).header} isCollapsed={false} />
                    </PromptRedline>
                  </div>
                );
              } else if (redline.type === REDLINE_TYPE.REDLINE_COMMENT) {
                return (
                  <div key={redline._id} className="w-full">
                    <PromptRedline
                      allRedlineUserIds={[userId]}
                      timestamp={timestamp}
                      isLocationAmbiguous={true}
                      isDisconnected={true}
                      contextUrl={getRedlineSourceStepPath({
                        teamId: currentTeamId,
                        redline,
                      })}
                    >
                      <Comment
                        comment={revisions.getCommentFromRedlineDoc(redline)}
                        onResolve={onResolveComment}
                        hideContextLink={true}
                        onFieldRefChanged={undefined}
                        onUnresolve={undefined}
                        editComment={undefined}
                        commentButton={undefined}
                        onRefChanged={undefined}
                        isChild={undefined}
                        onSetParentCollapsed={undefined}
                        parentCollapsed={undefined}
                        error={undefined}
                      />
                    </PromptRedline>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </Modal>
      )}
    </>
  );
};

export default DetachedRedlinesModal;

import DateTimeDisplay from './DateTimeDisplay';
import { useRunContext } from '../contexts/RunContext';
import TextLinkify from './TextLinkify';

export default function EndOfRunContent() {
  const { run, isRun, isEndOfRunContentVisible } = useRunContext();

  if (isRun && !isEndOfRunContentVisible()) {
    return null;
  }

  return (
    <>
      {/* End of run status. */}
      {run.status && (
        <div className="mt-4 app-bg-blue-note pb-1 pt-1 px-2">
          <span className="whitespace-pre-line capitalize">Status: {run.status}</span>
        </div>
      )}
      {/* End of run comment. */}
      {run.comments &&
        run.comments
          .filter((comment) => comment.type !== 'review_comment')
          .map((comment, index) => (
            <div key={index} className="mt-4 app-bg-blue-note">
              <div className="pt-1 px-2 text-sm">
                {comment.user_id}, <DateTimeDisplay timestamp={comment.created_at} />
              </div>
              <div className="pb-1 pt-1 px-2">
                {comment.text && (
                  <TextLinkify>
                    <span className="whitespace-pre-line">{comment.text}</span>
                  </TextLinkify>
                )}
              </div>
            </div>
          ))}
    </>
  );
}

import React, { useCallback } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ContactSupportMessage from './ContactSupportMessage';
import { NewPasswordType, SetFieldErrorType } from './types';

const NEW_PASSWORD_SCHEMA = Yup.object({
  password: Yup.string().required('Required.'),
  confirmPassword: Yup.string()
    .required('Required.')
    .oneOf([Yup.ref('password'), null], 'Passwords must match.'),
});

interface FormNewPasswordProps {
  submitLabel: string;
  onSubmit: (values: NewPasswordType, { setFieldError }: SetFieldErrorType) => Promise<void>;
  onFieldBlur: () => void;
  submitError: string | null;
  showSupportLink?: boolean;
}

const FormNewPassword = ({
  submitLabel,
  onSubmit,
  onFieldBlur,
  submitError,
  showSupportLink,
}: FormNewPasswordProps) => {
  const submitHandler = useCallback(
    (values, actions) => {
      if (!onSubmit || typeof onSubmit !== 'function') {
        return;
      }
      return onSubmit(values, actions).finally(() => actions.setSubmitting(false));
    },
    [onSubmit]
  );

  const onBlurHandler = useCallback(
    (e, handleBlur) => {
      if (onFieldBlur || typeof onFieldBlur !== 'function') {
        onFieldBlur();
      }
      // Call original formik blur handler.
      handleBlur(e);
    },
    [onFieldBlur]
  );

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationSchema={NEW_PASSWORD_SCHEMA}
      onSubmit={submitHandler}
    >
      {({ errors, touched, isValid, isSubmitting, handleBlur }) => (
        <Form>
          <div className="flex flex-col">
            <label htmlFor="password" className="mt-2">
              New Password
            </label>
            <Field
              className="w-full border-1 border-gray-400 rounded"
              autoComplete="new-password"
              id="password"
              name="password"
              placeholder="New Password"
              type="password"
              onBlur={(e) => onBlurHandler(e, handleBlur)}
            />
            {touched.password && errors.password && <div className="text-red-700">{errors.password}</div>}

            <label htmlFor="confirmPassword" className="mt-2">
              Confirm Password
            </label>
            <Field
              className="w-full border-1 border-gray-400 rounded"
              autoComplete="new-password"
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Confirm Password"
              type="password"
              onBlur={(e) => onBlurHandler(e, handleBlur)}
            />
            {touched.confirmPassword && errors.confirmPassword && (
              <div className="text-red-700">{errors.confirmPassword}</div>
            )}

            {submitError && <div className="text-red-700">{submitError}</div>}
            {showSupportLink && <ContactSupportMessage />}
            <button className="btn mt-4 py-2.5 self-center" type="submit" disabled={isSubmitting || !isValid}>
              {submitLabel}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormNewPassword;

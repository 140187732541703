import { useMemo, useState } from 'react';
import { BatchStepProps, RunStep } from 'shared/lib/types/views/procedures';
import SummaryStep from '../RunSummary/SummaryStep';
import SummaryBatchStepHeader from './SummaryBatchStepHeader';
import { useProcedureFilter } from '../../contexts/ProcedureFilterContext';
import { BatchStepHeaderTabConfig, TabStatus } from './BatchStepHeaderTab';

type SummaryBatchStepProps = {
  steps: RunStep[];
  sectionStepKey: string;
  sectionId: string;
  onRefChanged: (id: string, element: HTMLElement) => void;
};

const getBatchProps = (step: RunStep): BatchStepProps => {
  return step.batchProps as BatchStepProps;
};

const getTabStatus = (step: RunStep): TabStatus => {
  if (step.state === 'failed') {
    return 'failed';
  }
  if (step.completed) {
    return 'complete';
  }
  if (step.skipped) {
    return 'skipped';
  }
  return 'pending';
};

const SummaryBatchStep = ({ steps, sectionStepKey, sectionId, onRefChanged }: SummaryBatchStepProps) => {
  const [selectedTab, setSelectedTab] = useState(getBatchProps(steps[0]).instanceId);
  const displayedStep = useMemo(() => {
    return steps.find((step) => getBatchProps(step).instanceId === selectedTab);
  }, [steps, selectedTab]);
  const { isStepFiltered } = useProcedureFilter();

  if (!isStepFiltered(steps[0].id)) {
    return null;
  }

  const tabs: BatchStepHeaderTabConfig[] = steps.map((step) => ({
    label: getBatchProps(step).instanceId,
    status: getTabStatus(step),
  }));
  const onSelectTab = (label: string) => setSelectedTab(label);

  return (
    <>
      <SummaryBatchStepHeader tabs={tabs} selectedTabLabel={selectedTab} onSelectTab={onSelectTab} />
      <SummaryStep
        repeatKey={undefined}
        sectionStepKey={sectionStepKey}
        sectionId={sectionId}
        step={displayedStep}
        onRefChanged={onRefChanged}
      />
    </>
  );
};

export default SummaryBatchStep;

import superlogin from '../../api/superlogin';
import { AxiosResponse } from 'axios';
import { API_URL } from '../../config';
import { Swimlane } from 'shared/schedule/types/event';

class SwimlaneService {
  private teamId: string;
  private restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    this.restUrl = `${API_URL}/teams/${this.teamId}/planning/swimlanes`;
  }

  async getSwimlanes(): Promise<Array<Swimlane>> {
    const response = await superlogin.getHttp().get(this.restUrl);
    return response.data.swimlanes;
  }

  async createSwimlane(swimlane: Omit<Swimlane, 'id'>): Promise<string> {
    const response = await superlogin
      .getHttp()
      .post(this.restUrl, { name: swimlane.name });
    return response.data.id;
  }

  async updateSwimlane(swimlane: Swimlane): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${swimlane.id}`;
    const response = await superlogin.getHttp().patch(url, {
      id: swimlane.id,
      name: swimlane.name,
    });
    return response;
  }

  async deleteSwimlane(id: string): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${id}`;
    const response = await superlogin.getHttp().delete(url);
    return response;
  }
}

export default SwimlaneService;

import {
  DraggableStateSnapshot,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';

const contentDragHandleVisibilityClasses = (
  droppableSnapshot: DroppableStateSnapshot,
  draggableSnapshot: DraggableStateSnapshot,
  isSelected: boolean,
  isHidden = false
): string => {
  if (isHidden) {
    return 'opacity-0';
  }

  // If content is selected, show all hover menu items.
  if (isSelected) {
    return 'opacity-100';
  }

  // Show drag handle if this content block is being dragged.
  if (draggableSnapshot.isDragging) {
    return 'opacity-100';
  }

  // Hide drag handle if another content block is being dragged.
  if (droppableSnapshot.isDraggingOver) {
    return 'opacity-0';
  }

  return 'opacity-0 group-hover/content:opacity-40';
};

export default contentDragHandleVisibilityClasses;

import { Run, RunSection } from './types/views/procedures';

const timingUtil = {
  _millisecondsToISO8601: (durationMs: number) => {
    if (isNaN(durationMs) || durationMs < 0) {
      return 'PT0H0M0S';
    }

    const totalSeconds = Math.floor(durationMs / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `PT${hours}H${minutes}M${seconds}S`;
  },

  _isoDurationToMillis: (isoDuration: string) => {
    const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
    const matches = isoDuration.match(regex);

    if (!matches) return 0;

    const hours = parseInt(matches[1]) || 0;
    const minutes = parseInt(matches[2]) || 0;
    const seconds = parseInt(matches[3]) || 0;

    const totalMillis =
      hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;

    return totalMillis;
  },

  _calculateRunPauseTimeInMs: (actions, startTime) => {
    let totalPauseDuration = 0;
    let currentPauseTimestamp: Date | null = null;
    const startTimestamp = new Date(startTime);

    actions?.forEach((entry) => {
      const entryTimestamp = new Date(entry.timestamp);
      if (entryTimestamp >= startTimestamp) {
        if (
          (entry.type === 'pause' || entry.type === 'issue pause') &&
          currentPauseTimestamp === null
        ) {
          currentPauseTimestamp = entryTimestamp;
        } else if (entry.type === 'resume' && currentPauseTimestamp !== null) {
          const resumeTimestamp = entryTimestamp;
          const duration =
            resumeTimestamp.getTime() - currentPauseTimestamp.getTime();
          totalPauseDuration += duration;
          currentPauseTimestamp = null;
        }
      }
    });

    // Handle ongoing pause if it started after the start time
    if (currentPauseTimestamp !== null) {
      const currentTime = new Date();
      const duration =
        currentTime.getTime() - (currentPauseTimestamp as Date).getTime();
      totalPauseDuration += duration;
    }

    return totalPauseDuration;
  },

  _updateSectionSteps: (
    run: Run,
    section: RunSection,
    stepId?: string,
    completedAtInMs?: string
  ) => {
    for (const step of section.steps) {
      if (stepId === undefined || step.id === stepId) {
        if (
          typeof step.duration === 'object' &&
          step.duration.started_at &&
          !step.duration.duration
        ) {
          const startTimeInMs = new Date(step.duration.started_at).getTime();
          const pauseDurationInMs = timingUtil._calculateRunPauseTimeInMs(
            run.actions,
            startTimeInMs
          );
          const currentTimeInMs = completedAtInMs
            ? new Date(completedAtInMs).getTime()
            : new Date().getTime();
          const adjustedTimeInMs =
            currentTimeInMs - startTimeInMs - pauseDurationInMs;

          step.duration.duration = timingUtil._millisecondsToISO8601(
            Math.max(0, adjustedTimeInMs)
          );
        }

        if (
          typeof step.timer === 'object' &&
          step.timer.started_at &&
          !step.timer.completed &&
          !step.timer.time_remaining
        ) {
          const startTime = new Date(step.timer.started_at).getTime();
          let currentTime = new Date().getTime();

          if (completedAtInMs) {
            const completedAtTime = new Date(completedAtInMs).getTime();
            if (completedAtTime > startTime) {
              currentTime = completedAtTime;
            }
          }

          const pauseDuration = timingUtil._calculateRunPauseTimeInMs(
            run.actions,
            startTime
          );
          const adjustedTime = currentTime - startTime - pauseDuration;
          const timeLeftDuration = timingUtil._isoDurationToMillis(
            step.timer.time_left
          );

          if (adjustedTime >= timeLeftDuration) {
            step.timer.completed = true;
            continue;
          }

          const remainingTime = timeLeftDuration - adjustedTime;
          step.timer.time_remaining = timingUtil._millisecondsToISO8601(
            Math.max(0, remainingTime)
          );
        }
      }
    }
  },

  updateRunWithDurations: (
    run: Run,
    sectionId?: string,
    stepId?: string,
    completedAt?: string
  ) => {
    if (sectionId !== undefined && sectionId !== null) {
      const section = run.sections.find((section) => section.id === sectionId);
      if (section) {
        timingUtil._updateSectionSteps(run, section, stepId, completedAt);
      }
    } else {
      for (const section of run.sections) {
        timingUtil._updateSectionSteps(run, section, stepId, completedAt);
      }
    }
  },
};

export default timingUtil;

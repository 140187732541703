import React, { useMemo } from 'react';
import CreatableSelect from 'react-select/creatable';

const EventSelect = ({ field, form, options, placeholder }) => {
  const handleChange = (newVal) => {
    // Happens on clear
    if (!newVal) {
      form.setFieldValue(field.name, null);
      return;
    }
    form.setFieldValue(field.name, newVal.value);
  };

  const value = useMemo(() => {
    return options.find((o) => o.value === field.value);
  }, [options, field]);

  return (
    <div className="flex flex-row items-center relative">
      <CreatableSelect
        value={value}
        aria-label={placeholder}
        placeholder={placeholder}
        classNamePrefix="react-select"
        className="w-96 max-w-prose text-sm border-1 border-gray-400 rounded"
        onChange={handleChange}
        options={options}
        isClearable={true}
      />
    </div>
  );
};

export default EventSelect;

const contains = (searchFields: Array<string>, searchTerm: string): boolean => {
  const combined = searchFields.join(' ').toLowerCase();
  const keywords = searchTerm.toLowerCase().split(/[\s]+/);
  for (const keyword of keywords) {
    if (!combined.includes(keyword)) {
      return false;
    }
  }
  return true;
};

export default contains;

import { FieldInputExternalDataBlock, RunFieldInputExternalDataBlock } from 'shared/lib/types/views/procedures';
import SelectDisplay from './SelectDisplay';
import externalDataUtil from '../../../lib/externalDataUtil';
import React from 'react';
import { Details } from '../FieldInputExternalSearch';

interface ExternalItemDisplayProps {
  block: FieldInputExternalDataBlock;
  referenceId?: string;
}

const ExternalItemDisplay = ({ block, referenceId }: ExternalItemDisplayProps) => {
  let selected;
  const blockRecordedValue = (block as RunFieldInputExternalDataBlock)?.recorded?.value;
  if (blockRecordedValue) {
    selected = { label: externalDataUtil.getItemLabel(blockRecordedValue) };
  }

  return (
    <div>
      <SelectDisplay block={block} optionSelectedRecorded={selected} referenceId={referenceId} />
      {/* Selection results table */}
      {blockRecordedValue?.details && (
        <div className="flex flex-row mt-2">
          <div className="border-l border-gray-300 border-1.5 mr-2" />
          <div className="flex flex-nowrap w-full ml-2">
            <Details details={blockRecordedValue?.details} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ExternalItemDisplay;

import { ReactElement } from 'react-markdown';
import { List } from 'react-virtualized';

interface VirtualizedListProps {
  children: Array<ReactElement> | ReactElement;
}

// TODO make the row and height parameterizable
const VirtualizedList = ({ children }: VirtualizedListProps) => {
  // when there are no options, children is a `NoOptionsMessage` element rather than an array of elements
  const rows = Array.isArray(children) ? children : [children];
  const rowRenderer = ({ key, index, style }) => (
    <div key={key} style={style}>
      {rows[index]}
    </div>
  );

  return (
    <List
      style={{ width: '100%' }}
      width={600}
      height={Math.min(rows.length * 38, 300)}
      rowHeight={38}
      rowCount={rows.length}
      rowRenderer={rowRenderer}
    />
  );
};

export default VirtualizedList;

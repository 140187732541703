const stepDependencyUtil = {
  getAllDependentIds: (dependencies = []) => {
    const allDependentIds = new Set();

    dependencies.forEach((dependency) => {
      dependency?.dependent_ids.forEach((dependentId) => {
        allDependentIds.add(dependentId);
      });
    });

    return allDependentIds;
  },
};

export default stepDependencyUtil;

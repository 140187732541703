import { useCallback, useMemo, useState } from 'react';
import { Signoff } from 'shared/lib/types/views/procedures';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useSettings } from '../contexts/SettingsContext';
import Button, { BUTTON_TYPES } from './Button';
import { Dialog } from 'primereact/dialog';
import { useUserInfo } from '../contexts/UserContext';
import { useRunContext } from '../contexts/RunContext';
import FieldError from '../elements/internal/FieldError';
import { User } from 'shared/lib/types/couch/settings';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import PopupListWithSearch from '../elements/PopupListWithSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BasicOption } from '../elements/ListWithSearch';

interface SelectProps {
  value: string;
  options: Array<BasicOption<undefined>>;
  onSelect: (option: BasicOption<undefined>) => Promise<void>;
  disableSearch?: boolean;
}

const Select = ({ value, options, onSelect, disableSearch }: SelectProps) => {
  return (
    <PopupListWithSearch
      Components={{
        Trigger: () => {
          return (
            <div className="w-[272px] text-left text-sm p-2 border border-gray-300 h-9 rounded hover:border-gray-600">
              {value}
              <div className="text-base absolute right-2 top-2">
                <FontAwesomeIcon icon="angle-down" className="text-gray-300" />
              </div>
            </div>
          );
        },
        ListItem: (option) => {
          return <div className="text-sm">{option.name}</div>;
        },
      }}
      options={options}
      onSelect={onSelect}
      disableSearch={disableSearch}
    />
  );
};

interface OnPinSignoffProps {
  operator: string;
  user: string;
  pin: string;
}

interface PinSignoffModalProps {
  isModalShown: boolean;
  signoff: Signoff;
  onPinSignoff: ({ operator, user, pin }: OnPinSignoffProps) => Promise<void>;
  onCloseModal: () => void;
}

const PinSignoffModal = ({ isModalShown, signoff, onPinSignoff, onCloseModal }: PinSignoffModalProps) => {
  const [globalError, setGlobalError] = useState('');
  const { users } = useSettings();
  const { userInfo } = useUserInfo();
  const { projectId } = useRunContext();
  const { currentTeamId } = useDatabaseServices();

  const { register, reset, handleSubmit, setValue, control, formState, watch } = useForm<OnPinSignoffProps>({
    defaultValues: {
      operator: signoff.operators.length === 1 ? signoff.operators[0] : '',
      user: '',
      pin: '',
    },
  });
  const { errors, isSubmitting } = formState;
  register('user', { required: 'required' });
  const operator = watch('operator');
  const user = watch('user');

  const operatorOptions = signoff.operators.map((operator) => ({
    name: operator,
  }));

  const clearGlobalError = () => setGlobalError('');

  const userCanSignoff = useCallback(
    (user: User, role: string): boolean => {
      const SIGNOFF_ACCESS_LEVELS = ['Admin', 'Operator', 'Editor'];
      if (!user.operator_roles.includes(role)) {
        return false;
      }
      const workspaceAccess = user.workspace_roles?.[currentTeamId] ?? [];
      if (SIGNOFF_ACCESS_LEVELS.some((level) => workspaceAccess.includes(level))) {
        return true;
      }
      if (projectId) {
        const projectAccess = user.project_roles?.[projectId] ?? [];
        if (SIGNOFF_ACCESS_LEVELS.some((level) => projectAccess.includes(level))) {
          return true;
        }
      }
      return false;
    },
    [currentTeamId, projectId]
  );

  const userOptions = useMemo(() => {
    if (!users || !users.users || !operator) {
      return [];
    }
    return Object.values(users.users)
      .filter((user) => user.email !== userInfo.session.user_id && userCanSignoff(user, operator))
      .map((user) => ({
        name: user.email as string,
      }));
  }, [users, operator, userInfo.session.user_id, userCanSignoff]);

  const onSubmit: SubmitHandler<OnPinSignoffProps> = useCallback(
    async (data) => {
      try {
        clearGlobalError();
        await onPinSignoff(data);
        onCloseModal();
        reset();
      } catch (error) {
        let msg = 'Failed to Sign Off';
        if (error.response?.status >= 400 && error.response?.status < 500) {
          msg = error.response.data?.message;
        }
        setGlobalError(msg);
      }
    },
    [reset, onCloseModal, onPinSignoff]
  );

  const onHide = useCallback(() => {
    onCloseModal();
    clearGlobalError();
    reset();
  }, [onCloseModal, reset]);

  return (
    <Dialog
      visible={isModalShown}
      header="Sign Off as User"
      onHide={onHide}
      draggable={false}
      resizable={false}
      closable={false}
      keepInViewport={false}
      className="w-80"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2 flex flex-col">
          <div className="field-title">Operator</div>
          {signoff.operators.length === 1 ? (
            <div className="w-full h-9 text-sm p-2 border border-gray-300 bg-gray-100 rounded">{operator}</div>
          ) : (
            <Controller
              name="operator"
              control={control}
              rules={{ required: 'required' }}
              render={({ field: { value } }) => (
                <Select
                  value={value}
                  options={operatorOptions}
                  onSelect={async (option) => {
                    if (option.name !== value) {
                      setValue('operator', option.name);
                      setValue('user', '');
                      setValue('pin', '');
                      clearGlobalError();
                    }
                  }}
                  disableSearch={true}
                />
              )}
            />
          )}
          {errors.operator?.message && <FieldError errorMessage={errors.operator.message} />}
        </div>
        <div className="mt-2 flex flex-col">
          <div className="field-title">User</div>
          {!operator || !userOptions.length ? (
            <div className="h-9 text-right p-2 border border-gray-300 bg-gray-100 rounded">
              <div className="text-base right-2 top-2">
                <FontAwesomeIcon icon="angle-down" className="text-gray-300" />
              </div>
            </div>
          ) : (
            <Controller
              name="user"
              control={control}
              render={({ field: { value } }) => (
                <Select
                  value={value}
                  options={userOptions}
                  onSelect={async (option) => {
                    if (option.name !== value) {
                      setValue('user', option.name);
                      setValue('pin', '');
                      clearGlobalError();
                    }
                  }}
                />
              )}
            />
          )}
          {errors.user?.message && <FieldError errorMessage={errors.user.message} />}
        </div>
        <div className="mt-2 flex flex-col">
          <div className="field-title">PIN</div>
          <input
            {...register('pin', {
              required: 'required',
              pattern: { value: /^\d{6}$/, message: 'Invalid PIN' },
            })}
            className="w-full text-sm border-1 border-gray-300 rounded disabled:bg-gray-100"
            type="password"
            inputMode="numeric"
            disabled={!user}
            autoComplete="one-time-code"
            onChange={clearGlobalError}
          />
          {errors.pin?.message && <FieldError errorMessage={errors.pin.message} />}
        </div>
        {globalError && <div className="mt-2 text-red-700">{globalError}</div>}
        <div className="mt-4 text-right space-x-2">
          <Button
            type={BUTTON_TYPES.SECONDARY}
            title="Cancel Signoff"
            ariaLabel="Cancel Signoff"
            isDisabled={isSubmitting}
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            title="Confirm Signoff"
            ariaLabel="Confirm Signoff"
            isDisabled={isSubmitting}
            buttonTypeAttribute="submit"
          >
            Sign Off
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default PinSignoffModal;

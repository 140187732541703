import { Beforeunload } from 'react-beforeunload';
import { Prompt } from 'react-router-dom';
import { Fragment } from 'react';

const MESSAGE = 'Are you sure you want to leave? Your changes will be lost.';

export default function PromptBeforeUnload({ shouldPrompt, message = MESSAGE }) {
  const onBeforeUnload = (event) => {
    if (shouldPrompt) {
      event.preventDefault();
      // Some browsers support showing this message, most browsers only show their own message.
      return message;
    }
  };

  return (
    <Fragment>
      <Beforeunload onBeforeunload={onBeforeUnload} />
      <Prompt when={shouldPrompt} message={message} />
    </Fragment>
  );
}

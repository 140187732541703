import { useCallback } from 'react';
import { useMixpanel } from '../../contexts/MixpanelContext';

const SCHEDULE_TRACKING_NAME = 'Schedule';

type ScheduleTrack = (name: string, properties?: object) => void;

const useScheduleTrack = (): ScheduleTrack => {
  const { mixpanel } = useMixpanel();

  const scheduleTrack = useCallback(
    (name: string, properties?: object) => {
      if (mixpanel) {
        mixpanel.track(name, {
          Module: SCHEDULE_TRACKING_NAME,
          ...properties,
        });
      }
    },
    [mixpanel]
  );

  return scheduleTrack;
};

export default useScheduleTrack;

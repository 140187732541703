import { useEffect, useMemo, useState } from 'react';
import { OperationId } from 'shared/lib/types/operations';
import { BatchStepProps, ReviewComment, RunIssue, RunState, RunStep } from 'shared/lib/types/views/procedures';
import { useRunContext } from '../../contexts/RunContext';
import ProcedureStep from '../ProcedureStep';
import { BatchStepHeaderTabConfig, TabStatus } from './BatchStepHeaderTab';
import ProcedureBatchStepHeader from './ProcedureBatchStepHeader';
import { RecordedBlocks } from '../../api/runs';

const TAB_TRANSITION_DELAY_MS = 500;

export type ProcedureBatchStepProps = {
  steps: RunStep[];
  runId: string;
  projectId: string;
  stepKey: string;
  sectionKey: string;
  sectionId: string;
  sourceName: string;
  docState?: RunState;
  operation?: OperationId;
  sectionIndex: number;
  sectionStepIndexMap: { [key: string]: number };
  skipStep: (sectionIndex: number, stepIndex: number, recorded) => void;
  signOffStep: (sectionIndex: number, stepIndex: number, signoffId, operator, recorded, operatorRoles) => void;
  pinSignOffStep: ({
    signoffId,
    operator,
    pinUser,
    pin,
    recorded,
  }: {
    signoffId: string;
    operator: string;
    pinUser: string;
    pin: string;
    recorded: RecordedBlocks;
  }) => Promise<void>;
  revokeStepSignoff: (sectionId: string, stepId: string, signoffId, operatorRoles) => void;
  onStepComplete: (sectionId: string, stepId: string, recorded) => void;
  failStep: (sectionIndex: number, stepIndex: number, recorded) => void;
  onRefChanged: (id: string, element: HTMLElement) => void;
  startLinkedRun: (sectionIndex: number, stepIndex: number, contentIndex: number, linkedRunId: string) => void;
  recordValuesChanged: (stepId: string, contentId: string, recorded, fieldIndex: number) => void;
  saveNewComment: (comment: ReviewComment) => Promise<void> | null;
  editComment: (comment: ReviewComment) => Promise<void> | null;
  onResolveReviewComment: (commentId: string) => void;
  onUnresolveReviewComment: (commentId: string) => void;
  saveReviewComment: (comment: ReviewComment) => Promise<void>;
  comments?: ReviewComment[];
  showReviewComments: boolean;
  isHidden: boolean;
  isCollapsedMap: { [key: string]: boolean };
  isPreviousStepComplete: (stepIndex: number) => boolean;
  onStepCollapse: (id: string, collapse: boolean) => void;
  scrollToBufferRem: number;
  notifyRemainingStepOperators: (stepId: string) => Promise<void>;
  notificationListenerConnected: boolean;
  hasPreviousStep: (stepId: string) => boolean;
  isInSectionSnippet: boolean;
  isPreviewMode: boolean;
  onAddStepIssue: (issue: RunIssue, stepId: string, sectionId: string) => Promise<void>;
  areRedlineCommentsExpanded: (stepId: string) => boolean;
  expandRedlineComments: (stepId: string, areExpanded?: boolean) => void;
  isStrictSignoffEnabled?: boolean;
  onStepDetailChanged: (sectionId: string, stepId: string, field: string, value) => void;
};

const getBatchProps = (step: RunStep): BatchStepProps => {
  return step.batchProps as BatchStepProps;
};

const getTabStatus = (step: RunStep): TabStatus => {
  if (step.state === 'failed') {
    return 'failed';
  }
  if (step.completed) {
    return 'complete';
  }
  if (step.skipped) {
    return 'skipped';
  }
  return 'pending';
};

const isBatchVisible = (stepsInBatch: RunStep[], isStepVisible: (step: RunStep) => boolean): boolean => {
  for (const step of stepsInBatch) {
    if (isStepVisible(step)) {
      return true;
    }
  }
  return false;
};

const isPendingStep = (step?: RunStep): boolean => {
  if (!step) {
    return false;
  }
  return getTabStatus(step) === 'pending';
};

const findNextPendingStep = (steps: RunStep[], startAfter?: RunStep): RunStep => {
  const batchSize = steps.length;
  let startIndex = 0;
  if (startAfter) {
    for (let index = 0; index < batchSize; index++) {
      if (startAfter.id === steps[index].id) {
        startIndex = (index + 1) % batchSize;
      }
    }
  }

  const defaultStepToReturn = startAfter ?? steps[batchSize - 1];
  for (let index = startIndex; steps[index].id !== defaultStepToReturn.id; index = (index + 1) % batchSize) {
    const stepAtIndex = steps[index];
    if (isPendingStep(stepAtIndex)) {
      return stepAtIndex;
    }
  }
  return defaultStepToReturn;
};

const ProcedureBatchStep = ({
  steps,
  runId,
  projectId,
  stepKey,
  sectionKey,
  sectionId,
  sourceName,
  docState,
  operation,
  sectionIndex,
  sectionStepIndexMap,
  skipStep,
  signOffStep,
  pinSignOffStep,
  revokeStepSignoff,
  onStepComplete,
  failStep,
  onRefChanged,
  startLinkedRun,
  recordValuesChanged,
  saveNewComment,
  editComment,
  onResolveReviewComment,
  onUnresolveReviewComment,
  saveReviewComment,
  comments,
  showReviewComments,
  isHidden,
  isCollapsedMap,
  isPreviousStepComplete,
  onStepCollapse,
  scrollToBufferRem,
  notifyRemainingStepOperators,
  notificationListenerConnected,
  hasPreviousStep,
  isInSectionSnippet,
  isPreviewMode,
  onAddStepIssue,
  areRedlineCommentsExpanded,
  expandRedlineComments,
  isStrictSignoffEnabled,
  onStepDetailChanged,
}: ProcedureBatchStepProps) => {
  const { isRun, isStepVisible } = useRunContext();

  const [selectedTab, setSelectedTab] = useState(getBatchProps(findNextPendingStep(steps)).instanceId);
  const displayedStep = useMemo(() => {
    return steps.find((step) => getBatchProps(step).instanceId === selectedTab);
  }, [steps, selectedTab]);

  const tabs: BatchStepHeaderTabConfig[] = steps.map((step) => ({
    label: getBatchProps(step).instanceId,
    status: getTabStatus(step),
  }));
  const onSelectTab = (label: string) => setSelectedTab(label);
  const stepId = displayedStep?.id ?? '';
  const stepIndex = displayedStep ? sectionStepIndexMap[displayedStep.id] : -1;

  const displayedStepInitiallyPending = useMemo(() => {
    return isPendingStep(displayedStep);

    // only recalculate when tab changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedStep?.id]);

  useEffect(() => {
    if (!displayedStepInitiallyPending) {
      return;
    }

    if (!isPendingStep(displayedStep)) {
      const nextStep = findNextPendingStep(steps, displayedStep);
      const nextTab = getBatchProps(nextStep).instanceId;
      setTimeout(() => setSelectedTab(nextTab), TAB_TRANSITION_DELAY_MS);
    }
  }, [displayedStepInitiallyPending, displayedStep, steps, setSelectedTab]);

  if (isRun && !isBatchVisible(steps, isStepVisible)) {
    return null;
  }

  return (
    <>
      <ProcedureBatchStepHeader
        batchId={getBatchProps(steps[0]).batchId}
        onRefChanged={onRefChanged}
        tabs={tabs}
        selectedTabLabel={selectedTab}
        onSelectTab={onSelectTab}
      />
      <ProcedureStep
        runId={runId}
        projectId={projectId}
        step={displayedStep}
        stepKey={stepKey}
        sectionKey={sectionKey}
        sectionId={sectionId}
        sourceName={sourceName}
        docState={docState}
        operation={operation}
        isRepeatable={false}
        repeatKey={undefined}
        onRepeat={undefined}
        onSkip={(recorded) => skipStep(sectionIndex, stepIndex, recorded)}
        onSignOff={(signoffId, operator, recorded, operatorRoles) =>
          signOffStep(sectionIndex, stepIndex, signoffId, operator, recorded, operatorRoles)
        }
        onPinSignOff={pinSignOffStep}
        onRevokeSignoff={(signoffId, operatorRoles) => revokeStepSignoff(sectionId, stepId, signoffId, operatorRoles)}
        onComplete={(recorded) => onStepComplete(sectionId, stepId, recorded)}
        onFailStep={(recorded) => failStep(sectionIndex, stepIndex, recorded)}
        onRefChanged={onRefChanged}
        onStartLinkedRun={(contentIndex, linkedRun) => startLinkedRun(sectionIndex, stepIndex, contentIndex, linkedRun)}
        onRecordValuesChanged={recordValuesChanged}
        onAddStepBelow={undefined}
        isRedlineFeatureEnabled={false}
        onSaveRedlineBlock={undefined}
        onSaveRedlineStepField={undefined}
        onSaveRedlineStepComment={undefined}
        onAcceptPendingRedline={undefined}
        saveNewComment={saveNewComment}
        editComment={editComment}
        onResolveReviewComment={onResolveReviewComment}
        onUnresolveReviewComment={onUnresolveReviewComment}
        saveReviewComment={saveReviewComment}
        comments={comments}
        showReviewComments={showReviewComments}
        isHidden={isHidden}
        isCollapsed={isCollapsedMap[stepId]}
        isPreviousStepComplete={isPreviousStepComplete(stepIndex)}
        onStepCollapse={onStepCollapse}
        scrollToBufferRem={scrollToBufferRem}
        notifyRemainingStepOperators={notifyRemainingStepOperators}
        notificationListenerConnected={notificationListenerConnected}
        hasPreviousStep={hasPreviousStep(stepId)}
        isInSectionSnippet={isInSectionSnippet}
        isPreviewMode={isPreviewMode}
        onAddIssue={(issue: RunIssue) => onAddStepIssue(issue, stepId, sectionId)}
        areRedlineCommentsExpanded={areRedlineCommentsExpanded}
        expandRedlineComments={expandRedlineComments}
        isStrictSignoffEnabled={isStrictSignoffEnabled}
        onStepDetailChanged={onStepDetailChanged}
      />
    </>
  );
};

export default ProcedureBatchStep;

import { Item } from '../lib/inventoryUtil';

interface ItemOverlayProps {
  items?: Item[];
  filter?: string;
  onSelectItem: (item: Item) => void;
}

const ItemOverlay = ({ items, filter, onSelectItem }: ItemOverlayProps) => {
  if (!items || items.length === 0) {
    return null;
  }

  const availableItems = items
    .filter((item) => {
      if (!filter) {
        return true;
      }
      const trimmedLCFilter = filter.trim().toLocaleLowerCase();
      return item.part.tracking === 'serial'
        ? item.serial?.trim().toLocaleLowerCase().includes(trimmedLCFilter)
        : item.lot?.trim().toLocaleLowerCase().includes(trimmedLCFilter);
    })
    .sort((item1, item2) => {
      if (item1.part.tracking === 'serial') {
        if (!item1.serial || !item2.serial) {
          return 0;
        }
        return item1.serial.localeCompare(item2.serial);
      }
      if (!item1.lot || !item2.lot) {
        return 0;
      }
      return item1.lot.localeCompare(item2.lot);
    });

  if (availableItems.length === 0) {
    return null;
  }

  return (
    <div className="z-100 bg-white absolute border border-gray-300 flex flex-col w-full h-max rounded top-[42px] right-0">
      {availableItems.map((item) => {
        const tracking = item.part.tracking === 'serial' ? item.serial : item.lot;
        return (
          <div
            className="pt-2 pb-2 pl-2 cursor-pointer hover:bg-blue-100"
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => onSelectItem(item)}
          >
            {tracking}
          </div>
        );
      })}
    </div>
  );
};

export default ItemOverlay;

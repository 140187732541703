import React from 'react';

interface Props {
  header: React.ReactNode;
  children: React.ReactNode;
}

const SettingsTable = ({ header, children }: Props) => {
  /**
   * SettingsTable is made up of a bunch of divs, rather than the <table>
   * elements, in order to allow embedding forms into the individual rows
   * instead of having to wrap the entire table inside a form.
   */
  return (
    <div className="table border-collapse w-full table-fixed" role="table">
      <div className="sticky top-0 bg-app-gray-100 table-header-group drop-shadow-bottom z-10">{header}</div>
      <div className="table-row-group">{children}</div>
    </div>
  );
};

export default React.memo(SettingsTable);

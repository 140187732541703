import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStorageTrack from '../hooks/useStorageTrack';

type OpenPlotModalIconProps = {
  onClick: () => void;
};

const OpenPlotModalIcon = ({ onClick }: OpenPlotModalIconProps) => {
  const storageTrack = useStorageTrack();

  const handleClick = () => {
    storageTrack('Opened run plot modal');
    onClick();
  };

  return (
    <button
      className="flex w-8 h-8 bg-black opacity-60 rounded-full justify-center items-center hover:cursor-pointer"
      onClick={handleClick}
    >
      <FontAwesomeIcon className="text-white" icon="magnifying-glass-plus" aria-label="Show Run Plot Modal" size="lg" />
    </button>
  );
};

export default OpenPlotModalIcon;

import { Link } from 'react-router-dom';
import { Tool, ToolInstance } from 'shared/lib/types/api/manufacturing/tools/models';
import { toolInstanceDetailPath } from '../../../lib/pathUtil';
import StatusIcons from './StatusIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type CompletedToolInstanceProps = {
  teamId: string;
  tool: Tool;
  toolInstance: ToolInstance | undefined;
  type?: 'in' | 'out';
};

const CompletedToolInstance = ({ teamId, tool, toolInstance, type }: CompletedToolInstanceProps) => {
  if (toolInstance) {
    return (
      <>
        <div className="flex flex-row px-1 min-w-20 h-[2.4rem] pt-2 text-blue-600 border border-gray-400 bg-gray-200 rounded-[4px]">
          <Link to={toolInstanceDetailPath(teamId, tool.id, toolInstance.id)}>
            <StatusIcons tool={tool} toolInstance={toolInstance} showCheckedOutStatus={false} />
          </Link>
        </div>
        {type && (
          <div className="pl-2 pt-[10px]">
            <FontAwesomeIcon icon="square-check" className="text-green-500" aria-label={`Checked ${type}`} />
          </div>
        )}
      </>
    );
  }
  return <div className="h-[2.4rem] w-20 text-blue-600 border border-gray-400 bg-gray-200 rounded-[4px]" />;
};

export default CompletedToolInstance;

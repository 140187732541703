import { API_URL } from '../config';
import superlogin from './superlogin';
import { SourceTypeEnum } from 'shared/lib/types/attachments';
import { Procedure } from 'shared/lib/types/views/procedures';

export type GenerateProcedureQuery = {
  query: string;
  code: string;
  version: string;
  generation_version: number;
};

export type GenerateProcedureResponse = {
  doc: Procedure;
};

class MlService {
  private teamId: string;
  private restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    this.restUrl = `${API_URL}/teams/${this.teamId}/ml`;
  }

  async generateProcedure(
    generateProcedureQuery: GenerateProcedureQuery
  ): Promise<GenerateProcedureResponse> {
    const url = `${this.restUrl}/procedure-generation`;
    const body = {
      query: generateProcedureQuery.query,
      code: generateProcedureQuery.code,
      version: generateProcedureQuery.version,
      generation_version: generateProcedureQuery.generation_version,
    };
    const response = await superlogin.getHttp().post(url, body);
    return response.data;
  }

  async processProcedureAttachment(file: File): Promise<void> {
    const url = `${this.restUrl}/procedure-generation/process-attachment`;

    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('source', SourceTypeEnum.ProceduresGeneration);
    formData.append('file', file);

    await superlogin.getHttp().post(url, formData);
  }
}

export default MlService;

import _, { isEqual } from 'lodash';
import { useCallback } from 'react';
import { Draft, PartList } from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import apm from '../lib/apm';
import dictionaryUtil from '../lib/dictionaryUtil';
import printUtil from '../lib/printUtil';
import procedureUtil from '../lib/procedureUtil';
import useAutoProcedureId from './useAutoProcedureId';

interface FormHelpersProps {
  present: Draft;
  onProcedureChanged;
}

// TODO fill in these types
interface FormHelpers {
  onPartListChanged: (partList: PartList) => void;
  onProcedureDetailsFormChanged;
  onProcedureSettingsFormChanged;
  onHeaderFormChanged;
  onSectionHeaderFormChanged;
  onSectionFormChanged;
  onStepFormChanged;
  onAddProcedureVariable;
  onRemoveProcedureVariable;
  onVariablesChanged;
}

const useFormHelpers = ({
  present,
  onProcedureChanged,
}: FormHelpersProps): FormHelpers => {
  const { services } = useDatabaseServices();
  const { tryUpdateDocWithUniqueId } = useAutoProcedureId();

  const onPartListChanged = useCallback(
    (partList: PartList) => {
      const updated = _.cloneDeep(present);
      if (!updated.part_list) {
        return;
      }

      updated.part_list = partList;

      onProcedureChanged(updated, present);
    },
    [onProcedureChanged, present]
  );

  const onProcedureDetailsFormChanged = useCallback(
    (values) => {
      if (!present) {
        return;
      }
      let updated = _.cloneDeep(present);
      updated = {
        ...updated,
        ...values,
      };

      if (isEqual(present, updated)) {
        return;
      }
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const onProcedureSettingsFormChanged = useCallback(
    async (values) => {
      if (!present) {
        return;
      }
      let updated = _.cloneDeep(present);
      updated = {
        ...updated,
        ...values,
      };

      // if the dictionary changes to a new dictionary, NOT a blank one
      if (
        values.dictionary_id &&
        values.dictionary_id !== present.dictionary_id
      ) {
        // fetch the telemetries and commands for the new dictionary ID
        Promise.all([
          services.telemetry.searchParameters(
            '',
            values.dictionary_id ? [values.dictionary_id] : undefined
          ),
          services.commanding.searchCommands(
            '',
            values.dictionary_id ? [values.dictionary_id] : undefined
          ),
        ])
          .then(([telemetries, commands]) => {
            // update the procedure with new telemetry and commands
            dictionaryUtil.updateProcedureOnDictionaryChange(
              updated,
              values.dictionary_id,
              telemetries,
              commands
            );
            onProcedureChanged(updated, present);
            return;
          })
          .catch((err) => apm.captureError(err));
        return;
      }

      await tryUpdateDocWithUniqueId(updated);

      if (isEqual(present, updated)) {
        return;
      }
      onProcedureChanged(updated, present);
    },
    [
      present,
      tryUpdateDocWithUniqueId,
      onProcedureChanged,
      services.telemetry,
      services.commanding,
    ]
  );

  const onHeaderFormChanged = useCallback(
    (values, headerIndex) => {
      if (!present) {
        return;
      }
      const updated = _.cloneDeep(present);
      const header = present.headers?.[headerIndex];
      if (updated.headers) {
        updated.headers[headerIndex] = {
          ...header,
          ...values,
        };
      }
      if (isEqual(present, updated)) {
        return;
      }
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const onSectionHeaderFormChanged = useCallback(
    (values, sectionIndex, headerIndex) => {
      if (!present) {
        return;
      }
      const updated = _.cloneDeep(present);
      const header = present.sections[sectionIndex].headers?.[headerIndex];
      const updatedHeaders = updated.sections[sectionIndex].headers;
      if (updatedHeaders) {
        updatedHeaders[headerIndex] = {
          ...header,
          ...values,
        };
      }
      if (isEqual(present, updated)) {
        return;
      }
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const onSectionFormChanged = useCallback(
    (values, sectionIndex) => {
      if (!present) {
        return;
      }
      const updated = _.cloneDeep(present);
      const section = present.sections[sectionIndex];
      updated.sections[sectionIndex] = {
        id: section.id, // don't copy everything here because snippet properties can be removed
        steps: section.steps,
        ...(section.headers && { headers: section.headers }),
        ...values,
      };
      if (isEqual(present, updated)) {
        return;
      }
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const onStepFormChanged = useCallback(
    (values, sectionIndex, stepIndex) => {
      if (!present) {
        return;
      }
      const updated = _.cloneDeep(present);
      const step = updated.sections[sectionIndex].steps[stepIndex];
      updated.sections[sectionIndex].steps[stepIndex] = {
        id: step.id, // don't copy everything here because step details can be removed
        ...values,
      };
      if (isEqual(present, updated)) {
        return;
      }
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const onAddProcedureVariable = useCallback(() => {
    const updated = _.cloneDeep(present);
    if (!updated.variables) {
      updated.variables = [];
    }
    updated.variables.push(procedureUtil.newProcedureVariable());
    onProcedureChanged(updated, present);
  }, [present, onProcedureChanged]);

  const onRemoveProcedureVariable = useCallback(
    (index) => {
      const updated = _.cloneDeep(present);
      if (!updated.variables) {
        updated.variables = [];
      }
      if (index >= 0 && index < updated.variables.length) {
        updated.variables.splice(index, 1);
      }
      printUtil.updateVariableReferences(updated);
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  const onVariablesChanged = useCallback(
    (values) => {
      if (isEqual(present.variables, values.variables)) {
        return;
      }
      const updated = {
        ..._.cloneDeep(present),
        variables: values.variables,
      };
      printUtil.updateVariableReferences(updated);
      onProcedureChanged(updated, present);
    },
    [present, onProcedureChanged]
  );

  return {
    onPartListChanged,
    onProcedureDetailsFormChanged,
    onProcedureSettingsFormChanged,
    onHeaderFormChanged,
    onSectionHeaderFormChanged,
    onSectionFormChanged,
    onStepFormChanged,
    onAddProcedureVariable,
    onRemoveProcedureVariable,
    onVariablesChanged,
  };
};

export default useFormHelpers;

import React, { useCallback, useMemo } from 'react';

import { Tag as TagType } from '../types';
import TagsDisplay from './TagsDisplay';
import PopupListWithSearch from '../../elements/PopupListWithSearch';
import Tag from '../../components/Tag';
import Button, { BUTTON_TYPES } from '../../components/Button';

type TagsProps = {
  tags: Array<TagType>;
  selectedTags: Array<TagType>;
  onSelectTag: (tagId: string) => Promise<void>;
  onCreateTag: (tagName: string) => Promise<void>;
  onRemoveTag: (tag: TagType) => Promise<void>;
  disabled?: boolean;
  permanentTags?: Array<TagType>;
};

const Tags = ({
  tags,
  selectedTags,
  onSelectTag,
  onCreateTag,
  onRemoveTag,
  permanentTags = [],
  disabled = false,
}: TagsProps) => {
  const nonSelectedTags = useMemo(() => {
    const selectedTagsSet = new Set(selectedTags.map((tag) => tag.id));

    return tags.filter((tag) => {
      return !selectedTagsSet.has(tag.id);
    });
  }, [selectedTags, tags]);

  const disallowedTerms = useMemo(() => {
    const selectedAndPermananentTags = selectedTags.concat(permanentTags);

    return selectedAndPermananentTags.map((tag) => tag.name);
  }, [selectedTags, permanentTags]);

  const _onSelect = useCallback(
    (option) => {
      return onSelectTag(option.id);
    },
    [onSelectTag]
  );

  return (
    <div className="flex flex-row flex-wrap gap-y-1 items-center min-h-[3rem]">
      {permanentTags && <TagsDisplay tags={permanentTags} onRemoveTag={() => Promise.resolve()} disabled={true} />}
      <TagsDisplay tags={selectedTags} onRemoveTag={onRemoveTag} disabled={disabled} />
      {!disabled && (
        <PopupListWithSearch
          Components={{
            Trigger: () => <AddTagButton />,
            ListItem: (option) => <Tag id={option.name} name={option.name} />,
          }}
          options={nonSelectedTags}
          onCreate={onCreateTag}
          disallowedTerms={disallowedTerms}
          onSelect={_onSelect}
          pt={{
            Trigger: {
              'aria-label': 'Add Tag',
            },
          }}
          maxItemLength={100}
        />
      )}
    </div>
  );
};

const AddTagButton = () => {
  return (
    <Button type={BUTTON_TYPES.TERTIARY} leadingIcon="plus-circle">
      Add Tag
    </Button>
  );
};

export default Tags;

import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMixpanel } from '../contexts/MixpanelContext';
import { FEATURE_RUN_STEP_EDITS_ENABLED, FLASH_MSG_MS } from '../config';
import revisionsUtil from '../lib/revisions';
import BlockAttachment from './Blocks/BlockAttachment';
import BlockTelemetry from './Blocks/BlockTelemetry';
import BlockCommanding from './Blocks/BlockCommanding';
import BlockProcedureLink from './Blocks/BlockProcedureLink';
import RunTableInput from './TableInput/RunTableInput';
import StepCommenting from './StepCommenting';
import ReviewCommenting from './ReviewCommenting';
import ProcedureFieldNoRedlines from './ProcedureFieldNoRedlines';
import ProcedureStepHeader from './ProcedureStepHeader';
import PartKit from '../manufacturing/components/PartKit';
import PartBuild from '../manufacturing/components/PartBuild';
import InventoryDetailInput from '../manufacturing/components/InventoryDetailInput';
import PartUsage from '../manufacturing/components/PartUsage';
import StepDetail from './StepDetail';
import { useAuth } from '../contexts/AuthContext';
import { PERM } from '../lib/auth';
import ExpandCollapseCaret from './ExpandCollapse/ExpandCollapseCaret';
import { generateHiddenClassString } from '../lib/styles';
import signoffUtil from 'shared/lib/signoffUtil';
import runUtil from '../lib/runUtil';
import {
  cannotUpdateStep,
  getStepState,
  IRREVOCABLE_BLOCK_TYPE_SET,
  isStepEnded,
  shouldRecordExpressionForBlock,
  RUN_STATE,
  STEP_STATE,
} from 'shared/lib/runUtil';
import { AttachmentImageDisplayStyle } from './Blocks/BlockTypes';
import { ProcedureContentBlockTypes } from 'shared/lib/types/blockTypes';
import JumpTo from './JumpTo/JumpTo';
import reviewUtil from '../lib/reviewUtil';
import ProcedureStepSignoffButton from './ProcedureStepSignoffButton';
import {
  AUTO_COLLAPSE_UNACTIONABLE_STEPS_KEY,
  DISABLE_COMMENTS_ON_ENDED_RUNS_KEY,
  useSettings,
} from '../contexts/SettingsContext';
import { DefaultStepDetailDefinitions } from './StepDetailTypes';
import { isEmptyValue } from 'shared/lib/text';
import { useRunContext } from '../contexts/RunContext';
import { selectRunStep } from '../contexts/runsSlice';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import CommentsList from './CommentsList';
import Pluralize from 'pluralize';
import '../App.css';
import procedureUtil from '../lib/procedureUtil';
import ReferenceBlock from './Blocks/ReferenceBlock';
import StepConditionals from './StepConditionals/StepConditionals';
import Button, { BUTTON_TYPES } from './Button';
import ExpressionBlock from './Expression/ExpressionBlock';
import snippetUtil from '../lib/snippetUtil';
import { INITIAL_REDLINE_STATE, useRedline } from '../hooks/useRedline';
import useProcedureAdapter from '../hooks/useProcedureAdapter';
import JiraIssueModal from './JiraIssueModal';
import CreateIssueModal from '../issues/components/CreateIssueModal';
import TestCasesBlock from '../testing/components/TestCasesBlock';
import { buildHasEmptyValues, kitHasEmptyValues } from '../manufacturing/lib/validation';
import { STEP_REFERENCE_TYPE } from '../issues/constants';
import ThreeDotMenu from '../elements/ThreeDotMenu';
import fieldInputUtil from '../lib/fieldInputUtil';
import { isEmpty, isEqual } from 'lodash';
import RunToolCheckOutIn from '../manufacturing/components/Tools/RunToolCheckOutIn';
import { getScrollToUrlParams } from '../lib/scrollToUtil';
import RunToolUsage from '../manufacturing/components/Tools/RunToolUsage';
import ProcedureStepBanner from './ProcedureStepBanner';
import tableUtil, { STATIC_COLUMN_TYPES } from 'shared/lib/tableUtil';
import { SIGNOFF_COLUMN_TYPE } from './TableInput/TableInputConstants';
import CommentWrapper from './CommentWrapper';
import { useProcedureContext } from '../contexts/ProcedureContext';
import { isBatchStep } from '../lib/batchSteps';
import { generateCommentId } from 'shared/lib/idUtil';
import StepTimeline from './StepTimeline';
import FieldInputTable from './FieldInputTable';
import { isStepSettingEnabled } from 'shared/lib/procedureUtil';
import {
  faCircle,
  faLayerGroup,
  faPaperPlane as fasPaperPlane,
  faRedo,
  faStepForward,
  faStrikethrough,
} from '@fortawesome/free-solid-svg-icons';
import SettingBadge from './SettingBadge';
import { selectOfflineInfo } from '../app/offline';
import { useUserInfo } from '../contexts/UserContext';
import { isValidCheckinAmount } from '../manufacturing/lib/items';
import inventoryUtil from '../manufacturing/lib/inventoryUtil';
import apm from '../lib/apm';
import ProcedureStepEdit from './ProcedureStepEdit';
import { SelectionContextProvider } from '../contexts/Selection';
import FullStepSuggestedEditsModal from './SuggestedEdits/FullStepSuggestedEditsModal';
import BlockTextNoRedlines from './Blocks/BlockTextNoRedlines';
import ProcedureBlockRunNoRedlines from './Blocks/ProcedureBlockRunNoRedlines';
import { stepMatchesStepDoc } from 'shared/lib/runStepUtil';
import useFullStepRedline from '../hooks/useFullStepRedline';

/*
 * String constants for user display.
 * These should be moved to an official i18n framework when ready.
 */
const CONFIRM_END_STEP_STR = 'Are you sure?';
const CONFIRM_SKIP_STEP_STR = 'Are you sure you want to skip this step?';
const CONFIRM_DISCARD_EDITS_STR = 'Your unsaved edits will be discarded.';
const CONFIRM_EMPTY_INPUTS_STR = 'Some inputs are missing.';
const CONFIRM_REPEAT_STEP_STR = 'Are you sure you want to repeat this step? The current step will be skipped.';
const CONFIRM_UPLOADING_INPUTS_STR = 'Some files are still uploading.';
const CONFIRM_EMPTY_AND_UPLOADING_INPUTS_STR = 'Some inputs are missing and some files are still uploading.';
const ALERT_STRICT_MODE_SIGNOFFS =
  'To enable signoff, all field inputs must be completed and passing when rules are given, and all linked procedures must be completed.';
const ALERT_INVENTORY_DETAIL_INCOMPLETE = 'Inventory detail input missing required serial number.';
const ALERT_INVALID_CHECKIN_AMOUNTS = 'Check-in quantity must be a positive integer.';

const MOBILE_WIDTH = 550;

/**
 * @typedef {Object} ProcedureStepProps
 * @property {number | undefined} [customStepKey] - Optional key for the step, defaults to undefined.
 */

/**
 * Renders a procedure step.
 *
 * onSignOff: Callback of type fn(operator, recorded), where
 *            operator: String operator role that was signed off.
 *            recorded: Dictionary of type {
 *              pass: true/false
 *              value: telemetry value (if single field)
 *              timestamp: timestamp of telemetry value (if single field)
 *            }
 * @param {ProcedureStepProps & any} props
 */
const ProcedureStep = ({
  runId,
  projectId,
  step,
  stepKey,
  sectionId,
  sectionKey,
  sourceName,
  docState,
  operation,
  isRepeatable,
  repeatKey,
  onRepeat,
  onSkip,
  onSignOff,
  onPinSignOff,
  onRevokeSignoff,
  onComplete,
  onFailStep,
  onStartLinkedRun,
  onRefChanged,
  onRecordValuesChanged,
  onAddStepBelow,
  isRedlineFeatureEnabled,
  onSaveRedlineStepComment,
  saveNewComment,
  editComment,
  onResolveReviewComment,
  onUnresolveReviewComment,
  saveReviewComment,
  comments,
  isHidden,
  isCollapsed,
  isPreviousStepComplete,
  onStepCollapse,
  scrollToBufferRem = 0,
  notifyRemainingStepOperators,
  notificationListenerConnected,
  hasPreviousStep,
  areRedlineCommentsExpanded,
  expandRedlineComments,
  onStepDetailChanged,
  configurePartKitBlock,
  configurePartBuildBlock,
  isInSectionSnippet = false,
  isPreviewMode = false,
  onAddIssue,
  showReviewComments = false,
  isStrictSignoffEnabled = false,
  customStepKey = undefined,
  showStepMenu = true,
  revokeSignoffEnabled = true,
  showSettings = true,
  onSaveFullStepSuggestedEdit,
  onIncludeFullStepSuggestedEdit,
  onEditSuggestedEditComment,
}) => {
  /** @type {{ services: import('../contexts/proceduresSlice').DatabaseServices; currentTeamId: string }} */
  const { services, currentTeamId } = useDatabaseServices();
  const { procedure } = useProcedureContext();
  // Map of {contentIndex: errors dict}
  const [contentErrors, setContentErrors] = useState({});
  // Map of {contentIndex: true if content is uploading}
  const [contentUploading, setContentUploading] = useState({});
  const [collapseCompletedSteps, setCollapseCompletedSteps] = useState(true);
  const [showJiraIssueModal, setShowJiraIssueModal] = useState(false);
  const [showNcrIssueModal, setShowNcrIssueModal] = useState(false);
  const [showSuggestedEditsModal, setShowSuggestedEditsModal] = useState(false);
  const [completedLinkedRuns, setCompletedLinkedRuns] = useState(new Set());
  const { auth } = useAuth();
  const { mixpanel } = useMixpanel();
  const { config, getSetting, isJiraIntegrationEnabled, isIssuesEnabled } = useSettings();
  const {
    run,
    isRun,
    isUserParticipant,
    areRequirementsMet,
    isStepVisible,
    advanceStep,
    goToNextFromStep,
    goToPreviousFromStep,
    isSingleCardEnabled,
    viewStepAsIncomplete,
    previewUserRoles,
    isPaused: isRunPaused,
  } = useRunContext();
  const isOnline = useSelector((state) => selectOfflineInfo(state).online);
  const { userInfo } = useUserInfo();
  const userId = userInfo.session.user_id;

  const location = window.location;
  const locationUrl = `${location.protocol}//${location.host}${location.pathname}`;

  const { sourceStepConditionalsMap, removedSourceStepConditionalsMap } = useProcedureAdapter();
  const isMounted = useRef(true);
  const stepGotoRef = useRef();
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_WIDTH);
  const liveRunStep = useSelector((state) => selectRunStep(state, currentTeamId, runId, sectionId, step.id));
  const runStep = isPreviewMode ? step : liveRunStep; // ignore redux when in preview
  const [recordedExpressionMap, setRecordedExpressionMap] = useState({});
  const [checkedOutInventory, setCheckedOutInventory] = useState(
    /** @type {import('../manufacturing/lib/inventoryUtil').Item[]} */ ([])
  );

  const endedRunCommentsDisabled = useMemo(
    () => run.state === 'completed' && getSetting(DISABLE_COMMENTS_ON_ENDED_RUNS_KEY, false),
    [getSetting, run.state]
  );

  const getRecordExpressionSetter = useCallback(
    (index) => (recorded) => {
      if (isEqual(recorded, recordedExpressionMap[index])) {
        return;
      }
      setRecordedExpressionMap((prev) => ({ ...prev, [index]: recorded }));
    },
    [recordedExpressionMap]
  );

  const showRedlineComments = useMemo(
    () => areRedlineCommentsExpanded && areRedlineCommentsExpanded(step.id),
    [areRedlineCommentsExpanded, step.id]
  );

  const stepState = useMemo(() => {
    let state = getStepState(step);
    if (state === STEP_STATE.COMPLETED && viewStepAsIncomplete(step.id)) {
      state = STEP_STATE.INCOMPLETE;
    }
    return state ?? STEP_STATE.INCOMPLETE;
  }, [step, viewStepAsIncomplete]);

  /**
   * Recorded values to use when completing a step. This is the source of truth,
   * and updated whenever child block content values change. Also passed back
   * down to children for reference.
   *
   * type {{ [contentIndex: number]: import('shared/lib/types/views/procedures').Recorded }}
   */
  const liveContentRecorded = useMemo(() => {
    if (
      cannotUpdateStep(step) ||
      !stepMatchesStepDoc({
        step,
        stepDoc: liveRunStep,
      })
    ) {
      return {};
    }
    const map = {};
    if (!runStep) {
      step.content.forEach((block, index) => {
        if (shouldRecordExpressionForBlock(block)) {
          const { value, display, richDisplay, references } = recordedExpressionMap[index] ?? {};
          map[index] = { value, display, richDisplay, references };
        }
      });
      return map;
    }
    runStep.content.forEach((block, index) => {
      if (block.type === ProcedureContentBlockTypes.FieldInputTable) {
        map[index] = block.fields.reduce((acc, field, fieldIndex) => {
          if (isPreviewMode && field.preview_recorded) {
            acc[fieldIndex] = field.preview_recorded;
          } else if (field.recorded) {
            acc[fieldIndex] = field.recorded;
          }
          return acc;
        }, {});
      } else if (isPreviewMode && block.preview_recorded) {
        map[index] = block.preview_recorded;
      } else if (block.recorded) {
        map[index] = block.recorded;
      } else if (shouldRecordExpressionForBlock(block)) {
        const { value, display, richDisplay, references } = recordedExpressionMap[index] ?? {};
        map[index] = { value, display, richDisplay, references };
      }
    });
    return map;
  }, [step, runStep, liveRunStep, recordedExpressionMap, isPreviewMode]);

  // Get recorded content or pending recorded content for a content block.
  const pendingOrRecordedContent = useCallback(
    (content, contentIndex) => {
      // TODO: Refactor and cleanup, this is getting gnarly with optional signoffs.
      if (!signoffUtil.isSignoffRequired(step.signoffs)) {
        // If user is trying to change the content values, show the new values.
        if (liveContentRecorded[contentIndex] !== null && liveContentRecorded[contentIndex] !== undefined) {
          return liveContentRecorded[contentIndex];
        }
        // Show the previous (recorded) values.
        if (content.recorded) {
          return content.recorded;
        }
        return null;
      }

      if (content.recorded) {
        return content.recorded;
      }

      return liveContentRecorded[contentIndex];
    },
    [liveContentRecorded, step.signoffs]
  );

  const getFieldInputTableRecordedData = useCallback(
    (content, contentIndex) => {
      if (liveContentRecorded[contentIndex] !== undefined && liveContentRecorded[contentIndex] !== null) {
        return content.fields.map((field, idx) => {
          return liveContentRecorded[contentIndex][idx] !== undefined
            ? liveContentRecorded[contentIndex][idx]
            : field.recorded;
        });
      }

      // Default to recorded data in fields if no live data is present or if signoff is not required
      return content.fields.map((field) => field.recorded);
    },
    [liveContentRecorded]
  );

  useEffect(() => {
    const handleWindowResize = function () {
      setIsMobile(window.innerWidth < MOBILE_WIDTH);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const isEnded = useMemo(() => isStepEnded(step), [step]);

  const isStepActive = useMemo(
    () => !isRunPaused && !isEnded && !signoffUtil.anySignoffsComplete(step),
    [isRunPaused, isEnded, step]
  );

  const isStepCollapsed = useMemo(() => {
    // Steps are always expanded in single card view
    if (isSingleCardEnabled) {
      return false;
    }
    return isCollapsed || isHidden;
  }, [isCollapsed, isHidden, isSingleCardEnabled]);

  // Returns an array of step detail objects.
  const allStepDetails = useMemo(() => {
    const standardStepDetails = Object.values(DefaultStepDetailDefinitions);

    // If config doc or step details do not exist, return standard step details.
    if (!config || !config.step_details) {
      return standardStepDetails;
    }

    const optionalStepDetails = Object.values(config.step_details);

    return [...standardStepDetails, ...optionalStepDetails];
  }, [config]);

  // Returns step details that have a non empty value.
  const visibleStepDetails = useMemo(() => {
    return allStepDetails.filter((stepDetail) => {
      const stepDetailValue = step[stepDetail.id];

      if (Array.isArray(stepDetailValue)) {
        return stepDetailValue.length !== 0;
      }

      return !isEmptyValue(stepDetailValue);
    });
  }, [step, allStepDetails]);

  const toggleIsStepCollapsed = useCallback(() => {
    if (typeof onStepCollapse === 'function') {
      onStepCollapse(step.id, !isStepCollapsed);
    }
  }, [isStepCollapsed, onStepCollapse, step.id]);

  // recorded: any dict or obj meant to be recorded with this content block
  const recordValuesChanged = useCallback(
    (contentId, recorded, fieldIndex) => {
      if (typeof onRecordValuesChanged !== 'function') {
        return;
      }
      onRecordValuesChanged(step.id, contentId, recorded, fieldIndex);
    },
    [step, onRecordValuesChanged]
  );

  const onRecordErrorsChanged = useCallback((contentIndex, errors) => {
    setContentErrors((dict) => {
      const updates = {};
      updates[contentIndex] = errors;
      return {
        ...dict,
        ...updates,
      };
    });
  }, []);

  const onRecordUploadingChanged = useCallback((contentIndex, isUploading) => {
    setContentUploading((dict) => {
      const updates = {};
      updates[contentIndex] = isUploading;
      return {
        ...dict,
        ...updates,
      };
    });
  }, []);

  const updateStepDetail = useCallback(
    (field, value, timingTriggered = false) => {
      if (timingTriggered && typeof step['duration'] === 'object') {
        const durationValue = {
          started_at: value.started_at,
          duration: '',
        };
        onStepDetailChanged(sectionId, step.id, 'duration', durationValue);
      }
      onStepDetailChanged(sectionId, step.id, field, value);
    },
    [onStepDetailChanged, sectionId, step]
  );

  const isRedlineDisabledBecauseOfStepSnippet = useMemo(() => snippetUtil.isSnippet(step), [step]);

  const isLatestStep = useMemo(() => isRepeatable, [isRepeatable]);

  const isRedlineDisabledBecauseOfRepeat = !isLatestStep;

  const {
    canSuggestEdits,
    isRedlineEnabled,
    isRedlineStateDirty,
    onToggleRedline,
    reasonRedlineIsDisabled,
    redlineState,
    setRedlineState,
    showsRedlineButton,
    clearRedlineState,
  } = useRedline({
    docState,
    isPreviewMode,
    isRedlineDisabledBecauseOfSectionSnippet: isInSectionSnippet,
    isRedlineDisabledBecauseOfStepSnippet,
    isRedlineDisabledBecauseOfRepeat,
    isRedlineFeatureEnabled,
    isUserParticipant,
    projectId,
  });

  const { isCurrentLatest } = useFullStepRedline({
    currentStep: step,
    redlines: step.redlines,
  });

  const dirtyCommentChangeHandler = useCallback(
    (dirty) => {
      setRedlineState((state) => ({
        ...state,
        dirtyComment: dirty,
      }));
    },
    [setRedlineState]
  );

  // Needed for back-compat for old-style redlines
  const redlineBlockChanges = useCallback(
    (contentIndex) => {
      // Only show redlines for runs.
      if (!isRun || !step.redlines) {
        return [];
      }
      const block = step.content[contentIndex];
      return revisionsUtil.getBlockChanges(block, contentIndex, step.redlines);
    },
    [isRun, step]
  );

  // Needed for back-compat for old-style redlines
  const redlineStepFieldChanges = useCallback(
    (field) => {
      // Only show redlines for runs.
      if (!isRun || !step.redlines) {
        return [];
      }
      return revisionsUtil.getStepFieldChanges(step, field, step.redlines);
    },
    [isRun, step]
  );

  const displayRedlineComments = useCallback(() => {
    expandRedlineComments && expandRedlineComments(step.id, true);
  }, [step.id, expandRedlineComments]);

  const canEditSuggestedEditComment = useMemo(() => {
    return isRun && isLatestStep && run?.state === RUN_STATE.RUNNING;
  }, [isRun, isLatestStep, run?.state]);

  const editRedlineCommentHandler = useCallback(
    (comment, id) => {
      return onSaveRedlineStepComment(comment.comment, id).then(displayRedlineComments).then(clearRedlineState);
    },
    [onSaveRedlineStepComment, displayRedlineComments, clearRedlineState]
  );

  // Returns true if there is a requires_previous step flag and the previous step is complete.
  const isPreviousStepRequirementFulfilled = useMemo(() => {
    if (!step.requires_previous) {
      return true;
    }

    return isPreviousStepComplete;
  }, [step.requires_previous, isPreviousStepComplete]);

  const areConditionalAndStepDependenciesFulfilled = useMemo(() => {
    /**
     * We use this component in a non-run (edit) context, where
     * areDependenciesFulfilled will be undefined. We don't need to verify if
     * dependencies are fulfilled in a non-run context, so we can return true.
     */
    if (!isRun || !areRequirementsMet) {
      return true;
    }
    return areRequirementsMet(step);
  }, [isRun, step, areRequirementsMet]);

  // All operators included on step signoffs
  const allSignoffOperators = useMemo(() => {
    if (!step.signoffs) {
      return [];
    }
    return [
      ...new Set(
        [].concat.apply(
          [],
          step.signoffs.map((s) => s.operators)
        )
      ),
    ];
  }, [step.signoffs]);

  // Operator roles the user has that are included on step signoffs
  const userStepRoles = useMemo(() => {
    if (isPreviewMode) {
      if (previewUserRoles?.length > 0) {
        const previewUserRoleSet = new Set(previewUserRoles);
        return allSignoffOperators.filter((operator) => previewUserRoleSet.has(operator));
      }
      return allSignoffOperators;
    }
    return allSignoffOperators.filter((operator) => auth.hasOperatorRole(operator));
  }, [allSignoffOperators, auth, isPreviewMode, previewUserRoles]);

  const hasGenericSignoffs = useMemo(() => signoffUtil.isGenericSignoffRequired(step.signoffs), [step.signoffs]);

  const completeSignoffs = useMemo(() => signoffUtil.getCompleteSignoffs(step), [step]);

  const userHasSignoffs = useMemo(() => {
    return userStepRoles.length !== 0 || hasGenericSignoffs;
  }, [userStepRoles, hasGenericSignoffs]);

  const isRunnable = useMemo(() => {
    // When signoffs are not required, allow user to complete step more than once
    if (!signoffUtil.isSignoffRequired(step.signoffs)) {
      return true;
    }
    if (isEnded) {
      return false;
    }
    return userHasSignoffs;
  }, [isEnded, step.signoffs, userHasSignoffs]);

  const isTimingComplete = useMemo(() => {
    if (step.timer && typeof step.timer === 'object') {
      return Boolean(step.timer.completed);
    } else {
      return true;
    }
  }, [step.timer]);

  const isDurationUnblocked = useMemo(() => {
    if (step.duration && typeof step.duration === 'object') {
      if (step.duration.started_at) {
        return true;
      } else {
        return false;
      }
    }

    if (step.timer && typeof step.timer === 'object') {
      if (step.timer.started_at) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }, [step.duration, step.timer]);

  const baseActiveCondition = useMemo(() => {
    return (
      areConditionalAndStepDependenciesFulfilled &&
      isPreviousStepRequirementFulfilled &&
      isUserParticipant &&
      auth.hasPermission(PERM.RUNS_EDIT, projectId) &&
      docState === RUN_STATE.RUNNING
    );
  }, [
    areConditionalAndStepDependenciesFulfilled,
    isPreviousStepRequirementFulfilled,
    isUserParticipant,
    auth,
    projectId,
    docState,
  ]);

  const baseRunningCondition = useMemo(() => {
    return baseActiveCondition && isRunnable;
  }, [baseActiveCondition, isRunnable]);

  const isStepEnabled = useMemo(() => {
    return baseRunningCondition && isDurationUnblocked;
  }, [baseRunningCondition, isDurationUnblocked]);

  // less restrictions than the "isStepEnabled" flag, because that flag is based on the current user's roles
  const isPinSignoffEnabled = useMemo(() => {
    return (
      onPinSignOff &&
      isOnline &&
      areConditionalAndStepDependenciesFulfilled &&
      isPreviousStepRequirementFulfilled &&
      isUserParticipant &&
      !isEnded &&
      docState === RUN_STATE.RUNNING &&
      isDurationUnblocked &&
      isTimingComplete
    );
  }, [
    areConditionalAndStepDependenciesFulfilled,
    onPinSignOff,
    docState,
    isDurationUnblocked,
    isEnded,
    isOnline,
    isPreviousStepRequirementFulfilled,
    isTimingComplete,
    isUserParticipant,
  ]);

  const isRevokeSignoffEnabledForStep = useMemo(() => {
    return (
      revokeSignoffEnabled &&
      baseActiveCondition &&
      signoffUtil.isSignoffRequired(step.signoffs) &&
      isLatestStep &&
      [STEP_STATE.COMPLETED, STEP_STATE.INCOMPLETE].includes(stepState) &&
      userHasSignoffs &&
      // Allow partially-signed-off steps with irrevocable blocks to be revoked.
      (signoffUtil.allSignoffsComplete(step)
        ? step.content.every((block) => !IRREVOCABLE_BLOCK_TYPE_SET.has(block.type))
        : true)
    );
  }, [revokeSignoffEnabled, baseActiveCondition, step, isLatestStep, stepState, userHasSignoffs]);

  // Steps can be skipped even if they have unmet requirements/dependencies
  const isSkipEnabled = useMemo(() => {
    return (
      isUserParticipant &&
      auth.hasPermission(PERM.RUNS_EDIT, projectId) &&
      docState === RUN_STATE.RUNNING &&
      !isEnded &&
      (userStepRoles.length !== 0 || hasGenericSignoffs) &&
      isStepSettingEnabled(step.skip_step_enabled, run.skip_step_enabled)
    );
  }, [
    isUserParticipant,
    auth,
    projectId,
    docState,
    isEnded,
    userStepRoles.length,
    hasGenericSignoffs,
    step.skip_step_enabled,
    run.skip_step_enabled,
  ]);

  const isRepeatEnabled = useMemo(
    () =>
      isRepeatable && // a step can only be repeated if the section is repeatable
      !step.created_during_run && // Repeat disabled for newly created steps (for now)
      areConditionalAndStepDependenciesFulfilled &&
      isPreviousStepRequirementFulfilled &&
      isUserParticipant &&
      auth.hasPermission(PERM.RUNS_EDIT, projectId) &&
      docState === RUN_STATE.RUNNING &&
      (userStepRoles.length !== 0 || hasGenericSignoffs) &&
      isStepSettingEnabled(step.repeat_step_enabled, run.repeat_step_enabled),
    [
      isRepeatable,
      step.created_during_run,
      step.repeat_step_enabled,
      areConditionalAndStepDependenciesFulfilled,
      isPreviousStepRequirementFulfilled,
      isUserParticipant,
      auth,
      projectId,
      docState,
      userStepRoles.length,
      hasGenericSignoffs,
      run.repeat_step_enabled,
    ]
  );

  const isContentCompleteEnabled = useMemo(() => {
    if (!signoffUtil.isSignoffRequired(step.signoffs)) {
      return isStepEnabled;
    }
    return isStepEnabled && (!completeSignoffs || completeSignoffs.length < 1);
  }, [isStepEnabled, completeSignoffs, step.signoffs]);

  const hasContentErrors = useMemo(() => {
    if (contentErrors) {
      for (const contentIndex in contentErrors) {
        if (Object.keys(contentErrors[contentIndex]).length > 0) {
          return true;
        }
      }
    }
    return false;
  }, [contentErrors]);

  const isEmptyInputValue = useCallback((value) => value === undefined || value === '' || value === null, []);

  const hasEmptyFieldInputValues = useCallback(
    (_contentRecorded) => {
      if (!_contentRecorded || isEmptyInputValue(_contentRecorded.value)) {
        return true;
      }
    },
    [isEmptyInputValue]
  );

  const hasEmptyTableInputValues = useCallback(
    (content, _contentRecorded) => {
      // If the table only has static columns no need to go through the validation.
      if (content.columns.every((column) => STATIC_COLUMN_TYPES.includes(column.column_type))) {
        return false;
      }

      if (!_contentRecorded) {
        return true;
      }

      const values = _contentRecorded.values;

      return values.some((rowsArray) =>
        rowsArray.some((cell, columnIndex) => {
          const column = content.columns[columnIndex];
          const columnType = column.column_type;

          // Signoff columns should have every signoff block satisfied.
          if (columnType === SIGNOFF_COLUMN_TYPE) {
            return !tableUtil.areAllSignoffsComplete(cell);
          }

          // Static text columns do not need to be validated.
          if (STATIC_COLUMN_TYPES.includes(columnType)) {
            return false;
          }

          // both default/undefined or unchecked/false checkbox should be considered empty
          if (column.input_type === 'checkbox') {
            return !cell;
          }

          // Returns true if a cell value is empty.
          return isEmptyInputValue(cell);
        })
      );
    },
    [isEmptyInputValue]
  );

  const addCompletedRunId = useCallback((runId) => {
    setCompletedLinkedRuns((completedRuns) => new Set([...completedRuns, runId]));
  }, []);

  const hasUnfinishedLinkedProcedures = useCallback(() => {
    if (!step.content || !isStrictSignoffEnabled) {
      return false;
    }
    const procedureLinks = step.content.filter((content) => content.type === ProcedureContentBlockTypes.ProcedureLink);
    if (procedureLinks.length === 0) {
      return false;
    }
    return procedureLinks.some((link) => !link.run || !completedLinkedRuns.has(link.run));
  }, [step.content, isStrictSignoffEnabled, completedLinkedRuns]);

  const hasNumberInputsFailing = useCallback(() => {
    if (!step.content) {
      return false;
    }

    const isFieldInputFailing = (field, recorded) => {
      if (field.inputType === 'number' && !isEmptyValue(field.rule) && !isEmpty(field.rule?.op)) {
        return !fieldInputUtil.isNumberFieldInputPassing(field, recorded);
      }
      return false;
    };

    return step.content.some((content, i) => {
      let recorded;
      if (content.type === ProcedureContentBlockTypes.FieldInputTable) {
        recorded = getFieldInputTableRecordedData(content, i);
        return content.fields.some((field, index) => isFieldInputFailing(field, recorded[index]));
      }
      if (content.type === ProcedureContentBlockTypes.FieldInput && content.inputType === 'number') {
        recorded = pendingOrRecordedContent(content, i);
        return isFieldInputFailing(content, recorded);
      }
      return false;
    });
  }, [step.content, pendingOrRecordedContent, getFieldInputTableRecordedData]);

  const hasInvalidInventoryDetailInputs = useMemo(() => {
    if (!step.content) {
      return false;
    }

    return step.content.some((content, i) => {
      const recorded = pendingOrRecordedContent(content, i);

      if (content.type === 'inventory_detail_input') {
        if (recorded && recorded.value && !recorded.item_id) {
          return true;
        }
      }
      return false;
    });
  }, [step.content, pendingOrRecordedContent]);

  const hasInvalidCheckInQuantity = useMemo(() => {
    if (!step.content) {
      return false;
    }

    return step.content.some((content, i) => {
      const recorded = pendingOrRecordedContent(content, i);

      if (content.type === 'part_build' && recorded && recorded.items) {
        const itemsArray = Object.values(recorded.items);
        return itemsArray.some(({ amount }) => !isValidCheckinAmount(amount));
      }
      return false;
    });
  }, [step.content, pendingOrRecordedContent]);

  const hasEmptyFieldInput = useCallback(
    (content, recorded, contentUploading, index) => {
      if (content.type === ProcedureContentBlockTypes.FieldInput) {
        if (!contentUploading[index] && hasEmptyFieldInputValues(recorded)) {
          return true;
        }
        if (content.inputType === 'sketch' && (!recorded?.value || !recorded?.value.attachment_id)) {
          return true;
        }
        if (content.inputType === 'checkbox' && recorded?.value === false) {
          return true;
        }
        if (
          content.inputType === 'timestamp' &&
          content.dateTimeType === 'datetime' &&
          typeof recorded?.value !== 'string' &&
          (!recorded?.value.date || !recorded?.value.time)
        ) {
          return true;
        }
        if (
          content.inputType === 'timestamp' &&
          content.dateTimeType === 'date' &&
          typeof recorded?.value !== 'string' &&
          !recorded?.value.date
        ) {
          return true;
        }
        if (
          content.inputType === 'timestamp' &&
          content.dateTimeType === 'time' &&
          typeof recorded?.value !== 'string' &&
          !recorded?.value.time
        ) {
          return true;
        }
      }
      return false;
    },
    [hasEmptyFieldInputValues]
  );

  /*
   * True if any content block is both empty and not in the process of uploading.
   *
   * To check if any files are uploading, check hasAnyUploadingValues or the
   * appropriate index in the contentUploading array.
   */
  const hasAnyEmptyValues = useMemo(() => {
    if (!step.content) {
      return false;
    }

    return step.content.some((content, i) => {
      const contentType = content.type;
      const recorded = pendingOrRecordedContent(content, i);

      if (contentType === ProcedureContentBlockTypes.FieldInput) {
        if (hasEmptyFieldInput(content, recorded, contentUploading, i)) {
          return true;
        }
      } else if (contentType === 'table_input') {
        if (hasEmptyTableInputValues(content, recorded)) {
          return true;
        }
      } else if (contentType === 'part_usage') {
        if (!recorded || recorded.length === 0) {
          return true;
        }
        if (isEmptyValue(recorded[0].item_id) || isEmptyValue(recorded[0].amount)) {
          return true;
        }
      } else if (contentType === 'part_kit') {
        return kitHasEmptyValues(content, recorded);
      } else if (contentType === 'part_build') {
        return buildHasEmptyValues(content, recorded);
      } else if (contentType === 'inventory_detail_input') {
        if (!recorded || !recorded.value) {
          return true;
        }
      } else if (contentType === 'field_input_table') {
        if (content.fields && content.fields.length > 0) {
          const recordedField = getFieldInputTableRecordedData(content, i);
          return content.fields.some((field, index) => {
            return hasEmptyFieldInput(field, recordedField[index], contentUploading, index);
          });
        }
      }
      return false;
    });
  }, [
    step.content,
    pendingOrRecordedContent,
    contentUploading,
    hasEmptyTableInputValues,
    hasEmptyFieldInput,
    getFieldInputTableRecordedData,
  ]);

  // True if any content block is in the process of uploading.
  const hasAnyUploadingValues = useMemo(() => {
    if (!step.content) {
      return false;
    }
    return step.content.some((_content, i) => contentUploading[i]);
  }, [step.content, contentUploading]);

  const signOffComplete = useCallback(
    (signoffId) => stepState === STEP_STATE.COMPLETED || signoffUtil.isSignoffCompleted(step, signoffId),
    [step, stepState]
  );

  const hasOperatorAlreadySigned = useCallback(
    (operator) => {
      return signoffUtil.isRoleSignedOffAnywhere({
        operator,
        userId,
        signoffable: step,
      });
    },
    [step, userId]
  );

  const completedByAutomation = useCallback(
    (signoffId) => stepState === STEP_STATE.COMPLETED && signoffUtil.isSignOffCompletedByAutomation(step, signoffId),
    [step, stepState]
  );

  const anyOperatorSignedOff = useCallback(
    (step) => stepState === STEP_STATE.COMPLETED || signoffUtil.anySignoffsComplete(step),
    [stepState]
  );

  /*
   * Shows a confirmation to the user when step is ending.
   * Shows a suitable confirmation message depending if there are unsaved suggested
   * edits or empty step inputs. Will show confirmation if there are empty
   * step inputs, or if there are unsaved suggested edits, or if `forceConfirm`
   * is true.
   *
   * message: String, the base confirmation message to show.
   * forceConfirm: Boolean, True to always show the user confirmation.
   * warnOnEmptyOrUploadingValues (optional): Boolean, True to warn if some inputs
   *                                          (field, table, file, etc.) are missing or
   *                                          in the process of being uploaded, default
   *                                          true.
   *
   * returns: True if user confirmed or no confirmation was needed, otherwise
   *          false.
   */
  const confirmStepEnd = useCallback(
    ({ message, forceConfirm = false, warnOnEmptyOrUploadingValues = true, enforceStrictMode = false }) => {
      if (typeof message !== 'string' || typeof forceConfirm !== 'boolean') {
        return;
      }
      if (enforceStrictMode && isStrictSignoffEnabled) {
        const blockSignoff = hasAnyEmptyValues || hasNumberInputsFailing() || hasUnfinishedLinkedProcedures();
        if (blockSignoff) {
          window.alert(ALERT_STRICT_MODE_SIGNOFFS);
          return false;
        }
      }

      if (hasInvalidInventoryDetailInputs) {
        window.alert(ALERT_INVENTORY_DETAIL_INCOMPLETE);
        return false;
      }

      if (hasInvalidCheckInQuantity) {
        window.alert(ALERT_INVALID_CHECKIN_AMOUNTS);
        return false;
      }

      // Build user confirmation message
      let shouldConfirm = forceConfirm;
      // Warn: If redlining is active and there are unsaved changes.
      if (isRedlineStateDirty(redlineState)) {
        message += ` ${CONFIRM_DISCARD_EDITS_STR}`;
        shouldConfirm = true;
      }

      // Warn: If some inputs (field, table, etc) are missing or uploading.
      if (warnOnEmptyOrUploadingValues && !anyOperatorSignedOff(step)) {
        // Missing and uploading values
        if (hasAnyEmptyValues && hasAnyUploadingValues) {
          message += ` ${CONFIRM_EMPTY_AND_UPLOADING_INPUTS_STR}`;
          shouldConfirm = true;
        }
        // Missing values only
        if (hasAnyEmptyValues && !hasAnyUploadingValues) {
          message += ` ${CONFIRM_EMPTY_INPUTS_STR}`;
          shouldConfirm = true;
        }
        // Uploading values only
        if (!hasAnyEmptyValues && hasAnyUploadingValues) {
          message += ` ${CONFIRM_UPLOADING_INPUTS_STR}`;
          shouldConfirm = true;
        }
      }

      // Show popup confirmation if needed
      return !(shouldConfirm && !window.confirm(message));
    },
    [
      redlineState,
      step,
      hasAnyEmptyValues,
      isRedlineStateDirty,
      hasAnyUploadingValues,
      anyOperatorSignedOff,
      hasNumberInputsFailing,
      hasUnfinishedLinkedProcedures,
      isStrictSignoffEnabled,
      hasInvalidInventoryDetailInputs,
      hasInvalidCheckInQuantity,
    ]
  );

  const isAutoCollapseEnabled = useMemo(() => {
    // Don't auto collapse in single card view or outside runs
    if (isSingleCardEnabled || !isRun) {
      return false;
    }
    return getSetting(AUTO_COLLAPSE_UNACTIONABLE_STEPS_KEY, false);
  }, [isSingleCardEnabled, getSetting, isRun]);

  /**
   * Initialize the step as collapsed if it is completed or has unmet
   * requirements. When the requirements are met, expand the step.
   *
   * Only does this on the first render, and not when the run data has changed,
   * to prevent the screen from jumping unexpectedly, when other users complete steps.
   */
  const requirementsPreviouslyMet = useRef(areConditionalAndStepDependenciesFulfilled);

  useEffect(() => {
    if (isAutoCollapseEnabled && collapseCompletedSteps) {
      if (isCollapsed === undefined) {
        if (isEnded || !areConditionalAndStepDependenciesFulfilled) {
          onStepCollapse(step.id, true);
        }
      } else {
        if (areConditionalAndStepDependenciesFulfilled && !requirementsPreviouslyMet.current) {
          onStepCollapse(step.id, false);
        }
        requirementsPreviouslyMet.current = areConditionalAndStepDependenciesFulfilled;
      }

      setCollapseCompletedSteps(false);
    }
  }, [
    areConditionalAndStepDependenciesFulfilled,
    onStepCollapse,
    step.id,
    isAutoCollapseEnabled,
    isEnded,
    isCollapsed,
    collapseCompletedSteps,
  ]);

  const handleOnSignoff = useCallback(
    (signoffId, operator) => {
      if (hasContentErrors) {
        // Form errors will render
        return;
      }
      // Confirm step completion
      if (
        !confirmStepEnd({
          message: CONFIRM_END_STEP_STR,
          enforceStrictMode: true,
        })
      ) {
        return;
      }
      // Clear redline state and discard any unsaved edits
      setRedlineState(INITIAL_REDLINE_STATE);

      if (typeof onSignOff === 'function') {
        if (mixpanel) {
          mixpanel.track('Operator Signed Off');
          const numSignOffs = completeSignoffs ? completeSignoffs.length : 0;
          if (step.signoffs && numSignOffs === step.signoffs.length - 1) {
            mixpanel.track('Step Completed');
            if (isAutoCollapseEnabled) {
              onStepCollapse(step.id, true);
            }
          }
        }
        onSignOff(signoffId, operator, liveContentRecorded, userStepRoles);
        advanceStep();
      }
    },
    [
      advanceStep,
      completeSignoffs,
      confirmStepEnd,
      liveContentRecorded,
      hasContentErrors,
      isAutoCollapseEnabled,
      mixpanel,
      onSignOff,
      onStepCollapse,
      setRedlineState,
      step.id,
      step.signoffs,
      userStepRoles,
    ]
  );

  const handleOnPinSignoff = useCallback(
    async ({ signoffId, operator, pinUser, pin }) => {
      if (hasContentErrors) {
        // Form errors will render
        return;
      }
      // Confirm step completion
      if (
        !confirmStepEnd({
          message: CONFIRM_END_STEP_STR,
          enforceStrictMode: true,
        })
      ) {
        return;
      }
      // Clear redline state and discard any unsaved edits
      setRedlineState(INITIAL_REDLINE_STATE);

      if (typeof onSignOff === 'function') {
        if (mixpanel) {
          mixpanel.track('Operator Signed Off', { method: 'PIN' });
          const numSignOffs = completeSignoffs ? completeSignoffs.length : 0;
          if (step.signoffs && numSignOffs === step.signoffs.length - 1) {
            mixpanel.track('Step Completed');
            if (isAutoCollapseEnabled) {
              onStepCollapse(step.id, true);
            }
          }
        }

        return onPinSignOff({ signoffId, operator, pinUser, pin, recorded: liveContentRecorded }).then(advanceStep());
      }
    },
    [
      hasContentErrors,
      confirmStepEnd,
      setRedlineState,
      onSignOff,
      mixpanel,
      onPinSignOff,
      liveContentRecorded,
      advanceStep,
      completeSignoffs,
      step.signoffs,
      step.id,
      isAutoCollapseEnabled,
      onStepCollapse,
    ]
  );

  const handleOnRevokeSignoff = useCallback(
    (signoffId) => {
      if (hasContentErrors) {
        // Form errors will render
        return;
      }

      const revokeCompleteStepMessage = 'Actions on any dependent steps will not be undone.';
      // Confirm revoke step
      if (
        !window.confirm(
          `Are you sure you want to revoke this signoff?${
            signoffUtil.allSignoffsComplete(step) ? ` ${revokeCompleteStepMessage}` : ''
          }`
        )
      ) {
        return;
      }

      if (typeof onRevokeSignoff === 'function') {
        if (mixpanel) {
          mixpanel.track('Signoff Revoked');
          const numSignOffs = completeSignoffs ? completeSignoffs.length : 0;
          if (step.signoffs && numSignOffs === step.signoffs.length) {
            mixpanel.track('Completed Step Revoked');
          }
        }

        onRevokeSignoff(signoffId, userStepRoles);
        onStepCollapse(step.id, false);
      }
    },
    [completeSignoffs, hasContentErrors, mixpanel, onRevokeSignoff, onStepCollapse, step, userStepRoles]
  );

  // Complete == no signoffs required, move to next step
  const handleOnComplete = useCallback(() => {
    if (hasContentErrors) {
      // Form errors will render
      return;
    }
    // Confirm step completion
    if (!confirmStepEnd({ message: CONFIRM_END_STEP_STR })) {
      return;
    }
    // Clear redline state and discard any unsaved edits
    setRedlineState(INITIAL_REDLINE_STATE);

    if (typeof onComplete === 'function') {
      if (mixpanel) {
        mixpanel.track('Step Completed');
        if (getSetting(AUTO_COLLAPSE_UNACTIONABLE_STEPS_KEY, false)) {
          onStepCollapse(step.id, true);
        }
      }
      onComplete(liveContentRecorded);
      goToNextFromStep(step.id);
    }
  }, [
    hasContentErrors,
    liveContentRecorded,
    getSetting,
    mixpanel,
    onComplete,
    onStepCollapse,
    step.id,
    goToNextFromStep,
    confirmStepEnd,
    setRedlineState,
  ]);

  const handleOnPreviousStep = useCallback(() => {
    if (mixpanel) {
      mixpanel.track('Step Previous');
    }
    goToPreviousFromStep(step);
  }, [goToPreviousFromStep, mixpanel, step]);

  const handleOnFailStep = useCallback(() => {
    if (hasContentErrors) {
      // Form errors will render
      return;
    }
    // Confirm step completion
    if (!confirmStepEnd({ message: CONFIRM_END_STEP_STR })) {
      return;
    }

    if (typeof onFailStep === 'function') {
      if (mixpanel) {
        mixpanel.track('Step Failed');
      }
      if (isAutoCollapseEnabled) {
        onStepCollapse(step.id, true);
      }
      onFailStep(liveContentRecorded);
      advanceStep();
    }
  }, [
    liveContentRecorded,
    onFailStep,
    onStepCollapse,
    step.id,
    mixpanel,
    confirmStepEnd,
    hasContentErrors,
    advanceStep,
    isAutoCollapseEnabled,
  ]);

  const repeatStep = useCallback(
    (_contentRecorded) => {
      if (typeof onRepeat !== 'function') {
        return;
      }
      // Show confirmation when step not ended
      if (!isEnded && !confirmStepEnd({ message: CONFIRM_REPEAT_STEP_STR, forceConfirm: true })) {
        return;
      }
      // Clear redline state and discard any unsaved edits
      setRedlineState(INITIAL_REDLINE_STATE);

      if (mixpanel) {
        mixpanel.track('Step Repeated');
      }
      onRepeat(_contentRecorded);
    },
    [onRepeat, isEnded, confirmStepEnd, mixpanel, setRedlineState]
  );

  const skipStep = useCallback(
    (_contentRecorded) => {
      if (typeof onSkip !== 'function') {
        return;
      }
      // Always show confirmation message when skipping step
      if (
        !confirmStepEnd({ message: CONFIRM_SKIP_STEP_STR, forceConfirm: true, warnOnEmptyOrUploadingValues: false })
      ) {
        return;
      }
      // Clear redline state and discard any unsaved edits
      setRedlineState(INITIAL_REDLINE_STATE);

      if (mixpanel) {
        mixpanel.track('Step Skipped');
      }

      if (isAutoCollapseEnabled) {
        onStepCollapse(step.id, true);
      }

      // Skip step
      onSkip(_contentRecorded);
      advanceStep();
    },
    [onSkip, confirmStepEnd, mixpanel, isAutoCollapseEnabled, onStepCollapse, step.id, advanceStep, setRedlineState]
  );

  const runStateBannerClass = useMemo(() => {
    if (stepState === STEP_STATE.COMPLETED) {
      return 'bg-app-green-200';
    } else if (stepState === STEP_STATE.FAILED) {
      return 'bg-red-200';
    } else if (stepState === STEP_STATE.SKIPPED) {
      return 'bg-app-gray-400';
    } else if (stepState === STEP_STATE.PAUSED) {
      return 'bg-amber-100';
    }
    return '';
  }, [stepState]);

  const runStateStepBodyClass = useMemo(() => {
    if (stepState === STEP_STATE.COMPLETED) {
      return 'border-2 border-green-400 bg-white';
    } else if (stepState === STEP_STATE.FAILED) {
      return 'border-2 border-red-400 bg-white';
    } else if (stepState === STEP_STATE.SKIPPED) {
      return 'bg-zinc-50 border-2 border-app-gray-400'; /// zinc-50 == #FAFAFA
    } else if (!isStepEnabled && isRun) {
      return 'bg-zinc-100 border-2 border-app-gray-400'; /// zinc-50 == #FAFAFA
    } else {
      // Step has not been completed, failed, nor skipped
      return 'border-2 border-blue-200 bg-white';
    }
  }, [stepState, isStepEnabled, isRun]);

  // TODO (jon): fix that rings are being clipped at top and bottom
  const ringClasses = useMemo(() => {
    if (stepState === STEP_STATE.COMPLETED) {
      return 'ring-2 ring-offset-2 ring-app-green-400 ring-offset-app-green-200';
    } else if (stepState === STEP_STATE.FAILED) {
      return 'ring-2 ring-offset-2 ring-red-400 ring-offset-red-200';
    } else if (stepState === STEP_STATE.SKIPPED) {
      return 'ring-2 ring-offset-2 ring-app-gray-600 ring-offset-app-gray-400';
    }
    return '';
  }, [stepState]);

  const onStepRefChanged = useCallback(
    (element) => {
      if (typeof onRefChanged === 'function') {
        onRefChanged(step.id, element);
      }
    },
    [step, onRefChanged]
  );

  const isAddedStep = useMemo(() => {
    return 'created_during_run' in step && step['created_during_run'] === true;
  }, [step]);

  const redlineComments = useMemo(() => {
    return step && step.redline_comments;
  }, [step]);

  const redlineCommentsButtonLabel = useMemo(() => {
    if (!redlineComments) {
      return null;
    }
    let label = showRedlineComments ? 'Hide' : 'Show';
    label += ` ${Pluralize('suggestion', redlineComments.length, true)}`;
    return label;
  }, [redlineComments, showRedlineComments]);

  const toggleShowRedlineComments = useCallback(
    () => expandRedlineComments && expandRedlineComments(step.id, !showRedlineComments),
    [expandRedlineComments, showRedlineComments, step.id]
  );

  const formatStepKey = useCallback(
    (displayStyle) => {
      if (!stepKey || !sectionKey) {
        return;
      }

      return {
        letters: `${sectionKey}${stepKey}`,
        numbers: `${sectionKey}.${stepKey}`,
      }[displayStyle || 'letters'];
    },
    [sectionKey, stepKey]
  );

  const isAddJiraIssueMenuOptionDisabled = useMemo(() => {
    return (
      !auth.hasPermission(PERM.RUNS_EDIT, projectId) ||
      !isUserParticipant ||
      (isJiraIntegrationEnabled && !isJiraIntegrationEnabled()) ||
      isPreviewMode
    );
  }, [auth, isUserParticipant, projectId, isPreviewMode, isJiraIntegrationEnabled]);

  const isAddIssueMenuOptionDisabled = useMemo(() => {
    return (
      !auth.hasPermission(PERM.RUNS_EDIT, projectId) ||
      !isUserParticipant ||
      (docState !== RUN_STATE.RUNNING && docState !== RUN_STATE.PAUSED) ||
      (isIssuesEnabled && !isIssuesEnabled()) ||
      isPreviewMode
    );
  }, [auth, projectId, isUserParticipant, docState, isIssuesEnabled, isPreviewMode]);

  const isAddStepMenuOptionDisabled = useMemo(() => {
    return (
      !auth.hasPermission(PERM.RUNS_EDIT_STEP, projectId) ||
      !isUserParticipant ||
      docState !== RUN_STATE.RUNNING ||
      !isRepeatable ||
      !getSetting('run_step_edits_enabled', false) ||
      !FEATURE_RUN_STEP_EDITS_ENABLED ||
      isPreviewMode
    );
  }, [auth, docState, getSetting, isRepeatable, isUserParticipant, projectId, isPreviewMode]);

  const isFailStepMenuOptionDisabled = useMemo(() => {
    return (
      !auth.hasPermission(PERM.RUNS_EDIT, projectId) ||
      !isUserParticipant ||
      docState !== RUN_STATE.RUNNING ||
      !baseRunningCondition ||
      hasContentErrors ||
      !signoffUtil.isSignoffRequired(step.signoffs)
    );
  }, [auth, baseRunningCondition, docState, hasContentErrors, isUserParticipant, projectId, step.signoffs]);

  const showRedlineCommentsRow = useMemo(() => {
    if (!(runUtil.isRunStateActive(docState) || docState === RUN_STATE.COMPLETED)) {
      return false;
    }
    return !(redlineState.isActive || !redlineComments);
  }, [docState, redlineComments, redlineState]);

  /**
   * @type {import('./MenuContext').MenuContextAction}
   */
  const skipStepAction = useMemo(() => {
    return {
      type: 'label',
      label: 'Skip Step',
      data: {
        icon: 'step-forward',
        title: 'Skip Step',
        onClick: () => skipStep(liveContentRecorded),
        disabled: !isSkipEnabled,
      },
    };
  }, [liveContentRecorded, isSkipEnabled, skipStep]);

  /**
   * @type {import('./MenuContext').MenuContextAction}
   */
  const repeatStepAction = useMemo(() => {
    return {
      type: 'label',
      label: 'Repeat Step',
      data: {
        icon: 'redo',
        title: 'Repeat Step',
        onClick: () => repeatStep(liveContentRecorded),
        disabled: !isRepeatEnabled,
      },
    };
  }, [liveContentRecorded, isRepeatEnabled, repeatStep]);

  /**
   * @type {import('./MenuContext').MenuContextAction}
   */
  const failStepAction = useMemo(() => {
    return {
      type: 'label',
      label: 'Fail Step',
      data: {
        icon: 'exclamation-circle',
        title: 'Fail Step',
        onClick: handleOnFailStep,
        disabled: isFailStepMenuOptionDisabled,
      },
    };
  }, [handleOnFailStep, isFailStepMenuOptionDisabled]);

  /**
   * @type {import('./MenuContext').MenuContextAction}
   */
  const addStepBelowAction = useMemo(() => {
    return {
      type: 'label',
      label: 'Add Step Below',
      data: {
        icon: 'plus',
        title: 'Add Step Below',
        onClick: () => onAddStepBelow(step),
        disabled: isAddStepMenuOptionDisabled,
      },
    };
  }, [isAddStepMenuOptionDisabled, onAddStepBelow, step]);

  /**
   * @type {import('./MenuContext').MenuContextAction}
   */
  const addJiraIssue = useMemo(() => {
    return {
      type: 'label',
      label: 'Add Jira Issue',
      data: {
        icon: /** @type {import('@fortawesome/fontawesome-svg-core').IconProp} */ ('fa-brands fa-jira'),
        title: 'Add Jira Issue',
        onClick: () => setShowJiraIssueModal(true),
        disabled: isAddJiraIssueMenuOptionDisabled,
      },
    };
  }, [isAddJiraIssueMenuOptionDisabled]);

  /**
   * @type {import('./MenuContext').MenuContextAction}
   */
  const addNcrIssue = useMemo(() => {
    return {
      type: 'label',
      label: 'Add Issue',
      data: {
        icon: 'file-circle-exclamation',
        title: 'Add Issue',
        onClick: () => setShowNcrIssueModal(true),
        disabled: isAddIssueMenuOptionDisabled,
      },
    };
  }, [isAddIssueMenuOptionDisabled]);

  /**
   * @type {import('./MenuContext').MenuContextAction}
   */
  const suggestEditsAction = useMemo(() => {
    return {
      type: 'label',
      label: redlineState.isActive ? 'Close Edits' : 'Suggest Edits',
      data: {
        icon: 'strikethrough',
        title: 'Suggest Edits',
        onClick: () => onToggleRedline(),
        disabled:
          !showsRedlineButton ||
          !isRedlineEnabled ||
          !isStepSettingEnabled(step.step_suggest_edits_enabled, run.step_suggest_edits_enabled),
      },
    };
  }, [
    redlineState.isActive,
    showsRedlineButton,
    isRedlineEnabled,
    step.step_suggest_edits_enabled,
    run.step_suggest_edits_enabled,
    onToggleRedline,
  ]);

  /**
   * @type {Array<import('./MenuContext').MenuContextAction>}
   */
  const menuActions = useMemo(() => {
    const actions = [skipStepAction, repeatStepAction, failStepAction];

    if (isJiraIntegrationEnabled && isJiraIntegrationEnabled()) {
      actions.push(addJiraIssue);
    }

    if (isIssuesEnabled && isIssuesEnabled()) {
      actions.push(addNcrIssue);
    }

    if (canSuggestEdits) {
      actions.push(suggestEditsAction);
      actions.push(addStepBelowAction);
    }

    return actions;
  }, [
    skipStepAction,
    repeatStepAction,
    failStepAction,
    isJiraIntegrationEnabled,
    isIssuesEnabled,
    canSuggestEdits,
    addJiraIssue,
    addNcrIssue,
    addStepBelowAction,
    suggestEditsAction,
  ]);

  const isMenuEnabled = useMemo(() => {
    return showStepMenu && runUtil.isRunStateActive(docState) && auth.hasPermission(PERM.RUNS_EDIT, projectId);
  }, [showStepMenu, docState, auth, projectId]);

  const stepHeaderHasValues = useMemo(() => procedureUtil.stepHeaderHasValues(step), [step]);

  const hasDependencies = useMemo(() => {
    const hasStepDependencies = Boolean(
      step.dependencies &&
        step.dependencies.length &&
        step.dependencies[0] &&
        step.dependencies[0].dependent_ids &&
        step.dependencies[0].dependent_ids.length
    );
    const stepId = step.id?.endsWith('__removed') ? step.id.replace('__removed', '') : step.id;
    const hasStepConditionals =
      Object.values(sourceStepConditionalsMap?.[stepId] || {}).length > 0 ||
      Object.values(removedSourceStepConditionalsMap?.[stepId] || {}).length > 0;
    return hasStepDependencies || hasStepConditionals;
  }, [step, sourceStepConditionalsMap, removedSourceStepConditionalsMap]);

  const showPreviousNextButtons = useMemo(() => {
    return (
      runUtil.isRunStateActive(docState) &&
      !signoffUtil.isSignoffRequired(step.signoffs) &&
      isPreviousStepRequirementFulfilled
    );
  }, [docState, step, isPreviousStepRequirementFulfilled]);

  const isPreviousButtonEnabled = useMemo(() => {
    return isUserParticipant && auth.hasPermission(PERM.RUNS_EDIT, projectId) && docState === RUN_STATE.RUNNING;
  }, [isUserParticipant, auth, projectId, docState]);

  const isNextButtonEnabled = useMemo(() => {
    return (
      isUserParticipant &&
      auth.hasPermission(PERM.RUNS_EDIT, projectId) &&
      areConditionalAndStepDependenciesFulfilled &&
      docState === RUN_STATE.RUNNING
    );
  }, [isUserParticipant, auth, projectId, areConditionalAndStepDependenciesFulfilled, docState]);

  const showSignOffButton = useMemo(() => {
    return (
      signoffUtil.isSignoffRequired(step.signoffs) &&
      stepState !== STEP_STATE.FAILED &&
      stepState !== STEP_STATE.SKIPPED
    );
  }, [step, stepState]);

  const isNotifyOperatorsButtonVisible = useMemo(
    () =>
      runUtil.isRunStateActive(docState) &&
      auth.hasPermission(PERM.RUNS_EDIT, projectId) &&
      !hasGenericSignoffs &&
      step.signoffs &&
      step.signoffs.length > 0 &&
      notificationListenerConnected &&
      !isPreviewMode,
    [docState, auth, projectId, hasGenericSignoffs, step.signoffs, notificationListenerConnected, isPreviewMode]
  );

  const [isNotifying, setIsNotifying] = useState(false);
  const [notificationSuccess, setNotificationSuccess] = useState(null);
  const handleOnNotifyRemainingOperators = useCallback(() => {
    setIsNotifying(true);
    notifyRemainingStepOperators(step.id)
      .then(() => {
        setNotificationSuccess(true);
        setTimeout(() => {
          setNotificationSuccess(null);
          setIsNotifying(false);
        }, FLASH_MSG_MS);
      })
      .catch(() => {
        setNotificationSuccess(false);
        setTimeout(() => {
          setNotificationSuccess(null);
          setIsNotifying(false);
        }, FLASH_MSG_MS);
      });
  }, [step.id, notifyRemainingStepOperators]);

  const formattedStepKey = useMemo(() => {
    return customStepKey ?? formatStepKey(config && config.display_sections_as);
  }, [config, customStepKey, formatStepKey]);

  const subStepKeys = useMemo(() => {
    let currentSubStepKey = 1;
    const numbers = {};
    step.content.forEach((content) => {
      const { substepKey: key, nextNumber } = procedureUtil.displaySubStepKey(
        formattedStepKey,
        currentSubStepKey,
        content.type
      );
      numbers[content.id] = key;
      currentSubStepKey = nextNumber;
    });
    return numbers;
  }, [step.content, formattedStepKey]);

  const isNotifyOperatorsButtonEnabled = useMemo(
    () =>
      areConditionalAndStepDependenciesFulfilled &&
      isPreviousStepRequirementFulfilled &&
      isUserParticipant &&
      !isNotifying &&
      !isEnded,
    [
      areConditionalAndStepDependenciesFulfilled,
      isPreviousStepRequirementFulfilled,
      isUserParticipant,
      isNotifying,
      isEnded,
    ]
  );

  const toTheSideImages = useMemo(() => {
    const toTheSideImages = [];
    if (step.content) {
      step.content.forEach((content) => {
        if (
          content.type.toLowerCase() === 'attachment' &&
          content.display_style === AttachmentImageDisplayStyle.ToTheSide
        ) {
          toTheSideImages.push(content);
        }
      });
    }
    return toTheSideImages;
  }, [step.content]);

  const hasToTheSideImages = useMemo(() => {
    return toTheSideImages.length > 0;
  }, [toTheSideImages]);

  // Makes the images in line if they are the only step contents
  const onlyHasToTheSideImages = useMemo(() => {
    return step.content && toTheSideImages.length === step.content.length;
  }, [toTheSideImages, step.content]);

  const shouldDisplayToTheSideImages = useMemo(() => {
    return hasToTheSideImages && !onlyHasToTheSideImages && !isMobile;
  }, [hasToTheSideImages, onlyHasToTheSideImages, isMobile]);

  const colSpanValue = useMemo(() => {
    return shouldDisplayToTheSideImages ? '2' : '3';
  }, [shouldDisplayToTheSideImages]);

  const stepDataTestId = useMemo(() => {
    return `step-${formatStepKey(config && config.display_sections_as)}-${repeatKey || 0}`;
  }, [config, repeatKey, formatStepKey]);

  const stepBannerEnabled = useMemo(() => {
    return (hasDependencies && !isSingleCardEnabled) || step.timer || step.duration || isAddedStep;
  }, [hasDependencies, isSingleCardEnabled, step.timer, step.duration, isAddedStep]);

  // this padding aligns the step title baseline with the step number (A1, A2, A3, etc.) baseline
  const stepTitlePadding = useMemo(() => {
    return 'pt-[0.071rem]'; // neither
  }, []);

  const saveStepComment = useCallback(
    async (comment) => {
      if (typeof saveNewComment !== 'function') {
        return;
      }
      return saveNewComment(comment, { sectionId, stepId: step.id });
    },
    [saveNewComment, sectionId, step.id]
  );

  const editStepComment = useCallback(
    async (comment) => {
      if (typeof editComment !== 'function') {
        return;
      }
      return editComment(comment, { sectionId, stepId: step.id });
    },
    [editComment, sectionId, step.id]
  );

  const getSaveTableTextComment = useCallback(
    (contentId) => {
      if (typeof saveNewComment !== 'function') {
        return;
      }
      return async (rowIndex, columnIndex, newCommentText, mentions) => {
        const trimmed = procedureUtil.trimLeadingAndTrailing(newCommentText);
        const newMentions = procedureUtil.getValidMentions(mentions, trimmed);

        const comment = {
          id: generateCommentId(),
          parent_id: '',
          text: trimmed,
          mention_list: newMentions,
        };
        return saveNewComment(comment, {
          sectionId,
          stepId: step.id,
          contentId,
          rowIndex,
          columnIndex,
        });
      };
    },
    [saveNewComment, sectionId, step.id]
  );

  const getEditTableTextComment = useCallback(
    (contentId, cells) => {
      if (typeof editComment !== 'function') {
        return;
      }
      return async (rowIndex, columnIndex, editedActivity, commentId) => {
        const existingCommentList = cells[rowIndex][columnIndex];
        if (!existingCommentList || existingCommentList.length === 0) {
          return;
        }
        const existingComment = existingCommentList.find((_comment) => _comment.id === commentId);
        if (!existingComment) {
          return;
        }
        const editedComment = {
          ...existingComment,
          text: editedActivity.comment,
          mention_list: editedActivity.mentions,
          updated_at: editedActivity.updated_at,
        };

        return editComment(editedComment, {
          sectionId,
          stepId: step.id,
          contentId,
          rowIndex,
          columnIndex,
        });
      };
    },
    [editComment, sectionId, step.id]
  );

  const skipStepEnabledSetting = useMemo(
    () => ({
      settingValue: isStepSettingEnabled(
        step.skip_step_enabled,
        isRun ? run.skip_step_enabled : procedure.skip_step_enabled
      ),
      visibleValue: !isStepSettingEnabled(undefined, isRun ? run.skip_step_enabled : procedure.skip_step_enabled),
      icon: faStepForward,
      tooltipText: 'Skip Step',
    }),
    [isRun, procedure.skip_step_enabled, run.skip_step_enabled, step.skip_step_enabled]
  );

  const repeatStepEnabledSetting = useMemo(
    () => ({
      settingValue: isStepSettingEnabled(
        step.repeat_step_enabled,
        isRun ? run.repeat_step_enabled : procedure.repeat_step_enabled
      ),
      visibleValue: !isStepSettingEnabled(undefined, isRun ? run.repeat_step_enabled : procedure.repeat_step_enabled),
      icon: faRedo,
      tooltipText: 'Repeat Step',
    }),
    [isRun, procedure.repeat_step_enabled, run.repeat_step_enabled, step.repeat_step_enabled]
  );

  const suggestEditsEnabledSetting = useMemo(
    () => ({
      settingValue: isStepSettingEnabled(
        step.step_suggest_edits_enabled,
        isRun ? run.step_suggest_edits_enabled : procedure.step_suggest_edits_enabled
      ),
      visibleValue: !isStepSettingEnabled(
        undefined,
        isRun ? run.step_suggest_edits_enabled : procedure.step_suggest_edits_enabled
      ),
      icon: faStrikethrough,
      tooltipText: 'Suggest Edits',
    }),
    [isRun, procedure.step_suggest_edits_enabled, run.step_suggest_edits_enabled, step.step_suggest_edits_enabled]
  );

  useEffect(() => {
    (async () => {
      if (step.completedAt) {
        return;
      }
      if (!inventoryUtil.hasPartCheckInOutBlocks(run)) {
        return;
      }
      if (!inventoryUtil.hasPartCheckInBlocks(step)) {
        return;
      }
      const allItems = await services.manufacturing.listItems();
      const checkedOutInventory = inventoryUtil.checkedOutInventory(run, allItems);
      setCheckedOutInventory(checkedOutInventory);
    })().catch((err) => {
      apm.captureError(err);
    });
  }, [run, services.manufacturing, step]);

  if (isRun && !isStepVisible(step)) {
    return null;
  }

  const renderStepDetails = (stepDetail) => {
    if (stepDetail.id === 'duration' && typeof step[stepDetail.id] === 'object') {
      // No-op
    } else if (stepDetail.id === 'timer' && typeof step[stepDetail.id] === 'object') {
      // No-op
    } else {
      return <StepDetail icon={stepDetail.icon} label={stepDetail.title} value={step[stepDetail.id]} />;
    }
  };

  return (
    <tbody data-testid={stepDataTestId}>
      <tr>
        <td colSpan={3}>
          {redlineState.isActive && (
            <SelectionContextProvider>
              <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
                <ProcedureStepEdit
                  step={step.redlines && step.redlines.length > 0 ? step.redlines[step.redlines.length - 1].step : step}
                  isPending={false}
                  isDisabled={docState === RUN_STATE.PAUSED || !isUserParticipant}
                  sectionId={sectionId}
                  stepId={step.id}
                  precedingStepId={undefined}
                  onSave={(updatedStep, isRedline, comments) => {
                    onSaveFullStepSuggestedEdit({
                      step,
                      updatedStep,
                      sectionId,
                      stepId: step.id,
                      isRedline,
                      comments,
                    });
                    onToggleRedline();
                  }}
                  onCancel={onToggleRedline}
                  configurePartKitBlock={configurePartKitBlock}
                  configurePartBuildBlock={configurePartBuildBlock}
                  stepKey={formattedStepKey}
                  allowComments={true}
                />
              </table>
            </SelectionContextProvider>
          )}
          {!redlineState.isActive && (
            <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
              <thead>
                <tr>
                  <th className="w-8"></th>
                  <th className="w-full"></th>
                  <th className="w-8"></th>
                </tr>
              </thead>
              <tbody aria-label="Step" role="region">
                <tr>
                  <td></td>
                  <td>
                    <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
                      <thead>
                        <tr>
                          <th className="w-4"></th>
                          <th className="w-full"></th>
                          <th className="w-0"></th>
                        </tr>
                      </thead>
                      {stepHeaderHasValues && (
                        <>
                          <tbody>
                            <tr>
                              <td>
                                <div
                                  className="w-0 h-0"
                                  ref={stepGotoRef}
                                  style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
                                ></div>
                              </td>
                            </tr>
                          </tbody>
                          <ProcedureStepHeader
                            header={step.headers[0]}
                            onRefChanged={onRefChanged}
                            scrollMarginTopValueRem={scrollToBufferRem}
                          />
                        </>
                      )}
                      {stepBannerEnabled && (
                        <ProcedureStepBanner
                          step={step}
                          isRun={isRun}
                          hasDependencies={hasDependencies}
                          isSingleCardEnabled={isSingleCardEnabled}
                          areConditionalAndStepDependenciesFulfilled={areConditionalAndStepDependenciesFulfilled}
                          onRefChanged={onRefChanged}
                          scrollMarginTopValueRem={scrollToBufferRem}
                          updateStepDetail={updateStepDetail}
                          baseRunningCondition={baseRunningCondition}
                          isStepActive={isStepActive}
                          stepState={stepState}
                          isAddedStep={isAddedStep}
                          addedStepTooltip={revisionsUtil.getSuggestedEditMessage(step.run_only, 'added step')}
                        />
                      )}
                      <tbody
                        aria-label="Step Body"
                        className={`shadow-lg shadow rounded ${runStateStepBodyClass}`}
                        ref={stepHeaderHasValues ? null : stepGotoRef}
                        style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
                        role="region"
                      >
                        {/* Title and completion checkbox row */}
                        <tr className={runStateBannerClass}>
                          <td className="align-top">
                            <div className={generateHiddenClassString('flex py-2', isHidden)}>
                              <div className="h-9">
                                {!isSingleCardEnabled && (
                                  <ExpandCollapseCaret
                                    isExpanded={!isStepCollapsed}
                                    onClick={toggleIsStepCollapsed}
                                    ariaLabel="Expand Collapse Step Toggle"
                                    isHidden={!onStepCollapse}
                                  />
                                )}
                              </div>
                            </div>
                          </td>
                          <td colSpan={2} className="break-words">
                            <div
                              ref={onStepRefChanged}
                              className={generateHiddenClassString(
                                'ml-4 py-2 flex items-start app-border-gray-4 page-break',
                                isHidden
                              )}
                              style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
                            >
                              {/* Step key */}
                              <button
                                className={`h-8 w-8 mt-0.5 flex justify-center items-center rounded-full bg-black ${ringClasses}`}
                                onClick={toggleIsStepCollapsed}
                              >
                                <span className="font-bold text-xs text-white">
                                  {formattedStepKey ? formattedStepKey : '--'}
                                </span>
                              </button>
                              {/* Step title bar */}
                              <div className="ml-4 w-8 flex flex-row grow justify-between">
                                {/* Step title bar left side content */}
                                <div className="flex grow font-semibold">
                                  <div className={`grow ${stepTitlePadding}`}>
                                    <ProcedureFieldNoRedlines
                                      fieldName="name"
                                      fieldValue={step.name}
                                      redlines={redlineStepFieldChanges('name')}
                                      onLabelClick={toggleIsStepCollapsed}
                                      isBold={true}
                                    />
                                  </div>
                                </div>

                                {/* TODO (jon): refactor this gnarly jsx */}
                                {/* Step title bar right side content */}
                                <div className="flex items-start gap-x-1">
                                  {repeatKey && (
                                    <div className="mt-2 px-2 whitespace-nowrap flex-none">
                                      <span className="text-sm text-gray-600">
                                        <FontAwesomeIcon icon="redo" />
                                      </span>
                                      <span className="ml-1 text-sm font-bold text-gray-600 italic">
                                        Repeat {repeatKey}
                                      </span>
                                    </div>
                                  )}
                                  {stepState === STEP_STATE.FAILED && (
                                    <div className="mt-2 px-2 whitespace-nowrap flex-none">
                                      <span className="text-sm text-gray-600">
                                        <FontAwesomeIcon icon="exclamation-circle" />
                                      </span>
                                      <span className="ml-1 text-sm font-bold text-gray-600 italic">Failed</span>
                                    </div>
                                  )}
                                  {/* Content with icon for skip */}
                                  {stepState === STEP_STATE.SKIPPED && (
                                    <div className="mt-2 px-2 whitespace-nowrap flex-none">
                                      <span className="text-sm text-gray-600">
                                        <FontAwesomeIcon icon="step-forward" aria-label="skipped" />
                                      </span>
                                      <span className="ml-1 text-sm font-bold text-gray-600 italic">Skipped</span>
                                    </div>
                                  )}
                                  {/* Notify operators button*/}
                                  {isNotifyOperatorsButtonVisible && (
                                    <div className="relative">
                                      <Button
                                        type={BUTTON_TYPES.TERTIARY}
                                        title="Notify Remaining Operator(s)"
                                        ariaLabel="Notify Remaining Operators Button"
                                        leadingIcon={fasPaperPlane}
                                        isDisabled={!isNotifyOperatorsButtonEnabled}
                                        onClick={handleOnNotifyRemainingOperators}
                                      />
                                      {notificationSuccess !== null && (
                                        <div className="absolute -left-8 top-10 m-auto shadow-md z-10">
                                          <div
                                            className={`px-2 py-1 rounded 0 text-center ${
                                              notificationSuccess
                                                ? 'bg-green-200 text-green-700'
                                                : 'bg-red-200 text-red-700'
                                            }`}
                                          >
                                            {notificationSuccess ? 'Notifications Sent' : 'Notification Failed'}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {step.redlines && step.redlines.length > 0 && (
                                    <div className="relative">
                                      <Button
                                        ariaLabel="View Suggested Edits"
                                        type={BUTTON_TYPES.TERTIARY}
                                        leadingIcon="strikethrough"
                                        iconTextColor={
                                          step.redlines[step.redlines.length - 1].run_only
                                            ? 'text-blue-500'
                                            : 'text-red-500'
                                        }
                                        onClick={() => setShowSuggestedEditsModal(true)}
                                      />
                                      {!isCurrentLatest && (
                                        <FontAwesomeIcon
                                          icon={faCircle}
                                          className={`absolute top-1.5 right-1 ${
                                            step.redlines[step.redlines.length - 1].run_only
                                              ? 'text-blue-800'
                                              : 'text-red-800'
                                          }`}
                                          style={{
                                            fontSize: '10px',
                                            fontWeight: '700',
                                            lineHeight: '1',
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                                  {showSuggestedEditsModal && (
                                    <FullStepSuggestedEditsModal
                                      currentStep={step}
                                      redlines={step.redlines}
                                      onClose={() => setShowSuggestedEditsModal(false)}
                                      acceptAction={(redline) =>
                                        onIncludeFullStepSuggestedEdit({
                                          step,
                                          sectionId,
                                          stepId: step.id,
                                          redline,
                                        })
                                      }
                                      editComment={
                                        canEditSuggestedEditComment
                                          ? (args) => onEditSuggestedEditComment({ stepId: step.id, ...args })
                                          : undefined
                                      }
                                    />
                                  )}
                                  {showSettings && !isRun && (
                                    <div className="flex flex-row items-center">
                                      <SettingBadge
                                        settingValue={skipStepEnabledSetting.settingValue}
                                        visibleSettingValue={skipStepEnabledSetting.visibleValue}
                                        tooltipText={skipStepEnabledSetting.tooltipText}
                                        icon={skipStepEnabledSetting.icon}
                                      />
                                      <SettingBadge
                                        settingValue={repeatStepEnabledSetting.settingValue}
                                        visibleSettingValue={repeatStepEnabledSetting.visibleValue}
                                        tooltipText={repeatStepEnabledSetting.tooltipText}
                                        icon={repeatStepEnabledSetting.icon}
                                      />
                                      <SettingBadge
                                        settingValue={suggestEditsEnabledSetting.settingValue}
                                        visibleSettingValue={suggestEditsEnabledSetting.visibleValue}
                                        tooltipText={suggestEditsEnabledSetting.tooltipText}
                                        icon={suggestEditsEnabledSetting.icon}
                                      />
                                    </div>
                                  )}
                                  {showSettings && isBatchStep(procedure, step) && (
                                    <div className="flex flex-row items-center">
                                      <SettingBadge
                                        settingValue={true}
                                        visibleSettingValue={true}
                                        icon={faLayerGroup}
                                        tooltipText="Batch Step"
                                      />
                                      {'batchProps' in step && (
                                        <span className="text-sm text-gray-500">
                                          {step.batchProps.index + 1}/{step.batchProps.batchSize}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                  {/* Content with checkbox for signoffs */}
                                  <div className="flex flex-col items-end px-2">
                                    <div className="flex flex-row flex-wrap justify-end gap-x-2 gap-y-2">
                                      {showSignOffButton &&
                                        step.signoffs.map((signoff) => (
                                          <ProcedureStepSignoffButton
                                            key={signoff.id}
                                            signoff={signoff}
                                            isSignedOff={signOffComplete(signoff.id)}
                                            onSignOff={handleOnSignoff}
                                            onPinSignoff={handleOnPinSignoff}
                                            isPinSignoffEnabled={isPinSignoffEnabled}
                                            onRevokeSignoff={handleOnRevokeSignoff}
                                            isRevokeEnabled={isRevokeSignoffEnabledForStep}
                                            userStepRoles={userStepRoles}
                                            isStepEnabled={isStepEnabled && isTimingComplete}
                                            isStepCollapsed={isStepCollapsed}
                                            completedByAutomation={completedByAutomation(signoff.id)}
                                            isOptionMenuAlwaysVisible={isRun}
                                            hasUserSignoffOnOtherRole={hasOperatorAlreadySigned}
                                          />
                                        ))}
                                    </div>
                                  </div>
                                  {isMenuEnabled && (
                                    <div className="print:hidden mr-1">
                                      <ThreeDotMenu
                                        menuActions={menuActions}
                                        menuLabel="Step Menu"
                                        hasPadding={false}
                                        boxSize="sm"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        {!isStepCollapsed && (visibleStepDetails.length > 0 || hasDependencies) && (
                          <tr>
                            <td colSpan={3}>
                              <div className={generateHiddenClassString('', isStepCollapsed)}></div>
                              <div
                                className={generateHiddenClassString(
                                  `flex flex-col flex-nowrap ${runStateBannerClass}`,
                                  isStepCollapsed
                                )}
                              >
                                <div className="w-11 mr-1"></div>
                                <div className="flex flex-row flex-wrap gap-x-3 items-start text-sm font-medium ml-10">
                                  {visibleStepDetails.map((stepDetail) => (
                                    <div key={stepDetail.id}>{renderStepDetails(stepDetail)}</div>
                                  ))}
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                        {!isStepCollapsed && (
                          <tr>
                            <td colSpan={3} className="align-top">
                              <table
                                className="table-fixed w-full border-collapse"
                                cellSpacing="0"
                                cellPadding="0"
                                border={0}
                              >
                                <thead>
                                  {shouldDisplayToTheSideImages && (
                                    <tr>
                                      <th className="w-4"></th>
                                      <th className="w-2/4"></th>
                                      <th className="w-2/4"></th>
                                    </tr>
                                  )}
                                  {!shouldDisplayToTheSideImages && (
                                    <tr>
                                      <th className="w-4"></th>
                                      <th className="w-auto"></th>
                                    </tr>
                                  )}
                                </thead>
                                <tbody>
                                  <tr>
                                    <td colSpan={colSpanValue} className="align-top">
                                      <table
                                        className="table-fixed w-full border-collapse"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        border={0}
                                      >
                                        <thead>
                                          <tr>
                                            <th className="w-4"></th>
                                            <th className="w-auto"></th>
                                            <th className="w-64"></th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {/* Step content blocks */}
                                          {step.content &&
                                            step.content.map((content, contentIndex) => (
                                              <CommentWrapper
                                                key={contentIndex}
                                                stepId={step.id}
                                                comments={step.comments}
                                                content={content}
                                                isRun={isRun}
                                                isCommentingDisabled={isPreviewMode || endedRunCommentsDisabled}
                                                saveNewComment={saveStepComment}
                                                editComment={editStepComment}
                                              >
                                                <Fragment>
                                                  {/* Render text content row */}
                                                  {/* TODO: Refactor to use ProcedureBlockRun */}
                                                  {/* Check type of content.text because empty string is falsey */}
                                                  {content.type.toLowerCase() === 'text' && (
                                                    <BlockTextNoRedlines
                                                      block={content}
                                                      blockLabel={subStepKeys[content.id]}
                                                      setExpressionRecorded={getRecordExpressionSetter(contentIndex)}
                                                      redlines={redlineBlockChanges(contentIndex)}
                                                      isHidden={isStepCollapsed}
                                                      onRefChanged={onRefChanged}
                                                      scrollMarginTopValueRem={scrollToBufferRem}
                                                    />
                                                  )}
                                                  {/* Render telemetry content row */}
                                                  {/* TODO: Refactor to use ProcedureBlockRun */}
                                                  {content.type.toLowerCase() === 'telemetry' && (
                                                    <BlockTelemetry
                                                      blockLabel={subStepKeys[content.id]}
                                                      telemetry={content}
                                                      docState={docState}
                                                      onTelemetryStateChange={recordValuesChanged}
                                                      canBeStale={!isEnded}
                                                      shouldPausePlot={
                                                        signoffUtil.anySignoffsComplete(step) ||
                                                        stepState === STEP_STATE.FAILED ||
                                                        stepState === STEP_STATE.SKIPPED
                                                      }
                                                    />
                                                  )}
                                                  {/* Render commanding content row */}
                                                  {content.type.toLowerCase() === 'commanding' && (
                                                    <BlockCommanding
                                                      blockLabel={subStepKeys[content.id]}
                                                      commanding={content}
                                                      contentIndex={contentIndex}
                                                      docState={docState}
                                                      isCompleteEnabled={isStepEnabled && !isPreviewMode}
                                                      isHidden={isStepCollapsed}
                                                      isSpacerHidden={false}
                                                      stepName={step.name}
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      onFieldValueChanged={recordValuesChanged}
                                                    />
                                                  )}
                                                  {/* Render procedure block */}
                                                  {(content.type.toLowerCase() === 'input' ||
                                                    content.type.toLowerCase() === 'alert' ||
                                                    content.type.toLowerCase() === 'requirement' ||
                                                    content.type.toLowerCase() === 'external_item') && (
                                                    <ProcedureBlockRunNoRedlines
                                                      block={content}
                                                      redlines={redlineBlockChanges(contentIndex)}
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      blockLabel={subStepKeys[content.id]}
                                                      contentIndex={contentIndex}
                                                      isEnabled={isContentCompleteEnabled}
                                                      isHidden={isStepCollapsed}
                                                      onRecordValuesChanged={recordValuesChanged}
                                                      onRecordErrorsChanged={onRecordErrorsChanged}
                                                      onRecordUploadingChanged={onRecordUploadingChanged}
                                                      onContentRefChanged={onRefChanged}
                                                      scrollMarginTopValueRem={scrollToBufferRem}
                                                      isSpacerHidden={false}
                                                      isDark={false}
                                                      setExpressionRecorded={getRecordExpressionSetter(contentIndex)}
                                                    />
                                                  )}
                                                  {/* Render Table input */}
                                                  {content.type.toLowerCase() === 'table_input' && (
                                                    <RunTableInput
                                                      content={content}
                                                      blockLabel={subStepKeys[content.id]}
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      contentIndex={contentIndex}
                                                      isHidden={isStepCollapsed}
                                                      isEnabled={isContentCompleteEnabled}
                                                      onFieldValueChange={recordValuesChanged}
                                                      onFieldErrorsChanged={onRecordErrorsChanged}
                                                      isSpacerHidden={false}
                                                      saveTextComment={getSaveTableTextComment(content.id)}
                                                      editTextComment={getEditTableTextComment(
                                                        content.id,
                                                        content.cells
                                                      )}
                                                    />
                                                  )}
                                                  {/* Render procedure link row */}
                                                  {content.type.toLowerCase() === 'procedure_link' && (
                                                    <BlockProcedureLink
                                                      docId={runId}
                                                      blockLabel={subStepKeys[content.id]}
                                                      docState={docState}
                                                      operation={operation}
                                                      contentIndex={contentIndex}
                                                      link={content}
                                                      onStartLinkedRun={onStartLinkedRun}
                                                      isHidden={isStepCollapsed}
                                                      isEnabled={isStepEnabled && !isPreviewMode}
                                                      addCompletedRunId={addCompletedRunId}
                                                      sourceName={sourceName}
                                                    />
                                                  )}
                                                  {/* Render attachments row */}
                                                  {content.type.toLowerCase() === 'attachment' &&
                                                    (onlyHasToTheSideImages ||
                                                      isMobile ||
                                                      !toTheSideImages.includes(content)) && (
                                                      <BlockAttachment
                                                        attachment={content}
                                                        blockLabel={subStepKeys[content.id]}
                                                        isHidden={isStepCollapsed}
                                                        isSpacerHidden={false}
                                                      />
                                                    )}
                                                  {/* Render jump to row */}
                                                  {content.type.toLowerCase() === ProcedureContentBlockTypes.JumpTo && (
                                                    <JumpTo
                                                      content={content}
                                                      blockLabel={subStepKeys[content.id]}
                                                      isHidden={isStepCollapsed}
                                                    />
                                                  )}
                                                  {/* Render reference row */}
                                                  {content.type.toLowerCase() ===
                                                    ProcedureContentBlockTypes.Reference && (
                                                    <ReferenceBlock
                                                      runId={runId}
                                                      originalReferencedContentId={content.reference}
                                                      originalReferencedSubtype={content.sub_reference}
                                                      originalReferencedFieldIndex={content.field_index}
                                                      blockLabel={subStepKeys[content.id]}
                                                      isHidden={Boolean(isStepCollapsed)}
                                                    />
                                                  )}
                                                  {/* Render expression row */}
                                                  {content.type.toLowerCase() ===
                                                    ProcedureContentBlockTypes.Expression && (
                                                    <ExpressionBlock
                                                      name={content.name}
                                                      tokens={content.tokens}
                                                      /*
                                                       * Use only content.recorded from the run, because the live runStep data starts as an
                                                       *  object with empty values instead of undefined
                                                       */
                                                      recorded={content.recorded}
                                                      setExpressionRecorded={getRecordExpressionSetter(contentIndex)}
                                                      blockLabel={subStepKeys[content.id]}
                                                      isHidden={Boolean(isStepCollapsed)}
                                                    />
                                                  )}
                                                  {/* Render part kit */}
                                                  {content.type.toLowerCase() ===
                                                    ProcedureContentBlockTypes.PartKit && (
                                                    <PartKit
                                                      content={content}
                                                      contentIndex={contentIndex}
                                                      onRecordValuesChanged={recordValuesChanged}
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      isHidden={Boolean(isStepCollapsed)}
                                                      isEnabled={isContentCompleteEnabled}
                                                      blockLabel={subStepKeys[content.id]}
                                                      isStepComplete={stepState === STEP_STATE.COMPLETED}
                                                      teamId={currentTeamId}
                                                      batchProps={step.batchProps}
                                                    />
                                                  )}
                                                  {/* Render part kit */}
                                                  {content.type.toLowerCase() ===
                                                    ProcedureContentBlockTypes.PartBuild && (
                                                    <PartBuild
                                                      content={content}
                                                      onRecordValuesChanged={recordValuesChanged}
                                                      onRecordErrorsChanged={(errors) =>
                                                        onRecordErrorsChanged(contentIndex, errors)
                                                      }
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      isHidden={Boolean(isStepCollapsed)}
                                                      isEnabled={isContentCompleteEnabled}
                                                      blockLabel={subStepKeys[content.id]}
                                                      isStepComplete={stepState === STEP_STATE.COMPLETED}
                                                      teamId={currentTeamId}
                                                      checkedOutItems={checkedOutInventory}
                                                    />
                                                  )}
                                                  {/* Render inventory detail input*/}
                                                  {content.type.toLowerCase() ===
                                                    ProcedureContentBlockTypes.InventoryDetailInput && (
                                                    <InventoryDetailInput
                                                      content={content}
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      onRecordValuesChanged={recordValuesChanged}
                                                      isEnabled={isContentCompleteEnabled}
                                                      blockLabel={subStepKeys[content.id]}
                                                    />
                                                  )}
                                                  {/* Render tool check-out */}
                                                  {content.type.toLowerCase() ===
                                                    ProcedureContentBlockTypes.ToolCheckOut && (
                                                    <RunToolCheckOutIn
                                                      content={content}
                                                      type="out"
                                                      onRecordValuesChanged={recordValuesChanged}
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      isEnabled={isContentCompleteEnabled}
                                                      stepState={stepState}
                                                      teamId={currentTeamId}
                                                    />
                                                  )}
                                                  {/* Render tool check-in */}
                                                  {content.type.toLowerCase() ===
                                                    ProcedureContentBlockTypes.ToolCheckIn && (
                                                    <RunToolCheckOutIn
                                                      content={content}
                                                      type="in"
                                                      onRecordValuesChanged={recordValuesChanged}
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      isEnabled={isContentCompleteEnabled}
                                                      stepState={stepState}
                                                      teamId={currentTeamId}
                                                    />
                                                  )}
                                                  {/* Render part usage */}
                                                  {content.type.toLowerCase() ===
                                                    ProcedureContentBlockTypes.PartUsage && (
                                                    <PartUsage
                                                      content={content}
                                                      errors={contentErrors && contentErrors[contentIndex]}
                                                      onRecordValuesChanged={recordValuesChanged}
                                                      onRecordErrorsChanged={(errors) =>
                                                        onRecordErrorsChanged(contentIndex, errors)
                                                      }
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      isHidden={Boolean(isStepCollapsed)}
                                                      isEnabled={isContentCompleteEnabled}
                                                      blockLabel={subStepKeys[content.id]}
                                                      teamId={currentTeamId}
                                                    />
                                                  )}
                                                  {/* Render tool usage */}
                                                  {content.type.toLowerCase() ===
                                                    ProcedureContentBlockTypes.ToolUsage && (
                                                    <RunToolUsage
                                                      content={content}
                                                      errors={contentErrors && contentErrors[contentIndex]}
                                                      onRecordValuesChanged={recordValuesChanged}
                                                      onRecordErrorsChanged={(errors) =>
                                                        onRecordErrorsChanged(contentIndex, errors)
                                                      }
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      isEnabled={isContentCompleteEnabled}
                                                      stepState={stepState}
                                                      teamId={currentTeamId}
                                                    />
                                                  )}
                                                  {content.type.toLowerCase() ===
                                                    ProcedureContentBlockTypes.TestCases && (
                                                    <TestCasesBlock
                                                      content={content}
                                                      recorded={pendingOrRecordedContent(content, contentIndex)}
                                                      isHidden={Boolean(isStepCollapsed)}
                                                      isEnabled={isContentCompleteEnabled}
                                                      blockLabel={subStepKeys[content.id]}
                                                      onRecordValuesChanged={recordValuesChanged}
                                                      isStepComplete={stepState === STEP_STATE.COMPLETED}
                                                    />
                                                  )}
                                                  {content.type.toLowerCase() ===
                                                    ProcedureContentBlockTypes.FieldInputTable && (
                                                    <FieldInputTable
                                                      content={content}
                                                      recorded={getFieldInputTableRecordedData(content, contentIndex)}
                                                      isHidden={Boolean(isStepCollapsed)}
                                                      isEnabled={isContentCompleteEnabled}
                                                      blockLabel={subStepKeys[content.id]}
                                                      onRecordValuesChanged={recordValuesChanged}
                                                      onRecordErrorsChanged={(errors) =>
                                                        onRecordErrorsChanged(contentIndex, errors)
                                                      }
                                                      onRefChanged={onRefChanged}
                                                    />
                                                  )}
                                                </Fragment>
                                              </CommentWrapper>
                                            ))}
                                        </tbody>
                                      </table>
                                    </td>
                                    {shouldDisplayToTheSideImages && (
                                      <td className="align-top">
                                        <table
                                          className="table-fixed w-full border-collapse"
                                          cellSpacing="0"
                                          cellPadding="0"
                                          border={0}
                                        >
                                          <tbody>
                                            {/* Render side by side images */}
                                            {toTheSideImages.map((content, contentIndex) => (
                                              <Fragment key={contentIndex}>
                                                <BlockAttachment
                                                  attachment={content}
                                                  isHidden={isStepCollapsed}
                                                  isSpacerHidden={true}
                                                  blockLabel={subStepKeys[content.id]}
                                                />
                                              </Fragment>
                                            ))}
                                          </tbody>
                                        </table>
                                      </td>
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}

                        {/* Step conditionals information */}
                        {!isStepCollapsed && step.conditionals && step.conditionals.length > 0 && (
                          <tr>
                            <td colSpan={3}>
                              <div className={generateHiddenClassString('', isStepCollapsed)}></div>
                              <div className={generateHiddenClassString('flex flex-row flex-nowrap', isStepCollapsed)}>
                                <div className="w-11 mr-1"></div>
                                <div className="flex flex-row flex-wrap gap-x-3 items-start text-sm font-medium">
                                  <StepConditionals step={step} conditionals={step.conditionals} />
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}

                        {/* Next and previous step actions */}
                        {!isStepCollapsed && showPreviousNextButtons && (
                          <tr>
                            <td></td>
                            <td colSpan={2}>
                              <div className={generateHiddenClassString('flex space-x-4 mt-4 ml-4', isStepCollapsed)}>
                                {isSingleCardEnabled && hasPreviousStep && (
                                  <Button
                                    type={BUTTON_TYPES.PRIMARY}
                                    onClick={handleOnPreviousStep}
                                    isDisabled={!isPreviousButtonEnabled}
                                  >
                                    Previous
                                  </Button>
                                )}
                                <Button
                                  type={BUTTON_TYPES.PRIMARY}
                                  onClick={handleOnComplete}
                                  isDisabled={!isNextButtonEnabled}
                                >
                                  Next
                                </Button>
                              </div>
                            </td>
                          </tr>
                        )}

                        {/* Step completion user id and timestamps */}
                        {!isStepCollapsed && isRun && !redlineState.isActive && (
                          <>
                            <tr>
                              <td></td>
                              <td colSpan={2}>
                                <div className={generateHiddenClassString('page-break', isStepCollapsed)}>
                                  <StepTimeline
                                    step={step}
                                    issues={run?.issues}
                                    isRun={isRun}
                                    sectionId={sectionId}
                                    isCommentingDisabled={isPreviewMode || endedRunCommentsDisabled}
                                    saveNewComment={saveStepComment}
                                    editComment={editStepComment}
                                  />
                                </div>
                              </td>
                            </tr>
                          </>
                        )}

                        {/* Review commenting row */}
                        {!isStepCollapsed && showReviewComments && (
                          <>
                            <tr>
                              <td></td>
                              <td colSpan={2}>
                                <div className={generateHiddenClassString('mb-2', isStepCollapsed)}></div>
                                <div className={generateHiddenClassString('mb-2 page-break', isStepCollapsed)}>
                                  <ReviewCommenting
                                    stepId={step.id}
                                    onResolveReviewComment={onResolveReviewComment}
                                    onUnresolveReviewComment={onUnresolveReviewComment}
                                    saveReviewComment={saveReviewComment}
                                    reviewComments={reviewUtil.getStepReviewComments(comments, step.id)}
                                  />
                                </div>
                              </td>
                            </tr>
                          </>
                        )}

                        {/* Extra padding if there is no run comment (isPreviewMode) and no review comment */}
                        {!isStepCollapsed && isPreviewMode && !showReviewComments && (
                          <tr className="h-3">
                            <td colSpan={3}></td>
                          </tr>
                        )}

                        {/* Show/hide redline comments button and comments */}
                        {!isStepCollapsed && showRedlineCommentsRow && (
                          <tr>
                            <td></td>
                            <td colSpan={2}>
                              <div className="w-4 shrink-0" />
                              <div className={generateHiddenClassString('ml-3 print:hidden', isStepCollapsed)}>
                                <div>
                                  <Button
                                    onClick={toggleShowRedlineComments}
                                    type="tertiary"
                                    leadingIcon="strikethrough"
                                    iconTextColor="text-red-500"
                                    removePadding={true}
                                  >
                                    {redlineCommentsButtonLabel}
                                  </Button>
                                </div>
                                {showRedlineComments && (
                                  <div className="mr-8">
                                    <CommentsList
                                      comments={redlineComments}
                                      onRefChanged={onRefChanged}
                                      onEdit={canEditSuggestedEditComment ? editRedlineCommentHandler : undefined}
                                    />
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        )}

                        <tr>
                          <td>
                            <JiraIssueModal
                              // TODO: [EPS-3845] config setting to allow this to be generic
                              urlText={`${run?.name}: ${step?.name}`}
                              url={`${locationUrl}${getScrollToUrlParams({
                                id: step?.id,
                                type: 'step',
                              })}`}
                              onAddIssue={onAddIssue}
                              isModalShown={showJiraIssueModal}
                              onHideModal={() => setShowJiraIssueModal(false)}
                            ></JiraIssueModal>
                          </td>
                        </tr>

                        {isIssuesEnabled && isIssuesEnabled() && (
                          <tr>
                            <td>
                              <CreateIssueModal
                                runId={runId}
                                referenceId={step?.id}
                                referenceType={STEP_REFERENCE_TYPE}
                                onAddIssue={onAddIssue}
                                isModalShown={showNcrIssueModal}
                                onHideModal={() => setShowNcrIssueModal(false)}
                                projectId={projectId}
                              />
                            </td>
                          </tr>
                        )}

                        {/* Step commenting row */}
                        {!isStepCollapsed && !isPreviewMode && (
                          <tr>
                            <td></td>
                            <td colSpan={2}>
                              <div className={generateHiddenClassString(isStepCollapsed)}></div>
                              <div
                                className={generateHiddenClassString(
                                  'flex flex-col gap-y-1 page-break',
                                  isStepCollapsed
                                )}
                              >
                                <div className="ml-4 mr-8">
                                  <StepCommenting
                                    redlineComments={step.redline_comments}
                                    isRedlineActive={redlineState.isActive}
                                    isEditing={redlineState.isEditing}
                                    reasonRedlineIsDisabled={reasonRedlineIsDisabled}
                                    onToggleRedline={onToggleRedline}
                                    onEditRedlineStepComment={
                                      canEditSuggestedEditComment ? editRedlineCommentHandler : undefined
                                    }
                                    onDirtyRedlineCommentChanged={dirtyCommentChangeHandler}
                                    onRefChanged={onRefChanged}
                                    step={step}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                      <tbody>
                        <tr className="h-2"></tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </td>
      </tr>
    </tbody>
  );
};

export default ProcedureStep;

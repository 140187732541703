import { TrackingType } from 'shared/lib/types/postgres/manufacturing/types';

export interface Tool {
  id: number;
  name: string;
  tool_number: string;
}

export interface ToolInstance {
  id: number;
  tool_id: number;
  serial_number: string;
}

export interface Item {
  serial?: string;
  lot?: string;
  part: {
    part_no: string;
    name: string;
    tracking: TrackingType;
  };
}

const messageUtil = {
  formatCheckedOutTools: (
    allTools: Array<Tool>,
    allToolInstances: Array<ToolInstance>,
    checkedOutToolInstanceIds: Array<number>
  ): string => {
    const tools = allToolInstances
      .filter((toolInstance) =>
        checkedOutToolInstanceIds.includes(toolInstance.id)
      )
      .map((toolInstance) => {
        const tool = allTools.find((tool) => tool.id === toolInstance.tool_id);
        return `  • ${tool?.name} : ${tool?.tool_number} ${toolInstance.serial_number}`;
      });
    return tools.join('\n');
  },

  formatCheckedOutParts: (checkedOutInventory: Array<Item>): string => {
    const parts = checkedOutInventory.map(
      (item) =>
        `  • ${item.part.name} : ${item.part.part_no} ${
          item.part.tracking === 'serial'
            ? item.serial
            : item.part.tracking === 'lot'
            ? item.lot
            : 'untracked'
        }`
    );
    return parts.join('\n');
  },
};

export default messageUtil;

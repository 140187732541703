import { isEqual, uniq, uniqWith } from 'lodash';
import { Project, User, Users } from 'shared/lib/types/couch/settings';
import {
  CreatableSelectOption,
  UserFormModalValues,
} from '../screens/UserFormModal';
import { OrgUser, UserFormUser } from '../components/Settings/types';
import * as Yup from 'yup';

const userUtil = {
  hashCode: (s: string): number => {
    return s.split('').reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  },

  rowKeyGetter: <T extends { id: string }>(row: T): number => {
    return userUtil.hashCode(row.id);
  },

  /**
   * @returns an array of any duplicates of `currentEmails` found in `newEmails`.
   */
  duplicates: (
    currentEmails: Array<UserFormUser>,
    newEmails: Array<string>
  ): Array<string> => {
    return currentEmails
      .reduce((dups, user) => {
        if (newEmails.includes(user.id)) {
          dups.push(user);
        }
        return dups;
      }, [] as Array<UserFormUser>)
      .map((dup) => dup.id);
  },

  /**
   * @param currentValues the currently validated form values
   * @param newValues on input clear: an empty array
   *                  on email removal: current valid emails with option removed
   *                  on email(s) added: current valid emails plus a new option with potentially multiple new emails
   * @returns newly validated emails
   */
  newEmails: (
    currentValues: UserFormModalValues,
    newValues: Array<CreatableSelectOption>
  ): Array<string> => {
    // clearing all emails
    if (newValues.length === 0) {
      return [];
    }

    // removing one email
    if (newValues.length < currentValues.emails.length) {
      return newValues.map((email) => email.value);
    }

    // adding email(s)
    const newEmails = newValues[newValues.length - 1].value
      .split(/[, ]/)
      .map((value) => value.trim().toLowerCase())
      .filter(
        (email: string) =>
          email.length > 0 && Yup.string().email().isValidSync(email)
      );
    return uniq(currentValues.emails.concat(newEmails));
  },

  filterBySearchTerm: (
    users: Array<UserFormUser>,
    operatorDescriptionByKey: {
      [key: string]: string;
    },
    searchTerm?: string
  ): Array<UserFormUser> => {
    return uniqWith(
      users
        .filter((user) =>
          searchTerm
            ? user.id
                .toLocaleLowerCase()
                .indexOf(searchTerm.toLocaleLowerCase()) !== -1
            : true
        )
        .concat(
          users.filter((user) =>
            searchTerm
              ? user.operator_roles?.filter((role) => {
                  // operator role may have been deleted, so make sure it still exists first
                  if (!operatorDescriptionByKey[role]) {
                    return false;
                  }
                  return (
                    operatorDescriptionByKey[role]
                      .toLocaleLowerCase()
                      .indexOf(searchTerm.toLocaleLowerCase()) !== -1
                  );
                }).length > 0
              : true
          )
        ),
      (user1, user2) => user1.id === user2.id
    );
  },

  filterByEmail: (
    users: Array<OrgUser>,
    searchTerm?: string
  ): Array<OrgUser> => {
    return uniqWith(
      users.filter((user) =>
        searchTerm
          ? user.email
              .toLocaleLowerCase()
              .indexOf(searchTerm.toLocaleLowerCase()) !== -1
          : true
      ),
      (user1, user2) => user1.id === user2.id
    );
  },

  filterInvalidOperatorRoles: (
    users: Array<UserFormUser>,
    operatorDescriptionByKey: { [description: string]: string }
  ): Array<UserFormUser> => {
    return users.map((user) => ({
      ...user,
      operator_roles: user.operator_roles.filter(
        (role) => operatorDescriptionByKey[role]
      ),
    }));
  },

  uniqWorkspaceRoles: (
    users: Array<UserFormUser>
  ): Array<string | undefined> => {
    return uniq(users.map((user) => user.workspace_role));
  },

  /**
   * @returns true if there is exactly one workspace role in common to all users
   */
  areWorkspaceRolesUniq: (users: Array<UserFormUser>): boolean =>
    userUtil.uniqWorkspaceRoles(users).length === 1,

  uniqOperatorRoles: (
    users: Array<UserFormUser>
  ): Array<Array<string> | undefined> => {
    return uniqWith(
      users.map((user) => user.operator_roles),
      isEqual
    );
  },

  /**
   * @returns true if there is exactly one operator roles array in common to all users
   */
  areOperatorRolesUniq: (users: Array<UserFormUser>): boolean =>
    userUtil.uniqOperatorRoles(users).length === 1,

  uniqProjectRoles: (
    users: Array<UserFormUser>,
    projectId: string
  ): Array<string | undefined> => {
    return uniqWith(
      users.map((user) => user.project_roles?.[projectId]?.[0]?.name),
      isEqual
    );
  },

  /**
   * @returns true if there is exactly one project role for the specified project in common to all users
   */
  areProjectRolesUniq: (
    users: Array<UserFormUser>,
    projectId: string
  ): boolean => userUtil.uniqProjectRoles(users, projectId).length === 1,

  /**
   * @returns true if the user's project roles are undefined or the user has a single project role for all projects
   */
  hasUniqProjectRole: (
    projectRoles: UserFormUser['project_roles'] | undefined,
    projects: Array<Project>
  ): boolean => {
    return (
      projectRoles === undefined ||
      (Object.keys(projectRoles).length === projects.length &&
        uniq(Object.values(projectRoles).map((role) => role[0]?.id)).length ===
          1)
    );
  },

  /**
   * @returns User object looked up using email (preferred) or database ID
   */
  getUserByEmailOrId: (emailOrId: string, users: Users): User | undefined => {
    const userByEmail = users.users[emailOrId];
    if (userByEmail) {
      return userByEmail;
    }
    return Object.values(users.users).find((user) => user.id === emailOrId);
  },

  getUserByUsername: (username: string, users: Users): User | undefined => {
    return Object.values(users.users).find(
      (user) => user.username === username
    );
  },
};

export default userUtil;

import React, { useMemo } from 'react';

const sizes = {
  sm: 'max-w-lg',
  md: 'max-w-screen-md',
  lg: 'max-w-screen-lg',
  xl: 'max-w-screen-xl',
};

const borders = {
  none: '',
  red: 'border-2 border-red-600',
  blue: 'border-2 border-blue-600',
};

/**
 * Renders the base (background) portion for a fullscreen, blocking modal.
 *
 * A full modal will use ModalBase, ModelContent, and ModelActionBar.
 */
const ModalBase = ({ children, size = 'sm', border = 'none' }) => {
  const widthClass = useMemo(() => sizes[size], [size]);
  const borderClass = useMemo(() => borders[border], [border]);

  return (
    <div
      className="fixed z-200 bg-gray-500 bg-opacity-75 transition-opacity inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0" aria-hidden="true"></div>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div
          className={`relative z-10 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden-d shadow-xl transition-all sm:my-8 sm:align-middle ${widthClass} ${borderClass} w-full`}
        >
          <div className="overflow-visible">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalBase;

import { procedureReviewPath } from '../../lib/pathUtil';
import React, { useCallback, useMemo } from 'react';
import { getPendingProcedureIndex } from 'shared/lib/procedureUtil';
import { useSelector } from 'react-redux';
import { selectProceduresMetadata } from '../../contexts/proceduresSlice';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { NOTIFICATION_STATUS, getNotificationMessage } from 'shared/lib/notifications';
import Tooltip from '../../elements/Tooltip';
import { AppNotificationDisplay, NotificationChannels, NotificationTypes } from 'shared/lib/types/notifications';
import { NotifyReviewerContext } from 'shared/lib/types/postgres/notifications';
import NotificationLink from './NotificationLink';

export interface NotificationLinkProps {
  notification: AppNotificationDisplay;
  duplicateIds: Array<number>;
  handleOnClick: (ids: Array<number>) => void;
  truncateCode?: boolean;
}

const ReviewerNotificationLink = ({
  notification,
  duplicateIds,
  handleOnClick,
  truncateCode = true,
}: NotificationLinkProps) => {
  const { currentTeamId } = useDatabaseServices();
  const reviewerContext = useMemo(() => notification.context as NotifyReviewerContext, [notification.context]);
  const code = useSelector((state) => {
    const procedures = selectProceduresMetadata(state, currentTeamId);
    const procedureId = reviewerContext.procedureId;
    const pendingProcedureId = getPendingProcedureIndex(procedureId);
    return (procedures[procedureId] ?? procedures[pendingProcedureId])?.code;
  });

  const codeDisplay = useMemo(() => code ?? 'A procedure', [code]);

  const onClick = useCallback(() => {
    if (handleOnClick) {
      return handleOnClick(duplicateIds);
    }
  }, [duplicateIds, handleOnClick]);

  const ariaLabel = useMemo(() => {
    const label = getNotificationMessage(NotificationTypes.Reviewers, NotificationChannels.InApp, {
      code: codeDisplay,
    }).message;
    return label ? label : '';
  }, [codeDisplay]);
  const to = useMemo(
    () => procedureReviewPath(currentTeamId, reviewerContext.procedureId),
    [currentTeamId, reviewerContext]
  );
  const messageWithoutCode = useMemo(
    () =>
      getNotificationMessage(NotificationTypes.Reviewers, NotificationChannels.InApp, {
        code: '',
      })?.message?.trim(),
    []
  );

  return (
    <NotificationLink
      notificationId={notification.id}
      ariaLabel={ariaLabel}
      to={to}
      onClick={onClick}
      duplicateIds={duplicateIds}
    >
      <div
        className={`${
          notification.status === NOTIFICATION_STATUS.UNRESOLVED && 'font-semibold'
        } flex flex-row space-x-1`}
      >
        <div className={`${truncateCode ? 'max-w-[5rem]' : ''}`}>
          <Tooltip content={codeDisplay}>
            <p className="text-blue-500 truncate">{codeDisplay}</p>
          </Tooltip>
        </div>
        <div>{messageWithoutCode}</div>
      </div>
    </NotificationLink>
  );
};

export default React.memo(ReviewerNotificationLink);

interface SpacerProps {
  isHidden?: boolean;
  hasExtraVerticalSpacing?: boolean;
}

const Spacer = ({ isHidden, hasExtraVerticalSpacing }: SpacerProps) => {
  return (
    <div className={`${isHidden ? 'hidden' : ''}`}>
      <div
        className={`h-8 ml-4 flex justify-center items-start shrink-0 ${hasExtraVerticalSpacing ? 'mt-4 xl:mt-2' : ''}`}
      />
    </div>
  );
};

export default Spacer;

import React from 'react';
import ModalBase from './ModalBase';
import ModalActionBar from './ModalActionBar';
import ModalContent from './ModalContent';
import Button from './Button';
import { MenuContextAction } from './MenuContext';

/**
 * Modal properties.
 *
 * @param title - Optional title of the modal to display.
 * @param children - Child content to display in modal body.
 * @param primaryActionTitle - Text to display for primary action button, defaults to "Confirm"
 * @param secondaryActionTitle - String to display for secondary action button, defaults to "Cancel"
 * @param onPrimaryAction - Callback when primary action is fired.
 * @param onSecondaryAction - Callback when secondary action is fired.
 * @param isPrimaryActionEnabled - Enables or disables primary action.
 * @param size - Size specification defined by the ModalBase, defaults to "sm"
 */
export interface ModalProps {
  title?: string;
  children: React.ReactNode;
  primaryActionTitle?: string;
  secondaryActionTitle?: null | string;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  isPrimaryActionEnabled?: boolean;
  isPrimaryActionVisible?: boolean;
  isSecondaryActionEnabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  border?: 'none' | 'red' | 'blue';
  threeDotMenuActions?: Array<MenuContextAction>;
}

/**
 * Displays a fullscreen, blocking modal.
 */
export default function Modal({
  title,
  children,
  primaryActionTitle = 'Confirm',
  secondaryActionTitle = 'Cancel',
  onPrimaryAction,
  onSecondaryAction,
  isPrimaryActionEnabled = true,
  isPrimaryActionVisible = true,
  isSecondaryActionEnabled = true,
  size = 'sm',
  border = 'none',
  threeDotMenuActions = undefined,
}: ModalProps) {
  return (
    <ModalBase size={size} border={border}>
      <ModalContent title={title} threeDotMenuActions={threeDotMenuActions}>
        {children}
      </ModalContent>
      <ModalActionBar>
        <div className="flex space-x-2">
          {typeof onSecondaryAction === 'function' && (
            <Button onClick={onSecondaryAction} type="secondary" isDisabled={!isSecondaryActionEnabled}>
              {secondaryActionTitle}
            </Button>
          )}
          {isPrimaryActionVisible && (
            <Button
              onClick={onPrimaryAction}
              buttonTypeAttribute="submit"
              type="primary"
              isDisabled={!isPrimaryActionEnabled}
            >
              {primaryActionTitle}
            </Button>
          )}
        </div>
      </ModalActionBar>
    </ModalBase>
  );
}

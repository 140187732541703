import React, { useCallback, useMemo, useState } from 'react';
import Select, { OptionsType } from 'react-select';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import procedureUtil from '../../lib/procedureUtil';
import JumpToEditSelectOption from './JumpToEditSelectOption';
import { useSettings } from '../../contexts/SettingsContext';
import { reactSelectStyles } from '../../lib/styles';

// Returns string with "'sectionKey'. 'sectionName'"
const getSectionLabel = (sectionName, index, style) => {
  const sectionKey = procedureUtil.displaySectionKey(index, style);

  if (sectionName) {
    return `${sectionKey}. ${sectionName}`;
  }

  return `${sectionKey}.`;
};

// Returns string with "'stepKey'. 'stepName'"
const getStepLabel = (stepName, stepIndex, sectionIndex, style) => {
  const stepLabel = procedureUtil.displaySectionStepKey(sectionIndex, stepIndex, style);

  if (stepName) {
    return `${stepLabel}. ${stepName}`;
  }

  return `${stepLabel}.`;
};

// Returns an array of react-select type options objects.
const getOptions = (sections, getSetting, includeSections, containingStepId) => {
  const options: OptionsType[] = [];

  sections.forEach((section, sectionIndex) => {
    if (includeSections) {
      const sectionLabel = getSectionLabel(section.name, sectionIndex, getSetting('display_sections_as', 'letters'));

      options.push({
        type: 'section',
        label: sectionLabel,
        value: section.id,
      });
    }

    section.steps.forEach((step, stepIndex) => {
      // Dont add containing step or dynamic step that has not been acceped into the options array.
      if (containingStepId === step.id || step.created_during_run) {
        return;
      }

      const stepLabel = getStepLabel(step.name, stepIndex, sectionIndex, getSetting('display_sections_as', 'letters'));
      options.push({
        type: 'step',
        label: stepLabel,
        value: step.id,
      });
    });
  });

  return options;
};

interface JumpToEditSelectProps {
  includeSections: boolean;
  path: string;
  containingStepId: string;
  value: string;
  onChange: (path: string, jumpToId: string) => void;
  isDisabled: boolean;
}

const JumpToEditSelect = React.memo(
  ({ includeSections, path, containingStepId, value, onChange, isDisabled }: JumpToEditSelectProps) => {
    const { getAllSections, getSectionSummary, getStepSummary, getItemPath } = useProcedureContext();
    const [options, setOptions] = useState<OptionsType>();
    const { getSetting } = useSettings();

    const updateOptions = useCallback(() => {
      const sections = getAllSections();
      const options = getOptions(sections, getSetting, includeSections, containingStepId);

      setOptions(options);
    }, [getAllSections, getSetting, includeSections, containingStepId]);

    const selectValue = useMemo(() => {
      if (value === null) {
        return null;
      }

      const path = getItemPath(value);

      if (!path) {
        return null;
      }

      const { sectionId, stepId } = procedureUtil.parsePath(path);
      const section = getSectionSummary(sectionId);
      if (!section) {
        return null;
      }

      if (!stepId) {
        return {
          type: 'section',
          label: getSectionLabel(section.name, section.index, getSetting('display_sections_as', 'letters')),
          value: sectionId,
        };
      }

      const step = getStepSummary(stepId, sectionId);

      if (!step) {
        return null;
      }

      return {
        type: 'step',
        label: getStepLabel(step.name, step.index, section.index, getSetting('display_sections_as', 'letters')),
        value: stepId,
      };
    }, [value, getSectionSummary, getStepSummary, getItemPath, getSetting]);

    const onChangeHandler = (option) => {
      const jumpToId = option.value;

      // Skip onChange when re-selecting the same value.
      if (jumpToId === value) {
        return;
      }

      onChange(path, jumpToId);
    };

    return (
      <Select
        aria-label="Select Jump To Location" // React-select requires aria-label instead of ariaLabel.
        classNamePrefix="react-select"
        components={{ Option: JumpToEditSelectOption }}
        name={path}
        onChange={onChangeHandler}
        onMenuOpen={updateOptions}
        options={options}
        styles={reactSelectStyles}
        value={selectValue}
        isDisabled={isDisabled}
      />
    );
  }
);

export default JumpToEditSelect;

import { ReactComponent as RocketSVG } from '../images/rocket.svg';

const ContactSupport = () => {
  return (
    <div className="flex flex-col items-center justify-center space-y-6 my-2 absolute top-0 bottom-0 left-0 right-0">
      <div>
        <RocketSVG className="text-blue-500" height="200" width="200" />
      </div>
      <div className="text-lg text-center leading-relaxed">
        Contact
        <a target="_blank" rel="noreferrer" href="mailto:support@epsilon3.io" className="link m-1">
          support@epsilon3.io
        </a>
        to enable this feature or learn more.
      </div>
    </div>
  );
};

export default ContactSupport;

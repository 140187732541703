import Pluralize from 'pluralize';
import IssuesGrid from '../components/IssuesGrid';
import useRealtimeIssues from '../hooks/useRealtimeIssues';
import { search, countOpenIssues } from '../lib/issues';
import { StatusTab } from '../types';
import { useSettings } from '../../contexts/SettingsContext';

type OpenIssuesGridWithCountProps = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  verticalPadding?: number;
};

const OpenIssuesGridWithCount = ({
  searchTerm = '',
  setSearchTerm,
  verticalPadding = 0,
}: OpenIssuesGridWithCountProps) => {
  const { projects } = useSettings();
  const { issues } = useRealtimeIssues();
  const filtered = search(issues, searchTerm, projects);
  const numIssues = countOpenIssues(filtered);

  return (
    <>
      <div className="my-2 text-sm text-gray-400">
        {numIssues} {Pluralize('issue', numIssues)}
      </div>
      <IssuesGrid
        issues={filtered}
        selectedStatus={StatusTab.Open}
        verticalPadding={verticalPadding}
        setSearchTerm={setSearchTerm}
      />
    </>
  );
};

export default OpenIssuesGridWithCount;

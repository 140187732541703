import React, { useCallback, useMemo } from 'react';
import EditReviewer from './EditReviewer';
import cloneDeep from 'lodash.clonedeep';
import { Reviewer, ReviewerId, ReviewerAction } from 'shared/lib/types/couch/procedures';
import { getHasReviewerApproved, getNewReviewer } from 'shared/lib/reviewUtil';
import { partition } from 'lodash';
import Button from '../Button';

interface EditReviewersProps {
  reviewers: Array<Reviewer>;
  actions: Array<ReviewerAction>;
  reviewerOptions: Array<ReviewerId>;
  onReviewersUpdated: (reviewers: Array<Reviewer>) => void;
  canEdit?: boolean;
  isActive?: boolean;
  canAddUserIdToOperatorRoleSignoff?: boolean;
  changeIdOnReviewerUpdate?: boolean;
}

const EditReviewers = ({
  reviewers = [],
  actions = [],
  reviewerOptions,
  onReviewersUpdated,
  canEdit = true,
  canAddUserIdToOperatorRoleSignoff = false,
  changeIdOnReviewerUpdate = true,
}: EditReviewersProps) => {
  const onRemoveReviewer = useCallback(
    (reviewerIndex) => {
      const updatedReviewers = cloneDeep(reviewers);

      updatedReviewers.splice(reviewerIndex, 1);

      onReviewersUpdated(updatedReviewers);
    },
    [reviewers, onReviewersUpdated]
  );

  const onReviewerUpdated = useCallback(
    (reviewer, reviewerIndex) => {
      const reviewersCopy = cloneDeep(reviewers);

      reviewersCopy[reviewerIndex] = reviewer;

      onReviewersUpdated(reviewersCopy);
    },
    [reviewers, onReviewersUpdated]
  );

  const onAddReviewer = useCallback(() => {
    const reviewersCopy = cloneDeep(reviewers);
    const newReviewer = getNewReviewer();

    newReviewer.reviewer_ids = [];
    reviewersCopy.push(newReviewer);

    onReviewersUpdated(reviewersCopy);
  }, [reviewers, onReviewersUpdated]);

  const showNewReviewerButton = useMemo(() => {
    return (
      canEdit &&
      reviewerOptions.length > 0 &&
      (!reviewers || reviewers.length === 0 || reviewers?.at(-1)?.reviewer_ids.length !== 0)
    );
  }, [canEdit, reviewerOptions.length, reviewers]);

  const getHasApproved = useCallback((reviewer) => getHasReviewerApproved(reviewer, actions), [actions]);

  const [requiredReviewers, addedReviewers] = useMemo(() => {
    return partition(reviewers, (reviewer) => reviewer.is_required);
  }, [reviewers]);

  return (
    <div className="flex flex-wrap grow items-start">
      <div className="flex flex-row divide-slate-300 divide-x-2">
        {requiredReviewers.length > 0 && (
          <div className="pr-2 flex flex-wrap gap-2 items-start min-w-fit max-w-[50%]">
            {requiredReviewers.map((reviewer, reviewerIndex) => (
              <EditReviewer
                key={reviewer.id}
                reviewerIndex={reviewerIndex}
                reviewer={reviewer}
                onRemoveReviewer={onRemoveReviewer}
                reviewerOptions={reviewerOptions}
                onReviewerUpdated={onReviewerUpdated}
                disabled={!canEdit}
                hasApproved={getHasApproved(reviewer)}
                canAddUserIdToOperatorRoleSignoff={canAddUserIdToOperatorRoleSignoff}
                changeIdOnReviewerUpdate={changeIdOnReviewerUpdate}
              />
            ))}
          </div>
        )}
        {addedReviewers.length > 0 && (
          <div className={`${requiredReviewers.length > 0 ? 'pl-2' : ''} flex flex-wrap grow gap-2 items-start`}>
            {addedReviewers.map((reviewer, addedReviewerIndex) => (
              <EditReviewer
                key={reviewer.id}
                reviewerIndex={addedReviewerIndex + requiredReviewers.length}
                reviewer={reviewer}
                onRemoveReviewer={onRemoveReviewer}
                reviewerOptions={reviewerOptions}
                onReviewerUpdated={onReviewerUpdated}
                disabled={!canEdit}
                hasApproved={getHasApproved(reviewer)}
                canAddUserIdToOperatorRoleSignoff={canAddUserIdToOperatorRoleSignoff}
                changeIdOnReviewerUpdate={changeIdOnReviewerUpdate}
              />
            ))}
            {showNewReviewerButton && (
              <div className="-ml-2">
                <Button
                  ariaLabel="Show Reviewer Selector"
                  onClick={onAddReviewer}
                  type="tertiary"
                  iconTextColor="text-blue-500"
                  leadingIcon="plus-circle"
                  size="base"
                />
              </div>
            )}
          </div>
        )}
      </div>
      {canEdit && addedReviewers.length === 0 && (
        <div className="-ml-2">
          <Button
            ariaLabel="Show Reviewer Selector"
            onClick={onAddReviewer}
            type="tertiary"
            iconTextColor="text-blue-500"
            leadingIcon="plus-circle"
            size="base"
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(EditReviewers);

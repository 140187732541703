import { isEmptyValue } from 'shared/lib/text';
import {
  KitItem,
  PartBuildRecordedItem,
  RunPartBuildBlock,
  RunPartBuildRecorded,
  RunPartKitBlock,
  RunPartKitRecorded,
} from 'shared/lib/types/views/procedures';

// PART KIT VALIDATION

const itemWasKitted = (item: KitItem): boolean => {
  return item.amount === 0 || Boolean(item.item_id);
};

const getProcedureKit = (recorded?: RunPartKitRecorded): Array<KitItem> => {
  if (!recorded || !recorded.items) {
    return [];
  }
  return Object.values(recorded.items);
};

const hasKittedProcedureItems = (
  block: RunPartKitBlock,
  recorded?: RunPartKitRecorded
): boolean => {
  const numItemsFromProcedure = block.items.length;
  const itemsKittedFromProcedure = getProcedureKit(recorded);
  if (numItemsFromProcedure !== itemsKittedFromProcedure.length) {
    return false;
  }
  return itemsKittedFromProcedure.every(itemWasKitted);
};

const getAddedItems = (recorded?: RunPartKitRecorded): Array<KitItem> => {
  if (!recorded || !recorded.added_items) {
    return [];
  }
  return recorded.added_items;
};

const hasKittedAddedItems = (recorded?: RunPartKitRecorded): boolean => {
  const addedItems = getAddedItems(recorded);
  return addedItems.every(itemWasKitted);
};

export const kitHasEmptyValues = (
  block: RunPartKitBlock,
  recorded?: RunPartKitRecorded
): boolean => {
  return (
    !hasKittedProcedureItems(block, recorded) || !hasKittedAddedItems(recorded)
  );
};

// PART BUILD VALIDATION

const buildItemHasAmount = (item: PartBuildRecordedItem): boolean =>
  !isEmptyValue(item.amount);

const buildItemHasTrackingId = (item: PartBuildRecordedItem): boolean => {
  const shouldHaveSerial = item.tracking === 'serial' || 'serial' in item;
  const shouldHaveLot = item.tracking === 'lot' || 'lot' in item;
  const trackingIdMissing =
    (shouldHaveSerial && isEmptyValue(item.serial)) ||
    (shouldHaveLot && isEmptyValue(item.lot));
  return !trackingIdMissing;
};

const buildItemWasFullyRecorded = (item: PartBuildRecordedItem): boolean =>
  buildItemHasAmount(item) && buildItemHasTrackingId(item);

const procedureBuildItemsAreFullyRecorded = (
  block: RunPartBuildBlock,
  recorded?: RunPartBuildRecorded
): boolean => {
  const numItemsFromProcedure = block.items.length;
  const recordedItems = Object.values(recorded?.items ?? {});
  if (recordedItems.length < numItemsFromProcedure) {
    return false;
  }
  return recordedItems.every(buildItemWasFullyRecorded);
};

const addedBuildItemsAreFullyRecorded = (
  recorded?: RunPartBuildRecorded
): boolean => {
  const addedItems = recorded?.added_items ?? [];
  return addedItems.every(buildItemWasFullyRecorded);
};

export const buildHasEmptyValues = (
  block: RunPartBuildBlock,
  recorded?: RunPartBuildRecorded
): boolean => {
  return (
    !procedureBuildItemsAreFullyRecorded(block, recorded) ||
    !addedBuildItemsAreFullyRecorded(recorded)
  );
};

import { Users } from './types/couch/settings';

/**
 * Creates a map of operator role to a set of users that have that operator role.
 */
export const getOperatorRoleToUsersMap = (
  users: Users['users'] | undefined
): { [operatorRole: string]: Set<string> } => {
  if (!users) {
    return {};
  }
  return Object.entries(users).reduce(
    (map, [userId, { operator_roles: operatorRoles }]) => {
      operatorRoles?.forEach((operatorRole) => {
        if (!map[operatorRole]) {
          map[operatorRole] = new Set();
        }
        map[operatorRole].add(userId);
      });

      return map;
    },
    {}
  );
};

import {
  PropertyTypes,
  ProcedurePropertyValidateMapping,
  AdditionalValidationInfo,
  ContentBlockValidateMapping,
} from './types/blockTypes';
import { StepBlock, Step, Section, Procedure } from './types/views/procedures';

function noOpTransform(property: any): any {
  return property;
}

const PROPERTIES_MAPPING: ProcedurePropertyValidateMapping = {
  [PropertyTypes.Id]: noOpTransform,
  [PropertyTypes.Rev]: noOpTransform,
  [PropertyTypes.LockedAt]: noOpTransform,
  [PropertyTypes.LockedBy]: noOpTransform,
  [PropertyTypes.State]: noOpTransform,
  [PropertyTypes.EditedAt]: noOpTransform,
  [PropertyTypes.EditedUserId]: noOpTransform,
  [PropertyTypes.ReviewerGroups]: noOpTransform,
  [PropertyTypes.ReviewerActionsHistory]: noOpTransform,
  [PropertyTypes.Type]: noOpTransform,
  [PropertyTypes.Comments]: noOpTransform,
  [PropertyTypes.Actions]: noOpTransform,
  [PropertyTypes.ProcedureRevNumber]: noOpTransform,
  [PropertyTypes.InitialRevNumber]: noOpTransform,
  [PropertyTypes.ReviewType]: noOpTransform,
  [PropertyTypes.ReleaseNote]: noOpTransform,
  [PropertyTypes.Conditionals]: noOpTransform,
  [PropertyTypes.Dependencies]: noOpTransform,
  [PropertyTypes.PartList]: noOpTransform,
};

export async function processContentBlock({
  mapping,
  block,
  additionalInfo,
}: {
  mapping: ContentBlockValidateMapping;
  block: StepBlock;
  additionalInfo: AdditionalValidationInfo;
}): Promise<any> {
  const blockType = block.type.toLowerCase();
  const mappedBlock = mapping[blockType] as (
    block,
    additionalInfo
  ) => Promise<any>;
  // Should never be true unless there is a developer error
  if (!mappedBlock) {
    console.log(`Unknown block type ${blockType}`);
    throw new Error(`Unknown block type ${blockType}`);
  }
  return mappedBlock(block, additionalInfo);
}

/**
 * Validates everything outside a content block
 */
export async function processProcedureStepSectionProperty({
  mapping = PROPERTIES_MAPPING,
  property,
  propertyName,
  additionalInfo,
}: {
  mapping: ProcedurePropertyValidateMapping;
  property: any;
  propertyName: string;
  additionalInfo: AdditionalValidationInfo;
}) {
  const mappedProperty = mapping[propertyName] as (
    property,
    additionalInfo
  ) => Promise<any>;
  // Should never be true unless there is a developer error
  if (!mappedProperty) {
    throw new Error(`Unknown procedure document property ${propertyName}`);
  }
  return mappedProperty(property, additionalInfo);
}

async function _processStep(step, mapping, additionalInfo) {
  for (const [propertyName, property] of Object.entries<Step>(step)) {
    if (propertyName !== 'content') {
      step[propertyName] = await processProcedureStepSectionProperty({
        mapping,
        property,
        propertyName,
        additionalInfo,
      });
    } else {
      step[propertyName] = await Promise.all(
        step[propertyName].map(async (block) =>
          processContentBlock({ mapping, block, additionalInfo })
        )
      );
    }
  }
  return step as Step;
}

async function _processSection(section, mapping, additionalInfo) {
  for (const [propertyName, property] of Object.entries<Section>(section)) {
    if (propertyName !== 'steps') {
      section[propertyName] = await processProcedureStepSectionProperty({
        mapping,
        property,
        propertyName,
        additionalInfo,
      });
    } else {
      section[propertyName] = await Promise.all(
        section[propertyName].map(async (step) =>
          _processStep(step, mapping, additionalInfo)
        )
      );
    }
  }
  return section as Section;
}

export async function processProcedure(procedure, mapping, additionalInfo) {
  for (const [propertyName, property] of Object.entries<Procedure>(procedure)) {
    if (propertyName !== 'sections') {
      procedure[propertyName] = await processProcedureStepSectionProperty({
        mapping,
        property,
        propertyName,
        additionalInfo,
      });
    } else {
      procedure[propertyName] = await Promise.all(
        procedure[propertyName].map(async (section) =>
          _processSection(section, mapping, additionalInfo)
        )
      );
    }
  }
  return procedure as Procedure;
}

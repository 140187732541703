import React from 'react';
import { AppNotificationDisplay } from 'shared/lib/types/notifications';
import Button from '../Button';
import Tooltip from '../../elements/Tooltip';
import { NOTIFICATION_STATUS } from 'shared/lib/notifications';
import { NotifyGenerationFailContext } from 'shared/lib/types/postgres/notifications';

export interface NotificationLinkProps {
  notification: AppNotificationDisplay;
  duplicateIds: Array<number>;
  handleOnClick: (ids: Array<number>) => void;
}

const ProcedureGenerationNotificationLink = ({ notification, duplicateIds, handleOnClick }: NotificationLinkProps) => {
  const context = notification.context as NotifyGenerationFailContext;
  const fileName = context.fileName;
  const message = 'failed to import';
  const fontClass = notification.status === NOTIFICATION_STATUS.UNRESOLVED ? 'font-semibold' : '';

  const onClick = () => handleOnClick && handleOnClick(duplicateIds);

  return (
    <Button key={notification.id} ariaLabel={message} onClick={onClick} type="tertiary" width="full">
      <div className={`flex space-x-1 ${fontClass}`}>
        <div className="max-w-[10rem]">
          <Tooltip content={fileName}>
            <p className="text-blue-500 truncate">{fileName}</p>
          </Tooltip>
        </div>
        <div>{message}</div>
        {duplicateIds && duplicateIds.length > 1 && <div className="text-gray-400"> ({duplicateIds.length})</div>}
      </div>
    </Button>
  );
};

export default React.memo(ProcedureGenerationNotificationLink);

import { Field } from 'formik';
import { FieldInputValueDataType } from '../lib/types';

interface FieldSetValueProps {
  path: string;
  dataType?: FieldInputValueDataType;
  isDisabled: boolean;
  valueError?: string;
  onChange: (value: string, path: string) => void;
}

// Renders field to enter a value
const FieldSetValue = ({ path, dataType, isDisabled, valueError, onChange }: FieldSetValueProps) => {
  return (
    <div className="flex flex-col">
      <span className="field-title">Value {dataType}</span>
      <Field name={`${path}.value`} validate={(value) => onChange(value, `${path}.value`)}>
        {({ field }) => (
          <input
            {...field}
            type="text"
            disabled={isDisabled}
            placeholder="Value"
            className="text-sm border-1 border-gray-400 rounded disabled:bg-gray-200"
          />
        )}
      </Field>
      {valueError && <div className="text-red-700">{valueError}</div>}
    </div>
  );
};

export default FieldSetValue;

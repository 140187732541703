import superlogin from '../../api/superlogin';
import { API_URL } from '../../config';

export enum DataType {
  RunTelemetry = 'telemetry',
  Upload = 'upload',
  Expression = 'expression',
  Parameter = 'parameter',
}

export type Annotation = {
  id?: number;
  channel?: string | null;
  start_time: string;
  end_time?: string | null;
  title: string;
  description: string;
  data_type: DataType;
  file_id?: number | null;
  run_id?: string | null;
  dictionary_id?: string | null;
};

class AnnotationService {
  private teamId: string;

  constructor(teamId: string) {
    this.teamId = teamId;
  }

  async getAnnotationsForUploadedData(fileId: number): Promise<Annotation[]> {
    const url = `${API_URL}/teams/${this.teamId}/storage/annotations?type=${DataType.Upload}&fileId=${fileId}`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async getAnnotationsForRunTelemetryData(
    runId: string
  ): Promise<Annotation[]> {
    const url = `${API_URL}/teams/${this.teamId}/storage/annotations?type=${DataType.RunTelemetry}&runId=${runId}`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async getAnnotationsForParameterData(
    dictionaryId: string
  ): Promise<Annotation[]> {
    const url = `${API_URL}/teams/${this.teamId}/storage/annotations?type=${DataType.Parameter}&dictionaryId=${dictionaryId}`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async createAnnotation(annotation: Annotation): Promise<void> {
    const apiUrl = `${API_URL}/teams/${this.teamId}/storage/annotations`;
    const body = {
      ...annotation,
    };
    await superlogin.getHttp().post(apiUrl, body);
  }
}

export default AnnotationService;

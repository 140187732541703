import React from 'react';

interface DividerProps {
  ml?: number;
  mr?: number;
}

const Divider = ({ ml = 2, mr = 2 }: DividerProps) => {
  return <div className={`bg-slate-300 w-[1px] h-6 ml-${ml} mr-${mr}`} />;
};

export default React.memo(Divider);

import superlogin from './superlogin';
import { API_URL } from '../config';
import { AxiosResponse } from 'axios';

/**
 * Distinct from api/user.ts for two reasons:
 *   1. This module is team agnostic whereas user.ts requires a teamId.
 *   2. This module is for calling endpoints related to the current user
 *      whereas user.ts is for endpoints which relate to creating and updating
 *      workspace user objects.
 */
const userSettings = {
  getUnacknowledgedUserMessageKeys: async (): Promise<string[]> => {
    const url = `${API_URL}/users/unacknowledged-messages`;
    const response = await superlogin.getHttp().get(url);
    return response.data.keys;
  },

  acknowledgedUserMessage: async (
    messageKey: string
  ): Promise<AxiosResponse> => {
    const url = `${API_URL}/users/acknowledged-messages`;
    return superlogin.getHttp().post(url, {
      message_key: messageKey,
    });
  },

  generatePin: async (): Promise<string> => {
    const url = `${API_URL}/users/pin`;
    const response = await superlogin.getHttp().post(url);
    return response.data.pin;
  },

  pinExists: async (): Promise<void> => {
    const url = `${API_URL}/users/pin`;
    await superlogin.getHttp().head(url);
  },
};

export default userSettings;

import React from 'react';
import OneTimeModal from './OneTimeModal';

interface WelcomeModalProps {
  title: string;
  children: React.ReactNode;
  modalKey: string;
  imgAspectRatio: number;
  imgPath: string;
  tutorialUrl: string;
}

const WelcomeModal = ({ title, children, modalKey, imgAspectRatio, imgPath, tutorialUrl }: WelcomeModalProps) => {
  return (
    <OneTimeModal title={title} modalKey={modalKey}>
      <div className="flex flex-col px-3 space-y-3 max-h-[70vh] overflow-auto w-full">
        {children}
        <div className={`shrink-0 aspect-[${imgAspectRatio}]`}>
          <img
            src={imgPath}
            style={{
              height: 'auto',
              width: 'auto',
            }}
            alt="Welcome modal"
          />
        </div>
        <span>
          Want to know more? Check out
          <a
            href={tutorialUrl}
            className="ml-1 underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            target="_blank"
            rel="noopener noreferrer"
          >
            the tutorial
          </a>
          .
        </span>
      </div>
    </OneTimeModal>
  );
};

export default WelcomeModal;

// eslint-disable-next-line import/prefer-default-export
export enum ACCESS {
  ORG_ADMIN = 'org admin',
  ADMIN = 'admin',
  EDITOR = 'editor',
  OPERATOR = 'operator',
  VIEWER = 'viewer',
}

export const ALL_LEVELS = [
  ACCESS.ORG_ADMIN,
  ACCESS.ADMIN,
  ACCESS.EDITOR,
  ACCESS.OPERATOR,
  ACCESS.VIEWER,
];

// Restricting these just so that users don't create anything that is too confusing
export enum ACCESS_RESTRICTED {
  ORG_ADMIN2 = 'orgadmin',
  ORG_ADMIN3 = 'org_admin',
}

export type AccessLevelCapitalized = Capitalize<ACCESS>;

export type AccessLevelOrNone = ACCESS | 'none';

/**
 * **WARNING:** THE ORDER HERE IS VERY IMPORTANT.
 */
export const ACCESS_LEVELS_ASCENDING = [
  ACCESS.ORG_ADMIN,
  ACCESS.VIEWER,
  ACCESS.OPERATOR,
  ACCESS.EDITOR,
  ACCESS.ADMIN,
] as const;

/**
 * **WARNING:** THE ORDER HERE IS VERY IMPORTANT.
 */
export const ACCESS_LEVELS_DESCENDING = [
  ACCESS.ADMIN,
  ACCESS.EDITOR,
  ACCESS.OPERATOR,
  ACCESS.VIEWER,
  ACCESS.ORG_ADMIN,
] as const;

/**
 * Type guard for {@link ACCESS}.
 */
export const isAccessLevel = (x: unknown): x is ACCESS => {
  return (
    !!x &&
    typeof x === 'string' &&
    (
      ACCESS_LEVELS_ASCENDING as [string, string, string, string, string]
    ).includes(x)
  );
};

export const ACCESS_LEVEL_TO_INDEX: Record<AccessLevelOrNone, number> = {
  none: 0,
  [ACCESS.ORG_ADMIN]: 0,
  [ACCESS.VIEWER]: 1,
  [ACCESS.OPERATOR]: 2,
  [ACCESS.EDITOR]: 3,
  [ACCESS.ADMIN]: 4,
} as const;

/**
 * Type guard for {@link AccessLevelOrNone}.
 */
export const isAccessLevelOrNone = (x: unknown): x is AccessLevelOrNone => {
  return x === 'none' || isAccessLevel(x);
};

import cloneDeep from 'lodash.clonedeep';
import superlogin from './superlogin';
import { API_URL } from '../config';
import signoffUtil from 'shared/lib/signoffUtil';
import revisions from '../lib/revisions';
import procedureUtil from '../lib/procedureUtil';
import runUtil, {
  ACTIVE_RUN_STATES,
  PARTICIPANT_TYPE,
  RUN_STATE,
} from '../lib/runUtil';
import {
  onDocChanged,
  onResourceChanged,
  onSelectorChanged,
} from './observers';
import stepConditionals from 'shared/lib/stepConditionals';
import {
  CommandingBlockRecordedResults,
  HeaderRedlineMetadata,
  RedlinedHeader,
  ReleaseHeader,
  RepeatedSection,
  RepeatedStep,
  Run,
  RunAction,
  RunAddedStep,
  RunFieldInputConditionalBlock,
  RunFieldInputRecordedValue,
  RunHeader,
  RunHeaderRedline,
  RunIssue,
  RunMetadata,
  RunProcedureLinkBlock,
  RunRedline,
  RunSectionHeader,
  RunStatus,
  RunStep,
  RunStepComment,
  RunStepFullRedline,
  RunVariable,
} from 'shared/lib/types/views/procedures';
import { RunTag } from 'shared/lib/types/couch/settings';
import { AxiosResponse } from 'axios';
import { OperationId } from 'shared/lib/types/operations';
import {
  copyStepWithoutActiveContent,
  ACTION_TYPE,
  checkCanSignOffStep,
  isStepEnded,
  updateDocWithSectionRepeated,
  updateDocWithStepRepeated,
  updateDocWithStepSkipped,
  updateDocWithSectionSkipped,
  updateDocWithRecorded,
  isBeforeReopen,
  isLatestStep,
  getSectionId,
} from 'shared/lib/runUtil';

import { validateCanEditComment, wasEdited } from 'shared/lib/comment';
import { isSuggestEditsStepSettingEnabled } from 'shared/lib/procedureUtil';
import { generateRedlineDocId } from 'shared/lib/idUtil';
const MAX_RETRIES = 3;

export class RunsError extends Error {
  status: number;
  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

export type RecordedAllSectionSteps = {
  steps: Array<{
    recorded: RecordedBlocks;
  }>;
};

type RunTableRecordedValue = {
  row: number;
  column: number;
  value: string;
};

export type CommandingBlockRecorded = {
  timestamp?: string;
  results?: CommandingBlockRecordedResults;
};

export type RecordedBlocks = {
  [index: number]: {
    value: RunFieldInputRecordedValue | RunTableRecordedValue;
  };
};

// 'signoff' | 'fail' | 'skip' | 'pause' | 'resume' | 'complete'
export type ActionType = (typeof ACTION_TYPE)[keyof typeof ACTION_TYPE];

type UpdateFunc = (run: Run | null) => Promise<Run | void>;
type cancelFunc = { cancel: () => void };

export type RepeatSectionOptions = {
  sectionRepeat: RepeatedSection;
  newToOldStepIds: { [id: string]: string };
};

class RunService {
  private teamId: string;
  private name: string;
  private restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    this.name = `runs_${teamId}`;
    this.restUrl = `${API_URL}/teams/${this.teamId}/runs`; // URL to E3 backend server
  }

  private static _updateDocWithSignOff(
    run: Run,
    userId: string,
    sectionIndex: number,
    stepIndex: number,
    signedOffAt: string,
    signoffId: string,
    operator: string,
    recorded: RecordedBlocks
  ): void {
    if (!run.sections[sectionIndex].steps[stepIndex].actions) {
      run.sections[sectionIndex].steps[stepIndex].actions = [];
    }

    // Add recorded info
    updateDocWithRecorded({ run, sectionIndex, stepIndex, recorded });

    const conditionalValue = stepConditionals.getRecordedConditionalValue(
      recorded as RunFieldInputConditionalBlock,
      run.sections[sectionIndex].steps[stepIndex]
    );

    // Add new signoff record
    run.sections[sectionIndex].steps[stepIndex].actions?.push({
      type: 'signoff',
      signoff_id: signoffId,
      operator,
      timestamp: signedOffAt,
      user_id: userId,
      ...(conditionalValue && { conditional_value: conditionalValue }),
    });
  }

  private static _updateStepWithComplete(
    run: Run,
    userId: string,
    sectionIndex: number,
    stepIndex: number,
    timestamp: string,
    recorded: RecordedBlocks
  ): void {
    if (!run.sections[sectionIndex].steps[stepIndex].actions) {
      run.sections[sectionIndex].steps[stepIndex].actions = [];
    }

    // Add recorded info
    updateDocWithRecorded({ run, sectionIndex, stepIndex, recorded });

    const conditionalValue = stepConditionals.getRecordedConditionalValue(
      recorded as RunFieldInputConditionalBlock,
      run.sections[sectionIndex].steps[stepIndex]
    );

    // Add new completion record
    run.sections[sectionIndex].steps[stepIndex].actions?.push({
      type: 'complete',
      timestamp,
      user_id: userId,
      ...(conditionalValue && { conditional_value: conditionalValue }),
    });
  }

  private static _updateStepState(
    step: RunStep,
    state: 'failed',
    updatedAt: string,
    userId: string
  ): void {
    step.state = state;

    if (!step.actions) {
      step.actions = [];
    }

    // Add new failure record
    step.actions.push({
      type: 'fail',
      timestamp: updatedAt,
      user_id: userId,
    });
  }

  private static _updateDocWithStepFailure(
    run: Run,
    userId: string,
    sectionIndex: number,
    stepIndex: number,
    failedAt: string,
    recorded: RecordedBlocks
  ): void {
    // Add recorded info
    updateDocWithRecorded({ run, sectionIndex, stepIndex, recorded });

    const step = run.sections[sectionIndex].steps[stepIndex];
    RunService._updateStepState(step, 'failed', failedAt, userId);
  }

  private static _updateDocWithStepCompletion(
    run: Run,
    userId: string,
    sectionIndex: number,
    stepIndex: number,
    completedAt: string
  ): void {
    const runStep = run.sections[sectionIndex].steps[stepIndex];
    runStep.completed = true;
    runStep.completedAt = completedAt;
    runStep.completedUserId = userId;
  }

  /**
   * Saves the given run document to the backend, retrying in case of conflicts.
   *
   * On conflict, refetches the run document and retries the update after
   * calling the provided `updateFunc` to reapply the pending changes.
   *
   * Note that this is a simple optimization and the network call is not
   * guaranteed to be successful.
   *
   * @param updateFunc function with signature updateFunc(run) that accepts a run and
   *                   returns a promise that resolves to an updated run.
   * @param run the run document passed to the updateFunc.
   * @param retries retries remaining, defaults to MAX_RETRIES
   * @returns Promise that resolves on success, or rejects with final error.
   */
  private async _putRunRetrying(
    updateFunc: UpdateFunc,
    run: Run | null,
    url?: string,
    retries = MAX_RETRIES
  ): Promise<AxiosResponse | void> {
    const updated = await updateFunc(run);
    // An empty document indicates a noop.
    if (!updated) {
      return Promise.resolve();
    }
    if (!run) {
      return Promise.reject('run is null');
    }
    const requestUrl = url || `${this.restUrl}/${run._id}`;
    try {
      return await superlogin.getHttp().put(requestUrl, { data: updated });
    } catch (error) {
      // Return original error if this was the last attempt.
      if (retries <= 0) {
        const raiseError = new RunsError(error.message, error.response.status);
        return Promise.reject(raiseError);
      }
      // Retry on document conflicts.
      if (error && error.response.status === 409) {
        const runFromDb = await this.getRun(run._id);
        return this._putRunRetrying(updateFunc, runFromDb, url, retries - 1);
      }
      // Return original error.
      const raiseError = new RunsError(error.message, error.response.status);
      return Promise.reject(raiseError);
    }
  }

  private async _postActionRetrying(
    type: ActionType,
    runId: string,
    comment?: string,
    retries = MAX_RETRIES
  ): Promise<AxiosResponse | void> {
    const url = `${this.restUrl}/${runId}/${type}`;
    try {
      return await superlogin.getHttp().post(url, comment && { comment });
    } catch (error) {
      // Return original error if this was the last attempt.
      if (retries <= 0) {
        const raiseError = new RunsError(error.message, error.response.status);
        return Promise.reject(raiseError);
      }
      // Retry on document conflicts.
      if (error && error.response.status === 409) {
        return this._postActionRetrying(type, runId, comment, retries - 1);
      }
      // Return original error.
      const raiseError = new RunsError(error.message, error.response.status);
      return Promise.reject(raiseError);
    }
  }

  static updateDocWithLinkedRun(
    run: Run,
    sectionId: string,
    stepId: string,
    contentId: string,
    linkedRunId: string
  ): boolean {
    const { sectionIndex, stepIndex } = runUtil.getSectionAndStepIndices(
      run,
      sectionId,
      stepId
    );
    const contentIndex = run.sections[sectionIndex].steps[
      stepIndex
    ].content.findIndex((content) => content.id === contentId);
    if (contentIndex === -1) {
      throw new Error('Invalid content index.');
    }

    // If run is already linked, drop this request
    if (
      (
        run.sections[sectionIndex].steps[stepIndex].content[
          contentIndex
        ] as RunProcedureLinkBlock
      ).run === linkedRunId
    ) {
      return false;
    }

    // Link run.
    (
      run.sections[sectionIndex].steps[stepIndex].content[
        contentIndex
      ] as RunProcedureLinkBlock
    ).run = linkedRunId;

    // Run doc was modified
    return true;
  }

  static removeLinkedRunFromDoc(
    run: Run,
    sectionId: string,
    stepId: string,
    contentId: string,
    linkedRunId: string
  ): void {
    const { sectionIndex, stepIndex } = runUtil.getSectionAndStepIndices(
      run,
      sectionId,
      stepId
    );
    const contentIndex = run.sections[sectionIndex].steps[
      stepIndex
    ].content.findIndex((content) => content.id === contentId);
    if (contentIndex === -1) {
      return;
    }

    const block = run.sections[sectionIndex].steps[stepIndex].content[
      contentIndex
    ] as RunProcedureLinkBlock;

    if (block?.run === linkedRunId) {
      delete block.run;
    }
  }

  /**
   * Updates the run document with the given linked procedure.
   */
  private static async _tryAddLinkedRun(
    run: Run,
    sectionId: string,
    stepId: string,
    contentId: string,
    linkedRunId: string
  ): Promise<void> {
    // If run is already completed, drop this request
    if (run.state === RUN_STATE.COMPLETED) {
      return Promise.resolve();
    }

    const updated = cloneDeep(run);
    try {
      const changed = RunService.updateDocWithLinkedRun(
        updated,
        sectionId,
        stepId,
        contentId,
        linkedRunId
      );
      if (!changed) {
        // Resolve with empty results to indicate noop.
        return Promise.resolve();
      }
      return Promise.resolve(updated);
    } catch (error) {
      // Something unexpected happened, rethrow the error.
      return Promise.reject(error);
    }
  }

  async addLinkedRun(
    run: Run,
    sectionId: string,
    stepId: string,
    contentId: string,
    linkedRunId: string
  ): Promise<AxiosResponse | void> {
    const updateFunc = (updated) =>
      RunService._tryAddLinkedRun(
        updated,
        sectionId,
        stepId,
        contentId,
        linkedRunId
      );
    return this._putRunRetrying(updateFunc, run);
  }

  async getRun(id: string): Promise<Run> {
    const url = `${this.restUrl}/${id}`;
    try {
      const response = await superlogin.getHttp().get(url);
      return response.data;
    } catch (error) {
      const raiseError = new RunsError(error.message, error.response.status);
      return Promise.reject(raiseError);
    }
  }

  /**
   * Calls GET /runs
   * Fetches a list of completed run documents for a given procedure.
   *
   * TODO: Number of results is practically unlimited, this needs to be redone
   * to support paged results.
   */
  async getRunHistoryByProcedureId(
    procedureId: string
  ): Promise<Array<Run> | void> {
    const params = {
      'procedure-id': procedureId,
      'run-state': RUN_STATE.COMPLETED,
      limit: Number.MAX_SAFE_INTEGER,
    };
    try {
      const response = await superlogin.getHttp().get(this.restUrl, { params });
      return response.data.data;
    } catch {
      /* empty */
    }
  }

  async getCompletedRunSummaries(
    operationKeys?: Array<string>
  ): Promise<Array<RunMetadata>> {
    const params = {
      'run-state': RUN_STATE.COMPLETED,
      'operation-keys': operationKeys,
    };
    try {
      const results = await superlogin
        .getHttp()
        .get(`${this.restUrl}/summary`, { params });
      return Promise.resolve(results.data.data as Array<RunMetadata>);
    } catch {
      /*
       * This can happen when the app is offline.  The only usage of this is in the home screen
       * which is expecting (via Typescript) a list, so better in this case to return an empty list
       * than catch the error and provide NO return
       */
      return [];
    }
  }

  async getCompletedRunInsights(): Promise<Array<Run>> {
    try {
      const results = await superlogin
        .getHttp()
        .get(`${this.restUrl}/insights`);
      return Promise.resolve(results.data.data as Array<Run>);
    } catch {
      /*
       * This can happen when the app is offline.  The only usage of this is in the home screen
       * which is expecting (via Typescript) a list, so better in this case to return an empty list
       * than catch the error and provide NO return
       */
      return [];
    }
  }

  async getActiveRunSummaries(
    operationKeys?: Array<string>
  ): Promise<Array<RunMetadata>> {
    const params = {
      'run-state': ACTIVE_RUN_STATES,
      'operation-keys': operationKeys,
    };
    const results = await superlogin
      .getHttp()
      .get(`${this.restUrl}/summary`, { params });
    return Promise.resolve(results.data.data as Array<RunMetadata>);
  }

  /**
   * Updates a run doc with the given step signoff, modifying the doc in place.
   *
   * This method facilitates "optimistic UI" techniques to show the results of
   * an action before the action is persisted and saved to the db.
   *
   * @returns True if document was modified, otherwise false if no change was
   *          made. No change can happen if run is already complete, step is
   *          already complete or skipped, or other.
   */
  static updateDocWithStepSignoff(
    run: Run,
    userId: string,
    sectionId: string,
    stepId: string,
    signoffId: string,
    completedAt: string,
    operator: string,
    recorded: RecordedBlocks,
    userOperatorRolesSet: Set<string>
  ): boolean {
    const { sectionIndex, stepIndex } = runUtil.getSectionAndStepIndices(
      run,
      sectionId,
      stepId
    );
    const step = run.sections[sectionIndex].steps[stepIndex];
    try {
      checkCanSignOffStep({
        run,
        step,
        signoffId,
        operator,
        userOperatorRolesSet,
        timestamp: completedAt,
        userId,
      });
    } catch (e) {
      return false;
    }

    RunService._updateDocWithSignOff(
      run,
      userId,
      sectionIndex,
      stepIndex,
      completedAt,
      signoffId,
      operator,
      recorded
    );
    if (signoffUtil.allSignoffsComplete(step)) {
      RunService._updateDocWithStepCompletion(
        run,
        userId,
        sectionIndex,
        stepIndex,
        completedAt
      );
    }
    // Run doc was modified
    return true;
  }

  static updateDocWithStepComplete(
    run: Run,
    userId: string,
    sectionId: string,
    stepId: string,
    completedAt: string,
    recorded: RecordedBlocks
  ): boolean {
    // If run is already completed, drop this request.
    if (run.state === RUN_STATE.COMPLETED) {
      return false;
    }

    const { sectionIndex, stepIndex } = runUtil.getSectionAndStepIndices(
      run,
      sectionId,
      stepId
    );

    RunService._updateStepWithComplete(
      run,
      userId,
      sectionIndex,
      stepIndex,
      completedAt,
      recorded
    );
    RunService._updateDocWithStepCompletion(
      run,
      userId,
      sectionIndex,
      stepIndex,
      completedAt
    );

    // Run doc was modified
    return true;
  }

  /**
   * Saves a step signoff by sending signoff action to backend actions endpoint.
   *
   * @returns Promise, resolves with 204 code or error if ultimately rejected.
   */
  async signOffStep(
    runId: string,
    sectionId: string,
    stepId: string,
    signoffId: string,
    completedAt: string,
    operator: string,
    recorded: RecordedBlocks
  ): Promise<AxiosResponse> {
    const signoff = {
      sectionId,
      stepId,
      signoffId,
      timestamp: completedAt,
      operator,
      recorded,
    };
    const url = `${this.restUrl}/${runId}/actions/signoffs`;
    return superlogin.getHttp().post(url, signoff);
  }

  async pinSignOffStep({
    runId,
    sectionId,
    stepId,
    signoffId,
    timestamp,
    operator,
    pinUser,
    pin,
    recorded,
  }: {
    runId: string;
    sectionId: string;
    stepId: string;
    signoffId: string;
    timestamp: string;
    operator: string;
    pinUser: string;
    pin: string;
    recorded: RecordedBlocks;
  }): Promise<AxiosResponse> {
    const signoff = {
      section_id: sectionId,
      step_id: stepId,
      signoff_id: signoffId,
      timestamp,
      operator,
      pin_user_id: pinUser,
      pin,
      recorded,
    };
    const url = `${this.restUrl}/${runId}/actions/pin-signoffs`;
    return superlogin.getHttp().post(url, signoff);
  }

  async revokeStepSignoff({
    runId,
    sectionId,
    stepId,
    signoffId,
    timestamp,
  }: {
    runId: string;
    sectionId: string;
    stepId: string;
    signoffId: string;
    timestamp: string;
  }): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/revoke-signoff`;
    return superlogin.getHttp().post(url, {
      section_id: sectionId,
      step_id: stepId,
      signoff_id: signoffId,
      timestamp,
    });
  }

  /**
   * Saves a step completion by sending complete action to backend actions endpoint.
   *
   * @returns Promise, API response
   */
  async completeStep(
    runId: string,
    sectionId: string,
    stepId: string,
    completedAt: string,
    recorded: RecordedBlocks
  ): Promise<AxiosResponse> {
    const completion = {
      sectionId,
      stepId,
      timestamp: completedAt,
      recorded,
    };
    const url = `${this.restUrl}/${runId}/actions/complete`;
    return superlogin.getHttp().post(url, completion);
  }

  /**
   * Fails a step by sending a fail action to backend actions endpoint.
   *
   * @returns resolves with a 204 code or error if ultimately rejected.
   */
  async failStep(
    runId: string,
    sectionId: string,
    stepId: string,
    failedAt: string,
    recorded: RecordedBlocks
  ): Promise<AxiosResponse> {
    const failPayload = {
      sectionId,
      stepId,
      timestamp: failedAt,
      recorded,
    };
    const url = `${this.restUrl}/${runId}/actions/fail`;
    return superlogin.getHttp().post(url, failPayload);
  }

  async getRunSteps(
    runId: string
  ): Promise<Array<RunStep | RunAddedStep | RepeatedStep>> {
    const url = `${this.restUrl}/${runId}/steps`;
    const response = await superlogin.getHttp().get(url);
    return response.data.steps;
  }

  async updateBlockRecorded({
    runId,
    sectionId,
    stepId,
    contentId,
    actionId,
    recorded,
    timestamp,
    fieldIndex,
  }: {
    runId: string;
    sectionId: string;
    stepId: string;
    contentId: string;
    actionId: string;
    recorded: RecordedBlocks | Array<CommandingBlockRecorded>;
    timestamp: string;
    fieldIndex?: number;
  }): Promise<AxiosResponse | void> {
    const payload = {
      section_id: sectionId,
      step_id: stepId,
      content_id: contentId,
      action_id: actionId,
      recorded,
      timestamp,
      field_index: fieldIndex,
    };
    const url = `${this.restUrl}/${runId}/actions/content`;
    return superlogin.getHttp().post(url, payload);
  }

  async updateStepDetail({
    runId,
    sectionId,
    stepId,
    field,
    value,
  }: {
    runId: string;
    sectionId: string;
    stepId: string;
    field: string;
    value;
  }): Promise<AxiosResponse> {
    const payload = {
      section_id: sectionId,
      step_id: stepId,
      field,
      value,
    };
    const url = `${this.restUrl}/${runId}`;
    return superlogin.getHttp().patch(url, payload);
  }

  /**
   * Updates a run doc with the given step failed, modifying the doc in place.
   *
   * This method facilitates "optimistic UI" techniques to show the results of
   * an action before the action is persisted and saved to the db.
   *
   * @returns True if document was modified, otherwise false if no change was
   *          made. No change can happen if run is already complete, step is
   *          already complete, failed or skipped, or other.
   */
  static updateDocWithStepFailure(
    run: Run,
    userId: string,
    sectionId: string,
    stepId: string,
    failedAt: string,
    recorded: RecordedBlocks
  ): boolean {
    const { sectionIndex, stepIndex } = runUtil.getSectionAndStepIndices(
      run,
      sectionId,
      stepId
    );
    // If step is already ended, drop this request
    if (RunService._isStepEnded(run, sectionIndex, stepIndex)) {
      return false;
    }

    RunService._updateDocWithStepFailure(
      run,
      userId,
      sectionIndex,
      stepIndex,
      failedAt,
      recorded
    );
    // Run doc was modified
    return true;
  }

  /**
   * Updates a run doc with the given step detail, modifying the doc in place.
   *
   * This method facilitates "optimistic UI" techniques to show the results of
   * an action before the action is persisted and saved to the db.
   *
   * @returns True if document was modified, otherwise false if no change was
   *          made. No change can happen if run is already complete, step is
   *          already complete, failed or skipped, or other.
   */
  static updateDocWithStepDetail(
    run: Run,
    sectionId: string,
    stepId: string,
    field: string,
    value: unknown
  ): boolean {
    const whiteListDetail = ['duration', 'timer'];
    const { sectionIndex, stepIndex } = runUtil.getSectionAndStepIndices(
      run,
      sectionId,
      stepId
    );
    // If step is already ended, drop this request
    if (RunService._isStepEnded(run, sectionIndex, stepIndex)) {
      return false;
    }

    const step = run.sections[sectionIndex].steps[stepIndex];

    if (step && whiteListDetail.includes(field)) {
      step[field] = value;
    }

    // Run doc was modified
    return true;
  }

  static updateDocWithRedlineHeader(
    run: Run,
    userId: string,
    headerId: string,
    redlinedHeader: RunRedline,
    pending: boolean,
    headerRedlineMetadata: HeaderRedlineMetadata,
    isRedline: boolean
  ): boolean {
    // If run is already completed, drop this request.
    if (run.state === RUN_STATE.COMPLETED) {
      return false;
    }

    if (!run.headers) {
      throw new Error('Run does not have the headers field');
    }
    const headerIndex: number | undefined = run.headers.findIndex(
      (header) => header.id === headerId
    );
    if (headerIndex === undefined || headerIndex === -1) {
      throw new Error('Invalid header id');
    }
    const header = run.headers[headerIndex];
    const headerRedline = revisions._newHeaderRedline(
      redlinedHeader,
      userId,
      pending,
      headerRedlineMetadata,
      isRedline
    );

    RunService._updateDocWithHeaderRedline(header, headerRedline);
    return true;
  }

  async addRedlineHeader(
    run: Run | null,
    header: ReleaseHeader,
    pending: boolean,
    headerRedlineMetadata: HeaderRedlineMetadata,
    isRedline: boolean
  ): Promise<AxiosResponse | void> {
    if (!run) {
      throw new Error('Missing run document');
    }
    const createdAt = new Date();
    return this.saveHeaderRedline(
      run?._id,
      createdAt.toISOString(),
      header,
      pending,
      headerRedlineMetadata,
      !isRedline
    );
  }

  private static async _trySkipStep(
    run: Run,
    userId: string,
    sectionId: string,
    stepId: string,
    skippedAt: string,
    recorded: RecordedBlocks
  ): Promise<void> {
    const updated = cloneDeep(run);

    try {
      const changed = updateDocWithStepSkipped({
        run: updated,
        userId,
        sectionId,
        stepId,
        skippedAt,
        recorded,
      });

      if (!changed) {
        // Resolve with empty results to indicate noop.
        return Promise.resolve();
      }
      return Promise.resolve(updated);
    } catch (error) {
      // Something unexpected happened, rethrow the error.
      return Promise.reject(error);
    }
  }

  async skipStep(
    run: Run,
    userId: string,
    sectionId: string,
    stepId: string,
    skippedAt: string,
    recorded: RecordedBlocks
  ): Promise<AxiosResponse | void> {
    const updateFunc = (updated) =>
      RunService._trySkipStep(
        updated,
        userId,
        sectionId,
        stepId,
        skippedAt,
        recorded
      );
    return this._putRunRetrying(updateFunc, run);
  }

  private static async _trySkipSection(
    run: Run,
    userId: string,
    sectionId: string,
    skippedAt: string,
    recordedAllSectionSteps: RecordedAllSectionSteps
  ): Promise<void> {
    const updated = cloneDeep(run);

    try {
      const changed = updateDocWithSectionSkipped({
        run: updated,
        userId,
        sectionId,
        skippedAt,
        recordedAllSectionSteps,
      });

      if (!changed) {
        return Promise.resolve();
      }
      return Promise.resolve(updated);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Skips each step in a section that is not already completed or skipped
   *
   * If all steps in the section are either completed or skipped, this function
   * returns a rejected Promise.
   * @param recordedAllSectionSteps contains recorded data to be saved for each step
   *                                in the section.
   *                                TODO: refactor this data structure to emulate the run
   *                                doc
   */
  async skipSection(
    run: Run,
    userId: string,
    sectionId: string,
    skippedAt: string,
    recordedAllSectionSteps: RecordedAllSectionSteps
  ): Promise<AxiosResponse | void> {
    const updateFunc = (updated) =>
      RunService._trySkipSection(
        updated,
        userId,
        sectionId,
        skippedAt,
        recordedAllSectionSteps
      );
    return this._putRunRetrying(updateFunc, run);
  }

  private static _isStepEnded(
    run: Run,
    sectionIndex: number,
    stepIndex: number
  ): boolean {
    const step = run.sections[sectionIndex].steps[stepIndex];
    return isStepEnded(step);
  }

  static createStepRepeat(
    run: Run,
    userId: string,
    sectionId: string,
    stepId: string
  ): RepeatedStep {
    const { sectionIndex, stepIndex } = runUtil.getSectionAndStepIndices(
      run,
      sectionId,
      stepId
    );

    const lastStep = run.sections[sectionIndex].steps[stepIndex];
    const stepRepeat = copyStepWithoutActiveContent(lastStep) as RepeatedStep;

    // Update ids and properties denoting that the step is repeated
    procedureUtil.updateStepWithNewIds(stepRepeat);
    stepRepeat.repeated_user_id = userId;
    stepRepeat.repeated_at = new Date().toISOString();
    stepRepeat.repeat_of = lastStep.id;

    return stepRepeat;
  }

  static createSectionRepeat(
    run: Run,
    userId: string,
    sectionId: string
  ): RepeatSectionOptions {
    const sectionIndex = runUtil.getSectionIndex(run, sectionId);
    const lastSection = run.sections[sectionIndex];
    const sectionRepeat = runUtil.copySectionWithoutActiveContent(lastSection);

    // Update ids and properties denoting that the step is repeated
    const newToOldStepIds =
      procedureUtil.updateSectionWithNewIds(sectionRepeat);
    sectionRepeat.repeated_user_id = userId;
    sectionRepeat.repeated_at = new Date().toISOString();
    sectionRepeat.repeat_of = lastSection.id;

    return {
      sectionRepeat,
      newToOldStepIds,
    };
  }

  /**
   * Tries to persist a repeated step that is a repeat of the step with id `sourceStepId`,
   *  retrying on document conflicts. Returns a rejected promise if the run is completed.
   *
   *  If the step with specified id is already repeated, the sourceStepId is updated to be id of the latest existing repeat.
   *
   *  @param run The run document to add the repeated step to
   *  @param userId The id of the user repeating the step
   *  @param recorded Data to record upon repeating step
   *  @param stepRepeat The repeated step object
   *  @param sectionId Section id of the section containing the step to be repeated
   *  @param sourceStepId Step id of the step to be repeated. The repeat_of field of the
   *                      newly created repeated step is populated with this id
   * @param includeRedlines whether to display pending redlines
   */
  private static async _tryRepeatStep(
    run: Run,
    userId: string,
    recorded: RecordedBlocks,
    stepRepeat: RepeatedStep,
    sectionId: string,
    sourceStepId: string,
    includeRedlines: boolean
  ): Promise<Run | void> {
    if (run.state === RUN_STATE.COMPLETED) {
      return Promise.reject(new Error('Run already completed'));
    }
    const updated = cloneDeep(run);

    try {
      const changed = updateDocWithStepRepeated({
        run: updated,
        userId,
        recorded,
        stepRepeat,
        sectionId,
        sourceStepId,
        includeRedlines,
      });

      if (!changed) {
        // Resolve with empty results to indicate noop.
        return Promise.resolve();
      }
      return Promise.resolve(updated);
    } catch (error) {
      // Something unexpected happened, rethrow the error.
      return Promise.reject(error);
    }
  }

  /**
   * Tries to persist a repeated section that is a repeat of the section with id `sourceSectionId`,
   *  retrying on document conflicts. Returns a rejected promise if the run is completed.
   *
   *  If the step with specified id is already repeated, the sourceStepId is updated to be id of the latest existing repeat.
   *
   *  @param run The run document to add the repeated step to
   *  @param userId The id of the user repeating the step
   *  @param recordedAllSectionSteps Data to record upon repeating step
   *  @param sectionRepeatOptions Includes information about the section being repeated.
   *  @param sourceSectionId Step id of the step to be repeated. The repeat_of field of the
   *                         newly created repeated step is populated with this id
   *  @param includeRedlines
   */
  private static async _tryRepeatSection(
    run: Run,
    userId: string,
    recordedAllSectionSteps: RecordedAllSectionSteps,
    sectionRepeatOptions: RepeatSectionOptions,
    sourceSectionId: string,
    includeRedlines: boolean
  ): Promise<void> {
    if (run.state === RUN_STATE.COMPLETED) {
      return Promise.reject(new Error('Run already completed'));
    }

    const updated = cloneDeep(run);

    try {
      const changed = updateDocWithSectionRepeated({
        run: updated,
        userId,
        recordedAllSectionSteps,
        sectionRepeatOptions,
        sectionId: sourceSectionId,
        includeRedlines,
      });

      if (!changed) {
        // Resolve with empty results to indicate noop.
        return Promise.resolve();
      }
      return Promise.resolve(updated);
    } catch (error) {
      // Something unexpected happened, rethrow the error.
      return Promise.reject(error);
    }
  }

  /**
   * Repeats the step that has the given section and step ids.
   *
   * This function skips the original step and inserts a repeat of the step in the
   * index after the original step. The original step is skipped if it is not already
   * completed or skipped.
   *
   * If another user repeats the same step while this call is in progress, this
   * function returns a rejected Promise.
   */
  async repeatStep(
    run: Run,
    userId: string,
    recorded: RecordedBlocks,
    stepRepeat: RepeatedStep,
    sectionId: string,
    sourceStepId: string,
    includeRedlines: boolean
  ): Promise<AxiosResponse | void> {
    const updateFunc = (updated) =>
      RunService._tryRepeatStep(
        updated,
        userId,
        recorded,
        stepRepeat,
        sectionId,
        sourceStepId,
        includeRedlines
      );
    return this._putRunRetrying(updateFunc, run);
  }

  /**
   * Tries to create a repeated section of the section with id sourceSectionId,
   * retrying on document conflicts. Returns a rejected promise if the run is
   * completed or the section with specified id is already repeated.
   *
   * @param run The run document to add the repeated section to.
   * @param userId The id of the user repeating the section.
   * @param recordedAllSectionSteps Data to record for the section specified by sectionIndex
   *                          when marking that section skipped.
   * @param sectionRepeatOptions Includes information about the section being repeated.
   * @param sourceSectionId Section id of the section to be repeated. The repeat_of field of
   *                  the newly created repeated section is populated with this id.
   * @param includeRedlines whether to display pending redlines
   */

  /**
   * Repeats a section at a given sectionIndex.
   *
   * This function skips all steps that are neither completed nor skipped in the
   * original section and inserts a repeat of that section in the index after the
   * original section.
   *
   * If another user repeats the same section while this call is in progress, this
   * function returns a rejected Promise.
   *
   * @param run The run document to add the repeated section to.
   * @param userId The id of the user repeating the section.
   * @param recordedAllSectionSteps Data to record for the section specified by sectionIndex
   *                                when marking that section skipped.
   * @param sectionRepeatOptions Includes information about the section being repeated.
   * @param sourceSectionId Section id of the section to be repeated.
   * @param includeRedlines
   */
  async repeatSection(
    run: Run,
    userId: string,
    recordedAllSectionSteps: RecordedAllSectionSteps,
    sectionRepeatOptions: RepeatSectionOptions,
    sourceSectionId: string,
    includeRedlines: boolean
  ): Promise<AxiosResponse | void> {
    const updateFunc = (updated) =>
      RunService._tryRepeatSection(
        updated,
        userId,
        recordedAllSectionSteps,
        sectionRepeatOptions,
        sourceSectionId,
        includeRedlines
      );

    return this._putRunRetrying(updateFunc, run);
  }

  /**
   * Ends a run, recording data and comment if provided.
   *
   * This function skips all steps that are neither completed nor skipped in
   * the run.
   *
   * @param runId The id of the run document to end
   * @param recordedAllSteps Data to record for the run specified by section and step.
   * @param comment text comment to record at end of run.
   * @param status status of the ended run
   */
  async endRun(
    runId: string,
    recordedAllSteps: RecordedAllSectionSteps,
    comment: string,
    status: RunStatus,
    endedAt?: string
  ): Promise<AxiosResponse> {
    /*
     * TODO this internal API route is deprecated
     * move simulated telemetry logic to backend
     * and use external end run API instead (EPS-4060)
     */
    const url = `${this.restUrl}/${runId}/actions/end`;
    const body = {
      recordedAllSteps,
      comment,
      status,
      endedAt,
    };
    return superlogin.getHttp().post(url, body);
  }

  async endRunExternal(
    runId: string,
    comment?: string,
    status?: RunStatus
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/end`;
    const body = {
      comment,
      status,
    };
    return superlogin.getHttp().post(url, body);
  }

  async reopenRun({
    runId,
    comment,
    timestamp,
  }: {
    runId: string;
    comment: string;
    timestamp: string;
  }): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/reopen`;
    const body = {
      comment,
      timestamp,
    };
    return superlogin.getHttp().post(url, body);
  }

  async startRun(run: Run): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${run._id}`;
    return superlogin.getHttp().put(url, { data: run });
  }

  private static _updateDocWithHeaderRedline(
    header: RunHeader,
    headerRedline: RunHeaderRedline
  ): void {
    if (!header.redlines) {
      header.redlines = [];
    }
    header.redlines.push(headerRedline);
  }

  /**
   * Accepts the pending header redline to the given run document and persists to db.
   *
   * @param run The run document to accept pending redline on.
   * @param userId The id of the user accepting the redline.
   * @param headerId Header id of the redlined header.
   * @param redlineIndex Index of redline to mark as not pending.
   *
   * @returns A promise that resolves when the update has been persisted to the db.
   */
  async acceptPendingHeaderRedline(
    run: Run,
    userId: string,
    headerId: string,
    redlineIndex: number
  ): Promise<AxiosResponse | void> {
    const updateFunc = (updated) =>
      revisions.updateDocWithPendingHeaderRedlineAccepted(
        updated,
        userId,
        headerId,
        redlineIndex
      );
    return this._putRunRetrying(updateFunc, run);
  }

  /**
   * Accepts the pending section header redline to the given run document and persists to db.
   *
   * @param run The run document to accept pending redline on.
   * @param userId The id of the user accepting the redline.
   * @param sectionHeaderId Section header id of the redlined section header.
   * @param redlineIndex Index of redline to mark as not pending.
   *
   * @returns A promise that resolves when the update has been persisted to the db.
   */
  async acceptPendingSectionHeaderRedline(
    run: Run,
    userId: string,
    sectionHeaderId: string,
    redlineIndex: number
  ): Promise<AxiosResponse | void> {
    const updateFunc = (updated) =>
      revisions.updateDocWithPendingSectionHeaderRedlineAccepted(
        updated,
        userId,
        sectionHeaderId,
        redlineIndex
      );
    return this._putRunRetrying(updateFunc, run);
  }

  static updateDocWithStepComment(
    run: Run,
    userId: string,
    sectionId: string,
    stepId: string,
    timestamp: string,
    comment: RunStepComment
  ): void {
    // If run is already completed, drop this request.
    if (run.state === RUN_STATE.COMPLETED) {
      return;
    }

    const { sectionIndex, stepIndex } = runUtil.getSectionAndStepIndices(
      run,
      sectionId,
      stepId
    );

    // Prepare comment section
    const runStep = run.sections[sectionIndex].steps[stepIndex];
    if (!runStep.comments) {
      runStep.comments = [];
    }

    if (wasEdited(comment.timestamp, comment.updated_at)) {
      const commentIndex = runStep.comments.findIndex(
        (existingComment) => existingComment.id === comment.id
      );

      if (commentIndex !== -1) {
        try {
          validateCanEditComment(
            comment.timestamp,
            comment.updated_at as string
          );
        } catch {
          return;
        }

        runStep.comments[commentIndex] = comment;
      }
    } else {
      // Append comment
      runStep.comments.push({
        ...comment,
        timestamp,
        user: userId,
      });
    }
  }

  static removeStepCommentFromDoc(
    run: Run,
    sectionId: string,
    stepId: string,
    commentId: string
  ): void {
    const { sectionIndex, stepIndex } = runUtil.getSectionAndStepIndices(
      run,
      sectionId,
      stepId
    );

    const runStep = run.sections[sectionIndex].steps[stepIndex];
    if (runStep.comments) {
      runStep.comments = runStep.comments.filter(
        (comment) => comment.id !== commentId
      );
    }
  }

  async addStepComment(
    runId: string,
    comment: RunStepComment,
    commentContext: {
      sectionId: string;
      stepId: string;
      contentId?: string;
      rowIndex?: number;
      columnIndex?: number;
    }
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/comments`;
    return superlogin.getHttp().post(url, {
      section_id: commentContext.sectionId,
      step_id: commentContext.stepId,
      content_id: commentContext.contentId,
      row_index: commentContext.rowIndex,
      column_index: commentContext.columnIndex,
      comment,
    });
  }

  /**
   * Adds a step to a running procedure.
   *
   * @returns response - API response.
   */
  async addStep({
    runId,
    sectionId,
    precedingStepId,
    createdAt,
    step,
    runOnly,
  }: {
    runId: string;
    sectionId: string;
    precedingStepId: string;
    createdAt: string;
    step: RunAddedStep;
    runOnly: boolean;
  }): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/steps`;
    const body = {
      step,
      section_id: sectionId,
      preceding_step_id: precedingStepId,
      created_at: createdAt,
      run_only: runOnly,
    };
    return superlogin.getHttp().post(url, body);
  }

  /**
   * Adds a suggested edit to a running procedure.
   *
   * @returns response - API response.
   */
  async addFullStepSuggestedEdit({
    runId,
    sectionId,
    stepId,
    redline,
    includeInRun,
  }: {
    runId: string;
    sectionId: string;
    stepId: string;
    redline: RunStepFullRedline;
    includeInRun: boolean;
  }): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/suggested-edit`;
    const body = {
      redline,
      section_id: sectionId,
      step_id: stepId,
      include_in_run: includeInRun,
    };
    return superlogin.getHttp().post(url, body);
  }

  /**
   * Includes an existing suggested edit to a running procedure.
   *
   * @returns response - API response.
   */
  async includeFullStepSuggestedEdit({
    runId,
    sectionId,
    stepId,
    redlineId,
    includedAt,
  }: {
    runId: string;
    sectionId: string;
    stepId: string;
    redlineId: string;
    includedAt: string;
  }): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/suggested-edit/include`;
    const body = {
      section_id: sectionId,
      step_id: stepId,
      redline_id: redlineId,
      included_at: includedAt,
    };
    return superlogin.getHttp().post(url, body);
  }

  /**
   * Edits an existing suggested edit comment in a running procedure.
   *
   * @returns response - API response.
   */
  async editSuggestedEditComment({
    runId,
    sectionId,
    stepId,
    redlineId,
    commentId,
    updatedText,
    updatedAt,
  }: {
    runId: string;
    sectionId: string;
    stepId: string;
    redlineId: string;
    commentId: string;
    updatedText: string;
    updatedAt: string;
  }): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/suggested-edit/${redlineId}/comment/${commentId}`;
    const body = {
      section_id: sectionId,
      step_id: stepId,
      updated_text: updatedText,
      updated_at: updatedAt,
    };
    return superlogin.getHttp().patch(url, body);
  }

  onRunChanged(
    id: string,
    onChange: () => void,
    onError?: (err) => void
  ): cancelFunc {
    const customRequestor = async () => {
      const run = await this.getRun(id);
      return [run];
    };
    return onDocChanged(this.name, id, onChange, onError, customRequestor);
  }

  onStepsChanged(
    runId: string,
    callback: (runs: Array<RunStep>) => void
  ): cancelFunc {
    const url = `${this.restUrl}/${runId}/steps/changes`;
    const resourceUrl = `${this.restUrl}/${runId}/steps`;
    return onResourceChanged(url, resourceUrl, callback);
  }

  /**
   * Register callback that triggers when anything in the runs db changes.
   *
   * It is the caller's responsibility to cancel the returned observer with
   * observer.cancel().
   *
   * Eg:
   *    const observer = onRunsChanged(() => {})
   *    ...
   *    observer.cancel()
   *
   * callback: A function of type fn() called whenever a change event happens.
   *
   * @returns An EventEmitter for cancelling the observer.
   */
  onRunsChanged(callback: (runs: Array<Run>) => void): cancelFunc {
    // Observe entire database
    return onSelectorChanged(this.name, null, null, callback, null);
  }

  onActiveRunsChanged(callback: () => void): cancelFunc {
    // Observe entire database
    return onSelectorChanged(this.name, null, null, callback, null);
  }

  // Getter method for teamId.
  getTeamId(): string {
    return this.teamId;
  }

  private static _updateDocWithParticipantType(
    run: Run,
    userId: string,
    createdAt: string,
    type: 'participant' | 'viewer'
  ): void {
    if (!run.participants) {
      run.participants = [];
    }

    const participant = {
      user_id: userId,
      created_at: createdAt,
      type,
    };

    const participantIndex = run.participants.findIndex(
      (p) => p.user_id === userId
    );
    if (participantIndex === -1) {
      run.participants.push(participant);
    } else {
      run.participants[participantIndex] = participant;
    }
  }

  static updateDocWithParticipantAdded(
    run: Run,
    userId: string,
    createdAt: string
  ): void {
    RunService._updateDocWithParticipantType(
      run,
      userId,
      createdAt,
      'participant'
    );
  }

  static updateDocWithParticipantRemoved(
    run: Run,
    userId: string,
    createdAt: string
  ): void {
    RunService._updateDocWithParticipantType(run, userId, createdAt, 'viewer');
  }

  async addParticipant(
    runId: string,
    createdAt: string
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/participants`;
    const participant = {
      created_at: createdAt,
      type: PARTICIPANT_TYPE.PARTICIPATING,
    };
    return superlogin.getHttp().post(url, participant);
  }

  async removeParticipant(
    runId: string,
    createdAt: string
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/participants`;
    const participant = {
      created_at: createdAt,
      type: PARTICIPANT_TYPE.VIEWING,
    };
    return superlogin.getHttp().post(url, participant);
  }

  /**
   * Adds/Updates the given redline comment to the given run document and persists to db.
   * @returns A promise that resolves when the update has been persisted to the db.
   */
  // TODO - Add validateCanEditComment validation to the backend when redline api is updated.
  async addRedlineStepComment(
    run: Run,
    userId: string,
    stepId: string,
    text: string,
    commentId: string
  ): Promise<AxiosResponse | void> {
    const updateFunc = (updated) =>
      RunService._tryAddRedlineStepCommment(
        updated,
        userId,
        stepId,
        text,
        commentId
      );
    return this._putRunRetrying(updateFunc, run);
  }

  static async _tryAddRedlineStepCommment(
    run: Run,
    userId: string,
    stepId: string,
    text: string,
    commentId: string
  ): Promise<void> {
    const updated = cloneDeep(run);

    try {
      const changed = RunService.updateDocWithRedlineStepComment(
        updated,
        userId,
        stepId,
        text,
        commentId
      );

      if (!changed) {
        // Resolve with empty results to indicate noop.
        return Promise.resolve();
      }
      return Promise.resolve(updated);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Updates a run doc with the comment, modifying the doc in place.
   * @returns True if document was modified, otherwise false if no change was
   *          made. No change can happen if the run is already completed.
   */
  static updateDocWithRedlineStepComment(
    run: Run,
    userId: string,
    stepId: string,
    text: string,
    commentId: string
  ): boolean {
    if (!run) {
      throw new Error('Missing run document');
    }

    // If run is already completed, drop this request.
    if (run.state === RUN_STATE.COMPLETED) {
      return false;
    }

    const step = procedureUtil.getStepById(run, stepId);
    if (!step) {
      throw new Error('Step not found');
    }

    if (!isSuggestEditsStepSettingEnabled({ step, procedure: run })) {
      return false;
    }

    const sectionId = getSectionId({ run, stepId });
    if (!sectionId) {
      return false;
    }
    // Can only edit redline comments in the latest step.
    if (!isLatestStep({ run, sectionId, stepId })) {
      return false;
    }
    const wasUpdated = RunService._updateDocWithRedlineStepComment(
      step,
      userId,
      text,
      commentId,
      run.actions
    );

    if (!wasUpdated) {
      return false;
    }

    // Run doc was modified
    return true;
  }

  private static _updateDocWithRedlineStepComment(
    step: RunStep,
    userId: string,
    text: string,
    commentId: string,
    actions?: Array<RunAction>
  ): boolean {
    if (!step.redline_comments) {
      step.redline_comments = [];
    }

    if (!commentId) {
      return false;
    }
    const existingCommentIndex = step.redline_comments.findIndex(
      (comment) => comment.id === commentId
    );
    if (existingCommentIndex === -1) {
      // Comment id not found: Adding a comment
      const comment = {
        id: commentId,
        redline_id: generateRedlineDocId(),
        text,
        user_id: userId,
        created_at: new Date().toISOString(),
      };
      step.redline_comments.push(comment);
    } else {
      // Comment id found: Editing a comment
      const existingComment = step.redline_comments[existingCommentIndex];
      if (isBeforeReopen({ actions, timestamp: existingComment.created_at })) {
        return false;
      }

      const updatedAt = new Date().toISOString();

      try {
        validateCanEditComment(existingComment.created_at, updatedAt);
      } catch {
        return false;
      }

      existingComment.text = text;
      existingComment.updated_at = updatedAt;
    }
    return true;
  }

  async saveVariable(
    run: Run,
    name: string,
    variable: RunVariable
  ): Promise<AxiosResponse | void> {
    // Update run doc and set variable value.
    const updateFunc = (run) => {
      const updated = cloneDeep(run);
      // Drop request if variable is not found or value is already set.
      if (!updated || (updated.value !== null && updated.value !== undefined)) {
        return Promise.resolve();
      }
      const variableIndex = updated.variables.findIndex(
        (variable) => variable.name.toLowerCase() === name.toLowerCase()
      );
      if (variableIndex !== -1) {
        updated.variables[variableIndex] = variable;
      }
      return Promise.resolve(updated);
    };

    // Fire away (and re-fire).
    return this._putRunRetrying(updateFunc, run);
  }

  /**
   * Set operation for a run.
   */
  async setOperation(
    runId: string,
    name: string
  ): Promise<string | AxiosResponse> {
    const url = `${this.restUrl}/${runId}/operation`;
    return superlogin.getHttp().post(url, { name });
  }

  /**
   * Clear operation for a run.
   */
  async clearOperation(runId: string): Promise<string | AxiosResponse> {
    const url = `${this.restUrl}/${runId}/operation`;
    return superlogin.getHttp().delete(url);
  }

  static updateDocWithOperation(run: Run, operation: OperationId): void {
    run.operation = operation;
  }

  static updateDocToClearOperation(run: Run): void {
    if (run.state === 'completed') {
      return;
    }
    delete run.operation;
  }

  /**
   * Update run tags document for a run
   * INTERNAL ROUTE ONLY TO SUPPORT CREATING MISSING TAGS DURING ASSIGNMENT
   * SEE THE POST /runs/tags FOR CREATING TAGS AND PATCH /runs/:runId/metadata FOR ASSIGNING TAGS TO A RUN
   */
  async updateRunTags(
    runId: string,
    runTags: Array<RunTag>
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/run-tags`;
    return superlogin.getHttp().post(url, runTags);
  }

  static updateDocWithRunTags(run: Run, runTags: Array<RunTag>): void {
    run.run_tags = runTags;
  }

  async insertAction(
    run: Run,
    type: ActionType,
    comment?: string
  ): Promise<AxiosResponse | void> {
    if (!runUtil.isRunStateActive(run?.state)) {
      return Promise.resolve();
    }

    return this._postActionRetrying(type, run._id, comment);
  }

  /**
   * Calls GET /runs
   * Gets all run documents for an operation.
   *
   * @param operationKey Operation key.
   * @returns results - List of runs with the given operation.
   */
  async getRunsByOperation(operationKey: string): Promise<Array<Run>> {
    const params = {
      'operation-key': operationKey,
      limit: Number.MAX_SAFE_INTEGER,
    };
    const response = await superlogin.getHttp().get(this.restUrl, { params });
    return response.data.data;
  }

  /**
   * Observes run documents for runs with the given operation.
   *
   * @param operationKey Operation key.
   * @param callback Callback used when the result set changes.
   * @returns results - Observer object for cancelling the listener.
   */
  onRunsByOperationChanged(
    operationKey: string,
    callback: () => void
  ): cancelFunc {
    const selector = { 'operation.key': operationKey };
    return onSelectorChanged(this.name, null, selector, callback, null);
  }

  /**
   * Adds header redline to a specified run.
   *
   * @param runId Id of running procedure.
   * @param createdAt Timestamp to use for header redline.
   * @param header Header object to add as a redline.
   * @param pending True if redline is pending.
   * @param headerRedlineMetadata
   * @param run_only If true, then this is a Blueline.
   * @returns response - API response.
   */
  async saveHeaderRedline(
    runId: string,
    createdAt: string,
    header: RedlinedHeader,
    pending: boolean,
    headerRedlineMetadata: HeaderRedlineMetadata,
    run_only = false
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/redlines/headers`;
    const body = {
      header,
      created_at: createdAt,
      pending,
      run_only,
      ...headerRedlineMetadata,
    };
    return superlogin.getHttp().post(url, body);
  }

  /**
   * Adds section header redline to a specified run.
   *
   * @param runId Id of running procedure.
   * @param createdAt Timestamp to use for section header redline.
   * @param sectionHeader section header object to add as a redline.
   * @param pending True if redline is pending.
   * @returns response - API response.
   */
  async saveSectionHeaderRedline(
    runId: string,
    createdAt: string,
    sectionHeader: RunSectionHeader,
    pending: boolean,
    sectionHeaderRedlineMetadata: { content_id: string } | { field: 'name' }
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/redlines/section-headers`;
    const body = {
      sectionHeader,
      created_at: createdAt,
      pending,
      ...sectionHeaderRedlineMetadata,
    };
    return superlogin.getHttp().post(url, body);
  }

  /**
   * Notify remaining operators on incomplete step.
   */
  async notifyRemainingStepOperators(
    runId: string,
    stepId: string
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/notify-operators`;
    const body = { step_id: stepId };
    return superlogin.getHttp().post(url, body);
  }

  /**
   * Queries whether a notification listener is connected.
   *
   * @returns a promise resolving to a boolean indicating whether any
   *          notification listeners are connected.
   */
  async isNotificationListenerConnected(): Promise<boolean> {
    const url = `${API_URL}/teams/${this.teamId}/notification-listener-connected`;
    const response = await superlogin.getHttp().get(url);
    return response.data.listener_connected === true;
  }

  /**
   * Adds a new step issue to a run
   *
   * @returns resolves with a 204 code or error if ultimately rejected.
   */
  async addStepIssue(
    runId: string,
    sectionId: string,
    stepId: string,
    issue: RunIssue
  ): Promise<AxiosResponse> {
    const body = {
      section_id: sectionId,
      timestamp: new Date().toISOString(),
      issue,
    };
    const url = `${this.restUrl}/${runId}/steps/${stepId}/issues`;
    return superlogin.getHttp().post(url, body);
  }

  /**
   * Adds a new (global) issue to a run
   *
   * @returns resolves with a 204 code or error if ultimately rejected.
   */
  async addRunIssue(runId: string, issue: RunIssue): Promise<AxiosResponse> {
    const body = { issue };
    const url = `${this.restUrl}/${runId}/issues`;
    return superlogin.getHttp().post(url, body);
  }

  /**
   * Starts automation on a run
   *
   * @returns resolves with 204 or error
   */
  async startAutomation(
    runId: string,
    resume = false,
    step_id = ''
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/automation/start`;
    const body = { resume, step_id };
    return superlogin.getHttp().post(url, body);
  }

  /**
   * Stops automation on a run
   *
   * @returns resolves with 204 or error
   */
  async stopAutomation(runId: string): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${runId}/automation/stop`;
    return superlogin.getHttp().patch(url);
  }
}

export default RunService;
export { MAX_RETRIES };

import { Run } from 'shared/lib/types/views/procedures';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
import { DateTime } from 'luxon';
import { CouchLikeOperations, CouchLikeOperationsSummary } from 'shared/lib/types/operations';
import { OperationFormValues } from '../OperationFormModal/types';
import labels from 'shared/lib/labelUtil';

interface RunMap {
  [runId: string]: Run;
}

export const compareEventStartTimes = (a: Event, b: Event, runMap: RunMap): number => {
  const aRun = runMap[a.run_id || ''];
  const bRun = runMap[b.run_id || ''];
  const aTime = aRun?.starttime || (a.start as DateTime)?.toUTC().toISO() || '';
  const bTime = bRun?.starttime || (b.start as DateTime)?.toUTC().toISO() || '';
  return aTime < bTime ? -1 : aTime > bTime ? 1 : 0;
};

const REQUIRED_MSG = 'Required.';
export const NAME_EXISTS_MSG = 'Operation already exists.';
const INVALID_CHAR_MSG = '"%" is not allowed.';

export const validateOperationForm = (
  values: OperationFormValues,
  operations: CouchLikeOperations | CouchLikeOperationsSummary | null
) => {
  const errors: { name?: string } = {};
  if (!values.name || !values.name.trim()) {
    errors.name = REQUIRED_MSG;
  }
  if (values.name.indexOf('%') !== -1) {
    errors.name = INVALID_CHAR_MSG;
  }
  // Check that operation name is unique
  const key = labels.getLabelKey(values.name);
  if (operations?.operations[key]) {
    errors.name = NAME_EXISTS_MSG;
  }
  return errors;
};

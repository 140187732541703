import React from 'react';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface JumpToEditSelectOptionProps {
  data: {
    type: string;
    label: string;
  };
  isSelected: boolean;
}

const JumpToEditSelectOption = React.memo((props: JumpToEditSelectOptionProps) => {
  const { data, isSelected } = props;

  return (
    <components.Option {...props}>
      <div className="flex flex-row align-center">
        {data.type === 'step' && (
          <FontAwesomeIcon
            icon="angle-right"
            className={`mr-1 self-center ${isSelected ? 'text-white' : 'text-gray-400'}`}
          />
        )}
        <div>{data.label}</div>
      </div>
    </components.Option>
  );
});

export default JumpToEditSelectOption;

import React from 'react';
import { FieldInputCheckboxBlock, RunFieldInputCheckboxBlock } from 'shared/lib/types/views/procedures';
import LabelDisplay from './LabelDisplay';
import ReferenceTokenTooltip from './ReferenceTokenTooltip';

interface CheckboxDisplayProps {
  block: FieldInputCheckboxBlock;
  referenceId?: string;
  referenceFieldIndex?: number;
}

const CheckboxDisplay = ({ block, referenceId, referenceFieldIndex }: CheckboxDisplayProps) => {
  return (
    <div className="flex flex-row space-x-2">
      <div className="flex items-start group">
        {referenceId && <ReferenceTokenTooltip referenceId={referenceId} referenceFieldIndex={referenceFieldIndex} />}
        <input
          type="checkbox"
          className={`w-6 h-6 mt-1.5 border-2 rounded-sm ${
            referenceId ? 'text-gray-300 bg-gray-100 border-gray-300' : 'text-gray-500 bg-gray-300 border-gray-500'
          }`}
          checked={
            (block as RunFieldInputCheckboxBlock).recorded
              ? (block as RunFieldInputCheckboxBlock).recorded?.value
              : false
          }
          disabled
        />
      </div>

      <div className="mt-1.5">
        <LabelDisplay text={block.name} />
      </div>
    </div>
  );
};

export default CheckboxDisplay;

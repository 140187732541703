import pluralize from 'pluralize';
import React, { useCallback } from 'react';
import { NOTIFICATION_STATUS } from 'shared/lib/notifications';
import { AppNotificationDisplay } from 'shared/lib/types/notifications';
import { NotifyToolContext } from 'shared/lib/types/postgres/notifications';
import Tooltip from '../../elements/Tooltip';
import { toolInstanceDetailPath } from '../../lib/pathUtil';
import NotificationLink from './NotificationLink';

interface NotificationLinkProps {
  notification: AppNotificationDisplay;
  duplicateIds: Array<number>;
  handleOnClick: (ids: Array<number>) => void;
  truncateCode?: boolean;
  teamId: string;
}

const ToolNotificationLink = ({
  notification,
  duplicateIds,
  handleOnClick,
  truncateCode,
  teamId,
}: NotificationLinkProps) => {
  const context = notification.context as NotifyToolContext;
  const { tool_id, tool_name, tool_number, tool_instance_id, num_days, type } = context;

  const onClick = useCallback(() => {
    if (handleOnClick) {
      return handleOnClick(duplicateIds);
    }
  }, [duplicateIds, handleOnClick]);

  const message =
    type === 'maintenance_approaching'
      ? `due for maintenance in ${pluralize('day', num_days, true)}`
      : `${pluralize('day', num_days, true)} overdue for maintenance`;

  return (
    <NotificationLink
      notificationId={notification.id}
      ariaLabel="automation-notification"
      to={toolInstanceDetailPath(teamId, tool_id, tool_instance_id)}
      onClick={onClick}
      duplicateIds={duplicateIds}
    >
      <div
        className={`${
          notification.status === NOTIFICATION_STATUS.UNRESOLVED && 'font-semibold'
        } flex flex-row space-x-1`}
      >
        <div className={`${truncateCode ? 'max-w-[5rem]' : ''}`}>
          <Tooltip content={`${tool_name}-${tool_number}`}>
            <p className="text-blue-500 truncate">
              {tool_name}-{tool_number}
            </p>
          </Tooltip>
        </div>
        <div>{message}</div>
      </div>
    </NotificationLink>
  );
};

export default React.memo(ToolNotificationLink);

import { cloneDeep } from 'lodash';
import Pluralize from 'pluralize';
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { type SortColumn } from 'react-data-grid';
import { capitalizeFirstLetter, isEmptyValue } from 'shared/lib/text';
import { Tag } from 'shared/lib/types/couch/settings';
import { ViewTab } from 'shared/lib/types/postgres/users';
import Grid from '../../elements/Grid';
import projectGroupCell from '../../elements/renderers/ProjectGroupCell';
import tableUtil from '../../lib/tableUtil';
import GridExpandCollapseButton from './GridExpandCollapseButton';
import { HeaderDefinition, RunsTableRowModel, RunTableMetadata } from './types';

const NO_RESULTS_MSG = 'No results found';
const MAIN_VERTICAL_PADDING = 160;
const GROUP_ROW_HEIGHT_PX = 30;
const ROW_HEIGHT = (row) =>
  row?.runLink?.runTags?.length > 0 || row?.runLink?.tags?.length > 0 || row?.procedureTitle?.tags?.length > 0
    ? 70
    : 50;

const childRowGrouper = (rowsToBeDisplayed, isGroupedByProject: boolean) => {
  const displayRows: Array<RunsTableRowModel> = [];
  // expand child rows
  for (const row of rowsToBeDisplayed) {
    displayRows.push(row);
    if (row.children?.length > 0) {
      for (let i = 0; i < row.children.length; i++) {
        const child = cloneDeep(row.children[i]);

        // For now, maintain parent-child relationship even if they have different projects
        if (isGroupedByProject) {
          child.projectName = row.projectName;
        }

        if (i === row.children.length - 1) {
          child.isLastChild = true;
        } else {
          child.isChild = true;
        }
        displayRows.push(child);
      }
    }
  }
  return displayRows;
};

const isColumnSortable = (viewTab: ViewTab, header: HeaderDefinition) => {
  if (viewTab === ViewTab.Tree && header.key === 'projectName') {
    return undefined;
  }
  return header.sortable === false ? undefined : true;
};

interface HomeScreenTableProps {
  headers: Array<HeaderDefinition>;
  rows: Array<RunTableMetadata>;
  emptyListText: string;
  searchTerm: string;
  setSearchTerm: (newSearchTerm: string) => void;
  projectNamesFilter: Set<string>;
  tagNamesFilter: Set<string>;
  sortPreference: Array<SortColumn>;
  setSortPreference: (newSortPreference: Array<SortColumn>) => void;
  showParentChildRelation: boolean;
  mainVerticalPadding?: number;
  rowCountLabel?: string;
  viewTab: ViewTab;
  expandedProjectNames: ReadonlySet<string>;
  setExpandedProjectNames: Dispatch<SetStateAction<ReadonlySet<string>>>;
}

const HomeScreenTableRDG = function ({
  headers,
  rows,
  emptyListText,
  searchTerm,
  setSearchTerm,
  projectNamesFilter,
  expandedProjectNames,
  setExpandedProjectNames,
  tagNamesFilter,
  sortPreference,
  setSortPreference,
  showParentChildRelation,
  mainVerticalPadding = MAIN_VERTICAL_PADDING,
  rowCountLabel = '',
  viewTab = ViewTab.List,
}: HomeScreenTableProps) {
  const grouping =
    viewTab === ViewTab.Tree
      ? {
          groupBy: ['projectName'],
          expandedGroupIds: expandedProjectNames,
          onExpandedGroupIdsChange: setExpandedProjectNames,
        }
      : undefined;

  const sortColumns = useMemo(() => {
    const columns = cloneDeep(sortPreference);
    if (viewTab === ViewTab.Tree && !sortPreference.find((column) => column.columnKey === 'projectName')) {
      columns.unshift({ columnKey: 'projectName', direction: 'ASC' });
    }
    return columns;
  }, [sortPreference, viewTab]);

  const rowHeightGetter = useMemo(() => {
    if (viewTab === ViewTab.Tree) {
      return ({ type, row }) => {
        if (type === 'GROUP') {
          return GROUP_ROW_HEIGHT_PX;
        }
        return ROW_HEIGHT(row);
      };
    }
    return ROW_HEIGHT;
  }, [viewTab]);

  const columns = useMemo(() => {
    if (!headers) {
      return [];
    }
    return headers.map((header) => ({
      key: header.key,
      name: header.name,
      renderCell: header.renderCell,
      renderGroupCell: header.key === 'projectName' ? projectGroupCell : undefined,
      comparator: header.comparator,
      width: header.width,
      hidden: header.hidden,
      sortable: isColumnSortable(viewTab, header),
    }));
  }, [headers, viewTab]);

  const filterRowByProjectNames = useCallback(
    (rows) => {
      if (!projectNamesFilter.size) {
        return rows;
      }

      return rows.filter((row) => {
        const value = row.projectName;

        return projectNamesFilter.has(value);
      });
    },
    [projectNamesFilter]
  );

  const filterRowByTagNames = useCallback(
    (rows) => {
      if (!tagNamesFilter.size) {
        return rows;
      }

      return rows.filter((row) => {
        let match = false;

        let tags: Array<Tag> = [];
        const runRow = row['runLink'] || row['runLinkWithIcon'];
        if (runRow) {
          tags = (runRow.tags ?? []).concat(runRow.runTags ?? []);
        }
        if (row['procedureTitle']?.tags) {
          tags = row['procedureTitle'].tags;
        }

        if (tags) {
          for (const tag of tags) {
            if (tagNamesFilter.has(tag.name)) {
              match = true;
            }
          }
        }

        return match;
      });
    },
    [tagNamesFilter]
  );

  const filterRowsByParentChild = useCallback((rows) => {
    const children: Array<RunsTableRowModel> = [];
    const parents: Array<RunsTableRowModel> = [];
    const parentIds = new Set();

    rows.forEach((row) => {
      if (row.sourceRun) {
        children.push(row);
      } else {
        parents.push(row);
        parentIds.add(row.id);
      }
    });

    const parentToChildrenMap = {};
    children.forEach((isChild) => {
      const { sourceRun } = isChild;
      parentToChildrenMap[sourceRun] = parentToChildrenMap[sourceRun] ?? [];
      parentToChildrenMap[sourceRun].push(isChild);
    });

    // Get list of orphans
    const orphans: Array<RunsTableRowModel> = [];
    children.forEach((isChild) => {
      const { sourceRun } = isChild;
      if (!parentIds.has(sourceRun)) {
        orphans.push(isChild);
      }
    });

    // Add children runs if exist for each parent
    for (const parent of parents) {
      if ('sourceRun' in parent) {
        parent.children = parentToChildrenMap[parent.id] ?? [];
      }
    }

    return parents.concat(orphans);
  }, []);

  const filterRowsBySearchTerm = useCallback(
    (rows) => {
      if (isEmptyValue(searchTerm)) {
        return rows;
      }

      return rows.filter((row) => {
        // Check if every term is in this run
        const lowerCaseSearchTerm = searchTerm.toLowerCase();

        const matchTableHeader = headers.some((header) => {
          const key = header.key;
          const matchString = tableUtil.convertToType(tableUtil.getComparisonValue(row[key]));

          if (matchString.toLowerCase().includes(lowerCaseSearchTerm)) {
            return true;
          }

          // Accounts for finding matches among procedure names (above check only catches ID matches)
          else if (header.componentType === 'link' || header.componentType === 'link_with_icon') {
            if (
              row[key].name.toLowerCase().includes(lowerCaseSearchTerm) ||
              row[key].statusText?.toLowerCase().includes(lowerCaseSearchTerm)
            ) {
              return true;
            }
            if (row[key].tags !== undefined) {
              for (const tag of row[key].tags) {
                if (tag.name.toLowerCase().includes(lowerCaseSearchTerm)) {
                  return true;
                }
              }
            }
            if (row[key].runTags !== undefined) {
              for (const runTag of row[key].runTags) {
                if (runTag.name.toLowerCase().includes(lowerCaseSearchTerm)) {
                  return true;
                }
              }
            }
          }
          return false;
        });

        return matchTableHeader;
      });
    },
    [searchTerm, headers]
  );

  const filteredRows = useMemo(() => {
    let rowsToBeDisplayed = filterRowByProjectNames(rows);
    rowsToBeDisplayed = filterRowByTagNames(rowsToBeDisplayed);
    rowsToBeDisplayed = filterRowsBySearchTerm(rowsToBeDisplayed);
    // Only show Parent Child relationship on Running Procedures / Running Tabs
    if (showParentChildRelation) {
      rowsToBeDisplayed = filterRowsByParentChild(rowsToBeDisplayed);
    }
    return rowsToBeDisplayed;
  }, [
    filterRowByProjectNames,
    rows,
    filterRowByTagNames,
    filterRowsBySearchTerm,
    showParentChildRelation,
    filterRowsByParentChild,
  ]);

  const noProceduresMessage = React.useMemo(() => {
    if (filteredRows.length > 0) {
      return;
    }
    if (isEmptyValue(searchTerm)) {
      return emptyListText;
    }
    return NO_RESULTS_MSG;
  }, [filteredRows, searchTerm, emptyListText]);

  const handleCellClick = useCallback(
    (args: { column: { key: string }; row: RunsTableRowModel }, event) => {
      switch (args.column.key) {
        case 'projectName':
          if (viewTab === ViewTab.List && args.row.projectName) {
            setSearchTerm(args.row.projectName);
          }
          break;
        case 'status':
          if (args.row.status) {
            setSearchTerm(capitalizeFirstLetter(args.row.status));
          }
          break;
      }

      if (event?.target?.classList?.contains('tag')) {
        if (event.target.ariaLabel) {
          setSearchTerm(event.target.ariaLabel);
        }
      }
    },
    [setSearchTerm, viewTab]
  );

  const handleSortChange = useCallback(
    (newSortPreference: SortColumn[]) => {
      setSortPreference(newSortPreference);
    },
    [setSortPreference]
  );

  const getChildRowGrouper = useCallback(
    (rowsToBeDisplayed) => {
      return childRowGrouper(rowsToBeDisplayed, viewTab === ViewTab.Tree);
    },
    [viewTab]
  );

  return (
    <>
      {rowCountLabel && (
        <div className="flex flex-row justify-between">
          <div className="mt-2 mb-2 text-sm text-gray-400">
            {filteredRows.length} {Pluralize(rowCountLabel, filteredRows.length)}
          </div>
          {viewTab === ViewTab.Tree && (
            <GridExpandCollapseButton
              rows={filteredRows}
              expandedProjectNames={expandedProjectNames}
              setExpandedProjectNames={setExpandedProjectNames}
            />
          )}
        </div>
      )}
      <div style={{ contain: 'inline-size' }}>
        <Grid<RunsTableRowModel>
          columns={columns}
          rows={filteredRows}
          rowHeight={rowHeightGetter}
          rowGrouping={grouping}
          usedVerticalSpace={mainVerticalPadding}
          sortPostProcessor={showParentChildRelation ? getChildRowGrouper : undefined}
          onSortColumnsChange={handleSortChange}
          defaultSort={sortColumns}
          onCellClick={handleCellClick}
          emptyRowMessage={noProceduresMessage}
        />
      </div>
    </>
  );
};

export default HomeScreenTableRDG;

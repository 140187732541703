import superlogin from './superlogin';
import { API_URL } from '../config';
import { Dictionary } from '../lib/models/postgres/dictionary';

class DictionaryService {
  private teamId: string;

  constructor(teamId: string) {
    this.teamId = teamId;
  }

  /**
   * Provides list of dictionary objects for the given team
   *
   * @returns A list of dictionary objects from the API.
   */
  async listDictionaries(): Promise<Array<Dictionary>> {
    const url = `${API_URL}/teams/${this.teamId}/dictionaries`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }
}

export default DictionaryService;

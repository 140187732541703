import { useState } from 'react';
import { Bounds } from '../storage/types';
import TelemetryRow, { ProcessedSample } from './TelemetryRow';
import PlotRow from './PlotRow';
import { Rule } from 'shared/lib/types/views/procedures';

type TelemetryAndPlotRowsProps = {
  name: string;
  blockLabel?: string;
  sample: ProcessedSample;
  isAggregate: boolean;
  isChildRow: boolean;
  canBeStale: boolean;
  isRecorded: boolean;
  isRunning: boolean;
  showNavigateToPlot: boolean;
  runId: string;
  dictionaryName: string;
  parameterName: string | null;
  bounds: Bounds;
  initialBounds?: Bounds;
  setBounds?: (bounds: Bounds) => void;
  resetBounds?: () => void;
  zoomLevel?: number;
  setZoomLevel?: (zoomLevel: number) => void;
  resetZoom?: () => void;
  searchParamsString: string;
  telemetryRule?: Rule;
  shouldPausePlot: boolean;
};

const TelemetryAndPlotRows = ({
  name,
  blockLabel,
  sample,
  isAggregate,
  isChildRow,
  canBeStale,
  isRecorded,
  isRunning,
  runId,
  showNavigateToPlot,
  dictionaryName,
  parameterName,
  bounds,
  initialBounds,
  setBounds,
  resetBounds,
  zoomLevel,
  setZoomLevel,
  resetZoom,
  searchParamsString,
  telemetryRule,
  shouldPausePlot,
}: TelemetryAndPlotRowsProps) => {
  const [isPlotShown, setIsPlotShown] = useState(false);
  const togglePlotShown = () => setIsPlotShown(!isPlotShown);

  return (
    <>
      <TelemetryRow
        name={name}
        blockLabel={blockLabel}
        sample={sample}
        isAggregate={isAggregate}
        isChildRow={isChildRow}
        canBeStale={canBeStale}
        isRecorded={isRecorded}
        isRunning={isRunning}
        showNavigateToPlot={showNavigateToPlot}
        isPlotShown={isPlotShown}
        togglePlotShown={togglePlotShown}
      />
      {isPlotShown && (
        <PlotRow
          runId={runId}
          searchParamsString={searchParamsString}
          dictionaryName={dictionaryName}
          parameterName={parameterName}
          bounds={bounds}
          initialBounds={initialBounds}
          setBounds={setBounds}
          resetBounds={resetBounds}
          zoomLevel={zoomLevel}
          setZoomLevel={setZoomLevel}
          resetZoom={resetZoom}
          telemetryRule={telemetryRule}
          shouldPausePlot={shouldPausePlot}
        />
      )}
    </>
  );
};

export default TelemetryAndPlotRows;

import { Bounds, Measurements } from '../types';

export type CsvArray = Array<Array<string | number>>;

const CSV_TIMESTAMP_COLUMN_NAME = 'timestamp';

export const getCsvFilename = (measurements: Measurements): string => {
  if (measurements.length < 1) {
    return '';
  }
  let filename = measurements[0].bucket;
  measurements.forEach((measurement) => {
    filename = `${filename}_${measurement.name}`;
  });
  return filename.endsWith('csv') ? filename : `${filename}.csv`;
};

const getHeader = (measurements) => {
  const header = measurements.map((m) => m.name);
  header.push(CSV_TIMESTAMP_COLUMN_NAME);
  return header;
};

const getRow = (measurements, index) => {
  const row = measurements.map((m) => m.series[index].value);
  const timestampMs = measurements[0].series[index].timestamp;
  row.push(timestampMs);
  return row;
};

export const getCsvArray = (
  bucket: string,
  measurements: Measurements,
  bounds: Bounds
): CsvArray => {
  const filtered = measurements.filter((m) => m.bucket === bucket);
  const header = getHeader(filtered);
  const csvArray = [header];
  const nPoints = filtered[0].series.length;
  for (let i = 0; i < nPoints; i++) {
    const timestamp = filtered[0].series[i].timestamp;
    if (timestamp > bounds.min && (!bounds.max || timestamp < bounds.max)) {
      const row = getRow(measurements, i);
      csvArray.push(row);
    }
  }
  return csvArray;
};

export default getCsvArray;

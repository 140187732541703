import { ChangeEvent, FocusEvent } from 'react';
import FieldLabel from './internal/FieldLabel';
import FieldError from './internal/FieldError';
import { errorFieldStyles, fieldStyles } from './lib/fieldStyles';

type FieldTextProps = {
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  value?: string;
  label?: string;
  placeholder?: string;
  maxLength?: number;
  isDisabled?: boolean;
  errorMessage?: string;
  height?: string;
};

const FieldText = ({
  name,
  onChange,
  onBlur,
  value,
  label,
  placeholder,
  maxLength,
  isDisabled,
  errorMessage,
  height,
}: FieldTextProps) => {
  return (
    <div className="w-full">
      {label && <FieldLabel label={label} />}

      <input
        className={`w-full ${height ?? ''} ${errorMessage ? errorFieldStyles : fieldStyles}`}
        name={name}
        type="text"
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        id={name}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={isDisabled}
      />

      <FieldError errorMessage={errorMessage} />
    </div>
  );
};

export default FieldText;

import ModalBase from '../../components/ModalBase';
import PlotModalTopBar from './PlotModalTopBar';
import PlotModalBottomBar from './PlotModalBottomBar';
import RunPlot from './RunPlot';
import { Bounds } from '../types';
import { Rule } from 'shared/lib/types/views/procedures';
import { PlotType } from './Plot';

type PlotModalProps = {
  runId: string;
  dictionaryName: string;
  measurementName: string;
  bounds: Bounds;
  initialBounds?: Bounds;
  setBounds?: (bounds: Bounds) => void;
  zoomLevel?: number;
  setZoomLevel?: (zoomLevel: number) => void;
  onCloseModal: () => void;
  searchParamsString: string;
  telemetryRule?: Rule;
  shouldPausePlot: boolean;
};

const PlotModal = ({
  runId,
  dictionaryName,
  measurementName,
  bounds,
  initialBounds,
  setBounds,
  zoomLevel,
  setZoomLevel,
  onCloseModal,
  searchParamsString,
  telemetryRule,
  shouldPausePlot,
}: PlotModalProps) => {
  return (
    <ModalBase size="xl">
      <PlotModalTopBar searchParamsString={searchParamsString} onCloseModal={onCloseModal} />
      <RunPlot
        runId={runId}
        bucketName={dictionaryName}
        measurementName={measurementName}
        bounds={bounds}
        plotType={PlotType.RunModalPlot}
        initialBounds={initialBounds}
        setBounds={setBounds}
        zoomLevel={zoomLevel}
        setZoomLevel={setZoomLevel}
        telemetryRule={telemetryRule}
        shouldPausePlot={shouldPausePlot}
      />
      <PlotModalBottomBar dictionaryName={dictionaryName} measurementName={measurementName} />
    </ModalBase>
  );
};

export default PlotModal;

import superlogin from '../../api/superlogin';
import { API_URL } from '../../config';
import {
  CustomDetail,
  InsertCustomDetail,
} from 'shared/lib/types/postgres/util';

class IssueDetailsService {
  teamId: string;
  restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    const baseUrl = `${API_URL}/teams/${this.teamId}`;
    this.restUrl = `${baseUrl}/issues/details`;
  }
  async listIssueDetails(): Promise<Array<CustomDetail>> {
    const response = await superlogin.getHttp().get(this.restUrl);
    return response.data.details;
  }

  async createIssueDetail(
    issueDetail: InsertCustomDetail
  ): Promise<CustomDetail> {
    const response = await superlogin.getHttp().post(this.restUrl, issueDetail);
    return response.data;
  }

  async updateIssueDetail(issueDetail: CustomDetail): Promise<CustomDetail> {
    const { id: issueDetailId, ...toUpdate } = issueDetail;
    const url = `${this.restUrl}/${issueDetailId}`;
    const response = await superlogin.getHttp().patch(url, toUpdate);
    return response.data;
  }

  async deleteIssueDetail(issueDetailId: number): Promise<void> {
    const url = `${this.restUrl}/${issueDetailId}`;
    await superlogin.getHttp().delete(url);
  }
}

export default IssueDetailsService;

import { Dialog } from 'primereact/dialog';
import { useCallback, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Button, { BUTTON_TYPES } from './Button';

const PauseModal = ({ onClose, pauseRun }) => {
  const [isPausing, setIsPausing] = useState(false);
  const [comment, setComment] = useState('');

  const onPauseRun = useCallback(() => {
    setIsPausing(true);
    pauseRun(comment.trim());
    onClose();
  }, [comment, pauseRun, onClose]);

  const getFooter = useCallback(() => {
    return (
      <>
        <Button
          type={BUTTON_TYPES.SECONDARY}
          title="Cancel Pausing Run"
          ariaLabel="Cancel Pausing Run"
          isDisabled={isPausing}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          type={BUTTON_TYPES.PRIMARY}
          title="Confirm Pausing Run"
          ariaLabel="Confirm Pausing Run"
          isDisabled={isPausing}
          onClick={onPauseRun}
        >
          Pause
        </Button>
      </>
    );
  }, [isPausing, onClose, onPauseRun]);

  return (
    <Dialog
      visible={true}
      header="Pause Run"
      onHide={onClose}
      closable={false}
      className="w-3/4 md:w-1/2"
      footer={() => getFooter()}
    >
      <TextareaAutosize
        className="w-full text-sm border border-gray-400 focus:ring-blue-600 ring-inset rounded"
        placeholder="Enter pause run comment (Optional)"
        value={comment}
        minRows={2}
        onChange={(e) => setComment(e.target.value)}
      />
    </Dialog>
  );
};

export default PauseModal;

import { RunActions } from 'shared/lib/types/postgres/issues';
import { ACTION_DESCRIPTION } from '../lib/runUtil';
import DateTimeDisplay from './DateTimeDisplay';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface RunActionTimelineMessageProps {
  action: RunActions;
  className?: string;
}

interface activityOptions {
  icon: IconProp;
  iconColor: string;
  bgColor: string;
}

const getActivityOps = (type: string): activityOptions | undefined => {
  if (type === 'pause' || type === 'automation_pause' || type === 'issue pause') {
    return {
      icon: 'pause',
      iconColor: 'text-gray-500',
      bgColor: 'bg-yellow-200',
    };
  }
  if (type === 'resume') {
    return {
      icon: 'play',
      iconColor: 'text-gray-500',
      bgColor: 'bg-green-200',
    };
  }
  if (type === 'automation_start' || type === 'automation_resume') {
    return {
      icon: 'bolt',
      iconColor: 'text-gray-500',
      bgColor: 'bg-blue-200',
    };
  }
  if (type === 'all issues resolved') {
    return {
      icon: 'thumbs-up',
      iconColor: 'text-gray-500',
      bgColor: 'bg-blue-200',
    };
  }
};
const RunSideBarActivityItem = ({ action, className }: RunActionTimelineMessageProps) => {
  const actionOps = getActivityOps(action.type);
  if (!actionOps) {
    return <></>;
  }
  return (
    <div className={`flex items-start text-sm relative ${className}`} aria-label={`${action.type}-sidebar-activity`}>
      <div className="flex-shrink-0">
        <div className={`rounded-full ${actionOps.bgColor} w-4 h-4 inline-flex items-center justify-center`}>
          <FontAwesomeIcon icon={actionOps.icon} className={`${actionOps.iconColor} fa-fw text-xxs`} />
        </div>
        <div className="w-0.5 bg-gray-300 h-5 absolute left-1.5"></div>{' '}
      </div>
      <div className="ml-4">
        <span className="font-bold">{ACTION_DESCRIPTION[action.type]}</span>
        <span> by </span>
        <span className="font-bold">{action.user_id} </span>
        <div className="text-xs text-gray-400">
          <DateTimeDisplay timestamp={action.timestamp} />
        </div>
      </div>
    </div>
  );
};

export default RunSideBarActivityItem;

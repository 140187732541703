import React, { useContext } from 'react';

export type NavState = {
  collapsed: boolean;
};

const NavContext = React.createContext<NavState | undefined>(undefined);

export const NavProvider = NavContext.Provider;

export const useNavState = (): NavState => {
  const context = useContext(NavContext);

  if (context === undefined) {
    throw new Error('useNavState must be used within a NavProvider');
  }

  return context;
};

import { ActivityType, CommentInterface } from 'shared/lib/comment';
import { Activity, Mention } from 'shared/lib/types/postgres/util';
import useCommentHandler from '../../hooks/useCommentHandler';
import TimelineIcon from './TimelineIcon';
import FormStepComment, { MentionPosition } from '../FormStepComment';
import CommentDisplay from './CommentDisplay';
import ShowOlderButton, { ShowOlderButtonProps } from './ShowOlderButton';

interface EntityActivityDisplayProps {
  activity: Array<Activity>;
  activityRenderer: (activity: Activity) => JSX.Element;
  onAddComment?: (comment: string, mentions: Mention[]) => Promise<void>;
  onEditComment?: (comment: CommentInterface, commentId: string) => Promise<void>;
  activityAscending: boolean;
  hasOlder?: boolean;
  loadOlderProps?: ShowOlderButtonProps;
}

const EntityActivityDisplay = ({
  activity,
  activityRenderer,
  onAddComment,
  onEditComment,
  activityAscending,
  hasOlder,
  loadOlderProps,
}: EntityActivityDisplayProps) => {
  const { isSubmittingComment, hasCommentError, submitComment, handleEditClick, editCommentContext, handleCancel } =
    useCommentHandler({
      onAddComment,
      onEditComment,
      autoFocus: false,
    });

  return (
    <div
      role="region"
      aria-label="Activity List"
      className={`flex ${
        activityAscending ? 'flex-col' : 'flex-col-reverse'
      } grow overflow-y-auto justify-between items-start w-full p-1 gap-y-2`}
    >
      {hasOlder && loadOlderProps && <ShowOlderButton {...loadOlderProps} />}
      <div className="w-full relative flex flex-col gap-y-3">
        {activity.map((activity, index) => (
          <div
            key={`activity-${index}`}
            role="row"
            aria-rowindex={index}
            className="flex flex-row flex-nowrap text-xs gap-x-2"
          >
            <TimelineIcon isFirstIcon={index === 0} actionType={activity.action} />
            <div className="flex flex-row grow flex-wrap items-center gap-0.5">
              {activity.action !== 'Added Comment' && activityRenderer(activity)}
              {activity.action === 'Added Comment' && (
                <>
                  {editCommentContext.id === activity.id ? (
                    <FormStepComment
                      autoFocus={false}
                      placeholder="Edit comment"
                      initialValues={{ comment: editCommentContext.text, mentions: editCommentContext.mentions }}
                      onSubmit={submitComment}
                      mentionEnabled={true}
                      mentionPosition={activityAscending ? MentionPosition.Above : MentionPosition.Below}
                      onCancel={handleCancel}
                    />
                  ) : (
                    <CommentDisplay
                      activity={{
                        id: activity.id,
                        type: ActivityType.Comment,
                        user_id: activity.user_id,
                        comment: activity.comment ?? '',
                        mentions: activity.mention_list ?? [],
                        timestamp: activity.timestamp,
                        updated_at: activity.updated_at,
                      }}
                      onEdit={handleEditClick}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      {!!onAddComment && (
        <div className="w-full">
          {!isSubmittingComment && (
            <FormStepComment
              autoFocus={false}
              mentionPosition={activityAscending ? MentionPosition.Above : MentionPosition.Below}
              placeholder="Add comment"
              onSubmit={submitComment}
              mentionEnabled={true}
            />
          )}
          {isSubmittingComment && <div>Saving Comment...</div>}
          {hasCommentError && (
            <div className="flex items-center text-red-700">Error saving comment. Please try again.</div>
          )}
        </div>
      )}
    </div>
  );
};

export default EntityActivityDisplay;

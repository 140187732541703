import pluralize from 'pluralize';
import { NotificationChannels, NotificationTypes } from './types/notifications';
import {
  NotificationChannel,
  NotificationType,
  NotifyAtMentionDetails,
  NotifyAutomationDetails,
  NotifyReviewerDetails,
  NotifyToolSubscriptionDetails,
} from './types/postgres/notifications';

export const NOTIFICATION_STATUS = {
  UNRESOLVED: 'unresolved',
  RESOLVED: 'resolved',
};

export type NotificationStatus =
  (typeof NOTIFICATION_STATUS)[keyof typeof NOTIFICATION_STATUS];

export const getProcedureGenerationNotificationMessage = (
  code: string
): string => {
  return `${code} is now available`;
};

export type NotificationMessageParams<NotificationDetailsT> = {
  details?: NotificationDetailsT;
  code?: string;
};

export const NOTIFICATION_TEMPLATES = {
  reviewers: (details?: NotifyReviewerDetails, code?: string) => {
    const procedure_name = details?.procedure_name ?? code ?? '';
    const review_url = details?.review_url;
    return {
      message: `${procedure_name} is waiting for your review`,
      action: 'Review Now',
      url: review_url,
    };
  },
  at_mention: (details?: NotifyAtMentionDetails) => ({
    message: details?.comment_text,
    action: 'Go to comment',
    url: details?.url,
  }),
  automation: (details?: NotifyAutomationDetails) => {
    const run_name = details?.run_name;
    const run_url = details?.run_url;

    return {
      message: `Automation paused on ${run_name} and needs your review`,
      action: 'Check Now',
      url: run_url,
    };
  },
  tool: (details: NotifyToolSubscriptionDetails) => {
    if (details.type === 'maintenance_approaching') {
      return {
        subject: `${details.tool_name}-${details.tool_number} approaching maintenance due`,
        message: `${details.tool_name}-${details.tool_number} (serial number ${
          details.serial_number
        }) due for maintenance in ${pluralize('day', details.num_days, true)}`,
        url: details.url,
        action: 'Go to tool',
      };
    }
    return {
      subject: `${details.tool_name}-${details.tool_number} overdue for maintenance`,
      message: `${details.tool_name}-${details.tool_number} (serial number ${
        details.serial_number
      }) overdue for maintenance by ${pluralize(
        'day',
        details.num_days,
        true
      )}`,
      url: details.url,
      action: 'Go to tool',
    };
  },
};

export const getNotificationMessage = <NotificationDetailsT>(
  type: NotificationType,
  channel: NotificationChannel,
  params?: NotificationMessageParams<NotificationDetailsT>
) => {
  const template = NOTIFICATION_TEMPLATES[type];
  const {
    subject,
    message,
    action,
    url,
  }: { subject?: string; message: string; action: string; url: string } =
    template(params?.details, params?.code);

  switch (channel) {
    case NotificationChannels.Email:
      return { subject, message, action, url };
    case NotificationChannels.SlackChannel:
    case NotificationChannels.SlackDM:
      return { message: `${message}. <${url}|${action}>` };
    case NotificationChannels.InApp:
      if (type === NotificationTypes.Automation) {
        return {
          message_1: 'Automation paused on',
          message_2: 'and needs your review.',
        };
      } else if (type === NotificationTypes.AtMention) {
        return {
          message_1: 'A comment in',
          message_2: 'mentions you.',
        };
      }
      return { message };
    default:
      return { message };
  }
};

import React from 'react';
import LoadingBadge from './LoadingBadge';

const LoadingScreen = () => (
  <div className="flex w-full h-full">
    <div className="absolute top-2/4 left-2/4 z-10">
      <LoadingBadge />
    </div>
  </div>
);

export default LoadingScreen;

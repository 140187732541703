import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';

const DEFAULT_KEY_LENGTH = 10;
// 20 has been observed in other large distributed database frameworks.
const LARGE_KEY_LENGTH = 20;
const UUID_EQUIVALENT_KEY_LENGTH = 22;

const idUtil = {
  _generateIdWithLength: (length: number): string => {
    const nanoid = customAlphabet(alphanumeric, length);
    return nanoid();
  },

  /*
   * Returns generated unique string id, e.g., 'e4cR301383'
   * It would take 250 years at one id generated per minute to
   * have a >1% chance of generating a previously generated id
   * Link to calculator: https://zelark.github.io/nano-id-cc/
   */
  generateId: (): string => {
    const nanoid = customAlphabet(alphanumeric, DEFAULT_KEY_LENGTH);
    return nanoid();
  },

  /**
   * Generate an id with length 20, as observed in other similar dbs.
   *
   * Returns: String, alphanumeric string of length 20.
   */
  generateLargeId: (): string => {
    const nanoid = customAlphabet(alphanumeric, LARGE_KEY_LENGTH);
    return nanoid();
  },

  /**
   * Generate an id with length 22, which guarantees the same level
   * of randomness as UUID.
   *
   * Returns: String, alphanumeric string of length 22.
   */
  generateUuidEquivalentId: (): string => {
    const nanoid = customAlphabet(alphanumeric, UUID_EQUIVALENT_KEY_LENGTH);
    return nanoid();
  },
};

export default idUtil;

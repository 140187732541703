import React from 'react';
import { MenuContextAction } from './MenuContext';
import ThreeDotMenu from '../elements/ThreeDotMenu';

/**
 * Model content properties.
 *
 * @param title - The modal title to render.
 * @param children - Child content to display.
 */
interface ModalContentProps {
  title?: string;
  children?: React.ReactNode;
  threeDotMenuActions?: Array<MenuContextAction>;
}

/**
 * Renders the content portion a fullscreen, blocking modal.
 *
 * A full modal will use ModalBase, ModelContent, and ModelActionBar.
 */
const ModalContent = ({ title, threeDotMenuActions, children }: ModalContentProps) => {
  return (
    <div>
      <div className="flex justify-between bg-white px-4 pt-5 sm:px-6 sm:pt-6 rounded-lg">
        {title && <h1>{title}</h1>}
        {threeDotMenuActions && <ThreeDotMenu menuActions={threeDotMenuActions} menuLabel={`${title} Menu`} />}
      </div>
      <div className="bg-white px-4 pb-4 sm:px-6 sm:pb-4 rounded-lg">
        <div className="sm:flex sm:items-start">{children}</div>
      </div>
    </div>
  );
};

export default ModalContent;

import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  current,
} from '@reduxjs/toolkit';
import { type Services } from '../api/types';
import { type SettingsDocument } from '../components/Settings/types';
import {
  ExternalDataDocument,
  ExternalData,
} from '../lib/models/postgres/externalData';
import {
  EnabledModulesView,
  OperatorRoles,
  Units,
} from '../lib/views/settings';
import { DOC_ID_EXTERNAL_DATA } from '../api/settings';
import {
  CouchLikeOperations,
  EMPTY_COUCH_LIKE_OPERATIONS_DOC,
} from 'shared/lib/types/operations';
import {
  Config,
  Projects,
  RunTag,
  RunTags,
  Tags,
  Users,
} from 'shared/lib/types/couch/settings';
import { Integrations } from '../lib/models/postgres/integrations';
import {
  Action,
  DeleteEventMessageData,
  EventMessage,
} from 'shared/lib/types/realtimeUpdatesTypes';
import { OperationsPagination } from './SettingsContext';
import { Operator } from 'shared/lib/types/settings';
import OperatorRolesService from '../api/operatorRoles';
import UnitsService from '../settings/api/units';
import { unionBy } from 'lodash';
import { DefaultView, UserPreferences } from 'shared/lib/types/postgres/users';
import { Unit } from 'shared/lib/types/api/settings/units/models';

export type ReduxState = {
  settings: CouchDBState;
};

export type ReduceAction<T> = {
  payload: {
    teamId: string;
    doc: T;
  };
  error?: Record<string, unknown>;
};

export type ReduceActionPostgres<T> = ReduceAction<T> & {
  payload: {
    dbAction: string;
    doc_property?: string;
  };
};

type CouchDBState = {
  [teamId: string]: {
    docs: {
      [_id: string]: SettingsDocument;
    };
  };
};

type ThunkInput = {
  services: Services;
  config?: {
    operations: {
      pagination: OperationsPagination | null;
      searchTerm: string | null;
    };
  };
};
type ThunkOutput<T extends SettingsDocument> = {
  teamId: string;
  doc: T;
};
type ThunkOutputPostgres<T extends SettingsDocument> = ThunkOutput<T> & {
  dbAction: string;
  doc_property?: string;
};
type RealtimeNotificationsThunkInput = {
  services: Services;
  updatesDoc: EventMessage;
  teamId: string;
};

const getInitialTeamState = () => {
  return { docs: {} };
};

/**
 * TODO (Deep) - Find a way to combine the fetch methods below into one fetchDoc.
 * At the moment, settings.getConfig has some custom logic which is not
 * carried over to settings.getUsers.
 */

/**
 * @param {Object} - with a services property.
 * services: Object, the services object from `DatabaseContext`, for getting the
 *           current SettingsService.
 *           SettingsService is required to have the getTeamId method.
 */
export const fetchConfig = createAsyncThunk(
  'settings/fetchConfig',
  async ({ services }: ThunkInput): Promise<ThunkOutput<Config>> => {
    if (!services || !services.settings) {
      throw new Error('No settings service found');
    }

    const teamId = services.settings.getTeamId();

    if (!teamId) {
      throw new Error('No team id found');
    }

    const doc = (await services.settings.getConfig()) as Config;

    return {
      teamId,
      doc,
    };
  }
);

/**
 * @param {RealtimeNotificationsThunkInput} params - with a services property.
 * services: Object, the services object from `DatabaseContext`, for getting the
 *           current SettingsService.
 *           SettingsService is required to have the getTeamId method.
 */
export const updateOperatorRoles = createAsyncThunk(
  'settings/updateOperatorRoles',
  async ({
    updatesDoc,
    teamId,
    services,
  }: RealtimeNotificationsThunkInput): Promise<
    ThunkOutputPostgres<OperatorRoles>
  > => {
    if (!updatesDoc || !teamId) {
      throw new Error('Operator roles update information missing');
    }
    let doc: Array<Operator>;

    if (updatesDoc.action === 'BACK_ONLINE') {
      // If it's a back online action, we don't have row numbers so fetch all the data
      const service = new OperatorRolesService(teamId);
      doc = await service.getOperatorRoles();
    } else {
      doc = updatesDoc.data as Array<Operator>;
    }

    const dbAction: Action = updatesDoc.action;
    const resultingDoc = { operators: doc, _id: 'operator_roles' as const };

    return {
      teamId,
      doc: resultingDoc,
      dbAction,
      doc_property: 'operators',
    };
  }
);

export const updateUnits = createAsyncThunk(
  'settings/updateUnits',
  async ({
    updatesDoc,
    teamId,
  }: RealtimeNotificationsThunkInput): Promise<ThunkOutputPostgres<Units>> => {
    if (!updatesDoc || !teamId) {
      throw new Error('Units update information missing');
    }
    let doc: Array<Unit>;

    if (updatesDoc.action === 'BACK_ONLINE') {
      const service = new UnitsService(teamId);
      doc = await service.getUnits();
    } else if (updatesDoc.action === 'DELETE') {
      const ids = (updatesDoc.data as DeleteEventMessageData).ids?.map(
        parseInt
      );
      doc = { ids } as unknown as Array<Unit>;
    } else {
      doc = updatesDoc.data as Array<Unit>;
    }

    const dbAction: Action = updatesDoc.action;
    const resultingDoc = { units: doc, _id: 'units' as const };

    return {
      teamId,
      doc: resultingDoc,
      dbAction,
      doc_property: 'units',
    };
  }
);

/**
 * @param {Object} - with a services property.
 * services: Object, the services object from `DatabaseContext`, for getting the
 *           current SettingsService.
 *           SettingsService is required to have the getTeamId method.
 */
export const fetchOperations = createAsyncThunk(
  'settings/fetchOperations',
  async ({
    services,
    config,
  }: ThunkInput): Promise<ThunkOutput<CouchLikeOperations>> => {
    if (!services || !services.settings) {
      throw new Error('No settings service found');
    }

    const teamId = services.settings.getTeamId();

    if (!teamId) {
      throw new Error('No team id found');
    }

    if (!config?.operations.pagination) {
      return {
        teamId,
        doc: EMPTY_COUCH_LIKE_OPERATIONS_DOC,
      };
    }

    const doc = await services.settings.getPaginatedOperationsDoc(
      config.operations.pagination ?? undefined,
      config.operations.searchTerm ?? undefined
    );

    return {
      teamId,
      doc,
    };
  }
);

/**
 * @param {Object} - with a services property.
 * services: Object, the services object from `DatabaseContext`, for getting the
 *           current SettingsService.
 *           SettingsService is required to have the getTeamId method.
 */
export const fetchTags = createAsyncThunk(
  'settings/fetchTags',
  async ({ services }: ThunkInput): Promise<ThunkOutput<Tags>> => {
    if (!services || !services.settings) {
      throw new Error('No settings service found');
    }

    const teamId = services.settings.getTeamId();

    if (!teamId) {
      throw new Error('No team id found');
    }

    const doc = (await services.settings.getTagsDoc()) as unknown as Tags;

    return {
      teamId,
      doc,
    };
  }
);

/**
 * @param {Object} - with a services property.
 * services: Object, the services object from `DatabaseContext`, for getting the
 *           current SettingsService.
 *           SettingsService is required to have the getTeamId method.
 */
export const fetchRunTags = createAsyncThunk(
  'settings/fetchRunTags',
  async ({ services }: ThunkInput): Promise<ThunkOutput<RunTags>> => {
    if (!services || !services.settings) {
      throw new Error('No settings service found');
    }

    const teamId = services.settings.getTeamId();

    if (!teamId) {
      throw new Error('No team id found');
    }

    const listOfTags = await services.settings.getRunTagsDoc();
    const doc = {
      _id: 'run_tags',
      run_tags: listOfTags.run_tags?.reduce((tagsObject, tag) => {
        tagsObject[tag.id] = { key: tag.id, name: tag.name };
        return tagsObject;
      }, {}),
    } as RunTags;

    return {
      teamId,
      doc,
    };
  }
);

/**
 * @param {RealtimeNotificationsThunkInput} params - with a services property.
 * services: Object, the services object from `DatabaseContext`, for getting the
 *           current SettingsService.
 *           SettingsService is required to have the getTeamId method.
 */
export const updateUsers = createAsyncThunk(
  'settings/updateUsers',
  async ({
    updatesDoc,
    teamId,
    services,
  }: RealtimeNotificationsThunkInput): Promise<ThunkOutputPostgres<Users>> => {
    if (!updatesDoc || !teamId || !services) {
      throw new Error('User update information missing');
    }
    let doc: Users;
    // If this is an INSERT or UPDATE we can just update the data
    if (updatesDoc.action !== 'DELETE' && updatesDoc.action !== 'BACK_ONLINE') {
      /*
       * users updatesDoc does not fully conform to the EventMessage interface since right now half
       * of users come from postgres and half from couchDB, explicitly typing here until that is fixed
       */
      doc = updatesDoc.data as Users;
    } else {
      // If it's a delete or a back online action, we don't have row numbers so fetch all the data
      if (!services || !services.settings) {
        throw new Error('No settings service found');
      }
      doc = await services.settings.getUsers(); // Move this to services.users
    }
    doc._id = 'users';

    return {
      teamId,
      doc,
      dbAction: updatesDoc.action,
    };
  }
);

/**
 * @param {Object} - with a services property.
 * services: Object, the services object from `DatabaseContext`, for getting the
 *           current SettingsService.
 *           SettingsService is required to have the getTeamId method.
 */

export const fetchDefaultView = createAsyncThunk(
  'settings/fetchDefaultView',
  async ({ services }: ThunkInput): Promise<ThunkOutput<DefaultView>> => {
    if (!services || !services.settings) {
      throw new Error('No settings service found');
    }

    const teamId = services.settings.getTeamId();
    if (!teamId) {
      throw new Error('No team id found');
    }

    const doc = (await services.settings.getDefaultView()) as DefaultView;
    doc._id = 'default_view';
    return {
      teamId,
      doc,
    };
  }
);

export const fetchUserPreferences = createAsyncThunk(
  'settings/fetchUserPreferences',
  async ({ services }: ThunkInput): Promise<ThunkOutput<UserPreferences>> => {
    if (!services || !services.settings) {
      throw new Error('No settings service found');
    }

    const teamId = services.settings.getTeamId();
    if (!teamId) {
      throw new Error('No team id found');
    }

    const doc =
      (await services.settings.getUserPreferences()) as UserPreferences;
    doc._id = 'user_preferences';
    return {
      teamId,
      doc,
    };
  }
);

/**
 * @param {Object} - with a services property.
 * services: Object, the services object from `DatabaseContext`, for getting the
 *           current SettingsService.
 *           SettingsService is required to have the getTeamId method.
 */
export const fetchProjects = createAsyncThunk(
  'settings/fetchProjects',
  async ({ services }: ThunkInput): Promise<ThunkOutput<Projects>> => {
    if (!services || !services.settings) {
      throw new Error('No settings service found');
    }

    const teamId = services.settings.getTeamId();

    if (!teamId) {
      throw new Error('No team id found');
    }

    const doc = (await services.settings.getProjects()) as unknown as Projects;

    return {
      teamId,
      doc,
    };
  }
);

/**
 * @param {Object} - with a services property.
 * services: Object, the services object from `DatabaseContext`, for getting the
 *           current SettingsService.
 *           SettingsService is required to have the getTeamId method.
 */
export const fetchExternalData = createAsyncThunk(
  'settings/fetchExternalData',
  async ({
    services,
  }: ThunkInput): Promise<ThunkOutput<ExternalDataDocument>> => {
    if (!services || !services.settings) {
      throw new Error('No settings service found');
    }

    const teamId = services.settings.getTeamId();

    if (!teamId) {
      throw new Error('No team id found');
    }

    const doc =
      (await services.settings.getExternalData()) as unknown as ExternalData;
    const types: ExternalDataDocument = {
      _id: DOC_ID_EXTERNAL_DATA,
      types: doc,
    };

    return {
      teamId,
      doc: types,
    };
  }
);

/**
 * @param {Object} - with a services property.
 * services: Object, the services object from `DatabaseContext`, for getting the
 *           current SettingsService.
 *           SettingsService is required to have the getTeamId method.
 */
export const fetchEnabledModules = createAsyncThunk(
  'settings/fetchEnabledModules',
  async ({ services }: ThunkInput): Promise<ThunkOutput<SettingsDocument>> => {
    if (!services || !services.settings) {
      throw new Error('No settings service found');
    }

    const teamId = services.settings.getTeamId();

    if (!teamId) {
      throw new Error('No team id found');
    }

    const enabledModules = await services.settings.getEnabledModules();

    return {
      teamId,
      doc: {
        _id: 'enabled_modules',
        keys: enabledModules,
      },
    };
  }
);

/**
 * @param {Object} - with a services property.
 * services: Object, the services object from `DatabaseContext`, for getting the
 *           current SettingsService.
 *           SettingsService is required to have the getTeamId method.
 */
export const fetchIntegrations = createAsyncThunk(
  'settings/fetchIntegrations',
  async ({ services }: ThunkInput): Promise<ThunkOutput<Integrations>> => {
    if (!services || !services.settings) {
      throw new Error('No settings service found');
    }

    const teamId = services.settings.getTeamId();

    if (!teamId) {
      throw new Error('No team id found');
    }

    const integrations = await services.settings.getIntegrations();

    return {
      teamId,
      doc: integrations,
    };
  }
);

const reduceDoc = (
  state: CouchDBState,
  action: ReduceAction<SettingsDocument>
): void => {
  const { doc, teamId } = action.payload;

  if (!teamId) {
    throw new Error('No team id found.');
  }

  if (!state[teamId]) {
    state[teamId] = getInitialTeamState();
  }

  // Redux Toolkit allows "mutating" state directly thanks to Immer.
  state[teamId].docs[doc._id] = doc;
};

const reduceUsersDoc = (
  state: CouchDBState,
  action: ReduceActionPostgres<SettingsDocument>
): void => {
  const { doc, teamId, dbAction } = action.payload;

  if (!teamId) {
    throw new Error('No team id found.');
  }

  if (!state[teamId]) {
    state[teamId] = getInitialTeamState();
  }

  if (dbAction === 'DELETE' || dbAction === 'BACK_ONLINE') {
    // Redux Toolkit allows "mutating" state directly thanks to Immer.
    state[teamId].docs[doc._id] = doc;
    return;
  }
  // Redux Toolkit allows "mutating" state directly thanks to Immer.
  for (const userGroup of ['users', 'invites']) {
    // PLU-341 Update to active and invited
    for (const user in doc[userGroup]) {
      for (const field in doc[userGroup][user]) {
        if (
          typeof doc[userGroup][user][field] === 'object' &&
          !Array.isArray(doc[userGroup][user][field])
        ) {
          for (const subfield in doc[userGroup][user][field]) {
            if (!state[teamId].docs[doc._id][userGroup][user]) {
              state[teamId].docs[doc._id][userGroup][user] = {
                [field]: { [subfield]: null },
              };
            } else if (!state[teamId].docs[doc._id][userGroup][user][field]) {
              state[teamId].docs[doc._id][userGroup][user][field] = {
                [subfield]: null,
              };
            }
            state[teamId].docs[doc._id][userGroup][user][field][subfield] =
              doc[userGroup][user][field][subfield];
          }
        }
        if (!state[teamId].docs[doc._id][userGroup][user]) {
          state[teamId].docs[doc._id][userGroup][user] = { [field]: null };
        }
        state[teamId].docs[doc._id][userGroup][user][field] =
          doc[userGroup][user][field];
      }
    }
  }
};

const reduceDocPostgres = (
  state: CouchDBState,
  action: ReduceActionPostgres<SettingsDocument>
): void => {
  const { doc, teamId, dbAction, doc_property } = action.payload;

  if (!teamId || !doc_property) {
    throw new Error('No team id found.');
  }

  if (!state[teamId]) {
    state[teamId] = getInitialTeamState();
  }

  if (dbAction === 'DELETE') {
    // Redux Toolkit allows "mutating" state directly thanks to Immer.
    state[teamId].docs[doc._id][doc_property] = current(
      state[teamId].docs[doc._id][doc_property]
    ).filter((item) => !doc[doc_property].ids.includes(item.id));
  } else if (dbAction === 'BACK_ONLINE') {
    state[teamId].docs[doc._id] = doc;
  } else {
    state[teamId].docs[doc._id][doc_property] = unionBy(
      doc[doc_property],
      current(state[teamId].docs[doc._id][doc_property]),
      'id'
    );
  }
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {},
  reducers: {
    saveRunTags: {
      reducer: (
        state: CouchDBState,
        action: PayloadAction<Array<RunTag>, string, { teamId: string }>
      ): void => {
        const { teamId } = action.meta;
        if (!state[teamId].docs['run_tags']) {
          state[teamId].docs['run_tags'] = {
            _id: 'run_tags',
            run_tags: {} as { [key: string]: RunTag },
          };
        }

        for (const runTag of action.payload) {
          const key = runTag.key;
          const name = runTag.name;
          state[teamId].docs['run_tags']['run_tags'][key] = {
            key,
            name,
          };
        }
      },
      prepare: (runTags: Array<RunTag>, teamId: string) => {
        return {
          payload: runTags,
          meta: { teamId },
        };
      },
    },
  },
  extraReducers: (builder) => {
    // Must use Builder notation for Typescript to infer types correctly
    builder.addCase(fetchConfig.fulfilled, reduceDoc);
    builder.addCase(updateOperatorRoles.fulfilled, reduceDocPostgres);
    builder.addCase(updateUnits.fulfilled, reduceDocPostgres);
    builder.addCase(fetchOperations.fulfilled, reduceDoc);
    builder.addCase(updateUsers.fulfilled, reduceUsersDoc);
    builder.addCase(fetchUserPreferences.fulfilled, reduceDoc);
    builder.addCase(fetchDefaultView.fulfilled, reduceDoc);
    builder.addCase(fetchProjects.fulfilled, reduceDoc);
    builder.addCase(fetchExternalData.fulfilled, reduceDoc);
    builder.addCase(fetchTags.fulfilled, reduceDoc);
    builder.addCase(fetchRunTags.fulfilled, reduceDoc);
    builder.addCase(fetchEnabledModules.fulfilled, reduceDoc);
    builder.addCase(fetchIntegrations.fulfilled, reduceDoc);
  },
});

export const { saveRunTags } = settingsSlice.actions;

/**
 * @param {Object} state - Redux state
 * @param {String} teamId
 * @param {String} docName - Document name eg 'config', 'users'.
 * @returns {Object} Doc.
 */
type ValidDocname<T> = T extends Config
  ? 'config'
  : T extends Users
  ? 'users'
  : T extends UserPreferences
  ? 'user_preferences'
  : T extends DefaultView
  ? 'default_view'
  : T extends Projects
  ? 'projects'
  : T extends CouchLikeOperations
  ? 'operations'
  : T extends ExternalDataDocument
  ? 'external_data'
  : T extends Tags
  ? 'tags'
  : T extends RunTags
  ? 'run_tags'
  : T extends EnabledModulesView
  ? 'enabled_modules'
  : T extends Integrations
  ? 'integrations'
  : T extends OperatorRoles
  ? 'operator_roles'
  : T extends Units
  ? 'units'
  : never;

export const selectDocByTeamId = <T>(
  state: { settings: CouchDBState },
  teamId: string,
  docName: ValidDocname<T>
): null | T => {
  if (!state.settings[teamId]) {
    return null;
  }

  // Cast return type to match requested doc type
  return state.settings[teamId].docs[docName] as T;
};

export default settingsSlice.reducer;

import _ from 'lodash';
import {
  ClientTool,
  ClientToolInstance,
  Tool,
  ToolInstance,
} from 'shared/lib/types/api/manufacturing/tools/models';

const fromClient = {
  toTool: (clientTool: ClientTool): Tool => ({
    ...clientTool,
    created_at: new Date(clientTool.created_at),
  }),

  toToolInstance: (clientToolInstance: ClientToolInstance): ToolInstance => {
    const toolInstance: ToolInstance = {
      ..._.omit(clientToolInstance, 'last_maintained_at'),
      created_at: new Date(clientToolInstance.created_at),
      updated_at: new Date(clientToolInstance.updated_at),
      usage: clientToolInstance.usage,
    };
    if (clientToolInstance.last_maintained_at) {
      toolInstance.last_maintained_at = new Date(
        clientToolInstance.last_maintained_at
      );
    }
    if (clientToolInstance.location_id) {
      toolInstance.location_id = clientToolInstance.location_id;
    }
    return toolInstance;
  },
};

export default fromClient;

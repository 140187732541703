import React, { useMemo } from 'react';
import { SignoffDiffElement } from 'shared/lib/types/views/procedures';
import DiffContainer from '../Diff/DiffContainer';
import diffUtil from '../../lib/diffUtil';
import { DiffSignoffButton } from '../types';
import signoffUtil from 'shared/lib/signoffUtil';
import sharedDiffUtil from 'shared/lib/diffUtil';
import ReviewSignoffButtons from './ReviewSignoffButtons';
import { isEmpty } from 'lodash';

interface ReviewProcedureStepSignoffButtonProps {
  signoff: SignoffDiffElement;
  isStepCollapsed: boolean;
}

/**
 * This button allows user to sign off a step.
 */
const ReviewProcedureStepSignoffButton = React.memo(
  ({ signoff, isStepCollapsed }: ReviewProcedureStepSignoffButtonProps) => {
    const buttons: Array<DiffSignoffButton> = useMemo(() => {
      if (!signoffUtil.hasOperators(signoff)) {
        return [
          {
            id: signoff.id,
            label: '',
            isDisabled: true,
            onClick: () => null,
          },
        ];
      }
      return signoff.operators.map((operator) => {
        const oldOperator = sharedDiffUtil.getDiffValue<string>({ operator }, 'operator', 'old');
        const newOperator = sharedDiffUtil.getDiffValue<string>({ operator }, 'operator', 'new');

        const isAdded = isEmpty(oldOperator) && !isEmpty(newOperator);
        const isRemoved = isEmpty(newOperator) && !isEmpty(oldOperator);
        const operatorLabel = isRemoved ? oldOperator ?? '' : newOperator ?? '';
        return {
          id: `${signoff.id}-${operatorLabel}`,
          label: operatorLabel,
          isDisabled: true,
          onClick: () => null,
          isAdded,
          isRemoved,
        };
      });
    }, [signoff]);

    return (
      <>
        <DiffContainer
          label="Signoff"
          diffChangeState={diffUtil.getDiffChangeStateForAddedRemovedOnly(signoff)}
          isTextSticky={false}
          width="fit"
        >
          <ReviewSignoffButtons
            buttons={buttons}
            leadingIcon="check-circle"
            showVerticalSpaceInPrint={!isStepCollapsed}
          />
        </DiffContainer>
      </>
    );
  }
);

export default ReviewProcedureStepSignoffButton;

import { useEffect, useMemo, useState } from 'react';
import { Procedure, Run } from 'shared/lib/types/views/procedures';
import runUtil from '../lib/runUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { Parameter } from 'shared/lib/telemetry';
import apm from '../lib/apm';

interface useTelemetryParametersProps {
  procedure: Procedure | Run | null;
}

interface useTelemetryParametersReturns {
  fetchedTelemetryParameters: Array<Parameter>;
}

const useTelemetryParameters = ({
  procedure,
}: useTelemetryParametersProps): useTelemetryParametersReturns => {
  const { services } = useDatabaseServices();
  const [fetchedTelemetryParameters, setFetchedTelemetryParameters] = useState<
    Array<Parameter>
  >([]);
  const allTelemetryInProcedure = useMemo(
    () => runUtil.allTelemetryInRun(procedure),
    [procedure]
  );

  useEffect(() => {
    (async () => {
      if (allTelemetryInProcedure.length > 0) {
        const allParameters = await services.telemetry.listAllParameters();
        // this is so we can store them in the context and don't have to fetch them again for each telemetry block
        setFetchedTelemetryParameters(allParameters);
      }
    })().catch((err) => apm.captureError(err));
  }, [allTelemetryInProcedure.length, services.telemetry]);

  return { fetchedTelemetryParameters };
};

export default useTelemetryParameters;

import React, { useMemo, useCallback } from 'react';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldSetSignoffSelect from './FieldSetSignoffSelect';
import cloneDeep from 'lodash.clonedeep';
import { useProcedureContext } from '../contexts/ProcedureContext';
import { isEmpty } from 'lodash';

/**
 * Renders a step signoff button with adjustable operators.
 *
 * @param {Object} props - The component props, which includes:
 *  - {String} name - Property path of the field to be updated.
 *  - {String} signoffIndex - Index of current signoff.
 *  - {Object} signoff - Signoff object.
 *  - {Function} onRemoveSignoff - Callback to remove signoff from form.
 *  - {Array} operatorOptions - Operators available to add to signoff.
 *  - {Function} onSignoffUpdated - Callback to step function syncing form to procedure.
 *  - {HTMLElement} placeholder - HTML element to use as placeholder.
 *  - {number} numSignoffs - Number of signoffs on the step.
 */
const FieldSetStepSignoff = ({
  name,
  signoffIndex,
  signoff,
  onRemoveSignoff,
  operatorOptions,
  onSignoffUpdated,
  placeholder,
  numSignoffs,
  isDisabled,
}) => {
  const { stepOptions } = useProcedureContext();

  const canRemoveSignoffs = useMemo(() => {
    return isEmpty(stepOptions) || stepOptions.canRemoveSignoffs;
  }, [stepOptions]);

  const getOnChange = useCallback(() => {
    return (options) => {
      const values = options.map((o) => o.label);
      if (values.length === 0) {
        if (canRemoveSignoffs || (!canRemoveSignoffs && numSignoffs > 1)) {
          onRemoveSignoff(signoffIndex);
          return;
        }
      }
      const updated = cloneDeep(signoff);
      updated.operators = values;
      onSignoffUpdated(updated, signoffIndex);
    };
  }, [canRemoveSignoffs, numSignoffs, signoff, onSignoffUpdated, signoffIndex, onRemoveSignoff]);

  const onRemove = () => {
    onRemoveSignoff(signoffIndex);
  };

  const signoffOperatorValueLabels = useMemo(
    () =>
      signoff.operators.map((operator) => ({
        value: operator,
        label: operator,
      })),
    [signoff.operators]
  );

  return (
    <Field name={name}>
      {({ field }) => (
        <div className="relative group max-w-xl">
          <FieldSetSignoffSelect
            options={operatorOptions}
            name={field.name}
            placeholder={placeholder}
            opacity={0.8}
            value={signoffOperatorValueLabels}
            onChange={getOnChange()}
            onBlur={field.onBlur}
            ariaLabel="Signoff Select"
            isDisabled={isDisabled}
          />
          {!isDisabled && canRemoveSignoffs && (
            <FontAwesomeIcon
              onClick={onRemove}
              icon="times-circle"
              aria-label="Remove signoffs"
              className="absolute -top-4 right-0 text-gray-400 hover:text-gray-500 opacity-0 group-hover:opacity-100"
            />
          )}
        </div>
      )}
    </Field>
  );
};

export default FieldSetStepSignoff;

import { DraftStep, Run } from 'shared/lib/types/views/procedures';

const BUILDS_CONTENT_TYPES = [
  'part_kit',
  'part_usage',
  'part_build',
  'tool_check_out',
  'tool_usage',
  'tool_check_in',
];

const hasBuildsContent = (step: DraftStep): boolean => {
  for (const block of step.content) {
    if (BUILDS_CONTENT_TYPES.includes(block.type)) {
      return true;
    }
  }
  return false;
};

export const hasCheckoutBlock = (run: Run): boolean => {
  for (const section of run.sections) {
    for (const step of section.steps) {
      for (const block of step.content) {
        if (block.type === 'part_kit' || block.type === 'tool_check_in') {
          return true;
        }
      }
    }
  }
  return false;
};

export default hasBuildsContent;

import React, { ReactNode } from 'react';

interface MarkdownLinkWrapperProps {
  children: ReactNode;
  href?: string;
}

const MarkdownLinkWrapper = React.memo(({ children, href = '' }: MarkdownLinkWrapperProps) => {
  return (
    <a className="text-blue-600 underline" href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
});

export default MarkdownLinkWrapper;

import React, { useMemo } from 'react';
import { Tooltip as PrimeReactTooltip } from 'primereact/tooltip';
import { nanoid } from 'nanoid';

export interface TooltipProps {
  /** Wrapped element for the tooltip trigger area */
  children: JSX.Element;
  /** Tooltip text.  Can be a rich JSX element */
  content: React.ReactNode;
  /** If set, includes the tooltip wrapper */
  visible?: boolean;
  limitWidth?: boolean;
  position?: 'top' | 'right' | 'bottom' | 'left' | 'mouse';
  grow?: boolean;
}

/**
 * A component for providing consistent display of tooltips.
 * Tooltips should be used as a conditionally rendered component in cases where text is too long to display by default
 *
 * @returns a flex element displaying the supplied tooltip
 */
const Tooltip = ({
  children,
  content,
  visible = true,
  limitWidth = true,
  position = 'top',
  grow = false,
}: TooltipProps) => {
  const popupClass = `${limitWidth ? 'max-w-md' : 'w-fit'} whitespace-normal break-normal text-xs bg-transparent`;
  const targetId = useMemo(() => {
    return `tooltip-${nanoid()}`;
  }, []);

  return visible ? (
    <>
      <PrimeReactTooltip target={`#${targetId}`} position={position} pt={{ text: { style: { padding: '0.5rem' } } }}>
        <div className={popupClass}>{content}</div>
      </PrimeReactTooltip>
      <div id={targetId} className={`inline ${grow ? 'grow' : ''}`}>
        {children}
      </div>
    </>
  ) : (
    children
  );
};

export default Tooltip;

import React, { useCallback, useEffect } from 'react';
import { default as ReactDatePicker } from 'react-datepicker';
import { DateTime, Duration } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './EventDateTime.css';

const EventDateTime = ({ field, form, utcLabel = true }) => {
  const handleChange = useCallback(
    (jsDate) => {
      if (jsDate === null) {
        form.setFieldValue(field.name, null);
      } else {
        const newDate = DateTime.fromJSDate(jsDate, { locale: 'utc' }).setZone('utc', { keepLocalTime: true });

        form.setFieldTouched(field.name, true);
        form.setFieldValue(field.name, newDate);
      }
    },
    [field.name, form]
  );
  const { name, value } = field;
  const { start, end, predecessor_id, duration } = form.values;

  const minDate = name === 'end' && start ? start.setZone('local', { keepLocalTime: true }).toJSDate() : undefined;

  let utcJSDate;
  if (value) {
    // Force UTC to local so that ReactDatePicker shows it correctly
    utcJSDate = value.setZone('local', { keepLocalTime: true }).toJSDate();
  } else {
    utcJSDate = null;
  }

  useEffect(() => {
    if (name === 'end' && end && start && start > end) {
      form.setFieldValue(name, start.plus(Duration.fromObject({ minutes: 15 })));
    }
    if (predecessor_id && name === 'end' && duration?.as('seconds') < 0) {
      form.setFieldValue(name, start.plus(Duration.fromObject({ minutes: 15 })));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, predecessor_id]);

  return (
    <div className="flex flex-row items-center">
      <label className="hidden" id={`${field.name}-label-id`}>
        Event {field.name}
      </label>
      <div className="flex items-center space-x-1 mr-2">
        <div className="flex items-center">
          <ReactDatePicker
            selected={utcJSDate}
            onChange={handleChange}
            showTimeSelect
            timeIntervals={15}
            timeFormat="HH:mm"
            dateFormat="MMMM d, yyyy HH:mm:ss"
            className="rounded border-gray-300 pl-2 pr-5 disabled:bg-gray-100 h-[38px] w-60"
            disabled={form.isSubmitting}
            minDate={minDate}
            showMonthDropdown
            showYearDropdown
            ariaLabelledBy={`${field.name}-label-id`}
          />
          {field.value && (
            <FontAwesomeIcon
              icon={faTimes}
              className="cursor-pointer -ml-4 text-stone-300 hover:text-neutral-400 z-10"
              onClick={() => handleChange(null)}
              data-testid="reset-button"
            />
          )}
        </div>
        {utcLabel && <span className="ml-1">UTC</span>}
      </div>
    </div>
  );
};

export default EventDateTime;

import React, { useCallback, useMemo, useState } from 'react';
import signoffUtil from 'shared/lib/signoffUtil';
import { Signoff } from 'shared/lib/types/views/procedures';
import SignoffButtons from './SignoffButtons';
import { SignoffButton } from './types';
import PinSignoffModal from './PinSignoffModal';

interface onPinSignoffProps {
  signoffId: string;
  operator: string;
  pinUser: string;
  pin: string;
}

interface ProcedureStepSignoffButtonProps {
  isStepEnabled: boolean;
  isSignedOff: boolean;
  onSignOff: (id: string, operator: string) => void;
  onPinSignoff: ({ signoffId, operator, pinUser, pin }: onPinSignoffProps) => Promise<void>;
  isPinSignoffEnabled: boolean;
  onRevokeSignoff: (signoffId: string) => void;
  isRevokeEnabled: boolean;
  signoff: Signoff;
  userStepRoles: Array<string>;
  isStepCollapsed: boolean;
  completedByAutomation?: boolean;
  isOptionMenuAlwaysVisible?: boolean;
  hasUserSignoffOnOtherRole?: (operator: string) => boolean;
}

/**
 * This button allows user to sign off a step.
 */
const ProcedureStepSignoffButton = React.memo(
  ({
    isStepEnabled,
    isSignedOff,
    onSignOff,
    onPinSignoff,
    isPinSignoffEnabled,
    onRevokeSignoff,
    isRevokeEnabled,
    signoff,
    userStepRoles,
    isStepCollapsed,
    hasUserSignoffOnOtherRole,
    completedByAutomation = false,
    isOptionMenuAlwaysVisible = false,
  }: ProcedureStepSignoffButtonProps) => {
    const [showPinSignoffModal, setShowPinSignoffModal] = useState(false);

    const isEnabled = useMemo(() => {
      return (
        isStepEnabled && (signoffUtil.requiresAnyRoles(signoff, userStepRoles) || !signoffUtil.hasOperators(signoff))
      );
    }, [isStepEnabled, signoff, userStepRoles]);

    const userStepRoleSet = useMemo(() => new Set(userStepRoles), [userStepRoles]);

    const isRevokeEnabledWithPermission = useMemo(() => {
      return (
        isRevokeEnabled &&
        (!signoffUtil.hasOperators(signoff) || signoff.operators.some((operator) => userStepRoleSet.has(operator)))
      );
    }, [isRevokeEnabled, signoff, userStepRoleSet]);

    const onRevoke = useCallback(() => {
      if (isSignedOff && signoff.id) {
        onRevokeSignoff(signoff.id);
      }
    }, [isSignedOff, onRevokeSignoff, signoff.id]);

    // Use useCallback to prevent onBlur from cancelling the click.
    const getOnButtonSignoff = useCallback(
      (signoffId: string, operator: string) => () => {
        if (!isSignedOff && signoffId) {
          onSignOff(signoffId, operator);
        }
      },
      [isSignedOff, onSignOff]
    );

    const buttons: Array<SignoffButton> = useMemo(() => {
      if (!signoffUtil.hasOperators(signoff)) {
        return [
          {
            id: signoff.id,
            label: '',
            isDisabled: !isEnabled,
            onClick: getOnButtonSignoff(signoff.id, ''),
          },
        ];
      }
      return signoff.operators.map((operator) => {
        return {
          id: `${signoff.id}-${operator}`,
          label: operator ?? '',
          isDisabled:
            !isEnabled ||
            !userStepRoleSet.has(operator) ||
            (typeof hasUserSignoffOnOtherRole === 'function' ? hasUserSignoffOnOtherRole(operator) : false),
          onClick: getOnButtonSignoff(signoff.id, operator ?? ''),
        };
      });
    }, [getOnButtonSignoff, isEnabled, signoff, userStepRoleSet, hasUserSignoffOnOtherRole]);

    const showVerticalSpaceInPrint = !isStepCollapsed && !isSignedOff;

    return (
      <>
        <SignoffButtons
          isComplete={isSignedOff}
          buttons={buttons}
          isDisabled={false}
          revokeApproval={onRevoke}
          isRevokeEnabled={isRevokeEnabledWithPermission}
          leadingIcon={completedByAutomation ? 'bolt' : 'check-circle'}
          showVerticalSpaceInPrint={showVerticalSpaceInPrint}
          isPinSignoffEnabled={isPinSignoffEnabled && signoff.operators.length > 0}
          onPinSignoff={() => setShowPinSignoffModal(true)}
          isOptionMenuAlwaysVisible={isOptionMenuAlwaysVisible}
        />
        <PinSignoffModal
          isModalShown={showPinSignoffModal}
          signoff={signoff}
          onPinSignoff={async ({ operator, user, pin }) => {
            return onPinSignoff({ signoffId: signoff.id, pinUser: user, pin, operator });
          }}
          onCloseModal={() => setShowPinSignoffModal(false)}
        />
      </>
    );
  }
);

export default ProcedureStepSignoffButton;

import React, { ReactNode } from 'react';
import { MARKDOWN_MARGIN_CLASS } from './MarkdownView';

interface MarkdownUListWrapperProps {
  children: ReactNode;
}

const MarkdownUListWrapper = React.memo(({ children }: MarkdownUListWrapperProps) => {
  return <ul className={`list-disc pl-8 ${MARKDOWN_MARGIN_CLASS}`}>{children}</ul>;
});

export default MarkdownUListWrapper;

import React, { useMemo, useCallback, forwardRef } from 'react';
import { FieldArray } from 'formik';
import FieldSetStepSignoff from './FieldSetStepSignoff';
import procedureUtil from '../lib/procedureUtil';
import { useSettings } from '../contexts/SettingsContext';
import FieldSetSignoffSelect from './FieldSetSignoffSelect';
import signoffUtil from 'shared/lib/signoffUtil';
import cloneDeep from 'lodash.clonedeep';
import { EDIT_STICKY_HEADER_HEIGHT_REM } from './EditToolbar';

const ANY_OPERATOR = {
  value: 'Any Operator',
  label: 'Any Operator',
};

const FieldSetStepSignoffArray = forwardRef(
  ({ signoffs, sectionIndex, stepIndex, onSignoffsUpdated, trackedCallback, isDisabled }, ref) => {
    const { operatorRoles } = useSettings();

    const operatorOptions = useMemo(() => {
      if (!operatorRoles || !operatorRoles.operators) {
        return [];
      }
      const allOperatorOptions = operatorRoles.operators.map((op) => op.code);
      return allOperatorOptions.map((op) => ({
        value: op,
        label: op,
      }));
    }, [operatorRoles]);

    const getOnAddSignoff = useCallback(() => {
      return trackedCallback('Signoff added', (options) => {
        if (options.length !== 1) {
          return;
        }
        const newSignoff = procedureUtil.newSignoff();
        if (options[0].value !== ANY_OPERATOR.value) {
          newSignoff.operators = [options[0].value];
        }

        const signoffsCopy = cloneDeep(signoffs);
        signoffsCopy.push(newSignoff);

        onSignoffsUpdated(signoffsCopy);
      });
    }, [signoffs, trackedCallback, onSignoffsUpdated]);

    const getOnRemoveSignoff = useCallback(
      (signoffIndex) => {
        return trackedCallback('Signoff removed', () => {
          const signoffsCopy = cloneDeep(signoffs);
          signoffsCopy.splice(signoffIndex, 1);

          onSignoffsUpdated(signoffsCopy);
        });
      },
      [signoffs, trackedCallback, onSignoffsUpdated]
    );

    const onSignoffUpdated = useCallback(
      (updatedSignoff, signoffIndex) => {
        const signoffsCopy = cloneDeep(signoffs);
        signoffsCopy[signoffIndex] = updatedSignoff;

        onSignoffsUpdated(signoffsCopy);
      },
      [signoffs, onSignoffsUpdated]
    );

    return (
      <FieldArray
        name="signoffs"
        key={`section.${sectionIndex}.steps.${stepIndex}.signoffs`}
        render={() => (
          <div
            className="flex flex-wrap gap-x-2 gap-y-4 items-start"
            ref={ref}
            style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
          >
            {signoffUtil.isSignoffRequired(signoffs) &&
              signoffs.map((signoff, signoffIndex) => (
                <FieldSetStepSignoff
                  key={signoff.id}
                  name={`signoffs[${signoffIndex}]`}
                  signoffIndex={signoffIndex}
                  signoff={signoff}
                  onRemoveSignoff={getOnRemoveSignoff(signoffIndex)}
                  operatorOptions={operatorOptions}
                  onSignoffUpdated={onSignoffUpdated}
                  placeholder="Any Operator"
                  numSignoffs={signoffs.length}
                  isDisabled={isDisabled}
                />
              ))}
            {!signoffUtil.isSignoffRequired(signoffs) && (
              <FieldSetSignoffSelect
                options={[ANY_OPERATOR, ...operatorOptions]}
                placeholder="None"
                opacity={0.3}
                value={null}
                hoverOpacity={0.6}
                onChange={getOnAddSignoff()}
                ariaLabel="Add Signoff Select"
                isDisabled={isDisabled}
              />
            )}
            {!isDisabled && signoffUtil.requiresSpecificOperator(signoffs) && (
              <FieldSetSignoffSelect
                options={operatorOptions}
                placeholder="Add Signoff"
                opacity={0.3}
                value={null}
                hoverOpacity={0.6}
                onChange={getOnAddSignoff()}
                ariaLabel="Add Signoff Select"
                isDisabled={isDisabled}
              />
            )}
          </div>
        )}
      />
    );
  }
);

export default FieldSetStepSignoffArray;

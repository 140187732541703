import { SortDirection } from 'react-data-grid';
import { ProcedureMetadataBase } from '../../components/Home/types';

const GridComparators = {
  // Always sort undefined projects last
  project: (
    a: ProcedureMetadataBase,
    b: ProcedureMetadataBase,
    direction: SortDirection
  ): number => {
    if (a.projectName === b.projectName) {
      return 0;
    }
    if (!a.projectName) {
      return direction === 'ASC' ? 1 : -1;
    }
    if (!b.projectName) {
      return direction === 'ASC' ? -1 : 1;
    }

    return a.projectName
      .toLowerCase()
      .localeCompare(b.projectName.toLowerCase());
  },
};

export default GridComparators;

import Button from '../Button';
import React, { useCallback, useMemo } from 'react';
import Select, { Option, StylesConfig } from 'react-select';

interface DiffSelectorProps {
  secondaryLabel: string;
  initialOldPrimaryValue: Option;
  initialOldSecondaryValue: Option;
  initialNewPrimaryValue: Option;
  initialNewSecondaryValue: Option;
  oldSecondaryOptions: Array<Option>;
  newSecondaryOptions: Array<Option>;
  onCompare: (values: {
    old: string;
    oldVersion: string;
    oldPending: 'true' | 'false';
    new: string;
    newVersion: string;
    newPending: 'true' | 'false';
  }) => void;
}

const isOptionSelected = (option, selectedArray) => {
  return selectedArray.some((selected) => option.value === selected.value && option.pending === selected.pending);
};

const DiffSelector = ({
  secondaryLabel,
  initialOldPrimaryValue,
  initialOldSecondaryValue,
  initialNewPrimaryValue,
  initialNewSecondaryValue,
  oldSecondaryOptions,
  newSecondaryOptions,
  onCompare,
}: DiffSelectorProps) => {
  const getStyles: StylesConfig = useCallback(
    ({ color, backgroundColor }) => ({
      control: (base) => ({
        ...base,
        borderColor: color,
        backgroundColor,
        color,
        borderRadius: '9999px',
        minHeight: 'fit-content',
        height: 'fit-content',
      }),
      valueContainer: (base) => ({
        ...base,
        minHeight: 'fit-content',
        height: 'fit-content',
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginRight: 0,
      }),
      singleValue: (base) => ({
        ...base,
        color,
        position: 'static',
        transform: 'none',
        minWidth: 'fit-content',
        maxWidth: 'none',
      }),
      dropdownIndicator: (base) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: '0.25rem',
        color,
      }),
      input: (base) => ({
        ...base,
        margin: 0,
      }),
      menu: (base) => ({
        ...base,
        width: 'max-content',
        zIndex: 20,
      }),
    }),
    []
  );

  const oldStyles = useMemo(
    () =>
      getStyles({
        color: 'rgb(220 38 38)',
        backgroundColor: 'rgb(254 226 226)',
      }),
    [getStyles]
  );
  const newStyles = useMemo(
    () =>
      getStyles({
        color: 'rgb(6 95 70)',
        backgroundColor: 'rgb(209 250 229)',
      }),
    [getStyles]
  );

  const swapOldAndNew = useCallback(() => {
    onCompare({
      old: initialNewPrimaryValue.value,
      oldVersion: initialNewSecondaryValue.value,
      oldPending: initialNewSecondaryValue.pending ? 'true' : 'false',
      new: initialOldPrimaryValue.value,
      newVersion: initialOldSecondaryValue.value,
      newPending: initialOldSecondaryValue.pending ? 'true' : 'false',
    });
  }, [
    onCompare,
    initialOldPrimaryValue.value,
    initialOldSecondaryValue.value,
    initialOldSecondaryValue.pending,
    initialNewPrimaryValue.value,
    initialNewSecondaryValue.value,
    initialNewSecondaryValue.pending,
  ]);

  const onChangeOldVersion = useCallback(
    (option: Option) => {
      if (option.value === initialOldSecondaryValue.value && option.pending === initialOldSecondaryValue.pending) {
        return;
      }
      onCompare({
        old: initialOldPrimaryValue.value,
        oldVersion: option.value,
        oldPending: option.pending ? 'true' : 'false',
        new: initialNewPrimaryValue.value,
        newVersion: initialNewSecondaryValue.value,
        newPending: initialNewSecondaryValue.pending ? 'true' : 'false',
      });
    },
    [
      onCompare,
      initialOldPrimaryValue.value,
      initialOldSecondaryValue.value,
      initialOldSecondaryValue.pending,
      initialNewPrimaryValue.value,
      initialNewSecondaryValue.value,
      initialNewSecondaryValue.pending,
    ]
  );

  const onChangeNewVersion = useCallback(
    (option: Option) => {
      if (option.value === initialNewSecondaryValue.value && option.pending === initialNewSecondaryValue.pending) {
        return;
      }
      onCompare({
        old: initialOldPrimaryValue.value,
        oldVersion: initialOldSecondaryValue.value,
        oldPending: initialOldSecondaryValue.pending ? 'true' : 'false',
        new: initialNewPrimaryValue.value,
        newVersion: option.value,
        newPending: option.pending ? 'true' : 'false',
      });
    },
    [
      onCompare,
      initialOldPrimaryValue.value,
      initialOldSecondaryValue.value,
      initialOldSecondaryValue.pending,
      initialNewPrimaryValue.value,
      initialNewSecondaryValue.value,
      initialNewSecondaryValue.pending,
    ]
  );

  return (
    <div className="flex flex-row flex-wrap items-center">
      <span className="font-medium mr-2">{initialOldPrimaryValue.label}</span>
      <Select
        value={initialOldSecondaryValue}
        styles={oldStyles}
        components={{
          IndicatorSeparator: () => null,
        }}
        aria-label={`Old ${secondaryLabel}`}
        placeholder={`Old ${secondaryLabel}`}
        classNamePrefix="react-select"
        className="text-sm"
        options={oldSecondaryOptions}
        onChange={onChangeOldVersion}
        isOptionSelected={isOptionSelected}
      />
      <Button type="tertiary" leadingIcon="right-left" onClick={swapOldAndNew} />

      {initialOldPrimaryValue.label !== initialNewPrimaryValue.label && (
        <span className="font-medium mr-2">{initialNewPrimaryValue.label}</span>
      )}
      <Select
        value={initialNewSecondaryValue}
        styles={newStyles}
        components={{
          IndicatorSeparator: () => null,
        }}
        aria-label={`New ${secondaryLabel}`}
        placeholder={`New ${secondaryLabel}`}
        classNamePrefix="react-select"
        className="text-sm"
        options={newSecondaryOptions}
        onChange={onChangeNewVersion}
        isOptionSelected={isOptionSelected}
      />
    </div>
  );
};

export default DiffSelector;

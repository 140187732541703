import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FEATURE_OFFLINE_RUN_ACTIONS_ENABLED } from '../config';
import { setCurrentUserId, fetchUnacknowledgedUserMessageKeys } from '../contexts/usersSlice';
import { getSession } from '../api/superlogin';
import { E3Session } from 'shared/lib/types/api/util';

export type UserState = {
  userInfo: { session: E3Session };
  setUserInfo: React.Dispatch<React.SetStateAction<{ session: E3Session }>>;
};

const UserContext = React.createContext<UserState | undefined>(undefined);

const UserProvider = (props) => {
  // Use provided session or request from superlogin
  let session = props && props.session;
  if (!session) {
    session = getSession();
  }
  const [userInfo, setUserInfo] = useState({ session });
  const dispatch = useDispatch();

  /**
   * Perform one-time initialization of the current user.
   *
   * This ensures that when currentUserId is released, clients that upgrade
   * will have currentUserId set without requiring a new login event. This
   * can be removed at some point once all clients upgrade to the new version.
   */
  useEffect(() => {
    if (!FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      return;
    }

    const session = getSession();
    if (session && session.user_id) {
      dispatch(setCurrentUserId(session.user_id));
    }
  }, [dispatch]);

  // Load unacknowledged user message keys.
  useEffect(() => {
    dispatch(fetchUnacknowledgedUserMessageKeys());
  }, [dispatch]);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUserInfo,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

const useUserInfo = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserInfo must be used within a UserProvider');
  }
  return context;
};

const updateUserInfo = (session, setUserInfo) => {
  setUserInfo({ session });
};

export { UserProvider, useUserInfo, updateUserInfo };

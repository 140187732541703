import { AxiosResponse } from 'axios';
import { NOTIFICATION_STATUS } from 'shared/lib/notifications';
import { CreateSubscriptionReq } from 'shared/lib/types/api/notifications/subscriptions/requests';
import { ListSubscriptionsRcvRes } from 'shared/lib/types/api/notifications/subscriptions/responses';
import {
  AppNotificationDisplay,
  FormattedNotificationPreferences,
  SlackChannelInfo,
} from 'shared/lib/types/notifications';
import {
  NotificationChannel,
  NotificationPreferenceDetails,
  NotificationType,
} from 'shared/lib/types/postgres/notifications';
import { API_URL } from '../config';
import superlogin from './superlogin';

class NotificationsService {
  private teamId: string;

  constructor(teamId: string) {
    this.teamId = teamId;
  }

  async getUnresolvedNotifications(): Promise<Array<AppNotificationDisplay>> {
    try {
      const url = `${API_URL}/teams/${this.teamId}/notifications/app`;
      const response = await superlogin.getHttp().get(url, {
        params: { status: NOTIFICATION_STATUS.UNRESOLVED },
      });
      return response.data;
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }

  async getAllNotifications(): Promise<Array<AppNotificationDisplay>> {
    try {
      const url = `${API_URL}/teams/${this.teamId}/notifications/app`;
      const response = await superlogin.getHttp().get(url);
      return response.data;
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }

  async resolveNotifications(ids: Array<number>): Promise<AxiosResponse> {
    try {
      const url = `${API_URL}/teams/${this.teamId}/notifications/app`;
      return superlogin.getHttp().patch(url, {
        ids,
        status: NOTIFICATION_STATUS.RESOLVED,
      });
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }

  async getNotificationPreferences(): Promise<{
    notificationPreferences: FormattedNotificationPreferences[];
    slackChannelsList: SlackChannelInfo[];
  }> {
    try {
      const url = `${API_URL}/teams/${this.teamId}/notifications/preferences`;
      const response = await superlogin.getHttp().get(url);
      return {
        notificationPreferences: response.data.preferences,
        slackChannelsList: response.data.slack_channels_list,
      };
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }

  async updateNotificationPreferences(
    notificationChannel: NotificationChannel,
    notificationType: NotificationType,
    isEnabled: boolean,
    details?: NotificationPreferenceDetails
  ): Promise<AxiosResponse> {
    try {
      const url = `${API_URL}/teams/${this.teamId}/notifications/preferences`;
      return superlogin.getHttp().put(url, {
        notification_channel: notificationChannel,
        notification_type: notificationType,
        is_enabled: isEnabled,
        details,
      });
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }

  async createSubscription(req: CreateSubscriptionReq): Promise<AxiosResponse> {
    try {
      const url = `${API_URL}/teams/${this.teamId}/notifications/subscriptions`;
      return superlogin.getHttp().post(url, req);
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }

  async getSubscriptions(): Promise<ListSubscriptionsRcvRes> {
    try {
      const url = `${API_URL}/teams/${this.teamId}/notifications/subscriptions`;
      const resp = await superlogin.getHttp().get<ListSubscriptionsRcvRes>(url);
      return resp.data;
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }

  async deleteSubscription(id: number): Promise<AxiosResponse> {
    try {
      const url = `${API_URL}/teams/${this.teamId}/notifications/subscriptions/${id}`;
      return superlogin.getHttp().delete(url);
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }
}

export default NotificationsService;

import React from 'react';
import { Link } from 'react-router-dom';
import runUtil from '../../lib/runUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Section } from 'shared/lib/types/views/procedures';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface SectionSpanProps {
  linkTo: string;
  sectionKey: string;
  sectionRepeatKey?: string;
  currentSection: Section | { name: string };
  hasError?: boolean;
}

const SectionSpan = React.memo(
  ({ linkTo, sectionKey, sectionRepeatKey, currentSection, hasError }: SectionSpanProps) => {
    const repeatSubtitle = runUtil.isSectionRepeat(currentSection) ? `(repeat ${sectionRepeatKey})` : '';
    const sectionKeyDisplay = sectionKey ? `${sectionKey}:` : '';

    return (
      <Link to={linkTo}>
        <div className="flex flex-nowrap flex-row items-center w-full my-2 truncate">
          <div className="mx-2 text-lg font-semibold flex flex-row cursor-pointer hover:text-blue-600">
            <span className="truncate" title={`${currentSection.name} ${repeatSubtitle}`}>
              {`${sectionKeyDisplay} ${currentSection.name}`}
            </span>
            {runUtil.isSectionRepeat(currentSection) && (
              <span className="ml-1 whitespace-nowrap" aria-label={`repeat ${sectionRepeatKey}`}>
                <FontAwesomeIcon icon="redo" className="text-sm" />
                {` ${sectionRepeatKey}`}
              </span>
            )}
          </div>
          {hasError && (
            <FontAwesomeIcon icon={'fa-solid fa-triangle-exclamation' as IconProp} className="text-red-500 text-base" />
          )}
        </div>
      </Link>
    );
  }
);

export default SectionSpan;

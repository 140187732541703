import superlogin from '../../api/superlogin';
import { API_URL } from '../../config';

import {
  NewPasswordRequest,
  StoredNewPasswordResponse,
  VerifyNewPasswordResponse,
} from 'shared/lib/types/api/passwords/requests';

class PasswordsService {
  private readonly restUrl: string;
  private readonly teamId: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    this.restUrl = `${API_URL}/teams/${this.teamId}`;
  }

  async verifyNewPassword(
    newPassword: string
  ): Promise<VerifyNewPasswordResponse> {
    const url = `${this.restUrl}/old_passwords/verify`;

    const request: NewPasswordRequest = {
      newPassword,
    };

    const response = await superlogin
      .getHttp()
      .post<VerifyNewPasswordResponse>(url, request);
    return response.data;
  }

  async storeNewPassword(
    newPassword: string
  ): Promise<StoredNewPasswordResponse> {
    const url = `${this.restUrl}/old_passwords/store`;

    const request: NewPasswordRequest = {
      newPassword,
    };

    const response = await superlogin
      .getHttp()
      .post<StoredNewPasswordResponse>(url, request);
    return response.data;
  }
}

export default PasswordsService;

import { FrontendEvent as Event } from 'shared/schedule/types/event';
import MultiEvent from './MultiEvent';
import { Duration, DateTime } from 'luxon';

export enum CollapseLevels {
  Collapsed = 0,
  Expanded = 1,
  Maximized = 3,
}

const swimlaneLib = {
  collapseSortedEvents: (
    sortedEvents: Array<Event>,
    instantaneousEventPadding: Duration
  ): Array<Event | MultiEvent> => {
    if (sortedEvents.length === 0) {
      return [];
    }

    const ret: Array<Event | MultiEvent> = [];

    let prev;
    for (const event of sortedEvents) {
      // Input events *should* all have starts, but check to make the types happy
      if (!event.start) {
        continue;
      }

      // Initialize prev with first event
      if (!prev) {
        prev = event;
        continue;
      }

      /**
       * If the current event overlaps the previous event, join into a
       * multievent or add to previous multievent. Otherwise push prev to
       * appropriate return array and set prev to current event.
       */
      if (
        (prev.end && event.start < prev.end) ||
        event.start < prev.start.plus(instantaneousEventPadding)
      ) {
        // Join to existing MultiEvent or create new MultiEvent
        if (prev.isMultiEvent) {
          prev.events.push(event);
        } else {
          prev = new MultiEvent([prev, event]);
        }
      } else {
        ret.push(prev);
        prev = event;
      }
    }

    ret.push(prev);

    return ret;
  },

  sortedEventsToMinRows: (
    sortedEvents: Array<Event>,
    instantaneousEventPadding: Duration
  ): Array<Array<Event>> => {
    if (sortedEvents.length === 0) {
      return [];
    }

    const rows: Array<Array<Event>> = [];
    const ongoingEventRows: Array<Array<Event>> = [];

    eventLoop: for (const event of sortedEvents) {
      // Input events *should* all have starts, but check to make the types happy
      if (!event.start) {
        continue;
      }

      // Ongoing events get their own rows
      if (event.start && !event.end && event.status === 'running') {
        ongoingEventRows.push([event]);
        continue;
      }

      // Add to the first row without overlap
      for (const row of rows) {
        const lastEvent = row[row.length - 1];
        const lastEnd =
          lastEvent.end ||
          (lastEvent.start as DateTime).plus(instantaneousEventPadding);
        if (event.start >= lastEnd) {
          row.push(event);
          continue eventLoop;
        }
      }
      rows.push([event]);
    }

    return [...rows, ...ongoingEventRows];
  },

  swimlaneBgColorClass: (i: number): string => {
    return i % 2 ? 'bg-gray-200' : 'bg-gray-100';
  },
};

export default swimlaneLib;

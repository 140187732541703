import Toggle from 'react-toggle';
import React, { useCallback } from 'react';

interface ToggleDiffProps {
  showDiff: boolean;
  setShowDiff: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToggleDiff = ({ showDiff, setShowDiff }: ToggleDiffProps) => {
  const handleToggleDiff = useCallback(() => {
    setShowDiff((oldShowDiff) => !oldShowDiff);
  }, [setShowDiff]);

  const textColorGray = showDiff ? '' : 'text-gray-400';

  return (
    <label>
      <div className="flex flex-row items-center whitespace-nowrap">
        <Toggle checked={showDiff} onChange={handleToggleDiff} aria-label="Diff Toggle" icons={false} />
        <div className={`ml-2 text-sm ${textColorGray}`}>Show Changes</div>
      </div>
    </label>
  );
};

export default React.memo(ToggleDiff);

import { Link } from 'react-router-dom';
import { storagePath } from '../../lib/pathUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import useStorageTrack from '../hooks/useStorageTrack';

type GoToStorageToolLinkProps = {
  searchParamsString: string;
};

const GoToStorageToolLink = ({ searchParamsString }: GoToStorageToolLinkProps) => {
  const storageTrack = useStorageTrack();
  const { currentTeamId } = useDatabaseServices();

  const onClick = () => {
    storageTrack('Navigated to plot from telemetry');
  };

  return (
    <Link
      to={{ pathname: storagePath(currentTeamId), search: searchParamsString }}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
    >
      <FontAwesomeIcon
        className="text-blue-500 hover:text-blue-600 hover:cursor-pointer"
        icon="arrow-up-right-from-square"
        aria-label="Navigate To Plot"
      />
    </Link>
  );
};

export default GoToStorageToolLink;

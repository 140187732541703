import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { FLASH_MSG_MS } from '../config';
import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';

interface FlashMessageProps {
  message: string | null;
  messageUpdater: (newMessage: string | null) => void;
  type?: 'warning' | 'info';
  topOffset?: number;
}

/**
 * Used to display a message at the top center of the screen for a set amount of time.
 *
 * @param message - Message to display
 * @param messageUpdater - Function to update the message being displayed
 * @param type - Type of banner to adjust styling
 * @param topOffset - offset (in tailwind units) to offset message from top
 */
const FlashMessage = ({ message, messageUpdater, type, topOffset = 0 }: FlashMessageProps) => {
  const [flashMessage, setFlashMessage] = useState<string | null>(null);
  const [timeoutHandle, setTimeoutHandle] = useState<TimeoutId | null>(null);
  const style = useMemo(() => {
    switch (type) {
      case 'warning':
        return 'bg-red-100/90 text-red-700';
      case 'info':
        return 'bg-blue-100/90 text-blue-700';
      default:
        return 'bg-green-200/90 text-green-700';
    }
  }, [type]);

  useEffect(() => {
    if (!message) {
      return;
    }
    if (timeoutHandle) {
      clearTimeout(timeoutHandle);
    }
    setFlashMessage(message);
    messageUpdater(null);
    setTimeoutHandle(setTimeout(() => setFlashMessage(null), FLASH_MSG_MS));
  }, [message, messageUpdater, timeoutHandle]);

  return (
    <Fragment>
      {flashMessage && (
        <div className={`fixed left-0 right-0 top-${topOffset} m-auto max-w-max shadow-md z-[1000] rounded`}>
          <div className={`px-2 py-1 rounded 0 ${style} text-center`}>{flashMessage}</div>
        </div>
      )}
    </Fragment>
  );
};

export default FlashMessage;

import { Measurements } from '../types';

export const getMinTimeBetweenMeasurements = (measurements: Measurements): number => {
  let minTime = Infinity;
  measurements.forEach((measurement) => {
    for (let i = 0; i < measurement.series.length - 1; i++) {
      const time = measurement.series[i + 1].timestamp - measurement.series[i].timestamp;
      minTime = Math.min(time, minTime);
    }
  });
  return minTime;
};

// Returns ISO UTC time with second granulariy
export const getIsoTimeSeconds = (datetime: Date): string => {
  const isoString = new Date(datetime).toISOString();
  return `${isoString.split('.')[0]}Z`;
};

// Returns ISO UTC time with microsecond granulariy
export const getIsoTimeMicroseconds = (datetime: Date): string => {
  const isoString = new Date(datetime).toISOString();
  const microseconds = isoString.slice(-3);
  const dateWithMicroseconds = isoString.replace('Z', `${microseconds}`);
  return dateWithMicroseconds;
};

import superlogin from './superlogin';
import { API_URL } from '../config';

export type IssueType = {
  id: string;
  name: string;
};

export type ProjectType = {
  id: string;
  name: string;
  issueTypes: Array<IssueType>;
};

export type IssueMetadata = {
  projects: Array<ProjectType>;
};

export type CreateIssueRequest = {
  projectId: string;
  typeId: string;
  title: string;
  description?: string;
  url?: string;
  urlText?: string;
};

export type CreateIssueResponse = {
  url: string;
  issueKey: string;
};

class IssueService {
  private teamId: string;

  constructor(teamId: string) {
    this.teamId = teamId;
  }

  async getMetadata(): Promise<IssueMetadata> {
    const url = `${API_URL}/teams/${this.teamId}/issues/createMetadata`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async createIssue({
    projectId,
    typeId,
    title,
    description,
    url,
    urlText,
  }: CreateIssueRequest): Promise<CreateIssueResponse> {
    const apiUrl = `${API_URL}/teams/${this.teamId}/issues/jira`;
    const body = {
      project_id: projectId,
      type_id: typeId,
      title,
      description,
      url,
      url_text: urlText,
    };
    const response = await superlogin.getHttp().post(apiUrl, body);
    return response.data;
  }
}

export default IssueService;

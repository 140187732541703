import {
  ExpressionBlock,
  ExpressionToken,
  FieldInputBlock,
  RunExpressionBlock,
  RunFieldInputBlock,
  RunFieldInputNumberBlock,
  RunFieldInputRecorded,
  RunFieldInputTextBlock,
  TimestampValue,
} from './types/views/procedures';
import { Unit } from './types/api/settings/units/models';
import {
  ExpressionReferenceTargetBlock,
  ExpressionReferenceTargetRecorded,
  ParseTokenParams,
  ReferenceContext,
  TokenResolvedResult,
} from './types/expressions';
import { getTimestampFromRecorded } from './datetime';
import { evaluate as evaluateMathJs } from 'mathjs';
import { isNumber } from 'lodash';

const UNRESOLVED_RESULT: TokenResolvedResult = {
  resolvedExpression: undefined,
  richDisplayText: '?',
  displayText: '?',
  isResolved: false,
  context: {},
};

/**
 * _getRichText creates a string with delimiters and context to give a markdown
 * code block a way to identify and then enhance reference tokens.  It adds
 * backticks to denote the block as code and uses the emoji delimiters to
 * avoid overlap with anything a user might enter into a text field.  Lastly,
 * it uses the zero width space &#x200B; to allow tokens to be right next to
 * each other while still parsing out to separate code blocks
 */
const _getRichText = ({
  referenceId,
  value = '',
  isResolved,
  fieldIndex,
}: {
  referenceId: string;
  value?: string;
  isResolved?: boolean;
  fieldIndex?: number;
}) => {
  return `\`🌜${referenceId}🌓${isResolved ? value : '??'}🌗${
    fieldIndex ?? ''
  }🌛\`&#x200B;`;
};

const _handleExpressionToken = ({
  token,
  referenceContext,
  findDefinedUnit,
  getRecorded,
}: ParseTokenParams): TokenResolvedResult => {
  if (!token?.reference_id) {
    return UNRESOLVED_RESULT;
  }
  if (getRecorded(referenceContext[token.reference_id] as RunExpressionBlock)) {
    const value =
      getRecorded(referenceContext[token.reference_id] as RunExpressionBlock)
        ?.value ?? '';
    const isResolved = !(value === undefined || value === '');
    const resolvedExpression = isResolved
      ? `(${token.reference_id})`
      : undefined;

    return {
      resolvedExpression,
      richDisplayText: _getRichText({
        referenceId: token.reference_id,
        value: value as string,
        isResolved,
      }),
      displayText: isResolved ? `${value as string | number}` : '?',
      isResolved,
      context: { [token.reference_id]: `${(value as string | number) ?? ''}` },
    };
  }

  const childTokens = (referenceContext[token.reference_id] as ExpressionBlock)
    .tokens;
  const result = resolveTokens({
    tokens: childTokens,
    referenceContext,
    findDefinedUnit,
    consolidate: true,
    parentToken: token,
    getRecorded,
  });
  return {
    ...result,
    resolvedExpression:
      result.resolvedExpression === undefined
        ? undefined
        : `(${result.resolvedExpression})`, // Parentheses are needed here to preserve the correct Order of Operations.
  };
};

const _handleInputToken = ({
  token,
  referenceContext,
  findDefinedUnit,
  getRecorded,
}: ParseTokenParams): TokenResolvedResult => {
  if (!token?.reference_id) {
    return UNRESOLVED_RESULT;
  }

  const referencedContext = referenceContext[token.reference_id];
  const block =
    referencedContext?.type === 'field_input_table' &&
    isNumber(token.field_index) &&
    referencedContext?.fields
      ? referencedContext.fields[token.field_index]
      : referencedContext;

  const recorded = getRecorded(block);
  if (!recorded || recorded.value === '') {
    return {
      resolvedExpression: undefined,
      displayText: '?',
      richDisplayText: _getRichText({
        referenceId: token.reference_id,
        fieldIndex: token.field_index,
      }),
      isResolved: false,
      context: {},
    };
  }

  const { value } = recorded;
  let valueWithUnits = value;

  if (findDefinedUnit) {
    const units = (block as RunFieldInputNumberBlock | RunFieldInputTextBlock)
      ?.units;

    if (units) {
      const settingsUnit = findDefinedUnit(units);
      const abbreviation = settingsUnit?.abbreviation ?? units;
      valueWithUnits = `${value as string | number} ${abbreviation}`;
    }
  }

  const expressionName = isNumber(token.field_index)
    ? `${token.reference_id}_${token.field_index}`
    : token.reference_id;
  const resolvedExpression = `(${expressionName})`;

  if ((block as FieldInputBlock).inputType === 'timestamp') {
    const { timestamp } = getTimestampFromRecorded(
      recorded as RunFieldInputRecorded<TimestampValue>
    );
    return timestamp
      ? {
          resolvedExpression,
          richDisplayText: _getRichText({
            referenceId: token.reference_id,
            value: timestamp,
            isResolved: true,
            fieldIndex: token.field_index,
          }),
          displayText: timestamp,
          isResolved: true,
          context: { [token.reference_id]: timestamp },
        }
      : {
          resolvedExpression,
          richDisplayText: _getRichText({
            referenceId: token.reference_id,
            fieldIndex: token.field_index,
          }),
          displayText: '?',
          isResolved: false,
          context: {},
        };
  }

  return {
    resolvedExpression,
    displayText: `${(valueWithUnits as string | number) ?? ''}`,
    richDisplayText: _getRichText({
      referenceId: token.reference_id,
      value: valueWithUnits as string,
      isResolved: true,
      fieldIndex: token.field_index,
    }),
    isResolved: true,
    context: { [expressionName]: `${(value as string | number) ?? ''}` },
  };
};

/**
 * To allow an expression to reference a block type,
 * add a handler for that block type to this dispatch table.
 */
const TOKEN_REFERENCE_HANDLER: {
  [tokenType in NonNullable<ExpressionReferenceTargetBlock>['type']]: (
    params: ParseTokenParams
  ) => TokenResolvedResult;
} = {
  expression: _handleExpressionToken,
  input: _handleInputToken,
  field_input_table: _handleInputToken,
};

const _resolveToken = ({
  token,
  referenceContext,
  findDefinedUnit,
  getRecorded,
}: ParseTokenParams): TokenResolvedResult => {
  if (token.type === 'text') {
    return {
      resolvedExpression: token.value,
      displayText: token.value,
      richDisplayText: token.value,
      isResolved: true,
      context: {},
    };
  }

  // At this point, the token must be a reference token.
  const referencedContext = getContentBlock(
    referenceContext[token.reference_id ?? ''],
    token.field_index
  );
  const handlerType = referencedContext?.type;

  const tokenReferenceHandler = TOKEN_REFERENCE_HANDLER[handlerType];
  if (!token.reference_id || !tokenReferenceHandler) {
    return UNRESOLVED_RESULT;
  }

  return tokenReferenceHandler({
    token,
    referenceContext,
    findDefinedUnit,
    getRecorded,
  });
};

export const resolveTokens = ({
  tokens,
  referenceContext,
  findDefinedUnit,
  consolidate = false,
  parentToken,
  getRecorded = (block) =>
    (block as RunFieldInputBlock | RunExpressionBlock)?.recorded,
}: {
  tokens: Array<ExpressionToken>;
  referenceContext: Record<string, ReferenceContext>;
  findDefinedUnit?: (string) => Unit | undefined;
  consolidate?: boolean;
  parentToken?: ExpressionToken;
  getRecorded?: (
    block: ExpressionReferenceTargetBlock
  ) => ExpressionReferenceTargetRecorded;
}): Required<TokenResolvedResult> => {
  const tokenResolutionResults = tokens.map((token) => {
    return _resolveToken({
      token,
      referenceContext,
      findDefinedUnit,
      getRecorded,
    });
  });

  const isResolved = tokenResolutionResults.every(
    (result) => result.isResolved
  );
  const resolvedExpression = tokenResolutionResults
    .map((result) => result.resolvedExpression ?? '')
    .join('');
  const displayTextRaw = tokenResolutionResults
    .map((result) => result.displayText)
    .join('');
  const richDisplayTextRaw = tokenResolutionResults
    .map((result) => result.richDisplayText)
    .join('');
  const context = tokenResolutionResults
    .map((result) => result.context)
    .reduce((fullContext, tokenContext) => {
      return {
        ...fullContext,
        ...tokenContext,
      };
    }, {});

  const evaluated = isResolved
    ? evaluate(resolvedExpression, context)
    : undefined;

  return consolidate && parentToken?.reference_id
    ? {
        resolvedExpression,
        displayText: evaluated ?? '?',
        richDisplayText: _getRichText({
          referenceId: parentToken.reference_id,
          value: evaluated as string,
          isResolved,
        }),
        isResolved,
        context,
      }
    : {
        resolvedExpression,
        displayText: displayTextRaw,
        richDisplayText: richDisplayTextRaw,
        isResolved,
        context,
      };
};

export const evaluate = (
  formula: string,
  context: Record<string, string>
): string => {
  try {
    const result = evaluateMathJs(formula, context);
    return result?.toString() as string;
  } catch (e) {
    return (e as Error).message;
  }
};

/**
 * Depth-first search to check if there is a cycle anywhere in the directed graph
 */
export const hasCyclicReference = ({
  tokens,
  procedureMap,
  seen = new Set(),
}: {
  tokens: Array<ExpressionToken>;
  procedureMap: { [id: string]: ExpressionBlock | FieldInputBlock | undefined };
  seen?: Set<string>;
}) => {
  return tokens
    .filter((token) => token.type === 'reference')
    .some((token) => {
      const referenceId = token.reference_id ?? '';
      if (seen.has(referenceId)) {
        return true;
      }

      const target = procedureMap[referenceId];
      if (target && target.type === 'expression') {
        return hasCyclicReference({
          tokens: target.tokens,
          procedureMap,
          seen: new Set([...seen, referenceId]),
        });
      }

      return false;
    });
};

export const getContentBlock = (block, fieldIndex?: number) => {
  if (block?.type === 'field_input_table' && isNumber(fieldIndex)) {
    return block.fields[fieldIndex] as FieldInputBlock;
  }
  return block as FieldInputBlock;
};

import { Procedure, Run, RunStep } from 'shared/lib/types/views/procedures';

export type BlockType = 'intro' | 'section' | 'batchStep' | 'step';

export const getScrollToUrlParams = ({ id, type }: { id: string; type: BlockType }) => {
  return `?scrollToId=${id}&scrollToType=${type}`;
};

export const getScrollToParamsFromUrlSearchParams = (
  searchParams: URLSearchParams
): undefined | { id: string; type: BlockType } => {
  const id = searchParams.get('scrollToId') as string;
  const type = searchParams.get('scrollToType') as BlockType;

  if (!id || !type) {
    return undefined;
  }

  return {
    id,
    type,
  };
};

const getSectionId = (procedure: Procedure, scrollToId: string, isBatchStepId: boolean): string | undefined => {
  for (const section of procedure.sections) {
    for (const step of section.steps as RunStep[]) {
      // when scrollToId is a batch ID, idToMatch will be undefined for non batch steps
      const idToMatch = isBatchStepId ? step.batchProps?.batchId : step.id;
      if (idToMatch === scrollToId) {
        return section.id;
      }
    }
  }
};

const getFirstStepIdInBatch = (procedure: Procedure, batchId: string): string | undefined => {
  for (const section of procedure.sections) {
    for (const step of section.steps as RunStep[]) {
      if (step.batchProps?.batchId === batchId) {
        return step.id;
      }
    }
  }
};

export const getScrollToArguments = ({
  id,
  type,
  procedure,
}: {
  id: string;
  type: BlockType;
  procedure: Run | Procedure;
}) => {
  let scrollToArguments;

  switch (type) {
    case 'intro':
      scrollToArguments = {
        scrollToId: id,
      };
      break;
    case 'section':
      scrollToArguments = {
        sectionId: id,
        scrollToId: id,
      };
      break;
    case 'batchStep':
      scrollToArguments = {
        sectionId: getSectionId(procedure, id, true),
        stepId: getFirstStepIdInBatch(procedure, id),
        scrollToId: id,
      };
      break;
    case 'step':
      scrollToArguments = {
        sectionId: getSectionId(procedure, id, false),
        stepId: id,
        scrollToId: id,
      };
      break;
    default:
      throw new Error(`Invalid type: ${type}`);
  }

  return scrollToArguments;
};

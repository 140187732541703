import React, { useMemo, useState, useEffect } from 'react';
import Button, { BUTTON_TYPES } from './Button';
import Papa, { ParseResult } from 'papaparse';
import Select from 'react-select';
import { reactSelectStyles } from '../lib/styles';

// key = parsed header, value = custom header value from example
export type CustomHeaderMap = {
  [key: string]: string;
};

export interface UploadCsvPreviewProps {
  file: File;
  exampleHeaders: Array<string>;
  onSubmit: (headerMap: CustomHeaderMap) => void;
  onCancel: (error?: string) => void;
}

const UploadCsvPreview = ({ file, exampleHeaders, onSubmit, onCancel }: UploadCsvPreviewProps) => {
  const [headerMap, setHeaderMap] = useState(
    exampleHeaders.reduce((accumulator, current) => ({ ...accumulator, [current]: current }), {})
  );
  const [parsedHeaders, setParsedHeaders] = useState<Array<string>>([]);
  const [parsedDataRows, setParsedDataRows] = useState<Array<Array<string>>>([[]]);

  // Parse the header and up to 3 data rows
  useEffect(() => {
    if (!file) {
      return;
    }

    Papa.parse(file, {
      skipEmptyLines: true,
      preview: 4,
      complete: (results: ParseResult) => {
        const headerRow = results.data?.[0];
        if (!headerRow) {
          return;
        }
        setParsedHeaders(headerRow.map((header) => header.toLowerCase().trim()));
        setParsedDataRows(results.data.slice(1));
      },
      error: () => {
        onCancel('Error parsing file');
      },
    });
  }, [file, onCancel]);

  const missingHeaders = useMemo(() => {
    return exampleHeaders.filter((header) => !parsedHeaders.includes(header.toLowerCase()));
  }, [parsedHeaders, exampleHeaders]);

  const headerOptions = useMemo(() => {
    return missingHeaders.map((header) => ({ value: header, label: header }));
  }, [missingHeaders]);

  const longestHeader = useMemo(() => {
    let longest = '';
    for (const header of missingHeaders) {
      longest = longest.length > header.length ? longest : header;
    }
    return longest;
  }, [missingHeaders]);

  const onCustomHeaderChange = (parsedValue, customValue) => {
    setHeaderMap((header) => {
      const updated = { ...header };
      for (const [k, v] of Object.entries(updated)) {
        if (customValue === v) {
          delete updated[k]; // enforce unique headers when re-assigning
        }
      }
      updated[parsedValue] = customValue;
      return updated;
    });
  };

  const renderHeaderCell = (header: string, index: number, exampleHeaders: Array<string>) => {
    const headerToOption = (header?: string) => {
      if (!header) {
        return null;
      }
      return {
        value: header,
        label: header,
      };
    };

    const validHeader = missingHeaders.length === 0 || exampleHeaders.includes(header) ? header : '';

    return (
      <th key={index} className="border border-black p-0.5">
        {validHeader ? (
          validHeader
        ) : (
          <div>
            {/* Workaround for sizing react-select to fit width of options, see: https://github.com/JedWatson/react-select/issues/4201 */}
            <div className="mx-2 h-0 px-5">{longestHeader}</div>
            <Select
              key={index}
              name="select-filter"
              options={headerOptions}
              classNamePrefix="react-select"
              styles={{
                ...reactSelectStyles,
                placeholder: (base) => ({
                  ...base,
                  color: '#B91C1C', // red-700
                }),
              }}
              onChange={(option) => onCustomHeaderChange(header, option.value)}
              value={headerToOption(headerMap[header])}
              placeholder={header}
              menuPosition="fixed"
            />
          </div>
        )}
      </th>
    );
  };

  const renderBodyCell = (cell: string, index: number) => {
    return (
      <td key={index} className="border border-black p-0.5">
        <div className="max-w-[300px] truncate">{cell}</div>
      </td>
    );
  };

  return (
    <>
      <div className="py-1">
        {`Preview file `}
        <code className="bg-gray-100">{`${file.name}`}</code>
        {` and assign unknown headers:`}
      </div>
      <code>
        <div className="my-3 p-2 bg-gray-100 overflow-auto whitespace-pre">
          <table className="table-auto border-collapse">
            <thead>
              <tr className="capitalize">
                {parsedHeaders.map((header, index) => renderHeaderCell(header, index, exampleHeaders))}
              </tr>
            </thead>
            <tbody>
              {parsedDataRows.map((entry, index) => (
                <tr key={index}>{entry.map((cell, index) => renderBodyCell(cell, index))}</tr>
              ))}
            </tbody>
          </table>
        </div>
      </code>
      <div className="flex flex-row gap-2 my-2 justify-center">
        <Button type={BUTTON_TYPES.SECONDARY} onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button type={BUTTON_TYPES.PRIMARY} onClick={() => onSubmit(headerMap)}>
          Submit
        </Button>
      </div>
    </>
  );
};

export default UploadCsvPreview;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopupListWithSearch from '../../elements/PopupListWithSearch';
import UserId from '../../elements/UserId';
import { useSettings } from '../../contexts/SettingsContext';
import { useCallback, useMemo } from 'react';
import useUsers from '../../hooks/useUsers';

interface AssigneeSelectorProps {
  assignee?: string;
  onChange: (userId: string | null) => Promise<void>;
  emailAsId?: boolean;
  canBeUnassigned: boolean;
}

const AssigneeSelector = ({ assignee, onChange, emailAsId, canBeUnassigned }: AssigneeSelectorProps) => {
  const { users } = useSettings();
  const { getUserDisplayText } = useUsers();

  const displayName = useMemo(() => {
    if (!users || !assignee) {
      return;
    }
    return getUserDisplayText(assignee);
  }, [assignee, getUserDisplayText, users]);

  const userList = useMemo(() => {
    if (!users) {
      return [];
    }

    const unassignedOption = assignee
      ? [
          {
            id: 'unassigned',
            name: 'Unassigned',
            user: null,
          },
        ]
      : [];

    const options = [
      ...(canBeUnassigned ? unassignedOption : []),
      ...Object.values(users.users)
        .map((user) => {
          // users should have ids and emails, despite the current User type which is nullable for those
          return {
            id: user.id ?? '',
            name: user.email ?? '',
            user,
          };
        })
        .filter((user) => user.id !== assignee)
        // filter out any user that somehow didn't have an id or email
        .filter((user) => user.id && user.name),
    ];

    return options;
  }, [assignee, canBeUnassigned, users]);

  const onSelect = useCallback(
    async (option) => {
      if (option.id === 'unassigned') {
        return onChange(null);
      }
      if (emailAsId) {
        return onChange(option.user.email);
      }
      return onChange(option.id);
    },
    [emailAsId, onChange]
  );

  return (
    <PopupListWithSearch
      Components={{
        Trigger: () => (
          <button
            type="button"
            className="flex flex-row gap-x-1 items-center hover:brightness-90"
            aria-label="Select Assignee"
          >
            {!displayName && <div className="text-gray-400 italic">Unassigned</div>}
            {displayName && <UserId userId={displayName} />}
            <FontAwesomeIcon icon="caret-down" className="text-gray-400" />
          </button>
        ),
        ListItem: (option) => {
          if (option.id === 'unassigned') {
            return <div className="text-sm truncate italic text-gray-400">Unassigned</div>;
          }
          return <UserId userId={option.name} />;
        },
      }}
      options={userList.filter((userOption) => userOption.name !== assignee)}
      onSelect={onSelect}
    />
  );
};

export default AssigneeSelector;

import React from 'react';
import Spacer from '../Spacer';
import SubstepNumber from '../SubstepNumber';
import ButtonActionIcon from './../ButtonActionIcon';
import { TypedProcedureBlockProps } from './BlockTypes';

/*
 * Renders a Requirement block within a procedure context.
 * Conforms to TypedProcedureBlock, see comments in useBlockComponents
 */
const RequirementProcedureBlock = ({ isHidden, actions, children, blockLabel }: TypedProcedureBlockProps) => {
  return (
    <div className={`mt-2 mr-8 flex page-break ${isHidden ? 'hidden' : ''}`}>
      <Spacer />
      <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />

      {/* Render child content */}
      <div className="min-w-0 w-full">{children}</div>

      {/* Render action buttons */}
      {actions &&
        actions.map((action) => (
          <span key={action.ariaLabel} className="ml-1">
            <ButtonActionIcon
              icon={action.icon}
              onAction={action.onAction}
              ariaLabel={action.ariaLabel}
              pendingAction={action.pendingAction}
            />
          </span>
        ))}
    </div>
  );
};

export default RequirementProcedureBlock;

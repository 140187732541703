import { useEffect, useMemo } from 'react';
import { useRunContext } from '../../contexts/RunContext';
import { generateHiddenClassString } from '../../lib/styles';
import TextLinkify from '../TextLinkify';
import {
  DiffArrayChangeSymbol,
  ExpressionReferences,
  ExpressionToken,
  RunExpressionBlock,
} from 'shared/lib/types/views/procedures';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import { BlockProvider } from '../../contexts/BlockContext';
import ReferenceDetail from '../ReferenceDetail';
import { isNil } from 'lodash';
import useExpression from '../../hooks/useExpression';
import TooltipOverlay from '../TooltipOverlay';

interface ReferenceEditSelectProps {
  name: string;
  tokens: ExpressionToken[];
  recorded?: RunExpressionBlock['recorded'];
  setExpressionRecorded?: (recorded: { value?: string; display?: string; references?: ExpressionReferences }) => void;
  isHidden: boolean;
  blockLabel?: string;
  isValid?: boolean;
  diffChangeState?: DiffArrayChangeSymbol;
  runId?: string;
}

const ExpressionBlock = ({
  name,
  tokens,
  recorded,
  setExpressionRecorded,
  isHidden,
  blockLabel = '',
  isValid = true,
}: ReferenceEditSelectProps) => {
  const { isRun, isPreviewMode } = useRunContext();
  const { expressionResult } = useExpression({ tokens, recorded });

  useEffect(() => {
    setExpressionRecorded && setExpressionRecorded(expressionResult);
  }, [expressionResult, setExpressionRecorded]);

  const displayText = useMemo(() => {
    return expressionResult.display ?? '';
  }, [expressionResult.display]);

  const evaluated = useMemo(() => {
    if (!expressionResult || isNil(expressionResult.value) || !(isRun || isPreviewMode)) {
      return <div className="text-gray-400">Pending</div>;
    }
    return expressionResult.value;
  }, [expressionResult, isRun, isPreviewMode]);

  return (
    <BlockProvider value={{ isValid }}>
      <tr>
        <td />
        <td colSpan={2}>
          <div className={generateHiddenClassString('', isHidden)}>
            <div className={generateHiddenClassString('mt-2 mr-8 flex items-start page-break', isHidden)}>
              <Spacer />
              <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={true} />
              {tokens && (
                <div className="flex flex-col">
                  <div className="flex flex-wrap flex-col items-start w-full my-1 gap-x-2">
                    <div className="flex flex-row items-start w-full py-1 gap-x-2">
                      <TextLinkify>
                        <div
                          style={{
                            flex: '2',
                            minHeight: '2.25rem',
                          }}
                          className="flex self-top max-w-max"
                        >
                          <div className="flex self-center">
                            <div>{name}</div>
                          </div>
                        </div>
                      </TextLinkify>
                      <div className="flex items-center h-9">
                        <span>=</span>
                      </div>
                      <TooltipOverlay
                        content={
                          <ReferenceDetail
                            tokens={tokens}
                            displayText={displayText}
                            references={recorded?.references}
                          />
                        }
                        delayClose={true}
                      >
                        <div className="group text-sm px-2 py-1.5 border-2 border-gray-400 rounded bg-gray-200 bg-opacity-50 whitespace-wrap">
                          {evaluated}
                        </div>
                      </TooltipOverlay>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </td>
      </tr>
    </BlockProvider>
  );
};

export default ExpressionBlock;

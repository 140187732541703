import { useSettings } from '../contexts/SettingsContext';
import userUtil from '../lib/userUtil';

interface UseUsersReturns {
  getUserDisplayText: (userId?: string) => string;
}

const useUsers = (): UseUsersReturns => {
  const { users } = useSettings();

  const getUserDisplayText = (userId?: string): string => {
    if (!userId) {
      return '';
    }
    const user = users && userUtil.getUserByEmailOrId(userId, users);
    return user?.email || userId;
  };

  return { getUserDisplayText };
};

export default useUsers;

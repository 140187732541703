import { Dialog } from 'primereact/dialog';

export interface PopupProps {
  isPopUpShown: boolean;
  children: React.ReactNode;
  onPopupClose: () => void;
  header?: string;
  draggable?: boolean;
  resizable?: boolean;
}

const Popup = ({
  isPopUpShown,
  children,
  onPopupClose,
  header = '',
  draggable = false,
  resizable = false,
}: PopupProps) => {
  return (
    <Dialog
      draggable={draggable}
      resizable={resizable}
      header={header}
      visible={isPopUpShown}
      onHide={onPopupClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      {children}
    </Dialog>
  );
};

export default Popup;

import {
  Actions,
  ContentMenuItem,
  addGroupings,
  Shortcuts,
} from './addContentTypes';

export interface GetStepAddContentOptions {
  hasStepHeader: boolean;
  canAddStep?: boolean;
  canAddSection?: boolean;
  canInsertSnippet?: boolean;
  canDeleteStep?: boolean;
  canCopyStep?: boolean;
  canCutStep?: boolean;
  canPasteStep?: boolean;
  canPasteBlock?: boolean;
  canSaveAsSnippet?: boolean;
  isPartKitStep?: boolean;
  isSnippet?: boolean;
}

// Required params are not in the default because the component that calls this component has to set them.
const defaultOptions: Omit<GetStepAddContentOptions, 'hasStepHeader'> = {
  canAddStep: true,
  canAddSection: false,
  canInsertSnippet: true,
  canDeleteStep: true,
  canCopyStep: true,
  canCutStep: true,
  canPasteStep: false,
  canPasteBlock: false,
  canSaveAsSnippet: true,
  isPartKitStep: false,
  isSnippet: false,
};

const getStepAddContentItems = (
  options: GetStepAddContentOptions
): Array<ContentMenuItem> => {
  const opts = {
    ...defaultOptions,
    ...options,
  };
  return addGroupings([
    {
      group: 'Add Content',
      action: Actions.AddStep,
      label: 'Add Step',
      icon: 'square',
      description: 'Steps are collections of blocks',
      hidden: !opts.canAddStep,
    },
    {
      group: 'Add Content',
      action: Actions.AddSection,
      label: 'Add Section',
      icon: 'square',
      description: 'Sections are collections of steps',
      hidden: !opts.canAddSection,
    },
    {
      group: 'Add Content',
      action: Actions.AddStepHeader,
      label: 'Add Step Header',
      icon: 'square',
      description: 'Add a header to the step',
      hidden: opts.hasStepHeader || opts.isSnippet,
    },
    {
      group: 'Add Content',
      action: Actions.InsertSnippet,
      label: 'Insert Snippet',
      icon: 'puzzle-piece',
      description: 'Insert a step snippet',
      hidden: !opts.canInsertSnippet,
    },
    {
      group: 'Actions',
      action: Actions.CopyStep,
      label: 'Copy Step',
      icon: 'clone',
      description: 'Copy this step to clipboard',
      hidden: !opts.canCopyStep,
      keyboardShortcut: Shortcuts.copy,
    },
    {
      group: 'Actions',
      action: Actions.CutStep,
      label: 'Cut Step',
      icon: 'scissors',
      description: 'Move this step to clipboard',
      hidden: !opts.canCutStep,
      keyboardShortcut: Shortcuts.cut,
    },
    {
      group: 'Actions',
      action: Actions.PasteStep,
      label: 'Paste Step',
      icon: 'clone',
      description: 'Paste step from clipboard',
      hidden: !opts.canPasteStep,
      keyboardShortcut: Shortcuts.paste,
    },
    {
      group: 'Actions',
      action: Actions.PasteBlock,
      label: 'Paste Block',
      icon: 'clone',
      description: 'Paste block from clipboard',
      hidden: !opts.canPasteBlock,
      keyboardShortcut: Shortcuts.paste,
    },
    {
      group: 'Actions',
      action: Actions.SaveAsSnippet,
      label: 'Save as Snippet',
      icon: 'puzzle-piece',
      description: 'Turn this step into a snippet',
      hidden: !opts.canSaveAsSnippet || opts.isSnippet,
    },
    {
      group: 'Actions',
      action: Actions.DeleteStep,
      label: 'Delete Step',
      icon: 'circle-xmark',
      description: 'Delete this step',
      hidden: !opts.canDeleteStep || opts.isPartKitStep,
      keyboardShortcut: Shortcuts.remove,
    },
    {
      group: 'Actions',
      action: Actions.DeleteStepHeader,
      label: 'Delete Step Header',
      icon: 'circle-xmark',
      description: 'Delete the step header',
      hidden: !opts.hasStepHeader || opts.isSnippet,
      keyboardShortcut: Shortcuts.remove,
    },
  ]);
};

export default getStepAddContentItems;

import { useEffect, useState } from 'react';
import { Activity } from 'shared/lib/types/postgres/util';
import { Run } from 'shared/lib/types/views/procedures';
import { DatabaseServices } from '../../../contexts/proceduresSlice';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import apm from '../../../lib/apm';
import { runViewPath } from '../../../lib/pathUtil';
import { Link } from 'react-router-dom';
import RunLabel from '../../RunLabel';

const REGEX_LINKED_RUN_STARTED = /^Linked Run (\w+) Started$/;

export const checkLinkedRunStarted = (action: string): boolean => {
  return REGEX_LINKED_RUN_STARTED.test(action);
};

export const linkedRunStartedAction = (activity: Activity) => checkLinkedRunStarted(activity.action);

interface LinkedRunStartedMessageProps {
  activity: Activity;
}

const LinkedRunStartedMessage = ({ activity }: LinkedRunStartedMessageProps) => {
  const [linkedRun, setLinkedRun] = useState<Run | null>();
  const { services, currentTeamId }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();

  useEffect(() => {
    if (linkedRun || linkedRun === null) {
      return;
    }
    const match = activity.action.match(REGEX_LINKED_RUN_STARTED);
    if (!match) {
      return;
    }
    const runId = match[1];
    services.runs
      .getRun(runId)
      .then((run) => setLinkedRun(run))
      .catch((err) => {
        setLinkedRun(null);
        apm.captureError(err);
      });
  }, [linkedRun, activity.action, services.runs]);

  const match = activity.action.match(REGEX_LINKED_RUN_STARTED);
  if (!match || !linkedRun) {
    return <div>Linked Run Started</div>;
  }
  const runId = match[1];
  const runLink = runViewPath(currentTeamId, runId);

  return (
    <div className="flex items-center flex-wrap">
      <span className="font-medium flex items-center">
        <span>Linked Run&nbsp;</span>
        <Link className="text-blue-600 hover:underline" to={runLink}>
          <RunLabel code={linkedRun.code} runNumber={linkedRun.run_number} />
        </Link>
        <span>&nbsp;Started</span>
      </span>
      <span>&nbsp;by&nbsp;</span>
      <span className="font-medium">{activity.user_id}</span>
    </div>
  );
};

export default LinkedRunStartedMessage;

import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { REVIEW_STICKY_HEADER_HEIGHT_REM } from '../ReviewStickyHeader';

const REMOVED_BACKGROUND_COLOR = 'bg-red-100';
const ADDED_BORDER_COLOR = 'border-green-600';
const DIFF_BORDER_COLOR = 'border-red-600';
const DIFF_TEXT_COLOR = 'text-gray-500';

interface DiffContainerContentsProps {
  diffIcon: IconProp;
  diffMessage: string;
  children: React.ReactNode;
  onScrollToDiffRefChanged?: (HTMLElement) => void;
  isRemoval?: boolean;
  width?: 'full' | 'fit';
  isTextSticky?: boolean;
  isAdded?: boolean;
}

const DiffContainerContents = ({
  diffIcon,
  diffMessage,
  children,
  onScrollToDiffRefChanged,
  isRemoval = false,
  width = 'full',
  isTextSticky = true,
  isAdded = false,
}: DiffContainerContentsProps) => {
  const diffWrapperColors = useMemo(() => {
    const background = isRemoval ? REMOVED_BACKGROUND_COLOR : '';
    return `${isAdded ? ADDED_BORDER_COLOR : DIFF_BORDER_COLOR} ${background}`;
  }, [isAdded, isRemoval]);

  const diffTextClass = useMemo(() => {
    const background = isRemoval ? REMOVED_BACKGROUND_COLOR : isTextSticky ? 'bg-gray-50' : '';
    return `${DIFF_TEXT_COLOR} ${background} italic text-xs`;
  }, [isRemoval, isTextSticky]);

  const widthClass = useMemo(() => (width === 'full' ? 'w-full' : 'w-fit'), [width]);

  return (
    <div
      ref={onScrollToDiffRefChanged}
      className={`py-1 mb-2 border-2 rounded shadow ${diffWrapperColors} ${widthClass}`}
      aria-label={diffMessage}
      style={{ scrollMarginTop: `${REVIEW_STICKY_HEADER_HEIGHT_REM}rem` }}
    >
      <div className="relative flex flex-col">
        {/*
          The `top` value needs to be hard-coded because tailwind classes cannot be interpolated. The value should be equal to REVIEW_STICKY_HEADER_HEIGHT_REM
          See https://tailwindcss.com/docs/content-configuration#dynamic-class-names
        */}
        <div
          className={`mx-2 mb-1 ${
            isTextSticky ? 'sticky z-10' : 'z-[9]'
          } top-[2.5rem] whitespace-nowrap ${diffTextClass}`}
        >
          <FontAwesomeIcon icon={diffIcon} />
          <span className="ml-2">{diffMessage}</span>
        </div>

        <div className="px-2">{children}</div>
      </div>
    </div>
  );
};

export default DiffContainerContents;

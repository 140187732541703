import { Dialog } from 'primereact/dialog';
import Button, { BUTTON_TYPES } from './Button';

const HEADER_TEXT = 'This draft has new changes.';
const BODY_TEXT = `You have made edits to this draft in another window. Refresh to get the latest changes and continue editing. Unsaved changes will not be incorporated.`;

type SelfEditConflictModalProps = {
  onHide: () => void;
};

const SelfEditConflictModal = ({ onHide }: SelfEditConflictModalProps) => {
  const onRefresh = () => window.location.reload();

  return (
    <Dialog
      className="w-1/2"
      header={HEADER_TEXT}
      visible={true}
      onHide={onHide}
      footer={<SelfEditConflictModalFooter onRefresh={onRefresh} />}
    >
      <div className="mt-4">{BODY_TEXT}</div>
    </Dialog>
  );
};

const SelfEditConflictModalFooter = ({ onRefresh }) => {
  return (
    <Button type={BUTTON_TYPES.PRIMARY} title="Refresh" ariaLabel="Refresh" onClick={onRefresh}>
      Refresh
    </Button>
  );
};

export default SelfEditConflictModal;

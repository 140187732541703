import { pick } from 'lodash';
import { getMatrixColumnName, getMatrixRowName } from '../../testing/libs/hazards';
import field from './field';

/**
 * Example table_input content object from the front end:
 * {
 *     "type": "table_input",
 *     "columns": [
 *         {
 *             "name": "Engine",
 *             "column_type": "text",
 *             "input_type": "text",
 *             "units": ""
 *         },
 *         {
 *             "name": "",
 *             "column_type": "input",
 *             "input_type": "checkbox",
 *             "units": ""
 *         }
 *     ],
 *     "rows": 3,
 *     "cells": [ [ "Engine 01", "" ], [ "Engine 02", "" ], [ "Engine 03", "" ] ],
 *     "id": "ct_9jgFmxtFAgEqJhE0jfKU",
 *     "recorded": {
 *         "values": [ [ "Engine 01", true ],  [ "Engine 02", "" ], [ "Engine 03", true ] ],
 *         "version": 1
 *     }
 * }
 *
 * Example input (single field) object from the front end:
 * {
 *     type: 'input',
 *     name: 'Thrust vector angle',
 *     inputType: 'number',
 *     units: 'radians',
 *     recorded: { value: 0.12 },
 * },
 */
const tableInput = {
  /**
   * getTableData() serves as an adapter to convert the `content` values for a table to `content` values that the
   * functions in field.js can understand.
   */
  getTableData: (content, includeFieldValues) => {
    // The `cellValue` key will be used for getting values of cells in a table
    const columnHeaderNames = content.columns.map((columnMetaData) => {
      return { cellValue: columnMetaData.name };
    });
    const rowIndexArray = [...Array(content.rows).keys()]; // Creates an array of all indices 0..(content.rows - 1)
    const table = rowIndexArray.map((rowIndex) => {
      const row = content.cells[rowIndex];
      const rowInputs = row.map((cellValue, columnIndex) => {
        const columnMetadata = content.columns[columnIndex];

        /*
         * Each cell in the table is a Field type.
         * The following code is an adapter from `table_input` content to `input` or `static` content
         */
        const singleCellContent = {
          name: `${columnMetadata.name}: row ${rowIndex + 1}, column ${columnIndex}`, // Add 1 since the header row is rowIndex 0
          inputType:
            columnMetadata.column_type === 'input' ? `input:${columnMetadata.input_type}` : columnMetadata.column_type,
          units: columnMetadata.units,
        };
        /*
         * Static fields within tables will appear in the downloaded CSV.
         * For non-input text, the `static` field will be set below.
         * For input (text, number, checkbox, etc.) the `recorded.value` field will be set below.
         */
        if (columnMetadata.column_type === 'comment') {
          singleCellContent.static = true;
          singleCellContent.value = content.cells[rowIndex][columnIndex].map((comment) =>
            pick(comment, ['text', 'timestamp', 'updated_at', 'user'])
          );
        } else if (columnMetadata.column_type === 'test_point') {
          singleCellContent.static = true;
          const cellValue = content.cells[rowIndex][columnIndex];
          singleCellContent.value = {
            name: cellValue.name,
            conditions: cellValue.test_case_conditions.map((condition) => {
              return `${condition.name}: ${condition.value}${
                condition.units !== undefined && condition.units !== '' ? ` ${condition.units}` : ''
              }`;
            }),
            ...(cellValue.risks && {
              hazards: cellValue.risks.map(
                (hazard) => `${hazard.name}: ${getMatrixRowName(hazard)} and ${getMatrixColumnName(hazard)}`
              ),
            }),
          };
        } else if (columnMetadata.column_type === 'signoff') {
          singleCellContent.alwaysShow = true;
          const cellValue = tableInput._hasRecordedValues(content)
            ? content.recorded.values[rowIndex][columnIndex]
            : content.cells[rowIndex][columnIndex];

          singleCellContent.value = cellValue.map((signoff) => pick(signoff, ['operators', 'actions']));
        } else if (columnMetadata.column_type !== 'input') {
          // For static fields
          singleCellContent.static = true;
          singleCellContent.value = content.cells[rowIndex][columnIndex];
        } else if (tableInput._hasRecordedValues(content)) {
          // For input fields
          singleCellContent.recorded = { value: content.recorded.values[rowIndex][columnIndex] };
        }

        singleCellContent.cellValue = field.getValue(singleCellContent, includeFieldValues);

        return singleCellContent;
      });

      return rowInputs;
    });

    return [columnHeaderNames, ...table];
  },

  getTableInputTypes: (tableData) => {
    return tableData[1] // Use index 1 since index 0 contains the table headers
      .map((cellData) => field.getInputType(cellData));
  },

  getTableInputUnits: (tableData) => {
    return tableData[1] // Use index 1 since index 0 contains the table headers
      .map((cellData) => field.getUnits(cellData));
  },

  _hasRecordedValues: (content) => {
    return content.recorded && content.recorded.values;
  },
};

export default tableInput;

import * as math from 'mathjs';
import { TelemetryModel } from './types/postgres/telemetry';

const telemetryExpressionUtil = {
  getExpressionErrorMessage: (expression: string) => {
    try {
      math.parse(expression);
      return '';
    } catch (e) {
      return (e as Error).message;
    }
  },

  validateExpression: (expression: string) => {
    return telemetryExpressionUtil.getExpressionErrorMessage(expression) === '';
  },

  /*
   * In order to support variables with dots in the names, we need to convert these values
   * into nested objects.
   * If we have these variables:
   * 'sim.node1.float' = 0.555
   * 'sim.node1.int' = 2
   * 'sim.node1.string' = 'taco'
   *  We need to create an object like this for math.js to reference things correctly:
   *  {
   *    sim: {
   *      node1: {
   *        float: 0.555,
   *        int: 2,
   *        string: 'taco'
   *      }
   *    }
   *  }
   *
   * @param context the object to build nested structure into
   * @param key the remainder of the current key to build out the tree for
   * @param value the value to ultimately assign to the key
   */
  _buildVarScope: (context, key, value) => {
    const parts = key.split('.');
    const head = parts.shift();
    if (parts.length === 0) {
      context[key] = value;
    } else {
      if (typeof context[head] !== 'object') {
        context[head] = {};
      }
      telemetryExpressionUtil._buildVarScope(
        context[head],
        parts.join('.'),
        value
      );
    }
  },

  /**
   * Evaluates an expression
   *
   * expression: String, an expression to evaluate
   * variables: Object containing a map of variable names to values
   * returns: The result of the expression, null if invalid
   */
  evalExpression: (
    expression: string,
    variables = {}
  ): string | boolean | number | null => {
    const scope = {};
    for (const v in variables) {
      telemetryExpressionUtil._buildVarScope(scope, v, variables[v]);
    }
    if (telemetryExpressionUtil.validateExpression(expression)) {
      return math.evaluate(expression, scope) as
        | string
        | boolean
        | number
        | null;
    }
    return null;
  },

  getVariablesFromExpression: (expression: string) => {
    const parsed = math.parse(expression);
    const variables = parsed.filter(
      math.isSymbolNode
    ) as Array<math.SymbolNode>;
    return [...new Set(variables.map((v) => v.name))];
  },

  /**
   * Extracts a list of which parameters are used in an expression.
   * This is a basic approach, it could go wrong if someone names their variable "sin" etc
   * In the future: make getVariablesFromExpression support dot syntax and replace this
   *
   * @param expression The expression string.
   * @param parameterCandidates A list of known possible variables.
   */
  extractTelemetryParameterNamesFromExpression: (
    expression: string,
    parameterCandidates: Array<TelemetryModel>
  ) => {
    return parameterCandidates.filter((variable) =>
      expression.includes(variable.name ?? '')
    );
  },

  /**
   * Transforms an array of telemetry recorded values (as returned by getLatestSample)
   * into a map of parameter name to value (as expected by evalExpression).
   */
  getVariableMapFromTelemetryRecordedValues: (recordedValues) => {
    const map = {};
    for (const recorded of recordedValues) {
      if (!recorded) continue;
      map[recorded.name] = recorded.value;
    }
    return map;
  },
};

export default telemetryExpressionUtil;

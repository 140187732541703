import { AssessmentMatrixMetadata } from './types/testing';

// Must leave these on "risk" so they align.  A data migration is required to change this
export const HAZARD_PREFIX = 'risk';

export const DEFAULT_ASSESSMENT_MATRIX_METADATA: AssessmentMatrixMetadata = {
  id: 'default',
  row_labels: [
    { id: `${HAZARD_PREFIX}_row_default4`, name: 'Catastrophic' },
    { id: `${HAZARD_PREFIX}_row_default3`, name: 'Hazardous' },
    { id: `${HAZARD_PREFIX}_row_default2`, name: 'Major' },
    { id: `${HAZARD_PREFIX}_row_default1`, name: 'Minor' },
    { id: `${HAZARD_PREFIX}_row_default0`, name: 'No Safety Effect' },
  ],
  row_level_order: 'desc',
  column_labels: [
    { id: `${HAZARD_PREFIX}_column_default4`, name: 'Frequent' },
    { id: `${HAZARD_PREFIX}_column_default3`, name: 'Probable' },
    { id: `${HAZARD_PREFIX}_column_default2`, name: 'Occasional' },
    { id: `${HAZARD_PREFIX}_column_default1`, name: 'Remote' },
    { id: `${HAZARD_PREFIX}_column_default0`, name: 'Improbable' },
  ],
  column_level_order: 'desc',
  row_category: 'Severity',
  column_category: 'Probability',
  assessment_matrix: [
    [5, 3, 3, 1, 0],
    [5, 3, 1, 1, 0],
    [3, 3, 1, 1, 0],
    [1, 1, 1, 0, 0],
    [0, 0, 0, 0, 0],
  ],
};

import React, { useMemo } from 'react';
import { Field } from 'formik';
import FieldSetMultiSelectWithIcon from './FieldSetMultiSelectWithIcon';
import { useSettings } from '../contexts/SettingsContext';
import { Icon } from '@fortawesome/fontawesome-svg-core';

/**
 * Renders a multi-select input with a list of operators.
 */
interface FieldSetOperatorSelectProps {
  name: string;
  values: Array<string>;
  icon: Icon;
  isDisabled?: boolean;
}

const FieldSetOperatorSelect = React.memo(({ name, values, icon, isDisabled = false }: FieldSetOperatorSelectProps) => {
  const { operatorRoles } = useSettings();
  const labelOptions = useMemo(() => {
    if (!operatorRoles || !operatorRoles.operators) {
      return [];
    }

    return operatorRoles.operators.map((op) => ({
      value: op.code,
      label: op.code,
    }));
  }, [operatorRoles]);

  const selectValue = useMemo(() => {
    // If its values is not an array, return an empty array.
    if (!Array.isArray(values)) {
      return [];
    }

    return values.map((value) => {
      return {
        value,
        label: value,
      };
    });
  }, [values]);

  return (
    <Field
      name={name}
      value={selectValue}
      component={FieldSetMultiSelectWithIcon}
      options={labelOptions}
      icon={icon}
      disabled={isDisabled}
    />
  );
});

export default FieldSetOperatorSelect;

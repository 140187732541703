import { useCallback } from 'react';
import { useMixpanel } from '../../contexts/MixpanelContext';

const MANUFACTURING_TRACKING_NAME = 'Manufacturing';

type ManufacturingTrack = (name: string, properties?: object) => void;

const useManufacturingTrack = (): ManufacturingTrack => {
  const { mixpanel } = useMixpanel();

  const manufacturingTrack = useCallback(
    (name: string, properties?: object) => {
      if (mixpanel) {
        mixpanel.track(name, {
          Module: MANUFACTURING_TRACKING_NAME,
          ...properties,
        });
      }
    },
    [mixpanel]
  );

  return manufacturingTrack;
};

export default useManufacturingTrack;

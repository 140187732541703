import FieldSetStepSnippet from './FieldSetStepSnippet';
import FieldSetSectionSnippet from './FieldSetSectionSnippet';
import { Snippet } from '../../lib/views/settings';

interface FieldSetSnippetProps {
  snippet: Snippet | null;
  onClose?: () => void;
  onRemove?: (id: string) => Promise<void>;
  onSaveSuccess: () => void;
  isDuplicate?: boolean;
  testingModule?: boolean;
}

const FieldSetSnippet = ({
  snippet,
  onClose,
  onRemove,
  onSaveSuccess,
  isDuplicate,
  testingModule,
}: FieldSetSnippetProps) => {
  return (
    <>
      <div>
        {/* Edit Snippet Section */}
        {snippet?.snippet_type === 'section' && (
          <FieldSetSectionSnippet
            snippet={snippet}
            onClose={onClose}
            onRemove={onRemove}
            onSaveSuccess={onSaveSuccess}
            isDuplicate={isDuplicate}
            testingModule={testingModule}
          />
        )}

        {/* Edit Snippet Step */}
        {snippet?.snippet_type === 'step' && (
          <FieldSetStepSnippet
            snippet={snippet}
            onClose={onClose}
            onRemove={onRemove}
            onSaveSuccess={onSaveSuccess}
            isDuplicate={isDuplicate}
            testingModule={testingModule}
          />
        )}
      </div>
    </>
  );
};

export default FieldSetSnippet;

import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useMixpanel } from '../../contexts/MixpanelContext';
import useBlockRedlines from '../../hooks/useBlockRedlines';
import useBlockComponents from '../../hooks/useBlockComponents';
import SuggestedEditsModal from '../SuggestedEditsModal';

/*
 * Component for rendering a procedure block when running or viewing a procedure.
 * Supports showing redline history changes.
 *
 * block: A Block object to render.
 * redlines: A list of RedlineBlock objects with revision history.
 * recorded: An arbitrary object with recorded values for the given Block.
 * contentIndex: The current contentIndex of this Block in the parent Step.
 *               In the future, this could be refactored out.
 * isEnabled: True if recording new Block values should be enabled.
 * isHidden: True to hide all block content (expand/collapse)
 * onRecordValuesChanged: Callback for when user enters new recorded values.
 *                        Type fn(recorded), where recorded is any arbitrary object.
 * onRecordErrorsChanged: Callback when there are errors in user-submitted recorded
 *                        values. Type fn(errors), where errors is a dictionary
 *                        of type { fieldName: String error }
 * onRecordUploadingChanged: Callback when user recorded values are uploading. Type
 *                           fn(isUploading), where isUploading is a boolean that is
 *                           true if the content block is currently uploading data.
 * actions: A list of Action objects to render within the procedure block.
 */
const ProcedureBlockRun = ({
  blockLabel,
  block,
  redlines,
  recorded,
  contentIndex,
  isEnabled,
  isHidden,
  onRecordValuesChanged,
  onRecordErrorsChanged,
  onRecordUploadingChanged,
  actions,
  isSpacerHidden,
  isDark,
  onContentRefChanged,
  scrollMarginTopValueRem,
  onAcceptPendingRedline,
  setExpressionRecorded,
}) => {
  const { mixpanel } = useMixpanel();
  const [showsRedlineModal, setShowsRedlineModal] = useState(false);
  const { latestApprovedBlock, latestRedline, hasRedlines, allRedlineUserIds, hasPendingRedlines } = useBlockRedlines({
    block,
    redlines,
  });

  const acceptRedline = useCallback(() => {
    if (!onAcceptPendingRedline) {
      return Promise.resolve();
    }
    return onAcceptPendingRedline(latestRedline.redlineIndex);
  }, [latestRedline, onAcceptPendingRedline]);

  const acceptRedlineEnabled = useMemo(
    () => Boolean(hasPendingRedlines && onAcceptPendingRedline),
    [hasPendingRedlines, onAcceptPendingRedline]
  );

  const { TypedProcedureBlock, TypedBlock } = useBlockComponents({ blockType: block.type });

  const recordValueChangedHandler = useCallback(
    (recorded) => {
      onRecordValuesChanged && onRecordValuesChanged(block.id, recorded);
    },
    [block.id, onRecordValuesChanged]
  );

  const recordErrorsChangedHandler = useCallback(
    (errors) => {
      onRecordErrorsChanged && onRecordErrorsChanged(contentIndex, errors);
    },
    [contentIndex, onRecordErrorsChanged]
  );

  const recordUploadingChangedHandler = useCallback(
    (isUploading) => {
      onRecordUploadingChanged(contentIndex, isUploading);
    },
    [contentIndex, onRecordUploadingChanged]
  );

  const showRedlineModal = useCallback(() => {
    if (mixpanel) {
      mixpanel.track('Redline Viewed', { 'Block Type': block.type });
    }
    setShowsRedlineModal(true);
  }, [block.type, mixpanel]);

  const extendedActions = useMemo(() => {
    const showRedlineModalAction = {
      icon: 'strikethrough',
      iconType: latestRedline?.run_only ? 'primary' : 'caution',
      onAction: showRedlineModal,
      ariaLabel: `Suggested Edits (${latestRedline?.run_only ? 'Blueline' : 'Redline'})`,
      pendingAction: hasPendingRedlines,
      pendingDotMatchesIcon: true,
    };
    return hasRedlines ? [showRedlineModalAction, ...actions] : actions;
  }, [latestRedline, showRedlineModal, hasPendingRedlines, hasRedlines, actions]);

  return (
    <Fragment>
      {/* Redline changes modal */}
      {showsRedlineModal && (
        <SuggestedEditsModal
          hideModal={() => setShowsRedlineModal(false)}
          allRedlineUserIds={allRedlineUserIds}
          latestTimestamp={latestRedline.createdAt}
          acceptAction={acceptRedlineEnabled ? acceptRedline : undefined}
          redlinePending={hasPendingRedlines}
          isBlueline={latestRedline.run_only}
        >
          <TypedBlock
            block={block}
            redlined={latestRedline} // TODO: remove redlined when older code no longer needs to use it.
            redlinedBlock={latestRedline.block}
            isEnabled={false}
          />
        </SuggestedEditsModal>
      )}

      {/* Render specific block content */}
      <TypedProcedureBlock
        blockLabel={blockLabel}
        isHidden={isHidden}
        actions={extendedActions}
        isSpacerHidden={isSpacerHidden}
        hasExtraVerticalSpacing={isEnabled && block.inputType === 'checkbox'}
      >
        <TypedBlock
          block={latestApprovedBlock}
          blockId={block.id}
          recorded={recorded}
          isEnabled={isEnabled}
          isDark={isDark}
          onRecordValuesChanged={recordValueChangedHandler}
          onRecordErrorsChanged={recordErrorsChangedHandler}
          onRecordUploadingChanged={recordUploadingChangedHandler}
          onContentRefChanged={onContentRefChanged}
          scrollMarginTopValueRem={scrollMarginTopValueRem}
          setExpressionRecorded={setExpressionRecorded}
        />
      </TypedProcedureBlock>
    </Fragment>
  );
};

export default ProcedureBlockRun;

interface TruncateLeftProps {
  children: JSX.Element | string;
  textAlign?: 'left' | 'center' | 'right';
}

const TruncateLeft = ({ children, textAlign }: TruncateLeftProps) => {
  const alignment = textAlign ? `text-${textAlign}` : 'text-left';
  return (
    <div className={`truncate ${alignment}`} style={{ direction: 'rtl' }}>
      &lrm;{children}
    </div>
  );
};

export default TruncateLeft;

import React from 'react';

interface NoValueRecordedProps {
  text?: string;
}

const NoValueRecorded = React.memo(({ text }: NoValueRecordedProps) => {
  return (
    <div className="mt-0.5 px-2 py-1.5 h-fit text-sm text-gray-400 italic rounded bg-gray-100 whitespace-nowrap">
      {text || 'Pending'}
    </div>
  );
});

export default NoValueRecorded;

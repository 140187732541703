import React, { FC, SVGProps, useCallback, useMemo } from 'react';
import { cloneDeep, isEmpty } from 'lodash';
import stepConditionals, { CONDITIONAL_TYPE } from 'shared/lib/stepConditionals';
import { useRunContext } from '../../contexts/RunContext';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import ConditionalStateTag from '../StepConditionals/ConditionalStateTag';
import useProcedureAdapter from '../../hooks/useProcedureAdapter';
import DiffContainer from '../Diff/DiffContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import { Dependency, WithDiffChange } from 'shared/lib/types/views/procedures';
import { ConditionalDependency } from '../StepDependency/DisplayDependencies';

interface ReviewDependenciesProps {
  dependencies?: Array<Dependency>;
  blockId: string;
  SvgIcon?: FC<SVGProps<SVGSVGElement>>; // if provided, replaces default leading icon
}

const ReviewDependencies = ({ dependencies, blockId, SvgIcon }: ReviewDependenciesProps) => {
  const { getContentBlock, getSectionAndStepIdPair } = useRunContext();
  const { scrollTo } = useProcedureContext();

  const { stepIdsToLabelsMap, sourceStepConditionalsMap, removedSourceStepConditionalsMap } = useProcedureAdapter();

  const onLabelClick = useCallback(
    (dependentId) => {
      const { sectionId, stepId } = getSectionAndStepIdPair(dependentId);

      scrollTo({
        sectionId,
        stepId,
      });
    },
    [getSectionAndStepIdPair, scrollTo]
  );

  const sourceName = useCallback(
    (conditional) => {
      if (!conditional) {
        return '';
      }
      if (conditional.source_type === CONDITIONAL_TYPE.STEP) {
        return '';
      } else if (
        conditional.source_type === CONDITIONAL_TYPE.CONTENT ||
        conditional.source_type === CONDITIONAL_TYPE.CONTENT_BINARY ||
        conditional.source_type === CONDITIONAL_TYPE.CONTENT_TERNARY
      ) {
        const contentBlock = getContentBlock(conditional.source_id, conditional.content_id);
        return contentBlock ? sharedDiffUtil.getDiffValue(contentBlock, 'name', 'new') : '';
      }
      return '';
    },
    [getContentBlock]
  );

  const getDependentIdForVersion = (dependentId, version) => {
    const id = sharedDiffUtil.getDiffValue({ value: dependentId }, 'value', version);
    return typeof id === 'string' ? id : '';
  };

  const isDependentIdRemoved = useCallback(
    (id, dependency) =>
      (dependency && dependency.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED) ||
      (!isEmpty(getDependentIdForVersion(id, 'old')) && isEmpty(getDependentIdForVersion(id, 'new'))),
    []
  );

  const isDependentIdAdded = useCallback(
    (id, dependency) =>
      (dependency && dependency.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED) ||
      (!isEmpty(getDependentIdForVersion(id, 'new')) && isEmpty(getDependentIdForVersion(id, 'old'))),
    []
  );

  // Validate which dependencies are still in the doc
  const validDependencies = useMemo(() => {
    // Compute step conditional dependencies
    const conditionals = sourceStepConditionalsMap?.[blockId]
      ? [...Object.values(sourceStepConditionalsMap[blockId])]
      : [];
    const oldConditionals =
      removedSourceStepConditionalsMap && removedSourceStepConditionalsMap?.[blockId]
        ? [...Object.values(removedSourceStepConditionalsMap[blockId])]
        : [];
    const conditionalDiffChangeState = stepConditionals.getDiffChangeStateForConditionals(blockId, [
      ...conditionals,
      ...oldConditionals,
    ]);
    const filteredConditionals = conditionals.filter(
      (conditional) => !conditional.diff_change_state || conditional.diff_change_state !== ARRAY_CHANGE_SYMBOLS.REMOVED
    );
    const conditionalDependency = {
      id: 'step_conditionals',
      dependent_ids:
        conditionalDiffChangeState === ARRAY_CHANGE_SYMBOLS.REMOVED
          ? [...conditionals, ...oldConditionals].map((conditional) => conditional.source_id)
          : filteredConditionals.map((conditional) => conditional.source_id),
      dependent_conditionals: filteredConditionals,
      diff_change_state: conditionalDiffChangeState,
    };

    // Remove step dependencies already covered by conditionals
    const deps: Array<WithDiffChange<Dependency> | ConditionalDependency> = cloneDeep(dependencies || []);
    let i = deps.length;
    while (i--) {
      const dependency = deps[i];
      // @ts-ignore
      dependency.dependent_ids = dependency.dependent_ids
        .filter((id) => {
          const diffValue = sharedDiffUtil.getDiffValue({ id }, 'id', 'new');
          const idDiffValue = typeof diffValue === 'string' && diffValue ? diffValue : '';
          if (
            conditionalDependency.dependent_ids.includes(idDiffValue) &&
            conditionalDependency.diff_change_state !== ARRAY_CHANGE_SYMBOLS.ADDED &&
            conditionalDependency.diff_change_state !== ARRAY_CHANGE_SYMBOLS.REMOVED &&
            (isDependentIdRemoved(id, dependency) || isDependentIdAdded(id, dependency))
          ) {
            conditionalDependency.diff_change_state = ARRAY_CHANGE_SYMBOLS.MODIFIED;
          }
          return (
            !isEmpty(idDiffValue) &&
            stepIdsToLabelsMap[idDiffValue] &&
            !conditionalDependency.dependent_ids.includes(idDiffValue)
          );
        })
        .map((id) => sharedDiffUtil.getDiffValue({ id }, 'id', 'new'));
      if (dependency.dependent_ids.length === 0) {
        deps.splice(i, 1);
      }
    }

    if (conditionalDependency.dependent_ids.length > 0) {
      deps.push(conditionalDependency);
    }

    return deps;
  }, [
    dependencies,
    sourceStepConditionalsMap,
    removedSourceStepConditionalsMap,
    stepIdsToLabelsMap,
    blockId,
    isDependentIdAdded,
    isDependentIdRemoved,
  ]);

  const validDependenciesToDisplay = useMemo(
    () =>
      validDependencies.filter(
        (dependency) => !dependency.diff_change_state || dependency.diff_change_state !== ARRAY_CHANGE_SYMBOLS.REMOVED
      ),
    [validDependencies]
  );

  const areAllDependentIdsRemoved = (dependency) => {
    return (
      dependency.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED ||
      dependency.dependent_ids.filter((id) => isDependentIdRemoved(id, dependency)).length ===
        dependency.dependent_ids.length
    );
  };

  const areAllDependentIdsAdded = (dependency) => {
    return (
      dependency.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED ||
      dependency.dependent_ids.filter((id) => isDependentIdAdded(id, dependency)).length ===
        dependency.dependent_ids.length
    );
  };

  /*
   * Because there's always at least 1 array entry for dependencies, we can't just rely on the overall diff_change_state
   * for each dependency to know if all dependencies have been added or removed. We need to check the dependent ids of
   * each dependency individually.
   */
  const getDiffContainerDiffChangeState = () => {
    if (
      validDependencies.length > 0 &&
      validDependencies.filter((dependency) => areAllDependentIdsAdded(dependency)).length === validDependencies.length
    ) {
      return ARRAY_CHANGE_SYMBOLS.ADDED;
    }
    if (
      validDependencies.length > 0 &&
      validDependencies.filter((dependency) => areAllDependentIdsRemoved(dependency)).length ===
        validDependencies.length
    ) {
      return ARRAY_CHANGE_SYMBOLS.REMOVED;
    }
    if (
      validDependencies.some(
        (dependency) =>
          (dependency.diff_change_state &&
            (dependency.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED ||
              dependency.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED ||
              dependency.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED)) ||
          dependency.dependent_ids.some(
            (id) => isDependentIdAdded(id, dependency) || isDependentIdRemoved(id, dependency)
          )
      )
    ) {
      return ARRAY_CHANGE_SYMBOLS.MODIFIED;
    }
    return ARRAY_CHANGE_SYMBOLS.UNCHANGED;
  };

  const showConditionalDependency = (dependency: Dependency | ConditionalDependency) =>
    'dependent_conditionals' in dependency &&
    dependency.dependent_conditionals &&
    dependency.dependent_conditionals.length > 0;

  if (validDependencies.length === 0 && getDiffContainerDiffChangeState() !== ARRAY_CHANGE_SYMBOLS.REMOVED) {
    return null;
  }

  return (
    <DiffContainer label="Dependency" diffChangeState={getDiffContainerDiffChangeState()} isTextSticky={false}>
      {getDiffContainerDiffChangeState() === ARRAY_CHANGE_SYMBOLS.REMOVED && (
        <div className="flex">
          <FontAwesomeIcon className="text-gray-500 self-center mr-1" icon="info-circle" />
          <div className="flex flex-row gap-x-1 py-1">No longer valid</div>
        </div>
      )}
      {getDiffContainerDiffChangeState() !== ARRAY_CHANGE_SYMBOLS.REMOVED && (
        <div className="flex">
          {SvgIcon && <SvgIcon className="w-[14px] text-gray-500 self-center mr-1" />}
          {!SvgIcon && <FontAwesomeIcon className="text-gray-500 self-center mr-1" icon="info-circle" />}
          <div className="flex flex-row gap-x-1 py-1">
            <div>Requires</div>
            <div className="flex flex-row">
              {validDependenciesToDisplay.map((dependency, dependencyIndex) => {
                return (
                  <div key={dependency.id} className="flex flex-row">
                    {dependency.dependent_ids.map((dependentId, dependentIdIndex) => {
                      return (
                        <div key={`${dependentId}${dependentIdIndex}`} className="flex flex-row gap-x-1 items-center">
                          <button
                            className="link"
                            onClick={() => onLabelClick(dependentId)}
                            disabled={typeof scrollTo !== 'function'} // Disables for viewing in edit procedure
                            aria-label="Go to Dependency"
                          >
                            {stepIdsToLabelsMap[dependentId]}
                          </button>

                          {/* Show required state for conditional dependencies*/}
                          {showConditionalDependency(dependency) && (
                            <>
                              <div>
                                {sourceName(
                                  (dependency as ConditionalDependency).dependent_conditionals[dependentIdIndex]
                                )}
                              </div>
                              <div className="font-normal">
                                <ConditionalStateTag
                                  conditional={
                                    (dependency as ConditionalDependency).dependent_conditionals[dependentIdIndex]
                                  }
                                  size="sm"
                                  conditionalStepId={dependentId}
                                />
                              </div>
                            </>
                          )}

                          {/* Show OR (/) */}
                          {dependentIdIndex !== dependency.dependent_ids.length - 1 && <div className="mx-1">/</div>}
                        </div>
                      );
                    })}

                    {/* Show AND (&) */}
                    {dependencyIndex !== validDependenciesToDisplay.length - 1 && <div className="mx-1">&</div>}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </DiffContainer>
  );
};

export default React.memo(ReviewDependencies);

import React from 'react';
import PropTypes from 'prop-types';
import RepeatYearly from './Yearly/index';
import RepeatMonthly from './Monthly/index';
import RepeatWeekly from './Weekly/index';
import RepeatDaily from './Daily/index';
import RepeatHourly from './Hourly/index';

const Repeat = ({ id, repeat: { frequency, yearly, monthly, weekly, daily, hourly, options }, handleChange }) => {
  const isOptionAvailable = (option) => !options.frequency || options.frequency.indexOf(option) !== -1;
  const isOptionSelected = (option) => frequency === option;

  return (
    <div className="flex flex-col space-y-1 px-3 text-sm">
      <label htmlFor={`${id}-frequency`} className="col-form-label">
        <label className="text-xs text-gray-500">Repeat</label>
      </label>
      <div className="col-sm-6">
        <select
          name="repeat.frequency"
          id={`${id}-frequency`}
          className="rounded text-sm border-gray-300"
          value={frequency}
          onChange={handleChange}
        >
          {isOptionAvailable('Yearly') && <option value="Yearly">Yearly</option>}
          {isOptionAvailable('Monthly') && <option value="Monthly">Monthly</option>}
          {isOptionAvailable('Weekly') && <option value="Weekly">Weekly</option>}
          {isOptionAvailable('Daily') && <option value="Daily">Daily</option>}
          {isOptionAvailable('Hourly') && <option value="Hourly">Hourly</option>}
          {isOptionAvailable('None') && <option value="None">None</option>}
        </select>
      </div>

      {isOptionSelected('Yearly') && <RepeatYearly id={`${id}-yearly`} yearly={yearly} handleChange={handleChange} />}
      {isOptionSelected('Monthly') && (
        <RepeatMonthly id={`${id}-monthly`} monthly={monthly} handleChange={handleChange} />
      )}
      {isOptionSelected('Weekly') && <RepeatWeekly id={`${id}-weekly`} weekly={weekly} handleChange={handleChange} />}
      {isOptionSelected('Daily') && <RepeatDaily id={`${id}-daily`} daily={daily} handleChange={handleChange} />}
      {isOptionSelected('Hourly') && <RepeatHourly id={`${id}-hourly`} hourly={hourly} handleChange={handleChange} />}
    </div>
  );
};

Repeat.propTypes = {
  id: PropTypes.string.isRequired,
  repeat: PropTypes.shape({
    frequency: PropTypes.oneOf(['Yearly', 'Monthly', 'Weekly', 'Daily', 'Hourly', 'None']).isRequired,
    yearly: PropTypes.object.isRequired,
    monthly: PropTypes.object.isRequired,
    weekly: PropTypes.object.isRequired,
    daily: PropTypes.object.isRequired,
    hourly: PropTypes.object.isRequired,
    options: PropTypes.shape({
      frequency: PropTypes.arrayOf(PropTypes.oneOf(['Yearly', 'Monthly', 'Weekly', 'Daily', 'Hourly', 'None'])),
      yearly: PropTypes.oneOf(['on', 'on the']),
      monthly: PropTypes.oneOf(['on', 'on the']),
    }).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Repeat;

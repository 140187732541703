import React, { useMemo } from 'react';
import Tooltip from '../elements/Tooltip';
import { MAX_LENGTH, MAX_LENGTH_MOBILE } from '../lib/tableTextUtil';
import ProcedureFieldDiff from './ProcedureFieldDiff';
import { isNil } from 'lodash';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ICON_COLOR_CLASSES = {
  red: 'text-red-600',
  blue: 'text-blue-600',
};

export type LabelSize = 'xs' | 'sm';

interface LabelProps {
  oldText?: string;
  text: string;
  onClick?: () => void;
  clickable?: boolean;
  color?: string;
  onScrollToDiffRefChanged?: (HTMLElement) => void;
  icon?: IconProp;
  iconColor?: 'red' | 'blue';
  size?: LabelSize;
  enableTruncate?: boolean;
  scrollToBufferRem?: number;
  verticalPadding?: boolean;
  maxLength?: number;
}

const Label = ({
  oldText,
  text,
  onClick,
  clickable,
  color,
  onScrollToDiffRefChanged,
  icon,
  iconColor,
  size = 'xs',
  enableTruncate = true,
  scrollToBufferRem = 0,
  verticalPadding = true,
  maxLength = MAX_LENGTH.sm,
}: LabelProps) => {
  const isChanged = useMemo(() => !isNil(oldText) && text !== oldText, [oldText, text]);

  const labelColor = useMemo(() => {
    return color ?? 'bg-blue-200';
  }, [color]);

  const iconColorClass = useMemo(
    () => (iconColor && ICON_COLOR_CLASSES[iconColor] ? ICON_COLOR_CLASSES[iconColor] : ''),
    [iconColor]
  );

  const labelCursor = useMemo(() => {
    return clickable || onClick ? 'cursor-pointer' : '';
  }, [clickable, onClick]);

  const truncate = useMemo(() => {
    // Do not truncate for diffs.
    return enableTruncate && !isChanged && text.length > maxLength;
  }, [enableTruncate, isChanged, maxLength, text.length]);

  const truncateMobile = useMemo(() => {
    // Do not truncate for diffs.
    return enableTruncate && !isChanged && text.length > MAX_LENGTH_MOBILE.sm;
  }, [enableTruncate, isChanged, text.length]);

  const displayText = useMemo(() => {
    return truncate ? `${text.slice(0, maxLength - 1)}...` : text;
  }, [truncate, text, maxLength]);

  const displayTextOld = useMemo(() => {
    if (isNil(oldText)) {
      return displayText;
    }
    return truncate ? `${oldText.slice(0, maxLength - 1)}...` : oldText;
  }, [oldText, truncate, maxLength, displayText]);

  const displayTextMobile = useMemo(() => {
    return truncateMobile ? `${text.slice(0, MAX_LENGTH_MOBILE.sm - 1)}...` : text;
  }, [truncateMobile, text]);

  const displayTextMobileOld = useMemo(() => {
    if (isNil(oldText)) {
      return displayTextMobile;
    }
    return truncateMobile ? `${oldText.slice(0, MAX_LENGTH_MOBILE.sm - 1)}...` : oldText;
  }, [oldText, truncateMobile, displayTextMobile]);

  const textSize = useMemo(() => {
    if (size === 'sm') {
      return 'text-sm';
    }

    return 'text-xs';
  }, [size]);

  return (
    <>
      <Tooltip content={text} visible={Boolean(truncate)}>
        <div
          className={`hidden lg:inline-block font-medium text-gray-600 max-w-max ${textSize} ${
            size === 'sm' ? 'px-2' : 'px-1.5'
          } ${verticalPadding ? 'py-0.5' : ''} group rounded-xl ${labelCursor} ${labelColor}`}
          onClick={onClick}
          aria-label={text}
          ref={onScrollToDiffRefChanged}
          style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
        >
          {icon && (
            <span className="mr-1">
              <FontAwesomeIcon icon={icon} className={iconColorClass} />
            </span>
          )}
          <ProcedureFieldDiff original={displayTextOld} redlined={displayText} wrapPlainText={Boolean(truncate)} />
        </div>
      </Tooltip>

      <Tooltip content={text} visible={Boolean(truncateMobile)}>
        <div
          className={`lg:hidden font-medium text-gray-600 text-xs px-1.5 py-0.5 group rounded-xl max-w-max ${labelCursor} ${labelColor}`}
          onClick={onClick}
          aria-label={text}
          ref={onScrollToDiffRefChanged}
          style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
        >
          {icon && (
            <span className="mr-1">
              <FontAwesomeIcon icon={icon} className={iconColorClass} />
            </span>
          )}
          <ProcedureFieldDiff
            original={displayTextMobileOld}
            redlined={displayTextMobile}
            wrapPlainText={Boolean(truncate)}
          />
        </div>
      </Tooltip>
    </>
  );
};

export default Label;

import ItemSelect from '../ItemSelect';
import { getUsageTypeLabel, isPartRestricted } from '../../lib/parts';
import SubstepNumber from '../../../components/SubstepNumber';
import { generateHiddenClassString } from '../../../lib/styles';
import DiffContainer from '../../../components/Diff/DiffContainer';
import sharedDiffUtil from 'shared/lib/diffUtil';
import PartAndUsageTypeSelect from '../PartAndUsageTypeSelect';
import ReviewPartLabel from './ReviewPartLabel';
import useParts from '../../hooks/useParts';
import RestrictedInfo, { RESTRICTED_TEXT } from '../RestrictedInfo';

const ReviewPartUsage = ({ content, blockLabel, teamId, isHidden }) => {
  const { getPart } = useParts();
  const part = content.part ? getPart(sharedDiffUtil.getDiffValue(content.part, 'id', 'new')) : undefined;
  const partRestricted = content.part === null ? false : isPartRestricted(part);

  let usageTypeSelected;
  if (content && content.usage_types && content.usage_types.length > 0) {
    usageTypeSelected = content.usage_types[0];
  }

  return (
    <tr>
      <td></td>
      <td colSpan={2}>
        <div className={generateHiddenClassString('', isHidden)} />
        <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
          <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
          <DiffContainer label="Part usage" diffChangeState={content.diff_change_state} isTextSticky={false}>
            <div className="flex flex-col grow gap-y-6">
              <div>
                <div className="flex flex-col">
                  <div className={`text-gray-500 text-sm tracking-wide ${content?.part !== null ? 'pt-6' : ''}`}>
                    Part Usage
                  </div>

                  {!partRestricted && (
                    <div>
                      {!content?.part ? (
                        <PartAndUsageTypeSelect
                          recorded={undefined}
                          content={content}
                          onChangePartDuringRun={null}
                          onChangeUsage={null}
                          isEnabled={false}
                        />
                      ) : (
                        <ReviewPartLabel teamId={teamId} part={content?.part} />
                      )}
                    </div>
                  )}
                  {partRestricted && (
                    <div className="mt-2 ml-0.5">
                      <RestrictedInfo text={RESTRICTED_TEXT} />
                    </div>
                  )}
                </div>
              </div>
              {!partRestricted && (
                <div className="flex gap-x-2">
                  <div className="w-56">
                    <div className="field-title">Item</div>
                    <ItemSelect
                      itemId={undefined}
                      partId={sharedDiffUtil.getDiffValue(content, 'part_id', 'new')}
                      isDisabled={true}
                      onChangeItem={() => {
                        /* no-op */
                      }}
                      usageTypeName={usageTypeSelected?.name || ''}
                    />
                  </div>
                  <div>
                    <div className="field-title">Usage</div>
                    <input
                      data-testid="usage-amount"
                      className="px-2 h-[2.4rem] w-16 border border-gray-400 rounded disabled:bg-gray-200 disabled:bg-opacity-50"
                      disabled={true}
                      onChange={() => {
                        /* no-op */
                      }}
                      value=""
                    />
                  </div>
                  <div className="mt-6">
                    <div className="self-end">{usageTypeSelected ? `${getUsageTypeLabel(usageTypeSelected)}` : ''}</div>
                  </div>
                </div>
              )}
            </div>
          </DiffContainer>
        </div>
      </td>
    </tr>
  );
};

export default ReviewPartUsage;

import { InternalComponentItem } from 'shared/lib/types/api/manufacturing/internal/models';
import { ItemUsageInRun } from 'shared/lib/types/api/manufacturing/items/models';
import { Attachment } from 'shared/lib/types/attachments';
import {
  ComponentPart,
  Part,
} from 'shared/lib/types/postgres/manufacturing/types';

export const EMPTY_TRACKING_DISPLAY_VALUE = '--';
export const EMPTY_REVISION_DISPLAY_VALUE = '--';

export type PartParams = {
  partId: string;
};

export type ItemParams = {
  itemId: string;
};

export type TotalUsageRecord = {
  name: string;
  totalAmount: number;
  amountSinceReset: number;
  limit?: number;
};

export type ComponentItem = InternalComponentItem;

export enum ItemStatus {
  InInventory = 'In Inventory',
  Scrap = 'Scrap',
  CheckedOut = 'Checked Out',
  Consumed = 'Consumed',
}

export type Item = {
  id: string;
  rev: string;
  part_revision_id: string;
  part: Part;
  amount: number;
  serial?: string;
  lot?: string;
  location_id?: string;
  unit_cost_cents?: number; // Unit cost in US cents
  notes?: string;
  created_at?: string;
  usage?: Array<ItemUsageInRun>;
  kit_components?: Array<ComponentItem>;
  components?: Array<ComponentItem>;
  attachments?: Array<Attachment>;
  details?: Array<ItemDetail>;
  status: ItemStatus;
  usage_resets: {
    [usage_type: string]: string; // maps usage name to ISO timestamp
  };
};

export enum ComponentItemType {
  Kit = 'kit',
  UserDefined = 'user',
  Missing = 'missing',
}

export type DenormalizedComponentItem = ComponentItem & {
  type: ComponentItemType;
  item?: Item;
  part?: ComponentPart;
};

export type ItemOrder = {
  id: string;
  order_id: string;
  item_id: string;
  location_id?: string;
  quantity: number;
  unit_cost_cents: number;
  created_at: string;
};

export type Vendor = {
  id: string;
  name: string;
  address: string;
  contact?: string;
  notes?: string;
};

export type Location = {
  id: string;
  parent: string;
  name: string;
  code: string;
  full_code?: string;
  items_enabled?: boolean;
};

export type LocationMap = {
  [id: string]: Location;
};

export type Tag = {
  id: string;
  name: string;
};

export type PartTag = {
  id: string;
  part_id: string;
  tag_id: string;
};

export type ItemDetail = {
  id: string;
  name: string;
  value?: { recorded: string };
};

export enum ToolTab {
  Available = 'available',
  Unavailable = 'unavailable',
}

import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type ToolbarButtonWithHoverLabelProps = {
  icon: IconProp;
  ariaLabel: string;
  label: string;
  disabled: boolean;
  onClick?: () => void;
};

const ToolbarButtonWithHoverLabel = ({
  icon,
  ariaLabel,
  label,
  disabled,
  onClick,
}: ToolbarButtonWithHoverLabelProps) => {
  const classNames = useMemo(() => {
    const defaultClassNames = 'flex h-10 space-x-2 py-2 px-3 items-center rounded-md';
    let additionalClassNames;

    if (!disabled) {
      additionalClassNames = 'group/toolbar_button hover:bg-gray-100';
    } else {
      additionalClassNames = 'text-gray-300';
    }

    return `${defaultClassNames} ${additionalClassNames}`;
  }, [disabled]);

  return (
    <button className={classNames} type="button" disabled={disabled} onClick={onClick} aria-label={ariaLabel}>
      <FontAwesomeIcon icon={icon} />
      <div className="hidden group-hover/toolbar_button:block">{label}</div>
    </button>
  );
};

export default React.memo(ToolbarButtonWithHoverLabel);

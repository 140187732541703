import React, { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import stepConditionals from 'shared/lib/stepConditionals';
import { useRunContext } from '../../contexts/RunContext';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import TooltipOverlay from '../TooltipOverlay';
import StepConditionalDetails from './StepConditionalDetails';
import { FieldInputConditionalBlock, Step, StepConditional } from 'shared/lib/types/views/procedures';
import useProcedureAdapter from '../../hooks/useProcedureAdapter';

interface StepConditionalProps {
  step: Step;
  conditionals: Array<StepConditional>;
}

const StepConditionals = ({ step, conditionals }: StepConditionalProps) => {
  const { stepIdsToLabelsMap: runLabelMap, getSectionAndStepIdPair } = useRunContext();
  const { scrollTo } = useProcedureContext();

  const { stepIdsToLabelsMap } = useProcedureAdapter();

  const onStepLinkClicked = useCallback(
    (stepId: string | null) => {
      const { sectionId } = getSectionAndStepIdPair(stepId);

      if (typeof scrollTo === 'function' && stepId) {
        scrollTo({
          sectionId,
          stepId,
        });
      }
    },
    [getSectionAndStepIdPair, scrollTo]
  );

  const nextStepId = useMemo(() => {
    // Disables for viewing in edit procedure
    if (typeof runLabelMap !== 'object') {
      return null;
    }
    for (const conditional of conditionals) {
      if (stepConditionals.isConditionalFulfilled(step, conditional)) {
        return conditional.target_id;
      }
    }
    return null;
  }, [runLabelMap, step, conditionals]);

  return (
    <div className="flex flex-row gap-x-1 py-1">
      <FontAwesomeIcon className="text-gray-500 self-center" icon="sitemap" />
      <div className="ml-1">
        <div className="flex flex-row">
          Next:&nbsp;
          <TooltipOverlay
            delayClose={true}
            content={
              <StepConditionalDetails
                content={step.content as FieldInputConditionalBlock[]}
                conditionals={conditionals}
                onStepLinkClicked={onStepLinkClicked}
                stepIdsToLabelsMap={stepIdsToLabelsMap}
                stepId={step.id}
              />
            }
          >
            <button
              className="link disabled:text-gray-400"
              onClick={() => onStepLinkClicked(nextStepId)}
              disabled={typeof scrollTo !== 'function' || !nextStepId} // Disables for viewing in edit procedure
            >
              {nextStepId ? stepIdsToLabelsMap[nextStepId] : 'Pending'}
            </button>
          </TooltipOverlay>
        </div>
      </div>
    </div>
  );
};

export default React.memo(StepConditionals);

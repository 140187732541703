import React, { useContext, useRef } from 'react';
import { Boundary } from '../components/Selection/Selectable';
import SelectionApi from '../components/Selection/SelectionApi';

export type Selectable = {
  type: Boundary;
  id: string;
};

type Callback = () => void;

export interface SelectionReturnValue {
  isSelected: (selectable: Selectable) => boolean;
  addSelection: (selectable: Selectable, isMultiSelect?: boolean) => void;
  removeSelection: (selectable: Selectable) => void;
  clearSelections: (boundary?: Boundary) => void;
  onSelectionChanged: (callback: Callback) => Callback;
}

const SelectionContext = React.createContext<SelectionReturnValue | undefined>(undefined);

export const SelectionContextProvider = ({ children }) => {
  const selectionApiRef = useRef(new SelectionApi());

  return (
    <SelectionContext.Provider
      value={{
        isSelected: selectionApiRef.current.isSelected,
        addSelection: selectionApiRef.current.addSelection,
        removeSelection: selectionApiRef.current.removeSelection,
        clearSelections: selectionApiRef.current.clearSelections,
        onSelectionChanged: selectionApiRef.current.onSelectionChanged,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

export const useSelectionContext = () => {
  const context = useContext(SelectionContext);

  if (context === undefined) {
    throw new Error('useSelectionContext must be used within a SelectionContextProvider');
  }

  return context;
};

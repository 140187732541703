import React, { useCallback } from 'react';
import { NOTIFICATION_STATUS, getNotificationMessage } from 'shared/lib/notifications';
import { AppNotificationDisplay, NotificationTypes, NotificationChannels } from 'shared/lib/types/notifications';
import { NotifyAtMentionContext } from 'shared/lib/types/postgres/notifications';
import Tooltip from '../../elements/Tooltip';
import NotificationLink from './NotificationLink';

export interface NotificationLinkProps {
  notification: AppNotificationDisplay;
  duplicateIds: Array<number>;
  handleOnClick: (ids: Array<number>) => void;
  truncateCode?: boolean;
}

const AtMentionNotificationLink = ({
  notification,
  duplicateIds,
  handleOnClick,
  truncateCode = true,
}: NotificationLinkProps) => {
  const context = notification.context as NotifyAtMentionContext;
  const { entityName, entityUrlPath } = context;

  const onClick = useCallback(() => {
    if (handleOnClick) {
      return handleOnClick(duplicateIds);
    }
  }, [duplicateIds, handleOnClick]);

  return (
    <NotificationLink
      notificationId={notification.id}
      ariaLabel="automation-notification"
      to={entityUrlPath}
      onClick={onClick}
      duplicateIds={duplicateIds}
    >
      <div
        className={`${
          notification.status === NOTIFICATION_STATUS.UNRESOLVED && 'font-semibold'
        } flex flex-row space-x-1`}
      >
        <div>{getNotificationMessage(NotificationTypes.AtMention, NotificationChannels.InApp).message_1}</div>
        <div className={`${truncateCode ? 'max-w-[3.5rem]' : ''}`}>
          <Tooltip content={entityName}>
            <p className="text-blue-500 truncate">{entityName}</p>
          </Tooltip>
        </div>
        <div>{getNotificationMessage(NotificationTypes.AtMention, NotificationChannels.InApp).message_2}</div>
      </div>
    </NotificationLink>
  );
};

export default React.memo(AtMentionNotificationLink);

import { Duration } from 'luxon';
import { ItemUsageInRun } from 'shared/lib/types/api/manufacturing/items/models';
import { ToolInstanceUsage } from 'shared/lib/types/api/manufacturing/tools/models';
import { UsageType } from 'shared/lib/types/api/manufacturing/usage_types/models';

export const getUsageNameForItemInRun = (
  itemUsageInRun: ItemUsageInRun
): string => itemUsageInRun.content_data.usage_type;

export const getUsageTypeForToolInRun = (
  toolInstanceUsage: ToolInstanceUsage,
  toolUsageTypes?: UsageType[]
): UsageType | undefined => {
  const usageTypeId = toolInstanceUsage.content_data.usage_type_id;
  return toolUsageTypes?.find((usageType) => usageType.id === usageTypeId);
};

export const getUsageNameForToolInRun = (
  toolInstanceUsage: ToolInstanceUsage,
  toolUsageTypes?: UsageType[]
): string | undefined => {
  return getUsageTypeForToolInRun(toolInstanceUsage, toolUsageTypes)?.name;
};

export const getUsageUnitsForToolInRun = (
  toolInstanceUsage: ToolInstanceUsage,
  toolUsageTypes?: UsageType[]
): string | null | undefined => {
  return getUsageTypeForToolInRun(toolInstanceUsage, toolUsageTypes)?.units;
};

export const getDisplayTextForTimeBasedUsage = (
  usageInSeconds: number,
  opts?: { unitDisplay?: 'short' | 'narrow' }
): string => {
  const unitDisplay = opts?.unitDisplay ?? 'short';
  return Duration.fromMillis(1000 * usageInSeconds)
    .shiftTo('hours', 'minutes', 'seconds')
    .toHuman({ unitDisplay });
};

import { isNaN } from 'lodash';

const numericalFieldHandler = (callback) => (event) => {
  const inputValue = event.target.value;
  const inputNumber = +inputValue;

  // Allow empty input for better UX
  if (inputValue === '') {
    callback(event);
    return;
  }
  // Check if is a number, is greater than 0, and is less than 1000
  if (isNaN(inputNumber) || inputNumber <= 0 || inputNumber >= 1000) return;

  const editedEvent = { target: { value: inputNumber, name: event.target.name } };
  callback(editedEvent);
};

export default numericalFieldHandler;

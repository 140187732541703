import { useState, useCallback } from 'react';
import { CouchLikeOperationSummary } from 'shared/lib/types/operations';
import CreateEventModal from '../components/CreateEventModal';

interface DisplayProps {
  operation?: CouchLikeOperationSummary;
}

interface CreateEventModalHook {
  displayCreateEventModal: (props?: DisplayProps) => void;
  CreateEventModal: () => JSX.Element;
  eventSaved: object;
}

const useCreateEventModal = (): CreateEventModalHook => {
  const [visible, setVisible] = useState<boolean>(false);
  const [eventSaved, setEventSaved] = useState<object>({});
  const [operation, setOperation] = useState<CouchLikeOperationSummary | undefined>(undefined);

  const handleSave = useCallback(() => {
    setVisible(false);
    setEventSaved({});
  }, []);

  const modal = useCallback(() => {
    return (
      <CreateEventModal
        isVisible={visible}
        onCancel={() => setVisible(false)}
        onSave={handleSave}
        operation={operation}
      />
    );
  }, [visible, handleSave, operation]);

  const display = useCallback((props?: DisplayProps) => {
    setOperation(props?.operation);
    setVisible(true);
  }, []);

  return {
    displayCreateEventModal: display,
    CreateEventModal: modal,
    eventSaved,
  };
};

export default useCreateEventModal;

import { ProcedureType } from 'shared/lib/types/couch/procedures';
import { isDraft, isInReview } from 'shared/lib/procedureUtil';
import {
  procedurePendingPath,
  procedureReviewPath,
  procedureViewPath,
} from '../../lib/pathUtil';
import procedureUtil from '../../lib/procedureUtil';
import { PlanProcedureMetadata } from '../components/Plans/ActivePlansGrid';

export const procedureTypeDisplay = {
  [ProcedureType.TestPlan]: 'Plan',
  [ProcedureType.TestSequence]: 'Sequence',
  empty: '',
};

export const goToProcedure = (
  event: PlanProcedureMetadata,
  currentTeamId: string
): string => {
  let path = '';
  const procedureId = procedureUtil.getProcedureId(event);
  if (isDraft(event)) {
    path = procedurePendingPath(currentTeamId, procedureId);
  } else if (isInReview(event)) {
    path = procedureReviewPath(currentTeamId, procedureId);
  } else {
    path = procedureViewPath(currentTeamId, procedureId);
  }
  return path;
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { useCallback, useState } from 'react';

interface SidebarLayoutProps {
  paddingTop?: string;
  children: Array<JSX.Element>;
  setSidebarWidth?: (width: number) => void;
  alternativePanelWidths?: number[];
  toggleSidebarTopMargin?: string;
}

const DEFAULT_PANEL_WIDTHS_COLLAPSED = [0, 100];
const DEFAULT_PANEL_WIDTHS_EXPANDED = [25, 75];

export const MainScrollPanelId = 'main-scroll-panel';

const SidebarLayout = ({
  children,
  paddingTop = 'pt-10',
  setSidebarWidth = (width) => {
    /* no-op */
  },
  alternativePanelWidths,
  toggleSidebarTopMargin = 'mt-0',
}: SidebarLayoutProps) => {
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(true);
  const [panelWidths, setPanelWidths] = useState<Array<number>>(
    alternativePanelWidths || DEFAULT_PANEL_WIDTHS_EXPANDED
  );

  const toggleSidebar = useCallback(() => {
    if (sidebarVisible) {
      setSidebarVisible(false);
      setPanelWidths(DEFAULT_PANEL_WIDTHS_COLLAPSED);
      setSidebarWidth(DEFAULT_PANEL_WIDTHS_COLLAPSED[0]);
    } else {
      setSidebarVisible(true);
      setPanelWidths(alternativePanelWidths || DEFAULT_PANEL_WIDTHS_EXPANDED);
      setSidebarWidth(alternativePanelWidths ? alternativePanelWidths[0] : DEFAULT_PANEL_WIDTHS_EXPANDED[0]);
    }
  }, [sidebarVisible, setSidebarWidth, alternativePanelWidths]);

  const onResizeEnd = useCallback(
    (event) => {
      setPanelWidths(event.sizes);
      setSidebarWidth(event.sizes[0]);
    },
    [setSidebarWidth]
  );

  if (children.length !== 2) {
    return <></>;
  }

  return (
    <Splitter
      onResizeEnd={onResizeEnd}
      className={`${paddingTop} print:pt-0 print:border-0 print:h-full h-screen relative w-full`}
      gutterSize={sidebarVisible ? 4 : 0}
    >
      <SplitterPanel
        className={`relative print:hidden flex flex-col align-items-center justify-content-center overflow-hidden ${
          sidebarVisible ? 'min-w-[320px] pt-2 px-3' : 'max-w-[10px] border-r-2 border-gray-300'
        }`}
        size={panelWidths[0]}
      >
        <>
          <button
            onClick={toggleSidebar}
            aria-label="sidebar-toggle"
            className={`absolute -right-4 h-8 w-10 rounded-l-full border border-gray-100 bg-white shadow-md ${toggleSidebarTopMargin}`}
          >
            <FontAwesomeIcon icon="arrow-left" className="-ml-2 text-gray-500" />
          </button>
          {children[0]}
          {!sidebarVisible && (
            <div className="h-full w-full absolute m-auto top-0 right-0 bg-white" onClick={toggleSidebar} />
          )}
        </>
      </SplitterPanel>
      <SplitterPanel
        className={`print:bg-white relative overflow-y-auto flex flex-col align-items-center justify-content-center bg-gray-50 w-full ${
          sidebarVisible && 'min-w-[440px]'
        }`}
        size={panelWidths[1]}
        pt={{ root: { id: MainScrollPanelId } }} // We target this scroll panel using id to add scroll listeners in VirtualizedElement.tsx
      >
        {!sidebarVisible && (
          <button
            onClick={toggleSidebar}
            className="sticky flex justify-center items-center top-2 -ml-1 h-8 w-8 rounded-r-full border border-gray-100 bg-white shadow-md py-2"
          >
            <FontAwesomeIcon icon="arrow-right" className="text-gray-500" />
          </button>
        )}
        {children[1]}
      </SplitterPanel>
    </Splitter>
  );
};

const Sidebar = ({ children }) => {
  return <>{children}</>;
};

const Content = ({ children }) => {
  return <>{children}</>;
};

// Attach Sidebar and Content as named exports
SidebarLayout.Sidebar = Sidebar;
SidebarLayout.Content = Content;

export default SidebarLayout;

import superlogin from './superlogin';
import { API_URL } from '../config';
import {
  OrganizationRoles,
  WorkspaceRoles,
} from '../components/Settings/types';

class RolesService {
  teamId: string;
  url: string;

  constructor(teamId: string | null) {
    if (!teamId) {
      throw new Error('Missing teamId');
    }
    this.teamId = teamId;
    this.url = `${API_URL}/teams/${this.teamId}/roles`;
  }

  async listRoles(): Promise<WorkspaceRoles> {
    try {
      const response = await superlogin.getHttp().get(this.url);
      return response.data.data;
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }

  static async listOrganizationRoles(
    orgId: string
  ): Promise<OrganizationRoles> {
    const url = `${API_URL}/organizations/${orgId}/roles`;
    try {
      const response = await superlogin.getHttp().get(url);
      return response.data.data;
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }
}

export default RolesService;

import { ReactElement } from 'react';
import { Attachment } from '../../hooks/useAttachment';
import ThumbnailImage from './ThumbnailImage';

type BadgeProps = {
  title: ReactElement;
  subtitle: ReactElement;
  attachment?: Attachment;
};

const Badge = ({ title, subtitle, attachment }: BadgeProps) => {
  return (
    <div className="flex items-center gap-x-2">
      <ThumbnailImage size="md" attachment={attachment} />
      <div className="flex flex-col">
        {title}
        {subtitle}
      </div>
    </div>
  );
};

export default Badge;

import { formattedLocalDateTime, timeAgo } from '../../lib/datetime';
import React from 'react';

interface TimeAgoDisplayProps {
  timestamp: Date;
  currentTime: Date;
}
/**
 * A component for displaying the difference between two timestamps
 * formatted in a human readable format, e.g., "days/minutes ago."
 */
const TimeAgoDisplay = React.memo(({ timestamp, currentTime }: TimeAgoDisplayProps) => {
  return (
    <span className="text-xs whitespace-nowrap" title={formattedLocalDateTime(timestamp.toISOString()) ?? undefined}>
      {timeAgo(timestamp, currentTime)}
    </span>
  );
});

export default TimeAgoDisplay;

import { Error } from '../types';
import {
  Draft,
  DraftSection,
  DraftStep,
  DraftStepBlock,
  Procedure,
  SnippetRef,
  Step,
  TextBlock,
} from 'shared/lib/types/views/procedures';
import procedureUtil from './procedureUtil';
import stepDependencyUtil from './stepDependencyUtil';
import { Snippet, StepSnippet, SectionSnippet } from './views/settings';
import { cloneDeep } from 'lodash';
import { ProcedureContentBlockTypes } from 'shared/lib/types/blockTypes';

const ERROR_JUMP_TO_NOT_SUPPORTED =
  'Jump to links are not currently supported in snippets.';
const ERROR_INPUT_REFERENCE_NOT_SUPPORTED =
  'Input references are not currently supported in snippets.';
const ERROR_STEP_DEPENDENCIES_NOT_SUPPORTED =
  'Dependencies are not currently supported in snippets.';
const ERROR_STEP_CONDITIONALS_NOT_SUPPORTED =
  'Step conditionals are not currently supported in snippets.';
export const ERROR_DYNAMIC_STEPS_NOT_SUPPORTED_IN_SNIPPETS =
  'Adding steps during runs are not currently supported in snippets.';
export const ERROR_TEXT_BLOCKS_WITH_REFERENCES_NOT_SUPPORTED_IN_SNIPPETS =
  'Text blocks with references are not currently supported in snippets';

export const SNIPPET_STEP_DELETED_AND_DETACHED =
  'This step has been detached from a snippet that was deleted.';
export const SNIPPET_SECTION_DELETED_AND_DETACHED =
  'This section has been detached from a snippet that was deleted.';

const snippetUtil = {
  validateBlockForSnippet: (block: DraftStepBlock): Error | undefined => {
    if (block.type.toLowerCase() === 'jump_to') {
      return {
        type: 'unsupported_content',
        unsupported_content: ERROR_JUMP_TO_NOT_SUPPORTED,
      };
    }

    if (block.type.toLowerCase() === 'reference') {
      return {
        type: 'unsupported_content',
        unsupported_content: ERROR_INPUT_REFERENCE_NOT_SUPPORTED,
      };
    }

    if (block.type.toLowerCase() === 'text' && (block as TextBlock).tokens) {
      const { tokens } = block as TextBlock;

      if (tokens?.some((token) => token.type !== 'text')) {
        return {
          type: 'unsupported_content',
          unsupported_content:
            ERROR_TEXT_BLOCKS_WITH_REFERENCES_NOT_SUPPORTED_IN_SNIPPETS,
        };
      }
    }
  },

  validateStepForSnippet: (step: DraftStep): Error | undefined => {
    const dependencies = stepDependencyUtil.getAllDependentIds(
      step.dependencies
    );

    if (dependencies.size !== 0) {
      return {
        type: 'unsupported_content',
        unsupported_content: ERROR_STEP_DEPENDENCIES_NOT_SUPPORTED,
      };
    }

    // Check for content not supported in step snippets.
    for (const block of step.content) {
      const error = snippetUtil.validateBlockForSnippet(block);
      if (error && Object.keys(error).length !== 0) {
        return error;
      }
    }

    // Check for step conditionals.
    if (step.conditionals && step.conditionals.length > 0) {
      return {
        type: 'unsupported_content',
        unsupported_content: ERROR_STEP_CONDITIONALS_NOT_SUPPORTED,
      };
    }
  },

  validateSectionForSnippet: (section: DraftSection): Error | undefined => {
    // Validate steps in this section for snippet.
    for (const step of section.steps) {
      const error = snippetUtil.validateStepForSnippet(step);
      if (error && Object.keys(error).length !== 0) {
        return error;
      }
    }
  },

  sanatizeSnippet: (step: DraftStep): DraftStep => {
    const dependencies = stepDependencyUtil.getAllDependentIds(
      step.dependencies
    );

    if (dependencies.size !== 0) {
      step.dependencies = [];
    }

    // Check for content not supported in step snippets.
    const sanitizedContent: Array<DraftStepBlock> = [];
    for (const block of step.content) {
      const error = snippetUtil.validateBlockForSnippet(block);
      if (!error || Object.keys(error).length === 0) {
        sanitizedContent.push(block);
      }
    }

    step.content = sanitizedContent;

    if (step.conditionals && step.conditionals.length > 0) {
      step.conditionals = [];
    }

    return step;
  },

  isSnippet: (block: DraftSection | DraftStep): boolean => {
    return Boolean(block.snippet_id && block.snippet_name);
  },

  isTestSnippet: (block: DraftSection | DraftStep): boolean => {
    if (!snippetUtil.isSnippet(block)) {
      return false;
    }

    const stepBlock = block as Step;

    return stepBlock.content.some(
      (item) => item.type === ProcedureContentBlockTypes.TestCases
    );
  },

  detachSnippet: (block: DraftSection | DraftStep): void => {
    if (!snippetUtil.isSnippet(block)) {
      return;
    }

    delete block.snippet_id;
    delete block.snippet_name;
    delete block.snippet_rev;
  },

  _processBlockSnippet: (
    block: DraftSection | DraftStep,
    snippets: Array<SnippetRef>
  ): void => {
    if (block.snippet_id) {
      const snippet = snippets.find(
        (snippet) => snippet.snippet_id === block.snippet_id
      );

      if (!snippet) {
        return; // Something went wrong, parent is supposed to load all linked snippets.
      }

      if (snippet.deleted) {
        snippetUtil.detachSnippet(block);

        // TODO: Rework this and avoid having to store this in the section/step.
        block.shows_snippet_detached_message = true;
      }
    }
  },

  // finds unresolved snippets in procedure
  getUnresolvedSnippets: (
    procedure: Draft,
    fetchedSnippets: Array<Snippet> | null
  ): Array<string> => {
    const unresolvedSectionStepSnippets: Array<string> = [];
    const mapping = {};
    if (!fetchedSnippets) {
      return [];
    }
    for (const snippet of fetchedSnippets) {
      if (snippet.snippet_id) {
        mapping[snippet.snippet_id] = snippet.revision;
      }
    }
    for (const section of procedure.sections) {
      if (
        section.snippet_id &&
        mapping[section.snippet_id] &&
        section.snippet_rev !== mapping[section.snippet_id]
      ) {
        unresolvedSectionStepSnippets.push(section.id);
      }

      for (const step of section.steps) {
        if (
          step.snippet_id &&
          mapping[step.snippet_id] &&
          step.snippet_rev !== mapping[step.snippet_id]
        ) {
          unresolvedSectionStepSnippets.push(step.id);
        }
      }
    }
    return unresolvedSectionStepSnippets;
  },

  detachDeletedSnippets: (
    updatedProcedure: Procedure,
    snippets: Array<SnippetRef>
  ): Procedure => {
    for (const section of updatedProcedure.sections) {
      snippetUtil._processBlockSnippet(section, snippets);

      for (const step of section.steps) {
        snippetUtil._processBlockSnippet(step, snippets);
      }
    }

    return updatedProcedure;
  },

  getNewStepSnippet: (): SnippetRef => {
    const newStepSnippet = {
      snippet_id: procedureUtil.generateSnippetId(),
      step: procedureUtil.newStep(),
      snippet_type: 'step',
      type: 'snippet',
    };
    return newStepSnippet;
  },

  getNewSectionSnippet: (): SnippetRef => {
    const newSectionSnippet = {
      snippet_id: procedureUtil.generateSnippetId(),
      section: procedureUtil.newSection(),
      snippet_type: 'section',
      type: 'snippet',
    };
    return newSectionSnippet;
  },

  sectionHasSnippets: (section: DraftSection): boolean => {
    return section.steps.some(
      (step: DraftStep) => step.snippet_id && step.snippet_name
    );
  },

  duplicateSnippet: (snippet: Snippet): Snippet => {
    if (snippet.snippet_type === 'section') {
      return snippetUtil.duplicateSectionSnippet(snippet);
    }
    return snippetUtil.duplicateStepSnippet(snippet);
  },

  duplicateStepSnippet: (snippet: StepSnippet): StepSnippet => {
    const newSnippetId = procedureUtil.generateSnippetId();
    const duplicateStep = cloneDeep(snippet.step);
    procedureUtil.updateStepWithNewIds(duplicateStep);

    const duplicateStepSnippet = {
      _id: newSnippetId,
      name: snippet.name.concat(' (Copy)'),
      description: snippet.description,
      type: snippet.type,
      snippet_type: snippet.snippet_type,
      snippet_id: newSnippetId,
      step: duplicateStep,
    };
    return duplicateStepSnippet;
  },

  duplicateSectionSnippet: (snippet: SectionSnippet): SectionSnippet => {
    const newSnippetId = procedureUtil.generateSnippetId();
    const duplicateSection = cloneDeep(snippet.section);
    procedureUtil.updateSectionWithNewIds(duplicateSection);

    const duplicateSectionSnippet = {
      _id: newSnippetId,
      name: snippet.name.concat(' (Copy)'),
      description: snippet.description,
      type: snippet.type,
      snippet_type: snippet.snippet_type,
      snippet_id: newSnippetId,
      section: duplicateSection,
    };
    return duplicateSectionSnippet;
  },
};

export default snippetUtil;

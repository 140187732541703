import { useLocation } from 'react-router-dom';

interface LocationState {
  goToTab?: 'list' | 'chart';
}

const useLocationState = (): LocationState => {
  const location = useLocation();
  const state = location.state as LocationState; // Cast state since by default it is unknown
  return state;
};

export default useLocationState;

import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { STEP_STATE } from 'shared/lib/runUtil';
import sharedDiffUtil from 'shared/lib/diffUtil';

interface ExpressionTokenDisplayProps {
  referenceName: string;
  onClick: () => void;
  referencedFromStepKey?: string;
  referencedFromSectionKey?: string;
  sectionRepeatKey?: string;
  stepRepeatKey?: string;
  stepRecordedState?: string;
  inRunningRun: boolean;
  isVariable: boolean;
  isVariableRecorded: boolean;
}

const ExpressionTokenDisplay = ({
  referenceName,
  onClick,
  referencedFromStepKey,
  referencedFromSectionKey,
  sectionRepeatKey,
  stepRepeatKey,
  stepRecordedState,
  inRunningRun,
  isVariable,
  isVariableRecorded,
}: ExpressionTokenDisplayProps) => {
  const valueFromSectionRepeatSpan = useMemo(
    () =>
      sectionRepeatKey && (
        <span>
          Section {referencedFromSectionKey} <FontAwesomeIcon icon="redo" className="text-xxs mb-px" />{' '}
          {sectionRepeatKey},
        </span>
      ),
    [referencedFromSectionKey, sectionRepeatKey]
  );

  const valueFromStepRepeatSpan = useMemo(
    () =>
      stepRepeatKey && (
        <span>
          {' '}
          <FontAwesomeIcon icon="redo" className="text-xxs mb-px" /> {stepRepeatKey}
        </span>
      ),
    [stepRepeatKey]
  );

  const chipStatusColor = useMemo(() => {
    const base = 'bg-slate-300';
    const complete = 'bg-emerald-200';

    if (isVariable) {
      if (isVariableRecorded) {
        return complete;
      }
      return base;
    }

    if (inRunningRun) {
      if (stepRecordedState === STEP_STATE.COMPLETED) {
        return complete;
      }
      if (stepRecordedState === STEP_STATE.SKIPPED) {
        return base;
      }
      if (stepRecordedState === STEP_STATE.FAILED) {
        return 'bg-red-100';
      }
    }
    //Case where step is not ended or procedure is not running
    return base;
  }, [stepRecordedState, inRunningRun, isVariable, isVariableRecorded]);

  const referencedFromPrefix = isVariable ? 'Variable' : referencedFromStepKey;

  const diffValueReferenceName = sharedDiffUtil.getDiffValue({ referenceName }, 'referenceName', 'new');

  return (
    <div className="flex flex-row items-start">
      <button
        onClick={onClick}
        className={`font-medium text-xs px-1.5 py-0.5 rounded-xl whitespace-nowrap ${chipStatusColor}`}
      >
        {valueFromSectionRepeatSpan} {referencedFromPrefix}. {diffValueReferenceName} {valueFromStepRepeatSpan}
      </button>
    </div>
  );
};

export default ExpressionTokenDisplay;

import { useState, useMemo, useCallback } from 'react';

const useExpandCollapse = (
  onSetIsCollapsed = () => {
    /** Noop */
  }
) => {
  const [isCollapsedMap, setIsCollapsedMap] = useState({});
  const [redlineCommentExpandedMap, setRedlineCommentExpandedMap] = useState({});

  const areAllExpanded = useMemo(() => {
    for (const key in isCollapsedMap) {
      if (isCollapsedMap[key]) {
        return false;
      }
    }

    return true;
  }, [isCollapsedMap]);

  const setIsCollapsed = useCallback(
    (id, collapse) => {
      setIsCollapsedMap((state) => {
        const newState = { ...state };

        newState[id] = collapse;
        return newState;
      });

      if (onSetIsCollapsed) {
        onSetIsCollapsed();
      }
    },
    [onSetIsCollapsed]
  );

  const setIsCollapsedMultiple = useCallback(
    (ids, collapse) => {
      setIsCollapsedMap((state) => {
        const newState = { ...state };
        if (!collapse) {
          for (const id of ids) {
            newState[id] = false;
          }
        } else {
          for (const id of ids) {
            newState[id] = true;
          }
        }
        return newState;
      });

      if (onSetIsCollapsed) {
        onSetIsCollapsed();
      }
    },
    [onSetIsCollapsed]
  );

  const setAllExpanded = useCallback((expand, sectionIds, headerIds) => {
    setIsCollapsedMap((state) => {
      const newState = { ...state };
      for (const key in state) {
        newState[key] = false;
      }
      if (!expand) {
        const headersAndSectionsToCollapse = [...sectionIds, ...headerIds];
        for (const id of headersAndSectionsToCollapse) {
          newState[id] = true;
        }
      }
      return newState;
    });
  }, []);

  const areAllStepsInSectionExpanded = useCallback(
    (section) => section.steps.every((step) => !isCollapsedMap[step.id]),
    [isCollapsedMap]
  );

  const setAllStepsInSectionExpanded = useCallback(
    (expand, section) =>
      setIsCollapsedMultiple(
        section.steps.map((step) => step.id),
        !expand
      ),
    [setIsCollapsedMultiple]
  );

  const areRedlineCommentsExpanded = useCallback(
    (stepId) => Boolean(redlineCommentExpandedMap[stepId]),
    [redlineCommentExpandedMap]
  );

  const expandRedlineComments = useCallback(
    (stepId, areExpanded = true) =>
      setRedlineCommentExpandedMap((commentMap) => ({
        ...commentMap,
        [stepId]: areExpanded,
      })),
    []
  );

  return {
    isCollapsedMap,
    setIsCollapsedMap,
    areAllExpanded,
    setAllExpanded,
    setIsCollapsed,
    setIsCollapsedMultiple,
    areAllStepsInSectionExpanded,
    setAllStepsInSectionExpanded,
    areRedlineCommentsExpanded,
    expandRedlineComments,
  };
};

export default useExpandCollapse;

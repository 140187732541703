import PromptRedline from '../PromptRedline';
import React, { useCallback, useMemo } from 'react';
import { getRedlineFromDoc, getRedlineId } from 'shared/lib/redlineUtil';
import revisions from '../../lib/revisions';
import ReviewBlock from '../Review/ReviewBlock';
import diffUtil from '../../lib/diffUtil';
import { PROCEDURE_STATE_IN_REVIEW } from 'shared/lib/procedureUtil';
import { FullStepRedline, StepBlockRedline } from 'shared/lib/types/views/redlines';
import { DraftStep, RunStepBlockRedline, RunStepFullRedline } from 'shared/lib/types/views/procedures';
import { getScrollToUrlParams } from '../../lib/scrollToUtil';
import { runViewPath } from '../../lib/pathUtil';
import { useDatabaseServices } from '../../contexts/DatabaseContext';

interface BlockRedlineProps {
  redline: StepBlockRedline | FullStepRedline;
  currentStep: DraftStep;
  error?: string;
  onAcceptRedline: (runRedline: RunStepBlockRedline | RunStepFullRedline, sourceContentId?: string) => void;
  onRejectRedline: (runRedline: RunStepBlockRedline | RunStepFullRedline) => void;
  sourceName: string;
}

const BlockRedline = ({
  redline,
  currentStep,
  error,
  onAcceptRedline,
  onRejectRedline,
  sourceName,
}: BlockRedlineProps) => {
  const { currentTeamId } = useDatabaseServices();
  const blockId = redline.content_id ?? '';
  const blockType = useMemo(() => {
    if (!blockId) {
      return;
    }
    const currentBlock = currentStep.content.find((block) => block.id === blockId);
    return currentBlock?.type;
  }, [blockId, currentStep.content]);

  const runRedline = getRedlineFromDoc(redline) as RunStepBlockRedline | RunStepFullRedline;
  const sourceBlockId =
    (redline as FullStepRedline).source_content_id ?? (runRedline as RunStepBlockRedline).source_content_id;
  const createdBy = revisions.createdBy(runRedline);
  const timestamp = revisions.createdAt(runRedline);

  const blockDiffList = useMemo(() => {
    if (!blockId || !sourceBlockId) {
      return [];
    }
    return diffUtil.getBlockDiff({
      previousStep: currentStep,
      updatedStep: runRedline.step,
      blockId,
      sourceBlockId,
    });
  }, [blockId, currentStep, runRedline.step, sourceBlockId]);

  const handleOnAccept = useCallback(() => {
    return onAcceptRedline && onAcceptRedline(runRedline, (redline as FullStepRedline).source_content_id);
  }, [onAcceptRedline, redline, runRedline]);

  const handleOnReject = useCallback(() => {
    return onRejectRedline && onRejectRedline(runRedline);
  }, [onRejectRedline, runRedline]);

  const sourceStepUrl = `${runViewPath(currentTeamId, redline.run_id)}${getScrollToUrlParams({
    id: redline.source_step_id,
    type: 'step',
  })}`;

  return (
    <PromptRedline
      allRedlineUserIds={[createdBy]} // TODO EPS-2141: Refactor to pass in a single string when the keep-all-redlines work is done.
      timestamp={timestamp}
      onAccept={handleOnAccept}
      onReject={handleOnReject}
      error={error}
      key={getRedlineId(runRedline)}
      contextUrl={sourceStepUrl}
    >
      <div className={`w-full bg-white rounded ${blockType === 'table_input' ? 'pl-2' : ''}`}>
        <table className={`${blockType === 'table_input' ? '-mr-2' : 'w-full'}`}>
          <tbody>
            {blockDiffList.map((blockDiff, index) => (
              <ReviewBlock
                key={index}
                block={blockDiff}
                docState={PROCEDURE_STATE_IN_REVIEW}
                isMobile={false}
                isStepComplete={false}
                isValid={true}
                onRefChanged={() => null}
                onlyHasToTheSideImages={false}
                scrollToBufferRem={0}
                sourceName={sourceName}
              />
            ))}
            <tr className="h-2" />
          </tbody>
        </table>
      </div>
    </PromptRedline>
  );
};

export default BlockRedline;

import { AxiosResponse } from 'axios';
import { SourceType } from 'shared/lib/types/attachments';
import superlogin from '../api/superlogin';
import { API_URL } from '../config';
import { assembleAddFileRequest } from './lib';

class AttachmentsApi {
  static instances = {};

  static getInstance = (teamId: string): AttachmentsApi => {
    if (!AttachmentsApi.instances[teamId]) {
      AttachmentsApi.instances[teamId] = new AttachmentsApi(teamId);
    }

    return AttachmentsApi.instances[teamId];
  };

  static removeInstance = (teamId: string): void => {
    delete AttachmentsApi.instances[teamId];
  };

  private readonly teamId: string;
  private readonly restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    this.restUrl = `${API_URL}/teams/${this.teamId}/files`;
  }

  async add(
    id: string,
    name: string,
    source: SourceType,
    file: Blob
  ): Promise<AxiosResponse> {
    const formData = assembleAddFileRequest({
      id,
      name,
      source,
      file,
    });
    const response = await superlogin.getHttp().post(this.restUrl, formData);
    return response.data;
  }

  async get(id: string): Promise<Blob> {
    const url = `${this.restUrl}/${id}`;
    const response = await superlogin
      .getHttp()
      .get<Blob>(url, { responseType: 'blob' });
    return response.data;
  }

  async delete(id: string): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${id}`;
    const response = await superlogin.getHttp().delete(url);
    return response.data;
  }
}

export default AttachmentsApi;

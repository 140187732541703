import React, { useMemo } from 'react';
import ProcedureFieldDiff from './ProcedureFieldDiff';
import { DiffArrayChangeSymbol, DiffField } from 'shared/lib/types/views/procedures';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';

interface ProcedureDiffTextProps {
  diffValue: DiffField<number | string>;
  onScrollToDiffRefChanged?: (HTMLElement) => void;
  useMarkdownWhenNoDiff?: boolean;
  diffChangeState?: DiffArrayChangeSymbol;
  wrapPlainText?: boolean;
}
const ProcedureDiffText = ({
  diffValue,
  onScrollToDiffRefChanged,
  useMarkdownWhenNoDiff,
  diffChangeState,
  wrapPlainText = true,
}: ProcedureDiffTextProps) => {
  const oldValue = useMemo(
    () =>
      diffChangeState === ARRAY_CHANGE_SYMBOLS.ADDED
        ? ''
        : sharedDiffUtil.getDiffValue<string>({ value: diffValue }, 'value', 'old'),
    [diffChangeState, diffValue]
  );
  const newValue = useMemo(() => {
    if (diffChangeState === ARRAY_CHANGE_SYMBOLS.REMOVED) {
      return '';
    }
    const newText = sharedDiffUtil.getDiffValue<string>({ value: diffValue }, 'value', 'new');
    if (!diffChangeState && newText === oldValue) {
      return undefined;
    }
    return newText;
  }, [oldValue, diffChangeState, diffValue]);

  return (
    <ProcedureFieldDiff
      original={oldValue}
      redlined={newValue}
      onScrollToDiffRefChanged={onScrollToDiffRefChanged}
      useMarkdownWhenNoDiff={useMarkdownWhenNoDiff}
      wrapPlainText={wrapPlainText}
    />
  );
};

export default React.memo(ProcedureDiffText);

export const STEP_REFERENCE_TYPE = 'step';

export const RUN_REFERENCE_TYPE = 'run';

export const ITEM_REFERENCE_TYPE = 'item';

export const PART_REFERENCE_TYPE = 'part';

export const ORDER_REFERENCE_TYPE = 'order';

export const UNLINKED_REFERENCE_TYPE = 'unlinked';

// types that should have an associated run_id
export const RUN_REFERENCE_TYPES = [RUN_REFERENCE_TYPE, STEP_REFERENCE_TYPE];

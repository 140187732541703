import React, { useMemo } from 'react';
import ProcedureDiffText from '../../ProcedureDiffText';
import { RequirementBlockDiffElement } from 'shared/lib/types/views/procedures';
import diffUtil from 'shared/lib/diffUtil';
import { isEmpty } from 'lodash';

/*
 * Component for rendering a Block of type RequirementBlockDiffElement.
 * Conforms to TypedBlockInterface, see comments in useBlockComponents
 */
interface RequirementProps {
  block: RequirementBlockDiffElement;
}

const RequirementReview = ({ block }: RequirementProps) => {
  const { integrationDetails, diff_change_state } = block;

  const hasIntegrationDetails = !isEmpty(integrationDetails);

  const subTypeChanged = useMemo(() => {
    return diffUtil.isChanged(block, 'subType');
  }, [block]);

  const renderFieldDiff = (field) => {
    if (!field) return null;
    if (diff_change_state !== '~') {
      return (
        <div>
          <span>{field.__new || field}</span>
        </div>
      );
    }

    return (
      <div>
        {field.__old && field && (
          <>
            <span className="line-through text-red-500">{field.__old}</span>
            <span className="text-green-500"> {field.__new}</span>
          </>
        )}
        {!field.__old && <span>{field.__new}</span>}
      </div>
    );
  };

  const renderOldManualDetails = () => (
    <div>
      <span>Requirement:</span>
      <span className="ml-2">
        <ProcedureDiffText diffValue={block.label} />
      </span>
    </div>
  );

  return (
    <div className="flex px-2 py-1 rounded bg-slate-200">
      <div className="ml-2 whitespace-pre-line min-w-0 w-full break-words">
        {subTypeChanged && diffUtil.getDiffValue(block, 'subType', 'new') === 'jama' && (
          <div>
            <div>
              <span className="field-title">Requirement</span>
            </div>
            <span className="line-through text-red-500">{diffUtil.getDiffValue(block ?? {}, 'label', 'old')}</span>
            <span className=" text-green-500">{diffUtil.getDiffValue(integrationDetails ?? {}, 'name', 'old')}</span>
          </div>
        )}
        {subTypeChanged && diffUtil.getDiffValue(block, 'subType', 'new') === 'manual' && hasIntegrationDetails && (
          <div>
            <div className="flex space-x-2">
              <span className="line-through text-red-500">
                {diffUtil.getDiffValue(integrationDetails ?? {}, 'nam', 'old')}
              </span>
              <span className="text-green-500">{diffUtil.getDiffValue(block ?? {}, 'label', 'new')}</span>
            </div>
            <div>
              <span className="line-through text-red-500">
                {diffUtil.getDiffValue(integrationDetails ?? {}, 'description', 'old')}
              </span>
            </div>
          </div>
        )}
        {!subTypeChanged && !hasIntegrationDetails && renderOldManualDetails()}
        {!subTypeChanged && hasIntegrationDetails && (
          <div>
            <div>
              <span className="field-title">Requirement</span>
            </div>
            {integrationDetails.name && (
              <>
                <a
                  href={diffUtil.getDiffValue(integrationDetails, 'link', 'new') ?? (integrationDetails.link as string)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500"
                >
                  <>{renderFieldDiff(integrationDetails.name)}</>
                </a>
              </>
            )}
            <div>{integrationDetails.description && <>{renderFieldDiff(integrationDetails.description)}</>}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequirementReview;

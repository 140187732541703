import React from 'react';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@fortawesome/fontawesome-svg-core';

interface FieldSetInputWithIconProps {
  name: string; //unique key for value in json
  type: 'text' | 'number';
  placeholder: string;
  className: string;
  icon: Icon;
  validate?: (value: unknown) => void;
  list?: string; //key for data-list to be used
  errors?: string;
  isDisabled?: boolean;
}

const FieldSetInputWithIcon = React.memo(
  ({
    name,
    type,
    placeholder,
    className,
    icon,
    validate,
    list,
    errors,
    isDisabled = false,
    ...props
  }: FieldSetInputWithIconProps) => (
    <div className="grow w-full">
      <div className="grow relative">
        <div className="absolute top-2 left-3 text-gray-400">
          <FontAwesomeIcon icon={icon} />
        </div>
        <Field
          name={name}
          type={type}
          placeholder={placeholder}
          className={`${className} pl-10 w-full ${isDisabled ? 'bg-gray-200' : ''}`}
          validate={validate}
          list={list}
          disabled={isDisabled}
          {...props}
        />
      </div>
      {errors && <div className="text-red-700">{errors}</div>}
    </div>
  )
);

export default FieldSetInputWithIcon;

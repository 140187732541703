import localforage from 'localforage';
import { SourceType } from 'shared/lib/types/attachments';

export interface DownloadCacheEntry {
  id: string;
  data: Blob;
}

export interface UploadCacheEntry extends DownloadCacheEntry {
  name: string;
  source: SourceType;
}

interface TtlCacheEntry extends DownloadCacheEntry {
  created: number;
}

class AttachmentCache<T extends DownloadCacheEntry> {
  private cache: LocalForage;
  private _keys: Set<string>;
  private ttl: number;

  constructor(name: string, ttl?: number) {
    this.cache = localforage.createInstance({ name });
    this._keys = new Set();
    this.ttl = ttl ?? Number.POSITIVE_INFINITY;
  }

  async get(id: string): Promise<T | null> {
    return this.cache.getItem<T>(id);
  }

  async set(entry: T): Promise<T> {
    const internalEntry: TtlCacheEntry = {
      ...entry,
      created: Date.now(),
    };
    await this.cache.setItem(entry.id, internalEntry);
    this._keys.add(entry.id);
    return entry;
  }

  async delete(id: string): Promise<void> {
    await this.cache.removeItem(id);
    this._keys.delete(id);
  }

  /**
   * Note: bug in local forage (?) causes `await`ing `iterate` to hang.
   * Do not `await` this method.
   */
  async deleteExpired(): Promise<void> {
    const now = Date.now();
    const expiredKeys: Array<string> = [];
    await this.cache.iterate((entry: TtlCacheEntry, key: string) => {
      if (entry.created + this.ttl < now) {
        expiredKeys.push(key);
      }
    });
    for (const key of expiredKeys) {
      await this.delete(key);
    }
  }

  keys(): Set<string> {
    return this._keys;
  }

  has(id: string): boolean {
    return this._keys.has(id);
  }

  clear(): Promise<void> {
    return this.cache.clear();
  }
}

export default AttachmentCache;

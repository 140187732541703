import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import REFRESH_TRY_AGAIN_MESSAGE from '../../lib/messages';
import { Snippet } from '../../lib/views/settings';
import Button, { BUTTON_TYPES } from '../Button';

interface SnippetDeleteButtonProps {
  snippet: Snippet;
  onRemove?: (id: string) => Promise<void>;
}

const SnippetDeleteButton = ({ snippet, onRemove }: SnippetDeleteButtonProps) => {
  const [submitErrors, setSubmitErrors] = useState<string | null>();
  const isMounted = useRef(true);

  const remove = (setSubmitting) => {
    // Do nothing if there's no onRemove passed in
    if (!onRemove) {
      return;
    }

    // Confirm with user before removing Snippet
    const message = 'Are you sure you want to delete this snippet?';
    if (!window.confirm(message)) {
      return;
    }

    setSubmitErrors(null);
    setSubmitting(true);

    onRemove(snippet.snippet_id)
      .then(() => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);
      })
      .catch((errors) => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);
        if (typeof errors === 'string') {
          setSubmitErrors(errors);
        } else {
          setSubmitErrors(REFRESH_TRY_AGAIN_MESSAGE);
        }
      });
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {
        /* no-op */
      }}
    >
      {({ isSubmitting, setSubmitting }) => (
        <Form>
          <div>
            {/* Remove row button. */}
            {onRemove ? (
              <Button
                type={BUTTON_TYPES.TERTIARY}
                size="sm"
                onClick={() => remove(setSubmitting)}
                ariaLabel="Delete Snippet"
                leadingIcon="trash-alt"
                iconTextColor="text-red-500"
              >
                Delete
              </Button>
            ) : null}
          </div>
          {submitErrors && <div className="text-red-700 mr-2">{submitErrors}</div>}
        </Form>
      )}
    </Formik>
  );
};

export default SnippetDeleteButton;

import React from 'react';

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
}

/**
 * This will wrap any children elements in a table-row wrapper, to be used in various Row implementations.
 */
const SettingsRow = ({ children, onClick }: Props) => (
  <div className="table-row border-t border-b border-gray-300 bg-white" onClick={onClick}>
    {children}
  </div>
);

export default React.memo(SettingsRow);

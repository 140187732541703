import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { BUTTON_TYPES } from './Button';
import UploadCsvPreview, { CustomHeaderMap } from './UploadCsvPreview';
import FilePicker from '../elements/FilePicker';
import Modal, { ModalProps } from './Modal';
import Error from './Error';

interface UploadCsvModalProps {
  modalSize?: ModalProps['size'];
  dataType: string;
  example: {
    header: Array<string>;
    data: Array<Array<string>>;
  };
  allowCustomHeaders?: boolean;
  requireConfirmation?: boolean;
  footerText?: React.ReactNode;
  descriptionText?: string;
  isUploading?: boolean;
  uploadErrorMessage?: string;
  onSubmit: (file: File, customHeaderMap?: CustomHeaderMap) => void;
  onCancel: () => void;
}

const UploadCsvModal = ({
  dataType,
  example,
  allowCustomHeaders = false,
  requireConfirmation = false,
  footerText = '',
  descriptionText = '',
  modalSize = 'sm',
  isUploading = false,
  uploadErrorMessage = '',
  onSubmit,
  onCancel,
}: UploadCsvModalProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState(uploadErrorMessage);

  const ACCEPTED_CSV_TYPES = ['application/csv', 'text/csv'];

  useEffect(() => {
    setErrorMessage(uploadErrorMessage);
  }, [uploadErrorMessage]);

  const onClickUpload = () => {
    if (!fileInputRef.current) {
      return;
    }
    fileInputRef.current.click();
  };

  const onSetFile = useCallback((updatedFile: File | null) => {
    setErrorMessage('');
    setFile(updatedFile);
  }, []);

  const onFileInputChange = async (e) => {
    const file = e.currentTarget.files[0];

    if (!ACCEPTED_CSV_TYPES.includes(file.type)) {
      setErrorMessage(`Invalid csv file type. Accepted file types: ${ACCEPTED_CSV_TYPES.join(', ')}`);
      return;
    }

    // File submission will be handled by preview component
    if (allowCustomHeaders) {
      onSetFile(file);
      return;
    }

    // Bypass preview component
    await onSubmit(file);
  };

  const onCancelPreview = (error = '') => {
    onSetFile(null);
    setErrorMessage(error);
  };

  const canSubmit = useMemo(() => {
    return Boolean(file && file.type === 'text/csv');
  }, [file]);

  return (
    <Modal
      size={modalSize}
      isPrimaryActionVisible={requireConfirmation}
      isPrimaryActionEnabled={requireConfirmation && canSubmit}
      onPrimaryAction={() => {
        requireConfirmation && file && onSubmit(file);
      }}
    >
      <button
        onClick={onCancel}
        className="absolute right-5 top-4 text-gray-200 hover:text-gray-400 cursor-pointer disabled:pointer-events-none"
        disabled={isUploading}
      >
        <FontAwesomeIcon icon="times" size="lg" />
      </button>
      <div className="flex flex-col w-full gap-y-2">
        <h1 className="text-center mt-4">Import {dataType}</h1>
        {!requireConfirmation && !(allowCustomHeaders && file) && (
          <div className="flex justify-center my-2 mx-2">
            <input
              ref={fileInputRef}
              type="file"
              value=""
              accept=".csv"
              onChange={(e) => onFileInputChange(e)}
              className="hidden"
              data-testid="import_csv_file_input"
            />
            <Button isDisabled={isUploading} type={BUTTON_TYPES.PRIMARY} leadingIcon="upload" onClick={onClickUpload}>
              {isUploading ? 'Uploading...' : 'Upload .csv'}
            </Button>
          </div>
        )}
        {requireConfirmation && (
          <div className="my-2">
            <FilePicker accept={['.csv']} file={file} setFile={onSetFile} displayMode="column" />
          </div>
        )}
        {errorMessage && <Error text={errorMessage} showIcon={true} />}
        {!(allowCustomHeaders && file) && (
          <div className="flex flex-col gap-y-2 w-full">
            <ul className="ml-4 list-disc list-outside">
              <li>Currently only comma separated value (.csv) files are supported for import.</li>
              {descriptionText && <li>{descriptionText}</li>}
              <li>CSV files can be imported with the following format.</li>
            </ul>
            <ImportCsvExample header={example.header} data={example.data} />
            {footerText && (
              <div className="flex items-start">
                <FontAwesomeIcon icon="exclamation-circle" className="p-1 text-slate-500" />
                <span>{footerText}</span>
              </div>
            )}
          </div>
        )}
        {allowCustomHeaders && file && (
          <div className="my-3 mx-4">
            <UploadCsvPreview
              file={file}
              exampleHeaders={example.header}
              onSubmit={(headerMap) => {
                onSubmit(file, headerMap);
              }}
              onCancel={onCancelPreview}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UploadCsvModal;

interface ImportCsvExampleProps {
  header: string[];
  data: string[][];
}

const renderTableCell = (isHeaderCell: boolean) => (val: string, i: number, a: string[]) => {
  const displayVal = val === '' ? ' ' : val;
  const content = `${displayVal}${i < a.length - 1 ? ', ' : ''}`;
  return isHeaderCell ? <th key={i}>{content}</th> : <td key={i}>{content}</td>;
};
const renderTableHeaderCell = renderTableCell(true);
const renderTableBodyCell = renderTableCell(false);

const ImportCsvExample = ({ header, data }: ImportCsvExampleProps) => {
  return (
    <code>
      <div className="text-sm p-2 bg-gray-200 rounded border border-gray-600 overflow-auto whitespace-pre text-right">
        <table className="table-auto">
          <thead>
            <tr className="capitalize">{header.map(renderTableHeaderCell)}</tr>
          </thead>
          <tbody>
            {data.map((entry, i) => (
              <tr key={i}>{entry.map(renderTableBodyCell)}</tr>
            ))}
          </tbody>
        </table>
      </div>
    </code>
  );
};

import React, { useMemo } from 'react';
import {
  formattedDoyUtcTimestamp,
  formattedLocalDateTime,
  formattedLocalTime,
  formattedUtcTime,
} from '../lib/datetime';

/**
 * A type of 'date' should return empty content.
 */
interface DateTimeTooltipContentProps {
  timestamp: string;
  zone?: string;
  type?: 'date' | 'time' | 'datetime';
}
const DateTimeTooltipContent = React.memo(({ timestamp, zone, type }: DateTimeTooltipContentProps) => {
  const localTime = useMemo(() => {
    if (type === 'date') {
      return null;
    }
    return type === 'time' ? formattedLocalTime(timestamp, zone ?? 'UTC') : formattedLocalDateTime(timestamp);
  }, [timestamp, type, zone]);

  const utcTimestamp = useMemo(() => {
    if (type === 'date') {
      return null;
    }
    return type === 'time' ? formattedUtcTime(timestamp, zone ?? 'UTC') : formattedDoyUtcTimestamp(timestamp);
  }, [timestamp, type, zone]);

  if (type === 'date') {
    return <></>;
  }

  return (
    <div className="p-1">
      <div>{localTime}</div>
      <div>{utcTimestamp}</div>
    </div>
  );
});

export default DateTimeTooltipContent;

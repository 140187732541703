import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

interface UpdateParams {
  [key: string]: string;
}

interface UpdateFunctions {
  replaceSearchParams: (params: UpdateParams) => void;
  pushSearchParams: (params: UpdateParams) => void;
  deleteSearchParams: (keys: string) => void;
}

const useSearchParams = (): UpdateFunctions => {
  const history = useHistory();

  const updateSearchParamsString = useCallback(
    (params: UpdateParams) => {
      const searchParams = new URLSearchParams(history.location.search);
      for (const key of Object.keys(params)) {
        searchParams.set(key, params[key]);
      }
      return searchParams.toString();
    },
    [history]
  );

  const replaceSearchParams = useCallback(
    (params: UpdateParams) => {
      history.replace({
        search: updateSearchParamsString(params),
      });
    },
    [history, updateSearchParamsString]
  );

  const pushSearchParams = useCallback(
    (params: UpdateParams) => {
      history.push({
        search: updateSearchParamsString(params),
      });
    },
    [history, updateSearchParamsString]
  );

  const deleteSearchParams = useCallback(
    (keys: string) => {
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.delete(keys);
      history.replace({
        search: searchParams.toString(),
      });
    },
    [history]
  );

  return { replaceSearchParams, pushSearchParams, deleteSearchParams };
};

export default useSearchParams;

import React, { Fragment, useEffect, useCallback, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Field } from 'formik';
import { isEmptyValue } from 'shared/lib/text';
import ProcedureDiffText from '../ProcedureDiffText';
import Selector from '../../elements/Selector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../../elements/Tooltip';

const TableInputCheckbox = React.memo(() => {
  return (
    <div className="py-1.5 border border-transparent">
      <div className="w-6 h-6 m-auto text-gray-500 border-2 border-gray-500 rounded-sm" title="not checked" />
    </div>
  );
});

const TableInputLabel = React.memo(({ label, isDisabled }) => (
  <div className="m-auto pl-2 ml-1 text-sm font-medium text-gray-600 text-right">
    {isDisabled && <ProcedureDiffText diffValue={label} wrapPlainText={false} />}
    {!isDisabled && label}
  </div>
));

const TableInputTextField = React.memo(
  ({ isDisabled, name, type, onBlur, label, value, error, setFieldValue, icon, iconDescription }) => {
    const [state, setState] = useState({
      active: false,
      value,
    });

    useEffect(() => {
      if (state.active) {
        return;
      }
      setState({
        active: false,
        value,
      });
    }, [value, state.active]);

    const validateValue = useCallback(
      (e) => {
        let value = e.target.value;

        if (type === 'number') {
          /**
           * Number has stricter parsing than parseFloat. Eg, '1a' is NaN for
           * Number but '1' for parseFloat.
           *
           * Reference:
           * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseFloat
           * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number
           */
          const number = Number(value);

          /**
           * Store values as strings so we preserve number of significant digits.
           * However, trim any leading and trailing whitespace since Number allows it.
           */
          if (!isNaN(number)) {
            value = value.trim();
          }
        }

        return value;
      },
      [type]
    );

    const handleOnBlur = useCallback(
      (e) => {
        const _value = validateValue(e);

        setState({
          value: _value,
          active: false,
        });

        // Update recorded value.
        setFieldValue(name, _value);
        onBlur(name, _value);
      },
      [name, setFieldValue, onBlur, validateValue]
    );

    const handleOnChange = useCallback((e) => {
      setState((state) => ({
        ...state,
        value: e.target.value,
      }));
    }, []);

    const handleOnFocus = useCallback(() => {
      setState((state) => ({
        ...state,
        active: true,
      }));
    }, []);

    if (isDisabled) {
      return (
        <Fragment>
          <div className="w-full text-sm px-3 py-2 flex items-center border border-transparent">
            <div className="flex flex-row h-full w-full items-center">
              {/* If there is no text, add a height to the div to prevent cell height change */}
              {isEmptyValue(value) && <span className="h-5"></span>}
              {!isEmptyValue(value) && (
                <ProcedureDiffText
                  diffValue={typeof value === 'object' ? value : `${value}`}
                  useMarkdownWhenNoDiff={true}
                />
              )}
            </div>

            {label && <TableInputLabel label={label} isDisabled={isDisabled} />}
            {icon && (
              <Tooltip content={iconDescription}>
                <FontAwesomeIcon icon={icon} className="text-gray-500" aria-label={iconDescription} />
              </Tooltip>
            )}
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className={`flex flex-row h-full w-full items-center ${label ? 'pr-3' : ''}`}>
            {type === 'text' && (
              <div className="flex flex-col h-full grow min-w-0">
                <Field name={name}>
                  {({ field }) => (
                    <TextareaAutosize
                      data-testid="text-area"
                      className="grow text-sm border border-transparent resize-none"
                      {...field}
                      onBlur={handleOnBlur}
                      onChange={handleOnChange}
                      onFocus={handleOnFocus}
                      value={state.value}
                    />
                  )}
                </Field>
              </div>
            )}

            {type === 'number' && (
              <Field
                name={name}
                type="text"
                className={`grow min-w-0 text-sm border ${
                  error ? 'border-red-700 bg-red-100 bg-opacity-50' : 'border-transparent'
                }`}
                onBlur={handleOnBlur}
                onChange={handleOnChange}
                onFocus={handleOnFocus}
                value={state.value}
              />
            )}

            {label && <TableInputLabel label={label} isDisabled={isDisabled} />}
          </div>
        </Fragment>
      );
    }
  }
);

const ReviewFieldSetTableCell = React.memo(
  ({ name, type, label, onBlur, value, isDisabled, error, setFieldValue, icon, iconDescription }) => (
    <div className="flex flex-col w-full h-full justify-center items-center">
      {type === 'checkbox' && (
        /*
         * Wrap the label around the checkbox and the following text in order to make the entire cell clickable to toggle the check.
         * Ensure minimum clickable area of 44pt x 44pt on mobile.
         */
        <label className="flex w-full h-full min-h-12 justify-center items-center">
          <TableInputCheckbox />
        </label>
      )}
      {type === 'list' && (
        <Field name={name}>
          {({ field }) => (
            <Selector
              type="table-cell"
              name={field.name}
              description="List Value"
              onChangeHandler={() => null}
              selectOptions={[]}
              selectedOption={{ label: value, value }}
              isDisabled={true}
              isDiff={true}
            />
          )}
        </Field>
      )}
      {!['checkbox', 'list'].includes(type) && (
        <TableInputTextField
          isDisabled={isDisabled}
          name={name}
          type={type}
          label={label}
          value={value}
          error={error}
          setFieldValue={setFieldValue}
          onBlur={onBlur}
          icon={icon}
          iconDescription={iconDescription}
        />
      )}
    </div>
  )
);

export default ReviewFieldSetTableCell;

import { Dialog } from 'primereact/dialog';
import Button, { BUTTON_TYPES } from './Button';
import { useHistory } from 'react-router-dom';
import { procedurePendingPath } from '../lib/pathUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';

const HEADER_TEXT = 'This draft has new changes.';
const getBodyText = (editedUserId) =>
  `This draft has been edited by ${editedUserId}. Your changes will not be incorporated.`;

type OtherUserEditConflictModalProps = {
  editedUserId: string;
  procedureId: string;
  onHide: () => void;
};

const OtherUserEditConflictModal = ({ editedUserId, procedureId, onHide }: OtherUserEditConflictModalProps) => {
  const { currentTeamId } = useDatabaseServices();
  const history = useHistory();

  const onExit = () => {
    const path = procedurePendingPath(currentTeamId, procedureId);
    history.push(path);
  };

  return (
    <Dialog
      className="w-1/2"
      header={HEADER_TEXT}
      visible={true}
      onHide={onHide}
      footer={<OtherUserEditConflictModalFooter onExit={onExit} />}
    >
      <div className="mt-4">{getBodyText(editedUserId)}</div>
    </Dialog>
  );
};

const OtherUserEditConflictModalFooter = ({ onExit }) => {
  return (
    <Button type={BUTTON_TYPES.PRIMARY} title="Exit Edit" ariaLabel="Exit Edit" onClick={onExit}>
      Exit Edit
    </Button>
  );
};

export default OtherUserEditConflictModal;

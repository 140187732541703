import React from 'react';
import FieldSetPrintContent from './FieldSetPrintContent';
import { Field, FieldInputProps } from 'formik';
import { useProcedureContext } from '../contexts/ProcedureContext';
import { PrintSettings } from 'shared/lib/types/views/procedures';

interface FieldSetPrintSettingsProps {
  value?: PrintSettings;
  field: FieldInputProps<string>;
}

const FieldSetPrintSettings = ({ value, field }: FieldSetPrintSettingsProps) => {
  const { getAllVariables } = useProcedureContext();

  return (
    <>
      {/* Print Header */}
      <label className="my-1 text-sm font-semibold border-b">Print Header</label>
      <Field
        name={`${field.name}.header`}
        className="h-full border border-gray-400 rounded"
        component={FieldSetPrintContent}
        value={value && value.header}
        variables={getAllVariables()}
      />
      {/* Print Footer */}
      <label className="mt-3 mb-1 text-sm font-semibold border-b">Print Footer</label>
      <Field
        name={`${field.name}.footer`}
        className="h-full border border-gray-400 rounded"
        component={FieldSetPrintContent}
        value={value && value.footer}
        variables={getAllVariables()}
        maxCharacters={350}
      />
    </>
  );
};

export default React.memo(FieldSetPrintSettings);

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../../../elements/Tooltip';
import { SequenceId } from 'shared/lib/types/postgres/information_schema';

export type AutoNumberingAddClearProps = {
  entityId?: string;
  type: SequenceId['type'];
  currValue: string | undefined;
  onAdd: (entityId?: string) => void;
  onClear: (entityId?: string) => void;
};

const NAMES: { [key in SequenceId['type']]: string } = {
  part_numbers: 'part',
  serials: 'serial',
  lot_numbers: 'lot',
  tool_nos: 'tool',
};

const AutoNumberingAddClear = ({ entityId, type, currValue, onAdd, onClear }: AutoNumberingAddClearProps) => {
  return (
    <div className="absolute top-[0.65rem] right-[0.3rem] bg-white">
      <Tooltip
        content={
          <div className="flex flex-col">
            {currValue && <div>Clear {NAMES[type]} number</div>}
            {!currValue && <div>Generate new {NAMES[type]} number</div>}
          </div>
        }
      >
        <div>
          <button
            className={`${
              currValue ? 'text-gray-300 hover:text-gray-400' : 'text-blue-500 hover:text-blue-800'
            } disabled:text-gray-400 ${currValue ? 'mr-[0.2rem]' : 'px-[0.15rem]'}`}
            type="button"
            aria-label={`${currValue ? 'Clear' : 'Generate new'} ${NAMES[type]} number`}
            onClick={() => (currValue ? onClear(entityId) : onAdd(entityId))}
          >
            <FontAwesomeIcon icon={currValue ? 'times-circle' : 'arrow-down-1-9'} />
          </button>
        </div>
      </Tooltip>
    </div>
  );
};

export default AutoNumberingAddClear;

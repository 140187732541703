import {
  Tool,
  ToolInstance,
  ToolInstanceStatus,
} from 'shared/lib/types/api/manufacturing/tools/models';
import { NumberSelectOption as SelectOption } from '../../../lib/formik';

const toolToSelectOption = (tool: Tool): SelectOption => ({
  value: tool.id,
  label: `${tool.tool_number} ${tool.name}`,
});

const toolInstanceToSelectOption = (
  toolInstance: ToolInstance
): SelectOption => ({
  value: toolInstance.id,
  label: toolInstance.serial_number,
});

const isToolInstanceAvailable = (
  toolInstance: ToolInstance,
  type: 'in' | 'out'
): boolean => {
  if (type === 'in') {
    return !!toolInstance.checked_out;
  }
  return (
    !toolInstance.checked_out &&
    toolInstance.status === ToolInstanceStatus.Available
  );
};

export {
  isToolInstanceAvailable,
  toolInstanceToSelectOption,
  toolToSelectOption,
};

import { Fragment, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopupListWithSearch from '../../elements/PopupListWithSearch';

enum FilterState {
  None,
  Some,
  All,
}

export type FilterOption<T> = {
  label: string;
  id: T | null; // null represents lack of filter field
};

interface SearchFilterProps<T> {
  filterTitle: string;
  filterOptions: Array<FilterOption<T>>;
  selectedFilterIds: ReadonlySet<T | null>;
  sortOptions?: boolean;
  disableSelectAll?: boolean;
  ariaLabel?: string;
  onFilterIdsChange?: (ids: ReadonlySet<T | null>) => void;

  // deprecate these
  addSelectedFilter?: (id: T | null) => void;
  removeSelectedFilter?: (id: T | null) => void;
  addAllSelectedFilters?: () => void;
  resetSelectedFilters?: () => void;
}

const SearchFilter = <T,>({
  filterTitle,
  filterOptions,
  selectedFilterIds,
  onFilterIdsChange,
  addSelectedFilter,
  removeSelectedFilter,
  addAllSelectedFilters,
  resetSelectedFilters,
  disableSelectAll = false,
  ariaLabel,
}: SearchFilterProps<T>) => {
  const _filterOptions = useMemo(() => {
    return filterOptions.map((option) => {
      return {
        name: option.label,
        id: option.id as string,
      };
    });
  }, [filterOptions]);

  const _sortedOptions = useMemo(
    () =>
      _filterOptions.sort((a, b) => {
        if (a.id === null) {
          return -1;
        }
        if (b.id === null) {
          return 1;
        }
        return a.name.localeCompare(b.name);
      }),
    [_filterOptions]
  );

  const toggleSelectedFilter = useCallback(
    (id: T | null) => {
      const updated = new Set(selectedFilterIds);
      if (selectedFilterIds.has(id)) {
        updated.delete(id);
        removeSelectedFilter && removeSelectedFilter(id);
      } else {
        updated.add(id);
        addSelectedFilter && addSelectedFilter(id);
      }
      onFilterIdsChange && onFilterIdsChange(updated);
    },
    [selectedFilterIds, onFilterIdsChange, removeSelectedFilter, addSelectedFilter]
  );

  const onSelectAllFilters = useCallback(
    (event) => {
      // Don't trigger button or document onClick handler.
      event.stopPropagation();
      if (filterOptions.length === 0 || disableSelectAll) {
        return;
      }
      onFilterIdsChange && onFilterIdsChange(new Set(filterOptions.map((option) => option.id)));
      addAllSelectedFilters && addAllSelectedFilters();
    },
    [filterOptions, disableSelectAll, onFilterIdsChange, addAllSelectedFilters]
  );

  const onResetSelectedFilters = useCallback(
    (event) => {
      // Don't trigger button or document onClick handler.
      event.stopPropagation();

      onFilterIdsChange && onFilterIdsChange(new Set());
      resetSelectedFilters && resetSelectedFilters();
    },
    [onFilterIdsChange, resetSelectedFilters]
  );

  const currentSelectionState = useMemo(() => {
    if (filterOptions.length === 0) {
      return FilterState.None;
    }

    if (selectedFilterIds.size === 0) {
      return FilterState.None;
    }

    if (selectedFilterIds.size === filterOptions.length) {
      return FilterState.All;
    }

    return FilterState.Some;
  }, [filterOptions, selectedFilterIds]);

  return (
    <PopupListWithSearch
      options={_sortedOptions}
      onSelect={(option) => {
        return Promise.resolve(toggleSelectedFilter(option.id as T));
      }}
      dismissOnSelect={false}
      Components={{
        Trigger: () => {
          return (
            <button className="flex items-center p-2 text-xs lg:text-sm relative dark:text-white">
              {filterOptions.length === 0 && (
                <FontAwesomeIcon
                  aria-label={`No ${filterTitle} Available`}
                  className="bg-gray-300 opacity-50"
                  icon={['far', 'square']}
                />
              )}
              {filterOptions.length > 0 && (
                <Fragment>
                  {currentSelectionState === FilterState.Some && (
                    <FontAwesomeIcon
                      aria-label={`Some ${filterTitle} Selected`}
                      icon="minus-square"
                      onClick={onResetSelectedFilters}
                    />
                  )}
                  {currentSelectionState === FilterState.All && (
                    <FontAwesomeIcon
                      aria-label={`All ${filterTitle} Selected`}
                      icon={['far', 'check-square']}
                      onClick={onResetSelectedFilters}
                    />
                  )}
                  {currentSelectionState === FilterState.None && (
                    <FontAwesomeIcon
                      aria-label={`No ${filterTitle} Selected`}
                      title={disableSelectAll ? 'Selecting all filters is disabled' : 'Select All'}
                      icon={['far', 'square']}
                      className={disableSelectAll ? 'text-gray-400' : ''}
                      onClick={onSelectAllFilters}
                    />
                  )}
                </Fragment>
              )}

              <span className="px-2 whitespace-nowrap">{filterTitle}</span>
              <FontAwesomeIcon className="text-blue-500" icon="caret-down" />
            </button>
          );
        },
        ListItem: (option, searchTerm) => {
          let before, match, after;
          if (option) {
            const highlightStart = option.name.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase());
            before = option.name.substring(0, highlightStart);
            match = option.name.substring(highlightStart, highlightStart + searchTerm.length);
            after = option.name.substring(highlightStart + searchTerm.length);
          }
          return (
            <div className="flex flex-row items-center w-full whitespace-nowrap">
              {selectedFilterIds.has(option.id as T) && (
                <FontAwesomeIcon className="mr-2" icon={['far', 'check-square']} />
              )}
              {!selectedFilterIds.has(option.id as T) && <FontAwesomeIcon className="mr-2" icon={['far', 'square']} />}
              <span title={option.name} className="truncate max-w-[180px]">
                {!searchTerm && option.name}
                {searchTerm && (
                  <>
                    <span>{before}</span>
                    <span className="bg-yellow-200">{match}</span>
                    <span>{after}</span>
                  </>
                )}
              </span>
            </div>
          );
        },
      }}
      pt={{
        Trigger: {
          'aria-label': ariaLabel,
        },
      }}
    />
  );
};

export default SearchFilter;

import superlogin, { getSession, setSession } from './superlogin';
import { API_URL } from '../config';

/**
 * Use this method to register a new user with an invite token.
 * @param {String} token
 * @param {String} password
 * @param {String} confirmPassword
 * @returns {Promise}
 */
const registerWithInvite = (
  token: string,
  password: string,
  confirmPassword: string
): Promise<void> => {
  const url = `${API_URL}/invites/register`;
  const body = {
    token,
    password,
    confirmPassword,
  };

  return superlogin
    .getHttp()
    .post(url, body)
    .then((response) => response.data)
    .then(async (session) => {
      // TODO: Clean up mixed-use of promise chains and async/await.
      if (session.user_id && session.token) {
        session.serverTimeDiff = session.issued - Date.now();
        await superlogin.setSession(session);
        superlogin._onLogin(session);
      }
      superlogin._onRegister(body);
    })
    .catch((error) => {
      const statusCode = error && error.response && error.response.status;

      if (statusCode >= 400 && statusCode <= 499) {
        return Promise.reject(error.response.data);
      }

      return Promise.reject(error);
    });
};

/**
 * Accept a team workspace invitation by invite token.
 *
 * Requires the user to be logged in with a valid session.
 * @param {String} token - Team invite token.
 * @returns {Promise} - Promise representing the server response or error.
 */
const acceptInvite = (token: string): Promise<void> => {
  const url = `${API_URL}/invites/accept`;
  const body = { token };

  return superlogin
    .getHttp()
    .post(url, body)
    .then((response) => response.data)
    .then(async (results) => {
      // Update session with new user org data.
      const session = await getSession();
      if (session) {
        session.userOrgData = results.userOrgData;
        await setSession(session);
      }
    })
    .catch((error) => {
      const statusCode = error && error.response && error.response.status;

      if (statusCode >= 400 && statusCode <= 499) {
        return Promise.reject(error.response.data);
      }

      return Promise.reject(error);
    });
};

export type TokenDetails = {
  token: string;
  existingUser: boolean;
  loaded: boolean;
  valid?: boolean;
};
/**
 * Get some information about a token.
 * This is publicly safe information so we can
 * better route the user on how to interact with it.
 *
 * @param {String} token - Team invite token.
 * @returns {Promise} - Promise representing infomration about the invite
 */
const getTokenDetails = async (token: string): Promise<TokenDetails> => {
  const url = `${API_URL}/invites/${token}/details`;
  const response = await superlogin.getHttp().get(url);
  return response.data;
};

export type PendingInvite = {
  inviteId: string;
  token: string;
  teamName: string;
  teamId: string;
  createdAt: string;
};
/**
 * Get a list of invites pending the current user
 *
 * Requires the user to be logged in with a valid session.
 * @param {String} token - Team invite token.
 * @returns {Promise} - Promise representing the server response or error.
 */
const getPendingInvites = async (): Promise<Array<PendingInvite>> => {
  const url = `${API_URL}/invites/pending`;
  const response = await superlogin.getHttp().get(url);
  return response.data;
};

export { acceptInvite, registerWithInvite, getPendingInvites, getTokenDetails };

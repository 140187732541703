import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface StepDetailProps {
  icon: IconProp;
  value: string | Array<string>;
  label?: string;
}

const StepDetail: React.FC<StepDetailProps> = React.memo(
  ({
    icon, // fontawesome icon key
    value, // an array of strings or a string.
    label,
  }) => {
    const iconName = useMemo(() => {
      return icon || 'cog';
    }, [icon]);

    const displayText = useMemo(() => {
      if (Array.isArray(value)) {
        return value.join(', ');
      }

      return value;
    }, [value]);

    return (
      <div className="flex flex-row py-1">
        <div className="text-gray-400 mr-1 whitespace-nowrap">
          <FontAwesomeIcon icon={iconName} />
          <span className="ml-1">{`${label}:`}</span>
        </div>
        <div>{displayText}</div>
      </div>
    );
  }
);

export default StepDetail;

import { useMemo } from 'react';
import ProcedureDiffText from '../ProcedureDiffText';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import DiffContainer from '../Diff/DiffContainer';
import { AttachmentBlockDiffElement, DiffArrayChangeSymbol } from 'shared/lib/types/views/procedures';
import InlineImageContent, { InlineImageContentAttachmentBlock } from '../InlineImageContent';

interface ReviewInlineImageProps {
  attachment: AttachmentBlockDiffElement;
  showCaptionText: boolean;
  url?: string;
  onSave?: (file: File) => void;
  onClose?: () => void;
  isAnnotating?: boolean;
  diffChangeState?: DiffArrayChangeSymbol;
}

const ReviewInlineImage = ({
  attachment,
  url,
  showCaptionText,
  onSave,
  onClose,
  isAnnotating = false,
  diffChangeState = ARRAY_CHANGE_SYMBOLS.UNCHANGED,
}: ReviewInlineImageProps) => {
  const attachmentName: string = useMemo(
    () => (attachment ? sharedDiffUtil.getDiffValue(attachment, 'name', 'new') : ''),
    [attachment]
  );

  // Wrap img in plain div to fix nested flexbox issue on Safari
  return (
    // Apply image CSS sizing to parent and image so buttons are positioned correctly.
    <div className="table">
      {!url && (
        <DiffContainer label="File" diffChangeState={diffChangeState} isTextSticky={false}>
          <div className="border-gray-200 border p-5 text-xs max-w-sm">
            <div>{attachmentName}</div>
          </div>
        </DiffContainer>
      )}
      {url && (
        <>
          <DiffContainer label="File" diffChangeState={diffChangeState} isTextSticky={false}>
            <InlineImageContent
              attachment={attachment as InlineImageContentAttachmentBlock}
              url={url}
              onSave={onSave}
              onClose={onClose}
              isAnnotating={isAnnotating}
            />
          </DiffContainer>
          {showCaptionText && (
            <span aria-label="Attachment Caption" className="text-xs table-caption [caption-side:bottom]">
              <ProcedureDiffText diffValue={attachment.caption || ''} diffChangeState={attachment.diff_change_state} />
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default ReviewInlineImage;

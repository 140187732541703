import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import QRModal from './QRModal';

type TrackingWithQRCodeParams = {
  name: string;
  url: string;
};

const TrackingWithQRCode = ({ name, url }: TrackingWithQRCodeParams) => {
  const [showQrCode, setShowQrCode] = useState<boolean>(false);

  return (
    <>
      {showQrCode && <QRModal name={name} url={url} onClose={() => setShowQrCode(false)} />}
      <button onClick={() => setShowQrCode(true)} title="Show QR Code" aria-label="Show QR Code">
        <div className="flex flex-row">
          <FontAwesomeIcon className="m-1 hover:cursor-pointer text-slate-700" icon="qrcode" />
        </div>
      </button>
    </>
  );
};

export default TrackingWithQRCode;

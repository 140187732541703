import { DIFF_COLOR_CLASSES } from '../../lib/diffUtil';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import React from 'react';

interface DiffOldAndNewProps {
  previous: React.ReactNode;
  updated: React.ReactNode;
  hasChanged: boolean;
  label: string;
}

/**
 * Provides a full before and after comparison of previous and updated.
 */
const DiffOldAndNew = ({ previous, updated, hasChanged, label }: DiffOldAndNewProps) => {
  return (
    <div className="flex flex-col gap-2 w-fit items-start">
      <div
        aria-label={hasChanged ? `New ${label}` : label}
        className={`max-w-max break-all text-sm flex flex-row items-center flex-wrap ${
          hasChanged
            ? `py-1 px-2 rounded ${DIFF_COLOR_CLASSES[ARRAY_CHANGE_SYMBOLS.ADDED].backgroundColor} ${
                DIFF_COLOR_CLASSES[ARRAY_CHANGE_SYMBOLS.ADDED].borderColor
              }`
            : ''
        }`}
      >
        {updated}
      </div>
      {hasChanged && (
        <div
          aria-label={`Old ${label}`}
          className={`relative max-w-max py-1 px-2 break-all text-sm flex flex-row items-center flex-wrap rounded ${
            DIFF_COLOR_CLASSES[ARRAY_CHANGE_SYMBOLS.REMOVED].backgroundColor
          } ${DIFF_COLOR_CLASSES[ARRAY_CHANGE_SYMBOLS.REMOVED].borderColor}`}
        >
          {previous}
          <div className="absolute left-1 right-1 h-[1.5px] top-1/2 -translate-y-1/2 bg-red-600 opacity-80" />
        </div>
      )}
    </div>
  );
};

export default DiffOldAndNew;

import { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useMixpanel } from '../contexts/MixpanelContext';
import FormBlockEdit from '../components/Blocks/FormBlockEdit';
import ProcedureBlockEdit from '../components/Blocks/ProcedureBlockEdit';
import useBlockRedlines from '../hooks/useBlockRedlines';

const withBlockRedlining =
  (Component) =>
  ({
    block,
    redlines,
    contentIndex,
    showsRedlineAction,
    onDirtyChanged,
    onSubmitEdit,
    blockLabel,
    isEditing,
    setIsEditing,
    defaultEditFormState = false,
    step = undefined,
    ...other
  }) => {
    const [showsEditForm, setShowsEditForm] = useState(defaultEditFormState);
    const { latestBlock } = useBlockRedlines({
      block,
      redlines,
    });
    const { mixpanel } = useMixpanel();
    const isMounted = useRef(true);

    const closeForm = useCallback(() => {
      setIsEditing && setIsEditing(false);
      setShowsEditForm(false);
    }, [setIsEditing]);

    const openForm = useCallback(() => {
      setIsEditing && setIsEditing(true);
      setShowsEditForm(true);
    }, [setIsEditing]);

    useEffect(
      () => () => {
        isMounted.current = false;
      },
      []
    );

    // Keep `isRedlineActive` prop in sync with internal state,
    useEffect(() => {
      /*
       * If `isEditMode` is false, also hide (cancel) the edit form.
       * If `isEditMode` is true, let `showsEditForm` control form behavior.
       */
      if (!showsRedlineAction) {
        closeForm();
      }
    }, [showsRedlineAction, closeForm]);

    const cancelFormHandler = useCallback(() => {
      if (mixpanel) {
        mixpanel.track('Redline Cancelled', { 'Block Type': block.type });
      }
      closeForm();
      // Since form has closed, notify parent dirty has changed
      if (typeof onDirtyChanged === 'function') {
        onDirtyChanged(false, contentIndex);
      }
    }, [mixpanel, closeForm, onDirtyChanged, block.type, contentIndex]);

    const dirtyChangedHandler = useCallback(
      (dirty) => {
        if (typeof onDirtyChanged === 'function') {
          onDirtyChanged(dirty, contentIndex);
        }
      },
      [onDirtyChanged, contentIndex]
    );

    const saveFormHandler = useCallback(
      (values) => {
        if (typeof onSubmitEdit === 'function') {
          return onSubmitEdit(values).then(() => {
            if (!isMounted.current) {
              return;
            }
            closeForm();
          });
        }
      },
      [closeForm, onSubmitEdit]
    );

    const showEditFormAction = {
      icon: 'pencil-alt',
      onAction: openForm,
      ariaLabel: 'Edit',
    };

    // TODO: Update "text" and "alert" to new component interfaces and remove this.
    const useLegacyInterface = useMemo(() => latestBlock.type.toLowerCase() === 'text', [latestBlock.type]);

    return (
      <>
        {showsRedlineAction && isEditing && showsEditForm && (
          <>
            {/* TODO: FormBlockEdit is deprecated, can remove after "text" and
            "alert" blocks are refactored to use new components and interfaces. */}
            {useLegacyInterface && (
              <FormBlockEdit
                block={latestBlock}
                contentIndex={contentIndex}
                onDirtyChanged={dirtyChangedHandler}
                onCancel={cancelFormHandler}
                onSubmit={saveFormHandler}
                step={step}
                {...other}
              />
            )}
            {!useLegacyInterface && (
              <ProcedureBlockEdit
                block={latestBlock}
                contentIndex={contentIndex}
                onDirtyChanged={dirtyChangedHandler}
                onCancel={cancelFormHandler}
                onSubmit={saveFormHandler}
                step={step}
                {...other}
              />
            )}
          </>
        )}
        {(!showsRedlineAction || !isEditing || !showsEditForm) && (
          <>
            {/* TODO: Refactor "text" and "alert" blocks to new component interfaces
            and remove this. */}
            {useLegacyInterface && (
              <Component
                blockLabel={blockLabel}
                block={block}
                redlines={redlines}
                contentIndex={contentIndex}
                showsRedlineAction={showsRedlineAction}
                isEditing={isEditing}
                onRedlineAction={openForm}
                {...other}
              />
            )}
            {!useLegacyInterface && (
              <Component
                blockLabel={blockLabel}
                block={block}
                redlines={redlines}
                contentIndex={contentIndex}
                actions={showsRedlineAction && !isEditing ? [showEditFormAction] : []}
                {...other}
              />
            )}
          </>
        )}
      </>
    );
  };

export default withBlockRedlining;

import { useEffect, useState } from 'react';
import quantitiesUtil from 'shared/lib/manufacturing/inventory/util/quantities';

const DEFAULT_INTEGER_MIN = 1;
const DEFAULT_DECIMAL_MIN = 0.0001;
const DEFAULT_MAX = 1e10 - 1; // max 10 digit integer

export type ItemQuantityInputProps = {
  value?: number;
  onChange: (value: number | null) => void;
  allowDecimalValue: boolean;
  allowNull?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  placeholder?: string;
  w?: number;
  textSize?: 'sm' | 'base';
};

const ItemQuantityInput: React.FC<ItemQuantityInputProps> = ({
  value,
  onChange,
  allowDecimalValue,
  allowNull = true,
  disabled = false,
  min: minFromProps,
  max: maxFromProps,
  placeholder,
  w,
  textSize = 'base',
}) => {
  const [inputValue, setInputValue] = useState<string>(value?.toString() ?? '');

  const min = minFromProps ?? (allowDecimalValue ? DEFAULT_DECIMAL_MIN : DEFAULT_INTEGER_MIN);
  const max = maxFromProps ?? DEFAULT_MAX;

  // needed in procedure runs for substep sync to work
  useEffect(() => {
    setInputValue(value?.toString() ?? '');
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleOnBlur = () => {
    let numericValue = allowDecimalValue ? parseFloat(inputValue) : parseInt(inputValue, 10);

    if (!isNaN(numericValue)) {
      numericValue = quantitiesUtil.toDefaultPrecision(numericValue);
      numericValue = Math.max(numericValue, min);
      numericValue = Math.min(numericValue, max);
      onChange(numericValue);
      setInputValue(String(numericValue));
    } else {
      onChange(allowNull ? null : min);
      setInputValue(allowNull ? '' : String(min));
    }
  };

  return (
    <input
      name="quantity"
      aria-label="quantity"
      type={allowDecimalValue ? 'text' : 'number'}
      className={`border-1 border-gray-300 rounded disabled:bg-gray-100 text-${textSize} w-${w ?? 'full'}`}
      value={inputValue}
      onChange={handleInputChange}
      onBlur={handleOnBlur}
      disabled={disabled}
      inputMode={allowDecimalValue ? 'decimal' : 'numeric'}
      placeholder={placeholder}
      min={min}
      max={max}
    />
  );
};

export default ItemQuantityInput;

import React from 'react';
import ButtonActionIcon from './../ButtonActionIcon';
import Spacer from '../Spacer';
import { TypedProcedureBlockProps } from './BlockTypes';

/*
 * Renders a ExternalItem block within a procedure context.
 * Conforms to TypedProcedureBlock, see comments in useBlockComponents
 */
const ExternalItemProcedureBlock = ({ isHidden, actions, children }: TypedProcedureBlockProps) => {
  return (
    <tr aria-label="Content Block" role="region">
      <td></td>
      <td colSpan={2}>
        <div className={`mt-2 mr-8 flex page-break ${isHidden ? 'hidden' : ''}`}>
          <Spacer />

          {/* Render child content */}
          <div className="min-w-0 w-full">{children}</div>

          {/* Render action buttons */}
          {actions &&
            actions.map((action) => (
              <span key={action.ariaLabel} className="ml-1">
                <ButtonActionIcon
                  icon={action.icon}
                  onAction={action.onAction}
                  ariaLabel={action.ariaLabel}
                  label={undefined}
                  disabled={undefined}
                  iconType={undefined}
                  pendingAction={action.pendingAction}
                />
              </span>
            ))}
        </div>
      </td>
    </tr>
  );
};

export default ExternalItemProcedureBlock;

import RunProgressBar from '../../RunProgressBar';

const renderRunProgressBar = (value) => {
  if (!(value && value.summary)) {
    return <></>;
  }
  const runStatus = {
    id: value.summary._id,
    state: value.summary.state,
    status: value.summary.status,
  };
  const stepCounts = {
    totalCount: value.summary.total_steps,
    completedCount: value.summary.completed_steps,
    notRequiredCount: value.summary.not_required_steps,
    skippedCount: value.summary.skipped_steps,
    failedCount: value.summary.failed_steps,
  };
  return <RunProgressBar runStatus={runStatus} stepCounts={stepCounts} isResponsive={true} />;
};

export default renderRunProgressBar;

import ProcedureStep from './ProcedureStep';
import procedureUtil from '../lib/procedureUtil';
import PromptRedline from './PromptRedline';
import React, { useCallback } from 'react';
import { DraftAddedStep as DraftAddedStepType } from 'shared/lib/types/views/procedures';
import { useSettings } from '../contexts/SettingsContext';
import { runViewStepPath } from '../lib/pathUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';

interface AddedStepProps {
  sectionIndex: number;
  stepIndex: number;
  addedStep: DraftAddedStepType;
  errors: { stepAddedDuringRun?: string; message?: string };
  onAcceptRedlineStep: (sectionIndex: number, stepIndex: number, step: DraftAddedStepType) => void;
  onRejectRedlineStep: (sectionIndex: number, stepIndex: number, step: DraftAddedStepType) => void;
  isCollapsedMap: { [id: string]: boolean };
  onCollapse: () => void;
}

const DraftAddedStep = ({
  sectionIndex,
  stepIndex,
  errors,
  addedStep,
  onAcceptRedlineStep,
  onRejectRedlineStep,
  isCollapsedMap,
  onCollapse,
}: AddedStepProps) => {
  const { getSetting } = useSettings();
  const { currentTeamId } = useDatabaseServices();

  const onAccept = useCallback(
    () => onAcceptRedlineStep(sectionIndex, stepIndex, addedStep),
    [onAcceptRedlineStep, sectionIndex, stepIndex, addedStep]
  );
  const onReject = useCallback(
    () => onRejectRedlineStep(sectionIndex, stepIndex, addedStep),
    [onRejectRedlineStep, sectionIndex, stepIndex, addedStep]
  );

  return (
    <PromptRedline
      allRedlineUserIds={[addedStep.created_by]}
      timestamp={addedStep.created_at}
      error={errors?.stepAddedDuringRun}
      onAccept={onAccept}
      onReject={onReject}
      contextUrl={addedStep.source_run_id ? runViewStepPath(currentTeamId, addedStep.source_run_id, addedStep.id) : ''}
      isLocationAmbiguous={addedStep.orphaned || addedStep.moved}
    >
      <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
        <thead>
          <tr>
            <th className="w-8" />
            <th className="w-auto" />
            <th className="w-64" />
          </tr>
        </thead>
        {/* TODO: When ProcedureStep is updated to .tsx, update fields to be optional where necessary. Until then, ignore the missing attributes TS error. */}
        {/* @ts-ignore */}
        <ProcedureStep
          step={addedStep}
          stepKey={procedureUtil.displayStepKey(stepIndex)}
          sectionKey={procedureUtil.displaySectionKey(sectionIndex, getSetting('display_sections_as', 'letters'))}
          isCollapsed={isCollapsedMap[addedStep.id]}
          onStepCollapse={onCollapse}
        />
      </table>
    </PromptRedline>
  );
};

export default DraftAddedStep;

import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import ProcedureDiffText from '../ProcedureDiffText';
import { DiffArrayChangeSymbol, DiffFieldChange } from 'shared/lib/types/views/procedures';
import diffUtil from '../../lib/diffUtil';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import DiffContainer from '../Diff/DiffContainer';
import { StepTiming } from 'shared/lib/types/couch/procedures';
import { Duration } from 'luxon';

interface StepDetailProps {
  icon: IconProp;
  value:
    | string
    | Array<string>
    | DiffFieldChange<string | number>
    | Array<DiffFieldChange<string | number>>
    | StepTiming;
  label?: string;
  diffChangeState?: DiffArrayChangeSymbol;
}

const ReviewStepDetail: React.FC<StepDetailProps> = React.memo(
  ({
    icon, // fontawesome icon key
    value, // an array of strings or a string.
    label,
    diffChangeState,
  }) => {
    const iconName = useMemo(() => {
      return icon || 'cog';
    }, [icon]);

    const displayText = useMemo(() => {
      if (Array.isArray(value)) {
        return diffUtil.joinArrayDiff(value as Array<string>);
      }

      if (label === 'Duration' && typeof value === 'object') {
        return 'HH:MM:SS';
      }
      if (label === 'Timer') {
        return Duration.fromISO((value as StepTiming).time_left).toFormat('hh:mm:ss');
      }

      return value;
    }, [value, label]);

    return (
      <div className="flex flex-row py-1">
        <DiffContainer label="Step detail" showModified={false} diffChangeState={diffChangeState} isTextSticky={false}>
          <div className="flex flex-row">
            <div className="text-gray-400 mr-1 whitespace-nowrap">
              <FontAwesomeIcon icon={iconName} />
              <span className="ml-1">{`${label}:`}</span>
            </div>
            <div>
              <ProcedureDiffText
                diffValue={displayText as string}
                // We don't want to show strikethrough or red text for added/removed step details, since we're already using a diff container.
                diffChangeState={
                  diffChangeState === ARRAY_CHANGE_SYMBOLS.REMOVED || diffChangeState === ARRAY_CHANGE_SYMBOLS.ADDED
                    ? ARRAY_CHANGE_SYMBOLS.UNCHANGED
                    : diffChangeState
                }
              />
            </div>
          </div>
        </DiffContainer>
      </div>
    );
  }
);

export default ReviewStepDetail;

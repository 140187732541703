import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { Unit } from 'shared/lib/types/api/settings/units/models';
import {
  CreateUnitReq,
  UpdateUnitReq,
} from 'shared/lib/types/api/settings/units/requests';
import {
  CreateUnitRes,
  ListUnitsRes,
} from 'shared/lib/types/api/settings/units/responses';
import superlogin from '../../api/superlogin';
import { API_URL } from '../../config';

class UnitsService {
  static instances = {};

  static getInstance = (teamId: string): UnitsService => {
    if (!UnitsService.instances[teamId]) {
      UnitsService.instances[teamId] = new UnitsService(teamId);
    }

    return UnitsService.instances[teamId];
  };

  static removeInstance = (teamId: string): void => {
    delete UnitsService.instances[teamId];
  };

  private readonly teamId: string;
  private readonly restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    this.restUrl = `${API_URL}/teams/${this.teamId}/settings`;
  }

  async createUnit(unit: CreateUnitReq): Promise<number> {
    const url = `${this.restUrl}/units`;
    const req: CreateUnitReq = unit;
    const response = await superlogin.getHttp().post<CreateUnitRes>(url, req);
    return response.data.id;
  }

  async getUnits(): Promise<Array<Unit>> {
    const url = `${this.restUrl}/units`;
    const response = await superlogin.getHttp().get<ListUnitsRes>(url);
    return response.data.units;
  }

  async updateUnit(unit: Unit, unitId: number): Promise<AxiosResponse> {
    const url = `${this.restUrl}/units/${unitId}`;
    const req: UpdateUnitReq = _.omit(unit, 'id');
    return superlogin.getHttp().patch(url, req);
  }

  async deleteUnit(unitId: number): Promise<AxiosResponse> {
    const url = `${this.restUrl}/units/${unitId}`;
    return superlogin.getHttp().delete(url);
  }
}

export default UnitsService;

import React, { useContext } from 'react';

export type BlockState = {
  isValid: boolean;
};

const BlockContext = React.createContext<BlockState | undefined>(undefined);

export const BlockProvider = BlockContext.Provider;

export const useBlockState = (): BlockState => {
  const context = useContext(BlockContext);

  if (context === undefined) {
    throw new Error('useBlockState must be used within a BlockProvider');
  }

  return context;
};

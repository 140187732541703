import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from 'primereact/dialog';
import Button, { BUTTON_TYPES } from '../Button';
import './RunBatchProcedureModal.css';
import useManufacturingTrack from '../../manufacturing/hooks/useManufacturingTrack';

const DEFAULT_BATCH_SIZE = 2;
const REQUIRED_ERROR = 'Required';
const INTEGER_ERROR = 'Must be an integer';
const MIN_BATCH_SIZE = 1;
const MIN_BATCH_SIZE_ERROR = `Must be at least ${MIN_BATCH_SIZE}`;
const MAX_BATCH_SIZE = 100;
const MAX_BATCH_SIZE_ERROR = `Must be ${MAX_BATCH_SIZE} or less`;

interface RunBatchProcedureModalProps {
  onRun: (batchSize: number) => void;
  onCancel: () => void;
}

interface BatchSizeFormData {
  batchSize: number;
}

const StartRunButton = ({ onStartRun, isDisabled }: { onStartRun: () => void; isDisabled: boolean }) => (
  <Button type={BUTTON_TYPES.PRIMARY} ariaLabel="Start Run" isDisabled={isDisabled} onClick={onStartRun}>
    Start Run
  </Button>
);

const CancelButton = ({ onCancel }: { onCancel: () => void }) => (
  <Button type={BUTTON_TYPES.SECONDARY} ariaLabel="Cancel" onClick={onCancel}>
    Cancel
  </Button>
);

const RunBatchProcedureModal = ({ onRun, onCancel }: RunBatchProcedureModalProps) => {
  const manufacturingTrack = useManufacturingTrack();

  const onSubmit = (values: BatchSizeFormData) => {
    manufacturingTrack('Run Procedure With Batch Steps');
    onRun(values.batchSize);
  };

  const BatchSizeFormSchema = Yup.object().shape({
    batchSize: Yup.number()
      .integer(INTEGER_ERROR)
      .required(REQUIRED_ERROR)
      .min(MIN_BATCH_SIZE, MIN_BATCH_SIZE_ERROR)
      .max(MAX_BATCH_SIZE, MAX_BATCH_SIZE_ERROR),
  });

  const getFooter = ({ isStartDisabled, onStartRun }: { isStartDisabled: boolean; onStartRun: () => void }) => (
    <div className="flex flex-row-reverse gap-x-2">
      <StartRunButton onStartRun={onStartRun} isDisabled={isStartDisabled} />
      <CancelButton onCancel={onCancel} />
    </div>
  );

  return (
    <Formik
      initialValues={{ batchSize: DEFAULT_BATCH_SIZE }}
      validationSchema={BatchSizeFormSchema}
      onSubmit={onSubmit}
    >
      {({ values, isValid, isSubmitting }) => (
        <Form>
          <Dialog
            header="Batch Build Run"
            footer={() => getFooter({ isStartDisabled: !isValid || isSubmitting, onStartRun: () => onSubmit(values) })}
            onHide={onCancel}
            visible={true}
            className="w-1/4"
          >
            <div className="flex flex-col items-start gap-y-4">
              <div className="text-base">Set batch quantity</div>
              <div className="flex flex-col items-start gap-y-1">
                <label className="text-base font-semibold text-gray-500">Batch Quantity</label>
                <Field
                  id="batchSize"
                  name="batchSize"
                  aria-label="Batch Quantity"
                  type="number"
                  min={MIN_BATCH_SIZE}
                  max={MAX_BATCH_SIZE}
                  className="border-1 border-gray-300 rounded disabled:bg-gray-100 text-sm w-15"
                />
                <ErrorMessage name="batchSize" component="div" className="field-error text-sm text-red-700" />
              </div>
            </div>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

export default RunBatchProcedureModal;

import superlogin from './superlogin';
import { API_URL } from '../config';
import procedureVariableUtil from '../lib/procedureVariableUtil';
const ERROR_RETRY_TIME = 30000; // in milliseconds
class TelemetryService {
  constructor(teamId) {
    this.teamId = teamId;
    this.failureMap = {};
  }

  /**
   * Searches telemetry parameters by name, case insensitive.
   *
   * term: String, search term. Eg 'test', 'test.vehic', 'test.vehicle'. If
   *       empty, all parameters are returned up to the API limit.
   * returns: Promise, A list of Parameter objects from the API.
   */
  searchParameters(term, dictionaryIds) {
    const url = `${API_URL}/teams/${this.teamId}/telemetry/parameters/search`;
    const params = { term };
    if (dictionaryIds) {
      params.dictionaryIds = dictionaryIds;
    }
    return superlogin
      .getHttp()
      .get(url, { params })
      .then((response) => response.data);
  }

  /**
   * Lists all telemetry parameters.
   *
   * returns: Promise, a list of parameter objects from the API.
   */
  listAllParameters() {
    const url = `${API_URL}/teams/${this.teamId}/telemetry/parameters`;
    return superlogin
      .getHttp()
      .get(url)
      .then((response) => response.data);
  }

  /**
   * Gets a telemetry parameter by exact name.
   *
   * name: String, A parameter name, eg 'test.node.int'.
   * returns: Promise, A parameter object from the API.
   */
  getParameterByName(name, dictionaryId) {
    const url = `${API_URL}/teams/${this.teamId}/telemetry/parameters/query`;
    const params = {
      name,
      dictionary_id: dictionaryId,
    };
    return superlogin
      .getHttp()
      .get(url, { params })
      .then((response) => response.data);
  }

  /**
   * Gets the latest sample value for the given telemetry parameter.
   *
   * @param {String} name - A telemetry parameter name. Eg, 'test.voltage.1'
   * @param {Object} operation - The current run operation.
   * @param {string} dictionaryId
   * @param {Array} variables - List of current procedure variables and their values.
   * @param {string} runId
   * @param {string} streamId
   * @returns {Promise} sample - A telemetry sample object from the API.
   */
  async getLatestSample(name, operation, dictionaryId, variables, runId, streamId) {
    const url = `${API_URL}/teams/${this.teamId}/telemetry/samples/latest`;
    const dataTransferVariables = procedureVariableUtil.getDataTransferVariables(variables);
    const body = {
      name,
      operation,
      dictionaryId,
      variables: dataTransferVariables,
      run_id: runId,
      stream_id: streamId,
    };
    const errorKey = `${this.teamId}_${JSON.stringify(body)}`;
    if (this.paramHasError(errorKey)) {
      // if the last attempt to ge this value was an error, just wait.
      throw new Error('Parameter error backoff');
    }
    try {
      const result = await superlogin.getHttp().post(url, body);
      const data = result.data;
      data.streamId = data.stream_id;
      return data;
    } catch (error) {
      this.setParamError(errorKey);
      throw error;
    }
  }

  setParamError(errorKey) {
    const now = Date.now();
    this.failureMap[errorKey] = now;
  }

  paramHasError(errorKey) {
    const now = Date.now();
    if (this.failureMap[errorKey]) {
      const timeout = this.failureMap[errorKey] + ERROR_RETRY_TIME;
      if (timeout > now) {
        return true;
      } else {
        delete this.failureMap[errorKey];
        return false;
      }
    }
    return false;
  }

  /**
   * Gets the latest sample value for the given telemetry parameter.
   *
   * @param {Array<object>} parameters - An array of telemetry parameters containing name, stream ID and dictionary ID
   * @param {string} runId
   * @param {Array<object>} [previewProcedureVariables]
   * @returns {Promise} sample - A Map of telemetry samples from the API.
   */
  getLatestSampleBulk(parameters, runId, previewProcedureVariables) {
    const url = `${API_URL}/teams/${this.teamId}/telemetry/samples/latestbulk`;
    const message = buildStreamPayload(parameters, runId, previewProcedureVariables);
    return superlogin
      .getHttp()
      .post(url, message)
      .then((response) => response.data)
      .then((sample) => {
        return sample;
      });
  }
}

const buildStreamPayload = (parameters, runId, previewProcedureVariables) => {
  const telemetry = parameters.map((parameter) => {
    return {
      name: parameter.name,
      dictionary_id: parameter.dictionaryId,
      stream_id: parameter.streamId,
    };
  });
  return {
    telemetry,
    run_id: runId,
    variables: previewProcedureVariables,
  };
};

export default TelemetryService;

import { useCallback } from 'react';
import Tooltip from './Tooltip';

export enum AvatarSize {
  base = 'h-7 w-7 text-base',
  lg = 'h-12 w-12 text-xl',
}

interface AvatarProps {
  userId: string;
  size?: AvatarSize;
}

const Avatar = ({ userId, size = AvatarSize.base }: AvatarProps) => {
  const avatarText = useCallback((userId) => {
    return userId[0].toUpperCase();
  }, []);

  return (
    <Tooltip content={userId}>
      <div
        className={`${size} group rounded-full text-white flex justify-center items-center border border-white bg-slate-300 cursor-default`}
        aria-label={userId}
      >
        {avatarText(userId)}
      </div>
    </Tooltip>
  );
};

export default Avatar;

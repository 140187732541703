import { useEffect, useState } from 'react';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { StepSnippet } from '../../../lib/views/settings';
import { Link } from 'react-router-dom';
import { TestCaseBlock } from '../TestCasesBlock';
import { testingTestCasesPath } from '../../../lib/pathUtil';
import DiffContainer from '../../../components/Diff/DiffContainer';
import diffUtil from '../../../lib/diffUtil';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import { TestCaseList, WithDiffChange } from 'shared/lib/types/views/procedures';
import AssessmentIndicator from '../AssessmentIndicator';
import usePopup from '../../../hooks/usePopup';
import HazardPopup from '../HazardPopup';

type ReviewSnippetSelectorProps = {
  content: WithDiffChange<TestCaseList>;
};

const ReviewSnippetSelector = ({ content }: ReviewSnippetSelectorProps) => {
  const { services, currentTeamId } = useDatabaseServices();
  const [snippets, setSnippets] = useState<Array<StepSnippet>>([]);
  const { activeChildId, handlePopupOpen, handlePopupClose } = usePopup();

  useEffect(() => {
    if (!services.settings) return;

    const snippetIds = diffUtil.isDiffPair(content?.items[0]) ? content.items.map((item) => item[1]) : content.items;
    services.settings
      .listSnippets({ isTestSnippet: true, snippetIds })
      .then((snippets) => {
        setSnippets(snippets);
      })
      .catch(() => {
        /* Ignored */
      });
  }, [services.settings, content.items]);

  const itemMap = content.items.reduce((acc, item) => {
    if (diffUtil.isDiffPair(item)) {
      acc[item[1]] = item[0];
    } else {
      acc[item] = null;
    }
    return acc;
  }, {});

  const getStyleByDiffChangeState = (id) => {
    const value = itemMap[id];

    const styles = {
      [ARRAY_CHANGE_SYMBOLS.ADDED]: 'bg-emerald-100 text-emerald-800',
      [ARRAY_CHANGE_SYMBOLS.REMOVED]: 'bg-red-100 line-through text-red-600',
    };

    return styles[value] || 'bg-white';
  };

  return (
    <div className="flex flex-col grow group border border-gray-200 rounded bg-white p-3">
      <div className="field-title">Test Cards</div>
      <DiffContainer
        label="Test Point Header"
        diffChangeState={content.diff_change_state}
        isTextSticky={false}
        width="full"
      >
        <table className="w-full border-collapse">
          <thead>
            <tr className="items-center">
              <th className="border border-gray-400 p-1">
                <div className="text-left">
                  <span className="field-title">Test Card</span>
                </div>
              </th>
              <th className="border border-gray-400 p-1">
                <div className="text-left">
                  <span className="field-title">Test Points</span>
                </div>
              </th>
              <th className="border border-gray-400 p-1">
                <div className="text-left">
                  <span className="field-title">Hazard</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {!snippets.length && (
              <tr className="h-10 text-sm text-gray-400 bg-white items-center text-center">
                <td className="border border-gray-400 p-1" colSpan={4}>
                  No Test Point Added
                </td>
              </tr>
            )}
            {snippets.map((snippet) => (
              <tr
                key={snippet._id}
                className={`h-10 text-sm border items-center ${getStyleByDiffChangeState(snippet.snippet_id)}`}
              >
                <td className="border border-gray-400 p-1">
                  <div className="p-1 relative w-72">{snippet.name}</div>
                </td>
                <td className="border border-gray-400 p-1">
                  {snippet?.step &&
                    snippet?.step.content.map((stepContent: unknown, index: number) => {
                      const testCaseContent = stepContent as TestCaseBlock;
                      if (testCaseContent.type === 'test_cases')
                        return testCaseContent.items.map((item, itemIndex) => (
                          <div key={`item-${index}-${itemIndex}`}>
                            <Link
                              key={`hazard-${itemIndex}-${item}`}
                              to={`${testingTestCasesPath(currentTeamId)}/${item.id}`}
                              className="text-sm text-blue-600 hover:underline truncate"
                            >
                              {item?.name}
                            </Link>
                          </div>
                        ));
                      return null;
                    })}
                </td>
                <td className="border border-gray-400 p-1">
                  {snippet?.step &&
                    snippet?.step.content.map((stepContent: unknown, index: number) => {
                      const hazardMap = new Map();
                      const testCaseContent = stepContent as TestCaseBlock;
                      if (testCaseContent.type === 'test_cases')
                        return testCaseContent.items.map((item, itemIndex) => (
                          <div key={`item-${index}-${itemIndex}`}>
                            {item?.hazards?.map((hazard, hazardIndex) => {
                              if (!hazardMap.has(hazard.id)) {
                                hazardMap.set(hazard.id, true); // Add hazard to map
                                return (
                                  <div
                                    key={`hazard-div-${itemIndex}-${hazardIndex}`}
                                    className="flex items-center space-x-2"
                                  >
                                    <AssessmentIndicator analysis={hazard} />
                                    <span
                                      key={`hazard-${itemIndex}-${hazardIndex}`}
                                      onClick={(e) => handlePopupOpen(e, hazard.id)}
                                      className="text-sm text-blue-600 hover:underline truncate"
                                    >
                                      {hazard?.name}
                                    </span>
                                    <HazardPopup
                                      isPopUpShown={activeChildId === hazard.id}
                                      onPopupClose={handlePopupClose}
                                      hazard={hazard}
                                    />
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </div>
                        ));
                      return null;
                    })}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </DiffContainer>
    </div>
  );
};

export default ReviewSnippetSelector;

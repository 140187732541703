import RemovableTag from '../components/RemovableTag';
import { Tag } from '../types';

type TagsDisplayProps = {
  tags: Array<Tag>;
  onRemoveTag: (tag: Tag) => Promise<void>;
  disabled?: boolean;
};

const TagsDisplay = ({ tags, onRemoveTag, disabled = false }: TagsDisplayProps) => {
  const getOnRemove = (tag: Tag) => () => onRemoveTag(tag);

  return (
    <div className="flex flex-row flex-wrap gap-y-1 items-center">
      {tags.map((tag) => (
        <RemovableTag key={tag.id} tag={tag} onRemove={getOnRemove(tag)} disabled={disabled} />
      ))}
    </div>
  );
};

export default TagsDisplay;

import { ChangeEvent, useRef } from 'react';
import { OverlaySize, OverlayUpload } from './OverlayUpload';
import { useDropzone } from 'react-dropzone';
import { Attachment } from 'shared/lib/types/attachments';
import AttachmentsEditPreview from './AttachmentsEditPreview';
import { MAX_FILE_SIZE } from 'shared/lib/types/api/files/requests';
import { MAX_FILE_SIZE_EXCEEDED_MESSAGE } from '../attachments/service';

type AttachmentsEditProps = {
  attachments: Array<Attachment>;
  setAttachments: (attachments: Array<Attachment>) => void;
  acceptedFileTypes?: Array<string>;
  stagedFiles?: Array<File>;
  setStagedFiles?: (files: Iterable<File>) => void;
  onRemoveStagedFile?: (file: File) => void;
  maxFiles?: number;
};

const AttachmentsEdit = ({
  attachments,
  setAttachments,
  acceptedFileTypes,
  stagedFiles,
  setStagedFiles,
  onRemoveStagedFile,
  maxFiles,
}: AttachmentsEditProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleStagedFiles = (files: File[]) => {
    if (setStagedFiles) {
      setStagedFiles(files);
    }
  };

  const onFileChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    const files = event.currentTarget.files;
    if (!files) {
      return;
    }
    if (!areFileTypesAccepted(files)) {
      window.alert(`Invalid image file type. Accepted file types: ${acceptedFileTypes?.join(', ')}`);
      return;
    }
    if (!areFileSizesAccepted(files)) {
      event.target.value = '';
      window.alert(MAX_FILE_SIZE_EXCEEDED_MESSAGE);
      return;
    }
    handleStagedFiles(Array.from(files));
  };

  const areFileTypesAccepted = (files: Iterable<File>): boolean => {
    if (!acceptedFileTypes) {
      return true;
    }

    for (const file of files) {
      if (!acceptedFileTypes.includes(file.type)) {
        return false;
      }
    }
    return true;
  };

  const areFileSizesAccepted = (files: Iterable<File>): boolean =>
    Array.from(files).every((file) => file.size <= MAX_FILE_SIZE);

  const onDrop = (files: Array<File>) => {
    handleStagedFiles(files);
  };

  const { getRootProps } = useDropzone({ accept: acceptedFileTypes, maxFiles: 2, onDrop });
  const exceededMaxFiles = maxFiles && maxFiles > 0 && stagedFiles ? stagedFiles.length >= maxFiles : false;
  const isUploadDisabled = maxFiles && maxFiles > 0 ? exceededMaxFiles : false;

  return (
    <>
      <AttachmentsEditPreview
        attachments={attachments}
        stagedFiles={stagedFiles}
        setAttachments={setAttachments}
        removeStageFile={onRemoveStagedFile}
      />
      <div className="w-full h-20 relative" {...getRootProps()}>
        <input
          ref={fileInputRef}
          accept={acceptedFileTypes?.join(',')}
          multiple={true}
          type="file"
          id="_hidden_file_input"
          data-testid="hidden_file_input"
          onChange={(e) => onFileChanged(e)}
          className="hidden"
          disabled={isUploadDisabled}
        />
        <OverlayUpload
          hintText={maxFiles === 1 ? 'Drop file here' : 'Drop files here'}
          isDragActive={true}
          size={OverlaySize.small}
          isDisabled={isUploadDisabled}
        />
        <button
          onClick={() => fileInputRef.current?.click()}
          type="button"
          className="absolute w-full h-full"
          disabled={isUploadDisabled}
        ></button>
      </div>
    </>
  );
};

export default AttachmentsEdit;

import React, { useCallback, useMemo } from 'react';
import { useSettings } from '../contexts/SettingsContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import signoffUtil from 'shared/lib/signoffUtil';
import cloneDeep from 'lodash.clonedeep';
import FieldSetSignoffSelect from './FieldSetSignoffSelect';
import procedureUtil from '../lib/procedureUtil';
import FieldSetSignoff from './FieldSetSignoff';
import { Signoff, SignoffType } from 'shared/lib/types/views/procedures';
import { FieldInputProps, FormikProps } from 'formik';

const ANY_OPERATOR = {
  value: 'Any Operator',
  label: 'Any Operator',
};

interface FieldSetSignoffArrayProps {
  signoffs: Array<Signoff>;
  field: FieldInputProps<string>;
  signoffType: SignoffType;
  form: FormikProps<string>;
}

const FieldSetSignoffArray = React.memo(({ signoffs, field, form, signoffType }: FieldSetSignoffArrayProps) => {
  const { operatorRoles } = useSettings();
  const usedOperators = signoffUtil.getAllOperatorsInSignoffs(signoffs);
  const { mixpanel } = useMixpanel();
  const mixpanelTrack = useCallback(
    (trackingKey: string) => {
      if (mixpanel) {
        mixpanel.track(trackingKey);
      }
    },
    [mixpanel]
  );

  const operatorOptions = useMemo(() => {
    if (!operatorRoles || !operatorRoles.operators) {
      return [];
    }
    const allOperatorOptions = operatorRoles.operators.map((op) => op.code);
    const remainingOperatorOptions = allOperatorOptions.filter((o) => !usedOperators.includes(o));
    return remainingOperatorOptions.map((op) => ({
      value: op,
      label: op,
    }));
  }, [operatorRoles, usedOperators]);

  const onAddSignoff = useCallback(
    (options) => {
      if (options.length !== 1) {
        return;
      }
      const newSignoff = signoffType === 'end' ? procedureUtil.newEndRunSignoff() : procedureUtil.newStartRunSignoff();
      if (options[0].value !== ANY_OPERATOR.value) {
        newSignoff.operators = [options[0].value];
      }
      const signoffsCopy: Array<Signoff> = [];
      signoffsCopy.push(newSignoff);
      mixpanelTrack(signoffType === 'end' ? 'End Run Signoff Added' : 'Start Run Signoff Added');
      form.setFieldValue(field.name, signoffsCopy);
    },
    [field.name, form, mixpanelTrack, signoffType]
  );

  const onSignoffUpdated = useCallback(
    (updatedSignoff, signoffIndex) => {
      const signoffsCopy = cloneDeep(signoffs);
      signoffsCopy[signoffIndex] = updatedSignoff;
      form.setFieldValue(field.name, signoffsCopy);
    },
    [field.name, form, signoffs]
  );

  return (
    <div className="flex text-sm font-medium flex-wrap grow gap-x-2 gap-y-2 items-start">
      {signoffs?.map((signoff, signoffIndex) => (
        <FieldSetSignoff
          key={signoff.id}
          name={`signoffs[${signoffIndex}]`}
          signoffIndex={signoffIndex}
          signoff={signoff}
          operatorOptions={operatorOptions}
          onSignoffUpdated={onSignoffUpdated}
          placeholder="ANY OPERATOR"
        />
      ))}
      {!signoffUtil.isSignoffRequired(signoffs) && (
        <FieldSetSignoffSelect
          options={[...operatorOptions]}
          placeholder="ANY OPERATOR"
          opacity={0.9}
          value={null}
          hoverOpacity={undefined}
          onChange={onAddSignoff}
          ariaLabel="Add Signoff Select"
          name={undefined}
          onBlur={undefined}
        />
      )}
    </div>
  );
});
export default FieldSetSignoffArray;

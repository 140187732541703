import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import revisionsUtil from '../lib/revisions';
import ProcedureDetails from '../components/ProcedureDetails';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { ReviewContextProvider } from '../contexts/ReviewContext';
import { Helmet } from 'react-helmet-async';
import { useSettings } from '../contexts/SettingsContext';
import diffUtil from '../lib/diffUtil';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import useLocationParams from '../hooks/useLocationParams';
import useExpandCollapse from '../hooks/useExpandCollapse';
import useDiff from '../hooks/useDiff';
import ReviewProcedureContent from '../components/Review/ReviewProcedureContent';
import { ProcedureDiff } from 'shared/lib/types/views/procedures';
import useSearchParams from '../hooks/useSearchParams';
import { ReleaseRevision } from '../lib/views/revisions';
import DiffStickyHeader, { DIFF_STICKY_HEADER_HEIGHT_REM } from '../components/Diff/DiffStickyHeader';
import useProcedureVersions from '../hooks/useProcedureVersions';
import { isReleased, PROCEDURE_STATE_DRAFT, PROCEDURE_STATE_IN_REVIEW } from 'shared/lib/procedureUtil';
import externalDataUtil from '../lib/externalDataUtil';
import LoadingScreen from '../components/LoadingScreen';
import NotFound from '../components/NotFound';
import { useMixpanel } from '../contexts/MixpanelContext';
import apm from '../lib/apm';

const Diff = () => {
  const history = useHistory();
  const location = useLocation();
  const { hash } = useLocationParams(location);
  const { mixpanel } = useMixpanel();

  const initialSearchParams = new URLSearchParams(history.location.search);
  const oldId = initialSearchParams.get('old') ?? '';
  const oldVersion = initialSearchParams.get('oldVersion');
  const oldPending = initialSearchParams.get('oldPending');
  const newId = initialSearchParams.get('new') ?? '';
  const newVersion = initialSearchParams.get('newVersion');
  const newPending = initialSearchParams.get('newPending');

  const { pushSearchParams } = useSearchParams();
  const [docs, setDocs] = useState<{
    oldProcedure: ReleaseRevision | null;
    newProcedure: ReleaseRevision | null;
    procedureDiff: ProcedureDiff | null;
    loading: boolean;
  }>({
    oldProcedure: null,
    newProcedure: null,
    procedureDiff: null,
    loading: true,
  });
  const { services } = useDatabaseServices();
  const [showVersionHistory] = useState(history.location.state && history.location.state['versions']);

  const { versions: oldProcedureMetadataList } = useProcedureVersions({ id: oldId });
  const { versions: newProcedureMetadataList } = useProcedureVersions({ id: newId });

  const {
    isCollapsedMap,
    setIsCollapsedMap,
    setAllExpanded,
    setIsCollapsed,
    areAllStepsInSectionExpanded,
    setAllStepsInSectionExpanded,
  } = useExpandCollapse();

  const [scrollToAction, setScrollToAction] = useState({
    id: hash,
    smooth: false,
  });
  const scrollToRefs = useRef({});

  const { isFullDiffReviewDisabled } = useSettings();

  // Track when any of the parameters change.
  useEffect(() => {
    if (!mixpanel) {
      return;
    }
    const options = {
      customUrl: oldId !== newId,
    };
    mixpanel.track('Diff page visited', options);
  }, [mixpanel, oldId, oldVersion, oldPending, newId, newVersion, newPending]);

  const expandChanges = useCallback(
    (diff) => {
      const collapsedMap = {};
      diff.sections.forEach((section) => {
        collapsedMap[section.id] =
          !section.diff_change_state || section.diff_change_state === ARRAY_CHANGE_SYMBOLS.UNCHANGED;
        section.steps.forEach(
          (step) =>
            (collapsedMap[step.id] =
              (!step.diff_change_state || step.diff_change_state === ARRAY_CHANGE_SYMBOLS.UNCHANGED) &&
              section.diff_change_state !== ARRAY_CHANGE_SYMBOLS.ADDED &&
              section.diff_change_state !== ARRAY_CHANGE_SYMBOLS.REMOVED)
        );
      });
      if (Object.keys(collapsedMap).length > 0) {
        setIsCollapsedMap(collapsedMap);
      }
    },
    [setIsCollapsedMap]
  );

  // Load the diff.
  useEffect(() => {
    if (
      !oldId ||
      !oldVersion ||
      !oldPending ||
      !newId ||
      !newVersion ||
      !newPending ||
      !services.revisions ||
      oldProcedureMetadataList.length === 0 ||
      newProcedureMetadataList.length === 0
    ) {
      return;
    }

    const refreshProcedure = async () => {
      const oldProcedureSummary = oldProcedureMetadataList.find(
        (procedure) =>
          procedure.version === oldVersion && (oldPending === 'true' ? !isReleased(procedure) : isReleased(procedure))
      );
      const newProcedureSummary = newProcedureMetadataList.find(
        (procedure) =>
          procedure.version === newVersion && (newPending === 'true' ? !isReleased(procedure) : isReleased(procedure))
      );

      if (!oldProcedureSummary || !newProcedureSummary) {
        setDocs({
          oldProcedure: null,
          newProcedure: null,
          procedureDiff: null,
          loading: false,
        });
        return;
      }

      let oldProcedure = [PROCEDURE_STATE_IN_REVIEW, PROCEDURE_STATE_DRAFT].includes(oldProcedureSummary?.state ?? '')
        ? await services.procedures.getProcedure(oldProcedureSummary?._id)
        : await services.revisions.getProcedureRevision(oldId, oldProcedureSummary?.procedure_rev_num);
      let newProcedure = [PROCEDURE_STATE_IN_REVIEW, PROCEDURE_STATE_DRAFT].includes(newProcedureSummary?.state ?? '')
        ? await services.procedures.getProcedure(newProcedureSummary?._id)
        : await services.revisions.getProcedureRevision(newId, newProcedureSummary?.procedure_rev_num);

      try {
        const oldExternalItems = await services.externalData.getAllExternalItems(oldProcedure);
        oldProcedure = externalDataUtil.updateProcedureWithItems(oldProcedure, oldExternalItems);

        const newExternalItems = await services.externalData.getAllExternalItems(newProcedure);
        newProcedure = externalDataUtil.updateProcedureWithItems(newExternalItems, newExternalItems);
      } catch {
        // Ignore errors and fall back to using procedure without dynamic data.
      }

      const newDiff = diffUtil.getProcedureDiff(
        oldProcedure,
        newProcedure,
        isFullDiffReviewDisabled && isFullDiffReviewDisabled()
      );
      setDocs({
        oldProcedure,
        newProcedure,
        procedureDiff: newDiff,
        loading: false,
      });
      expandChanges(newDiff);
    };

    refreshProcedure().catch((err) => apm.captureError(err));
  }, [
    services.revisions,
    oldId,
    oldVersion,
    oldPending,
    newId,
    newVersion,
    newPending,
    isFullDiffReviewDisabled,
    oldProcedureMetadataList,
    newProcedureMetadataList,
    services.procedures,
    services.externalData,
    expandChanges,
  ]);

  const diffDisplay = useMemo(() => {
    // don't show steps added from a run not accepted to the procedure
    return docs.procedureDiff ? revisionsUtil.getProcedureWithoutRunSteps(docs.procedureDiff) : null;
  }, [docs.procedureDiff]);

  // Called when element refs are changed, will scroll to element if element exists and scrollToId matches element id.
  const onScrollToDiffRefChanged = useCallback(
    (id, element) => {
      scrollToRefs.current[id] = element;

      if (element && scrollToAction.id && scrollToAction.id === id) {
        // Browser default is 'auto'.
        element.scrollIntoView({ behavior: scrollToAction.smooth ? 'smooth' : 'auto' });
        setScrollToAction({
          id: null,
          smooth: false,
        });
      }
    },
    [scrollToRefs, scrollToAction]
  );

  const { scrollToDiff } = useDiff({
    onScrollToDiffRefChanged,
    setIsCollapsed,
    setScrollToAction,
  });

  const sectionIds = React.useMemo(
    () => (diffDisplay ? diffDisplay.sections.map((section) => section.id) : []),
    [diffDisplay]
  );
  const headerIds = React.useMemo(
    () => (diffDisplay && diffDisplay.headers ? diffDisplay.headers.map((header) => header.id) : []),
    [diffDisplay]
  );

  const onExpandAll = useCallback(() => {
    setAllExpanded(true, sectionIds, headerIds);
  }, [setAllExpanded, sectionIds, headerIds]);

  const onCollapseAll = useCallback(() => {
    setAllExpanded(false, sectionIds, headerIds);
  }, [setAllExpanded, sectionIds, headerIds]);

  const onCompare = useCallback(
    (values: {
      old: string;
      oldVersion: string;
      oldPending: 'true' | 'false';
      new: string;
      newVersion: string;
      newPending: 'true' | 'false';
    }) => {
      setDocs({
        oldProcedure: null,
        newProcedure: null,
        procedureDiff: null,
        loading: true,
      });
      pushSearchParams(values);
    },
    [pushSearchParams]
  );

  if (docs.loading) {
    return <LoadingScreen />;
  }

  if (!docs.procedureDiff || !docs.oldProcedure || !docs.newProcedure) {
    return <NotFound />;
  }

  return (
    <>
      <ReviewContextProvider
        released={docs.newProcedure}
        review={diffDisplay}
        onScrollToDiffRefChanged={onScrollToDiffRefChanged}
        isDiffShown={true}
      >
        {/* Sets the document title */}
        <Helmet>
          <title>{`Comparing ${docs.oldProcedure?.name} to ${docs.newProcedure?.name}`}</title>
        </Helmet>
        <div data-testid="reviewBody" className="mt-8 flex flex-grow">
          <div className={`flex-1 pr-4 pl-20 lg:pr-8 py-4 print:m-0 print:p-0 ${showVersionHistory && 'mr-72'}`}>
            <div className="flex gap-x-2">
              <ProcedureDetails procedure={diffDisplay} scrollToBufferRem={DIFF_STICKY_HEADER_HEIGHT_REM} />
            </div>

            <div className="grid grid-cols-4 gap-8 justify-items-center">
              <div className="col-span-4 print:col-span-4 w-full">
                <DiffStickyHeader
                  oldProcedureMetadataList={oldProcedureMetadataList}
                  newProcedureMetadataList={newProcedureMetadataList}
                  procedureDiff={diffDisplay}
                  oldProcedure={docs.oldProcedure as ReleaseRevision}
                  newProcedure={docs.newProcedure as ReleaseRevision}
                  showDiff={true}
                  scrollToDiff={scrollToDiff}
                  onExpandAll={onExpandAll}
                  onCollapseAll={onCollapseAll}
                  onCompare={onCompare}
                />
                <ReviewProcedureContent
                  procedure={diffDisplay}
                  isCollapsedMap={isCollapsedMap}
                  setIsCollapsed={setIsCollapsed}
                  areAllStepsInSectionExpanded={areAllStepsInSectionExpanded}
                  setAllStepsInSectionExpanded={setAllStepsInSectionExpanded}
                  scrollToBufferRem={DIFF_STICKY_HEADER_HEIGHT_REM}
                  showReviewComments={true}
                  onResolveReviewComment={undefined}
                  onUnresolveReviewComment={undefined}
                  saveReviewComment={undefined}
                />
              </div>
            </div>
          </div>
        </div>
      </ReviewContextProvider>
    </>
  );
};

export default Diff;

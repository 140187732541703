import React, { useMemo } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  ApproveAction,
  DraftAction,
  ProcedureChangeAction,
  ReviewerAction,
  ReviewerGroupChangeAction,
  RevokeApprovalAction,
} from 'shared/lib/types/couch/procedures';
import ReviewActionRow from './ReviewActionRow';

const ProcedureChangeActionRow = React.memo(({ action }: { action: ProcedureChangeAction }) => {
  return (
    <ReviewActionRow iconName={'fa-regular fa-circle-right' as IconProp} timestamp={action.changed_at}>
      <span className="font-medium ml-1">Updated procedure moved to review by</span>
      <span className="font-medium">{action.user_id}</span>
    </ReviewActionRow>
  );
});

const ApproveActionRow = React.memo(({ action }: { action: ApproveAction }) => {
  return (
    <ReviewActionRow iconName={'fa-regular fa-check-circle' as IconProp} timestamp={action.approved_at}>
      <span className="font-medium ml-1">Review approved by</span>
      {action.operator_role && <span className="font-medium">{action.operator_role}</span>}
      <span className="font-medium">{action.user_id}</span>
    </ReviewActionRow>
  );
});

const RevokeApprovalActionRow = React.memo(({ action }: { action: RevokeApprovalAction }) => {
  return (
    <ReviewActionRow iconName="times-circle" timestamp={action.changed_at}>
      <span className="font-medium ml-1">Approval revoked by</span>
      <span className="font-medium">{action.user_id}</span>
    </ReviewActionRow>
  );
});

const ReviewerTypeChangeActionRow = React.memo(({ action }: { action: ReviewerGroupChangeAction }) => {
  const typeWasChanged = action.changed_from && action.changed_to;
  const typeWasAdded = !action.changed_from && action.changed_to;
  const typeWasRemoved = action.changed_from && !action.changed_to;

  const iconName = React.useMemo(() => {
    if (typeWasAdded) {
      return 'user-plus';
    }
    if (typeWasRemoved) {
      return 'user-xmark';
    }
    return 'user-pen';
  }, [typeWasAdded, typeWasRemoved]);

  return (
    <ReviewActionRow iconName={iconName} timestamp={action.changed_at}>
      <span className="font-medium ml-1">
        {typeWasChanged && (
          <>
            Review type changed from {action.changed_from} to {action.changed_to} by
          </>
        )}
        {typeWasAdded && <>Review type {action.changed_to} added by</>}
        {typeWasRemoved && <>Review type {action.changed_from} removed by</>}
      </span>
      <span className="font-medium">{action.user_id}</span>
    </ReviewActionRow>
  );
});

type ActionRowType = ({ action }: { action: DraftAction | ReviewerAction }) => JSX.Element;

const ACTION_ROWS = {
  procedure_change: ProcedureChangeActionRow,
  approval: ApproveActionRow,
  reviewer_group_change: ReviewerTypeChangeActionRow,
  review_type_change: ReviewerTypeChangeActionRow,
  revoke_approval: RevokeApprovalActionRow,
};

interface ReviewActionsProps {
  action: DraftAction | ReviewerAction;
}
const ReviewAction = React.memo(({ action }: ReviewActionsProps) => {
  const ActionRow = useMemo(() => {
    return action.type && (ACTION_ROWS[action.type] as ActionRowType);
  }, [action.type]);
  // Display nothing for unknown action types.
  return ActionRow && <ActionRow action={action} />;
});

export default ReviewAction;

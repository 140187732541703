import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ClosePlotModalButtonProps = {
  onClick: () => void;
};

const ClosePlotModalButton = ({ onClick }: ClosePlotModalButtonProps) => {
  return (
    <FontAwesomeIcon
      className="text-gray-500 hover:text-gray-600 hover:cursor-pointer"
      icon="xmark"
      aria-label="Close Plot"
      onClick={onClick}
    />
  );
};

export default ClosePlotModalButton;

import React, { useState } from 'react';
import EventPopup from '../EventPopup';
import useEventNav from '../../hooks/useEventNav';
import useSingleClick from '../../hooks/useSingleClick';
import Tooltip from '../../../elements/Tooltip';

const CalendarEvent = ({ event }) => {
  const [showPopOver, setShowPopOver] = useState(false);
  const { navigateToEventPage } = useEventNav();
  const { handleClick, clickPosition } = useSingleClick(() => setShowPopOver(true));

  return (
    <Tooltip content={event.name}>
      <>
        <div
          className="cursor-pointer my-1 flex items-center shadow-lg pl-2 rounded bg-blue-500/30 hover:bg-blue-600/30"
          onClick={handleClick}
          onDoubleClick={() => navigateToEventPage(event)}
        >
          {event.name}
        </div>
        <EventPopup
          showPopOver={showPopOver}
          event={event}
          position={clickPosition}
          closePopup={() => setShowPopOver(false)}
        />
      </>
    </Tooltip>
  );
};

export default CalendarEvent;

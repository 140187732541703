import { AddFileSendRequest } from 'shared/lib/types/api/files/requests';
import { AttachmentMetadata } from '../lib/views/attachments';
import { UploadCacheEntry } from './cache';

export const createUploadCacheEntry = (
  attachment: AttachmentMetadata,
  data: Blob
): UploadCacheEntry => {
  return {
    id: attachment._id,
    name: attachment.name,
    source: attachment.source,
    data,
  };
};

export const assembleAddFileRequest = (req: AddFileSendRequest): FormData => {
  const { id, name, source, file } = req;
  const formData = new FormData();
  formData.append('id', id);
  formData.append('name', name);
  formData.append('source', source);
  formData.append('file', file as Blob);
  return formData;
};

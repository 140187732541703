import { useEffect, useMemo, useState } from 'react';
import MarkdownView from './Markdown/MarkdownView';
import { BlockProvider } from '../contexts/BlockContext';
import useUnits from '../hooks/useUnits';
import useExpression from '../hooks/useExpression';
import { ExpressionReferences, RunAlertBlock, RunTextBlock } from 'shared/lib/types/views/procedures';
import { isLegacyTextBlock } from 'shared/lib/procedureUtil';
import Button, { BUTTON_TYPES } from './Button';
import { faClose, faCode } from '@fortawesome/free-solid-svg-icons';

interface BlockTextProps {
  block: RunTextBlock | RunAlertBlock;
  isValid?: boolean;
  setExpressionRecorded?: (recorded: { value?: string; display?: string; references?: ExpressionReferences }) => void;
}

const ReferenceTextView = ({ block, isValid = true, setExpressionRecorded }: BlockTextProps) => {
  const { findDefinedUnit } = useUnits();
  const legacyTextActive = useMemo(() => isLegacyTextBlock(block), [block]);
  const { expressionResult } = useExpression({
    tokens: block.tokens,
    recorded: block.recorded,
    findDefinedUnit,
    shouldEvaluate: false,
  });

  const hasReferences = useMemo(() => {
    return block.tokens?.some((token) => token.type === 'reference');
  }, [block.tokens]);

  useEffect(() => {
    if (!legacyTextActive) {
      setExpressionRecorded && setExpressionRecorded(expressionResult);
    }
  }, [expressionResult, legacyTextActive, setExpressionRecorded]);

  const displayText = useMemo(() => {
    if (legacyTextActive) {
      return block.text;
    }

    return expressionResult.display || '';
  }, [legacyTextActive, expressionResult.display, block.text]);

  const [showFormatted, setShowFormatted] = useState(true);

  return (
    <BlockProvider value={{ isValid }}>
      {!showFormatted && (
        <div className="flex flex-row justify-between items-start border border-gray-200 p-2 rounded">
          <pre className="text-xs leading-4 whitespace-pre-wrap">{block.text}</pre>
          <div>
            <Button
              type={BUTTON_TYPES.TERTIARY}
              removePadding
              onClick={() => setShowFormatted(true)}
              leadingIcon={faClose}
              iconTextColor="text-gray-500"
              title="Close Markdown view"
            ></Button>
          </div>
        </div>
      )}
      {showFormatted && (
        <div className="flex flex-row justify-between items-start group/markdown">
          <MarkdownView text={displayText} />
          {hasReferences && (
            <div className="opacity-0 group-hover/markdown:opacity-100">
              <Button
                type={BUTTON_TYPES.TERTIARY}
                size="xs"
                removePadding
                onClick={() => setShowFormatted(false)}
                leadingIcon={faCode}
                iconTextColor="text-gray-500"
                title="View Markdown and references"
              ></Button>
            </div>
          )}
        </div>
      )}
    </BlockProvider>
  );
};

export default ReferenceTextView;

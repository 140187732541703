import React, { useContext, useEffect, useState } from 'react';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

const ServiceWorkerContext = React.createContext();

const ServiceWorkerProvider = (props) => {
  const [showsUpdate, setShowsUpdate] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onWorkerUpdate = (registration) => {
    setWaitingWorker(registration && registration.waiting);
    setShowsUpdate(!!(registration && registration.waiting));
  };

  useEffect(() => {
    /*
     * If you want your app to work offline and load faster, you can change
     * unregister() to register() below. Note this comes with some pitfalls.
     * Learn more about service workers: https://cra.link/PWA
     */
    serviceWorkerRegistration.register({ onUpdate: onWorkerUpdate });
  }, []);

  const updateWorker = () => {
    if (!waitingWorker) {
      return;
    }

    waitingWorker.postMessage({ type: 'SKIP_WAITING' });
  };

  return (
    <ServiceWorkerContext.Provider
      value={{
        showsUpdate,
        updateWorker,
      }}
    >
      {props.children}
    </ServiceWorkerContext.Provider>
  );
};

const useServiceWorker = () => {
  const context = useContext(ServiceWorkerContext);
  if (context === undefined) {
    throw new Error('useServiceWorker must be used within a ServiceWorkerContext');
  }
  return context;
};

export { ServiceWorkerProvider, useServiceWorker };

import React, { useCallback } from 'react';
import { ChangeEntry } from '../lib/diffUtil';

interface UseDiffProps {
  onScrollToDiffRefChanged: (string, HTMLElement) => void;
  setIsCollapsed?: (string, boolean) => void;
  setScrollToAction?: React.Dispatch<React.SetStateAction<{ id: string; smooth: boolean }>>;
}

const useDiff = ({ onScrollToDiffRefChanged, setIsCollapsed, setScrollToAction }: UseDiffProps) => {
  const handleOnScrollToDiffRefChanged = useCallback(
    (id, element) => {
      if (typeof onScrollToDiffRefChanged === 'function') {
        onScrollToDiffRefChanged(id, element);
      }
    },
    [onScrollToDiffRefChanged]
  );

  // Will show diff item (e.g. title, section/step/stepHeader/content/header) with associated id, and expand the section, step or header if necessary.
  const scrollToDiff = useCallback(
    ({
      title,
      version,
      automation_enabled,
      is_strict_signoff_enabled,
      skip_step_enabled,
      repeat_step_enabled,
      step_suggest_edits_enabled,
      owner,
      project,
      defaultView,
      endRunSignoffOperators,
      description,
      printHeader,
      printFooter,
      tags,
      variableId,
      headerId,
      partListId,
      sectionId,
      sectionHeaderId,
      stepId,
    }: ChangeEntry) => {
      if (!setIsCollapsed || !setScrollToAction) {
        return;
      }
      const scrollToId =
        title ||
        version ||
        automation_enabled ||
        is_strict_signoff_enabled ||
        skip_step_enabled ||
        repeat_step_enabled ||
        step_suggest_edits_enabled ||
        owner ||
        project ||
        defaultView ||
        endRunSignoffOperators ||
        description ||
        printHeader ||
        printFooter ||
        tags ||
        headerId ||
        variableId ||
        partListId ||
        stepId ||
        sectionHeaderId ||
        sectionId;

      if (!scrollToId) {
        return;
      }

      if (sectionId) {
        setIsCollapsed(sectionId, false);
      }

      if (headerId) {
        setIsCollapsed(headerId, false);
      }

      if (stepId) {
        setIsCollapsed(stepId, false);
      }

      setScrollToAction({
        id: scrollToId,
        smooth: true,
      });
    },
    [setIsCollapsed, setScrollToAction]
  );

  return {
    handleOnScrollToDiffRefChanged,
    scrollToDiff,
  };
};

export default useDiff;

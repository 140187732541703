import { ProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import { capitalizeFirstLetter } from 'shared/lib/text';

export type ProgressStep = {
  id: string;
  percent: number;
  label?: string;
};

interface EntityProgressBarProps {
  steps: Array<ProgressStep>;
  currentStepId: string;
}

const EntityProgressBar = ({ steps, currentStepId }: EntityProgressBarProps) => {
  const currentStepPercent = steps.find((step) => step.id === currentStepId)?.percent || 0;
  const getStepLabel = (step: ProgressStep) => step.label || capitalizeFirstLetter(step.id);

  return (
    <div className="w-full">
      <ProgressBar percent={currentStepPercent} height={5}>
        {steps.map((step) => (
          <Step key={step.id}>
            {({ accomplished }) => (
              <div className="flex flex-col items-center" style={{ paddingTop: '19px' }}>
                <div
                  className={`w-4 h-4 rounded-full  flex justify-center items-center mb-1 ${
                    accomplished ? 'bg-blue-500' : 'bg-gray-400'
                  }`}
                />
                <div className="text-center text-xs">{getStepLabel(step)}</div>
              </div>
            )}
          </Step>
        ))}
      </ProgressBar>
    </div>
  );
};

export default EntityProgressBar;

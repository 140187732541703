import FieldInput from './Blocks/FieldInput';
import procedureVariableUtil from '../lib/procedureVariableUtil';
import { useMixpanel } from '../contexts/MixpanelContext';
import { isEmpty, cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { V2RunVariable, Variable } from 'shared/lib/types/views/procedures';
import Button from './Button';

/**
 * Renders a procedure variable.  Compatible with legacy and V2 variables.
 */
interface FormRunVariableProps {
  variable: Variable;
  onSaveVariable?: (variable: V2RunVariable) => void;
  isEnabled: boolean;
  onRefChanged: (id: string, element: HTMLElement) => void;
  scrollMarginTopValueRem: number;
}

const FormRunVariable = ({
  variable,
  onSaveVariable,
  isEnabled,
  onRefChanged,
  scrollMarginTopValueRem,
}: FormRunVariableProps) => {
  const updatedVariable: V2RunVariable = useMemo(() => {
    return procedureVariableUtil.getUpdatedVariable(variable);
  }, [variable]);

  const [recordedValue, setRecordedValue] = useState(updatedVariable.value);
  const [errors, setErrors] = useState({});
  const { mixpanel } = useMixpanel();

  const isEnabledCheck = isEnabled && (updatedVariable.value === undefined || updatedVariable.value === null);

  const mixpanelTrack = useCallback(
    (trackingKey) => {
      if (mixpanel && trackingKey) {
        mixpanel.track(trackingKey);
      }
    },
    [mixpanel]
  );

  const onSubmit = useCallback(() => {
    if (recordedValue !== undefined && isEmpty(errors) && typeof onSaveVariable === 'function') {
      const recordedVariable = cloneDeep(updatedVariable);
      recordedVariable.value = recordedValue;
      mixpanelTrack(`Procedure Variable Saved, variable type: ${recordedVariable.inputType}`);
      onSaveVariable(recordedVariable);
    }
  }, [recordedValue, errors, onSaveVariable, updatedVariable, mixpanelTrack]);

  const recorded = useMemo(() => {
    if (recordedValue !== undefined) {
      return { value: recordedValue };
    }
  }, [recordedValue]);

  useEffect(() => {
    setRecordedValue(updatedVariable.value);
  }, [updatedVariable.value]);

  const onVariableRefChanged = useCallback(
    (element) => {
      if (typeof onRefChanged === 'function') {
        onRefChanged(updatedVariable.id, element);
      }
    },
    [updatedVariable, onRefChanged]
  );

  const onRecordValuesChanged = useCallback((recorded) => setRecordedValue(recorded.value), []);

  const onRecordErrorsChanged = useCallback((errors) => setErrors(errors), []);

  return (
    <div
      className="flex items-center"
      ref={onVariableRefChanged}
      style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
    >
      <div className="flex flex-col mr-2">
        <FieldInput
          key={updatedVariable.name}
          /* @ts-ignore TODO: remove when BlockTypes are refactored to view types */
          block={updatedVariable}
          /* @ts-ignore TODO: remove when BlockTypes are refactored to view types */
          recorded={recorded}
          isEnabled={isEnabledCheck}
          onRecordValuesChanged={onRecordValuesChanged}
          onRecordErrorsChanged={onRecordErrorsChanged}
        />
      </div>
      {isEnabledCheck && (
        <div className="flex flex-col mr-2 items-center print:hidden">
          <Button size="sm" type="primary" onClick={onSubmit}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(FormRunVariable);

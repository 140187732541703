import { CommandingBlockDiffElement } from 'shared/lib/types/views/procedures';
import sharedDiffUtil from 'shared/lib/diffUtil';
import { isNil } from 'lodash';

const commanding = {
  /**
   * Returns true if the command block represents a 'standard' command.
   *
   * Standard commands are available through the API and represent the
   * future architecture of command objects.
   *
   * command: A procedure command block object.
   * returns: True if this command object represents a standard command,
   *          otherwise false.
   */
  isStandardCommand: (command: CommandingBlockDiffElement): boolean => {
    return (
      !isNil(command) &&
      !isNil(sharedDiffUtil.getDiffValue(command, 'key', 'new'))
    );
  },

  /**
   * Gets the command name from a procedure command block object. Handles
   * backwards compatibility with `key` and `name` issues.
   *
   * command: A procedure command block object.
   * returns: String, Command name for this block, or null if this block does
   *          not represent a command object.
   */
  getCommandName: (command: CommandingBlockDiffElement): string | null => {
    // Check for newer command definition and use `name` property.
    const commandKey =
      sharedDiffUtil.getDiffValue<string>(command, 'key', 'new') || '';
    const commandName = sharedDiffUtil.getDiffValue<string>(
      command,
      'name',
      'new'
    );
    if (commandKey.toLowerCase() === 'commanding') {
      if (commandName) {
        return commandName;
      }
      return null;
    }
    /*
     * COSMOS is deprecated in this codebase. This is just a stub to support legacy COSMOS data.
     * Fallback to `cosmos` definition if defined.
     */
    if (commandKey.toLowerCase() === 'cosmos') {
      if (commandName) {
        return commandName;
      }
      const cosmos = command.cosmos;
      if (cosmos.target && cosmos.command) {
        return `${cosmos.target}.${cosmos.command}`;
      }
    }
    // Fallback to whatever is in newer `name` property if defined.
    if (commandName) {
      return commandName;
    }
    // Fallback to old `key` property as last resort.
    return commandKey;
  },
};

export default commanding;

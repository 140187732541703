import React, { useMemo } from 'react';
import {
  DraftAlertBlock,
  DraftExternalItemBlock,
  DraftFieldInputBlock,
  DraftRequirementBlock,
  ReleaseAlertBlock,
  ReleaseFieldInputBlock,
  RunHeaderBlockRedline,
  RunStepBlockRedline,
} from 'shared/lib/types/views/procedures';
import { HeaderBlockRedline, StepBlockRedline } from 'shared/lib/types/views/redlines';
import BlockRedlineBlock from './redline/BlockRedlineBlock';
import { getRedlineDocsSortedLatestToEarliest } from '../../lib/redlineUtil';
import AlertFieldSet from './AlertFieldSet';
import { FormikHelpers, FormikValues } from 'formik';
import {
  AlertBlockContentErrors,
  ExternalItemContentErrors,
  FieldInputBlockContentErrors,
  HeaderBlockErrors,
  RequirementContentErrors,
  StepBlockErrors,
} from '../../lib/types';
import RequirementFieldSet from './RequirementFieldSet';
import FieldInputFieldSet from './FieldInputFieldSet';
import ExternalItemFieldSet from './ExternalItemFieldSet';
import { getRedlineFromDoc } from 'shared/lib/redlineUtil';
import { getRedlineSourceStepPath } from '../../lib/pathUtil';
import { useDatabaseServices } from '../../contexts/DatabaseContext';

type FieldSetHeaderBlockType = DraftAlertBlock;
type FieldSetStepBlockType = DraftAlertBlock | DraftFieldInputBlock | DraftRequirementBlock | DraftExternalItemBlock;

/**
 * Component for rendering the form fields for a given procedure content block
 * when editing a procedure. Supports showing any redline changes to a
 * block for acceptance/rejection.
 *
 * @param type - header or step
 * @param path - a path prefix to use in for all Formik fields. Can be empty.
 *       Eg, 'sections[0].steps[0].content[2]', for editing the third block
 *       in the first step of the first section.
 * @param block - The current Block being edited.
 * @param contentErrors - Current Formik errors for the given content object.
 * @param setFieldValue - Function to call when a field value is updated
 * @param acceptRedline - Function to call when a redline block is accepted
 * @param rejectRedline - Function to call when a redline block is rejected
 */
interface FieldSetProcedureBlockInterface<RedlineArrayT extends Array<HeaderBlockRedline> | Array<StepBlockRedline>> {
  path: string;
  block: RedlineArrayT extends Array<HeaderBlockRedline> ? FieldSetHeaderBlockType : FieldSetStepBlockType;
  redlines?: RedlineArrayT;
  contentErrors: RedlineArrayT extends Array<HeaderBlockRedline> ? HeaderBlockErrors : StepBlockErrors;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
  acceptRedline?: (
    path: string,
    block: ReleaseAlertBlock | ReleaseFieldInputBlock,
    redline: RunHeaderBlockRedline | RunStepBlockRedline
  ) => void;
  rejectRedline?: (
    path: string,
    block: ReleaseAlertBlock | ReleaseFieldInputBlock,
    redline: RunHeaderBlockRedline | RunStepBlockRedline
  ) => void;
  isDisabled?: boolean;
}
const FieldSetProcedureBlock = <RedlineArrayT extends Array<HeaderBlockRedline> | Array<StepBlockRedline>>({
  path,
  block,
  redlines,
  contentErrors,
  setFieldValue,
  acceptRedline,
  rejectRedline,
  isDisabled,
}: FieldSetProcedureBlockInterface<RedlineArrayT>) => {
  const { currentTeamId } = useDatabaseServices();
  const redlinesSorted = useMemo(
    () => getRedlineDocsSortedLatestToEarliest<HeaderBlockRedline | StepBlockRedline>(redlines),
    [redlines]
  );

  const hasRedlines = useMemo(() => redlinesSorted && redlinesSorted.length > 0, [redlinesSorted]);
  return (
    <fieldset className="min-w-0 w-full flex flex-col gap-y-2">
      {/* Prompt for accepting/rejecting latest redline changes */}
      {hasRedlines &&
        acceptRedline &&
        rejectRedline &&
        redlinesSorted.map((redline) => (
          <BlockRedlineBlock
            key={redline._id}
            block={block as DraftAlertBlock | DraftFieldInputBlock}
            path={path}
            contentErrors={contentErrors as Array<StepBlockErrors>}
            runRedline={getRedlineFromDoc(redline) as RunHeaderBlockRedline | RunStepBlockRedline}
            acceptRedline={acceptRedline}
            rejectRedline={rejectRedline}
            contextUrl={getRedlineSourceStepPath({
              teamId: currentTeamId,
              redline,
            })}
          />
        ))}

      {/* Render form fields for block content */}
      {block.type === 'requirement' && (
        <RequirementFieldSet
          path={path}
          block={block}
          contentErrors={contentErrors as RequirementContentErrors}
          setFieldValue={setFieldValue}
        />
      )}

      {block.type === 'alert' && (
        <AlertFieldSet
          path={path}
          content={block as DraftAlertBlock}
          contentErrors={contentErrors as AlertBlockContentErrors}
          setFieldValue={setFieldValue}
        />
      )}

      {block.type === 'input' && (
        <FieldInputFieldSet
          path={path}
          content={block as DraftFieldInputBlock}
          contentErrors={contentErrors as FieldInputBlockContentErrors}
          setFieldValue={setFieldValue}
          isDisabled={isDisabled}
        />
      )}

      {block.type === 'external_item' && (
        <ExternalItemFieldSet
          path={path}
          content={block as DraftExternalItemBlock}
          contentErrors={contentErrors as ExternalItemContentErrors}
          setFieldValue={setFieldValue}
        />
      )}
    </fieldset>
  );
};

export default FieldSetProcedureBlock;

import { Request } from 'express';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Multer } from 'multer';
import { SourceType } from '../../attachments';

export const MAX_FILE_SIZE_MB = 500;
export const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;

/**
 * Represents the request body received by the POST /files endpoint.
 */
export type AddFileReceiveRequest = Omit<Request, 'body'> & {
  body: {
    id: string;
    name: string;
    source: SourceType;
  };
  file: Express.Multer.File;
};

/**
 * Represents the request sent by the POST /files endpoint. Needs to
 * be assembled into a FormData before sending.
 */
export type AddFileSendRequest = {
  id: string;
  name: string;
  source: SourceType;
  file: Blob | Buffer;
};

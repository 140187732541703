import Button, { BUTTON_TYPES } from './Button';
import ModalBase from './ModalBase';
import ModalActionBar from './ModalActionBar';
import ModalContent from './ModalContent';

interface ModalDeleteSectionStepUnresolvedCommentsProps {
  onSecondaryAction: () => void;
}

const ModalDeleteSectionStepUnresolvedComments = ({
  onSecondaryAction,
}: ModalDeleteSectionStepUnresolvedCommentsProps) => (
  <ModalBase>
    <ModalContent title="Cannot be removed.">
      <div className="w-full">Please address any unresolved comments before removing. </div>
    </ModalContent>
    <ModalActionBar>
      <div className="flex gap-x-2">
        <Button
          type={BUTTON_TYPES.PRIMARY}
          ariaLabel="unresolvedactions"
          isDisabled={false}
          onClick={onSecondaryAction}
        >
          Proceed
        </Button>
      </div>
    </ModalActionBar>
  </ModalBase>
);

export default ModalDeleteSectionStepUnresolvedComments;

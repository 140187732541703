import { useLocation } from 'react-router-dom';

const TEAM_REGEX = /\/app\/team\/([^/]+)\/?/i;

const useTeamFromPath = (): string | undefined => {
  const location = useLocation();
  const match = location.pathname.match(TEAM_REGEX);
  if (match && match.length > 1) {
    return match[1];
  }
};

export default useTeamFromPath;

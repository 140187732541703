const ID_FIELD_NAME = 'id';
const ID_FIELD_TYPES = ['string', 'number'];

/*
 * Returns all ids in an object, i.e., the value of all
 * fields whose key is "id."
 */
const getAllIds = (obj: object): Set<string> => {
  const ids = new Set<string>();
  _getAllIds(obj, ids);
  return ids;
};

const _getAllIds = (obj: object, ids: Set<string>): void => {
  Object.entries(obj).forEach(([key, value]) => {
    if (value && typeof value === 'object') {
      _getAllIds(value, ids);
    } else if (key === ID_FIELD_NAME && ID_FIELD_TYPES.includes(typeof value)) {
      ids.add(value);
    }
  });
};

export default getAllIds;

import React from 'react';
import { RuleOperator, Range as RangeType, FieldInputNumberBlock } from 'shared/lib/types/views/procedures';

import RuleStatus from './RuleStatus';
import { isEmptyValue } from 'shared/lib/text';

export const Chip = ({ name, isLight = false }: { name: React.ReactNode; isLight?: boolean }) => {
  return (
    <div
      className={`flex items-center text-xs font-medium font-mono py-0.5 px-1.5 rounded-xl ${
        isLight ? 'bg-white' : 'bg-gray-200'
      }`}
    >
      {name}
    </div>
  );
};
export const Range = ({
  range,
  inputName,
  isLight = false,
  useChip = true,
}: {
  range: RangeType;
  inputName: React.ReactNode;
  isLight?: boolean;
  useChip?: boolean;
}) => {
  const lowerInequality = range.include_min ? '≤' : '<';
  const upperInequality = range.include_max ? '≤' : '<';

  const min = isEmptyValue(range.min) ? '?' : range.min;
  const max = isEmptyValue(range.max) ? '?' : range.max;
  return (
    <div className="flex flex-row gap-x-2 flex-nowrap items-center">
      <span className="font-mono">
        {min} {lowerInequality}
      </span>{' '}
      {useChip && <Chip name={inputName ?? '(input)'} isLight={isLight} />}
      {!useChip && <span>{inputName ?? '(input)'}</span>}
      <span className="font-mono">
        {upperInequality} {max}
      </span>
    </div>
  );
};
export const BasicRule = ({
  op,
  value,
  inputName,
  isLight = false,
  useChip = true,
}: {
  op: RuleOperator;
  value: string | number;
  inputName: React.ReactNode;
  isLight?: boolean;
  useChip?: boolean;
}) => {
  if (!op) {
    return <div className="font-mono">No Rule</div>;
  }

  return (
    <div className="flex flex-row gap-x-2 flex-nowrap items-center">
      {useChip && <Chip name={inputName ?? '(input)'} isLight={isLight} />}
      {!useChip && <span>{inputName ?? '(input)'}</span>}
      <span className="font-mono">
        {op} {value}
      </span>
    </div>
  );
};

interface RuleProps {
  block: FieldInputNumberBlock;
  isPassing?: boolean | null;
  label?: React.ReactNode;
}

const Rule = ({ block, isPassing = null, label = block.name }: RuleProps) => {
  return (
    <div className="flex flex-col gap-2 w-fit items-start">
      {isPassing !== null && <RuleStatus isPassing={isPassing} />}
      {!block.rule?.op && <div className="font-mono">No Rule</div>}
      {block.rule?.op && (
        <>
          {block.rule.op !== 'range' && (
            <BasicRule op={block.rule.op} value={block.rule.value} inputName={label} useChip={label === block.name} />
          )}
          {block.rule.op === 'range' && block.rule.range && (
            <Range range={block.rule.range} inputName={label} useChip={label === block.name} />
          )}
        </>
      )}
    </div>
  );
};

export default Rule;

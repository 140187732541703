import EventEmitter2 from 'eventemitter2';
import ParametersObserver from '../telemetry/parameters';
import { isEmpty } from 'lodash';
import { Run } from 'shared/lib/types/views/procedures';
import { Reading, TelemetryParams } from 'shared/lib/types/telemetry';
import { getTelemetryStreamId } from 'shared/lib/realtimeUpdates';
import BulkParametersObserver from '../telemetry/observeBulk';
import TelemetryService from '../api/telemetry';

type Callback = () => void;
type ServicesWithTelemetry = { telemetry: TelemetryService };

// Total number of sections, steps, and context blocks we support
const MAX_LISTENERS = 1000000;

class TelemetryUpdater {
  emitter: EventEmitter2;
  observer: ParametersObserver | BulkParametersObserver | null;
  isPreviewMode: boolean;

  constructor(isPreviewMode: boolean) {
    this.emitter = new EventEmitter2();
    this.observer = null;
    this.emitter.setMaxListeners(MAX_LISTENERS);
    this.onReceiveTelemetry = this.onReceiveTelemetry.bind(this);
    this.onSignalLost = this.onSignalLost.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.isPreviewMode = isPreviewMode;
  }

  start(
    services: ServicesWithTelemetry,
    run: Run,
    telemetryParameters: TelemetryParams,
    teamId: string,
    useBulkFetch: boolean
  ): void {
    if (this.observer) {
      this.observer.clear();
      this.observer = null;
    }

    if (
      !telemetryParameters ||
      isEmpty(telemetryParameters) ||
      !services.telemetry
    ) {
      return;
    }

    const runId = run && run._id;

    const onChange = (samples: Record<string, Reading>) => {
      Object.keys(samples).forEach((key) => {
        this.emitter.emit(key, samples[key]);
      });
    };

    const onSignaLost = () => {
      this.emitter.emit('signal_lost');
    };

    if (useBulkFetch) {
      this.observer = new BulkParametersObserver(
        services.telemetry,
        runId,
        onChange,
        onSignaLost
      );
    } else {
      this.observer = new ParametersObserver(
        services.telemetry,
        run.operation?.name,
        run.variables,
        runId,
        onChange,
        onSignaLost
      );
    }

    const params = Object.values(telemetryParameters).map((param) => ({
      name: param.name,
      dictionaryId: param.dictionaryId,
      runId,
      isSimulation: param.isSimulation,
      streamId: getTelemetryStreamId({
        teamId,
        parameterName: param.name,
        runId,
        variables: run.variables,
        dictionaryId: param.dictionaryId,
      }),
    }));

    if (this.isPreviewMode) {
      this.observer.observeParameters(Object.values(params), run.variables);
    } else {
      this.observer.observeParameters(Object.values(params));
    }
  }

  stop(): void {
    if (!this.observer) {
      return;
    }
    this.observer.clear();
    this.observer = null;
  }

  onReceiveTelemetry(callback: Callback, ids: Array<string> = []): Callback {
    if (ids.length > 0) {
      ids.forEach((id) => {
        this.emitter.on(id, callback);
      });
    }

    return () => {
      if (ids.length > 0) {
        ids.forEach((id) => {
          this.emitter.off(id, callback);
        });
      }
    };
  }

  onSignalLost(callback: Callback): Callback {
    this.emitter.on('signal_lost', callback);

    return () => {
      this.emitter.off('signal_lost', callback);
    };
  }
}

export default TelemetryUpdater;

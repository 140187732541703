import ReferenceToken from './Blocks/Display/ReferenceToken';
import { ExpressionReferences, ExpressionToken } from 'shared/lib/types/views/procedures';

interface ReferenceDetailProps {
  tokens: Array<ExpressionToken>;
  displayText: string;
  references?: ExpressionReferences;
}

const ReferenceDetail = ({ tokens, displayText, references }: ReferenceDetailProps) => {
  if (tokens && tokens.length > 0) {
    return (
      <div className="break-all max-w-max text-sm">
        <div className="flex flex-col">
          <div className="flex flex-col ml-0.5 font-bold">{displayText}</div>
          <div>
            <div className="flex flex-row items-center flex-wrap">
              {tokens.map((token, index) => {
                return token.type === 'reference' && token.reference_id ? (
                  <ReferenceToken
                    key={index}
                    originalReferencedContentId={token.reference_id}
                    originalToken={token}
                    references={references}
                  />
                ) : (
                  <div key={index} className="py-1 mx-0.5 text-sm">
                    {token.value}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ReferenceDetail;

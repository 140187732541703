import authUtil from '../../lib/authUtil';
import superlogin from '../../api/superlogin';

/**
 * Helper method to fetch a JSON response with auth support.
 *
 * @param {URL} url - The url to fetch.
 * @param {Object | null} options - (Optional) Options object to pass to native fetch.
 * @returns {Promise<Object>} - The fetch results as JSON data.
 */
const fetchJson = async (url, options) => {
  // authUtil expects the headers to be a Headers object.
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');

  const _options = {
    ...options,
    headers,
  };

  // Send request with auth library for session handling.
  const response = await authUtil.authFetch(superlogin, url.toString(), _options);

  // Return data on success (2XX), and throw error on failure.
  if (!response.ok) {
    throw Error(response.statusText);
  }
  // Get response data.
  return response.json();
};

export default fetchJson;

import { BlockType } from 'shared/lib/types/blockTypes';
import {
  AttachmentBlockDiffElement,
  ConditionalDiff,
  ConditionalDiffElement,
  ContentBlock,
  Diffable,
  DiffableDiff,
  DiffArrayChangeSymbol,
  DiffElement,
  DiffField,
  DiffFieldChange,
  Draft,
  EndRunSignoffsGroupsDiff,
  EndRunSignoffsGroupsDiffElement,
  ExpressionBlockDiffElement,
  ExpressionTokenDiffElement,
  FieldInputBlock,
  FieldInputBlockDiffElement,
  HeaderBlockDiff,
  HeaderBlockDiffElement,
  HeaderDiff,
  HeaderDiffElement,
  Procedure,
  ProcedureDiff,
  ProcedurePrimitive,
  ProcedureSettings,
  Range,
  RangeDiffElement,
  RedlinedStep,
  Release,
  Rule,
  RuleDiffElement,
  RuleOperator,
  SectionDiff,
  SectionDiffElement,
  SectionHeaderDiff,
  SectionHeaderDiffElement,
  SettingsDiff,
  SignoffDiff,
  SignoffDiffElement,
  Step,
  StepBlockDiff,
  StepBlockDiffElement,
  DependencyDiff,
  DependencyDiffElement,
  StepDiff,
  StepDiffElement,
  StepHeaderBlockDiff,
  StepHeaderBlockDiffElement,
  StepHeaderDiff,
  StepHeaderDiffElement,
  TableCell,
  TableColumnDiffElement,
  TableInputBlockDiffElement,
  TagDiff,
  TagDiffElement,
  TelemetryBlockDiffElement,
  V2Variable,
  VariableDiff,
  VariableDiffElement,
  WithDiffChange,
  WithDiffChangeI,
  Section,
  RunStep,
} from 'shared/lib/types/views/procedures';
import _, {
  cloneDeep,
  isEmpty,
  isEqual,
  isEqualWith,
  isNil,
  mapKeys,
  mapValues,
  omit,
  pickBy,
  zip,
} from 'lodash';
import { diff } from 'json-diff';
import procedureUtil, { Summary } from './procedureUtil';
import procedureVariableUtil from './procedureVariableUtil';
import printUtil from './printUtil';
import revisions from './revisions';
import { RunTag, Tag } from 'shared/lib/types/couch/settings';
import stepConditionals from 'shared/lib/stepConditionals';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import tableUtil from 'shared/lib/tableUtil';
import { copyStepWithoutActiveContent } from 'shared/lib/runUtil';
import { updateIdsForIncludingSuggestedEdit } from 'shared/lib/runStepUtil';

export type ChangeEntry = {
  title?: 'title';
  version?: 'version';
  owner?: 'owner';
  project?: 'project';
  defaultView?: 'defaultView';
  endRunSignoffOperators?: 'endRunSignoffOperators';
  printHeader?: 'printHeader';
  printFooter?: 'printFooter';
  description?: 'description';
  tags?: 'tags';
  variableId?: string;
  partListId?: string;
  headerId?: string;
  sectionId?: string;
  sectionHeaderId?: string;
  stepId?: string;
} & {
  [key in keyof ProcedureSettings]: keyof ProcedureSettings;
};

type DiffableProcedure = Release | Draft;

export const DIFF_COLOR_CLASSES = {
  [ARRAY_CHANGE_SYMBOLS.ADDED]: {
    borderColor: 'border-green-600 shadow border-2',
    backgroundColor: 'bg-app-green-200',
  },
  [ARRAY_CHANGE_SYMBOLS.REMOVED]: {
    borderColor: 'border-red-600 shadow border-2',
    backgroundColor: 'bg-red-100',
  },
  [ARRAY_CHANGE_SYMBOLS.MODIFIED]: {
    borderColor: 'border-red-600 shadow border-2',
    backgroundColor: '',
  },
  [ARRAY_CHANGE_SYMBOLS.UNCHANGED]: {
    borderColor: '',
    backgroundColor: '',
  },
};

const diffUtil = {
  /**
   * Get the procedure diff and modify it so that it is of the form of a procedure. For example, any array that contains
   * elements that were modified will be transformed by json-diff into a 2D array with diff information, so we need to transform
   * the diff'ed json back not only into a form our app can understand, but also a form that contains change information.
   */
  getProcedureDiff: (
    previous: DiffableProcedure,
    updated: DiffableProcedure,
    isFullDiffReviewDisabled = true,
    ignoredFields: Array<string> = []
  ): ProcedureDiff => {
    let updatedCopy = cloneDeep(updated);
    revisions._stripRunSteps(updatedCopy);

    updatedCopy = procedureUtil.getProcedureDefinition(
      updatedCopy
    ) as DiffableProcedure;
    const previousCopy = procedureUtil.getProcedureDefinition(previous);
    diffUtil._prepareProceduresForDiff(previousCopy, updatedCopy);

    const procedureDiff = diff(previousCopy, updatedCopy, {
      full: true,
      excludeKeys: ignoredFields,
    });

    const procedureDiffFlattened = diffUtil._flattenDiffArrays(procedureDiff);

    const procedureDiffCleaned = !isFullDiffReviewDisabled
      ? diffUtil._cleanProcedureDiff(procedureDiffFlattened, updatedCopy)
      : diffUtil._replaceWithNewValues(procedureDiffFlattened, updatedCopy);

    diffUtil._includePreviousVariables(procedureDiffCleaned, previous);
    if (procedureDiffCleaned.variables) {
      procedureDiffCleaned.variables =
        diffUtil._replaceProcedureVariablesWithNewValues(
          procedureDiffCleaned,
          updated
        );
    }
    diffUtil._updateStepDiffChangeStateForConditionals(procedureDiffCleaned);

    return procedureDiffCleaned;
  },

  /**
   * Return a list of StepDiffElement in case the diff is interpreted as removing and adding the step.
   */
  getStepDiff: ({
    previous,
    updated,
    ignoredFields = [],
  }: {
    previous: Partial<Step | RedlinedStep>;
    updated: Partial<Step | RedlinedStep>;
    ignoredFields?: Array<string>;
  }): Array<StepDiffElement> => {
    const previousPseudoProcedure = {
      sections: [{ steps: [previous] }],
    } as Release;
    const updatedPseudoProcedure = {
      sections: [{ steps: [updated] }],
    } as Release;

    const pseudoProcedureDiff = diffUtil.getProcedureDiff(
      previousPseudoProcedure,
      updatedPseudoProcedure,
      false,
      ignoredFields
    );

    return (pseudoProcedureDiff.sections[0] as SectionDiffElement)
      .steps as Array<StepDiffElement>;
  },

  getBlockDiff: ({
    previousStep,
    updatedStep,
    blockId,
    sourceBlockId,
  }: {
    previousStep: Step | RedlinedStep;
    updatedStep: Step | RedlinedStep;
    blockId: string;
    sourceBlockId: string;
  }): Array<StepBlockDiffElement> => {
    const previousPseudoStep = diffUtil.prepareRedlinedStepForBlockDiff({
      step: previousStep,
      blockId,
    });

    const updatedPseudoStep = diffUtil.prepareRedlinedStepForBlockDiff({
      step: updatedStep,
      blockId: sourceBlockId,
    });

    if (!previousPseudoStep || !updatedPseudoStep) {
      return [];
    }

    const pseudoStepDiffArray = diffUtil.getStepDiff({
      previous: previousPseudoStep,
      updated: updatedPseudoStep,
    });

    return pseudoStepDiffArray
      .map((pseudoStepDiff) => pseudoStepDiff.content)
      .flat() as Array<StepBlockDiffElement>;
  },

  getFieldInputBlockDiff: <T extends FieldInputBlock>(
    previous: T,
    updated: T
  ): FieldInputBlockDiffElement => {
    return diff(previous, updated, { full: true });
  },

  // Extra work is required to diff `table_input` content blocks. See `_convertTableColumns` for full explanation.
  _prepareProceduresForDiff: (
    previous: Procedure,
    updated: DiffableProcedure
  ): void => {
    updated.headers?.forEach((header) => {
      diffUtil._prepareTablesAndTelemetryForDiff(header.content);
    });
    updated.sections.forEach((section) => {
      diffUtil._prepareDependenciesForDiff(section);
      section.steps.forEach((step) => {
        diffUtil._prepareTablesAndTelemetryForDiff(step.content);
        diffUtil._prepareDependenciesForDiff(step);
      });
    });
    previous.headers?.forEach((header) => {
      diffUtil._prepareTablesAndTelemetryForDiff(header.content);
    });
    previous.sections.forEach((section) => {
      diffUtil._prepareDependenciesForDiff(section);
      section.steps.forEach((step) => {
        diffUtil._prepareTablesAndTelemetryForDiff(step.content);
        diffUtil._prepareDependenciesForDiff(step);
      });
    });
  },

  _prepareDependenciesForDiff: (block: Step | Section): void => {
    if (block.dependencies) {
      block.dependencies = block.dependencies?.filter(
        (dependency) => dependency.dependent_ids.length > 0
      );
    }
  },

  /**
   * The `table_input` content block stores column information in the field `columns` and all the values for the
   * table in a 2D array `cells`, indexed first by rows and then by columns. Separating the column information from
   * the values that the columns contains causes issues when diffing. Particularly, when many columns are added and
   * deleted, json-diff may return that an unmodified column was removed and added. However, there is only 1 copy of
   * values in the `cells` array for that column, which causes bugs when trying to determine the diff of the `cells`
   * values.
   *
   * The solution here is to move the values for every column to the column object itself and then perform the diff, so
   * that the column metadata and the column values are diffed together. During a later stage, we convert the `column_values`
   * to `DiffField`s as necessary and copy them back to the `cell` array.
   */
  _prepareTablesAndTelemetryForDiff: (
    content: Array<ContentBlock> | undefined
  ): void => {
    content?.forEach((contentBlock) => {
      if (contentBlock.type === 'table_input') {
        contentBlock.columns.forEach((column, index) => {
          column['column_values'] = [];
          contentBlock.cells?.forEach((row) => {
            const value = row[index];
            column['column_values'].push(
              Array.isArray(value) || typeof value === 'object'
                ? JSON.stringify(value)
                : value
            );
          });
        });
      } else if (contentBlock.type === 'telemetry') {
        if (contentBlock.value) {
          contentBlock.value = `${contentBlock.value}`;
        }
        if (contentBlock.range && contentBlock.range.min) {
          contentBlock.range.min = `${contentBlock.range?.min}`;
        }
        if (contentBlock.range && contentBlock.range.max) {
          contentBlock.range.max = `${contentBlock.range?.max}`;
        }
      }
    });
  },

  /**
   * Since conditionals affect both the source step and the target step, but only change the source step object, the
   * target step may be changed but json-diff will not detect it. This updates the step diff_change_state when this is
   * the case, so that "Step has changed" `DiffContainer` is displayed, and so that the user can scroll to the step
   * change.
   */
  _updateStepDiffChangeStateForConditionals: (
    procedureDiff: ProcedureDiff
  ): void => {
    procedureDiff.sections.forEach((section) => {
      section.steps.forEach((step) => {
        if (
          (!step.diff_change_state ||
            step.diff_change_state === ARRAY_CHANGE_SYMBOLS.UNCHANGED) &&
          stepConditionals.getDiffChangeStateFromSourceConditionalMaps(
            step,
            // Equivalent of `sourceStepConditionalsMap` from `useProcedureAdapter`
            stepConditionals.getSourceConditionalsMapForVersion(
              procedureDiff,
              'new'
            ),
            // Equivalent of `removedSourceStepConditionalsMap` from `useProcedureAdapter`
            stepConditionals.getSourceOldConditionalsMap(procedureDiff)
          ) !== ARRAY_CHANGE_SYMBOLS.UNCHANGED
        ) {
          step.diff_change_state = ARRAY_CHANGE_SYMBOLS.MODIFIED;
          if (
            !section.diff_change_state ||
            section.diff_change_state === ARRAY_CHANGE_SYMBOLS.UNCHANGED
          ) {
            section.diff_change_state = ARRAY_CHANGE_SYMBOLS.MODIFIED;
          }
        }
      });
    });
  },

  trimFieldName: (field: string): string => {
    return field.replace('__deleted', '').replace('__added', '');
  },

  /**
   * If an object of the same type is both added and removed, json-diff will consider it as a single
   * modified object, rather than one added object and one removed object separately. We can tell
   * this has happened because the "id" field of the object will have both an "old" and "new" value.
   *
   * There is also a case for field inputs where the `inputType` field has changed, and we want to consider these
   * separate objects as well, since different components are used to render different input types.
   *
   * This method splits the single modified object into two separate diff objects (one added and one removed).
   * Note: Currently handles sub-fields that are objects themselves and arrays of `ProcedurePrimitive`, but it does not
   * yet handle arrays of objects.
   */
  _splitIfIdsOrInputTypesModifiedIntoAddedAndRemoved: (
    diffObject: object
  ): Array<object> => {
    const oldId: string = sharedDiffUtil.getDiffValue(diffObject, 'id', 'old');
    const newId: string = sharedDiffUtil.getDiffValue(diffObject, 'id', 'new');
    const oldInputType = sharedDiffUtil.getDiffValue<string>(
      diffObject,
      'inputType',
      'old'
    );
    const newInputType = sharedDiffUtil.getDiffValue<string>(
      diffObject,
      'inputType',
      'new'
    );
    if (oldId === newId && oldInputType === newInputType) {
      return [diffObject];
    }
    return [
      oldInputType !== newInputType
        ? diffUtil._splitRemovedObject({
            ...diffObject,
            id: `${oldId}__removed`,
          })
        : diffUtil._splitRemovedObject(diffObject),
      diffUtil._splitAddedObject(diffObject),
    ];
  },

  _splitDiffArray: (
    arrayEntry: Array<object | ProcedurePrimitive>,
    diffChangeState: DiffArrayChangeSymbol
  ): object => {
    return arrayEntry
      .filter(
        (entry) =>
          !diffUtil.isDiffPair(entry) ||
          (Array.isArray(entry) &&
            entry.length === 2 &&
            (entry[0] === ARRAY_CHANGE_SYMBOLS.UNCHANGED ||
              entry[0] === diffChangeState ||
              entry[0] === ARRAY_CHANGE_SYMBOLS.MODIFIED))
      )
      .map((entry) =>
        Array.isArray(entry) && diffUtil.isDiffPair(entry) ? entry[1] : entry
      )
      .map((entry) =>
        Array.isArray(entry)
          ? diffUtil._splitDiffArray(entry, ARRAY_CHANGE_SYMBOLS.REMOVED)
          : typeof entry === 'object'
          ? diffChangeState === ARRAY_CHANGE_SYMBOLS.REMOVED
            ? diffUtil._splitRemovedObject(entry)
            : diffUtil._splitAddedObject(entry)
          : entry
      );
  },

  /**
   * When a similar object is removed, json-diff sometimes mistakenly marks the object as modified.
   * This method splits out the originally removed object from the modified object, so it can be displayed correctly.
   */
  _splitRemovedObject: (diffObject: object): object => {
    const removedObject: WithDiffChange<object> = mapKeys(
      mapValues(
        pickBy(diffObject, (value, key) => !key.includes('__added')),
        (_, key) => {
          let oldValue: ProcedurePrimitive | object =
            sharedDiffUtil.getDiffValue(diffObject, key, 'old');
          /*
           * Arrays of procedure primitives have 2 options for values:
           * 1. Array entries are not changed, so they remain as they were in the original object.
           * 2. Array entries are another array with two entries:
           *     * The first entry is the diff change state: added, removed, unchanged (json-diff does not use modified
           *       for primitive arrays; if an item is modified, it shows up as 2 elements: 1 added and 1 removed).
           *     * The second entry is the ProcedurePrimitive value.
           * Currently only handle arrays of `ProcedurePrimitive`. The logic would need to be adjusted for object arrays.
           */
          if (oldValue && Array.isArray(oldValue)) {
            oldValue = diffUtil._splitDiffArray(
              oldValue,
              ARRAY_CHANGE_SYMBOLS.REMOVED
            );
          } else if (oldValue && typeof oldValue === 'object') {
            oldValue = diffUtil._splitRemovedObject(oldValue);
          }
          return oldValue;
        }
      ),
      (_, key) => diffUtil.trimFieldName(key)
    );
    removedObject.diff_change_state = ARRAY_CHANGE_SYMBOLS.REMOVED;
    return removedObject;
  },

  /**
   * When a similar object is removed, json-diff sometimes mistakenly marks the object as modified.
   * This method splits out the originally added object from the modified object, so it can be displayed correctly.
   */
  _splitAddedObject: (diffObject: object): object => {
    const addedObject: WithDiffChange<object> = mapKeys(
      mapValues(
        pickBy(diffObject, (value, key) => !key.includes('__deleted')),
        (_, key) => {
          let newValue: ProcedurePrimitive | object =
            sharedDiffUtil.getDiffValue(diffObject, key, 'new');
          if (newValue && Array.isArray(newValue)) {
            newValue = diffUtil._splitDiffArray(
              newValue,
              ARRAY_CHANGE_SYMBOLS.ADDED
            );
          } else if (newValue && typeof newValue === 'object') {
            newValue = diffUtil._splitAddedObject(newValue);
          }
          return newValue;
        }
      ),
      (_, key) => diffUtil.trimFieldName(key)
    );
    addedObject.diff_change_state = ARRAY_CHANGE_SYMBOLS.ADDED;
    return addedObject;
  },

  _processContentItemsArray: (
    content: StepBlockDiffElement
  ): StepBlockDiffElement => {
    // @ts-ignore TODO(EPS-4464): Types for builds are not in procedure views yet.
    content.items = content.items.flatMap((item) => {
      if (diffUtil.isDiffPair(item)) {
        const splitItems =
          diffUtil._splitIfIdsOrInputTypesModifiedIntoAddedAndRemoved(item[1]);
        return splitItems.length === 1
          ? { ...splitItems[0], diff_change_state: item[0] }
          : splitItems;
      }
      /*
       * Don't persist diff_change_state for items that aren't a part of a diff pair. This is because
       * items have an added/removed diff_change_state when the whole part kit or part build was added or removed.
       * But we don't want to display the UI background and text changes for added or removed items when the whole
       * part kit or part build was added or removed because we've already outlined it in a box with an added and
       * removed message.
       */
      delete item.diff_change_state;
      return [item];
    });
    return content;
  },

  _setAddedAndRemovedColumnsForTable: (
    content: TableInputBlockDiffElement
  ): { removedColumnsFound: Set<number>; addedColumnsFound: Set<number> } => {
    const removedColumnsFound = new Set(
      content.columns.flatMap((column, index) =>
        column.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED ? [index] : []
      )
    );
    const addedColumnsFound = new Set(
      content.columns.flatMap((column, index) =>
        column.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED ? [index] : []
      )
    );

    // @ts-ignore Field `removed_columns` does not exist on TableInputBlock but is only necessary here for diffs.
    content.removed_columns = removedColumnsFound;
    // @ts-ignore Field `added_columns` does not exist on TableInputBlock but is only necessary here for diffs.
    content.added_columns = addedColumnsFound;

    return {
      removedColumnsFound,
      addedColumnsFound,
    };
  },

  _setAddedAndRemovedRowsForTable: (
    content: TableInputBlockDiffElement
  ): { removedRowsFound: Set<number>; addedRowsFound: Set<number> } => {
    const removedRowsFound = new Set(
      content.cells?.flatMap((row, index) =>
        diffUtil.isDiffPair(row) && row[0] === ARRAY_CHANGE_SYMBOLS.REMOVED
          ? [index]
          : []
      )
    );
    const addedRowsFound = new Set(
      content.cells?.flatMap((row, index) =>
        diffUtil.isDiffPair(row) && row[0] === ARRAY_CHANGE_SYMBOLS.ADDED
          ? [index]
          : []
      )
    );

    // @ts-ignore Field `removed_rows` does not exist on TableInputBlock but is only necessary here for diffs.
    content.removed_rows = removedRowsFound;
    // @ts-ignore Field `added_rows` does not exist on TableInputBlock but is only necessary here for diffs.
    content.added_rows = addedRowsFound;

    return {
      removedRowsFound,
      addedRowsFound,
    };
  },

  /**
   * Converts the row values of modified columns, taking the initial representation of `DiffPair`s (for example,
   * [['-', 'old_value'], ['+', 'new_value']]) and converting it to the `DiffField` format (for example,
   * [{__old: 'old_value', __new: 'new_value'}]).
   */
  _convertModifiedColumnValuesToDiffFields(
    columns: Array<TableColumnDiffElement>,
    rowCount: number,
    removedRows: Set<number>,
    addedRows: Set<number>
  ): Array<TableColumnDiffElement> {
    return columns.map((column) => {
      // If the column was modified but not every `column_values` entry is a `DiffPair`, then a field other than `column_values` was modified.
      const columnValuesLength = column['column_values'].length;
      const areColumnValuesDiffPairs = column['column_values'].every(
        (row_value) => diffUtil.isDiffPair(row_value)
      );
      if (
        column.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED &&
        areColumnValuesDiffPairs
      ) {
        column['column_values'] =
          diffUtil._calculateColumnValuesForModifiedColumn(
            column['column_values'],
            rowCount,
            removedRows,
            addedRows
          );
      } else if (
        (!column.diff_change_state ||
          column.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED ||
          column.diff_change_state === ARRAY_CHANGE_SYMBOLS.UNCHANGED) &&
        columnValuesLength < rowCount &&
        !areColumnValuesDiffPairs
      ) {
        /*
         * This case covers when a column's values were not modified, but rows were added and removed from the table.
         * This happens when the same number of rows are removed and added, and the column values didn't change for that
         * particular column.
         *
         * Most often this happens for input type rows, where the values are always empty, but it can occur for a column
         * with non-empty values as well. In this case, we need to fill in values for the removed rows, so that the
         * diff will be displayed correctly.
         *
         * A simple example would be a column where the old values are ['A', 'B', 'C', 'C'] and the new values are the
         * same: ['A', 'B', 'C', 'C'], but row index 2 was removed from the old version and row index 3 in the new version
         * was added.
         * The new table has 4 rows, but the diff will have 5 rows: 4 rows to represent the new table and 1 row to
         * represent the row that was added, so the diff values will be: ['A', 'B', 'C', 'C', 'C'], where the first 'C'
         * will be displayed as part of a removed row and the last 'C' will be displayed as part of an added row.
         */
        const adjustedColumnValues: Array<string> = cloneDeep(
          column['column_values']
        );
        const sortedRemovedRows = Array.from(removedRows).sort();
        sortedRemovedRows.forEach((row_index) => {
          /*
           * Use the adjacent column value to account for the case where rows with the same values were added and
           * removed, so the values appear unchanged.
           *
           * The removed row will always appear before its identical counterpart, except if the removed row is the last
           * row. To understand this more intuitively, think about how you'd find which value in column['column_values']
           * belongs to the removed row, knowing the removed/added rows in the diff and that column['column_values'] is
           * identical to the old version.
           */
          adjustedColumnValues.splice(
            row_index,
            0,
            row_index + 1 < column['column_values'].length
              ? column['column_values'][row_index + 1]
              : column['column_values'][columnValuesLength - 1]
          );
        });
        return { ...column, column_values: adjustedColumnValues };
      }

      column['column_values'] = column['column_values'].map((value) => {
        try {
          const parsed = JSON.parse(value);
          return Array.isArray(parsed) || typeof parsed === 'object'
            ? parsed
            : value;
        } catch (e) {
          return value;
        }
      });

      return column;
    });
  },

  _expandFlattenSignoffs: (
    signoffs: Array<SignoffDiff>
  ): Array<SignoffDiffElement> => {
    let updatedSignoffs = diffUtil.objectDiffArrayToObjectArray(
      signoffs
    ) as Array<SignoffDiffElement>;

    updatedSignoffs = updatedSignoffs.flatMap((signoff) => {
      if (signoff.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED) {
        // TODO PLU-815: Show removed generic signoffs
        return diffUtil._splitIfIdsOrInputTypesModifiedIntoAddedAndRemoved(
          signoff
        );
      }
      return signoff;
    }) as Array<SignoffDiffElement>;
    updatedSignoffs.map((signoff) => {
      if (
        signoff.operators.length > 0 &&
        signoff.operators.every(
          (operator) =>
            diffUtil.isDiffPair(operator) &&
            operator[0] === ARRAY_CHANGE_SYMBOLS.ADDED
        )
      ) {
        signoff.diff_change_state = ARRAY_CHANGE_SYMBOLS.ADDED;
      } else if (
        signoff.operators.length > 0 &&
        signoff.operators.every(
          (operator) =>
            diffUtil.isDiffPair(operator) &&
            operator[0] === ARRAY_CHANGE_SYMBOLS.REMOVED
        )
      ) {
        signoff.diff_change_state = ARRAY_CHANGE_SYMBOLS.REMOVED;
      }
      signoff.operators = signoff.operators.map((operator) => {
        if (diffUtil.isDiffPair(operator)) {
          /*
           * Operators won't be modified because 1) operator names must be changed in settings, not in the procedure
           * edit page 2) json-diff only uses added/removed/unchanged for string arrays (if an item is modified, it
           * will show up as 2 elements: 1 added, 1 removed).
           */
          if (operator[0] === ARRAY_CHANGE_SYMBOLS.ADDED) {
            return signoff.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED
              ? operator[1]
              : { __old: '', __new: operator[1] };
          } else if (operator[0] === ARRAY_CHANGE_SYMBOLS.REMOVED) {
            return signoff.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED
              ? operator[1]
              : { __old: operator[1], __new: '' };
          } else if (operator[0] === ARRAY_CHANGE_SYMBOLS.UNCHANGED) {
            return operator[1];
          }
        }
        return operator;
      });
      return signoff;
    });

    return updatedSignoffs as Array<SignoffDiffElement>;
  },

  /**
   * This method uses the json-diff information to find the diffed row values for a modified column.
   *
   * In json-diff, string array values that are modified do not show up as modified but rather they show up as two
   * entries: one added diff pair (["+", "added value"]) and one removed diff pair (["-", "removed value"]).
   *
   * If a row was added, the column value for that row will show up as added: ["+", "added column value for added row"].
   * If a row was removed, the column value for that row will show up as removed: ["-", "column value for removed row"].
   * If the column value for the row was not modified, it will up as unchanged: [" ", "column value"].
   * String array values do not ever show up as modified.
   *
   * In addition, json-diff may stack added entries together or stack removed entries together, so we must recreate
   * which fields are modified and which are newly added/removed.
   *
   * First, we separate the existing `columnValues` into their old and new sets. The old set of row values will be all row
   * values that are removed or unchanged (we include modified for completeness), and the new column values will be all
   * row values that are added or unchanged (we include modified for completeness). Then we combine them to create a
   * single array of row values represented as `DiffField`: { __old: "old value", __new: "new value" }.
   *
   * For example, the input [['+', 'edit_row_value'], ['+', 'edit_row_value_2'], ['+', 'new_row_value'],
   * ['-', 'old_row_value'], ['-', 'old_row_value_2'], [' ', 'unchanged_row_value']] becomes the following:
   * [{__old: 'old_row_value', __new: 'edit_row_value'}, {__old: 'old_row_value_2, __new: 'edit_row_value_2'},
   * {__old: '', __new: 'new_row_value'}, {__old: 'unchanged_row_value', __new: 'unchanged_row_value'}].
   */
  _calculateColumnValuesForModifiedColumn: (
    columnValues: Array<DiffArrayChangeSymbol | string | Array<string>>,
    rowCount: number,
    removedRows: Set<number>,
    addedRows: Set<number>
  ): Array<DiffField<string | null> | Array<SignoffDiffElement>> => {
    const oldColumnValues = columnValues
      .filter(
        (value) =>
          diffUtil.isDiffPair(value) &&
          (
            [
              ARRAY_CHANGE_SYMBOLS.REMOVED,
              ARRAY_CHANGE_SYMBOLS.UNCHANGED,
              ARRAY_CHANGE_SYMBOLS.MODIFIED,
            ] as Array<string>
          ).includes(value[0] as DiffArrayChangeSymbol)
      )
      .map((value) => value[1]);
    const newColumnValues = columnValues
      .filter(
        (value) =>
          diffUtil.isDiffPair(value) &&
          (
            [
              ARRAY_CHANGE_SYMBOLS.ADDED,
              ARRAY_CHANGE_SYMBOLS.UNCHANGED,
              ARRAY_CHANGE_SYMBOLS.MODIFIED,
            ] as Array<string>
          ).includes(value[0] as DiffArrayChangeSymbol)
      )
      .map((value) => value[1]);

    const combinedColumnValues: Array<
      DiffField<string | null> | Array<SignoffDiffElement>
    > = [];
    while (combinedColumnValues.length < rowCount) {
      const currentRowIndex = combinedColumnValues.length;
      if (removedRows.has(currentRowIndex)) {
        const oldValue = !isEmpty(oldColumnValues)
          ? oldColumnValues.shift() ?? ''
          : '';

        try {
          const oldParsedValueRaw = JSON.parse(oldValue);
          const oldParsedValue =
            typeof oldParsedValueRaw === 'number'
              ? `${oldParsedValueRaw}`
              : oldParsedValueRaw;
          if (!tableUtil.isSignoffCell(oldParsedValue)) {
            combinedColumnValues.push({
              __old: oldParsedValue,
              __new: '',
            } as DiffField<string>);
            continue;
          }
          const signoffDiffArray = diff(oldParsedValue || [], [], {
            full: true,
            excludeKeys: ['id'],
          });
          const signoffDiffElementArray =
            diffUtil._expandFlattenSignoffs(signoffDiffArray);
          combinedColumnValues.push(signoffDiffElementArray);
        } catch (e) {
          combinedColumnValues.push({
            __old: oldValue,
            __new: '',
          } as DiffField<string>);
        }
      } else if (addedRows.has(currentRowIndex)) {
        const newValue = !isEmpty(newColumnValues)
          ? newColumnValues.shift() ?? ''
          : '';

        try {
          const newParsedValueRaw = JSON.parse(newValue);
          const newParsedValue =
            typeof newParsedValueRaw === 'number'
              ? `${newParsedValueRaw}`
              : newParsedValueRaw;
          if (!tableUtil.isSignoffCell(newParsedValue)) {
            combinedColumnValues.push({
              __old: '',
              __new: newParsedValue,
            } as DiffField<string>);
            continue;
          }

          const signoffDiffArray = diff([], newParsedValue, {
            full: true,
            excludeKeys: ['id'],
          });

          const signoffDiffElementArray =
            diffUtil._expandFlattenSignoffs(signoffDiffArray);
          combinedColumnValues.push(signoffDiffElementArray);
        } catch (e) {
          combinedColumnValues.push({
            __old: '',
            __new: newValue,
          } as DiffField<string>);
        }
      } else {
        const oldValue = !isEmpty(oldColumnValues)
          ? oldColumnValues.shift() ?? ''
          : '';
        const newValue = !isEmpty(newColumnValues)
          ? newColumnValues.shift() ?? ''
          : '';

        let oldParsedValue: TableCell | undefined = undefined;
        let newParsedValue: TableCell | undefined = undefined;
        try {
          const oldParsedValueRaw = JSON.parse(oldValue);
          oldParsedValue =
            typeof oldParsedValueRaw === 'number'
              ? `${oldParsedValueRaw}`
              : oldParsedValueRaw;
        } catch (e) {
          // oldValue was not parseable
        }

        try {
          const newParsedValueRaw = JSON.parse(newValue);
          newParsedValue =
            typeof newParsedValueRaw === 'number'
              ? `${newParsedValueRaw}`
              : newParsedValueRaw;
        } catch (e) {
          // newValue was not parseable
        }

        if (
          tableUtil.isSignoffCell(oldParsedValue) ||
          tableUtil.isSignoffCell(newParsedValue)
        ) {
          const oldSignoffsScrubbed = tableUtil.isSignoffCell(oldParsedValue)
            ? oldParsedValue
            : [];
          const newSignoffsScrubbed = tableUtil.isSignoffCell(newParsedValue)
            ? newParsedValue
            : [];
          const signoffDiffArray = diff(
            oldSignoffsScrubbed,
            newSignoffsScrubbed,
            {
              full: true,
              excludeKeys: ['id'],
            }
          );

          const signoffDiffElementArray =
            diffUtil._expandFlattenSignoffs(signoffDiffArray);
          combinedColumnValues.push(signoffDiffElementArray);
        } else {
          combinedColumnValues.push({
            __old: oldParsedValue !== undefined ? oldParsedValue : oldValue,
            __new: newParsedValue !== undefined ? newParsedValue : newValue,
          } as DiffField<string>);
        }
      }
    }
    return combinedColumnValues;
  },

  /**
   * Adjust the column values for columns that are added and removed to account for added and removed rows.
   *
   * For added columns, add an empty value for removed rows. For removed columns, add an empty value for added rows.
   */

  _adjustAddedAndRemovedColumnsForAddedAndRemovedRows: (
    columns: Array<TableColumnDiffElement>,
    rowCount: number,
    removedRows: Set<number>,
    addedRows: Set<number>
  ): Array<TableColumnDiffElement> => {
    return columns.map((column: TableColumnDiffElement) => {
      if (
        column.diff_change_state !== ARRAY_CHANGE_SYMBOLS.REMOVED &&
        column.diff_change_state !== ARRAY_CHANGE_SYMBOLS.ADDED
      ) {
        return column;
      }
      const columnValues = cloneDeep(column['column_values']);
      let rowIndex = 0;
      const adjustedColumnValues: Array<string> = [];
      while (!isEmpty(columnValues) && rowIndex < rowCount) {
        if (
          column.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED &&
          addedRows.has(rowIndex)
        ) {
          adjustedColumnValues.push('');
        } else if (
          column.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED &&
          removedRows.has(rowIndex)
        ) {
          adjustedColumnValues.push('');
        } else {
          adjustedColumnValues.push(columnValues.shift());
        }
        rowIndex += 1;
      }
      // Accounts for rows that were added at the bottom of the table.
      for (let i = rowIndex; i < rowCount; i++) {
        adjustedColumnValues.push('');
      }
      return { ...column, column_values: adjustedColumnValues };
    });
  },

  /**
   * Take the `column_values` for every column (where table values are indexed first by column, then by row), and reorder
   * them into a `cell` matrix that the table input component is expecting (where table values are indexed first by row,
   * then by column).
   */
  _createCellMatrixForDiff: (
    columns: Array<TableColumnDiffElement>
  ): Array<Array<DiffField<string | null>>> => {
    const allColumnValues: Array<Array<DiffField<string | null>>> = [];
    columns.forEach((column) => {
      allColumnValues.push(column['column_values']);
      // Clean up the temporary field `column_values`.
      delete column['column_values'];
    });
    // Transpose the array to convert from a 2D column x row array to a 2D row x column array;
    return zip.apply(_, allColumnValues);
  },

  /**
   * Not all values were being properly cleared when switching between column types and input types. This adjusts the
   * diff so that it displays correctly for these columns/input types. The logic to clear these fields has been added to
   * the table editing component, so once the front end without that logic is no longer supported we can remove this method.
   */
  _clearFieldsForInputAndColumnTypeChanges: (
    content: TableInputBlockDiffElement
  ): TableInputBlockDiffElement => {
    content.columns.forEach((column, columnIndex) => {
      if (
        sharedDiffUtil.getDiffValue(column, 'column_type', 'old') === 'input' &&
        sharedDiffUtil.getDiffValue(column, 'column_type', 'new') !== 'input'
      ) {
        column.units = {
          __old: sharedDiffUtil.getDiffValue(column, 'units', 'old'),
          __new: '',
        };
        content.cells = content.cells?.map((row) => {
          if (tableUtil.isSignoffCell(row[columnIndex])) {
            return row;
          }
          row[columnIndex] = {
            __old: '',
            __new: sharedDiffUtil.getDiffValue(
              { cell: row[columnIndex] },
              'cell',
              'new'
            ),
          };
          return row;
        });
      } else if (
        sharedDiffUtil.getDiffValue(column, 'column_type', 'old') !== 'input' &&
        sharedDiffUtil.getDiffValue(column, 'column_type', 'new') === 'input'
      ) {
        column.units = {
          __old: '',
          __new: sharedDiffUtil.getDiffValue(column, 'units', 'new'),
        };
      }

      if (sharedDiffUtil.isChanged(column, 'input_type')) {
        if (
          sharedDiffUtil.getDiffValue(column, 'input_type', 'new') ===
          'checkbox'
        ) {
          column.units = {
            __old: sharedDiffUtil.getDiffValue(column, 'units', 'old'),
            __new: '',
          };
        } else if (
          sharedDiffUtil.getDiffValue(column, 'input_type', 'old') ===
          'checkbox'
        ) {
          column.units = {
            __old: '',
            __new: sharedDiffUtil.getDiffValue(column, 'units', 'new'),
          };
        }
      }
    });
    return content;
  },

  _expandFlattenInputTable: (
    content: TableInputBlockDiffElement
  ): TableInputBlockDiffElement => {
    content.columns = content.columns.map((column) =>
      diffUtil.isDiffPair(column)
        ? { ...column[1], diff_change_state: column[0] }
        : column
    );

    // Split the columns that are marked as modified but that have separate IDs.
    content.columns = content.columns.flatMap((column) => {
      if (column.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED) {
        return diffUtil._splitIfIdsOrInputTypesModifiedIntoAddedAndRemoved(
          column
        );
      }
      return column;
    }) as Array<TableColumnDiffElement>;

    // Get and set the added/removed indices for rows and columns.
    let removedRows = new Set<number>();
    let addedRows = new Set<number>();
    if (
      content.diff_change_state !== ARRAY_CHANGE_SYMBOLS.REMOVED &&
      content.diff_change_state !== ARRAY_CHANGE_SYMBOLS.ADDED
    ) {
      diffUtil._setAddedAndRemovedColumnsForTable(content);
      ({ removedRowsFound: removedRows, addedRowsFound: addedRows } =
        diffUtil._setAddedAndRemovedRowsForTable(content));
    }
    content.rows = content.cells ? content.cells.length : 0;
    content.columns = diffUtil._convertModifiedColumnValuesToDiffFields(
      content.columns,
      content.rows,
      removedRows,
      addedRows
    );
    content.columns =
      diffUtil._adjustAddedAndRemovedColumnsForAddedAndRemovedRows(
        content.columns,
        content.rows,
        removedRows,
        addedRows
      );
    content.cells = diffUtil._createCellMatrixForDiff(content.columns);
    content = diffUtil._clearFieldsForInputAndColumnTypeChanges(content);

    return content;
  },

  _expandFlattenExpression: (
    content: ExpressionBlockDiffElement
  ): ExpressionBlockDiffElement => {
    content.tokens = diffUtil.objectDiffArrayToObjectArray(
      content.tokens
    ) as Array<ExpressionTokenDiffElement>;
    return content;
  },

  _expandFlattenedStepFields: (
    steps: Array<StepDiffElement>
  ): Array<StepDiffElement> => {
    return steps.map((step) => {
      if (step.content) {
        step.content = diffUtil.objectDiffArrayToObjectArray(
          step.content as Array<StepBlockDiff>
        ) as Array<StepBlockDiffElement>;
        step.content = step.content.flatMap((content) => {
          if (content.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED) {
            return diffUtil._splitIfIdsOrInputTypesModifiedIntoAddedAndRemoved(
              content
            );
          }
          return content;
        }) as Array<StepBlockDiffElement>;
        step.content.map((content, index) => {
          if (
            content.type === 'input' &&
            (content.inputType === 'multiple_choice' ||
              content.inputType === 'select')
          ) {
            /**
             * Need handle when a string array becomes an array of arrays, where each entry in the top level array has a
             * length of two with the first entry is the diff_change_state of added, removed, or unchanged and the
             * second entry is the string.
             */
            content.options = diffUtil.objectDiffArrayToDiffFieldStringArray(
              content.options
            );
          } else if (
            (content.type as BlockType) === 'part_kit' ||
            (content.type as BlockType) === 'part_build' ||
            (content.type as BlockType) === 'test_cases'
          ) {
            content = diffUtil._processContentItemsArray(content);
          } else if ((content.type as BlockType) === 'part_usage') {
            // @ts-ignore TODO(EPS-4464): Part usage doesn't currently have a type in views/procedures.
            content.usage_types =
              diffUtil.objectDiffArrayToDiffFieldStringArray(
                // @ts-ignore TODO(EPS-4464): Part usage doesn't currently have a type in views/procedures.
                content.usage_types
              );
          } else if (content.type === 'commanding' && content.arguments) {
            const updatedArguments = {};
            const commandingArguments = diffUtil.getFieldValue(
              content,
              'arguments'
            );
            if (typeof commandingArguments === 'object') {
              for (const name in commandingArguments) {
                if (name.includes('__added')) {
                  updatedArguments[name.replace('__added', '')] =
                    content.arguments[name];
                } else if (!name.includes('__deleted')) {
                  updatedArguments[name] = content.arguments[name];
                }
              }
            }
            content.arguments = updatedArguments;
          } else if (content.type === 'table_input') {
            step.content[index] = diffUtil._expandFlattenInputTable(content);
          } else if (content.type === 'expression') {
            step.content[index] = diffUtil._expandFlattenExpression(content);
          }
          return content;
        });
      }
      if (step.signoffs) {
        step.signoffs = diffUtil._expandFlattenSignoffs(
          step.signoffs as Array<SignoffDiff>
        );
      }

      if (step.dependencies) {
        step.dependencies = diffUtil.objectDiffArrayToObjectArray(
          step.dependencies as Array<DependencyDiff>
        ) as Array<DependencyDiffElement>;
        step.dependencies = step.dependencies.flatMap(
          (dependency) =>
            diffUtil._splitIfIdsOrInputTypesModifiedIntoAddedAndRemoved(
              dependency
            ) as Array<DependencyDiffElement>
        );
        step.dependencies = step.dependencies.map((dependency) => {
          dependency.dependent_ids =
            diffUtil.objectDiffArrayToDiffFieldStringArray(
              dependency.dependent_ids
            );
          return dependency;
        });
      }
      diffUtil._consolidateAddedAndDeleted(step, 'dependencies');
      if (step.conditionals) {
        step.conditionals = diffUtil.objectDiffArrayToObjectArray(
          step.conditionals as Array<ConditionalDiff>
        ) as Array<ConditionalDiffElement>;
        step.conditionals = step.conditionals.flatMap(
          (conditional) =>
            diffUtil._splitIfIdsOrInputTypesModifiedIntoAddedAndRemoved(
              conditional
            ) as Array<ConditionalDiffElement>
        );
        step.conditionals.forEach((conditional) => {
          conditional.diff_change_state =
            step.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED
              ? ARRAY_CHANGE_SYMBOLS.ADDED
              : conditional.diff_change_state;
        });
      }
      diffUtil._consolidateAddedAndDeleted(step, 'conditionals');
      return step;
    });
  },

  _expandFlattenedHeaderFields: (
    headers: Array<SectionHeaderDiffElement> | Array<HeaderBlockDiffElement>
  ): Array<SectionHeaderDiffElement> | Array<HeaderBlockDiffElement> => {
    return headers.map((header) => {
      if (header.content) {
        header.content = diffUtil.objectDiffArrayToObjectArray(
          header.content as Array<HeaderBlockDiff>
        ) as Array<HeaderBlockDiffElement>;
        header.content = header.content.flatMap((content) => {
          if (content.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED) {
            return diffUtil._splitIfIdsOrInputTypesModifiedIntoAddedAndRemoved(
              content
            );
          }
          return content;
        }) as Array<HeaderBlockDiffElement>;

        header.content.map((content, index) => {
          if (content.type === 'table_input') {
            header.content[index] = diffUtil._expandFlattenInputTable(content);
          }
          return content;
        });
      }
      return header;
    });
  },

  _expandFlattenedStepHeaderFields: (
    headers: Array<StepHeaderDiffElement>
  ): Array<StepHeaderDiffElement> => {
    return headers.map((header) => {
      if (header.content) {
        header.content = diffUtil.objectDiffArrayToObjectArray(
          header.content as Array<StepHeaderBlockDiff>
        ) as Array<StepHeaderBlockDiffElement>;
        header.content = header.content.flatMap((content) => {
          if (content.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED) {
            return diffUtil._splitIfIdsOrInputTypesModifiedIntoAddedAndRemoved(
              content
            );
          }
          return content;
        }) as Array<StepHeaderBlockDiffElement>;
      }
      return header;
    });
  },

  /**
   * Flatten the json-diff change arrays back to arrays as they are structured in procedures, but include change information.
   * Handles two distinct cases:
   *   1. Changes were made to an existing array.
   *   2. A new property was added/removed (such as `headers`) whose value is an array of objects.
   */
  _flattenDiffArrays: (procedureDiff: ProcedureDiff): ProcedureDiff => {
    const procedureDiffFlattened = cloneDeep(procedureDiff);

    // Procedure Tags
    if (procedureDiffFlattened.tags) {
      procedureDiffFlattened.tags = diffUtil.objectDiffArrayToObjectArray(
        procedureDiffFlattened.tags as Array<TagDiff>
      ) as Array<TagDiffElement>;
    }
    diffUtil._consolidateAddedAndDeleted(procedureDiffFlattened, 'tags');

    // Procedure Variables
    if (procedureDiffFlattened.variables) {
      procedureDiffFlattened.variables = diffUtil.objectDiffArrayToObjectArray(
        procedureDiffFlattened.variables as Array<VariableDiff>
      ) as Array<VariableDiffElement>;
    }
    diffUtil._consolidateAddedAndDeleted(procedureDiffFlattened, 'variables');

    // Part list
    const partListFieldName =
      'part_list' in procedureDiffFlattened
        ? 'part_list'
        : sharedDiffUtil.wasFieldAdded(procedureDiffFlattened, 'part_list')
        ? 'part_list__added'
        : sharedDiffUtil.wasFieldDeleted(procedureDiffFlattened, 'part_list')
        ? 'part_list__deleted'
        : '';
    if (
      !isEmpty(partListFieldName) &&
      procedureDiffFlattened[partListFieldName]
    ) {
      diffUtil._processContentItemsArray(
        procedureDiffFlattened[partListFieldName]
      );
    }
    diffUtil._consolidateAddedAndDeleted(procedureDiffFlattened, 'part_list');
    /*
     * Since part list is not an array element, it will not have an overall diff_change_state when modified so we need
     * to figure out if it has been modified and add the diff_change_state ourselves.
     */
    if (
      procedureDiffFlattened.part_list &&
      // @ts-ignore TODO(EPS-4464): Types for builds are not in procedure views yet.
      !procedureDiffFlattened.part_list.diff_change_state &&
      (sharedDiffUtil.isChanged(procedureDiffFlattened.part_list, 'part_id') ||
        sharedDiffUtil.isChanged(procedureDiffFlattened.part_list, 'id') ||
        procedureDiffFlattened.part_list.items?.some(
          (item) =>
            // @ts-ignore TODO(EPS-4464): Types for builds are not in procedure views yet.
            item.diff_change_state &&
            // @ts-ignore TODO(EPS-4464): Types for builds are not in procedure views yet.
            item.diff_change_state !== ARRAY_CHANGE_SYMBOLS.UNCHANGED
        ))
    ) {
      // @ts-ignore Part list does not have its own type in views/procedures yet.
      procedureDiffFlattened.part_list.diff_change_state =
        ARRAY_CHANGE_SYMBOLS.MODIFIED;
    }

    // Procedure Headers
    if (procedureDiffFlattened.headers) {
      procedureDiffFlattened.headers = diffUtil.objectDiffArrayToObjectArray(
        procedureDiffFlattened.headers as Array<HeaderDiff>
      ) as Array<HeaderDiffElement>;
      procedureDiffFlattened.headers = diffUtil._expandFlattenedHeaderFields(
        procedureDiffFlattened.headers
      ) as Array<HeaderDiffElement>;
    }
    diffUtil._consolidateAddedAndDeleted(procedureDiffFlattened, 'headers');

    // Sections
    procedureDiffFlattened.sections = diffUtil.objectDiffArrayToObjectArray(
      procedureDiffFlattened.sections as Array<SectionDiff>
    ) as Array<SectionDiffElement>;
    procedureDiffFlattened.sections.forEach((section) => {
      // Section Headers
      if (section.headers) {
        section.headers = diffUtil.objectDiffArrayToObjectArray(
          section.headers as Array<SectionHeaderDiff>
        ) as Array<SectionHeaderDiffElement>;
        section.headers = diffUtil._expandFlattenedHeaderFields(
          section.headers
        ) as Array<SectionHeaderDiffElement>;
      }
      diffUtil._consolidateAddedAndDeleted(section, 'headers');

      if (section.dependencies) {
        section.dependencies = diffUtil.objectDiffArrayToObjectArray(
          section.dependencies as Array<DependencyDiff>
        ) as Array<DependencyDiffElement>;
        section.dependencies = section.dependencies.flatMap(
          (dependency) =>
            diffUtil._splitIfIdsOrInputTypesModifiedIntoAddedAndRemoved(
              dependency
            ) as Array<DependencyDiffElement>
        );
        section.dependencies = section.dependencies.map((dependency) => {
          dependency.dependent_ids =
            diffUtil.objectDiffArrayToDiffFieldStringArray(
              dependency.dependent_ids
            );
          return dependency;
        });
      }
      diffUtil._consolidateAddedAndDeleted(section, 'dependencies');

      // Steps
      section.steps = diffUtil.objectDiffArrayToObjectArray(
        section.steps as Array<StepDiff>
      ) as Array<StepDiffElement>;
      section.steps.forEach((step) => {
        if (step.headers) {
          step.headers = diffUtil.objectDiffArrayToObjectArray(
            step.headers as Array<StepHeaderDiff>
          ) as Array<StepHeaderDiffElement>;
          step.headers = diffUtil._expandFlattenedStepHeaderFields(
            step.headers
          ) as Array<StepHeaderDiffElement>;
        }
        diffUtil._consolidateAddedAndDeleted(step, 'headers');
      });
      section.steps = diffUtil._expandFlattenedStepFields(section.steps);
    });

    // Settings
    diffUtil._consolidateAddedAndDeleted(procedureDiffFlattened, 'settings');

    // End Run Signoff Groups
    if (procedureDiffFlattened.end_run_signoffs_groups) {
      procedureDiffFlattened.end_run_signoffs_groups =
        diffUtil.objectDiffArrayToObjectArray(
          procedureDiffFlattened.end_run_signoffs_groups as Array<EndRunSignoffsGroupsDiff>
        ) as Array<EndRunSignoffsGroupsDiffElement>;
    }
    diffUtil._consolidateAddedAndDeleted(
      procedureDiffFlattened,
      'end_run_signoffs_groups'
    );

    return procedureDiffFlattened;
  },

  /**
   * Mutates a settings object to have either all old or all new data.
   */
  collapseSettingsToVersion: (
    settings: SettingsDiff | undefined,
    version: 'old' | 'new'
  ): void => {
    if (!settings?.print_settings) {
      return;
    }
    if (
      (version === 'old' &&
        settings.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED) ||
      (version === 'new' &&
        settings.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED)
    ) {
      settings.print_settings = undefined;
      return;
    }

    diffUtil._replaceAddedDeletedWithOriginal<string>(
      settings.print_settings,
      'header__added',
      version
    );
    diffUtil._replaceAddedDeletedWithOriginal<string>(
      settings.print_settings,
      'header__deleted',
      version
    );
    if (settings.print_settings.header) {
      Object.keys(settings.print_settings?.header).forEach((propertyRaw) => {
        if (!settings.print_settings?.header) {
          return;
        }
        diffUtil._replaceAddedDeletedWithOriginal<string>(
          settings.print_settings.header,
          propertyRaw,
          version
        );
      });
    }

    diffUtil._replaceAddedDeletedWithOriginal<string>(
      settings.print_settings,
      'footer__added',
      version
    );
    diffUtil._replaceAddedDeletedWithOriginal<string>(
      settings.print_settings,
      'footer__deleted',
      version
    );
    if (settings.print_settings.footer) {
      Object.keys(settings.print_settings?.footer).forEach((propertyRaw) => {
        if (!settings.print_settings?.footer) {
          return;
        }
        diffUtil._replaceAddedDeletedWithOriginal<string>(
          settings.print_settings.footer,
          propertyRaw,
          version
        );
      });
    }
  },

  _replaceAddedDeletedWithOriginal: <T extends ProcedurePrimitive>(
    parent: object,
    propertyRaw: string,
    version: 'old' | 'new'
  ): void => {
    const property = diffUtil._getOriginalProperty(propertyRaw);

    const value = sharedDiffUtil.getDiffValue<T>(parent, property, version);
    if (value !== undefined) {
      parent[property] = value;
    }
    if (property !== propertyRaw) {
      delete parent[propertyRaw];
    }
  },

  _getOriginalProperty: (propertyRaw: string): string => {
    const propertyMatch = propertyRaw.match(/^(.*?)__(?:added|deleted)$/);
    if (propertyMatch) {
      return propertyMatch[1];
    }

    return propertyRaw;
  },

  /**
   * If a property is added or removed, json-diff will append __added or __deleted, respectively, to that property.
   * For the case where the added value is an array or object, this function transforms (if necessary) those modified properties into properties
   * that the app can understand, and adds the relevant diff change state.
   */
  _consolidateAddedAndDeleted: (
    diffObject: ProcedureDiff | SectionDiffElement | StepDiffElement,
    field: string
  ): void => {
    if (!diffObject) {
      return;
    }
    // Consolidate an added property.
    const addedField = `${field}__added`;
    if (diffObject[addedField]) {
      if (typeof diffObject[addedField] !== 'object') {
        return;
      }

      // At this point the value is either an array or an object.
      if (Array.isArray(diffObject[addedField])) {
        diffObject[addedField].forEach((element) => {
          element.diff_change_state = ARRAY_CHANGE_SYMBOLS.ADDED;
        });
        diffObject[field] = diffObject[addedField];
      } else {
        // is an object
        diffObject[field] = diffObject[addedField];
        diffObject[field].diff_change_state = ARRAY_CHANGE_SYMBOLS.ADDED;
      }

      delete diffObject[addedField];
    }

    // Consolidate a deleted property.
    const deletedField = `${field}__deleted`;
    if (diffObject[deletedField]) {
      if (typeof diffObject[deletedField] !== 'object') {
        return;
      }

      // At this point the value is either an array or an object.
      if (Array.isArray(diffObject[deletedField])) {
        diffObject[deletedField].forEach((element) => {
          element.diff_change_state = ARRAY_CHANGE_SYMBOLS.REMOVED;
        });
        diffObject[field] = diffObject[deletedField];
      } else {
        // is an object
        diffObject[field] = diffObject[deletedField];
        diffObject[field].diff_change_state = ARRAY_CHANGE_SYMBOLS.REMOVED;
      }

      delete diffObject[deletedField];
    }
  },

  _cleanProcedureDiff(
    procedureDiff: ProcedureDiff,
    updated: DiffableProcedure
  ): ProcedureDiff {
    const procedureDiffCleaned = cloneDeep(procedureDiff);

    diffUtil._overwriteValues(procedureDiffCleaned, updated);
    diffUtil._removeUnneededValues(procedureDiffCleaned);
    diffUtil._updateRemovedItemIds(procedureDiffCleaned);

    procedureDiffCleaned.sections.forEach((section) => {
      section.id = sharedDiffUtil.getDiffValue<string>(section, 'id', 'new');
      section.steps.forEach(
        (step) =>
          (step.id = sharedDiffUtil.getDiffValue<string>(step, 'id', 'new'))
      );
    });
    return procedureDiffCleaned;
  },

  _replaceProcedureVariablesWithNewValues: (
    procedureDiffCleaned: ProcedureDiff,
    updated: DiffableProcedure
  ): VariableDiff[] | VariableDiffElement[] | undefined => {
    // If a procedure variable changed, replace the variable with original values.
    procedureDiffCleaned.variables?.forEach((variable, index) => {
      if (!procedureDiffCleaned.variables) {
        return;
      }
      procedureDiffCleaned.variables[index] = diffUtil._replaceDiffWithOriginal(
        variable,
        (id: string) => procedureVariableUtil.getVariable(updated, id)
      );
    });
    return procedureDiffCleaned.variables;
  },

  /**
   * Replace some diff values with values from the updated procedure to allow for incremental progress on procedure diffing.
   */
  _replaceWithNewValues: (
    procedureDiff: ProcedureDiff,
    updated: DiffableProcedure
  ): ProcedureDiff => {
    const procedureDiffCleaned = diffUtil._cleanProcedureDiff(
      procedureDiff,
      updated
    );

    if (procedureDiffCleaned.variables) {
      procedureDiffCleaned.variables =
        diffUtil._replaceProcedureVariablesWithNewValues(
          procedureDiffCleaned,
          updated
        );
    }

    // If a header changed, replace the header with original values.
    procedureDiffCleaned.headers?.forEach((header, index) => {
      if (!procedureDiffCleaned.headers) {
        return;
      }
      procedureDiffCleaned.headers[index] = diffUtil._replaceDiffWithOriginal(
        header,
        (id: string) => procedureUtil.getHeaderById(updated, id)
      );
    });

    // If a section header or step changed, replace it with original values.
    procedureDiffCleaned.sections.forEach((section, sectionIndex) => {
      section.headers?.forEach((header, index) => {
        section.headers[index] = diffUtil._replaceDiffWithOriginal(
          header,
          (id: string) => procedureUtil.getSectionHeaderById(updated, id)
        );
      });
      section.steps.forEach((step, stepIndex) => {
        (
          procedureDiffCleaned.sections[sectionIndex] as SectionDiffElement
        ).steps[stepIndex] = diffUtil._replaceDiffWithOriginal(
          step,
          (id: string) => procedureUtil.getStepById(updated, id)
        );
      });
    });

    return procedureDiffCleaned;
  },

  /**
   * Use some fields from the updated procedure only (i.e. ignore changes).
   * This overwriting is done, instead of selecting which fields should show diffs, so that the default is to show the diffed field.
   * If the UI is not updated to handle the diff, the display will likely break, which is a mechanism to ensure all new
   * fields and blocks show diffs.
   *
   */
  _overwriteValues: (
    procedureDiff: ProcedureDiff,
    updated: DiffableProcedure
  ): void => {
    const overriddenFields = ['state', 'editedUserId', 'editedAt', 'comments'];
    overriddenFields.forEach((field) => {
      if (updated[field]) {
        procedureDiff[field] = updated[field];
      }
    });
  },

  /**
   * This removal function does not generically handle __added and __deleted fields.
   * It just handles cases in which we know we do not care about the change, and instead will use the value from the review.
   */
  _removeUnneededValues: (procedureDiff: ProcedureDiff): void => {
    const unneededFields = [
      'release_note',
      'release_note__added',
      'release_note__deleted',
      'approvals',
      'approvals__added',
      'approvals__deleted',
      'reviewer_groups',
      'reviewer_groups__added',
      'reviewer_groups__deleted',
    ];
    unneededFields.forEach((field) => {
      delete procedureDiff[field];
    });
  },

  /**
   * Replaces a diffed container with its non-diffed version. This is done to allow incremental progress in which lower components
   * will not display diffs.
   */
  _replaceDiffWithOriginal: <T extends DiffElement>(
    diffElement: T,
    getDiffable: (id: string) => T
  ): T => {
    if (diffElement.diff_change_state !== ARRAY_CHANGE_SYMBOLS.MODIFIED) {
      return diffElement;
    }
    // Get original entity.
    const id = sharedDiffUtil.getDiffValue<string>(diffElement, 'id', 'new'); // Allow for the case in which the id was changed.
    const updatedDiffable = getDiffable(id);

    // Keep the content of the original entity, but also keep the diff change state.
    return {
      ...updatedDiffable,
      diff_change_state: diffElement.diff_change_state,
    } as T;
  },

  /**
   * If a container (section, step, header, etc.) was removed, update its id so there are not two containers with the same id in the diff.
   */
  _updateRemovedItemIds: (procedureDiff: ProcedureDiff): void => {
    diffUtil._updateRemovedContainerIds(
      procedureDiff.headers as Array<HeaderDiffElement>
    );
    diffUtil._updateRemovedContainerIds(
      procedureDiff.sections as Array<SectionDiffElement>
    );
    procedureDiff.sections.forEach((section) => {
      diffUtil._updateRemovedContainerIds(
        section.headers as Array<SectionHeaderDiffElement>
      );

      const sectionIsRemoved =
        section.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED;
      diffUtil._updateRemovedContainerIds(section.steps, sectionIsRemoved);
    });
  },

  _updateRemovedContainerIds: (
    containers:
      | Array<SectionDiffElement>
      | Array<StepDiffElement>
      | Array<HeaderDiffElement>
      | Array<SectionHeaderDiffElement>
      | undefined,
    parentIsRemoved = false
  ): void => {
    containers?.forEach((container) => {
      if (
        parentIsRemoved ||
        container.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED
      ) {
        container.id = `${container.id}__removed`;
      }
    });
  },

  /**
   * Since we are only retaining the new version of variables in the diff (for now), we need a way to display changes to
   * variables in the print settings header/footer. This workaround will go away when we have full diffs.
   *
   * @param procedureDiff
   * @param previous
   */
  _includePreviousVariables: (
    procedureDiff: ProcedureDiff,
    previous: DiffableProcedure
  ): void => {
    if (previous.variables) {
      procedureDiff.variables__previous = previous.variables;
    }
  },

  /*
   * Gets the value of the field. This is used instead of getDiffValue when we want the __old/__new structure to be
   * preserved, rather than just getting the primitive value of the field. Since the field name may have the
   * "__added"/"__deleted" suffixes, we can't just get the field directly.
   */
  getFieldValue: (
    parent: object,
    field: string
  ): DiffFieldChange<string | number> | string | WithDiffChange<unknown> => {
    if (sharedDiffUtil.wasFieldAdded(parent, field)) {
      return parent[`${field}__added`];
    }
    if (sharedDiffUtil.wasFieldDeleted(parent, field)) {
      return parent[`${field}__deleted`];
    }
    return parent[field];
  },

  getDiffChangeStateForFieldValue: (parent: object, field: string): string => {
    if (sharedDiffUtil.wasFieldAdded(parent, field)) {
      return ARRAY_CHANGE_SYMBOLS.ADDED;
    }
    if (sharedDiffUtil.wasFieldDeleted(parent, field)) {
      return ARRAY_CHANGE_SYMBOLS.REMOVED;
    }
    if (
      sharedDiffUtil.getDiffValue(parent, field, 'old') ===
      sharedDiffUtil.getDiffValue(parent, field, 'new')
    ) {
      return ARRAY_CHANGE_SYMBOLS.UNCHANGED;
    }
    return ARRAY_CHANGE_SYMBOLS.MODIFIED;
  },

  /**
   * Given a diff array of primitive values, this function reconstructs the old or new version of the array.
   */
  getVersionFromPrimitiveArrayDiff: (
    array:
      | Array<ProcedurePrimitive>
      | Array<Array<DiffArrayChangeSymbol | ProcedurePrimitive>>,
    version: 'old' | 'new'
  ): Array<ProcedurePrimitive> => {
    // Return the array if it was not diffed.
    if (array.length === 0 || !diffUtil.isDiffPair(array[0])) {
      return array as Array<ProcedurePrimitive>;
    }
    const ignoreType =
      version === 'old'
        ? ARRAY_CHANGE_SYMBOLS.ADDED
        : ARRAY_CHANGE_SYMBOLS.REMOVED;
    return (array as Array<Array<DiffArrayChangeSymbol | ProcedurePrimitive>>)
      .filter(
        (diffPair) =>
          diffUtil._getDiffChangeStateFromDiffPair(diffPair) !== ignoreType
      )
      .map((diffPair) => diffUtil._getValueFromDiffPair(diffPair));
  },

  _getDiffChangeStateFromDiffPair: <T>(
    diffPair: Array<DiffArrayChangeSymbol | T>
  ): T => diffPair[0] as T,
  _getValueFromDiffPair: <T>(diffPair: Array<DiffArrayChangeSymbol | T>): T =>
    diffPair[1] as T,

  /**
   * json-diff can return two forms for an array of objects:
   *  1. If at least one object in the array changed, json-diff will transform the array of objects into a 2D array of the form:
   *    [
   *      [DiffArrayChangeSymbol, originalObject0],
   *      [DiffArrayChangeSymbol, originalObject1],
   *      ...
   *    ]
   *  2. If no object in the array changed, the array of objects is unchanged.
   *
   * This function converts the 2D array form back into an array of objects, where those objects now have
   * an additional field that contains information about the change (addition, subtraction, modification, or no change).
   * No change can be present if at least one--but not all--of the objects was changed in the array.
   *
   * @param diffArray - an array resulting from running json-diff against an array
   */
  objectDiffArrayToObjectArray: <T extends Diffable | DiffableDiff>(
    diffArray: Array<T>
  ): Array<Diffable | DiffElement> => {
    return diffArray.map((objectOrDiff) => {
      if (diffUtil.isDiffPair(objectOrDiff)) {
        // This means at least one of the array elements has changed, and all elements are of the form [changeState, object]
        return diffUtil._convertDiffPairToObject(objectOrDiff as DiffableDiff);
      }
      return objectOrDiff as Diffable; // None of the elements changed, so just return the element, which will not be an array in this case.
    });
  },

  /**
   * Convert a diff array of strings to an array of `DiffField<string>` that components can understand/display.
   * For example, it takes the following array: [[" ", "entry1"], ["+", "entry2"], ["-", "entry3"]] and converts it
   * to this: ["entry1", {__old: "", __new: "entry2"}, {__old: "entry3", __new: ""}].
   *
   * If the array is simply an array of strings, such as ["entry2", "entry2"], it will simply return the array as is.
   * Currently only handles strings but could be updated to handle other primitive types if necessary.
   */
  objectDiffArrayToDiffFieldStringArray: (
    diffArray: Array<unknown>
  ): Array<DiffField<string>> => {
    return diffArray.map((stringOrDiff) => {
      if (diffUtil.isDiffPair(stringOrDiff)) {
        return diffUtil._convertDiffPairToDiffFieldString(
          stringOrDiff as DiffableDiff
        );
      }
      return stringOrDiff as DiffField<string>;
    });
  },

  /**
   * Is the argument of the form [changeState, changedEntity]
   */
  isDiffPair: (objectOrDiff: unknown): boolean =>
    Array.isArray(objectOrDiff) &&
    objectOrDiff.length === 2 &&
    Object.values(ARRAY_CHANGE_SYMBOLS).includes(
      objectOrDiff[0] as DiffArrayChangeSymbol
    ),

  /**
   * If `diffPair` parameter is a diff pair of length two, where the first entry is the diff change state of added or
   * removed and the second entry is the string value, then create a `DiffField` object with two fields __old and __new.
   * Otherwise, if the first entry is the diff change state of unchanged or if the `diff pair` is a string value, then
   * simply return the string value.
   */
  _convertDiffPairToDiffFieldString: (
    diffPair: DiffableDiff
  ): DiffField<string> => {
    const arrayChangeSymbol = diffPair[0] as DiffArrayChangeSymbol;
    const stringOrObjectValue = diffPair[1];
    if (
      typeof stringOrObjectValue !== 'string' &&
      typeof stringOrObjectValue !== 'object'
    ) {
      throw new Error(
        `Invalid type: expected string or DiffField string object, was ${typeof stringOrObjectValue}`
      );
    }
    if (
      typeof stringOrObjectValue === 'string' &&
      arrayChangeSymbol === ARRAY_CHANGE_SYMBOLS.REMOVED
    ) {
      return { __old: stringOrObjectValue, __new: '' };
    }
    if (
      typeof stringOrObjectValue === 'string' &&
      arrayChangeSymbol === ARRAY_CHANGE_SYMBOLS.ADDED
    ) {
      return { __old: '', __new: stringOrObjectValue };
    }
    return stringOrObjectValue as DiffField<string>;
  },

  /**
   * Convert a diff pair like [changeState, object] to an object that has an additional field that describes how the object changed.
   * @param diffPair [changeState, object]
   */
  _convertDiffPairToObject: (diffPair: DiffableDiff): DiffElement => {
    const arrayChangeSymbol = diffPair[0] as DiffArrayChangeSymbol;
    const object = diffPair[1] as SectionDiffElement | StepDiffElement;
    if (typeof object !== 'object') {
      throw new Error(`Invalid type: expected object, was ${typeof object}`);
    }

    object.diff_change_state = arrayChangeSymbol;
    return object;
  },

  getIndexForKey: (
    container: StepDiffElement | SectionDiffElement,
    oldContainerMap: Map<string, number>,
    newContainerMap: Map<string, number>
  ): number | undefined => {
    const diffChangeState = container.diff_change_state;
    const sectionId = sharedDiffUtil.getDiffValue<string>(
      container,
      'id',
      diffChangeState === ARRAY_CHANGE_SYMBOLS.REMOVED ? 'old' : 'new'
    );
    return container.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED
      ? oldContainerMap.get(sectionId)
      : newContainerMap.get(sectionId);
  },

  getPrintHeader: (
    procedure: ProcedureDiff | DiffableProcedure,
    version: 'old' | 'new'
  ): string => {
    const procedureCopy = cloneDeep(procedure);

    // Workaround to display old values of procedure variables
    if (
      version === 'old' &&
      (procedureCopy as ProcedureDiff).variables__previous
    ) {
      procedureCopy.variables = (
        procedureCopy as ProcedureDiff
      ).variables__previous;
    }
    // Since procedure variables are referenced in print header, remove potential duplicates caused by diffing.
    const ignoreType =
      version === 'old'
        ? ARRAY_CHANGE_SYMBOLS.ADDED
        : ARRAY_CHANGE_SYMBOLS.REMOVED;
    procedureCopy.variables = (
      procedureCopy.variables as Array<V2Variable | VariableDiffElement>
    )?.filter(
      (variable) =>
        (variable as VariableDiffElement).diff_change_state !== ignoreType
    );

    diffUtil.collapseSettingsToVersion(procedureCopy.settings, version);
    return printUtil.getPrintHeaderPreview(procedureCopy);
  },

  getPrintFooter: (
    procedure: ProcedureDiff | DiffableProcedure,
    version: 'old' | 'new'
  ): string => {
    const procedureCopy = cloneDeep(procedure);

    // Workaround to display old values of procedure variables
    if (
      version === 'old' &&
      (procedureCopy as ProcedureDiff).variables__previous
    ) {
      procedureCopy.variables = (
        procedureCopy as ProcedureDiff
      ).variables__previous;
    }
    // Since procedure variables are referenced in print footer, remove potential duplicates caused by diffing.
    const ignoreType =
      version === 'old'
        ? ARRAY_CHANGE_SYMBOLS.ADDED
        : ARRAY_CHANGE_SYMBOLS.REMOVED;
    procedureCopy.variables = (
      procedureCopy.variables as Array<V2Variable | VariableDiffElement>
    )?.filter(
      (variable) =>
        (variable as VariableDiffElement).diff_change_state !== ignoreType
    );

    diffUtil.collapseSettingsToVersion(procedureCopy.settings, version);
    return printUtil.getPrintFooterPreview(procedureCopy);
  },

  getTags: (
    tags: Array<Tag | RunTag | TagDiffElement> | undefined,
    version: 'old' | 'new'
  ): Array<Tag | RunTag | TagDiffElement> => {
    if (!tags) {
      return [];
    }

    const ignoreType =
      version === 'old'
        ? ARRAY_CHANGE_SYMBOLS.ADDED
        : ARRAY_CHANGE_SYMBOLS.REMOVED;

    return cloneDeep(tags)
      .filter((tag) => (tag as TagDiffElement).diff_change_state !== ignoreType)
      .map((tag) => {
        tag.key = sharedDiffUtil.getDiffValue(tag, 'key', version);
        tag.name = sharedDiffUtil.getDiffValue(tag, 'name', version);
        return tag;
      });
  },

  didTagsChange: (
    tags: Array<Tag | RunTag | TagDiffElement> | undefined
  ): boolean => {
    return (
      Boolean(tags) &&
      (tags as Array<TagDiffElement>).some(
        (tag) =>
          tag.diff_change_state &&
          tag.diff_change_state !== ARRAY_CHANGE_SYMBOLS.UNCHANGED
      )
    );
  },

  getEndRunSignoffOperators: (
    endRunSignoffsGroups: Array<EndRunSignoffsGroupsDiffElement> | undefined,
    version: 'old' | 'new'
  ): string => {
    const endRunSignoffsRequired =
      endRunSignoffsGroups &&
      endRunSignoffsGroups.length > 0 &&
      (endRunSignoffsGroups[0] as EndRunSignoffsGroupsDiffElement).operators &&
      (endRunSignoffsGroups[0] as EndRunSignoffsGroupsDiffElement).operators
        .length > 0;

    if (!endRunSignoffsRequired) {
      return '';
    }

    const ignoreType =
      version === 'old'
        ? ARRAY_CHANGE_SYMBOLS.ADDED
        : ARRAY_CHANGE_SYMBOLS.REMOVED;
    const operators = (
      endRunSignoffsGroups as Array<EndRunSignoffsGroupsDiffElement>
    )
      .filter((group) => group.diff_change_state !== ignoreType)
      .flatMap((group) =>
        diffUtil.getVersionFromPrimitiveArrayDiff(group.operators, version)
      );

    return operators.sort().join(', ');
  },

  /**
   * Gets a list of changes used for scrolling to diffed items. Order of insertion into the list determines order of scrolling.
   */
  getChangeEntries: (
    procedureDiff: ProcedureDiff | undefined
  ): Array<ChangeEntry> => {
    if (!procedureDiff) {
      return [];
    }

    const entries: Array<ChangeEntry> = [];

    if (sharedDiffUtil.isChanged(procedureDiff, 'code')) {
      entries.push({ title: 'title' });
    }
    if (sharedDiffUtil.isChanged(procedureDiff, 'name')) {
      entries.push({ title: 'title' });
    }

    const oldVersion = procedureUtil.getVersionLabel(
      sharedDiffUtil.getDiffValue(procedureDiff, 'version', 'old'),
      sharedDiffUtil.getDiffValue(procedureDiff, 'state', 'new')
    );
    const newVersion = procedureUtil.getVersionLabel(
      sharedDiffUtil.getDiffValue(procedureDiff, 'version', 'new'),
      sharedDiffUtil.getDiffValue(procedureDiff, 'state', 'new')
    );
    if (oldVersion !== newVersion) {
      entries.push({ version: 'version' });
    }

    [
      'automation_enabled',
      'is_strict_signoff_enabled',
      'skip_step_enabled',
      'repeat_step_enabled',
      'step_suggest_edits_enabled',
    ].forEach((setting) => {
      if (sharedDiffUtil.isChanged(procedureDiff, setting)) {
        entries.push({ [setting]: setting });
      }
    });

    if (sharedDiffUtil.isChanged(procedureDiff, 'owner')) {
      entries.push({ owner: 'owner' });
    }

    if (sharedDiffUtil.isChanged(procedureDiff, 'project_id')) {
      entries.push({ project: 'project' });
    }

    const defaultViewOld = sharedDiffUtil.getDiffValue(
      procedureDiff,
      'default_view_mode',
      'old'
    );
    const defaultViewNew = sharedDiffUtil.getDiffValue(
      procedureDiff,
      'default_view_mode',
      'new'
    );
    if (defaultViewOld !== defaultViewNew) {
      entries.push({ defaultView: 'defaultView' });
    }

    const endRunSignoffsGroupsOld = diffUtil.getEndRunSignoffOperators(
      procedureDiff.end_run_signoffs_groups as Array<EndRunSignoffsGroupsDiffElement>,
      'old'
    );
    const endRunSignoffsGroupsNew = diffUtil.getEndRunSignoffOperators(
      procedureDiff.end_run_signoffs_groups as Array<EndRunSignoffsGroupsDiffElement>,
      'new'
    );
    if (endRunSignoffsGroupsOld !== endRunSignoffsGroupsNew) {
      entries.push({ endRunSignoffOperators: 'endRunSignoffOperators' });
    }

    const oldPrintHeader = diffUtil.getPrintHeader(procedureDiff, 'old');
    const newPrintHeader = diffUtil.getPrintHeader(procedureDiff, 'new');
    if (oldPrintHeader !== newPrintHeader) {
      entries.push({ printHeader: 'printHeader' });
    }

    const oldPrintFooter = diffUtil.getPrintFooter(procedureDiff, 'old');
    const newPrintFooter = diffUtil.getPrintFooter(procedureDiff, 'new');
    if (oldPrintFooter !== newPrintFooter) {
      entries.push({ printFooter: 'printFooter' });
    }

    if (sharedDiffUtil.isChanged(procedureDiff, 'description')) {
      entries.push({ description: 'description' });
    }
    if (diffUtil.didTagsChange(procedureDiff.tags as Array<TagDiffElement>)) {
      entries.push({ tags: 'tags' });
    }

    procedureDiff.variables?.forEach((variable) => {
      if (diffUtil._isContainerChanged(variable)) {
        entries.push({ variableId: variable.id });
      }
    });

    if (
      procedureDiff.part_list &&
      // @ts-ignore TODO(EPS-4464): Types for builds are not in procedure views yet.
      diffUtil._isContainerChanged(procedureDiff.part_list)
    ) {
      entries.push({
        partListId: sharedDiffUtil.getDiffValue(
          procedureDiff.part_list,
          'id',
          'new'
        ),
      });
    }

    procedureDiff.headers?.forEach((header) => {
      if (diffUtil._isContainerChanged(header)) {
        entries.push({ headerId: header.id });
      }
    });

    procedureDiff.sections.forEach((section) => {
      if (
        section.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED ||
        section.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED ||
        sharedDiffUtil.isChanged(section, 'name')
      ) {
        entries.push({ sectionId: section.id });
      }
      section.headers?.forEach((sectionHeader) => {
        if (diffUtil._isContainerChanged(sectionHeader)) {
          entries.push({
            sectionId: section.id,
            sectionHeaderId: sectionHeader.id,
          });
        }
      });
      section.steps?.forEach((step) => {
        if (diffUtil._isContainerChanged(step)) {
          entries.push({
            sectionId: section.id,
            stepId: step.id,
          });
        }
      });
    });

    return entries;
  },

  _isContainerChanged: (container: DiffElement): boolean => {
    return (
      !isNil(container.diff_change_state) &&
      container.diff_change_state !== ARRAY_CHANGE_SYMBOLS.UNCHANGED
    );
  },

  getForVersion: <T extends WithDiffChangeI>(
    allObjects: Array<T>,
    version: 'old' | 'new'
  ): Array<T> => {
    const ignoreType =
      version === 'old'
        ? ARRAY_CHANGE_SYMBOLS.ADDED
        : ARRAY_CHANGE_SYMBOLS.REMOVED;

    return allObjects.filter(
      (object) => object.diff_change_state !== ignoreType
    );
  },

  getContainerSummary: (
    containerId: string,
    allContainers: Array<StepDiffElement | SectionDiffElement> | null,
    version: 'old' | 'new' = 'new'
  ): Summary | null => {
    if (!allContainers) {
      return null;
    }
    const matchingContainerIndex = allContainers?.findIndex(
      (container) =>
        sharedDiffUtil.getDiffValue(container, 'id', version) === containerId
    );
    if (matchingContainerIndex === -1) {
      return null;
    }

    const matchingContainer = allContainers[matchingContainerIndex];

    return {
      name: sharedDiffUtil.getDiffValue(matchingContainer, 'name', version),
      id: sharedDiffUtil.getDiffValue(matchingContainer, 'id', version),
      index: matchingContainerIndex,
    };
  },

  joinArrayDiff: (
    value: Array<string> | Array<Array<string>>
  ): string | DiffField<string> => {
    const oldValue = (value as Array<string>)
      .filter(
        (entry) =>
          diffUtil.isDiffPair(entry) && entry[0] !== ARRAY_CHANGE_SYMBOLS.ADDED
      )
      .map((entry) => entry[1]);
    const newValue = (value as Array<string>)
      .filter(
        (entry) =>
          diffUtil.isDiffPair(entry) &&
          entry[0] !== ARRAY_CHANGE_SYMBOLS.REMOVED
      )
      .map((entry) => entry[1]);

    return oldValue.length > 0 || newValue.length > 0
      ? { __old: oldValue.join(', '), __new: newValue.join(', ') }
      : value.join(', ');
  },

  isAttachmentDataChanged: (
    attachment: AttachmentBlockDiffElement,
    ignoreFields: Set<string>
  ): boolean => {
    if (attachment.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED) {
      return Object.keys(attachment)
        .filter((key) => !ignoreFields.has(diffUtil.trimFieldName(key)))
        .some((key) => sharedDiffUtil.isChanged(attachment, key));
    } else if (
      attachment.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED ||
      attachment.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED
    ) {
      return true;
    }
    return false;
  },

  getDiffChangeStateForChangesOnly(
    parent: object | undefined,
    field: string
  ): DiffArrayChangeSymbol {
    return sharedDiffUtil.isChanged(parent, field)
      ? ARRAY_CHANGE_SYMBOLS.MODIFIED
      : ARRAY_CHANGE_SYMBOLS.UNCHANGED;
  },

  getDiffChangeStateForAddedRemovedOnly(
    parent: WithDiffChange<object>
  ): DiffArrayChangeSymbol | undefined {
    return parent.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED
      ? ARRAY_CHANGE_SYMBOLS.UNCHANGED
      : parent.diff_change_state;
  },

  areDiffableFieldsChanged(
    oldDiff: ProcedureDiff | null,
    newDiff: ProcedureDiff | null
  ): boolean {
    if (!oldDiff && !newDiff) {
      return false;
    }
    if ((!oldDiff && newDiff) || (oldDiff && !newDiff)) {
      return true;
    }

    // At this point both diffs must be non-null.

    const ignoreFields = [
      'state',
      'editedUserId',
      'editedAt',
      'locked_at',
      'locked_by',
      'reviewer_groups',
    ];
    const newDraftFiltered = omit(newDiff, ignoreFields);
    const oldDraftFiltered = omit(oldDiff, ignoreFields);

    return !isEqual(newDraftFiltered, oldDraftFiltered);
  },

  _getBasicRuleForVersion: (
    rule: RuleDiffElement | undefined,
    version: 'old' | 'new'
  ): Rule | null => {
    if (!rule) {
      return null;
    }
    const op = sharedDiffUtil.getDiffValue<RuleOperator>(rule, 'op', version);
    if (op === 'range') {
      return null;
    }

    return {
      op,
      value: sharedDiffUtil.getDiffValue<string | number>(
        rule,
        'value',
        version
      ),
    };
  },

  _getRangeForVersion: (
    parent: RuleDiffElement | TelemetryBlockDiffElement,
    version: 'old' | 'new'
  ): Range | null => {
    const range = sharedDiffUtil.getDiffValue(
      parent,
      'range',
      version
    ) as unknown as RangeDiffElement;
    return {
      min: sharedDiffUtil.getDiffValue<string | number>(range, 'min', version),
      max: sharedDiffUtil.getDiffValue<string | number>(range, 'max', version),
      include_min: sharedDiffUtil.getDiffValue<boolean>(
        range,
        'include_min',
        version
      ),
      include_max: sharedDiffUtil.getDiffValue<boolean>(
        range,
        'include_max',
        version
      ),
    };
  },

  getNewRange: (
    parent: RuleDiffElement | TelemetryBlockDiffElement
  ): Range | null => {
    return diffUtil._getRangeForVersion(parent, 'new');
  },

  getRuleDiffValues: (
    rule: RuleDiffElement | undefined
  ): {
    newBasicRule: Rule | null;
    oldBasicRule: Rule | null;
    newRange: Range | null;
    oldRange: Range | null;
    hasChanged: boolean;
  } => {
    if (!rule) {
      return {
        newBasicRule: null,
        oldBasicRule: null,
        newRange: null,
        oldRange: null,
        hasChanged: false,
      };
    }
    const newBasicRule = diffUtil._getBasicRuleForVersion(rule, 'new');
    const oldBasicRule = diffUtil._getBasicRuleForVersion(rule, 'old');

    const newRange = newBasicRule
      ? null
      : diffUtil._getRangeForVersion(rule, 'new');

    const oldRange = oldBasicRule
      ? null
      : diffUtil._getRangeForVersion(rule, 'old');

    const hasChanged =
      // value can be number or string, so compare strings
      !isEqualWith(oldBasicRule, newBasicRule, (a, b, key) => {
        if (key === 'value') {
          return String(a) === String(b);
        }
      }) ||
      /*
       * min/max can be number or string, so compare strings.
       * include_min/include_max can be undefined or a boolean, so force comparing booleans
       */
      !isEqualWith(oldRange, newRange, (a, b, key) => {
        if (key === 'min' || key === 'max') {
          return String(a) === String(b);
        }
        if (key === 'include_min' || key === 'include_max') {
          return Boolean(a) === Boolean(b);
        }
      });

    return {
      newBasicRule,
      oldBasicRule,
      newRange,
      oldRange,
      hasChanged,
    };
  },

  /**
   * Consolidates an array only to have the changed elements surrounded by closest surrounding non-changed elements for context.
   * (Similar to a code diff that only shows lines that changes along with some surrounding unchanged lines)
   */
  getChangesWithContext: <T extends DiffElement>({
    items,
    placeholderGenerator,
    getIsPlaceholder,
    numVisibleElementsAroundChange = 1,
  }: {
    items: Array<T>;
    placeholderGenerator: () => T;
    getIsPlaceholder: (item: T) => boolean;
    numVisibleElementsAroundChange?: number;
  }): Array<T> => {
    if (!items || items.length === 0) {
      return [];
    }

    /*
     * 1. Get all indices of items that have changed.
     * 2. For each index, store the surrounding indices (+/- numVisibleElementsAroundChange) in a set
     */
    const changedIndexSet = items.reduce((changedIndexSet, item, index) => {
      if (
        [
          ARRAY_CHANGE_SYMBOLS.ADDED,
          ARRAY_CHANGE_SYMBOLS.REMOVED,
          ARRAY_CHANGE_SYMBOLS.MODIFIED,
        ].some((state) => state === item.diff_change_state)
      ) {
        const includeMinIndex = Math.max(
          index - numVisibleElementsAroundChange,
          0
        );
        const includeMaxIndex = Math.min(
          index + numVisibleElementsAroundChange,
          items.length - 1
        );
        for (let i = includeMinIndex; i <= includeMaxIndex; i++) {
          changedIndexSet.add(i);
        }
      }
      return changedIndexSet;
    }, new Set<number>());

    // 3. Create a new array that contains all elements at indices in the set, buffered by one placeholder for every contiguous group of absent elements.
    return items.reduce((consolidated, item, index) => {
      if (changedIndexSet.has(index)) {
        consolidated.push(item);
      } else if (
        consolidated.length === 0 ||
        !getIsPlaceholder(consolidated[consolidated.length - 1])
      ) {
        consolidated.push(placeholderGenerator());
      }

      return consolidated;
    }, [] as Array<T>);
  },

  prepareRedlinedStepForDiff: ({
    step,
    stepId,
  }: {
    step: RedlinedStep;
    stepId?: string;
  }): Partial<RedlinedStep> => {
    const omitFields = [
      'redlines',
      'redline_comments',
      'redline_id',
      'created_during_run', // diffs ignore added steps, so remove this field for comparison
      'created_at',
      'created_by',
    ];

    const cleanedStep = omit(copyStepWithoutActiveContent(step), omitFields);
    if (!cleanedStep.id && stepId) {
      cleanedStep.id = stepId;
    }
    return cleanedStep;
  },

  prepareRedlinedStepForBlockDiff: ({
    step,
    blockId,
  }: {
    step: RedlinedStep;
    blockId: string;
  }): Partial<RedlinedStep> | undefined => {
    const previousStepCleaned = diffUtil.prepareRedlinedStepForDiff({
      step,
    });
    if (!previousStepCleaned.content) {
      return;
    }

    const previousContent = previousStepCleaned.content.find(
      (prevBlock) => prevBlock.id === blockId
    );
    if (!previousContent) {
      return;
    }

    return { content: [previousContent] };
  },

  getFullStepRedlineDiff: ({
    previousStep,
    updatedStep,
    stepId,
    ignoreIds = false,
  }: {
    previousStep: RedlinedStep | RunStep;
    updatedStep: RedlinedStep | RunStep;
    stepId?: string;
    ignoreIds?: boolean;
  }): Array<StepDiffElement> => {
    const updatedRaw = diffUtil.prepareRedlinedStepForDiff({
      step: updatedStep,
      stepId: stepId ?? previousStep.id,
    });

    // Update the ids to account for ids changed due to section/step repeats.
    const updatedWithAdjustedIds: Partial<RedlinedStep> =
      updateIdsForIncludingSuggestedEdit(
        previousStep,
        updatedRaw as RedlinedStep
      );
    const previous = diffUtil.prepareRedlinedStepForDiff({
      step: previousStep,
      stepId,
    });
    if (updatedWithAdjustedIds.conditionals) {
      previous.conditionals = updatedWithAdjustedIds.conditionals;
    }
    return diffUtil.getStepDiff({
      previous,
      updated: updatedWithAdjustedIds,
      ignoredFields: [
        'actions',
        'redline_id',
        'dependencies',
        ...(ignoreIds ? ['id'] : []),
      ],
    });
  },
};

export default diffUtil;

import Tooltip from '../../../elements/Tooltip';

const renderText = (value) => {
  return (
    <Tooltip content={value} visible={value?.length > 25}>
      <div className="truncate font-medium text-xs text-gray-600">{value}</div>
    </Tooltip>
  );
};

export default renderText;

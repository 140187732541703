import Pluralize from 'pluralize';
import { tz } from 'moment-timezone';
import { createDateTime, isOldTimestampData } from 'shared/lib/datetime';
const { DateTime, Info, IANAZone } = require('luxon');

export const MILLISECONDS_PER_SECOND = 1000;
export const MILLISECONDS_PER_MINUTE = MILLISECONDS_PER_SECOND * 60;
export const MILLISECONDS_PER_HOUR = MILLISECONDS_PER_MINUTE * 60;
export const MILLISECONDS_PER_DAY = MILLISECONDS_PER_HOUR * 24;

const JUST_NOW_THRESHOLD_MILLISECONDS = 10 * MILLISECONDS_PER_SECOND;

const VALID_TZ_REGEX = new RegExp(/^(\w+\/\w+|UTC)/);
const INVALID_TZ_REGEX = new RegExp(/^Etc/);
export const TIMEZONE_NAMES = tz
  .names()
  .filter(
    (tzName) =>
      DateTime.local().setZone(tzName).isValid && tzName.match(VALID_TZ_REGEX) && !tzName.match(INVALID_TZ_REGEX)
  );

/**
 * @return {Array<{abbr: string, offset: string }>} A list of
 * abbreviations and offsets sorted by UTC offset, lowest to highest value.
 */
export const _getTimezoneAbbrListWithOffset = (tzName) => {
  const standardTimestamp = '2023-01-02T00:00:00Z';
  const standardDt = DateTime.fromISO(standardTimestamp).setZone(tzName);
  const standardAbbr = standardDt.toFormat('ZZZZ');

  /*
   * The offset that Luxon calculates is the UTC offset.
   * See https://moment.github.io/luxon/api-docs/index.html#datetime
   */
  const list = [
    {
      abbr: standardAbbr,
      offset: standardDt.toFormat('Z'),
    },
  ];

  if (!Info.hasDST(tzName)) {
    return list;
  }

  /*
   * Start by adding 6 months, since after 6 months there has likely been a
   * DST shift. If the shift is not found, keep incrementing months until it
   * is found.
   */
  for (let i = 6; i <= 17; i++) {
    const updatedDt = standardDt.plus({ month: i });
    const abbr = updatedDt.toFormat('ZZZZ');
    if (abbr !== standardAbbr) {
      list.push({
        abbr,
        offset: updatedDt.toFormat('Z'),
      });

      break;
    }
  }

  return list.sort((abbrObjA, abbrObjB) => {
    return abbrObjA.offset.localeCompare(abbrObjB.offset);
  });
};

const TIMEZONE_ABBR_LIST_MAP = new Map(
  TIMEZONE_NAMES.map((tzName) => [tzName, _getTimezoneAbbrListWithOffset(tzName)])
);

export const getTimezoneDisplayLabel = (tzName) => {
  const zone = new IANAZone(tzName);
  if (!zone?.isValid) {
    return tzName;
  }

  const zoneAbbrList = TIMEZONE_ABBR_LIST_MAP.get(tzName);
  if (!zoneAbbrList) {
    return tzName;
  }
  const offsetDisplay =
    tzName === 'UTC'
      ? ''
      : `${zoneAbbrList.map((abbrObj) => abbrObj.abbr).join(' | ')} (${zoneAbbrList
          .map((abbrObj) => `UTC${abbrObj.offset}`)
          .join(' | ')})`;

  return `${tzName.replaceAll('_', ' ')}${offsetDisplay && ` — ${offsetDisplay}`}`;
};

export const getUtcTimestampFromIso = (isoTimestamp) => {
  return DateTime.fromISO(isoTimestamp, { zone: 'UTC' }).toISO();
};

/**
 * Create a Date whose year, month, and day correspond to the iso date param.
 * However, the Date's time will be set to be midnight (00:00:00) in the
 * system's local timezone.
 * @return {null|Date}
 */
export const createUtcEquivalentDateFromIsoDate = (isoDate) => {
  if (!isoDate) {
    return null;
  }
  const dt = DateTime.fromISO(isoDate, { zone: 'UTC' });

  // The month value for Date is 0-based.
  const date = new Date(Date.UTC(dt.year, dt.month - 1, dt.day));

  /*
   * Add the offset in minutes to the date.
   * This creates a false date that represents midnight on the date
   * represented by the iso date, but the timezone for this Date will be in
   * local time, and should be ignored.
   */
  const utcOffsetMinutes = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() + utcOffsetMinutes);
  return date;
};

/**
 * @param {string | null} timestamp Full ISO timestamp
 * @returns {string | null} of the form 'yyyy-mm-dd'
 */
export const getISODate = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return DateTime.fromISO(timestamp, { setZone: true }).toISODate();
};

/**
 * @param {string | null} timestamp Full ISO timestamp
 * @returns {string | null} of the form 'HH:mm:ss'
 */
export const getISOTime = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  const dt = DateTime.fromISO(timestamp, { setZone: true });
  return dt.set({ millisecond: 0 }).toISOTime({ includeOffset: false, suppressMilliseconds: true });
};

/**
 * Creates a luxon DateTime from various timestamp formats used in the application.
 * @param {string | Date} timestamp to interpret as a DateTime
 * @returns a luxon DateTime
 */
const getDateTimeInstance = (timestamp) => {
  if (timestamp instanceof Date) {
    return DateTime.fromJSDate(timestamp).toUTC();
  }
  return DateTime.fromISO(timestamp).toUTC();
};

/**
 * @param {Date} timestamp
 * @returns {String} representing start of said day as a UTC formatted string.
 */
export const getStartOfDay = (timestamp) => {
  const dt = getDateTimeInstance(timestamp);

  return dt.startOf('day').toISO();
};

/**
 * @param {Date} timestamp
 * @returns {String} representing end of said day as a UTC formatted string.
 */
export const getEndOfDay = (timestamp) => {
  const dt = getDateTimeInstance(timestamp);

  return dt.endOf('day').toISO();
};

/**
 *
 * @param {String | Date} timestamp to format
 * @param {String} format to use, eg 'yyyy-MM-dd'
 * @returns {String} formatted timestamp
 */
export const formattedDate = (timestamp, format) => {
  const dt = getDateTimeInstance(timestamp);

  return dt.toFormat(format);
};

/**
 * Format timestamp with specified timezone.
 * @param {string} timestamp to format
 * @param {string} zoneName
 * @returns a string representation of the timestamp
 */
export const formattedISOTimestampInTimezone = (timestamp, zoneName = 'UTC') => {
  if (!timestamp) {
    return '';
  }

  const dtUTC = getDateTimeInstance(timestamp);
  const dtInZone = dtUTC.setZone(zoneName);

  // Use zulu (UTC) time if the timezone is invalid.
  const dtFinal = dtInZone.zoneName && dtInZone.zone.isValid ? dtInZone : dtUTC;

  const isUTC = dtFinal.toFormat('ZZZZ') === dtUTC.toFormat('ZZZZ');

  return isUTC ? `${dtFinal.toFormat('yyyy-MM-dd HH:mm:ss')}Z` : dtFinal.toFormat('yyyy-MM-dd HH:mm:ss ZZZZ');
};

/**
 * Format field input timestamp with specified timezone.
 * @param {string} timestamp to format
 * @param {string} zoneName
 * @returns a string representation of the timestamp
 */
export const formattedFieldInputISOTimestampInTimezone = (timestamp, zoneName = 'UTC') => {
  if (!timestamp) {
    return '';
  }

  const dtUTC = getDateTimeInstance(timestamp);
  const dtInZone = dtUTC.setZone(zoneName);

  // Use zulu (UTC) time if the timezone is invalid.
  const dtFinal = dtInZone.zoneName && dtInZone.zone.isValid ? dtInZone : dtUTC;

  return dtFinal.toFormat('dd MMM yyyy HH:mm:ss ZZZZ');
};

export const formattedISODateInTimezone = (date, zone) => {
  if (!date || !zone) {
    return '';
  }
  const dt = DateTime.fromISO(date, { zone });
  return dt.toFormat('dd MMM yyyy ZZZZ');
};

/**
 * @param {string} time A timestamp containing the time-only info.
 *   This can be HH:mm:ss, or a full ISO timestamp that includes the current
 *   date.
 * @param {string} zone The timezone in which to set the time
 * @returns {DateTime} A DateTime object representing the given time in the
 * given time zone (the current date is used to determine daylight savings)
 */
export const getTimeInstance = (time, zone) => {
  // Use { keepLocalTime: true } to prevent any time conversion.
  const timeValidDt = DateTime.fromISO(time, { zone: 'UTC' }).setZone(zone, { keepLocalTime: true });

  return createDateTime({
    zone,
    hour: timeValidDt.hour,
    minute: timeValidDt.minute,
    second: timeValidDt.second,
  });
};

/**
 * @param {string} time A time of the form 'HH:mm:ss', or a full
 *   timestamp, the date of which will be ignored.
 * @param {string} zone
 * @returns {string} Returns a formatted string that displays the time in the
 * given time zone.
 */
export const formattedIsoTimeInTimezone = (time, zone) => {
  if (!time || !zone) {
    return '';
  }

  const isoTime = getISOTime(time);
  if (!isoTime) {
    return '';
  }
  return getTimeInstance(isoTime, zone).toFormat('HH:mm:ss ZZZZ');
};

/**
 * Creates a Day-of-Year UTC timestamp of the form:
 *   2013-348 / 11:04:23 UTC
 * @param {string} timestamp Full ISO timestamp
 * @return {null | string}
 */
export const formattedDoyUtcTimestamp = (timestamp) => {
  if (!timestamp) {
    return '';
  }

  const dtUtc = getDateTimeInstance(timestamp);
  return dtUtc.toFormat('yyyy-o / HH:mm:ss ZZZZ');
};

/**
 * Creates a time-only UTC timestamp of the form:
 *   11:04:23 UTC
 * @param {string} time Time of the form 'HH:mm:ss'
 * @param {string} zone
 * @return {null | string}
 */
export const formattedUtcTime = (time, zone) => {
  if (!time || !zone) {
    return null;
  }

  const dtUtc = getTimeInstance(time, zone).setZone('UTC');
  return dtUtc.toFormat('HH:mm:ss ZZZZ');
};

/**
 * Format timestamp in an additional manner that provides
 * some extra convenience for the user.
 * Uses the user's local timezone.
 *
 * @param {string | Date} timestamp to format
 * @returns {string | null} a string representation of the timestamp of the
 * form:
 *   December 8, 2023 at 6:04:07 PM EST
 */
export const formattedLocalDateTime = (timestamp) => {
  if (!timestamp) {
    return null;
  }

  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dt = getDateTimeInstance(timestamp).setZone(clientTimeZone);
  return dt.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);
};

/**
 * Format time in an additional manner that provides
 * some extra convenience for the user.
 * Uses the user's local timezone (so the value will be different for PST
 * and PDT, for example, where PST/PDT is determined by the current date in
 * the user's local timezone).
 *
 * @param {string} time to format, of the form 'HH:mm:ss' or ISO timestamp
 * @param {string} zone the timezone of the passed-in time
 * @returns {string | null} a string representation of the local time of the
 * form:
 *   6:04:07 PM EST
 */
export const formattedLocalTime = (time, zone) => {
  if (!time) {
    return null;
  }

  const timeInstance = getTimeInstance(time, zone);
  const currentDateTime = createDateTime({
    zone,
    hour: timeInstance.hour,
    minute: timeInstance.minute,
    second: timeInstance.second,
  });

  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return currentDateTime.setZone(clientTimeZone).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);
};

/**
 *
 * @param {string | null} timestamp
 * @return {{date: string | null, time: string | null}}
 */
export const splitIsoTimestamp = (timestamp) => {
  if (!timestamp) {
    return {
      date: null,
      time: null,
    };
  }
  return {
    date: getISODate(timestamp),
    time: getISOTime(timestamp),
  };
};

export const versionDateString = (date) => {
  const padTwoDigits = (num) => {
    if (num < 10) {
      return `0${num}`;
    }
    return `${num}`;
  };
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() is 0-indexed
  const day = date.getDate();
  return `${year}.${padTwoDigits(month)}.${padTwoDigits(day)}`;
};

/**
 * Breaks up a duration value (in milliseconds) into its components
 * including seconds, minutes, hours, and days.
 * As well as converts milliseconds to seconds, minutes, hours, and days.
 *
 * @param {Number} durationInMilliseconds - Duration in milliseconds
 * @returns {Object} Duration broken up by components and converted
 */
export const durationToComponents = (durationInMilliseconds) => {
  const days = Math.floor(durationInMilliseconds / MILLISECONDS_PER_DAY);
  const hours = Math.floor((durationInMilliseconds % MILLISECONDS_PER_DAY) / MILLISECONDS_PER_HOUR);
  const minutes = Math.floor((durationInMilliseconds % MILLISECONDS_PER_HOUR) / MILLISECONDS_PER_MINUTE);
  const seconds = Math.floor((durationInMilliseconds % MILLISECONDS_PER_MINUTE) / MILLISECONDS_PER_SECOND);
  const milliseconds = Math.floor(durationInMilliseconds % MILLISECONDS_PER_SECOND);

  const totalDays = durationInMilliseconds / MILLISECONDS_PER_DAY;
  const totalHours = durationInMilliseconds / MILLISECONDS_PER_HOUR;
  const totalMinutes = durationInMilliseconds / MILLISECONDS_PER_MINUTE;
  const totalSeconds = durationInMilliseconds / MILLISECONDS_PER_SECOND;

  return {
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
    totalDays,
    totalHours,
    totalMinutes,
    totalSeconds,
    totalMilliseconds: durationInMilliseconds,
  };
};

/**
 * Converts a duration value (in milliseconds) into '#d #h #m #s' format
 * omitting empty components
 *
 * @param {Number} durationInMilliseconds - Duration in milliseconds
 * @returns {String} Duration in '#d #h #m #s' format
 */
export const durationToDhms = (durationInMilliseconds) => {
  if (!durationInMilliseconds) {
    return '0s';
  }
  if (durationInMilliseconds < MILLISECONDS_PER_SECOND) {
    return `${durationInMilliseconds / 1000}s`;
  }

  const { days, hours, minutes, seconds } = durationToComponents(durationInMilliseconds);

  const daysDisplay = days > 0 ? `${days}d` : null;
  const hoursDisplay = hours > 0 ? `${hours}h` : null;
  const minutesDisplay = minutes > 0 ? `${minutes}m` : null;
  const secondsDisplay = seconds > 0 ? `${seconds}s` : null;
  return [daysDisplay, hoursDisplay, minutesDisplay, secondsDisplay].filter((el) => !!el).join(' ');
};

/**
 * Identifies the largest component in a duration value (in milliseconds)
 * which could be milliseconds, seconds, minutes, hours, and days
 *
 * @param {Number} milliseconds - Duration in milliseconds
 * @returns {Object} with the following properties:
 * {String} short - Short name of the largest duration component
 * {String} long - Long name of the largest duration component
 * {String} label - Label of the largest duration component
 */
export const getDurationComponentLabel = (milliseconds) => {
  if (milliseconds > MILLISECONDS_PER_DAY) {
    return {
      short: 'days',
      long: 'totalDays',
      label: 'days',
    };
  } else if (milliseconds > MILLISECONDS_PER_HOUR) {
    return {
      short: 'hours',
      long: 'totalHours',
      label: 'hours',
    };
  } else if (milliseconds > MILLISECONDS_PER_MINUTE) {
    return {
      short: 'minutes',
      long: 'totalMinutes',
      label: 'minutes',
    };
  } else if (milliseconds > MILLISECONDS_PER_SECOND) {
    return {
      short: 'seconds',
      long: 'totalSeconds',
      label: 'seconds',
    };
  } else {
    return {
      short: 'milliseconds',
      long: 'totalMilliseconds',
      label: 'milliseconds',
    };
  }
};

/**
 * Parses the given timestamp as a Date object. Currently ignores ms.
 *
 * @param {String} timestamp - An ISO8601 formatted datetime string.
 * @returns {Date} date - The resulting date.
 */
export const timestampAsUtc = (timestamp) => {
  const date = new Date(timestamp);
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();
  return new Date(year, month, day, hours, minutes, seconds);
};

export const timeAgo = (timestamp, currentTime) => {
  const timeDifferenceMs = currentTime.getTime() - timestamp.getTime();
  if (timeDifferenceMs < JUST_NOW_THRESHOLD_MILLISECONDS) {
    return 'just now';
  } else if (timeDifferenceMs <= MILLISECONDS_PER_MINUTE) {
    const secondsAgo = Math.floor(timeDifferenceMs / MILLISECONDS_PER_SECOND);
    return `${secondsAgo} seconds ago`;
  } else if (timeDifferenceMs < MILLISECONDS_PER_HOUR) {
    const minutesAgo = Math.floor(timeDifferenceMs / MILLISECONDS_PER_MINUTE);
    return `${Pluralize('minute', minutesAgo, true)} ago`;
  } else if (timeDifferenceMs < MILLISECONDS_PER_DAY) {
    // Display hours ago
    const hoursAgo = Math.floor(timeDifferenceMs / MILLISECONDS_PER_HOUR);
    return `${Pluralize('hour', hoursAgo, true)} ago`;
  } else {
    return 'over 1 day ago';
  }
};

/**
 *
 * @param { 'date' | 'time' | 'datetime' } type
 * @param { string | null } [timestamp]
 * @param { string | null } [zone]
 * @return { string }
 */
export const getFormattedRecordedTimestamp = (type, timestamp, zone = 'UTC') => {
  if (!timestamp) {
    return '';
  }
  if (type === 'date') {
    return formattedISODateInTimezone(timestamp, zone);
  }
  if (type === 'time') {
    return formattedIsoTimeInTimezone(timestamp, zone ?? 'UTC');
  }

  // datetime type
  return formattedFieldInputISOTimestampInTimezone(timestamp, zone ?? undefined);
};

/**
 *
 * @param { 'date' | 'time' | 'datetime' } type
 * @param { {value?: string | import('shared/lib/types/views/procedures').TimestampValueV2} | undefined } recorded
 * @return { boolean }
 */
export const getHasAllValuesRecorded = (type, recorded) => {
  if (!recorded?.value) {
    return false;
  }
  if (
    isOldTimestampData(
      /** @type {import('shared/lib/types/views/procedures').RunFieldInputRecorded<import('shared/lib/types/views/procedures').TimestampValue>} */ (
        recorded
      )
    )
  ) {
    return true;
  }

  const timestampValueV2 = /** @type {import('shared/lib/types/views/procedures').TimestampValueV2}*/ (recorded.value);
  if (type === 'date') {
    return Boolean(timestampValueV2?.date);
  }

  if (type === 'time') {
    return Boolean(timestampValueV2?.time);
  }

  // datetime type
  return Boolean(timestampValueV2?.date) && Boolean(timestampValueV2?.time);
};

import { getTimestampFromRecorded } from 'shared/lib/datetime';
import { getFormattedRecordedTimestamp } from '../datetime';

/**
 * Contains functions related to getting data from fields
 */
const field = {
  getValue: (content, includeFieldValues) => {
    if (content.static || content.alwaysShow) {
      // For static text fields in tables
      return field._getStaticTableFieldValue(content);
    }

    const fieldInputType = field.getInputType(content);
    if (fieldInputType === 'attachment') {
      return field._getAttachmentFieldValue(content);
    }
    if (fieldInputType === 'checkbox' || fieldInputType === 'input:checkbox') {
      return field._getCheckboxFieldValue(content, includeFieldValues);
    }

    if (fieldInputType === 'timestamp') {
      const fieldDateTimeType = field._getDateTimeType(content);

      const { timestamp, zone } = getTimestampFromRecorded(content.recorded);
      return getFormattedRecordedTimestamp(fieldDateTimeType, timestamp, zone);
    }

    // number or text field
    return field._getFieldValue(content);
  },

  getInputType: (content) => {
    return content.inputType?.toLowerCase();
  },

  getUnits: (content) => {
    const fieldInputType = field.getInputType(content);
    if (
      fieldInputType === 'number' ||
      fieldInputType === 'text' ||
      fieldInputType === 'input:number' ||
      fieldInputType === 'input:text'
    ) {
      return content.units;
    }

    return '';
  },

  _hasRecordedValue: (content) => {
    // Return true if the value is 0
    return content.recorded && content.recorded.value !== null && content.recorded.value !== undefined;
  },

  _getAttachmentFieldValue: (content) => {
    if (!field._hasRecordedValue(content)) {
      return null;
    }
    return content.recorded.value.name;
  },

  _getCheckboxFieldValue: (content, includeFieldValues) => {
    if (!includeFieldValues) return null;
    return field._hasRecordedValue(content) ? (content.recorded.value ? 'TRUE' : 'FALSE') : 'FALSE';
  },

  _getDateTimeType: (content) => content.dateTimeType && content.dateTimeType.toLowerCase(),

  _getFieldValue: (content) => {
    if (!field._hasRecordedValue(content)) {
      return null;
    }
    return content.recorded.value;
  },

  _getStaticTableFieldValue: (content) => {
    return content.value;
  },
};

export default field;

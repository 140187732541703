import { useMemo, useState, useEffect } from 'react';
import PartAndRevisionIdSelect, { PartSelection } from '../../manufacturing/components/PartAndRevisionIdSelect';
import useParts from '../../manufacturing/hooks/useParts';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { ItemDetail } from '../../manufacturing/types';
import Select from 'react-select';
import { DraftInventoryDetailInputBlock } from 'shared/lib/types/views/procedures';
import FieldSetCheckbox from '../../components/FieldSetCheckbox';
import { InventoryDetailInputContentErrors } from '../../lib/types';
import { reactSelectStyles } from '../../lib/styles';
import { FormikHelpers, FormikValues } from 'formik';
import { isPartRestricted } from '../lib/parts';
import RestrictedInfo, { RESTRICTED_TEXT } from './RestrictedInfo';
import apm from '../../lib/apm';
import { useRunContext } from '../../contexts/RunContext';

interface InventoryDetailInputFieldSetProps {
  content: DraftInventoryDetailInputBlock;
  contentErrors: InventoryDetailInputContentErrors;
  path: string;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
}

const InventoryDetailInputFieldSet = ({
  content,
  contentErrors,
  path,
  setFieldValue,
}: InventoryDetailInputFieldSetProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { parts, getPartByRevisionId, getPart } = useParts();
  const { isRun } = useRunContext();

  const [itemDetails, setItemDetails] = useState<Array<ItemDetail> | null>(null);
  const selectedPart: PartSelection | null = useMemo(() => {
    const part = getPartByRevisionId(content.part_revision_id);
    return parts && part
      ? {
          part,
          partRevisionId: content.part_revision_id,
        }
      : null;
  }, [content.part_revision_id, getPartByRevisionId, parts]);

  const handleSelectPart = (selection: PartSelection | null) => {
    if (selection?.partRevisionId) {
      const value = {
        ...content,
        part_revision_id: selection.partRevisionId,
        part_id: selection.part.id,
      };
      setFieldValue(path, value);
    }
  };

  useEffect(() => {
    services.manufacturing
      .listItemDetails()
      .then(setItemDetails)
      .catch((err) => apm.captureError(err));
  }, [services.manufacturing]);

  const itemDetailOptions = useMemo(() => {
    return itemDetails
      ? itemDetails.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      : [];
  }, [itemDetails]);

  const handleItemDetailChange = (newItem) => {
    setFieldValue(`${path}.detail_id`, newItem.value);
  };

  const selectedItemDetail = useMemo(() => {
    return itemDetailOptions.find((item) => item.value === content?.detail_id);
  }, [itemDetailOptions, content?.detail_id]);

  const isLoading = useMemo(
    () => (content.part_revision_id && !selectedPart) || itemDetails === null,
    [selectedPart, itemDetails, content.part_revision_id]
  );

  // if showing an already selected part that the user does not have access to
  if (content.part_revision_id && isPartRestricted(getPart(content.part_id || ''))) {
    return (
      <div className="my-2 ml-1">
        <RestrictedInfo text={RESTRICTED_TEXT} />
      </div>
    );
  }

  return isLoading ? null : (
    <div className="grow w-full">
      <div className="grow relative">
        <div className="flex flex-wrap flex-row space-x-2">
          {/* Part and revision selector */}
          <div className="flex flex-col">
            <PartAndRevisionIdSelect selected={selectedPart} onSelect={handleSelectPart} />
            {contentErrors?.part_revision_id && <span className="text-red-700">{contentErrors.part_revision_id}</span>}
          </div>
          {/* Item detail selector*/}
          <div className="flex flex-col">
            <span className="field-title">Inventory Detail</span>
            <Select
              styles={reactSelectStyles}
              classNamePrefix="react-select"
              className="w-64"
              options={itemDetailOptions}
              onChange={handleItemDetailChange}
              value={selectedItemDetail}
              isSearchable
            />
            {contentErrors?.detail_id && <span className="text-red-700">{contentErrors.detail_id}</span>}
          </div>
          {/* Include in summary checkbox */}
          <div className="ml-2 mt-2 flex flex-row items-center">
            <FieldSetCheckbox
              text="Include in Summary"
              fieldName={`${path}.include_in_summary`}
              setFieldValue={setFieldValue}
              isDisabled={isRun}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryDetailInputFieldSet;

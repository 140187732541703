import React, { useCallback, useMemo } from 'react';
import SignoffButtons from '../SignoffButtons';
import reviewUtil from '../../lib/reviewUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SignoffButton } from '../types';
import { getHasReviewerApproved } from 'shared/lib/reviewUtil';
import Checkbox from '../Checkbox';
import { Reviewer, ReviewerGroup, ReviewerId, ReviewerOperatorRoleId } from 'shared/lib/types/couch/procedures';

interface ViewReviewerGroupProps {
  reviewerGroup: ReviewerGroup;
  onReviewerApprove?: (reviewerGroupId: string, reviewerId: string, operatorRole: string) => void;
  onRevokeApproval?: (reviewerGroupId: string, reviewerId: string) => void;
  hasPermission: (reviewerId: ReviewerId) => boolean;
  onEdit?: () => void;
  showEditActions: boolean;
  showSignoffCheckmark: boolean;
  showSettings?: boolean;
  isActive: boolean;
  hasTopPadding: boolean;
  allowClearingApprovals?: boolean;
}
const ViewReviewerGroup = ({
  reviewerGroup,
  onReviewerApprove,
  onRevokeApproval,
  hasPermission,
  onEdit,
  showEditActions,
  showSignoffCheckmark,
  showSettings = false,
  isActive = false,
  hasTopPadding = true,
  allowClearingApprovals,
}: ViewReviewerGroupProps) => {
  const getOnClickHandler = useCallback(
    (reviewer: Reviewer, reviewerId: ReviewerId) => {
      return () => {
        let operatorRole;

        if (reviewerId.type === 'operator_role') {
          operatorRole = reviewerId.value;
        }

        return onReviewerApprove && onReviewerApprove(reviewerGroup.id, reviewer.id, operatorRole);
      };
    },
    [reviewerGroup.id, onReviewerApprove]
  );

  const getButtons = useCallback(
    (reviewer: Reviewer): Array<SignoffButton> => {
      if (!reviewer.reviewer_ids) {
        return [];
      }

      return reviewer.reviewer_ids.map((reviewerId) => ({
        id: reviewerId.value,
        type: reviewerId.type,
        label: reviewUtil.getReviewerLabel(reviewerId),
        userIds: (reviewerId as ReviewerOperatorRoleId).user_ids,
        isDisabled: !isActive || !hasPermission(reviewerId),
        onClick: getOnClickHandler(reviewer, reviewerId),
      }));
    },
    [getOnClickHandler, hasPermission, isActive]
  );

  const groupName = useMemo(() => reviewerGroup.name, [reviewerGroup]);

  const getIsReviewComplete = useCallback(
    (reviewer: Reviewer) => {
      return getHasReviewerApproved(reviewer, reviewerGroup.actions);
    },
    [reviewerGroup.actions]
  );

  const getRevokeApprovalHandler = useCallback(
    (reviewer: Reviewer) => {
      if (
        onRevokeApproval &&
        isActive &&
        getIsReviewComplete(reviewer) &&
        reviewer.reviewer_ids.some((reviewerId) => hasPermission(reviewerId))
      ) {
        return () => onRevokeApproval(reviewerGroup.id, reviewer.id);
      }

      return null;
    },
    [getIsReviewComplete, hasPermission, isActive, onRevokeApproval, reviewerGroup.id]
  );

  return (
    <div className={`flex flex-col w-full gap-y-2 ${hasTopPadding ? 'pt-2' : ''}`} aria-label={`Group ${groupName}`}>
      <label className="text-gray-500 font-medium text-sm">{groupName}</label>
      <div className="flex flex-row flex-wrap gap-2">
        {reviewerGroup.reviewers.map((reviewer) => (
          <div key={reviewer.id}>
            <SignoffButtons
              buttons={getButtons(reviewer)}
              isDisabled={!isActive || showSettings} // additional disabling is done in the buttons
              isComplete={getIsReviewComplete(reviewer)}
              leadingIcon={showSignoffCheckmark ? 'check-circle' : undefined}
              revokeApproval={getRevokeApprovalHandler(reviewer)}
            />
          </div>
        ))}
        {showEditActions && (
          <button
            aria-label="Edit Reviewer Groups"
            className="text-blue-500 hover:text-blue-800 disabled:text-gray-400 ml-3"
            disabled={false}
            onClick={onEdit}
          >
            <FontAwesomeIcon icon="pencil-alt" size="sm" />
          </button>
        )}
      </div>
      {showSettings && allowClearingApprovals && (
        <div className="font-normal text-sm">
          <Checkbox
            checked={!reviewerGroup.persist_approvals}
            disabled={true}
            ariaLabel="Clear approvals if additional changes are made."
            label="Clear approvals if additional changes are made."
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(ViewReviewerGroup);

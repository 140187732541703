import { RELEASE_VERSION } from '../config';

let asciiArt = `
  _/_/_/_/  _/_/_/      _/_/_/  _/_/_/  _/          _/_/    _/      _/      _/_/_/
  _/        _/    _/  _/          _/    _/        _/    _/  _/_/    _/            _/
  _/_/_/    _/_/_/      _/_/      _/    _/        _/    _/  _/  _/  _/        _/_/
  _/        _/              _/    _/    _/        _/    _/  _/    _/_/            _/
  _/_/_/_/  _/        _/_/_/    _/_/_/  _/_/_/_/    _/_/    _/      _/      _/_/_/


  We're building the OS for spacecraft and complex operations.

  Changelog:    https://epsilon3.io/behind-the-console
  We're hiring: https://jobs.lever.co/epsilon3

`;

if (RELEASE_VERSION) {
  asciiArt += `\n  Release ${RELEASE_VERSION}`;
}

export default function printAsciiArt(printCb) {
  printCb(`%c${asciiArt}`, 'font-family: monospace;');
}

import {
  AggregateTelemetryModel,
  AggregateValue,
  EnumValue,
} from './types/postgres/telemetry';

export type Parameter = {
  created_at?: string;
  description?: string | null;
  dictionary_id?: number;
  id?: number;
  name: string;
  refresh_rate_ms?: number;
  type?: string;
  values?: Array<AggregateValue | EnumValue>;
  isSimulation?: boolean;
};

export const SimulatedFields = {
  'sim.node1.flag': {
    type: 'uint',
    bits: 1,
    states: {
      0: 'false',
      1: 'true',
    },
    description: 'Simulated flag value (0=false, 1=true)',
  },
  'sim.node1.status': {
    type: 'uint',
    bits: 2,
    states: {
      0: 'OFF',
      1: 'ON',
      2: 'ERROR',
    },
    description: 'Simulated status value (0=OFF, 1=ON, 2=ERROR)',
  },
  'sim.node1.float': {
    type: 'float',
    bits: 32,
    description: 'Simulated float value (0.0 to <1.0 random)',
  },
  'sim.node1.int': {
    type: 'int',
    bits: 32,
    description: 'Simulated int value (-5 to 5 repeating)',
  },
  'sim.node1.uint': {
    type: 'uint',
    bits: 32,
    description: 'Simulated uint value (0 to 5 repeating)',
  },
  'sim.node1.string': {
    type: 'text',
    bits: 0,
    description: 'simulated string value (STR1, STR2, STR3 repeating)',
  },
};

export const formatAggregateParameterName = (
  telemetryName: string,
  aggregateName: string
) => {
  return `${telemetryName}_${aggregateName}`;
};

/**
 * Extracts a list of which parameters are used in an expression.
 * This is a basic approach, it could go wrong if someone names their variable "sin" etc
 *
 * @param {String} expression The expression string.
 * @param {Array} parameterCandidates A list of known possible variables.
 */
export const extractParameterNamesFromExpression = (
  expression: string,
  parameterCandidates: Array<Parameter>
) => {
  const parametersInExpression = parameterCandidates.filter(
    (parameter) =>
      parameter.type !== 'aggregate' && expression.includes(parameter.name)
  );

  const simulatedParameters = Object.keys(SimulatedFields)
    .filter((name) => expression.includes(name))
    .map((name) => ({
      name,
      dictionary_id: 1,
      isSimulation: true,
      refresh_rate_ms: 1000,
    }));
  parametersInExpression.push(...simulatedParameters);

  /*
   * Check if the telemetry param matches the full path of an aggregate param.
   * If names overlap, use the non-aggregate param
   */
  const aggregateParameters = parameterCandidates.filter(
    (parameter): parameter is AggregateTelemetryModel =>
      parameter.type === 'aggregate'
  );
  for (const aggregateParameter of aggregateParameters) {
    const fullPathAggregateParameterNames = (
      aggregateParameter.values ?? []
    ).map((parameter) =>
      formatAggregateParameterName(aggregateParameter.name, parameter.name)
    );
    const aggregateParameterNamesInExpression =
      fullPathAggregateParameterNames.filter((parameter) =>
        expression.includes(parameter)
      );

    const isAlreadyIncluded = parametersInExpression.some((parameter) =>
      aggregateParameterNamesInExpression.includes(parameter.name)
    );

    if (aggregateParameterNamesInExpression.length > 0 && !isAlreadyIncluded) {
      parametersInExpression.push(aggregateParameter);
    }
  }

  return parametersInExpression;
};

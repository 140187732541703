import React, { useContext, createContext, ReactNode, useCallback } from 'react';
import { Interval, DateTime } from 'luxon';

export type GanttState = {
  period: Interval;
  ganttWidth: number;
  dateToXPos: (DateTime) => number;
  intervalWidth: (Interval) => number;
  timeScale: number;
};

const GanttContext = createContext<GanttState | undefined>(undefined);

interface GanttContextProps {
  period: Interval;
  timeScale: number;
  ganttWidth: number;
  dateToXPos: (date: DateTime) => number;
  children: ReactNode;
}

const GanttContextProvider = ({ period, timeScale, ganttWidth, dateToXPos, children }: GanttContextProps) => {
  const intervalWidth = useCallback(
    (interval: Interval) => {
      const startXPos = dateToXPos(interval.start);
      const endXPos = dateToXPos(interval.end);
      return endXPos - startXPos;
    },
    [dateToXPos]
  );

  return (
    <GanttContext.Provider
      value={{
        period,
        ganttWidth,
        dateToXPos,
        intervalWidth,
        timeScale,
      }}
    >
      {children}
    </GanttContext.Provider>
  );
};

const useGanttContext = (): GanttState => {
  const context = useContext(GanttContext);

  if (context === undefined) {
    throw new Error('useGanttContext must be used within a ScheduleProvider');
  }

  return context;
};

export { GanttContextProvider, useGanttContext };

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Attachment } from 'shared/lib/types/attachments';
import attachmentUtil from '../lib/attachmentUtil';
import AttachmentPreview from './AttachmentPreview';
import FilePreview from './FilePreview';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { DatabaseServices } from '../contexts/proceduresSlice';

type AttachmentsEditPreviewProps = {
  attachments: Array<Attachment>;
  setAttachments: (attachments: Array<Attachment>) => void;
  warnOnRemove?: boolean;
  stagedFiles?: Array<File>;
  removeStageFile?: (file: File) => void;
};

const AttachmentsEditPreview = ({
  attachments,
  stagedFiles = [],
  setAttachments,
  warnOnRemove = false,
  removeStageFile,
}: AttachmentsEditPreviewProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  const confirmRemoveMessage = (file: Attachment) => {
    return `Are you sure you want to remove the file ${file.name}?`;
  };

  const onRemoveFile = async (file: Attachment) => {
    if (warnOnRemove && !window.confirm(confirmRemoveMessage(file))) {
      return;
    }

    const _attachments = [...attachments];
    if (file.attachment_id) {
      await services.attachments.clearAttachment(file.attachment_id);
      _attachments.splice(_attachments.indexOf(file), 1);
      setAttachments(_attachments);
    }
  };

  const images = attachmentUtil.getImageAttachents(attachments);
  const files = attachmentUtil.getFileAttachents(attachments);

  const stagedImages = attachmentUtil.getStagedImageAttachents(stagedFiles);
  const stagedFile = attachmentUtil.getStagedFileAttachents(stagedFiles);

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap">
        {images.map((file) => (
          <div key={file.attachment_id} className="mr-2 mb-2 flex">
            <AttachmentPreview attachment={file} />
            <RemoveFileButton onRemove={() => onRemoveFile(file)} />
          </div>
        ))}
      </div>
      <div className="flex flex-col">
        {files.map((file) => (
          <div key={file.attachment_id} className="flex">
            <AttachmentPreview attachment={file} />
            <RemoveFileButton onRemove={() => onRemoveFile(file)} />
          </div>
        ))}
      </div>
      {/* Staging Files */}
      <div className="flex flex-wrap">
        {stagedImages.map((file) => (
          <div key={file.name} className="mr-2 mb-2 flex">
            <FilePreview file={file} />
            <RemoveFileButton onRemove={() => removeStageFile?.(file)} />
          </div>
        ))}
      </div>
      <div className="flex flex-col">
        {stagedFile.map((file) => (
          <div key={file.name} className="flex">
            <FilePreview file={file} />
            <RemoveFileButton onRemove={() => removeStageFile?.(file)} />
          </div>
        ))}
      </div>
    </div>
  );
};

const RemoveFileButton = ({ onRemove }) => {
  return (
    <button
      type="button"
      onClick={onRemove}
      className="place-self-center pl-2 pb-1 print:hidden"
      aria-label="Cancel Attachment"
    >
      <FontAwesomeIcon icon="circle-xmark" className="text-gray-400 w-3 h-3" />
    </button>
  );
};

export default AttachmentsEditPreview;

import { useState } from 'react';
import SearchInputControlled from './SearchInputControlled';

type SearchInputProps = {
  placeholder: string;
  onChange: (searchTerm: string) => void;
};

const SearchInput = ({ placeholder, onChange }: SearchInputProps) => {
  const [searchTerm, setSearchTerm] = useState('');

  const onSetSearchTerm = (term) => {
    setSearchTerm(term);
    onChange?.(term);
  };

  return <SearchInputControlled searchTerm={searchTerm} setSearchTerm={onSetSearchTerm} placeholder={placeholder} />;
};

export default SearchInput;

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Tooltip from '../../../elements/Tooltip';
import Button, { BUTTON_TYPES } from '../../Button';
import { RowAction } from '../types';

const renderAction = (action: RowAction) => {
  if (!action) {
    return <></>;
  }
  return (
    <div>
      <Tooltip content={action.tooltipContent} visible={action.tooltipEnabled || false} position="left">
        <>
          {action.componentType === 'button' && (
            <Button type={BUTTON_TYPES.PRIMARY} onClick={action.onClick} isDisabled={action.disabled} size="sm">
              {action.label}
            </Button>
          )}
          {action.componentType === 'buttonWithIcon' && (
            <Button
              type={BUTTON_TYPES.TERTIARY}
              removePadding={true}
              to={action.to}
              leadingIcon={action.icon as IconProp}
            >
              {action.label}
            </Button>
          )}
        </>
      </Tooltip>
    </div>
  );
};

export default renderAction;

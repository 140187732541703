import React from 'react';
import Tooltip from '../../elements/Tooltip';
import Label from '../Label';
import revisions from '../../lib/revisions';

interface SuggestedEditIconProps {
  isBlueline: boolean;
  description?: string;
}
const SuggestedEditIcon = React.memo(({ isBlueline, description }: SuggestedEditIconProps) => {
  const editType = isBlueline ? 'Blueline' : 'Redline';
  const color = isBlueline ? 'bg-blue-200' : 'bg-red-200';
  const iconColor = isBlueline ? 'blue' : 'red';
  const message = revisions.getSuggestedEditMessage(isBlueline, description);

  return (
    <div className="flex flex-row gap-x-1 items-center">
      <Tooltip content={message}>
        <Label text={editType} color={color} icon="strikethrough" iconColor={iconColor} verticalPadding={false} />
      </Tooltip>
    </div>
  );
});

export default SuggestedEditIcon;

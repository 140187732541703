import Select from 'react-select';
import { reactSelectStyles } from '../../lib/styles';
import useLocations from '../hooks/useLocations';
import { Location } from '../types';

interface LocationSelectProps {
  locationId: string;
  onChangeLocation?: (Location) => void;
  isDisabled?: boolean;
}

type LocationOption = {
  value: string;
  label: string;
  location: Location;
};

const getLocationLabel = (location) => `${location.full_code} ${location.name}`;

const LocationSelect = ({ locationId, onChangeLocation, isDisabled }: LocationSelectProps) => {
  const { locations } = useLocations();

  const options: LocationOption[] = Object.values(locations || {})
    .filter((location) => location.items_enabled === true)
    .sort((loc1, loc2) => getLocationLabel(loc1).localeCompare(getLocationLabel(loc2)))
    .map((location) => ({
      value: location.id,
      label: getLocationLabel(location),
      location,
    }));

  /**
   * Unset value must be 'null'. An 'undefined' value leads to subtle bugs.
   */
  const selectedValue: LocationOption | null = options?.find((option) => option.value === locationId) ?? null;

  const onChange = (option) => {
    onChangeLocation?.(option ? option.location : null);
  };

  return (
    <div className="w-full">
      <Select
        styles={reactSelectStyles}
        classNamePrefix="react-select"
        options={options}
        value={selectedValue}
        onChange={onChange}
        isDisabled={isDisabled}
        isSearchable={true}
        placeholder="Search locations"
        isClearable={true}
        aria-label="location"
      />
    </div>
  );
};

export default LocationSelect;

import { useMemo } from 'react';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import { DiffArrayChangeSymbol, PartList } from 'shared/lib/types/views/procedures';
import DiffContainer from '../../../components/Diff/DiffContainer';
import ProcedureDiffText from '../../../components/ProcedureDiffText';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { useReviewContext } from '../../../contexts/ReviewContext';
import useDiff from '../../../hooks/useDiff';
import { generateHiddenClassString } from '../../../lib/styles';
import useParts from '../../hooks/useParts';
import inventoryUtil from '../../lib/inventoryUtil';
import { getPartRevisionId, isPartRestricted } from '../../lib/parts';
import RestrictedInfo from '../RestrictedInfo';
import ReviewPartBadge from './ReviewPartBadge';

type ReviewPartListProps = {
  content: PartList & { diff_change_state?: DiffArrayChangeSymbol };
  isHidden: boolean;
};

const ReviewPartList = ({ content, isHidden }: ReviewPartListProps) => {
  const partIdsInBlock = useMemo(() => inventoryUtil.collectPartIdsInBlock(content), [content]);

  const { onScrollToDiffRefChanged } = useReviewContext();
  const { handleOnScrollToDiffRefChanged } = useDiff({ onScrollToDiffRefChanged });
  const { parts, getPart, getPartByRevisionId } = useParts({
    partIds: partIdsInBlock,
    includeAllReleasedRevisions: true,
  });
  const { currentTeamId } = useDatabaseServices();

  const getStyleByDiffChangeState = (item) =>
    item.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED
      ? 'bg-emerald-100 text-emerald-800'
      : item.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED
      ? 'bg-red-100 line-through text-red-600'
      : 'bg-white';

  if (!parts) {
    return null;
  }

  const bomPartRevisionId = content?.part ? getPartRevisionId(content.part) : undefined;
  const bomPart = bomPartRevisionId ? getPartByRevisionId(bomPartRevisionId) : content?.part;

  const retrievedPart = getPart(content?.part_id || '');
  const isBomPartRestricted = retrievedPart ? isPartRestricted(retrievedPart) : false;

  return (
    <div className={generateHiddenClassString('flex flex-wrap page-break mt-2', isHidden)}>
      <DiffContainer
        label="Bill of materials"
        diffChangeState={content.diff_change_state}
        isTextSticky={false}
        onScrollToDiffRefChanged={(element) =>
          handleOnScrollToDiffRefChanged(sharedDiffUtil.getDiffValue(content, 'id', 'new'), element)
        }
      >
        <div className="flex flex-col items-start w-full py-1 mr-8">
          <div className="w-full px-4">
            <div>
              <span className="text-sm font-medium uppercase">Part to Build</span>
            </div>
            <ReviewPartBadge part={bomPart ?? undefined} teamId={currentTeamId} partRestricted={isBomPartRestricted} />
            {/* Kit items */}
            {content?.items?.length > 0 && (
              <table className="table-auto w-full mt-3">
                <thead>
                  <tr>
                    <td>
                      <div className="pr-2 py-1">
                        <label htmlFor="components" className="text-sm font-medium uppercase">
                          Component Materials
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="px-2 py-1">
                        <span className="text-sm font-medium uppercase">Quantity</span>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {content?.items.map((component, index) => {
                    const partRestricted = isPartRestricted(getPart(component.part_id));
                    return (
                      <tr
                        key={index}
                        className={`h-10 text-sm border-b items-center ${getStyleByDiffChangeState(component)}`}
                      >
                        <td>
                          <div className="px-2 py-1">
                            <ReviewPartBadge
                              teamId={currentTeamId}
                              part={
                                component.revision_id
                                  ? getPartByRevisionId(component.revision_id)
                                  : getPart(component.part_id)
                              }
                              partRestricted={partRestricted}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="px-2 py-1">
                            {!partRestricted && <ProcedureDiffText diffValue={component.amount} />}
                            {partRestricted && <RestrictedInfo />}
                          </div>
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </DiffContainer>
    </div>
  );
};

export default ReviewPartList;

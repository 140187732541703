import ThumbnailFile from '../../ThumbnailFile';
import { generateHiddenClassString } from '../../../lib/styles';
import useContentType from '../../../hooks/useContentType';
import ReviewInlineImageStandalone from '../ReviewInlineImageStandalone';
import SubstepNumber from '../../SubstepNumber';
import DiffContainer from '../../Diff/DiffContainer';
import diffUtil from '../../../lib/diffUtil';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import { AttachmentBlockDiffElement } from 'shared/lib/types/views/procedures';

interface ReviewBlockAttachmentProps {
  attachment: AttachmentBlockDiffElement;
  isHidden: boolean;
  isSpacerHidden: boolean;
  blockLabel?: string;
}

// Component for rendering an attachment either as an inline image or file link.
const ReviewBlockAttachment = ({ attachment, isHidden, isSpacerHidden, blockLabel }: ReviewBlockAttachmentProps) => {
  const { shouldRenderImage } = useContentType({ attachment });

  const diffChangeStateForFile =
    // This ensures we won't show the diff container for images if only the caption changed.
    attachment.diff_change_state !== ARRAY_CHANGE_SYMBOLS.MODIFIED ||
    diffUtil.isAttachmentDataChanged(attachment, new Set(['caption']))
      ? attachment.diff_change_state
      : ARRAY_CHANGE_SYMBOLS.UNCHANGED;

  // Layout is intended for CSS grid template defined in Run.js and Procedure.js
  return (
    <tr aria-label="Content Block" role="region">
      {!isSpacerHidden && <td></td>}
      <td colSpan={2}>
        <div className={generateHiddenClassString('', isHidden)}></div>
        {/* Limit inline image to column 1 */}
        {shouldRenderImage && (
          <>
            <div className={generateHiddenClassString('mt-2 mr-8 flex page-break', isHidden)}>
              {!isSpacerHidden && <div className="w-2 h-6 mr-2 shrink-0"></div>}
              <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
              <ReviewInlineImageStandalone
                attachment={attachment}
                showCaptionText={true}
                diffChangeState={diffChangeStateForFile}
              />
            </div>
            <div className={generateHiddenClassString('mt-2 mr-8 flex', isHidden)}></div>
          </>
        )}
        {!shouldRenderImage && (
          <div className={generateHiddenClassString('mt-2 mr-8 flex page-break', isHidden)}>
            <div className="h-6 ml-2 mr-2 shrink-0"></div>
            <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
            <DiffContainer label="File" diffChangeState={diffChangeStateForFile} isTextSticky={false}>
              <ThumbnailFile attachment={attachment} />
            </DiffContainer>
          </div>
        )}
      </td>
    </tr>
  );
};

export default ReviewBlockAttachment;

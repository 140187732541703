import React, { useState, useCallback } from 'react';
import ReleaseNoteEdit from './ReleaseNoteEdit';
import DateTimeDisplay from './DateTimeDisplay';
import MarkdownView from './Markdown/MarkdownView';
import Button from './Button';

type ReleaseNoteContents = {
  text: string;
  user_id: string;
  timestamp: string;
};

interface ReleaseNoteProps {
  releaseNote: ReleaseNoteContents;
  onSaveReleaseNote?: (message: string) => Promise<void>;
  isEditable: boolean;
  hasBorder?: boolean;
}

// Release note component to display and edit release notes.
const ReleaseNote = ({ releaseNote, onSaveReleaseNote, isEditable, hasBorder = false }: ReleaseNoteProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  let noteText, noteAuthor, noteTimestamp;

  if (releaseNote) {
    noteText = releaseNote.text;
    noteAuthor = releaseNote.user_id;
    noteTimestamp = releaseNote.timestamp;
  }

  const onEdit = useCallback(() => setIsEditing(true), []);

  const onDelete = async () => {
    if (onSaveReleaseNote) {
      setIsDeleting(true);
      return onSaveReleaseNote('')
        .then(() => {
          setIsDeleting(false);
          setIsEditing(false);
        })
        .catch(() => {
          setIsDeleting(false);
        });
    }
  };

  return (
    <div aria-label="release note">
      <span className="font-medium text-sm">Release Note</span>
      <div>
        {!isEditing && noteText && (
          <div className={`flex flex-col rounded py-3 px-4 bg-white ${hasBorder ? 'border border-gray-800' : ''}`}>
            <div className="flex flex-row text-sm gap-x-2">
              <span className="text-gray-600 font-semibold">{noteAuthor}</span>
              <span className="text-gray-600">
                <DateTimeDisplay timestamp={noteTimestamp} />
              </span>
            </div>

            <div className="my-2">
              <MarkdownView text={noteText} />
            </div>
          </div>
        )}
        {isEditing && onSaveReleaseNote && (
          <ReleaseNoteEdit noteText={noteText} onSaveReleaseNote={onSaveReleaseNote} setIsEditing={setIsEditing} />
        )}
      </div>
      {!isEditing && isEditable && (
        <div className="flex flex-row gap-x-3 text-bold text-gray-500">
          <Button leadingIcon="note-sticky" iconTextColor="text-gray-800" type="tertiary" onClick={onEdit}>
            {noteText ? 'Edit' : 'Add'} Note
          </Button>
          {noteText && (
            <Button
              leadingIcon="circle-xmark"
              iconTextColor="text-gray-800"
              type="tertiary"
              onClick={onDelete}
              isDisabled={isDeleting}
            >
              {isDeleting ? 'Deleting' : 'Delete'} Note
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default ReleaseNote;

import React, { useMemo, useState, useCallback } from 'react';
import { generateHiddenClassString } from '../../lib/styles';
import SuggestedEditsModal from '../SuggestedEditsModal';
import useBlockRedlines from '../../hooks/useBlockRedlines';
import ButtonActionIcon from '../ButtonActionIcon';
import { BlockValues, RedlineBlock } from './BlockTypes';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import ProcedureFieldDiff from '../ProcedureFieldDiff';
import ReferenceTextView from '../ReferenceTextView';
import { ExpressionReferences } from 'shared/lib/types/views/procedures';

interface BlockTextProps {
  block: BlockValues<'text'>;
  blockLabel?: string;
  redlines?: Array<RedlineBlock<'text'>>;
  isHidden?: boolean;
  showsRedlineAction?: boolean;
  isEditing?: boolean;
  onRedlineAction?: () => void;
  onAcceptPendingRedline?: (redlineIndex: number) => Promise<void>;
  onRefChanged?: (id: string, element) => void;
  scrollMarginTopValueRem?: number;
  setExpressionRecorded?: (recorded: { value?: string; display?: string; references?: ExpressionReferences }) => void;
}

/*
 * Component for rendering a text block.
 *
 * block: Block object of type "text", with text to display
 * redlines: Array of RedlineBlock objects with change history
 * isHidden: Whether this block is hidden (expanded/collapsed)
 * showsRedlineAction: True to display an additional action to begin a redline edit.
 *                     False to cancel or close any existing redline edit.
 * onRedlineAction: Function callback, triggered when redline action button is clicked.
 */
const BlockText = ({
  block,
  blockLabel,
  redlines,
  isHidden,
  showsRedlineAction,
  isEditing,
  onRedlineAction = () => {
    /* no-op */
  },
  onAcceptPendingRedline,
  onRefChanged,
  scrollMarginTopValueRem,
  setExpressionRecorded,
}: BlockTextProps) => {
  const [showsRedlineModal, setShowsRedlineModal] = useState(false);
  const { latestApprovedBlock, latestRedline, hasRedlines, allRedlineUserIds, hasPendingRedlines } = useBlockRedlines({
    block,
    redlines,
  });

  const onTextBlockRefChanged = useCallback(
    (element) => {
      return onRefChanged && onRefChanged(block.id, element);
    },
    [block.id, onRefChanged]
  );

  const acceptRedline = useCallback(() => {
    if (!onAcceptPendingRedline) {
      return Promise.resolve();
    }
    return onAcceptPendingRedline(latestRedline.redlineIndex);
  }, [latestRedline, onAcceptPendingRedline]);

  const acceptRedlineEnabled = useMemo(
    () => Boolean(hasPendingRedlines && onAcceptPendingRedline),
    [hasPendingRedlines, onAcceptPendingRedline]
  );

  // Layout is intended for CSS grid template defined in Run.js and Procedure.js
  return (
    <>
      <div className={generateHiddenClassString('', isHidden)}></div>
      <div
        aria-label="Text Content"
        className={generateHiddenClassString('mt-2 mr-8 flex page-break', isHidden)}
        ref={onTextBlockRefChanged}
        style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
      >
        {/* Redline changes modal */}
        {showsRedlineModal && (
          <SuggestedEditsModal
            hideModal={() => setShowsRedlineModal(false)}
            allRedlineUserIds={allRedlineUserIds}
            latestTimestamp={latestRedline.createdAt}
            acceptAction={acceptRedlineEnabled ? acceptRedline : undefined}
            redlinePending={hasPendingRedlines}
            isBlueline={latestRedline.run_only}
          >
            <div className="min-w-0 whitespace-pre-line break-words">
              {latestRedline && <ProcedureFieldDiff original={block.text} redlined={latestRedline.block.text} />}
            </div>
          </SuggestedEditsModal>
        )}
        <Spacer />
        <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
        {/* Content */}
        <div className="min-w-0 w-full self-start">
          {latestApprovedBlock && latestApprovedBlock.text && (
            <div className="max-w-full break-words">
              <ReferenceTextView block={latestApprovedBlock} setExpressionRecorded={setExpressionRecorded} />
            </div>
          )}
        </div>
        {/* Show action button for showing redline modal */}
        {hasRedlines && (
          <span className="ml-1">
            <ButtonActionIcon
              icon="strikethrough"
              iconType={latestRedline.run_only ? 'primary' : 'caution'}
              onAction={() => setShowsRedlineModal(true)}
              ariaLabel={`Suggested Edits (${latestRedline.run_only ? 'Blueline' : 'Redline'})`}
              pendingAction={hasPendingRedlines}
              pendingDotMatchesIcon={true}
            />
          </span>
        )}
        {/* Show action button for making new redline edit */}
        {showsRedlineAction && !isEditing && (
          <span className="ml-1">
            <ButtonActionIcon icon="pencil-alt" onAction={onRedlineAction} ariaLabel="Edit" />
          </span>
        )}
      </div>
    </>
  );
};

export default BlockText;

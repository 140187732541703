// Triggers a browser download for the attachment.
export const downloadBlob = (blob, filename) => {
  // Create object URL.
  const downloadObjectUrl = URL.createObjectURL(blob);

  // Create link element for triggering download.
  const a = document.createElement('a');
  a.href = downloadObjectUrl;
  a.download = filename || 'download';

  // Create click handler for object cleanup.
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(downloadObjectUrl);
      a.removeEventListener('click', clickHandler);
    }, 0);
  };
  a.addEventListener('click', clickHandler, false);

  // Trigger download.
  a.click();
};

const fileUtil = { downloadBlob };

export default fileUtil;

import React, { useCallback, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuContextColumnHeader from './MenuContextColumnHeader';
import { setIn } from 'formik';
import useMenu from '../../hooks/useMenu';
import { TableCell, TableColumn } from 'shared/lib/types/views/procedures';
import { cloneDeep } from 'lodash';

const PLACEHOLDER_CLASSES = 'before:empty:content-[attr(placeholder)] empty:truncate not-empty:text-black';

interface FieldSetColumnHeaderProps {
  column: TableColumn;
  index: number;
  isRemoveColumnDisabled: boolean;
  onAddColumn: (index: number) => void;
  onRemoveColumn: (index: number) => void;
  path: string;
  onContentChange: (
    path: string,
    updatedColumn: TableColumn,
    columnIndexToClear?: number,
    resetValue?: TableCell
  ) => void;
  onColumnResize?: (delta: number) => void;
  onColumnResizeEnd: () => void;
  onMoveColumnLeft?: () => void;
  onMoveColumnRight?: () => void;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  isLast: boolean;
}

const FieldSetColumnHeader = React.memo(
  ({
    column,
    index,
    isRemoveColumnDisabled,
    onAddColumn,
    onRemoveColumn,
    path,
    onContentChange,
    onColumnResize,
    onColumnResizeEnd,
    onMoveColumnLeft,
    onMoveColumnRight,
    isReadOnly = false,
    isDisabled = false,
    isLast,
  }: FieldSetColumnHeaderProps) => {
    const { isMenuVisible, displayMenu } = useMenu();
    const mousePosition = useRef(0);

    // Workaround to allow clicking one menu to close another
    const menuParentRef = useRef<HTMLDivElement>(null);

    const addColumn = useCallback(() => {
      if (onAddColumn) {
        onAddColumn(index);
      }
    }, [index, onAddColumn]);

    const removeColumn = useCallback(() => {
      onRemoveColumn(index);
    }, [index, onRemoveColumn]);

    const isUnitsFieldDisabled = useMemo(() => !['text', 'number'].includes(column.input_type as string), [column]);

    const onUpdateColumn = useCallback(
      (
        columnUpdateObject: {
          [columnPath: string]: string | boolean | number | undefined;
        },
        columnIndexToClear: number | undefined = undefined,
        resetValue: TableCell = ''
      ) => {
        let updatedColumn = cloneDeep(column);
        Object.entries(columnUpdateObject).forEach(([columnPath, value]) => {
          updatedColumn = setIn(updatedColumn, columnPath, value);
        });
        onContentChange(path, updatedColumn, columnIndexToClear, resetValue);
      },
      [column, onContentChange, path]
    );

    const onMouseMove = useCallback(
      (event) => {
        event.preventDefault();

        const delta = mousePosition.current - event.clientX;

        onColumnResize && onColumnResize(delta);

        mousePosition.current = event.clientX;
      },
      [onColumnResize]
    );

    const onMouseUp = useCallback(() => {
      onColumnResizeEnd();
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    }, [onColumnResizeEnd, onMouseMove]);

    const onMouseDown = useCallback(
      (event) => {
        event.preventDefault();

        mousePosition.current = event.clientX;

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
      },
      [onMouseMove, onMouseUp]
    );

    return (
      <div className="flex flex-row w-full h-full relative">
        {column.column_type !== 'comment' && (
          <div
            contentEditable={!isDisabled}
            suppressContentEditableWarning={true}
            placeholder="Enter Column Name"
            aria-label="Column Header"
            className={`w-full h-full min-h-12 text-sm text-gray-500 font-medium pt-3.5 pb-3 pl-2 pr-6 whitespace-pre-line break-words focus:outline-none focus:ring-2 focus:ring-blue-600 ${PLACEHOLDER_CLASSES}`}
            onBlur={(event) => onUpdateColumn({ name: event.target.innerText })}
          >
            {column.name}
          </div>
        )}
        {column.column_type === 'comment' && (
          <div
            aria-label="Column Header"
            className={`flex items-center justify-center h-full w-full min-h-12 ${index === 0 && 'pl-6'} ${
              isLast && 'pr-6'
            }`}
          >
            <FontAwesomeIcon icon="comment" className="p-2 text-sm text-gray-600" />
          </div>
        )}
        {!isDisabled && (
          <div className="absolute flex flex-none top-0 bottom-0 right-0 w-8">
            <div ref={menuParentRef} className="w-full relative print:hidden">
              <button
                className={`min-w-min w-full h-full hover:text-gray-600 ${
                  isMenuVisible ? 'text-gray-600' : 'text-gray-400'
                }`}
                tabIndex={-1}
                aria-label="Column Menu"
                type="button"
                onClick={(e) => {
                  // Multi-menu click workaround
                  menuParentRef.current?.click();
                  e.nativeEvent.stopPropagation();
                  // End multi-menu click workaround
                  displayMenu();
                }}
              >
                <FontAwesomeIcon className="text-xl" icon="ellipsis-v" />
              </button>

              {isMenuVisible && (
                <div className="absolute top-10 -left-56">
                  <MenuContextColumnHeader
                    column={column}
                    isRemoveColumnDisabled={isRemoveColumnDisabled}
                    isUnitsFieldDisabled={isUnitsFieldDisabled}
                    onAddColumn={addColumn}
                    onRemoveColumn={removeColumn}
                    onUpdateColumn={onUpdateColumn}
                    columnIndex={index}
                    onMoveColumnLeft={onMoveColumnLeft}
                    onMoveColumnRight={onMoveColumnRight}
                    isReadOnly={isReadOnly}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {onColumnResize && (
          <div
            className="absolute w-1.5 -right-1 h-full cursor-col-resize hover:bg-blue-400"
            onMouseDown={onMouseDown}
          />
        )}
      </div>
    );
  }
);

export default FieldSetColumnHeader;

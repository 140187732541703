type isEdgeInViewportProps = {
  edge: number;
  top: number;
  bottom: number;
};

type isItemInViewportProps = {
  itemTop: number;
  itemBottom: number;
  containerTop: number;
  containerBottom: number;
};

export const isEdgeInViewport = ({
  edge,
  top,
  bottom,
}: isEdgeInViewportProps): boolean => {
  return top <= edge && edge <= bottom;
};

export const isItemInViewport = ({
  itemTop,
  itemBottom,
  containerTop,
  containerBottom,
}: isItemInViewportProps): boolean => {
  return (
    isEdgeInViewport({
      edge: itemTop,
      top: containerTop,
      bottom: containerBottom,
    }) ||
    isEdgeInViewport({
      edge: itemBottom,
      top: containerTop,
      bottom: containerBottom,
    }) ||
    (itemTop <= containerTop && itemBottom >= containerBottom)
  );
};

const virtualizationUtil = {
  isElementInViewport: (
    element: HTMLElement | null,
    threshold: number
  ): boolean => {
    if (!element) {
      return false;
    }

    const { top, bottom } = element.getBoundingClientRect();
    const { innerHeight } = window;

    const viewportTop = 0 - threshold;
    const viewportBottom = innerHeight + threshold;

    /**
     * Returns true if either or both the top and bottom edges are in the viewport,
     * or if the top edge is above the viewport and the bottom edge is below the viewport (element larger than the viewport);
     */
    return isItemInViewport({
      itemTop: top,
      itemBottom: bottom,
      containerTop: viewportTop,
      containerBottom: viewportBottom,
    });
  },
};

export default virtualizationUtil;

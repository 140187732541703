import React, { useMemo, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditDependency from './EditDependency';
import DependencySelect from './DependencySelect';
import procedureUtil from '../../lib/procedureUtil';
import { useSettings } from '../../contexts/SettingsContext';
import { useProcedureContext } from '../../contexts/ProcedureContext';
import stepDependencyUtil from '../../lib/stepDependencyUtil';
import cloneDeep from 'lodash.clonedeep';
import { Dependency } from 'shared/lib/types/views/procedures';

const addStepDependencyIcon = (
  <FontAwesomeIcon className="mx-4 self-center text-gray-600 cursor-pointer hover:text-gray-800" icon="plus-circle" />
);

// Returns an array of react-select type options objects.
const getOptions = (sections, getSetting, sectionId) => {
  const options: Array<{ type: string; label: string; value: string }> = [];

  sections.forEach((section, sectionIndex) => {
    if (section.id === sectionId) {
      return;
    }
    const sectionLabel = procedureUtil.displaySectionKey(sectionIndex, getSetting('display_sections_as', 'letters'));

    const sectionName = section.name;

    let label = sectionLabel;

    if (sectionName) {
      label = `${sectionLabel}. ${sectionName}`;
    }
    options.push({
      type: 'section',
      label,
      value: section.id,
    });
  });

  return options;
};

interface EditSectionDependenciesProps {
  dependencies: Array<Dependency>;
  sectionId: string;
  onDependenciesUpdated: (dependencies: Array<Dependency>) => void;
  errors;
}

const EditSectionDependencies = ({
  dependencies,
  sectionId,
  onDependenciesUpdated,
  errors,
}: EditSectionDependenciesProps) => {
  const { getAllSections } = useProcedureContext();
  const { getSetting } = useSettings();

  const dependencyOptions = useMemo(() => {
    const allSections = getAllSections();
    const allDependencyOptions = getOptions(allSections, getSetting, sectionId);
    const usedDependencyOptions = stepDependencyUtil.getAllDependentIds(dependencies);

    /**
     * Remaining options should not include options that have already been selected by user
     * or allow the same same section
     */
    const remainingDependencyOptions = allDependencyOptions.filter((depOpt) => {
      return !usedDependencyOptions.has(depOpt.value) && depOpt.value;
    });

    return {
      remaining: remainingDependencyOptions,
      all: allDependencyOptions,
    };
  }, [dependencies, getAllSections, getSetting, sectionId]);

  const onAddStepDependency = useCallback(
    (options) => {
      const newDependency = procedureUtil.newDependency();
      newDependency.dependent_ids = [options[0].value];
      const dependenciesCopy = cloneDeep(dependencies);
      dependenciesCopy.push(newDependency);
      onDependenciesUpdated(dependenciesCopy);
    },
    [dependencies, onDependenciesUpdated]
  );

  const onRemoveDependency = useCallback(
    (dependencyIndex) => {
      // Cannot remove last dependency.
      if (dependencies.length === 1) {
        return;
      }
      const dependenciesCopy = cloneDeep(dependencies);
      dependenciesCopy.splice(dependencyIndex, 1);

      onDependenciesUpdated(dependenciesCopy);
    },
    [dependencies, onDependenciesUpdated]
  );

  const onDependencyUpdated = useCallback(
    (updatedDependency, index) => {
      const dependenciesCopy = cloneDeep(dependencies);
      dependenciesCopy[index] = updatedDependency;

      onDependenciesUpdated(dependenciesCopy);
    },
    [dependencies, onDependenciesUpdated]
  );

  const isAddDependencyVisible = useMemo(() => {
    return !!(
      dependencies &&
      dependencies.length &&
      dependencies[dependencies.length - 1] &&
      dependencies[dependencies.length - 1].dependent_ids.length !== 0
    );
  }, [dependencies]);

  return (
    <div className="px-3 pb-2">
      <span className="field-title">Section Dependencies</span>
      <div className="flex items-start group">
        <div className="flex flex-wrap flex-grow gap-x-2 gap-y-2 items-start">
          {dependencies &&
            dependencies.map((dependency, dependencyIndex) => (
              <EditDependency
                key={`dependencies[${dependencyIndex}]`}
                dependencyIndex={dependencyIndex}
                dependency={dependency}
                onRemoveDependency={onRemoveDependency}
                dependencyOptions={dependencyOptions}
                onDependencyUpdated={onDependencyUpdated}
              />
            ))}

          {isAddDependencyVisible && (
            <DependencySelect
              options={dependencyOptions.remaining}
              placeholder={addStepDependencyIcon}
              opacity={0.3}
              hoverOpacity={0.6}
              value={[]}
              onChange={onAddStepDependency}
              ariaLabel="Add Section Dependency"
            />
          )}
        </div>
      </div>
      {errors && <div className="text-red-700 text-sm normal-case font-normal">{errors}</div>}
    </div>
  );
};

export default EditSectionDependencies;

import { add, multiply, subtract } from 'mathjs';

const PRECISION = 4;

/**
 * Provides math operations that calculate using floating point precision appropriate for inventory quantities
 */
const quantitiesUtil = {
  /**
   * @param quantity
   * @returns quantity with capped number of significant digits appropriate for inventory quantities
   */
  toDefaultPrecision: (quantity: number) => +quantity.toFixed(PRECISION),

  /**
   * @param x - First value to add
   * @param y - Second value to add
   * @returns Sum of x and y with capped number of significant digits appropriate for inventory quantities
   */
  add: (x: number, y: number) => quantitiesUtil.toDefaultPrecision(add(x, y)),

  /**
   * @param a - Initial value
   * @param b - Value to subtract from x
   * @returns Subtraction of x and y with capped number of significant digits appropriate for inventory quantities
   */
  subtract: (a: number, b: number) =>
    quantitiesUtil.toDefaultPrecision(subtract(a, b)),

  /**
   * @param a — The first value to multiply
   * @param b — The second value to multiply
   * @returns Multiplication of x and y with capped number of significant digits appropriate for inventory quantities
   */
  multiply: (a: number, b: number) =>
    quantitiesUtil.toDefaultPrecision(multiply(a, b)),
};

export default quantitiesUtil;

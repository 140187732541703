import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckboxDisplay from './Display/CheckboxDisplay';
import ExternalItemDisplay from './Display/ExternalItemDisplay';
import ExternalSearchDisplay from './Display/ExternalSearchDisplay';
import AttachmentDisplay from './Display/AttachmentDisplay';
import TextDisplay from './Display/TextDisplay';
import TimestampDisplay from './Display/TimestampDisplay';
import CustomListDisplay from './Display/CustomListDisplay';
import SettingsListDisplay from './Display/SettingsListDisplay';
import RadioGroupDisplay from './Display/RadioGroupDisplay';
import { generateHiddenClassString } from '../../lib/styles';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import useProcedureAdapter from '../../hooks/useProcedureAdapter';
import {
  FieldInputBlock,
  FieldInputNumberBlock,
  Run,
  RunFieldInputNumberBlock,
  V2Variable,
} from 'shared/lib/types/views/procedures';
import { BlockProvider } from '../../contexts/BlockContext';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useSelector } from 'react-redux';
import { selectRunStep } from '../../contexts/runsSlice';
import NumberFieldInputDisplay from './FieldInput/NumberFieldInputDisplay';
import ReferenceToken from './Display/ReferenceToken';
import { getContentBlock } from 'shared/lib/expressionUtil';
export const SUPPORTED_INPUT_DISPLAYS = {
  checkbox: CheckboxDisplay,
  text: TextDisplay,
  number: ({
    block,
    referenceId,
    referenceFieldIndex,
  }: {
    block: FieldInputNumberBlock;
    referenceId?: string;
    referenceFieldIndex?: number;
  }) => {
    return (
      <NumberFieldInputDisplay
        block={block}
        recorded={(block as RunFieldInputNumberBlock).recorded}
        label={
          <ReferenceToken
            originalReferencedContentId={referenceId ?? ''}
            originalToken={{ field_index: referenceFieldIndex, value: '', type: 'reference' }}
          />
        }
        isReference={true}
      />
    );
  },
  timestamp: TimestampDisplay,
  select: CustomListDisplay,
  list: SettingsListDisplay,
  external_item: ExternalItemDisplay,
  external_search: ExternalSearchDisplay,
  multiple_choice: RadioGroupDisplay,
  attachment: AttachmentDisplay,
};

interface DisplayBlockProps {
  block: FieldInputBlock | V2Variable;
  referenceId: string;
  referenceSubtype?: string;
  referenceFieldIndex?: number;
}
type DisplayBlockType = ({ block, referenceId, referenceSubtype }: DisplayBlockProps) => JSX.Element;

interface ReferenceEditSelectProps {
  originalReferencedContentId: string;
  originalReferencedSubtype?: string;
  isHidden: boolean;
  blockLabel: string;
  isValid?: boolean;
  originalReferencedFieldIndex?: number;
  runId?: string;
}

export interface RunStateType {
  runs: Array<Run>;
}

const ReferenceBlock = ({
  originalReferencedContentId,
  originalReferencedSubtype,
  isHidden,
  blockLabel,
  runId,
  originalReferencedFieldIndex,
  isValid = true,
}: ReferenceEditSelectProps) => {
  const { getReferencedContentContext } = useProcedureAdapter();
  const { currentTeamId } = useDatabaseServices();
  const referencedContentContext = getReferencedContentContext(originalReferencedContentId);
  const liveRunStep = useSelector((state: RunStateType) =>
    runId
      ? selectRunStep(
          state,
          currentTeamId,
          runId,
          referencedContentContext?.referencedFromSectionId || '',
          referencedContentContext?.referencedFromStepId || ''
        )
      : null
  );

  const referencedContent = useMemo(() => {
    let content;
    if (liveRunStep) {
      content = liveRunStep.content.find((content) => content.id === referencedContentContext?.referencedContent?.id);
    } else {
      content = referencedContentContext?.referencedContent;
    }

    content = getContentBlock(content, originalReferencedFieldIndex);

    return content;
  }, [referencedContentContext, liveRunStep, originalReferencedFieldIndex]);

  // Casting is needed to allow for using a dispatch table to conditionally render a component.
  const DisplayBlock = useMemo(
    () => referencedContent && SUPPORTED_INPUT_DISPLAYS[referencedContent.inputType],
    [referencedContent]
  ) as DisplayBlockType;

  return (
    <BlockProvider value={{ isValid }}>
      <tr>
        <td />
        <td colSpan={2}>
          <div className={generateHiddenClassString('', isHidden)}>
            {referencedContent?.type?.toLowerCase() === 'input' && DisplayBlock && (
              <div className="flex flex-row">
                <Spacer />
                <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={true} />
                <div className="flex flex-wrap items-start w-full my-1 gap-x-2" aria-label="Input Reference">
                  <DisplayBlock
                    block={referencedContent}
                    referenceId={originalReferencedContentId}
                    referenceSubtype={originalReferencedSubtype}
                    referenceFieldIndex={originalReferencedFieldIndex}
                  />
                </div>
              </div>
            )}
            {(!referencedContent || !DisplayBlock) && (
              <div className="flex">
                <span className="mt-2 mr-8 py-2 w-full">
                  <FontAwesomeIcon className="mr-2 text-red-500" icon="exclamation-circle" />
                  Invalid Referenced Input Field
                </span>
              </div>
            )}
          </div>
        </td>
      </tr>
    </BlockProvider>
  );
};

export default ReferenceBlock;

import isNil from 'lodash.isnil';
import { dinero, multiply, add, toDecimal, Dinero } from 'dinero.js';
import { USD } from '@dinero.js/currencies';
import { OrderItem } from 'shared/lib/types/postgres/manufacturing/orders';

export const getFormattedDinero = (d?: Dinero<number>): string => {
  if (!d) {
    return '';
  }
  return `$${toDecimal(d)}`;
};

export const getFormattedAmount = (amount: number): string => {
  try {
    const d = dinero({
      amount,
      currency: USD,
    });
    return getFormattedDinero(d);
  } catch {
    if (amount === null) {
      return '';
    }
    return amount.toString();
  }
};

export const getLineTotal = (item: OrderItem): Dinero<number> | undefined => {
  if (isNil(item.unit_amount) || isNil(item.quantity)) {
    return;
  }
  const unitAmount = dinero({
    amount: item.unit_amount,
    currency: USD,
  });
  const total = multiply(unitAmount, item.quantity);
  return total;
};

export const getTotal = (items: OrderItem[]): Dinero<number> => {
  let total;
  for (const item of items) {
    const lineTotal = getLineTotal(item);
    if (total && lineTotal) {
      total = add(total, lineTotal);
    } else {
      total = lineTotal;
    }
  }
  return total ? total : dinero({ amount: 0, currency: USD });
};

export const parseInputAmount = (value: string): number | null => {
  // Parse value as decimal amount.
  const sanitizedInput = value
    .replace(/,/g, '')
    .replace(/ /g, '')
    .replace(/^\$/, '');
  const amountRegex = /^\d*.?\d+$/;
  const results = sanitizedInput.match(amountRegex);
  if (results === null) {
    return null;
  }
  const [matched] = results;
  const amountDecimal = Number(matched);
  if (isNaN(amountDecimal)) {
    return null;
  }

  // Convert to USD cents amount.
  const amountInt = Math.round(amountDecimal * 100);
  return amountInt;
};

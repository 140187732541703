import {
  Procedure,
  ProcedureDiff,
  V2Variable,
} from 'shared/lib/types/views/procedures';
import procedureVariableUtil from './procedureVariableUtil';
import referenceUtil from './referenceUtil';

const MAX_VARIABLE_NAME_LENGTH = 20;
export const SUPPORTED_REFERENCES = ['text', 'number'];

type ContentOptions = {
  defaultTitle: string;
  maxVariableLength: number;
  isPreview: boolean;
  location: string;
};

const printUtil = {
  _getContent: (
    procedure: Procedure | ProcedureDiff | null,
    options: ContentOptions
  ): string => {
    if (!procedure) {
      return options.defaultTitle;
    }

    const settings =
      procedure?.settings?.print_settings &&
      procedure?.settings?.print_settings[options.location];
    if (!settings) {
      return options.defaultTitle ?? '';
    }

    let contentText = settings?.text ?? '';
    if (settings?.variable_id) {
      const variable = procedureVariableUtil.getVariable(
        procedure,
        settings?.variable_id
      ) as V2Variable | null;
      const includeVariable =
        variable &&
        referenceUtil.isReferenceEnabled(variable, SUPPORTED_REFERENCES) &&
        ('value' in variable || options.isPreview);
      if (includeVariable) {
        if (contentText) {
          contentText = `${contentText} | `;
        }
        let variableName = variable.name;
        if (variableName.length > options.maxVariableLength) {
          variableName = `${variableName.substring(
            0,
            options.maxVariableLength
          )}...`;
        }
        const variableValue = 'value' in variable ? variable.value : '{ }';
        contentText = `${contentText}${variableName}: ${variableValue}`;
      }
    }
    return contentText || options.defaultTitle;
  },

  getPrintHeader: (
    procedure: Procedure | null,
    defaultTitle = '',
    maxVariableLength = MAX_VARIABLE_NAME_LENGTH
  ): string => {
    const options = {
      defaultTitle,
      maxVariableLength,
      isPreview: false,
      location: 'header',
    };
    return printUtil._getContent(procedure, options);
  },

  // Nearly identical to getPrintHeader, except variable name is always visible in preview
  getPrintHeaderPreview: (
    procedure: Procedure | ProcedureDiff | null,
    defaultTitle = '',
    maxVariableLength = MAX_VARIABLE_NAME_LENGTH
  ): string => {
    const options = {
      defaultTitle,
      maxVariableLength,
      isPreview: true,
      location: 'header',
    };
    return printUtil._getContent(procedure, options);
  },

  getPrintFooter: (
    procedure: Procedure | null,
    defaultTitle = '',
    maxVariableLength = MAX_VARIABLE_NAME_LENGTH
  ): string => {
    const options = {
      defaultTitle,
      maxVariableLength,
      isPreview: false,
      location: 'footer',
    };
    return printUtil._getContent(procedure, options);
  },

  // Nearly identical to getPrintFooter, except variable name is always visible in preview
  getPrintFooterPreview: (
    procedure: Procedure | ProcedureDiff | null,
    defaultTitle = '',
    maxVariableLength = MAX_VARIABLE_NAME_LENGTH
  ): string => {
    const options = {
      defaultTitle,
      maxVariableLength,
      isPreview: true,
      location: 'footer',
    };
    return printUtil._getContent(procedure, options);
  },

  // Update print settings variable references (in place)
  updateVariableReferences: (procedure: Procedure): void => {
    // Print header
    if (procedure.settings?.print_settings?.header?.variable_id) {
      if (!procedure.variables) {
        delete procedure.settings.print_settings.header.variable_id;
        return;
      }
      // remove variable from print settings if the reference is invalid or input type is not supported
      const matchingVariable = procedureVariableUtil.getVariable(
        procedure,
        procedure.settings.print_settings.header.variable_id
      ) as V2Variable | null;
      if (
        !matchingVariable ||
        (matchingVariable &&
          !referenceUtil.isReferenceEnabled(
            matchingVariable,
            SUPPORTED_REFERENCES
          ))
      ) {
        delete procedure.settings.print_settings.header.variable_id;
      }
    }

    // Same as above, but for footer
    if (procedure.settings?.print_settings?.footer?.variable_id) {
      if (!procedure.variables) {
        delete procedure.settings.print_settings.footer.variable_id;
        return;
      }
      // remove variable from print settings if the reference is invalid or input type is not supported
      const matchingVariable = procedureVariableUtil.getVariable(
        procedure,
        procedure.settings.print_settings.footer.variable_id
      ) as V2Variable | null;
      if (
        !matchingVariable ||
        (matchingVariable &&
          !referenceUtil.isReferenceEnabled(
            matchingVariable,
            SUPPORTED_REFERENCES
          ))
      ) {
        delete procedure.settings.print_settings.footer.variable_id;
      }
    }
  },
};

export default printUtil;

import { Transaction } from '@elastic/apm-rum';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import { PROCEDURE_STATE_IN_REVIEW, getPendingProcedureIndex } from 'shared/lib/procedureUtil';
import {
  compareReviewActions,
  getHasReleasePermission,
  getHasReviewerPermission,
  getHaveAllReviewerGroupsApproved,
} from 'shared/lib/reviewUtil';
import { ProcedureType } from 'shared/lib/types/couch/procedures';
import { Draft, ProcedureDiff, Release, Step } from 'shared/lib/types/views/procedures';
import RunBatchProcedureModal from '../components/BatchSteps/RunBatchProcedureModal';
import Button from '../components/Button';
import ButtonsProcedure from '../components/ButtonsProcedure';
import Error from '../components/Error';
import NotFound from '../components/NotFound';
import PageSidebar from '../components/PageSidebar';
import ProcedureDetails from '../components/ProcedureDetails';
import ProcedureVersionList from '../components/ProcedureVersionList';
import ReleaseNote from '../components/ReleaseNote';
import ReviewAction from '../components/Review/ReviewAction';
import ReviewProcedureContent from '../components/Review/ReviewProcedureContent';
import Reviewers from '../components/Reviewers/Reviewers';
import ReviewStickyHeader, { REVIEW_STICKY_HEADER_HEIGHT_REM } from '../components/ReviewStickyHeader';
import ProcedureFlowChart from '../components/StepConditionals/ProcedureFlowChart';
import { useAuth } from '../contexts/AuthContext';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import { ReviewContextProvider } from '../contexts/ReviewContext';
import { useSettings } from '../contexts/SettingsContext';
import { useUserInfo } from '../contexts/UserContext';
import { SnippetUploadError } from '../errors/errors';
import useDiff from '../hooks/useDiff';
import useExpandCollapse from '../hooks/useExpandCollapse';
import useLocationParams from '../hooks/useLocationParams';
import useProcedureVersions from '../hooks/useProcedureVersions';
import useSaveStepSnippet from '../hooks/useSaveStepSnippet';
import apm from '../lib/apm';
import { PERM } from '../lib/auth';
import { isProcedureWithBatchSteps } from '../lib/batchSteps';
import diffUtil from '../lib/diffUtil';
import externalDataUtil from '../lib/externalDataUtil';
import { BASIC_REFRESH_TRY_AGAIN_MESSAGE } from '../lib/messages';
import { procedurePendingPath, procedureReviewPath, procedureViewPath } from '../lib/pathUtil';
import procedureUtil from '../lib/procedureUtil';
import revisionsUtil from '../lib/revisions';
import snippetUtil from '../lib/snippetUtil';
import Run, { PREVIEW_MODE } from './Run';

const ON_RELEASE_INVALID_PERMISSIONS = 'You do not have permission to release procedures.';
const TRUNCATED_ACTIONS_NUM = 10;

const Review = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const location = useLocation();
  const { hash } = useLocationParams(location);
  const { path } = useRouteMatch();
  const isMounted = useRef(true);
  const transactionRef = useRef<Transaction | null | undefined>(null);
  const [docs, setDocs] = useState<{ released: Release | null; pending: Draft | null; loading: boolean }>({
    released: null,
    pending: null,
    loading: true,
  });
  const { auth } = useAuth();
  const { userInfo } = useUserInfo();
  const { mixpanel } = useMixpanel();
  const { currentTeamId, services } = useDatabaseServices();
  const { updateStepSnippet, saveStepSnippet } = useSaveStepSnippet();
  const [showVersionHistory, setShowVersionHistory] = useState(
    history.location.state && history.location.state['versions']
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [onReviewError, setOnReviewError] = useState('');
  // Start with diff not shown to allow time for diff to be calculated.
  const [showDiff, setShowDiff] = useState(false);
  const [procedureDiff, setProcedureDiff] = useState<ProcedureDiff | null>(null);
  const [firstDiffOpened, setFirstDiffOpened] = useState(false);
  const [showAllActions, setShowAllActions] = useState(false);
  const [showBatchRunModal, setShowBatchRunModal] = useState(false);

  const {
    isCollapsedMap,
    setIsCollapsedMap,
    setAllExpanded,
    setIsCollapsed,
    areAllStepsInSectionExpanded,
    setAllStepsInSectionExpanded,
  } = useExpandCollapse();

  const [scrollToAction, setScrollToAction] = useState({
    id: hash,
    smooth: false,
  });
  const scrollToRefs = useRef({});

  const { versions } = useProcedureVersions({ id });
  const { users, operatorRoles, getSetting, isFullDiffReviewDisabled } = useSettings();

  useEffect(() => {
    transactionRef.current = apm.startTransaction('review.load', 'custom');
  }, []);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  // Load procedure and observe procedure changes.
  useEffect(() => {
    if (!id || !services.procedures) {
      return;
    }
    const pendingId = getPendingProcedureIndex(id);

    const refreshProcedure = async () => {
      const loadingProcedures = [services.procedures.getProcedure(id), services.procedures.getProcedure(pendingId)];
      const [releasedPromise, pendingPromise] = await Promise.allSettled(loadingProcedures);
      if (!isMounted.current) {
        return;
      }
      const released = releasedPromise.status === 'fulfilled' && releasedPromise.value ? releasedPromise.value : null;
      let pending = pendingPromise.status === 'fulfilled' && pendingPromise.value ? pendingPromise.value : null;

      try {
        if (pending) {
          const externalItems = await services.externalData.getAllExternalItems(pending);
          pending = externalDataUtil.updateProcedureWithItems(pending, externalItems);
        }
      } catch {
        // Ignore errors and fall back to using procedure without dynamic data.
      }

      setDocs({
        released,
        pending,
        loading: false,
      });
    };
    const procedureObserver = services.procedures.onProcedureChanged(id, refreshProcedure);
    const pendingObserver = services.procedures.onProcedureChanged(pendingId, refreshProcedure);

    refreshProcedure().catch((err) => apm.captureError(err));

    return () => {
      procedureObserver.cancel();
      pendingObserver.cancel();
    };
  }, [id, services.procedures, services.externalData]);

  /**
   * Since diff calculation can take some time, calculate it asynchronously
   * on first load.
   *
   * If a diff already has been calculated (and not cleared) do nothing.
   *
   * Only re-set the diff if a diff-able value changes (i.e. do not reset the
   * diff if the editedAt field changes).
   */
  useEffect(() => {
    // Move the diff calculation to the event loop, to unblock rendering.
    setTimeout(() => {
      if (!docs.released || !docs.pending) {
        return;
      }
      /*
       * TODO: Optimize this so that the diff is only recalculated if there is
       *  a relevant change.
       */
      const newDiff = diffUtil.getProcedureDiff(
        docs.released,
        docs.pending,
        isFullDiffReviewDisabled && isFullDiffReviewDisabled()
      );
      // Set the diff only if diffable fields have changed.
      if (diffUtil.areDiffableFieldsChanged(procedureDiff, newDiff)) {
        setProcedureDiff(newDiff);
      }
    }, 0);
  }, [docs.pending, docs.released, isFullDiffReviewDisabled, procedureDiff]);

  const review = useMemo(() => {
    if (docs.pending && docs.pending.state === PROCEDURE_STATE_IN_REVIEW) {
      // don't show steps added from a run not accepted to the procedure
      return revisionsUtil.getProcedureWithoutRunSteps(docs.pending);
    }
    return null;
  }, [docs.pending]);

  const isDiffShown = useMemo(() => showDiff && Boolean(procedureDiff), [showDiff, procedureDiff]);
  const reviewDisplay = useMemo(() => {
    if (docs.pending && docs.pending.state === PROCEDURE_STATE_IN_REVIEW) {
      const reviewDoc = isDiffShown ? procedureDiff : docs.pending;
      if (isDiffShown && procedureDiff && !firstDiffOpened) {
        const collapsedMap = {};
        procedureDiff.sections.forEach((section) => {
          collapsedMap[section.id] =
            !section.diff_change_state || section.diff_change_state === ARRAY_CHANGE_SYMBOLS.UNCHANGED;
          section.steps.forEach(
            (step) =>
              (collapsedMap[step.id] =
                (!step.diff_change_state || step.diff_change_state === ARRAY_CHANGE_SYMBOLS.UNCHANGED) &&
                section.diff_change_state !== ARRAY_CHANGE_SYMBOLS.ADDED &&
                section.diff_change_state !== ARRAY_CHANGE_SYMBOLS.REMOVED)
          );
        });
        if (Object.keys(collapsedMap).length > 0) {
          setIsCollapsedMap(collapsedMap);
        }
        setFirstDiffOpened(true);
      } else if (
        !isDiffShown &&
        reviewDoc &&
        reviewDoc.sections.length >= 5 &&
        Object.keys(isCollapsedMap).length === 0
      ) {
        const collapsedMap = {};
        reviewDoc.sections.forEach((section) => {
          collapsedMap[section.id] = true;
        });
        setIsCollapsedMap(collapsedMap);
      }
      // don't show steps added from a run not accepted to the procedure
      return revisionsUtil.getProcedureWithoutRunSteps(reviewDoc);
    }
    return null;
  }, [docs.pending, isDiffShown, procedureDiff, firstDiffOpened, isCollapsedMap, setIsCollapsedMap]);

  useEffect(() => {
    // Wait for the render after the displayed review object loads to end the load timer
    if (!transactionRef.current || !reviewDisplay) {
      return;
    }

    transactionRef.current.end();
    transactionRef.current = null;
  }, [reviewDisplay]);

  // Used for TCM uploading of snippets
  const uploadTestSnippets = useCallback(
    async (procedure) => {
      const savedSnippetIds: Array<string> = [];
      const updatedSnippets: Array<{
        values: { name: string; description: string };
        original: Step & { step?: Step };
        step: Step;
      }> = [];
      try {
        const existingSnippets = await services.settings.listSnippets({ isTestSnippet: true });

        for (let sectionIndex = 0; sectionIndex < procedure.sections.length; sectionIndex++) {
          const section = procedure.sections[sectionIndex];

          if (!section.snippet_id) {
            for (let stepIndex = 0; stepIndex < section.steps.length; stepIndex++) {
              const step = section.steps[stepIndex];

              if (!step.snippet_id && step.content.some((block) => block.type === 'test_cases')) {
                const snippetName = `${procedure.code}-${section.name}-${step.name}`;
                const matchingSnippet = existingSnippets.find((snippet) => snippet.name === snippetName);

                const sanatizedSnippet = snippetUtil.sanatizeSnippet(step);

                const values = {
                  name: snippetName,
                  description: matchingSnippet ? matchingSnippet.description : '',
                };

                if (matchingSnippet) {
                  await updateStepSnippet(values, sanatizedSnippet, matchingSnippet, procedure);
                  updatedSnippets.push({ values, original: matchingSnippet, step });
                } else {
                  const id = await saveStepSnippet(
                    sectionIndex + 1,
                    stepIndex + 1,
                    values,
                    sanatizedSnippet,
                    procedure
                  );
                  savedSnippetIds.push(id);
                }
              }
            }
          }
        }
      } catch (error) {
        // Reverting updates to snippets
        for (const snippetInfo of updatedSnippets) {
          updateStepSnippet(snippetInfo.values, snippetInfo.original, snippetInfo.step, procedure, true).catch(
            apm.captureError
          );
        }

        // Deleting newly created snippets
        for (const snippetInfo of savedSnippetIds) {
          services.settings.deleteSnippet(snippetInfo);
        }
        throw new SnippetUploadError(error.message);
      }
    },
    [services.settings, saveStepSnippet, updateStepSnippet]
  );

  const mixpanelTrack = useCallback(
    (name, options) => {
      if (mixpanel && name) {
        mixpanel.track(name, options);
      }
    },
    [mixpanel]
  );

  const userOperatorRolesSet = useMemo(() => {
    const userId = userInfo.session.user_id;
    const operatorRoles = users?.users[userId].operator_roles;

    return new Set(operatorRoles);
  }, [users, userInfo]);

  const hasReleasePermission = useMemo(() => {
    const operators = operatorRoles?.operators;
    return getHasReleasePermission(operators, userOperatorRolesSet);
  }, [operatorRoles, userOperatorRolesSet]);

  const acknowledgeCommentsBeforeRelease = useMemo(() => {
    return getSetting('disable_procedure_release_redlines_acknowledged', false);
  }, [getSetting]);

  const hasReviewCommentsAcknowledged = useMemo(() => {
    if (docs.loading) {
      return false;
    }
    if (docs.pending && docs.pending.state === PROCEDURE_STATE_IN_REVIEW) {
      if (revisionsUtil.hasOutstandingReviewComments(docs.pending)) {
        return false;
      }
    }
    return true;
  }, [docs.loading, docs.pending]);

  const canRelease = useMemo(() => {
    if (docs.loading) {
      return false;
    }

    // When releasing a procedure, we may not have an already released version, but we will always have a pending one
    const releasedPerms = docs.released ? auth.hasPermission(PERM.PROCEDURES_EDIT, docs.released.project_id) : true;
    const pendingPerms = docs.pending && auth.hasPermission(PERM.PROCEDURES_EDIT, docs.pending.project_id);
    return releasedPerms && pendingPerms;
  }, [auth, docs]);

  const isApproved = useMemo(() => {
    if (!review) {
      return false;
    }
    return getHaveAllReviewerGroupsApproved(review.reviewer_groups);
  }, [review]);

  const onRelease = useCallback(async () => {
    try {
      if (!services.procedures) {
        return;
      }
      if (!hasReleasePermission) {
        setOnReviewError(ON_RELEASE_INVALID_PERMISSIONS);
        return;
      }

      const procedureId = procedureUtil.getProcedureId(review);
      setIsSubmitting(true);
      // Upload test plan steps as snippets
      if (review.procedure_type === ProcedureType.TestPlan) {
        await uploadTestSnippets(review);
      }

      await services.procedures
        .release(procedureId)
        .then(() => {
          mixpanelTrack('Procedure Released', { Source: 'Review Screen' });

          // Show released procedure.
          const procedureId = procedureUtil.getProcedureId(review);
          history.push(procedureViewPath(currentTeamId, procedureId));
        })
        .catch(() => {
          setIsSubmitting(false);
          setOnReviewError(BASIC_REFRESH_TRY_AGAIN_MESSAGE);
        });
    } catch (error) {
      setIsSubmitting(false);
      setOnReviewError(BASIC_REFRESH_TRY_AGAIN_MESSAGE);
    }
  }, [services.procedures, mixpanelTrack, review, history, hasReleasePermission, currentTeamId, uploadTestSnippets]);

  const projectId = useMemo(() => (docs?.released?.project_id || docs?.pending?.project_id) ?? null, [docs]);

  const updateReleaseNote = useCallback(
    (releaseNoteText) => {
      if (!services.procedures || !review) {
        return null;
      }
      mixpanelTrack('Release Note Saved', { Source: 'Review Screen' });
      return services.procedures.updateReleaseNote(review._id, releaseNoteText);
    },
    [review, services.procedures, mixpanelTrack]
  );

  const saveReviewComment = useCallback(
    (comment) => {
      if (!services.procedures || !review) {
        return null;
      }
      mixpanelTrack('Review Comment Saved', { level: comment.parent_id ? 'Child' : 'Parent' });
      const transaction = apm.startTransaction('review.comment', 'custom');
      return services.procedures.addComment(review._id, comment).finally(() => transaction?.end());
    },
    [review, services.procedures, mixpanelTrack]
  );

  const resolveReviewComment = useCallback(
    (commentId) => {
      if (!services.procedures || !review) {
        return null;
      }
      mixpanelTrack('Review Comment Resolved', { Source: 'Review Screen' });
      return services.procedures.resolveComment(review._id, commentId);
    },
    [review, services.procedures, mixpanelTrack]
  );

  const unResolveReviewComment = useCallback(
    (commentId) => {
      if (!services.procedures || !review) {
        return null;
      }
      mixpanelTrack('Review Comment Unresolved', { Source: 'Review Screen' });
      return services.procedures.unresolveComment(review._id, commentId);
    },
    [review, services.procedures, mixpanelTrack]
  );

  const isArchived = useMemo(() => {
    return docs.released && docs.released.archived;
  }, [docs.released]);

  const canEditReleaseNote = useMemo(() => {
    return auth.hasPermission(PERM.PROCEDURES_EDIT, projectId) && !isArchived;
  }, [auth, projectId, isArchived]);

  const onShowVersionHistory = useCallback(() => {
    setShowVersionHistory(true);
  }, []);

  const onReviewerApprove = useCallback(
    (reviewerGroupId, reviewerId, operatorRole) => {
      return services.procedures.approve(
        review._id,
        review.procedure_rev_num,
        reviewerGroupId,
        reviewerId,
        operatorRole
      );
    },
    [review?._id, review?.procedure_rev_num, services.procedures]
  );

  const onRevokeApproval = useCallback(
    (reviewerGroupId, reviewerId) => {
      return services.procedures.revokeApproval({
        procedureId: review._id,
        procedureRevNum: review.procedure_rev_num,
        reviewerGroupId,
        reviewerId,
      });
    },
    [review, services.procedures]
  );

  const onNotifyReviewers = useCallback(() => {
    if (!services.procedures) {
      return;
    }

    return services.procedures.sendNotificationsToReviewers(id);
  }, [id, services.procedures]);

  const hasReviewPermission = useCallback(
    (reviewerId) => getHasReviewerPermission(reviewerId, userInfo.session.user_id, userOperatorRolesSet),
    [userInfo, userOperatorRolesSet]
  );

  // Called when element refs are changed, will scroll to element if element exists and scrollToId matches element id.
  const onScrollToDiffRefChanged = useCallback(
    (id, element) => {
      scrollToRefs.current[id] = element;

      if (element && scrollToAction.id && scrollToAction.id === id) {
        // Browser default is 'auto'.
        element.scrollIntoView({ behavior: scrollToAction.smooth ? 'smooth' : 'auto' });
        setScrollToAction({
          id: null,
          smooth: false,
        });
      }
    },
    [scrollToRefs, scrollToAction]
  );

  const { scrollToDiff } = useDiff({
    onScrollToDiffRefChanged,
    setIsCollapsed,
    setScrollToAction,
  });

  const onReview = useCallback(() => {
    history.push(procedureReviewPath(currentTeamId, id));
  }, [currentTeamId, history, id]);

  const switchToPreview = useCallback(
    (batchSize?: number) => {
      const batchSizeParam = batchSize ? `?batchSize=${batchSize}` : '';
      history.push(`${procedureReviewPath(currentTeamId, id)}/run-preview${batchSizeParam}`);
    },
    [currentTeamId, history, id]
  );

  const onStartBatchPreview = useCallback(
    (batchSize: number) => {
      switchToPreview(batchSize);
      setShowBatchRunModal(false);
    },
    [switchToPreview]
  );

  const onCancelBatchPreview = useCallback(() => setShowBatchRunModal(false), []);

  const onPreview = useCallback(() => {
    if (docs.pending && isProcedureWithBatchSteps(docs.pending)) {
      setShowBatchRunModal(true);
      return;
    }

    switchToPreview();
  }, [docs.pending, switchToPreview]);

  const onFlowView = useCallback(() => {
    history.push(`${procedureReviewPath(currentTeamId, id)}/flow-view`);
  }, [currentTeamId, history, id]);

  const sectionIds = React.useMemo(
    () => (reviewDisplay ? reviewDisplay.sections.map((section) => section.id) : []),
    [reviewDisplay]
  );
  const headerIds = React.useMemo(
    () => (reviewDisplay && reviewDisplay.headers ? reviewDisplay.headers.map((header) => header.id) : []),
    [reviewDisplay]
  );

  const onExpandAll = useCallback(() => {
    setAllExpanded(true, sectionIds, headerIds);
  }, [setAllExpanded, sectionIds, headerIds]);

  const onCollapseAll = useCallback(() => {
    setAllExpanded(false, sectionIds, headerIds);
  }, [setAllExpanded, sectionIds, headerIds]);

  const reviewActionsSorted = useMemo(() => {
    return (review?.actions ?? []).sort(compareReviewActions);
  }, [review?.actions]);

  const reviewActionsSortedDisplay = useMemo(() => {
    return showAllActions ? reviewActionsSorted : reviewActionsSorted.slice(-TRUNCATED_ACTIONS_NUM);
  }, [reviewActionsSorted, showAllActions]);

  if (docs.loading || isSubmitting) {
    return null;
  }

  if (!docs.released && !docs.pending) {
    return <NotFound />;
  }

  if (!review) {
    return (
      <div className="px-4 lg:px-8 py-4 mt-16">
        <div className="mt-3">
          <span className="italic">
            {docs.released ? 'Procedure is now released.' : 'Procedure is currently being edited.'}
          </span>
          <Link
            className="italic underline text-blue-600"
            to={docs.released ? procedureViewPath(currentTeamId, id) : procedurePendingPath(currentTeamId, id)}
          >
            Go to procedure.
          </Link>
        </div>
      </div>
    );
  }

  return (
    <ReviewContextProvider
      released={docs.released}
      review={reviewDisplay}
      onScrollToDiffRefChanged={onScrollToDiffRefChanged}
      isDiffShown={isDiffShown}
    >
      <Switch>
        <Route exact path={[path, `${path}/flow-view`]}>
          {/* Sets the document title */}
          <Helmet>
            <title>{`Review · ${review.name}`}</title>
          </Helmet>
          {showBatchRunModal && <RunBatchProcedureModal onRun={onStartBatchPreview} onCancel={onCancelBatchPreview} />}
          <ReviewStickyHeader
            procedureDiff={procedureDiff}
            review={review}
            release={docs.released}
            showDiff={showDiff}
            setShowDiff={setShowDiff}
            isApproved={isApproved}
            scrollToDiff={scrollToDiff}
            onReview={onReview}
            onPreview={onPreview}
            onFlowView={onFlowView}
            onExpandAll={onExpandAll}
            onCollapseAll={onCollapseAll}
          />
          <Switch>
            <Route exact path={path}>
              <div data-testid="reviewBody" className="mt-8 flex flex-grow">
                <div className={`flex-1 pr-4 pl-20 lg:pr-8 py-4 print:m-0 print:p-0 ${showVersionHistory && 'mr-72'}`}>
                  <div className="flex gap-x-2">
                    <ProcedureDetails procedure={reviewDisplay} scrollToBufferRem={REVIEW_STICKY_HEADER_HEIGHT_REM} />

                    <ButtonsProcedure
                      procedure={review} // Pass the actual review instead of the diff so that the buttons will work properly.
                      isRunDisabled={true}
                      isDuplicateDisabled={true}
                      isArchiveDisabled={true}
                      isRunHistoryDisabled={true}
                      hideRunActions={Boolean(isArchived)}
                      hideEditActions={Boolean(isArchived)}
                      onShowVersionHistory={onShowVersionHistory}
                      projectId={projectId}
                    />
                  </div>

                  <div className="grid grid-cols-4 gap-8 justify-items-center">
                    <div className={`${isArchived ? 'col-span-4' : 'col-span-4 print:col-span-4'} w-full`}>
                      {/* Don't allow additions/edits to review comments on archived procedures */}
                      {!isArchived && review.reviewer_groups && (
                        <Reviewers
                          reviewName={review.review_type?.name}
                          reviewerGroups={review.reviewer_groups}
                          onReviewerApprove={onReviewerApprove}
                          onRevokeApproval={onRevokeApproval}
                          onNotifyReviewers={onNotifyReviewers}
                          hasReviewPermission={hasReviewPermission}
                          hasReviewCommentsAcknowledged={
                            acknowledgeCommentsBeforeRelease ? hasReviewCommentsAcknowledged : true
                          }
                          onRelease={onRelease}
                          hasReleasePermission={hasReleasePermission}
                          canRelease={canRelease}
                          projectId={projectId}
                        >
                          {review.actions && review.actions.length > TRUNCATED_ACTIONS_NUM && (
                            <div>
                              <Button
                                type="tertiary"
                                leadingIcon={showAllActions ? 'chevron-down' : 'chevron-up'}
                                onClick={() => setShowAllActions((_showAllActions) => !_showAllActions)}
                              >
                                Show {showAllActions ? 'Less' : 'More'} History
                              </Button>
                            </div>
                          )}
                          {reviewActionsSortedDisplay.length > 0 && (
                            <div className="flex flex-col">
                              {reviewActionsSortedDisplay.map((action, index) => (
                                <ReviewAction key={index} action={action} />
                              ))}
                            </div>
                          )}
                          {(!isArchived || review.release_note) && (
                            <ReleaseNote
                              releaseNote={review.release_note}
                              onSaveReleaseNote={updateReleaseNote}
                              isEditable={canEditReleaseNote}
                            />
                          )}
                        </Reviewers>
                      )}
                      {onReviewError && <Error text={onReviewError} />}

                      {/*
                        There are few procedures that do not have reviewer_groups,
                        so this block should hardly ever be needed.
                       */}
                      {(!isArchived || review.release_note) && !review.reviewer_groups && (
                        <ReleaseNote
                          releaseNote={review.release_note}
                          onSaveReleaseNote={updateReleaseNote}
                          isEditable={canEditReleaseNote}
                        />
                      )}
                      <ReviewProcedureContent
                        procedure={reviewDisplay}
                        onResolveReviewComment={isArchived ? undefined : resolveReviewComment}
                        onUnresolveReviewComment={isArchived ? undefined : unResolveReviewComment}
                        saveReviewComment={isArchived ? undefined : saveReviewComment}
                        isCollapsedMap={isCollapsedMap}
                        setIsCollapsed={setIsCollapsed}
                        areAllStepsInSectionExpanded={areAllStepsInSectionExpanded}
                        setAllStepsInSectionExpanded={setAllStepsInSectionExpanded}
                        scrollToBufferRem={REVIEW_STICKY_HEADER_HEIGHT_REM}
                        showReviewComments={true}
                      />
                    </div>
                  </div>
                </div>
                {showVersionHistory && (
                  <PageSidebar
                    title="Version History"
                    onClose={() => setShowVersionHistory(false)}
                    hasTopBuffer={true}
                    hasTopDivider={(versions ?? []).length < 2}
                  >
                    <ProcedureVersionList procedureId={id} procedure={review} versions={versions} />
                  </PageSidebar>
                )}
              </div>
            </Route>
            <Route path={`${path}/flow-view`}>
              <div className="m-8 pt-4 w-full">
                <h1>
                  {review.code} {review.name}
                </h1>
                <ProcedureFlowChart procedure={review} />
              </div>
            </Route>
          </Switch>
        </Route>
        <Route exact path={`${path}/run-preview`}>
          <Run previewMode={PREVIEW_MODE.REVIEW} />
        </Route>
      </Switch>
    </ReviewContextProvider>
  );
};

export default Review;

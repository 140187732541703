import PouchDB from 'pouchdb';

const authUtil = {
  authFetch: async (superlogin, url, opts) => {
    const session = superlogin.getSession();
    if (!session) {
      throw new Error('No session found');
    }
    const encoded = btoa(`${session.token}:${session.password}`);
    opts.headers.set('Authorization', `Basic ${encoded}`);
    return PouchDB.fetch(url, opts).then((response) => {
      // Require login on unauthorized error when logged in.
      if (response.status === 401 && superlogin.authenticated()) {
        superlogin.logout().catch(() => {});
        return Object.defineProperty(
          new Response(new Blob(), {
            status: response.status,
            statusText: response.statusText,
          }),
          'url',
          { value: response.url }
        );
      }
      return response;
    });
  },
};

export default authUtil;

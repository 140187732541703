import React from 'react';

export interface LeftSideProps {
  children?: React.ReactNode;
  showOverflow?: boolean;
}

const LeftSide = ({ children, showOverflow = false }: LeftSideProps) => {
  return (
    <div
      className={`flex flex-row items-center whitespace-nowrap text-ellipsis ${
        showOverflow ? 'overflow-visible' : 'overflow-hidden'
      }`}
    >
      {children}
    </div>
  );
};

export default React.memo(LeftSide);

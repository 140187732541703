import React, { useCallback, useMemo } from 'react';
import { generateHiddenClassString } from '../../lib/styles';
import telemetryUtil from '../../lib/telemetry';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import diffUtil from '../../lib/diffUtil';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import DiffContainer from '../Diff/DiffContainer';
import { BasicRule, Range } from '../Blocks/Rule';
import DiffOldAndNew from '../Diff/DiffOldAndNew';

const ReviewBlockTelemetry = ({ telemetry, blockLabel, docState, isHidden, isSpacerHidden }) => {
  const oldTelemetryKey = sharedDiffUtil.getDiffValue<string>(telemetry, 'key', 'old').toLowerCase() ?? null;
  const newTelemetryKey = sharedDiffUtil.getDiffValue<string>(telemetry, 'key', 'new')?.toLowerCase() ?? null;

  const getParameterDisplayKey = useCallback(
    (version: 'old' | 'new') => {
      const telemetryKey = sharedDiffUtil.getDiffValue<string>(telemetry, 'key', version).toLowerCase() ?? null;
      if (!telemetryKey) {
        return;
      }

      if (telemetryKey === 'custom') {
        return;
      }

      const telemetryUnits = sharedDiffUtil.getDiffValue<string | undefined>(telemetry, 'units', version);

      const unitsSuffix = telemetryUnits ? ` (${telemetryUnits})` : '';
      return `${telemetryUtil.getParameterName(telemetry, version)}${unitsSuffix}`;
    },
    [telemetry]
  );

  const oldParameterDisplayKey = useMemo(() => getParameterDisplayKey('old'), [getParameterDisplayKey]);
  const newParameterDisplayKey = useMemo(() => getParameterDisplayKey('new'), [getParameterDisplayKey]);

  const hasChanged = telemetry.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED;
  const { newBasicRule, oldBasicRule, newRange, oldRange } = useMemo(() => {
    return diffUtil.getRuleDiffValues({
      op: telemetry.rule,
      value: telemetry.value,
      range: telemetry.range,
    });
  }, [telemetry]);

  const oldExpression = useMemo(() => {
    if (oldTelemetryKey !== 'custom') {
      return null;
    }
    return sharedDiffUtil.getDiffValue<string>(telemetry, 'expression', 'old');
  }, [oldTelemetryKey, telemetry]);

  const newExpression = useMemo(() => {
    if (newTelemetryKey !== 'custom') {
      return null;
    }
    return sharedDiffUtil.getDiffValue<string>(telemetry, 'expression', 'new');
  }, [newTelemetryKey, telemetry]);

  return (
    <>
      <tr>
        <td>
          <div className={generateHiddenClassString('', isHidden)} />
        </td>
        <td colSpan={2}>
          {telemetry.key && (
            <div className={generateHiddenClassString('flex items-start mt-2 mr-8 page-break', isHidden)}>
              <Spacer isHidden={isSpacerHidden} />
              <SubstepNumber blockLabel={blockLabel} isHidden={isSpacerHidden} hasExtraVerticalSpacing={false} />
              <div>
                <DiffContainer label="Telemetry" diffChangeState={telemetry.diff_change_state} isTextSticky={false}>
                  <div>
                    <DiffOldAndNew
                      previous={
                        <div className="flex flex-row gap-x-8">
                          {oldParameterDisplayKey}
                          {oldParameterDisplayKey && oldBasicRule && (
                            <BasicRule
                              op={oldBasicRule.op}
                              value={oldBasicRule.value}
                              inputName={oldParameterDisplayKey}
                              isLight={hasChanged}
                            />
                          )}
                          {oldParameterDisplayKey && oldRange && (
                            <Range range={oldRange} inputName={oldParameterDisplayKey} isLight={hasChanged} />
                          )}
                          {oldExpression && <span>{oldExpression}</span>}
                        </div>
                      }
                      updated={
                        <div className="flex flex-row gap-x-8">
                          {newParameterDisplayKey}
                          {newParameterDisplayKey && newBasicRule && (
                            <BasicRule
                              op={newBasicRule.op}
                              value={newBasicRule.value}
                              inputName={newParameterDisplayKey}
                              isLight={hasChanged}
                            />
                          )}
                          {newParameterDisplayKey && newRange && (
                            <Range range={newRange} inputName={newParameterDisplayKey} isLight={hasChanged} />
                          )}
                          {newExpression && <span>{newExpression}</span>}
                        </div>
                      }
                      label="Rule"
                      hasChanged={hasChanged}
                    />
                  </div>
                </DiffContainer>
              </div>
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default ReviewBlockTelemetry;

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import EditSelectClearIndicator from './EditSelectClearIndicator';
import { reactSelectStyles } from '../lib/styles';
import { createNewTag } from '../lib/tagsUtil';
import { RunTag, Tag } from 'shared/lib/types/couch/settings';
import DiffContainer from './Diff/DiffContainer';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import { TagDiffElement } from 'shared/lib/types/views/procedures';
import { useReviewContext } from '../contexts/ReviewContext';
import useDiff from '../hooks/useDiff';

interface TagSelectorProps {
  tags?: Array<RunTag | Tag | TagDiffElement>;
  globalTags: Array<Tag>;
  onSaveTags: (tags: Array<Tag>) => void;
  isDisabled?: boolean;
  isRunTagsSelector?: boolean;
  didTagsChange?: boolean;
}

/**
 * Formik custom select component that allows users to set tags when editing procedures.
 *
 * @param tags - List of procedure/run level tags
 * @param globalTags - Object containing team level tags (can be either tags or run_tags)
 * @param onSaveTags - Function to process tags onChange
 * @param isDisabled - Determines whether the tags selector dropdown is disabled or not
 */
const TagsSelector = React.memo<TagSelectorProps>(
  ({ tags, globalTags, onSaveTags, isDisabled = false, isRunTagsSelector = false, didTagsChange = false }) => {
    const isMounted = useRef(true);
    const { onScrollToDiffRefChanged } = useReviewContext();
    const { handleOnScrollToDiffRefChanged } = useDiff({ onScrollToDiffRefChanged });

    // Update mounted flag when component is unmounted
    useEffect(
      () => () => {
        isMounted.current = false;
      },
      []
    );

    const tagOptions = useMemo(() => {
      return globalTags.map((tag) => ({
        value: tag.key,
        label: tag.name,
      }));
    }, [globalTags]);

    const selectValue = useMemo(() => {
      if (!tags) {
        return '';
      }

      return Object.values(tags).map((tag) => ({
        value: tag.key,
        label: tag.name,
      }));
    }, [tags]);

    const onChangeHandler = useCallback(
      (options) => {
        const tags = options.reduce((allTags, option) => {
          if (!option.label?.trim()) {
            return allTags;
          }
          if (option.__isNew__) {
            allTags.push(createNewTag(option.label));
          } else {
            allTags.push({
              key: option.value,
              name: option.label,
            });
          }

          return allTags;
        }, []);

        onSaveTags(tags);
      },
      [onSaveTags]
    );

    const diffChangeState = useMemo(
      () => (didTagsChange ? ARRAY_CHANGE_SYMBOLS.MODIFIED : ARRAY_CHANGE_SYMBOLS.UNCHANGED),
      [didTagsChange]
    );

    return (
      <DiffContainer
        diffChangeState={diffChangeState}
        label="Tags"
        isPlural={true}
        onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged('tags', element)}
      >
        <div className="w-full items-center max-w-md">
          <label htmlFor="Tags" className="mb-1 font-semibold text-sm">
            {isRunTagsSelector ? 'Run Tags' : 'Tags'}
          </label>
          <div className="w-full">
            <CreatableSelect
              aria-label="Tags"
              classNamePrefix="react-select"
              components={{ ClearIndicator: EditSelectClearIndicator }}
              isClearable={true}
              isDisabled={isDisabled}
              onChange={onChangeHandler}
              placeholder={isRunTagsSelector ? 'Select run tags' : 'Select tags'}
              options={tagOptions}
              styles={reactSelectStyles}
              value={selectValue}
              isMulti
            />
          </div>
        </div>
      </DiffContainer>
    );
  }
);

export default TagsSelector;

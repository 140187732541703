import React, { useContext, useCallback, useMemo, useState } from 'react';
import AttachmentService from '../attachments/service';
import ProcedureService from '../api/procedures';
import RunService from '../api/runs';
import SettingsService from '../api/settings';
import RevisionsService from '../api/revisions';
import TelemetryService from '../api/telemetry';
import CommandingService from '../api/commanding';
import DictionaryService from '../api/dictionary';
import ExternalDataService from '../api/externalData';
import ManufacturingService from '../manufacturing/api/manufacturing';
import UserService from '../api/user';
import OrganizationService from '../api/organizations';
import EventService from '../schedule/api/events';
import SwimlaneService from '../schedule/api/swimlanes';
import superlogin, { getAllTeamIdsFromSession, getDefaultTeamIdFromSession, getSession } from '../api/superlogin';
import RolesService from '../api/roles';
import InfluxService from '../storage/api/influx';
import DataService from '../storage/api/data';
import { RealtimeProvider } from './RealtimeContext';
import useTeamFromPath from '../hooks/useTeamFromPath';
import ApiKeysService from '../api/apiKeys';
import IssueService from '../api/issue';
import NCRService from '../issues/api/issues';
import IssueDetailsService from '../issues/api/issueDetails';
import TestingService from '../api/testing';
import NotificationsService from '../api/notifications';
import MlService from '../api/ml';
import ToolsService from '../manufacturing/api/tools';
import AnnotationService from '../storage/api/annotation';
import SearchService from '../api/search';
import BuildsService from '../manufacturing/api/builds';
import PasswordsService from '../passwords/api/passwords';
import JamaService from '../api/jama';
import { DatabaseServices } from './proceduresSlice';
import { UserOrgData } from 'shared/lib/types/api/util';
import RiskService from '../risks/api/risks';
import RiskDetailsService from '../risks/api/riskDetails';
import OperationService from '../api/operation';

export type DatabaseContextType = {
  clearLocalData: () => Promise<PromiseSettledResult<void>[]>;
  teamsServices: { [teamId: string]: DatabaseServices };
  services;
  currentTeamId: string;
  updateCurrentTeamId: (teamId: string) => void;
  updateTeamIds: (userOrgData: Array<UserOrgData>, defaultTeamId?: string) => void;
  ensureNavigationTeam: (teamId?: string) => void;
};

const DatabaseContext = React.createContext<undefined | DatabaseContextType>(undefined);

const createServices = (orgId: string, teamId: string): DatabaseServices => {
  // Create dependency services first.
  const revisionsService = new RevisionsService(teamId, superlogin);
  const services = {
    attachments: AttachmentService.getInstance(teamId),
    procedures: ProcedureService.getInstance(teamId, revisionsService, superlogin),
    revisions: revisionsService,
    runs: new RunService(teamId),
    settings: SettingsService.getInstance(teamId),
    apiKeys: new ApiKeysService(teamId),
    telemetry: new TelemetryService(teamId),
    commanding: new CommandingService(teamId),
    dictionary: new DictionaryService(teamId),
    externalData: new ExternalDataService(teamId),
    users: new UserService(teamId),
    organizations: new OrganizationService(orgId),
    roles: new RolesService(teamId),
    manufacturing: new ManufacturingService(teamId),
    passwords: new PasswordsService(teamId),
    builds: new BuildsService(teamId),
    tools: new ToolsService(teamId),
    events: new EventService(teamId),
    swimlanes: new SwimlaneService(teamId),
    influx: new InfluxService(teamId),
    issue: new IssueService(teamId),
    ncr: new NCRService(teamId),
    issueDetails: new IssueDetailsService(teamId),
    data: new DataService(teamId),
    testing: new TestingService(teamId),
    notifications: new NotificationsService(teamId),
    ml: new MlService(teamId),
    annotation: new AnnotationService(teamId),
    search: new SearchService(teamId),
    risk: new RiskService(teamId),
    riskDetails: new RiskDetailsService(teamId),
    jama: new JamaService(teamId),
    operation: new OperationService(teamId),
  };

  return services;
};

const createTeamsServices = (userOrgData: Array<UserOrgData>): { [teamId: string]: DatabaseServices } => {
  const teamsServices = {};

  for (const org of userOrgData) {
    for (const teamId of org.teamIds) {
      if (teamId) {
        teamsServices[teamId] = createServices(org.id, teamId);
      }
    }
  }

  return teamsServices;
};

/**
 * Traverses all the services and closes them if they have the close method.
 */
const closeTeamsServices = (teamsServices: { [teamId: string]: DatabaseServices } | null) => {
  if (!teamsServices) {
    return;
  }

  Object.values(teamsServices).forEach((services) => {
    // Some services don't have the 'close' method, need to check before calling it.
    Object.values(services).forEach((service) => service.close && service.close());
  });
};

/**
 * Provides the following properties:
 * 1) teamsServices - a services map (teamsServices) that provides a services object for each team id.
 * 2) services - services object for current team (will be deprecated).
 * 3) currentTeamId - currently set team id for the application.
 * 4) updateCurrentTeamId - a method that accepts teamId and updates currentTeamId.
 * 5) updateTeamIds - a method to update all teamId's that are relevant to the user.
 */
const DatabaseProvider = ({ children }) => {
  const session = getSession();
  const teamIds = useMemo(() => getAllTeamIdsFromSession(session) ?? [], [session]);

  const teamFromPath = useTeamFromPath();
  let defaultTeamId;
  if (teamFromPath && teamIds.includes(teamFromPath)) {
    defaultTeamId = teamFromPath;
  } else {
    defaultTeamId = getDefaultTeamIdFromSession(session);
  }

  const initialTeamsServices = createTeamsServices(session?.userOrgData ?? []);

  const initialState = {
    currentTeamId: defaultTeamId,
    teamsServices: initialTeamsServices,
  };

  const [state, setState] = useState(initialState); // Use one state for both currentTeamId and teamsServices to avoid double rendering when setting both.

  const currentTeamId = useMemo(() => {
    return state.currentTeamId;
  }, [state]);

  const teamsServices = useMemo(() => {
    return state.teamsServices;
  }, [state]);

  // Returns services for the current team.
  const services = useMemo(() => {
    if (!teamsServices || !currentTeamId) {
      return {};
    }

    return teamsServices[currentTeamId];
  }, [teamsServices, currentTeamId]);

  const updateCurrentTeamId = useCallback(
    (teamId) => {
      if (!teamId) {
        closeTeamsServices(teamsServices);

        setState({
          currentTeamId: null,
          teamsServices: {},
        });

        return;
      }

      setState((oldState) => {
        const newState = { ...oldState };
        newState.currentTeamId = teamId;
        return newState;
      });
    },
    [teamsServices]
  );

  const ensureNavigationTeam = useCallback(
    (teamId?: string) => {
      if (!teamId || !teamIds) {
        return;
      }
      if (teamId === state.currentTeamId || !teamIds.includes(teamId)) {
        return;
      }
      updateCurrentTeamId(teamId);
    },
    [state, updateCurrentTeamId, teamIds]
  );

  // Closes current services before reinitializing with new teamIds, and sets new currentTeamId.
  const updateTeamIds = useCallback(
    (userOrgData, defaultTeamId) => {
      closeTeamsServices(teamsServices);

      const newTeamsServices = createTeamsServices(userOrgData);
      const teamIds = userOrgData.map((org) => org.teamIds).flat();
      const currentTeamId = defaultTeamId ? defaultTeamId : teamIds && teamIds.length && teamIds[0];
      const newState = {
        currentTeamId,
        teamsServices: newTeamsServices,
      };

      setState(newState);
    },
    [teamsServices]
  );

  const clearLocalData = useCallback(async () => {
    if (!teamsServices) {
      return Promise.allSettled([]);
    }

    return Promise.allSettled<void>(
      Object.values(teamsServices)
        .flatMap((services) => Object.values(services))
        .map((service) => service.clear && service.clear())
    );
  }, [teamsServices]);

  return (
    <DatabaseContext.Provider
      value={{
        clearLocalData,
        teamsServices,
        services, // TODO - deprecate services from here and pass down map of teamsServices only.
        currentTeamId,
        updateCurrentTeamId,
        updateTeamIds,
        ensureNavigationTeam,
      }}
    >
      <RealtimeProvider>{children}</RealtimeProvider>
    </DatabaseContext.Provider>
  );
};

const useDatabaseServices = () => {
  const context = useContext(DatabaseContext);
  if (context === undefined) {
    throw new Error('useDatabaseServices must be used within a DatabaseProvider');
  }
  return context;
};

export { DatabaseProvider, useDatabaseServices };

import React from 'react';
import ThumbnailFile from './ThumbnailFile';
import ThumbnailImage from '../components/ThumbnailImage';
import useContentType from '../hooks/useContentType';

/**
 * Renders a preview of the given attachment or file. Checks the content type
 * of the relevant object and shows an image or file link.
 * attachment: A json attachment object. Ignored if `file` is specified.
 * file: A browser File object.
 * onShouldRenderImageChange: Callback to communicate to the parent
 * StepCommenting component whether the attachment is an image
 *
 * @param {Object} props
 * @param {Object} props.attachment
 * @param {File} [props.file]
 * @param {Function} [props.onShouldRenderImageChange]
 * @param {'sm' | 'lg'} [props.size]
 * @param {boolean} [props.crop]
 * @param {boolean} [props.canDownload]
 */
const AttachmentPreview = ({ attachment, file, onShouldRenderImageChange, size, crop, canDownload }) => {
  const { shouldRenderImage } = useContentType({
    attachment,
    file,
  });

  React.useEffect(() => {
    if (onShouldRenderImageChange) {
      onShouldRenderImageChange(shouldRenderImage);
    }
  }, [shouldRenderImage, onShouldRenderImageChange]);

  if (!attachment && !file) {
    return null;
  }

  if (shouldRenderImage) {
    return <ThumbnailImage attachment={attachment} file={file} size={size} crop={crop} canDownload={canDownload} />;
  }

  return <ThumbnailFile attachment={attachment} file={file} />;
};

export default AttachmentPreview;

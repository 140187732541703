import { FormikHelpers, FormikValues, getIn, setIn } from 'formik';
import { NumberFieldSetContentErrors, OperationFieldName } from '../lib/types';
import { Rule } from 'shared/lib/types/views/procedures';
import FieldSetMathOperation from './FieldSetMathOperation';
import FieldSetRange from './FieldSetRange';
import FieldSetValue from './FieldSetValue';
import { Option } from 'react-select';

interface FieldSetRuleProps {
  path: string;
  rule?: Rule;
  contentErrors: NumberFieldSetContentErrors;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
  operationFieldName: OperationFieldName;
  onChangeValueField: (value: string, path: string) => void;
  isDisabled?: boolean;
  disabledFields?: unknown;
}

const FieldSetRule = ({
  path,
  rule,
  contentErrors,
  setFieldValue,
  operationFieldName,
  onChangeValueField,
  isDisabled,
  disabledFields = {},
}: FieldSetRuleProps) => {
  const showsRangeFields = rule?.op === 'range';
  const showsValueField = rule?.op !== 'range';
  const onChangeRule = (option: Option) => {
    const op = option.value;
    if (op === rule?.[operationFieldName]) {
      return;
    }
    let ruleCopy = setIn(rule, `${operationFieldName}`, op);

    // Reset `range` and `value` whenever value/range form element visibilities change
    if (op.toLowerCase() === 'range') {
      ruleCopy = setIn(ruleCopy, 'range', {
        min: '',
        max: '',
        include_min: true,
        include_max: true,
      });

      ruleCopy = setIn(ruleCopy, 'value', '');
    } else {
      // Reset the range values when the operation is not a range; otherwise the range values will persist across releases.
      ruleCopy = setIn(ruleCopy, 'range', {
        min: '',
        max: '',
        include_min: undefined,
        include_max: undefined,
      });
    }

    setFieldValue(path, ruleCopy);
  };

  return (
    <div className="flex flex-row flex-wrap gap-x-2 mr-2">
      {/* Parameter rule */}
      <FieldSetMathOperation
        value={getIn(rule, operationFieldName)}
        operatorError={contentErrors?.rule?.op}
        isDisabled={isDisabled || getIn(disabledFields, 'rule_operation')}
        onChange={onChangeRule}
        description="Rule"
      />

      {/* Parameter range min and max values */}
      {showsRangeFields && (
        <FieldSetRange
          path={path}
          rangeError={contentErrors?.rule?.range}
          onChange={onChangeValueField}
          isDisabled={isDisabled || getIn(disabledFields, 'rule_range')}
          setFieldValue={setFieldValue}
        />
      )}

      {/* Parameter value */}
      {showsValueField && (
        <FieldSetValue
          path={path}
          dataType={null}
          valueError={contentErrors?.rule?.value}
          onChange={onChangeValueField}
          isDisabled={isDisabled || getIn(disabledFields, 'rule_value')}
        />
      )}
    </div>
  );
};

export default FieldSetRule;

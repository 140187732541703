import { useMemo } from 'react';
import { useUserInfo } from '../contexts/UserContext';

const DEFAULT_OFFLINE_PROCEDURE_DATA_ENABLED = true;
const PROFILE_OFFLINE_PROCEDURE_DATA_ENABLED_KEY = 'offline_procedure_data_enabled';

const useProfile = () => {
  const { userInfo } = useUserInfo();

  const profile = useMemo(() => {
    if (!userInfo || !userInfo.session) {
      return;
    }
    return userInfo.session.profile;
  }, [userInfo]);

  /**
   * Check if offline support is enabled for master procedure data.
   *
   * This is a bit of a kill switch in case we need to disable local
   * storage for browser quota problems and allow a user login to still
   * function. In the future this can be a visible user setting.
   */
  const isOfflineProcedureDataEnabled = useMemo(() => {
    // Wait for profile to load before falling back to defaults.
    if (!profile) {
      return;
    }
    const isEnabled = profile[PROFILE_OFFLINE_PROCEDURE_DATA_ENABLED_KEY];
    // If not defined, default to enabled.
    if (isEnabled === null || isEnabled === undefined) {
      return DEFAULT_OFFLINE_PROCEDURE_DATA_ENABLED;
    }
    // Any value other than 'true' is false.
    return isEnabled === true;
  }, [profile]);

  return { isOfflineProcedureDataEnabled };
};

export default useProfile;

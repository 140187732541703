import { ChangeEvent } from 'react';
import Icon from '../../../elements/Icon';

export type NumberInputProps = {
  id?: string;
  prefix?: string;
  value?: string | undefined;
  hasDelimiter?: boolean;
  placeholder: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  testId?: string;
  isDisabled?: boolean;
  onClick?: () => void;
  showCaret: boolean;
};

const NumberInput = ({
  id,
  prefix,
  value,
  hasDelimiter,
  placeholder,
  onChange,
  onBlur,
  testId,
  isDisabled = false,
  onClick,
  showCaret,
}: NumberInputProps) => {
  return (
    <div className="w-full h-full rounded">
      <input
        type="text"
        id={id}
        className="w-full border-0 text-sm focus:ring-0 rounded pt-[0.65rem] bg-inherit disabled:placeholder:text-gray-400"
        value={prefix ? value?.substring(prefix.length + (hasDelimiter ? 1 : 0)) : value}
        maxLength={64}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        data-testid={testId}
        disabled={isDisabled}
        onClick={onClick}
      />
      {showCaret && <Icon element="chevron-down" className="absolute right-[1rem] top-[0.8rem] text-gray-300" />}
    </div>
  );
};

export default NumberInput;

import React, { useEffect, useState, useMemo } from 'react';
import { useGanttContext } from '../../components/Gantt/GanttContext';
import { REFRESH_INTERVAL } from '../../config';
import { DateTime } from 'luxon';

const TimeBar = () => {
  const { dateToXPos, ganttWidth } = useGanttContext();
  const [currentTime, setCurrentTime] = useState(DateTime.utc({ locale: 'utc' }));

  // Initialize current time clock
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(DateTime.utc({ locale: 'utc' }));
    }, REFRESH_INTERVAL);

    return () => clearInterval(intervalId);
  }, [setCurrentTime]);

  const xPos = useMemo(() => dateToXPos(currentTime), [currentTime, dateToXPos]);

  // If timebar is outside current view period, don't render
  if (xPos > ganttWidth) {
    return null;
  }

  return (
    <div
      className="absolute top-0 bottom-0 border-blue-800 opacity-50 border-r-[3px] border-dashed z-10"
      style={{ transform: `translate3d(${xPos}px, 0, 0)` }}
    />
  );
};

export default TimeBar;

import { ChangeEvent } from 'react';
import FieldText from '../../elements/FieldText';
import { CustomDetail } from '../../lib/customDetails';

type FieldSetCustomDetailsProps = {
  details: Array<CustomDetail>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const FieldSetCustomDetails = ({ details, onChange }: FieldSetCustomDetailsProps) => {
  return (
    <div className="flex flex-col mt-2 gap-y-2 w-full">
      {details.map(({ name, value }, index) => (
        <FieldText
          key={name}
          name={`details[${index}].value.recorded`}
          label={name}
          onChange={onChange}
          value={value?.recorded || ''}
        />
      ))}
    </div>
  );
};

export default FieldSetCustomDetails;

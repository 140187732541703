import Konva from 'konva';

export type Size = 2 | 3 | 5 | 8;
export type SizeLabel = 'XS' | 'SM' | 'MD' | 'LG';
export type Color =
  | 'red'
  | 'orange'
  | 'yellow'
  | 'lime'
  | 'blue'
  | 'purple'
  | 'white'
  | 'black'
  | 'gray';
export type Tool = 'Line' | 'Arrow' | 'Pen' | 'Rect' | 'Ellipse' | 'Text';

export type Point = {
  x: number;
  y: number;
};

export type Dimensions = {
  width: number;
  height: number;
  scale: number;
};

export type PointerSize = {
  pointerLength: number;
  pointerWidth: number;
};

export const PALLETTE: Array<Color> = [
  'red',
  'orange',
  'yellow',
  'lime',
  'blue',
  'purple',
  'white',
  'black',
  'gray',
];
export const EMPTY_ANNOTATION = { shapes: [] };
export const DOWNLOAD_QUALITY = 0.8;

export const enum Sizes {
  XS = 2,
  SM = 3,
  MD = 5,
  LG = 8,
}

const horizontalPadding = 42;
const verticalPaddingWithToolbar = 126;

const AnnotationUtil = {
  getDimensions: (image: HTMLImageElement): Dimensions => {
    let width = Math.min(image.width, window.innerWidth - horizontalPadding);
    let height = (width / image.width) * image.height;
    if (height > window.innerHeight - verticalPaddingWithToolbar) {
      // If portrait orientation and exceeds bounds, constrain by height first and resize width
      height = window.innerHeight - verticalPaddingWithToolbar;
      width = (height / image.height) * image.width;
    }
    return {
      width,
      height,
      scale: width / image.width,
    };
  },

  pointerSize: (size: Size, resolution: number): PointerSize => {
    return {
      pointerLength: (size + 3) * 3 * resolution,
      pointerWidth: (size + 3) * 3 * resolution,
    };
  },

  fontSize: (size: Size, resolution: number): number => {
    const sizeMap = {
      [Sizes.XS]: 16 * resolution,
      [Sizes.SM]: 24 * resolution,
      [Sizes.MD]: 36 * resolution,
      [Sizes.LG]: 60 * resolution,
    };

    return sizeMap[size];
  },

  textLines: (text?: string): number => {
    if (!text) {
      return 1;
    }
    return text.split(/[\n\r]/).length;
  },

  strokeWidth: (size: Size, resolution: number): number => {
    return (size + 1) * resolution;
  },

  scaleToViewport: (pos: Point, viewportScale: number): Point => {
    return {
      x: pos.x * viewportScale,
      y: pos.y * viewportScale,
    };
  },

  getFileFromStage: (
    stage: Konva.Stage,
    mimeType: string,
    pixelRatio: number,
    fileName: string
  ): Promise<File | null> => {
    const promise = new Promise<File | null>((resolve) => {
      stage
        .toImage({
          mimeType, // mimetype must match the file or quality won't apply
          quality: DOWNLOAD_QUALITY,
          pixelRatio,
        })
        .then((image) => {
          resolve(
            AnnotationUtil.dataURLtoFile(
              (image as HTMLImageElement).src,
              fileName
            )
          );
        })
        .catch(() => {
          resolve(null);
        });
    });
    return promise;
  },

  // see https://stackoverflow.com/questions/43358456/convert-image-uri-into-javascript-file-object
  dataURLtoFile: (dataUrl: string, fileName: string): File => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1];
    const bstr = window.atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  },
};

export default AnnotationUtil;

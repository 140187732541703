import React from 'react';
import PropTypes from 'prop-types';
import numericalFieldHandler from '../../../utils/numericalFieldHandler';

const RepeatDaily = ({ id, daily: { interval }, handleChange }) => (
  <div className="flex flex-row items-center ml-3 space-x-2">
    <span>every</span>
    <input
      id={`${id}-interval`}
      name="repeat.daily.interval"
      aria-label="Repeat daily interval"
      className="rounded h-[38px] w-12 border text-center"
      value={interval}
      onChange={numericalFieldHandler(handleChange)}
      onBlur={(e) => {
        if (e.target.value === '' || +e.target.value < 1) {
          // default to 1 if invalid value
          handleChange({ target: { name: e.target.name, value: 1 } });
        }
      }}
    />
    <span>{`${interval === 1 ? 'day' : 'days'}`}</span>
  </div>
);
RepeatDaily.propTypes = {
  id: PropTypes.string.isRequired,
  daily: PropTypes.shape({
    interval: PropTypes.number.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RepeatDaily;

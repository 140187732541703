import React, { useCallback, useMemo } from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Control = ({ children, ...props }) => {
  return (
    <components.Control className="h-full flex align-middle" {...props}>
      <div className="flex pl-3">
        <FontAwesomeIcon className="text-gray-500" icon="eye" />
      </div>
      {children}
    </components.Control>
  );
};

const TIMESCALE_PRESETS = [
  {
    value: 60 * 60 * 24 * 31,
    label: 'Month',
  },
  {
    value: 60 * 60 * 24 * 7, // seconds
    label: 'Week',
  },
  {
    value: 60 * 60 * 24, // seconds
    label: 'Day',
  },
];

const TimescaleSelect = ({ viewWidth, timeScale, setTimeScale, period }) => {
  const handleChange = useCallback(
    ({ value }) => {
      const newScale = viewWidth / value; //  px/s
      setTimeScale(newScale);
    },
    [viewWidth, setTimeScale]
  );

  const value = useMemo(() => {
    const displayPeriod = viewWidth / timeScale;
    return TIMESCALE_PRESETS.find((p) => displayPeriod === p.value) || null;
  }, [viewWidth, timeScale]);

  const options = useMemo(
    () =>
      TIMESCALE_PRESETS.map((preset) => ({
        ...preset,
        isDisabled: preset.value > period.length('seconds'),
      })),
    [period]
  );

  return (
    <Select
      value={value}
      aria-label="Timescale Select"
      placeholder="Snap view"
      classNamePrefix="react-select"
      className="w-40 z-20 text-sm border-1 h-full"
      onChange={handleChange}
      options={options}
      components={{
        Control,
        IndicatorSeparator: () => null,
      }}
      isSearchable={false}
      styles={{
        valueContainer: (base) => ({
          ...base,
          justifyContent: 'center',
        }),
        option: (base) => ({
          ...base,
          textAlign: 'center',
        }),
        control: (base) => ({
          ...base,
          height: '100%',
          minHeight: '100%',
        }),
        dropdownIndicator: (base) => ({
          ...base,
          padding: '0px 8px 0px 8px',
        }),
      }}
    />
  );
};

export default TimescaleSelect;

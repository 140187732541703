import React, { useEffect, useMemo, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import procedureUtil from '../../lib/procedureUtil';
import { useSettings } from '../../contexts/SettingsContext';
import useProcedureObserver from '../../hooks/useProcedureObserver';
import { generateHiddenClassString } from '../../lib/styles';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { ProcedureLinkBlockDiffElement } from 'shared/lib/types/views/procedures';
import SubstepNumber from '../SubstepNumber';
import { procedureSnapshotViewPathWithSourceUrl } from '../../lib/pathUtil';
import DiffContainer from '../Diff/DiffContainer';
import sharedDiffUtil from 'shared/lib/diffUtil';

export type ReviewBlockProcedureLinkProps = {
  link: ProcedureLinkBlockDiffElement;
  isHidden: boolean;
  blockLabel?: string;
  showLabels?: boolean;
  wrapInsideTable?: boolean;
  sourceName?: string;
};

/**
 * Component for rendering a linked procedure.
 */
const ReviewBlockProcedureLink = ({
  link,
  isHidden,
  blockLabel,
  showLabels = true,
  sourceName = 'Untitled',
}: ReviewBlockProcedureLinkProps) => {
  const { procedure: linkedProcedure } = useProcedureObserver({
    id: sharedDiffUtil.getDiffValue<string>(link, 'procedure', 'new'),
  });
  const { currentTeamId } = useDatabaseServices();
  const { getSetting } = useSettings();
  const isMounted = useRef(true);
  const history = useHistory();

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  const section = useMemo(() => sharedDiffUtil.getDiffValue<string>(link, 'section', 'new'), [link]);

  const hasSection = useMemo(() => {
    return !(section === undefined || section === null || section === '');
  }, [section]);

  const linkedSectionIndex = useMemo((): number | null => {
    if (!section) {
      return null;
    }
    return procedureUtil.getSectionIndexById(linkedProcedure, section);
  }, [linkedProcedure, section]);

  const containsLinkedSection = useMemo(() => {
    if (!linkedProcedure || !linkedProcedure.sections) {
      return true;
    }

    return linkedSectionIndex !== null;
  }, [linkedProcedure, linkedSectionIndex]);

  const displaySection = useMemo(() => {
    if (linkedSectionIndex !== null) {
      return `[Section ${procedureUtil.displaySectionKey(
        linkedSectionIndex,
        getSetting('display_sections_as', 'letters')
      )}]`;
    }
    return null;
  }, [linkedSectionIndex, getSetting]);

  const displayLinkText = useMemo(() => {
    if (linkedProcedure && linkedProcedure.code && linkedProcedure.name) {
      return `${linkedProcedure.code} - ${linkedProcedure.name}${displaySection ? ` ${displaySection}` : ''}`;
    }
    return null;
  }, [displaySection, linkedProcedure]);

  const pathToProcedure = useMemo(() => {
    const sourceUrl = `${history.location.pathname}${history.location.search ?? ''}`;
    return procedureSnapshotViewPathWithSourceUrl({
      teamId: currentTeamId,
      procedureId: link.procedure as string,
      sectionId: link.section as string,
      sourceUrl,
      sourceName,
    });
  }, [link, currentTeamId, sourceName, history.location]);

  // Layout is intended for CSS grid template defined in Run.js and Procedure.js.
  return (
    <div className={generateHiddenClassString(`flex page-break`, isHidden)}>
      {blockLabel && <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />}
      {linkedProcedure && (
        <DiffContainer
          label="Link to procedure"
          diffChangeState={link.diff_change_state}
          isTextSticky={false}
          width="fit"
        >
          <div className="flex flex-row flex-grow justify-between items-center">
            {/* Render procedure link definition. */}
            <div className="flex gap-x-2 self-center pt-0 mr-2">
              {showLabels && <div className="whitespace-nowrap">Linked procedure:</div>}
              {displayLinkText && (
                <Link className="link" to={pathToProcedure}>
                  {displayLinkText}
                </Link>
              )}
            </div>
            {/* Render procedure archived message */}
            {linkedProcedure.archived && (
              <div className="ml-2 pt-2 italic text-sm flex items-end self-center">
                (This procedure has been archived.)
              </div>
            )}
            {!linkedProcedure.archived && hasSection && !containsLinkedSection && (
              <div className="ml-2 pt-2 italic text-sm flex items-end self-center">
                (This section has been removed.)
              </div>
            )}
          </div>
        </DiffContainer>
      )}
    </div>
  );
};

export default ReviewBlockProcedureLink;

import { isEmptyValue } from 'shared/lib/text';

const tableUtil = {
  /**
   * Sort the given array according to the sort preference hierarchy
   * TODO: Add types
   * TODO: Abstract this function more so that comparator(s) are passed in as arguments
   * @param {Array} rows - the rows to sort
   * @param {Object} sortPreferenceHierarchy - object of the form {
   *   primary: {
   *     key: string,
   *     direction: string,
   *     sortType: string
   *   },
   *   secondary: {
   *     key: string,
   *     direction: string,
   *     sortType: string
   *   },
   *   tertiary: {
   *     key: string,
   *     direction: string,
   *     sortType: string
   *   }
   * }
   *
   * secondary and tertiary fields are optional
   */
  sortRows: (rows, sortPreferenceHierarchy) => {
    rows.sort((row1, row2) => tableUtil._multiLevelRowComparator(row1, row2, sortPreferenceHierarchy));
  },

  convertToType: (value, type = 'string') => {
    if (type === 'number') {
      return tableUtil._convertToNumber(value);
    } else {
      return tableUtil._convertToString(value);
    }
  },

  getComparisonValue: (value) => {
    if (value?.comparisonValue) {
      return value.comparisonValue;
    }
    // value.label can be the number 0 so not enough to check truthy/falsey
    else if (value && value.label !== null && value.label !== undefined) {
      return value.label;
    } else if (value && value.code !== null && value.code !== undefined) {
      return value.code;
    } else {
      return value;
    }
  },

  _multiLevelRowComparator: (row1, row2, sortPreferenceHierarchy) => {
    const tertiaryRowComparator = sortPreferenceHierarchy.tertiary
      ? (row1, row2) => {
          return tableUtil._rowComparator(row1, row2, sortPreferenceHierarchy.tertiary, null);
        }
      : null;

    const secondaryRowComparator =
      !sortPreferenceHierarchy.secondary ||
      sortPreferenceHierarchy.primary.key === sortPreferenceHierarchy.secondary.key
        ? tertiaryRowComparator
        : (row1, row2) => {
            return tableUtil._rowComparator(row1, row2, sortPreferenceHierarchy.secondary, tertiaryRowComparator);
          };

    return tableUtil._rowComparator(row1, row2, sortPreferenceHierarchy.primary, secondaryRowComparator);
  },

  _rowComparator: (row1, row2, sortPreference, secondaryRowComparator) => {
    const field1 = tableUtil.convertToType(
      tableUtil.getComparisonValue(row1[sortPreference.key]),
      sortPreference.sortType
    );
    const field2 = tableUtil.convertToType(
      tableUtil.getComparisonValue(row2[sortPreference.key]),
      sortPreference.sortType
    );

    if (field1 < field2) {
      return sortPreference.direction === 'asc' ? -1 : 1;
    } else if (field1 > field2) {
      return sortPreference.direction === 'asc' ? 1 : -1;
    } else {
      if (secondaryRowComparator) {
        return secondaryRowComparator(row1, row2);
      }
      return 0;
    }
  },

  _convertToString: (value) => {
    if (isEmptyValue(value)) {
      return '';
    }

    return value.toString().toLowerCase();
  },

  _convertToNumber: (value) => Number(value),
};

export default tableUtil;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';

interface Props {
  count: number;
}

/**
 * Component wrapping NotificationBadge to show 9+ when count exceeds 9.
 *
 * @param count - Notification badge count.
 * @param label - Optional label to display instead of count.
 *
 */

export default function SingleDigitNotificationBadge({ count }: Props) {
  const label = useMemo(() => (count > 9 ? '9+' : String(count)), [count]);

  return (
    <div className="relative w-full h-full">
      {count > 0 && (
        <span
          style={{
            display: 'inline-block',
            position: 'absolute',
            minWidth: '15px',
            padding: '3px 4px',
            fontSize: '8px',
            fontWeight: '700',
            lineHeight: '1',
            borderRadius: '10px',
            top: '-2px',
            right: '-2px',
            whiteSpace: 'nowrap',
            textAlign: 'center',
          }}
          className="bg-red-700 text-white"
        >
          {label}
        </span>
      )}
      {count === 0 && (
        <FontAwesomeIcon icon="bell" className="absolute text-sm -right-[2px] -top-[2px] text-slate-500" />
      )}
    </div>
  );
}

import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAttachment from '../hooks/useAttachment';
import sharedDiffUtil from 'shared/lib/diffUtil';
import ProcedureDiffText from './ProcedureDiffText';
import { AttachmentBlockDiffElement } from 'shared/lib/types/views/procedures';

// For security reasons, we will force these content types to be downloaded instead of shown in the browser.
const INSECURE_TYPES = new Set([
  'application/xhtml+xml',
  'application/x-shockwave-flash',
  'application/svg+xml',
  'image/svg+xml',
  'text/html',
]);

interface ThumbnailFileProps {
  attachment: AttachmentBlockDiffElement;
  file?: File;
}

/**
 * Renders the name for an attachment or file object, with view and download.
 *
 * @param {Object} ParamObj
 * @param ParamObj.attachment: A json attachment object. Ignored if `file` is specified.
 * @param [ParamObj.file]: A browser File object.
 */
const ThumbnailFile = ({ attachment, file }: ThumbnailFileProps) => {
  const { downloadAttachment, url } = useAttachment({
    // @ts-ignore - https://linear.app/epsilon3/issue/EPS-3423/update-attachment-type
    attachment,
    file,
    isDiff: true, // TODO split out when review version of this component is created
  });

  const contentType = attachment
    ? sharedDiffUtil.getDiffValue<string>(attachment, 'content_type', 'old')?.toLowerCase()
    : '';
  const isPending = useMemo(() => Boolean(file), [file]);
  const isInsecure = INSECURE_TYPES.has(contentType);
  const name = attachment ? sharedDiffUtil.getDiffValue<string>(attachment, 'name', 'old') : file ? file.name : '';
  const nameField = attachment && attachment.name ? attachment.name : file ? file.name : '';

  if (!url) {
    return null;
  }
  return (
    <div className="flex items-center">
      {isPending && <ProcedureDiffText diffValue={nameField} />}
      {!isPending && (
        <>
          {isInsecure && (
            <button
              type="button"
              className="link"
              onClick={downloadAttachment}
              aria-label={name && `Download link for ${name}`}
            >
              <ProcedureDiffText diffValue={nameField} />
            </button>
          )}
          {!isInsecure && (
            <>
              {/**
               * Using 'noopener' preserves the security considerations, while
               * avoiding the Chrome 'this file could harm your computer' for
               * files with application/octet-stream content type. Sending
               * the Referrer header seems OK since this is our server.
               *
               * More: https://web.dev/external-anchors-use-rel-noopener/
               */}
              {/*eslint-disable-next-line react/jsx-no-target-blank*/}
              <a className="link" target="_blank" rel="noopener" href={url} download={name}>
                <ProcedureDiffText diffValue={nameField} />
              </a>
            </>
          )}
          <button
            type="button"
            className="ml-1 w-8 h-8 group"
            onClick={downloadAttachment}
            aria-label={name && `Download ${name}`}
          >
            <FontAwesomeIcon icon="cloud-download-alt" className="link text-lg group-hover:text-blue-800" />
          </button>
        </>
      )}
    </div>
  );
};

export default ThumbnailFile;

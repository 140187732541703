import React, { useCallback, useMemo } from 'react';
import stepConditionals, { CONDITIONAL_TYPE } from 'shared/lib/stepConditionals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConditionalStateTag from './ConditionalStateTag';
import {
  FieldInputConditionalBlock,
  StepConditionalDiffElement,
  DiffArrayChangeSymbol,
} from 'shared/lib/types/views/procedures';
import InvalidMessage from '../InvalidMessage';
import diffUtil from '../../lib/diffUtil';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';

interface StepConditionalDetailsProps {
  content: Array<FieldInputConditionalBlock>;
  conditionals: Array<StepConditionalDiffElement>;
  stepIdsToLabelsMap: Record<string, string>;
  onStepLinkClicked: (linkId: string) => void;
  areConditionalsValid: boolean;
  diffChangeState?: DiffArrayChangeSymbol;
  stepId: string;
}

const ReviewStepConditionalDetails = ({
  content,
  conditionals,
  stepIdsToLabelsMap,
  onStepLinkClicked,
  areConditionalsValid = true,
  diffChangeState,
  stepId,
}: StepConditionalDetailsProps) => {
  const nonRemovedConditionals = useMemo(() => {
    return diffUtil.getForVersion(conditionals ?? [], 'new');
  }, [conditionals]);

  const getSourceName = useCallback(
    (conditional: StepConditionalDiffElement) => {
      if (!conditional) {
        return '';
      }
      if (conditional.source_type === CONDITIONAL_TYPE.STEP) {
        return 'Step';
      } else if (stepConditionals.isContentConditional(conditional)) {
        const contentBlock = content.find(
          (block) => block.id === sharedDiffUtil.getDiffValue(conditional, 'content_id', 'new')
        );
        if (contentBlock) {
          return sharedDiffUtil.getDiffValue(contentBlock, 'name', 'new');
        }
      }
      return '';
    },
    [content]
  );

  if (!conditionals || !conditionals.length) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2 px-4 py-3">
      {diffChangeState === ARRAY_CHANGE_SYMBOLS.REMOVED && 'Conditionals are no longer valid.'}
      {diffChangeState !== ARRAY_CHANGE_SYMBOLS.REMOVED && nonRemovedConditionals.length > 0 && (
        <>
          <div className="whitespace-nowrap">{getSourceName(nonRemovedConditionals[0])}:</div>
          {!areConditionalsValid && <InvalidMessage>Conditionals are no longer valid.</InvalidMessage>}
          {areConditionalsValid &&
            nonRemovedConditionals.map((conditional) => (
              <div key={conditional.id} className="flex flex-row gap-x-1 items-center">
                <ConditionalStateTag conditional={conditional} size="base" conditionalStepId={stepId} />
                <FontAwesomeIcon className="text-gray-600" icon="arrow-right" />
                <button
                  className="link"
                  onClick={() =>
                    onStepLinkClicked(sharedDiffUtil.getDiffValue<string>(conditional, 'target_id', 'new'))
                  }
                  disabled={typeof onStepLinkClicked !== 'function'}
                >
                  {stepIdsToLabelsMap[sharedDiffUtil.getDiffValue<string>(conditional, 'target_id', 'new')]}
                </button>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default ReviewStepConditionalDetails;

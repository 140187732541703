import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { TestCase } from '../types';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useSettings } from '../../contexts/SettingsContext';
import { calculateAssessmentLevel } from '../libs/hazards';
import procedureUtil from '../../lib/procedureUtil';
import { useUserInfo } from '../../contexts/UserContext';
import { useAuth } from '../../contexts/AuthContext';
import { procedureEditPath } from '../../lib/pathUtil';
import { useHistory } from 'react-router-dom';
import { filterByField, filterBySearchTerm } from '../../lib/gridUtils';

type UseCasesReturn = {
  isLoading: boolean;
  testCases: Array<TestCase>;
  filteredTestCases: Array<TestCase>;
  selectedRows: ReadonlySet<string>;
  forceUpdate: () => void;
  createNewTestPlan: (testPoints?: Array<TestCase>) => void;
  setSearchTerm: (searchTerm: string) => void;
  setSelectedRows: Dispatch<SetStateAction<ReadonlySet<string>>>;
  setSelectedProjects: Dispatch<SetStateAction<ReadonlySet<string | null>>>;
};

const useCases = (includeHazards: boolean): UseCasesReturn => {
  const { isTestConditionsMatrixEnabled, config } = useSettings();
  const { services, currentTeamId } = useDatabaseServices();
  const { userInfo } = useUserInfo();
  const { auth } = useAuth();
  const history = useHistory();

  const [testCases, setTestCases] = useState<Array<TestCase>>([]);
  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<string> => new Set()
  );
  const [selectedProjects, setSelectedProjects] = useState(
    (): ReadonlySet<string | null> => new Set()
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [forceUpdateVal, forceUpdateFn] = useReducer((x) => x + 1, 0);

  const userId = userInfo.session.user_id;

  const forceUpdate = () => {
    forceUpdateFn();
  };

  const getAllTestCases = useCallback(() => {
    setIsLoading(true);
    services.testing
      .getCases(includeHazards)
      .then((cases) => {
        cases.forEach((testCase) => {
          if (testCase.hazards) {
            testCase.hazards.sort((a, b) => {
              return calculateAssessmentLevel(a) < calculateAssessmentLevel(b)
                ? 1
                : -1;
            });
          }
        });
        setTestCases(cases);
      })
      .catch(() => {
        setTestCases([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services.testing, includeHazards, forceUpdateVal]);

  const filteredTestCases: Array<TestCase> = useMemo(() => {
    const filteredRows = filterByField({
      rows: testCases,
      fieldName: 'project_id',
      values: selectedProjects,
    });

    if (!searchTerm) {
      return filteredRows;
    }
    return filterBySearchTerm({
      searchTerm,
      allData: filteredRows,
      getStrings: (testCase: TestCase) => {
        const searchableTerms = [testCase.name];
        testCase.hazards?.forEach((hazard) =>
          searchableTerms.push(hazard.name)
        );
        testCase.test_case_conditions?.forEach((condition) =>
          searchableTerms.push.apply(searchableTerms, [
            condition.name,
            condition.value,
            condition.units,
          ])
        );
        return searchableTerms;
      },
    });
  }, [searchTerm, testCases, selectedProjects]);

  useEffect(() => {
    const newSelectedRows = new Set<string>();
    filteredTestCases.forEach((testCase) => {
      if (selectedRows.has(testCase.id)) {
        newSelectedRows.add(testCase.id);
      }
    });
    if (selectedRows.size !== newSelectedRows.size) {
      setSelectedRows(newSelectedRows);
    }
  }, [filteredTestCases, selectedRows]);

  const createNewTestPlan = useCallback(
    async (testPoints: Array<TestCase> = []) => {
      let persistedTestPlan;

      try {
        // Generate test plan locally
        const defaultList = Object.values(config?.lists ?? {}).find(
          (list) => list.id === 'ls_default_quality'
        );

        const testPlanDoc = procedureUtil.getNewTestPlan({
          userId,
          testPoints,
          defaultList,
        });

        // If the user is a project-only admin, set a project id to allow permissions to work
        if (auth.hasProjectOnlyEditPermissions()) {
          testPlanDoc.project_id = auth.projectsWithEditPermission()[0];
        }

        //  Try to persist to the backend
        persistedTestPlan = await services.procedures.saveDraft(testPlanDoc);
      } catch (error) {
        return;
      }

      history.push(
        procedureEditPath(currentTeamId, persistedTestPlan.procedure_id)
      );
    },
    [auth, currentTeamId, history, services.procedures, userId, config?.lists]
  );

  useEffect(() => {
    if (!isTestConditionsMatrixEnabled?.()) {
      return;
    }
    getAllTestCases();
  }, [currentTeamId, isTestConditionsMatrixEnabled, getAllTestCases]);

  return {
    isLoading,
    testCases,
    filteredTestCases,
    selectedRows,
    forceUpdate,
    createNewTestPlan,
    setSearchTerm,
    setSelectedRows,
    setSelectedProjects,
  };
};

export default useCases;

import React, { useMemo } from 'react';
import { FieldInputNumberBlockDiffElement } from 'shared/lib/types/views/procedures';
import sharedDiffUtil from 'shared/lib/diffUtil';
import { BasicRule, Range } from '../../Blocks/Rule';
import DiffOldAndNew from '../../Diff/DiffOldAndNew';
import diffUtil from '../../../lib/diffUtil';

interface RuleProps {
  block: FieldInputNumberBlockDiffElement;
  newLabel?: React.ReactNode;
}

const ReviewRule = ({ block, newLabel }: RuleProps) => {
  const newBlockName = useMemo(() => {
    return sharedDiffUtil.getDiffValue<string>(block, 'name', 'new');
  }, [block]);
  const oldBlockName = useMemo(() => {
    return sharedDiffUtil.getDiffValue<string>(block, 'name', 'old');
  }, [block]);

  const { newBasicRule, oldBasicRule, newRange, oldRange, hasChanged } = useMemo(() => {
    return diffUtil.getRuleDiffValues(block.rule);
  }, [block]);

  const newInputName = useMemo(() => {
    return !hasChanged && newLabel ? newLabel : newBlockName;
  }, [hasChanged, newBlockName, newLabel]);

  return (
    <div className="font-mono">
      <DiffOldAndNew
        previous={
          <>
            {oldBasicRule && (
              <BasicRule
                op={oldBasicRule.op}
                value={oldBasicRule.value}
                inputName={oldBlockName}
                useChip={true}
                isLight={hasChanged}
              />
            )}
            {!oldBasicRule && oldRange && (
              <Range range={oldRange} inputName={oldBlockName} useChip={true} isLight={hasChanged} />
            )}
          </>
        }
        updated={
          <>
            {newBasicRule && (
              <BasicRule
                op={newBasicRule.op}
                value={newBasicRule.value}
                inputName={newInputName}
                useChip={newInputName === newBlockName}
                isLight={hasChanged}
              />
            )}
            {!newBasicRule && newRange && (
              <Range
                range={newRange}
                inputName={newInputName}
                useChip={newInputName === newBlockName}
                isLight={hasChanged}
              />
            )}
          </>
        }
        hasChanged={hasChanged}
        label="Rule"
      />
    </div>
  );
};

export default ReviewRule;

import procedureUtil from './procedureUtil';
import cloneDeep from 'lodash.clonedeep';
import {
  Variable,
  V2Variable,
  V2RunVariable,
  LegacyRunVariable,
  RunVariable,
  RunVariableDataTransferObject,
  Procedure,
  FieldInputBlock,
  RunFieldInputBlock,
  ProcedureDiff,
  VariableDiffElement,
} from 'shared/lib/types/views/procedures';

const SUPPORTED_VARIABLE_TYPES = [
  'text',
  'number',
  'checkbox',
  'timestamp',
  'select',
  'external_item',
  'external_search',
  'multiple_choice',
  'list',
];

const procedureVariableUtil = {
  /**
   * Returns a procedure variable, with support
   * for backwards compatibility.
   *
   * This checks to see if it contains an outdated procedure variable,
   * if older, then will update it to the newer data structure
   *
   * @param {object} variable - An object of type procedure variable
   * @returns {object} - An object of version 2 procedure variable structure
   */
  getUpdatedVariable: (variable: Variable): V2Variable => {
    let updatedVariableStructure = cloneDeep(variable);
    // Convert Legacy variables
    if (!('version' in variable)) {
      updatedVariableStructure = procedureUtil.newProcedureVariable();
      updatedVariableStructure.inputType = variable.input_type;
      updatedVariableStructure.name = variable.name;
      if ('value' in variable) {
        updatedVariableStructure.value = variable.value;
      }
    }
    if (!updatedVariableStructure.id) {
      updatedVariableStructure.id = procedureUtil.generateVariableId();
    }
    return updatedVariableStructure;
  },

  /**
   * Updates a procedure with version: 2 variable structure.
   *
   * @param {Object} procedure - A procedure.
   * @returns {Object} procedure - A copy of the procedure doc with updated
   *   procedure variable structure.
   */
  migrateProcedureVariables: (procedure: Procedure): Procedure => {
    if (procedure.variables) {
      const updated = cloneDeep(procedure);
      updated.variables = procedure.variables.map((variable) =>
        procedureVariableUtil.getUpdatedVariable(variable)
      );
      return updated;
    } else {
      return procedure;
    }
  },

  /**
   * Updates a variable to include property input_type to
   * keep backwards compatible with backend when sending variables
   * to commanding or telemetry.
   *
   * @param {Array} variables - A list of variable objects
   * @returns {Array}  A list of updated variable objects
   */
  getDataTransferVariables: (
    variables?: Array<RunVariable>
  ): Array<RunVariableDataTransferObject> | undefined => {
    if (!variables) {
      return variables;
    }
    return variables
      .filter((variable) => {
        const inputType =
          (variable as LegacyRunVariable).input_type ??
          (variable as V2RunVariable).inputType ??
          '';
        return SUPPORTED_VARIABLE_TYPES.includes(inputType);
      })
      .map((variable) => {
        const updatedVariable = {} as RunVariableDataTransferObject;
        updatedVariable.input_type =
          (variable as LegacyRunVariable).input_type ??
          (variable as V2RunVariable).inputType;
        updatedVariable.name = variable.name;
        updatedVariable.value = variable.value;
        return updatedVariable;
      });
  },

  // coerces a variable type into a field input.
  getFieldInputFromVariable: (variable: Variable): FieldInputBlock => {
    const updated = procedureVariableUtil.getUpdatedVariable(variable);
    const recordedValue = (updated as V2RunVariable).value;
    if (recordedValue !== undefined) {
      (updated as RunFieldInputBlock).recorded = { value: recordedValue };
    }
    return updated;
  },

  getVariable: (
    procedure: Procedure | ProcedureDiff,
    variableId: string
  ): V2Variable | VariableDiffElement | null => {
    if (!procedure.variables) {
      return null;
    }
    // Only V2 variables contain an id, so it's safe to cast here
    return (
      ((procedure.variables as Array<V2Variable | VariableDiffElement>).find(
        (variable) => 'id' in variable && variable.id === variableId
      ) as V2Variable) ?? null
    );
  },
};

export default procedureVariableUtil;

/**
 * Utility enum for comparison and ordering operations.
 */
export enum Ordering {
  Less = -1,
  Equal = 0,
  Greater = 1,
}

// This is a curried function, with no parameters in case we want to add
// more parameters in the future
export const timestampSort = (order = SortOrder.ascending) => {
  return stringFieldSort<['timestamp']>(['timestamp'], order);
};

export enum SortOrder {
  ascending,
  descending,
}

// type to convert a list of fields into an object with those fields.
type ObjectFromList<T extends ReadonlyArray<string>, V = string> = {
  [K in T extends ReadonlyArray<infer U> ? U : never]: V;
};

export function stringFieldSort<
  K extends ReadonlyArray<string>,
  T = ObjectFromList<K>
>(field: K, order = SortOrder.ascending) {
  const factor = order === SortOrder.descending ? -1 : 1;
  return (a: Partial<T>, b: Partial<T>): number => {
    for (let i = 0; i < field.length; i++) {
      const aVal = a[field[i]];
      const bVal = b[field[i]];
      if (aVal && bVal) {
        const compare = aVal.localeCompare(bVal) * factor;
        if (compare === 0) {
          continue;
        }
        return compare;
      } else if (aVal) {
        return 1 * factor;
      } else if (bVal) {
        return -1 * factor;
      }
      // neither has a timestamp
      return 0;
    }
    return 0;
  };
}

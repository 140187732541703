import React, { useMemo } from 'react';
import { formattedISOTimestampInTimezone } from '../lib/datetime';
import Tooltip from '../elements/Tooltip';
import { useSettings } from '../contexts/SettingsContext';
import DateTimeTooltipContent from './DateTimeTooltipContent';
import { createIsoString } from 'shared/lib/datetime';

/**
 * A component for providing consistent display of DateTime elements.
 * The timestamp will be displayed in the element. A localized version of the time is provided in the tooltip.
 */
interface DateTimeDisplayProps {
  timestamp: string;
  wrap?: boolean;
  hasTooltip?: boolean;
  type?: 'date' | 'time' | 'datetime';
  zone?: string;
}

const DateTimeDisplay = ({
  timestamp,
  wrap = false,
  hasTooltip = true,
  type = 'datetime',
  zone,
}: DateTimeDisplayProps) => {
  const { getSetting } = useSettings();
  const timezone = useMemo(() => zone ?? getSetting('timezone', undefined), [getSetting, zone]);

  const timezoneDateTimeStampDisplay = useMemo(
    () => formattedISOTimestampInTimezone(timestamp, timezone),
    [timestamp, timezone]
  );

  const isoTimestamp = useMemo(() => createIsoString({ timestamp, zone: timezone }), [timestamp, timezone]);

  return (
    <Tooltip
      content={<DateTimeTooltipContent timestamp={timestamp} zone={zone ?? 'UTC'} type={type as 'time' | 'datetime'} />}
      visible={Boolean(hasTooltip) && type !== 'date'}
    >
      <time
        dateTime={isoTimestamp ?? undefined}
        className={`cursor-default group ${wrap ? 'whitespace-normal' : 'whitespace-nowrap'}`}
      >
        {timezoneDateTimeStampDisplay}
      </time>
    </Tooltip>
  );
};

export default DateTimeDisplay;

import React, { useCallback, useMemo } from 'react';
import Modal from '../Modal';
import ReviewSettingsSelectReviewType from './ReviewSettingsSelectReviewType';
import FlashMessage from '../FlashMessage';
import EditReviewerGroups from '../Reviewers/EditReviewerGroups';
import { ReviewParameters } from '../../api/procedures';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ReviewSettings } from 'shared/lib/reviewUtil';
import { cloneDeep } from 'lodash';

interface ReviewSettingsModalProps {
  settings: ReviewSettings | null;
  onSubmitReview: (settings: ReviewParameters) => void;
  onCancel: () => void;
  submissionErrors: string | null;
  setSubmissionErrors: (err: string | null) => void;
  isMoveToReviewDisabled: boolean;
  canAddUserIdToOperatorRoleSignoff?: boolean;
  buttonText?: string;
  changeIdOnReviewerUpdate?: boolean;
}
const ReviewSettingsModal = React.memo(
  ({
    settings,
    onSubmitReview,
    onCancel,
    submissionErrors,
    setSubmissionErrors,
    isMoveToReviewDisabled,
    canAddUserIdToOperatorRoleSignoff = true,
    buttonText = 'Move to Review',
    changeIdOnReviewerUpdate = true,
  }: ReviewSettingsModalProps) => {
    const options = useMemo(() => {
      if (!settings) {
        return [];
      }
      if (settings.reviewTypes && settings.reviewTypes.length > 0) {
        return settings.reviewTypes
          .map((rt) => ({ value: rt.id, label: rt.name }))
          .sort((optionA, optionB) => optionA.label.localeCompare(optionB.label));
      }

      const reviewerGroupsList = Object.values(settings.reviewerGroupMap);
      const unsortedOptions =
        reviewerGroupsList
          .filter((rgs) => rgs[0].name)
          .map((rgs) => ({ value: rgs[0].id, label: rgs[0].name ?? '' })) ?? [];
      return unsortedOptions.sort((optionA, optionB) => optionA.label.localeCompare(optionB.label));
    }, [settings]);

    const defaultValues: ReviewSettings = useMemo(() => {
      if (!settings) {
        return {
          reviewTypeOrGroupId: null,
          reviewerGroupMap: {},
        };
      }
      const _defaultValues = cloneDeep(settings);
      if (!_defaultValues.reviewTypeOrGroupId || !_defaultValues.reviewerGroupMap[_defaultValues.reviewTypeOrGroupId]) {
        if (options.length > 0 && _defaultValues.reviewerGroupMap[options[0].value]) {
          _defaultValues.reviewTypeOrGroupId = options[0].value;
        }
      }
      return _defaultValues;
    }, [options, settings]);

    const methods = useForm({ defaultValues });
    const { handleSubmit, control, getValues, setValue, watch } = methods;

    const onMoveToReview = useCallback(
      (values) => {
        const reviewerGroups = values.reviewerGroupMap[values.reviewTypeOrGroupId];
        reviewerGroups.forEach((rg) => {
          rg.reviewers = rg.reviewers.filter((reviewer) => reviewer.reviewer_ids?.length > 0);
        });
        const reviewType = values.reviewTypes?.find((rt) => rt.id === values.reviewTypeOrGroupId);
        onSubmitReview({
          ...(reviewType && { reviewType }),
          reviewerGroups,
        });
      },
      [onSubmitReview]
    );

    const onSelectName = useCallback(
      ({ reviewTypeOrGroupId, reviewerGroups }) => {
        setValue('reviewTypeOrGroupId', reviewTypeOrGroupId);
        setValue(`reviewerGroupMap.${reviewTypeOrGroupId}`, reviewerGroups);
      },
      [setValue]
    );

    const getOnUpdateReviewerGroups = useCallback(
      (currentReviewerGroups, fieldName) => {
        return (updateFunc) => {
          const updatedReviewerGroups = updateFunc(currentReviewerGroups);
          setValue(fieldName, updatedReviewerGroups);
        };
      },
      [setValue]
    );

    if (!settings) {
      return <></>;
    }
    return (
      <FormProvider {...methods}>
        <FlashMessage message={submissionErrors} messageUpdater={setSubmissionErrors} type="warning" />
        <Modal
          title="Review Settings"
          onPrimaryAction={handleSubmit(onMoveToReview)}
          onSecondaryAction={onCancel}
          primaryActionTitle={buttonText}
          secondaryActionTitle="Cancel"
          isPrimaryActionEnabled={!isMoveToReviewDisabled}
          isSecondaryActionEnabled={!isMoveToReviewDisabled}
          size="lg"
        >
          <div className="flex flex-col w-full" aria-label="Review Settings Modal">
            {options.length > 0 && (
              <div>
                <label className="text-sm gray-800">Review Type</label>
                <Controller
                  name="reviewTypeOrGroupId"
                  control={control}
                  render={({ field }) => (
                    <ReviewSettingsSelectReviewType
                      reviewTypeOrGroupId={field.value}
                      reviewerGroupMap={getValues().reviewerGroupMap}
                      reviewTypes={getValues().reviewTypes}
                      options={options}
                      onSelect={onSelectName}
                    />
                  )}
                />
              </div>
            )}

            <div className="mt-2 px-2 pt-2 pb-2 bg-slate-100 border rounded overflow-x-auto w-full">
              <Controller
                name={`reviewerGroupMap.${watch('reviewTypeOrGroupId')}`}
                control={control}
                render={({ field }) => (
                  <EditReviewerGroups
                    name={field.name}
                    reviewerGroups={watch(field.name)}
                    onUpdateReviewerGroups={getOnUpdateReviewerGroups(watch(field.name), field.name)}
                    showOperatorRoles={true}
                    showUsers={true}
                    canEditGroupStructure={false}
                    allowEmptyReviewers={true}
                    canAddUserIdToOperatorRoleSignoff={canAddUserIdToOperatorRoleSignoff}
                    changeIdOnReviewerUpdate={changeIdOnReviewerUpdate}
                  />
                )}
              />
            </div>
          </div>
        </Modal>
      </FormProvider>
    );
  }
);

export default ReviewSettingsModal;

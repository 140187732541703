import React, { useMemo, useState } from 'react';
import AttachmentPreview from './AttachmentPreview';
import DateTimeDisplay from './DateTimeDisplay';
import TextLinkify from './TextLinkify';
import CommentForm from './CommentForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RunStepComment } from 'shared/lib/types/views/procedures';
import { isNil } from 'lodash';
import EditCommentButton from './EditCommentButton';
import useCommentHandler from '../hooks/useCommentHandler';
import FormStepComment from './FormStepComment';
import { CommentInterface, wasEdited } from 'shared/lib/comment';

interface ChildrenCommentProps {
  childComments?: Array<RunStepComment>;
  parentId: string;
  sectionId: string;
  stepId: string;
  saveNewComment?: (comment: RunStepComment) => void;
  editComment: (comment: CommentInterface, commentId?: string) => Promise<void>;
  isRun?: boolean;
  isCommentingDisabled?: boolean;
}

const ChildrenComments = ({
  childComments,
  parentId,
  sectionId,
  stepId,
  saveNewComment,
  editComment,
  isRun,
  isCommentingDisabled,
}: ChildrenCommentProps) => {
  const [formVisible, setFormVisible] = useState(false);

  const buttonClass = useMemo(
    () =>
      'mt-2 mb-1 btn-link px-3 py-1 text-sm rounded hover:bg-slate-300 disabled:bg-transparent disabled:text-gray-400 disabled:cursor-default',
    []
  );

  const commentButton = !isNil(saveNewComment) ? (
    <button className={buttonClass} onClick={() => setFormVisible(true)}>
      <FontAwesomeIcon icon="comment" /> Reply
    </button>
  ) : undefined;

  const { editCommentContext, submitComment, handleEditClick, handleCancel } = useCommentHandler({
    onEditComment: editComment,
    autoFocus: true,
  });

  return (
    <>
      {/* Render Child Comments */}
      {childComments?.map((comment, index) => (
        <div key={index} className="ml-2 mt-2 border-l-2 border-slate-400">
          <div className="flex justify-between items-center pt-1 px-2 text-sm">
            <div className="flex items-center">
              <span className="mr-1 font-semibold">{comment.user}</span>
              <DateTimeDisplay timestamp={comment.timestamp} />
              {wasEdited(comment.timestamp, comment.updated_at) && <span className="ml-1 text-gray-400">(edited)</span>}
            </div>
            {!isNil(saveNewComment) && !isCommentingDisabled && (
              <EditCommentButton comment={comment} onEdit={handleEditClick} />
            )}
          </div>
          <div className="pb-1 pt-1 px-2">
            {editCommentContext.id === comment.id ? (
              <FormStepComment
                autoFocus={true}
                placeholder="Edit comment"
                initialValues={{ comment: editCommentContext.text, mentions: editCommentContext.mentions }}
                onSubmit={submitComment}
                mentionEnabled={true}
                onCancel={handleCancel}
              />
            ) : (
              <>
                {comment.text && (
                  <TextLinkify mentionsEnabled={true} mentionList={comment.mention_list}>
                    <span className="whitespace-pre-line">{comment.text}</span>
                  </TextLinkify>
                )}
                {!comment.text && comment.attachment && <AttachmentPreview attachment={comment.attachment} />}
              </>
            )}
          </div>
        </div>
      ))}

      {/* Render Comment form here */}
      <CommentForm
        parentId={parentId}
        sectionId={sectionId}
        stepId={stepId}
        saveNewComment={saveNewComment}
        formVisible={formVisible}
        setFormVisible={setFormVisible}
        commentButton={commentButton}
        isRun={isRun}
        isCommentingDisabled={isCommentingDisabled}
      />
    </>
  );
};

export default React.memo(ChildrenComments);

import React, { useCallback, useState, useEffect } from 'react';
import Modal from './Modal';
import { useSelector, useDispatch } from 'react-redux';
import { type ReduxState, acknowledgeMessage } from '../contexts/usersSlice';
import userSettings from '../api/userSettings';

interface OneTimeModalProps {
  title: string;
  children: React.ReactNode;
  modalKey: string;
}

const OneTimeModal = ({ title, children, modalKey }: OneTimeModalProps) => {
  const unacknowledgedMessageKeys = useSelector((state: ReduxState) => state.users.unacknowledgedMessageKeys);
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (unacknowledgedMessageKeys.includes(modalKey)) {
      setIsVisible(true);
    }
  }, [unacknowledgedMessageKeys, modalKey]);

  const handleClose = useCallback(async () => {
    setIsVisible(false);
    dispatch(acknowledgeMessage(modalKey));
    await userSettings.acknowledgedUserMessage(modalKey);
  }, [dispatch, modalKey]);

  if (!isVisible) {
    return <> </>;
  }

  return <Modal title={title} size="lg" children={children} primaryActionTitle="Close" onPrimaryAction={handleClose} />;
};

export default OneTimeModal;

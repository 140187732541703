import { Procedure } from 'shared/lib/types/views/procedures';
import projectUtil from '../lib/projectUtil';
import { useSettings } from '../contexts/SettingsContext';
import { useCallback, useMemo } from 'react';
import { useDatabaseServices } from '../contexts/DatabaseContext';

export const ID_PLACEHOLDER_SUFFIX = '###';

interface UseAutoProcedureIdReturnType {
  generatePlaceholderId: (projectId?: string) => string;
  tryUpdateDocWithUniqueId: (
    doc: Procedure,
    forceUpdate?: boolean
  ) => Promise<void>;
  isAutoProcedureIdEnabled: boolean;
}

const useAutoProcedureId = (
  procedure?: Procedure
): UseAutoProcedureIdReturnType => {
  const { projects, config } = useSettings();
  const { services } = useDatabaseServices();

  const isAutoProcedureIdEnabled = useMemo(
    () =>
      Boolean(
        config?.auto_procedure_id_enabled &&
          (procedure ? procedure.auto_procedure_id_enabled : true)
      ),
    [config?.auto_procedure_id_enabled, procedure]
  );

  const generatePlaceholderId = useCallback(
    (projectId?: string) => {
      if (!isAutoProcedureIdEnabled) {
        return '';
      }
      const projectCode = projectUtil.getProjectCode(projects, projectId);
      return projectCode
        ? `${projectCode}-${ID_PLACEHOLDER_SUFFIX}`
        : ID_PLACEHOLDER_SUFFIX;
    },
    [projects, isAutoProcedureIdEnabled]
  );

  const tryUpdateDocWithUniqueId = useCallback(
    async (doc: Procedure, forceUpdate = false) => {
      if (!doc) {
        return;
      }
      if (
        config?.auto_procedure_id_enabled &&
        doc.auto_procedure_id_enabled &&
        (forceUpdate || doc.code?.endsWith(ID_PLACEHOLDER_SUFFIX))
      ) {
        const projectCode = projectUtil.getProjectCode(
          projects,
          doc.project_id
        );
        try {
          doc.code = await services.procedures.getUniqueProcedureId(
            projectCode
          );
        } catch {
          /* Keep placeholder ID */
        }
      }
    },
    [config?.auto_procedure_id_enabled, projects, services.procedures]
  );

  return {
    generatePlaceholderId,
    tryUpdateDocWithUniqueId,
    isAutoProcedureIdEnabled,
  };
};

export default useAutoProcedureId;

const MixpanelUtil = {
  // session: Superlogin session object from /login
  updateWithSession: (mixpanel, session) => {
    if (!mixpanel || !session) {
      return;
    }
    // Reference: https://mixpanel.com/report/2327177/view/2872705/setup/
    mixpanel.identify(session.user_id);
    mixpanel.people.set({
      $email: session.user_id, // Revisit if email becomes distinct from user_id
      USER_ID: session.user_id,
    });
    if (session.profile && session.profile.signup_at) {
      mixpanel.people.set({ 'Sign up date': session.profile.signup_at });
    }
  },

  updateWithTeam: (mixpanel, teamId, teamName) => {
    if (!mixpanel) {
      return;
    }
    mixpanel.set_group('team', teamId);
    mixpanel.get_group('team', teamId).set({ $name: teamName });
  },
};

export default MixpanelUtil;

import { API_URL } from '../config';
import superlogin from './superlogin';
import {
  ApiKeyDisplayInfo,
  ApiKeyDisplayInfoJson,
  ApiKeyStatus,
  GeneratedApiKey,
} from 'shared/lib/types/apiKeys';
import { AxiosResponse } from 'axios';

/**
 * Service for managing API keys.
 */
class ApiKeysService {
  private readonly teamId: string;

  constructor(teamId: string) {
    this.teamId = teamId;
  }

  /**
   * Requests a new API key from the backend.
   */
  async generateApiKey(): Promise<GeneratedApiKey> {
    const url = `${API_URL}/teams/${this.teamId}/api-keys`;
    const response = await superlogin.getHttp().post(url);
    return response.data;
  }

  /**
   * Retrieves all the API keys for the current team.
   */
  async getApiKeys(): Promise<ApiKeyDisplayInfo[]> {
    const url = `${API_URL}/teams/${this.teamId}/api-keys`;
    const response = await superlogin.getHttp().get(url);
    return response.data.data.map((jsonKeyInfo: ApiKeyDisplayInfoJson) => {
      return ApiKeyDisplayInfo.fromJSON(jsonKeyInfo);
    });
  }

  /**
   * Requests that the given keys be deactivated on the backend.
   *
   * @param keyIds Public API key IDs.
   */
  async deactivateApiKeys(
    keyIds: ReadonlyArray<string>
  ): Promise<AxiosResponse> {
    return this.setApiKeyStatuses(keyIds, ApiKeyStatus.Inactive);
  }

  /**
   * Requests that the given keys be activated on the backend.
   *
   * @param keyIds Public API key IDs.
   */
  async activateApiKeys(keyIds: ReadonlyArray<string>): Promise<AxiosResponse> {
    return this.setApiKeyStatuses(keyIds, ApiKeyStatus.Active);
  }

  private async setApiKeyStatuses(
    keyIds: ReadonlyArray<string>,
    status: ApiKeyStatus
  ): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/api-keys`;
    return superlogin.getHttp().patch(url, { ids: keyIds, status });
  }
}

export default ApiKeysService;

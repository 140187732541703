import { Tool, ToolInstance } from '../../types/api/manufacturing/tools/models';

export const MILLIS_PER_DAY = 24 * 60 * 60 * 1000;

export class ToolMaintenance {
  private readonly nextScheduledMaintenance: Date;

  // for toString
  private readonly toolId: number;
  private readonly toolInstanceId: number;

  private constructor(
    lastMaintainedAt: Date,
    maintenanceIntervalDays: number,
    toolId: number,
    toolInstanceId: number
  ) {
    this.nextScheduledMaintenance = new Date(
      lastMaintainedAt.getTime() + maintenanceIntervalDays * MILLIS_PER_DAY
    );
    this.toolId = toolId;
    this.toolInstanceId = toolInstanceId;
  }

  static from(toolInstance: ToolInstance, tool: Tool): ToolMaintenance {
    if (!tool.maintenance_interval_days) {
      throw new Error('maintenance interval not set for tool');
    }
    return new ToolMaintenance(
      toolInstance.last_maintained_at ?? toolInstance.created_at,
      tool.maintenance_interval_days,
      tool.id,
      toolInstance.id
    );
  }

  get nextScheduled() {
    return this.nextScheduledMaintenance;
  }

  get overdue() {
    return this.nextScheduledMaintenance.getTime() < Date.now();
  }

  get approaching() {
    return !this.overdue && this.dueIn <= 7;
  }

  get dueIn() {
    if (this.overdue) {
      return 0;
    }
    return Math.floor(
      (this.nextScheduledMaintenance.getTime() - Date.now()) / MILLIS_PER_DAY
    );
  }

  get overdueBy() {
    if (this.nextScheduledMaintenance.getTime() > Date.now()) {
      return 0;
    }
    return Math.floor(
      (Date.now() - this.nextScheduledMaintenance.getTime()) / MILLIS_PER_DAY
    );
  }
}

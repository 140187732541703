import superlogin, { getSession } from './superlogin';
import { E3Session } from 'shared/lib/types/api/util';
import { API_URL } from '../config';
import axios from 'axios';

export type SSOProvider = {
  provider: string | null;
  message: string | null;
};

const getRequestHeaders = async (): Promise<{ Authorization: string }> => {
  const session = getSession();
  if (!session) {
    throw new Error('No session found');
  }
  const encoded = btoa(`${session.token}:${session.password}`);
  return { Authorization: `Basic ${encoded}` };
};

const getSSOProvider = async (email: string): Promise<SSOProvider> => {
  return axios
    .post(`${API_URL}/sso/provider`, { email }, { withCredentials: true })
    .then((response) => response.data);
};

const loginWithSSO = async (token: string): Promise<E3Session> => {
  return axios
    .post(`${API_URL}/sso/session`, { token }, { withCredentials: true })
    .then((response) => response.data)
    .then((session) => {
      // Imitate superlogin-client and manually perform socialLogin().
      session.serverTimeDiff = session.issued - Date.now();
      superlogin.setSession(session);
      superlogin._onLogin(session);
      return session;
    });
};

const agreeToDisclaimer = async (): Promise<E3Session> => {
  const url = `${API_URL}/auth/disclaimer/agree`;
  return superlogin
    .getHttp()
    .post(url)
    .then((response) => response.data)
    .then((session) => {
      // Imitate superlogin-client and manually perform socialLogin().
      session.serverTimeDiff = session.issued - Date.now();
      superlogin.setSession(session);
      superlogin._onLogin(session);
      return session;
    });
};

export default getRequestHeaders;
export { getSSOProvider, loginWithSSO, agreeToDisclaimer };

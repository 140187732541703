import { useCallback } from 'react';
import BatchStepHeader, { BatchStepHeaderProps } from './BatchStepHeader';

type ProcedureBatchStepHeaderProps = BatchStepHeaderProps & {
  batchId: string;
  onRefChanged: (id: string, element: HTMLElement) => void;
};

const ProcedureBatchStepHeader = ({
  batchId,
  onRefChanged,
  ...batchStepHeaderProps
}: ProcedureBatchStepHeaderProps) => {
  const onBatchStepRefChanged = useCallback(
    (element: HTMLTableSectionElement) => {
      onRefChanged(batchId, element);
    },
    [batchId, onRefChanged]
  );

  return (
    <tbody ref={onBatchStepRefChanged}>
      <tr>
        <td colSpan={3}>
          <table className="table-fixed w-full border-collapse" cellSpacing={0} cellPadding={0} border={0}>
            <thead>
              <tr>
                <th className="w-8"></th>
                <th className="w-full"></th>
                <th className="w-8"></th>
              </tr>
            </thead>
            <tbody role="region">
              <tr>
                <td></td>
                <td>
                  <table className="table-fixed w-full border-collapse" cellSpacing={0} cellPadding={0} border={0}>
                    <thead>
                      <tr>
                        <th className="w-8"></th>
                        <th className="w-full"></th>
                        <th className="w-8"></th>
                      </tr>
                    </thead>
                    <tbody role="region">
                      <tr>
                        <td colSpan={3}>
                          <BatchStepHeader {...batchStepHeaderProps} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  );
};

export default ProcedureBatchStepHeader;

import React, { useCallback } from 'react';
import { generateHiddenClassString } from '../../lib/styles';
import SubstepNumber from '../SubstepNumber';
import Spacer from '../Spacer';
import ReferenceTextViewDiff from '../ReferenceTextViewDiff';
import ProcedureDiffText from '../ProcedureDiffText';
import { TextBlockDiffElement } from 'shared/lib/types/views/procedures';

interface BlockTextProps {
  block: TextBlockDiffElement;
  blockLabel?: string;
  isHidden?: boolean;
  onRefChanged?: (id: string, element) => void;
  scrollMarginTopValueRem?: number;
}

const ReviewBlockText = ({ block, blockLabel, isHidden, onRefChanged, scrollMarginTopValueRem }: BlockTextProps) => {
  const onTextBlockRefChanged = useCallback(
    (element) => {
      return onRefChanged && onRefChanged(block.id, element);
    },
    [block.id, onRefChanged]
  );

  // Layout is intended for CSS grid template defined in Run.js and Procedure.js
  return (
    <>
      <tr aria-label="Content Block" role="region">
        <td></td>
        <td colSpan={2}>
          <div className={generateHiddenClassString('', isHidden)}></div>
          <div
            aria-label="Text Content"
            className={generateHiddenClassString('mt-2 mr-8 flex page-break', isHidden)}
            ref={onTextBlockRefChanged}
            style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
          >
            <Spacer />
            <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
            {/* Content */}
            <div className="min-w-0 w-full self-start">
              {block && block.text && (
                <div className="max-w-full break-words">
                  {block.tokens && <ReferenceTextViewDiff block={block} />}
                  {!block.tokens && (
                    <ProcedureDiffText
                      diffValue={block.text}
                      diffChangeState={block.diff_change_state}
                      useMarkdownWhenNoDiff={true}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default ReviewBlockText;

import React, { useCallback, useMemo } from 'react';
import AlertIcon from '../../Blocks/AlertIcon';
import { AlertBlockDiffElement, ReleaseAlertBlock } from 'shared/lib/types/views/procedures';
import ProcedureFieldDiff from '../../ProcedureFieldDiff';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import ProcedureDiffText from '../../ProcedureDiffText';

export const AlertSubtypes = Object.freeze({
  NOTE: 'note',
  CAUTION: 'caution',
  WARNING: 'warning',
} as const);

/*
 * Component for rendering a Block of type BlockType.Alert.
 * Conforms to TypedBlockInterface, see comments in useBlockComponents.js
 */
interface AlertReviewProps {
  block: AlertBlockDiffElement;
  redlinedBlock?: ReleaseAlertBlock;
  onContentRefChanged?: (id: string, element: HTMLElement | null) => void;
  scrollMarginTopValueRem?: number;
  isDark?: boolean;
  blockId?: string;
}

const AlertReview = ({
  block,
  blockId = block.id,
  redlinedBlock,
  onContentRefChanged,
  scrollMarginTopValueRem,
  isDark = false,
}: AlertReviewProps) => {
  const alertBgColor = useMemo(() => {
    const subtype = sharedDiffUtil.getDiffValue(block, 'subtype', 'new');
    if (subtype === AlertSubtypes.NOTE) {
      return isDark ? 'app-bg-blue-note-dark' : 'app-bg-blue-note';
    } else if (subtype === AlertSubtypes.CAUTION) {
      return isDark ? 'app-bg-yellow-caution-dark' : 'app-bg-yellow-caution';
    } else if (subtype === AlertSubtypes.WARNING) {
      return isDark ? 'app-bg-red-warning-dark' : 'app-bg-red-warning';
    }
  }, [block, isDark]);

  const onAlertBlockRefChanged = useCallback(
    (element) => {
      if (!onContentRefChanged) {
        return;
      }
      return onContentRefChanged(blockId, element);
    },
    [blockId, onContentRefChanged]
  );

  // Layout is intended for CSS grid template defined in Run.js and Procedure.js
  return (
    <div
      ref={onAlertBlockRefChanged}
      style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
      className={`flex px-2 py-1 rounded ${alertBgColor}`}
    >
      <div className="flex items-center h-6 text-gray-600">
        <AlertIcon alert={block} />
      </div>
      <div className="ml-2 min-w-0 w-full break-words">
        {/* Render text with redlined changes */}
        {redlinedBlock && (
          <ProcedureFieldDiff
            original={sharedDiffUtil.getDiffValue(block, 'text', 'old')}
            redlined={redlinedBlock.text}
          />
        )}
        {/* Render block text */}
        {!redlinedBlock && (
          <ProcedureDiffText
            diffValue={block.text}
            useMarkdownWhenNoDiff={true}
            // We don't want to show strikethrough or red text for added/removed alerts, since we're already using a diff container.
            diffChangeState={
              block.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED ||
              block.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED
                ? ARRAY_CHANGE_SYMBOLS.UNCHANGED
                : block.diff_change_state
            }
          />
        )}
      </div>
    </div>
  );
};

export default AlertReview;

import { useCallback, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import printPDF from '../lib/printPDF';
import { useRunContext } from '../contexts/RunContext';
import csvUtil from '../lib/input/csvUtil';
import { useMixpanel } from '../contexts/MixpanelContext';
import { useSettings } from '../contexts/SettingsContext';
import { runSummaryPath } from '../lib/pathUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import Button from './Button';
import ThreeDotMenu from '../elements/ThreeDotMenu';
import { MenuContextLabelAction } from './MenuContext';

interface RunActionButtonsProps {
  isPreviewMode: boolean;
}

export default function RunActionButtons({ isPreviewMode }: RunActionButtonsProps) {
  const { run, isSingleCardEnabled } = useRunContext();
  const { mixpanel } = useMixpanel();
  const { config } = useSettings();
  const [csvData, setCsvData] = useState<Array<Array<string | null>>>([]);
  const { currentTeamId } = useDatabaseServices();

  const extraMenuItems: Array<MenuContextLabelAction> = [
    {
      type: 'label',
      label: 'Print',
      data: {
        icon: 'print',
        onClick: printPDF,
        disabled: isSingleCardEnabled,
      },
    },
  ];

  const prepareCsv = useCallback(() => {
    if (mixpanel) {
      mixpanel.track('Downloaded CSV');
    }
    const csvArray = csvUtil.getCsvArray(run, config);
    setCsvData(csvArray);
  }, [mixpanel, run, config]);

  const csvFilename = useMemo(() => {
    if (!run || !run.code || !run.name || !run.starttime) {
      return '';
    }
    return `${run.code}_${run.name}_${run.starttime}.csv`;
  }, [run]);

  const trackClick = useCallback(
    (text: string) => {
      if (mixpanel) {
        mixpanel.track(`Click Run Action`, { Action: text });
      }
    },
    [mixpanel]
  );

  return (
    <>
      {!isPreviewMode && (
        <div className="flex flex-row w-full items-center justify-between print:hidden">
          <div>
            <Button
              type="tertiary"
              size="sm"
              to={runSummaryPath(currentTeamId, run._id)}
              leadingIcon="file-alt"
              onClick={() => trackClick('View Summary')}
            >
              View Summary
            </Button>

            <CSVLink className="-ml-1" data={csvData} filename={csvFilename} onClick={prepareCsv}>
              <Button type="tertiary" leadingIcon="download">
                Export CSV
              </Button>
            </CSVLink>
          </div>
          <ThreeDotMenu menuActions={extraMenuItems} menuLabel="More Options" />
        </div>
      )}
      {isPreviewMode && (
        <div className="flex flex-row w-full items-center justify-end print:hidden">
          <div>
            <Button type="tertiary" size="sm" leadingIcon="print" onClick={printPDF}>
              Print
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type SortConfig = {
  sortColumn: string;
  sortDirection: 'asc' | 'desc';
};

export type SortIconProps = {
  columnName: string;
  sortConfig: SortConfig;
  onSortConfigChange: (newConfig: SortConfig) => void;
};

const onColumnClick = (columnClicked: string, currentConfig: SortConfig, onChange: (newConfig: SortConfig) => void) => {
  if (currentConfig.sortColumn !== columnClicked) {
    onChange({ sortColumn: columnClicked, sortDirection: 'asc' });
  } else {
    onChange({ sortColumn: columnClicked, sortDirection: currentConfig.sortDirection === 'asc' ? 'desc' : 'asc' });
  }
};

const SortableGridColumnHeader = ({ columnName, sortConfig, onSortConfigChange }: SortIconProps) => {
  const showAscending = sortConfig.sortColumn !== columnName || sortConfig.sortDirection === 'asc';
  const showDescending = sortConfig.sortColumn !== columnName || sortConfig.sortDirection === 'desc';
  return (
    <button
      className="flex flex-row items-center"
      onClick={() => onColumnClick(columnName, sortConfig, onSortConfigChange)}
    >
      <span className="ml-2">{columnName}</span>
      <div className="flex flex-col ml-1" style={{ fontSize: '8px' }}>
        <FontAwesomeIcon icon="chevron-up" className={`${showAscending ? '' : 'hidden'}`} />
        <FontAwesomeIcon icon="chevron-down" className={`${showDescending ? '' : 'hidden'}`} />
      </div>
    </button>
  );
};
export default SortableGridColumnHeader;

import { useCallback } from 'react';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { cloneDeep } from 'lodash';
import attachmentUtil from '../lib/attachmentUtil';
import procedureUtil from '../lib/procedureUtil';
import { Procedure, Step } from 'shared/lib/types/views/procedures';
import { DatabaseServices } from '../contexts/proceduresSlice';

type UseStepSnippetReturnType = {
  saveStepSnippet: (
    sectionIndex: number,
    stepIndex: number,
    values: { name: string; description: string },
    step: Step,
    procedure: Procedure
  ) => Promise<string>;

  updateStepSnippet: (
    values: { name: string; description: string },
    step: Step & { step?: Step },
    present: Step,
    procedure: Procedure,
    revert?: boolean
  ) => Promise<void>;
};

export const useStepSnippet = (): UseStepSnippetReturnType => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  const saveStepSnippet = useCallback(
    async (
      sectionIndex: number,
      stepIndex: number,
      values: { name: string; description: string },
      step: Step,
      procedure: Procedure
    ) => {
      const updatedStep = cloneDeep(step);
      const snippetId = procedureUtil.generateSnippetId();
      const reversedIdsMap =
        procedureUtil.generateNewIdsMapForStep(updatedStep);
      const snippetStep = procedureUtil.copyStep(updatedStep, reversedIdsMap);

      const idsMap = Object.fromEntries(
        Object.entries(reversedIdsMap).map(([idInProcedure, idInSnippet]) => [
          idInSnippet,
          idInProcedure,
        ])
      );

      await attachmentUtil.uploadAllFilesFromStep(step, services.attachments);

      await services.settings.saveStepSnippet({
        id: snippetId,
        name: values.name,
        description: values.description,
        step: snippetStep,
        isTestSnippet: Boolean(procedure.procedure_type),
      });

      Object.assign(updatedStep, {
        snippet_id: snippetId,
        snippet_name: values.name,
        snippet_rev: 1,
        snippet_ids_map: idsMap,
      });

      return snippetId;
    },
    [services.attachments, services.settings]
  );

  const updateStepSnippet = useCallback(
    async (
      values: { name: string; description: string },
      step: Step & { step?: Step },
      present: Step,
      procedure: Procedure,
      revert = false
    ) => {
      const updatedStep = revert
        ? cloneDeep((step as { step: Step }).step)
        : cloneDeep(step);

      await attachmentUtil.uploadAllFilesFromStep(
        updatedStep,
        services.attachments
      );

      if (present.snippet_id) {
        await services.settings.saveStepSnippet({
          id: present.snippet_id,
          name: values.name,
          description: values.description,
          step: updatedStep,
          isTestSnippet: Boolean(procedure.procedure_type),
        });
      }

      Object.assign(present, {
        name: values.name,
        description: values.description,
      });
    },
    [services.attachments, services.settings]
  );

  return { saveStepSnippet, updateStepSnippet };
};

export default useStepSnippet;

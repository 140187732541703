import { Socket } from 'socket.io-client';
import onNetworkChanged from './lib/network';
import {
  EntityType,
  EventMessage,
} from 'shared/lib/types/realtimeUpdatesTypes';
import {
  SocketIoActions,
  getEmitEventName,
  getRoomEventName,
} from 'shared/lib/realtimeUpdates';

export type CancelFunc = {
  cancel: () => void;
};

class RealtimeService {
  teamId: string;
  socket: Socket;
  roomsJoined: Map<string, { entityType: EntityType; teamId: string }>;

  constructor(teamId: string, socket: Socket) {
    this.teamId = teamId;
    this.socket = socket;
    this.roomsJoined = new Map();
  }

  observeEvent(
    callback: (data: EventMessage) => void,
    entityType: EntityType,
    entityId?: number | string
  ): CancelFunc {
    return this._listenForRoomEvents(callback, entityType, entityId);
  }

  // Do not use this pattern unless necessary, preferably use the `useRealtimeUpdates` hook
  onUsersEvent(callback: (data: EventMessage) => void): CancelFunc {
    return this._listenForRoomEvents(callback, 'users');
  }

  onOperatorRoleEvent(callback: (data: EventMessage) => void): CancelFunc {
    return this._listenForRoomEvents(callback, 'operator_roles');
  }

  onUnitEvent(callback: (data: EventMessage) => void): CancelFunc {
    return this._listenForRoomEvents(callback, 'units');
  }

  private _buildRoomPayload(entityType, entityId) {
    return {
      entityType,
      teamId: this.teamId,
      ...(entityId && { entityId }),
    };
  }

  private _listenForRoomEvents(
    callback: (data: EventMessage) => void,
    entityType: EntityType,
    entityId?: number | string
  ) {
    let networkListener;
    if (!this.roomsJoined.has(this._getRoomEventName(entityType, entityId))) {
      this._registerRoom(entityType, entityId);
      this._onRoomEvent(callback, entityType, entityId);
      networkListener = onNetworkChanged(({ online }) => {
        if (online) {
          callback({ action: 'BACK_ONLINE', data: {} });
        }
      });
      this.roomsJoined.set(this._getRoomEventName(entityType, entityId), {
        entityType,
        teamId: this.teamId,
      });
    } else {
      throw new Error('Listener for this room already in use');
    }
    return {
      cancel: () => {
        if (networkListener) {
          networkListener.cancel();
          networkListener = null;
        }
        this.roomsJoined.delete(this._getRoomEventName(entityType, entityId));
        this.socket.off(
          RealtimeService._getEmitEventName(entityType, entityId)
        );
        this.socket.emit(
          SocketIoActions.leaveRoom,
          this._buildRoomPayload(entityType, entityId)
        );
      },
    };
  }

  private _registerRoom(entityType, entityId) {
    this.socket.emit(
      SocketIoActions.joinRoom,
      this._buildRoomPayload(entityType, entityId)
    );
  }

  private _getRoomEventName(entityType, entityId) {
    return getRoomEventName(this._buildRoomPayload(entityType, entityId));
  }

  private static _getEmitEventName(entityType, entityId) {
    return getEmitEventName({ entityType, entityId });
  }

  private _onRoomEvent(
    callback: (data: EventMessage) => void,
    entityType: EntityType,
    entityId?: string | number
  ) {
    this.socket.on(
      RealtimeService._getEmitEventName(entityType, entityId),
      (data: EventMessage) => {
        callback(data);
      }
    );
  }
}

export default RealtimeService;

import LabelDisplay from './LabelDisplay';
import { isEmptyValue } from 'shared/lib/text';
import NoValueRecorded from './NoValueRecordedDisplay';
import RecordedValueDisplay from './RecordedValueDisplay';
import { useMemo } from 'react';
import {
  FieldInputNumberBlock,
  FieldInputTextBlock,
  RunFieldInputNumberBlock,
  RunFieldInputTextBlock,
} from 'shared/lib/types/views/procedures';
import ReferenceTokenTooltip from './ReferenceTokenTooltip';
import ProcedureDiffText from '../../ProcedureDiffText';

interface TextDisplayProps {
  block: FieldInputTextBlock | FieldInputNumberBlock;
  referenceId?: string;
  referenceFieldIndex?: number;
}

const TextDisplay = ({ block, referenceId, referenceFieldIndex }: TextDisplayProps) => {
  const recordedValue = useMemo(() => {
    return (block as RunFieldInputTextBlock | RunFieldInputNumberBlock).recorded?.value;
  }, [block]);

  return (
    <div className="flex flex-row items-start">
      <div className="mt-1">
        <LabelDisplay text={block.name} />
      </div>
      <div className="mt-1 px-1.5 flex items-center">
        <span>=</span>
      </div>
      <div className="h-fit flex flex-nowrap group ">
        {referenceId && <ReferenceTokenTooltip referenceId={referenceId} referenceFieldIndex={referenceFieldIndex} />}
        {(!(block as RunFieldInputTextBlock | RunFieldInputNumberBlock).recorded || isEmptyValue(recordedValue)) &&
          (referenceId ? (
            <NoValueRecorded />
          ) : (
            <div className="mt-1 px-2 py-1.5 w-36 text-sm border-2 border-gray-400 rounded bg-gray-300 bg-opacity-50" />
          ))}
        {recordedValue !== undefined && !isEmptyValue(recordedValue) && <RecordedValueDisplay value={recordedValue} />}
        {block.units && (
          <span className="ml-2 mt-1 self-start">
            <ProcedureDiffText diffValue={block.units} />
          </span>
        )}
      </div>
    </div>
  );
};

export default TextDisplay;

import { useSettings } from '../contexts/SettingsContext';
interface SubstepNumberProps {
  blockLabel?: string;
  isHidden?: boolean; // deprecate this.  Only for to the side images it seems.
  hasExtraVerticalSpacing?: boolean;
}

const SubstepNumber = ({ blockLabel, isHidden, hasExtraVerticalSpacing }: SubstepNumberProps) => {
  const { getSetting } = useSettings();
  const showSubstepLabels = getSetting('show_substep_labels', false);

  return (
    <div>
      {showSubstepLabels && blockLabel && (
        <div className={`${isHidden ? 'hidden' : ''}`}>
          <div className={`w-12 text-xs text-gray-400  ${hasExtraVerticalSpacing ? 'mt-3.5' : 'mt-1'}`}>
            {blockLabel}
          </div>
        </div>
      )}
    </div>
  );
};

export default SubstepNumber;

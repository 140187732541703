import React, { useMemo } from 'react';
import DiffContainerContents from './DiffContainerContents';

interface AddedDiffContainerProps {
  label: string;
  children: React.ReactNode;
  width?: 'full' | 'fit';
  onScrollToDiffRefChanged?: (HTMLElement) => void;
  isTextSticky?: boolean;
}

const AddedDiffContainer = ({
  label,
  children,
  width,
  onScrollToDiffRefChanged,
  isTextSticky,
}: AddedDiffContainerProps) => {
  const diffMessage = useMemo(() => (label ? `${label} was added` : 'Added'), [label]);

  return (
    <DiffContainerContents
      diffIcon="wand-magic-sparkles"
      diffMessage={diffMessage}
      width={width}
      onScrollToDiffRefChanged={onScrollToDiffRefChanged}
      isTextSticky={isTextSticky}
      isAdded={true}
    >
      {children}
    </DiffContainerContents>
  );
};

export default AddedDiffContainer;

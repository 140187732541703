import { useMemo } from 'react';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import diffUtil from '../lib/diffUtil';
import ProcedureFieldDiff from './ProcedureFieldDiff';
import { TextBlockDiffElement } from 'shared/lib/types/views/procedures';

interface BlockTextProps {
  block: TextBlockDiffElement;
}

const ReferenceTextViewDiff = ({ block }: BlockTextProps) => {
  const formatTokenValue = (token, value: string) => {
    if (!value) {
      return '';
    }
    return token.type === 'text' ? value : `{${value}}`;
  };

  const useMemoizedOutput = (block, symbolCheck: string, defaultEmpty: boolean) =>
    useMemo(() => {
      let output = '';

      if (symbolCheck === ARRAY_CHANGE_SYMBOLS.ADDED && block.diff_change_state === ARRAY_CHANGE_SYMBOLS.ADDED) {
        return '';
      }
      if (symbolCheck === ARRAY_CHANGE_SYMBOLS.REMOVED && block.diff_change_state === ARRAY_CHANGE_SYMBOLS.REMOVED) {
        return '';
      }

      if (!block.tokens) {
        return output;
      }

      block.tokens.forEach((token) => {
        if (diffUtil.isDiffPair(token)) {
          const [symbol, tokenObject] = token;

          let value =
            symbol === symbolCheck
              ? ''
              : sharedDiffUtil.getDiffValue<string>(
                  { value: tokenObject.value },
                  'value',
                  defaultEmpty ? 'new' : 'old'
                );

          if (!symbol && value === output) {
            value = '';
          }

          output += formatTokenValue(tokenObject, value);
        } else {
          output += formatTokenValue(token, token.value || '');
        }
      });

      return output;
    }, [block, defaultEmpty, symbolCheck]);

  const oldValue = useMemoizedOutput(block, ARRAY_CHANGE_SYMBOLS.ADDED, false);
  let newValue: string | undefined = useMemoizedOutput(block, ARRAY_CHANGE_SYMBOLS.REMOVED, true);

  if (oldValue === newValue) {
    newValue = undefined;
  }

  return <ProcedureFieldDiff original={oldValue} redlined={newValue} useMarkdownWhenNoDiff={true} />;
};

export default ReferenceTextViewDiff;

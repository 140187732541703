import React from 'react';
import Select, { components, Option } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MultiValueRemove = ({ isDisabled, ...props }) => {
  return (
    <>
      {!isDisabled && <components.MultiValueRemove {...props} />}
      {isDisabled && <div className="mr-1" />}
    </>
  );
};

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <div className="flex pl-3 last:pl-4">
        <FontAwesomeIcon className="text-gray-500" icon="info-circle" />
      </div>
      {children}
    </components.Control>
  );
};

interface DependencySelectProps {
  options: Array<Option>;
  name?: string;
  placeholder: JSX.Element | string;
  opacity: number;
  hoverOpacity: number;
  value: Option;
  onChange: (option: Option) => void;
  ariaLabel: string;
  isDisabled?: boolean;
}

const DependencySelect = ({
  options,
  name,
  placeholder,
  opacity,
  hoverOpacity,
  value,
  onChange,
  ariaLabel,
  isDisabled = false,
}: DependencySelectProps) => {
  const styles = {
    control: (base) => ({
      ...base,
      borderColor: 'rgba(156, 163, 175)',
      backgroundColor: isDisabled ? 'rgb(243 244 246)' : 'white',
      opacity,
      '&:hover': { opacity: hoverOpacity },
      borderWidth: 1,
      minWidth: 40,
    }),
    valueContainer: (base) => ({
      ...base,
      minWidth: 0,
      paddingRight: 0,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: 'rgba(156, 163, 175)',
    }),
  };

  const Placeholder = () => {
    return <div className="text-gray-600">{placeholder}</div>;
  };
  return (
    <Select
      classNamePrefix="react-select"
      styles={styles}
      options={options}
      name={name}
      components={{
        Control,
        Placeholder,
        IndicatorSeparator: () => null,
        MultiValueRemove: (props) => <MultiValueRemove {...props} isDisabled={isDisabled} />,
      }}
      value={value}
      onChange={(option) => {
        if (isDisabled) {
          return;
        }
        return onChange(option);
      }}
      isMulti
      placeholder
      isClearable={false}
      aria-label={ariaLabel}
      isDisabled={isDisabled}
      isSearchable={!isDisabled}
    />
  );
};

export default React.memo(DependencySelect);

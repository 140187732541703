import React from 'react';
import { Helmet } from 'react-helmet-async';

const DefaultTitle = React.memo(() => (
  <Helmet>
    <title>Epsilon3</title>
  </Helmet>
));

export default DefaultTitle;

import React, { useCallback, useState, useMemo } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import Toggle from 'react-toggle';
import runUtil from '../lib/runUtil';
import { Helmet } from 'react-helmet-async';
import useRunObserver from '../hooks/useRunObserver';
import useRun from '../hooks/useRun';
import TextLinkify from '../components/TextLinkify';
import NotFound from '../components/NotFound';
import DateTimeDisplay from '../components/DateTimeDisplay';
import SummarySection from '../components/RunSummary/SummarySection';
import RunProgressBar from '../components/RunProgressBar';
import RunStatusBadge from '../components/RunStatusBadge';
import { ProcedureFilterProvider } from '../contexts/ProcedureFilterContext';
import printPDF from '../lib/printPDF';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSettings } from '../contexts/SettingsContext';
import { RunContextProvider } from '../contexts/RunContext';
import { ProcedureContextProvider } from '../contexts/ProcedureContext';
import useLocationParams from '../hooks/useLocationParams';
import { runViewPath } from '../lib/pathUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import useUrlScrollTo from '../hooks/useUrlScrollTo';
import useTelemetryParameters from '../hooks/useTelemetryParameters';
import RunProcedureVariables from '../components/RunProcedureVariables';

const RunSummary = () => {
  const { id } = useParams();
  const location = useLocation();
  const { run, runNotFound } = useRunObserver({ id });
  const { displaySections } = useRun({ run });
  const { config, getSetting } = useSettings();
  const { currentTeamId } = useDatabaseServices();
  const { searchParams } = useLocationParams(location);
  const { fetchedTelemetryParameters } = useTelemetryParameters({ procedure: run });
  const [filters, setFilters] = useState({
    activity: true,
    data: true,
  });

  const { onScrollToRefChanged, onScrollToId } = useUrlScrollTo({ procedure: run, searchParams });

  const toggleShowData = useCallback(() => {
    setFilters((filters) => ({
      ...filters,
      data: !filters.data,
    }));
  }, []);

  const toggleShowActivity = useCallback(() => {
    setFilters((filters) => ({
      ...filters,
      activity: !filters.activity,
    }));
  }, []);

  /**
   * True if there is any summary content to render.
   *
   * Checks for content marked "include in summary", any step comments, and any
   * end of run comments.
   */
  const hasDisplayContent = useMemo(() => {
    if (!displaySections) {
      return false;
    }
    for (const sectionData of displaySections) {
      for (const step of sectionData[0].steps) {
        for (const content of step.content) {
          if (content.include_in_summary) {
            return true;
          }
        }
        if (step.comments) {
          return true;
        }
      }
    }
    return false;
  }, [displaySections]);

  // Returns true if there are no summary items or end of run comments
  const isSummaryEmpty = useMemo(() => {
    if (!run) {
      return true;
    }
    return !hasDisplayContent && !run.comments;
  }, [hasDisplayContent, run]);

  const scrollTo = useCallback(
    ({ sectionId, stepId, stepHeaderId, contentId }) => {
      const scrollToId = contentId || stepHeaderId || stepId || sectionId;
      onScrollToId({
        sectionId,
        stepId,
        scrollToId,
      });
    },
    [onScrollToId]
  );

  const runStatus = useMemo(() => {
    return runUtil.getRunStatus(run);
  }, [run]);

  const runStepCounts = useMemo(() => {
    return runUtil.getRunStepCounts(run);
  }, [run]);

  // If run is not found show message.
  if (runNotFound) {
    return <NotFound />;
  }

  if (!run) {
    return null;
  }

  return (
    <div className="px-4 lg:px-8 w-full mx-auto py-4 print:m-0 print:p-0">
      <RunContextProvider run={run} fetchedTelemetryParameters={fetchedTelemetryParameters}>
        <div className="mx-6">
          {/* Sets the document title */}
          <Helmet>
            <title>{`Summary · ${run.name}`}</title>
          </Helmet>
          <div className="flex">
            <div className="grow print:hidden">
              {run && (
                <Link to={runViewPath(currentTeamId, run._id)} className="text-blue-700 hover:underline">
                  <span>Go to {run.code}</span>
                  {run.run_number && <span className="ml-1 px-1 bg-slate-300">{run.run_number}</span>}
                </Link>
              )}
            </div>
            <div className="flex shrink-0 items-end print:hidden">
              <button
                className="flex flex-row items-center text-sm font-bold uppercase rounded hover:bg-gray-200"
                type="button"
                onClick={printPDF}
              >
                <div className="flex flex-col">
                  <FontAwesomeIcon className="text-blue-500 text-sm mr-2" icon="print" />
                </div>
                <div>Print</div>
              </button>
            </div>
          </div>
          <div className="-mx-6 px-6 py-6 mt-1 bg-white">
            {/* Title and description */}
            <h1 className="flex flex-row gap-x-1 mb-6">
              <span>Summary of</span>
              <span>{run.code}</span>
              {run.run_number && <span className="px-1 bg-slate-300">{run.run_number}</span>}
              <span>{runUtil.displayName(run, config)}</span>
            </h1>
            {/* Sidebar */}
            <table cellSpacing="0" cellPadding="0" border="0">
              <tbody>
                {run.operation && run.operation.name && (
                  <tr>
                    <td className="py-1">
                      <span className="mr-12 font-semibold">Operation</span>
                    </td>
                    <td className="py-1">{run.operation.name}</td>
                  </tr>
                )}
                <tr>
                  <td className="py-1">
                    <span className="mr-12 font-semibold">Progress</span>
                  </td>
                  <td className="py-1">
                    <RunProgressBar runStatus={runStatus} stepCounts={runStepCounts.runCounts} />
                  </td>
                </tr>
                {run.started_by && (
                  <tr>
                    <td className="py-1">
                      <span className="mr-12 font-semibold">Started By</span>
                    </td>
                    <td className="py-1">{run.started_by?.user_id || run.started_by?.method}</td>
                  </tr>
                )}
                {run.starttime && (
                  <tr>
                    <td className="py-1">
                      <span className="mr-12 font-semibold">Start Time</span>
                    </td>
                    <td className="py-1">
                      <DateTimeDisplay timestamp={run.starttime} />
                    </td>
                  </tr>
                )}
                {run.completedUserId && (
                  <tr>
                    <td className="py-1">
                      <span className="mr-12 font-semibold">Ended By</span>
                    </td>
                    <td className="py-1">{run.completedUserId}</td>
                  </tr>
                )}
                {run.completedAt && (
                  <tr>
                    <td className="py-1">
                      <span className="mr-12 font-semibold">End Time</span>
                    </td>
                    <td className="py-1">{run.completedAt && <DateTimeDisplay timestamp={run.completedAt} />}</td>
                  </tr>
                )}
                <tr>
                  <td className="py-1">
                    <span className="mr-12 font-semibold">Status</span>
                  </td>
                  <td className="py-1">
                    <RunStatusBadge runStatus={runStatus} size="base" />
                  </td>
                </tr>
                {run.comments && (
                  <tr>
                    <td className="py-1">
                      <span className="mr-12 font-semibold">Comment</span>
                    </td>
                    <td className="py-1">
                      {/* Render end of run comments */}
                      {run.comments &&
                        run.comments.map((comment, index) => (
                          <div key={index} className="ml-0.5 pb-1 pt-1">
                            <div>
                              <div className=" text-sm">
                                <span className="font-semibold">{comment.user_id}</span>
                                <span>
                                  , <DateTimeDisplay timestamp={comment.created_at} />
                                </span>
                              </div>
                              <div className="pt-1">
                                <TextLinkify>
                                  <span className="whitespace-pre-line">{comment.text}</span>
                                </TextLinkify>
                              </div>
                            </div>
                          </div>
                        ))}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex gap-8 mx-6 mt-8">
          <label>
            <div className="flex flex-row items-center">
              <Toggle checked={filters.data} onChange={toggleShowData} aria-label="Show Summary Data" icons={false} />
              <div className="ml-2 text-sm font-bold uppercase">Show Summary Data</div>
            </div>
          </label>
          <label>
            <div className="flex flex-row items-center">
              <Toggle
                checked={filters.activity}
                onChange={toggleShowActivity}
                aria-label="Show Activity"
                icons={false}
              />
              <div className="ml-2 text-sm font-bold uppercase">Show Activity</div>
            </div>
          </label>
        </div>
        <div className="mx-2 mt-2 bg-red-100d">
          {/* Content */}
          <ProcedureFilterProvider displaySections={displaySections} filters={filters} run={run}>
            <ProcedureContextProvider procedure={run} scrollTo={scrollTo}>
              <RunProcedureVariables
                isEnabled={false}
                variables={run.variables ?? []}
                onRefChanged={onScrollToRefChanged}
                scrollMarginTopValueRem={0}
              />
              {/* Grid tracks: [section, bullet and content 1, content 2 and checkbox] */}
              <table cellSpacing="0" cellPadding="0" border="0">
                <thead>
                  <tr>
                    <th className="w-8"></th>
                    <th className="w-full"></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* Show empty summary message */}
                  {isSummaryEmpty && (
                    <tr>
                      <td className="pt-4 italic whitespace-nowrap">
                        This summary has no content. Select content in the "Edit Procedure" page to show in the
                        summaries of future runs.
                      </td>
                    </tr>
                  )}
                  {/* Show summary content */}
                  {!isSummaryEmpty &&
                    displaySections.map(([section, sectionIndex]) => (
                      <SummarySection
                        key={`section.${sectionIndex}`}
                        section={section}
                        sectionKey={runUtil.displaySectionKey(
                          run.sections,
                          sectionIndex,
                          getSetting('display_sections_as', 'letters')
                        )}
                        stepKeyFormatter={(stepIndex) =>
                          runUtil.displaySectionStepKey(
                            run.sections,
                            sectionIndex,
                            stepIndex,
                            getSetting('display_sections_as', 'letters')
                          )
                        }
                        repeatKey={runUtil.displayRepeatKey(run.sections, sectionIndex)}
                        onRefChanged={onScrollToRefChanged}
                      />
                    ))}
                </tbody>
              </table>
            </ProcedureContextProvider>
          </ProcedureFilterProvider>
        </div>
      </RunContextProvider>
    </div>
  );
};

export default RunSummary;

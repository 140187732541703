import React from 'react';
import PropTypes from 'prop-types';
import numericalFieldHandler from '../../utils/numericalFieldHandler';

const EndAfter = ({ id, after, handleChange }) => (
  <div className="flex flex-row items-center space-x-3">
    <input
      id={id}
      name="end.after"
      aria-label="End after"
      className="border rounded w-12 h-full text-center"
      value={after}
      onChange={numericalFieldHandler(handleChange)}
    />
    <span>executions.</span>
  </div>
);

EndAfter.propTypes = {
  id: PropTypes.string.isRequired,
  after: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default EndAfter;

import { useCallback, useEffect, useMemo, useState } from 'react';
import useRealtimeUpdates, { useRealtimeUpdatesProps } from '../../hooks/useRealtimeUpdates';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { MetadataOptions, Risk as ServerRisk, Status } from 'shared/lib/types/postgres/risks';
import { useSettings } from '../../contexts/SettingsContext';
import { mapServerRiskToClient } from '../api/risks';
import { Risk } from '../types';

const useRealtimeRisks = () => {
  const { services } = useDatabaseServices();
  const { isRisksEnabled } = useSettings();
  const [statuses, setStatuses] = useState<Array<Status>>([]);

  const getInitialItems = useCallback(async () => {
    if (!services) {
      return;
    }
    return services.risk.listRisks();
  }, [services]);

  const { realtimeData, isLoading } = useRealtimeUpdates<ServerRisk>({
    entityType: 'risks',
    initialGetter: getInitialItems,
  } as useRealtimeUpdatesProps);

  const risks = useMemo<Array<Risk>>(() => {
    if (isLoading) {
      return [];
    }
    return (realtimeData as Array<ServerRisk>).map(mapServerRiskToClient);
  }, [isLoading, realtimeData]);

  const refreshMetadata = useCallback(() => {
    return services.risk
      .getMetadataOptions()
      .then(({ status: statuses }: MetadataOptions) => {
        setStatuses(statuses);
      })
      .catch(() => undefined);
  }, [services.risk]);

  useEffect(() => {
    if (!isRisksEnabled?.()) {
      return;
    }
    refreshMetadata();
  }, [isRisksEnabled, refreshMetadata]);

  return { isLoading, risks, statuses };
};

export default useRealtimeRisks;

import { useState, useMemo, useEffect, useRef } from 'react';
import swimlaneLib, { CollapseLevels } from '../../lib/swimlane';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../../components/Modal';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import MenuContext, { MenuContextAction } from '../../../components/MenuContext';
import { Swimlane } from 'shared/schedule/types/event';
import Truncate from 'react-truncate';
import Tooltip from '../../../elements/Tooltip';
import { DatabaseServices } from '../../../contexts/proceduresSlice';

const menuOffset = [-30, 10]; // px

interface SwimlaneLabelProps {
  index: number;
  id: string;
  label: string;
  height: number;
  collapseLevel: number;
  toggleCollapse: () => void;
  maximize: () => void;
  onChange?: () => void;
}

const SwimlaneLabel = ({
  index,
  id,
  label,
  height,
  collapseLevel,
  toggleCollapse,
  maximize,
  onChange,
}: SwimlaneLabelProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const [labelValue, setLabelValue] = useState(label);
  const bgColorClass = swimlaneLib.swimlaneBgColorClass(index);
  const [showEdit, setShowEdit] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const labelRef = useRef<HTMLInputElement>(null);
  const [menuClickPosition, setMenuClickPosition] = useState<Array<number> | null>(null);
  const [labelTruncated, setLabelTruncated] = useState(false);

  const menuActions = useMemo(() => {
    const actions: Array<MenuContextAction> = [
      {
        type: 'label',
        label: 'Maximize',
        data: {
          icon: 'expand-alt',
          title: 'Maximize Swimlane',
          onClick: () => maximize(),
          disabled: collapseLevel === CollapseLevels.Maximized,
        },
      },
    ];
    if (label !== 'Other' && onChange) {
      actions.push(
        {
          type: 'label',
          label: 'Edit',
          data: {
            icon: 'pencil',
            title: 'Edit Swimlane',
            onClick: () => setShowEdit(true),
            disabled: false,
          },
        },
        {
          type: 'label',
          label: 'Delete',
          data: {
            icon: 'trash',
            title: 'Delete Swimlane',
            onClick: () => setShowDeleteModal(true),
            disabled: false,
          },
        }
      );
    }
    return [actions];
  }, [maximize, label, collapseLevel, onChange]);

  const handleSwimlaneUpdate = async () => {
    await services.swimlanes.updateSwimlane({ id, name: labelValue } as Swimlane);
    setShowEdit(false);
    if (onChange) {
      onChange();
    }
  };

  const handleDelete = async () => {
    await services.swimlanes.deleteSwimlane(id);
    setShowDeleteModal(false);
    if (onChange) {
      onChange();
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    const closeMenu = () => {
      // Any click on the document (including in dropdown menu) closes menu
      setMenuClickPosition(null);
    };
    if (menuClickPosition) {
      document.addEventListener('click', closeMenu);
    }
    // Remove listener when menu visibility changes or component unmounts
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, [menuClickPosition]);

  const nLabelLines = useMemo(() => {
    let availableHeight = height;
    availableHeight -= 24; // h-6 for expand/collapse row
    availableHeight -= 16; // p-2
    return Math.floor(availableHeight / 24.0); // 24px per text row
  }, [height]);

  const menuPosition = useMemo(() => {
    const labelOffset = labelRef.current?.getBoundingClientRect();
    return (
      menuClickPosition &&
      labelOffset && [
        menuClickPosition[0] - labelOffset.x + menuOffset[0],
        menuClickPosition[1] - labelOffset.y + menuOffset[1],
      ]
    );
  }, [menuClickPosition]);

  return (
    <div
      className={`flex flex-col relative shrink-0 ${bgColorClass} group`}
      style={{ height: `${height}px` }}
      ref={labelRef}
    >
      <div className="sticky top-0 p-2 flex flex-row">
        {/* Expand/Collapse icon column */}
        <div
          className="flex shrink-0 mr-2 text-lg hover:bg-gray-400/25 w-4 h-6 rounded items-center justify-center cursor-pointer"
          onClick={toggleCollapse}
        >
          {collapseLevel === CollapseLevels.Collapsed && <FontAwesomeIcon aria-label="Collapse" icon="angle-right" />}
          {collapseLevel !== CollapseLevels.Collapsed && <FontAwesomeIcon aria-label="Expand" icon="angle-down" />}
        </div>

        {/* Swimlane name & three-dot-menu */}
        <div className="flex flex-col w-full">
          <Tooltip content={label} visible={labelTruncated}>
            <div className="cursor-pointer" onClick={toggleCollapse}>
              <Truncate lines={nLabelLines} onTruncate={(b) => setLabelTruncated(b)}>
                {label}
              </Truncate>
            </div>
          </Tooltip>
          <div className="flex shrink-0 relative items-center h-6 w-6 text-lg text-gray-700">
            <FontAwesomeIcon
              icon="ellipsis"
              className="invisible cursor-pointer group-hover:visible text-gray-500 text-lg"
              data-testid="ellipsis-icon"
              onClick={(e) => setMenuClickPosition([e.pageX, e.pageY])}
            ></FontAwesomeIcon>
          </div>
        </div>
      </div>

      {menuPosition && (
        <div
          className="absolute flex flex-col z-100 bg-white shadow-md border rounded"
          style={{
            left: menuPosition[0],
            top: menuPosition[1],
          }}
        >
          <MenuContext menuContextActions={menuActions} className="font-medium text-xs" />
        </div>
      )}

      {showEdit && (
        <Modal
          title="Edit Swimlane"
          primaryActionTitle="Save"
          secondaryActionTitle="Cancel"
          onPrimaryAction={handleSwimlaneUpdate}
          onSecondaryAction={() => {
            setShowEdit(false);
          }}
          size="sm"
        >
          <input
            id="labelInput"
            className="label-input w-full px-2 py-1 mb-1 border border-gray-300 rounded"
            type="text"
            data-testid="labelInput"
            defaultValue={label}
            onChange={(e) => setLabelValue(e.target.value)}
          ></input>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          title="Delete Confirmation"
          primaryActionTitle="Delete"
          secondaryActionTitle="Cancel"
          onPrimaryAction={handleDelete}
          onSecondaryAction={handleDeleteCancel}
        >
          <span>Are you sure you want to delete the swimlane "{label}"?</span>
        </Modal>
      )}
    </div>
  );
};

export default SwimlaneLabel;

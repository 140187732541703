const capitalizeFirstLetter = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1);

const isEmptyValue = (value: unknown) =>
  value === null || value === undefined || value === '';

// Displays text value if defined or the string 'Empty'
const textOrEmpty = (value: unknown) => value || 'Empty';

// Displays text value if defined or the string 'Untitled'
const textOrUntitled = (value: unknown) => value || 'Untitled';

const titleCase = (text: string) =>
  text.split(' ').map(capitalizeFirstLetter).join(' ');

export {
  capitalizeFirstLetter,
  isEmptyValue,
  textOrEmpty,
  textOrUntitled,
  titleCase,
};

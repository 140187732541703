import cloneDeep from 'lodash.clonedeep';
import { ProcedureContentBlockTypes } from 'shared/lib/types/blockTypes';
import procedureUtil from '../lib/procedureUtil';
import sharedDiffUtil from 'shared/lib/diffUtil';

// object-curly-newline is failing because of method comment.
// eslint-disable-next-line object-curly-newline
const externalDataUtil = {
  /**
   * Returns an external data item from an external item block, with support
   * for backwards compatibility.
   *
   * Older procedures do not contain the `item` property so for backwards compatibility
   * create the item from the inline field values
   *
   * @param {object} block - A block of type external data
   * @returns {ExternalItem} - An external data item attached to the block.
   */
  getItemFromBlock: (block) => {
    if (block.item) {
      return block.item;
    }
    if (!block.item_id) {
      return null;
    }
    return {
      id: block.item_id,
      name: block.item_name,
      label: block.item_label,
      url: block.item_url,
      valid: block.item_valid,
    };
  },

  /**
   * Checks if a procedure document has any external data items that are invalid.
   *
   * @param {Object} procedure - A procedure document.
   * @returns {Boolean} True if the procedure has items that are invalid.
   */
  hasInvalidExternalItems: (procedure) => {
    const blocks = procedureUtil.getAllContentType(procedure, ProcedureContentBlockTypes.ExternalItem);
    return blocks.some((block) => externalDataUtil.getItemFromBlock(block).valid === false);
  },

  /**
   * Checks if a section has any external data items that are invalid.
   *
   * @param {Object} section - A procedure section
   * @returns {Boolean} True if the section has items that are invalid.
   */
  sectionHasInvalidExternalItems: (section) => {
    const blocks = procedureUtil.getAllContentTypeInSection(section, ProcedureContentBlockTypes.ExternalItem);
    return blocks.some((block) => externalDataUtil.getItemFromBlock(block).valid === false);
  },

  /**
   * Returns the label that should be used when rendering an external data item.
   *
   * @param {ExternalItem} item - An external data item.
   * @returns {string} label - A string for rendering the external item label.
   */
  getItemLabel: (item) => {
    return item.label
      ? sharedDiffUtil.getDiffValue(item, 'label', 'new')
      : `${sharedDiffUtil.getDiffValue(item, 'id', 'new')} ${sharedDiffUtil.getDiffValue(item, 'name', 'new')}`;
  },

  /**
   * Updates a procedure (or run) doc with the given external data items.
   *
   * @param {Object} procedure - A procedure or run doc.
   * @param {List<ExternalItem>} - A list of newly fetched external data items.
   * @returns {Object} procedure - A copy of the procedure doc with updated
   *   external data items.
   */
  updateProcedureWithItems: (procedure, items) => {
    const itemsMap = Object.assign({}, ...items.map((item) => ({ [item.id]: item })));

    const updated = cloneDeep(procedure);
    for (const section of updated.sections) {
      for (const step of section.steps) {
        for (const content of step.content) {
          if (content.type.toLowerCase() !== ProcedureContentBlockTypes.ExternalItem) {
            continue;
          }
          if (!itemsMap[content.item_id]) {
            continue;
          }
          // Update item.
          content.item = itemsMap[content.item_id];
          /**
           * For backwards compatibility, update legacy fields that were present
           * before we added the full `item`.
           */
          content.item_name = content.item.name;
          if (content.item.label !== undefined) {
            content.item_label = content.item.label;
          }
          if (content.item.url !== undefined) {
            content.item_url = content.item.url;
          }
          if (content.item.valid !== undefined) {
            content.item_valid = content.item.valid;
          }
        }
      }
    }
    return updated;
  },
};

export default externalDataUtil;

import { Fragment, useRef, useState, useEffect, useMemo } from 'react';
import useStateHistory from '../../hooks/useStateHistory';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { Stage, Layer, Rect, Ellipse, Line, Arrow, Image, Group, Text } from 'react-konva';
import { Annotation, Shape } from '../../lib/views/attachments';
import AnnotationToolbar from './AnnotationToolbar';
import _ from 'lodash';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';
import AnnotationUtil, { EMPTY_ANNOTATION, Color, Point, Size, Tool } from './AnnotationUtil';
import { Html } from './HtmlUtil';
import apm from '../../lib/apm';

interface AnnotationEditorProps {
  name: string;
  image: HTMLImageElement;
  mimeType: string;
  annotation?: Annotation;
  onSave: (file: File) => void;
  onClose: () => void;
}

const AnnotationEditor = ({ annotation, image, mimeType, name, onSave, onClose }: AnnotationEditorProps) => {
  const [isDirty, setIsDirty] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [currentAnnotation, setCurrentAnnotation] = useState(annotation || EMPTY_ANNOTATION);
  const [currentShape, setCurrentShape] = useState<Shape>();
  const [startingPoint, setStartingPoint] = useState<Point>();
  const [tool, setTool] = useState<Tool>('Arrow');
  const [color, setColor] = useState<Color>('red');
  const [size, setSize] = useState<Size>(3);
  const [selectedShapeIndex, setSelectedShapeIndex] = useState<number>();
  const [textEditingIndex, setTextEditingIndex] = useState<number>();
  const [dimensions, setDimensions] = useState(AnnotationUtil.getDimensions(image));
  const [textAreaSizeScale, setTextAreaSizeScale] = useState(1);

  const { present, hasPast, hasFuture, redoState, updatePresentAndAddToPast, undoState } = useStateHistory(annotation);
  const { mixpanel } = useMixpanel();

  const stageRef = useRef<Konva.Stage>(null);
  const isDrawingRef = useRef(false);
  const textAreaRef = useRef({});
  const textWidthCalculatorRef = useRef<HTMLDivElement>(null);

  const currentText = useMemo(() => {
    if (currentShape) {
      return currentShape;
    } else if (textEditingIndex !== undefined && currentAnnotation?.shapes.length > textEditingIndex) {
      return currentAnnotation.shapes[textEditingIndex];
    }
    return null;
  }, [currentShape, textEditingIndex, currentAnnotation.shapes]);

  const resolution = useMemo(() => {
    return dimensions.scale === 0 ? 1 : 1 / dimensions.scale;
  }, [dimensions]);

  const flattenAndSave = () => {
    if (stageRef.current) {
      AnnotationUtil.getFileFromStage(stageRef.current, mimeType, resolution, name)
        .then((file) => {
          if (file) {
            onSave(file);
          } else {
            setHasError(true);
          }
        })
        .catch((err) => apm.captureError(err));
    }
  };

  const updateState = (updated: Annotation, previous: Annotation) => {
    updatePresentAndAddToPast(updated, previous);
    setIsDirty(true);
  };

  const undoAnnotationState = () => {
    undoState(currentAnnotation);
    setCurrentAnnotation(present);
    setIsDirty(true);
  };

  const redoAnnotationState = () => {
    redoState(currentAnnotation);
    setCurrentAnnotation(present);
    setIsDirty(true);
  };

  // When the editor is provided an annotation
  useEffect(() => {
    if (annotation) {
      setCurrentAnnotation(annotation);
    }
  }, [annotation]);

  // Keep current annotation up to date with the undo/redo history state
  useEffect(() => {
    if (present) {
      setCurrentAnnotation(present);
    }
  }, [present]);

  /*
   * We can't syncronously inject a DOM textarea into the canvas and focus it
   * so it needs a setTimeout here to allow it to inject, then focus at the end
   */
  useEffect(() => {
    setTimeout(() => {
      if (textEditingIndex !== undefined && textAreaRef.current[textEditingIndex]) {
        const element = textAreaRef.current[textEditingIndex];
        element.setSelectionRange(element.value.length, element.value.length);
        element.focus();
      }
    });
  }, [textEditingIndex]);

  /*
   * When the current text changes (keyups) recaulculate its width to resize the textarea
   * this uses a hidden "calculator" div synchronized to the text to provide dimensions
   */
  useEffect(() => {
    if (currentText && textEditingIndex !== undefined && textAreaRef.current[textEditingIndex]) {
      const element = textAreaRef.current[textEditingIndex];
      if (textWidthCalculatorRef.current) {
        const textAreaPadding = 65;

        // iPad borderWidth is significantly narrower, so make it 8 for good visibility
        const borderWidth = textAreaSizeScale === 2 ? 8 : 2;
        const width = textWidthCalculatorRef.current?.clientWidth + textAreaPadding * textAreaSizeScale;

        element.style.borderWidth = `${borderWidth}px`;
        element.style.width = `${width}px`;
      }
    }
  }, [currentText, textAreaSizeScale, textEditingIndex]);

  useEffect(() => {
    let resizeTimeoutId;
    const handleViewportResize = () => {
      clearTimeout(resizeTimeoutId);
      // this 100ms delay prevents a bunch of re-render flickers on resize and from excessive upstream prop changes
      resizeTimeoutId = setTimeout(() => {
        setDimensions(AnnotationUtil.getDimensions(image));
      }, 100);
    };

    window.addEventListener('resize', handleViewportResize);
    handleViewportResize();

    return () => {
      window.removeEventListener('resize', handleViewportResize);
    };
  }, [image]);

  const startDrawing = (element) => {
    const clickedOnEmpty = element.attrs.name === 'background-image';
    if (clickedOnEmpty && selectedShapeIndex !== undefined) {
      setSelectedShapeIndex(undefined);
    }

    // clicked on a shape so prevent new drawing to allow it to be selected or dragged
    if (!clickedOnEmpty) {
      return;
    }

    const pos = AnnotationUtil.scaleToViewport(element.getStage().getPointerPosition(), resolution);
    setStartingPoint(pos);

    if (tool === 'Text') {
      if (textEditingIndex !== undefined) {
        return;
      }
      setCurrentShape({
        type: 'Text',
        attrs: {
          x: pos.x,
          y: pos.y,
          fill: color,
          text: '',
          fontSize: AnnotationUtil.fontSize(size, resolution),
        },
      });
      setTextEditingIndex(currentAnnotation.shapes.length + 1);
    }

    if (tool === 'Pen' || tool === 'Line') {
      isDrawingRef.current = true;
      setCurrentShape({
        type: 'Line',
        attrs: {
          points: [pos.x, pos.y],
          stroke: color,
          strokeWidth: AnnotationUtil.strokeWidth(size, resolution),
          tension: 0.5,
          lineCap: 'square',
          lineJoin: 'round',
        },
      });
    }

    if (tool === 'Arrow') {
      isDrawingRef.current = true;
      setCurrentShape({
        type: 'Arrow',
        attrs: {
          points: [pos.x, pos.y],
          stroke: color,
          fill: color,
          strokeWidth: AnnotationUtil.strokeWidth(size, resolution),
          ...AnnotationUtil.pointerSize(size, resolution),
        },
      });
    }

    if (tool === 'Rect') {
      isDrawingRef.current = true;
      setCurrentShape({
        type: 'Rect',
        attrs: {
          x: pos.x,
          y: pos.y,
          width: 0,
          height: 0,
          stroke: color,
          strokeWidth: AnnotationUtil.strokeWidth(size, resolution),
        },
      });
    }

    if (tool === 'Ellipse') {
      isDrawingRef.current = true;
      setCurrentShape({
        type: 'Ellipse',
        attrs: {
          x: pos.x,
          y: pos.y,
          radiusX: 0,
          radiusY: 0,
          stroke: color,
          strokeWidth: AnnotationUtil.strokeWidth(size, resolution),
        },
      });
    }
  };

  const handleMouseDown = (e) => {
    if (e.evt.button !== 0) {
      return;
    }
    startDrawing(e.target);
  };

  const handleTouchStart = (e) => {
    /*
     * This covers part of the onBlur event the iPad can't capture.
     * If text editing is active and you touch the stage, commit the text
     */
    if (textEditingIndex !== undefined && currentShape?.type === 'Text') {
      commitText();
    }

    // if we are using a touch device, make sure the text areas are wide enough to touch
    setTextAreaSizeScale(2);

    startDrawing(e.target);
  };

  const handleMouseMove = (e) => {
    if (!isDrawingRef.current) {
      return;
    }

    const stage = e.target.getStage();
    const point = AnnotationUtil.scaleToViewport(stage.getPointerPosition(), resolution);

    if (!currentShape) {
      return;
    }

    if (!startingPoint) {
      return;
    }

    if (tool === 'Pen') {
      setCurrentShape(() => {
        const updated = _.cloneDeep(currentShape);
        updated.attrs.points.push(point.x, point.y);
        return updated;
      });
      return;
    }

    if (tool === 'Line' || tool === 'Arrow') {
      setCurrentShape(() => {
        const updated = _.cloneDeep(currentShape);
        updated.attrs.points = [startingPoint.x, startingPoint.y, point.x, point.y];
        return updated;
      });
      return;
    }

    if (tool === 'Rect') {
      setCurrentShape(() => {
        const updated = _.cloneDeep(currentShape);
        updated.attrs.x = Math.min(point.x, startingPoint.x);
        updated.attrs.y = Math.min(point.y, startingPoint.y);
        updated.attrs.width = Math.abs(point.x - startingPoint.x);
        updated.attrs.height = Math.abs(point.y - startingPoint.y);
        return updated;
      });
      return;
    }

    if (tool === 'Ellipse') {
      setCurrentShape(() => {
        const updated = _.cloneDeep(currentShape);

        updated.attrs.radiusX = Math.abs((point.x - startingPoint.x) / 2);
        updated.attrs.radiusY = Math.abs((point.y - startingPoint.y) / 2);

        updated.attrs.x = (startingPoint.x + point.x) / 2;
        updated.attrs.y = (startingPoint.y + point.y) / 2;
        return updated;
      });
      return;
    }
  };

  const handleTouchEnd = (e) => {
    // on iPad, we have to prevent endDrawing when using the text tool so the textarea can come up and focus
    if (tool === 'Text') {
      return;
    }
    endDrawing(e);
  };

  const handleMouseUp = (e) => {
    endDrawing(e);
  };

  const endDrawing = (e) => {
    const stage = e.target.getStage();
    const point = AnnotationUtil.scaleToViewport(stage.getPointerPosition(), resolution);

    // don't create a little speck of a shape when clicking/touching and releasing on a single point or very small area
    if (
      isDrawingRef.current &&
      (!startingPoint ||
        (Math.abs(startingPoint.x - point.x) < resolution && Math.abs(startingPoint.y - point.y) < resolution))
    ) {
      setCurrentShape(undefined);
      return;
    }

    isDrawingRef.current = false;
    if (currentShape) {
      if (mixpanel) {
        mixpanel.track('Annotate Shape', { Tool: tool });
      }
      setCurrentAnnotation((current) => {
        const updated = _.cloneDeep(current);
        updated.shapes.push(currentShape);
        updateState(updated, current);
        return updated;
      });
      setCurrentShape(undefined);
    }
  };

  const handleTextDoubleClick = (index) => {
    setTextEditingIndex(index);
  };

  const updateShape = (index: number, x: number, y: number) => {
    if (currentAnnotation?.shapes.length > index) {
      setCurrentAnnotation((current) => {
        const updated = _.cloneDeep(current);
        updated.shapes[index].attrs.x = x;
        updated.shapes[index].attrs.y = y;
        updateState(updated, current);
        return updated;
      });
    }
  };

  const updateText = (index: number, text: string) => {
    if (currentShape) {
      setCurrentShape(() => {
        const updated = _.cloneDeep(currentShape);
        updated.attrs.text = text;
        return updated;
      });
    } else if (currentAnnotation?.shapes.length > index) {
      setCurrentAnnotation((current) => {
        const updated = _.cloneDeep(current);
        updated.shapes[index].attrs.text = text;
        updateState(updated, current);
        return updated;
      });
    }
  };

  const commitText = () => {
    if (currentShape && currentShape.type === 'Text' && !currentShape.attrs.text) {
      setCurrentShape(undefined);
      setTextEditingIndex(undefined);
      return;
    }
    setCurrentAnnotation((current) => {
      const updated = _.cloneDeep(current);
      if (currentShape) {
        /*
         * commitText can be called twice since it responds to onBlur and touching the stage (iPad accommodation)
         * so prevent adding the same text twice into the annotation
         */
        const lastShape = updated.shapes.length > 0 ? updated.shapes[updated.shapes.length - 1] : undefined;
        if (
          lastShape &&
          lastShape.type === 'Text' &&
          lastShape.attrs.text === currentShape.attrs.text &&
          lastShape.attrs.x === currentShape.attrs.x &&
          lastShape.attrs.y === currentShape.attrs.y
        ) {
          return updated;
        }
        updated.shapes.push(currentShape);
        setCurrentShape(undefined);
      }
      updateState(updated, current);
      return updated;
    });
    setTextEditingIndex(undefined);
  };

  const onChangeTool = (tool: Tool) => {
    setTool(tool);
  };

  const onChangeColor = (color: Color) => {
    setColor(color);
    if (selectedShapeIndex !== undefined) {
      setCurrentAnnotation((current) => {
        const updated = _.cloneDeep(current);
        if (updated.shapes[selectedShapeIndex].type === 'Text') {
          updated.shapes[selectedShapeIndex].attrs.fill = color;
        } else {
          updated.shapes[selectedShapeIndex].attrs.stroke = color;
          if (updated.shapes[selectedShapeIndex].type === 'Arrow') {
            updated.shapes[selectedShapeIndex].attrs.fill = color;
          }
        }
        updateState(updated, current);
        return updated;
      });
    }
  };

  const onChangeSize = (size: Size) => {
    setSize(size);
    if (selectedShapeIndex !== undefined) {
      setCurrentAnnotation((current) => {
        const updated = _.cloneDeep(current);
        if (updated.shapes[selectedShapeIndex].type === 'Text') {
          updated.shapes[selectedShapeIndex].attrs.fontSize = AnnotationUtil.fontSize(size, resolution);
        } else {
          updated.shapes[selectedShapeIndex].attrs.strokeWidth = AnnotationUtil.strokeWidth(size, resolution);
        }
        if (updated.shapes[selectedShapeIndex].type === 'Arrow') {
          updated.shapes[selectedShapeIndex].attrs = {
            ...updated.shapes[selectedShapeIndex].attrs,
            ...AnnotationUtil.pointerSize(size, resolution),
          };
        }
        updateState(updated, current);
        return updated;
      });
    }
  };

  const onDeleteSelected = () => {
    if (selectedShapeIndex !== undefined) {
      setSelectedShapeIndex(undefined);
      setCurrentAnnotation((current) => {
        const updated = _.cloneDeep(current);
        updated.shapes.splice(selectedShapeIndex, 1);
        updateState(updated, current);
        return updated;
      });
    }
  };

  const getShape = (shape: Shape, index: number) => {
    const handlers = {
      onClick: () => {
        setSelectedShapeIndex(index);
      },
      onTouchStart: () => {
        setSelectedShapeIndex(index);
      },
      draggable: true,
      onDragStart: () => {
        setSelectedShapeIndex(index);
      },
      onDragEnd: (e: KonvaEventObject<DragEvent>) => {
        setSelectedShapeIndex(undefined);
        updateShape(index, e.target.x(), e.target.y());
      },
      shadowBlur: 0,
      shadowOffset: {
        x: 0,
        y: 0,
      },
    };

    if (selectedShapeIndex === index) {
      handlers.shadowBlur = 10;
      handlers.shadowOffset = {
        x: 0,
        y: 0,
      };
    }

    switch (shape.type) {
      case 'Line':
        return <Line key={`shape-${index}`} {...shape.attrs} {...handlers} />;
      case 'Arrow':
        return <Arrow key={`shape-${index}`} {...shape.attrs} {...handlers} />;
      case 'Rect':
        return <Rect key={`shape-${index}`} {...shape.attrs} {...handlers} />;
      case 'Ellipse':
        return <Ellipse key={`shape-${index}`} {...shape.attrs} {...handlers} />;
      case 'Text':
        return (
          <Fragment key={`text-group-${index}`}>
            <Group x={shape.attrs.x} y={shape.attrs.y}>
              <Html>
                <textarea
                  rows={AnnotationUtil.textLines(shape.attrs.text)}
                  value={shape.attrs.text}
                  ref={(t) => (textAreaRef.current[index] = t)}
                  style={{
                    fontSize: `${shape.attrs.fontSize || AnnotationUtil.fontSize(size, resolution)}px`,
                    lineHeight: 1.1,
                    borderWidth: '2px',
                    borderColor: `${shape.attrs.fill || color}`,
                    color: `${shape.attrs.fill || color}`,
                  }}
                  className={`-m-3 bg-transparent ${textEditingIndex === index ? 'visible' : 'hidden'}`}
                  onChange={(e) => {
                    textEditingIndex !== undefined && updateText(textEditingIndex, e.target.value);
                  }}
                  onBlur={commitText}
                ></textarea>
              </Html>
            </Group>
            <Text
              visible={textEditingIndex !== index}
              key={`shape-${index}`}
              {...shape.attrs}
              {...handlers}
              onDblTap={() => handleTextDoubleClick(index)}
              onDblClick={() => handleTextDoubleClick(index)}
            />
          </Fragment>
        );
    }
  };

  return (
    <div
      data-testid="annotation-editor"
      style={{ width: dimensions.width + 2 }}
      className="max-h-[95vh] mb-5 mx-auto grid justify-items-center"
    >
      {currentText && (
        <div
          ref={textWidthCalculatorRef}
          style={{
            fontSize: `${currentText.attrs.fontSize}px`,
            lineHeight: 1,
          }}
          className="invisible absolute w-auto h-auto whitespace-pre"
        >
          {currentText.attrs.text}
        </div>
      )}

      {hasError && <div className=" bg-red-100 text-red-700 px-5 py-1 rounded-t-md">Sorry, there was an error</div>}

      <AnnotationToolbar
        onChangeTool={onChangeTool}
        onChangeColor={onChangeColor}
        onChangeSize={onChangeSize}
        onDeleteSelected={onDeleteSelected}
        onUndo={undoAnnotationState}
        onRedo={redoAnnotationState}
        onSave={flattenAndSave}
        onClose={onClose}
        canSave={(isDirty && hasPast) || annotation}
        canUndo={hasPast}
        canRedo={hasFuture}
        canDelete={selectedShapeIndex !== undefined}
      />
      <Stage
        className="bg-gray-200 border-2"
        ref={stageRef}
        width={dimensions.width}
        height={dimensions.height}
        scale={{
          x: dimensions.scale,
          y: dimensions.scale,
        }}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleTouchEnd}
      >
        <Layer>
          <Image name="background-image" image={image} />
          {currentAnnotation.shapes.map((shape, index) => getShape(shape, index))}
          {currentShape && getShape(currentShape, currentAnnotation.shapes.length + 1)}
        </Layer>
      </Stage>
    </div>
  );
};
export default AnnotationEditor;

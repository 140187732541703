import { useCallback } from 'react';
import CreatableSelect from 'react-select/creatable';
import { reactSelectStyles, selectCreatableDisabledStyles } from '../lib/styles';

/*
 * Renders a react select that allows users to create new select options.
 *
 * @param {Array} value - array of objects with "value" and "label" fields. The label
 *                        field is what is displayed and the value field is what is
 *                        saved after selection.
 * @param {Array} options - array of objects with "value" and "label" fields used as
 *                          the options from which the user can select.
 * @param {Object} field - Formik field.
 * @param {Object} form - Formik form.
 * @param {String} placeholder - text to show when no options are selected.
 * @param {String} ariaLabel - label used to describe this component.
 * @param {String} disabled - disables widget if true.
 */
const FieldSetMultiSelectCreatable = ({
  value,
  options,
  onChange,
  field,
  form,
  placeholder,
  ariaLabel,
  isDisabled,
}) => {
  // Sets field value, using `onChange` override if present.
  const handleOnChange = useCallback(
    (options, form, field) => {
      const values = options.map((option) => option.value);
      if (typeof onChange === 'function') {
        onChange(values);
      } else {
        form.setFieldValue(field.name, values);
      }
    },
    [onChange]
  );

  return (
    <div className="grow w-full">
      <div className="grow">
        <CreatableSelect
          classNamePrefix="react-select"
          styles={isDisabled ? selectCreatableDisabledStyles : reactSelectStyles}
          options={options}
          name={field.name}
          value={value}
          onChange={(options) => handleOnChange(options, form, field)}
          onBlur={field.onBlur}
          placeholder={placeholder}
          aria-label={ariaLabel}
          isDisabled={isDisabled}
          isMulti
          noOptionsMessage={() => null} // disables select dropdown
        />
      </div>
    </div>
  );
};

export default FieldSetMultiSelectCreatable;

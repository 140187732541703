import { capitalize } from 'lodash';
import { useMemo } from 'react';
import ActionIcon from './ActionIcon';

interface ActionTypeLabelProps {
  action: string;
  prefix?: string;
}

const ActionTypeLabel = ({ action, prefix }: ActionTypeLabelProps) => {
  const capitalized = useMemo(() => {
    switch (action) {
      case 'check-out':
        return 'Check-Out';
      case 'check-in':
        return 'Check-In';
      default:
        return capitalize(action);
    }
  }, [action]);

  return (
    <div key={action} className="flex flex-row">
      <ActionIcon action={action} />
      {`${prefix ? `${prefix} - ` : ''}${capitalized}`}
    </div>
  );
};

export default ActionTypeLabel;

import Label from '../../Label';

const renderOperation = (label: string) => {
  return label ? (
    <div>
      <Label clickable={true} text={label} color="bg-violet-200" />
    </div>
  ) : (
    <></>
  );
};

export default renderOperation;

import cloneDeep from 'lodash.clonedeep';
import sharedRunUtil from 'shared/lib/runUtil';
import { RedlinedStep, Run, RunStep } from 'shared/lib/types/views/procedures';

const runUtil = {
  replaceStep: ({
    run,
    sectionId,
    stepId,
    updatedStep,
  }: {
    run: Run;
    sectionId: string;
    stepId: string;
    updatedStep: RunStep | RedlinedStep;
  }): Run => {
    const updatedRun = cloneDeep(run);
    const { sectionIndex, stepIndex } = sharedRunUtil.getSectionAndStepIndices(
      updatedRun,
      sectionId,
      stepId
    );
    updatedRun.sections[sectionIndex].steps[stepIndex] = {
      id: stepId,
      ...updatedStep,
    };

    return updatedRun;
  },
};

export default runUtil;

import React from 'react';

interface Props {
  children: React.ReactNode;
}

/**
 * This component will wrap all children with a parent row,
 * and each child with a table cell, to be used in SettingsTable.
 *
 * @returns {ReactComponentElement}
 */
const SettingsHeaderRow = ({ children }: Props) => (
  <div className="table-row font-medium uppercase text-sm">{children}</div>
);

export default React.memo(SettingsHeaderRow);

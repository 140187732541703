import _, { IsEqualCustomizer } from 'lodash';

/**
 * A generator function that applies a map function and skips any outputs that
 * are `null` or `undefined`.
 *
 * @example
 * const input = [1, 2, 3, 4, 5];
 * const oddSquares = [
 *   ...filterMap(input, (x) => x % 2 === 0 ? undefined : x * x)
 * ];
 * expect(oddSquares).toEqual([1, 9, 25]).
 *
 * @param collection - The iterable collection to process.
 * @param mapFn - A function that maps from `In` to `Out`, or returns `null` or
 *     `undefined`.
 * @typeParam In - The type of the input element.
 * @typeParam Out - The type of the output element.
 */
export const filterMap = function* <In, Out extends NonNullable<unknown>>(
  collection: Iterable<In>,
  mapFn: (item: In, index: number) => Out | null | undefined
): Generator<Out> {
  let index = 0;
  for (const item of collection) {
    const mapped = mapFn(item, index);
    index += 1;
    if (typeof mapped !== 'undefined' && mapped !== null) {
      yield mapped;
    }
  }
};

/**
 * Finds the duplicates in an array of primitive types or objects. Elements are
 * compared using `_.isEqual`. Duplicates are only included once in the output
 * array regardless of the number of times they occur in the input array.
 *
 * @returns the unique duplicates in `array`, compared using `_.isEqual`
 */
export const findDuplicates = function <T>(array: Array<T>): Array<T> {
  if (array.length === 0) {
    return [];
  }

  // handle object elements separately since this is less efficient
  if (typeof array[0] === 'object') {
    const dups: Array<T> = [];
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < i; j++) {
        if (
          _.isEqual(array[i], array[j]) &&
          !dups.find((elem) => _.isEqual(elem, array[i]))
        ) {
          dups.push(array[i]);
        }
      }
    }
    return dups;
  }

  const duplicateMap = array.reduce((accumulatorMap, entry) => {
    return accumulatorMap.set(entry, (accumulatorMap.get(entry) ?? 0) + 1);
  }, new Map<T, number>());
  return Array.from(duplicateMap.entries())
    .filter(([, count]) => count > 1)
    .map(([entry]) => entry);
};

/**
 * Returns the difference between two arrays. All duplicates are included.
 * Works with primitives and objects, and optionally takes an array of fields
 * to use to compare elements for equality.
 */
export const computeDiff = function <T>(
  array1: Array<T>,
  array2: Array<T>,
  fields?: Array<keyof T>
): Array<T> {
  const customizer: IsEqualCustomizer = (elem1, elem2) => {
    if (!fields) {
      return undefined;
    }
    for (const field of fields) {
      if (!_.isEqual(elem1[field], elem2[field])) {
        return false;
      }
    }
    return true;
  };
  const delta1 = array1.filter(
    (elem1) => !array2.some((elem2) => _.isEqualWith(elem1, elem2, customizer))
  );
  const delta2 = array2.filter(
    (elem2) => !array1.some((elem1) => _.isEqualWith(elem2, elem1, customizer))
  );
  return delta1.concat(delta2);
};

/**
 * Predicate to use to filter a list of type `T | undefined` to a list of type `T`.
 *
 * Usage: `col.filter(notEmpty)`
 *
 * Taken from {@link https://github.com/microsoft/TypeScript/issues/45097#issuecomment-882526325}.
 */
export const notEmpty = function <TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
};

/**
 * Wraps _.sortBy with explicit typing on the sort columns.
 */
export const sortBy = <T extends object>(
  arr: Array<T>,
  sortColumns: Array<keyof T>,
  sortOrder: 'asc' | 'desc' = 'asc'
): Array<T> => {
  const sortedTools = _.sortBy(arr, sortColumns);
  if (sortOrder === 'desc') {
    _.reverse(sortedTools);
  }
  return sortedTools;
};

/**
 * Filters an array of objects, searching `filterColumns` for `keywords`.
 * The search is case-insensitive.
 */
export const filterByKeywords = <T extends object>(
  arr: Array<T>,
  filterColumns: Array<keyof T>,
  keywords: Array<string>
): Array<T> => {
  return arr.filter((elem) => {
    const combined = filterColumns
      .map((filterColumn) => elem[filterColumn])
      .join(' ')
      .toLocaleLowerCase();
    for (const keyword of keywords) {
      if (combined.includes(keyword.toLocaleLowerCase())) {
        return true;
      }
    }
    return false;
  });
};

export const deduplicate = (array: Array<string>): Array<string> => {
  return [...new Set(array)];
};

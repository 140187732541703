import React, { Fragment, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { DiffSignoffButton } from '../types';

interface ReviewSignoffButtonsProps {
  buttons: Array<DiffSignoffButton>;
  leadingIcon?: IconName;
  showVerticalSpaceInPrint?: boolean;
}

const ReviewSignoffButtons = ({
  buttons,
  leadingIcon,
  showVerticalSpaceInPrint = false,
}: ReviewSignoffButtonsProps) => {
  const baselineBackgroundColor = useMemo(() => {
    if (buttons.every((button) => button.isAdded)) {
      return 'bg-emerald-100 ';
    }
    if (buttons.every((button) => button.isRemoved)) {
      return 'bg-red-100 ';
    }

    return 'bg-gray-200';
  }, [buttons]);

  const getButtonBackgroundClasses = useCallback((button: DiffSignoffButton): string => {
    if (button.isRemoved) {
      return 'bg-red-100 text-red-600 line-through';
    }
    if (button.isAdded) {
      return 'bg-emerald-100 text-emerald-800';
    }
    return 'bg-gray-200 text-gray-400';
  }, []);

  const LeadingIconComponent = () => (
    <>
      {leadingIcon && (
        <div className="flex items-center h-full text-gray-500">
          <FontAwesomeIcon icon={['far', leadingIcon]} />
        </div>
      )}
    </>
  );

  const getSingleButtonClasses = useCallback(
    (button: DiffSignoffButton) => {
      const padding = button.label ? 'px-1.5' : 'px-2.5';
      const rounded = `rounded-md ${showVerticalSpaceInPrint ? 'print:rounded-md-none print:rounded-t-md' : ''}`;
      const backgroundColor = getButtonBackgroundClasses(button);
      return `flex flex-row flex-nowrap items-center h-full min-h-8 ${padding} ${rounded} ${backgroundColor}`;
    },
    [getButtonBackgroundClasses, showVerticalSpaceInPrint]
  );

  const getMultiButtonClasses = useCallback(
    (button: DiffSignoffButton, buttonIndex: number) => {
      const padding = 'px-1.5';
      const roundedLeft = buttonIndex === 0 && !leadingIcon ? 'rounded-l-md' : '';
      const roundedRight =
        buttonIndex === buttons.length - 1
          ? `rounded-r-md ${showVerticalSpaceInPrint ? 'print:rounded-r-none print:rounded-tr-md' : ''}`
          : '';
      const rounded = `${roundedLeft} ${roundedRight}`;
      const backgroundColor = getButtonBackgroundClasses(button);
      return `flex flex-row flex-nowrap items-center h-full min-h-8 ${padding} ${rounded} ${backgroundColor}`;
    },
    [leadingIcon, buttons.length, showVerticalSpaceInPrint, getButtonBackgroundClasses]
  );

  const getButtonAriaLabel = useCallback((button) => {
    const descriptor = [button.label ?? '', (button.userIds ?? []).join('')].join(' ').trim();
    return `Signoff${descriptor ? `: ${descriptor}` : ''}`;
  }, []);

  return (
    <div
      className={`min-h-8 h-fit flex flex-col rounded-md ${
        showVerticalSpaceInPrint ? 'print:outline print:outline-1 print:outline-gray-400' : ''
      }`}
    >
      <div
        className={`w-fit min-h-8 h-fit flex flex-row flex-nowrap items-center text-gray-600 outline outline-1 outline-gray-400 rounded-md ${baselineBackgroundColor} ${
          showVerticalSpaceInPrint ? 'print:rounded-none print:rounded-t-md' : ''
        }`}
      >
        {leadingIcon && buttons.length > 1 && (
          <div className="ml-1.5 mr-0.5">
            <LeadingIconComponent />
          </div>
        )}
        <div className="flex flex-row items-center h-full divide-x divide-gray-400">
          {buttons.map((button, buttonIndex) => (
            <Fragment key={button.id}>
              <button
                key={button.id}
                className={
                  buttons.length === 1 ? getSingleButtonClasses(button) : getMultiButtonClasses(button, buttonIndex)
                }
                disabled={true}
                aria-label={getButtonAriaLabel(button)}
              >
                <div className="flex flex-row items-center gap-x-1.5">
                  {leadingIcon && buttons.length === 1 && <LeadingIconComponent />}
                  {button.label && (
                    <span
                      className={`text-xs font-medium min-w-10 break-all ${
                        button.type === 'user_id' ? '' : 'uppercase'
                      }`}
                    >
                      {button.label}
                    </span>
                  )}

                  {button.userIds && (
                    <span className="text-xs font-light normal-case whitespace-nowrap">
                      {button.userIds.join(', ')}
                    </span>
                  )}
                </div>
              </button>
            </Fragment>
          ))}
        </div>
      </div>
      {showVerticalSpaceInPrint && <div className="print:py-5" />}
    </div>
  );
};

export default React.memo(ReviewSignoffButtons);

import Select from 'react-select';
import { reactSelectStyles } from '../../lib/styles';

const XAxisSelect = ({ selectedValue, options, onChange }) => {
  const selectedOption = options.find((option) => option.value === selectedValue);

  return (
    <div className="flex flex-row items-center justify-start gap-x-2">
      <div>X-Axis</div>
      <div className="w-32">
        <Select
          styles={reactSelectStyles}
          classNamePrefix="react-select"
          options={options}
          value={selectedOption}
          onChange={onChange}
          isSearchable={false}
          aria-label="Select Time Display"
        />
      </div>
    </div>
  );
};

export default XAxisSelect;

import ShowRunPlotIcon from '../components/ShowRunPlotIcon';
import CloseRunPlotIcon from '../components/CloseRunPlotIcon';

type RunPlotIconProps = {
  isPlotShown: boolean;
  onClick: () => void;
};

const RunPlotIcon = ({ isPlotShown, onClick }: RunPlotIconProps) => {
  return (
    <div>
      {isPlotShown && <CloseRunPlotIcon onClick={onClick} />}
      {!isPlotShown && <ShowRunPlotIcon onClick={onClick} />}
    </div>
  );
};

export default RunPlotIcon;

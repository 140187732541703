import { Field, FormikHelpers, FormikValues } from 'formik';
import React, { useCallback } from 'react';

interface FieldSetCheckboxProps {
  text: string;
  fieldName: string;
  setFieldValue?: FormikHelpers<FormikValues>['setFieldValue'];
  id?: string;
  isDisabled?: boolean;
}

const FieldSetCheckbox = ({ text, fieldName, setFieldValue, id, isDisabled = false }: FieldSetCheckboxProps) => {
  /**
   * Using onChange instead of onBlur for checkboxes because
   * of a known bug with safari.
   */
  const setCheckedValue = useCallback(
    (event, name) => {
      const value = event.target.checked;
      // Update recorded value
      setFieldValue && setFieldValue(name, value);
    },
    [setFieldValue]
  );

  return (
    <label className={`flex-none shrink-0 ${!isDisabled ? 'opacity-100' : 'opacity-40'}`}>
      <Field
        id={id}
        name={fieldName}
        type="checkbox"
        disabled={isDisabled}
        className="h-6 w-6 mr-1 text-sm border border-gray-400 rounded"
        onChange={(event) => setCheckedValue(event, fieldName)}
      />
      <span className="ml-1 field-title">{text}</span>
    </label>
  );
};

export default FieldSetCheckbox;

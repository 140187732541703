import { FieldInputProps } from 'formik';
import React, { useMemo } from 'react';
import { useSettings } from '../../contexts/SettingsContext';
import Selector from '../../elements/Selector';

interface ListSelectFieldSetProps {
  field: FieldInputProps<string>;
  isDisabled?: boolean;
  onChange: (id: string) => void;
  value: string;
}

const ListSelectFieldSet = ({ field, isDisabled = false, onChange, value }: ListSelectFieldSetProps) => {
  const { config } = useSettings();

  const onChangeHandler = (option) => {
    const id = option.value;
    // Skip onChange when re-selecting the same value.
    if (id === value) {
      return;
    }

    onChange(id);
  };

  const selectOptions = useMemo(() => {
    if (!config?.lists) {
      return [];
    }

    return Object.values(config.lists).map((list) => ({
      value: list.id,
      label: list.name,
    }));
  }, [config]);

  const selectValue = useMemo(() => {
    return selectOptions.find((option) => option.value === value) || '';
  }, [selectOptions, value]);

  return (
    <div className="grow w-full">
      <div className="grow relative">
        <div>
          <Selector
            type="standalone"
            name={field.name}
            description="list"
            isDisabled={isDisabled}
            onChangeHandler={onChangeHandler}
            selectedOption={selectValue}
            selectOptions={selectOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ListSelectFieldSet);

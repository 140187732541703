import DiffContainer, { DiffContainerProps } from './DiffContainer';
import React from 'react';

/**
 * This adapter is needed for cases like wrapping sections, which are a sequence of tbody tags.
 * The props need to be the same as those for the diff container, so use the diff container props interface.
 */
const DiffContainerTableAdapter = ({
  diffChangeState,
  label,
  children,
  width,
  showModified,
  onScrollToDiffRefChanged,
}: DiffContainerProps) => {
  return (
    <tbody aria-label="Section" role="region">
      <tr>
        <td colSpan={3}>
          <DiffContainer
            diffChangeState={diffChangeState}
            label={label}
            width={width}
            showModified={showModified}
            onScrollToDiffRefChanged={onScrollToDiffRefChanged}
          >
            <table className="w-full">{children}</table>
          </DiffContainer>
        </td>
      </tr>
    </tbody>
  );
};

export default DiffContainerTableAdapter;

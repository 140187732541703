/**
 * Create a network change listener for the browser's online status.
 *
 * callback: fn({ online: true/false }).
 * returns: Object with method `cancel()` to cancel the change listener.
 */
const onNetworkChanged = (callback) => {
  const onlineListener = () => handle(callback, true);
  const offlineListener = () => handle(callback, false);

  const handle = (callback, online) => {
    if (window.requestAnimationFrame) {
      window.requestAnimationFrame(() => callback({ online }));
    } else {
      setTimeout(() => callback({ online }), 0);
    }
  };

  if (typeof window !== 'undefined' && window.addEventListener) {
    window.addEventListener('online', onlineListener);
    window.addEventListener('offline', offlineListener);
    handle(callback, window.navigator.onLine);
  }

  return {
    cancel: () => {
      if (typeof window !== 'undefined' && window.addEventListener) {
        window.removeEventListener('online', onlineListener);
        window.removeEventListener('offline', offlineListener);
      }
    },
  };
};

export default onNetworkChanged;

/** Possible statuses of an API key. */
export enum ApiKeyStatus {
  Unknown = 0,
  /** Created and unmodified (or deactivated and then reactivated). */
  Active = 1,
  /** Deactivated by an admin. */
  Inactive = 2,
}

/**
 * The variants of {@link ApiKeyStatus} that are accepted by APIs and database
 * schemas.
 */
export const VALID_API_KEY_STATUSES = [
  ApiKeyStatus.Active,
  ApiKeyStatus.Inactive,
] as const;

/**
 * Describes a newly generated API key, which is provided to an admin only once
 * and is not stored anywhere.
 */
export interface GeneratedApiKey {
  /**
   * The public ID of the key.
   */
  id: string;
  /**
   * The full (secret) key used for API access.
   */
  key: string;
}

/** Structure describing an API key for display in a listing.  */
export class ApiKeyDisplayInfo {
  /**
   * The public ID of the key.
   */
  readonly id: string;
  readonly createdAt: Date;
  readonly status: ApiKeyStatus;

  constructor(id: string, createdAt: Date | string, status: ApiKeyStatus) {
    this.id = id;
    this.createdAt = new Date(createdAt);
    this.status = status;
  }

  // TODO: The conversion of field names should be automated.
  toJSON(): ApiKeyDisplayInfoJson {
    return {
      id: this.id,
      created_at: this.createdAt,
      status: this.status,
    };
  }

  static fromJSON({
    id,
    created_at,
    status,
  }: ApiKeyDisplayInfoJson): ApiKeyDisplayInfo {
    return new ApiKeyDisplayInfo(id, created_at, status);
  }
}

/** JSON representation of {@link ApiKeyDisplayInfo}. */
export type ApiKeyDisplayInfoJson = {
  id: string;
  created_at: string | Date;
  status: ApiKeyStatus;
};

import { Attachment } from '../../hooks/useAttachment';
import AttachmentImage from './AttachmentImage';

const SIZES = {
  sm: 6,
  md: 10,
  lg: 12,
  xl: 16,
};

const ThumbnailImage = ({
  attachment,
  size,
  xPadding = true,
}: {
  attachment: Attachment | undefined;
  size: keyof typeof SIZES;
  xPadding?: boolean;
}) => {
  return (
    <div className={`${xPadding ? 'mx-1' : ''} w-${SIZES[size]} h-${SIZES[size]} rounded overflow-hidden`}>
      <AttachmentImage attachment={attachment} />
    </div>
  );
};

export default ThumbnailImage;

import { API_URL } from '../config';
import superlogin from './superlogin';
import {
  Condition,
  ConditionDetails,
  Hazard,
  HazardDetails,
  Case,
  CaseDetails,
  Requirement,
  RequirementDetails,
  AddRequirement,
  UpdateRequirement,
  TestingSettingsDetails,
  CaseHazard,
  RequirementMetadataOptions,
  RequirementRecord,
  CaseRecord,
  DraftCase,
  DraftRequirement,
  DraftHazard,
  UpsertReviewer,
  Reviewer,
  Comment,
} from 'shared/lib/types/testing';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { Mention } from 'shared/lib/types/postgres/util';

class TestingService {
  private teamId: string;
  private restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    this.restUrl = `${API_URL}/teams/${this.teamId}/testing`;
  }

  async addSettings(
    settingsDetails: TestingSettingsDetails
  ): Promise<TestingSettingsDetails> {
    const url = `${this.restUrl}/settings`;
    const response = await superlogin.getHttp().post(url, settingsDetails);
    return response.data?.details;
  }

  async getSettings(): Promise<TestingSettingsDetails> {
    const url = `${this.restUrl}/settings`;
    const response = await superlogin.getHttp().get(url);
    return response.data?.testingSettingsDetails;
  }

  async addCondition(conditionDetails: ConditionDetails): Promise<Condition> {
    const url = `${this.restUrl}/conditions`;
    const response = await superlogin.getHttp().post(url, conditionDetails);
    return response.data;
  }

  async updateCondition(condition: Condition): Promise<AxiosResponse> {
    const url = `${this.restUrl}/conditions/${condition.id}`;
    // id is part of the url params and not included in body
    const conditionDetails = _.omit(condition, ['id']);
    return superlogin.getHttp().put(url, conditionDetails);
  }

  async getConditions(): Promise<Array<Condition>> {
    const url = `${this.restUrl}/conditions`;
    const response = await superlogin.getHttp().get(url);
    return response.data?.data;
  }

  async getCondition(conditionId: string): Promise<Condition> {
    const url = `${this.restUrl}/conditions/${conditionId}`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async deleteCondition(conditionId: string): Promise<AxiosResponse> {
    const url = `${this.restUrl}/conditions/${conditionId}`;
    return superlogin.getHttp().delete(url);
  }

  async addHazard(hazardDetails: HazardDetails): Promise<Hazard> {
    const url = `${this.restUrl}/hazards`;
    const response = await superlogin
      .getHttp()
      .post(url, { hazard_details: hazardDetails });
    return response.data;
  }

  async updateHazard(hazard: Hazard): Promise<AxiosResponse> {
    const url = `${this.restUrl}/hazards/${hazard.id}`;
    // id is part of the url params and not included in body
    const hazardDetails = _.omit(hazard, ['id']);
    return superlogin.getHttp().put(url, { hazard_details: hazardDetails });
  }

  async getHazards(): Promise<Array<Hazard>> {
    const url = `${this.restUrl}/hazards`;
    const response = await superlogin.getHttp().get(url);
    return response.data?.data;
  }

  // deprecated
  async getHazardsDrafts(): Promise<Array<DraftHazard>> {
    const url = `${this.restUrl}/draft-hazards`;
    const response = await superlogin.getHttp().get(url);
    return response.data?.data;
  }

  async getHazard(hazardId: string, versionId?: string): Promise<Hazard> {
    const url = versionId
      ? `${this.restUrl}/hazards/${hazardId}/versions/${versionId}`
      : `${this.restUrl}/hazards/${hazardId}`;
    const response = await superlogin.getHttp().get(url);
    return versionId ? response.data?.record : response.data;
  }

  async listHazardVersions(hazardId: string): Promise<Array<Hazard>> {
    const url = `${this.restUrl}/hazards/${hazardId}/versions`;
    const response = await superlogin.getHttp().get(url);
    return response.data?.data;
  }

  async deleteHazard(hazardId: string): Promise<AxiosResponse> {
    const url = `${this.restUrl}/hazards/${hazardId}`;
    return superlogin.getHttp().delete(url);
  }

  async addCase(
    caseDetails: CaseDetails,
    hazardIds?: Array<string>
  ): Promise<Case> {
    const url = `${this.restUrl}/cases`;
    const response = await superlogin
      .getHttp()
      .post(url, { case_details: caseDetails, hazard_ids: hazardIds });
    return response.data;
  }

  async importCases(file: File): Promise<AxiosResponse> {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const url = `${this.restUrl}/cases/import`;
    return superlogin.getHttp().post(url, bodyFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  async updateCase(
    testCase: Case,
    hazardIds?: Array<string>
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/cases/${testCase.id}`;
    // id is part of the url params and not included in body
    const caseDetails = _.omit(testCase, ['id']);
    return superlogin
      .getHttp()
      .put(url, { case_details: caseDetails, hazard_ids: hazardIds });
  }

  async getCases(includeHazards: boolean): Promise<Array<Case>> {
    const url = `${this.restUrl}/cases`;
    const params = {
      include: 'hazards',
    };
    const response = includeHazards
      ? await superlogin.getHttp().get(url, { params })
      : await superlogin.getHttp().get(url);
    return response.data?.data;
  }

  async getCase(caseId: string, versionId?: string): Promise<Case> {
    const url = versionId
      ? `${this.restUrl}/cases/${caseId}/versions/${versionId}`
      : `${this.restUrl}/cases/${caseId}`;
    const response = await superlogin.getHttp().get(url);
    return versionId ? response.data?.record : response.data;
  }

  // deprecated
  async getCaseDraft(caseDraftId: string): Promise<DraftCase> {
    const url = `${this.restUrl}/draft-cases/${caseDraftId}`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  // deprecated
  async createCaseDraft({
    caseId,
    name,
    caseDetails,
    hazardIds,
  }: {
    caseId?: string;
    name?: string;
    caseDetails?: CaseDetails;
    hazardIds?: Array<string>;
  }): Promise<Case> {
    const url = `${this.restUrl}/draft-cases`;
    let response;
    if (caseId) {
      response = await superlogin.getHttp().post(url, { case_id: caseId });
      return response.data;
    } else if (name) {
      response = await superlogin
        .getHttp()
        .post(url, { name, case_details: caseDetails, hazard_ids: hazardIds });
    }
    return response.data;
  }

  // deprecated
  async updateCaseDraft(
    testCaseDraft: Case,
    hazardIds?: Array<string>
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/draft-cases/${testCaseDraft.id}`;
    // id is part of the url params and not included in body
    const caseDetails = _.omit(testCaseDraft, ['id']);
    return superlogin
      .getHttp()
      .put(url, { case_details: caseDetails, hazard_ids: hazardIds });
  }

  // deprecated
  async getCasesDrafts(): Promise<Array<DraftCase>> {
    const url = `${this.restUrl}/draft-cases`;
    const response = await superlogin.getHttp().get(url);
    return response.data?.data;
  }

  async listCaseVersions(caseId: string): Promise<Array<CaseRecord>> {
    const url = `${this.restUrl}/cases/${caseId}/versions`;
    const response = await superlogin.getHttp().get(url);
    return response.data?.data;
  }

  async deleteCase(caseId: string): Promise<AxiosResponse> {
    const url = `${this.restUrl}/cases/${caseId}`;
    return superlogin.getHttp().delete(url);
  }

  // deprecated
  async deleteCaseDraft(caseId: string): Promise<AxiosResponse> {
    const url = `${this.restUrl}/draft-cases/${caseId}`;
    return superlogin.getHttp().delete(url);
  }

  // unused - remove
  async getCasesHazards(
    caseId?: string,
    hazardId?: string
  ): Promise<Array<CaseHazard>> {
    const url = `${this.restUrl}/cases-hazards`;
    const params = {
      case_id: caseId,
      hazard_id: hazardId,
    };
    const response = await superlogin.getHttp().get(url, { params });
    return response.data?.data;
  }

  // unused - remove
  async addCaseHazard(caseId: string, hazardId: string): Promise<CaseHazard> {
    const url = `${this.restUrl}/cases-hazards`;
    const response = await superlogin
      .getHttp()
      .put(url, { case_id: caseId, hazard_id: hazardId });
    return response.data;
  }

  // unused - remove
  async deleteCaseHazard(caseHazardId: string): Promise<AxiosResponse> {
    const url = `${this.restUrl}/cases-hazards/${caseHazardId}`;
    return superlogin.getHttp().delete(url);
  }

  async addRequirement(requirement: AddRequirement): Promise<Requirement> {
    const url = `${this.restUrl}/requirements`;
    const response = await superlogin.getHttp().post(url, requirement);
    return response.data;
  }

  async updateRequirement(
    id: string,
    requirement: UpdateRequirement
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/requirements/${id}`;
    return superlogin.getHttp().put(url, requirement);
  }

  async getRequirements(includeCases?: boolean): Promise<Array<Requirement>> {
    const url = `${this.restUrl}/requirements`;
    const params = {
      include: 'cases',
    };
    const response = includeCases
      ? await superlogin.getHttp().get(url, { params })
      : await superlogin.getHttp().get(url);
    return response.data?.data;
  }

  // deprecated
  async getRequirementsDrafts(): Promise<Array<DraftRequirement>> {
    const url = `${this.restUrl}/draft-requirements`;
    const response = await superlogin.getHttp().get(url);
    return response.data?.data;
  }

  async getRequirement(
    requirementId: string,
    versionId?: string
  ): Promise<Requirement> {
    const url = versionId
      ? `${this.restUrl}/requirements/${requirementId}/versions/${versionId}`
      : `${this.restUrl}/requirements/${requirementId}`;
    const response = await superlogin.getHttp().get(url);
    return versionId ? response.data?.record : response.data;
  }

  // deprecated
  async getRequirementDraft(
    requirementDraftId: string
  ): Promise<DraftRequirement> {
    const url = `${this.restUrl}/draft-requirements/${requirementDraftId}`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  // depreacted
  async createRequirementDraft({
    requirementId,
    customId,
  }: {
    requirementId?: string;
    customId?: string;
  }): Promise<Case> {
    const url = `${this.restUrl}/draft-requirements`;
    let response;
    if (requirementId) {
      response = await superlogin
        .getHttp()
        .post(url, { requirement_id: requirementId });
    } else if (customId) {
      response = await superlogin.getHttp().post(url, { custom_id: customId });
    }
    return response.data;
  }

  // deprecated
  async updateRequirementDraft(
    id: string,
    requirement: UpdateRequirement
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/draft-requirements/${id}`;
    return superlogin.getHttp().put(url, requirement);
  }

  async listRequirementVersions(
    requirementId: string
  ): Promise<Array<RequirementRecord>> {
    const url = `${this.restUrl}/requirements/${requirementId}/versions`;
    const response = await superlogin.getHttp().get(url);
    return response.data?.data;
  }

  async deleteRequirement(requirementId: string): Promise<AxiosResponse> {
    const url = `${this.restUrl}/requirements/${requirementId}`;
    return superlogin.getHttp().delete(url);
  }

  // deprecated
  async deleteRequirementDraft(requirementId: string): Promise<AxiosResponse> {
    const url = `${this.restUrl}/draft-requirements/${requirementId}`;
    return superlogin.getHttp().delete(url);
  }

  async importRequirements(
    requirements: Array<RequirementDetails>
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/requirements/import`;
    return superlogin.getHttp().post(url, { requirements });
  }

  async getRequirementMetadataOptions(): Promise<RequirementMetadataOptions> {
    const url = `${this.restUrl}/requirements/metadata-options`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  // unused - remove
  async listReviewers(
    resourceType: 'cases' | 'requirements',
    resourceId: string
  ): Promise<Array<Reviewer>> {
    const url = `${this.restUrl}/draft-${resourceType}/${resourceId}/reviewers`;
    const response = await superlogin.getHttp().get(url);
    return response.data?.data;
  }

  // unused - remove
  async updateReviewers(
    resourceType: 'cases' | 'requirements',
    resourceId: string,
    reviewers: Array<UpsertReviewer>
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/draft-${resourceType}/${resourceId}/reviewers`;
    return superlogin.getHttp().put(url, { reviewers });
  }

  // unused - remove
  async addApproval(
    resourceType: 'cases' | 'requirements',
    resourceId: string,
    reviewerId: number,
    operatorRoleId: string
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/draft-${resourceType}/${resourceId}/approvals/${reviewerId}`;
    return superlogin.getHttp().put(url, { operator_role_id: operatorRoleId });
  }

  // unused - remove
  async deleteApproval(
    resourceType: 'cases' | 'requirements',
    resourceId: string,
    reviewerId: number
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/draft-${resourceType}/${resourceId}/approvals/${reviewerId}`;
    return superlogin.getHttp().delete(url);
  }

  // unused - remove
  async releaseDraft(
    resourceType: 'cases' | 'requirements',
    resourceId: string
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/draft-${resourceType}/${resourceId}/release`;
    return superlogin.getHttp().post(url);
  }

  // unused - remove
  async updateReviewState(
    resourceType: 'cases' | 'requirements',
    resourceId: string,
    inReview: boolean
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/draft-${resourceType}/${resourceId}/state`;
    return superlogin.getHttp().put(url, { in_review: inReview });
  }

  // unused - remove
  async listComments(
    resourceType: 'cases' | 'requirements',
    resourceId: string
  ): Promise<Array<Comment>> {
    const url = `${this.restUrl}/draft-${resourceType}/${resourceId}/comments`;
    const response = await superlogin.getHttp().get(url);
    return response.data?.data;
  }

  // unused - remove
  async addComment(
    resourceType: 'cases' | 'requirements',
    resourceId: string,
    comment: string,
    mentionList?: Array<Mention>
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/draft-${resourceType}/${resourceId}/comments`;
    return superlogin
      .getHttp()
      .post(url, { comment, mention_list: mentionList });
  }

  async importHazards(file: File): Promise<AxiosResponse> {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const url = `${this.restUrl}/risks/import`;
    return superlogin.getHttp().post(url, bodyFormData);
  }

  async importConditions(file: File): Promise<AxiosResponse> {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const url = `${this.restUrl}/conditions/import`;
    return superlogin.getHttp().post(url, bodyFormData);
  }
}

export default TestingService;

import { RequestData } from './types/realtimeUpdatesTypes';
import { DEFAULT_DICTIONARY_ID } from './types/telemetry';

enum SocketIoActions {
  joinRoom = 'joinRoom',
  leaveRoom = 'leaveRoom',
}

const getTelemetryStreamId = ({
  teamId,
  parameterName,
  runId,
  variables,
  dictionaryId = DEFAULT_DICTIONARY_ID,
}: {
  teamId: string;
  parameterName: string;
  runId: string;
  variables?: object;
  dictionaryId?: number;
}): string => {
  let streamId = `${teamId}_${runId}_${dictionaryId}_${parameterName}`;
  if (variables) {
    const variablesHash = hashCode(JSON.stringify(variables));
    streamId += `_${variablesHash}`;
  }
  return streamId;
};

const hashCode = (stringInput) => {
  let hash = 0;
  let i;
  let chr: number;
  if (stringInput.length === 0) return hash;
  for (i = 0; i < stringInput.length; i++) {
    chr = stringInput.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const getRoomEventName = (requestData: RequestData): string => {
  return (['teamId', 'entityType', 'entityId'] as Array<keyof RequestData>)
    .map((field) => requestData[field] as string)
    .filter((a) => a) // Filter out undefined values.
    .join(':');
};

const getRebroadcastEventName = (requestData: RequestData): string => {
  return `${getRoomEventName(requestData)}:rebroadcast`;
};

const getEmitEventName = (
  genericRequestData: Omit<RequestData, 'teamId'>
): string => {
  return (['entityType', 'entityId'] as Array<keyof RequestData>)
    .map((field) => genericRequestData[field] as string)
    .filter((a) => a) // Filter out undefined values.
    .join(':')
    .concat(':event');
};

export {
  SocketIoActions,
  getRoomEventName,
  getRebroadcastEventName,
  getEmitEventName,
  getTelemetryStreamId,
};

export const GANTT_ROW_HEIGHT = 60; //px

export const REFRESH_INTERVAL = 1000; //ms

export const LABEL_WIDTH = 120; // px
export const RULER_HEIGHT = 34; // px

export const INITIAL_TIME_SCALE = 0.002; // px/s
export const MAGNIFICATION_DIFF = 0.0001; // px/s

export const PIXELS_PER_TICK = 120;

export const GANTT_TIMESCALE_PADDING = { days: 1 };
export const GANTT_DEFAULT_HALF_PERIOD = { weeks: 1 };

export const SEARCH_PARAM_DEBOUNCE_MS = 400; // milliseconds

export const INSTANTANEOUS_EVENT_PADDING = 8; //px

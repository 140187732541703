import dagre from 'dagre';
import { MarkerType } from '@xyflow/react';
import procedureUtil from './procedureUtil';
import validateUtil from './validateUtil';
import stepConditionals, {
  CONDITIONAL_TYPE,
  CONDITIONAL_STATE,
  CONDITIONAL_TERNARY_STATE,
} from 'shared/lib/stepConditionals';
import { STEP_STATE, getStepState } from 'shared/lib/runUtil';
import runUtil from './runUtil';
import dependencyUtil from './stepDependencyUtil';
import { groupBatchStepsInRun } from './batchSteps';

const NODE_WIDTH = 172;
const NODE_HEIGHT = 56;
const SECTION_NODE_WIDTH = 400;
const EDGE_TYPE = 'default';
const ARROW_MARKER = { markerEnd: { type: MarkerType.Arrow } };
const DEPENDENCY_MARKER = { markerEnd: 'circle' };

/**
 * Determines the background color for a conditional label based on its state.
 *
 * @param {Object} conditional - The conditional object.
 * @returns {string} - The CSS color value.
 */
const stateBgColorCss = (conditional) => {
  const positiveStates = [STEP_STATE.COMPLETED, CONDITIONAL_STATE.PASS, CONDITIONAL_TERNARY_STATE.PASS];
  const negativeStates = [
    STEP_STATE.FAILED,
    CONDITIONAL_STATE.FAIL,
    CONDITIONAL_TERNARY_STATE.FAIL_HIGH,
    CONDITIONAL_TERNARY_STATE.FAIL_LOW,
    CONDITIONAL_TERNARY_STATE.FAIL_NO_DATA,
  ];

  if (
    [CONDITIONAL_TYPE.STEP, CONDITIONAL_TYPE.CONTENT_BINARY, CONDITIONAL_TYPE.CONTENT_TERNARY].includes(
      conditional.source_type
    )
  ) {
    if (positiveStates.includes(conditional.state)) {
      return '#d8ece4'; // app-green-200
    } else if (negativeStates.includes(conditional.state)) {
      return 'rgb(254 202 202)'; // red-200
    }
  }
  return 'rgb(191 219 254)'; // bg-blue-200
};

const getLabelFromConditional = (conditional) => ({
  label: `${conditional.state.toUpperCase()}`,
  labelBgPadding: [8, 4],
  labelBgBorderRadius: 10,
  labelBgStyle: { fill: stateBgColorCss(conditional) },
});

const getStepsForSection = (section) =>
  groupBatchStepsInRun(section.steps).map((stepOrBatch) => (Array.isArray(stepOrBatch) ? stepOrBatch[0] : stepOrBatch));

const doNodesOverlap = (nodeA, nodeB) => {
  return (
    nodeA.position.x < nodeB.position.x + nodeB.style.width &&
    nodeA.position.x + nodeA.style.width > nodeB.position.x &&
    nodeA.position.y < nodeB.position.y + nodeB.style.height &&
    nodeA.position.y + nodeA.style.height > nodeB.position.y
  );
};

/**
 * Main flowchart utility object.
 */
const flowchart = {
  /**
   * Adds an edge between two nodes.
   *
   * @param {Object} nodeIDs - Contains sourceId and targetId.
   * @param {Object} nodeData - Contains nodes, edges, nodeIsDependentMap, edgeIdSet.
   * @param {boolean} isDependent - Indicates if the edge is a dependency.
   * @param {Object} markerEnd - Marker configuration for the edge.
   * @param {Object} label - Label configuration for the edge.
   */
  _addEdge: (nodeIDs, nodeData, isDependent, markerEnd, label = {}) => {
    // Avoid adding a default edge if the target node already has a dependency edge
    if (nodeData.nodeIsDependentMap.get(nodeIDs.targetId) && !isDependent) {
      return;
    }

    const id = `edge-${nodeIDs.sourceId}-${nodeIDs.targetId}`;
    let style = { strokeWidth: '2', zIndex: '10' };
    if (!isDependent) {
      style = { ...style, strokeDasharray: '5' };
    }

    if (!nodeData.edgeIdSet.has(id)) {
      nodeData.edges.push({
        id,
        source: nodeIDs.sourceId,
        target: nodeIDs.targetId,
        type: EDGE_TYPE,
        style,
        ...markerEnd,
        ...label,
      });
      nodeData.edgeIdSet.add(id);
      nodeData.nodeIsDependentMap.set(nodeIDs.targetId, isDependent);
    }
  },

  /**
   * Adds a section dependency edge.
   *
   * @param {Object} nodeIds - Contains sourceId and targetId.
   * @param {Object} nodeData - Contains sectionEdges.
   */
  _addSectionDependency: (nodeIds, nodeData) => {
    const formatId = (id) => (id.startsWith('sctn_') ? `section-${id}` : id);

    const sourceId = formatId(nodeIds.sourceId);
    const targetId = formatId(nodeIds.targetId);
    const edgeId = `edge-${sourceId}-${targetId}`;

    nodeData.sectionEdges.push({
      id: edgeId,
      source: sourceId,
      target: targetId,
      isDependency: true,
    });
  },

  /**
   * Checks if adding a conditional would create a cycle and updates the sourceConditionalsMap.
   *
   * @param {Object} conditional - The conditional object.
   * @param {Object} sourceConditionalsMap - Map of source conditionals.
   * @returns {boolean} - True if it creates a cycle, false otherwise.
   */
  _hasConditionalCycle: (conditional, sourceConditionalsMap) => {
    const createsCycle = validateUtil.conditionalCreatesCycle(
      conditional.source_id,
      conditional.target_id,
      sourceConditionalsMap
    );

    if (!createsCycle) {
      if (!sourceConditionalsMap[conditional.target_id]) {
        sourceConditionalsMap[conditional.target_id] = new Set();
      }
      sourceConditionalsMap[conditional.target_id].add(conditional);
    }

    return createsCycle;
  },

  /**
   * Checks for dependency cycles between steps.
   *
   * @param {string} sourceId - The source step ID.
   * @param {string} targetId - The target step ID.
   * @param {Object} stepMap - Map of step information.
   * @returns {boolean} - True if it creates a cycle, false otherwise.
   */
  _hasDependencyCycle: (sourceId, targetId, stepMap) => {
    const visited = new Set();
    const stack = [targetId];

    while (stack.length) {
      const currentId = stack.pop();

      if (currentId === sourceId) {
        return true;
      }

      if (currentId && !visited.has(currentId)) {
        visited.add(currentId);
        const dependencies = stepMap[currentId]?.targetDependencies || [];
        stack.push(...dependencies);
      }
    }

    return false;
  },

  /**
   * Gets the next step ID in linear progression.
   *
   * @param {string} stepId - The current step ID.
   * @param {Object} stepMap - Map of step information.
   * @param {Array} stepList - List of step IDs.
   * @param {Object} repeatMap - Map of repeated steps.
   * @returns {string|null} - The next step ID or null if none found.
   */
  _getNextStepId: (stepId, stepMap, stepList, repeatMap) => {
    const currentIndex = stepMap[stepId].index;

    for (let i = currentIndex + 1; i < stepList.length; i++) {
      const nextStepId = stepList[i];
      const nextStep = stepMap[nextStepId];
      if (nextStep.sourceDependencies.size === 0 && !repeatMap[nextStepId]) {
        return nextStepId;
      }
    }
    return null;
  },

  /**
   * Determines the color styling for a node based on its state.
   *
   * @param {Object} step - The step object.
   * @returns {Object} - Style object with backgroundColor and borderColor.
   */
  _nodeColor: (step) => {
    if (!step) return {};

    const stepState = getStepState(step);
    switch (stepState) {
      case STEP_STATE.COMPLETED:
        return {
          backgroundColor: 'rgb(216 236 228)', // app-green-200
          borderColor: 'rgb(44 172 123)', // app-green-600
        };
      case STEP_STATE.FAILED:
        return {
          backgroundColor: 'rgb(254 202 202)', // red-200
          borderColor: 'rgb(248 113 113)', // red-500
        };
      case STEP_STATE.SKIPPED:
        return {
          backgroundColor: 'rgb(224 224 224)', // gray-200
          borderColor: 'rgb(132 132 132)', // gray-500
        };
      default:
        return {};
    }
  },

  /**
   * Generates a list of nodes and edges for React Flow using Dagre for layout.
   *
   * @param {Object} procedure - The procedure document.
   * @param {Object} config - Workspace config for generating keys.
   * @returns {Object} - Contains arrays of nodes and edges.
   */
  getPositionedElements: (procedure, config) => {
    const nodes = [];
    const edges = [];
    const sectionMap = {};
    const stepMap = {};
    const stepList = [];
    const repeatMap = {};
    const sectionEdges = [];
    const edgesToRemove = [];
    const nodeIsDependentMap = new Map();
    const edgeIdSet = new Set();
    const sourceConditionalsMap = {};
    const sectionDependencies = {};

    let globalStepIndex = 0;
    let sectionIndex = -1;

    // Build section and step maps
    procedure.sections.forEach((section) => {
      if (!section.repeat_of) {
        sectionIndex++;
      } else {
        repeatMap[section.repeat_of] = section.id;
      }

      const sectionKey = procedureUtil.displaySectionKey(sectionIndex, config?.display_sections_as);
      sectionMap[section.id] = {
        section,
        sectionKey,
        targetDependencies: new Set(),
        sourceDependencies: new Set(),
      };

      let stepIndex = -1;
      const steps = getStepsForSection(section);

      steps.forEach((step) => {
        if (!('repeat_of' in step && step.repeat_of)) {
          stepIndex++;
        } else {
          repeatMap[step.repeat_of] = step.id;
        }

        const stepKey = procedureUtil.displaySectionStepKey(sectionIndex, stepIndex, config?.display_sections_as);
        stepMap[step.id] = {
          step,
          section,
          stepKey,
          index: globalStepIndex,
          sourceDependencies: new Set(),
          targetDependencies: new Set(),
        };
        stepList[globalStepIndex] = step.id;
        globalStepIndex++;
      });
    });

    // Add dependencies to the map
    procedure.sections.forEach((section) => {
      const steps = getStepsForSection(section);

      // Check if section.dependencies exists and fetch dependentIds
      if (section?.dependencies) {
        const dependentIds = dependencyUtil.getAllDependentIds(section.dependencies);

        dependentIds.forEach((dependentId) => {
          // Ensure dependentId is in sectionMap before accessing properties
          if (sectionMap[dependentId]) {
            sectionMap[dependentId].targetDependencies.add(section.id);
          }

          // Initialize sourceDependencies for section if not already set
          if (!sectionMap[section.id].sourceDependencies) {
            sectionMap[section.id].sourceDependencies = new Set();
          }
          sectionMap[section.id].sourceDependencies.add(dependentId);
        });
      }

      steps.forEach((step) => {
        const dependentIds = dependencyUtil.getAllDependentIds(step.dependencies);

        dependentIds.forEach((dependentId) => {
          if (stepMap[dependentId]) {
            stepMap[dependentId].targetDependencies.add(step.id);
          }
          if (sectionMap[dependentId]) {
            sectionMap[dependentId].targetDependencies.add(step.id);
          }
          stepMap[step.id].sourceDependencies.add(dependentId);
        });

        if (step.conditionals) {
          step.conditionals.forEach((conditional) => {
            if (!conditional.target_id) return;
            stepMap[step.id].targetDependencies.add(conditional.target_id);
            stepMap[conditional.target_id].sourceDependencies.add(step.id);
          });
        }
      });
    });

    // Build nodes and edges
    procedure.sections.forEach((section) => {
      const sectionNodeId = `section-${section.id}`;
      const steps = getStepsForSection(section);
      const sectionKey = sectionMap[section.id].sectionKey;

      // Add section node
      nodes.push({
        id: sectionNodeId,
        data: { label: `Section ${sectionKey}: ${section.name || ''}` },
        type: 'section',
        position: { x: 0, y: 0 },
        style: {
          width: 1000, // Set an initial width
          height: 1000, // Set an initial height
          backgroundColor: 'rgba(240, 240, 240, 0.2)',
          border: '1px solid #ccc',
          borderRadius: '8px',
          zIndex: '1',
        },
      });

      // Populate section dependencies
      if (section.dependencies) {
        section.dependencies.forEach((dependency) => {
          dependency.dependent_ids.forEach((dependentSectionId) => {
            if (!sectionDependencies[section.id]) {
              sectionDependencies[section.id] = new Set();
            }
            sectionDependencies[section.id].add(dependentSectionId);
          });
        });
      }

      // Add step nodes and conditional edges
      steps.forEach((step, idx) => {
        if (repeatMap[step.id]) return;

        const { stepKey } = stepMap[step.id];
        const repeatKey = runUtil.displayRepeatKey(steps, idx);

        nodes.push({
          id: step.id,
          data: {
            stepKey,
            label: step.name,
            isRepeat: 'repeat_of' in step && !!step.repeat_of,
            repeatKey,
          },
          type: 'step',
          position: { x: 0, y: 0 },
          parentId: sectionNodeId,
          extent: 'parent',
          draggable: false,
          style: {
            width: NODE_WIDTH,
            height: NODE_HEIGHT,
            borderRadius: '0.375rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            zIndex: '5',
            ...flowchart._nodeColor(step),
          },
        });
        nodeIsDependentMap.set(step.id, false);

        // Add conditional edges
        if (step.conditionals) {
          const hideLabel =
            stepConditionals.areConditionalsRedundant(step.conditionals) ||
            stepConditionals.isSingleConditional(step.conditionals);

          step.conditionals.forEach((conditional) => {
            if (!conditional.target_id) return;
            const createsCycle = flowchart._hasConditionalCycle(conditional, sourceConditionalsMap);
            if (createsCycle) return;

            const label = hideLabel ? {} : getLabelFromConditional(conditional);
            const nodeIds = {
              sourceId: step.id,
              targetId: conditional.target_id,
            };

            flowchart._addEdge(nodeIds, { nodes, edges, nodeIsDependentMap, edgeIdSet }, true, ARROW_MARKER, label);
          });
        }
      });
    });

    // Add dependency edges
    procedure.sections.forEach((section) => {
      const steps = getStepsForSection(section);

      // Section dependencies
      if (section.dependencies) {
        section.dependencies.forEach((dependency) => {
          dependency.dependent_ids.forEach((dependentSectionId) => {
            const nodeIds = {
              sourceId: dependentSectionId,
              targetId: section.id,
            };
            flowchart._addSectionDependency(nodeIds, {
              sectionEdges,
            });
          });
        });
      }

      // Step dependencies
      steps.forEach((step) => {
        if (repeatMap[step.id]) return;

        const { targetDependencies, sourceDependencies } = stepMap[step.id];

        // Add edges for target dependencies
        targetDependencies.forEach((targetId) => {
          const createsCycle = flowchart._hasDependencyCycle(step.id, targetId, stepMap);
          if (createsCycle) return;

          const nodeIds = {
            sourceId: step.id,
            targetId,
          };

          flowchart._addEdge(nodeIds, { nodes, edges, nodeIsDependentMap, edgeIdSet }, true, DEPENDENCY_MARKER);
        });

        // Add edges for source dependencies (cross-section)
        sourceDependencies.forEach((sourceId) => {
          if (!sectionMap[sourceId]) return;
          const sourceSectionId = `section-${sourceId}`;
          const nodeIds = {
            sourceId: sourceSectionId,
            targetId: step.id,
          };
          flowchart._addSectionDependency(nodeIds, {
            sectionEdges,
          });
        });
      });
    });

    // Add default linear edges within sections
    procedure.sections.forEach((section) => {
      const steps = getStepsForSection(section);

      steps.forEach((step) => {
        if (repeatMap[step.id]) return;

        const stepData = stepMap[step.id];
        const { targetDependencies } = stepData;
        const nextStepId = flowchart._getNextStepId(step.id, stepMap, stepList, repeatMap);
        const nextStepData = stepMap[nextStepId];

        // Check if nextStepId exists and is in the same section
        const isSameSection = nextStepData && stepData.section.id === nextStepData.section.id;

        if (nextStepId && isSameSection && !step.conditionals?.length && !targetDependencies.size) {
          const createsCycle = flowchart._hasDependencyCycle(step.id, nextStepId, stepMap);
          if (createsCycle) {
            stepData.targetDependencies.delete(nextStepId);
            return;
          }

          const nodeIds = {
            sourceId: step.id,
            targetId: nextStepId,
          };

          flowchart._addEdge(nodeIds, { nodes, edges, nodeIsDependentMap, edgeIdSet }, false, ARROW_MARKER);
        }
      });
    });

    // Add default edges from section to section
    procedure.sections.forEach((section, index) => {
      if (index === 0) return;

      const previousSection = procedure.sections[index - 1];
      const currentSection = section;

      const previousSectionId = previousSection.id;
      const currentSectionId = currentSection.id;

      const previousSectionData = sectionMap[previousSectionId];
      const currentSectionData = sectionMap[currentSectionId];

      if (!previousSectionData || !currentSectionData) {
        return;
      }

      const currentSectionHasIncomingDependencies = sectionMap[currentSectionId]?.sourceDependencies?.size > 0;

      const previousSteps = getStepsForSection(previousSection);
      const currentSteps = getStepsForSection(currentSection);

      const lastStepOfPreviousSection = previousSteps[previousSteps.length - 1];
      const firstStepOfCurrentSection = currentSteps[0];

      if (lastStepOfPreviousSection && firstStepOfCurrentSection) {
        const edgeId = `edge-${lastStepOfPreviousSection.id}-${firstStepOfCurrentSection.id}`;

        flowchart._addEdge(
          {
            sourceId: lastStepOfPreviousSection.id,
            targetId: firstStepOfCurrentSection.id,
          },
          { nodes, edges, nodeIsDependentMap, edgeIdSet },
          false,
          ARROW_MARKER
        );

        edgesToRemove.push(edgeId);
      }
      // Only add default edge if previous section has no outgoing dependencies
      if (!currentSectionHasIncomingDependencies) {
        sectionEdges.push({
          id: `edge-section-${previousSectionId}-section-${currentSectionId}`,
          source: `section-${previousSectionId}`,
          target: `section-${currentSectionId}`,
          isDependency: false,
        });
      }
    });

    // Initialize Dagre graph
    const dagreGraph = new dagre.graphlib.Graph({ compound: true });
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    dagreGraph.setGraph({ rankdir: 'TB', align: 'UL', ranksep: 75 });

    // Add nodes to the graph
    nodes.forEach((node) => {
      const width = Number(node.style.width) || NODE_WIDTH;
      const height = Number(node.style.height) || NODE_HEIGHT;

      dagreGraph.setNode(node.id, { width, height });

      if (node.parentId) {
        dagreGraph.setParent(node.id, node.parentId);
      }
    });

    // Add edges to the graph
    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    // Compute the layout
    dagre.layout(dagreGraph);

    // Update node positions with absolute positions
    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      if (nodeWithPosition) {
        node.position = {
          x: nodeWithPosition.x - (Number(node.style.width) || NODE_WIDTH) / 2,
          y: nodeWithPosition.y - (Number(node.style.height) || NODE_HEIGHT) / 2,
        };
      }
    });

    // Calculate section node sizes based on child positions
    nodes.forEach((sectionNode) => {
      if (sectionNode.type === 'section') {
        const childNodes = nodes.filter((n) => n.parentId === sectionNode.id);

        if (childNodes.length > 0) {
          let minX = Infinity;
          let minY = Infinity;
          let maxX = -Infinity;
          let maxY = -Infinity;

          childNodes.forEach((child) => {
            const childX = child.position.x;
            const childY = child.position.y;
            const childWidth = Number(child.style.width) || NODE_WIDTH;
            const childHeight = Number(child.style.height) || NODE_HEIGHT;

            minX = Math.min(minX, childX);
            minY = Math.min(minY, childY);
            maxX = Math.max(maxX, childX + childWidth);
            maxY = Math.max(maxY, childY + childHeight);
          });

          const padding = 40;

          sectionNode.position = {
            x: minX - padding,
            y: minY - padding,
          };

          sectionNode.style.width = maxX - minX + 2 * padding;
          sectionNode.style.height = maxY - minY + 2 * padding;
        } else {
          sectionNode.style.width = SECTION_NODE_WIDTH;
          sectionNode.style.height = NODE_HEIGHT;
        }
      }
    });

    // Overlap detection and adjustment for section nodes
    const moveSectionAndChildren = (sectionNode, offsetY) => {
      // Move the section node
      sectionNode.position.y += offsetY;

      // Move child nodes
      nodes.forEach((node) => {
        if (node.parentId === sectionNode.id) {
          node.position.y += offsetY;
        }
      });
    };

    const sectionNodes = nodes.filter((node) => node.type === 'section');
    sectionNodes.sort((a, b) => a.position.y - b.position.y);

    let hasOverlaps = true;
    const maxIterations = sectionNodes.length; // Overlap will only check one section at a time
    let iteration = 0;

    while (hasOverlaps && iteration < maxIterations) {
      hasOverlaps = false;
      iteration += 1;

      let lowestPosition = sectionNodes[0].position.y + sectionNodes[0].style.height;

      for (let i = 1; i < sectionNodes.length; i++) {
        const sectionA = sectionNodes[i - 1];
        const sectionB = sectionNodes[i];

        // Check for overlap with the previous section
        if (doNodesOverlap(sectionA, sectionB)) {
          hasOverlaps = true;

          // Calculate the amount to move sectionB and all subsequent sections down
          const overlapHeight = sectionA.position.y + sectionA.style.height - sectionB.position.y;
          const offsetY = overlapHeight + 50;

          // Move sectionB and all following sections
          for (let j = i; j < sectionNodes.length; j++) {
            moveSectionAndChildren(sectionNodes[j], offsetY);
            sectionNodes[j].position.y += offsetY;
          }
          // Update lowest position to reflect the move
          lowestPosition = sectionNodes[i].position.y + sectionNodes[i].style.height;

          // Break after adjusting all subsequent sections
          break;
        } else {
          // Update lowestPosition if no overlap, so subsequent nodes start below
          lowestPosition = Math.max(lowestPosition, sectionB.position.y + sectionB.style.height);
        }
      }
    }

    // Adjust child node positions to be relative to their parent nodes
    nodes.forEach((node) => {
      if (node.parentId) {
        const parentNode = nodes.find((n) => n.id === node.parentId);
        if (parentNode) {
          node.position = {
            x: node.position.x - parentNode.position.x,
            y: node.position.y - parentNode.position.y,
          };
        }
      }
    });

    // Add section edges
    sectionEdges.forEach((edgeInfo) => {
      const isDependency = edgeInfo.isDependency;
      edges.push({
        id: edgeInfo.id,
        source: edgeInfo.source,
        target: edgeInfo.target,
        type: EDGE_TYPE,
        style: { strokeWidth: '2', zIndex: '10', ...(isDependency ? {} : { strokeDasharray: '5' }) },
        ...(isDependency ? DEPENDENCY_MARKER : ARROW_MARKER),
      });
    });

    // Remove edges as needed
    edgesToRemove.forEach((edgeId) => {
      const index = edges.findIndex((edge) => edge.id === edgeId);
      if (index !== -1) {
        edges.splice(index, 1);
      }
    });

    return {
      nodes,
      edges,
    };
  },
};

export default flowchart;

import React, { useCallback, useMemo } from 'react';
import ProcedureSection from './ProcedureSection';
import { ProcedureContextProvider } from '../contexts/ProcedureContext';
import { RunContextProvider } from '../contexts/RunContext';
import procedureUtil from '../lib/procedureUtil';
import TextLinkify from './TextLinkify';
import ProcedureHeader from './ProcedureHeader';
import '../App.css';
import { useSettings } from '../contexts/SettingsContext';
import RunProcedureVariables from './RunProcedureVariables';
import diffUtil from '../lib/diffUtil';
import sharedDiffUtil from 'shared/lib/diffUtil';
import PartList from '../manufacturing/components/PartList';
import SnippetSelector from '../testing/components/SnippetSelector';
import useTelemetryParameters from '../hooks/useTelemetryParameters';
import ProcedureRiskList from '../risks/components/ProcedureRiskList';
import FieldLabel from '../elements/internal/FieldLabel';
import { Procedure, Run } from 'shared/lib/types/views/procedures';

// TODO fill in these types
interface ProcedureContentProps {
  procedure: Procedure;
  onResolveReviewComment?;
  onUnresolveReviewComment?;
  saveReviewComment?;
  addStepAfter?;
  isCollapsedMap;
  setIsCollapsed;
  areAllStepsInSectionExpanded;
  setAllStepsInSectionExpanded;
  scrollToBufferRem?;
  showReviewComments?;
  onlyShowSection?;
  onSectionOrStepRefChanged?;
  onScrollToId?;
}

/**
 * Displays the procedure description and all section and step content.
 *
 * @param procedure - Procedure whose content to render.
 * @param saveReviewComment - Function called to save a review comment.
 *                                       Accepts a comment object.
 *                                 from the released version of the procedure.
 */
const ProcedureContent = ({
  procedure,
  onResolveReviewComment,
  onUnresolveReviewComment,
  saveReviewComment,
  addStepAfter,
  isCollapsedMap,
  setIsCollapsed,
  areAllStepsInSectionExpanded,
  setAllStepsInSectionExpanded,
  scrollToBufferRem = 0,
  showReviewComments,
  onlyShowSection,
  onSectionOrStepRefChanged,
  onScrollToId,
}: ProcedureContentProps) => {
  const { getSetting, isRisksEnabled } = useSettings();
  const { fetchedTelemetryParameters } = useTelemetryParameters({ procedure });

  // Array of booleans mapping whether all the steps in section *i* are collapsed
  const allStepsInSectionExpandedMap = React.useMemo(
    () => procedure && procedure.sections.map(areAllStepsInSectionExpanded),
    [areAllStepsInSectionExpanded, procedure]
  );

  const scrollTo = useCallback(
    ({ sectionId, stepId, stepHeaderId, contentId }) => {
      const scrollToId = contentId || stepHeaderId || stepId || sectionId;
      onScrollToId({
        sectionId,
        stepId,
        scrollToId,
      });
    },
    [onScrollToId]
  );

  const isReviewCommentEnabled = useMemo(() => {
    return showReviewComments && (procedure.state === 'in_review' || procedure.state === 'released');
  }, [showReviewComments, procedure.state]);
  const removedSectionMap = useMemo(
    () => sharedDiffUtil.getContainerMap(procedure.sections, 'old'),
    [procedure.sections]
  );
  const nonRemovedSectionMap = useMemo(
    () => sharedDiffUtil.getContainerMap(procedure.sections, 'new'),
    [procedure.sections]
  );
  const getSectionKey = useCallback(
    (section, sectionIndex) => {
      const sectionIndexForKey =
        procedure.state === 'in_review'
          ? diffUtil.getIndexForKey(section, removedSectionMap, nonRemovedSectionMap) ?? 0
          : sectionIndex;

      return procedureUtil.displaySectionKey(sectionIndexForKey, getSetting('display_sections_as', 'letters'));
    },
    [getSetting, nonRemovedSectionMap, procedure.state, removedSectionMap]
  );

  const sections = useMemo(() => {
    if (!procedure || !procedure.sections) {
      return [];
    }

    return procedure.sections;
  }, [procedure]);

  const sourceName = useMemo(() => {
    if (procedure.code && procedure.name) {
      return `${procedure.code} - ${procedure.name}`;
    }

    return 'Untitled procedure';
  }, [procedure]);

  return (
    <>
      <div className="mb-3">
        <span className="whitespace-pre-line break-words">
          <TextLinkify>{procedure.description}</TextLinkify>
        </span>
      </div>
      <ProcedureContextProvider procedure={procedure} scrollTo={scrollTo}>
        <RunContextProvider run={(procedure as Run) || {}} fetchedTelemetryParameters={fetchedTelemetryParameters}>
          {isRisksEnabled?.() && procedure.risks && procedure.risks.length > 0 && (
            <div>
              <ProcedureRiskList procedureRisks={procedure.risks} />
            </div>
          )}

          {procedure.variables && procedure.variables?.length > 0 && (
            <div className="mt-2 p-2 bg-white border border-gray-200 rounded">
              <FieldLabel label="Procedure Variables" />
              <RunProcedureVariables
                isEnabled={false}
                variables={procedure.variables}
                onRefChanged={onSectionOrStepRefChanged}
                scrollMarginTopValueRem={scrollToBufferRem}
              />
            </div>
          )}

          {procedure.part_list && (
            <div className="mt-2 p-2 bg-white border border-gray-200 rounded">
              <FieldLabel label="Bill of Materials" />
              <PartList content={procedure.part_list} isHidden={false} />
            </div>
          )}

          {procedure.test_case_list && (
            <div className="my-4">
              <SnippetSelector content={procedure.test_case_list} />
            </div>
          )}

          {/* Procedure Header */}
          {procedure.headers && (
            <div className="mt-3">
              {procedure.headers.map((header) => {
                return (
                  <ProcedureHeader
                    key={header.id}
                    header={header}
                    isCollapsed={isCollapsedMap[header.id]}
                    onCollapse={setIsCollapsed}
                  />
                );
              })}
            </div>
          )}
          {/* Grid tracks: [section, bullet and content 1, content 2 and checkbox] */}
          <table className="table-fixed w-full border-collapse" cellSpacing="0" cellPadding="0" border={0}>
            <thead>
              <tr>
                <th className="w-4"></th>
                <th className="w-auto"></th>
                <th className="w-64"></th>
              </tr>
            </thead>
            {sections.map((section, sectionIndex) => {
              // Need to return null for all sections that do not match the "onlyShowSection" id to keep track of the section sequence numbers.
              if (onlyShowSection && onlyShowSection !== section.id) {
                return null;
              } else {
                return (
                  <ProcedureSection
                    key={`section.${sectionIndex}`}
                    section={section}
                    sectionKey={getSectionKey(section, sectionIndex)}
                    sectionIndex={sectionIndex}
                    isCollapsedMap={isCollapsedMap}
                    onCollapse={setIsCollapsed}
                    onResolveReviewComment={onResolveReviewComment}
                    onUnresolveReviewComment={onUnresolveReviewComment}
                    saveReviewComment={saveReviewComment}
                    addStepAfter={addStepAfter}
                    docState={procedure.state}
                    comments={(procedure as Run).comments}
                    showReviewComments={isReviewCommentEnabled}
                    onRefChanged={onSectionOrStepRefChanged}
                    onExpandCollapseAllSteps={() =>
                      setAllStepsInSectionExpanded(!allStepsInSectionExpandedMap[sectionIndex], section)
                    }
                    allStepsAreExpanded={allStepsInSectionExpandedMap && allStepsInSectionExpandedMap[sectionIndex]}
                    scrollToBufferRem={scrollToBufferRem}
                    isStrictSignoffEnabled={procedure.is_strict_signoff_enabled}
                    sourceName={sourceName}
                  />
                );
              }
            })}
          </table>
        </RunContextProvider>
      </ProcedureContextProvider>
    </>
  );
};

export default ProcedureContent;

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type IconType = IconProp | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

const isFontAwesome = (element: IconType): element is IconProp => {
  /*
   * FontAwesome icons can be defined as:
   * 1. a text string to look up the icon
   * 2. a tuple (with a length) like ['fas','cog']
   * 3. an icon definition object with property iconName
   */
  return typeof element === 'string' || element['length'] || 'iconName' in element;
};

interface IconProps {
  element: IconType;
  className?: string;
}

const Icon = ({ element, className }: IconProps): JSX.Element => {
  if (isFontAwesome(element)) {
    return <FontAwesomeIcon className={className} icon={element as IconProp} />;
  }

  const SvgIcon = element;
  //match this svg to the class of our font awesome svgs for consistency
  return <SvgIcon className={`${className} svg-inline--fa fa-plus`} />;
};

export default Icon;

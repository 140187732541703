import superlogin from './superlogin';
import { API_URL } from '../config';
import { Operator } from 'shared/lib/types/settings';

type createOperatorParams = {
  name: string;
  code: string;
  canRelease?: boolean;
  canSuggestEdits?: boolean;
};

type UpdateOperatorParams = Partial<createOperatorParams> & {
  id: string;
};

class OperatorRolesService {
  private readonly teamId: string;

  constructor(teamId: string) {
    this.teamId = teamId;
  }

  async getOperatorRoles(): Promise<Array<Operator>> {
    const url = `${API_URL}/teams/${this.teamId}/operator-roles`;
    const response = await superlogin.getHttp().get(url);
    return response.data;
  }

  async createOperatorRole({
    name,
    code,
    canRelease,
    canSuggestEdits,
  }: createOperatorParams): Promise<{ id: string }> {
    const url = `${API_URL}/teams/${this.teamId}/operator-roles`;
    const body = {
      name,
      code,
      ...(canRelease ? { can_release: canRelease } : {}),
      ...(canSuggestEdits ? { can_suggest_edits: canSuggestEdits } : {}),
    };
    try {
      const response = await superlogin.getHttp().post(url, body);
      return response.data;
    } catch (error) {
      const statusCode = error && error.response && error.response.status;

      if (statusCode >= 400 && statusCode <= 499) {
        error.userFacingMessage = error.response.data.message;
        throw error;
      }

      throw error;
    }
  }

  async updateOperatorRole({
    id,
    name,
    code,
    canRelease,
    canSuggestEdits,
  }: UpdateOperatorParams): Promise<void> {
    const url = `${API_URL}/teams/${this.teamId}/operator-roles/${id}`;
    const body = {
      ...(name ? { name } : {}),
      ...(code ? { code } : {}),
      ...(canRelease !== undefined ? { can_release: canRelease } : {}),
      ...(canSuggestEdits !== undefined
        ? { can_suggest_edits: canSuggestEdits }
        : {}),
    };
    try {
      await superlogin.getHttp().patch(url, body);
    } catch (error) {
      const statusCode = error && error.response && error.response.status;

      if (statusCode >= 400 && statusCode <= 499) {
        error.userFacingMessage = error.response.data.message;
        throw error;
      }
      return;
    }
  }

  async deleteOperatorRoles(ids: Array<string>): Promise<void> {
    const url = `${API_URL}/teams/${this.teamId}/operator-roles`;
    const body = {
      ids,
    };
    await superlogin.getHttp().delete(url, { data: body });
    return;
  }
}

export default OperatorRolesService;

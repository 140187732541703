import React, { useMemo } from 'react';
import { ParentReferenceType, ProcedureLinkBlock } from 'shared/lib/types/views/procedures';
import BlockProcedureLink from '../../components/Blocks/BlockProcedureLink';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
import { ProcedureContextProvider } from '../../contexts/ProcedureContext';
import { RunContextProvider } from '../../contexts/RunContext';
import { VIEW_MODES } from '../../components/FieldSetViewModeEditSelect';

interface EventProcedureLinkProps {
  event: Event;
  handleStartRun?: (contentIndex: number, run: string) => void;
  multiLine?: boolean;
}

const EventProcedureLink = ({ event, handleStartRun, multiLine = true }: EventProcedureLinkProps) => {
  const procedureShell = useMemo(() => {
    return {
      _id: '',
      code: '',
      name: '',
      description: '',
      starttime: '',
      sections: [
        {
          id: 'steps',
          name: 'steps',
          steps: [
            {
              id: 'stub_step_id',
              name: 'sub_step',
              content: [],
              signoffs: [],
            },
          ],
        },
      ],
    };
  }, []);

  const procedureIds: ProcedureLinkBlock = {
    id: 'event_procedure_link',
    procedure: event.procedure_id || '',
    section: event.procedure_section_id || '',
    run: event.run_id || '',
    type: 'procedure_link',
  };
  const canAddRun = !event.operation || event.operation.state === 'running';

  return (
    <ProcedureContextProvider procedure={procedureShell} scrollTo={undefined}>
      <RunContextProvider
        run={procedureShell || {}}
        viewMode={VIEW_MODES.LIST}
        isPreviewMode={false}
        showStepAction=""
        setShowStepAction={() => {
          /* no-op */
        }}
        currentStepId=""
        setCurrentStepId={() => {
          /* no-op */
        }}
        telemetryParameters={[]}
        fetchedTelemetryParameters={[]}
        optionalParameters={{
          forceUserToParticipate: true,
          parentReference: {
            id: event.id,
            type: ParentReferenceType.Event,
          },
        }}
      >
        <BlockProcedureLink
          contentIndex={0}
          docId=""
          docState="running"
          operation=""
          link={procedureIds}
          onStartLinkedRun={handleStartRun}
          isHidden={false}
          isEnabled={canAddRun}
          showLabels={false}
          multiLine={multiLine}
          sourceName={event.name}
        />
      </RunContextProvider>
    </ProcedureContextProvider>
  );
};

export default EventProcedureLink;

import { useEffect, useState } from 'react';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { DatabaseServices } from '../contexts/proceduresSlice';
import apm from '../lib/apm';

export type UseRunNumber = {
  runNumber: number | undefined;
};

const useRunNumber = (runId?: string): UseRunNumber => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const [runNumber, setRunNumber] = useState<number>();

  useEffect(() => {
    (async () => {
      if (!runId) {
        return;
      }
      const run = await services.runs.getRun(runId);
      setRunNumber(run.run_number);
    })().catch((err) => apm.captureError(err));
  });

  return { runNumber };
};

export default useRunNumber;

import superlogin from './superlogin';
import { API_URL } from '../config';
import { AxiosResponse } from 'axios';
import { UserRole } from 'shared/lib/types/settings';

class UserService {
  private teamId: string;

  constructor(teamId: string) {
    this.teamId = teamId;
  }

  async createUserInvites(
    emails: Array<string>,
    workspaceRole: string | undefined,
    operatorRoles: Array<string>,
    projects: Map<string, UserRole | undefined>
  ): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/invites`;
    const projectRoles = Array.from(projects.entries(), ([id, role]) => ({
      project_id: id,
      role_id: role?.id,
    })).filter((project) => project.role_id); // filter out none access
    const body = {
      emails,
      workspace_access: workspaceRole,
      operator_roles: operatorRoles,
      project_access: projectRoles,
    };

    return superlogin.getHttp().post(url, body);
  }

  async updateUsers(
    emails: Array<string>,
    workspaceRole: UserRole | undefined,
    operatorRoles: Array<string>,
    projects: Map<string, UserRole | undefined>
  ): Promise<void> {
    const url = `${API_URL}/teams/${this.teamId}/users`;
    const projectRoles = Array.from(projects.entries(), ([id, role]) => ({
      project_id: id,
      ...(role || {}),
    })).filter((role) => role.id); // filter out none
    const body = {
      ids: emails,
      workspace_role: workspaceRole,
      operator_roles: operatorRoles,
      project_roles: projectRoles,
    };

    return superlogin.getHttp().patch(url, body);
  }

  async deleteUsers(emails: Array<string>): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/users/deactivate`;
    const body = {
      emails,
    };

    return superlogin.getHttp().post(url, body);
  }

  async updateLdapUsers(): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/users/update-ldap`;
    return superlogin.getHttp().post(url);
  }

  // TODO: move static method out of class
  static async createTeam(name: string): Promise<AxiosResponse> {
    const body = { name };
    const response = await superlogin.getHttp().post(`${API_URL}/teams`, body);
    if (!response || !response.data) {
      return Promise.reject('Missing or invalid response');
    }
    if (response.data.error) {
      return Promise.reject(response.data.error);
    }
    return response.data;
  }
}

export default UserService;

import { useState, useEffect } from 'react';
import useTools from './useTools';
import useToolInstances from './useToolInstances';
import toDisplay, { ToolAndToolInstance } from '../lib/tools/toDisplay';

type UseToolAndToolInstanceProps = {
  toolInstanceId: number;
};

type UseToolAndToolInstanceReturn = {
  toolAndToolInstance: ToolAndToolInstance | undefined;
};

const useToolAndToolInstance = ({
  toolInstanceId,
}: UseToolAndToolInstanceProps): UseToolAndToolInstanceReturn => {
  const [toolAndToolInstance, setToolAndToolInstance] =
    useState<ToolAndToolInstance>();
  const { getTool } = useTools();
  const { getToolInstance } = useToolInstances();

  useEffect(() => {
    const toolInstance = getToolInstance(toolInstanceId);
    if (!toolInstance) {
      return;
    }
    const tool = getTool(toolInstance.tool_id);
    if (!tool) {
      return;
    }
    const toolAndToolInstance = toDisplay.fromToolAndToolInstance(
      tool,
      toolInstance
    );
    setToolAndToolInstance(toolAndToolInstance);
  }, [toolInstanceId, getTool, getToolInstance]);

  return { toolAndToolInstance };
};

export default useToolAndToolInstance;

import { useCallback } from 'react';
import procedureUtil from '../lib/procedureUtil';
import runUtil from '../lib/runUtil';
import { useProcedureContext } from '../contexts/ProcedureContext';

const useJumpToStep = (
  currentStepId: string
): ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) => {
  const { procedure, getItemPath, scrollTo } = useProcedureContext();

  const onJumpToClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      const path = getItemPath(currentStepId);

      const { sectionId, stepId } = procedureUtil.parsePath(path);
      const latestRepeat = runUtil.getLatestRepeat(
        procedure,
        sectionId,
        stepId
      );

      scrollTo({
        sectionId: latestRepeat.sectionId,
        stepId: latestRepeat.stepId,
        stepHeaderId: latestRepeat.stepHeaderId,
      });
    },
    [currentStepId, getItemPath, procedure, scrollTo]
  );

  return onJumpToClick;
};

export default useJumpToStep;

import React, { useMemo, useCallback, useContext } from 'react';

const ProcedureFilterContext = React.createContext();

export const ProcedureFilterProvider = ({ displaySections, filters, children }) => {
  const filterMap = useMemo(() => {
    const map = {};
    if (!displaySections) {
      return map;
    }
    displaySections.forEach(([section]) => {
      section.steps.forEach((step) => {
        step.content.forEach((content) => {
          if (filters.data && content.include_in_summary) {
            map[content.id] = true;
            map[step.id] = true;
            map[section.id] = true;
          }
        });

        if (filters.activity && step.comments) {
          map[step.id] = true;
          map[section.id] = true;
        }
      });
    });
    return map;
  }, [filters, displaySections]);

  // Returns true if there is no content that can be filtered.
  const isContentEmpty = useMemo(() => {
    return Object.keys(filterMap);
  }, [filterMap]);

  const isSectionFiltered = useCallback(
    (sectionId) => {
      return filterMap[sectionId];
    },
    [filterMap]
  );

  const isStepFiltered = useCallback(
    (stepId) => {
      return filterMap[stepId];
    },
    [filterMap]
  );

  const isContentFiltered = useCallback(
    (contentId) => {
      return filterMap[contentId];
    },
    [filterMap]
  );

  const value = {
    isContentEmpty,
    isSectionFiltered,
    isStepFiltered,
    isContentFiltered,
    filters,
  };

  return <ProcedureFilterContext.Provider value={value}>{children}</ProcedureFilterContext.Provider>;
};

export const useProcedureFilter = () => {
  const context = useContext(ProcedureFilterContext);
  if (context === undefined) {
    throw new Error('useProcedureFilter must be used within an ProcedureFilterProvider');
  }
  return context;
};

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Item, ItemStatus } from '../types';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useSettings } from '../../contexts/SettingsContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';

const QUERY_KEY = 'items';

interface ItemsState {
  allItems?: Item[];
  activeItems?: Item[];
  getItem: (itemId: string) => Item | undefined;
  refreshItems: () => Promise<void>;
}

const useItems = (): ItemsState => {
  const queryClient = useQueryClient();
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { isManufacturingEnabled } = useSettings();

  const fetchItems = async () => {
    if (!isManufacturingEnabled?.()) {
      return null;
    }
    const items: Item[] = await services.manufacturing.listItems();
    return { items };
  };

  const { data } = useQuery({
    queryKey: [QUERY_KEY],
    queryFn: fetchItems,
  });

  const refreshItems = () =>
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY],
      refetchType: 'all',
    });

  const allItems = data?.items;

  const activeItems = allItems?.filter((item) => {
    return item.amount > 0 && item.status !== ItemStatus.Scrap;
  });

  const getItem = (itemId) => allItems?.find((item) => item.id === itemId);

  return {
    allItems,
    activeItems,
    getItem,
    refreshItems,
  };
};

export default useItems;

import React, { useCallback, useMemo } from 'react';
import { max } from 'lodash';
import { AssessmentMatrixMetadata } from 'shared/lib/types/testing';
import { getAssessmentBorderColor, getAssessmentColorForCell, ASSESSMENT_PARAMETERS } from '../libs/hazards';
import { HazardFormValues } from './HazardEdit';

interface AssessmentMatrixProps {
  setRowAndColumnValues?: ({
    rowId,
    columnId,
  }: {
    rowId: HazardFormValues['row_value'];
    columnId: HazardFormValues['column_value'];
  }) => void;
  selectedRowValue: string | undefined;
  selectedColumnValue: string | undefined;
  assessmentMatrixMetadata: AssessmentMatrixMetadata;
  isDisabled: boolean;
  compact?: boolean;
}

const AssessmentMatrix = ({
  setRowAndColumnValues,
  selectedRowValue,
  selectedColumnValue,
  assessmentMatrixMetadata,
  isDisabled,
  compact = false,
}: AssessmentMatrixProps) => {
  const {
    row_labels: rowLabels,
    column_labels: columnLabels,
    row_category: rowCategory,
    column_category: columnCategory,
    assessment_matrix: assessmentMatrix,
  } = assessmentMatrixMetadata;
  const onChange = (rowId, columnId) => {
    if (setRowAndColumnValues) {
      setRowAndColumnValues({
        rowId,
        columnId,
      });
    }
  };

  const getAssessmentColorForCellIndices = useCallback(
    (rowIndex, columnIndex) => {
      return getAssessmentColorForCell(assessmentMatrix, rowIndex, columnIndex);
    },
    [assessmentMatrix]
  );

  const getBorderColorForCell = useCallback(
    (rowIndex, columnIndex) => getAssessmentBorderColor(assessmentMatrix, rowIndex, columnIndex),
    [assessmentMatrix]
  );

  const maxColumnNameLength = useMemo(() => {
    return max(columnLabels.map(({ name }) => name.length)) ?? 0;
  }, [columnLabels]);

  const minCellWidth = useMemo(() => {
    return Math.min(maxColumnNameLength, 10);
  }, [maxColumnNameLength]);

  const maxCellWidth = useMemo(() => {
    return Math.min(maxColumnNameLength, 15) + 1;
  }, [maxColumnNameLength]);

  const maxRowNameLength = useMemo(() => {
    return max(rowLabels.map(({ name }) => name.length)) ?? 0;
  }, [rowLabels]);

  const minRowNameWidth = useMemo(() => {
    return Math.min(maxRowNameLength, 10);
  }, [maxRowNameLength]);

  const maxRowNameWidth = useMemo(() => {
    return Math.min(maxRowNameLength, 15) + 1;
  }, [maxRowNameLength]);

  return (
    <table className={`w-full border border-collapse bg-gray-50 h-80 ${compact && 'text-sm'}`}>
      <tbody>
        <tr>
          <td colSpan={2} rowSpan={2} className="border text-center pl-3 pr-3" />
          <th className={`${compact ? 'h-8' : 'h-10'} border font-normal text-center`} colSpan={columnLabels.length}>
            {columnCategory}
          </th>
        </tr>
        <tr>
          {columnLabels.map(({ id: columnId, name: columnName }) => (
            <th
              key={`${columnId}-name`}
              className={`border pl-2 pr-2 text-left font-normal ${compact ? 'h-8' : 'h-12'} break-all`}
              style={{
                minWidth: `${minCellWidth}ch`,
                maxWidth: `${maxCellWidth}ch`,
                width: `${maxCellWidth}ch`,
              }}
            >
              {columnName}
            </th>
          ))}
        </tr>
        {rowLabels.map(({ id: rowId, name: rowName }, rowIndex) => (
          <tr key={rowId}>
            {rowIndex === 0 && (
              <th
                className="text-center border font-normal rotate-180"
                style={{
                  writingMode: 'vertical-lr',
                  minWidth: '2.5rem',
                  maxWidth: '2.5rem',
                  width: '2.5rem',
                }}
                rowSpan={rowLabels.length}
              >
                {rowCategory}
              </th>
            )}
            <th
              className="border pl-2 pr-2 font-normal text-left break-words"
              style={{
                minWidth: `${minRowNameWidth}ch`,
                maxWidth: `${maxRowNameWidth}ch`,
                width: `${maxRowNameWidth}ch`,
              }}
            >
              {rowName}
            </th>
            {columnLabels.map(({ id: columnId, name: columnName }, columnIndex) => (
              <td
                key={`${rowId}-${columnId}`}
                className={`${compact ? 'h-8' : 'h-16'} border border-gray-500 border-opacity-30`}
                style={{
                  backgroundColor: getAssessmentColorForCellIndices(rowIndex, columnIndex),
                }}
              >
                <label
                  className={`w-full h-full flex items-center justify-center ${isDisabled ? '' : 'cursor-pointer'}`}
                  aria-label={`${rowName}/${columnName}: ${
                    ASSESSMENT_PARAMETERS[assessmentMatrix[rowIndex][columnIndex]].backgroundColorName
                  }`}
                >
                  <input
                    className={`border text-slate-600 ${
                      isDisabled ? 'text-opacity-50 border-opacity-20 border-dashed' : 'cursor-pointer'
                    } bg-transparent`}
                    style={{
                      borderColor: getBorderColorForCell(rowIndex, columnIndex),
                    }}
                    type="checkbox"
                    checked={selectedRowValue === rowId && selectedColumnValue === columnId}
                    onChange={() => onChange(rowId, columnId)}
                    disabled={isDisabled}
                  />
                </label>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AssessmentMatrix;

import superlogin from '../../api/superlogin';
import Papa from 'papaparse';
import {
  Bounds,
  FlatFileSchema,
  PointGroup,
  Series,
  SeriesGroup,
  FlatTelemetrySchema,
} from '../types';
import { API_URL } from '../../config';

export const CHUNK_SIZE = 1024 * 1024 * 2; // 2 Mb

type StringBounds = {
  min: string;
  max: string;
};

type WriteCsvOptions = {
  error: () => void;
  complete: () => void;
  onChunkComplete: () => void;
};

class InfluxService {
  private restUrl: string;

  constructor(teamId: string) {
    this.restUrl = `${API_URL}/teams/${teamId}/storage`;
  }

  async getFileSchema(): Promise<FlatFileSchema> {
    const url = `${this.restUrl}/schema-file`;
    return superlogin
      .getHttp()
      .get(url)
      .then((res) => res.data.schema);
  }

  async getTelemetrySchema(): Promise<FlatTelemetrySchema> {
    const url = `${this.restUrl}/schema-telemetry`;
    return superlogin
      .getHttp()
      .get(url)
      .then((res) => res.data.schema);
  }

  async getAllParameters(): Promise<void> {
    const url = `${this.restUrl}/influx-parameters`;
    return superlogin
      .getHttp()
      .get(url)
      .then((res) => res.data);
  }

  async deleteBucket(bucketName: string): Promise<void> {
    const url = `${this.restUrl}/buckets/${bucketName}`;
    await superlogin.getHttp().delete(url);
  }

  static _stringifyBounds(bounds: Bounds): StringBounds {
    const min = String(Math.round(bounds.min));
    const max = bounds.max ? String(Math.round(bounds.max)) : '';
    return {
      min,
      max,
    };
  }

  async getSeries(
    bucketName: string,
    measurementName: string,
    bounds: Bounds
  ): Promise<Series> {
    const measurementNameEncoded = encodeURIComponent(measurementName);
    let url = `${this.restUrl}/buckets/${bucketName}/measurements/${measurementNameEncoded}`;
    const { min, max } = InfluxService._stringifyBounds(bounds);
    const params = new URLSearchParams();
    if (bounds && bounds.min !== -Infinity) {
      params.append('min', min);
    }
    if (bounds && bounds.max !== Infinity) {
      params.append('max', max);
    }
    url = `${url}?${params.toString()}`;
    return superlogin
      .getHttp()
      .get(url)
      .then((res) => res.data);
  }

  async saveSeriesGroup(
    bucketName: string,
    measurements: SeriesGroup
  ): Promise<void> {
    const url = `${this.restUrl}/buckets/${bucketName}/measurements`;
    const body = { measurements };
    await superlogin.getHttp().post(url, body);
  }

  async evaluateExpression(
    expression: string,
    bucketName: string,
    bounds: Bounds
  ): Promise<Series> {
    const url = `${this.restUrl}/evaluate`;
    const body = {
      expression,
      bucketName,
      bounds: InfluxService._stringifyBounds(bounds),
    };
    return superlogin
      .getHttp()
      .post(url, body)
      .then((res) => res.data);
  }

  static _getSeriesGroupFromCsv(
    pointGroupList: Array<PointGroup>
  ): SeriesGroup {
    if (pointGroupList.length < 1) {
      return {};
    }
    const seriesGroup = {};
    for (const name of Object.keys(pointGroupList[0])) {
      seriesGroup[name] = [];
    }
    for (const pointGroup of pointGroupList) {
      for (const [name, value] of Object.entries(pointGroup)) {
        seriesGroup[name].push(value);
      }
    }
    return seriesGroup;
  }

  writeCsvToBucket(
    csvFile: File,
    bucketName: string,
    writeCsvOptions: WriteCsvOptions
  ): void {
    const { complete, error, onChunkComplete } = writeCsvOptions;
    const transformHeader = (header) => header.trim();

    const tryChunk = async (results, parser) => {
      parser.pause();
      const seriesGroup = InfluxService._getSeriesGroupFromCsv(results.data);
      await this.saveSeriesGroup(bucketName, seriesGroup);
      parser.resume();
    };

    // Errors when a chunk fails. Not sure why papaparse doesn't do this.
    const chunk = async (results, parser) => {
      try {
        await tryChunk(results, parser);
        onChunkComplete();
      } catch {
        error();
      }
    };

    const options = {
      header: true,
      delimiter: ',',
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader,
      chunkSize: CHUNK_SIZE,
      chunk,
      complete,
      error,
    };

    Papa.parse(csvFile, options);
  }
}

export default InfluxService;

import { isStepEnded } from './runUtil';
import stepConditionals from './stepConditionals';
import {
  Procedure,
  Section,
  SourceConditionalsMap,
  Step,
  StepConditionalDiffElement,
} from './types/views/procedures';

type Vertex = {
  type: 'step' | 'section';
  id: string;
};

type EdgeType = 'conditional' | 'dependency';

type Dependency = {
  id: string;
  type: EdgeType;
};

class ProcedureGraph {
  incoming: Map<string, Array<Dependency>>;
  outgoing: Map<string, Array<Dependency>>;
  vertices: Map<string, Vertex>;
  stepMap: Map<string, Step>;
  sectionMap: Map<string, Section>;
  endedSet: Set<string>;
  unreachableMap: Map<string, boolean>;
  sourceStepConditionalsMap: SourceConditionalsMap;
  stepIdToSectionIdMap: Map<string, string>;

  constructor(doc: Procedure) {
    this.incoming = new Map();
    this.outgoing = new Map();
    this.vertices = new Map();
    this.stepMap = new Map();
    this.sectionMap = new Map();
    this.endedSet = new Set();
    this.unreachableMap = new Map();
    this.sourceStepConditionalsMap = {};
    this.stepIdToSectionIdMap = new Map();

    if (!doc) {
      return;
    }

    this.addSections(doc.sections);
  }

  addSections(sections: Array<Section>) {
    for (const section of sections) {
      this.addVertex({ id: section.id, type: 'section' });
      this.sectionMap.set(section.id, section);

      if (section.dependencies) {
        for (const dependency of section.dependencies) {
          for (const dependentId of dependency.dependent_ids) {
            this.addEdge(dependentId, section.id, 'dependency');
          }
        }
      }

      let isSectionEnded = true;
      for (const step of section.steps) {
        this.addVertex({ id: step.id, type: 'step' });
        this.stepMap.set(step.id, step);
        this.stepIdToSectionIdMap.set(step.id, section.id);

        if (isStepEnded(step)) {
          this.endedSet.add(step.id);
        } else {
          isSectionEnded = false;
        }

        if (step.conditionals) {
          for (const conditional of step.conditionals) {
            this.addEdge(step.id, conditional.target_id, 'conditional');
            if (!this.sourceStepConditionalsMap[conditional.target_id]) {
              this.sourceStepConditionalsMap[conditional.target_id] = {};
            }
            this.sourceStepConditionalsMap[conditional.target_id][
              conditional.id
            ] = conditional as StepConditionalDiffElement;
          }
        }
        if (step.dependencies) {
          for (const dependency of step.dependencies) {
            for (const dependentId of dependency.dependent_ids) {
              this.addEdge(dependentId, step.id, 'dependency');
            }
          }
        }
      }
      if (isSectionEnded) {
        this.endedSet.add(section.id);
      }
    }
  }

  getConditionalParentIds(id: string) {
    const parents = this.incoming.get(id);
    if (!parents) {
      return [];
    }

    return parents
      .filter((parent) => parent.type === 'conditional')
      .map((parent) => parent.id);
  }

  getDependencyChildrenIds(id: string) {
    const children = this.outgoing.get(id);
    if (!children) {
      return [];
    }
    return children
      .filter((child) => child.type === 'dependency')
      .map((child) => child.id);
  }

  getVertexBlock(id: string) {
    return this.stepMap.get(id) ?? this.sectionMap.get(id);
  }

  private areConditionalsFulfilled(id: string) {
    const conditionalParentIds = this.getConditionalParentIds(id);

    if (conditionalParentIds.length === 0) {
      return true;
    }

    // Only one conditional is needed to fulfill a step's conditional requirements if multiple are present
    return conditionalParentIds.some((parentId) => {
      const parentStep = this.stepMap.get(parentId);
      if (!parentStep) {
        return false;
      }
      if (this.endedSet.has(parentId)) {
        const conditionals = parentStep.conditionals?.filter(
          (conditional) => conditional.target_id === id
        );
        return conditionals?.some((conditional) =>
          stepConditionals.isConditionalFulfilled(parentStep, conditional)
        );
      }
      return false;
    });
  }

  private isConditionalUnreachable(sourceId: string, targetId: string) {
    const parentStep = this.stepMap.get(sourceId);
    if (
      !parentStep ||
      !parentStep.conditionals ||
      !this.endedSet.has(parentStep.id)
    ) {
      return false;
    }
    const conditionals = parentStep.conditionals.filter(
      (conditional) => conditional.target_id === targetId
    );
    return (
      conditionals.length > 0 &&
      !conditionals.some((conditional) =>
        stepConditionals.isConditionalFulfilled(parentStep, conditional)
      )
    );
  }

  private areDependenciesFulfilled(id: string) {
    const block = this.stepMap.get(id) ?? this.sectionMap.get(id);
    if (!block) {
      return false;
    }

    if (block && block.dependencies && block.dependencies.length) {
      // Use "every" because each dependency block is an AND condition
      return block.dependencies.every((dependency) => {
        if (dependency?.dependent_ids && dependency.dependent_ids.length) {
          // Use "some" because each dependency within a block is an OR condition
          return dependency.dependent_ids.some((dependentId) =>
            this.isVertexEnded(dependentId)
          );
        }
        return true;
      });
    }
    return true;
  }

  areRequirementsMet(id: string) {
    if (this.vertices.get(id)?.type === 'section') {
      const requirements = this.incoming.get(id);
      if (!requirements || requirements.length === 0) {
        return true;
      }
      return this.areDependenciesFulfilled(id);
    } else if (this.vertices.get(id)?.type === 'step') {
      // For steps, we need to first check if the parent section requirements are met
      const sectionId = this.stepIdToSectionIdMap.get(id) ?? '';
      return (
        this.areDependenciesFulfilled(sectionId) &&
        this.areConditionalsFulfilled(id) &&
        this.areDependenciesFulfilled(id)
      );
    }

    return false;
  }

  private isVertexEnded(id: string) {
    const vertex = this.vertices.get(id);
    if (vertex?.type === 'step') {
      return this.endedSet.has(id);
    } else if (vertex?.type === 'section') {
      return this.isSectionEnded(id);
    }
    return false;
  }

  private isSectionEnded(sectionId: string): boolean {
    const section = this.sectionMap.get(sectionId);
    if (!section) {
      return false;
    }

    if (this.isVertexUnreachable(sectionId)) {
      return false;
    }

    // All steps are either ended or unreachable, but all steps cannot be unreachable
    return (section.steps as Array<Step>).every(
      (step) => this.endedSet.has(step.id) || this.isVertexUnreachable(step.id)
    );
  }

  // unreachable represents the branch of a procedure that cannot complete due to unmet conditionals
  private isVertexUnreachable(id: string, seen = new Set()): boolean {
    const memoized = this.unreachableMap.get(id);
    if (memoized !== undefined) {
      return memoized;
    }
    if (seen.has(id)) {
      return false;
    }
    seen.add(id);

    let isUnreachable = false;
    if (
      this.vertices.get(id)?.type === 'step' &&
      this.incoming.get(id)?.length
    ) {
      // a step is unreachable if every conditional and dependency is unreachable
      const conditionalParentIds = this.getConditionalParentIds(id);
      let allConditionalsAreUnreachable = false;
      if (conditionalParentIds.length) {
        allConditionalsAreUnreachable = conditionalParentIds.every((parentId) =>
          this.isConditionalUnreachable(parentId, id)
        );
      }

      let someDependenciesAreUnreachable = false;
      const step = this.stepMap.get(id);
      if (step && step.dependencies && step.dependencies.length) {
        // Use "some" because any unreachable dependency will cause this step to be unreachable
        someDependenciesAreUnreachable = step.dependencies.some(
          (dependency) => {
            if (dependency?.dependent_ids && dependency.dependent_ids.length) {
              // Use "every" because each dependency within a block is an OR condition
              return dependency.dependent_ids.every((dependentId) =>
                this.isVertexUnreachable(dependentId, seen)
              );
            }
            return false;
          }
        );
      }
      isUnreachable =
        allConditionalsAreUnreachable || someDependenciesAreUnreachable;
    } else if (this.vertices.get(id)?.type === 'section') {
      const section = this.sectionMap.get(id);
      if (section) {
        isUnreachable = (section.steps as Array<Step>).every((step) =>
          this.isVertexUnreachable(step.id, seen)
        );
      }
    }
    this.unreachableMap.set(id, isUnreachable);
    return isUnreachable;
  }

  private addVertex(vertex: Vertex) {
    if (!this.vertices.has(vertex.id)) {
      this.vertices.set(vertex.id, vertex);
    }
  }

  private addEdge(
    sourceId: string,
    targetId: string,
    edge: 'conditional' | 'dependency'
  ) {
    if (!this.incoming.get(targetId)) {
      this.incoming.set(targetId, []);
    }
    if (!this.outgoing.get(sourceId)) {
      this.outgoing.set(sourceId, []);
    }
    this.incoming.get(targetId)?.push({ id: sourceId, type: edge });
    this.outgoing.get(sourceId)?.push({ id: targetId, type: edge });
  }
}

export default ProcedureGraph;

import Tooltip from '../../elements/Tooltip';
import Button, { BUTTON_TYPES } from '../../components/Button';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ButtonIconProps {
  onClick: () => void;
  isSelected?: boolean;
  iconName: IconProp;
  tooltip?: string;
}

const ButtonIcon = ({ onClick, isSelected, iconName, tooltip }: ButtonIconProps) => {
  return (
    <Tooltip content={tooltip} visible={!isSelected}>
      <div className={`${isSelected ? 'bg-slate-300' : ''} text-slate-700 hover:bg-slate-300 p-0.5 rounded `}>
        <Button
          type={BUTTON_TYPES.TERTIARY}
          size="sm"
          leadingIcon={iconName}
          onClick={onClick}
          isDisabled={isSelected}
          iconTextColor="text-slate-700"
          ariaLabel={tooltip}
        />
      </div>
    </Tooltip>
  );
};

export default ButtonIcon;

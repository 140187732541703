import React, { useMemo, useState, useEffect } from 'react';
import Error from '../../components/Error';
import EventDependency from './EventDependency';
import { DateTime } from 'luxon';
import { filterPredecessors } from 'shared/lib/scheduleUtil';
interface IDependency {
  label: string;
  source: string;
  start: DateTime;
  end: DateTime;
}

const RelativeEventSelect = ({ field, form, events }) => {
  const [dependency, setDependency] = useState<IDependency | null>(null);
  const error = form.errors[field.name];
  const { setFieldValue } = form;
  const { duration } = form.values;

  const safePredecessors = filterPredecessors(events, form.values.id) || [];

  const options = safePredecessors
    .filter((e) => e.id !== form.values.id && e.start)
    .map((e) => ({
      label: `${e.name} (${(e.start as DateTime).toFormat('yyyy-MM-dd')})`,
      value: e.id,
      isDisabled: false,
      deleted: e.deleted,
      start: e.start,
      end: e.end,
    }))
    .sort((e1, e2) => (e1.start && e2.start && e1.start < e2.start ? 1 : -1));

  const selectedEvent = useMemo(() => {
    return options.find((e) => e.value === form.values.predecessor_id);
  }, [form.values.predecessor_id, options]);

  useEffect(() => {
    if (dependency) {
      const newStart = dependency.end ? dependency.end : dependency.start;
      if (duration) {
        setFieldValue('end', newStart.plus(duration));
      }
      setFieldValue('predecessor_id', dependency.source);
    }
  }, [dependency, setFieldValue, duration]);

  return (
    <div className="flex flex-row items-center h-[42px]">
      <EventDependency
        placeholder={selectedEvent?.label ? selectedEvent.label : 'Select Predecessor Event'}
        dependency={selectedEvent}
        options={options}
        onChange={(val) => {
          setDependency(val);
        }}
        onRemove={() => {
          form.setFieldValue('predecessor_id', null);
          setDependency(null);
        }}
        isRelative={true}
      />
      {error && <Error text={error} />}
    </div>
  );
};

export default RelativeEventSelect;

import { COUCHDB_URL } from '../../config';
import fetchJson from './fetch';

/**
 * Queries couchdb for documents using the given selector.
 *
 * @param {string} name - Name of couchdb database.
 * @param {Object} selector - (Optional) Couchdb selector object criteria.
 * @param {number | null} limit - (Optional) Maximum number of results to return.
 *                         Defaults to Number.MAX_SAFE_INTEGER.
 * @param {Array?} sort - (Optional) Sort syntax array
 * @param {string?} index - (Optional) Name of the design doc that contains the index to use
 * @returns {Promise<Object>} - The fetch results as JSON data.
 */
const find = async (name, selector, limit, sort, index) => {
  // Filter to explicit document ids if present.
  const body = { limit: limit || Number.MAX_SAFE_INTEGER };
  if (selector) {
    body.selector = selector;
  }
  if (sort) {
    body.sort = sort;
  }
  if (index) {
    body.use_index = index;
  }

  // Create url for couchdb find endpoint.
  const url = new URL(`${COUCHDB_URL}/${name}/_find`);

  // POST is recommended when using doc_ids.
  const options = {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(body),
  };

  const json = await fetchJson(url, options);
  return json.docs;
};

export default find;

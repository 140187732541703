import { Formik, Form } from 'formik';
import ContactSupportMessage from './ContactSupportMessage';

interface FormAcceptInviteProps {
  onSubmit: () => Promise<void>;
  submitError: string | null;
  showSupportLink?: boolean;
}

const FormAcceptInvite = ({ onSubmit, submitError, showSupportLink }: FormAcceptInviteProps) => {
  return (
    <Formik onSubmit={onSubmit} initialValues={{}}>
      {({ isSubmitting }) => (
        <Form>
          <div className="flex flex-col">
            {submitError && <div className="text-red-700">{submitError}</div>}
            {showSupportLink && <ContactSupportMessage />}
            <button className="self-start btn mt-4 py-2.5 px-4" type="submit" disabled={isSubmitting}>
              Join Workspace
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormAcceptInvite;

import React, { useState, useRef } from 'react';

const SINGLE_CLICK_TIMEOUT = 200; //ms

const useSingleClick = (onClick, onDoubleClick = () => null) => {
  const singleClickTimer = useRef<NodeJS.Timeout | null>(null);
  const [clickPosition, setClickPosition] = useState({ top: 0, left: 0 });

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (singleClickTimer.current) {
      clearTimeout(singleClickTimer.current);
    }
    singleClickTimer.current = setTimeout(() => {
      setClickPosition({
        top: event.clientY,
        left: event.clientX,
      });
      onClick();
    }, SINGLE_CLICK_TIMEOUT);
  };

  const handleDoubleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (singleClickTimer.current) {
      clearTimeout(singleClickTimer.current);
    }
    onDoubleClick();
  };

  return {
    handleClick,
    handleDoubleClick,
    clickPosition,
  };
};

export default useSingleClick;

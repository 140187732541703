import Label from '../../Label';

const renderProject = (label?: string) => {
  return label ? (
    <div>
      <Label clickable={true} text={label} color="bg-gray-200" />
    </div>
  ) : (
    <></>
  );
};

export default renderProject;

import React, { useEffect, useRef, useState } from 'react';
import Tooltip from '../../elements/Tooltip';

interface TruncatedColumnProps {
  text: string;
  onClick?: () => void;
}

// TODO: This component could eventually be used for all text columns in data grid tables
const TruncatedColumn = ({ text, onClick }: TruncatedColumnProps) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (spanRef.current) {
        setIsTruncated(spanRef.current.scrollWidth > spanRef.current.clientWidth);
      }
    };

    checkTruncation();
    window.addEventListener('resize', checkTruncation);
    return () => window.removeEventListener('resize', checkTruncation);
  }, [text]);

  return (
    <Tooltip content={text} visible={isTruncated} position="mouse">
      <span
        ref={spanRef}
        onClick={onClick}
        className="text-blue-600 font-medium hover:underline cursor-pointer truncate block"
      >
        {text}
      </span>
    </Tooltip>
  );
};

export default TruncatedColumn;

import { useState } from 'react';

interface UsePopupReturnType {
  activeChildId: string | null;
  clickPosition: { top: number; left: number };
  handlePopupOpen: (event: React.MouseEvent, childId: string) => void;
  handlePopupClose: () => void;
}

const usePopup = (): UsePopupReturnType => {
  const [activeChildId, setActiveChildId] = useState(null);
  const [clickPosition, setClickPosition] = useState({ top: 0, left: 0 });

  const handlePopupOpen = (event, childId) => {
    const position = { top: event.clientY, left: event.clientX };
    setClickPosition(position);
    setActiveChildId(childId);
  };

  const handlePopupClose = () => setActiveChildId(null);

  return { activeChildId, clickPosition, handlePopupOpen, handlePopupClose };
};

export default usePopup;

import React, { useMemo } from 'react';
import { RecordedString, RecordedNumber, TypedBlockProps } from './BlockTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import externalDataUtil from '../../lib/externalDataUtil';
import Tooltip from '../../elements/Tooltip';
import { Link } from 'react-router-dom';
import sharedDiffUtil from 'shared/lib/diffUtil';

/*
 * Component for rendering a Block of type BlockType.ExternalItem.
 * Conforms to TypedBlockInterface, see comments in useBlockComponents
 */
const ExternalItem = ({ block }: TypedBlockProps<'external_item', RecordedString | RecordedNumber>) => {
  const externalItem = useMemo(() => {
    return externalDataUtil.getItemFromBlock(block);
  }, [block]);

  return (
    <div className=" max-w-max flex px-2 py-1 rounded border-2 border-slate-200">
      <div className="flex flex-row gap-x-2 whitespace-pre-line min-w-0 break-word">
        {/* Show ExternalItem not chosen message */}
        {!externalItem && <span> External data item not selected</span>}

        {/* Item id, with URL when present */}
        {externalItem && externalItem.url && (
          <Link
            className="link"
            to={{ pathname: sharedDiffUtil.getDiffValue(externalItem, 'url', 'new') }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {externalDataUtil.getItemLabel(externalItem)}
          </Link>
        )}
        {externalItem && !externalItem.url && <span>{externalDataUtil.getItemLabel(externalItem)}</span>}

        {/* Item Validity Field */}
        {externalItem && externalItem.valid === false && (
          <Tooltip content="This item is invalid.">
            <FontAwesomeIcon aria-label="Invalid Item" className="text-red-500 fa-m" icon="exclamation-circle" />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ExternalItem;

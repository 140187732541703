import { useMemo } from 'react';

/**
 * Convenience methods for using the react-router-dom location object.
 *
 * @param {Object} location - Location object from react-router-dom.
 * @returns {Object} - Object with location information.
 */
const useLocationParams = (location) => {
  const searchParams = useMemo(() => {
    return new URLSearchParams(location?.search);
  }, [location]);

  const hash = useMemo(() => {
    if (!location) {
      return '';
    }
    return location.hash.startsWith('#') ? location.hash.substring(1) : '';
  }, [location]);

  const base = useMemo(() => {
    if (!location.pathname?.includes('/')) {
      return '';
    }
    const components = location.pathname.split('/');
    return `/${components[1]}`;
  }, [location]);

  return {
    searchParams,
    hash,
    base,
  };
};

export default useLocationParams;

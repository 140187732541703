import React, { useCallback, useRef, useMemo } from 'react';
import Button, { BUTTON_TYPES } from '../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface FilePickerProps {
  file: File | null;
  setFile: (file: File | null) => void;
  accept: Array<'.csv'>;
  displayMode?: 'row' | 'column';
}

const FilePicker = ({ file, setFile, accept, displayMode = 'row' }: FilePickerProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const onClickChooseFile = useCallback(() => {
    if (!fileInputRef.current) {
      return;
    }
    fileInputRef.current.click();
  }, []);

  const flexDirectionClasses = useMemo(() => {
    return displayMode === 'row' ? 'flex-row gap-x-2 justify-items-start' : 'flex-col gap-y-2';
  }, [displayMode]);

  return (
    <div className={`flex items-center w-full ${flexDirectionClasses}`}>
      <input
        ref={fileInputRef}
        type="file"
        accept={accept.join(',')}
        id="import-test-cases"
        className="hidden"
        onChange={(e) => {
          const _file = e.target?.files?.[0] ?? null;
          setFile(_file);
        }}
      />
      <Button type={BUTTON_TYPES.PRIMARY} onClick={onClickChooseFile} leadingIcon="upload">
        Choose File
      </Button>
      {!file && <span>No file selected</span>}
      {file && (
        <div className="flex flex-row gap-x-1 items-start mx-2">
          <FontAwesomeIcon icon="file" className="text-blue-500 mt-1" />
          <span className="truncate text-wrap break-all">{file.name}</span>
        </div>
      )}
    </div>
  );
};

export default FilePicker;

import React, { useEffect, useState } from 'react';

const useMenu = (): {
  isMenuVisible: boolean;
  setIsMenuVisible: React.Dispatch<React.SetStateAction<boolean>>;
  displayMenu: (Event?) => void;
} => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    const closeMenu = () => {
      // Any click on the document (including in dropdown menu) closes menu
      setIsMenuVisible(false);
    };
    if (isMenuVisible) {
      document.addEventListener('click', closeMenu);
    }
    // Remove listener when menu visibility changes or component unmounts
    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, [isMenuVisible, setIsMenuVisible]);

  const displayMenu = (event) => {
    event?.preventDefault();
    setIsMenuVisible(true);
  };

  return { isMenuVisible, setIsMenuVisible, displayMenu };
};

export default useMenu;

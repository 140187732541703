import { AppNotificationDisplay } from 'shared/lib/types/notifications';
import { NotificationRow } from '../notifications/types';
import { E3Session } from 'shared/lib/types/api/util';
import { getUnapprovedUserIds } from 'shared/lib/reviewUtil';
import { NotifyReviewerContext } from 'shared/lib/types/postgres/notifications';
import { Users } from 'shared/lib/types/couch/settings';
import { ProcedureMetadata } from 'shared/lib/types/views/procedures';
import { getPendingProcedureIndex } from 'shared/lib/procedureUtil';

const notifications = {
  getUniqueIdentifier: (
    notification: AppNotificationDisplay | NotificationRow
  ): string => {
    return [
      notification.type,
      ...Object.keys(notification.context)
        .sort()
        .map((key) => notification.context[key]),
    ].join(':');
  },

  getNotificationIdMap: (
    notificationsList: Array<AppNotificationDisplay>
  ): { [identifier: string]: Array<number> } => {
    return notificationsList.reduce<{ [procedureId: string]: Array<number> }>(
      (idMap, notification) => {
        const identifier = notifications.getUniqueIdentifier(notification);
        if (!idMap[identifier]) {
          idMap[identifier] = [];
        }
        idMap[identifier].push(notification.id);
        return idMap;
      },
      {}
    );
  },

  _getLastCreatedItem: (
    itemA: AppNotificationDisplay,
    itemB: AppNotificationDisplay
  ): AppNotificationDisplay => {
    // Favor (itemB) if created_at fields are equal
    return itemB.created_at.localeCompare(itemA.created_at) >= 0
      ? itemB
      : itemA;
  },

  /**
   * Gets a set of the latest version of each unique notification by created_at.
   */
  _getLatestNotificationSet: (
    notificationsList: Array<AppNotificationDisplay>
  ): Set<AppNotificationDisplay> => {
    const latestNotificationMap = notificationsList.reduce(
      (map, notification) => {
        const identifier = notifications.getUniqueIdentifier(notification);
        map[identifier] = map[identifier]
          ? notifications._getLastCreatedItem(map[identifier], notification)
          : notification;
        return map;
      },
      {}
    );

    return new Set(Object.values(latestNotificationMap));
  },

  /**
   * Gets a list of notifications unique by their unique identifiers, and sorted by latest updated first.
   */
  getUniqLatestSortedNotifications: (
    notificationsList: Array<AppNotificationDisplay>
  ): Array<AppNotificationDisplay> => {
    const latestNotificationSet =
      notifications._getLatestNotificationSet(notificationsList);
    return Array.from(latestNotificationSet).sort((itemA, itemB) =>
      itemB.created_at.localeCompare(itemA.created_at)
    );
  },

  isReviewerNotificationActive: (
    notification: AppNotificationDisplay,
    userInfo: { session: E3Session },
    users: Users | null,
    proceduresMetadata: { [id: string]: ProcedureMetadata }
  ): boolean => {
    const procedureId = (notification.context as NotifyReviewerContext)
      .procedureId;
    const pendingProcedureId = getPendingProcedureIndex(procedureId);
    const procedureMetadata = proceduresMetadata[pendingProcedureId];

    let isPendingReview = false;
    if (procedureMetadata && procedureMetadata.reviewer_groups && users) {
      const recipients = getUnapprovedUserIds(
        procedureMetadata.reviewer_groups,
        users
      );
      const userId = userInfo.session.user_id;
      isPendingReview = recipients.includes(userId);
    }

    //Add Active property based on the 2 conditions to notification object
    if (isPendingReview && procedureMetadata.state === 'in_review') {
      return true;
    }
    return false;
  },
};

export default notifications;

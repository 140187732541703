import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { evaluate, evaluateIsWithinRange, isSupportedOperation } from 'shared/lib/math';
import { getTelemetryStreamId } from 'shared/lib/realtimeUpdates';
import { RUN_STATE } from 'shared/lib/runUtil';
import {
  SimulatedFields,
  extractParameterNamesFromExpression,
  formatAggregateParameterName,
} from 'shared/lib/telemetry';
import { DEFAULT_DICTIONARY_ID, Reading } from 'shared/lib/types/telemetry';
import telemetryExpressionUtil from 'shared/lib/telemetryExpressionUtil';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useRunContext } from '../../contexts/RunContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import runUtil from '../../lib/runUtil';
import telemetryUtil from '../../lib/telemetry';
import { isEmptyValue } from 'shared/lib/text';
import { Bounds } from '../../storage/types';
import TelemetryAndPlotRows from '../../telemetry/TelemetryAndPlotRows';
import { Rule } from 'shared/lib/types/views/procedures';
import { DEFAULT_ZOOM_LEVEL } from '../../storage/components/Plot';
import { Range, BasicRule } from './Rule';

const DEFAULT_STALE_AFTER_MS = 10000;
const DEFAULT_DICTIONARY_NAME = 'Default';
const SAVE_SUPPORTED_TYPES = ['float', 'enum', 'aggregate'];

type EnumValues = { [key: string]: string };
type AggregateValues = { name: string; type: string; values?: EnumValues }[];

export interface TelemetryParam {
  name: string;
  type: string;
  created_at: string;
  dictionary_name: string;
  units?: string;
  values?: EnumValues | AggregateValues;
}

/**
 * @param value telemetry value (if single field)
 * @param pass true/false
 * @param stale true/false
 * @param timestamp timestamp of telemetry value (if single field)
 */
interface TelemetryState {
  value?: string;
  pass?: unknown;
  stale?: boolean;
  timestamp?: string | null;
}

const formatTelemetryRule = (telemetry): Rule => {
  return {
    op: telemetry.rule,
    value: telemetry.value,
    range: telemetry.range,
  };
};

const showNavigateToPlotForAggregateParam = (key: string, aggregateValues: AggregateValues): boolean => {
  const aggValue = aggregateValues.find((value) => value.name === key);
  return aggValue ? SAVE_SUPPORTED_TYPES.includes(aggValue.type) : false;
};

const isTelemetryRulePassing = (telemetry, readings: Record<string, Reading>, parameter, streamId) => {
  const isSimulatedTelemetryPassing = (telemetry, reading) => {
    if (!reading) {
      return false;
    }
    if (isSupportedOperation(telemetry.rule)) {
      return evaluate(reading.value, telemetry.value, telemetry.rule);
    } else if (telemetry.rule.toLowerCase() === 'range') {
      return evaluateIsWithinRange(reading.value, telemetry.range);
    }
    return null;
  };

  return new Promise((resolve, reject) => {
    if (!telemetry || !telemetry.key) {
      reject('Missing telemetry object');
      return;
    }
    if (telemetryUtil.isStandardTelemetry(telemetry) && !parameter) {
      reject('Missing parameter object');
      return;
    }
    switch (telemetry.key) {
      case 'custom': {
        if (Object.keys(readings).length === 0) {
          reject('Missing telemetry readings');
          return;
        }
        const variables = {};
        for (const reading of Object.values(readings)) {
          if (typeof reading.value !== 'undefined') {
            variables[reading.name] = reading.value;
          }
        }
        const result = telemetryExpressionUtil.evalExpression(telemetry.expression, variables);
        resolve(result);

        break;
      }
      default: {
        if (!readings[streamId]) {
          reject('Missing parameter sample');
          return;
        }
        // Eventually all telemetry can be migrated to standard telemetry architecture
        if (telemetryUtil.isStandardTelemetry(telemetry)) {
          try {
            const value = readings[streamId] && readings[streamId].value;
            resolve(telemetryUtil.isValuePassing(telemetry, parameter, value));
          } catch (error) {
            reject(error);
          }
        } else {
          resolve(isSimulatedTelemetryPassing(telemetry, readings[streamId]));
        }
        break;
      }
    }
  });
};

/*
 *"telemetry":
 *  {
 *    "key": "COSMOS",
 *    "rule": ">",
 *    "value": "4",
 *    "expression": "",
 *    "cosmos": {
 *      "target": "CFS",
 *      "packet": "CFE_EVS_PACKET",
 *      "item": "RECEIVED_COUNT"
 *    },
 *    "recorded": {
 *      "pass": true,
 *      "value": 136,
 *      "timestamp": "2021-02-25T22:58:57.822Z",
 *      "stale": true
 *    }
 *  },
 */

type VoidFunction = () => void;
enum StreamingStatus {
  Active = 'Active',
  NoSignal = 'No Signal',
  Stale = 'Stale',
}

const initialStatus = (telemetry) => {
  if (telemetry.recorded) {
    return telemetry.recorded.stale ? StreamingStatus.Stale : null;
  }
  return StreamingStatus.NoSignal;
};

const lastUpdated = (readings: Array<Reading>) => {
  if (readings.length === 0) {
    return null;
  }
  let latest = readings[0].recorded_at ?? '';
  readings.forEach((reading) => {
    if (reading.recorded_at && reading.recorded_at > latest) {
      latest = reading.recorded_at;
    }
  });
  return latest;
};

const BlockTelemetry = ({ telemetry, blockLabel, docState, onTelemetryStateChange, canBeStale, shouldPausePlot }) => {
  const { run, onReceiveTelemetry, onSignalLost, fetchedTelemetryParameters } = useRunContext();
  const [telemetryState, setTelemetryState] = useState<TelemetryState>();
  const [parameter, setParameter] = useState<TelemetryParam | null>();
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const isMounted = useRef(true);
  const teamId = services.runs.getTeamId();
  const [readings, setReadings] = useState<Record<string, Reading>>({});
  const [streamingStatus, setStreamingStatus] = useState(initialStatus(telemetry));

  const initialBounds: Bounds = useMemo(
    () => ({
      min: Date.parse(run?.starttime),
      max: run?.completedAt ? Date.parse(run?.completedAt) : null,
    }),
    [run?.completedAt, run?.starttime]
  );
  const [bounds, setBounds] = useState<Bounds>(initialBounds);
  const [zoomLevel, setZoomLevel] = useState<number>(DEFAULT_ZOOM_LEVEL);

  const listenerRef = useRef<VoidFunction | null>(null);
  const signalRef = useRef<VoidFunction | null>(null);
  const staleTimerRef = useRef<NodeJS.Timer>();

  const resetBounds = useCallback(() => {
    setBounds(initialBounds);
  }, [initialBounds]);

  const resetZoom = useCallback(() => setZoomLevel(DEFAULT_ZOOM_LEVEL), []);

  const streamIds = useMemo(() => {
    if (telemetry.key === 'custom' && telemetry.expression) {
      if (!fetchedTelemetryParameters) {
        return;
      }
      const params = extractParameterNamesFromExpression(telemetry.expression, fetchedTelemetryParameters).map(
        (param) =>
          getTelemetryStreamId({
            teamId,
            parameterName: param.name,
            runId: run._id,
            variables: run.variables,
            dictionaryId: (run.dictionary_id ? parseInt(run.dictionary_id) : undefined) || param.dictionary_id,
          })
      );
      return params;
    }
    if (telemetry.name && SimulatedFields[telemetry.name]) {
      return [telemetry.name];
    }

    return [
      getTelemetryStreamId({
        teamId,
        parameterName: telemetry?.name,
        runId: run._id,
        variables: run.variables,
        dictionaryId: run.dictionary_id || telemetry.dictionary_id,
      }),
    ];
  }, [
    fetchedTelemetryParameters,
    run._id,
    run.dictionary_id,
    run.variables,
    teamId,
    telemetry.dictionary_id,
    telemetry.expression,
    telemetry.key,
    telemetry.name,
  ]);

  // for singular parameters
  const streamId = useMemo(() => {
    return streamIds && streamIds.length === 1 ? streamIds[0] : '';
  }, [streamIds]);

  // stop listening to telemetry when this telemetry block is unmounted
  useEffect(
    () => () => {
      isMounted.current = false;
      if (listenerRef.current) {
        listenerRef.current();
      }
      if (signalRef.current) {
        signalRef.current();
      }
    },
    []
  );

  const updateReadings = useCallback(
    (reading: Reading) => {
      const staleAfter = reading.stale_after_ms ?? DEFAULT_STALE_AFTER_MS;
      const now = new Date();
      const recorded_at = reading.recorded_at ? new Date(reading.recorded_at) : now;

      // staleness calculation based on difference of time recorded vs now being outside the staleness timespan window
      const isStale = now.getTime() - recorded_at.getTime() > staleAfter;

      if (reading.value === null) {
        return;
      }

      if (staleTimerRef.current) {
        clearTimeout(staleTimerRef.current);
      }

      // staleness timeout that will trigger after lack of receiving data
      staleTimerRef.current = setTimeout(() => {
        clearTimeout(staleTimerRef.current);
        setStreamingStatus(StreamingStatus.Stale);
      }, staleAfter);

      if (!telemetry.recorded) {
        if (isStale) {
          setStreamingStatus(StreamingStatus.Stale);
        } else {
          setStreamingStatus(StreamingStatus.Active);
        }
        setReadings((current) => {
          return { ...cloneDeep(current), [reading.stream_id]: reading };
        });
      }
    },
    [telemetry]
  );

  const handleSignalLost = useCallback(() => {
    setStreamingStatus(StreamingStatus.NoSignal);
    if (signalRef.current) {
      signalRef.current();
      signalRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (streamingStatus === StreamingStatus.Active) {
      if (!signalRef.current) {
        signalRef.current = onSignalLost(handleSignalLost);
      }
    }
  }, [handleSignalLost, onSignalLost, streamingStatus, telemetry]);

  useEffect(() => {
    if (!telemetry.recorded) {
      if (streamIds) {
        if (listenerRef.current) {
          listenerRef.current();
        }
        listenerRef.current = onReceiveTelemetry(updateReadings, streamIds);
      }
      if (!signalRef.current) {
        signalRef.current = onSignalLost(handleSignalLost);
      }
    } else {
      if (listenerRef.current) {
        listenerRef.current();
        listenerRef.current = null;
      }
    }
  }, [handleSignalLost, onReceiveTelemetry, onSignalLost, streamIds, telemetry.recorded, updateReadings]);

  // Load telemetry standard parameter
  useEffect(() => {
    // Check if parameter already loaded
    if (parameter && parameter.name === telemetry.name) {
      return;
    }
    if (!telemetryUtil.isStandardTelemetry(telemetry)) {
      return;
    }

    if (fetchedTelemetryParameters) {
      const param = fetchedTelemetryParameters.find(
        (p) => p.name === telemetry.name && p.dictionary_id === telemetry.dictionary_id
      );
      // if parameter found and in default dictoinary, just set it. Otherwise need to fetch param with dictionary_name
      if (param && param.dictionary_id === DEFAULT_DICTIONARY_ID) {
        setParameter(param);
      } else {
        // Load any parameter that wasn't found
        services.telemetry
          .getParameterByName(telemetry.name, telemetry?.dictionary_id)
          .then((parameter) => {
            if (!isMounted.current) {
              return;
            }
            setParameter(parameter);
          })
          .catch(() => {
            /* no-op */
          });
      }
    }
  }, [telemetry, parameter, services.telemetry, fetchedTelemetryParameters]);

  // Notify listeners when telemetryState changes
  useEffect(() => {
    // If there are no telemetry values, don't post a change to allow the current values to remain visible.
    if (!telemetry.id || !telemetryState) {
      return;
    }
    onTelemetryStateChange && onTelemetryStateChange(telemetry.id, telemetryState);
  }, [telemetry.id, telemetryState, onTelemetryStateChange]);

  const telemetryKey = telemetry.key ? telemetry.key.toLowerCase() : null;
  const telemetryValue = telemetry.value;
  const telemetryRule = telemetry.rule.toLowerCase();

  // Updates telemetry rule state (pass/fail) when telemetry values change
  useEffect(() => {
    // Wait for telemetry and first sample (reading).
    if (!telemetry || !telemetry.key || !readings) {
      return;
    }

    // Wait for parameter object to load.
    if (telemetryUtil.isStandardTelemetry(telemetry) && !parameter) {
      return;
    }

    // Values for single variable rules
    const value = readings[streamId] ? readings[streamId].value : null;
    const timestamp = lastUpdated(Object.values(readings));

    let staleAfterMs = DEFAULT_STALE_AFTER_MS;
    if (readings[streamId]?.stale_after_ms) {
      staleAfterMs = readings[streamId].stale_after_ms as number;
    }

    let stale;
    if (timestamp) {
      const currentTime = new Date();
      const telemetryTime = new Date(timestamp);
      stale = currentTime.getTime() - telemetryTime.getTime() > staleAfterMs;
    }

    isTelemetryRulePassing(telemetry, readings, parameter, streamId)
      .then((pass) => {
        if (!isMounted.current) {
          return;
        }
        const updates = {
          pass,
          value: (telemetryKey === 'custom' ? pass : value) as string,
          timestamp,
          stale,
        };
        setTelemetryState(updates);
      })
      .catch(() => {
        if (!isMounted.current) {
          return;
        }
        setTelemetryState({});
      });
  }, [readings, telemetry, parameter, telemetryKey, streamId]);

  const getEnumLabel = (parameter, value) => {
    if (
      parameter &&
      value !== undefined &&
      parameter.type === 'enum' &&
      parameter.values &&
      parameter.values[value] !== undefined
    ) {
      return parameter.values[value];
    }
    return null;
  };

  // Gets the current telemetry value as a display string
  const resolvedValue = useMemo(() => {
    const expressionValueString = (bool) => (bool === true ? 'True' : 'False');
    let value;
    if (!telemetry.key) {
      return 'NaN';
    }
    if (telemetry.recorded) {
      value = telemetry.recorded.value;
    } else if (telemetryState?.value !== undefined && telemetryState.value !== null) {
      value = telemetryState.value;
    }
    // Convert to displayed string value
    if (telemetryKey === 'custom') {
      value = expressionValueString(value);
    } else if (value) {
      value = value.toString();
    }

    if (parameter && parameter.type === 'enum') {
      value = `${getEnumLabel(parameter, value)} (${value})`;
    }

    if (parameter?.type === 'aggregate') {
      value = telemetryState?.timestamp || telemetry.recorded ? '' : undefined;
    }

    return value;
  }, [telemetry.key, telemetry.recorded, telemetryState, telemetryKey, parameter]);

  const displayPass = useMemo(() => {
    if (telemetry.recorded) {
      return telemetry.recorded.pass;
    } else if (telemetryState?.pass !== undefined && telemetryState.pass !== null) {
      return telemetryState.pass;
    } else {
      return null;
    }
  }, [telemetryState, telemetry]);

  const displayValue = useMemo(() => {
    const formattedValue =
      resolvedValue !== undefined ? (
        <div className="truncate text-blue-500 font-mono">{resolvedValue}</div>
      ) : (
        <div className="truncate text-gray-400 italic">Pending</div>
      );
    if (isEmptyValue(telemetry.rule)) {
      return formattedValue;
    }
    // Ranges define a `min` and `max` instead of a `value`
    if (telemetryRule === 'range') {
      if (isEmptyValue(telemetry.range) || isEmptyValue(telemetry.range.min) || isEmptyValue(telemetry.range.max)) {
        return formattedValue;
      }
      return <Range range={telemetry.range} inputName={formattedValue} useChip={false} />;
    }
    // Standard telemetry rules define a single `value`
    if (telemetry.value === undefined || telemetry.value === null) {
      return formattedValue;
    }

    return <BasicRule op={telemetryRule} value={telemetryValue} inputName={formattedValue} useChip={false} />;
  }, [resolvedValue, telemetry, telemetryRule, telemetryValue]);

  const displayAggregateValue = ({
    name,
    value,
    parameter,
  }: {
    name: string;
    value: string;
    parameter: TelemetryParam;
  }) => {
    if (!parameter || !parameter.values) {
      return <></>;
    }
    const paramValues = parameter.values as AggregateValues;
    const paramValue = paramValues.find((value) => value.name === name);
    if (!paramValue || paramValue.type !== 'enum' || !paramValue.values) {
      return <div className="truncate text-blue-500 font-mono">{value}</div>;
    }
    return <div className="truncate text-blue-500 font-mono">{`${paramValue.values[value]} (${value})`}</div>;
  };

  const parameterName = telemetryUtil.getParameterName(telemetry);
  const dictionaryName = parameter?.dictionary_name || DEFAULT_DICTIONARY_NAME;
  const parameterUnits: string = useMemo(
    () => (parameter ? parameter.units : telemetry.units),
    [parameter, telemetry.units]
  );

  const displayKey = useMemo(() => {
    if (!telemetryKey) {
      return;
    }
    if (telemetryKey === 'custom') {
      return telemetry.expression;
    }

    const telemetryName = telemetryUtil.getParameterName(telemetry);
    if (parameterUnits && parameter?.type !== 'aggregate') {
      return `${telemetryName} (${parameterUnits})`;
    }
    return telemetryName;
  }, [parameter?.type, parameterUnits, telemetry, telemetryKey]);

  const searchParams = useMemo(() => {
    const params: Record<string, string> = {
      runId: run._id,
      bucket: dictionaryName,
      measurement: parameterName || '',
      starttime: run.starttime,
      endtime: run.completedAt ?? '',
    };
    if (parameterUnits) {
      params.units = parameterUnits;
    }
    return new URLSearchParams(params);
  }, [dictionaryName, parameterName, run, parameterUnits]);
  const searchParamsString = `?${searchParams}`;

  const formatSearchParamsForAggregateParameter = useCallback(
    (aggregateName: string) => {
      const aggregateSearchParams = new URLSearchParams(searchParams);
      aggregateSearchParams.set('measurement', aggregateName);
      return `?${aggregateSearchParams}`;
    },
    [searchParams]
  );

  const showNavigateToPlot = useMemo(() => {
    return (
      telemetryUtil.isStandardTelemetry(telemetry) &&
      parameter &&
      SAVE_SUPPORTED_TYPES.includes(parameter.type) &&
      (runUtil.isRunStateActive(docState) || docState === RUN_STATE.COMPLETED)
    );
  }, [docState, parameter, telemetry]);

  const isRunning = useMemo(() => {
    return runUtil.isRunStateActive(docState) && docState !== RUN_STATE.COMPLETED;
  }, [docState]);

  return (
    <>
      <TelemetryAndPlotRows
        isAggregate={parameter?.type === 'aggregate'}
        isChildRow={false}
        isRunning={isRunning}
        isRecorded={Boolean(telemetry.recorded)}
        name={displayKey}
        blockLabel={blockLabel}
        canBeStale={canBeStale}
        sample={{
          streamingStatus: streamingStatus || '',
          value: displayValue,
          pass: displayPass,
          timestamp: telemetry.recorded?.timestamp || telemetryState?.timestamp,
        }}
        runId={run._id}
        showNavigateToPlot={Boolean(showNavigateToPlot && parameter?.type !== 'aggregate')}
        searchParamsString={searchParamsString}
        dictionaryName={dictionaryName}
        parameterName={parameterName}
        bounds={bounds}
        initialBounds={initialBounds}
        setBounds={setBounds}
        resetBounds={resetBounds}
        zoomLevel={zoomLevel}
        setZoomLevel={setZoomLevel}
        resetZoom={resetZoom}
        telemetryRule={formatTelemetryRule(telemetry)}
        shouldPausePlot={shouldPausePlot}
      />
      {parameter &&
        parameter.type === 'aggregate' &&
        parameter.values &&
        (telemetryState?.value || telemetry.recorded?.value) && (
          <>
            {Object.entries(telemetry.recorded?.value || telemetryState?.value).map(([key, value]) => (
              <TelemetryAndPlotRows
                key={key}
                isAggregate={true}
                isChildRow={true}
                isRecorded={Boolean(telemetry.recorded)}
                isRunning={isRunning}
                name={key}
                blockLabel=" "
                canBeStale={canBeStale}
                sample={{
                  streamingStatus: streamingStatus || '',
                  value: displayAggregateValue({ name: key, value: value as string, parameter }),
                  pass: displayPass,
                  timestamp: telemetry.recorded?.timestamp || telemetryState?.timestamp,
                }}
                runId={run._id}
                showNavigateToPlot={showNavigateToPlotForAggregateParam(key, parameter.values as AggregateValues)}
                searchParamsString={formatSearchParamsForAggregateParameter(
                  formatAggregateParameterName(parameter.name, key)
                )}
                dictionaryName={dictionaryName}
                parameterName={formatAggregateParameterName(parameter.name, key)}
                bounds={bounds}
                initialBounds={initialBounds}
                shouldPausePlot={shouldPausePlot}
              />
            ))}
          </>
        )}
    </>
  );
};

export default BlockTelemetry;

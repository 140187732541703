import Avatar from '../elements/Avatar';

const MAX_ICONS_TO_SHOW = 5;

const AvatarStack = ({ userIds }) => {
  if (!userIds) {
    return null;
  }

  const slicedIds = userIds.slice(0, MAX_ICONS_TO_SHOW);
  const numHidden = userIds.length - MAX_ICONS_TO_SHOW;

  return (
    <div className="inline-flex -space-x-2">
      {slicedIds.map((userId) => (
        <Avatar key={userId} userId={userId} />
      ))}
      {numHidden > 0 && (
        <div className="h-6 w-6 pl-3 cursor-default flex justify-center items-center text-gray-400">+{numHidden}</div>
      )}
    </div>
  );
};

export default AvatarStack;

import React, { useMemo } from 'react';
import { Field, FormikHelpers, FormikValues } from 'formik';
import externalDataUtil from '../../lib/externalDataUtil';
import ExternalTypeSelectFieldSet from './ExternalTypeSelectFieldSet';
import ExternalItemSelectFieldSet from './ExternalItemSelectFieldSet';
import Tooltip from '../../elements/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DraftExternalItemBlock } from 'shared/lib/types/views/procedures';
import { ExternalItemContentErrors } from '../../lib/types';

/**
 * Renders the form fields for an ExternalItem block.
 * See comments in useBlockComponents for more details
 */
interface ExternalItemFieldSetProps {
  path: string;
  content: DraftExternalItemBlock;
  contentErrors: ExternalItemContentErrors;
  setFieldValue: FormikHelpers<FormikValues>['setFieldValue'];
}
const ExternalItemFieldSet = ({ path, content, contentErrors, setFieldValue }: ExternalItemFieldSetProps) => {
  const onChangeType = (type: string, dictionaryId: number) => {
    // Typescript needs a guard check here since 'setFieldValue()' is unknown.
    if (typeof setFieldValue !== 'function') {
      return;
    }
    setFieldValue(path ? path : '', {
      ...content,
      item_type: type,
      dictionary_id: dictionaryId,
      item_id: null,
      item_name: null,
      item_label: null,
      item_url: null,
      item_valid: null,
      item: null,
    });
  };

  const onChangeItem = (item) => {
    // Typescript needs a guard check here since 'setFieldValue()' is unknown.
    if (typeof setFieldValue !== 'function') {
      return;
    }
    setFieldValue(path ? path : '', {
      ...content,
      item_id: item.id || null,
      item_name: item.name || null,
      item_label: item.label || null,
      item_url: item.url || null,
      item_valid: item.valid !== undefined && item.valid !== null ? item.valid : null,
      item,
    });
  };

  const externalItem = useMemo(() => {
    return externalDataUtil.getItemFromBlock(content);
  }, [content]);

  return (
    <div className="flex flex-wrap grow">
      {/* External item type */}
      <div aria-label="Data Type Field" className="flex flex-col w-96 mr-2">
        <span className="field-title">Data Type</span>
        <Field
          name={path ? `${path}.item_type` : 'item_type'}
          value={content.item_type}
          dictionaryId={content.dictionary_id}
          component={ExternalTypeSelectFieldSet}
          onChange={onChangeType}
        />
        {contentErrors && contentErrors.item_type && (
          <div className="text-red-700 text-sm">{contentErrors.item_type}</div>
        )}
      </div>

      {/* External item */}
      <div className="flex flex-col w-96 mr-2">
        <span className="field-title">Item</span>
        <Field
          itemType={content.item_type}
          itemDictionary={content.dictionary_id}
          name={path ? `${path}.item` : 'item'}
          value={externalItem}
          onChange={onChangeItem}
          component={ExternalItemSelectFieldSet}
        />
        {contentErrors && contentErrors.item_id && <div className="text-red-700">{contentErrors.item_id}</div>}
      </div>
      <div className="flex items-center pt-5">
        {content.item_valid === false && (
          <Tooltip content="This item is invalid">
            <FontAwesomeIcon className="text-red-500" icon="exclamation-circle" />
          </Tooltip>
        )}

        {/* Item URL */}
        {content.item_url && (
          <div className="flex flex-row">
            <Link to={{ pathname: content.item_url }} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon
                className="text-blue-500 p-2 hover:text-blue-600"
                icon={'fas fa-external-link-alt' as IconProp}
              />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExternalItemFieldSet;

import Label from './Label';
import React from 'react';

interface ReviewStatusLabelProps {
  isReviewApproved: boolean;
}

const ReviewStatusLabel = ({ isReviewApproved }: ReviewStatusLabelProps) => {
  const statusColor = isReviewApproved ? 'bg-green-200' : 'bg-amber-100';
  return (
    <Label text={isReviewApproved ? 'Ready to Release' : 'In Review'} color={statusColor} enableTruncate={false} />
  );
};

export default React.memo(ReviewStatusLabel);

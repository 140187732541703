import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { BUTTON_TYPES } from '../../components/Button';

type ZoomControlProps = {
  zoomIn: () => void;
  zoomOut: () => void;
  resetZoom: () => void;
};

const ZoomControl = ({ zoomIn, zoomOut, resetZoom }: ZoomControlProps) => {
  return (
    <div className="flex items-center justify-center space-x-0">
      <Button type={BUTTON_TYPES.TERTIARY} size="sm" leadingIcon="minus" ariaLabel="Zoom out" onClick={zoomOut} />
      <FontAwesomeIcon icon="magnifying-glass" className="text-gray-400" />
      <Button type={BUTTON_TYPES.TERTIARY} size="sm" leadingIcon="plus" ariaLabel="Zoom in" onClick={zoomIn} />
      <div className="w-1" />
      <div className="border-l border-gray-300 h-6"></div>
      <div className="w-1" />
      <Button type={BUTTON_TYPES.TERTIARY} size="sm" leadingIcon="expand" ariaLabel="Reset zoom" onClick={resetZoom} />
    </div>
  );
};

export default ZoomControl;

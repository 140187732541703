import React, { useCallback, useMemo } from 'react';
import procedureUtil from '../lib/procedureUtil';
import { isReleased } from 'shared/lib/procedureUtil';

const ProcedureVersionListItem = ({ version, isSelected, onSelect }) => {
  const onSelectVersion = useCallback(() => {
    onSelect && onSelect(version);
  }, [version, onSelect]);

  const versionLabel = useMemo(() => {
    if (isReleased(version)) {
      return `Version ${version.version}`;
    }
    return procedureUtil.getVersionLabel(version.version, version.state);
  }, [version]);

  return (
    <div className="flex flex-row gap-x-2">
      <button
        className="px-2 py-1 text-left w-full hover:bg-gray-100 disabled:bg-white disabled:cursor-default"
        onClick={onSelectVersion}
        disabled={isSelected}
      >
        <div className="flex">
          {/* Show dot next to current version, or leave space for the dot. */}
          <div className="w-3">
            <span className={isSelected ? '' : 'invisible'}>•</span>
          </div>
          <div>
            <span className="text-sm">
              <span className={isSelected ? '' : 'text-blue-600'}>{versionLabel}</span>
            </span>
          </div>
        </div>
      </button>
    </div>
  );
};

export default ProcedureVersionListItem;

import { OperatorRoles } from './views/settings';
import { Config } from 'shared/lib/types/couch/settings';

const configUtil = {
  canSuggestEdits: (
    currentUserOperatorRoles: Array<string>,
    operatorRoles: OperatorRoles | null
  ): boolean => {
    if (!operatorRoles) {
      return false;
    }

    // Allow suggesting edits if there are no operator roles
    if (operatorRoles.operators.length === 0) {
      return true;
    }

    // Otherwise ensure the user has at least one of the suggest-edit-enabled roles
    const suggestEditRoles = new Set(
      operatorRoles.operators
        .filter((operator) => operator.can_suggest_edits !== false)
        .map((operator) => operator.code)
    );

    for (const role of currentUserOperatorRoles) {
      if (suggestEditRoles.has(role)) {
        return true;
      }
    }

    return false;
  },
  getUserParticipantType: (config: Config): 'participant' | 'viewer' => {
    let userParticipantType: 'participant' | 'viewer' = 'participant';
    if ('participate_by_default' in config) {
      userParticipantType = config.participate_by_default
        ? 'participant'
        : 'viewer';
    }
    return userParticipantType;
  },
};

export default configUtil;

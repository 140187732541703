import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StepTiming } from 'shared/lib/types/couch/procedures';
import { Duration } from 'luxon';
import { useRunContext } from '../contexts/RunContext';
import Button from './Button';

interface StepDetailProps {
  value: StepTiming;
  disabled: boolean;
  updateStepDetail: (field: string, value: StepTiming, timingTriggered?: boolean) => void;
}

const FieldStepTimer = (props: StepDetailProps) => {
  const { value, disabled, updateStepDetail } = props;
  const { isPaused, calculatePauseDurationInMs } = useRunContext();
  const [started, setStarted] = useState(Boolean(value.started_at));
  const [displayMessage, setDisplayMessage] = useState('');

  const validatedValue = useMemo(() => {
    const timeLeftIsValid = Duration.fromISO(value.time_left).isValid;
    if (!timeLeftIsValid) {
      return { ...value, time_left: 'PT1S' }; // Default to 1 second
    }
    return value;
  }, [value]);

  const initialTimeLeft = React.useMemo(() => {
    if (typeof validatedValue.time_left === 'string' && !validatedValue.completed) {
      const duration = Duration.fromISO(validatedValue.time_left);
      return duration.as('milliseconds');
    }
    return 0;
  }, [validatedValue.completed, validatedValue.time_left]);

  const [timeLeft, setTimeLeft] = useState(initialTimeLeft);

  const calculateTimeElapsed = useCallback(() => {
    if (validatedValue.started_at && !validatedValue.completed && !validatedValue.time_remaining) {
      const startTime = new Date(validatedValue.started_at).getTime();
      const currentTime = new Date().getTime();
      const adjustedTime = currentTime - startTime - calculatePauseDurationInMs(startTime);
      const timeLeftDuration = Duration.fromISO(validatedValue.time_left).toMillis();

      if (!disabled && adjustedTime >= timeLeftDuration) {
        const updatedValue = { ...validatedValue, completed: true };
        updateStepDetail('timer', updatedValue);
        return 0;
      }

      const remainingTime = timeLeftDuration - adjustedTime;

      return Math.max(0, remainingTime);
    }
    return 0;
  }, [validatedValue, calculatePauseDurationInMs, updateStepDetail, disabled]);

  useEffect(() => {
    let timerId;
    if (started && !isPaused && !validatedValue.completed && !validatedValue.time_remaining && !disabled) {
      timerId = setInterval(() => {
        const newDuration = calculateTimeElapsed();
        setTimeLeft(newDuration);
      }, 1000);
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [started, isPaused, calculateTimeElapsed, validatedValue.completed, validatedValue.time_remaining, disabled]);

  useEffect(() => {
    setStarted(Boolean(validatedValue.started_at));
    if (validatedValue.started_at) {
      const newDuration = calculateTimeElapsed();
      setTimeLeft(newDuration);
    }
  }, [validatedValue.started_at, calculateTimeElapsed]);

  const onButtonPress = useCallback(() => {
    if (!started && !disabled) {
      const startAt = new Date().toISOString();
      setStarted(true);
      validatedValue.started_at = startAt;
      updateStepDetail('timer', validatedValue, true);
    }
  }, [started, validatedValue, updateStepDetail, disabled]);

  useEffect(() => {
    if (validatedValue.time_remaining) {
      setDisplayMessage(Duration.fromISO(validatedValue.time_remaining).toFormat('hh:mm:ss'));
    } else {
      setDisplayMessage(Duration.fromMillis(timeLeft).toFormat('hh:mm:ss'));
    }
  }, [validatedValue.time_left, timeLeft, validatedValue.time_remaining]);

  const percentTimeElapsed = useMemo(() => {
    const effectiveTimeLeft =
      validatedValue.time_remaining !== '' ? Duration.fromISO(validatedValue.time_remaining).toMillis() : timeLeft;
    const startingTimeLeft = Duration.fromISO(validatedValue.time_left).toMillis();

    if (effectiveTimeLeft > 0 && startingTimeLeft > 0) {
      return (effectiveTimeLeft / startingTimeLeft) * 100;
    }

    return 0;
  }, [timeLeft, validatedValue.time_left, validatedValue.time_remaining]);

  const calculateStrokeDashoffset = () => {
    const radius = 12;
    const circumference = 2 * Math.PI * radius;
    return circumference - (percentTimeElapsed / 100) * circumference;
  };

  const isStarted = validatedValue.started_at;
  const isCompleted = validatedValue.completed;

  return (
    <div className="flex items-center">
      {isStarted && !isCompleted && (
        <>
          <div className="relative w-7 h-7 mr-1 ml-2">
            <svg width="28" height="28" viewBox="0 0 32 32" style={{ transform: 'rotate(-90deg)' }}>
              <circle cx="16" cy="16" r="12" fill="none" stroke="#FFFFFF" strokeWidth="6" />
              <circle
                cx="16"
                cy="16"
                r="12"
                fill="none"
                stroke="#3b82f6"
                strokeWidth="6"
                strokeDasharray="75.40"
                strokeDashoffset={calculateStrokeDashoffset()}
              />
            </svg>
          </div>

          <div className="text-lg">{displayMessage}</div>
          <div className="font-light text-md ml-1">
            /&#40;{Duration.fromISO(validatedValue.time_left).toFormat('hh:mm:ss')}&#41;
          </div>
        </>
      )}
      {!isStarted && !isPaused && (
        <div className="ml-1">
          <Button onClick={onButtonPress} isDisabled={disabled} type="primary" size="sm">
            Start
          </Button>
        </div>
      )}
    </div>
  );
};

export default FieldStepTimer;

import { useCallback, useMemo } from 'react';
import { Chart as ChartJS, ChartEvent, Scale, CoreScaleOptions } from 'chart.js';
import { ChartAction, ExtendedChartData, PlotType } from '../components/Plot';
import { Annotation, DataType } from '../api/annotation';
import { Rule } from 'shared/lib/types/views/procedures';
import { Measurement } from '../types';

const ANNOTATION_LINE_WIDTH = 3;
const ANNOTATION_DASH_LENGTH = 5;
const ANNOTATION_GAP_LENGTH = 5;
const ANNOTATION_PURPLE_LINE = '#8A2BE2';
const ANNOTATION_GREEN_LINE = '#008B8B';

const drawDottedLine = (chart: ChartJS, x: number): void => {
  const ctx = chart.ctx;
  const yAxis = chart.scales.y ?? Object.values(chart.scales).find((scale) => scale.axis === 'y');

  ctx.save();
  ctx.beginPath();
  ctx.setLineDash([ANNOTATION_DASH_LENGTH, ANNOTATION_GAP_LENGTH]);
  ctx.moveTo(x, yAxis.top);
  ctx.lineTo(x, yAxis.bottom);
  ctx.strokeStyle = 'gray';
  ctx.lineWidth = ANNOTATION_LINE_WIDTH;
  ctx.stroke();
  ctx.restore();
};

const drawHorizontalRuleLine = (
  ctx: CanvasRenderingContext2D,
  rule: string,
  xAxis: Scale<CoreScaleOptions>,
  yValue: number
) => {
  ctx.beginPath();
  if (rule === '<' || rule === '>') {
    ctx.setLineDash([5, 5]);
  }
  ctx.lineWidth = 1;
  ctx.globalAlpha = 1;
  ctx.moveTo(xAxis.left, yValue);
  ctx.lineTo(xAxis.right, yValue);
  ctx.stroke();
};

const drawAnnotationLine = (chart: ChartJS, annotation: Annotation) => {
  const ctx = chart.ctx;
  const unixTime = new Date(annotation.start_time).getTime();

  const x = chart.scales.x.getPixelForValue(unixTime);
  const yAxis = chart.scales.y ?? Object.values(chart.scales).find((scale) => scale.axis === 'y');
  const yTop = yAxis.getPixelForValue(yAxis.max);
  const yBottom = yAxis.getPixelForValue(yAxis.min);

  const lineColor = annotation.channel ? ANNOTATION_PURPLE_LINE : ANNOTATION_GREEN_LINE;

  ctx.beginPath();
  ctx.setLineDash([ANNOTATION_DASH_LENGTH, ANNOTATION_GAP_LENGTH]);
  ctx.moveTo(x, yTop);
  ctx.lineTo(x, yBottom);
  ctx.strokeStyle = lineColor;
  ctx.lineWidth = ANNOTATION_LINE_WIDTH;
  ctx.stroke();

  const triangleUnicode = '\u25B2';
  const triangleFontSize = 10;

  ctx.font = `${triangleFontSize}px Arial`;
  ctx.fillStyle = lineColor;
  ctx.fillText(triangleUnicode, x - triangleFontSize / 2, yBottom);
};

const findAnnotationAtMousePosition = (
  chart: ChartJS,
  drawnAnnotations: Annotation[],
  xPosition: number
): Annotation | null => {
  const tolerance = 2; // pixels
  for (const drawnAnnotation of drawnAnnotations) {
    const unixTime = new Date(drawnAnnotation.start_time).getTime();
    const annotationX = chart.scales.x.getPixelForValue(unixTime);

    if (xPosition >= annotationX - tolerance && xPosition <= annotationX + tolerance) {
      return drawnAnnotation;
    }
  }
  return null;
};

const annotationMatchesCurrentMeasurement = (annotation: Annotation, measurement: Measurement): boolean => {
  return (
    ((annotation.data_type === DataType.Upload && annotation.file_id === measurement.folderId) ||
      (annotation.data_type === DataType.RunTelemetry && annotation.run_id === measurement.folderId) ||
      (annotation.data_type === DataType.Parameter && annotation.dictionary_id === measurement.folderId)) &&
    (annotation.channel === null || annotation.channel === measurement.name)
  );
};

const annotationInBounds = (chart: ChartJS, annotation: Annotation): boolean => {
  const unixTime = new Date(annotation.start_time).getTime();
  const annotationX = chart.scales.x.getPixelForValue(unixTime);

  const xAxis = chart.scales.x;
  const xAxisStart = xAxis.left;
  const xAxisEnd = xAxis.right;

  return annotationX >= xAxisStart && annotationX <= xAxisEnd;
};

const useChartPlugin = (storageTrack, telemetryRule, enabledAction, plotType, setAnnotateModal) => {
  const createAnnotationEnabled = useCallback(
    (event: MouseEvent, annotationEnabled: boolean): boolean => {
      if (plotType === PlotType.RunPlot || !annotationEnabled) {
        // Cannot create annotations on the inline run plot
        return false;
      }
      return event.metaKey || event.ctrlKey || enabledAction === ChartAction.Annotate;
    },
    [enabledAction, plotType]
  );

  const drawTelemetryRule = useCallback((chart: ChartJS, rule: Rule) => {
    const ctx = chart.ctx;
    ctx.save();
    const xAxis = chart.scales.x;
    const yAxis = chart.scales.y ?? Object.values(chart.scales).find((scale) => scale.axis === 'y');

    if (rule.op !== 'range') {
      const ruleValue = Number(rule.value);
      const yValue = yAxis.getPixelForValue(ruleValue);

      drawHorizontalRuleLine(ctx, rule.op, xAxis, yValue);
    } else {
      const minValue = Number(rule.range?.min);
      const maxValue = Number(rule.range?.max);
      const minYValue = yAxis.getPixelForValue(minValue);
      const maxYValue = yAxis.getPixelForValue(maxValue);

      drawHorizontalRuleLine(ctx, '>', xAxis, minYValue);
      drawHorizontalRuleLine(ctx, '<', xAxis, maxYValue);
    }

    ctx.restore();
  }, []);

  // Get the unique annotations across all measuements drawn on the plot
  const getAnnotationsToDraw = useCallback(
    (chart: ChartJS, chartMeasurements?: Measurement[], chartAnnotations?: Annotation[]): Annotation[] => {
      if (!chartMeasurements || !chartAnnotations) {
        return [];
      }
      const uniqueAnnotationIds = new Set();
      for (const chartMeasurement of chartMeasurements) {
        for (const chartAnnotation of chartAnnotations) {
          if (annotationMatchesCurrentMeasurement(chartAnnotation, chartMeasurement)) {
            uniqueAnnotationIds.add(chartAnnotation.id);
          }
        }
      }
      const annotationsToDraw: Annotation[] = [];
      for (const chartAnnotation of chartAnnotations) {
        if (uniqueAnnotationIds.has(chartAnnotation.id) && annotationInBounds(chart, chartAnnotation)) {
          annotationsToDraw.push(chartAnnotation);
        }
      }
      return annotationsToDraw;
    },
    []
  );

  return useMemo(() => {
    return {
      id: 'defaultPlugin',
      afterDatasetsDraw: (chart: ChartJS) => {
        const ctx = chart.ctx;
        ctx.save();

        const chartData = chart.data as ExtendedChartData;
        const annotationsToDraw = getAnnotationsToDraw(chart, chartData.measurements, chartData.annotations);

        for (const annotationToDraw of annotationsToDraw) {
          drawAnnotationLine(chart, annotationToDraw);
        }

        if (telemetryRule && telemetryRule.op) {
          drawTelemetryRule(chart, telemetryRule);
        }

        ctx.restore();
      },
      afterEvent(chart: ChartJS, args: { event: ChartEvent }) {
        const event = args.event;
        const nativeEvent = event.native as MouseEvent | undefined;

        const chartData = chart.data as ExtendedChartData;
        const chartMeasurements = chartData.measurements;
        const chartAnnotations = chartData.annotations;
        const annotationEnabled = chartData.annotationEnabled || false;
        const chartEnabledAction = chartData.enabledAction;

        if (event.x === null || event.y === null || !chartMeasurements || !nativeEvent || !chartEnabledAction) {
          return;
        }

        const drawnAnnotations = getAnnotationsToDraw(chart, chartMeasurements, chartAnnotations);
        const annotationAtMousePosition = findAnnotationAtMousePosition(chart, drawnAnnotations, event.x);
        chart.canvas.style.cursor = annotationAtMousePosition !== null ? 'pointer' : 'default';

        const xAxis = chart.scales.x;
        const xAxisStart = xAxis.left;
        const xAxisEnd = xAxis.right;

        if (event.type === 'click') {
          // View annotation at current position
          const xAxisValue = xAxis.getValueForPixel(event.x) as number;
          if (annotationAtMousePosition !== null) {
            setAnnotateModal({
              clientX: nativeEvent.clientX,
              clientY: nativeEvent.clientY,
              isCreating: false,
              visible: true,
              annotation: annotationAtMousePosition,
              type: annotationAtMousePosition.data_type,
            });
            storageTrack('Annotation viewed');
          } else if (
            createAnnotationEnabled(nativeEvent, annotationEnabled) &&
            event.x >= xAxisStart &&
            event.x <= xAxisEnd
          ) {
            // Create annotation at current position
            if (chartMeasurements?.length === 1) {
              const { folderId, type, name: channel } = chartMeasurements[0];
              setAnnotateModal({
                channel,
                type,
                identifier: folderId,
                xValue: xAxisValue,
                clientX: nativeEvent.clientX,
                clientY: nativeEvent.clientY,
                isCreating: true,
                visible: true,
              });
            } else if (chartMeasurements?.length >= 1) {
              const allSameDataset = chartMeasurements.every((obj, _, array) => obj.bucket === array[0].bucket);
              if (!allSameDataset) {
                window.alert('Annotating across datasets not currently supported');
                return;
              }
              const { folderId, type } = chartMeasurements[0];
              setAnnotateModal({
                type,
                identifier: folderId,
                xValue: xAxisValue,
                clientX: nativeEvent.clientX,
                clientY: nativeEvent.clientY,
                isCreating: true,
                visible: true,
              });
            }
          }
        } else if (event.type === 'mousemove' && createAnnotationEnabled(nativeEvent, annotationEnabled)) {
          chart.clear();
          chart.render();

          if (annotationAtMousePosition === null && event.x >= xAxisStart && event.x <= xAxisEnd) {
            drawDottedLine(chart, event.x);
          }
        }
      },
    };
  }, [getAnnotationsToDraw, telemetryRule, drawTelemetryRule, createAnnotationEnabled, setAnnotateModal, storageTrack]);
};

export default useChartPlugin;

import { useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_STATE } from '@redux-offline/redux-offline/lib/constants';
import { RESET_APP_STATE } from '../app/store';
import { selectOfflineInfo } from '../app/offline';
import { INTERCOM_ENABLED } from '../config';
import superlogin, { getDefaultTeamIdFromSession, refreshSessionCookie } from '../api/superlogin';
import MixpanelUtil from '../lib/mixpanel';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import { useUserInfo, updateUserInfo } from '../contexts/UserContext';
import apm from '../lib/apm';

const LOGOUT_PENDING_ACTIONS_PROMPT = 'Are you sure you want to sign out? Your offline work will be discarded.';

const useAuth = (setIsLoggingOut) => {
  const { updateTeamIds, clearLocalData } = useDatabaseServices();
  const { userInfo, setUserInfo } = useUserInfo();
  const { mixpanel } = useMixpanel();
  const { update } = useIntercom();
  const hasActions = useSelector((state) => selectOfflineInfo(state).hasActions);
  const dispatch = useDispatch();

  const resetAppState = useCallback(async () => {
    // Clear any local data from local storage.
    await clearLocalData();
    // Reset redux-offline state.
    dispatch({ type: RESET_STATE });
    // Reset all redux state.
    dispatch({ type: RESET_APP_STATE });
  }, [clearLocalData, dispatch]);

  const onAuthenticationSuccess = useCallback(
    (session) => {
      // Mixpanel user identification and tracking
      MixpanelUtil.updateWithSession(mixpanel, session);
      if (mixpanel) {
        mixpanel.track('Login');
      }

      // Intercom user
      if (INTERCOM_ENABLED) {
        update({
          userId: session.user_id,
          userHash: session.profile && session.profile.intercom_user_hash,
          email: session.user_id,
        });
      }

      // Update app state with user's session and team info
      updateUserInfo(session, setUserInfo);

      const defaultTeamId = getDefaultTeamIdFromSession(session);
      updateTeamIds(session.userOrgData, defaultTeamId ?? undefined);

      // Wait for initial session cookie on first-time login.
      return refreshSessionCookie(session);
    },
    [mixpanel, setUserInfo, update, updateTeamIds]
  );

  const logout = useCallback(async () => {
    // Confirm with user about losing any unsaved offline actions.
    if (hasActions && !window.confirm(LOGOUT_PENDING_ACTIONS_PROMPT)) {
      return;
    }
    setIsLoggingOut && setIsLoggingOut(true);
    // For security, clear app data when user explicitly signs out.
    try {
      await resetAppState();
    } catch (e) {
      apm.captureError(`Error during logout: ${e.message}`);
    }

    try {
      // Mark this session as being ended by user action, not timeout.
      if (userInfo.session) {
        userInfo.session.intentional_logout = true;
      }
      await superlogin.logout();
    } catch (e) {
      // Ignore errors, logout() will delete the session in either case.
      apm.captureError(`Error during logout: ${e.message}`);
    }
  }, [hasActions, resetAppState, setIsLoggingOut, userInfo.session]);

  return {
    logout,
    onAuthenticationSuccess,
  };
};

export default useAuth;

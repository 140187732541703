import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useSettings } from '../../contexts/SettingsContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';

type UsePartsProps = {
  partIds?: string[];
  includeAllReleasedRevisions?: boolean;
};

interface PartsState {
  parts?: Part[];
  assemblyParts?: Part[];
  getPart: (partId: string) => Part | undefined;
  getPartByRevisionId: (revisionId: string) => Part | undefined;
}

const useParts = (props: UsePartsProps = {}): PartsState => {
  const { partIds, includeAllReleasedRevisions } = props;
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const { isManufacturingEnabled } = useSettings();

  const fetchParts = async () => {
    if (!isManufacturingEnabled?.()) {
      return null;
    }
    if (partIds?.length === 0) {
      return { parts: [], assemblyParts: [] };
    }
    const promises = [
      services.manufacturing.getParts({ partIds, includeAllReleasedRevisions }),
      services.manufacturing.getAssemblyParts(),
    ];
    const [parts, assemblyParts] = await Promise.all(promises);
    return { parts, assemblyParts };
  };

  const { data } = useQuery({
    queryKey: ['parts', partIds, includeAllReleasedRevisions],
    queryFn: fetchParts,
  });

  const getPart = (partId) => data?.parts.find((part) => part.id === partId);

  const getPartByRevisionId = useCallback(
    (revisionId: string) => {
      let partIdMatch: Part | undefined = undefined;
      for (const part of data?.parts ?? []) {
        if (!part.revisions || part.revisions.length === 0) {
          continue;
        }
        for (const revision of part.revisions) {
          if (revision.id === revisionId) {
            if (revision.revision === part.rev) {
              return part; // exact match
            }
            partIdMatch = part;
          }
        }
      }
      return partIdMatch;
    },
    [data?.parts]
  );

  return {
    parts: data?.parts,
    assemblyParts: data?.assemblyParts,
    getPart,
    getPartByRevisionId,
  };
};

export default useParts;

import React, { useCallback } from 'react';
import { useMixpanel } from '../contexts/MixpanelContext';
import { FieldArray, Form, Formik, setIn } from 'formik';
import FieldSetProcedureVariable from './FieldSetProcedureVariable';

const FormProcedureVariables = ({ values, errors, onRemoveVariable, onFieldRefChanged, onChanged }) => {
  const { mixpanel } = useMixpanel();

  const mixpanelTrack = useCallback(
    (name: string, options?: object) => {
      if (mixpanel) {
        mixpanel.track(name, options);
      }
    },
    [mixpanel]
  );

  /*
   * Duplicated from FieldSetProcedureSection.js and redefined here to avoid
   * re-rendering component when a sibling step changes.
   * TODO: DRY this up, possibly by creating a MixpanelContext.js
   */
  const trackedCallback = useCallback(
    (trackingKey, callback) => (event) => {
      mixpanelTrack(trackingKey);
      callback(event);
    },
    [mixpanelTrack]
  );

  const getOnRemoveVariable = useCallback(
    (index) => {
      return trackedCallback('Remove Variable', () => onRemoveVariable(index));
    },
    [trackedCallback, onRemoveVariable]
  );

  const setFieldValue = useCallback(
    (path, value) => {
      const variablesCopy = setIn(values, path, value);
      onChanged(variablesCopy);
    },
    [onChanged, values]
  );

  return (
    <Formik
      initialValues={values}
      initialErrors={errors}
      onSubmit={() => {
        /* no-op */
      }}
      validateOnChange={false}
      validate={onChanged}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <div className="flex flex-col mr-2">
            <FieldArray
              name="variables"
              render={() => (
                <div>
                  {values.variables &&
                    values.variables.map((variable, index) => (
                      <FieldSetProcedureVariable
                        key={`variables[${index}]`}
                        index={index}
                        variable={variable}
                        setFieldValue={setFieldValue}
                        onFieldRefChanged={onFieldRefChanged}
                        getOnRemoveVariable={getOnRemoveVariable}
                        errors={errors}
                      />
                    ))}
                </div>
              )}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormProcedureVariables;

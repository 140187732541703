import superlogin from './superlogin';
import { API_URL } from '../config';
import { ProjectUserRoles } from '../components/Settings/types';

class ProjectUsersService {
  teamId: string;
  projectId: string;
  url: string;

  constructor(teamId: string | null, projectId: string | undefined) {
    if (!teamId || !projectId) {
      throw new Error('Missing teamId or projectId');
    }
    this.teamId = teamId;
    this.projectId = projectId;
    this.url = `${API_URL}/teams/${this.teamId}/projects/${this.projectId}/users`;
  }

  async upsertProjectUser(
    user_id: string,
    project_roles: string[]
  ): Promise<string> {
    const body = {
      id: user_id,
      project_roles,
    };

    try {
      const response = await superlogin.getHttp().put(this.url, body);
      return response.data;
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }

  async deleteProjectUser(user_ids: string[]): Promise<string> {
    const body = { ids: user_ids };

    try {
      const response = await superlogin
        .getHttp()
        .delete(this.url, { data: body });
      return response.data;
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }

  async listProjectUsers(): Promise<ProjectUserRoles[]> {
    try {
      const response = await superlogin.getHttp().get(this.url);
      return response.data.data;
    } catch (message) {
      throw new Error(message.response.data.error);
    }
  }
}

export default ProjectUsersService;

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import { reactSelectStyles } from '../../lib/styles';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { FieldInputProps, FormikProps } from 'formik';
import { SnippetType, Snippet } from '../../lib/views/settings';
import { DatabaseServices } from '../../contexts/proceduresSlice';

interface SnippetFieldProps {
  field: FieldInputProps<Snippet>;
  form: FormikProps<Snippet>;
  snippetType: SnippetType;
  isTestSnippet: boolean;
}

const SnippetField = ({ field, form, snippetType, isTestSnippet }: SnippetFieldProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const [snippets, setSnippets] = useState<Array<Snippet>>([]);
  const isMounted = useRef(true);

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  useEffect(() => {
    if (!services.settings) {
      return;
    }
    services.settings
      .listSnippets({ isTestSnippet })
      .then((snippets) => {
        if (!isMounted.current) {
          return;
        }

        const filteredSnippets = snippets.filter((snippet: Snippet) => snippet.snippet_type === snippetType);

        setSnippets(filteredSnippets);
      })
      .catch(() => {
        /* Ignored */
      });
  }, [services.settings, snippetType, isTestSnippet]);

  const onChangeHandler = useCallback(
    (option) => {
      const snippet = snippets.find((snippet) => snippet._id === option.value);
      if (snippet) {
        form.setFieldValue(field.name, snippet);
      }
    },
    [snippets, form, field]
  );

  const snippetOptions = useMemo(() => {
    return snippets.map((snippet) => ({
      value: snippet._id,
      label: snippet.name,
    }));
  }, [snippets]);

  const selectValue = field.value
    ? {
        value: field.value._id,
        label: field.value.name,
      }
    : null;

  return (
    <div>
      <Select
        isSearchable={true}
        value={selectValue}
        options={snippetOptions}
        classNamePrefix="react-select"
        onChange={onChangeHandler}
        placeholder="Search by name"
        styles={reactSelectStyles}
      />
    </div>
  );
};

export default SnippetField;

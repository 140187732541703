import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Handle, Position } from '@xyflow/react';

/**
 * A react-flow node for rendering a procedure section.
 *
 * @param data {Object} - A react-flow node data object.
 */
const StepNode = ({ data }) => {
  return (
    <div className="flex justify-center items-center border border-black p-2 h-[56px] leading-3 rounded-md">
      <Handle type="target" position={Position.Top} />
      <div className="whitespace-normal line-clamp-3">
        <span className="font-semibold text-sm">{data.stepKey}</span>
        <span className="ml-1 text-xs text-gray-700">{data.label}</span>
        {data.isRepeat && (
          <span className="ml-1 whitespace-nowrap" aria-label={`repeat ${data.repeatKey}`}>
            <FontAwesomeIcon icon="redo" className="text-xs" />
            {` ${data.repeatKey}`}
          </span>
        )}
      </div>
      <Handle type="source" position={Position.Bottom} id="a" />
    </div>
  );
};

export default StepNode;

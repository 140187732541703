import { ClipboardItem, ClipboardItemType } from '../contexts/proceduresSlice';
import { Section, Step, StepBlock } from 'shared/lib/types/views/procedures';
import procedureUtil from './procedureUtil';

const clipboardUtil = {
  createClipboardItemSection: (data: Section): ClipboardItem => {
    return {
      type: ClipboardItemType.SECTION,
      [ClipboardItemType.SECTION]: data,
    };
  },
  createClipboardItemStep: (data: Step): ClipboardItem => {
    return {
      type: ClipboardItemType.STEP,
      [ClipboardItemType.STEP]: data,
    };
  },
  createClipboardItemBlock: (data: StepBlock): ClipboardItem => {
    return {
      type: ClipboardItemType.BLOCK,
      [ClipboardItemType.BLOCK]: data,
    };
  },
  getSectionFromClipboardItem: (
    clipboardItem: ClipboardItem
  ): Section | undefined => {
    if (!clipboardItem[ClipboardItemType.SECTION]) {
      return undefined;
    }
    return {
      ...clipboardItem[ClipboardItemType.SECTION],
      id: procedureUtil.generateSectionId(),
    };
  },
  getStepFromClipboardItem: (
    clipboardItem: ClipboardItem
  ): Step | undefined => {
    if (!clipboardItem[ClipboardItemType.STEP]) {
      return undefined;
    }
    return {
      ...clipboardItem[ClipboardItemType.STEP],
      id: procedureUtil.generateStepId(),
    };
  },
  getBlockFromClipboardItem: (
    clipboardItem: ClipboardItem
  ): StepBlock | undefined => {
    if (!clipboardItem[ClipboardItemType.BLOCK]) {
      return undefined;
    }
    return {
      ...clipboardItem[ClipboardItemType.BLOCK],
      id: procedureUtil.generateContentId(),
    };
  },
  getClipboardDescription: (clipboardItem: ClipboardItem): string => {
    return `${clipboardItem.type} copied to clipboard`;
  },
};

export default clipboardUtil;

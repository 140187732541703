import React, { useState, useEffect } from 'react';

const FilePreview = ({ file }) => {
  const [shouldRenderImage, setShouldRenderImage] = useState(false);

  useEffect(() => {
    if (file) {
      setShouldRenderImage(file.type.startsWith('image/'));
    }
  }, [file]);

  if (!file) {
    return null;
  }

  if (shouldRenderImage) {
    return <ThumbnailImage file={file} />;
  }

  return <ThumbnailFile file={file} />;
};

const ThumbnailImage = ({ file }) => {
  return <img src={URL.createObjectURL(file)} alt={file.name} />;
};

const ThumbnailFile = ({ file }) => {
  return <div>{file.name}</div>;
};

export default FilePreview;

import { OperationState } from '../operations';

export type Operation = {
  id: number;
  team_id: string;
  name: string;
  description: string | null;
  state: OperationState;
  created_by: string;
  created_at: string;
  updated_at: string;
};

export type OperationEvent = {
  operation_id: number;
  event_id: string;
};

export type OperationProcedure = {
  operation_id: number;
  procedure_id: string;
};

export enum OperationAction {
  Created = 'Created',
  Started = 'Started',
  Ended = 'Ended',
  AssigneeUpdated = 'Assignee Updated',
  DescriptionUpdated = 'Description Updated',
  EventAdded = 'Event Added',
  EventStarted = 'Event Started',
  EventEnded = 'Event Ended',
  EventDeleted = 'Event Deleted',
}

export enum OperationActorType {
  Api = 'api',
  User = 'user',
}

export type DbOperationActivity = {
  id: string; // bigint in the database, gets deserialized as a string
  timestamp: Date;
  operation_id: string;
  action: OperationAction;
  actor_type: OperationActorType;
  actor_id?: string;
  new_value?: object;
  old_value?: object;
};

export type InsertOperationActivity = Omit<
  DbOperationActivity,
  'id' | 'timestamp'
>;

export type ListOperationActivities = {
  has_older: boolean;
  activities: DbOperationActivity[];
};

export type AssigneeUpdatedActivityData = { assignee_id: string | null };

export type EventActivityData = { event_id: string };

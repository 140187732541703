import Pluralize from 'pluralize';
import { useMemo } from 'react';
import Tooltip from '../../../elements/Tooltip';
import useUnits from '../../../hooks/useUnits';

export interface UnitDisplayProps {
  unit: string;
}

const UnitDisplay = ({ unit }: UnitDisplayProps) => {
  const { findDefinedUnit } = useUnits();

  const settingsUnit = useMemo(() => findDefinedUnit(unit), [findDefinedUnit, unit]);

  const plural = useMemo(() => (settingsUnit ? settingsUnit.plural : Pluralize(unit)), [settingsUnit, unit]);

  const abbreviation = useMemo(() => {
    return settingsUnit?.abbreviation ?? unit;
  }, [settingsUnit, unit]);

  const isCustom = useMemo(() => !settingsUnit, [settingsUnit]);

  return isCustom ? (
    <div>{unit}</div>
  ) : (
    <Tooltip content={plural}>
      <div>{abbreviation}</div>
    </Tooltip>
  );
};

export default UnitDisplay;

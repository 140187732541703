import React, { useMemo } from 'react';
import DiffContainer from '../Diff/DiffContainer';
import Select from 'react-select';
import sharedDiffUtil from 'shared/lib/diffUtil';
import { isArray } from 'lodash';
import ProcedureDiffText from '../ProcedureDiffText';
import TextLinkify from '../TextLinkify';
import TimestampFieldInputDisplay from '../Blocks/FieldInput/TimestampFieldInputDisplay';
import Checkbox from '../Checkbox';
import UnitDisplay from '../Settings/Units/UnitDisplay';
import { disabledGridReactSelectStyles } from '../../lib/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RuleStatusIcon from '../Blocks/RuleStatusIcon';
import { FieldInputNumberBlock } from 'shared/lib/types/views/procedures';
import ReviewRule from './Blocks/ReviewRule';
import TooltipOverlay from '../TooltipOverlay';

interface ReviewFieldInputTableProps {
  content;
}

const ReviewFieldInputTable = ({ content }: ReviewFieldInputTableProps) => {
  const { fieldsPerRow, fields } = content;

  const diffChanges = useMemo(
    () =>
      fields.map((field) => {
        if (isArray(field)) {
          return {
            ...field[1],
            diff_change_state: field[0],
          };
        }
        return field;
      }),
    [fields]
  );

  return (
    <DiffContainer label="Field Input Table" diffChangeState={content.diff_change_state} isTextSticky={false}>
      <div className="relative flex max-w-full w-full overflow-visible mr-2">
        <div className="flex-grow relative">
          <div className="ml-1 text-sm">
            <ProcedureDiffText
              diffValue={fieldsPerRow}
              diffChangeState={sharedDiffUtil.isChanged(content, 'fieldsPerRow') ? '~' : ' '}
              useMarkdownWhenNoDiff={false}
            />
            &nbsp;columns
          </div>
          <div
            className="grid"
            style={{
              gridTemplateColumns: `repeat(${sharedDiffUtil.getDiffValue(
                content,
                'fieldsPerRow',
                'old'
              )}, minmax(125px, 1fr))`,
            }}
          >
            {diffChanges.map((field, index) => (
              <DiffContainer label="Entry" diffChangeState={field.diff_change_state} isTextSticky={false}>
                <div key={index} className="flex items-center w-full">
                  <div className="flex flex-col w-full p-1 relative">
                    {/* Display for each block type */}
                    <TextLinkify>
                      <div className="flex justify-start w-full absolute top-1 left-2 text-xs text-gray-500 font-semibold z-20 truncate">
                        <label className="text-xs text-gray-500 font-semibold z-20 truncate w-full pr-3">
                          {sharedDiffUtil.getDiffValue(field, 'name', 'new')}
                          {(sharedDiffUtil.getDiffValue(field, 'inputType', 'new') === 'number' ||
                            sharedDiffUtil.getDiffValue(field, 'inputType', 'new') === 'text') &&
                            sharedDiffUtil.getDiffValue(field, 'units', 'new') && (
                              <span className="inline-flex ml-1">
                                (<UnitDisplay unit={sharedDiffUtil.getDiffValue(field, 'units', 'new')} />)
                              </span>
                            )}
                        </label>
                      </div>
                    </TextLinkify>

                    {/* Specific input type handling */}
                    {sharedDiffUtil.getDiffValue(field, 'inputType', 'new') === 'timestamp' && (
                      <div className="border rounded-md border-gray-400 w-full flex items-center bg-gray-300 bg-opacity-50">
                        <FontAwesomeIcon
                          icon={field.dateTimeType === 'time' ? 'clock' : 'calendar-alt'}
                          className="text-sm mt-1 ml-1 text-gray-500"
                        />
                        <TimestampFieldInputDisplay type={field.dateTimeType} recorded={undefined} isGridFormat={true}>
                          <></>
                        </TimestampFieldInputDisplay>
                      </div>
                    )}
                    {sharedDiffUtil.getDiffValue(field, 'inputType', 'new') === 'checkbox' && (
                      <div className="w-full h-[2.9rem] text-gray-500 border border-gray-400 rounded bg-gray-300 bg-opacity-50 flex justify-start">
                        <div className="ml-2 mt-3">
                          <Checkbox disabled={true} ariaLabel="checkbox" size="text-md" checked={false} />
                        </div>
                      </div>
                    )}
                    {sharedDiffUtil.getDiffValue(field, 'inputType', 'new') === 'text' && (
                      <div className="text-sm px-2 py-1 border border-gray-400 rounded bg-gray-300 bg-opacity-50 w-full h-[2.9rem]" />
                    )}
                    {sharedDiffUtil.getDiffValue(field, 'inputType', 'new') === 'number' && (
                      <TooltipOverlay
                        content={
                          <div>
                            <ReviewRule block={field} />
                          </div>
                        }
                        visible={Boolean((field as FieldInputNumberBlock).rule?.op)}
                      >
                        <div className="relative">
                          <div className="text-sm px-2 py-1 border border-gray-400 rounded bg-gray-300 bg-opacity-50 w-full h-[2.9rem]" />

                          {Boolean((field as FieldInputNumberBlock).rule?.op) && (
                            <div className="absolute top-1/2 -translate-y-1/2 right-1.5">
                              <RuleStatusIcon isPassing={null} />
                            </div>
                          )}
                        </div>
                      </TooltipOverlay>
                    )}
                    {sharedDiffUtil.getDiffValue(field, 'inputType', 'new') === 'select' && (
                      <Select
                        classNamePrefix="react-select"
                        className="w-full"
                        styles={disabledGridReactSelectStyles}
                        value={undefined}
                        aria-label="Field Input Select"
                        isDisabled={true}
                      />
                    )}
                    {sharedDiffUtil.getDiffValue(field, 'inputType', 'new') === 'list' && (
                      <Select
                        classNamePrefix="react-select"
                        className="w-full"
                        styles={disabledGridReactSelectStyles}
                        value={undefined}
                        aria-label="Field Input List"
                        isDisabled={true}
                      />
                    )}
                  </div>
                </div>
              </DiffContainer>
            ))}
          </div>
        </div>
      </div>
    </DiffContainer>
  );
};

export default ReviewFieldInputTable;

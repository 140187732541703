import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { ReviewToolUsageBlock } from 'shared/lib/types/views/procedures';
import DiffContainer from '../../../components/Diff/DiffContainer';
import diffUtil from '../../../lib/diffUtil';
import sharedDiffUtil from 'shared/lib/diffUtil';
import { toolDetailPath } from '../../../lib/pathUtil';
import { reactSelectStyles } from '../../../lib/styles';
import useTool from '../../hooks/useTool';
import useUsageTypes from '../../hooks/useUsageTypes';
import toDisplay from '../../lib/tools/toDisplay';
import ThumbnailImage from '../ThumbnailImage';
import UsageTypeLabel from '../UsageTypeLabel';

interface ReviewToolUsageProps {
  content: ReviewToolUsageBlock;
  teamId: string;
}

const ReviewToolUsage = ({ content, teamId }: ReviewToolUsageProps) => {
  const toolId = sharedDiffUtil.getDiffValue<number>(content, 'tool_id', 'new');
  const usageTypeId = sharedDiffUtil.getDiffValue<number>(content, 'usage_type_id', 'new');
  const { tool } = useTool({ toolId });
  const { usageTypes } = useUsageTypes();

  const usageType = useMemo(
    () => usageTypes?.find((usageType) => usageType.id === usageTypeId),
    [usageTypeId, usageTypes]
  );

  if (!tool) {
    return null;
  }

  const diffChangeState =
    content.diff_change_state === '~'
      ? diffUtil.getDiffChangeStateForChangesOnly(content, 'tool_id')
      : diffUtil.getDiffChangeStateForAddedRemovedOnly(content);

  return (
    <tr>
      <td></td>
      <td colSpan={2}>
        <div className="mt-3 ml-4">
          <DiffContainer label="Tool usage" diffChangeState={diffChangeState} isTextSticky={false} width="fit">
            <div className="flex flex-row">
              <div className="w-64">
                <div className="field-title">Tool for Recording Usage</div>
                <div className="flex pt-2 flex-grow">
                  <div>
                    <ThumbnailImage size="sm" attachment={toDisplay.fromToolImageProps(tool)} />
                  </div>
                  <div className="pl-2 pr-1 truncate text-blue-600">
                    <Link to={toolDetailPath(teamId, tool.id)}>{tool.tool_number}</Link>
                  </div>
                  {tool.name}
                </div>
              </div>
              <div className="w-2" />
              <div className="w-36">
                <div className="field-title">Usage Type</div>
                <div className="mt-1 flex">{usageType && <UsageTypeLabel usageType={usageType.name} />}</div>
              </div>
              <div className="w-2" />
              <div className="w-72">
                <div className="field-title">Tool Instance</div>
                <Select
                  styles={reactSelectStyles}
                  classNamePrefix="react-select"
                  isDisabled={true}
                  placeholder="Select tool instance"
                  aria-label="Select tool instance"
                />
              </div>
            </div>
          </DiffContainer>
        </div>
      </td>
    </tr>
  );
};

export default ReviewToolUsage;

import React, { Fragment, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAttachment from '../hooks/useAttachment';

/**
 *Renders a small image preview of an attachment or file object.
 *
 * @param {Object} props
 * @param props.attachment: A json attachment object. Ignored if `file` is specified.
 * @param [props.file]: A browser File object.
 * @param {'sm' | 'lg'} [props.size]
 * @param {boolean} [props.crop]
 * @param {boolean} [props.canDownload]
 */
const ThumbnailImage = ({ attachment, file, size = 'lg', crop = true, canDownload = true }) => {
  const { downloadAttachment, url, name } = useAttachment({
    attachment,
    file,
    isDiff: true, // TODO split out when review version of this component is created
  });

  const isPending = useMemo(() => Boolean(file), [file]);

  const height = size === 'sm' ? 'h-8' : 'h-32';
  const width = size === 'sm' ? 'w-8' : 'w-32';

  if (!url) {
    return null;
  }

  return (
    <div className={`relative ${crop ? `${height} ${width}` : height} rounded overflow-hidden`}>
      <a className="w-full h-full" target="_blank" rel="noreferrer" href={url}>
        <img src={url} alt={name} className={`w-full h-full ${crop ? 'object-cover' : 'object-scale-down'}`} />
      </a>
      {!isPending && canDownload && (
        <Fragment>
          {/* Radial gradiant shadow background with no click handling */}
          <div className="absolute top-0 right-0 overflow-hidden pointer-events-none">
            <div className="w-20 h-20 rounded-full translate-x-10 -translate-y-10 bg-gradient-radial from-gray-800 to-transparent"></div>
          </div>
          {/* Download button */}
          <button
            className="absolute top-0 right-0 p-1 group"
            onClick={downloadAttachment}
            aria-label={name && `Download ${name}`}
          >
            <FontAwesomeIcon icon="cloud-download-alt" className="text-lg text-white group-hover:text-gray-100" />
          </button>
        </Fragment>
      )}
    </div>
  );
};

export default ThumbnailImage;

const percentUtil = {
  /*
   * Returns the ratio of the given numbers expressed as a percent,
   * undefined if both args are zero, else `Infinity` if `denominator` is 0
   */
  toPercent: (numerator, denominator) => {
    if (numerator === 0 && denominator === 0) {
      return;
    }
    if (denominator === 0) {
      return Infinity;
    }
    const percentage = (100 * numerator) / denominator;
    if (percentage > 0 && percentage < 1) {
      return Math.ceil(percentage);
    }
    return Math.floor(percentage);
  },

  /*
   * Rounds to nearest whole percent
   * E.g., percent = 66.67 returns 67
   */
  toNearestPercent: (percent) => parseFloat(percent.toFixed(0)),
};

export default percentUtil;

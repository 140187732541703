import { useCallback, useMemo } from 'react';
import { Column } from 'react-data-grid';
import { useSelector } from 'react-redux';
import { getIsReviewApproved } from 'shared/lib/reviewUtil';
import { isDraft, isInReview, isPending } from 'shared/lib/procedureUtil';
import renderAction from '../../../components/Home/Renderers/Action';
import renderDateTime from '../../../components/Home/Renderers/DateTime';
import renderProject from '../../../components/Home/Renderers/Project';
import renderRunStatus from '../../../components/Home/Renderers/Status';
import { RowAction } from '../../../components/Home/types';
import { useAuth } from '../../../contexts/AuthContext';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { selectProceduresMetadata } from '../../../contexts/proceduresSlice';
import Grid from '../../../elements/Grid';
import RunNameLinkRenderer from '../../../elements/renderers/RunNameLinkRenderer';
import { PERM } from '../../../lib/auth';
import { procedureEditPath, procedureReviewPath } from '../../../lib/pathUtil';
import procedureUtil from '../../../lib/procedureUtil';
import { ProcedureMetadata } from 'shared/lib/types/views/procedures';
import useProjectFilterHelper from '../../hooks/useProjectFilterHelper';
import { NO_TAGS_ROW_HEIGHT, TAGS_ROW_HIGHT, filterByField, filterBySearchTerm } from '../../../lib/gridUtils';
import { goToProcedure } from '../../libs/testPlanUtil';

const MAIN_VERTICAL_PADDING = 190;

const rowHeight = (row) => (row?.tags?.length ? TAGS_ROW_HIGHT : NO_TAGS_ROW_HEIGHT);

export type PlanProcedureMetadata = ProcedureMetadata & {
  draftAction?: RowAction;
};

export interface PlansGridProps {
  searchTerm?: string;
  selectedProjects?: Set<string | null>;
}

const ActivePlansGrid = ({ searchTerm = '', selectedProjects }: PlansGridProps) => {
  const { auth } = useAuth();
  const { currentTeamId } = useDatabaseServices();

  const proceduresMetadata = useSelector((state) => selectProceduresMetadata(state, currentTeamId));

  const testPlanProcedures = useMemo(() => {
    return Object.entries(proceduresMetadata)
      .map(([, procedure]) => procedure)
      .filter((procedure) => procedure.procedure_type && !procedure.archived);
  }, [proceduresMetadata]);

  const getPendingProcedure = useCallback((procedure: ProcedureMetadata, procedures: ProcedureMetadata[]) => {
    if (isPending(procedure)) {
      return procedure;
    }
    return procedures.find((p) => p.procedure_id === procedure._id);
  }, []);

  const hasReviewVersion = useCallback(
    (procedure: ProcedureMetadata, procedures: ProcedureMetadata[]) => {
      const pending = getPendingProcedure(procedure, procedures);
      if (pending) {
        return isInReview(pending);
      }
      return false;
    },
    [getPendingProcedure]
  );

  const editDraftAction = useCallback(
    (procedureId: string): RowAction => {
      return {
        componentType: 'buttonWithIcon',
        label: 'Edit Draft',
        icon: 'edit',
        to: procedureEditPath(currentTeamId, procedureId),
      };
    },
    [currentTeamId]
  );

  const reviewAction = useCallback(
    (procedureId: string, isReviewApproved: boolean): RowAction => {
      return {
        componentType: 'buttonWithIcon',
        label: 'Review',
        icon: isReviewApproved ? 'clipboard-check' : 'clipboard',
        to: procedureReviewPath(currentTeamId, procedureId),
      };
    },
    [currentTeamId]
  );

  const getDraftAction = useCallback(
    (procedure: ProcedureMetadata, procedures: ProcedureMetadata[]): RowAction | null => {
      const pending = getPendingProcedure(procedure, procedures);
      if (!pending) {
        return null;
      }
      const procedureId = procedureUtil.getProcedureId(pending);
      if (isDraft(pending)) {
        return editDraftAction(procedureId);
      }
      if (isInReview(pending)) {
        const isReviewApproved = getIsReviewApproved(pending);
        return reviewAction(procedureId, isReviewApproved);
      }
      return null;
    },
    [getPendingProcedure, editDraftAction, reviewAction]
  );

  const testPlanMetadata = useMemo(() => {
    return procedureUtil.getMasterProcedureList(testPlanProcedures).map((metadata) => {
      const hasEntityEditPermission = auth.hasPermission(PERM.PROCEDURES_EDIT, metadata.project_id);

      let draftAction;
      if (hasEntityEditPermission || hasReviewVersion(metadata, testPlanProcedures)) {
        // all users can review procedures
        draftAction = getDraftAction(metadata, testPlanProcedures);
      }

      return {
        ...metadata,
        draftAction,
      };
    });
  }, [auth, getDraftAction, hasReviewVersion, testPlanProcedures]);

  const { getProjectName } = useProjectFilterHelper<ProcedureMetadata>(testPlanMetadata);

  const displayProcedures = useMemo(() => {
    const filteredRows = filterBySearchTerm({
      searchTerm,
      allData: testPlanMetadata,
      getStrings: (procedure: ProcedureMetadata) => [
        procedure.name,
        procedure.code,
        getProjectName(procedure.project_id),
        procedure.version,
      ],
    });
    return filterByField({
      rows: filteredRows,
      fieldName: 'project_id',
      values: selectedProjects,
    }) as Array<ProcedureMetadata>;
  }, [searchTerm, testPlanMetadata, selectedProjects, getProjectName]);

  const columns: Array<Column<PlanProcedureMetadata>> = [
    {
      key: 'name',
      name: 'Plan',
      width: '28.9%',
      sortable: true,
      renderCell: ({ row }) =>
        RunNameLinkRenderer({
          code: row.code,
          link: goToProcedure(row, currentTeamId),
          name: row.name,
          tags: row.tags || [],
        }),
    },
    {
      key: 'project_id',
      name: 'Project',
      width: '19%',
      renderCell: ({ row }) => renderProject(getProjectName(row.project_id)),
    },
    {
      key: 'status',
      name: 'Status',
      width: '12%',
      renderCell: ({ row }) => {
        if (row.draftAction) {
          return renderAction(row.draftAction);
        }

        let output = row.state.toString();
        if (output.includes('_')) {
          output = output
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join('');
        }
        return renderRunStatus(output, false);
      },
    },
    {
      key: 'editedAt',
      name: 'Last Updated',
      width: '25%',
      sortable: true,
      renderCell: ({ row }) => renderDateTime(row.editedAt),
    },
    {
      key: 'version',
      name: 'Version',
      width: '15%',
    },
  ];

  return (
    <div className="mt-2">
      <Grid
        columns={columns}
        rows={displayProcedures}
        rowHeight={rowHeight}
        emptyRowMessage="No matching plans found"
        usedVerticalSpace={MAIN_VERTICAL_PADDING}
      />
    </div>
  );
};

export default ActivePlansGrid;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, FormikProps } from 'formik';
import { capitalize } from 'lodash';
import { Dialog } from 'primereact/dialog';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select, { Option } from 'react-select';
import { Attachment } from 'shared/lib/types/attachments';
import { MetadataOptions, ReferenceType, ReferenceTypeEnum } from 'shared/lib/types/postgres/issues';
import { EntityDetailWithValue } from 'shared/lib/types/postgres/util';
import { IssueType, RunIssue } from 'shared/lib/types/views/procedures';
import Button from '../../components/Button';
import FieldSetCustomDetails from '../../components/CustomDetails/FieldSetCustomDetails';
import FileStagingManager, { FileStagingManagerRef } from '../../components/FileStagingManager';
import FlashMessage from '../../components/FlashMessage';
import ProjectsEditSelect from '../../components/ProjectsEditSelect/ProjectsEditSelect';
import TextAreaAutoHeight from '../../components/TextAreaAutoHeight';
import { useAuth } from '../../contexts/AuthContext';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { useSettings } from '../../contexts/SettingsContext';
import apm from '../../lib/apm';
import { ACCESS, PERM } from '../../lib/auth';
import { reactSelectStyles } from '../../lib/styles';
import { ITEM_REFERENCE_TYPE, RUN_REFERENCE_TYPES } from '../constants';
import getSeverityOptions from '../lib/issues';
import { IssueCreate } from '../types';

type IssueFormValues = {
  title?: string;
  severity?: number;
  assignee?: string;
  notes?: string;
  attachments?: Array<Attachment>;
  details?: Array<EntityDetailWithValue>;
  project_id?: string;
};

interface CreateIssueModalProps {
  runId?: string;
  referenceId: string;
  referenceType: ReferenceType;
  onAddIssue: (issue: RunIssue) => Promise<void>;
  isModalShown: boolean;
  onHideModal: () => void;
  projectId?: string;
}

const CreateIssueModal = ({
  runId,
  referenceId,
  referenceType,
  onAddIssue,
  isModalShown,
  onHideModal,
  projectId,
}: CreateIssueModalProps) => {
  const { services } = useDatabaseServices();
  const { users } = useSettings();
  const [metadata, setMetadata] = useState<MetadataOptions | null>(null);
  const [createMessage, setCreateMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [customDetails, setCustomDetails] = useState({
    details: [],
    loading: true,
  });
  const [showModal, setShowModal] = useState(false);
  const [isLoadingMetadata, setIsLoadingMetadata] = useState(false);
  const fileStagingManagerRef = useRef<FileStagingManagerRef>(null);
  const formRef = useRef<FormikProps<IssueFormValues>>(null);
  const { mixpanel } = useMixpanel();
  const { auth } = useAuth();

  // isModalShown prop is used to drive the internal flag showModal to allow rendering FlashMessage
  useEffect(() => {
    if (typeof isModalShown !== 'boolean') {
      return;
    }
    if (isModalShown) {
      setIsLoadingMetadata(true);
      services.ncr
        .getMetadataOptions()
        .then((metadata) => {
          setMetadata(metadata);
        })
        .catch(() => {
          setMetadata(null);
        })
        .finally(() => {
          setIsLoadingMetadata(false);
        });
    }
    setShowModal(isModalShown);
  }, [isModalShown, services.ncr]);

  /*
   * If user has workspace none, but has project edit permissions:
   * need to set the ititial project in project select dropdown + prevent clearing it
   */
  const initialProjectId = useMemo(() => {
    if (projectId) {
      return projectId;
    }
    // user is at least workspace operator
    if (auth.hasPermission(PERM.ISSUE_EDIT)) {
      return null;
    }
    const projectsWithOperatorPermission = auth.projectsWithOperatorPermission();
    if (projectsWithOperatorPermission && projectsWithOperatorPermission.length > 0) {
      return projectsWithOperatorPermission[0];
    }
    return null;
  }, [auth, projectId]);

  useEffect(() => {
    if (!services.issueDetails || !isModalShown) {
      return;
    }
    services.issueDetails.listIssueDetails().then((details) => {
      setCustomDetails({
        details,
        loading: false,
      });
    });
  }, [services.issueDetails, isModalShown]);

  const onValidate = (values: IssueFormValues) => {
    const errors: IssueFormValues = {};
    if (!values.title || values.title.trim() === '') {
      errors.title = 'Required';
    }
    return errors;
  };

  const onSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setCreateMessage('Creating Issue');

      const newIssue: IssueCreate = {
        runId,
        referenceId,
        referenceType,
        severityId: values.severity,
        assignee: values.assignee,
        title: values.title,
        notes: values.notes?.trim() || undefined,
        details: values.details,
        projectId: values.project_id,
      };

      if (runId) {
        newIssue.runId = runId;
      }

      if (fileStagingManagerRef.current) {
        try {
          const newAttachments = await fileStagingManagerRef.current.uploadFiles();
          if (newAttachments && newAttachments.length > 0) {
            newIssue.attachments = newAttachments;
          }
        } catch (error) {
          setErrorMessage('Uploading of Attachments Failed');
        }
      }

      onHideModal();

      services.ncr
        .createIssue(newIssue)
        .then((response) => {
          return onAddIssue({
            id: response.id,
            text: values.title,
            url: runId ?? referenceId,
            type: IssueType.Internal,
          });
        })
        .then(() => {
          setSuccessMessage('Issue Created!');
          if (mixpanel) {
            mixpanel.track('Issue created', { Scope: referenceType });
          }
        })
        .catch((err) => {
          apm.captureError(err);
          setErrorMessage('Failed to Create Issue');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [onHideModal, runId, referenceId, referenceType, services.ncr, onAddIssue, mixpanel]
  );

  // shared with issue detail.  consolidate them
  const severityList = getSeverityOptions(metadata?.severity);

  const toOption = (user) => {
    return {
      value: user,
      label: user,
    };
  };

  const userList = useMemo<Array<Option>>(() => {
    if (!users) {
      return [];
    }
    return Object.keys(users.users).map(toOption);
  }, [users]);

  const onCreateClick = useCallback(() => {
    formRef.current?.handleSubmit();
  }, []);

  const getFooter = useCallback(
    (isSubmitting: boolean) => {
      return (
        <div className="mt-2 sm:flex sm:flex-row-reverse">
          <Button type="primary" onClick={onCreateClick} isDisabled={isSubmitting}>
            Create Issue
          </Button>
          <Button type="secondary" onClick={onHideModal}>
            Cancel
          </Button>
        </div>
      );
    },
    [onHideModal, onCreateClick]
  );

  const topOffset = RUN_REFERENCE_TYPES.includes(referenceType) ? 10 : 0;

  if (customDetails.loading) {
    return null;
  }

  return (
    <>
      {/* "toast" messages upon successful issue creation or failure */}
      <FlashMessage topOffset={topOffset} message={createMessage} messageUpdater={setCreateMessage} type="info" />
      <FlashMessage topOffset={topOffset} message={successMessage} messageUpdater={setSuccessMessage} />
      <FlashMessage topOffset={topOffset} message={errorMessage} messageUpdater={setErrorMessage} type="warning" />
      {showModal && (
        <Formik
          innerRef={formRef}
          initialValues={{
            title: '',
            runId,
            severity: undefined,
            assignee: undefined,
            attachments: [],
            details: customDetails.details,
            project_id: initialProjectId,
          }}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur={false}
          validate={onValidate}
          enableReinitialize
        >
          {({ errors, values, setFieldValue, isSubmitting, handleChange }) => (
            <Dialog
              header={`Create New ${
                referenceType !== ReferenceTypeEnum.Unlinked ? capitalize(referenceType) : ''
              } Issue`}
              onHide={onHideModal}
              visible={showModal}
              closable={false}
              className="w-3/4 md:w-1/2"
              footer={() => getFooter(isSubmitting)}
            >
              <Form aria-label="Create Issue">
                <div className="flex flex-col gap-2 w-full mt-3">
                  <div className="flex flex-row flex-grow gap-x-2 justify-between w-full">
                    <div className="flex flex-col w-5/6">
                      <label className="text-xs text-gray-500">Name*</label>
                      <Field
                        aria-label="Name"
                        maxLength={255}
                        name="title"
                        type="text"
                        className="text-sm border-1 border-gray-400 rounded"
                      />
                      {errors.title && <div className="text-xs text-red-700">{errors.title}</div>}
                    </div>
                    <div className="flex flex-col w-48 max-w-prose">
                      <label className="text-xs text-gray-500">Project</label>
                      <Field
                        aria-label="Project"
                        name="Project"
                        className="h-full border border-gray-400 rounded"
                        component={ProjectsEditSelect}
                        value={values.project_id}
                        isClearable={auth.hasPermission(PERM.ISSUE_EDIT)}
                        onUpdateProject={(updatedProjectId?: string) => {
                          setFieldValue('project_id', updatedProjectId);
                        }}
                        isDisabled={!!projectId}
                        projectAccessLevel={ACCESS.OPERATOR}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-4">
                    <div className="flex-col w-1/2">
                      <label className="text-xs text-gray-500">Severity</label>
                      <Select
                        styles={reactSelectStyles}
                        classNamePrefix="react-select"
                        className="text-sm"
                        aria-label="Severity"
                        menuPosition="fixed"
                        placeholder="(Select)"
                        options={severityList}
                        onChange={(newSeverity) => setFieldValue('severity', newSeverity.value)}
                        value={severityList?.find((severity) => severity.value === values.severity)}
                        isLoading={isLoadingMetadata}
                      />
                      {errors.severity && <div className="text-sm text-red-700 mt-1">{errors.severity}</div>}
                      {RUN_REFERENCE_TYPES.includes(referenceType) &&
                        metadata?.severity.find((s) => s.id === values.severity)?.pausesRun && (
                          <div className="flex items-center">
                            <FontAwesomeIcon className="mr-2" icon="exclamation-circle" />
                            <span>{`Issues with a ${severityList
                              .find((s) => s.value === values.severity)
                              ?.label.toLowerCase()} severity will pause the run.`}</span>
                          </div>
                        )}
                      {referenceType === ITEM_REFERENCE_TYPE && (
                        <div className="flex items-center">
                          <FontAwesomeIcon className="mr-2" icon="exclamation-circle" />
                          <span>Issues with a critical severity will prevent selecting this item.</span>
                        </div>
                      )}
                    </div>
                    <div className="flex-col w-1/2">
                      <label className="text-xs text-gray-500">Assignee</label>
                      <Select
                        styles={reactSelectStyles}
                        classNamePrefix="react-select"
                        className="text-sm"
                        aria-label="Assignee"
                        menuPosition="fixed"
                        placeholder="(Select)"
                        options={userList}
                        onChange={(newAssignee) => setFieldValue('assignee', newAssignee.value)}
                        value={userList?.find((user) => user.value === values.assignee)}
                      />
                      {errors.assignee && <div className="text-sm text-red-700 mt-1">{errors.assignee}</div>}
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <label className="text-xs text-gray-500">Notes</label>
                    <Field id="notes" name="notes">
                      {({ field }) => <TextAreaAutoHeight defaultRows={4} aria-label="Notes" {...field} />}
                    </Field>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-xs text-gray-500">Attachments</label>
                    <FileStagingManager initialAttachments={[]} ref={fileStagingManagerRef} source="issues" />
                  </div>
                  <FieldSetCustomDetails details={values.details || []} onChange={handleChange} />
                </div>
              </Form>
            </Dialog>
          )}
        </Formik>
      )}
    </>
  );
};

export default CreateIssueModal;

import sharedDiffUtil from 'shared/lib/diffUtil';
import ReviewPartTopLabel from './ReviewPartTopLabel';

const ReviewPartLabel = ({ part, teamId }) => {
  const nameLabel = part ? sharedDiffUtil.getDiffValue(part, 'name', 'new') : undefined;

  return (
    <div className="p-1">
      <ReviewPartTopLabel teamId={teamId} part={part} />
      <div className="text-base">{nameLabel}</div>
    </div>
  );
};

export default ReviewPartLabel;

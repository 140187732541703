import React, { useMemo, useCallback, useState } from 'react';
import { ActionMeta } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldSetSignoffSelect from '../FieldSetSignoffSelect';
import cloneDeep from 'lodash.clonedeep';
import reviewUtil from '../../lib/reviewUtil';
import procedureUtil from '../../lib/procedureUtil';
import { useSettings } from '../../contexts/SettingsContext';

const EditReviewer = ({
  reviewerIndex,
  reviewer,
  onRemoveReviewer,
  reviewerOptions,
  onReviewerUpdated,
  hasApproved,
  disabled = false,
  canAddUserIdToOperatorRoleSignoff = false,
  changeIdOnReviewerUpdate = true,
}) => {
  const { operatorRoleToUsersMap } = useSettings();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectedOperatorRole, setSelectedOperatorRole] = useState(null);

  const isDisabled = useMemo(() => disabled || reviewer.is_required, [disabled, reviewer.is_required]);

  const showUserIdMenu = useCallback(
    (operatorRole) => {
      setSelectedOperatorRole(operatorRole);

      if (!isDisabled || operatorRole) {
        setMenuIsOpen(true);
      }
    },
    [isDisabled]
  );

  const hideMenu = useCallback(() => {
    setSelectedOperatorRole(null);
    setMenuIsOpen(false);
  }, []);

  const onChange = useCallback(
    (options, actionMeta: ActionMeta) => {
      // Prevent any change if disabled and there is no selected operator role
      if (isDisabled && !selectedOperatorRole) {
        return;
      }

      if (isDisabled && (actionMeta.action === 'remove-value' || actionMeta.action === 'pop-value')) {
        return;
      }
      if (options.length === 0) {
        onRemoveReviewer(reviewerIndex);
        return;
      }

      const updated = cloneDeep(reviewer);
      if (actionMeta.action === 'select-option' && selectedOperatorRole) {
        const updatedReviewerId = updated.reviewer_ids.find(
          (rId) => rId.type === 'operator_role' && rId.value === selectedOperatorRole
        );
        if (actionMeta.option.value === null) {
          delete updatedReviewerId.user_ids;
        } else if (actionMeta.option.type === 'user_id') {
          updatedReviewerId.user_ids = [actionMeta.option.value];
        }
        setSelectedOperatorRole(null);
      } else {
        updated.reviewer_ids = options.map((o) => ({
          type: o.type,
          value: o.value,
          user_ids: o.user_ids,
        }));
      }

      /*
       * Changing the reviewer content also causes the unique reviewer id to change
       * in order to reset any existing approvals.  This prevents an existing
       * approval from being replaced by a new user/role
       */
      if (changeIdOnReviewerUpdate) {
        updated.id = procedureUtil.generateReviewerId();
      }
      onReviewerUpdated(updated, reviewerIndex);
    },
    [
      isDisabled,
      selectedOperatorRole,
      reviewer,
      onReviewerUpdated,
      reviewerIndex,
      onRemoveReviewer,
      changeIdOnReviewerUpdate,
    ]
  );

  const onRemove = () => {
    onRemoveReviewer(reviewerIndex);
  };

  const reviewerSelectOptions = useMemo(() => {
    const remainingOptions = reviewUtil.getReviewerSelectOptions(reviewerOptions);
    if (!selectedOperatorRole) {
      return remainingOptions;
    }

    const userForOperatorRoleOptions: Array<{ label: string; value: string | null }> = remainingOptions.filter(
      (option) => option.type === 'user_id' && operatorRoleToUsersMap[selectedOperatorRole]?.has(option.value)
    );

    // Add to the beginning of the menu the option to use any operator.
    userForOperatorRoleOptions.unshift({
      label: `Any ${selectedOperatorRole} user`,
      value: null,
    });
    return userForOperatorRoleOptions;
  }, [operatorRoleToUsersMap, reviewerOptions, selectedOperatorRole]);

  const reviewerSelectValues = useMemo(() => {
    return reviewUtil.getReviewerSelectOptions(reviewer.reviewer_ids);
  }, [reviewer.reviewer_ids]);

  return (
    <div className="relative group text-sm font-medium">
      <FieldSetSignoffSelect
        options={reviewerSelectOptions}
        opacity={0.8}
        value={reviewerSelectValues}
        onChange={onChange}
        ariaLabel="Add Reviewer"
        placeholder="Add Reviewer"
        showControl={false}
        borderWidth={1}
        hasApproved={hasApproved}
        menuIsOpen={Boolean(menuIsOpen && (!isDisabled || (isDisabled && selectedOperatorRole)))}
        showUserIdMenu={showUserIdMenu}
        hideMenu={hideMenu}
        isDisabled={isDisabled}
        canAddUserIdToOperatorRoleSignoff={canAddUserIdToOperatorRoleSignoff}
      />
      {!isDisabled && (
        <>
          <FontAwesomeIcon
            icon="circle"
            className="absolute -top-1.5 -right-1.5 opacity-0 group-hover:opacity-100 group-hover:text-white"
          />
          <FontAwesomeIcon
            aria-label="Remove Reviewer"
            onClick={onRemove}
            icon="times-circle"
            className="absolute -top-1.5 -right-1.5 text-gray-400 hover:text-gray-500 opacity-0 group-hover:opacity-100"
          />
        </>
      )}
    </div>
  );
};

export default React.memo(EditReviewer);

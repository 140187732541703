import React from 'react';
import { DateTime, Interval } from 'luxon';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
import CalEvent from './Event';

interface DayProps {
  date: Interval;
  events: Array<Event>;
  isDisabled: boolean;
}

const Day = ({ date, events, isDisabled }: DayProps) => {
  const isToday = date.contains(DateTime.utc());

  if (isDisabled) {
    return <div className="bg-gray-200" />;
  }

  return (
    <div className={`flex flex-col p-1 ${isToday ? 'bg-blue-100' : 'bg-gray-50'}`}>
      <div className={`${isToday ? 'text-gray-600' : 'text-gray-400'}`}>{date.start.toFormat('DD')}</div>
      {events.map((event, idx) => (
        <CalEvent key={idx} event={event} />
      ))}
      {events.length === 0 && <div className="h-14" />}
    </div>
  );
};

export default Day;

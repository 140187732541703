import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as TelescopeSVG } from '../images/telescope.svg';

const DEFAULT_NOT_FOUND_TITLE_STR = 'Sorry, the document you have requested cannot be found.';
const DEFAULT_NOT_FOUND_MESSAGE_STR = 'Please make sure you are in the correct workspace and the document exists.';

interface NotFoundProps {
  title?: string;
  message?: string;
}

const NotFound = React.memo(({ title, message }: NotFoundProps) => {
  // Use default strings if title or message are not passed in.
  const displayTitle = title || DEFAULT_NOT_FOUND_TITLE_STR;
  const displayMessage = message || DEFAULT_NOT_FOUND_MESSAGE_STR;

  return (
    <div className="flex flex-col items-center justify-center space-y-6 my-2 absolute top-0 bottom-0 left-0 right-0">
      <div>
        <TelescopeSVG className="text-blue-500" height="200" width="200" />
      </div>
      <div className="flex flex-col items-center text-xl">
        <div className="text-lg">{displayTitle}</div>
        <div className="text-sm">{displayMessage}</div>
      </div>
      <div>
        <Link
          className="text-sm font-semibold uppercase bg-white py-2 px-4 text-blue-500 border-2 border-blue-500 rounded"
          to="/" // Redirect to homepage.
        >
          <FontAwesomeIcon className="mr-2" icon="home" />
          Back to home
        </Link>
      </div>
    </div>
  );
});

export default NotFound;

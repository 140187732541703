import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ExpandCollapseButtonsProps {
  buttonClasses: string;
  onExpand: () => void;
  onCollapse: () => void;
  isDisabled: boolean;
  showLabel?: boolean;
  labelText?: string;
}

const ExpandCollapseButtons = ({
  buttonClasses,
  onExpand,
  onCollapse,
  isDisabled,
  showLabel = false,
  labelText = 'Sections',
}: ExpandCollapseButtonsProps) => {
  return (
    <>
      <button
        className={buttonClasses}
        type="button"
        title={`Expand ${labelText}`}
        disabled={isDisabled}
        onClick={onExpand}
      >
        <FontAwesomeIcon fixedWidth={true} icon="expand-alt" />
        {showLabel && <div className="visible ml-2 lg:hidden">Expand {labelText}</div>}
      </button>
      <button
        className={buttonClasses}
        type="button"
        title={`Collapse ${labelText}`}
        disabled={isDisabled}
        onClick={onCollapse}
      >
        <FontAwesomeIcon fixedWidth={true} icon="compress-alt" />
        {showLabel && <div className="visible ml-2 lg:hidden">Collapse {labelText}</div>}
      </button>
    </>
  );
};

export default ExpandCollapseButtons;

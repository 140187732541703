import {
  RunFieldInputRecorded,
  TimestampValue,
  TimestampValueV2,
} from './types/views/procedures';
import { DateTime } from 'luxon';

export const isOldTimestampData = (
  recorded: RunFieldInputRecorded<TimestampValue>
) => {
  return recorded?.value && typeof recorded.value === 'string';
};

export const createDateTime = ({
  timestamp,
  zone = 'UTC',
  year,
  month,
  day,
  hour,
  minute,
  second,
}: {
  timestamp?: string;
  zone?: string;
  year?: number;
  month?: number;
  day?: number;
  hour?: number;
  minute?: number;
  second?: number;
}): DateTime => {
  /*
   * If a timestamp is passed in, convert it to a Datetime object with a
   * timezone offset given in the timestamp, then set the timezone without
   *  changing the underlying date or time values.
   *
   * If timestamp is falsy, use "now" in the passed-in zone.
   */
  const dt = timestamp
    ? DateTime.fromISO(timestamp, { setZone: true }).setZone(zone, {
        keepLocalTime: true,
      })
    : DateTime.local().setZone(zone, { keepLocalTime: false });

  return dt.set({
    year,
    month,
    day,
    hour,
    minute,
    second,
    millisecond: 0,
  });
};

export const createIsoString = ({
  timestamp,
  zone = 'UTC',
  year,
  month,
  day,
  hour,
  minute,
  second,
}: {
  timestamp?: string | undefined;
  zone?: string | undefined;
  year?: number;
  month?: number;
  day?: number;
  hour?: number;
  minute?: number;
  second?: number;
}): string | null => {
  const dt = createDateTime({
    timestamp,
    zone,
    year,
    month,
    day,
    hour,
    minute,
    second,
  });
  return dt.toISO();
};

/**
 * Get the minimal date/time stamp.
 *
 * @returns
 *  If date and time: 'yyyy-MM-ddTHH:mm:ss[Z| ZZ]'
 *  If date only: 'yyyy-MM-dd'
 *  If time only: 'HH:mm:ss'
 */
export const toDateTimeStamp = ({
  date,
  time,
  zone,
}: {
  date?: string | null;
  time?: string | null;
  zone?: string;
}): string | null => {
  if (date && time) {
    const dateDT = date ? DateTime.fromISO(date, { zone }) : null;
    const timeDT = time ? DateTime.fromISO(time, { zone }) : null;
    return createIsoString({
      timestamp: undefined,
      zone,
      year: dateDT?.year,
      month: dateDT?.month,
      day: dateDT?.day,
      hour: timeDT?.hour,
      minute: timeDT?.minute,
      second: timeDT?.second,
    });
  }

  if (date && !time) {
    return date;
  }

  if (!date && time) {
    return time;
  }

  return null;
};

export const getTimestampFromRecorded = (
  recorded?: RunFieldInputRecorded<TimestampValue>
): { zone?: string; timestamp: string | null } => {
  if (!recorded?.value) {
    return {
      timestamp: null,
    };
  }
  return isOldTimestampData(recorded)
    ? {
        timestamp: (recorded as RunFieldInputRecorded<string>).value,
        zone: 'UTC',
      }
    : {
        timestamp: toDateTimeStamp({
          ...(recorded as RunFieldInputRecorded<TimestampValueV2>)?.value,
        }),
        zone:
          (recorded as RunFieldInputRecorded<TimestampValueV2>)?.value?.zone ??
          'UTC',
      };
};

export type Milliseconds = number;
export const MILLI_SECONDS_PER_SECOND = 1000;

export const MAX_LENGTH = Object.freeze({
  sm: 12,
  md: 30,
  lg: 42,
});

export const MAX_LENGTH_MOBILE = Object.freeze({
  sm: 9,
  md: 9,
  lg: 15,
});

import {
  StepBlock,
  FieldInputBlock,
  FieldInputExternalSearchBlock,
} from 'shared/lib/types/views/procedures';

const referenceUtil = {
  isReferenceEnabled: (
    contentBlock: StepBlock,
    supportedReferenceTypes: string[]
  ): boolean => {
    if (contentBlock.type !== 'input') {
      return false;
    }
    if (
      !supportedReferenceTypes.includes(
        (contentBlock as FieldInputBlock).inputType
      )
    ) {
      return false;
    }
    if (!(contentBlock as FieldInputBlock).name) {
      return false;
    }
    return true;
  },

  _hasSubReferences: (contentBlock: FieldInputBlock): boolean => {
    return contentBlock.inputType === 'external_search';
  },

  getAllSubReferences: (contentBlock: FieldInputBlock): Array<string> => {
    let allSubReferences = [] as string[];
    if (referenceUtil._hasSubReferences(contentBlock)) {
      // since external search is currently the only type with subreferences, we can simply cast for now
      allSubReferences = (contentBlock as FieldInputExternalSearchBlock)
        .external_search_type.filter_options;
    }
    return allSubReferences;
  },
};

export default referenceUtil;

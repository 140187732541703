import { FieldInputCustomListBlock } from 'shared/lib/types/views/procedures';
import SelectDisplay from './SelectDisplay';

interface CustomListDisplayProps {
  block: FieldInputCustomListBlock;
  referenceId?: string;
  referenceFieldIndex?: number;
}

const CustomListDisplay = ({ block, referenceId, referenceFieldIndex }: CustomListDisplayProps) => {
  return (
    <SelectDisplay
      block={block}
      values={block.options}
      referenceId={referenceId}
      referenceFieldIndex={referenceFieldIndex}
    />
  );
};

export default CustomListDisplay;

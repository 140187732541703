import { useState, useCallback, useEffect } from 'react';
import { FilterOption } from '../../components/lib/SearchFilter';
import { useSettings } from '../../contexts/SettingsContext';
import projectUtil from '../../lib/projectUtil';
import { filterByField } from '../../lib/gridUtils';

const noneOption = {
  label: 'None',
  id: null,
};

interface ProjectRow {
  project_id?: string;
}

interface useProjectFilterHelperReturns<RT> {
  projectOptions: Array<FilterOption<string>>;
  selectedProjects: Set<string | null>;
  addProjectFilter: (projectId: string | null) => void;
  removeProjectFilter: (projectId: string | null) => void;
  addAllProjectFilters: () => void;
  resetAllProjectFilters: () => void;
  filterRowsByProject: (rows: Array<RT>) => Array<RT>;
  getProjectName: (projectId?: string) => string | undefined;
}

const useProjectFilterHelper = <RT extends ProjectRow>(
  rows?: Array<RT>
): useProjectFilterHelperReturns<RT> => {
  const [projectOptions, setProjectOptions] = useState<
    Array<FilterOption<string>>
  >([]);
  const [selectedProjects, setSelectedProjects] = useState<Set<string | null>>(
    new Set()
  );
  const { projects } = useSettings();

  const getProjectName = useCallback(
    (projectId?: string) => {
      return projectUtil.getProjectName(projects, projectId);
    },
    [projects]
  );

  useEffect(() => {
    if (!rows?.length) {
      setProjectOptions(
        Object.values(projects?.projects ?? {}).map((project) => ({
          id: project.id,
          label: project.name,
        }))
      );
      return;
    }
    const projectIdMap = new Map<string, FilterOption<string>>();
    for (const row of rows) {
      const projectName = getProjectName(row.project_id);
      if (projectName && row.project_id) {
        projectIdMap.set(row.project_id, {
          label: projectName,
          id: row.project_id,
        });
      }
    }
    setProjectOptions([noneOption, ...Array.from(projectIdMap.values())]);
  }, [rows, getProjectName, projects?.projects]);

  const addProjectFilter = (projectId: string | null) => {
    setSelectedProjects((projects) => new Set(projects.add(projectId)));
  };

  const removeProjectFilter = (projectId: string | null) => {
    setSelectedProjects((projects) => {
      const newProjects = new Set(projects);
      newProjects.delete(projectId);
      return newProjects;
    });
  };

  const addAllProjectFilters = useCallback(() => {
    setSelectedProjects(new Set(projectOptions.map((option) => option.id)));
  }, [projectOptions]);

  const resetAllProjectFilters = () => {
    setSelectedProjects(new Set());
  };

  const filterRowsByProject = useCallback(
    (rows: Array<RT>) => {
      return filterByField({
        rows,
        fieldName: 'project_id',
        values: selectedProjects,
      });
    },
    [selectedProjects]
  );

  return {
    projectOptions,
    selectedProjects,
    addProjectFilter,
    removeProjectFilter,
    addAllProjectFilters,
    resetAllProjectFilters,
    filterRowsByProject,
    getProjectName,
  };
};

export default useProjectFilterHelper;

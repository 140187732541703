import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import superlogin from '../api/superlogin';
import { useMixpanel } from '../contexts/MixpanelContext';
import { LocationState } from './types';

const ForgotPasswordSchema = Yup.object().shape({ email: Yup.string().email().required('Required') });

const ForgotPassword = () => {
  const location = useLocation<LocationState>();
  const [flash, setFlash] = useState((location.state && location.state.flash) || '');
  const [globalErrors, setGlobalErrors] = useState(null);
  const { mixpanel } = useMixpanel();

  const forgotPassword = (email) => {
    setGlobalErrors(null);
    return superlogin
      .forgotPassword(email)
      .then(() => {
        if (mixpanel) {
          mixpanel.track('Forgot Password');
        }
        setFlash('If you provided a valid email address, please check your email for a reset link.');
      })
      .catch((error) => {
        if (error && error.message) {
          setGlobalErrors(error.message);
        } else if (error && error.error) {
          setGlobalErrors(error.error);
        } else {
          setGlobalErrors(error);
        }
      });
  };

  return (
    <div className="container mx-auto px-12 py-4">
      <div className="items-start max-w-md mx-auto">
        {flash && (
          <div className="my-4 py-2 px-3 bg-green-100 border rounded border-black border-opacity-20 text-green-700">
            {flash}
          </div>
        )}
        <h1 className="mb-4">Reset your password</h1>
        <p className="mb-4">
          Enter the email address associated with your account, and we'll send you a link to reset your password.
        </p>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={ForgotPasswordSchema}
          onSubmit={async (values) => forgotPassword(values.email)}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <div className="flex flex-col">
                <label htmlFor="email">Email address</label>
                <Field
                  id="email"
                  name="email"
                  placeholder="Email address"
                  type="email"
                  className="w-full border-1 border-gray-400 rounded "
                />
                {errors.email && touched.email ? <div className="text-red-700">{errors.email}</div> : null}
                {globalErrors && <p className="self-center mt-2 text-red-700">{globalErrors}</p>}
                <button className="btn self-center mt-4" type="submit" disabled={isSubmitting}>
                  Request Reset Link
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="flex my-3 justify-center">
          <Link to="/login" className="link">
            Return to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

import React, { Fragment, useMemo } from 'react';
import { Field } from 'formik';
import { Duration } from 'luxon';
import { defaultDurationTimeUnits, durationToTimeUnits } from '../lib/event';
import { cloneDeep } from 'lodash';

const UNITS = ['days', 'hours', 'minutes', 'seconds'];

interface DurationGeneratorProps {
  value: Duration | null;
  setDraftDuration: (duration: Duration) => void;
}

const DurationGenerator = ({ value, setDraftDuration }: DurationGeneratorProps) => {
  const durations = useMemo(() => (value ? durationToTimeUnits(value) : defaultDurationTimeUnits()), [value]);

  const updateDuration = (unit: string, newValue: string) => {
    const updatedDurations = cloneDeep(durations);
    updatedDurations[unit] = Number(newValue);

    setDraftDuration(Duration.fromObject(updatedDurations));
  };

  return (
    <div className="flex flex-row items-center relative">
      {UNITS.map((unit, index) => (
        <Fragment key={unit}>
          <Field
            name={`durationValue${index}`}
            defaultValue={durations[unit]}
            aria-label={`Duration Value ${index}`}
            component="input"
            type="number"
            min="0"
            className={`${
              index !== 0 ? 'ml-4 ' : ''
            }border-1 border-gray-400 p-2 w-[8rem] rounded mr-2 disabled:bg-gray-100`}
            onChange={(event) => {
              const inputValue = event.target.value;
              if (inputValue >= 0) {
                updateDuration(unit, inputValue);
              }
            }}
          />
          <span>
            {unit}
            {index !== UNITS.length - 1 ? ', ' : ''}
          </span>
        </Fragment>
      ))}
    </div>
  );
};

export default DurationGenerator;

import React, { useMemo } from 'react';
import FieldSetProcedureLink from '../../../components/FieldSetProcedureLink';
import { useDatabaseServices } from '../../../contexts/DatabaseContext';
import { selectProceduresNoDraftsForReleased } from '../../../contexts/proceduresSlice';
import { useSelector } from 'react-redux';
import procedureUtil from '../../../lib/procedureUtil';
import { ProcedureContextProvider } from '../../../contexts/ProcedureContext';

const ProcedureSelect = ({ form, procedureIds, isDisabled, filterUnreleased = false }) => {
  const { currentTeamId }: { currentTeamId: string } = useDatabaseServices();
  const procedures = useSelector((state) => selectProceduresNoDraftsForReleased(state, currentTeamId));

  const handleChange = (field, value) => {
    if (field === 'procedures[0]') {
      // If currently selected procedure removed, set explicitly to null
      form.setFieldValue('procedure_id', value.procedure ?? null);
      form.setFieldValue('procedure_section_id', null);

      if (value.procedure && !form.values.name) {
        const selectedProcedure = procedures[value.procedure];
        if (selectedProcedure) {
          const title = procedureUtil.getProcedureTitle(selectedProcedure.code, selectedProcedure.name);
          form.setFieldValue('name', title);
        }
      }
    } else {
      // Set event section id if selected or null if "All Sections" selected
      form.setFieldValue('procedure_section_id', value ?? null);
    }
  };

  // Used as a filler prop for ProcedureContextProvider
  const procedureShell = useMemo(() => {
    const shell = {
      _id: '',
      code: '',
      name: '',
      description: '',
      sections: [{ id: '', name: '', steps: [] }],
      starttime: '',
      project_id: '',
    };
    return shell;
  }, []);

  const isSelectDisabled = isDisabled || form.isSubmitting;

  return (
    <ProcedureContextProvider
      procedure={procedureShell}
      scrollTo={undefined}
      stepOptions={{
        displayAddReferences: false,
        canRemoveSignoffs: false,
        filterProceduresByProject: false,
      }}
    >
      <FieldSetProcedureLink
        content={procedureIds}
        contentIndex={0}
        contentErrors={{}}
        path="procedures"
        setFieldValue={handleChange}
        showLabels={false}
        isDisabled={isSelectDisabled}
        isClearable={true}
        filterUnreleased={filterUnreleased}
      />
    </ProcedureContextProvider>
  );
};

export default ProcedureSelect;

import { useCallback, useEffect, useRef, useState } from 'react';
import sharedDiffUtil from 'shared/lib/diffUtil';
import { AttachmentResponse } from '../attachments/types';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { DatabaseServices } from '../contexts/proceduresSlice';
import attachmentUtil from '../lib/attachmentUtil';
import { downloadBlob } from '../lib/fileUtil';
import { AttachmentMetadata } from '../lib/views/attachments';

export type AttachmentHook = {
  url?: string;
  name?: string;
  contentType?: string;
  metadata?: AttachmentMetadata;
  error?: Error;
};

export type UseAttachmentReturns = AttachmentHook & {
  downloadAttachment: () => void;
};

export type Attachment = {
  attachment_id: string;
  name?: string;
  content_type?: string;
};

export interface UseAttachmentProps {
  attachment?: Attachment;
  file?: File;
  isDiff?: boolean;
}

const useAttachment = ({
  attachment,
  file,
  isDiff = false,
}: UseAttachmentProps): UseAttachmentReturns => {
  const isMounted = useRef(true);
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const [state, setState] = useState<AttachmentHook>({
    url: undefined,
    name: undefined,
    contentType: undefined,
    metadata: undefined,
    error: undefined,
  });

  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );

  // Load attachment data and object blob.
  useEffect(() => {
    const attachmentName = attachment
      ? isDiff
        ? sharedDiffUtil.getDiffValue<string>(attachment, 'name', 'new')
        : attachment.name
      : '';
    const attachmentId = attachment
      ? isDiff
        ? sharedDiffUtil.getDiffValue<string>(
            attachment,
            'attachment_id',
            'new'
          )
        : attachment.attachment_id
      : undefined;
    let objectUrl: string;
    // Show selected file or current attachment object
    if (file && typeof file === 'object') {
      objectUrl = URL.createObjectURL(file);
      setState({
        url: objectUrl,
        name: file.name,
        contentType: file.type,
      });
    } else if (attachmentId) {
      services.attachments
        .getAttachment(attachmentId)
        .then((attachmentResponse: AttachmentResponse) => {
          if (!isMounted.current) {
            return;
          }
          objectUrl = attachmentUtil.createObjectURL(attachmentResponse.blob);
          setState({
            url: objectUrl,
            name: attachmentName,
            contentType: attachment
              ? isDiff
                ? sharedDiffUtil.getDiffValue(attachment, 'content_type', 'new')
                : attachment.content_type
              : undefined,
          });
        })
        .catch((error: Error) => {
          // This can catch either metadata or attachment file load errors
          setState({ error });
        });
    }
    return () => {
      if (objectUrl) {
        attachmentUtil.revokeObjectURL(objectUrl);
      }
    };
  }, [services.attachments, attachment, file, isDiff]);

  const downloadAttachment = useCallback(() => {
    if (!attachment) {
      return;
    }
    const attachmentName = isDiff
      ? sharedDiffUtil.getDiffValue<string>(attachment, 'name', 'new')
      : attachment.name;
    services.attachments
      .getAttachment(
        isDiff
          ? sharedDiffUtil.getDiffValue(attachment, 'attachment_id', 'new')
          : attachment.attachment_id
      )
      .then((resp: AttachmentResponse) =>
        downloadBlob(resp.blob, attachmentName)
      )
      .catch((err) => {
        /* noop */
      });
  }, [services.attachments, attachment, isDiff]);

  return {
    downloadAttachment,
    ...state,
  };
};

export default useAttachment;

import React from 'react';

export interface RightSideProps {
  children?: React.ReactNode;
  visible?: boolean;
}

// Note: visible is passed in as a property so that <LeftSide> and <RightSide> can be at the same indentation level
const RightSide = ({ children, visible = true }: RightSideProps) => {
  return visible ? <div className="flex flex-row items-center justify-end">{children}</div> : null;
};

export default React.memo(RightSide);

import React from 'react';
import TextLinkify from '../components/TextLinkify';
import { useRunContext } from '../contexts/RunContext';

export default function RunDescription() {
  const { run, isIntroductionVisible } = useRunContext();

  if (!isIntroductionVisible()) {
    return null;
  }
  return (
    <div>
      <TextLinkify>
        <span className="whitespace-pre-line break-words">{run.description}</span>
      </TextLinkify>
    </div>
  );
}

import React, { useCallback } from 'react';
import MultiEvent from '../../../lib/MultiEvent';
import Tooltip from '../../../../elements/Tooltip';
import { useGanttContext } from '../../../components/Gantt/GanttContext';
import { Interval } from 'luxon';

interface MultiEventBarProps {
  multiEvent: MultiEvent;
  expandSwimlane: () => void;
}

const MultiEventBar = ({ multiEvent, expandSwimlane }: MultiEventBarProps) => {
  const { intervalWidth } = useGanttContext();
  const width = intervalWidth(Interval.fromDateTimes(multiEvent.start, multiEvent.end));

  const onClick = useCallback(() => {
    expandSwimlane();
  }, [expandSwimlane]);

  const label = `[${multiEvent.events.length} Events]`;

  return (
    <Tooltip content={label}>
      <div
        className="flex items-center justify-center shadow-lg px-1 h-full rounded bg-blue-500/30 hover:bg-blue-600/30 ring-1 cursor-pointer"
        onClick={onClick}
        style={{ width: `${width}px` }}
      >
        <span className="line-clamp-2">{label}</span>
      </div>
    </Tooltip>
  );
};

export default MultiEventBar;

import React, { useMemo } from 'react';
import { ProcedureState } from 'shared/lib/types/views/procedures';
import DraftStatusLabel from '../../components/DraftStatusLabel';
import ReleaseStatusLabel from '../../components/ReleaseStatusLabel';
import ReviewStatusLabel from '../../components/ReviewStatusLabel';
import BackToLink from '../../components/Toolbar/BackToLink';
import Divider from '../../components/Toolbar/Divider';
import LeftSide from '../../components/Toolbar/LeftSide';
import RightSide from '../../components/Toolbar/RightSide';
import Title from '../../components/Toolbar/Title';
import Toolbar from '../../components/Toolbar/Toolbar';
import EditDeleteButtons from './EditDeleteButtons';

interface StickyHeaderProps {
  code: string;
  name: string;
  onDelete?: () => void;
  resourcePath: string;
  projectId?: string;
  showEditDeleteButtons?: boolean;
  state?: ProcedureState;
  source?: {
    title: string;
    from: string;
  };
}
const StickyHeader = ({
  code,
  name,
  onDelete,
  resourcePath,
  projectId,
  showEditDeleteButtons,
  state,
  source,
}: StickyHeaderProps) => {
  const title = useMemo(() => {
    if (!code && !name) {
      return 'Untitled';
    }
    if (!code || !name) {
      return code || name;
    }
    return `${code} ${name}`;
  }, [code, name]);

  return (
    <Toolbar>
      <LeftSide>
        {source && <BackToLink name={source.title} to={source.from} />}
        <Divider />
        <Title title={title} />
        {state === 'draft' && <DraftStatusLabel />}
        {state === 'in_review' && <ReviewStatusLabel isReviewApproved={false} />}
        {state === 'released' && <ReleaseStatusLabel />}
      </LeftSide>
      <RightSide>
        {showEditDeleteButtons && (
          <EditDeleteButtons
            onDelete={onDelete}
            isInReview={state === 'in_review'}
            resourcePath={resourcePath}
            projectId={projectId}
          />
        )}
      </RightSide>
    </Toolbar>
  );
};

export default React.memo(StickyHeader);

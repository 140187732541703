import { Dialog } from 'primereact/dialog';
import { useCallback, useEffect } from 'react';
import Button from '../../components/Button';
import { TestCase } from '../types';
import TestCasesTable from './TestCasesTable';
import useCases from '../hooks/useCases';
import SearchInput from '../../elements/SearchInput';
import { ColumnOverrides } from '../../lib/gridUtils';

const MAIN_VERTICAL_PADDING = 320;

const columnOverrides: ColumnOverrides = {
  expand_collapse: { hidden: true },
  project_id: { hidden: true },
  name: { width: null },
  conditions: { width: '30%' },
  hazard: { width: '30%' },
};

interface TestPointSelectionModalProps {
  visible: boolean;
  onAdd: (testPoints: Array<TestCase>) => void;
  onCancel: () => void;
}

const TestPointSelectionModal = ({ visible, onAdd, onCancel }: TestPointSelectionModalProps) => {
  const { isLoading, filteredTestCases, selectedRows, setSearchTerm, setSelectedRows } = useCases(true);

  useEffect(() => {
    if (visible) {
      setSearchTerm('');
      setSelectedRows(new Set());
    }
  }, [setSearchTerm, setSelectedRows, visible]);

  const onClickedRow = useCallback(
    (id: string) => {
      setSelectedRows((current) => {
        const updated = new Set(current);
        updated.add(id);
        return updated;
      });
    },
    [setSelectedRows]
  );

  const onAddSelected = useCallback(() => {
    onAdd(filteredTestCases.filter((testCase) => selectedRows.has(testCase.id)));
  }, [onAdd, selectedRows, filteredTestCases]);

  const getFooter = useCallback(() => {
    return (
      <div className="mt-2 sm:flex sm:flex-row-reverse">
        <Button type="primary" isDisabled={selectedRows.size === 0} onClick={onAddSelected}>
          Add Selected Test Points
        </Button>
        <Button type="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    );
  }, [onAddSelected, onCancel, selectedRows.size]);

  if (isLoading) {
    return <></>;
  }

  return (
    <Dialog
      header="Add Test Points"
      onHide={onCancel}
      visible={visible}
      closable={false}
      draggable={false}
      className="w-4/5 md:w-3/4 transition-all"
      footer={getFooter}
    >
      <div className="pt-1">
        <SearchInput placeholder="Search test points" onChange={(term) => setSearchTerm(term)} />
        <TestCasesTable
          testCases={filteredTestCases}
          selectedRows={selectedRows}
          usedVerticalSpace={MAIN_VERTICAL_PADDING}
          columnOverrides={columnOverrides}
          onSelectedRowsChange={setSelectedRows}
          onClickedRow={onClickedRow}
        />
      </div>
    </Dialog>
  );
};

export default TestPointSelectionModal;

import ClosePlotModalButton from './ClosePlotModalButton';
import GoToStorageToolLink from './GoToStorageToolLink';
import { useRunContext } from '../../contexts/RunContext';
import procedureUtil from '../../lib/procedureUtil';

type PlotModalTopBarProps = {
  searchParamsString: string;
  onCloseModal: () => void;
};

const PlotModalTopBar = ({ searchParamsString, onCloseModal }: PlotModalTopBarProps) => {
  const { run } = useRunContext();
  const plotTitle = procedureUtil.getProcedureTitle(run.code, run.name, true);

  return (
    <div className="px-3 py-2 bg-gray-100 shadow-md rounded-t-lg flex justify-between">
      <div className="flex gap-x-2">
        <div>{plotTitle}</div>
        <GoToStorageToolLink searchParamsString={searchParamsString} />
      </div>
      <div>
        <ClosePlotModalButton onClick={onCloseModal} />
      </div>
    </div>
  );
};

export default PlotModalTopBar;

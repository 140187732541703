import { generateHiddenClassString } from '../../lib/styles';
import Spacer from '../Spacer';
import ButtonActionIcon from '../ButtonActionIcon';

/*
 * Component for rendering a Block of type AlertRun within a procedure context.
 * Conforms to TypedProcedureBlock, see comments in useBlockComponents.js
 */
const AlertProcedureBlock = ({ isHidden, actions, children }) => (
  <>
    <div className={generateHiddenClassString('', isHidden)}></div>
    <div className={generateHiddenClassString('mt-2 mr-8 flex page-break', isHidden)}>
      <Spacer />

      {/* Render child content */}
      <div className="min-w-0 w-full">{children}</div>

      {/* Render action buttons */}
      {actions &&
        actions.map((action) => (
          <span key={action.ariaLabel} className="ml-1">
            <ButtonActionIcon
              icon={action.icon}
              iconType={action.iconType ?? undefined}
              onAction={action.onAction}
              ariaLabel={action.ariaLabel}
              pendingAction={action.pendingAction}
              pendingDotMatchesIcon={action.pendingDotMatchesIcon}
            />
          </span>
        ))}
    </div>
  </>
);

export default AlertProcedureBlock;

import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import virtualizationUtil from '../lib/virtualizationUtil';
import VirtualizedAPI from './VirtualizedAPI';

interface VirtualizedElementProps {
  optimize: boolean;
  scrollElementId: string;
  children: ReactNode;
  namespace: string;
  onRefChanged?: (element: HTMLElement) => void;
  overrideVirtualization?: boolean;
}

type elementPropsRefType = {
  elementDimensions: null | DOMRect;
  elementText: null | string;
};

const VirtualizedElement = ({
  optimize,
  scrollElementId,
  children,
  onRefChanged,
  namespace,
  overrideVirtualization = false,
}: VirtualizedElementProps) => {
  const containerRef = useRef<HTMLElement | null>(null);

  const [isElementInViewport, setIsElementInViewport] = useState(false);
  const elementPropsRef = useRef<elementPropsRefType>({
    elementDimensions: null,
    elementText: null,
  });

  const updateRef = useCallback(
    (element) => {
      containerRef.current = element;
      if (onRefChanged) {
        onRefChanged(element);
      }
    },
    [onRefChanged]
  );

  useEffect(() => {
    if (!optimize) {
      return;
    }

    const scrollElement = document.getElementById(scrollElementId);
    const updateIsElementInViewPort = () => {
      if (!containerRef.current) {
        return;
      }

      requestAnimationFrame(() => {
        setIsElementInViewport((oldValue) => {
          if (containerRef.current) {
            const newValue = virtualizationUtil.isElementInViewport(containerRef.current, 200);

            if (oldValue === true && newValue === false) {
              elementPropsRef.current.elementDimensions = containerRef.current.getBoundingClientRect();
              elementPropsRef.current.elementText = containerRef.current.textContent;

              // Blur any inputs that are children of virtualized element if they are focused before virtualizing element to prevent data loss.
              if (containerRef.current.contains(document.activeElement)) {
                (document.activeElement as HTMLInputElement).blur &&
                  (document.activeElement as HTMLInputElement).blur();
              }
            }

            return newValue;
          }

          return oldValue;
        });
      });
    };

    setTimeout(() => {
      if (containerRef.current) {
        elementPropsRef.current.elementDimensions = containerRef.current.getBoundingClientRect();
        elementPropsRef.current.elementText = containerRef.current.textContent;
        setIsElementInViewport(virtualizationUtil.isElementInViewport(containerRef.current, 200));
      }
    }, 0);

    if (scrollElement) {
      scrollElement.addEventListener('scroll', updateIsElementInViewPort);
    }

    const unsubscribeFromRefresh = VirtualizedAPI.onRefresh(namespace, updateIsElementInViewPort);

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', updateIsElementInViewPort);
      }

      unsubscribeFromRefresh();
    };
  }, [optimize, scrollElementId, namespace]);

  const elementDimensions = elementPropsRef.current.elementDimensions;
  const elementText = elementPropsRef.current.elementText;

  return (
    <div ref={updateRef} className="w-full h-full">
      {!optimize && <>{children}</>}

      {optimize && (
        <>
          {elementDimensions && !isElementInViewport && !overrideVirtualization && (
            <div className="overflow-hidden" style={{ height: elementDimensions.height }}>
              <span className="hidden">{elementText}</span>
            </div>
          )}
          {(!elementDimensions || isElementInViewport || overrideVirtualization) && <>{children}</>}
        </>
      )}
    </div>
  );
};

export default VirtualizedElement;

import React, { useMemo, useCallback } from 'react';
import { Field, Form, Formik } from 'formik';
import TextLinkify from '../../TextLinkify';
import ExternalItemSelectFieldSet from '../../Blocks/ExternalItemSelectFieldSet';
import Tooltip from '../../../elements/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ExternalDataValue, FieldInputExternalDataBlock } from 'shared/lib/types/views/procedures';
import revisions, { DMPDiffType } from '../../../lib/revisions';
import ProcedureDiffText from '../../ProcedureDiffText';
import DiffContainer from '../../Diff/DiffContainer';
import diffUtil from '../../../lib/diffUtil';

/*
 * Component for rendering a field input with an external data type.
 * Conforms to TypedBlockInterface, see comments in useBlockComponents.js
 */
interface FieldInputExternalItemReviewProps {
  block: FieldInputExternalDataBlock;
  recorded?: { value: ExternalDataValue };
  isEnabled?: boolean;
  onRecordValuesChanged: (recorded: { value?: ExternalDataValue }) => void;
  onContentRefChanged?: (id: string, element: HTMLElement) => void;
  scrollMarginTopValueRem?: number;
  redline?: FieldInputExternalDataBlock;
}
const FieldInputExternalItemReview = React.memo(
  ({
    block,
    recorded,
    isEnabled,
    onRecordValuesChanged,
    onContentRefChanged,
    scrollMarginTopValueRem,
    redline,
  }: FieldInputExternalItemReviewProps) => {
    const updateExternalItem = (item) => {
      if (typeof onRecordValuesChanged !== 'function') {
        return;
      }
      const recorded = { value: item };
      onRecordValuesChanged(recorded);
    };
    const nameRedlineDiffs = useMemo(() => {
      if (!block || !redline) {
        return [];
      }
      return revisions.getTextDiffs(block.name, redline.name);
    }, [block, redline]);

    const getDiffTypeClass = (type: number) => {
      switch (type) {
        case DMPDiffType.insertion:
          return 'text-red-600';
        case DMPDiffType.deletion:
          return 'line-through';
        default:
          return '';
      }
    };

    const onFieldInputRefChanged = useCallback(
      (element) => {
        return typeof onContentRefChanged === 'function' && onContentRefChanged(block.id, element);
      },
      [block.id, onContentRefChanged]
    );

    return (
      <div
        ref={(element) => onFieldInputRefChanged(element)}
        style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
        className="grow"
      >
        {/* Render input form, disabling if input is not live. */}
        <Formik
          initialValues={{}}
          onSubmit={() => {
            /* no-op */
          }}
        >
          {() => (
            <Form>
              <div className="flex flex-nowrap w-full">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="flex align-middle pr-2 whitespace-pre-wrap">
                          <TextLinkify>
                            {redline &&
                              nameRedlineDiffs.map(([type, text], index) => (
                                <div key={index} className={getDiffTypeClass(type)}>
                                  {text}
                                </div>
                              ))}
                            {!redline && (
                              <div>
                                <ProcedureDiffText diffValue={block.name} useMarkdownWhenNoDiff={true} />
                              </div>
                            )}
                          </TextLinkify>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div className="self-center w-64">
                            <DiffContainer
                              label="External data field input"
                              diffChangeState={diffUtil.getDiffChangeStateForChangesOnly(block, 'external_item_type')}
                              isTextSticky={false}
                              width="fit"
                            >
                              <Field
                                itemType={block.external_item_type}
                                itemDictionary={block.dictionary_id}
                                isDisabled={!isEnabled}
                                value={recorded && recorded.value}
                                onChange={updateExternalItem}
                                component={ExternalItemSelectFieldSet}
                              />
                            </DiffContainer>
                          </div>
                        </div>
                      </td>

                      {/* Item validity */}
                      <td>
                        {recorded && recorded.value && recorded?.value.valid === false && (
                          <Tooltip content="This item is invalid.">
                            <FontAwesomeIcon className="text-red-500 pl-1.5" icon="exclamation-circle" />
                          </Tooltip>
                        )}
                      </td>

                      {/* Item URL */}
                      <td>
                        {recorded && recorded.value && recorded?.value.url !== undefined && (
                          <div className="flex flex-row">
                            <Link to={{ pathname: recorded?.value.url }} target="_blank" rel="noopener noreferrer">
                              <FontAwesomeIcon
                                className="text-blue-500 p-2 hover:text-blue-600"
                                icon={'fas fa-external-link-alt' as IconProp}
                              />
                            </Link>
                          </div>
                        )}
                      </td>
                    </tr>

                    {/* Item details */}
                    {recorded?.value?.details?.map((detail, index) => (
                      <tr key={index}>
                        <td>
                          <div className="py-0.5">{detail.name}:</div>
                        </td>
                        <td>
                          <div className="py-0.5">{detail.value}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
);

export default FieldInputExternalItemReview;

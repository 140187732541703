import React, { useMemo } from 'react';
import { DiffArrayChangeSymbol, DiffField } from 'shared/lib/types/views/procedures';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';

interface ProcedureDiffCompareTextProps {
  diffValue: DiffField<number | string>;
  diffChangeState?: DiffArrayChangeSymbol;
}

const ProcedureDiffCompareText = ({ diffValue, diffChangeState }: ProcedureDiffCompareTextProps) => {
  const oldValue = useMemo(
    () =>
      diffChangeState === ARRAY_CHANGE_SYMBOLS.ADDED
        ? ''
        : sharedDiffUtil.getDiffValue<string>({ value: diffValue }, 'value', 'old'),
    [diffChangeState, diffValue]
  );
  const newValue = useMemo(
    () =>
      diffChangeState === ARRAY_CHANGE_SYMBOLS.REMOVED
        ? ''
        : sharedDiffUtil.getDiffValue<string>({ value: diffValue }, 'value', 'new'),
    [diffChangeState, diffValue]
  );

  return (
    <>
      {oldValue === newValue && <div className="truncate">{newValue}</div>}
      {oldValue !== newValue && (
        <div className="flex flex-row gap-x-1 flex-wrap line-clamp-2">
          {oldValue && <span className="px-1 text-red-600 bg-red-100 line-through truncate">{oldValue}</span>}
          {newValue && <span className="px-1 text-emerald-800 bg-emerald-100 truncate">{newValue}</span>}
        </div>
      )}
    </>
  );
};

export default React.memo(ProcedureDiffCompareText);

import React, { useRef, useCallback, Dispatch, SetStateAction } from 'react';
import { Field, Form, Formik } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';
import validateUtil from '../lib/validateUtil';
import * as Yup from 'yup';
import Button from './Button';

interface ReleaseNoteEditProps {
  noteText: string;
  onSaveReleaseNote: (message: string) => Promise<void>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}

// Editor for release notes.
const ReleaseNoteEdit = ({ noteText, onSaveReleaseNote, setIsEditing }: ReleaseNoteEditProps) => {
  const textAreaRef = useRef(null);

  const onCancel = useCallback(() => setIsEditing(false), [setIsEditing]);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    return onSaveReleaseNote(values.releaseNoteText)
      .then(() => {
        resetForm();
        setIsEditing(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{ releaseNoteText: noteText }}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({ releaseNoteText: validateUtil.getNonEmptyStringValidator() })}
    >
      {({ isSubmitting, dirty, isValid, handleSubmit }) => (
        <Form className="w-full">
          <div className="flex flex-col">
            <Field name="releaseNoteText">
              {({ field }) => (
                <TextareaAutosize
                  ref={textAreaRef}
                  placeholder="Add a release note"
                  className="w-full px-3 py-2 resize-none pr-16 rounded border-none"
                  {...field}
                  disabled={isSubmitting}
                  minRows={3}
                />
              )}
            </Field>
            <div className="flex flex-row gap-x-3 text-bold text-gray-500">
              <Button
                type="tertiary"
                leadingIcon="check"
                iconTextColor="text-gray-800"
                isDisabled={!dirty || isSubmitting || !isValid}
                onClick={handleSubmit}
              >
                {isSubmitting ? 'Saving' : 'Save'}
              </Button>
              <Button
                type="tertiary"
                leadingIcon="ban"
                iconTextColor="text-gray-800"
                onClick={onCancel}
                isDisabled={isSubmitting}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ReleaseNoteEdit;

import React, { useCallback, useState, useEffect, useMemo } from 'react';
import Modal from './Modal';
import { Formik, Form, Field } from 'formik';
import Select, { Option } from 'react-select';
import FieldSetCheckbox from './FieldSetCheckbox';
import TextAreaAutoHeight from './TextAreaAutoHeight';
import { IssueType, RunIssue } from 'shared/lib/types/views/procedures';
import { IssueMetadata } from '../api/issue';
import idUtil from '../lib/idUtil';
import FlashMessage from './FlashMessage';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { DatabaseServices } from '../contexts/proceduresSlice';

type JiraFormValues = {
  project: string;
  issueType: string;
  title: string;
  description: string;
  includeProcedureUrl: boolean;
};

interface JiraIssueModalProps {
  urlText?: string;
  url: string;
  onAddIssue: (issue: RunIssue) => Promise<void>;
  isModalShown: boolean;
  onHideModal: () => void;
}

const JiraIssueModal = ({ urlText, url, onAddIssue, isModalShown, onHideModal }: JiraIssueModalProps) => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const [metadata, setMetadata] = useState<IssueMetadata | null>(null);
  const [projectId, setProjectId] = useState('');
  const [createMessage, setCreateMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoadingMetadata, setIsLoadingMetadata] = useState(false);

  // isModalShown prop is used to drive the internal flag showModal to allow rendering FlashMessage
  useEffect(() => {
    if (typeof isModalShown !== 'boolean') {
      return;
    }
    if (isModalShown) {
      setIsLoadingMetadata(true);
      services.issue
        .getMetadata()
        .then((metadata) => {
          setMetadata(metadata);
        })
        .catch(() => {
          setMetadata(null);
        })
        .finally(() => {
          setIsLoadingMetadata(false);
        });
    }
    setShowModal(isModalShown);
  }, [isModalShown, services.issue]);

  const projectOptions = useMemo(() => {
    if (!metadata) {
      return [];
    }
    const options: Array<Option> = [];
    for (const project of metadata.projects) {
      options.push({
        value: project.id,
        label: project.name,
      });
    }
    return options;
  }, [metadata]);

  const onProjectChange = useCallback((newProject, setFieldValue) => {
    setProjectId(newProject.value);
    setFieldValue('project', newProject.value);
  }, []);

  const issueTypeOptions = useMemo(() => {
    if (!metadata || !projectId) {
      return [];
    }
    const project = metadata.projects.find((project) => project.id === projectId);
    if (!project) {
      return [];
    }

    const options: Array<Option> = [];
    for (const issueType of project.issueTypes) {
      options.push({
        value: issueType.id,
        label: issueType.name,
      });
    }
    return options;
  }, [projectId, metadata]);

  const onValidate = (values: JiraFormValues) => {
    const errors: { project?: string; issueType?: string; title?: string } = {};
    if (!values.project) {
      errors.project = 'Required.';
    }
    if (!values.issueType) {
      errors.issueType = 'Required.';
    }
    if (!values.title.trim().length) {
      errors.title = 'Required.';
    }
    return errors;
  };

  const onSubmit = useCallback(
    (values, { setSubmitting }) => {
      let procedureUrl;
      if (values.includeProcedureUrl) {
        procedureUrl = url;
      }
      onHideModal();
      setCreateMessage('Creating Issue');
      services.issue
        .createIssue({
          projectId: values.project,
          typeId: values.issueType,
          title: values.title,
          description: values.description,
          url: procedureUrl,
          urlText,
        })
        .then((response) => {
          onAddIssue({
            id: `jira_${idUtil.generateId()}`,
            text: response.issueKey,
            url: response.url,
            type: IssueType.Jira,
          })
            .then(() => setSuccessMessage('Issue Created!'))
            .catch(() => setErrorMessage('Failed to Create Issue'));
        })
        .catch(() => {
          setErrorMessage('Failed to Create Issue');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [onAddIssue, onHideModal, url, urlText, services.issue]
  );

  return (
    <>
      {/* "toast" messages upon successful issue creation or failure */}
      <FlashMessage topOffset={10} message={createMessage} messageUpdater={setCreateMessage} type="info" />
      <FlashMessage topOffset={10} message={successMessage} messageUpdater={setSuccessMessage} />
      <FlashMessage topOffset={10} message={errorMessage} messageUpdater={setErrorMessage} type="warning" />
      {showModal && (
        <Formik
          initialValues={{
            project: '',
            issueType: '',
            title: '',
            description: '',
            includeProcedureUrl: true,
          }}
          onSubmit={onSubmit}
          validateOnChange={false}
          validateOnBlur={false}
          validate={onValidate}
          enableReinitialize
        >
          {({ errors, values, setFieldValue, isSubmitting }) => (
            <Form aria-label="Create Issue">
              <Modal
                title="Create New Jira Issue"
                primaryActionTitle="Create Issue"
                isPrimaryActionEnabled={!isSubmitting}
                onSecondaryAction={onHideModal}
                size="sm"
              >
                <div className="flex flex-col w-full overflow-y-auto">
                  <div className="flex flex-row px-1 pb-1">
                    <div className="flex-col w-1/2 pr-1">
                      <label className="font-semibold text-sm font-medium uppercase">Jira Project*</label>
                      <Select
                        classNamePrefix="settings-select react-select"
                        className="text-sm"
                        aria-label="Jira project"
                        menuPosition="fixed"
                        placeholder="Select Project"
                        options={projectOptions}
                        onChange={(newProject) => onProjectChange(newProject, setFieldValue)}
                        value={projectOptions?.find((project) => project.value === values.project)}
                        isLoading={isLoadingMetadata}
                      />
                      {errors.project && <div className="text-sm text-red-700 mt-1">{errors.project}</div>}
                    </div>
                    <div className="flex-col w-1/2 pl-1">
                      <label className="font-semibold text-sm font-medium uppercase">Issue Type*</label>
                      <Select
                        classNamePrefix="settings-select react-select"
                        className="text-sm border-1 border-gray-400 rounded"
                        aria-label="Jira issue type"
                        menuPosition="fixed"
                        placeholder="Select Issue Type"
                        options={issueTypeOptions}
                        onChange={(newIssueType) => setFieldValue('issueType', newIssueType.value)}
                        value={issueTypeOptions?.find((issueType) => issueType.value === values.issueType)}
                        isDisabled={!values.project}
                      />
                      {errors.issueType && <div className="text-sm text-red-700 mt-1">{errors.issueType}</div>}
                    </div>
                  </div>
                  <div className="flex flex-col mt-1 px-1 pb-1">
                    <label className="font-semibold text-sm font-medium uppercase">Title*</label>
                    <Field
                      name="title"
                      type="text"
                      placeholder="Issue Summary"
                      className="text-sm border-1 border-gray-400 rounded"
                    />
                    {errors.title && <div className="text-sm text-red-700 mt-1">{errors.title}</div>}
                  </div>
                  <div className="flex flex-col mt-1 px-1 pb-1">
                    <label className="font-semibold text-sm font-medium uppercase">Description</label>
                    <Field id="description" name="description">
                      {({ field }) => <TextAreaAutoHeight placeholder="Issue Details" {...field} />}
                    </Field>
                  </div>
                  <div className="px-1 my-1">
                    <FieldSetCheckbox
                      text="Include Procedure URL"
                      fieldName="includeProcedureUrl"
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </div>
              </Modal>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default JiraIssueModal;

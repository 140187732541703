import React from 'react';
import ResultsLabel from '../ResultsLabel';

interface RuleStatusProps {
  isPassing?: boolean | null;
}

const RuleStatus = ({ isPassing = null }: RuleStatusProps) => {
  return <>{isPassing !== null && <ResultsLabel success={isPassing} successText="Pass" failText="Fail" />}</>;
};

export default RuleStatus;

import { AxiosResponse } from 'axios';
import {
  ClientTool,
  ClientToolInstance,
} from 'shared/lib/types/api/manufacturing/tools/models';
import {
  CreateTool,
  CreateToolInstance,
  CreateToolInstancesReq,
  CreateToolsReq,
  DeleteToolInstancesReq,
  DeleteToolsReq,
  UpdateToolInstanceReq,
  UpdateToolReq,
} from 'shared/lib/types/api/manufacturing/tools/requests';
import {
  CreateToolInstancesRes,
  CreateToolsRes,
  GetToolInstanceRcvRes,
  ListToolInstancesRcvRes,
  ListToolsRcvRes,
} from 'shared/lib/types/api/manufacturing/tools/responses';
import { UsageType } from 'shared/lib/types/api/manufacturing/usage_types/models';
import { ListUsageTypesRes } from 'shared/lib/types/api/manufacturing/usage_types/responses';
import superlogin from '../../api/superlogin';
import { API_URL } from '../../config';

export type GetToolInstanceReq = {
  toolId: number;
  toolInstanceId: number;
};

export type UpdateToolInstanceFullReq = {
  toolId: number;
  toolInstanceId: number;
  toolInstance: UpdateToolInstanceReq;
};

class ToolsService {
  static instances = {};

  static getInstance = (teamId: string): ToolsService => {
    if (!ToolsService.instances[teamId]) {
      ToolsService.instances[teamId] = new ToolsService(teamId);
    }

    return ToolsService.instances[teamId];
  };

  static removeInstance = (teamId: string): void => {
    delete ToolsService.instances[teamId];
  };

  private readonly teamId: string;
  private readonly restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    this.restUrl = `${API_URL}/teams/${this.teamId}/builds`;
  }

  // tools
  async createTool(tool: CreateTool): Promise<number> {
    const url = `${this.restUrl}/tools`;
    const req: CreateToolsReq = { tools: [tool] };
    const response = await superlogin.getHttp().post<CreateToolsRes>(url, req);
    return response.data.ids[0];
  }

  async getTools(): Promise<Array<ClientTool>> {
    const url = `${this.restUrl}/tools`;
    const response = await superlogin.getHttp().get<ListToolsRcvRes>(url);
    return response.data.tools;
  }

  async getTool(toolId: number): Promise<ClientTool> {
    const url = `${this.restUrl}/tools/${toolId}`;
    const response = await superlogin.getHttp().get<ClientTool>(url);
    return response.data;
  }

  async updateTool(
    tool: UpdateToolReq,
    toolId: number
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/tools/${toolId}`;
    return superlogin.getHttp().patch(url, tool);
  }

  async deleteTool(toolId: number): Promise<AxiosResponse> {
    const url = `${this.restUrl}/tools`;
    const req: DeleteToolsReq = { ids: [toolId] };
    return superlogin.getHttp().delete(url, { data: req });
  }

  // tool instances
  async createToolInstance(toolInstance: CreateToolInstance): Promise<number> {
    const url = `${this.restUrl}/tools/instances`;
    const req: CreateToolInstancesReq = {
      tool_instances: [toolInstance],
    };
    const response = await superlogin
      .getHttp()
      .post<CreateToolInstancesRes>(url, req);
    return response.data.ids[0];
  }

  async getToolInstances(): Promise<Array<ClientToolInstance>> {
    const url = `${this.restUrl}/tools/instances`;
    const response = await superlogin
      .getHttp()
      .get<ListToolInstancesRcvRes>(url);
    return response.data.tool_instances;
  }

  async getToolInstance({
    toolId,
    toolInstanceId,
  }: GetToolInstanceReq): Promise<ClientToolInstance> {
    const url = `${this.restUrl}/tools/${toolId}/instances/${toolInstanceId}`;
    const response = await superlogin.getHttp().get<GetToolInstanceRcvRes>(url);
    return response.data;
  }

  async updateToolInstance({
    toolId,
    toolInstanceId,
    toolInstance,
  }: UpdateToolInstanceFullReq): Promise<AxiosResponse> {
    const url = `${this.restUrl}/tools/${toolId}/instances/${toolInstanceId}`;
    return superlogin.getHttp().patch(url, toolInstance);
  }

  async deleteToolInstance(toolInstanceId: number): Promise<AxiosResponse> {
    const url = `${this.restUrl}/tools/instances`;
    const req: DeleteToolInstancesReq = { ids: [toolInstanceId] };
    return superlogin.getHttp().delete(url, { data: req });
  }

  // TODO move to separate service
  async listUsageTypes(): Promise<Array<UsageType>> {
    const url = `${this.restUrl}/usage_types`;
    const response = await superlogin.getHttp().get<ListUsageTypesRes>(url);
    return response.data.usage_types;
  }
}

export default ToolsService;

const MAX_BACKOFF_SEC = 32;

/**
 * Retries with truncated exponential backoff with max backoff MAX_BACKOFF_SEC.
 *
 * Reference: https://cloud.google.com/iot/docs/how-tos/exponential-backoff
 *
 * Returns: Number, milliseconds until retry should be attempted.
 */
const retryDelay = (retries) => {
  const randomSec = Math.random(); // nosemgrep: javascript-crypto-rule-node_insecure_random_generator
  return Math.min(2 ** retries + randomSec, MAX_BACKOFF_SEC) * 1000;
};

/**
 * Helper method to schedule a retry callback with a backoff schedule.
 *
 * retries: Number, the current number of retries.
 * callback: Function, callback to execute when retry should be attempted.
 */
const retry = (retries, callback) => {
  setTimeout(() => {
    callback();
  }, retryDelay(retries));
};

export default retry;
export { retryDelay, MAX_BACKOFF_SEC };

import React from 'react';
import Toolbar from '../Toolbar/Toolbar';
import LeftSide from '../Toolbar/LeftSide';
import BackToLink from '../Toolbar/BackToLink';
import Divider from '../Toolbar/Divider';
import Title from '../Toolbar/Title';
import Spacer from '../Toolbar/Spacer';
import RunStatusLabel from '../RunStatusLabel';
import RightSide from '../Toolbar/RightSide';
import MoreActionsMenu from '../Toolbar/MoreActionsMenu';
import { CouchLikeOperationSummary } from 'shared/lib/types/operations';
import { MenuContextAction } from '../MenuContext';
import Button, { BUTTON_TYPES } from '../Button';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { operationsDashboardPath } from '../../lib/pathUtil';

interface OperationToolbarProps {
  operation: CouchLikeOperationSummary;
  addEvent: () => void;
  hasEditPermission: boolean;
  menuActions: Array<MenuContextAction>;
}

const OperationToolbar = ({ operation, addEvent, hasEditPermission, menuActions }: OperationToolbarProps) => {
  const { currentTeamId }: { currentTeamId: string } = useDatabaseServices();
  return (
    <Toolbar>
      <LeftSide>
        <BackToLink to={operationsDashboardPath(currentTeamId)} name="Operations" />
        <Divider />
        <Title title={operation.name} />
        <Spacer />
        <RunStatusLabel statusText={operation.state} />
      </LeftSide>
      <RightSide visible={hasEditPermission}>
        {hasEditPermission && operation.state !== 'ended' && (
          <Button type={BUTTON_TYPES.PRIMARY} leadingIcon="plus" onClick={addEvent} title="Add Event" size="sm">
            Add Event
          </Button>
        )}
        <MoreActionsMenu actions={menuActions} label="Operation Details Menu" />
      </RightSide>
    </Toolbar>
  );
};

export default OperationToolbar;

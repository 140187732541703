import { useCallback } from 'react';
import { useMixpanel } from '../../contexts/MixpanelContext';

const STORAGE_TRACKING_NAME = 'Storage';

type StorageTrack = (name: string, properties?: object) => void;

const useStorageTrack = (): StorageTrack => {
  const { mixpanel } = useMixpanel();

  const storageTrack = useCallback(
    (name: string, properties?: object) => {
      if (mixpanel) {
        mixpanel.track(name, {
          Module: STORAGE_TRACKING_NAME,
          ...properties,
        });
      }
    },
    [mixpanel]
  );

  return storageTrack;
};

export default useStorageTrack;

import {
  ComponentPart,
  Part,
} from 'shared/lib/types/postgres/manufacturing/types';

export const getPartRevisionId = (part: Part | ComponentPart): string => {
  if ('revision_id' in part && part.revision_id) {
    return part.revision_id;
  }
  if ('revisions' in part && part.revisions && part.revisions.length > 0) {
    return part.revisions[0].id;
  }
  return '';
};

export const getPartId = (part: Part | ComponentPart): string => {
  if ('part_id' in part && part.part_id) {
    return part.part_id;
  }
  if ('id' in part && part.id) {
    return part.id;
  }
  return '';
};

export const getPartRevision = (part: Part | ComponentPart): string => {
  if ('rev' in part && part.rev) {
    return part.rev;
  }
  if ('revision' in part && part.revision) {
    return part.revision;
  }
  return '';
};

import { EventEmitter2 } from 'eventemitter2';

type Callback = () => void;

// Total number of sections, steps, and context blocks we support
const MAX_LISTENERS = 1000000;

class VirtualizedAPI {
  emitter: EventEmitter2;

  constructor() {
    this.emitter = new EventEmitter2({
      maxListeners: MAX_LISTENERS,
      delimiter: '.',
    });

    this.refresh = this.refresh.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
  }

  refresh(namespace: string): void {
    this.emitter.emit(`refresh.${namespace}`);
  }

  onRefresh(namespace: string, callback: Callback): Callback {
    const eventName = `refresh.${namespace}`;
    this.emitter.on(eventName, callback);

    return () => {
      this.emitter.off(eventName, callback);
    };
  }
}

const VirtualizedAPIInstance = new VirtualizedAPI();
export default VirtualizedAPIInstance;

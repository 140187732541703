import Button, { BUTTON_TYPES } from '../Button';
import ModalBase from '../ModalBase';
import ModalActionBar from '../ModalActionBar';
import ModalContent from '../ModalContent';

interface ModalSnippetDetachProps {
  onClickProceed: () => void;
  onClickCancel: () => void;
}

const ModalSnippetDetach = ({ onClickProceed, onClickCancel }: ModalSnippetDetachProps) => (
  <ModalBase>
    <ModalContent title="Snippet will be detached.">
      <div className="w-full">
        This snippet will no longer be updated with new changes to the original snippet. Are you sure you want to
        proceed?
      </div>
    </ModalContent>
    <ModalActionBar>
      <div className="flex gap-x-2">
        <Button type={BUTTON_TYPES.SECONDARY} ariaLabel="Cancel" isDisabled={false} onClick={onClickCancel}>
          Cancel
        </Button>
        <Button type={BUTTON_TYPES.PRIMARY} ariaLabel="Proceed" isDisabled={false} onClick={onClickProceed}>
          Proceed
        </Button>
      </div>
    </ModalActionBar>
  </ModalBase>
);

export default ModalSnippetDetach;

import contains from './search';

export const MAIN_VERTICAL_PADDING = 140;
export const TAGS_ROW_HIGHT = 70;
export const NO_TAGS_ROW_HEIGHT = 50;

export const filterByField = <T>({
  rows,
  fieldName,
  values,
}: {
  rows: Array<T>;
  fieldName: string;
  values?: ReadonlySet<string | null>;
}): Array<T> => {
  if (!values || values.size === 0) {
    return rows;
  }
  return rows.filter((row) => {
    if (!row[fieldName]) {
      // null indicates the filter for "none" was selected, which associates with lack of field
      return values.has(null);
    }
    return values.has(row[fieldName]);
  });
};

export const filterBySearchTerm = <T>({
  allData,
  searchTerm,
  getStrings,
}: {
  allData: Array<T>;
  searchTerm: string;
  getStrings: (data: T) => Array<string | undefined>;
}): Array<T> => {
  return allData.filter((data) =>
    searchMatches({ data, searchTerm, getStrings })
  );
};

const searchMatches = <T>({
  data,
  searchTerm,
  getStrings,
}: {
  data: T;
  searchTerm: string;
  getStrings: (data: T) => Array<string | undefined>;
}): boolean => {
  const searchStrings = getStrings(data).filter(Boolean).map(String);
  return contains(searchStrings, searchTerm);
};

interface ColumnWithKey {
  key: string;
}
export type ColumnOverrides = Record<string, ColumnOverride>;
export type ColumnOverride = {
  hidden?: boolean;
  /** Provide width in percentage string 'xx%', pixels number or null to unset */
  width?: string | number | null;
};

export const applyOverrides = <T extends ColumnWithKey>(
  baseColumns: Array<T>,
  columnOverrides: ColumnOverrides
): Array<T> => {
  if (columnOverrides) {
    return baseColumns.map((col) =>
      columnOverrides[col.key] ? { ...col, ...columnOverrides[col.key] } : col
    );
  }
  return baseColumns;
};

import TimeAgoDisplayUpdating from './TimeAgoDisplayUpdating';
import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface LastSavedDisplayProps {
  lastSavedTime: Date | null;
  isSubmitting: boolean;
  isDirty: boolean;
  displayMessage?: string;
  processingMessage?: string;
}

const LAST_SAVED_DISPLAY_CLASS = 'text-xs text-slate-700';
const LastSavedDisplay = ({
  lastSavedTime,
  isSubmitting,
  isDirty,
  displayMessage = 'Last saved',
  processingMessage = 'Saving...',
}: LastSavedDisplayProps) => {
  const icon = useMemo(() => {
    if (isSubmitting) {
      return null;
    }
    if (isDirty) {
      return <FontAwesomeIcon icon="dot-circle" title="Changes Made" className="text-xs text-gray-500" />;
    }

    if (lastSavedTime) {
      return <FontAwesomeIcon icon="check-circle" title="All Changes Saved" className="text-xs text-green-500" />;
    }
  }, [isDirty, isSubmitting, lastSavedTime]);

  const textSpan = useMemo(() => {
    if (isSubmitting) {
      return <span className={LAST_SAVED_DISPLAY_CLASS}>{processingMessage}</span>;
    }
    if (isDirty) {
      return <span className={LAST_SAVED_DISPLAY_CLASS}>Changes made</span>;
    }

    if (lastSavedTime) {
      return (
        <span className={LAST_SAVED_DISPLAY_CLASS}>
          {displayMessage} <TimeAgoDisplayUpdating timestamp={lastSavedTime} />
        </span>
      );
    }
  }, [isDirty, isSubmitting, lastSavedTime, displayMessage, processingMessage]);

  if (!(isSubmitting || isDirty || lastSavedTime)) {
    return <></>;
  }

  return (
    <div>
      {icon && <span className="mr-1">{icon}</span>}
      {textSpan}
    </div>
  );
};

export default LastSavedDisplay;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateTimeDisplay from '../DateTimeDisplay';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ReviewerActionRowProps {
  iconName: IconProp;
  children: React.ReactNode;
  timestamp: string;
}

const ReviewActionRow = React.memo(({ iconName, children, timestamp }: ReviewerActionRowProps) => {
  return (
    <div className="flex flex-row flex-wrap gap-x-1 items-center text-sm my-1">
      <span>
        <FontAwesomeIcon className="text-black" icon={iconName} />
      </span>
      {children}
      <span className="ml-1">
        <DateTimeDisplay hasTooltip={true} timestamp={timestamp} />
      </span>
    </div>
  );
});

export default ReviewActionRow;

import React from 'react';
import Error from '../../components/Error';
import TextAreaAutoHeight from '../../components/TextAreaAutoHeight';

const EventMarkdown = ({ field, form }) => {
  const touched = form.touched[field.name];
  const error = form.errors[field.name];

  return (
    <div className="flex flex-col pt-1 pl-3 w-full space-">
      {error && touched && <Error text={error} />}
      <div className="relative w-full text-lg">
        <TextAreaAutoHeight
          placeholder="Add notes [markdown supported]"
          defaultRows={3}
          textSize="base"
          {...field}
          value={field.value || ''}
        />
      </div>
    </div>
  );
};

export default EventMarkdown;

import React, { ReactNode, useContext } from 'react';
import mixpanel from 'mixpanel-browser';
import { RELEASE_VERSION } from '../config';

interface MixpanelContextProps {
  mixpanel?: {
    track: (
      event_name: string,
      properties?: object,
      options?: {
        transport: string;
        send_immediately: boolean;
      },
      callback?: () => void
    ) => void;
  };
}

const MixpanelContext = React.createContext<MixpanelContextProps>({});

const MixpanelProvider = ({ token, children }: { token?: string; children: ReactNode }) => {
  if (token) {
    mixpanel.init(token);
    mixpanel.register({ 'Spock Version': RELEASE_VERSION });
  }

  return <MixpanelContext.Provider value={{ mixpanel: token ? mixpanel : null }}>{children}</MixpanelContext.Provider>;
};

const useMixpanel = () => {
  const context = useContext(MixpanelContext);

  if (context === undefined) {
    throw new Error('useMixpanel must be used within a MixpanelProvider');
  }
  return context;
};

export { MixpanelProvider, useMixpanel };

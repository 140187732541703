import { FieldInputExternalSearchBlock, RunFieldInputExternalSearchBlock } from 'shared/lib/types/views/procedures';
import SelectDisplay from './SelectDisplay';
import externalDataUtil from '../../../lib/externalDataUtil';
import React, { useMemo } from 'react';
import { Details } from '../FieldInputExternalSearch';

interface ExternalItemDisplayProps {
  block: FieldInputExternalSearchBlock;
  referenceId?: string;
  referenceSubtype?: string;
}

const ExternalSearchDisplay = ({ block, referenceId, referenceSubtype }: ExternalItemDisplayProps) => {
  const recordedValue = (block as RunFieldInputExternalSearchBlock).recorded?.value;

  const selected = useMemo(() => {
    if (recordedValue) {
      return { label: externalDataUtil.getItemLabel(recordedValue) };
    }
  }, [recordedValue]);

  const filteredDetails = useMemo(() => {
    if (referenceSubtype && recordedValue?.details) {
      return recordedValue.details.filter((detail) => detail.name === referenceSubtype);
    } else {
      return recordedValue?.details;
    }
  }, [recordedValue, referenceSubtype]);

  return (
    <div>
      <SelectDisplay
        block={block}
        optionSelectedRecorded={selected}
        referenceId={referenceId}
        referenceSubtype={referenceSubtype}
      />
      {/* Selection results table */}
      {recordedValue?.details && (
        <div className="flex flex-row mt-2">
          <div className="border-l border-gray-300 border-1.5 mr-2" />
          <div className="flex flex-nowrap w-full ml-2">
            <Details details={filteredDetails} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ExternalSearchDisplay;

const printPDF = () => {
  const safariAgent = navigator.userAgent.indexOf('Safari') > -1;
  if (safariAgent) {
    document.execCommand('print', false, null);
  } else {
    window.print();
  }
};

export default printPDF;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type PlotLegendProps = {
  dictionaryName: string;
  measurementName: string;
};

const PlotLegend = ({ dictionaryName, measurementName }: PlotLegendProps) => {
  return (
    <div className="flex items-center gap-x-2">
      <BlueCircleIcon />
      <div>
        {dictionaryName} {measurementName}
      </div>
    </div>
  );
};

const BlueCircleIcon = () => {
  return <FontAwesomeIcon className="text-blue-500" icon="circle" size="xs" />;
};

export default PlotLegend;

import React, { useState } from 'react';
import { FrontendEvent as Event } from 'shared/schedule/types/event';
import FixedBar from './FixedBar';
import FixedTime from './FixedTime';
import MultiEventBar from './MultiEventBar';
import OngoingBar from './OngoingBar';
import MultiEvent from '../../../lib/MultiEvent';
import EventPopup from '../../EventPopup';
import useEventNav from '../../../hooks/useEventNav';
import useSingleClick from '../../../hooks/useSingleClick';
import Tooltip from '../../../../elements/Tooltip';

interface GanttEventProps {
  event: Event | MultiEvent;
  expandSwimlane: () => void;
}

const renderSingleEvent = (event) => {
  if (event.start && event.end) {
    return <FixedBar event={event} />;
  }
  if (event.start && event.status === 'running') {
    return <OngoingBar event={event} />;
  }
  if (event.start) {
    return <FixedTime event={event} />;
  }
};

const GanttEvent = ({ event, expandSwimlane }: GanttEventProps) => {
  const [showPopOver, setShowPopOver] = useState(false);
  const { navigateToEventPage } = useEventNav();
  const { handleClick, clickPosition } = useSingleClick(() => setShowPopOver(true));

  if ((event as MultiEvent).isMultiEvent) {
    return <MultiEventBar multiEvent={event as MultiEvent} expandSwimlane={expandSwimlane} />;
  }
  return (
    <Tooltip content={(event as Event).name} position="mouse">
      <>
        <div
          className="cursor-pointer h-full"
          onClick={handleClick}
          onDoubleClick={() => navigateToEventPage(event as Event)}
        >
          {renderSingleEvent(event)}
        </div>
        <EventPopup
          showPopOver={showPopOver}
          event={event as Event}
          position={clickPosition}
          closePopup={() => setShowPopOver(false)}
        />
      </>
    </Tooltip>
  );
};

export default GanttEvent;

import { SUPPORT_URL } from '../../config';

const ContactSupportMessage = () => {
  return (
    <div>
      Please try again or{' '}
      <a href={SUPPORT_URL} className="link">
        contact support
      </a>
      .
    </div>
  );
};

export default ContactSupportMessage;

import DateTimeDisplay from '../../DateTimeDisplay';

const renderDateTime = function (value) {
  return (
    <span className="text-xs text-gray-600">
      {value && <DateTimeDisplay timestamp={value} wrap={true} hasTooltip={true} />}
    </span>
  );
};

export default renderDateTime;

import { useMemo } from 'react';
import { AttachmentBlock } from 'shared/lib/types/views/procedures';
import InlineImageContent from './InlineImageContent';

interface InlineImageProps {
  attachment: AttachmentBlock;
  showCaptionText: boolean;
  url?: string;
  onSave?: (file: File) => void;
  onClose?: () => void;
  isAnnotating?: boolean;
}

const InlineImage = ({ attachment, url, showCaptionText, onSave, onClose, isAnnotating = false }: InlineImageProps) => {
  const attachmentName: string = useMemo(() => attachment?.name ?? '', [attachment]);

  // Wrap img in plain div to fix nested flexbox issue on Safari
  return (
    // Apply image CSS sizing to parent and image so buttons are positioned correctly.
    <div className="table">
      {!url && (
        <div className="border-gray-200 border p-5 text-xs max-w-sm">
          <div>{attachmentName}</div>
        </div>
      )}
      {url && (
        <>
          <InlineImageContent
            attachment={attachment}
            url={url}
            onSave={onSave}
            onClose={onClose}
            isAnnotating={isAnnotating}
          />
          {showCaptionText && (
            <span aria-label="Attachment Caption" className="text-xs table-caption [caption-side:bottom]">
              {attachment.caption || ''}
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default InlineImage;

import DateTimeDisplay from '../../../components/DateTimeDisplay';

export type VersionSelectOption = {
  id: string;
  version?: string | null;
  timestamp: string;
  path: string;
};

type VersionSelectProps = {
  option: VersionSelectOption;
  showTitle: boolean;
};

const VersionDisplay = ({ option, showTitle }: VersionSelectProps) => {
  return (
    <div className="flex flex-row">
      {showTitle && <span className="text-slate-800 mr-1">Version</span>}
      {option.version ? (
        <div className="text-left text-slate-800">{option.version}</div>
      ) : (
        <DateTimeDisplay timestamp={option.timestamp} hasTooltip={false} />
      )}
    </div>
  );
};

export default VersionDisplay;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../../elements/Tooltip';

const BatchProcedureStepBadge = () => {
  return (
    <div className="h-8 w-8 flex items-center justify-center">
      <Tooltip content="Batch Step">
        <FontAwesomeIcon className="text-black-700" icon="layer-group" />
      </Tooltip>
    </div>
  );
};

export default BatchProcedureStepBadge;

import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import isNil from 'lodash.isnil';
import {
  getState,
  isReleased,
  PROCEDURE_STATE_DRAFT,
  PROCEDURE_STATE_IN_REVIEW,
  PROCEDURE_STATE_RELEASED,
} from 'shared/lib/procedureUtil';
import ProcedureVersionListItem from '../components/ProcedureVersionListItem';
import {
  procedureDiffPath,
  procedurePendingPath,
  procedureReviewPath,
  procedureViewVersionPath,
} from '../lib/pathUtil';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import Button, { BUTTON_TYPES } from './Button';
import { faCodeCompare } from '@fortawesome/free-solid-svg-icons';

const ProcedureVersionList = ({ procedureId, procedure, versions }) => {
  const history = useHistory();
  const { currentTeamId } = useDatabaseServices();

  const currentVersion = useMemo(() => {
    if (!procedure || !versions) {
      return;
    }
    if (!isNil(procedure.procedure_rev_num)) {
      return versions.find((version) => version.procedure_rev_num === procedure.procedure_rev_num);
    } else {
      return versions.find((version) => version._id === procedure._id);
    }
  }, [procedure, versions]);

  const onSelectVersion = useCallback(
    (version) => {
      if (!version) {
        return;
      }
      const procedureState = getState(version);
      const state = { versions: true };
      if (procedureState === PROCEDURE_STATE_RELEASED) {
        history.push({
          pathname: procedureViewVersionPath(currentTeamId, procedureId, version.procedure_rev_num),
          state,
        });
      } else if (procedureState === PROCEDURE_STATE_DRAFT) {
        history.push({
          pathname: procedurePendingPath(currentTeamId, procedureId),
          state,
        });
      } else if (procedureState === PROCEDURE_STATE_IN_REVIEW) {
        history.push({
          pathname: procedureReviewPath(currentTeamId, procedureId),
          state,
        });
      }
    },
    [procedureId, history, currentTeamId]
  );

  const { oldDiffVersion, newDiffVersion } = useMemo(() => {
    if (!versions || versions.length < 2) {
      return {};
    }

    return {
      oldDiffVersion: versions.at(-2),
      newDiffVersion: versions.at(-1),
    };
  }, [versions]);

  return (
    <div className="w-full mt-16 flex flex-col gap-y-2">
      {versions && versions.length >= 2 && (
        <div className="px-4">
          <Button
            type={BUTTON_TYPES.SECONDARY}
            leadingIcon={faCodeCompare}
            to={{
              pathname: procedureDiffPath(currentTeamId),
              search: new URLSearchParams({
                old: oldDiffVersion.procedure_id,
                oldVersion: oldDiffVersion.version,
                oldPending: !isReleased(oldDiffVersion) ? 'true' : 'false',
                new: newDiffVersion.procedure_id,
                newVersion: newDiffVersion.version,
                newPending: !isReleased(newDiffVersion) ? 'true' : 'false',
              }).toString(),
            }}
          >
            Compare Versions
          </Button>
        </div>
      )}
      {versions && (
        <div>
          {versions.map((version) => (
            <ProcedureVersionListItem
              key={version._id}
              version={version}
              isSelected={version._id === currentVersion?._id}
              onSelect={onSelectVersion}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProcedureVersionList;

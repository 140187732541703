import React, { useEffect, useState } from 'react';
import useMousePosition from '../../hooks/useMousePosition';

const RulerMouseTick = ({ rulerMouseTickX }) => {
  const { x } = useMousePosition();
  const [, forceUpdate] = useState(0);

  useEffect(() => {
    forceUpdate(x);
  }, [x]);

  return (
    <div
      className="absolute top-0 bottom-0 border-blue-800 opacity-50 border-r-[1px] z-8"
      style={{ transform: `translate3d(${rulerMouseTickX.current}px, 0, 0)` }}
    />
  );
};

export default RulerMouseTick;

import React, { useCallback, useMemo } from 'react';
import { cloneDeep, isEmpty } from 'lodash';

import { useUserInfo } from '../../contexts/UserContext';
import { useAuth } from '../../contexts/AuthContext';
import SignoffButtons from '../SignoffButtons';
import tableInputUtil from '../../lib/tableInputUtil';
import tableUtil from 'shared/lib/tableUtil';
import SignoffAction from '../../elements/SignoffAction';
import { TableSignoff } from 'shared/lib/types/views/procedures';
import { SignoffButton } from '../types';
import { useRunContext } from '../../contexts/RunContext';
import signoffUtil from 'shared/lib/signoffUtil';

interface RunSignoffCellProps {
  signoffs?: Array<TableSignoff>;
  isDisabled?: boolean;
  isActive?: boolean;
  onSignoffsChanged?: (signoffs: Array<TableSignoff>, signoffId: string) => void;
}

const RunSignoffCell = ({
  signoffs = [],
  isDisabled = false,
  isActive = true,
  onSignoffsChanged = (updatedSignoffs) => {
    /* noop */
  },
}: RunSignoffCellProps) => {
  const { userInfo } = useUserInfo();
  const { auth } = useAuth();
  const { isPreviewMode, previewUserRoles } = useRunContext();

  const _parseButtonId = useCallback((buttonId: string) => {
    const [signoffId, operator] = buttonId.split(':');
    return { signoffId, operator };
  }, []);

  const _onSignoff = useCallback(
    (id: string) => {
      const userId = userInfo.session.user_id;

      const { signoffId, operator } = _parseButtonId(id);

      const _signoffs = cloneDeep(signoffs);
      const matchingSignoff = _signoffs.find((signoff) => signoff.id === signoffId);
      if (!matchingSignoff) {
        return;
      }
      const signoffAction = tableInputUtil.getSignoffAction({
        userId,
        operator,
      });

      matchingSignoff.actions.push(signoffAction);
      onSignoffsChanged(_signoffs, signoffId);
    },
    [signoffs, userInfo, onSignoffsChanged, _parseButtonId]
  );

  const _onRevokeSignoff = useCallback(
    (id: string) => {
      const userId = userInfo.session.user_id;

      const { signoffId } = _parseButtonId(id);
      const _signoffs = cloneDeep(signoffs);
      const matchingSignoff = _signoffs.find((signoff) => signoff.id === signoffId);
      if (!matchingSignoff) {
        return;
      }
      const revokeSignoffAction = tableInputUtil.getRevokeSignoffAction({
        userId,
        signoffId,
        actions: matchingSignoff.actions,
      });

      matchingSignoff.actions.push(revokeSignoffAction);
      onSignoffsChanged(_signoffs, signoffId);
    },
    [signoffs, userInfo, onSignoffsChanged, _parseButtonId]
  );

  const buttonDefinitions: Array<Array<SignoffButton>> = useMemo(() => {
    const allActionsWithSignoffId = signoffs.flatMap((signoff) =>
      signoff.actions.map((action) => ({
        ...action,
        signoff_id: signoff.id,
      }))
    );

    return signoffs.map((signoff) => {
      if (!signoff.operators.length) {
        return [
          {
            id: signoff.id,
            isDisabled,
            onClick: () => _onSignoff(signoff.id),
            type: 'operator_role',
          },
        ] as Array<SignoffButton>;
      }

      return signoff.operators.map((operator) => {
        const previouslyCompleted = signoffUtil.isRoleSignedOffAnywhere({
          operator,
          userId: userInfo.session.user_id,
          signoffable: {
            signoffs,
            actions: allActionsWithSignoffId,
          },
        });

        const isSignoffDisabled = isPreviewMode
          ? isDisabled || (!isEmpty(previewUserRoles) && !previewUserRoles.includes(operator)) || previouslyCompleted
          : isDisabled || !auth.hasOperatorRole(operator) || previouslyCompleted;

        return {
          id: `${signoff.id}:${operator}`,
          isDisabled: isSignoffDisabled,
          onClick: () => _onSignoff(`${signoff.id}:${operator}`),
          label: operator,
          type: 'operator_role',
        } as SignoffButton;
      });
    }, []);
  }, [signoffs, isDisabled, _onSignoff, isPreviewMode, previewUserRoles, auth, userInfo.session]);

  const getIsSignoffComplete = useCallback(
    (buttonGroup: Array<SignoffButton>) => {
      if (!signoffs.length) {
        return false;
      }

      const { signoffId } = _parseButtonId(buttonGroup[0].id);

      const signoffObject = signoffs.find((signoff) => signoff.id === signoffId);

      return tableUtil.isSignoffComplete(signoffObject);
    },
    [signoffs, _parseButtonId]
  );

  const getTooltipContent = useCallback(
    (buttonGroup: Array<SignoffButton>) => {
      if (!signoffs) {
        return;
      }
      const { signoffId } = _parseButtonId(buttonGroup[0].id);

      const matchingSignoff = signoffs.find((signoff) => signoff.id === signoffId);

      if (!matchingSignoff || !matchingSignoff.actions.length) {
        return;
      }

      return (
        <div className="flex flex-col gap-y-1">
          {matchingSignoff.actions.map((action, index) => (
            <SignoffAction key={index} action={action} />
          ))}
        </div>
      );
    },
    [signoffs, _parseButtonId]
  );

  const getIsRevokeEnabled = useCallback(
    (buttonGroup: Array<SignoffButton>) => {
      return !isDisabled && getIsSignoffComplete(buttonGroup);
    },
    [isDisabled, getIsSignoffComplete]
  );

  return (
    <div className="flex flex-row flex-wrap gap-x-1 gap-y-1 px-2">
      {buttonDefinitions.map((buttonGroup, index) => (
        <SignoffButtons
          key={index}
          buttons={buttonGroup}
          isComplete={getIsSignoffComplete(buttonGroup)}
          isDisabled={false}
          leadingIcon="check-circle"
          tooltip={getTooltipContent(buttonGroup)}
          revokeApproval={() => _onRevokeSignoff(_parseButtonId(buttonGroup[0].id).signoffId)}
          isRevokeEnabled={getIsRevokeEnabled(buttonGroup)}
          showVerticalSpaceInPrint={!isDisabled}
          isActive={isActive}
        />
      ))}
    </div>
  );
};

export default React.memo(RunSignoffCell);

import { useCallback, useEffect, useState } from 'react';
import { HazardSettingsState } from '../types';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import {
  AssessmentMatrixMetadata,
  TestingSettingsDetails,
} from 'shared/lib/types/testing';

type UseHazardSettingsReturn = {
  hazardSettings: HazardSettingsState;
  saveHazardSettings: ({
    assessmentMatrixMetadata,
  }: {
    assessmentMatrixMetadata: AssessmentMatrixMetadata;
  }) => Promise<void>;
};

const useHazardSettings = (): UseHazardSettingsReturn => {
  const { services: databaseServices } = useDatabaseServices();
  const [hazardSettings, setHazardSettings] = useState<HazardSettingsState>({
    details: [],
    loading: true,
  });

  // Get hazard settings only when the component containing the hook mounts.
  useEffect(() => {
    void getHazardSettings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getHazardSettings = useCallback(async () => {
    setHazardSettings((hazardSettings) => ({
      ...hazardSettings,
      loading: true,
    }));
    try {
      const settings: TestingSettingsDetails =
        await databaseServices.testing.getSettings();
      setHazardSettings((hazardSettings) => {
        return {
          ...hazardSettings,
          details: settings.hazards_custom_details ?? [],
          assessment_matrix_metadata: settings.assessment_matrix_metadata,
          loading: false,
        };
      });
    } catch (e) {
      setHazardSettings((hazardSettings) => ({
        ...hazardSettings,
        loading: false,
      }));
    }
  }, [databaseServices.testing]);

  const saveHazardSettings = useCallback(
    async ({
      assessmentMatrixMetadata,
    }: {
      assessmentMatrixMetadata: AssessmentMatrixMetadata;
    }) => {
      setHazardSettings((hazardSettings) => ({
        ...hazardSettings,
        loading: true,
      }));

      try {
        const settings: TestingSettingsDetails =
          await databaseServices.testing.addSettings({
            assessment_matrix_metadata: assessmentMatrixMetadata,
          });
        setHazardSettings((hazardSettings) => ({
          ...hazardSettings,
          details: settings.hazards_custom_details ?? [],
          assessment_matrix_metadata: settings.assessment_matrix_metadata,
          loading: false,
        }));
      } catch {
        setHazardSettings((hazardSettings) => ({
          ...hazardSettings,
          loading: false,
        }));
      }
    },
    [databaseServices.testing]
  );

  return {
    hazardSettings,
    saveHazardSettings,
  };
};

export default useHazardSettings;

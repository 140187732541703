import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkSlash, faExternalLinkAlt as fasExternalLinkAlt, faLink } from '@fortawesome/free-solid-svg-icons';
import DateTimeDisplay from './DateTimeDisplay';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import TooltipOverlay from './TooltipOverlay';
import Button, { BUTTON_TYPES } from './Button';
import ExpandCollapseCaret from './ExpandCollapse/ExpandCollapseCaret';
import FieldError from '../elements/internal/FieldError';
import CommentsList from './CommentsList';
import { RunRedlineComment } from 'shared/lib/types/views/procedures';

interface PromptRedlineProps {
  allRedlineUserIds: Array<string>;
  timestamp: string;
  onReject?: () => void;
  error?: string;
  onAccept?: () => void;
  children: React.ReactNode;
  isLocationAmbiguous?: boolean;
  isDisconnected?: boolean;
  contextUrl?: string;
  iconLabel?: string;
  onClickIcon?: () => void;
  startsExpanded?: boolean;
  comments?: Array<RunRedlineComment>;
}

const PromptRedline = ({
  allRedlineUserIds,
  timestamp,
  onReject,
  error,
  onAccept,
  children,
  isLocationAmbiguous,
  isDisconnected,
  contextUrl = '',
  iconLabel,
  onClickIcon,
  comments,
  startsExpanded = true,
}: PromptRedlineProps) => {
  const [isExpanded, setIsExpanded] = useState(startsExpanded);
  const backgroundColor = useMemo(
    () => (isLocationAmbiguous ? 'bg-amber-100' : 'app-bg-blue-note'),
    [isLocationAmbiguous]
  );

  const tooltipText = useMemo(() => {
    if (isLocationAmbiguous && isDisconnected) {
      return 'This suggested edit is disconnected.';
    }
    if (isLocationAmbiguous && !isDisconnected) {
      return 'This suggested edit may be out of place.';
    }
    return '';
  }, [isDisconnected, isLocationAmbiguous]);

  const linkIcon = isLocationAmbiguous ? faLinkSlash : faLink;

  return (
    <>
      <div
        className={`relative flex flex-col py-2 px-3 ${backgroundColor} rounded border border-black border-opacity-10`}
      >
        <div className="flex flex-row items-center justify-between">
          <div
            className="-ml-2 flex flex-row grow items-center cursor-pointer gap-x-1"
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            <ExpandCollapseCaret
              isExpanded={isExpanded}
              onClick={() => null}
              ariaLabel="Expand/Collapse Suggested Edit"
              isHidden={false}
            />
            <span className="-ml-1 font-semibold">{allRedlineUserIds.join(', ')}</span>
            <DateTimeDisplay timestamp={timestamp} />
            {contextUrl && (
              <div onClick={(e) => e.stopPropagation()}>
                <TooltipOverlay
                  content={
                    <div>
                      {tooltipText} See{' '}
                      <Link className="link group" to={{ pathname: contextUrl }} target="_blank">
                        source run
                        <FontAwesomeIcon
                          className="px-1 text-blue-500 group-hover:text-blue-600"
                          icon={fasExternalLinkAlt}
                        />
                      </Link>
                      for context.
                    </div>
                  }
                  delayClose={true}
                >
                  <FontAwesomeIcon icon={linkIcon} />
                </TooltipOverlay>
              </div>
            )}
          </div>
          {onClickIcon && (
            <Button
              type={BUTTON_TYPES.TERTIARY}
              ariaLabel={iconLabel}
              leadingIcon="strikethrough"
              iconTextColor="text-red-500"
              onClick={onClickIcon}
            >
              {iconLabel}
            </Button>
          )}
        </div>
        {isExpanded && (
          <>
            <div className="mt-1">{children}</div>
            {comments && <CommentsList comments={comments} />}
            <div className="flex items-center justify-between text-sm">
              {error && (
                <div className="ml-1">
                  <FieldError errorMessage={error} />
                </div>
              )}
              {!error && <div />}
              <div className="self-end flex flex-nowrap">
                {onReject !== undefined && (
                  <Button type={BUTTON_TYPES.TERTIARY} leadingIcon="times" onClick={onReject}>
                    Ignore
                  </Button>
                )}
                {onAccept !== undefined && (
                  <Button type={BUTTON_TYPES.TERTIARY} leadingIcon="check" onClick={onAccept}>
                    Accept Edits
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PromptRedline;

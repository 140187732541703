import tableUtil from './tableUtil';
import cloneDeep from 'lodash.clonedeep';
import _ from 'lodash';
import isEqual from 'lodash.isequal';
import { TableSignoff } from './types/views/procedures';

const blockUtil = {
  _addAction: (block, actionId, userId, timestamp, recorded) => {
    if (!block.actions) {
      block.actions = [];
    }
    block.actions.push({
      user_id: userId,
      action_id: actionId,
      timestamp,
      recorded,
    });
    return block;
  },

  getLatestRecorded: (block) => block.recorded,

  _getDefaultRecorded: (recorded) => {
    return recorded;
  },

  _getTableInputRecorded: (block, recorded, userOperatorRoleSet) => {
    // Get the latest recorded values.
    let latest = blockUtil.getLatestRecorded(block);
    if (!latest) {
      latest = {
        version: 1,
        values: tableUtil.getInitialRecordedCells(block),
      };
    }
    const mergedValue = tableUtil.mergeTableRecorded({
      block,
      previousRecorded: latest,
      updatedValue: recorded,
      userOperatorRoleSet,
    });
    const updated = cloneDeep(latest);
    _.set(
      updated,
      ['values', mergedValue.row, mergedValue.column],
      mergedValue.value
    );

    return updated;
  },

  addRecordedAction: ({
    block,
    userId,
    timestamp,
    actionId,
    recorded,
    userOperatorRoleSet,
  }) => {
    let updated: null | {
      row: number;
      column: number;
      value: string | boolean | TableSignoff[];
    } = null;

    try {
      if (
        block.type === 'input' ||
        block.type === 'telemetry' ||
        block.type === 'part_kit' ||
        block.type === 'part_build' ||
        block.type === 'tool_check_out' ||
        block.type === 'tool_check_in' ||
        block.type === 'part_usage' ||
        block.type === 'test_cases' ||
        block.type === 'tool_usage' ||
        block.type === 'commanding' ||
        block.type === 'inventory_detail_input'
      ) {
        updated = blockUtil._getDefaultRecorded(recorded);
      } else if (block.type === 'table_input') {
        updated = blockUtil._getTableInputRecorded(
          block,
          recorded,
          userOperatorRoleSet
        );
      } else {
        throw new Error('Unrecognized content type');
      }
    } catch (e) {
      return false;
    }

    if (isEqual(block.recorded, updated)) {
      return false;
    }

    // Set current recorded values and add to action history.
    block.recorded = updated;
    blockUtil._addAction(block, actionId, userId, timestamp, recorded);

    return true;
  },

  updateRecorded: ({ block, recorded }) => {
    // Set current recorded values.
    block.recorded = recorded;

    return true;
  },
};

export default blockUtil;

export const SOURCE_TYPES = [
  'api',
  'procedures:file',
  'procedures:field_input:attachment',
  'procedures:commanding',
  'procedures:generation',
  'runs:comments',
  'builds:inventory',
  'builds:parts',
  'builds:purchase_orders',
  'builds:tool_instances',
  'builds:tools',
  'testing:requirements',
  'issues',
  'risks',
] as const;

export type SourceType = (typeof SOURCE_TYPES)[number];

export enum SourceTypeEnum {
  Api = 'api',
  ProceduresFile = 'procedures:file',
  ProceduresFieldInputAttachment = 'procedures:field_input:attachment',
  ProceduresCommanding = 'procedures:commanding',
  ProceduresGeneration = 'procedures:generation',
  RunsComments = 'runs:comments',
  BuildsInventory = 'builds:inventory',
  BuildsParts = 'builds:parts',
  BuildsPurchaseOrders = 'builds:purchase_orders',
  BuildsTools = 'builds:tools',
  TestingRequirements = 'testing:requirements',
  Issues = 'issues',
}

// TODO add couch-specific type for use in backend
export type Attachment = {
  attachment_id: string;
  source: SourceType;
  name: string;
  content_type: string;
  _attachments: {
    [name: string]: {
      data: Buffer | Blob;
    };
  };
};
